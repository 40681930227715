export const specialLearningNeeds = [
	{
		label: 'Anxiety',
		value: 'Anxious',
	},
	{
		label: 'Attention Deficit Hyperactivity Disorder (ADHD) ',
		value: 'ADHD',
	},
	{
		label: 'Autistic Disorder',
		value: 'Autistic Disorder',
	},
	{
		label: 'Dyslexia',
		value: 'Dyslexia',
	},
	{
		label: 'Dyscalculia',
		value: 'Dyscalculia',
	},
	{
		label: 'Hearing Impairment',
		value: 'Hearing Impairment',
	},
	{
		label: 'Visual Impairment',
		value: 'Visual Impairment',
	},
];

export { SpecialNeeds, SpecialNeedsData } from './SpecialNeeds';
