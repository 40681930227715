import { Link } from 'react-router-dom';
import classNames from 'classnames';

export type HeadNavLinkProps = {
	to: string;
	isActive?: boolean;
	name: string;
};

export const HeadNavLink = ({ to, name, isActive = false }: HeadNavLinkProps) => {
	const navLinkClassesV2 = classNames(
		`flex flex-col items-center py-4 px-6 text-sm font-bold text-center rounded-t-lg border-t border-l border-r lg:px-8 grow lg:grow-0`,
		isActive ? 'border-grey-2 drop-shadow-sm' : 'border-grey-2 text-blue-5 bg-slate-1 border-b hover:text-purple-6'
	);

	return (
		<Link to={to} className={navLinkClassesV2}>
			<span>{name}</span>
		</Link>
	);
};
