import React from 'react';
import PropTypes from 'prop-types';
import { TopicCovered } from './TopicCovered';

export function TopicsCovered({ topics }) {
  return (
    <React.Fragment>
      <div className="pb-4">
        <h1 className="font-bold text-xl md:text-2xl lg:text-3xl">Topics covered</h1>
      </div>
      <div className="p-0">
        {topics.map((t) => {
          return <TopicCovered key={t} {...t} />;
        })}
      </div>
    </React.Fragment>
  );
}

TopicsCovered.propTypes = {
  // @todo update prop type based on topic data-structure
  // eslint-disable-next-line react/forbid-prop-types
  topics: PropTypes.array,
};
TopicsCovered.defaultProps = {
  topics: [],
};
