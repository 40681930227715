import classNames from 'classnames';

import { Header } from '../Header';
import { PresentationalFooter as Footer } from '../Footer';
import { ConnectedTopBanner } from '../summerMode/TopBanner';
import { ConnectedReviewEnrolmentBanner as ReviewEnrolmentBanner } from '../reboarding/ReviewEnrolmentBanner';

interface Props {
	header: {
		show: boolean;
		title: string;
	};
	footer: {
		show: boolean;
	};
	children: JSX.Element | Array<JSX.Element>;
	bodyContainerCustomClass: string;
	isNZUser: boolean;
}

/* @TODO add test */
const PageLayout = ({ header, footer, children, bodyContainerCustomClass, isNZUser }: Props) => {
	return (
		<div className="flex flex-col">
			<ReviewEnrolmentBanner />
			<ConnectedTopBanner />
			{header.show && (
				<div className="header-container flex w-full border-b-4 border-secondary bg-white">
					<Header title={header.title} isNZUser={isNZUser} />
				</div>
			)}
			<div className={classNames('body-container', bodyContainerCustomClass || '')}>{children}</div>
			{footer.show && (
				<div className="footer-container flex w-full">
					<Footer isNZUser={isNZUser} />
				</div>
			)}
		</div>
	);
};

export { PageLayout };
