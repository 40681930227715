import { z } from 'zod';
import { createApi } from '../core';
import { Assessment, AssessmentReport } from '../types/assessment';
import type { AssessmentSubject } from '../../types/hubTypes';

export const assessmentApi = createApi.group(['assessment'], (createKey) => {
  const config = {
    queryFn: async ({ customerNumber }: { customerNumber: string }) => {
      const response = await createApi.fetch({
        path: ['customer', 'assessments'],
        method: 'GET',
        query: {
          customerNumber
        },
        basePath: 'portalAPI',
        sign: true,
        validator: z.object({data: z.array(Assessment)}),
      })
      return response.data
    },
    staleTime: createApi.time(1, 'hours'),
    refetchInterval: createApi.time(2, 'hours'),
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  };
  
  return {
    all: createApi.query({
      ...config,
      queryKey: createKey(['all']),
    }),
    getReport: createApi.query({
      ...config,
      queryKey: createKey(['assessmentReport']),
      queryFn: async ({ assessmentToken }: { assessmentToken: string }) => {
        const response = await createApi.fetch({
          path: ['assessments', 'reports', 'find'],
          method: 'POST',
          body: {
            assessmentTokens: [assessmentToken],
          },
          sign: true,
          basePath: 'mulesoft',
          validator: AssessmentReport, 
        });
        
        return response; 
      },
    }),
    generate: createApi.mutation({
      mutationFn: (params: {
        userIdentifierField: 'accountId' | 'leadId';
        userIdentifierFieldValue: string;
        subject: AssessmentSubject;
        countryCode: 'AU' | 'NZ';
        yearLevel: string;
      }) => {
        return createApi.fetch({
          basePath: 'mulesoft',
          path: ['customers', 'internal', 'events', 'customerSendAssessment', 'trigger'],
          method: 'POST',
          body: {
            returnResult: false,
            userIdentifierField: {
              type: params.userIdentifierField,
              value: params.userIdentifierFieldValue,
            },
            event: {
              type: 'assessment',
              sourceSystem: 'customer-hub'
            },
            data: {
              subject: params.subject,
              countryCode: params.countryCode,
              yearLevel: params.yearLevel,
            }
          },
          validator: z.unknown(),
          sign: true,
        });
      }
    })
  }
});