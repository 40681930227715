import React from 'react';
import classNames from 'classnames';

interface SessionTimelineProps {
  animate?: boolean;
  children: React.ReactNode;
  className?: string;
}

export const SessionTimeline = ({ animate = true, children, className = '' }: SessionTimelineProps) => (
  <div className="timeline-wrapper w-full p-0 pt-9">
    <div
      className={classNames(className, 'timeline', {
        'timeline-animate': animate,
      })}
    >
      {children}
    </div>
  </div>
);
