import { Anchor, Alert, Pending } from "@cluey/cluey-components";
import { Loader } from "../../../../../components/Loader";
import { PATH_HUB_PLAN_DETAILS } from "../../../../../util/pagePath";
import { getSessionsOnDateAndBeyond } from "../../../../../selectors/sessionSelector";
import { getActiveEnrolments, getTimezone } from "../../../../../selectors/hubSelector";
import { useSelector } from "react-redux";
import { getAlertData } from "../../../../../util/helper/cancelEnrolment";
import { useGetBulkSessionPolicy, useGetSessions } from "../../../../../hooks/queries/useSessionQuery";
import { ENROLMENT_STATUS } from "../../../../../types/hubEnums";
import { getWillEnrolmentBePaused } from "../../../../../util/helper/enrolment";
import useAccountPricingPlanQuery from "../../../../../hooks/queries/useAccountPricingPlanQuery";
import type { Enrolment } from "../../../../../api/types/enrolment";

interface Props {
  selectedDate: string;
  selectedEnrolment: Enrolment
}

const CancelEnrolmentAlert = ({ selectedDate, selectedEnrolment }: Props) => {
  const { enrolmentId, enrolmentStatus, enrolmentPausedFrom, enrolmentPausedTo } = selectedEnrolment;
  const timezone = useSelector(getTimezone);
  const activeEnrolments = useSelector(getActiveEnrolments);
  const pausedOrWillBe = enrolmentStatus === ENROLMENT_STATUS.PAUSED || getWillEnrolmentBePaused({ 
    enrolmentPausedFrom,
    enrolmentPausedTo,
  });

  const { data: pausedSessions, isLoading: isPausedSessionsLoading } = useGetSessions({ 
    filter: 'paused',
    enabled: pausedOrWillBe,
  });
  
  const sessionsOnOrAfterCancellationDate = useSelector(getSessionsOnDateAndBeyond({
    date: selectedDate,
    enrolmentId,
    timezone,
    additionalSessions: pausedSessions ?? []
  }));

  const { data: bulkSessionPolicy, isLoading: bulkSessionPolicyLoading, error: bulkPolicyError } = useGetBulkSessionPolicy({
    sessionIds: sessionsOnOrAfterCancellationDate.map(session => session.studentSessionId),
    enabled: sessionsOnOrAfterCancellationDate.length > 0,
  });

  const {
    data: accountPricingPlan,
    isLoading: isLoadingAccountPricingPlan,
    isError: isErrorAccountPricingPlan,
  } = useAccountPricingPlanQuery();
  const { currentPricingPlan } = accountPricingPlan || {};

  if (sessionsOnOrAfterCancellationDate.length === 0) {
    return null;
  }

  const { alertType, title, body } = getAlertData({
    sessionsOnOrAfterCancellationDate,
    selectedDate,
    activeEnrolments,
    bulkSessionPolicy,
    timezone,
    currentPricingPlan
  })

  if (bulkPolicyError) {
    return (<Alert body="" type="error">There was an error retrieving the enrolment cancellation details.</Alert>)
  }

  if (isErrorAccountPricingPlan) {
    return (<Alert body="" type="error">There was an error retrieving the pricing plan details.</Alert>)
  }
  
  return (
    <Pending loading={
      bulkSessionPolicyLoading ||
      isLoadingAccountPricingPlan ||
      (isPausedSessionsLoading && pausedOrWillBe)
    }
      loader={<Loader />}>
      <Alert
        type={alertType}
        title={title}
        body={null}
      > 
        {body}
        <Anchor target="blank" href={PATH_HUB_PLAN_DETAILS} className="font-bold uppercase mt-3">View plan</Anchor>
      </Alert>
    </Pending>
  );
}

export default CancelEnrolmentAlert;