import moment from 'moment';
import { get, isEmpty } from 'lodash';
import Cookies from 'js-cookie';
import { logError } from '../logging';
import * as ViewTypes from '../util/constants';
import {
  RESCHEDULE_PAGE_REQUEST_TYPES, RESCHEDULE_PAGE_STATUS_CODES,
  RESCHEDULE_PAGE_EXCEPTION_TYPES, REFUND_TYPES,
} from '../util/constants';
import * as actionTypes from './actionTypes';
import {
  apiFetch,
  REQ_POST_CREATE_NEW_CASE,
  REQ_POST_CUSTOMER_TRIGGER_PAYMENT,
  REQ_POST_RESCHEDULE_SESSION,
  REQ_POST_CANCEL_SESSION,
  REQ_POST_FETCH_REFUND_POLICY,
  REQ_POST_TO_CONTACT,
} from '../services/backendApi';
import { scrollToTop } from '../util/helpers';
import { setExceptionType } from './rescheduleSessionActions';

export const updateAppView = ({ appView }) => (dispatch) => {
  scrollToTop();
  dispatch({
    type: actionTypes.APP_UPDATE_VIEW,
    payload: {
      appView,
    },
  });
};

export const setSessionForContactForm = ({ sessionDetail, studentSessionId }) => (dispatch) => {
  dispatch({
    type: actionTypes.CF_SET_SESSION_FOR_FORM,
    payload: {
      sessionDetail,
      studentSessionId,
    },
  });
};

export const initContactUsFlow = ({
  flowType, sessionDetail, studentSessionId,
}) => (dispatch) => {
  dispatch(updateAppView({ appView: ViewTypes.APP_VIEW_CONTACT_OPTIONS }));

  dispatch({
    type: actionTypes.CF_UPDATE_CONTACTUS_FLOW_TYPE,
    payload: {
      flowType,
    },
  });

  dispatch(setSessionForContactForm({ sessionDetail, studentSessionId }));
};

export const initContactUsForm = ({
  title, ciClass, caseCategory, caseType, recordType,
}) => (dispatch) => {
  dispatch(updateAppView({ appView: ViewTypes.APP_VIEW_CONTACT_FORM }));

  dispatch({
    type: actionTypes.CF_UPDATE_CONTACTUS_FORM,
    payload: {
      title,
      ciClass,
      caseCategory,
      caseType,
      recordType,
    },
  });
};

export const submitContactForm = ({
  message,
  caseOrigin,
  customerNumber,
}) => (dispatch, getState) => {
  dispatch({ type: actionTypes.CF_SUBMIT_CONTACTUS_FORM_START });

  const { contactUsFlow } = getState();
  const { studentSessionId, enrolmentId, formScreen } = contactUsFlow;
  const { caseCategory, caseType, recordType } = formScreen;

  const requestPayload = {
    action: 'ContactRequest',
    message,
    caseOrigin,
    caseCategory,
    caseType,
    recordType,
    customerNumber,
    uniqueEnrolmentId: enrolmentId,
  };

  if (studentSessionId && studentSessionId.length > 0) {
    requestPayload.studentSessionId = studentSessionId;
  }

  if (isEmpty(studentSessionId) && isEmpty(enrolmentId)) {
    logError('Submit contact case to SF without an enrolmentId or a studentSessionId!', null, {});
  }

  return apiFetch(REQ_POST_CREATE_NEW_CASE, getState(), requestPayload)
    .then(() => {
      dispatch(updateAppView({ appView: ViewTypes.APP_VIEW_CONTACT_SUCCESS }));
      dispatch({ type: actionTypes.CF_SUBMIT_CONTACTUS_FORM_SUCCESS });
    })
    .catch((e) => {
      dispatch({ type: actionTypes.CF_SUBMIT_CONTACTUS_FORM_FAILED });
      logError(e, null, { message: 'Unable to submit request to Salesforce' });
    });
};

export const resetConactFlowState = () => (dispatch) => {
  dispatch({
    type: actionTypes.CF_RESET_CONTACTUS_STATE,
  });
};

export const saveEnrolmentIdForSAF = ({ enrolmentId }) => (dispatch) => {
  dispatch({
    type: actionTypes.CF_SAVE_ENROLMENT_ID_FOR_SAF,
    payload: { enrolmentId },
  });
};

export const triggerPaymentToSF = ({ studentSessionId }) => (dispatch, getState) => {
  dispatch({ type: actionTypes.CF_TRIGGER_PAYMENT_START });
  const { triggerPayment } = getState();
  const { succeededSessions, failedSessions } = triggerPayment;

  return apiFetch(REQ_POST_CUSTOMER_TRIGGER_PAYMENT, getState(), { studentSessionId })
    .then(() => {
      const index = failedSessions.indexOf(studentSessionId);
      if (index > -1) {
        failedSessions.splice(index, 1);
      }

      dispatch({
        type: actionTypes.CF_TRIGGER_PAYMENT_SUCCESS,
        payload: {
          succeededSessions: [...succeededSessions, studentSessionId],
          failedSessions: [...failedSessions],
        },
      });
      Cookies.set(studentSessionId, true, {
        expires: 1 / 24, // one hour expiry
      });
    })
    .catch((e) => {
      const index = succeededSessions.indexOf(studentSessionId);
      if (index > -1) {
        succeededSessions.splice(index, 1);
      }

      dispatch({
        type: actionTypes.CF_TRIGGER_PAYMENT_FAILED,
        payload: {
          succeededSessions: [...succeededSessions],
          failedSessions: [...failedSessions, studentSessionId],
        },
      });

      logError(e, null, { message: `Unable to trigger payment for session ${studentSessionId}` });
    });
};

export const changeSession = ({ requestType }) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.RS_POST_SESSION_TO_CHANGE_START,
    payload: {
      requestType,
    },
  });
  const { rescheduleSession } = getState();
  const {
    studentSessionId, selectedSession, nextSession, token,
  } = rescheduleSession;

  const payload = {
    action: requestType,
    studentSessionId,
    uniqueEnrolmentId: token,
  };
  let endpointType = REQ_POST_CANCEL_SESSION;

  if (requestType === RESCHEDULE_PAGE_REQUEST_TYPES.RESCHEDULE) {
    const { sessionId, startTime, endTime } = selectedSession;
    payload.targetSessionId = sessionId;
    payload.startDateAndTime = moment.utc(startTime).format();
    payload.endDateAndTime = moment.utc(endTime).format();

    endpointType = REQ_POST_RESCHEDULE_SESSION;
  }

  return apiFetch(endpointType, getState(), payload)
    .then(() => {
      let changedSession = {};
      if (requestType === RESCHEDULE_PAGE_REQUEST_TYPES.RESCHEDULE) {
        changedSession = selectedSession;
      } else {
        changedSession = nextSession;
      }

      dispatch({
        type: actionTypes.RS_POST_SESSION_TO_CHANGE_SUCCESS,
        payload: {
          changedSession,
        },
      });
      dispatch(updateAppView({ appView: ViewTypes.APP_VIEW_CONTACT_SUCCESS }));
    })
    .catch((e) => {
      dispatch({
        type: actionTypes.RS_POST_SESSION_TO_CHANGE_FAILED,
      });

      logError(e, null, { message: `Unable to change session with id ${studentSessionId}` });
    });
};

const deriveRefundType = (rescheduleStatusCode, cancelStatusCode) => {
  const errorMap = new Map();
  errorMap.set(
    `${RESCHEDULE_PAGE_STATUS_CODES.C00}${RESCHEDULE_PAGE_STATUS_CODES.R00}`,
    REFUND_TYPES.FULL_REFUND,
  );
  errorMap.set(
    `${RESCHEDULE_PAGE_STATUS_CODES.C06}${RESCHEDULE_PAGE_STATUS_CODES.R00}`,
    REFUND_TYPES.PARTIAL_REFUND,
  );
  errorMap.set(
    `${RESCHEDULE_PAGE_STATUS_CODES.C06}${RESCHEDULE_PAGE_STATUS_CODES.R04}`,
    REFUND_TYPES.PARTIAL_REFUND,
  );
  errorMap.set(
    `${RESCHEDULE_PAGE_STATUS_CODES.C05}${RESCHEDULE_PAGE_STATUS_CODES.R00}`,
    REFUND_TYPES.NO_REFUND_YES_RESCHEDULE,
  );
  errorMap.set(
    `${RESCHEDULE_PAGE_STATUS_CODES.C05}${RESCHEDULE_PAGE_STATUS_CODES.R04}`,
    REFUND_TYPES.NO_REFUND_YES_RESCHEDULE,
  );
  errorMap.set(
    `${RESCHEDULE_PAGE_STATUS_CODES.C03}${RESCHEDULE_PAGE_STATUS_CODES.R00}`,
    REFUND_TYPES.NO_REFUND_YES_RESCHEDULE,
  );
  errorMap.set(
    `${RESCHEDULE_PAGE_STATUS_CODES.C03}${RESCHEDULE_PAGE_STATUS_CODES.R04}`,
    REFUND_TYPES.NO_REFUND_YES_RESCHEDULE,
  );
  errorMap.set(
    `${RESCHEDULE_PAGE_STATUS_CODES.C03}${RESCHEDULE_PAGE_STATUS_CODES.R05}`,
    REFUND_TYPES.NO_REFUND_NO_RESCHEDULE,
  );
  errorMap.set(
    `${RESCHEDULE_PAGE_STATUS_CODES.C05}${RESCHEDULE_PAGE_STATUS_CODES.R05}`,
    REFUND_TYPES.NO_REFUND_NO_RESCHEDULE,
  );
  errorMap.set(
    `${RESCHEDULE_PAGE_STATUS_CODES.C04}${RESCHEDULE_PAGE_STATUS_CODES.R01}`,
    REFUND_TYPES.NO_REFUND_RESCHEDULED_ONCE,
  );
  errorMap.set(
    `${RESCHEDULE_PAGE_STATUS_CODES.C01}${RESCHEDULE_PAGE_STATUS_CODES.R00}`,
    REFUND_TYPES.NO_POLICY,
  );
  errorMap.set(
    `${RESCHEDULE_PAGE_STATUS_CODES.C01}${RESCHEDULE_PAGE_STATUS_CODES.R05}`,
    REFUND_TYPES.NO_POLICY,
  );

  return errorMap.get(`${cancelStatusCode}${rescheduleStatusCode}`) ?
    errorMap.get(`${cancelStatusCode}${rescheduleStatusCode}`) : REFUND_TYPES.NO_POLICY;
};

const getExceptionTypeOrRefundPolicy = (apiRes) => {
  const { cancelStatusCode, rescheduleStatusCode } = apiRes;
  let exceptionType = RESCHEDULE_PAGE_EXCEPTION_TYPES.NO_EXCEPTION;
  let refundType = REFUND_TYPES.NO_POLICY;

  if (cancelStatusCode === RESCHEDULE_PAGE_STATUS_CODES.E01
    || cancelStatusCode === RESCHEDULE_PAGE_STATUS_CODES.E99) {
    exceptionType = RESCHEDULE_PAGE_EXCEPTION_TYPES.SYSTEM_NOT_AVAILABLE;
  }

  if (rescheduleStatusCode === RESCHEDULE_PAGE_STATUS_CODES.R01
    && cancelStatusCode !== RESCHEDULE_PAGE_STATUS_CODES.C01) {
    refundType = REFUND_TYPES.NO_REFUND_RESCHEDULED_ONCE;
  } else {
    refundType = deriveRefundType(rescheduleStatusCode, cancelStatusCode);
  }

  return {
    exceptionType,
    refundType,
  };
};
// eslint-disable-next-line
export const fetchRefundDetails = (from, to) => (dispatch, getState) => {
  // do the api call to salesforce
  return Promise.resolve();
};


export const fetchRefundPolicy = () => (dispatch, getState) => {
  dispatch({
    type: actionTypes.RS_GET_REFUND_POLICY_START,
  });

  const { rescheduleSession } = getState();
  const { studentSessionId } = rescheduleSession;

  const payload = {
    action: RESCHEDULE_PAGE_REQUEST_TYPES.CANCEL,
    studentSessionId,
  };

  return apiFetch(REQ_POST_FETCH_REFUND_POLICY, getState(), payload)
    .then((json) => {
      const { exceptionType, refundType } = getExceptionTypeOrRefundPolicy(json, dispatch);
      if (exceptionType !== RESCHEDULE_PAGE_EXCEPTION_TYPES.NO_EXCEPTION) {
        dispatch(setExceptionType({ exceptionType }));
      } else {
        dispatch({
          type: actionTypes.RS_GET_REFUND_POLICY_SUCCESS,
          payload: {
            refundType,
            refundAmount: json.amountToRefund,
          },
        });
        dispatch(updateAppView({ appView: ViewTypes.APP_VIEW_CONTACT_CANCEL_REQUEST }));
      }
    })
    .catch((e) => {
      dispatch({
        type: actionTypes.RS_GET_REFUND_POLICY_FAILED,
      });
      dispatch(setExceptionType({ exceptionType: RESCHEDULE_PAGE_EXCEPTION_TYPES.SYSTEM_NOT_AVAILABLE }));

      logError(e, null, { message: `Unable to get refund policy for session ${studentSessionId}` });
    });
};

export const initContactSpecialRequest = ({ requestView, selectedSession }) => (dispatch) => {
  dispatch({
    type: actionTypes.RS_SELECT_SESSION_TO_CHANGE,
    payload: {
      selectedSession,
    },
  });

  if (requestView === ViewTypes.APP_VIEW_CONTACT_CANCEL_REQUEST) {
    dispatch(fetchRefundPolicy());
  } else {
    dispatch(updateAppView({ appView: requestView }));
  }
};

export const saveSessionForHubForm = ({
  uniqueEnrolmentId,
  student,
  subject,
  studentSessionId,
  startTime,
  endTime,
  tutor,
}) => (dispatch) => {
  dispatch({
    type: actionTypes.CF_SAVE_SELECTED_SESSIONS_FOR_HUB,
    payload: {
      uniqueEnrolmentId,
      student,
      subject,
      studentSessionId,
      startTime,
      endTime,
      tutor,
    },
  });
};

export const submitContactCase = ({
  action = 'Contact Request',
  startDate,
  endDate,
  uniqueEnrolmentId,
  studentSession,
  caseCategory,
  relatedBillingContact,
  targetSession,
  message,
  caseSubject,
  caseSubCategory,
  caseType,
  caseRecordType = 'Support',
  caseOrigin = 'Hub',
  caseRequestFrom,
  groupPackageCode,
  email,
  onSubmitSucceed = () => {},
  onSubmitFailed = () => {},
  actionsTodo = {},
}) => (dispatch, getState) => {
  const { login } = getState();
  const customerId = get(login, 'customerId', null);

  const requestPayload = {
    startDate,
    endDate,
    action,
    uniqueEnrolmentId,
    caseOrigin,
    studentSession,
    caseCategory,
    relatedBillingContact,
    targetSession,
    message,
    caseSubject,
    caseSubCategory,
    caseType,
    caseRecordType,
    caseRequestFrom,
    email,
    customerNumber: customerId,
    groupPackageCode,
  };

  let actions = {
    start: actionTypes.CF_SUBMIT_CONTACTUS_FORM_START,
    success: actionTypes.CF_SUBMIT_CONTACTUS_FORM_SUCCESS,
    failed: actionTypes.CF_SUBMIT_CONTACTUS_FORM_FAILED,
  };

  if (!isEmpty(actionsTodo)) {
    actions = {
      start: actionsTodo.start,
      success: actionsTodo.success,
      failed: actionsTodo.failed,
    };
  }

  dispatch({
    type: actions.start,
  });

  return apiFetch(REQ_POST_TO_CONTACT, getState(), requestPayload, dispatch)
    .then(() => {
      dispatch({ type: actions.success });
      onSubmitSucceed();
    })
    .catch((e) => {
      const errorMsg = get(e, 'message');
      dispatch({
        type: actions.failed,
        payload: {
          errorMsg,
        },
      });
      onSubmitFailed(errorMsg);
      logError(e, null, { message: 'Unable to submit request to Heroku' });
    });
};
