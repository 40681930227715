import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Form } from '@cluey/cluey-components';

import { completeSignup } from '../../../actions/hub/hubAuthPagesActions';
import withHeadTag from '../../../common/HeadComponent';
import AuthPageLayout from '../../../common/hub/AuthPageLayout';
import InPageLoader from '../../../common/InPageLoader';
import PageError from '../../../common/PageError';
import ErrorBlock from '../../../common/ErrorBlock';
import PasswordField from '../../../components/hub/auth/PasswordField';
import RetypedPasswordField from '../../../components/hub/auth/RetypedPasswordField';
import KeepMeLoggedIn from '../../../components/hub/auth/KeepMeLoggedIn';

import { PASSWORD_VALIDATION_RESULT } from '../../../util/constants';
import { PATH_HUB_ONBOARDING } from '../../../util/pagePath';
import { validatePassword } from '../../../util/validation/password';
import { passwordValidatorHelper } from '../../../util/helpers';
import TextBlock from '../../../components/hub/auth/TextBlock';

const HubSignupConfirmationPage = ({
  email,
  tempPassword,
  activeSpinner,
  errorLoading,
  completeSignupWithNewPWD,
}) => {
  const history = useHistory();
  const [passwordOrg, setPasswordOrg] = useState('');
  const [passwordRetyped, setPasswordRetyped] = useState('');
  const [retypedPWDValidation, setRetypedPWDValidation] = useState(PASSWORD_VALIDATION_RESULT.INIT);
  const [inPageError, setInPageError] = useState('');

  const pageError = !email || !tempPassword;
  // const pageError = false; // for dev

  useEffect(() => {
    passwordValidatorHelper({
      passwordOrg,
      passwordRetyped,
      setRetypedPWDValidation,
    });
  }, [passwordOrg, passwordRetyped]);

  const onConfirm = (e) => {
    e.target.blur();
    if (passwordOrg === '' || passwordRetyped === '') {
      setInPageError('Please enter and confirm your password!');
      return;
    }

    if (!validatePassword(passwordRetyped)) {
      setRetypedPWDValidation(PASSWORD_VALIDATION_RESULT.INVALID);
      return;
    }

    if (validatePassword(passwordOrg) && validatePassword(passwordRetyped)) {
      if (passwordOrg === passwordRetyped) {
        setRetypedPWDValidation(PASSWORD_VALIDATION_RESULT.MATCH);
      } else {
        setRetypedPWDValidation(PASSWORD_VALIDATION_RESULT.NOT_MATCH);
        return;
      }
    } else {
      setInPageError('Please make sure your passwords are valid!');
      return;
    }

    setInPageError('');
    setRetypedPWDValidation(PASSWORD_VALIDATION_RESULT.INIT);
    completeSignupWithNewPWD({
      email,
      tempPassword,
      newPassword: passwordOrg,
      callback: () => {
        history.push(PATH_HUB_ONBOARDING);
      },
    });
  };

  return (
    <Fragment>
      { (errorLoading || pageError) && <PageError activePage="hub-first-log-in-page" retryEnabled={false} /> }
      {
        (!errorLoading && !pageError) && (
          <AuthPageLayout
            title="Complete your Cluey Hub account set up"
            subtitle="Complete the set up of your Cluey Hub account to view and manage your sessions, enrolments and account settings."
          >
            { inPageError && <ErrorBlock errorMsg={inPageError} /> }
            {activeSpinner && <InPageLoader position="relative" />}
            {
              !activeSpinner && (
                <Fragment>
                  <TextBlock
                    upper="Your email address"
                    lower={email}
                  />
                  <Form>
                    <PasswordField
                      fieldLabel="Password"
                      fieldId="passwordOrg"
                      savePassword={setPasswordOrg}
                    />
                    <RetypedPasswordField
                      fieldLabel="Retype password"
                      fieldId="passwordRetyped"
                      placeholderText="Please re-enter your password"
                      savePassword={setPasswordRetyped}
                      retypedPWDValidation={retypedPWDValidation}
                    />
                    <KeepMeLoggedIn />
                  </Form>
                  <Button size="xs" className="w-full uppercase" onClick={onConfirm}>Complete sign up</Button>
                </Fragment>
              )
            }
          </AuthPageLayout>
        )
      }
    </Fragment>
  );
};

HubSignupConfirmationPage.propTypes = {
  email: PropTypes.string.isRequired,
  tempPassword: PropTypes.string.isRequired,
  activeSpinner: PropTypes.bool.isRequired,
  errorLoading: PropTypes.bool.isRequired,
  completeSignupWithNewPWD: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    const { ui, hubUser } = state;
    return {
      email: hubUser.email,
      tempPassword: hubUser.tempPassword,
      activeSpinner: ui.apiState.hubAuthPage.isLoading,
      errorLoading: ui.apiState.hubAuthPage.fetchError,
    };
  },
  (dispatch) => {
    return {
      completeSignupWithNewPWD: bindActionCreators(completeSignup, dispatch),
    };
  },
)(withHeadTag(HubSignupConfirmationPage));

export { HubSignupConfirmationPage as StatelessHubSignupConfirmationPage };
