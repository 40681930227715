import { type FC, useEffect, ComponentType } from 'react';
import { useHistory, useParams } from 'react-router';
import { api } from '../../api';
import { Pending } from '@cluey/cluey-components';
import { Loader } from '../../components/Loader';
import { Enrolment } from '../../api/types/enrolment';
import SelectionModal, { useSelectionModalProps } from '../../components/hub/contact/SelectionModal';
import PageError from '../../common/PageError';
import { PATH_HUB_HOME } from '../../util/pagePath';
import { isGroupSessionCheck } from '../../util/helpers';
import { ENROLMENT_SELECTION_MODAL_TITLE, SESSION_TIME_FORMAT, HOC_PAGE_PARAM } from '../../util/constants';
import { useSearchParams } from '../../util/hooks/useSearchParams';
import { useDispatch, useSelector } from 'react-redux';
import { saveSelectedEnrolment } from '../../actions/hub/hubContactPageActions';
import { InitialState } from '../../types/hubTypes';
import moment from 'moment';
import first from 'lodash/first';

export type SelectedEnrolment = Enrolment & {
	resumeSessionDate?: string;
};

type SelectedEnrolmentProps = {
	selectedEnrolment: SelectedEnrolment;
};

type HOCPageParam = keyof typeof HOC_PAGE_PARAM;

const withSelectedEnrolment = <Props extends SelectedEnrolmentProps>(
	Component: ComponentType<Props>,
	page: HOCPageParam | null = HOC_PAGE_PARAM.DEFAULT,
	redirect?: string
): FC<Props> => {
	return function ComponentWithAsyncData(props: Props) {
		const history = useHistory();
		const dispatch = useDispatch();
		const urlParams = useParams<{ enrolmentId: string }>();
		const queryParams = useSearchParams();
		const { selectedRadio, setSelectedRadio } = useSelectionModalProps();
		/*
      @TODO refactor
      A fetch dispatch can't be found for this state slice;
      It can be refactored to a regular function instead.
    */
		const resumeDates = useSelector((state: InitialState) => state.hubEnrolmentsWithResumeSession || []);
		const {
			data: activeEnrolments,
			isLoading: loadingActiveEnrolments,
			isError: loadingActiveEnrolmentsError,
		} = api.enrolment.all.useQuery('active');
		const {
			data: cancelledEnrolments,
			isLoading: loadingCancelledEnrolments,
			isError: loadingCancelledEnrolmentsError,
		} = api.enrolment.all.useQuery('cancelled');
		const {
			data: pastEnrolments,
			isLoading: loadingPastEnrolments,
			isError: loadingPastEnrolmentsError,
		} = api.enrolment.all.useQuery('past');

		const allEnrolments = [...(activeEnrolments || []), ...(cancelledEnrolments || []), ...(pastEnrolments || [])];

		const loadingEnrolments = loadingActiveEnrolments || loadingCancelledEnrolments || loadingPastEnrolments;
		const loadingEnrolmentsError =
			loadingActiveEnrolmentsError || loadingCancelledEnrolmentsError || loadingPastEnrolmentsError;

		const { data: accountDetails, isLoading: loadingAccountDetails } = api.account.details.useQuery();
		const { timezone = 'Australia/Sydney' } = accountDetails ?? {};

		const getEnrolmentId = () => {
			if (!!urlParams?.enrolmentId) return urlParams?.enrolmentId;
			if (!!queryParams?.get('token')) return queryParams?.get('token');
			if (allEnrolments?.length === 1) return first(allEnrolments)?.enrolmentId;
			return undefined;
		};
		const enrolmentId = getEnrolmentId();

		const getSelectedEnrolment = () => {
			const selected =
				allEnrolments?.length === 1
					? allEnrolments[0]
					: allEnrolments?.find((enrolment) => enrolment.enrolmentId === enrolmentId);
			if (!selected) return undefined;
			const { tutor, startTime, endTime } = selected;
			const nextSessionDate = moment(startTime).tz(timezone).format('dddd, MMMM D');
			const nextSessionStartTime = moment(startTime).tz(timezone).format(SESSION_TIME_FORMAT);
			const nextSessionEndTime = moment(endTime).tz(timezone).format(SESSION_TIME_FORMAT);
			const { resumeSessionDate } = resumeDates.find((e) => e.enrolmentId === enrolmentId) || {};

			return {
				...selected,
				nextSessionTutor: `Next session with ${tutor}`,
				nextSessionDetail: `${nextSessionDate} at ${nextSessionStartTime} - ${nextSessionEndTime}`,
				resumeSessionDate,
				timezone,
			};
		};
		const selectedEnrolment = getSelectedEnrolment();

		useEffect(() => {
			if (!!enrolmentId) {
				dispatch(
					saveSelectedEnrolment({
						enrolmentId,
					})
				);
			}
		}, [dispatch, enrolmentId]);

		const onBackClickHandler = () => {
			history.push(PATH_HUB_HOME);
		};

		const getRadioOptions = () => {
			return activeEnrolments?.map((enrolment) => {
				const { student, subject, enrolmentId, type } = enrolment;
				return {
					text: `${student}, ${subject}`,
					value: enrolmentId,
					isGroup: isGroupSessionCheck(type),
				};
			});
		};

		const renderComponent = () => {
			if (!enrolmentId && loadingEnrolmentsError) {
				return <PageError />;
			}
			return !!selectedEnrolment ? (
				<Component {...props} selectedEnrolment={selectedEnrolment} />
			) : (
				<SelectionModal
					showModal
					toggleModal={onBackClickHandler}
					selectedRadio={selectedRadio}
					setSelectedRadio={setSelectedRadio}
					radios={getRadioOptions()}
					title={ENROLMENT_SELECTION_MODAL_TITLE}
					page={page}
					cyClassName="cy-enrolment-selection"
					showBackBtn
					redirect={redirect}
				/>
			);
		};

		return (
			<Pending loading={loadingEnrolments || loadingAccountDetails} loader={<Loader />}>
				{renderComponent()}
			</Pending>
		);
	};
};

export default withSelectedEnrolment;
