import initialState from '../../initialState';
import * as actionTypes from '../../../actions/actionTypes';

export default function summaryReducer(state = initialState.ui.apiState.summary, action) {
  switch (action.type) {
    case actionTypes.SR_REQUEST_SUMMARY_SUCCESS:
      return {
        ...state,
        firstFetch: false,
      };
    case actionTypes.SR_REQUEST_SUMMARY_FAILED:
      return {
        ...state,
        fetchError: true,
        firstFetch: false,
      };
    default:
      return state;
  }
}
