import { Enrolment } from '../../../../../api/types/enrolment';
import { StudentEnrolments } from './types';

export function useMapStudentsEnrolments(enrolments: Enrolment[]): StudentEnrolments {
  return enrolments.reduce((studentEnrolments: StudentEnrolments, enrolment) => {
    const enrolmentList = studentEnrolments[enrolment.student] || [];
    studentEnrolments[enrolment.student] = [...enrolmentList, enrolment];
    return studentEnrolments;
  }, {});
}
