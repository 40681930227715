import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { get } from 'lodash';

import InPageLoader from '../common/InPageLoader';
import StudentProgressSummary from '../components/StudentProgress/StudentProgressSummary';
import CurriculumProgressSummary from '../components/StudentProgress/CurriculumProgressSummary';
import PracticeSummary from '../components/StudentProgress/PracticeSummary';
import NewYearBanner from '../components/StudentProgress/NewYearBanner';

import { fetchStudentProgressData } from '../actions/studentProgressActions';
import {
  hasModules as wereModulesCovered,
  isEndOfTheYear,
  showPracticeData,
  showSummarySection,
} from '../selectors/studentProgressSelector';
import DataNotAvailable from '../components/StudentProgress/DataNotAvailable';

const StudentProgress = ({
  needFetchData,
  isFetching,
  fetchError,
  fetchData,
  hasPractice,
  hasModules,
  showEOY,
  studentName,
  hasSummary,
  country,
}) => {
  useEffect(() => {
    if (needFetchData) {
      fetchData();
    }
  }, [needFetchData, fetchData]);

  if (isFetching) {
    return <InPageLoader bgClass="bg-white" position="relative" />;
  }

  if (fetchError) {
    return (
      <div className="p-6">
        <DataNotAvailable
          firstLine="We are having some trouble loading your report"
          secondLine="Please try again in a few minutes"
          showReloadButton
        />
      </div>
    );
  }

  return (
    <Fragment>
      <div
        className={classNames(
          'track-progress-overview-container container mx-auto px-4 pt-9 font-body text-grey-6'
        )}
      >
        <StudentProgressSummary country={country} />
      </div>
      {hasModules && (
        <div className="container mx-auto mb-12 text-grey-6 lg:mb-16">
          <CurriculumProgressSummary />
        </div>
      )}
      {hasPractice && (
        <div className="container mx-auto mb-10 text-grey-6">
          <PracticeSummary country={country} />
        </div>
      )}
      {!hasPractice && !hasModules && !hasSummary && (
        <div className="pt-20 pb-24">
          <DataNotAvailable
            firstLine="We are still processing the data"
            secondLine={`${studentName}’s progress report will be available soon `}
          />
        </div>
      )}
      {showEOY && <NewYearBanner customClass="pt-6 pb-9 lg:pt-14 lg:pb-20" />}
    </Fragment>
  );
};

StudentProgress.propTypes = {
  needFetchData: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  fetchError: PropTypes.bool.isRequired,
  fetchData: PropTypes.func.isRequired,
  hasPractice: PropTypes.bool.isRequired,
  hasModules: PropTypes.bool.isRequired,
  hasSummary: PropTypes.bool.isRequired,
  showEOY: PropTypes.bool.isRequired,
  studentName: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
};

export default connect(
  (state) => {
    const firstFetch = get(state, 'ui.apiState.studentProgress.firstFetch');
    const fetchError = get(state, 'ui.apiState.studentProgress.fetchError');
    return {
      needFetchData: firstFetch && !fetchError,
      isFetching: firstFetch,
      fetchError,
      hasPractice: showPracticeData(state),
      hasModules: wereModulesCovered(state),
      hasSummary: showSummarySection(state),
      showEOY: isEndOfTheYear(state),
      studentName: state.report.studentName,
      country: state.login.country,
    };
  },
  (dispatch) => {
    return {
      fetchData: bindActionCreators(fetchStudentProgressData, dispatch),
    };
  }
)(StudentProgress);
