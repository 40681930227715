import React from 'react';
import PropTypes from 'prop-types';
import env from '../env';
import { AnchorButton, Container } from '@cluey/cluey-components';

import { PATH_HUB_CR_REQUEST_OPTIONS } from '../util/pagePath';
import { getCompanyDetailByCountry } from '../util/helpers';
import { CUSTOMER_SUPPORT_EMAIL } from '../util/constants';

const Footer = ({ title, copyright, isNZUser }) => {
  const { address, postcode, phone, businessNumber } =
    getCompanyDetailByCountry({ isNZ: isNZUser });
  const contactUsHref = isNZUser
    ? `mailto:${CUSTOMER_SUPPORT_EMAIL}`
    : PATH_HUB_CR_REQUEST_OPTIONS;
  const showContactUs = !env.REACT_APP_MAINTENANCE_MODE && !isNZUser;

  return (
    <div className="mt-8 flex w-full">
      <Container>
        <div className="mb-4 border-t-2 border-grey-2 text-grey-6 lg:mb-5">
          <h6 className="mb-0 pt-6 pb-3 text-2xs font-bold uppercase tracking-[1px]">
            {title}
          </h6>
          <div className="flex flex-col text-sm md:flex-row">
            <ul className="w-auto p-0 lg:w-72">
              <li>{address}</li>
              <li className="uppercase">{postcode}</li>
            </ul>
            <ul className="w-auto p-0 lg:w-72">
              <li x-ms-format-detection="none">Phone: {phone}</li>
              <li className="uppercase">ABN: {businessNumber}</li>
            </ul>
            {showContactUs && (
              <div className="hidden text-right md:ml-auto md:block">
                <p className="mb-2 text-base font-bold leading-none">
                  Have an enquiry?
                </p>
                {/* DO NOT USE <LINK TO REDIRECT OTHERWISE IT WILL NOT FORCE THE PAGE TO
          RELOAD AND IT WILL USE THE UN-AUTHENTICATED CREDENTIALS  */}
                <AnchorButton
                  href={contactUsHref}
                  size="xs"
                  appearance="reverse"
                >
                  Contact us
                </AnchorButton>
              </div>
            )}
          </div>
        </div>
        <div className="border-t-2 border-grey-2 pt-4 pb-11 text-xs text-grey-6">
          &copy; {copyright}
        </div>
      </Container>
    </div>
  );
};

Footer.propTypes = {
  title: PropTypes.string,
  copyright: PropTypes.string,
  isNZUser: PropTypes.bool.isRequired,
};

Footer.defaultProps = {
  title: 'Cluey Learning',
  copyright: 'Copyright 2020, Cluey Learning',
};
export { Footer as PresentationalFooter };
