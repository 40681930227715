import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useHistory } from 'react-router-dom';
import { ButtonAsLink, Button } from '@cluey/cluey-components';

import { redirectToFormWithEnrolmentId } from '../../actions/hub/hubEnrolmentManagementActions';
import { redirectToChangeSchedule } from '../../actions/hub/hubChangeSchedulePageActions';
import {
	isGroupEnrolment,
	isEnrolmentCancelled,
	isEnrolmentPending,
	isEnrolmentPaused,
	isEnrolmentCancelRequested,
	isEnrolmentFinished,
	isEnrolmentActive,
} from '../../selectors/hubSelector';
import { willEnrolmentBePaused, getEnrolmentWillBePausedResumeSession } from '../../selectors/enrolmentSelector';
import { shouldShowHolidayLearningBanner } from '../../selectors/summerModeSelector';
import {
	PATH_HUB_CR_CHANGE_TUTOR,
	PATH_HUB_ENROLMENT_CHANGE_SCHEDULE,
	PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP,
	PATH_HUB_UPCOMING_SESSIONS,
} from '../../util/pagePath';
import alertExclamation from '../../assets/images/icon-filled-alert-exclamation.svg';

const EnrolmentStatusReboardingView = ({
	enrolmentId,
	isGroup,
	isPaused,
	isPending,
	isCancelledOrFinished,
	isActive,
	willBePaused,
	redirectToForm,
	redirectToCSP,
	showHolidayLearningBanner,
}) => {
	const history = useHistory();
	const containerClasses = classNames(
		'flex flex-col lg:flex-row mt-4 md:mt-5 lg:mt-6 rounded-lg enrolment-status bg-yellow-1'
	);

	const onRequestTutorChangeClick = () => {
		redirectToForm({
			enrolmentId,
			callback: () => {
				history.push(PATH_HUB_CR_CHANGE_TUTOR);
			},
		});
	};

	const onChangeScheduleClick = (e) => {
		e.preventDefault();
		const changeSchedulePage = isGroup ? PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP : PATH_HUB_ENROLMENT_CHANGE_SCHEDULE;
		redirectToCSP({
			enrolmentId,
			callback: () => {
				history.push(changeSchedulePage);
			},
		});
	};

	if (!showHolidayLearningBanner || !isActive || isPaused || willBePaused || isPending || isCancelledOrFinished) {
		return null;
	}

	const shouldDisplayChangeTutor = !isGroup;
	const changeScheduleLinkLabel = isGroup ? 'Change group' : 'Change ongoing schedule';

	return (
		<div className={containerClasses}>
			<div className="mb-5 flex items-start lg:mb-0">
				<img src={alertExclamation} alt="icon" className="mr-5 lg:mr-4" />
				<div>
					<span className="block text-base leading-tight">Holiday learning ends on Jan 30</span>
					<span className="mt-1 block text-lg font-bold leading-snug">
						The enrolment will continue as indicated below.
					</span>
					<span className="mt-1 block text-lg font-bold leading-snug">
						Please review and manage as required for the upcoming school year.
					</span>
				</div>
			</div>
			<div className="cta-wrapper lg:ml-auto">
				<Fragment>
					<Button
						size="xs"
						appearance="reverse"
						onClick={onChangeScheduleClick}
						className="track-hub-enrolment-details-change-schedule mb-5 block uppercase lg:mb-4"
					>
						{changeScheduleLinkLabel}
					</Button>
					<Link
						to={PATH_HUB_UPCOMING_SESSIONS}
						rel="noopener noreferrer"
						className="mb-1 block text-sm leading-[1.14] text-primary hover:underline"
					>
						Manage upcoming sessions
					</Link>
					{shouldDisplayChangeTutor && (
						<ButtonAsLink
							size="sm"
							className="block border-0 p-0 leading-[1.14] tracking-normal"
							onClick={onRequestTutorChangeClick}
						>
							Request tutor change
						</ButtonAsLink>
					)}
				</Fragment>
			</div>
		</div>
	);
};

EnrolmentStatusReboardingView.propTypes = {
	enrolmentId: PropTypes.string.isRequired,
	isGroup: PropTypes.bool,
	isPaused: PropTypes.bool,
	isPending: PropTypes.bool,
	isCancelledOrFinished: PropTypes.bool,
	isActive: PropTypes.bool,
	willBePaused: PropTypes.bool,
	willBePausedDates: PropTypes.shape({
		pauseFrom: PropTypes.string,
		resumeFrom: PropTypes.string,
	}),
	redirectToForm: PropTypes.func.isRequired,
	redirectToCSP: PropTypes.func.isRequired,
	showHolidayLearningBanner: PropTypes.bool,
};

EnrolmentStatusReboardingView.defaultProps = {
	isGroup: false,
	isPaused: false,
	isPending: false,
	isCancelledOrFinished: false,
	isActive: false,
	willBePaused: false,
	willBePausedDates: {},
	showHolidayLearningBanner: false,
};

export const EnrolmentStatusReboarding = connect(
	(state) => {
		const { enrolmentId, enrolmentPausedTo } = state.hubEnrolmentDetailPage;
		return {
			enrolmentId,
			enrolmentPausedTo,
			timezone: state.hubUser.timezone,
			isGroup: isGroupEnrolment(state),
			isPaused: isEnrolmentPaused(state),
			isPending: isEnrolmentPending(state),
			isCancelledOrFinished: isEnrolmentCancelled(state) || isEnrolmentFinished(state),
			isCancelRequested: isEnrolmentCancelRequested(state),
			isActive: isEnrolmentActive(state),
			willBePaused: willEnrolmentBePaused(state),
			willBePausedDates: getEnrolmentWillBePausedResumeSession(state, true),
			showHolidayLearningBanner: shouldShowHolidayLearningBanner(state, enrolmentId),
		};
	},
	(dispatch) => {
		return {
			redirectToForm: bindActionCreators(redirectToFormWithEnrolmentId, dispatch),
			redirectToCSP: bindActionCreators(redirectToChangeSchedule, dispatch),
		};
	}
)(EnrolmentStatusReboardingView);

export { EnrolmentStatusReboardingView as StatelessEnrolmentStatusReboardingView };
