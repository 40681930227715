import moment from 'moment';

import { AccountDetails } from '../../api/types/account';
import { NAPLAN_LITERACY, NAPLAN_NUMERACY, SUBJECT_ENGLISH, SUBJECT_MATHS } from '../constants';
import { NAPLAN_PACKAGE_MASTER_CODES, OPTED_NAPLAN_PACKAGE_MASTER_CODES } from '../constants/NAPLAN';
import { isGroupSessionCheck } from '../helpers';

const NAPLAN_START_DATE = '2025-01-13';
const NAPLAN_END_DATE = '2025-03-12';

export const hasNAPLANCheck = ({ packageMasterCode, courseType, checkDate = true }) => {
  if (
    isGroupSessionCheck(courseType) ||
    (checkDate &&
      (moment().isSameOrBefore(moment(NAPLAN_START_DATE), 'day') ||
        moment().isSameOrAfter(moment(NAPLAN_END_DATE), 'day')))
  ) {
    return {
      showNAPLAN: false,
      optNAPLAN: false,
    };
  }

  const hasValidCourse = [...NAPLAN_PACKAGE_MASTER_CODES, ...OPTED_NAPLAN_PACKAGE_MASTER_CODES].includes(
    packageMasterCode
  );

  return {
    showNAPLAN: hasValidCourse,
    optNAPLAN: false,
  };
};

const isNaplanYear = (yearLevel) => {
  return yearLevel.includes('3') || yearLevel.includes('5') || yearLevel.includes('7') || yearLevel.includes('9');
};

export const naplanInfo = ({ enrolmentSubject, yearLevel, country }) => {
  const subject = (enrolmentSubject || '').toLowerCase();
  const isNAPLAN = country === AccountDetails.shape.country.Values.AU && isNaplanYear(yearLevel);
  const showNAPLAN =
    moment().isSameOrAfter(moment(NAPLAN_START_DATE), 'day') && moment().isSameOrBefore(moment(NAPLAN_END_DATE), 'day');

  let typeNAPLAN = '';
  if (subject === SUBJECT_MATHS) {
    typeNAPLAN = NAPLAN_NUMERACY;
  } else if (subject === SUBJECT_ENGLISH) {
    typeNAPLAN = NAPLAN_LITERACY;
  }

  return { isNAPLAN, typeNAPLAN, showNAPLAN };
};

export const getNAPLANInfo = ({ enrolmentSubject, packageMasterCode, courseType, checkDate = true }) => {
  const subject = (enrolmentSubject || '').toLowerCase();

  const { showNAPLAN, optNAPLAN } = hasNAPLANCheck({ packageMasterCode, courseType, checkDate });
  let typeNAPLAN = '';
  if (subject === SUBJECT_MATHS) {
    typeNAPLAN = NAPLAN_NUMERACY;
  } else if (subject === SUBJECT_ENGLISH) {
    typeNAPLAN = NAPLAN_LITERACY;
  }

  return { showNAPLAN, typeNAPLAN, optNAPLAN };
};

export const isNAPLANLegible = ({ subject, yearLevel }) => {
  return [SUBJECT_MATHS, SUBJECT_ENGLISH].includes((subject || '').toLowerCase()) && [3, 5, 7, 9].includes(yearLevel);
};
