import { ELEMENT_TYPES } from "../../../../../types/hubEnums";
import {
    PATH_HUB_CR_TECHNICAL_ISSUE,
  PATH_HUB_CR_PAYMENT_QUERY,
  PATH_HUB_CR_OTHER_QUERY,
  PATH_HUB_CR_FEEDBACK
} from "../../../../../util/pagePath";
import { REPORT_ISSUE_MENU_OPTIONS } from "./enums";

interface Choice {
  text: string;
  to: string;
  element?: ELEMENT_TYPES;
}


export function getReportIssueMenuOptions(): Array<Choice> {
  const {
    REQUEST_CREDIT,
FOLLOW_UP_CREDIT,
REPORT_TECHNICAL_ISSUE,
FORMAL_TUTOR_COMPLAINT,
OTHER,
  } = REPORT_ISSUE_MENU_OPTIONS;
  return [
    {text: REQUEST_CREDIT, to: PATH_HUB_CR_PAYMENT_QUERY},
    {text: FOLLOW_UP_CREDIT, to: PATH_HUB_CR_PAYMENT_QUERY},
    {text: REPORT_TECHNICAL_ISSUE, to: PATH_HUB_CR_TECHNICAL_ISSUE},
    {text: FORMAL_TUTOR_COMPLAINT, to: PATH_HUB_CR_FEEDBACK},
    {text: OTHER, to: PATH_HUB_CR_OTHER_QUERY},
  ]
}