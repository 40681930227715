import { Fragment } from 'react';
import withHeadTag from '../../common/HeadComponent';
import HubPageLayout from '../../common/hub/HubPageLayout';
import PageError from '../../common/PageError';
import EnrolmentsPageContentHeadNav from '../../components/hub/enrolments/EnrolmentsPageContentHeadNav';
import EnrolmentList, { EmptyActiveEnrolments } from '../../components/hub/enrolments/EnrolmentList';
import { api } from '../../api';

const HubEnrolmentsPage = () => {
  const { error: activeEnrolmentsQueryError } = api.enrolment.all.useQuery('active');
  const { 
    isLoading: isLoadingPastEnrolments,
    data: pastEnrolments,
    error: pastEnrolmentsQueryError
  } = api.enrolment.all.useQuery('past');

  return (
    <HubPageLayout>
      {(activeEnrolmentsQueryError || pastEnrolmentsQueryError) && (
        <PageError activePage="hub-page" retryEnabled={false} />
      )}
      <Fragment>
        <EnrolmentsPageContentHeadNav />
        <p className="mb-10">
          Manage learning programs for your household enrolments, including content needs, schedules and tutor requirements.
        </p>
        <EnrolmentList filter="active" title="Current" renderWhenEmpty={<EmptyActiveEnrolments />} />
        {(!isLoadingPastEnrolments && pastEnrolments?.length > 0) && <EnrolmentList filter="past" title="Finished" />}
      </Fragment>
    </HubPageLayout>
  );
}

export default withHeadTag(HubEnrolmentsPage);
