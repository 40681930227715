import { Link } from '@cluey/cluey-components';
import { generatePath } from 'react-router-dom';
import { VirtualCoachIcon } from '../../icons';
import { PATH_HUB_AI_TUTOR_FAQ } from '../../util/pagePath';
import useGetRemainingRequests from './useGetRemainingRequests';
import { SkeletonLoader } from '../../components/SkeletonLoader';
import posthog from 'posthog-js';

export interface BetaVersionBannerProp {
  enrolmentId: string;
}

export const BetaVersionBanner = ({ enrolmentId }: BetaVersionBannerProp) => {
  const { status, data: remainingRequests } = useGetRemainingRequests(enrolmentId);
  const loading = status === 'loading';

  return (
    <div className="container rounded-lg bg-blood-orange-1 p-8 shadow-md">
      <div>
        <p className="mb-2 flex items-center font-display font-bold">
          <VirtualCoachIcon width={40} height={40} />
          <span className="ml-3 font-display text-xl font-bold">Free beta version</span>
        </p>
        <div className="px-0 md:px-12">
          <p className="mb-2">
            You are on a free beta use of Cluey Coach which gives you 30 requests for this enrolment each day.
          </p>
          <p>
            <SkeletonLoader className="mt-3 h-6" loading={loading} data-testid="loading-ai-tutor-beta-banner">
              <b>You have {remainingRequests} requests left for today.</b>{' '}
              <Link
                className="block !text-base text-blue-5 underline md:inline"
                to={generatePath(PATH_HUB_AI_TUTOR_FAQ, { enrolmentId })}
                onClick={() => posthog.capture('click_cluey-coach_beta-banner-learn-more')}
              >
                Learn how to get the most out of your requests.
              </Link>
            </SkeletonLoader>
          </p>
        </div>
      </div>
    </div>
  );
};
