import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useLocation } from 'react-router-dom';
import { Button, Form } from '@cluey/cluey-components';
import { get } from 'lodash';

import withHeadTag from '../../../common/HeadComponent';
import { validatePassword } from '../../../util/validation/password';
import AuthPageLayout from '../../../common/hub/AuthPageLayout';
import InPageLoader from '../../../common/InPageLoader';
import ErrorBlock from '../../../common/ErrorBlock';
import PasswordField from '../../../components/hub/auth/PasswordField';
import RetypedPasswordField
  from '../../../components/hub/auth/RetypedPasswordField';
import KeepMeLoggedIn from '../../../components/hub/auth/KeepMeLoggedIn';

import {
  PATH_HUB_LOG_IN,
} from '../../../util/pagePath';
import { PASSWORD_VALIDATION_RESULT, CUSTOMER_CARE_CONTACT_PHONE_2 } from '../../../util/constants';
import { passwordValidatorHelper } from '../../../util/helpers';

import { resetPassword } from '../../../actions/hub/hubAuthPagesActions';
import TextBlock from '../../../components/hub/auth/TextBlock';

export const BackToLogin = () => (
  <Link
    to={PATH_HUB_LOG_IN}
    className="block mt-10 text-primary text-sm font-body font-medium"
  >
    Wait, I remember my password
  </Link>
);

const HubResetPassword = ({
  inPageSpinner,
  pageError,
  errorMsg,
  setNewPassword,
}) => {
  const [inPageError, setInPageError] = useState('');

  const [email, setEmail] = useState('');
  const [tempPwd, setTempPwd] = useState('');
  const [passwordOrg, setPasswordOrg] = useState('');
  const [passwordRetyped, setPasswordRetyped] = useState('');
  const [retypedPWDValidation, setRetypedPWDValidation] = useState(PASSWORD_VALIDATION_RESULT.INIT);

  const query = new URLSearchParams(useLocation().search);
  const invalidLinkError = `Invalid reset password link - please use the link sent to you or contact Customer Care on ${CUSTOMER_CARE_CONTACT_PHONE_2}.`;

  useEffect(() => {
    const token = query.get('token');
    if (!token) {
      setInPageError(invalidLinkError);
      return () => {};
    }
    const tokenDecoded = JSON.parse(atob(token));
    const emailFromToken = get(tokenDecoded, 'email', '');
    const passwordFromToken = get(tokenDecoded, 'temporaryPassword', '');
    if (emailFromToken !== '' && passwordFromToken !== '') {
      setEmail(emailFromToken);
      setTempPwd(passwordFromToken);
    } else {
      setInPageError(invalidLinkError);
    }
    return null;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    passwordValidatorHelper({
      passwordOrg,
      passwordRetyped,
      setRetypedPWDValidation,
    });
  }, [passwordOrg, passwordRetyped]);

  const onConfirm = (e) => {
    e.target.blur();
    if (passwordOrg === '' || passwordRetyped === '') {
      setInPageError('Please enter and confirm your password!');
      return;
    }

    if (!validatePassword(passwordRetyped)) {
      setRetypedPWDValidation(PASSWORD_VALIDATION_RESULT.INVALID);
      return;
    }

    if (validatePassword(passwordOrg) && validatePassword(passwordRetyped)) {
      if (passwordOrg === passwordRetyped) {
        setRetypedPWDValidation(PASSWORD_VALIDATION_RESULT.MATCH);
      } else {
        setRetypedPWDValidation(PASSWORD_VALIDATION_RESULT.NOT_MATCH);
        return;
      }
    } else {
      setInPageError('Please make sure your passwords are valid!');
      return;
    }

    setInPageError('');
    setRetypedPWDValidation(PASSWORD_VALIDATION_RESULT.INIT);
    setNewPassword({
      email,
      tempPassword: tempPwd,
      newPassword: passwordOrg,
    });
  };

  return (
    <AuthPageLayout
      title="Set your new password"
      subtitle="Please enter your new password below."
    >
      { inPageSpinner && <InPageLoader position="relative" /> }
      { pageError && errorMsg && <ErrorBlock errorMsg={errorMsg} /> }
      { inPageError && <ErrorBlock errorMsg={inPageError} /> }
      { !inPageSpinner && (
        <Fragment>
          <TextBlock
            upper="Your email address"
            lower={email}
          />
          <Form>
            <PasswordField
              fieldLabel="New Password"
              fieldId="passwordOrg"
              savePassword={setPasswordOrg}
            />
            <RetypedPasswordField
              fieldLabel="Confirm new password"
              fieldId="passwordRetyped"
              placeholderText="Please re-enter your password"
              savePassword={setPasswordRetyped}
              retypedPWDValidation={retypedPWDValidation}
            />
            <KeepMeLoggedIn />
          </Form>
          <Button size="xs" className="w-full uppercase" onClick={onConfirm}>Reset Password</Button>
          {BackToLogin}
        </Fragment>
      ) }
    </AuthPageLayout>
  );
};

HubResetPassword.propTypes = {
  inPageSpinner: PropTypes.bool.isRequired,
  pageError: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string,
  setNewPassword: PropTypes.func.isRequired,
};

HubResetPassword.defaultProps = {
  errorMsg: '',
};

export default connect(
  (state) => {
    const { ui } = state;
    return {
      inPageSpinner: ui.apiState.hubAuthPage.isLoading,
      pageError: ui.apiState.hubAuthPage.fetchError,
      errorMsg: ui.apiState.hubAuthPage.errorMsg,
    };
  },
  (dispatch) => {
    return {
      setNewPassword: bindActionCreators(resetPassword, dispatch),
    };
  },
)(withHeadTag(HubResetPassword));

export { HubResetPassword as StatelessHubResetPassword };
