import classnames from 'classnames';
import { Link, LinkButton } from '@cluey/cluey-components';
import infoIcon from '../../assets/images/icon-filled-alert-info.svg';
import warningIcon from '../../assets/images/icon-filled-alert-exclamation-red.svg';
import { PATH_HUB_ADD_ONE_OFF_SESSION, PATH_HUB_AI_TUTOR_FAQ } from '../../util/pagePath';
import { SkeletonLoader } from '../../components/SkeletonLoader';
import { generatePath } from 'react-router-dom';
import useGetRemainingRequests from './useGetRemainingRequests';
import posthog from 'posthog-js';
import { useEffect } from 'react';

export type TRequestLimitAlertProps = {
  enrolmentId: string;
};

const RequestLimitAlert = ({ enrolmentId }: TRequestLimitAlertProps) => {
  const { status, data: remainingRequests } = useGetRemainingRequests(enrolmentId);
  const requestsUsedUp = remainingRequests <= 0;

  useEffect(() => {
    if (requestsUsedUp) {
      posthog.capture('view_cluey-coach_requests-used-up', { $enrolmentId: enrolmentId });
    }
  }, [requestsUsedUp, enrolmentId]);

  return (
    <div
      className={classnames(
        'flex flex-col space-x-0 space-y-4 rounded-lg bg-blue-1 px-6 py-5 md:flex-row md:space-x-4 md:space-y-0',
        requestsUsedUp && 'bg-red-1'
      )}
    >
      <SkeletonLoader className="mt-3 h-4" loading={status === 'loading'}>
        <img alt="info icon" src={requestsUsedUp ? warningIcon : infoIcon} width={25} height={25} />
        <div className="flex w-full flex-col items-center space-x-0 space-y-4 md:flex-row md:space-x-2 md:space-y-0">
          <span className="flex-grow">
            <b>{`You have ${remainingRequests} requests left for today. `}</b>
            <Link
              className={classnames('!text-base text-blue-5 underline', requestsUsedUp ? 'hidden' : 'visible')}
              to={generatePath(PATH_HUB_AI_TUTOR_FAQ, { enrolmentId })}
              onClick={() => posthog.capture('click_cluey-coach_learn-more')}
            >
              Learn how to get the most out of your requests.
            </Link>
          </span>
          {requestsUsedUp && (
            <LinkButton
              to={generatePath(PATH_HUB_ADD_ONE_OFF_SESSION, { enrolmentId })}
              onClick={() => posthog.capture('click_cluey-coach_book-a-session', { $enrolmentId: enrolmentId })}
              size="xs"
              appearance="reverse"
              className="w-full flex-none self-end md:w-auto"
            >
              <p className="font-bold">Book one-off session</p>
            </LinkButton>
          )}
        </div>
      </SkeletonLoader>
    </div>
  );
};

export default RequestLimitAlert;
