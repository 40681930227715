import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Button } from '@cluey/cluey-components';
import { useHistory } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';

import withHeadTag from '../../../common/HeadComponent';
import AuthPageLayout from '../../../common/hub/AuthPageLayout';
import InPageLoader from '../../../common/InPageLoader';
import EmailField from '../../../components/hub/auth/EmailField';
import { resendSignupLink } from '../../../actions/hub/hubAuthPagesActions';
import { EMAIL_VALIDATION_RESULT } from '../../../util/constants';
import { PATH_HUB_RESEND_SIGNUP_LINK_REQ_SENT } from '../../../util/pagePath';
import paperplane from '../../../assets/images/hubAuth/paperplane.svg';

const HubResendSignupLinkRequestPage = ({
  inPageSpinner,
  pageError,
  errorMsg,
  resendSignupLinkRequest,
}) => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [inPageError, setInPageError] = useState('');
  const [emailValidation, setEmailValidation] = useState(EMAIL_VALIDATION_RESULT.INIT);

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (isEmail(email)) {
      setInPageError('');
      setEmailValidation(EMAIL_VALIDATION_RESULT.INIT);
      resendSignupLinkRequest({
        email,
        onSuccess: () => {
          history.push(PATH_HUB_RESEND_SIGNUP_LINK_REQ_SENT);
        },
      });
    } else {
      setInPageError('Please enter a valid email!');
      setEmailValidation(EMAIL_VALIDATION_RESULT.INVALID);
    }
  };

  return (
    <AuthPageLayout
      title="Re-send your sign up link"
      subtitle="Enter the email address associated with your Cluey account and we’ll send your sign up link."
      imgIcon={paperplane}
      imgIconAlt="paper plane"
      imgIconWrapClass="icon-wrap--sail"
    >
      { inPageSpinner && <InPageLoader position="relative" /> }
      { pageError && errorMsg && !inPageError && (
        <p className="px-4 py-3 rounded bg-forget-me-not text-punch">{ errorMsg }</p>
      )}
      { inPageError && <p className="px-4 py-3 rounded bg-forget-me-not text-punch">{ inPageError }</p> }
      { !inPageSpinner && (
        <Form onSubmit={onFormSubmit}>
          <EmailField
            emailValidation={emailValidation}
            saveEmail={setEmail}
          />
          <Button
            type="submit"
            size="xs"
            className="w-full uppercase"
          >
            Re-send sign up link
          </Button>
        </Form>
      )}
    </AuthPageLayout>
  );
};

HubResendSignupLinkRequestPage.propTypes = {
  inPageSpinner: PropTypes.bool.isRequired,
  pageError: PropTypes.bool.isRequired,
  errorMsg: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
      ]),
    ),
  ]),
  resendSignupLinkRequest: PropTypes.func.isRequired,
};

HubResendSignupLinkRequestPage.defaultProps = {
  errorMsg: '',
};

export default connect(
  (state) => {
    const { ui } = state;
    return {
      inPageSpinner: ui.apiState.hubAuthPage.isLoading,
      pageError: ui.apiState.hubAuthPage.fetchError,
      errorMsg: ui.apiState.hubAuthPage.errorMsg,
    };
  },
  (dispatch) => {
    return {
      resendSignupLinkRequest: bindActionCreators(resendSignupLink, dispatch),
    };
  },
)(withHeadTag(HubResendSignupLinkRequestPage));

export { HubResendSignupLinkRequestPage as StatelessHubResendSignupLinkRequestPage };
