import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

import GraphContainer from './GraphContainer';
import IconBox from './IconBox';
import timerIcon from '../../assets/images/icon-outline-timer.svg';
import { getModuleModalGraphOptions } from './ChartsOptionConfiguration';

const options = getModuleModalGraphOptions();
const ModalOverview = ({
  title,
  topicsCommencedCoverage,
  topicsCoveredCount,
  moduleCoverage,
  leftIcon,
  rightIcon,
  graphData,
  totalTimeSpentOnModule,
  totalTimeSpentOnModuleBreakdown,
}) => {
  return (
    <div className="mt-2 mb-8 px-4 lg:px-16">
      {title && (
        <h1 className="font-display font-bold text-lg md:text-2xl lg:text-4xl leading-tight mb-3 md:mb-5 lg:mb-7">
          {title}
        </h1>
      )}
      <div className="flex flex-col lg:flex-row lg:gap-4 items-stretch mb-4">
        <div className="w-full lg:w-1/2 mb-2 lg:mb-0">
          <IconBox icon={leftIcon}>
            <div className="text-lg leading-tight">
              <span className="font-bold">{moduleCoverage}</span>{' '}
              complete
            </div>
          </IconBox>
        </div>
        <div className="w-full lg:w-1/2">
          <IconBox icon={rightIcon}>
            <div className="text-lg leading-tight">
              <span className="font-bold">{totalTimeSpentOnModule}</span>
              &nbsp;{totalTimeSpentOnModuleBreakdown}
            </div>
          </IconBox>
        </div>
      </div>
      <GraphContainer
        leftTextTop="Topics covered"
        leftTextBottom={topicsCoveredCount}
        rightTextTop="Completion of commenced topics"
        rightTextBottom={`${topicsCommencedCoverage}%`}
        showHR
        showChidlrenWrapperBg
      >
        <Bar data={graphData} options={options} />
      </GraphContainer>
    </div>
  );
};

ModalOverview.propTypes = {
  title: PropTypes.string,
  topicsCommencedCoverage: PropTypes.number,
  topicsCoveredCount: PropTypes.number,
  moduleCoverage: PropTypes.number,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  graphData: PropTypes.any,
  totalTimeSpentOnModule: PropTypes.string,
  totalTimeSpentOnModuleBreakdown: PropTypes.string,
};

ModalOverview.defaultProps = {
  title: '',
  topicsCommencedCoverage: 0,
  topicsCoveredCount: 0,
  moduleCoverage: 0,
  leftIcon: '',
  rightIcon: timerIcon,
  graphData: {},
  totalTimeSpentOnModule: '',
  totalTimeSpentOnModuleBreakdown: '',
};

export default ModalOverview;
