import { Container } from '@cluey/cluey-components';
import { useSelector } from 'react-redux';
import { ELEMENT_TYPES } from '../../../../../types/hubEnums';
import EnrolmentLayout from '../../../../../common/hub/EnrolmentLayout';
import { PATH_HUB_CR_PAYMENT_QUERY } from '../../../../../util/pagePath';
import { useSelectEnrolmentModal } from '../../../../../components/Modals/SelectEnrolmentModal/SelectEnrolmentModal';
import { getActiveEnrolments } from '../../../../../selectors/hubSelector';
import { useGetChoices } from './hooks';
import { useHistory } from 'react-router';
import type { Enrolment } from '../../../../../types/hubInterfaces';
import { IntentIntro } from '../../../../../components/IntentIntro';
import { OptionCard, OptionCardContent } from '../../../../../components/EnrolmentManagement/OptionCard';

function ManageServiceBillingAndPayments() {
  const activeEnrolments: Array<Enrolment> = useSelector(getActiveEnrolments);
  const accountDetails = useSelector(
    (state: { hubAccountPage: { accountDetails: { country: string } } }) => state.hubAccountPage.accountDetails
  );
  const history = useHistory();
  const { open, openModal, closeModal, SelectEnrolmentModal } = useSelectEnrolmentModal();
  const choices = useGetChoices(accountDetails?.country);

  return (
    <EnrolmentLayout
      sectionHeading="Home"
      mainHeading="Payments and billing"
      showEnrolmentStatus={false}
      showSummerModeEnrolmentStatus={false}
    >
      <SelectEnrolmentModal
        closeModal={closeModal}
        enrolmentLinkTo={(enrolment) => `${PATH_HUB_CR_PAYMENT_QUERY}?token=${enrolment.enrolmentId}`}
        open={open}
      />
      <Container>
        <IntentIntro>How can we help you with payments and billing?</IntentIntro>
        <ul className="list-none">
          {choices.map(({ text, to, element }) => {
            if (element === ELEMENT_TYPES.BUTTON) {
              return (
                <li key={text}>
                  <OptionCard
                    type={ELEMENT_TYPES.BUTTON}
                    onClickHandler={() => {
                      if (activeEnrolments.length === 1) {
                        history.push(`${PATH_HUB_CR_PAYMENT_QUERY}?token=${activeEnrolments[0].enrolmentId}`);
                      } else {
                        openModal();
                      }
                    }}
                  >
                    <OptionCardContent>{text}</OptionCardContent>
                  </OptionCard>
                </li>
              );
            }

            return (
              <li key={text}>
                <OptionCard linkTo={to} type={element}>
                  <OptionCardContent>{text}</OptionCardContent>
                </OptionCard>
              </li>
            );
          })}
        </ul>
      </Container>
    </EnrolmentLayout>
  );
}

export default ManageServiceBillingAndPayments;
