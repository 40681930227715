import { FormFlow } from '../../../components/FormFlow/FormFlow';
import { GenerateAssessmentForm } from './GenerateAssessmentForm';
import { GenerateAssessmentOngoing } from './GenerateAssessmentOngoing';
import { GenerateAssessmentSuccessful } from './GenerateAssessmentSuccessful';
import { GenerateAssessmentFailed } from './GenerateAssessmentFailed';
import { GenerateAssessmentTimedOut } from './GenerateAssessmentTimedOut';
import { useSearchParams } from '../../../util/hooks/useSearchParams';
import { api } from '../../../api';
import { FullPageLoader } from '../../../components/Loader/Loader';
import { FormFlowProps } from '../../../types/hubInterfaces';
import { Student } from '../../../api/types/student';
import { useRef } from 'react';
import { Redirect, useLocation } from 'react-router';
import { PATH_HUB_HOME } from '../../../util/pagePath';
import withHeadTag from '../../../common/HeadComponent';

type FormFlowState = { enrolments: number; student?: Student };
export type GenerateAssessmentFormProps = FormFlowProps<FormFlowState>;

export type LocationState = {
	data: {
		student: Student;
		subject: string;
	};
	yearLevel?: string;
};

const GenerateAssessment = () => {
	const { data, isLoading: isLoadingAccountDetails } = api.account.details.useQuery();

	const isLead = data && 'userType' in data && data.userType === 'Lead';
	const location = useLocation<LocationState>();
	const searchParams = useSearchParams();
	const state = useRef<FormFlowState>({ enrolments: 0 });

	const hasLocationData = !!location?.state?.data?.student;
	const hasSearchParamsData = !!searchParams.get('studentId');

	const { data: students, isLoading: isLoadingStudents } = api.student.all.useQuery(undefined, {
		enabled: hasSearchParamsData || isLead,
	});
	const { isLoading: isLoadingEnrolments } = api.enrolment.all.useQuery('active', {
		enabled: hasSearchParamsData || hasLocationData || isLead,
	});

	const studentId = searchParams.get('studentId');

	const student = location.state?.data?.student || students?.find((student) => student.studentId === studentId);

	const formElements = {
		'': GenerateAssessmentForm,
		ongoing: GenerateAssessmentOngoing,
		successful: GenerateAssessmentSuccessful,
		failed: GenerateAssessmentFailed,
		'timed-out': GenerateAssessmentTimedOut,
	};

	if (!hasLocationData && !student && !hasSearchParamsData && !isLead) {
		return <Redirect to={PATH_HUB_HOME} />;
	}

	if (isLoadingStudents || isLoadingEnrolments || isLoadingAccountDetails) return <FullPageLoader />;

	return <FormFlow elements={formElements} state={state.current} />;
};

const GenerateAssessmentWithHeadTag = withHeadTag(GenerateAssessment);

export { GenerateAssessmentWithHeadTag as GenerateAssessment };
