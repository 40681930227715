import classNames from 'classnames';
import { sortBy } from 'lodash';
import { generatePath, useHistory } from 'react-router';
import { api } from '../../../../../api';
import { VirtualCoachIcon } from '../../../../../icons/VirtualCoachIcon';
import { Loader } from '../../../../../components/Loader/Loader';
import { formatError } from '../../../../../util/errors';
import { AITutorCard } from './AITutorCard';
import { PATH_HUB_AI_TUTOR } from '../../../../../util/pagePath';
import { useMapStudentsEnrolments } from './hooks';
import { StudentEnrolments } from './types';
import { VirtualCoachBotIcon } from '../../../../../icons';
import { useFeatureFlagEnabled } from 'posthog-js/react';

export interface AITutorWidgetProp {
  className?: string;
  studentEnrolments: StudentEnrolments;
}

export const AITutorWidgetBase = ({ studentEnrolments, className }: AITutorWidgetProp) => {
  const history = useHistory();

  return (
    <div className={classNames('-mt-16 flex flex-col gap-4 text-xl text-grey-6', className)}>
      <div className="container mx-auto px-4 py-14 md:px-0">
        <div className="mb-0 flex flex-col md:mb-9 md:flex-row">
          <div className="my-auto">
            <p className="mb-3 flex items-center font-display font-bold">
              <VirtualCoachIcon width={40} height={40} />
              <span className="ml-3 text-2xl">Cluey Coach</span>
            </p>
            <p className="text-lg">
              Get help any time with personalised support, study questions, explanations, and more.
            </p>
          </div>
          <div className="mx-auto my-9 md:my-0 md:ml-auto md:mr-0">
            <VirtualCoachBotIcon width={100} height={120} />
          </div>
        </div>

        {Object.entries(studentEnrolments).map(([student, enrolments]) => (
          <AITutorCard
            key={`${student}_ai_tutor`}
            student={student}
            enrolments={enrolments.sort((a, b) => {
              return a.subject.localeCompare(b.subject);
            })}
            handleRedirect={(id: string) => {
              const path = generatePath(PATH_HUB_AI_TUTOR, { enrolmentId: id });
              history.push(path);
            }}
          />
        ))}
      </div>
    </div>
  );
};

const AITutorWidget = ({ className, mockFeatureFlag }: { className?: string, mockFeatureFlag?: boolean }) => {
  const {
    data: activeEnrolments,
    isLoading: isLoadingEnrolments,
    error: enrolmentsError,
  } = api.enrolment.all.useQuery('active', {
    select: (data) => {
      return sortBy(data, 'student').filter((enrolment) => enrolment.aiTutor.allowed === true);
    },
  });

  const studentEnrolments: StudentEnrolments = useMapStudentsEnrolments(activeEnrolments);
  
  const clueyCoachEnabled = useFeatureFlagEnabled('cluey-coach-enabled') || mockFeatureFlag;


  if (isLoadingEnrolments) {
    return (
      <div className="-pt-16 container mx-auto flex flex-grow flex-col items-center justify-center pb-20">
        <Loader />
      </div>
    );
  }

  if (enrolmentsError) {
    return (
      <div className="-pt-16 col-span-3 flex w-full flex-col gap-2 py-2">
        <div className="flex items-center justify-center">Something went wrong</div>
        <pre className="text-center text-xs">{formatError(enrolmentsError)}</pre>
      </div>
    );
  }

  return Object.keys(studentEnrolments).length > 0 && clueyCoachEnabled ? (
    <AITutorWidgetBase studentEnrolments={studentEnrolments} className={className} />
  ) : null;
};

export default AITutorWidget;
