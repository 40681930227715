import moment from 'moment';
import { BillingQuery, BillingQuerySessionInfo } from '../../../../../types/hubTypes';
import { AccountDetails } from '../../../../../api/types/account';
import uniqBy from 'lodash/uniqBy';

export const getFormattedWeekRange = (nextPayment: BillingQuery) => {
	// Ensure week starts on a monday
	moment.updateLocale('en', {
		week: {
			dow: 1,
		},
	});
	const sessions = nextPayment?.sessionInfo.sort((a: BillingQuerySessionInfo, b: BillingQuerySessionInfo) =>
		moment(a.sessionStartDateTime).diff(moment(b.sessionStartDateTime))
	);
	const firstDate = moment(sessions[0].sessionStartDateTime).weekday(0);
	const endDate = moment(sessions[sessions.length - 1].sessionStartDateTime).weekday(6);
	if (firstDate.format('MMM') !== endDate.format('MMM')) {
		return `period of ${firstDate.format('MMM D')}-${endDate.format('MMM D')}`;
	}
	return `period of ${firstDate.format('MMM D')}-${endDate.format('D')}`;
};

export const getDaysAway = (
	paymentDate: string,
	options?: {
		today?: string;
		tomorrow?: string;
		wrapperFunction?: (content: string | number, daysAway: number) => string | number;
	}
) => {
	if (!paymentDate) return '';
	const nextPaymentDate = moment(paymentDate);
	const daysAway = nextPaymentDate.diff(moment().startOf('day'), 'days');
	const result =
		daysAway === 0 ? options?.today || 'Today' : daysAway === 1 ? options?.tomorrow || 'Tomorrow' : daysAway;
	return options?.wrapperFunction?.(result, daysAway) || result;
};

export const getForStudentString = ({
	nextPayment,
	accountDetails,
}: {
	nextPayment: BillingQuery;
	accountDetails: AccountDetails;
}) => {
	const multipleEnrolments =
		nextPayment?.sessionInfo?.length > 1 &&
		uniqBy(nextPayment.sessionInfo, (session: BillingQuerySessionInfo) => session.enrolmentId).length > 1;
	if (accountDetails?.invoicePer === 'Enrolment' && !multipleEnrolments) {
		return `for ${nextPayment?.sessionInfo?.[0]?.studentName.split(' ')[0]} (${
			nextPayment?.sessionInfo?.[0]?.subject
		})`;
	}
	return '';
};
