import { sortBy, minBy } from 'lodash';
import moment from 'moment';
import initialState from './initialState';
import {
  HUB_AP_FETCH_ACCOUNT_DETAILS_SUCCESS,
  HUB_AP_FETCH_ACCOUNT_DETAILS_FAILED,
  HUB_AP_UPDATE_ACCOUNT_DETAILS_SUCCESS,
  HUB_AP_UPDATE_ACCOUNT_DETAILS_FAILED,
  HUB_AP_CLEAR_ACCOUNT_DETAILS_STATUS_MESSAGE,
  HUB_AP_FETCH_BILLING_INFORMATION_SUCCESS,
  HUB_AP_FETCH_BILLING_INFORMATION_FAILED,
  HUB_AP_CREATE_CHARGENT_ORDER_SUCCESS,
  HUB_AP_CREATE_CHARGENT_ORDER_FAILED,
  HUB_AP_PROCESS_PAYMENT_SUCCESS,
  HUB_AP_PROCESS_PAYMENT_FAILED,
  HUB_AP_FETCH_INVOICE_HISTORY_SUCCESS,
  HUB_AP_FETCH_INVOICE_HISTORY_FAILED,
  HUB_AP_CLEAR_BILLING_INFORMATION_STATUS_MESSAGE,
  HUB_AP_BILLING_INFO_SUCCESS,
} from '../actions/actionTypes';

export default function hubAccountPage(state = initialState.hubAccountPage, action) {
  switch (action.type) {
    case HUB_AP_FETCH_ACCOUNT_DETAILS_SUCCESS:
    case HUB_AP_UPDATE_ACCOUNT_DETAILS_SUCCESS:
      if (action.payload && typeof action.payload === 'object') {
        return {
          ...state,
          accountDetails: action.payload,
          accountDetailsErrorMsg: null,
        };
      }
      return state;

    case HUB_AP_FETCH_ACCOUNT_DETAILS_FAILED:
    case HUB_AP_UPDATE_ACCOUNT_DETAILS_FAILED:
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          accountDetailsSucceedMsg: null,
        },
        accountDetailsErrorMsg: action.payload,
      };
    case HUB_AP_CLEAR_ACCOUNT_DETAILS_STATUS_MESSAGE:
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          accountDetailsSucceedMsg: null,
        },
        accountDetailsErrorMsg: null,
      };

    case HUB_AP_FETCH_BILLING_INFORMATION_SUCCESS:
      return {
        ...state,
        billingInformation: action.payload,
        billingInformationErrorMsg: null,
      };

    case HUB_AP_FETCH_BILLING_INFORMATION_FAILED:
      return {
        ...state,
        billingInformationErrorMsg: action.payload,
      };
    case HUB_AP_CLEAR_BILLING_INFORMATION_STATUS_MESSAGE:
      return {
        ...state,
        billingInformation: {
          ...state.billingInformation,
          billingInformationSucceedMsg: null,
        },
        billingInformationErrorMsg: null,
      };
    case HUB_AP_CREATE_CHARGENT_ORDER_SUCCESS:
      return {
        ...state,
        billingInformationErrorMsg: null,
        billingInformationChargent: action.payload,
      };

    case HUB_AP_CREATE_CHARGENT_ORDER_FAILED:
    case HUB_AP_PROCESS_PAYMENT_FAILED:
      return {
        ...state,
        billingInformation: {
          ...state.billingInformation,
          billingInformationSucceedMsg: null,
        },
        billingInformationErrorMsg: action.payload,
      };

    case HUB_AP_PROCESS_PAYMENT_SUCCESS:
      return {
        ...state,
        billingInformation: {
          cardType: action.payload.CardType,
          endingIn: action.payload.CardNumber.slice(-4),
          billingInformationSucceedMsg: action.payload.billingInformationSucceedMsg,
        },
        billingInformationErrorMsg: null,
      };

    case HUB_AP_BILLING_INFO_SUCCESS:
      return {
        ...state,
        billingInformation: {
          ...state.billingInformation,
          billingInformationSucceedMsg: action.payload,
        },
        billingInformationErrorMsg: null,
      };

    case HUB_AP_FETCH_INVOICE_HISTORY_SUCCESS:
      return {
        ...state,
        // invoiceHistory: sortBy(action.payload || [{}], 'invoiceDate').reverse(),
        // invoiceHistory: {
        //   legacy: sortBy(action.payload.c2gInvoice, 'invoiceDate').reverse(),
        //   zuora: sortBy(action.payload.zuoraInvoice, 'invoiceDate').reverse(),
        // },
        invoiceHistory: sortBy(
          [
            ...action.payload.c2gInvoice.map(invoice => ({ ...invoice, type: 'legacy' })),
            ...action.payload.zuoraInvoice.map(invoice => ({ ...invoice, type: 'zuora' })),
          ],
          'invoiceDate',
        ).reverse(),
        nextChargeDate: minBy(
          action.payload.nextChargeDates.map(item => item.nextChargeDate),
          item => moment(item).unix(),
        ),
        invoiceHistoryErrorMsg: null,
      };

    case HUB_AP_FETCH_INVOICE_HISTORY_FAILED:
      return {
        ...state,
        invoiceHistoryErrorMsg: action.payload,
      };

    default:
      return state;
  }
}
