import moment from 'moment';

import initialState from './initialState';
import {
  SR_LOGIN_RECEIVE_INFO,
  SR_REQUEST_SUMMARY_SUCCESS,
  STPG_FETCH_DISCIPLINE_LIST_SUCCESS,
  STPG_UPDATE_SELECTED_DISCIPLINE,
  STPG_UPDATE_TABBED_VIEW_ACTIVE_TAB,
} from '../actions/actionTypes';

export default function reportReducer(state = initialState.report, action) {
  switch (action.type) {
    case STPG_FETCH_DISCIPLINE_LIST_SUCCESS: {
      if (action.payload.disciplineList.length === 0) {
        return state;
      }
      let activeSelection = action.payload.disciplineList.find(
        (d) => {
          if (state.useEoyToPickDiscipline) {
            return d.currentEoy === true;
          }
          return d.currentSelection === true;
        },
      );
      if (!activeSelection) {
        activeSelection = action.payload.disciplineList.find(
          (d) => {
            return d.currentSelection === true;
          },
        );
      }
      const startingDate = action.payload.disciplineList.reduce(
        (min, d) => (!min || (moment(min).isAfter(moment(d.startingDate))) ? d.startingDate : min),
        null,
      );
      return {
        ...state,
        mode: activeSelection.mode,
        yearLevel: activeSelection.yearLevel,
        discipline: activeSelection.discipline,
        startingDate,
        eoy: activeSelection.showEoy,
        subject: activeSelection.subject,
      };
    }
    case STPG_UPDATE_SELECTED_DISCIPLINE: {
      return {
        ...state,
        mode: action.payload.discipline.mode,
        yearLevel: action.payload.discipline.yearLevel,
        discipline: action.payload.discipline.discipline,
        eoy: action.payload.discipline.showEoy,
        subject: action.payload.discipline.subject,
        useEoyToPickDiscipline: false,
      };
    }

    case STPG_UPDATE_TABBED_VIEW_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action.payload.activeTab,
      };
    }

    case SR_LOGIN_RECEIVE_INFO:
      return {
        ...state,
        studentName: action.payload.studentName,
        subject: action.payload.subject,
      };
    case SR_REQUEST_SUMMARY_SUCCESS:
      return {
        ...state,
        enrolledSubjects: action.payload.enrolledSubjects,
        completedSessions: action.payload.completedSessions,
        firstSessionDate: action.payload.firstSessionDate,
      };
    default:
      return state;
  }
}
