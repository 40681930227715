export const SESSION_FREQUENCY = {
  WEEKLY: 'weekly',
  FORTNIGHTLY: 'fortnightly',
  TWICE_WEEKLY: 'twice weekly',
  MULTIPLE_SESSIONS: 'multiple sessions',
  OTHER: 'other',
};

/* @deprecated; max pause weeks is derived from the session policy api */
export const PAUSE_SESSIONS_BY_FREQUENCY = {
  weekly: {
    MIN_SESSIONS_TO_SKIP: 2,
    MAX_SESSIONS_TO_SKIP: 4,
    MIN_WEEKS_TO_SKIP: 2,
    MAX_WEEKS_TO_SKIP: 4,
  },
  fortnightly: {
    MIN_SESSIONS_TO_SKIP: 2,
    MAX_SESSIONS_TO_SKIP: 2,
    MIN_WEEKS_TO_SKIP: 2,
    MAX_WEEKS_TO_SKIP: 4,
  },
  'twice weekly': {
    MIN_SESSIONS_TO_SKIP: 2,
    MAX_SESSIONS_TO_SKIP: 8,
    MIN_WEEKS_TO_SKIP: 2,
    MAX_WEEKS_TO_SKIP: 4,
  },
  'multiple sessions': {
    MIN_SESSIONS_TO_SKIP: 2,
    MAX_SESSIONS_TO_SKIP: 8,
    MIN_WEEKS_TO_SKIP: 2,
    MAX_WEEKS_TO_SKIP: 4,
  },
  other: {
    MIN_SESSIONS_TO_SKIP: 0,
    MAX_SESSIONS_TO_SKIP: 0,
    MIN_WEEKS_TO_SKIP: 2,
    MAX_WEEKS_TO_SKIP: 4,
  },
};

export const PAUSE_SESSIONS_UNITS = {
  SESSIONS: 'Sessions',
  WEEKS: 'Weeks',
};
