import { COUNTRY } from '../../types/hubEnums';
import { TabButton } from '@cluey/cluey-components';
import type { COUNTRY_MAP } from '../../types/hubTypes';

interface Props {
  activeCountry: COUNTRY_MAP["COUNTRY_CODE"];
  onTabClick(country: COUNTRY_MAP["COUNTRY_CODE"]);
}

const CountryTabs = ({ activeCountry, onTabClick }: Props) => {
  return (
    <div className='flex items-end'>
      <div>
        <TabButton
          active={activeCountry === COUNTRY.AUSTRALIA.COUNTRY_CODE}
          onClick={() => onTabClick(COUNTRY.AUSTRALIA.COUNTRY_CODE)}
        >
          {COUNTRY.AUSTRALIA.NAME}
        </TabButton>
        <TabButton
          active={activeCountry === COUNTRY.NEW_ZEALAND.COUNTRY_CODE}
          onClick={() => onTabClick(COUNTRY.NEW_ZEALAND.COUNTRY_CODE)}
          >
          {COUNTRY.NEW_ZEALAND.NAME}
        </TabButton>
      </div>
      <div className='border-b border-grey-3 grow h-px'></div>
    </div>
  )
}

export { CountryTabs } 