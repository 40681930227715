import calendar from '../../../../../assets/images/atoms-icon-outline-calendar.svg';
import creditCard from '../../../../../assets/images/atoms-icon-outline-credit-card.svg';
import { Alert, Button, ButtonAsLink, Container, Pending } from '@cluey/cluey-components';
import { DATE_FORMAT_TYPE_8, SESSION_TIME_FORMAT } from '../../../../../util/constants';
import EnrolmentLayout from '../../../../../common/hub/EnrolmentLayout';
import { AccountDetails, Enrolment, EnrolmentDetail, FormFlowProps, TutorTimeSlot } from '../../../../../types/hubInterfaces';
import { getSpecificActiveEnrolment } from '../../../../../selectors/enrolmentSelector';
import { getAccountDetails } from '../../../../../selectors/hubSelector';
import { Loader } from '../../../../../components/Loader';
import moment from 'moment';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useCreateOneOffSession } from '../../../../../hooks/mutations/useSessionMutation';
import { getOneOffSessionDiscountEligibility } from '../../../../../selectors/sessionSelector';
import useAccountPricingPlanQuery from '../../../../../hooks/queries/useAccountPricingPlanQuery';
import { getSessionCost } from '../../../../../util/helper/oneOffSession';
import { setTutorTypeDefault } from '../../../../../util/helpers';

interface Props {
  formFlowProps: FormFlowProps;
}

interface State {
  enrolmentDetail: EnrolmentDetail;
  currentSelectedDate: string;
  timeSlot: TutorTimeSlot;
}

const Confirm = ({ formFlowProps }: Props) => {
  const history = useHistory<State>();
  const { location: { state } } = history;
  const { enrolmentDetail, currentSelectedDate, timeSlot } = state ?? {};
  const { 
    curriculum,
    enrolmentDiscipline,
    enrolmentId,
    enrolmentSfid,
    enrolmentSubject,
    enrolmentYearLevel,
    studentName,
    tutorSfId: tutorId,
    tutorName,
    tutorPhoto,
    tutorTimezone,
    tutorType
  } = enrolmentDetail ?? {};
  const {
    data: pricingPlanData,
    isLoading: isLoadingPricingPlan,
    isError: isErrorPricingPlan,
  } = useAccountPricingPlanQuery();
  
  const { 
    customerSfid: customerId,
    firstName: recipientFirstname,
    timezone: recipientTimezone,
    country: recipientCountry,
   }: AccountDetails = useSelector(getAccountDetails);
  const enrolment: Enrolment = useSelector(getSpecificActiveEnrolment({ enrolmentId }));
  const sessionCost = getSessionCost({ data: pricingPlanData });

  const discountEligible = useSelector(getOneOffSessionDiscountEligibility({
    enrolmentId,
    date: moment(currentSelectedDate).format('YYYY-MM-DD')
  }));

  useEffect(() => {
    if (!enrolmentDetail || !currentSelectedDate || !timeSlot) {
      formFlowProps.jump(0);
    }
  }, [formFlowProps, enrolmentDetail, currentSelectedDate, timeSlot])

  const { isError, isLoading, mutateAsync } = useCreateOneOffSession({
		data: {
      tutorId,
      enrolmentId: enrolmentSfid,
      customerId,
      recipientFirstname,
      recipientTimezone,
      recipientCountry,
      studentFirstName: enrolment?.student,
      studentName: `${enrolment?.student} ${enrolment?.lastname}`,
      studentYearLevel: enrolmentYearLevel,
      studentCurriculum: curriculum,
      programSubject: enrolmentSubject,
      sessionStartDateTime: timeSlot?.start,
      sessionEndDateTime: timeSlot?.end,
      tutorFirstName: tutorName,
      tutorTimezone,
      tutorPhoto
    },
		onSuccess: () => formFlowProps.next({ enrolmentDetail, discountEligible }),
	});

  const confirmAddSession = async () => {
    await mutateAsync()
  }

  const getSessionCostText = () => {
    if (discountEligible) {
      return 'The charge for this session will be processed in an upcoming billing cycle.';
    } else {
      /* 
        if the selected date is within 7 days, payment will be taken today;
        otherwise, it will be taken in the upcoming billing cycle
      */
      const withinSevenDays = moment(currentSelectedDate).isSameOrBefore(moment().add(7, 'days'));
      const paymentAcquisitionText = withinSevenDays ? 'taken today' : 'processed in an upcoming billing cycle';
      return `The ${sessionCost ? '$' : ''}${sessionCost || ''} charge for this session will be ${paymentAcquisitionText}.`
    }
  }

  return (
    <EnrolmentLayout
      sectionHeading={`${studentName} ${enrolmentDiscipline}`}
      mainHeading="Confirm session details"
      enrolmentId={enrolmentId}
      showEnrolmentStatus={false}
      showSummerModeEnrolmentStatus={false}
      isLoading={isLoadingPricingPlan}
    >
      <Container>
        {isErrorPricingPlan && (
          <Alert
            className='mb-6'
            type="error"
            body="There was an error retrieving your pricing plan. You won't be able to see an accurate session cost."
          />
        )}
        <p className='text-grey-5'>Please review and confirm the session time and billing details:</p>
        <section className='border border-grey-2 flex flex-col md:flex-row justify-between mt-6'>
          <div className='flex flex-col gap-y-3.5 p-8 md:p-16'>
            <p className='font-bold rounded text-xs uppercase text-grey-5'>One-off SESSION</p>
            <div className='flex gap-x-5 text-grey-5'>
              <img className='self-start' src={calendar} alt="calendar" width={22} />
              <div className='text-sm'>
                <div>{moment.utc(timeSlot?.start).tz(recipientTimezone).format(DATE_FORMAT_TYPE_8)}</div>
                <div>
                  {`
                    ${moment.utc(timeSlot?.start).tz(recipientTimezone).format(SESSION_TIME_FORMAT)}
                     - 
                    ${moment.utc(timeSlot?.end).tz(recipientTimezone).format(SESSION_TIME_FORMAT)} 
                  `}
                </div>
                <ButtonAsLink onClick={() => formFlowProps.previous({ currentSelectedDate })}>Change</ButtonAsLink>
              </div>
            </div>

            <div className='flex gap-x-5 text-grey-5'>
              <img className='self-start' src={creditCard} alt="credit-card" width={22} />
              <div className='text-sm'>
                <div>{getSessionCostText()}</div>
              </div>
            </div>

            <div className='mt-2.5'>
              <Pending loading={isLoading} loader={<Loader />}>
                <Button className='w-full md:w-auto' onClick={confirmAddSession} size='lg'>Confirm session</Button>
              </Pending>
              {isError && <div className='mt-2.5 text-sm text-red-5'>There was a problem encountered when trying to create your one-off session on the chosen date.</div>}
            </div>
          </div>
          <div className='bg-slate-1 basis-1/2'>
            <div className='flex gap-x-4 items-center p-8 md:p-16'>
              <div className='rounded-full overflow-hidden w-12'>
                <img src={tutorPhoto} alt={`${tutorName}`} width={50} />
              </div>
              <div className='text-grey-5'>
                <div className='font-bold'>{tutorName}</div>
                <div className='text-xs'>{setTutorTypeDefault({ tutorType })}</div>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </EnrolmentLayout>
  )
}

export { Confirm };