import { api } from '../../../api';
import HubPageLayout from '../../../common/hub/HubPageLayout';
import { FullPageLoader } from '../../../components/Loader/Loader';
import { HomeHero } from './hero';
import { HomeWidgets } from './widgets';

export const HomePage = () => {
	const { data, isLoading } = api.account.details.useQuery();

	if (isLoading) return <FullPageLoader />;

	const isLead = 'userType' in data && data.userType === 'Lead';

	return (
		<HubPageLayout noContainer>
			<div id="cz_home_banner">
			</div>
			<HomeHero isLead={isLead} />
			<HomeWidgets isLead={isLead} />
		</HubPageLayout>
	);
};
