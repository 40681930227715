import initialState from '../../initialState';
import * as actionTypes from '../../../actions/actionTypes';

export default function hubContactPageReducer(state = initialState.ui.apiState.hubContactPage, action) {
  switch (action.type) {
    case actionTypes.HUB_CR_SUBMIT_CONTACT_CASE_START:
      return {
        ...state,
        fetchError: false,
        isLoading: true,
        isSubmitted: false,
        errorMsg: '',
      };
    case actionTypes.HUB_CR_RESET_CONTACT_CASE_STATE:
      return {
        ...state,
        fetchError: false,
        isLoading: false,
        isSubmitted: false,
        errorMsg: '',
      };
    case actionTypes.HUB_CR_SUBMIT_CONTACT_CASE_SUCCESS:
      return {
        ...state,
        fetchError: false,
        isLoading: false,
        isSubmitted: true,
        errorMsg: '',
      };
    case actionTypes.HUB_CR_SUBMIT_CONTACT_CASE_FAILED:
      return {
        ...state,
        fetchError: true,
        isLoading: false,
        isSubmitted: false,
        errorMsg: action.payload.errorMsg,
      };
    case actionTypes.HUB_CR_PS_FETCH_RESUME_SESSION_START:
      return {
        ...state,
        resumeSessionDate: {
          ...state.resumeSessionDate,
          firstFetch: true,
          fetchError: false,
        },
      };
    case actionTypes.HUB_CR_PS_FETCH_RESUME_SESSION_SUCCESS:
      return {
        ...state,
        resumeSessionDate: {
          ...state.resumeSessionDate,
          firstFetch: false,
          fetchError: false,
        },
      };
    case actionTypes.HUB_CR_PS_FETCH_RESUME_SESSION_FAILED:
      return {
        ...state,
        resumeSessionDate: {
          ...state.resumeSessionDate,
          firstFetch: false,
          fetchError: true,
        },
      };
    default:
      return state;
  }
}
