import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Alert, Container, Form, Button } from '@cluey/cluey-components';
import moment, { Moment } from 'moment';

import withSelectedEnrolment, { SelectedEnrolment } from '../../../../common/hub/NewWithSelectedEnrolment';
import InPageLoader from '../../../../common/InPageLoader';

import { isFormPauseSessionsLoading } from '../../../../selectors/loaderSelector';
import { isFormPauseSessionsLoadingFailed } from '../../../../selectors/errorSelector';
import { getNextUpcomingSessionForEnrolment, getTimezone } from '../../../../selectors/hubSelector';
import { fetchUpcomingSessionsWithSTPH } from '../../../../actions/hub/hubSessionsPageActions';
import { setFlagToReloadApp as setFlagToReloadAppAction } from '../../../../actions/hub/hubEnrolmentsPageActions';
import { submitCaseToSF } from '../../../../actions/hub/hubContactPageActions';
import ErrorBlock from '../../../../common/ErrorBlock';
import EnrolmentLayout from '../../../../common/hub/EnrolmentLayout';
import {
  PATH_HUB_CR_PAUSE_SESSIONS,
  PATH_HUB_ENROLMENT_MANAGE_PAUSE,
  PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP,
  PATH_HUB_SUMMER_PAUSE_ENROLMENT,
  PATH_HUB_CR_PAUSE_SUMMER_CANCEL,
  PATH_HUB_CR_CANCEL_ENROLMENT,
  PATH_HUB_HOME,
} from '../../../../util/pagePath';
import { ENROLMENT_MANAGEMENT_STEPS, PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT } from '../../../../util/constants';
import { isEnrolmentCancelRequested, isEnrolmentPausedCheck, isGroupSessionCheck } from '../../../../util/helpers';
import { fetchEnrolmentForManagement } from '../../../../actions/hub/hubEnrolmentManagementActions';
import { saveSummerModeData as saveSummerModeDataAction } from '../../../../actions/summerModeActions';
import { TRACKING_PE } from '../../../../util/trackingClasses';
import { submitNewPause, submitChangePause } from '../../../../actions/hub/hubPauseActions';
import BaseList from '../../../../components/List/BaseList';
import { getSummerPromo } from '../../../../selectors/summerModeSelector';
import { DatesUtils } from '../../../../util/dates';
import { isIncentiveValid } from '../../../../util/summerCopy';
import { Term4SummerPause } from '../../../../components/EnrolmentManagement/PauseSessions/summerMode/Term4SummerPause';
import { summerPauseInsteadOfCancelEnabled } from '../../../../selectors/enrolmentSelector';
import { useGetResumeDate } from '../../../../hooks/queries/useEnrolmentQuery';
import { willEnrolmentBePausedCheck } from '../../../../util/helpers';
import { PreferToStopSessions } from '../../../../components/PreferToStopSessions';
import type { InitialState } from '../../../../types/hubTypes';
import { CheckIcon } from '../../../../icons';
import HubFooter from '../../../../common/hub/HubFooter/HubFooter';
import HubHeader from '../../../../common/hub/HubHeader';
import classNames from 'classnames';
import { useFeatureFlagEnabled, useFeatureFlagPayload } from 'posthog-js/react';
import { ConnectedCodeCampPromo } from '../../../../components/hub/home/CodeCampPromo';
import { api } from '../../../../api';
import useAccountPricingPlanQuery from '../../../../hooks/queries/useAccountPricingPlanQuery';

type SummerPauseProps = {
  preventCancel?: boolean;
  selectedEnrolment: SelectedEnrolment;
};

type CodeCampPromo = {
  startDate: string;
  endDate: string;
  yearLevels: string[];
};

const REASONS_TO_PAUSE = [
  'Your paid sessions will be moved to Term 1',
  <span>
    Enjoy a <b>20% discount</b> on your first 5 unpaid Term 1 sessions when you return
  </span>,
];

const REASONS_TO_PAUSE_NO_DISCOUNT = [
  'Your paid sessions will be moved to Term 1',
  <div>
    <div>
      Enjoy a <b>20% discount</b> on your first 5 unpaid Term 1 sessions when you return
    </div>
    <div className="text-sm">* Does not apply to Corporate Accounts, Internal Staff, Family and friends</div>
  </div>,
];

// const PREVENT_CANCEL_REASONS = [
//   "Don't pay until learning restarts. Any credit for sessions you have already paid will be automatically applied once you start again.",
//   'Retain access to session reports and recordings.',
//   'Resume when term starts or earlier to continue learning progress and get ready for next year.',
// ];

/* @TODO now */
/* @TODO add test */
const SummerPause = ({ preventCancel = false, selectedEnrolment }: SummerPauseProps) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isFormPauseSessionsLoading);
  const failedToLoad = useSelector(isFormPauseSessionsLoadingFailed);
  const timezone = useSelector(getTimezone);
  const isSubmitting = useSelector((state: InitialState) => {
    const { isLoading: isSubmittingPauseRequest } = state.ui.apiState.hubContactPage;
    const { isSubmitting: isSavingSummerModeData } = state.ui.apiState.summerMode.saveSummerModeData;
    return isSubmittingPauseRequest || isSavingSummerModeData;
  });
  const submitError = useSelector((state: InitialState) => state.ui.apiState.hubContactPage.fetchError);
  const { errorMsg } = useSelector((state: InitialState) => state.ui.apiState.hubContactPage);
  const forceToReload = useSelector((state: InitialState) => state.ui.apiState.hubActiveEnrolmentsPage.forceToReload);
  const summerPromo = useSelector((state: InitialState) => {
    return getSummerPromo(state, { screen: PATH_HUB_CR_PAUSE_SESSIONS, confirming: true });
  });

  const [view, setView] = useState(ENROLMENT_MANAGEMENT_STEPS.DEFAULT);
  const [selectedEndDate, setSelectedEndDate] = useState<string | Moment>('');
  const summerPauseEnabled = useSelector(summerPauseInsteadOfCancelEnabled);
  const [selectedPauseFromDate, setSelectedPauseFromDate] = useState(null);
  const [selectedPauseToDate, setSelectedPauseToDate] = useState(null);

  const codeCampPromoFlagEnabled = useFeatureFlagEnabled('summer-pause-code-camp-promo');
  const codeCampPromo = useFeatureFlagPayload('summer-pause-code-camp-promo') as CodeCampPromo;

  const isYearLevelEligible = codeCampPromo?.yearLevels.includes(selectedEnrolment?.yearLevel);
  const isDuringCodeCampPromo = moment().isBetween(codeCampPromo?.startDate, codeCampPromo?.endDate, 'day', '[]');
  const { data: accountDetails } = api.account.details.useQuery();
  const isAU = accountDetails?.country === 'AU';
  const showCodeCampPromo = codeCampPromoFlagEnabled && isDuringCodeCampPromo && isYearLevelEligible && isAU;

  useEffect(() => {
    dispatch(fetchUpcomingSessionsWithSTPH());
    return undefined;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // NOTE: to reload app
    return () => {
      if (forceToReload) {
        window.location.reload();
      }
    };
  }, [forceToReload]);

  const {
    enrolmentId,
    student,
    subject,
    enrolmentStatus,
    enrolmentPausedFrom,
    enrolmentPausedTo,
    enrolmentSfid,
    type,
    resumeSessionDate,
  } = selectedEnrolment;
  const { isLoading: loadingResumeDate } = useGetResumeDate({
    enrolmentId,
    pauseToDate: selectedPauseToDate,
  });
  const history = useHistory();
  const willBePaused = willEnrolmentBePausedCheck({
    enrolmentPausedFrom,
    enrolmentPausedTo,
  });

  const nextUpcomingSession = useSelector(getNextUpcomingSessionForEnrolment({ enrolmentId }));

  const {
    data: accountPricingPlan,
    isLoading: isLoadingAccountPricingPlan,
    isError: isErrorAccountPricingPlan,
  } = useAccountPricingPlanQuery();
  const promoBlacklist = ['Internal Staff', 'Corporate Accounts', 'Friends and family', 'Family and friends'];
  const isPricingPlanBlacklisted = (planName) => {
    return promoBlacklist.some((name) => new RegExp(`^${name}`, 'i').test(planName));
  };
  const isBlacklistedFromPromo = isPricingPlanBlacklisted(accountPricingPlan?.currentPricingPlan?.pricingPlanName);

  /* CRE-103 Redirect Pause Session/Manage Pause */
  useEffect(() => {
    const isEnrolmentPaused = isEnrolmentPausedCheck({ enrolmentStatus });
    const isCancelRequested = isEnrolmentCancelRequested({ enrolmentStatus });
    const isGroup = isGroupSessionCheck(type);
    const redirectPath = isGroup
      ? PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP
      : `${PATH_HUB_ENROLMENT_MANAGE_PAUSE}?token=${enrolmentId}`;

    if (isCancelRequested) {
      history.push(`${PATH_HUB_CR_CANCEL_ENROLMENT}?token=${enrolmentId}`);
    } else if (isEnrolmentPaused) {
      dispatch(
        fetchEnrolmentForManagement({
          enrolmentId,
          callback: () => {
            history.push(redirectPath);
          },
        })
      );
      return undefined;
    }
    return undefined;
    // eslint-disable-next-line
  }, [enrolmentStatus, enrolmentPausedFrom, enrolmentPausedTo]);

  useEffect(() => {
    if (!summerPauseEnabled) {
      history.push(PATH_HUB_CR_PAUSE_SESSIONS);
    }
    // eslint-disable-next-line
  }, [summerPauseEnabled]);

  const reasonsToPause = isBlacklistedFromPromo ? REASONS_TO_PAUSE_NO_DISCOUNT : REASONS_TO_PAUSE;

  let display;
  if (isLoading || isSubmitting || (loadingResumeDate && !!selectedEndDate) || isLoadingAccountPricingPlan) {
    return <InPageLoader />;
  }

  if (failedToLoad || isErrorAccountPricingPlan) {
    display = <ErrorBlock errorMsg="Failed to load data, please try again later." />;
  }

  const onSubmissionHandler = ({ startDate, endDate, summerModeHolidayPractice }) => {
    const endDateUTC = DatesUtils.convertFromTimezoneToUTC({
      stringDate: endDate,
      dateFormat: PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT,
      timezone,
    });
    setSelectedPauseFromDate(moment(startDate).format('YYYY-MM-DD'));
    setSelectedPauseToDate(moment(endDate).format('YYYY-MM-DD'));
    const startDateInLocalTime = moment(startDate);
    const endDateInLocalTime = moment(endDate);
    const pauseToDateInLocalTime = moment(endDate).subtract(1, 'day'); // need to create a new moment instance passing endDate as argument because .subtract() mutates the instance
    /*
    // 2021 summer mode
    const summerPauseFrom = moment(startDate).tz(timezone).format(PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT);
    let summerPauseTo = moment(endDate).tz(timezone).format(PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT);
    const summerPauseFromDay = moment(startDate).tz(timezone).format(DATE_FORMAT_TYPE_3);

    summerPauseTo = moment(endDate).subtract(1, 'day').tz(timezone).format(PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT);

    const summerResumeDay = moment(endDate).tz(timezone).format(DATE_FORMAT_TYPE_3);
    */
    // DatesUtils.convertDatesForMuleSoft is two-step conversion: First it converts into Sydney midnight and converts the result into UTC.
    const summerPauseFrom = startDateInLocalTime.format(PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT);
    const summerPauseTo = pauseToDateInLocalTime.format(PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT);

    setSelectedEndDate(endDateInLocalTime);
    // create an SF case for the manual application of the discounts
    if (!isBlacklistedFromPromo) {
      const sfCasePayload = {
        caseType: 'Payment',
        caseRecordType: 'Support',
        caseRequestFrom: 'Parent',
        caseSubject: 'Apply resumption offer',
        caseCategory: 'Support Request',
        caseSubCategory: 'Additional discount requested',
        message:
          'The customer has opted to pause over summer rather than cancel. Please apply 20% off their next 5 unpaid sessions. Please ensure the pause has gone through first i.e. next unpaid session date is Term 1 2025.',
        uniqueEnrolmentId: enrolmentId,
      };
      dispatch(submitCaseToSF(sfCasePayload));
    }

    if (willBePaused) {
      const payload = {
        enrolmentId: enrolmentSfid,
        currentPauseStart: moment(enrolmentPausedFrom).format('YYYY-MM-DD'),
        selectedResumeDate: summerPauseTo,
        doNotOffsetEndDate: true,
        callback: () => {
          const onSubmitSuccessCallback = () => {
            setView(ENROLMENT_MANAGEMENT_STEPS.CONFIRMED);
            dispatch(setFlagToReloadAppAction());
          };

          const summerModePayload = {
            appliedSummerModeIncentive: summerPromo.incentiveCode,
            shouldApplyIncentive: isIncentiveValid(summerPromo.incentiveCode, endDateUTC, {
              screen: PATH_HUB_CR_PAUSE_SESSIONS,
            }),
            salesForceEnrolmentId: enrolmentSfid,
            enrolmentId,
            summerPauseFrom,
            summerPauseTo,
            summerModeHolidayPractice,
            onSubmitSuccess: onSubmitSuccessCallback,
          };
          dispatch(saveSummerModeDataAction(summerModePayload));
        },
      };

      dispatch(submitChangePause(payload));
    } else {
      const payload = {
        enrolmentId: enrolmentSfid,
        startDate,
        endDate: summerPauseTo,
        preventCancel,
        onSubmitSuccess: () => {
          const onSubmitSuccessCallback = () => {
            setView(ENROLMENT_MANAGEMENT_STEPS.CONFIRMED);
            dispatch(setFlagToReloadAppAction());
          };

          const summerModePayload = {
            appliedSummerModeIncentive: summerPromo.incentiveCode,
            shouldApplyIncentive: isIncentiveValid(summerPromo.incentiveCode, endDateUTC, {
              screen: PATH_HUB_CR_PAUSE_SESSIONS,
            }),
            salesForceEnrolmentId: enrolmentSfid,
            enrolmentId,
            summerPauseFrom,
            summerPauseTo,
            summerModeHolidayPractice,
            onSubmitSuccess: onSubmitSuccessCallback,
          };
          dispatch(saveSummerModeDataAction(summerModePayload));
        },
      };

      dispatch(submitNewPause(payload));
    }
  };

  // OLD: check whether it is term 4 after term4 start and before term 1 start
  // NEW: summer mode form will be enabled in AppRoute.js
  if (!isLoading && !failedToLoad) {
    display = (
      <Term4SummerPause
        enrolmentPausedFrom={enrolmentPausedFrom}
        willBePaused={willBePaused}
        enrolmentSfid={enrolmentSfid}
        student={student}
        enrolmentId={enrolmentId}
        isSubmitting={isSubmitting}
        preventCancel={preventCancel}
        submit={onSubmissionHandler}
      />
    );
  }

  const mainHeading = student && subject ? `${student} ${subject}` : '';

  if (view === ENROLMENT_MANAGEMENT_STEPS.CONFIRMED) {
    return (
      <>
        <HubHeader title="Hub" />
        <Container className="flex flex-col items-center gap-9 px-4 py-7 md:py-8 lg:mb-0">
          <div
            className={classNames('mt-10 flex flex-col items-center gap-2', {
              [TRACKING_PE.SUCCESS]: TRACKING_PE.SUCCESS,
            })}
          >
            <div className="flex h-[60px] w-[60px] items-center justify-center rounded-full bg-green-4">
              <CheckIcon className="text-white" width={40} height={40} />
            </div>
            <h1 className="text-center font-display text-[32px] font-bold sm:text-[40px]">
              Pause enrolment request sent
            </h1>
          </div>
          <div className="flex flex-col items-center gap-4 text-lg">
            <p>{`Your request to pause ${student}'s ${subject} enrolment has been sent.`}</p>
            <p>
              {`Sessions will be paused from ${moment(selectedPauseFromDate).format(
                'dddd, D MMMM YYYY'
              )} and resume on ${moment(selectedPauseToDate).format('dddd, D MMMM YYYY')}`}
            </p>
          </div>
          <Button
            onClick={() => {
              history.push(PATH_HUB_HOME);
            }}
            size="lg"
            appearance="reverse"
          >
            Return to home
          </Button>
          {showCodeCampPromo && (
            <div className="mt-12 w-full">
              <ConnectedCodeCampPromo />
            </div>
          )}
          <div className="h-[150px]" />
        </Container>
        <HubFooter />
      </>
    );
  }

  return (
    <EnrolmentLayout
      sectionHeading={mainHeading}
      mainHeading={!preventCancel ? 'Pause enrolment' : 'Just need a summer break?'}
      enrolmentId={enrolmentId}
      hasCTAAboveFooter
    >
      <Container>
        {submitError && errorMsg && <ErrorBlock errorMsg={errorMsg} />}
        {willBePaused ? (
          <Alert className="mb-9" type="info" body={null}>
            <p>You've already setup a pause for summer.</p>
            <p>
              Your enrolment will be paused from {moment(enrolmentPausedFrom).format('D MMMM YYYY')}
              &nbsp;and resume on {moment(enrolmentPausedTo)?.add(1, 'day').format('D MMMM YYYY')}.
            </p>
            {(resumeSessionDate || nextUpcomingSession?.startTime) && (
              <p>
                Your first session after resuming will be on{' '}
                {moment(resumeSessionDate ?? nextUpcomingSession?.startTime).format('D MMMM YYYY')}.
              </p>
            )}
          </Alert>
        ) : (
          <div className="mb-9 rounded-lg bg-slate-1 px-3 py-1 md:px-9 md:pb-10 md:pt-8 lg:px-14">
            <BaseList
              textLines={reasonsToPause}
              title={
                <p className="mb-1 font-display text-xl font-bold text-grey-6 lg:text-2xl">
                  Pause your plan and unlock Term 1 savings
                </p>
              }
              renderSubtitle={
                <p className="mb-4 mt-6 leading-snug text-grey-6">
                  We understand that you might need a break. Instead of cancelling, you can pause your enrolment and
                  enjoy exclusive benefits when you return. During this period:
                </p>
              }
            />
          </div>
        )}
        <Form>{display}</Form>
      </Container>
      <PreferToStopSessions enrolmentId={enrolmentId} />
    </EnrolmentLayout>
  );
};

export const SummerPauseForCancel = withSelectedEnrolment(
  (props) => {
    return <SummerPause {...props} preventCancel />;
  },
  null,
  PATH_HUB_CR_PAUSE_SUMMER_CANCEL
);

export { SummerPause as PresentationalFormPauseSessionsSummerMode };
export default withSelectedEnrolment(SummerPause, null, PATH_HUB_SUMMER_PAUSE_ENROLMENT);
