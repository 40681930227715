import { Fragment, useEffect, useState } from 'react';
import { Button } from '@cluey/cluey-components';
import { AffectedSessions } from '../AffectedSessions';
import { useDatePickerProps } from '../../../DatePicker/DatePicker';
import { Dropdown, useDropdownProps } from '../../../Dropdown/Dropdown';
import { ErrorMsg } from '../../../../common/Error';
import { RadioGroup, useRadioGroupProps } from '../../../RadioGroup/RadioGroup';
import { getTerm123PauseSessionsFormData } from '../../../../selectors/hubSelector';
import { getPauseSessionsOptionsByFrequency } from '../../../../selectors/enrolmentManagementSelector';
import moment, { Moment } from 'moment';
import { get, isEmpty } from 'lodash';
import { getPauseRange, getSessionsWithin48Hours } from '../../../../util/helper/pause';
import { getHolidayPauseDuration } from '../../../../selectors/enrolmentSelector';
import { getAffectedSessions } from '../../../../util/helpers';
import { PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT } from '../../../../util/constants';
import {
  savePauseFromResumeFromDate,
  saveAffectedSessions,
} from '../../../../actions/hub/hubContactPageActions';
import { SessionExcludedAlert } from '../AffectedSessions/SessionExcludedAlert';
import { useDispatch, useSelector } from 'react-redux';
import { allowHolidayPause } from '../../../../selectors/schoolTerms/schoolTermsSelectors';
import type { NullableMoment } from '../../../../types/hubTypes';
import type { Session, SessionPolicy } from '../../../../types/hubInterfaces';

interface Props {
  timezone: string;
  enrolmentId: string;
  sessions: Array<Session>
  isSubmitting: boolean;
  sessionPolicy: SessionPolicy;
  submit({startDate, endDate}: {
    startDate: NullableMoment;
    endDate: NullableMoment;
  }): void;
}

/* @TODO add test; add more test cases */
const Term123PauseSessions = ({
  timezone,
  enrolmentId,
  sessions,
  isSubmitting,
  sessionPolicy,
  submit,
}: Props) => {
  /* 
    NOTE, due to SalesForce limitation, we have to hide some options.
    The limitation to the date range picker is future dated pauses for up to 4 weeks into the future. Sales Force
    won't be able to properly resolve the pause action if there are no sessions within the selected future dates.

    Since we don't have time to remove and clean, we just use this below variable to hide it.
  */
  const dispatch = useDispatch();
  const term = useSelector(getTerm123PauseSessionsFormData);
  const skipByNextOptionData = useSelector(getPauseSessionsOptionsByFrequency);
  const schoolTermHolidayPauseEnabled = useSelector(allowHolidayPause);
  const holidayPauseDuration = useSelector(getHolidayPauseDuration); 

  const [pauseExactDate, setPauseExactDate] = useState(null);
  const [resumeExactDate, setResumeExactDate] = useState(null);

  const [dropdownDisabled, setDropdownDisabled] = useState(true);
  const [affectedSessions, setAffectedSessions] = useState({
    skippedSessions: []
  });

  const [validationError, setValidationError] = useState(null);

  const setDateForSubmission = ({ pause, resume }: {
    pause?: Moment | string | null;
    resume: Moment | string | null;
  }) => {
    setPauseExactDate(pause);
    setResumeExactDate(resume);
  };

  const {
    state,
    schoolTerm,
    endDate: termEndDate,
  } = term;

  const radios = [];
  const radioValues = {
    type1: `During upcoming ${state} public school holidays`,
    type2: 'Next sessions or weeks',
    type3: 'Specific date range',
  };

  if (schoolTermHolidayPauseEnabled) {
    radios.push({
      text: `During upcoming ${state} public school holidays`,
      value: radioValues.type1,
      key: `${groupName}-category-1`,
      subText: `${schoolTerm} ends ${moment(termEndDate)
        .tz(timezone)
        .format('MMMM D')}`,
      containerClassName: 'flex items-center cursor-pointer mb-5',
      // eslint-disable-next-line
      renderBelowLabel: ({ checked }) => {
        return checked
          ? resumeExactDate && (
              <AffectedSessions
                sessions={affectedSessions.skippedSessions}
                timezone={timezone}
                resumeFromDate={resumeExactDate}
              />
            )
          : null;
      },
    });
  }

  const selectRange = useDropdownProps({
    id: 'select-range',
    options: getPauseRange({ allowedWeeks: sessionPolicy?.pause.allowedWeeks }),
    defaultValue: 1,
    containerClasses: 'mr-3',
    disabled: dropdownDisabled,
  });

  const selectedRange: number = selectRange.selectedOption;

  const checkSkippedSessions = ({ skippedSessions = [], errorMsg }) => {
    if (isEmpty(skippedSessions)) {
      setValidationError(errorMsg);
      setDateForSubmission({
        pause: null,
        resume: null,
      });
      return true;
    }
    setValidationError(null);
    return false;
  };

  const handleSkipByWeeks = () => {
    /* For adjacent pauses, the pause start date should be after the day after tomorrow */
    const start = moment().tz(timezone).add(3, 'days');
    const end = moment().tz(timezone).add(3, 'days').add(selectedRange, 'weeks');
    const skippedSessions = getAffectedSessions({
      enrolmentId,
      sessions,
      start: start.toISOString(),
      end: end.toISOString(),
    });
    const hasNoSessionAffected = checkSkippedSessions({
      skippedSessions,
      errorMsg:
        'The selected pause period will not include any session',
    });
    if (hasNoSessionAffected) {
      return;
    }

    setDateForSubmission({
      pause: start.clone().format(PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT),
      resume: end.clone().format(PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT),
    });

    setAffectedSessions({
      skippedSessions,
    });
  };

  if (!isEmpty(skipByNextOptionData) && sessionPolicy?.pause.pauseAllowed === 'Anytime') {
    radios.push({
      text: 'Next',
      value: radioValues.type2,
      key: `${groupName}-category-2`,
      containerClassName: 'flex items-center cursor-pointer',

      renderNextToLabel: () => {
        return (
          <div className="ml-4 flex items-center">
            <Dropdown {...selectRange} />
            <div>week{selectedRange === 1 ? '' : 's'}</div>
          </div>
        );
      },
      renderBelowLabel: ({ checked }) => {
        return checked && (
          <Fragment>
            <div className='my-4'>
              {getSessionsWithin48Hours({ enrolmentId, sessions, timezone }).map((s) => ( 
                  <SessionExcludedAlert
                    session={s}
                    timezone={timezone}
                  />
                )
              )}
            </div>
            {resumeExactDate && (
              <AffectedSessions
                sessions={affectedSessions.skippedSessions}
                timezone={timezone}
                resumeFromDate={resumeExactDate}
              />
            )}
          </Fragment>
        )
      }
    });
  }

  const datePickerProps = useDatePickerProps({
    id: `${groupName}-datepicker`,
    labelText: 'Pause from:',
    placeholder: 'Select start date',
    options: {
      mode: 'single',
      minDate: new Date().fp_incr(1),
      maxDate: new Date().fp_incr(91),
    },
  });

  const pauseFromDate = get(datePickerProps, ['value', '0']);
  const resumeOnInitialDate = pauseFromDate
    ? new Date(pauseFromDate).fp_incr(1)
    : new Date().fp_incr(1);
  const resumeOnMaxDate = pauseFromDate
    ? new Date(pauseFromDate).fp_incr(28)
    : new Date().fp_incr(1);
  const resumeDatePickerProps = useDatePickerProps({
    id: `${groupName}-resume-datepicker`,
    labelText: 'Pause to:',
    placeholder: 'Select end date',
    options: {
      mode: 'single',
      minDate: resumeOnInitialDate,
      maxDate: resumeOnMaxDate,
    },
  });
  const resumeFromDate = get(resumeDatePickerProps, ['value', '0']);
  useEffect(() => {
    if (!pauseFromDate || !resumeFromDate) {
      setDateForSubmission({
        pause: pauseFromDate,
        resume: resumeFromDate,
      });

      return undefined;
    }

    if (moment(resumeFromDate).isSameOrBefore(moment(pauseFromDate))) {
      resumeDatePickerProps.setValue(null);
      setResumeExactDate(null);
      setDateForSubmission({
        resume: null,
      });
    }

    const skippedSessions = getAffectedSessions({
      enrolmentId,
      sessions,
      start: moment(pauseFromDate).toISOString(),
      end: moment(resumeFromDate).toISOString(),
    });

    setDateForSubmission({
      pause: pauseFromDate,
      resume: resumeFromDate,
    });

    setAffectedSessions({
      skippedSessions,
    });
    return undefined;
    // eslint-disable-next-line
  }, [pauseFromDate, resumeFromDate, sessions]);

  const radioGroupProps = useRadioGroupProps({
    groupName,
    radios,
    onChangeHandler: (selected) => {
      setValidationError(null);
      setAffectedSessions({
        skippedSessions: [],
      });

      if (selected === radioValues.type1) {
        const { pauseStart, pauseEnd } = holidayPauseDuration;
        const skippedSessions = getAffectedSessions({
          enrolmentId,
          sessions,
          start: pauseStart.toISOString(),
          end: pauseEnd.toISOString(),
        });
        const hasNoSessionAffected = checkSkippedSessions({
          skippedSessions,
          errorMsg:
            'The selected pause period will not include any session',
        });
        if (hasNoSessionAffected) {
          return;
        }

        setDateForSubmission({
          pause: pauseStart.format('YYYY-MM-DD'), // school holiday starts one day after school term ends
          resume: pauseEnd.format('YYYY-MM-DD'), // this is actually pause to date
        });

        setAffectedSessions({
          skippedSessions,
        });
      }

      if (selected === radioValues.type2) {
        setDropdownDisabled(false);
        handleSkipByWeeks();
      } else {
        setDropdownDisabled(true);
      }
    },
  });

  useEffect(() => {
    if (!radioGroupProps.selectedRadio) {
      return;
    }

    if (selectedRange > 4) {
      selectRange.setSelectedOption(1);
    }
    handleSkipByWeeks();
    // eslint-disable-next-line
  }, [selectedRange]);

  const onSubmitHandler = () => {
    if (!radioGroupProps.selectedRadio) {
      setValidationError('Please select a period to pause your sessions.');
      return;
    }

    if (validationError) {
      return;
    }

    dispatch(
      saveAffectedSessions({
        affectedSessions: (affectedSessions || {}).skippedSessions,
      })
    );

    dispatch(
      savePauseFromResumeFromDate({
        pauseFromDate: pauseExactDate,
        resumeFromDate: resumeExactDate,
      })
    );

    submit({
      startDate: pauseExactDate,
      endDate: resumeExactDate,
    });
  };

  return (
    <Fragment>
      <h2 className="lh-1-2 mb-7 font-display text-xl font-bold">
        When would you like to pause sessions?
      </h2>
      <RadioGroup {...radioGroupProps} className="mb-8" />
      <div className="mb-4 text-left">
        {validationError && (
          <ErrorMsg text={validationError} extraClasses="!mb-2" />
        )}
        <Button size="lg" disabled={isSubmitting} onClick={onSubmitHandler}>
          Confirm pause
        </Button>
      </div>
    </Fragment>
  );
};

export const groupName = 'contact-form-pause-session';
export default Term123PauseSessions;