import { useState } from 'react';
import { first } from 'lodash';
import { Loader } from '../../../../../components/Loader/Loader';
import { api } from "../../../../../api"
import refreshIcon from '../../../../../assets/images/errorPage/refreshIcon.svg'
import { AssessmentReport } from '../../../../../api/types/assessment';
import PerformanceHighlights from './PerformanceHighlights';
import PerformanceBreakdown from './PerformanceBreakdown';
import TopicDetailsModal, { TopicModalData } from './TopicDetailsModal';
import { TopicClickHandler } from './Topics';
import { HeroDesktopOnly } from '../../hero/AssessmentHero/HeroDesktopOnly';


export interface AssessmentWidgetBaseProps {
  data: AssessmentReport;
  studentLink: string;
  modalOpen: boolean;
  modalData: TopicModalData;
  viewTopicDetails: TopicClickHandler;
  closeModal: () => void;
  studentName?: string;
}

const AssessmentReportWidgetBase = ({ data, studentLink, modalOpen, modalData, closeModal, studentName = "", viewTopicDetails }: AssessmentWidgetBaseProps) => {
  const latestAssessment = first(data || []);
  const breakdown = latestAssessment?.breakdown || [];

  const showOpportunityToExcel = latestAssessment?.summary?.percentage >= 100;
  const showOpportunityForGrowth = latestAssessment?.summary?.percentage <= 40;
  const message = 
  (showOpportunityToExcel || showOpportunityForGrowth)
    ? 'Based on the assessment results:'
    : `Based on the assessment results, here are ${studentName ? studentName + "'s" : 'your'} strengths and focus areas:`

  return (
    <div className="container mx-auto flex flex-grow flex-col items-center justify-center py-12">
      <div className="rounded-lg rounded-b-none border border-b-0 border-grey-3 w-[100%] py-6">
        <div className="mb-5 px-6 text-lg">
          {message}
        </div>
        <PerformanceHighlights
          score={latestAssessment?.summary?.percentage}
          strengths={latestAssessment?.summary?.strengths} 
          focusAreas={latestAssessment?.summary?.focusAreas}
        />
        <div className="my-5 px-6 text-lg">
          For a detailed look at the results:
        </div>
        <div>
          <PerformanceBreakdown data={breakdown} onTopicClicked={viewTopicDetails} />
        </div>
      </div>
      <div className="border border-grey-3 rounded-b-lg w-full p-6">
        <HeroDesktopOnly overrideText='Please use a desktop or laptop computer to view your assessment.' />
        <a href={studentLink} target="_self" className="text-blue-5 hidden font-bold hover:text-purple-5 lg:block">View assessment</a>
      </div>
      <TopicDetailsModal
        assessmentName={latestAssessment?.assessmentName || ''}
        data={modalData}
        modalOpen={modalOpen}
        closeModal={closeModal}
      />
    </div>
  )
}

export interface AssessmentWidgetProps {
  token: string;
  studentLink: string;
}

const AssessmentReportWidget = ({ token, studentLink }: AssessmentWidgetProps) => {
  const { data: accountDetails } = api.account.details.useQuery();
  const { data: assessmentReports, isLoading, isRefetching, isError, refetch } = api.assessment.getReport.useQuery({ assessmentToken: token });
  const data = (assessmentReports || []).filter(report => report.assessmentToken === token);
  const [modalData, setModalData] = useState<TopicModalData | null>(null);
 
  const viewTopicDetails: TopicClickHandler = (topic, unitName) => {
    setModalData([topic, unitName]);
  };
  const closeModal = () => {
    setModalData(null);
  };


  if (isLoading || isRefetching) {
    return (
      <div className="container mx-auto flex flex-grow flex-col items-center justify-center py-36">
        <Loader />
      </div>
    );
  } else if (isError) {
    return (
      <div className="container mx-auto flex flex-grow flex-col items-center justify-center py-36">
        <div>There was an error loading the report.</div>
        <button className="mt-4 flex flex-row items-center" type="button" onClick={() => refetch()}>
            <img
              alt="refresh icon"
              src={refreshIcon} 
              width={16} 
              className="mr-2"
            />
              Try again
          </button>
      </div>
    );
  
  }

  return (
    <AssessmentReportWidgetBase
      data={data}
      studentName={accountDetails?.studentFirstName}
      studentLink={studentLink}
      viewTopicDetails={viewTopicDetails}
      modalOpen={!!modalData}
      modalData={modalData}
      closeModal={closeModal}
    />
  );
}

export default AssessmentReportWidget