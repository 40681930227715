import { Link, LinkButton } from '@cluey/cluey-components';
import { PlusIcon } from '../../../../icons';
import { HubSessionsListItem } from '../../../../components/hub/sessions/HubSessionsListItem';
import { PATH_HUB_UPCOMING_SESSIONS } from '../../../../util/pagePath';
import { first, isNil, sortBy } from 'lodash';
import { api } from '../../../../api';
import { Student } from '../../../../api/types/student';
import { useMemo } from 'react';
import moment from 'moment';
import { ENROLMENT_STATUS } from '../../../../types/hubEnums';
import { Enrolment } from '../../../../api/types/enrolment';
import { Session } from '../../../../api/types/session';

export interface StudentProfileBasePreview {
	student: Student;
	accountDetailsQuery: ReturnType<typeof api.account.details.useQuery>;
	enrolmentsQuery: ReturnType<typeof api.enrolment.all.useQuery>;
	upcomingSessionsQuery: ReturnType<typeof api.session.all.useQuery>;
}

const StudentProfilePreviewBase = ({
	student,
	accountDetailsQuery,
	enrolmentsQuery,
	upcomingSessionsQuery,
}: StudentProfileBasePreview) => {
	const { data: accountDetails } = accountDetailsQuery;
	const { data: studentEnrolments } = enrolmentsQuery;
	const { data: sessions = [] } = upcomingSessionsQuery;

	const everyEnrolmentNextSession = useMemo(() => {
		return studentEnrolments
			?.map((enrolment) => {
				const firstSession = first(enrolment.sessions);
				return sessions.find((sesh) => sesh.studentSessionId === firstSession?.studentSessionId);
			})
			.filter((sesh) => !isNil(sesh));
	}, [studentEnrolments, sessions]);

	return (
		<div className="mt-8 bg-white rounded-lg px-8 py-6">
			<div className="flex flex-col items-start justify-between md:flex-row md:items-center">
				<div>
					<Link className="text-xs font-bold uppercase" to="/">
						home
					</Link>
					<h1 className="font-display text-[28px] font-bold">
						{student?.firstName} {student?.lastName}
					</h1>
				</div>
				<LinkButton to="/" appearance="reverse" className="mt-8 flex items-center justify-between gap-2 md:mt-0 !font-bold">
					<PlusIcon width={14} height={14} />
					<span className="uppercase">Add a student</span>
				</LinkButton>
			</div>
			<h2 className="text-xl font-bold">Sessions</h2>
			<div className="mt-4 flex flex-col gap-y-4 rounded-lg border border-grey-3 bg-white px-4 py-4 shadow-md md:px-20 md:py-14">
				<h2 className="text-xs font-bold uppercase">Upcoming sessions</h2>

				<div className="flex flex-col items-start justify-between text-sm md:flex-row md:items-center">
					<span>Times are in your account timezone ({accountDetails?.timezone})</span>
					<Link className="mt-2.5 text-base md:mt-0" to={PATH_HUB_UPCOMING_SESSIONS}>
						View all sessions
					</Link>
				</div>
				<div className="pointer-events-none flex flex-col gap-y-4">
					{everyEnrolmentNextSession?.length > 0 ? (
						everyEnrolmentNextSession.map((session) => (
							<HubSessionsListItem
								isPreview
								key={session.studentSessionId}
								session={session}
								timezone={accountDetails.timezone}
							/>
						))
					) : (
						<div className="flex flex-col items-start justify-between rounded-lg border border-dashed border-slate-3 px-7 py-5 md:flex-row md:items-center">
							<div>
								<span className="text-base font-bold">{student?.firstName}</span>
								<span> &nbsp;|&nbsp; No active enrolment</span>
							</div>
							<button className="text-blue-5" onClick={() => {}}>
								+ Enrol now
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

function roundToNextQuarterHour(momentTime) {
	// Ensure the input is a valid Moment.js object
	if (!moment.isMoment(momentTime)) {
		throw new Error('Input is not a valid Moment.js object');
	}

	// Add minutes so the time is rounded up to the next 15-minute interval
	let minutesToAdd = 15 - (momentTime.minutes() % 15);

	// If the time is exactly on a quarter hour, don't add anything
	if (minutesToAdd === 15) {
		minutesToAdd = 0;
	}

	// Add the minutes
	momentTime.add(minutesToAdd, 'minutes');

	// Return the moment object for further manipulation, if needed
	return momentTime;
}

const StudentProfilePreview = () => {
	const accountDetailsQuery = api.account.details.useQuery(undefined, {
		enabled: false,
		placeholderData: { timezone: 'Australia/Sydney' },
	});

	const today = moment().tz(accountDetailsQuery.data.timezone);
	const mockUpcomingSessionStart1 = moment(roundToNextQuarterHour(today.clone()));
	const mockUpcomingSessionEnd1 = mockUpcomingSessionStart1.clone().add(1, 'hour');
	const mockUpcomingSessions: Session[] = [
		{
			automatedTutorChange: true,
			cancellationDateTime: null,
			discountCode: '',
			discountPercentage: 20,
			enrolmentType: '',
			invoiceId: 'invoiceId1',
			invoiceName: 'INVOICENAME1',
			endTime: mockUpcomingSessionEnd1.toISOString(),
			initiatedBy: null,
			isBundled: false,
			nextPaymentDate: '2023-01-06T06:00:00.000Z',
			paymentDate: null,
			paymentStatus: 'Paid',
			paymentType: 'PrePaid',
			recordingLink: null,
			rescheduledWithinDeadline: false,
			sessionReportId: 'sessionReportId1',
			sessionToken: 'Not Available',
			sessionsInBundle: 0,
			sessionDiscountActive: true,
			sessionDiscountName: '',
			sessionDiscountPercentage: 20,
			sessionDiscountStatus: 'Active',
			startTime: mockUpcomingSessionStart1.toISOString(),
			statusCode: '',
			student: 'Kerri',
			studentSessionId: 'studentSessionId1',
			subject: 'Mathematics',
			tutor: 'Tia',
			tutorNumber: 'T1234567890',
			tutorSessionId: 'tutorSessionId1',
			tutorPhoto: '',
			type: 'Course',
			uniqueEnrolmentId: 'uniqueEnrolmentId1',
			tutorRescheduleRequestStatusC: null,
			tutorSfId: 'tutorId1',
			packageTutorSfid: 'tutorId1',
		},
	];
	const mockEnrolments: Enrolment[] = [
		{
			cancelReason: null,
			costPerSession: 0,
			courseName: 'Mathematics',
			curriculum: 'AU',
			customerNumber: 'S1234567890',
			englishTextStudied: null,
			endTime: null,
			enrolmentCancelRequestedDate: null,
			enrolmentEndDate: null,
			enrolmentId: 'uniqueEnrolmentId1',
			enrolmentPausedFrom: null,
			enrolmentPausedTo: null,
			enrolmentSfid: 'enrolmentId1',
			enrolmentStartDate: '2023-07-19T00:00:00.000Z',
			enrolmentStatus: 'New Customer' as ENROLMENT_STATUS,
			lastname: 'Reynolds',
			packageMasterCode: 'MathsAU005',
			packageSfid: 'packageId1',
			packageTutorSfid: 'tutorId1',
			packageType: null,
			schedule: [],
			sessions: mockUpcomingSessions,
			sessionCount: '0',
			sessionFrequency: 'Weekly',
			sessionReportId: null,
			startTime: null,
			student: 'Kerri',
			studentInformation: '',
			studentSfid: 'studentId1',
			subject: 'Mathematics',
			tutor: 'Tia',
			tutorPhoto: '',
			tutorNumber: 'T1234567890',
			tutorSfId: 'tutorId1',
			type: 'Course',
			yearAsInteger: 5,
			yearLevel: 'Year 5',
		},
	];
	const enrolmentsQuery = api.enrolment.all.useQuery('active', {
		queryKey: ['enrolment', 'all', 'preview'],
		enabled: false,
		placeholderData: mockEnrolments,
	});

	const student: Student = {
		studentId: 'studentId1',
		customerNumber: 'S1234567890',
		firstName: 'Kerri',
		lastName: 'Reynolds',
		studentCountry: 'Australia',
		location: 'AU',
		yearLevel: 'Year 5',
		schoolName: 'Test',
		schoolId: 'schoolId1',
		learningNeeds: null,
		sessionsCompleted: '2',
		availableSubjects: ['Mathematics'],
		enrolments: {
			mathematics: [
				{
					sfId: 'packageId1',
					uniqueEnrolmentId: 'uniqueEnrolmentId1',
				},
			],
		},
		createdDate: '2023-01-12T00:00:00.000Z',
	};

	const upcomingSessionsQuery = api.session.all.useQuery('upcoming', {
		queryKey: ['session', 'all', 'preview'],
		enabled: false,
		placeholderData: mockUpcomingSessions,
		select: (data) => sortBy(data, ['startTime'], ['asc']),
	});

	return (
		<StudentProfilePreviewBase
			student={student}
			accountDetailsQuery={accountDetailsQuery}
			enrolmentsQuery={enrolmentsQuery}
			upcomingSessionsQuery={upcomingSessionsQuery}
		/>
	);
};

export default StudentProfilePreview;
