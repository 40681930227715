import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sortBy, uniqBy } from 'lodash';
import moment from 'moment';
import { AnchorButton } from '@cluey/cluey-components';
import { getStudents, getSchoolTerm } from '../selectors/upcomingSessionsSelector';

// import SessionsFilterCheckbox from './SessionsList/SessionsFilterCheckbox';
import SessionsListItem from './SessionsList/SessionsListItem';
import ListItemSchoolTerm from './SessionsList/ListItemSchoolTerm';
import { PATH_HUB_UPCOMING_SESSIONS } from '../util/pagePath';

const TERMITEM_TYPE = 'TermItem';

const getStudentsName = (studs) => {
  const uniqueStudents = uniqBy(studs, 'name');

  const isSingleStudent = uniqueStudents.length === 1;
  const uniqueStudentsNames = uniqueStudents.map((student) => {
    return student.name;
  });

  return {
    isSingleStudent,
    uniqueStudentsNames,
  };
};

const getChangeSessionCTALink = (students, uniqueEnrolmentId) => {
  let { sessionManagementLink } = students.find((s) => s.uniqueEnrolmentId === uniqueEnrolmentId) || {};
  // add this to replace '/main' in the link returned by server. Timo will fix this link later so we can remove this
  if (sessionManagementLink !== undefined) {
    sessionManagementLink = sessionManagementLink.replace('/main', '/');
  }
  return sessionManagementLink;
};

const SessionsList = ({ title, sessions, isTodaySection, students, schoolTerms, isNZUser }) => {
  const { isSingleStudent } = getStudentsName(students);

  let termItems = [];
  if (!isTodaySection && schoolTerms.length > 0) {
    termItems = schoolTerms
      .map((st) => {
        return [
          {
            type: TERMITEM_TYPE,
            startTime: st.startDate,
            state: st.state,
            schoolTerm: st.schoolTerm,
            isTermStartDate: true,
          },
          {
            type: TERMITEM_TYPE,
            startTime: st.endDate,
            state: st.state,
            schoolTerm: st.schoolTerm,
            isTermStartDate: false,
          },
        ];
      })
      .flat()
      .filter((item) => {
        return (
          moment(item.startTime).diff(moment(), 'days') <= 28 && moment(item.startTime).diff(moment(), 'days') >= 0
        );
      });
  }

  const mixedSessionsAndTerms = [...sessions, ...termItems];

  const sortedMixedSessionsAndTerms = sortBy(mixedSessionsAndTerms, ['startTime', 'student', 'subject']);

  const showViewAll = !isNZUser && !isTodaySection;

  return (
    <ul className="list-group list-sessions pb-10">
      <li className="list-group-item heading px-6 py-5">
        <div className="inner md:flex">
          <h3 className="title md:m-0">{title}</h3>
          {/* hide students filters for now */}
          {/* {!isTodaySection && uniqueStudentsNames.length > 1 && (
            <div className="d-flex filters">
              <span className="mr-4 text">Show sessions for:</span>
              {uniqueStudentsNames.map(name => (
                <SessionsFilterCheckbox
                  key={name}
                  studentName={name}
                />
              ))}
            </div>
          )} */}
        </div>
      </li>
      {sortedMixedSessionsAndTerms.length > 0 &&
        sortedMixedSessionsAndTerms.map((sessionOrTerm) => {
          const { type, startTime, uniqueEnrolmentId } = sessionOrTerm;

          if (type === TERMITEM_TYPE) {
            return <ListItemSchoolTerm key={startTime} termItem={sessionOrTerm} />;
          }
          const changeSessionCTA = getChangeSessionCTALink(students, uniqueEnrolmentId);

          return (
            <SessionsListItem
              key={uniqueEnrolmentId + startTime}
              isSingleStudent={isSingleStudent}
              isTodaySession={isTodaySection}
              session={sessionOrTerm}
              changeSessionCTA={changeSessionCTA}
              isNZUser={isNZUser}
            />
          );
        })}
      {showViewAll && (
        <li className="list-group-item bg-custom-1 px-7 py-5 text-center">
          <AnchorButton
            href={PATH_HUB_UPCOMING_SESSIONS}
            size="xs"
            className="btn-arrow-right relative block w-full uppercase no-underline lg:inline-block lg:w-[350px]"
          >
            View all upcoming sessions
          </AnchorButton>
        </li>
      )}
    </ul>
  );
};

SessionsList.propTypes = {
  title: PropTypes.string.isRequired,
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      student: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      uniqueEnrolmentId: PropTypes.string,
      subject: PropTypes.string,
      tutor: PropTypes.string,
      type: PropTypes.string,
      sessionToken: PropTypes.string,
    })
  ),
  students: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  schoolTerms: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      state: PropTypes.string,
      schoolTerm: PropTypes.string,
    })
  ),
  // filteredStudentsNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  isTodaySection: PropTypes.bool,
  isNZUser: PropTypes.bool,
};

SessionsList.defaultProps = {
  sessions: [],
  students: [],
  schoolTerms: [],
  isTodaySection: false,
  isNZUser: false,
};

export default connect((state) => {
  return {
    students: getStudents(state),
    schoolTerms: getSchoolTerm(state),
  };
}, null)(SessionsList);

export { SessionsList as PresentationalSessionsList };
