import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Enrolment } from '../../../api/types/enrolment';
import InPageLoader from '../../../common/InPageLoader';
import noEnrolmentIcon from '../../../assets/images/atoms-icon-filled-tutor-2.svg';
import { PATH_HUB_MS_STUDENTS_AND_SUBJECTS } from '../../../util/pagePath';
import EnrolmentCard from './EnrolmentCard/EnrolmentCard';
import { api } from '../../../api';

export const EmptyActiveEnrolments = () => {
	return (
		<div className="flex flex-row items-center">
			<img src={noEnrolmentIcon} alt="no enrolment icon" />
			<span className="ml-2">You have no active enrolments. </span>
			<span className="ml-2">
				<Link to={PATH_HUB_MS_STUDENTS_AND_SUBJECTS} className="text-blue-5 hover:text-purple-6 hover:underline">
					Add students or subjects.
				</Link>
			</span>
		</div>
	);
};

export interface EnrolmentListProps {
	enrolments: Enrolment[];
	title: string;
	renderWhenEmpty?: ReactNode;
}

const EnrolmentList = (props: EnrolmentListProps) => {
	const { enrolments, title, renderWhenEmpty = null } = props;
	// @ts-ignore
	const timezone = useSelector((state) => state.hubUser.timezone);

	const renderEnrolments =
		enrolments.length > 0
			? enrolments.map((enrolment, index) => (
					<EnrolmentCard key={`${enrolment.enrolmentId}-${index}`} enrolment={enrolment} timezone={timezone} />
			  ))
			: renderWhenEmpty;

	return (
		<div className="mb-7">
			<h3 className="mb-5 font-display text-xl font-bold text-black lg:mb-4">{title}</h3>
			{renderEnrolments}
		</div>
	);
};

export interface EnrolmentListContainerProps extends Omit<EnrolmentListProps, 'enrolments'> {
	filter: 'active' | 'past';
}

const EnrolmentListContainer = (props: EnrolmentListContainerProps) => {
	const { filter = 'active' } = props;

	const { isLoading, data: enrolments } = api.enrolment.all.useQuery(filter);

	if (isLoading) {
		return <InPageLoader position="relative" />;
	}

	return <EnrolmentList {...props} enrolments={enrolments || []} />;
};

export default EnrolmentListContainer;
