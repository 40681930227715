import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LinkButton } from '@cluey/cluey-components';

import withSelectedEnrolment from '../../../common/hub/NewWithSelectedEnrolment';
import { useTextAreaProps, TextArea } from '../../../components/TextArea/TextArea';
import { submitCaseToSF } from '../../../actions/hub/hubContactPageActions';
import { RestrictedButton } from '../../../components/RestrictedButton/RestrictedButton';
import ErrorBlock from '../../../common/ErrorBlock';
import { PATH_HUB_ENROLMENT_DETAIL, PATH_HUB_CR_CHANGE_CONTENT_FOCUS } from '../../../util/pagePath';
import EnrolmentLayout from '../../../common/hub/EnrolmentLayout';

const formName = 'contact-form-change-content-focus';

const ViewTypes = {
	Default: 'default',
	Success: 'success',
};

const ErrorTypes = {
	TextAreaMessage: 'textAreaMessage',
};

const FormChangeContentFocus = ({ selectedEnrolment, isSubmitting, submitError, errorMsg, submit }) => {
	const [view, setView] = useState(ViewTypes.Default);
	const [errors, setErrors] = useState([]);

	const textAreaProps = useTextAreaProps({
		id: `${formName}-textarea`,
		labelText: 'Message',
		placeholder: "Give us some detail on how you'd like to focus learning.",
	});

	const textAreaIsEmpty = () => !textAreaProps.value || !textAreaProps.value.replace(/\s/g, '').length;

	const formIsValid = () => {
		if (textAreaIsEmpty()) {
			return false;
		}

		return true;
	};

	const { enrolmentId, student, subject } = selectedEnrolment;

	// Clear error message if form is valid
	if (formIsValid() && errors.length) {
		setErrors([]);
	}

	const submitPayloadToSF = () => {
		const uniqueEnrolmentId = enrolmentId;
		const caseType = 'Support';
		const caseCategory = 'Support request';
		const caseSubCategory = 'Content request/support for student';
		const caseRequestFrom = 'Parent';
		const caseSubject = 'Content focus request';
		const message = textAreaProps.value;

		return submit({
			uniqueEnrolmentId,
			message,
			caseSubject,
			caseType,
			caseCategory,
			caseSubCategory,
			caseRequestFrom,
			onSubmitSuccess: () => {
				setView(ViewTypes.Success);
			},
		});
	};

	const onRequestContentFocusClickHandler = () => {
		if (!formIsValid()) {
			return setErrors([ErrorTypes.TextAreaMessage]);
		}

		return submitPayloadToSF();
	};

	const renderDefaultView = () => {
		return (
			<div className="container mx-auto px-4">
				<div className="flex flex-col">
					{submitError && errorMsg && (
						<div className="w-full lg:w-2/3">
							<ErrorBlock errorMsg={errorMsg} />
						</div>
					)}
					<div className="mb-8 w-full lg:w-2/3">
						<p className="mb-2 text-xl">
							Let&apos;s make sure {student}&apos;s {subject} sessions are focused on the right content, concepts and
							topics.
						</p>
						<p>
							Note: If you'd like to provide us with specific material or content, you can upload this during a session.
						</p>
					</div>
					<div className="flex w-full flex-col lg:w-1/2">
						<h4 className="mb-5 text-xl font-bold">What would you like us to focus on?</h4>
						<TextArea {...textAreaProps} className="mb-1" />
						{errors.includes(ErrorTypes.TextAreaMessage) && (
							<span className="my-1 text-xs text-red-5">Please enter a message</span>
						)}
					</div>
					<div className="mt-10 w-full">
						<div className="mb-4 tracking-normal lg:mb-8">
							<RestrictedButton
								buttonText="Request content focus"
								className="w-full md:w-auto"
								truthyChecks={[!isSubmitting]}
								onClick={() => {
									onRequestContentFocusClickHandler();
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const renderSuccessView = () => {
		return (
			<div className="container mx-auto px-4">
				<div className="flex flex-col">
					<div className="w-full lg:w-2/3">
						<p className="mb-8 text-xl">We will be in touch to discuss your request and set up your changes.</p>
					</div>
					<div className="mt-10 w-full">
						<div className="mb-4 tracking-normal lg:mb-8">
							<LinkButton
								to={`${PATH_HUB_ENROLMENT_DETAIL}?token=${enrolmentId}`}
								size="xs"
								appearance="reverse"
								className="font-bold uppercase"
							>
								View {student}&apos;s {subject} enrolment
							</LinkButton>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const renderEnrolmentLayoutChild = () => {
		switch (view) {
			case ViewTypes.Default:
				return renderDefaultView();
			case ViewTypes.Success:
				return renderSuccessView();
			default:
				return null;
		}
	};

	return (
		<EnrolmentLayout
			sectionHeading={`${student} ${subject}`}
			mainHeading="Content focus request"
			enrolmentId={enrolmentId}
		>
			{renderEnrolmentLayoutChild()}
		</EnrolmentLayout>
	);
};

FormChangeContentFocus.propTypes = {
	selectedEnrolment: PropTypes.shape({
		enrolmentId: PropTypes.string.isRequired,
		student: PropTypes.string.isRequired,
		subject: PropTypes.string.isRequired,
	}).isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	submitError: PropTypes.bool.isRequired,
	errorMsg: PropTypes.string.isRequired,
	submit: PropTypes.func.isRequired,
};

export default connect(
	(state) => {
		return {
			isSubmitting: state.ui.apiState.hubContactPage.isLoading,
			submitError: state.ui.apiState.hubContactPage.fetchError,
			errorMsg: state.ui.apiState.hubContactPage.errorMsg,
		};
	},
	(dispatch) => {
		return {
			submit: bindActionCreators(submitCaseToSF, dispatch),
		};
	}
)(withSelectedEnrolment(FormChangeContentFocus, null, PATH_HUB_CR_CHANGE_CONTENT_FOCUS));

export { FormChangeContentFocus as StatelessFormChangeContentFocus };
