import classnames from 'classnames';
import moment from 'moment';
import arrowRight from '../../assets/images/icon-filled-arrow-right-no-stem.svg';
import { InfoBox } from '../../components/hub/home/InfoBox';

import {
  CARD_TYPE_SESSION,
  CARD_TYPE_PRACTICE,
  CARD_TYPE_FEEDBACK,
  CARD_TYPE_TEST_SIMULATION,
  SF_TAG_STUDENT_RATING_FEEDBACK,
  SF_TAG_STUDENT_EMOJI_FEEDBACK,
  SF_TAG_TUTOR_RECOMMENDATIONS,
  SF_TAG_TUTOR_FEEDBACK,
  SF_TAG_STUDENT_FEEDBACK,
  SF_TAG_TUTOR_COMING_UP_NEXT,
  CARD_TYPE_GROUP,
  CARD_TYPE_STRAND,
  SF_TAG_TUTOR_ENGLISH_STRAND_1,
  SF_TAG_TUTOR_ENGLISH_STRAND_2,
  SF_TAG_TUTOR_ENGLISH_STRAND_3,
  SF_TAG_TUTOR_MATHS_STRAND_1,
  SF_TAG_TUTOR_MATHS_STRAND_2,
  SF_TAG_TUTOR_MATHS_STRAND_3,
  SF_TAG_STUDENT_ENGLISH_STRAND_1,
  SF_TAG_STUDENT_ENGLISH_STRAND_2,
  SF_TAG_STUDENT_ENGLISH_STRAND_3,
  SF_TAG_STUDENT_MATHS_STRAND_1,
  SF_TAG_STUDENT_MATHS_STRAND_2,
  SF_TAG_STUDENT_MATHS_STRAND_3,
  SUBJECT_ENGLISH,
  SUBJECT_MATHS,
  CARD_TYPE_KICK_OFF,
  SF_TAG_KICK_OFF_MATHS,
  SF_TAG_KICK_OFF_ENGLISH_STRAND_1,
  SF_TAG_KICK_OFF_ENGLISH_STRAND_2,
  SF_TAG_KICK_OFF_ENGLISH_STRAND_3,
  STRAND_SECTION_HEADER_KICKOFF_KEY,
  SF_TAG_TUTOR_RECOMMENDATIONS_GROUP_NAPLAN,
} from '../../util/constants';
import { CardItem } from '../../common/CardItem';
import { ShowMore } from '../../common/ShowMore';
import { TutorFeedback } from '../activityCards/TutorFeedback';
import { StudentFeedback } from '../activityCards/StudentFeedback.js';
import { TutorRecommendations } from '../activityCards/TutorRecommendations';
import { SessionGoals } from '../activityCards/SessionGoals';
import { FeedbackBox } from '../activityCards/FeedbackBox';
import { PracticeTSCard } from '../activityCards/PracticeTSCard';
import { TutorWhatsNext } from '../activityCards/TutorWhatsNext';
import { StrandCard } from '../activityCards/StrandCard';
import { GenericTextSection } from '../activityCards/GenericTextSection';
import { ConnectedSessionRecording as SessionRecording } from '../activityCards/VideoBox';
import { useState } from 'react';
import useWindowWidth from '../../hooks/common/useWindowWidth';
import { BREAKPOINTS } from '../../util/constants';
import { useSelector } from 'react-redux';
import { InitialState } from '../../types/hubTypes';

interface Props {
  header?: string;
  activityItem?: any;
  expandedByDefault?: boolean;
}

const ActivityItem = (props: Props) => {
  const windowWidth = useWindowWidth();
  const studentName = useSelector((state: InitialState) => state.report.studentName);
  const subject = useSelector((state: InitialState) => state.report.subject);
  const [openEmojiModalOpened, setOpenEmojiModal] = useState(false);

  const getSplitFeedback = (salesforceFeedback) => {
    if (!salesforceFeedback || !Array.isArray(salesforceFeedback)) {
      return {};
    }
    return {
      studentEmoji: salesforceFeedback.find(f => f.salesforceTagName === SF_TAG_STUDENT_EMOJI_FEEDBACK),
      studentRating: salesforceFeedback.find(f => f.salesforceTagName === SF_TAG_STUDENT_RATING_FEEDBACK),
      studentFeedback: salesforceFeedback.find(f => f.salesforceTagName === SF_TAG_STUDENT_FEEDBACK),
      tutorFeedback: salesforceFeedback.find(f => f.salesforceTagName === SF_TAG_TUTOR_FEEDBACK),
      tutorWhatsNext: salesforceFeedback.find(f => f.salesforceTagName === SF_TAG_TUTOR_COMING_UP_NEXT),
      tutorRecommendations: salesforceFeedback.find(f => f.salesforceTagName === SF_TAG_TUTOR_RECOMMENDATIONS),
      // eslint-disable-next-line
      tutorRecommendationsGroupNAPLAN: salesforceFeedback.find(f => f.salesforceTagName === SF_TAG_TUTOR_RECOMMENDATIONS_GROUP_NAPLAN),
      tutorEnglishStrand1: salesforceFeedback.find(f => f.salesforceTagName === SF_TAG_TUTOR_ENGLISH_STRAND_1),
      tutorEnglishStrand2: salesforceFeedback.find(f => f.salesforceTagName === SF_TAG_TUTOR_ENGLISH_STRAND_2),
      tutorEnglishStrand3: salesforceFeedback.find(f => f.salesforceTagName === SF_TAG_TUTOR_ENGLISH_STRAND_3),
      tutorMathsStrand1: salesforceFeedback.find(f => f.salesforceTagName === SF_TAG_TUTOR_MATHS_STRAND_1),
      tutorMathsStrand2: salesforceFeedback.find(f => f.salesforceTagName === SF_TAG_TUTOR_MATHS_STRAND_2),
      tutorMathsStrand3: salesforceFeedback.find(f => f.salesforceTagName === SF_TAG_TUTOR_MATHS_STRAND_3),
      studentEnglishStrand1: salesforceFeedback.find(f => f.salesforceTagName === SF_TAG_STUDENT_ENGLISH_STRAND_1),
      studentEnglishStrand2: salesforceFeedback.find(f => f.salesforceTagName === SF_TAG_STUDENT_ENGLISH_STRAND_2),
      studentEnglishStrand3: salesforceFeedback.find(f => f.salesforceTagName === SF_TAG_STUDENT_ENGLISH_STRAND_3),
      studentMathsStrand1: salesforceFeedback.find(f => f.salesforceTagName === SF_TAG_STUDENT_MATHS_STRAND_1),
      studentMathsStrand2: salesforceFeedback.find(f => f.salesforceTagName === SF_TAG_STUDENT_MATHS_STRAND_2),
      studentMathsStrand3: salesforceFeedback.find(f => f.salesforceTagName === SF_TAG_STUDENT_MATHS_STRAND_3),
      kickoffOutcomeMaths: salesforceFeedback.find(f => f.salesforceTagName === SF_TAG_KICK_OFF_MATHS),
      kickoffOutcomeEnglishStrand1: salesforceFeedback.find(f => f.salesforceTagName === SF_TAG_KICK_OFF_ENGLISH_STRAND_1),
      kickoffOutcomeEnglishStrand2: salesforceFeedback.find(f => f.salesforceTagName === SF_TAG_KICK_OFF_ENGLISH_STRAND_2),
      kickoffOutcomeEnglishStrand3: salesforceFeedback.find(f => f.salesforceTagName === SF_TAG_KICK_OFF_ENGLISH_STRAND_3),
    };
  }

  const closeEmojiModal = () => setOpenEmojiModal(false);
  const openEmojiModal = () => setOpenEmojiModal(true);

  const { header } = props;
  const { type, title, programName: originalProgramName, programChange } = props.activityItem;
  const hasProgramChange = (programChange?.last_plan_id 
    && programChange?.first_plan_id) && (programChange?.last_plan_id !== programChange?.first_plan_id);
  const programChangeFeedbackMessage = programChange?.change_feedback?.[0]?.response;
  const newProgramName = hasProgramChange ? programChange.last_plan_name : originalProgramName;
  const programName = hasProgramChange ? 
    <span className="flex flex-row">
      <div className="px-1">
        {programChange.first_plan_name}
      </div>
      <img className="px-1 py-1" src={arrowRight} alt="program change arrow" />
      <div className="px-1">
        {programChange.last_plan_name}
      </div>
    </span>
  : originalProgramName;

  const renderProgramChangeFeedback = hasProgramChange ? (
    <InfoBox
      type="starred"
      title={`Program changed to ${newProgramName}`}
      bodyContainerClasses="w-full"
      body={
        <>
          <p className="mt-1">{`This program change is better suited to ${studentName}'s goals. `}</p>
          {programChangeFeedbackMessage && (
            <div className="rounded bg-grey-1 border-2 p-5 my-4" style={{ borderColor: 'rgb(255, 200, 23)', minWidth: 0 }}>
              <p className="font-bold mb-2">Here's why we made the change:</p>
              <p>{programChangeFeedbackMessage}</p>
            </div>
          )}
        </>
      }
      sameTab
    />
  ) : null;

  switch (type) {
    case CARD_TYPE_KICK_OFF: {
      const { expandedByDefault } = props;
      const {
        tutorName,
        feedback,
        sessionId,
        startTime,
        endTime,
        goals,
        videoUrl,
        studentSessionId,
      } = props.activityItem;
      const {
        studentEmoji,
        studentFeedback,
        tutorFeedback,
        tutorWhatsNext,
        kickoffOutcomeMaths,
        kickoffOutcomeEnglishStrand1,
        kickoffOutcomeEnglishStrand2,
        kickoffOutcomeEnglishStrand3,
      } = getSplitFeedback(feedback);
      const sb = subject.toLowerCase();
      switch (sb) {
        case SUBJECT_ENGLISH:
          return (
            <CardItem sessionTitle={programName} programChangeFeedback={renderProgramChangeFeedback} id={sessionId} title={title} headerMsg={header} trackingClass="ga-timeline-item">
              <div>- Tutor: {tutorName}</div>
              <div>- Session duration: {moment(endTime)
                .diff(moment(startTime), 'minutes')} minutes
              </div>
              <ShowMore forceExpand={expandedByDefault}>
                <div className="pb-3">
                  <SessionGoals tutorName={tutorName} studentName={studentName} goals={goals || []} />
                </div>
                <TutorFeedback tutorName={tutorName} feedbackText={tutorFeedback && tutorFeedback.response} />
                <StrandCard
                  subject={sb}
                  tutorFeedback1={kickoffOutcomeEnglishStrand1 && kickoffOutcomeEnglishStrand1.response}
                  tutorFeedback2={kickoffOutcomeEnglishStrand2 && kickoffOutcomeEnglishStrand2.response}
                  tutorFeedback3={kickoffOutcomeEnglishStrand3 && kickoffOutcomeEnglishStrand3.response}
                  studentName={studentName}
                  hideStudentSection
                  sectionHeadersKey={STRAND_SECTION_HEADER_KICKOFF_KEY}
                  title={`WHERE ${studentName.toUpperCase()} IS AT NOW`}
                />

                <GenericTextSection title="WHAT'S NEXT" text={tutorWhatsNext && tutorWhatsNext.response} />
                <hr className="w-full mt-0" />
                <div
                  className={classnames('pb-3', 'md:flex', (windowWidth <= BREAKPOINTS.xs) ? 'flex flex-col items-left' : '')}>
                  <StudentFeedback
                    studentName={studentName}
                    isEmoji={!!studentEmoji}
                    feedbackText={studentFeedback && studentFeedback.response}
                    rating={studentEmoji && studentEmoji.response}
                    openEmojiModal={openEmojiModal}
                    isEmojiModalOpen={openEmojiModalOpened}
                    closeEmojiModal={closeEmojiModal}
                    showStudentNoRatingMessage={false}
                  />
                </div>
                <SessionRecording
                  sessionId={studentSessionId}
                  videoUrl={videoUrl}
                />
              </ShowMore>
            </CardItem>
          );
        case SUBJECT_MATHS:
          return (
            <CardItem sessionTitle={programName} programChangeFeedback={renderProgramChangeFeedback} id={sessionId} title={title} headerMsg={header} trackingClass="ga-timeline-item">
              <div>- Tutor: {tutorName}</div>
              <div>- Session duration: {moment(endTime)
                .diff(moment(startTime), 'minutes')} minutes
              </div>
              <ShowMore forceExpand={expandedByDefault}>
                <div className="pb-3">
                  <SessionGoals tutorName={tutorName} studentName={studentName} goals={goals || []} />
                </div>
                <TutorFeedback tutorName={tutorName} feedbackText={tutorFeedback && tutorFeedback.response} />
                <GenericTextSection text={kickoffOutcomeMaths && kickoffOutcomeMaths.response} title={`WHERE ${studentName.toUpperCase()} IS AT NOW`} />
                <GenericTextSection title="WHAT'S NEXT" text={tutorWhatsNext && tutorWhatsNext.response} />
                <hr className="w-full mt-0" />
                <div
                  className={classnames('pb-3', 'md:flex', (windowWidth <= BREAKPOINTS.xs) ? 'flex flex-col items-left' : '')}>
                  <StudentFeedback
                    studentName={studentName}
                    isEmoji={!!studentEmoji}
                    feedbackText={studentFeedback && studentFeedback.response}
                    rating={studentEmoji && studentEmoji.response}
                    openEmojiModal={openEmojiModal}
                    isEmojiModalOpen={openEmojiModalOpened}
                    closeEmojiModal={closeEmojiModal}
                    showStudentNoRatingMessage={false}
                  />
                </div>
                <SessionRecording
                  sessionId={studentSessionId}
                  videoUrl={videoUrl}
                />
              </ShowMore>
            </CardItem>
          );
        default:
          return null;
      }
    }
    case CARD_TYPE_STRAND: {
      const { expandedByDefault } = props;
      const {
        tutorName,
        // stats,
        feedback,
        sessionId,
      } = props.activityItem;
      let strandFb: {
        [key: string]: {
          [key: string]: string;
        }
      } = {};
      const {
        studentEmoji,
        studentFeedback,
        tutorFeedback,
        tutorRecommendations,
        tutorEnglishStrand1,
        tutorEnglishStrand2,
        tutorEnglishStrand3,
        tutorMathsStrand1,
        tutorMathsStrand2,
        tutorMathsStrand3,
        studentEnglishStrand1,
        studentEnglishStrand2,
        studentEnglishStrand3,
        studentMathsStrand1,
        studentMathsStrand2,
        studentMathsStrand3,
      } = getSplitFeedback(feedback);
      const sb = subject.toLowerCase();
      switch (sb) {
        case SUBJECT_ENGLISH:
          strandFb = {
            t1: tutorEnglishStrand1,
            t2: tutorEnglishStrand2,
            t3: tutorEnglishStrand3,
            s1: studentEnglishStrand1,
            s2: studentEnglishStrand2,
            s3: studentEnglishStrand3,
          };
          break;
        case SUBJECT_MATHS:
          strandFb = {
            t1: tutorMathsStrand1,
            t2: tutorMathsStrand2,
            t3: tutorMathsStrand3,
            s1: studentMathsStrand1,
            s2: studentMathsStrand2,
            s3: studentMathsStrand3,
          };
          break;
        default:
          break;
      }
      return (
        <CardItem sessionTitle={programName} programChangeFeedback={renderProgramChangeFeedback} id={sessionId} title={title} headerMsg={header} trackingClass="ga-timeline-item">
          <div>- Tutor: {tutorName}</div>
          { /* stats.pastSessionCount is wrongly calculated in the backend https://clueylearning.atlassian.net/browse/LN-1810 */}
          {/* <div>- Sessions completed: {stats.pastSessionsCount || 0}</div> */}
          {/* <div>- Practice questions completed: {stats.questionsInPractice || 0}</div> */}
          <ShowMore forceExpand={expandedByDefault}>
            <TutorFeedback tutorName={tutorName} feedbackText={tutorFeedback && tutorFeedback.response} />
            <StrandCard
              subject={sb}
              studentFeedback1={strandFb.s1 && strandFb.s1.response}
              studentFeedback2={strandFb.s2 && strandFb.s2.response}
              studentFeedback3={strandFb.s3 && strandFb.s3.response}
              tutorFeedback1={strandFb.t1 && strandFb.t1.response}
              tutorFeedback2={strandFb.t2 && strandFb.t2.response}
              tutorFeedback3={strandFb.t3 && strandFb.t3.response}
              studentName={studentName}
            />
            <hr className="mt-0 w-100" />
            <div className={classnames('pb-3', 'md:flex', (windowWidth <= BREAKPOINTS.xs) ? 'flex flex-col items-left' : '')}>
              <StudentFeedback
                studentName={studentName}
                isEmoji={!!studentEmoji}
                feedbackText={studentFeedback && studentFeedback.response}
                rating={studentEmoji && studentEmoji.response}
                openEmojiModal={openEmojiModal}
                isEmojiModalOpen={openEmojiModalOpened}
                closeEmojiModal={closeEmojiModal}
                showStudentNoRatingMessage={false}
              />
            </div>
            <TutorRecommendations tutorName={tutorName} text={tutorRecommendations && tutorRecommendations.response} />
          </ShowMore>
        </CardItem>
      );
    }
    case CARD_TYPE_SESSION:
    case CARD_TYPE_GROUP:
    {
      const { expandedByDefault } = props;
      const {
        tutorName, startTime, endTime, feedback, goals, sessionId, videoUrl, studentSessionId,
      } = props.activityItem;
      const {
        studentEmoji,
        studentRating,
        studentFeedback,
        tutorFeedback,
        tutorWhatsNext,
      } = getSplitFeedback(feedback);
      return (
        <CardItem sessionTitle={programName} programChangeFeedback={renderProgramChangeFeedback} id={sessionId} title={title} headerMsg={header} trackingClass="ga-timeline-item">
          <div>- Tutor: {tutorName}</div>
          <div>- Session duration: {moment(endTime).diff(moment(startTime), 'minutes')} minutes</div>
          <div className="pb-3">
            <SessionGoals tutorName={tutorName} studentName={studentName} goals={goals || []} />
          </div>
          <ShowMore forceExpand={expandedByDefault}>
            <TutorFeedback tutorName={tutorName} feedbackText={tutorFeedback && tutorFeedback.response} />
            <div className={classnames('pb-3', 'md:flex', (windowWidth <= BREAKPOINTS.xs) ? 'flex flex-col items-left' : '')}>
              <StudentFeedback
                studentName={studentName}
                isEmoji={!!studentEmoji}
                feedbackText={studentFeedback && studentFeedback.response}
                rating={studentEmoji ? studentEmoji.response : studentRating && studentRating.response}
                openEmojiModal={openEmojiModal}
                isEmojiModalOpen={openEmojiModalOpened}
                closeEmojiModal={closeEmojiModal}
                showStudentNoRatingMessage={false}
              />
            </div>
            <TutorWhatsNext text={tutorWhatsNext && tutorWhatsNext.response} />
            <SessionRecording
              sessionId={studentSessionId}
              videoUrl={videoUrl}
            />
          </ShowMore>
        </CardItem>
      );
    }
    case CARD_TYPE_TEST_SIMULATION:
    case CARD_TYPE_PRACTICE:
    {
      const {
        questionSubmitted, questionAssigned, numberOfVisits, lastVisited, linkToLP, sessionId, startTime,
      } = props.activityItem;
      return (
        <PracticeTSCard
          isTestSimulation={type === CARD_TYPE_TEST_SIMULATION}
          lastVisited={lastVisited}
          btnLink={linkToLP}
          questionAssigned={questionAssigned}
          questionSubmitted={questionSubmitted}
          name={title}
          numberOfVisits={numberOfVisits}
          header={header}
          id={sessionId}
          startTime={startTime}
        />
      );
    }
    case CARD_TYPE_FEEDBACK:
    {
      const { expandedByDefault } = props;
      const {
        tutorName,
        stats,
        feedback,
        sessionId,
      } = props.activityItem;

      const {
        studentEmoji,
        studentRating,
        studentFeedback,
        tutorFeedback,
        tutorWhatsNext,
        tutorRecommendations,
        tutorRecommendationsGroupNAPLAN,
      } = getSplitFeedback(feedback);
      const tR = tutorRecommendations || tutorRecommendationsGroupNAPLAN;
      return (
        <CardItem sessionTitle={programName} programChangeFeedback={renderProgramChangeFeedback} id={sessionId} title={title} headerMsg={header} trackingClass="ga-timeline-item">
          <div>- Tutor: {tutorName}</div>
          <div>- Sessions completed: {stats.pastSessionsCount || 0}</div>
          <div>- Practice assigned: {stats.pastPracticeCount || 0}</div>
          <div className="pb-3 test-simulation">
            <div className="pt-3 card-subTitle font-bold">TEST SIMULATIONS</div>
            {stats && stats.pastTestSimulations.map((ts) => {
              return (<div key={ts.name}>-&nbsp;{ts.name}</div>);
            })}
            {(!stats || !stats.pastTestSimulations) && <div>No test completed.</div>}
          </div>
          <ShowMore forceExpand={expandedByDefault}>
            <div className="pb-3">
              <div className="pt-3 card-subTitle font-bold">QUESTIONS ATTEMPTED</div>
              <div className="flex flex-wrap">
                <FeedbackBox title="Learning sessions" subTitle={stats && stats.questionsInSession} icon="icon-person" />
                <FeedbackBox title="Practice" subTitle={stats && stats.questionsInPractice} icon="icon-pencil" />
                <FeedbackBox title="Test simulation" subTitle={stats && stats.questionsInTestSimulation} icon="icon-clock" />
              </div>
            </div>
            <TutorFeedback tutorName={tutorName} feedbackText={tutorFeedback && tutorFeedback.response} />
            <TutorRecommendations tutorName={tutorName} text={tR && tR.response} />
            <div className={classnames('pb-3', 'md:flex', (windowWidth <= BREAKPOINTS.xs) ? 'flex flex-col items-left' : '')}>
              <StudentFeedback
                studentName={studentName}
                isEmoji={!!studentEmoji}
                feedbackText={studentFeedback && studentFeedback.response}
                rating={studentEmoji ? studentEmoji.response : studentRating && studentRating.response}
                openEmojiModal={openEmojiModal}
                isEmojiModalOpen={openEmojiModalOpened}
                closeEmojiModal={closeEmojiModal}
              />
            </div>
            <TutorWhatsNext text={tutorWhatsNext && tutorWhatsNext.response} />
          </ShowMore>
        </CardItem>
      );
    }
    default:
      console.error(`error activityType: ${type}`);
      return null;
  }
}

export { ActivityItem };
