import { ENR_SESSION_FREQUENCY } from "./constants/enrolmentConstants";
import { SELECTED_SUMMER_MODE } from "./summerCopy";

const frequencyToWeight = {
    [ENR_SESSION_FREQUENCY.FORTNIGHTLY]: 1,
    [ENR_SESSION_FREQUENCY.WEEKLY]: 2,
    [ENR_SESSION_FREQUENCY.MULTIPLE_SESSIONS]: 3,
}

export const summerModeFromFrequencyChange = (currentFrequency, newFrequency, isGroup) => {
    const currentFrequencyWeight = frequencyToWeight[currentFrequency];
    const newFrequencyWeight = frequencyToWeight[newFrequency];

    if (newFrequencyWeight > currentFrequencyWeight) {
        return SELECTED_SUMMER_MODE.INCREASE;
    } else if (newFrequencyWeight < currentFrequencyWeight) {
        return SELECTED_SUMMER_MODE.DECREASE;
    } else if (newFrequencyWeight === currentFrequencyWeight) {
        return isGroup ? SELECTED_SUMMER_MODE.GROUP : SELECTED_SUMMER_MODE.MAINTAIN;
    }
}