import { getActiveEnrolments } from './hubSelector';
import { isEmpty } from 'lodash';

export const getPkgTutorNameByEnrolmentId = (state, uniqueEnrolmentId) => {
  const enrolments = getActiveEnrolments(state);

  if (isEmpty(enrolments) || isEmpty(uniqueEnrolmentId)) {
    return null;
  }

  const enrolment = enrolments.find((e) => e.enrolmentId === uniqueEnrolmentId);
  return isEmpty(enrolment) ? null : enrolment.tutor;
};
