import classNames from 'classnames';

export interface RadioButtonProps {
	checked: boolean;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	name: string;
	value: string;
	id: string;
	label: string;
	isError?: boolean;
	disabled?: boolean;
}

const RadioButton = ({ checked, onChange, value, name, id, label, isError, disabled }: RadioButtonProps) => {
	return (
		<div className="flex flex-row items-center gap-x-3">
			<div className={classNames('box-content h-8 w-8 rounded-full', isError && 'border border-red-5 bg-red-2')}>
				<input
					disabled={disabled}
					id={id}
					type="radio"
					className={classNames('h-8 w-8', isError && 'opacity-0')}
					name={name}
					value={value}
					checked={checked}
					onChange={onChange}
				/>
			</div>
			<div>
				<label htmlFor={id}>{label}</label>
			</div>
		</div>
	);
};

export default RadioButton;
