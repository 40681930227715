import alertIcon from '../../../../../assets/images/icon-filled-alert-exclamation-red.svg';
import infoIcon from '../../../../../assets/images/icon-filled-alert-info.svg';
import { Link } from '@cluey/cluey-components';
import { ItemHeader } from './ItemHeader';
import { getHolidays } from '../../../../../selectors/hubSelector';
import { getPaymentStatusMessageForSessionId as getPaymentStatus } from '../../../../../selectors/paymentSelector';
import { PATH_HUB_INVOICE_HISTORY } from '../../../../../util/pagePath';
import env from '../../../../../env';
import { useGetSessionPaymentDetails } from '../hooks';
import { useSelector } from 'react-redux';
import { isHolidayCheck } from '../../../../../util/helpers';
import { ClockIcon } from '../../../../../icons';
import moment from 'moment';
import type { Session } from '../../../../../types/hubInterfaces';
import isSessionJoinable from '../../../../../util/helper/isSessionJoinable';

interface Props {
	session: Session;
	sessionStatus: string;
}

const ListItemHeader = ({ session, sessionStatus }: Props) => {
	const { studentSessionId, startTime } = session;
	const isMaintenanceMode = env.REACT_APP_MAINTENANCE_MODE;
	const holidays = useSelector(getHolidays);
	const { indicatorText: holidayMessage, isClueyHoliday, isPublicHoliday } = isHolidayCheck(startTime, holidays);
	const { isPaymentError } = useGetSessionPaymentDetails(session);
	const messageForPaymentStatus = useSelector((state) => getPaymentStatus(state, studentSessionId));
	const showJoinButton = isSessionJoinable(session);
	if (isMaintenanceMode) {
		return null;
	}
	if (isClueyHoliday) {
		return <ItemHeader icon={alertIcon} leftText={holidayMessage} />;
	}
	if (sessionStatus && sessionStatus.length > 0) {
		const hasStarted = moment(session.startTime).isBefore(new Date());
		return showJoinButton ? (
			<ItemHeader
				customClass="bg-blue-1"
				icon={<ClockIcon className="text-green-5" />}
				leftText={
					<span className="text-xs font-bold uppercase text-green-5">
						{hasStarted ? sessionStatus : `Starting ${sessionStatus}`}
					</span>
				}
			/>
		) : null;
	}
	if (isPaymentError) {
		return (
			<ItemHeader customClass="bg-red-1" icon={alertIcon} leftText="Payment failure">
				<Link to={PATH_HUB_INVOICE_HISTORY} className="text-uppercase fs-11px font-weight-bold ls-1px">
					Update Details
				</Link>
			</ItemHeader>
		);
	}
	if (messageForPaymentStatus) {
		return <ItemHeader icon={alertIcon} leftText={messageForPaymentStatus} />;
	}
	if (isPublicHoliday) {
		return <ItemHeader icon={infoIcon} leftText={holidayMessage} />;
	}
	return null;
};

export default ListItemHeader;
