import { ComputerIcon } from "../../icons";
import classNames from "classnames"
interface Props {
  className?: string;
}
const DesktopOnly = ({ className }: Props) => {
  return (
    <div className={classNames("w-full bg-grey-1 border border-grey-3 rounded-md py-3 px-5 flex gap-4 items-center lg:hidden", className)}>
      <ComputerIcon width={55} height={55} className='text-grey-4' />
      <span className="text-sm leading-4 text-grey-6">Please use a desktop or laptop computer to join session now.</span>
    </div>
  )
}
export { DesktopOnly }