import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Input } from '@cluey/cluey-components';

import PasswordStrength from './PasswordStrength/PasswordStrength';
import PWDValidationResult from './password/PWDValidationResult';
import showPwdIcon from '../../../assets/images/hubAuth/icon-slashed-eye.svg';
import pwdVisibleIcon from '../../../assets/images/hubAuth/icon-eye-on.svg';

import { PASSWORD_VALIDATION_RESULT } from '../../../util/constants';
import { validatePassword } from '../../../util/validation/password';
import classNames from 'classnames';
import FieldLabel from '../FieldLabel';

const FIELD_TYPE_PASSWORD = 'password';
const FIELD_TYPE_TEXT = 'text';
const getNewFieldType = (fieldType) => {
  return fieldType === FIELD_TYPE_PASSWORD ? FIELD_TYPE_TEXT : FIELD_TYPE_PASSWORD;
};

const PasswordField = ({ fieldLabel, fieldId, savePassword, placeholderText }) => {
  const [password, setPassword] = useState('');
  const [passwordType, setPasswordType] = useState(FIELD_TYPE_PASSWORD);
  const [passwordValidation, setPasswordValidation] = useState(PASSWORD_VALIDATION_RESULT.INIT);

  const onPasswordChange = (value) => {
    setPassword(value);
  };

  const onPasswordBlur = () => {
    if (password.length === 0) {
      setPasswordValidation(PASSWORD_VALIDATION_RESULT.INIT);
      savePassword(password);
      return false;
    }

    if (validatePassword(password)) {
      setPasswordValidation(PASSWORD_VALIDATION_RESULT.VALID);
    } else {
      setPasswordValidation(PASSWORD_VALIDATION_RESULT.INVALID);
    }
    savePassword(password);

    return false;
  };

  const onPasswordTypeChangeClick = (e) => {
    e.preventDefault();
    const newPasswordType = getNewFieldType(passwordType);
    setPasswordType(newPasswordType);
  };

  const isInvalid = passwordValidation === PASSWORD_VALIDATION_RESULT.INVALID;

  return (
    <div className="mb-4">
      <div className="flex items-center">
        <FieldLabel id={fieldId} labelText={fieldLabel} />
        <Tooltip
          content="Password should be at least 8 characters long and contain at least 1
            uppercase letter, 1 lowercase letter and 1 number"
          className="ml-auto"
        >
          <span className="inline-block cursor-pointer text-sm leading-none text-primary">Password requirements</span>
        </Tooltip>
      </div>
      <div className="relative">
        <Input
          type={passwordType}
          name={fieldId}
          id={fieldId}
          placeholder={placeholderText}
          autoComplete="off"
          className={classNames('block w-full', isInvalid && 'invalid')}
          value={password}
          onChange={onPasswordChange}
          invalid={isInvalid}
          onBlur={onPasswordBlur}
        />
        <button className="absolute right-[7px] bottom-[7px] p-0" onClick={onPasswordTypeChangeClick}>
          {passwordType === FIELD_TYPE_PASSWORD && <img src={showPwdIcon} alt="show password" />}
          {passwordType === FIELD_TYPE_TEXT && <img src={pwdVisibleIcon} alt="hide password" />}
        </button>
      </div>
      {password && <PasswordStrength password={password} />}
      <PWDValidationResult pwdValidation={passwordValidation} />
    </div>
  );
};

PasswordField.propTypes = {
  fieldLabel: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  savePassword: PropTypes.func.isRequired,
  placeholderText: PropTypes.string,
};

PasswordField.defaultProps = {
  placeholderText: 'Please enter your password',
};

export default PasswordField;
