import { z } from 'zod';

const EnrolmentIds = z.object({
  sfId: z.string(),
  uniqueEnrolmentId: z.string(),
});

export const Student = z.object({
  studentId: z.string(),
  customerNumber: z.string().nullable(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  studentCountry: z.enum(['Australia', 'New Zealand']),
  location: z.string().nullable(),
  yearLevel: z.string().nullable(),
  currentYearLevel: z.string().nullable(),
  schoolName: z.string().nullable(),
  schoolId: z.string().nullable(),
  learningNeeds: z.string().nullable(),
  sessionsCompleted: z.string().nullable(),
  availableSubjects: z.string().array(),
  enrolments: z.record(EnrolmentIds.array()),
  createdDate: z.string(),
});
export type Student = z.infer<typeof Student>;
