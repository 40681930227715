import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import withSelectedEnrolment from '../../../common/hub/NewWithSelectedEnrolment';
import { useTextAreaProps, TextArea } from '../../../components/TextArea/TextArea';

import { RestrictedButton } from '../../../components/RestrictedButton/RestrictedButton';
import ErrorBlock from '../../../common/ErrorBlock';
import { PATH_HUB_ENROLMENT_DETAIL, PATH_HUB_CR_SWITCH_LEARNING_MODE } from '../../../util/pagePath';
import { submitCaseToSF } from '../../../actions/hub/hubContactPageActions';
import EnrolmentLayout from '../../../common/hub/EnrolmentLayout';
import { isGroupSessionCheck } from '../../../util/helpers';
import { LinkButton } from '@cluey/cluey-components';

const ViewTypes = {
  Default: 'default',
  Success: 'success',
};

const ErrorTypes = {
  TextAreaMessage: 'textAreaMessage',
};

export const FormSwitchLearningMode = ({
  selectedEnrolment, isSubmitting, submitError, errorMsg, submit,
}) => {
  const [view, setView] = useState(ViewTypes.Default);
  const [errors, setErrors] = useState([]);

  const textAreaProps = useTextAreaProps({
    id: 'switch-learning-mode-textarea',
    labelText: 'Message',
    placeholder: 'Let us know if there’s anything else we need to consider.',
  });

  const {
    enrolmentId, student, subject, type,
  } = selectedEnrolment;

  // Clear error message if user types some text
  const message = textAreaProps.value;

  const fromTo = isGroupSessionCheck(type) ? 'Group to Private 1-to-1' : 'Private 1-to-1 to Group';

  const isFormValid = ({ val }) => {
    console.info(val && val.replace(/\s/g, '').length > 0);
    return val && val.replace(/\s/g, '').length > 0;
  };

  const submitRequest = () => {
    if (!isFormValid({ val: message })) {
      return setErrors([ErrorTypes.TextAreaMessage]);
    }
    setErrors([]);

    const uniqueEnrolmentId = enrolmentId;
    const caseType = 'Support';
    const caseCategory = 'Support request';
    const caseSubCategory = 'Content request/support for student';
    const caseRequestFrom = 'Parent';
    const caseSubject = 'Change learning mode request (Private <=> Group)';

    return submit({
      uniqueEnrolmentId,
      message,
      caseSubject,
      caseType,
      caseCategory,
      caseSubCategory,
      caseRequestFrom,
      onSubmitSuccess: () => {
        setView(ViewTypes.Success);
      },
    });
  };

  if (isFormValid({ val: message }) && errors.length) {
    setErrors([]);
  }

  const defaultView = (
    <div className="container mx-auto px-4">
      <div className="flex flex-col">
        {submitError && errorMsg && (
          <div className="w-full lg:w-2/3">
            <ErrorBlock errorMsg={errorMsg} />
          </div>
        )}
        <div className="w-full lg:w-2/3">
          <p className="text-xl mb-8">
            Let us help you change {student}&apos;s {subject} learning from {fromTo}
          </p>
        </div>
        <div className="flex flex-col w-full lg:w-1/2">
          <h4 className="text-xl font-bold mb-5">Anything else we should know?</h4>
          <TextArea {...textAreaProps} className="mb-1" />
          {errors.includes(ErrorTypes.TextAreaMessage) && <span className="text-red-5 text-xs my-1">Please enter a message</span>}
        </div>
        <div className="w-full mt-10">
          <div className="mb-4 lg:mb-8 tracking-normal">
            <RestrictedButton
              buttonText="Request learning mode change"
              className="w-full md:w-auto"
              truthyChecks={[!isSubmitting]}
              onClick={submitRequest}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const successView = (
    <div className="container mx-auto px-4">
      <div className="flex flex-col">
        <div className="w-full lg:w-2/3">
          <p className="text-xl mb-8">We will be in touch to discuss your request and set up your changes.</p>
        </div>
        <div className="w-full mt-10">
          <div className="mb-4 lg:mb-8 tracking-normal">
            <LinkButton
              to={`${PATH_HUB_ENROLMENT_DETAIL}?token=${enrolmentId}`}
              size="xs"
              appearance="reverse"
              className="uppercase font-bold"
            >
              View {student}&apos;s {subject} enrolment
            </LinkButton>
          </div>
        </div>
      </div>
    </div>
  );

  const mainHeading = view === ViewTypes.Success ? 'Change request confirmed' : 'Change learning mode';

  return (
    <EnrolmentLayout sectionHeading={`${student} ${subject}`} mainHeading={mainHeading} enrolmentId={enrolmentId}>
      {view === ViewTypes.Default && defaultView}
      {view === ViewTypes.Success && successView}
    </EnrolmentLayout>
  );
};

FormSwitchLearningMode.propTypes = {
  selectedEnrolment: PropTypes.shape({
    enrolmentId: PropTypes.string.isRequired,
    student: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitError: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
};

export const ConnectedFormSwitchLearningMode = connect(
  (state) => {
    return {
      isSubmitting: state.ui.apiState.hubContactPage.isLoading,
      submitError: state.ui.apiState.hubContactPage.fetchError,
      errorMsg: state.ui.apiState.hubContactPage.errorMsg,
    };
  },
  (dispatch) => {
    return {
      submit: bindActionCreators(submitCaseToSF, dispatch),
    };
  },
)(withSelectedEnrolment(FormSwitchLearningMode, null, PATH_HUB_CR_SWITCH_LEARNING_MODE));

export { FormSwitchLearningMode as StatelessFormSwitchLearningMode };
