import { RadioItem } from '@cluey/cluey-components';
import { useState } from 'react';
import { InvoicePer } from '../../../../types/hubTypes';

export type PaymentOptionSelectorProps = {
  onChange?: (value: InvoicePer) => void;
  initialValues: {
    paymentOption: InvoicePer;
  };
};

const PaymentOptionSelector = ({
  onChange,
  initialValues,
}: PaymentOptionSelectorProps) => {
  const OPTIONS = [
    { value: 'Account', label: <span className='text-base'>Pay for all enrolments (one single invoice)</span> },
    { value: 'Enrolment', label: <span className='text-base'>Pay for each enrolment separately (one invoice per student and subject)</span> },
  ];

  const [paymentOption, setPaymentOption] = useState(initialValues.paymentOption);

  const handleChange = (value: InvoicePer) => {
    setPaymentOption(value);
    onChange?.(value);
  };

  return (
    <div>
      <h3 className="pb-6 font-display font-bold text-lg">Payment options</h3>
      <div className="flex flex-col gap-1">
        <div className="flex flex-col gap-4">
          {OPTIONS.map((opt) => (
            <RadioItem
              key={opt.value}
              value={opt}
              group="paymentOption"
              onChange={handleChange}
              groupValue={paymentOption}
              className='min-w-[2rem]'
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PaymentOptionSelector;
