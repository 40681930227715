export type Weekday = "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
export const WEEKDAYS: Weekday[] = ["Monday" , "Tuesday" , "Wednesday" , "Thursday" , "Friday" , "Saturday" , "Sunday"]

export type WorkHours = {
    time: string;
    utc: {
        dayOfWeek?: Weekday,
        time: string;
    };
    cron?: {
        dayOfWeek: Weekday,
        time: string;
    };
};

export type WorkDay = {
    day: Weekday;
    start: WorkHours;
    finish: WorkHours;
    open: boolean;
};
export type BusinessHours = {
    name: string;
    workHours: WorkDay[];
    active: boolean;
    type: string;
    timezone: {
        name: string;
        utc: string;
    };
};