import { FAQQuestions, ParsedQuestionAndAnswers } from "./types";

/**
 * Merges multiple JSON objects with a specific schema together while ensuring that
 * any question object with multiple answers gets appended to an array of answers.
 *
 * @param {FAQQuestions[]} questionsAndAnswers - An array of FAQQuestions objects.
 * @returns {Object.<string, ParsedQuestionAndAnswers>} - The merged questions and answers
 *                                                     indexed by question key.
 */
export const mergeQuestions = (questionsAndAnswers: FAQQuestions[]): { [key: string]: ParsedQuestionAndAnswers; } => questionsAndAnswers.reduce((acc, faq) => {
    const { id, title, questions } = faq;
    const clonedQuestions = { ...acc };
    Object.entries(questions).forEach(([key, value]) => {
        const { question, answer, priority = 100 } = value;
        clonedQuestions[key] = {
            question,
            priority,
            answers: [...clonedQuestions[key]?.answers || [], { id, title, answer }]
        };
    });
    return clonedQuestions;
}, {});
