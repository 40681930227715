import { HeadNavLink } from '../../../../components/headNavLink/headNavLink';
import { BASE_ACCOUNT_PATH, accountLinkMap } from './constants';
import { useHistory } from 'react-router';
import { Breadcrumb, PageTitle } from '../../../../components/hub/PageTitle';
import { PATH_HUB_HOME } from '../../../../util/pagePath';
import { api } from '../../../../api';

const AccountPageNavBreadcrumbs: Breadcrumb[] = [{ label: 'Home', to: PATH_HUB_HOME }];

export const AccountPageNav = () => {
	const { location, push } = useHistory();
	if (location.pathname === BASE_ACCOUNT_PATH) {
		push(accountLinkMap[0].to);
	}

	const { data: details } = api.account.details.useQuery();

	const links = !details || details.userType === 'Lead' ? [accountLinkMap[1]] : accountLinkMap;

	return (
		<div className="pb-10">
			<PageTitle title="Account" breadcrumbs={AccountPageNavBreadcrumbs} />
			<div className="container mx-auto flex">
				{links.map((headNavLinkProps) => (
					<HeadNavLink
						{...headNavLinkProps}
						isActive={location.pathname === headNavLinkProps.to}
						key={headNavLinkProps.to}
					/>
				))}
				<div className="flex-1 border-b border-grey-2"></div>
			</div>
		</div>
	);
};
export default AccountPageNav;
