import { createContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import { ButtonAsLink, Link, LinkButton } from '@cluey/cluey-components';
import HubPageLayout from '../../../common/hub/HubPageLayout';
import { PATH_HUB_HOME } from '../../../util/pagePath';
import { api } from '../../../api';
import warningIcon from '../../../assets/images/icon-filled-alert-exclamation.svg';
import generateSessionDescription from './generateSessionDescription';
import { usePostHogCapture } from '../../../util/posthog';
import { useRocketChatContext } from '../../../util/RocketChat/RocketChatProvider';
import successIcon from '../../../assets/images/icon-filled-tick-green-2.svg';
import SupportRelatedToggle from './SupportRelatedToggle';

type RouteParams = {
	sessionId: string;
};

/*
	SessionMessage involves multiple steps, each step is a component that conditionally renders the next step.
	Form state lives inside SessionMessageForm.

	SupportRelatedToggle -> Asks the user if the message is support related. Conditionally renders SessionSpecificToggle.
	|- SessionSpecificToggle -> Asks the user if the message is session specific. Conditionally renders SessionMessageForm.
		|- SessionMessageForm -> Asks the user what type of message they want to send and provides a text area for the message.
*/

export const SessionMessageContext = createContext<{
	sessionId?: string;
	uniqueEnrolmentId?: string;
	isSubmitting: boolean;
	isSubmitted: boolean;
	setIsSubmitting: (value: boolean) => void;
	setIsSubmitted: (value: boolean) => void;
}>({ isSubmitting: false, isSubmitted: false, setIsSubmitting: () => {}, setIsSubmitted: () => {} });

const SessionMessage = () => {
	const { sessionId } = useParams<RouteParams>();
	const capture = usePostHogCapture();
	const { maximizeWidget } = useRocketChatContext();

	const {
		data: upcomingSessions = [],
		isError,
		isFetched,
	} = api.session.all.useQuery('upcoming', {
		enabled: !!sessionId,
		select: (data) => {
			return data.filter((session) => {
				return session.studentSessionId === sessionId && moment(session.startTime).isSameOrAfter(new Date(), 'day');
			});
		},
	});
	const upcomingSession = upcomingSessions[0];
	const description = upcomingSession ? generateSessionDescription(upcomingSession) : '';

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const context = useMemo(
		() => ({
			sessionId: upcomingSession?.tutorSessionId,
			uniqueEnrolmentId: upcomingSession?.uniqueEnrolmentId,
			isSubmitting,
			setIsSubmitting,
			isSubmitted,
			setIsSubmitted,
		}),
		[
			upcomingSession?.tutorSessionId,
			upcomingSession?.uniqueEnrolmentId,
			isSubmitting,
			setIsSubmitting,
			setIsSubmitted,
			isSubmitted,
		]
	);

	useEffect(() => {
		capture('view_add-session-message');
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	if ((isFetched && !upcomingSession) || isError) {
		return (
			<HubPageLayout>
				<div className="container mx-auto mt-12 flex flex-col items-center gap-y-9">
					<div className="flex flex-col items-center">
						<img src={warningIcon} alt="warning icon" className="mb-2 h-[60px] w-[60px]" />
						<div className=" text-xl font-bold">Session could not be found</div>
					</div>
					<div>
						We couldn't find the session you were looking for. This could be due to a network error, please try again
						later.
					</div>
					<div>
						If the problem persists you may contact our{' '}
						<ButtonAsLink size="base" onClick={() => maximizeWidget()}>
							Support.
						</ButtonAsLink>
					</div>
					<LinkButton className="px-9 py-3 normal-case" to={PATH_HUB_HOME}>
						<span className="text-base font-bold">Back to home</span>
					</LinkButton>
				</div>
			</HubPageLayout>
		);
	} else if (isSubmitted) {
		return (
			<HubPageLayout>
				<div className="container mx-auto mt-12 flex flex-col items-center gap-y-9">
					<div className="flex flex-col items-center">
						<img src={successIcon} alt="success icon" className="mb-2 h-[60px] w-[60px]" />
						<div className=" text-xl font-bold">Message submitted</div>
					</div>
					<div>Your message will be visible to the tutor attending {upcomingSession?.student}'s upcoming session</div>
					<LinkButton className="px-9 py-3 normal-case" to={PATH_HUB_HOME}>
						<span className="text-base font-bold">Back to home</span>
					</LinkButton>
				</div>
			</HubPageLayout>
		);
	}

	return (
		<HubPageLayout>
			<Link size="xs" className="mb-3 font-bold uppercase tracking-widest lg:mb-2" to={PATH_HUB_HOME}>
				Home
			</Link>
			<h1 className="cy-enrolment-title mb-10 mt-4 font-display text-[40px] font-bold">
				Let the tutor know something about this session
			</h1>
			<div className="mb-10 text-lg">{description}</div>
			<SessionMessageContext.Provider value={context}>
				<SupportRelatedToggle />
			</SessionMessageContext.Provider>
		</HubPageLayout>
	);
};

export default SessionMessage;
