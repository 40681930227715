import { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import InPageLoader from '../../../../common/InPageLoader';
import { RadioGroup, useRadioGroupProps } from '../../../RadioGroup/RadioGroup';
import { ErrorMsg } from '../../../../common/Error';
import { isManagePausePageLoadingOrSubmittingFailed } from '../../../../selectors/errorSelector';
import { isManagePausePageLoadingOrSubmitting } from '../../../../selectors/loaderSelector';
import { TRACKING_MP } from '../../../../util/trackingClasses';
import { PromoText } from '../../PromoText';
import { Button, Container } from '@cluey/cluey-components';
import SectionTitle from '../../SectionTitle';
import { getTimezone } from '../../../../selectors/hubSelector';
import { DATE_FORMAT_TYPE_1 } from '../../../../util/constants';
import moment from 'moment';


interface Props {
  options: Array<{
    text: string;
    value: string;
    key: string;
  }>;
  defaultOption: string;
  onSubmitHandler(params: {selectedResumeDate: string}): void;
  incentive: string;
}

const ManagePauseOptions = ({
  options,
  defaultOption,
  onSubmitHandler,
  incentive,
}: Props) => {
  const isLoadingOrSubmitting: boolean = useSelector(isManagePausePageLoadingOrSubmitting);
  const isLoadingOrSubmittingFailed: boolean = useSelector(isManagePausePageLoadingOrSubmittingFailed);
  const timezone = useSelector(getTimezone);
  const [selectedDate, setSelectedDate] = useState(defaultOption);
  const [error, setError] = useState('');

  useEffect(() => {
    if (selectedDate) {
      setError('');
    }
  }, [selectedDate]);

  const radioGroupProps = useRadioGroupProps({
    radios: options,
    defaultSelection: defaultOption,
    onChangeHandler: setSelectedDate,
  });

  const onSubmitClick = () => {
    if (!selectedDate) {
      setError('Please select your preferred date to resume.');
      return;
    }

    onSubmitHandler({ selectedResumeDate: selectedDate });
  };

  if (isLoadingOrSubmitting) {
    return (
      <div className="mb-lg-11 mb-10">
        <InPageLoader position="relative" />
      </div>
    );
  }

  return (
    <Container>
      <SectionTitle title="When would you like to resume?" />
      {incentive && (
        <div className="mb-4">
          <PromoText text={incentive} />
        </div>
      )}
      <Fragment>
        <p className="fs-12px mb-8">
          Changes to your pause period may result in a new tutor being
          assigned on resumption.
        </p>
        <RadioGroup {...radioGroupProps} className="mb-11" />
        <div className="mb-5 text-left">
          {error && <ErrorMsg text={error} extraClasses="mb-3" />}
          {isLoadingOrSubmittingFailed && (
            <ErrorMsg
              text="We are not able to process your request, please try again later."
              extraClasses="mb-3"
            />
          )}
          {
            options.length === 0 &&
            defaultOption && (
              <p className='mb-7'>{moment(defaultOption).tz(timezone).format(DATE_FORMAT_TYPE_1)}</p>
            )
          }
          <Button
            size="lg"
            onClick={onSubmitClick}
            disabled={isLoadingOrSubmitting || options.length === 0}
            tracking={TRACKING_MP.CONFIRM}
          >
            Confirm update
          </Button>
        </div>
      </Fragment>
    </Container>
  );
};

export default ManagePauseOptions;