import { PricePlan } from '../../../../types/hubTypes';
import { calculatePlanDiscount } from '../helper';
import useChangePricingPlanContext from '../useChangePricingPlanContext';
import ComparisonCard from './ComparisonCard';
import HeaderCard from './HeaderCard';

export type PricingPlanComparisonProps = {
  pricingPlans: PricePlan[];
  showHeaders?: boolean;
  showActionButton?: boolean;
};

const PricingPlanComparison = ({
  pricingPlans,
  showHeaders = false,
  showActionButton = true,
}: PricingPlanComparisonProps) => {
  const { currentPricingPlan, allowedPlansForTransition } = useChangePricingPlanContext();
  const opened = true;

  if (!allowedPlansForTransition?.length) return null;

  return (
    <div className="container mx-auto flex flex-col">
      <div className="flex w-full flex-row gap-5">
        <HeaderCard opened={opened} plan={currentPricingPlan} showHeaders={showHeaders} />
        <div className="flex w-full flex-col gap-x-3 gap-y-9 md:flex-row">
          {pricingPlans.map((plan) => {
            const discount = calculatePlanDiscount(currentPricingPlan, plan);
            return (
              <ComparisonCard
                key={plan.pricingPlanName}
                opened={opened}
                plan={plan}
                chipContent={discount > 0 && `${discount}% off`}
                showHeaders={showHeaders}
                showActionButton={showActionButton}
                cardCount={pricingPlans.length}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PricingPlanComparison;
