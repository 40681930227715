import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Container } from '@cluey/cluey-components';

import { useEnrolmentDetailQuery } from '../../../../../hooks/queries/useEnrolmentQuery';
import { getAccountDetails, getSessionReportId } from '../../../../../selectors/hubSelector';
import EnrolmentLayout from '../../../../../common/hub/EnrolmentLayout';
import { OptionCard, OptionCardContent } from '../../../../../components/EnrolmentManagement/OptionCard';
import { PATH_HUB_HOME } from '../../../../../util/pagePath';
import { AccountDetails } from '../../../../../types/hubInterfaces';
import { getNAPLANInfo } from '../../../../../util/helper/helperNAPLAN';
import { isGroupSessionCheck } from '../../../../../util/helpers';
import { generateLearningAdjustmentsOptions } from './generateLearningAdjustmentsOptions';
import { IntentIntro } from '../../../../../components/IntentIntro';

const SUBJECTS_WITH_PRACTICE = ['Mathematics', 'Biology', 'Chemistry', 'Physics'];
const ONE_ON_ONE = 'Course';

export const ManageServiceLearningAdjustments = () => {
	const { enrolmentId } = useParams<{ enrolmentId: string }>();
	const { data: enrolmentDetail, isLoading, error } = useEnrolmentDetailQuery({ enrolmentId });
	const history = useHistory();
	if (!enrolmentId) {
		history.push(PATH_HUB_HOME);
	}

	const {
		studentName,
		customerNumber: studentCustomerNumber,
		enrolmentDiscipline,
		yearAsInteger: yearLevel,
		enrolmentSubject,
		packageMasterCode,
		enrolmentType: courseType,
	} = enrolmentDetail ?? {};
	const { customerNumber }: AccountDetails = useSelector(getAccountDetails);
	const sessionReportToken = useSelector((state) => getSessionReportId(state, enrolmentId));
	const { showNAPLAN } = getNAPLANInfo({ enrolmentSubject, packageMasterCode, courseType, checkDate: false });
	const showPractice = courseType === ONE_ON_ONE && SUBJECTS_WITH_PRACTICE.includes(enrolmentSubject);
	const isAccountOwner = customerNumber === studentCustomerNumber;
	const isGroup = isGroupSessionCheck(courseType);

	const options = generateLearningAdjustmentsOptions({
		isAccountOwner,
		studentName,
		sessionReportToken,
		enrolmentId,
		isGroup,
		showNAPLAN,
		showPractice,
	});

	return (
		<EnrolmentLayout
			isLoading={isLoading}
			error={error}
			sectionHeading={`${studentName}'s ${enrolmentDiscipline}`}
			mainHeading={`Year ${yearLevel} ${enrolmentDiscipline}`}
			enrolmentId={enrolmentId}
			showEnrolmentStatus={false}
			showSummerModeEnrolmentStatus={false}
		>
			<Container>
				<IntentIntro>How can we better support in-session learning?</IntentIntro>
				<ul className="list-none">
					{options.map(({ label, linkTo, type }) => {
						return (
							<li key={label}>
								<OptionCard linkTo={linkTo} type={type}>
									<OptionCardContent>{label}</OptionCardContent>
								</OptionCard>
							</li>
						);
					})}
				</ul>
			</Container>
		</EnrolmentLayout>
	);
};
