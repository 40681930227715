import React, { Fragment, useState, useEffect, Children } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { isEmpty, sortBy } from 'lodash';

import Day from './Day';
import { ButtonAsLink } from '../../common/ButtonAsLink';
import { CustomLink } from './CustomLink';

import { SESSION_TIME_FORMAT, TUTOR_AVAILABILITY_FORMAT } from '../../util/constants';
import { PATH_HUB_ENROLMENT_DETAIL, PATH_HUB_TTC_CHANGE_SESSION_KEEP_TUTOR } from '../../util/pagePath';
import { TRACKING_KPT, TRACKING_TTC } from '../../util/trackingClasses';
import { getSkipSessionRoute } from '../../util/helper/routing';

// NOTE, when we have resource to merge change schedule page with enrolment management,
// those three helper function should also be merged
export const getDays = ({ startDay, numberOfDaysToShow = 7 }) => {
	const days = [];
	for (let i = 0; i < numberOfDaysToShow; i++) {
		days.push(moment(startDay).add(i, 'day'));
	}
	return days;
};

const getAvailabilitiesForADay = ({ availability, timezone, activeDay }) => {
	return availability.filter((a) => {
		const currentDayDate = moment.utc(a.startDate, TUTOR_AVAILABILITY_FORMAT).tz(timezone);
		const selectedDayDate = moment(activeDay).tz(timezone);

		return currentDayDate.isSame(selectedDayDate, 'day');
	});
};

const formatDateAndSort = ({ availability, timezone }) => {
	const dateFormatted = availability.map((availableTime) => {
		const displayTime = moment
			.utc(availableTime.startDate, TUTOR_AVAILABILITY_FORMAT)
			.tz(timezone)
			.format(SESSION_TIME_FORMAT);
		return {
			...availableTime,
			displayTime,
		};
	});
	return sortBy(dateFormatted, 'startDate');
};

/*
 * NOTE,
 * for change session with the current package tutor page page:
 *  prevTutorName = enrolmentTutor || packageTutor
 *  currentTutorName = sessionTutor
 * */
export const TutorAvailability = ({
	enrolmentId,
	nextSessionId,
	prevTutorName,
	currentTutorName,
	startDay,
	numberOfDaysToShow,
	availability,
	timezone,
	onChangeTutorClick,
	onAvailabilityItemClick,
	redirectToChangeSessionApp,
	page,
	tracking,
}) => {
	const [activeDay, setActiveDay] = useState(startDay);
	const [availabilitiesInView, setAvailabilitiesInView] = useState([]);
	const isPageChangeSessionWithSameTutor = page === PATH_HUB_TTC_CHANGE_SESSION_KEEP_TUTOR;
	const days = getDays({
		startDay,
		numberOfDaysToShow: isPageChangeSessionWithSameTutor ? numberOfDaysToShow : undefined, // if we pass null instead of undefined getDays will NOT use its default value
	});

	useEffect(() => {
		if (!isEmpty(availability)) {
			const availabilitiesForActiveDay = getAvailabilitiesForADay({
				availability,
				timezone,
				activeDay,
			});

			if (isEmpty(availabilitiesForActiveDay)) {
				setAvailabilitiesInView(availabilitiesForActiveDay);
			} else {
				const sorted = formatDateAndSort({
					availability: availabilitiesForActiveDay,
					timezone,
				});
				setAvailabilitiesInView(sorted);
			}
		}
	}, [timezone, activeDay, availability]);

	const title = isPageChangeSessionWithSameTutor
		? `Select another day from ${prevTutorName}'s availability` // enrolmentTutor
		: 'Select preferred day';
	const dayTitle = isPageChangeSessionWithSameTutor
		? 'Select preferred session time'
		: 'Select preferred day to start new schedule';

	return (
		<Fragment>
			<div className={`mb-10 border-b border-grey-1 lg:mb-7 ${tracking}`}>
				<div className="container mx-auto">
					<h2 className="mb-4 font-display text-xl font-bold leading-[1.2] lg:mb-6">{title}</h2>
					<div
						className={`flex flex-row flex-nowrap justify-start ${
							isPageChangeSessionWithSameTutor ? '' : 'lg:justify-center'
						} -bottmo-px relative overflow-x-scroll pl-[15px] [scrollbar-width:none] lg:mx-0 lg:overflow-x-auto lg:pl-0 [&::-webkit-scrollbar]:hidden`}
					>
						{days.map((d) => (
							<Day key={d} dayRaw={d} activeDay={activeDay} clickHandler={setActiveDay} />
						))}
					</div>
				</div>
			</div>
			<div className="container mx-auto pb-11 lg:pb-[88px]">
				{!isEmpty(availabilitiesInView) && (
					<h2 className="mb-4 font-display text-xl font-bold leading-[1.2] lg:mb-6">{dayTitle}</h2>
				)}
				{isEmpty(availabilitiesInView) && (
					<p className="mb-4 text-lg leading-[1.33] tracking-[-0.25px] md:mb-6">
						Unfortunately {prevTutorName} has no availability on this day, please select another day.
					</p>
				)}
				{Children.toArray(
					availabilitiesInView.map((availability, index) => {
						const { displayTime } = availability;

						return (
							<div
								className={classNames(
									`flex cursor-pointer flex-row items-center rounded border-slate-2 bg-grey-2 px-5 py-4 transition-colors hover:border-[2px] hover:px-[18px] hover:py-[14px]`,
									{ 'mb-9': index === availabilitiesInView.length - 1 },
									{ 'mb-2': index !== availabilitiesInView.length - 1 }
								)}
								role="button"
								tabIndex={-1}
								onClick={() => {
									onAvailabilityItemClick({
										...availability,
										selectedDay: moment(activeDay).format('D MMMM YYYY'),
									});
								}}
							>
								<span className="w-1/2 text-lg font-bold leading-[1.33] text-primary lg:text-2xl lg:leading-[1.17]">
									{displayTime}
								</span>
							</div>
						);
					})
				)}
				<div className="py-1">
					{isPageChangeSessionWithSameTutor ? (
						<CustomLink
							to={`${PATH_HUB_ENROLMENT_DETAIL}?token=${enrolmentId}`}
							text={`Keep existing time with ${currentTutorName}`}
							customClasses={`${TRACKING_TTC.CTA_KEEP_CURRENT_TIME} text-sm text-blue-5 hover:text-purple-6 cursor-pointer hover:underline disabled:opacity-60`}
						/>
					) : (
						<CustomLink
							to={`${PATH_HUB_ENROLMENT_DETAIL}?token=${enrolmentId}`}
							text={`Keep existing schedule with ${currentTutorName}`}
							customClasses={`${TRACKING_KPT.KEEP_NEW_TUTOR} text-sm text-blue-5 hover:text-purple-6 cursor-pointer hover:underline disabled:opacity-60`}
						/>
					)}
				</div>
				{typeof onChangeTutorClick === 'function' && (
					<div className="py-1">
						<ButtonAsLink
							text="Keep existing schedule with a different tutor"
							onClickHandler={onChangeTutorClick}
							extraClasses={TRACKING_KPT.CHANGE_TUTOR}
						/>
					</div>
				)}
				{redirectToChangeSessionApp && (
					<div className="py-1">
						<a
							href={getSkipSessionRoute(String(nextSessionId))}
							rel="noopener noreferrer"
							target="_blank"
							className={`
                block text-lg leading-[1.14] tracking-normal ${TRACKING_TTC.SKIP_CURRENT_SESSION}
                cursor-pointer text-blue-5 hover:text-purple-6 hover:underline disabled:opacity-60
              `}
						>
							Skip this session and wait for {prevTutorName}&apos;s return
						</a>
					</div>
				)}
			</div>
		</Fragment>
	);
};

TutorAvailability.propTypes = {
	enrolmentId: PropTypes.string.isRequired,
	prevTutorName: PropTypes.string.isRequired,
	currentTutorName: PropTypes.string.isRequired,
	startDay: PropTypes.string.isRequired,
	numberOfDaysToShow: PropTypes.number,
	nextSessionId: PropTypes.string,
	availability: PropTypes.arrayOf(PropTypes.string).isRequired,
	timezone: PropTypes.string.isRequired,
	onChangeTutorClick: PropTypes.func,
	onAvailabilityItemClick: PropTypes.func.isRequired,
	redirectToChangeSessionApp: PropTypes.bool,
	page: PropTypes.string,
	tracking: PropTypes.string,
};

TutorAvailability.defaultProps = {
	onChangeTutorClick: null,
	redirectToChangeSessionApp: false,
	nextSessionId: 'undefined',
	page: null,
	tracking: null,
	numberOfDaysToShow: 0,
};
