import { useQuery } from '@tanstack/react-query';
import { InvoiceService } from '../../services/InvoicesService';
import { GET_ACCOUNT_BALANCE_DETAILS } from '../../util/constants/queryKeys';

const useAccountBalanceDetailsQuery = (zuoraId, enabled = true) => {
  return useQuery({
    queryKey: [GET_ACCOUNT_BALANCE_DETAILS],
    queryFn: () => InvoiceService.getAccountBalanceDetails(zuoraId),
    staleTime: 600000,
    enabled: enabled && !!zuoraId,
  });
}

export default useAccountBalanceDetailsQuery;