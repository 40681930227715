import initialState from '../../initialState';
import * as actionTypes from '../../../actions/actionTypes';

export default function hubChangeSchedulePageReducer(state = initialState.ui.apiState.hubChangeSchedulePage, action) {
  switch (action.type) {
    case actionTypes.HUB_CSP_UPDATE_SCHEDULE_START:
    case actionTypes.HUB_CSP_FETCH_SELECTED_TUTOR_INFO_START:
      return {
        ...state,
        firstFetch: true,
        fetchError: false,
      };
    case actionTypes.HUB_CSP_UPDATE_SCHEDULE_SUCCESS:
    case actionTypes.HUB_CSP_FETCH_SELECTED_TUTOR_INFO_SUCCESS:
      return {
        ...state,
        firstFetch: false,
        fetchError: false,
      };
    case actionTypes.HUB_CSP_UPDATE_SCHEDULE_FAILED:
    case actionTypes.HUB_CSP_FETCH_SELECTED_TUTOR_INFO_FAILED:
      return {
        ...state,
        firstFetch: false,
        fetchError: true,
      };
    case actionTypes.HUB_CSP_UPDATE_SCHEDULE_FAILED_SHOW_NEW_SCHEDULE_CONFIRMATION:
    case actionTypes.HUB_EM_CS_RESET_PAGE_STATE:
      return {
        ...state,
        firstFetch: false,
        fetchError: false,
      };
    default:
      return state;
  }
}
