import React from 'react';
import PropTypes from 'prop-types';
import RoundedRec3 from '../../../assets/images/atoms-shapes-rounded-rec-3.svg';
import iconReport from '../../../assets/images/icon-filled-report.svg';
import { Anchor } from '@cluey/cluey-components';
import { PATH_NAME_REPORT } from '../../../util/pagePath';
import {
  STUDENT_PROGRESS_TAB_VIEWS,
  STUDENT_PROGRESS_URL_PARAM_TAB,
} from '../../../util/constants';

export const RedirectToEnrolmentReport = ({
  student,
  subject,
  sessionReportId,
  gaTags,
}) => {
  return (
    <div className="relative flex h-[110px] items-center rounded-lg bg-slate-1 px-7">
      <div className="absolute right-0 bottom-0">
        <img src={RoundedRec3} alt="progress" className="mr-5" />
      </div>
      <div className="pr-5">
        <img src={iconReport} alt="report" className="h-[36px]" />
      </div>
      <div className="flex flex-col" style={{ zIndex: 1 }}>
        <span className="pb-1 text-base font-bold">
          {student}&apos;s {subject} progress
        </span>
        <Anchor
          className="text-sm font-bold"
          href={`${PATH_NAME_REPORT}?token=${sessionReportId}&${STUDENT_PROGRESS_URL_PARAM_TAB}=${STUDENT_PROGRESS_TAB_VIEWS.PROGRESS_OVERVIEW}${gaTags}`}
          target="_blank"
        >
          Review tutor feedback
        </Anchor>
      </div>
    </div>
  );
};

RedirectToEnrolmentReport.propTypes = {
  student: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  sessionReportId: PropTypes.string,
  gaTags: PropTypes.string,
};

RedirectToEnrolmentReport.defaultProps = {
  sessionReportId: '',
  gaTags: '',
};
