import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@cluey/cluey-components';
import TextBox from './TextBox';
import { ERROR_CONTACT_DETAILS_DETECTED, FormSchema } from './formSchema';
import { ExclamationMarkIcon } from '../../../icons';
import RadioButton from './RadioButton';
import { SessionMessageContext } from './SessionMessage';
import { useContext } from 'react';
import { AlertExclamationIcon } from '../../../components/ErrorText/AlertExclamationIcon';
import { api } from '../../../api';
import { Loader } from '../../../components/Loader/Loader';
import { usePostHogCapture } from '../../../util/posthog';

const SessionMessageForm = () => {
	const { isSubmitting } = useContext(SessionMessageContext);
	const capture = usePostHogCapture();
	const {
		handleSubmit,
		setValue,
		clearErrors,
		watch,
		formState: { errors },
	} = useForm({
		resolver: zodResolver(FormSchema),
		reValidateMode: 'onChange',
		defaultValues: {
			category: null,
			message: '',
		},
	});

	const { sessionId, uniqueEnrolmentId, setIsSubmitting, setIsSubmitted } = useContext(SessionMessageContext);
	const { mutate, error: submitError } = api.session.createMessage.useMutation({
		onSuccess: () => {
			setIsSubmitted(true);
		},
		onSettled: () => {
			setIsSubmitting(false);
		},
	});

	const category = watch('category');

	const message = watch('message');
	const setMessage = (value) => {
		setValue('message', value);
	};

	const onSubmit = handleSubmit(
		(data: FormSchema) => {
			capture('click_add-session-message_submit-message');
			setIsSubmitting(true);
			mutate({
				category: data.category,
				message: data.message,
				sessionId,
				uniqueEnrolmentId,
			});
		},
		(errors) => {
			if (errors.message?.message === ERROR_CONTACT_DETAILS_DETECTED) {
				capture('click_add-session-message_submit-error-contact-details-detected');
			}
		}
	);

	const handleRadioChange = (event) => {
		setMessage('');
		clearErrors();
		const value: 'request-specific-content' | 'provide-student-update' | 'other' = event.target.value;
		capture(`click_add-session-message_select-category-${value}`, {
			value,
		});

		setValue('category', value);
	};

	return (
		<div>
			<div className="mb-4 text-lg font-bold">
				Select the option that best describe your message and provide details.
			</div>
			<div className="mb-10 flex flex-col gap-y-4">
				<RadioButton
					disabled={isSubmitting}
					isError={!!errors.category}
					checked={category === 'request-specific-content'}
					onChange={handleRadioChange}
					value="request-specific-content"
					name="message-details"
					id="message-details_request-specific-content"
					label="Request specific content or topic"
				/>
				{category === 'request-specific-content' && (
					<div className="flex flex-col gap-y-6 md:ml-11">
						<div className="rounded-lg bg-slate-1 p-6">
							<div className="text-lg font-bold">Looking to include an attachment?</div>
							<div>
								If you would like to share an attachment with your tutor as part of your content request to work on
								during the session (such as a practice paper, homework task, assignment, school report or notes) you can
								upload this during the session by pressing the upload button available in the session. The file will
								need to be a PDF, photo or Word Doc.
							</div>
						</div>
						<TextBox value={message} handleOnChange={setMessage} maxLength={255} error={errors.message?.message} />
					</div>
				)}
				<RadioButton
					disabled={isSubmitting}
					isError={!!errors.category}
					checked={category === 'provide-student-update'}
					onChange={handleRadioChange}
					value="provide-student-update"
					name="message-details"
					id="message-details_provide-student-update"
					label="Provide an update about how the student is feeling"
				/>
				{category === 'provide-student-update' && (
					<div className="flex flex-col gap-y-6 md:ml-11">
						<TextBox value={message} handleOnChange={setMessage} maxLength={255} error={errors.message?.message} />
					</div>
				)}
				<RadioButton
					disabled={isSubmitting}
					isError={!!errors.category}
					checked={category === 'other'}
					onChange={handleRadioChange}
					value="other"
					name="message-details"
					id="message-details_other"
					label="Other"
				/>
				{category === 'other' && (
					<div className="flex flex-col gap-y-6 md:ml-11">
						<TextBox value={message} handleOnChange={setMessage} maxLength={255} error={errors.message?.message} />
					</div>
				)}
			</div>
			{errors.category && (
				<div className="mb-10 flex flex-row items-center gap-x-2 text-red-5">
					<ExclamationMarkIcon width={24} height={24} />
					{`${errors.category.message}`}
				</div>
			)}
			{submitError && (
				<div className="mb-10 rounded-lg bg-red-2 px-7 py-4">
					<div className="flex flex-col gap-y-4">
						<div className="flex flex-row gap-x-4">
							<AlertExclamationIcon height={11} className="mt-0.5 inline-block flex-shrink-0 text-red-5" />
							<b className="font-bold">An error has occurred. Try submitting your message again.</b>
						</div>
					</div>
				</div>
			)}
			{isSubmitting && (
				<div className="mb-10 py-4">
					<Loader />
				</div>
			)}
			<Button size="lg" onClick={onSubmit} disabled={isSubmitting}>
				Submit message
			</Button>
		</div>
	);
};

export default SessionMessageForm;
