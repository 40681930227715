import { PATH_HUB_HOME } from '../../util/pagePath';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
function useRedirectInvalidSession(session, redirectPath = PATH_HUB_HOME) {
  const history = useHistory();
  useEffect(() => {
    if (!session) {
      history.push(redirectPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])
}

export default useRedirectInvalidSession;