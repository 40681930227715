export const HUB_LEARNING_NEEDS = [
	'Anxiety',
	'Attention Deficit hyperactivity disorder (ADHD)',
	'Autistic Disorder',
	'Dyslexia',
	'Dyscalculia',
	'Hearing Impairment',
	'Visual Impairment',
] as const;
export type HUB_LEARNING_NEEDS = typeof HUB_LEARNING_NEEDS[number];

export const SF_LEARNING_NEEDS = [
	'Anxious',
	'ADHD',
	'Autistic Disorder',
	'Autism spectrum level 2-3',
	'Dyslexia',
	'Dyscalculia',
	'Hearing Impairment',
	'Visual Impairment',
] as const;
export type SF_LEARNING_NEEDS = typeof SF_LEARNING_NEEDS[number];

const SF_TO_HUB_LEARNING_NEEDS_MAP: Record<SF_LEARNING_NEEDS, HUB_LEARNING_NEEDS> = {
	Anxious: 'Anxiety',
	ADHD: 'Attention Deficit hyperactivity disorder (ADHD)',
	'Autistic Disorder': 'Autistic Disorder',
	'Autism spectrum level 2-3': 'Autistic Disorder',
	Dyslexia: 'Dyslexia',
	Dyscalculia: 'Dyscalculia',
	'Hearing Impairment': 'Hearing Impairment',
	'Visual Impairment': 'Visual Impairment',
};

export const HUB_TO_SF_LEARNING_NEEDS_MAP: Record<HUB_LEARNING_NEEDS, SF_LEARNING_NEEDS> = {
	Anxiety: 'Anxious',
	'Attention Deficit hyperactivity disorder (ADHD)': 'ADHD',
	'Autistic Disorder': 'Autistic Disorder',
	Dyslexia: 'Dyslexia',
	Dyscalculia: 'Dyscalculia',
	'Hearing Impairment': 'Hearing Impairment',
	'Visual Impairment': 'Visual Impairment',
};

export const convertSfToHubLearningNeeds = (sfLearningNeeds: string[]): HUB_LEARNING_NEEDS[] => {
	return sfLearningNeeds.map((need) => SF_TO_HUB_LEARNING_NEEDS_MAP[need as SF_LEARNING_NEEDS]);
};

export const convertHubToSfLearningNeeds = (hubLearningNeeds: string[]): SF_LEARNING_NEEDS[] => {
	return hubLearningNeeds.map((need) => HUB_TO_SF_LEARNING_NEEDS_MAP[need]);
};

export const parseLearningNeeds = (learningNeeds: string): HUB_LEARNING_NEEDS[] => {
	return convertSfToHubLearningNeeds(learningNeeds.split(';')).filter(Boolean);
};
