import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Container, Button, ButtonAsLink } from '@cluey/cluey-components';
import moment from 'moment';
import { Link, useHistory, useLocation } from 'react-router-dom';
import EnrolmentLayout from '../../common/hub/EnrolmentLayout';
import { enrolmentScheduleDefault, enrolmentScheduleType, groupPackageType } from '../../types/hubPropTypes';
import { isEnrolmentPaused } from '../../selectors/hubSelector';
import { getGroupSessionMoment, isGroupSessionCheck, scrollToTop } from '../../util/helpers';
import InPageLoader from '../../common/InPageLoader';
import PageError from '../../common/PageError';
import {
	ENROLMENT_MANAGEMENT_STEPS,
	HOC_PAGE_PARAM,
	SCHEDULE_DATE_FORMAT,
	SESSION_TIME_FORMAT,
	CHANGE_GROUP_CONFIRM_FORMAT,
} from '../../util/constants';
import { EnrolmentManagementConfirmed } from '../../components/EnrolmentManagement/EnrolmentManagementConfirmed';
import { EnrolmentBrief } from '../../components/EnrolmentManagement/EnrolmentBrief';
import {
	changeGroup as actionChangeGroup,
	clearGroupSessions,
	fetchGroupSessions,
	clearError as clearGroupChangeError,
} from '../../actions/hub/hubChangeGroupPageActions';

import iconReview from '../../assets/images/icon-outline-review.svg';
import iconCalendar from '../../assets/images/icon-calendar--orange.svg';
import {
	PATH_HUB_ENROLMENT_CHANGE_SCHEDULE,
	PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP,
	PATH_HUB_ENROLMENT_DETAIL,
	PATH_HUB_ENROLMENT_MANAGE_PAUSE,
} from '../../util/pagePath';
import Error, { ErrorMsg } from '../../common/Error';
import { getChangeGroupSessions } from '../../selectors/enrolmentChangeGroupSelector';
import { RestrictedButton } from '../../components/RestrictedButton/RestrictedButton';
import WithSelectedEnrolment from '../../common/hub/NewWithSelectedEnrolment';
import { Radio } from '../../components/RadioGroup/Radio';
import { redirectToEnrolmentManagement } from '../../actions/hub/hubEnrolmentManagementActions';
import { GroupTimeslot } from '../../components/EnrolmentManagement/GroupTimeslot';
import {
	fetchSummerModeData as actionFetchSummerModeData,
	saveSummerModeData as saveSummerModeDataAction,
} from '../../actions/summerModeActions';
import { isIncentiveValid, SELECTED_SUMMER_MODE } from '../../util/summerCopy';
import { IncentiveText } from '../../components/IncentiveText';
import { SUMMER_15PCT_ALL_SESSIONS } from '../../util/constants/incentives';

import { saveSelectedEnrolment } from '../../actions/hub/hubContactPageActions';
import { willEnrolmentBePaused } from '../../selectors/enrolmentSelector';
import { getSummerPromo } from '../../selectors/summerModeSelector';
import SummerPromoLegalCopy from '../../components/EnrolmentManagement/SummerMode/SummerPromoLegalCopy';

/**
 * Check target schedule is in enrolment schedule
 * @param {string} targetSessionTime
 * @param {{ schedule: string }[]} enrolmentSchedule
 * @returns {boolean}
 */
const isCurrentSchedule = (targetSessionTime, enrolmentSchedule) => {
	let isSame = false;
	if (Array.isArray(enrolmentSchedule)) {
		const formatted = moment(targetSessionTime).format(SCHEDULE_DATE_FORMAT).toUpperCase();
		enrolmentSchedule.forEach((schedule) => {
			if (!isSame && schedule.schedule === formatted) {
				isSame = true;
			}
		});
	}
	return isSame;
};

const HubEnrolmentChangeGroupSchedule = ({
	enrolmentSfid,
	enrolmentId,
	studentName,
	enrolmentSubject,
	enrolmentType,
	tutorName,
	tutorPhoto,
	enrolmentSchedule,
	isPaused,
	isLoading,
	loadingError,
	isLoadingEnrolment,
	loadingEnrolmentError,
	fetchGroups,
	clearGroups,
	groupAvailabilities,
	changeGroup,
	nextSessionDate,
	nextSessionEndDate,
	timezone,
	changingGroup,
	changingGroupError,
	clearGroupError,
	redirectToDetailPage,
	fetchSummerModeData,
	customerLinkId,
	summerModeIncentives,
	clearSavedEnrolment,
	summerPromo,
	summerPromoConfirming,
	saveSummerModeData,
}) => {
	const { promoText } = summerPromo;
	const { incentiveCode, promoText: confirmingPromoText } = summerPromoConfirming;
	const [isSavingSummerModeData, setIsSavingSummerModeData] = useState(false);
	const [activeComponent, setActiveComponent] = useState(ENROLMENT_MANAGEMENT_STEPS.DEFAULT);
	const [chosenGroup, setChosenGroup] = useState({ id: '', session: '', hgId: '' });
	const incentiveIsValid = isIncentiveValid(incentiveCode, chosenGroup.session, {
		screen: PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP,
	});
	const [validGroup, setValidGroup] = useState(false);
	const [groupError, setGroupError] = useState('');

	const [nextSessionTimes, setNextSessionTimes] = useState([nextSessionDate, nextSessionEndDate]);
	const [filteredGroups, setFilteredGroups] = useState(groupAvailabilities);

	const history = useHistory();

	const isToday = useMemo(() => {
		return moment(nextSessionDate).tz(timezone).isSame(moment().tz(timezone), 'days');
	}, [nextSessionDate, timezone]);

	useEffect(() => {
		scrollToTop();
	}, [activeComponent]);
	const query = new URLSearchParams(useLocation().search);
	const enrolmentIdToken = query.get('token');
	useEffect(() => {
		if (!isLoadingEnrolment && !isGroupSessionCheck(enrolmentType) && !enrolmentIdToken) {
			history.push(PATH_HUB_ENROLMENT_CHANGE_SCHEDULE);
		}
	}, [enrolmentType, isLoadingEnrolment, history, enrolmentIdToken]);

	useEffect(() => {
		if (!isLoadingEnrolment) {
			fetchGroups();
		}

		return () => {
			clearGroups();
			clearSavedEnrolment({ enrolmentId: '' });
		};
	}, [fetchGroups, clearGroups, isLoadingEnrolment, clearSavedEnrolment]);

	useEffect(() => {
		setNextSessionTimes([nextSessionDate, nextSessionEndDate]);
	}, [nextSessionDate, nextSessionEndDate]);

	useEffect(() => {
		const filtered = groupAvailabilities.filter((group) => {
			return !isCurrentSchedule(getGroupSessionMoment(group.sessions[0]), enrolmentSchedule);
		});

		setFilteredGroups(filtered);
	}, [groupAvailabilities, enrolmentSchedule]);

	// Calculate whether chosen group is valid only on chosenGroup change
	useEffect(() => {
		const { id, session } = chosenGroup;
		const isValid = id !== '' && typeof session === 'string' && session;
		if (isValid) {
			const isSame = isCurrentSchedule(session, enrolmentSchedule);

			setValidGroup(!isSame);

			if (!isSame) {
				setGroupError('');
				const newTime = moment(session).utc();
				setNextSessionTimes([newTime.toISOString(), newTime.add(1, 'hour').toISOString()]);
			} else {
				setGroupError('Please select a new group time.');
			}
		} else {
			setValidGroup(false);
		}
	}, [chosenGroup, nextSessionDate, enrolmentSchedule]);

	useEffect(() => {
		// check for an incentive
		if (!summerModeIncentives) {
			fetchSummerModeData({ customerLinkId });
		}
	}, [summerModeIncentives, customerLinkId, fetchSummerModeData]);

	if (isLoadingEnrolment) {
		return <InPageLoader />;
	}

	if (loadingEnrolmentError) {
		return <PageError activePage="hub-enrolment-detail" />;
	}

	if (!isGroupSessionCheck(enrolmentType)) {
		history.push(`${PATH_HUB_ENROLMENT_CHANGE_SCHEDULE}?token=${enrolmentId}`);
	}

	if (activeComponent === ENROLMENT_MANAGEMENT_STEPS.CONFIRMED) {
		return (
			<EnrolmentManagementConfirmed
				mainHeading="Change confirmed"
				student={studentName}
				subject={enrolmentSubject}
				enrolmentId={enrolmentId}
				message="We have updated your permanent group and time - you will see it reflected on your enrolment details soon and you will also receive an email confirming the change."
				promoText={confirmingPromoText && incentiveIsValid ? confirmingPromoText : ''}
				incentiveCode={incentiveCode}
				showLegalCopy={incentiveIsValid && promoText}
			/>
		);
	}

	const submitGroupChange = () => {
		if (chosenGroup.id && chosenGroup.session) {
			changeGroup({
				enrolmentId,
				packageId: chosenGroup.id,
				startDate: chosenGroup.session,
				callback: () => {
					setIsSavingSummerModeData(true);
					saveSummerModeData({
						salesForceEnrolmentId: enrolmentSfid,
						shouldApplyIncentive: incentiveIsValid,
						appliedSummerModeIncentive: incentiveCode === SUMMER_15PCT_ALL_SESSIONS ? incentiveCode : '',
						enrolmentId,
						selectedSummerMode: SELECTED_SUMMER_MODE.GROUP,
						summerModeNewScheduleStartTime: moment(chosenGroup.session).format('HH:mm:ss'),
						summerModeNewScheduleDay: moment(chosenGroup.session).format('dddd'),
						summerModeAdditionalDetails: moment(chosenGroup.session).format('YYYY-MM-DD'),
						summerModeHoldingGroup: chosenGroup.hgId,
						onSubmitSuccess: () => {
							setIsSavingSummerModeData(false);
							setActiveComponent(ENROLMENT_MANAGEMENT_STEPS.CONFIRMED);
						},
						onSubmitFailed: () => {
							console.error('saveSummerModeData submit failed');
							setIsSavingSummerModeData(false);
							setActiveComponent(ENROLMENT_MANAGEMENT_STEPS.CONFIRMED);
						},
					});
				},
				isPaused,
				timezone,
			});
		}
	};

	const continueWithGroup = () => {
		if (validGroup) {
			setActiveComponent(ENROLMENT_MANAGEMENT_STEPS.CONFIRMATION);
		} else {
			setGroupError('Please select a new group time.');
		}
	};

	const clearSubmitError = () => {
		clearGroupError();
		setActiveComponent(ENROLMENT_MANAGEMENT_STEPS.CONFIRMATION);
	};

	let view;
	if (!isToday) {
		// Default - next session isn't today
		view = isLoading ? (
			<div className="container mx-auto mb-10">
				<InPageLoader position="relative" height="auto" />
			</div>
		) : (
			<Fragment>
				<div className="my-7 flex flex-col">
					{!isPaused
						? filteredGroups.map((group) => {
								const sessionTime = getGroupSessionMoment(group.sessions[0]);
								return (
									<Radio
										key={group.courseName}
										groupName="group-timeslot"
										text={`${group.dayName}s at ${sessionTime.tz(timezone).format(SESSION_TIME_FORMAT)}`}
										value={group.packageUniqueId}
										subText={`Start ${sessionTime.tz(timezone).format('MMM D')}`}
										checked={chosenGroup.id === group.packageUniqueId}
										onChange={() =>
											setChosenGroup({
												id: group.packageUniqueId,
												session: sessionTime.toISOString(),
												hgId: group.holdingPackageId,
											})
										}
										labelClassName="font-body font-normal text-lg"
										muteSubText={false}
									/>
								);
						  })
						: filteredGroups.map((group) => (
								<GroupTimeslot
									key={group.packageUniqueId}
									sessions={group.sessions}
									packageId={group.packageUniqueId}
									onChoose={(chosen) =>
										setChosenGroup({
											id: group.packageUniqueId,
											session: chosen,
											hgId: group.holdingPackageId,
										})
									}
									activeGroup={chosenGroup}
									timezone={timezone}
									className="mb-4"
								/>
						  ))}
				</div>
				<div className="mb-4 flex flex-col tracking-normal md:block lg:mb-8">
					{groupError && <ErrorMsg text={groupError} />}
					<Button className="mt-3.5" size="lg" onClick={continueWithGroup}>
						Continue
					</Button>
				</div>
			</Fragment>
		);
	} else {
		view = (
			<div className="flex flex-col md:block">
				<Button
					size="xs"
					appearance="reverse"
					onClick={() => {
						redirectToDetailPage({
							enrolmentId: '',
							callback: () => {
								history.push(`${PATH_HUB_ENROLMENT_DETAIL}?token=${enrolmentId}`);
							},
						});
					}}
				>
					View enrolment details
				</Button>
			</div>
		);
	}

	return (
		<EnrolmentLayout
			sectionHeading={`${studentName} ${enrolmentSubject}`}
			mainHeading="Change group"
			enrolmentId={enrolmentId}
		>
			{changingGroupError || loadingError ? (
				<Container>
					<Error
						firstLine="We are having trouble processing your request"
						secondLine="Please try again in a few minutes"
					>
						<Button size="lg" onClick={clearSubmitError}>
							Return to previous page
						</Button>
					</Error>
				</Container>
			) : (
				<Fragment>
					{activeComponent === ENROLMENT_MANAGEMENT_STEPS.DEFAULT && (
						<Container>
							<EnrolmentBrief tutorName={tutorName} tutorPhoto={tutorPhoto} enrolmentSchedule={enrolmentSchedule} />

							<h2 className="mb-4 font-display text-xl font-bold leading-[1.2] lg:mb-6">
								When would you like to join a new group?
							</h2>
							<div className="mb-4">
								<h3 className="font-display text-lg">
									{isToday
										? 'The session today will need to be completed before moving to another group.'
										: 'Select preferred time and start date'}
								</h3>
								<IncentiveText code={incentiveCode} />
							</div>

							{view}
							{promoText && !changingGroup && !isSavingSummerModeData && <SummerPromoLegalCopy />}
							{isPaused && (
								<div className="mt-9 flex flex-col lg:flex-row">
									<div className="mt-9 flex w-full flex-col items-center bg-slate-1 py-9">
										<div className="pb-3 text-xl font-bold">Need to restart sessions at a later date?</div>
										<Link to={PATH_HUB_ENROLMENT_MANAGE_PAUSE}>
											<Button size="xs" appearance="reverse" className="uppercase tracking-[1px]">
												Extend Pause
											</Button>
										</Link>
									</div>
								</div>
							)}
						</Container>
					)}

					{activeComponent === ENROLMENT_MANAGEMENT_STEPS.CONFIRMATION && validGroup && (
						<Container>
							<div className="mb-5">
								<h3 className="font-display text-lg font-light">Please review and confirm the new group time</h3>
								{incentiveIsValid && <IncentiveText code={incentiveCode} confirming />}
							</div>

							<div className="flex flex-col rounded-lg border-grey-2 bg-white px-5 py-6 lg:flex-row">
								{!changingGroup && !isSavingSummerModeData ? (
									<div className="w-full px-9">
										{/* New Ongoing */}
										<div className="lg:mt-8">
											<h3 className="mb-5 text-xs font-bold uppercase tracking-[1px]">new ongoing schedule</h3>
											<div className="flex items-start">
												<img src={iconReview} alt="icon" className="mr-4 lg:mr-5" />
												<div className="text-left">
													<div className="text-lg leading-snug lg:mb-3">
														<div className="font-bold">
															{moment(chosenGroup.session).tz(timezone).format(`dddd[s at ]${SESSION_TIME_FORMAT}`)}
														</div>
														<div>Starting from {moment(chosenGroup.session).tz(timezone).format('D MMMM YYYY')}</div>
													</div>
													<ButtonAsLink
														size="sm"
														className="mb-1 block border-0 p-0 leading-[1.14] tracking-normal"
														onClick={() => setActiveComponent(ENROLMENT_MANAGEMENT_STEPS.DEFAULT)}
													>
														Change
													</ButtonAsLink>
												</div>
											</div>
										</div>
										{/* Next Session */}
										<div className="my-9 border-t border-grey-2 pt-9">
											<h3 className="mb-4 text-xs font-bold uppercase tracking-[1px]">next session</h3>
											<div className="flex items-start">
												<img src={iconCalendar} alt="icon" className="mr-4 lg:mr-5" />
												<div className="text-left">
													<div className="text-lg lg:mb-3">
														<div>{moment(nextSessionTimes[0]).tz(timezone).format(CHANGE_GROUP_CONFIRM_FORMAT)}</div>
														<div>
															{`${moment(nextSessionTimes[0]).tz(timezone).format(SESSION_TIME_FORMAT)} - ${moment(
																nextSessionTimes[1]
															)
																.tz(timezone)
																.format(SESSION_TIME_FORMAT)}`}
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="submitBtn my-9">
											<RestrictedButton
												buttonText="Confirm new group"
												truthyChecks={[true]}
												onClick={submitGroupChange}
												contentClassName="my-3 mx-7"
											/>
										</div>
									</div>
								) : (
									<InPageLoader position="relative" height="auto" />
								)}
							</div>
							{promoText && !changingGroup && !isSavingSummerModeData && <SummerPromoLegalCopy />}
						</Container>
					)}
				</Fragment>
			)}
		</EnrolmentLayout>
	);
};

HubEnrolmentChangeGroupSchedule.propTypes = {
	enrolmentSfid: PropTypes.string.isRequired,
	enrolmentId: PropTypes.string.isRequired,
	studentName: PropTypes.string,
	enrolmentSubject: PropTypes.string,
	enrolmentType: PropTypes.string,
	tutorName: PropTypes.string,
	tutorPhoto: PropTypes.string,
	enrolmentSchedule: enrolmentScheduleType,
	isPaused: PropTypes.bool,
	isLoading: PropTypes.bool.isRequired,
	loadingError: PropTypes.bool.isRequired,
	isLoadingEnrolment: PropTypes.bool.isRequired,
	loadingEnrolmentError: PropTypes.bool.isRequired,
	groupAvailabilities: groupPackageType,
	fetchGroups: PropTypes.func.isRequired,
	clearGroups: PropTypes.func.isRequired,
	changeGroup: PropTypes.func.isRequired,
	nextSessionDate: PropTypes.string,
	nextSessionEndDate: PropTypes.string,
	timezone: PropTypes.string.isRequired,
	changingGroup: PropTypes.bool.isRequired,
	changingGroupError: PropTypes.bool.isRequired,
	clearGroupError: PropTypes.func.isRequired,
	redirectToDetailPage: PropTypes.func.isRequired,
	fetchSummerModeData: PropTypes.func.isRequired,
	customerLinkId: PropTypes.string.isRequired,
	summerModeIncentives: PropTypes.arrayOf(PropTypes.string).isRequired,
	clearSavedEnrolment: PropTypes.func.isRequired,
	summerPromo: PropTypes.shape({
		incentiveCode: PropTypes.string,
		promoText: PropTypes.string,
	}),
	summerPromoConfirming: PropTypes.shape({
		incentiveCode: PropTypes.string,
		promoText: PropTypes.string,
	}),
	saveSummerModeData: PropTypes.func.isRequired,
};

HubEnrolmentChangeGroupSchedule.defaultProps = {
	studentName: '',
	enrolmentSubject: '',
	enrolmentType: '',
	tutorName: '',
	tutorPhoto: '',
	enrolmentSchedule: enrolmentScheduleDefault,
	isPaused: false,
	willBePaused: false,
	nextSessionDate: null,
	nextSessionEndDate: null,
	groupAvailabilities: [],
	summerPromo: {
		incentiveCode: '',
		promoText: '',
	},
	summerPromoConfirming: {
		incentiveCode: '',
		promoText: '',
	},
};

export { HubEnrolmentChangeGroupSchedule as PresentationalHubEnrolmentChangeGroupSchedule };
export const ConnectedHubEnrolmentChangeGroupSchedule = connect(
	(state) => {
		const { timezone } = state.hubUser;

		const {
			enrolmentSfid,
			enrolmentId,
			studentName,
			enrolmentSubject,
			tutorName,
			tutorPhoto,
			enrolmentSchedule,
			nextSessionDate,
			nextSessionEndDate,
			enrolmentType,
		} = state.hubEnrolmentDetailPage;

		const { firstFetch: isLoadingEnrolment, fetchError: loadingEnrolmentError } =
			state.ui.apiState.hubEnrolmentDetailPage;

		const {
			firstFetch: isLoadingGroups,
			fetchError: loadingGroupsError,
			changingGroup: { isLoading: changingGroup, fetchError: changingGroupError },
		} = state.ui.apiState.hubChangeGroupPage;

		return {
			enrolmentSfid,
			enrolmentId,
			studentName,
			enrolmentSubject,
			enrolmentType,
			tutorName,
			tutorPhoto,
			enrolmentSchedule,
			isPaused: isEnrolmentPaused(state),
			willBePaused: willEnrolmentBePaused(state),
			isLoading: isLoadingGroups,
			loadingError: loadingGroupsError,
			isLoadingEnrolment,
			loadingEnrolmentError,
			groupAvailabilities: getChangeGroupSessions(state),
			timezone,
			nextSessionDate,
			nextSessionEndDate,
			changingGroup,
			changingGroupError,
			summerModeIncentives: state.summerMode ? state.summerMode.summerIncentivesAndPromotions : null,
			summerPromo: getSummerPromo(state, { screen: PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP }),
			summerPromoConfirming: getSummerPromo(state, {
				screen: PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP,
				confirming: true,
			}),
		};
	},
	(dispatch) => {
		return {
			fetchGroups: bindActionCreators(fetchGroupSessions, dispatch),
			clearGroups: bindActionCreators(clearGroupSessions, dispatch),
			changeGroup: bindActionCreators(actionChangeGroup, dispatch),
			clearGroupError: bindActionCreators(clearGroupChangeError, dispatch),
			redirectToDetailPage: bindActionCreators(redirectToEnrolmentManagement, dispatch),
			fetchSummerModeData: bindActionCreators(actionFetchSummerModeData, dispatch),
			clearSavedEnrolment: bindActionCreators(saveSelectedEnrolment, dispatch),
			saveSummerModeData: bindActionCreators(saveSummerModeDataAction, dispatch),
		};
	}
)(WithSelectedEnrolment(HubEnrolmentChangeGroupSchedule, HOC_PAGE_PARAM.CHANGE_GROUP_SCHEDULE));

export { HubEnrolmentChangeGroupSchedule as StatelessHubEnrolmentChangeGroupSchedule };
