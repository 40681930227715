import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useHistory } from 'react-router';
import { generatePath } from 'react-router-dom';
import { LinkButton, Container, Alert } from '@cluey/cluey-components';
import { Term123PauseSessions } from '../../../../../components/EnrolmentManagement/PauseSessions/Term123PauseSessions';
import EnrolmentLayout from '../../../../../common/hub/EnrolmentLayout';
import { fetchEnrolmentForManagement as fetchEnrolmentDetail } from '../../../../../actions/hub/hubEnrolmentManagementActions';
import { fetchUpcomingSessionsWithSTPH as fetchData } from '../../../../../actions/hub/hubSessionsPageActions';
import { getTimezone } from '../../../../../selectors/hubSelector';
import { allowHolidayPause } from '../../../../../selectors/schoolTerms/schoolTermsSelectors';
import { isGroupSessionCheck, isEnrolmentPausedCheck, isEnrolmentCancelRequested } from '../../../../../util/helpers';
import InPageLoader from '../../../../../common/InPageLoader';
import { isFormPauseSessionsLoading } from '../../../../../selectors/loaderSelector';
import { isFormPauseSessionsLoadingFailed } from '../../../../../selectors/errorSelector';
import ErrorBlock from '../../../../../common/ErrorBlock';
import {
  PATH_HUB_CR_CANCEL_ENROLMENT,
  PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP,
  PATH_HUB_ENROLMENT_MANAGE_PAUSE,
  PATH_HUB_RESCHEDULE_SESSION,
  PATH_HUB_SKIP_SESSION,
  PATH_HUB_SUMMER_PAUSE_ENROLMENT,
} from '../../../../../util/pagePath';
import ReasonsToPause from '../../../../../components/hub/ReasonsToPause';
import { useGetSessionPolicy } from '../../../../../hooks/queries/useSessionQuery';
import { FormFlowProps, Session } from '../../../../../types/hubInterfaces';
import { InitialState } from '../../../../../types/hubTypes';
import { saveSelectedEnrolment as clearSavedEnrolment } from '../../../../../actions/hub/hubContactPageActions';
import { submitNewPause as submit } from '../../../../../actions/hub/hubPauseActions';
import { getPauseSessionsOptionsByFrequency } from '../../../../../selectors/enrolmentManagementSelector';
import { PauseUnavailable } from '../PauseUnavailable';
import { getSession } from '../../../../../selectors/sessionSelector';
import withSelectedEnrolment, { SelectedEnrolment } from '../../../../../common/hub/NewWithSelectedEnrolment';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { PATH_HUB_CR_PAUSE_SESSIONS } from '../../../../../util/pagePath';

interface Props {
  formFlowProps: FormFlowProps;
  selectedEnrolment: SelectedEnrolment;
}

const PauseConfirm = ({ formFlowProps, selectedEnrolment }: Props) => {
  const isLoading: boolean = useSelector(isFormPauseSessionsLoading);
  const failedToLoad: boolean = useSelector(isFormPauseSessionsLoadingFailed);
  const timezone: string = useSelector(getTimezone);
  const sessions: Array<Session> = useSelector((state: InitialState) => state.hubSessionsPage.upcomingSessions);
  const {
    isLoading: isSubmitting,
    fetchError: submitError,
    errorMsg,
  } = useSelector((state: InitialState) => state.ui.apiState.hubContactPage);
  const { resumeSessionDate } = useSelector((state: InitialState) => state.hubContactPage.pauseSessions);
  const { nextSessionStudentSessionId } = useSelector((state: InitialState) => state.hubEnrolmentDetailPage);
  const nextSession = useSelector(getSession({ studentSessionId: nextSessionStudentSessionId }));
  const schoolTermHolidayPauseEnabled = useSelector(allowHolidayPause);
  const skipByNextOptionData = useSelector(getPauseSessionsOptionsByFrequency);
  const dispatch = useDispatch();
  const suggestSummerPause = false;

  const {
    data: sessionPolicy,
    isLoading: isPolicyAcquisitionLoading,
    error: sessionPolicyError,
  } = useGetSessionPolicy({
    enrolmentId: selectedEnrolment?.enrolmentSfid,
  });

  const { data: nextSessionPolicy, error: nextSessionPolicyError } = useGetSessionPolicy({
    sessionId: nextSessionStudentSessionId,
  });

  useEffect(() => {
    dispatch(fetchData());
    return () => {
      dispatch(clearSavedEnrolment({ enrolmentId: '' }));
    };
  }, [dispatch]);

  const {
    enrolmentId,
    student,
    subject,
    enrolmentStatus,
    enrolmentPausedFrom,
    enrolmentPausedTo,
    enrolmentSfid,
    type,
  } = selectedEnrolment;
  const history = useHistory();

  /* CRE-103 Redirect Pause Session/Manage Pause */
  useEffect(() => {
    const isEnrolmentPaused = isEnrolmentPausedCheck({ enrolmentStatus });
    const isGroup = isGroupSessionCheck(type);
    const redirectPath = isGroup
      ? PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP
      : `${PATH_HUB_ENROLMENT_MANAGE_PAUSE}?token=${enrolmentId}`;

    if (isEnrolmentPaused) {
      dispatch(
        fetchEnrolmentDetail({
          enrolmentId,
          callback: () => {
            history.replace(redirectPath);
          },
        })
      );
      return undefined;
    }
    return undefined;
    // eslint-disable-next-line
  }, [enrolmentStatus, enrolmentPausedFrom, enrolmentPausedTo, type]);

  if (isEnrolmentCancelRequested({ enrolmentStatus })) {
    history.push(`${PATH_HUB_CR_CANCEL_ENROLMENT}?view=form&token=${enrolmentId}`);
  }

  let display;
  if (isLoading || isPolicyAcquisitionLoading) {
    return <InPageLoader />;
  }

  if (failedToLoad || sessionPolicyError) {
    display = <ErrorBlock errorMsg="Failed to load data, please try again later." />;
  }

  const onSubmissionHandler = ({ startDate, endDate }) => {
    /* startDate and endDate should be in YYYY-MM-DD format */

    /* the resume session date comes from Salesforce and uses Sydney timezone  */
    const resumeFrom = resumeSessionDate ? moment(resumeSessionDate).subtract(1, 'day').format('YYYY-MM-DD') : endDate;

    const payload = {
      enrolmentId: enrolmentSfid,
      startDate,
      endDate: resumeFrom,
      onSubmitSuccess: () => {
        formFlowProps.next({ enrolment: selectedEnrolment });
      },
    };
    dispatch(submit(payload));
  };

  // OLD: check whether it is term 4 after term4 start and before term 1 start
  // NEW: summer mode form will be enabled in AppRoute.js
  if (!isLoading && !failedToLoad) {
    display = (
      <Term123PauseSessions
        timezone={timezone}
        enrolmentId={enrolmentId}
        sessions={sessions}
        isSubmitting={isSubmitting}
        sessionPolicy={sessionPolicy}
        submit={onSubmissionHandler}
      />
    );
  }

  const getNextSessionRedirectLink = () => {
    if (!nextSessionStudentSessionId) {
      return '';
    }
    return nextSessionPolicy?.reschedule?.isAllowed
      ? generatePath(PATH_HUB_RESCHEDULE_SESSION, { sessionId: nextSessionStudentSessionId })
      : generatePath(PATH_HUB_SKIP_SESSION, { sessionId: nextSessionStudentSessionId });
  };

  const mainHeading = student && subject ? `${student} ${subject}` : '';
  return (
    <EnrolmentLayout
      isLoading={isPolicyAcquisitionLoading}
      sectionHeading={mainHeading}
      mainHeading="Pause enrolment"
      enrolmentId={enrolmentId}
      hasCTAAboveFooter
    >
      {!schoolTermHolidayPauseEnabled &&
      (sessionPolicy?.pause.pauseAllowed !== 'Anytime' || isEmpty(skipByNextOptionData)) ? (
        <PauseUnavailable
          enrolmentId={enrolmentId}
          sessionPolicy={sessionPolicy}
          suggestSummerPause={suggestSummerPause}
        />
      ) : (
        <Fragment>
          <Container>
            {submitError && errorMsg && <ErrorBlock errorMsg={errorMsg} />}
            <div className="mb-10 rounded-lg bg-slate-1 px-4 pb-2 pt-2 md:px-10 md:pt-4">
              <ReasonsToPause title="Take a break by pausing tutoring sessions" showSubText={false} />
              {suggestSummerPause && (
                <div className="mb-6 mt-5 text-grey-6">
                  <p>Are you looking to pause for summer public school holidays?</p>
                  <LinkButton
                    className="mt-4"
                    appearance="reverse"
                    to={`${PATH_HUB_SUMMER_PAUSE_ENROLMENT}?token=${enrolmentId}`}
                  >
                    Pause for summer here
                  </LinkButton>
                </div>
              )}
            </div>
            <div>{display}</div>
          </Container>
          {nextSession && nextSessionPolicy && (
            <div className="bg-slate-1 py-10 text-center lg:-mb-5">
              <p className="mx-10 mb-5 font-display text-xl font-bold lg:text-2xl">
                Looking to change your next session only?
              </p>
              {nextSessionPolicyError ? (
                <div className="m-auto max-w-2xl px-4">
                  <Alert
                    className="mt-2"
                    type="error"
                    body={`There was an error retrieving the next session's policy. ${nextSessionPolicyError}`}
                  />
                </div>
              ) : (
                <LinkButton size="xs" appearance="reverse" to={getNextSessionRedirectLink()}>
                  CHANGE UPCOMING SESSION
                </LinkButton>
              )}
            </div>
          )}
        </Fragment>
      )}
    </EnrolmentLayout>
  );
};

export default compose(withSelectedEnrolment(PauseConfirm, null, PATH_HUB_CR_PAUSE_SESSIONS));
