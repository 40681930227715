import { createContext, ReactElement, useContext, useEffect, useState } from 'react';
import useMessageStore from './useMessageStore';
import { Enrolment } from '../../api/types/enrolment';
import { Topic } from './VirtualCoach/topics/index';
import useGetRemainingRequests from './useGetRemainingRequests';

export enum UserFlowSteps {
  CHOOSE_A_TOPIC = 'CHOOSE_A_TOPIC',
  CREATE_PROMPT = 'CREATE_PROMPT',
  CONTINUE_CHATTING = 'CONTINUE_CHATTING',
  RESTRICTED = 'RESTRICTED',
}

type MessageStore = ReturnType<typeof useMessageStore>;

export type TAiTutorContext = {
  chatId: string | null;
  setChatId: (chatId: string | null) => void;
  currentStep: UserFlowSteps;
  setCurrentStep: (step: UserFlowSteps) => void;
  enrolment: Enrolment;
  question: string;
  setQuestion: (question: string) => void;
  topic: Topic;
  setTopic: (topic: Topic) => void;
  error: Error;
  setError: (error: Error) => void;
} & MessageStore;

export const AiTutorContext = createContext<TAiTutorContext | null>(undefined);

export const AiTutorContextProvider = ({ children, enrolment }: { children: ReactElement; enrolment: Enrolment }) => {
  const messageStore = useMessageStore();
  const { data: remainingRequests } = useGetRemainingRequests(enrolment.enrolmentId);

  const [chatId, setChatId] = useState<string>(null);
  const [currentStep, setCurrentStep] = useState(UserFlowSteps.CHOOSE_A_TOPIC);
  const [question, setQuestion] = useState<string>(null);
  const [topic, setTopic] = useState<Topic>(null);
  const [error, setError] = useState<Error>(null);

  useEffect(() => {
    if (remainingRequests <= 0 && currentStep === UserFlowSteps.CHOOSE_A_TOPIC) {
      setCurrentStep(UserFlowSteps.RESTRICTED);
    }
  }, [remainingRequests, setCurrentStep, currentStep]);

  return (
    <AiTutorContext.Provider
      value={{
        ...messageStore,
        chatId,
        setChatId,
        currentStep,
        setCurrentStep,
        enrolment,
        question,
        setQuestion,
        topic,
        setTopic,
        error,
        setError,
      }}
    >
      {children}
    </AiTutorContext.Provider>
  );
};

export default function useAiTutorContext() {
  const context = useContext(AiTutorContext);

  return context;
}
