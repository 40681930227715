import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LinkButton, AnchorButton } from '@cluey/cluey-components';

import alertInfo from '../../../assets/images/icon-filled-alert-info.svg';
import alertExclamation from '../../../assets/images/icon-filled-alert-exclamation.svg';
import alertExclamationRed from '../../../assets/images/icon-filled-alert-exclamation-red.svg';
import alertTick from '../../../assets/images/icon-filled-alert-tick.svg';
import star from '../../../assets/images/atoms-icon-filled-star.svg';
import { PATH_HUB_INVOICE_HISTORY } from '../../../util/pagePath';

const infoBoxStyle = (type) => {
  switch (type) {
    case 'info':
      return ['bg-blue-1', alertInfo];
    case 'starred':
      return ['bg-yellow-1', star];
    case 'warning':
      return ['bg-yellow-1', alertExclamation];
    case 'error':
      return ['bg-red-1', alertExclamationRed];
    case 'success':
      return ['bg-green-1', alertTick];
    default:
      return ['bg-blue-1', alertInfo];
  }
};

// NOTE: may integrate with new re-boarding banner layout later.
export const InfoBox = ({
  title,
  body,
  href,
  type,
  ctaLabel,
  containerClasses,
  bodyContainerClasses,
  btnWithLongLabel,
  sameTab,
  cyTargetClass,
}) => {
  const [backgroundColorClass, alertIcon] = infoBoxStyle(type);
  const btnWrap = btnWithLongLabel
    ? 'lg:w-1/4 lg:ml-1/12 lg:flex lg:items-baseline'
    : 'lg:w-1/6 lg:ml-1/6';
  const renderBody = typeof body === 'string' ? <p className="mb-0">{body}</p> : body;

  return (
    <div
      className={classNames(
        'mb-2 rounded-lg px-6 py-5',
        backgroundColorClass,
        containerClasses,
        cyTargetClass
      )}
    >
      <div className="flex flex-col lg:flex-row">
        <div
          className={classNames('mb-5 flex w-full items-start lg:mb-0', {
            'lg:w-2/3': !!href,
          })}
        >
          <img
            src={alertIcon}
            alt="icon"
            className="mr-5 lg:mr-4"
            width={25}
            height={25}
          />
          <div className={bodyContainerClasses}>
            {title && (
              <h4 className="block text-base font-bold leading-tight">
                {title}
              </h4>
            )}
            {body && renderBody}
          </div>
        </div>
        {href && (
          <div
            className={`w-full text-center lg:ml-auto lg:text-right ${btnWrap}`}
          >
            {sameTab ? (
              <LinkButton to={href} appearance="reverse" size="xs">
                {ctaLabel}
              </LinkButton>
            ) : (
              <AnchorButton
                href={href}
                role="button"
                target="blank"
                className="text-decoration-none ml-auto block uppercase"
                size="xs"
                appearance="reverse"
              >
                {ctaLabel}
              </AnchorButton>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

InfoBox.propTypes = {
  title: PropTypes.string,
  body: PropTypes.oneOf([ PropTypes.string, PropTypes.node ]),
  bodyContainerClasses: PropTypes.string,
  href: PropTypes.string,
  type: PropTypes.oneOf(['info', 'warning', 'error', 'success']),
  ctaLabel: PropTypes.string,
  containerClasses: PropTypes.string,
  btnWithLongLabel: PropTypes.bool,
  sameTab: PropTypes.bool,
  cyTargetClass: PropTypes.string,
};

InfoBox.defaultProps = {
  title: '',
  body: '',
  href: '',
  type: 'info',
  ctaLabel: 'Learn more',
  containerClasses: '',
  bodyContainerClasses: '',
  btnWithLongLabel: false,
  sameTab: false,
  cyTargetClass: '',
};

export const MigrationInfoBox = () => {
  return (
    <InfoBox
      title="Important information about your payments"
      body="From March 12, 2022 we’re improving our billing system to provide more visibility of charges and discounts."
      href="https://clueylearning.com.au/support/billing-changes-2022/"
    />
  );
};

export const PaymentFailureAlertBox = ({ message }) => {
  return (
    <InfoBox
      title="Payment failure"
      body={message}
      href={PATH_HUB_INVOICE_HISTORY}
      type="error"
      ctaLabel="Update details"
      btnWithLongLabel
    />
  );
};

PaymentFailureAlertBox.propTypes = {
  message: PropTypes.string.isRequired,
};
