import { useQuery } from '@tanstack/react-query';
import { BillingInformationService } from '../../services/PortalAPIServices/BillingInformationService';
import { GET_BILLING_INFORMATION_QKEY } from '../../util/constants/queryKeys';

const useBillingInformationQuery = (enabled = true) => {
  return useQuery({
    queryKey: [GET_BILLING_INFORMATION_QKEY],
    queryFn: () => BillingInformationService.getBillingInformation(),
    staleTime: 600000,
    enabled,
  });
}

export default useBillingInformationQuery;