import initialState from '../initialState';
import * as actionTypes from '../../actions/actionTypes';
import {
  SR_CAN_ACCESS,
  SR_INITIAL_AUTH_FAILED,
  SR_STARTUP,
} from '../../actions/sessionReportAppLifecycleSteps';


export default function appLifecycleReducer(state = initialState.ui.appLifecycle, action) {
  switch (action.type) {
    case actionTypes.STPG_FETCH_DISCIPLINE_LIST_SUCCESS:
    case actionTypes.STPG_FETCH_DISCIPLINE_LIST_FAILED:
      return {
        ...state,
        currentStep: SR_CAN_ACCESS,
      };
    case actionTypes.SR_LOGIN_API_FAILURE:
      return {
        ...state,
        currentStep: SR_INITIAL_AUTH_FAILED,
      };
    case actionTypes.SR_LOGIN_RESET:
      return {
        ...state,
        currentStep: SR_STARTUP
      }; 
    default:
      return state;
  }
}
