import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import GoBack from './GoBack';
import ContactScreenWrap from './ContactScreenWrap';
import ContactOptionItem from './ContactOptionItem';
import ContactPhone from './ContactPhone';

import { APP_CONTACT_FLOW_SPECIFIC, GENERAL_CONTACT_OPTIONS, SPECIFIC_CONTACT_OPTIONS } from '../../util/constants';

const ContactOptions = ({ contactUsFlowType, sessionDetail }) => {
  let contactOptions = GENERAL_CONTACT_OPTIONS;
  if (contactUsFlowType === APP_CONTACT_FLOW_SPECIFIC) {
    contactOptions = SPECIFIC_CONTACT_OPTIONS;
  }

  return (
    <Fragment>
      <GoBack />
      <ContactScreenWrap ciClass="ci--question-mark" h1Text="How can we help?">
        <div className="relative z-0 rounded bg-slate-1 p-3 md:p-9 lg:px-16 lg:py-20">
          {sessionDetail.length > 0 && (
            <h3 className="mb-4 text-center text-base font-medium md:text-xl lg:mb-8 lg:text-2xl">{sessionDetail}</h3>
          )}
          <ul className="list-group list-contact-options text-left">
            {contactOptions.map((option) => {
              const {
                label,
                ciClass,
                action = '',
                caseCategory,
                caseType,
                recordType,
                sessionForm = null,
                enrolmentForm = null,
                changeSchedule = null,
              } = option;
              return (
                <ContactOptionItem
                  key={label + ciClass}
                  label={label}
                  ciClass={ciClass}
                  action={action}
                  caseCategory={caseCategory}
                  caseType={caseType}
                  recordType={recordType}
                  sessionForm={sessionForm}
                  enrolmentForm={enrolmentForm}
                  changeSchedule={changeSchedule}
                />
              );
            })}
          </ul>
        </div>
      </ContactScreenWrap>
      <ContactPhone />
    </Fragment>
  );
};

ContactOptions.propTypes = {
  contactUsFlowType: PropTypes.string.isRequired,
  sessionDetail: PropTypes.string,
};

ContactOptions.defaultProps = {
  sessionDetail: '',
};

export default connect((state) => {
  const { contactUsFlow } = state;
  return {
    contactUsFlowType: contactUsFlow.flowType,
    sessionDetail: contactUsFlow.sessionDetail,
  };
}, null)(ContactOptions);

export { ContactOptions as PresentationalContactOptions };
