import { AnchorButton, Container } from '@cluey/cluey-components';
import { useSelector } from 'react-redux';
import EnrolmentLayout from '../../../common/hub/EnrolmentLayout';
import { PATH_HUB_ENROLMENT_DETAIL } from '../../../util/pagePath';
import { useRescheduleSessionState } from './useRescheduleSessionState';
import { FormFlowProps } from '../../../types/hubInterfaces';
import { InitialState } from '../../../types/hubTypes';
import { DATE_FORMAT_TYPE_12, SESSION_TIME_FORMAT } from '../../../util/constants';
import moment from 'moment';
import { generatePath, useHistory, useParams } from 'react-router';

interface Props {
  formFlowProps: FormFlowProps<{
    scheduleDate: string;
    sessionDuration: number;
    isTempTutor: boolean;
  }>;
}

type State = {
  scheduleDate: string;
  isTempTutor: boolean;
};

const HubRescheduleSessionSuccessPage = ({ formFlowProps }: Props) => {
  console.log(formFlowProps);
  const history = useHistory<State>();
  const { location: { state } } = history;
  //   @ts-ignore
  const { sessionId } = useParams();

  const { scheduleDate, isTempTutor } = state;
  const { sectionHeading } = useRescheduleSessionState();
  const { enrolmentId, studentName, enrolmentSubject } = useSelector(
    (state: InitialState) => state.hubEnrolmentDetailPage
  );
  const [firstname] = studentName?.split(' ');
  const nameWithSubject = `${firstname}'s ${enrolmentSubject}`;
  const { timezone } = useRescheduleSessionState();
  const nextSessionDay = moment(scheduleDate).tz(timezone).format(DATE_FORMAT_TYPE_12);
  const nextSessionStartTime = moment(scheduleDate).tz(timezone).format(SESSION_TIME_FORMAT);

  return (
    <EnrolmentLayout
      sectionHeading={sectionHeading}
      mainHeading={`${isTempTutor ? 'Session' : 'Change'} confirmed`}
      enrolmentId={enrolmentId}
    >
      <Container>
        {isTempTutor ? (
          <p className="lh-1-2 mb-8 text-xl md:w-3/4 lg:mb-8 lg:w-8/12">
            {nameWithSubject} session on {nextSessionDay} at {nextSessionStartTime} will go ahead with a temporary
            tutor.
          </p>
        ) : (
          <p className="lh-1-2 mb-8 text-xl md:w-3/4 lg:mb-8 lg:w-8/12">
            We have changed your upcoming session time, you will see it reflected on your enrolment and session details
            soon. You will also receive an email confirming the details soon.
          </p>
        )}
        <AnchorButton
          className="mt-8"
          size="xs"
          appearance="reverse"
          href={generatePath(`${PATH_HUB_ENROLMENT_DETAIL}?token=${enrolmentId}`, { sessionId })}
          target="self"
        >
          View {nameWithSubject} enrolment
        </AnchorButton>
      </Container>
    </EnrolmentLayout>
  );
};

export default HubRescheduleSessionSuccessPage;
