import { get } from 'lodash';
import moment from 'moment';
import env from '../../env';
import { logError, WARNING } from '../../logging';
import {
  saveCredentials,
  handleGetCredentialsFailed,
} from '../../actions/hub/HubHelperActions';
import { getCurrentCredentials } from '../awsAuth';
import {
  getStudentId, getDiscipline, getYearLevel, getCourseMode, getSubject,
} from '../../selectors/requestOptionsSelector';
import { AUTHENTICATION_STATUS, ROLE_STAFF } from '../../util/constants';

const aws4 = require('aws4');

export async function getAwsCredentials(state, dispatch) {
  const authenticationStatus = get(state, 'login.authenticationStatus');
  const expiration = get(state, 'login.credentials.expiration');
  const isTokenExpired = moment(expiration).isBefore(moment());

  if (
    authenticationStatus === AUTHENTICATION_STATUS.AUTHENTICATED &&
    isTokenExpired
  ) {
    logError('Hub Token expired', null, { level: WARNING });
    try {
      const credentials = await getCurrentCredentials();
      dispatch(saveCredentials({ credentials }));
      return {
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
      };
    } catch (e) {
      logError(e, null, { message: 'Unable to refresh cognito token' });
      dispatch(handleGetCredentialsFailed(e));
    }
  }
  return {
    secretAccessKey: state.login.credentials.secret,
    accessKeyId: state.login.credentials.accessKey,
    sessionToken: state.login.credentials.sessionToken,
  };
}

export const processPath = (path, state) => path
  .replace('[PKG_CODE]', () => state.login.packageCode)
  .replace('[STUDENT_ID]', getStudentId(state))
  .replace('[DISCIPLINE]', getDiscipline(state))
  .replace('[YEAR_LEVEL]', getYearLevel(state))
  .replace('[COURSE_MODE]', getCourseMode(state))
  .replace('[SUBJECT]', getSubject(state))
  .replace('[TOKEN]', () => state.login.initialToken)
  .replace('[UPCOMING_SESSION_TOKEN]', () => state.upcomingSessions.token)
  .replace('[RESCHEDULE_SESSION_TOKEN]', () => state.rescheduleSession.token)
  .replace(
    '[ACTIVITY_FETCH_NEXT_PAGE]',
    () => state.ui.apiState.activities.nextPage || 1,
  )
  .replace('[ENROLMENT_ID]', () => state.hubEnrolmentDetailPage.enrolmentId)
  .replace('[TUTOR_ID]', () => state.hubEnrolmentDetailPage.alternativeTutor.tutorNumber)
  .replace('[SELECTED_TUTOR_ID]', () => state.hubEnrolmentDetailPage.selectedSchedule.tutorNumber)
  .replace('[MODULE_ID]', () => state.studentProgress.selectedModule.id)
  .replace('[ENROLMENT_TO_PAUSE]', () => state.hubContactPage.selectedEnrolment)
  .replace('[PAUSE_TO_DATE]', () => state.hubContactPage.pauseSessions.resumeFromDate);

/**
 * Prepares the request options for the specified type.
 * @param {boolean} signRequest     Should the request be signed with AWS credentials?
 * @param {object} state            Redux state
 * @param  {Function} dispatch      Redux function, used to retrieve new cognnito credential
 * @param {object} apiOptions       { method, path, host } parameters for the endpoint
 * @param {object|void} payload     optional request payload
 * @returns {object}                Object keys are url and reqOptions
 */
export default async function getRequestOptions(
  signRequest,
  state,
  dispatch,
  {
    method, path, host, region,
  },
  payload,
) {
  const reqOptions = {
    host,
    method,
    path: processPath(path, state),
    region: region || state.login.credentials.region || 'ap-southeast-2',
    service: 'execute-api',
    body: payload && method !== 'GET' ? JSON.stringify(payload) : undefined,
  };

  const url = `${env.REACT_APP_API_PROTOCOL}://${reqOptions.host}/${reqOptions.path}`;

  // sign the request options
  if (signRequest) {
    const { role } = state.login;
    if (role === ROLE_STAFF) {
      const { staffToken } = state.login.credentials;

      reqOptions.headers = {
        ...reqOptions.headers,
        stafftoken: staffToken,
      };
    }
    aws4.sign(reqOptions, await getAwsCredentials(state, dispatch));
  } else {
    reqOptions.headers = {};
  }

  return {
    url,
    reqOptions,
  };
}
