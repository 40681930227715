import { Fragment, useState, useEffect, useMemo } from 'react';
import { isEmpty, upperFirst } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { isGroupSessionCheck, scrollToTop } from '../../../util/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { getTerm123PauseSessionsFormData } from '../../../selectors/hubSelector';
import { compose } from 'redux';

import WithSelectedEnrolment from '../../../common/hub/NewWithSelectedEnrolment';
import EnrolmentManagementHOC from '../../../common/hub/EnrolmentManagementHOC';
import EnrolmentLayout from '../../../common/hub/EnrolmentLayout';
import { EnrolmentBrief } from '../../../components/EnrolmentManagement/EnrolmentBrief';
import { useDatePickerProps, DatePicker } from '../../../components/DatePicker/DatePicker';
import { Button, Checkbox, Dropdown, LinkButton, Alert } from '@cluey/cluey-components';
import InPageLoader from '../../../common/InPageLoader';
import PageError from '../../../common/PageError';
import ErrorBlock from '../../../common/ErrorBlock';
import Day from '../../../components/EnrolmentManagement/Day';
import { AvailabilitiesOfADayUnconnected } from '../../../components/EnrolmentManagement/AvailabilitiesOfADay';
import { ConnectedChangeScheduleConfirmationCSP } from '../../../components/EnrolmentManagement/ChangeScheduleConfirmation';
import { EnrolmentManagementConfirmed } from '../../../components/EnrolmentManagement/EnrolmentManagementConfirmed';
import { getTimezone } from '../../../selectors/hubSelector';
import { useGetNextSession, useGetSessionPolicy } from '../../../hooks/queries/useSessionQuery';

import { fetchSummerModeData, saveSummerModeData } from '../../../actions/summerModeActions';

import { isEnrolmentPaused } from '../../../selectors/hubSelector';
import {
  saveSelectedSchedule,
  resetChangeSchedulePage,
  updateSchedule,
} from '../../../actions/hub/hubChangeSchedulePageActions';

import { CHANGE_SCHEDULE_STEPS, HOC_PAGE_PARAM, SCHEDULE_DATE_FORMAT } from '../../../util/constants';
import { TRACKING_CSP } from '../../../util/trackingClasses';
import { ChoiceButton } from '../../../components/ChoiceButton/ChoiceButton';
import { ChangeSummary } from '../../../components/EnrolmentManagement/ChangeSummary';
import { Textarea } from '../../../components/Textarea';
import { PromoText } from '../../../components/EnrolmentManagement/PromoText';
import {
  PATH_HUB_ENROLMENT_CHANGE_SCHEDULE,
  PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP,
  PATH_HUB_ENROLMENT_DETAIL,
} from '../../../util/pagePath';
import { ENR_SESSION_FREQUENCY } from '../../../util/constants/enrolmentConstants';
import { isIncentiveValid } from '../../../util/summerCopy';
import { willEnrolmentBePaused } from '../../../selectors/enrolmentSelector';
import { SUMMER_15PCT_ALL_SESSIONS } from '../../../util/constants/incentives';
import { saveSelectedEnrolment } from '../../../actions/hub/hubContactPageActions';
import { getSummerPromo } from '../../../selectors/summerModeSelector';
import { summerModeFromFrequencyChange } from '../../../util/summerModeFromFrequencyChange';
import SummerPromoLegalCopy from '../../../components/EnrolmentManagement/SummerMode/SummerPromoLegalCopy';
import { useGetAvailableSlotsForSchedule } from '../../../hooks/queries/useSchedulesQuery';
import { InitialState } from '../../../types/hubTypes';
import { useSearchParams } from '../../../util/hooks/useSearchParams';
import { api } from '../../../api';

const getDays = (stateDay) => {
  const days = [];
  for (let i = 0; i < 7; i++) {
    days.push({
      dayRaw: new Date(stateDay).fp_incr(i),
    });
  }
  return days;
};
const frequencyLabel = (frequency) => {
  if (frequency === ENR_SESSION_FREQUENCY.WEEKLY) {
    return 'weekly';
  }
  if (frequency === ENR_SESSION_FREQUENCY.FORTNIGHTLY) {
    return 'fortnightly';
  }
  return 'more than once a week';
};

const frequencyList = (frequency) => {
  const frequencyMapper = (sessFreq) => ({
    value: sessFreq,
    label: frequencyLabel(sessFreq),
  });

  if (frequency === ENR_SESSION_FREQUENCY.WEEKLY) {
    return [
      ENR_SESSION_FREQUENCY.WEEKLY,
      ENR_SESSION_FREQUENCY.FORTNIGHTLY,
      ENR_SESSION_FREQUENCY.MULTIPLE_SESSIONS,
    ].map(frequencyMapper);
  }
  if (frequency === ENR_SESSION_FREQUENCY.FORTNIGHTLY) {
    return [
      ENR_SESSION_FREQUENCY.FORTNIGHTLY,
      ENR_SESSION_FREQUENCY.WEEKLY,
      ENR_SESSION_FREQUENCY.MULTIPLE_SESSIONS,
    ].map(frequencyMapper);
  }
  return [ENR_SESSION_FREQUENCY.MULTIPLE_SESSIONS, ENR_SESSION_FREQUENCY.WEEKLY, ENR_SESSION_FREQUENCY.FORTNIGHTLY].map(
    frequencyMapper
  );
};

const sessionsPerWeek = [
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
];

const HubEnrolmentChangeSchedule = () => {
  const enrolmentId = useSearchParams().get('token');
  const { 
    data: enrolmentDetails,
    isLoading: isLoadingEnrolmentDetails,
    error: loadingEnrolmentError
  } = api.enrolment.detail.useQuery({ enrolmentId });
  const {
    enrolmentSfid,
    packageSfid,
    studentName,
    enrolmentSubject,
    tutorName,
    tutorPhoto,
    enrolmentSchedule,
    sessionFrequency,
    customerNumber,
    enrolmentType,
    tutorNumber,
    tutorSfId,
    enrolmentPausedFrom
  } = enrolmentDetails ?? {}; 

  const summerModeIncentives = useSelector((state: InitialState) => {
    return state.summerMode ? state.summerMode.summerIncentivesAndPromotions : null;
  });

  const { data: nextSession, isLoading: isLoadingNextSession } = useGetNextSession({
    enrolmentId,
    enabled: true,
  });

  const nextSessionWith4Hrs = useMemo(() => {
    if (nextSession) {
      const startTime = moment(nextSession.startTime);
      const now = moment();

      console.log({ startTime: startTime.toISOString() });

      const HOURS = 4;

      return startTime.isAfter(now) && startTime.diff(now, 'hours') <= HOURS;
    } else {
      return false;
    }
  }, [nextSession]);

  const timezone = useSelector(getTimezone);
  const summerPromo = useSelector((state: InitialState) =>
    getSummerPromo(state, { screen: PATH_HUB_ENROLMENT_CHANGE_SCHEDULE })
  );
  const summerPromoConfirming = useSelector((state: InitialState) =>
    getSummerPromo(state, { screen: PATH_HUB_ENROLMENT_CHANGE_SCHEDULE, confirming: true })
  );
  const customerLinkId = useSelector((state: InitialState) => state.hubUser.customerLinkId);
  const isPaused = useSelector(isEnrolmentPaused);
  const willBePaused = useSelector(willEnrolmentBePaused);
  const { fetchError: loadingError } = useSelector((state: InitialState) => state.ui.apiState.hubChangeSchedulePage);

  const dispatch = useDispatch();
  const history = useHistory();
  const { promoText, incentiveCode } = summerPromo;
  const [activeComponent, setActiveComponent] = useState(CHANGE_SCHEDULE_STEPS.DEFAULT);
  const {
    data: enrolmentPolicy,
    isLoading: isPolicyAcquisitionLoading,
    error: enrolmentPolicyError,
  } = useGetSessionPolicy({
    enrolmentId: enrolmentSfid,
  });

  const isLoadingData = isPolicyAcquisitionLoading || isLoadingEnrolmentDetails;
  const hasErrors = loadingEnrolmentError || enrolmentPolicyError;
  const { nextTermStartDate } = useSelector(getTerm123PauseSessionsFormData);

  const [activeDay, setActiveDay] = useState(null);
  const [tabDays, setTabDays] = useState([]);
  const [additionalMessage, setAdditionalMessage] = useState('');
  const [frequencyChange, setFrequencyChange] = useState(null);
  const [selectedSummerMode, setSelectedSummerMode] = useState(undefined);
  const [errors, setErrors] = useState([]);
  const [selectedPerWeek, setSelectedPerWeek] = useState('');
  const [pending, setPending] = useState(false);

  // NOTE, all pages use EnrolmentLayout have scrollToTop in EnrolmentLayout called once
  // but need to keep this for this page since it has view changes
  useEffect(() => {
    scrollToTop();
  }, [activeComponent]);

  useEffect(() => {
    // check for an incentive
    if (summerModeIncentives === null) {
      dispatch(fetchSummerModeData({ customerLinkId }));
    }
  }, [dispatch, summerModeIncentives, customerLinkId]);

  useEffect(() => {
    return () => {
      dispatch(resetChangeSchedulePage());
      dispatch(saveSelectedEnrolment({ enrolmentId: '' }));
    };
    // eslint-disable-next-line
  }, []);
  // Note: https://clueylearning.atlassian.net/browse/CUP-519
  // const heading = isPaused ?
  //   'When would you like to restart weekly sessions?' : 'When would you like the new weekly schedule to begin?';
  const heading = `When would you like the new ${(
    (frequencyChange || {}).value || ''
  ).toLowerCase()} schedule to begin?`;

  const maxPauseWeeks = Number(enrolmentPolicy?.pause?.allowedWeeks);
  const futureResumptionLimit =
    enrolmentPolicy?.pause?.pauseAllowed === 'Anytime'
      ? moment(enrolmentPausedFrom).add(maxPauseWeeks, 'weeks')
      : moment(nextTermStartDate).tz(timezone).subtract(1, 'day');

  /* @TODO check */
  const maxDate =
    isPaused || willBePaused ? new Date(futureResumptionLimit.format('YYYY-MM-DD')) : new Date().fp_incr(29);
  const datePickerProps = useDatePickerProps({
    labelText: 'Effective from:',
    placeholder: 'Select start date',
    customInputClasses: 'lg:w-1/2',
    options: {
      enableTime: false,
      minDate: new Date().fp_incr(1),
      maxDate,
      altInput: true,
      altFormat: 'l j F, Y',
      dateFormat: 'Y-m-d',
    },
  });

  const {
    isFetching,
    isLoading,
    data: altScheds,
  } = useGetAvailableSlotsForSchedule({
    enabled: activeComponent !== CHANGE_SCHEDULE_STEPS.B_CHOOSE && !!datePickerProps.value?.[0],
    packageId: packageSfid,
    // date picker value is midnight in local time, we want to reschedule on a midnight in Sydney time (or whatever the customer timezone is)
    rescheduleFrom: moment(datePickerProps?.value?.[0]).tz(timezone, true).toDate(),
    sessionFrequency: frequencyChange?.value,
  });

  useEffect(() => {
    if (Array.isArray(datePickerProps.value) && datePickerProps.value[0] instanceof Date === true) {
      const selectedDateRaw = datePickerProps.value[0];
      setActiveDay(selectedDateRaw);
      setTabDays(getDays(selectedDateRaw));
    }
  }, [datePickerProps.value]);

  if (isGroupSessionCheck(enrolmentType)) {
    history.push(`${PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP}?token=${enrolmentId}`);
  }

  const frequencyOptions = useMemo(() => frequencyList(sessionFrequency), [sessionFrequency]);

  const [showCurrentTutorOnly, setShowCurrentTutorOnly] = useState(false);

  const dayClickHandler = (day) => {
    setActiveDay(day);
  };

  const onAvailabilityItemClick = ({
    selectedDay,
    scheduleDate,
    sessionDuration,
    // eslint-disable-next-line no-shadow
    tutorNumber,
    startDate,
  }) => {
    dispatch(
      saveSelectedSchedule({
        selectedDay,
        scheduleDate,
        sessionDuration,
        tutorNumber,
        sessionFrequency: frequencyChange.value,
        startDate,
      })
    ).then(() => {
      setActiveComponent(CHANGE_SCHEDULE_STEPS.A_CONFIRMATION);
    });
  };

  const [isSavingSummerModeData, setIsSavingSummerModeData] = useState(false);

  const onConfirmationChangeClick = () => {
    // Note: may have to clear state here
    datePickerProps.setValue(null);
    setActiveDay(null);
    setTabDays([]);
    setActiveComponent(CHANGE_SCHEDULE_STEPS.DEFAULT);
  };

  const shouldApplyIncentive = isIncentiveValid(summerPromoConfirming.incentiveCode, activeDay, {
    screen: PATH_HUB_ENROLMENT_CHANGE_SCHEDULE,
  });

  const onConfirmNewScheduleClick = (scheduleDate) => {
    const newScheduleStartTime = moment.utc(scheduleDate, SCHEDULE_DATE_FORMAT).format('HH:mm:ss.SSS[Z]');
    setIsSavingSummerModeData(true);

    dispatch(
      saveSummerModeData({
        salesForceEnrolmentId: enrolmentSfid,
        shouldApplyIncentive,
        appliedSummerModeIncentive: incentiveCode,
        enrolmentId: enrolmentSfid,
        selectedSummerMode,
        summerModeNewScheduleStartTime: newScheduleStartTime,
        summerModeNewScheduleDay: moment(activeDay).format('dddd'),
        summerModeFrequency: frequencyChange.value,
        // how do we handle onSubmitFailure?
        onSubmitSuccess: () => {
          setIsSavingSummerModeData(false);
          setActiveComponent(CHANGE_SCHEDULE_STEPS.A_CONFIRMED);
        },
        onSubmitFailed: () => {
          setIsSavingSummerModeData(false);
          setActiveComponent(CHANGE_SCHEDULE_STEPS.A_CONFIRMED);
        },
      })
    );
  };

  const chooseFrequencyChange = (frequencyOption) => {
    setSelectedSummerMode(summerModeFromFrequencyChange(sessionFrequency, frequencyOption.value));
    setFrequencyChange(frequencyOption);
    if (
      frequencyOption.value === ENR_SESSION_FREQUENCY.WEEKLY ||
      frequencyOption.value === ENR_SESSION_FREQUENCY.FORTNIGHTLY
    ) {
      // goto regular form
      setActiveComponent(CHANGE_SCHEDULE_STEPS.A_CHOOSE);
    } else {
      // goto contact request-like form
      setActiveComponent(CHANGE_SCHEDULE_STEPS.B_CHOOSE);
    }
  };

  const submitChangeToOther = () => {
    // on done
    if (!selectedPerWeek) {
      // validate selectedPerWeek
      setErrors(['flow-b-sessions-per-week']);
    } else {
      setErrors([]);
      // create ESSL
      setPending(true);
      // 1) Don't update internal state - doesn't really make sense here

      // 2) Dispatch an update schedule ESSL to SF
      dispatch(
        updateSchedule({
          uniqueEnrolmentId: enrolmentSfid,
          startDate: moment(activeDay).toISOString(),
          endDate: moment(activeDay).toISOString(),
          customerNumber,
          onSuccess: () => {
            if (incentiveCode === SUMMER_15PCT_ALL_SESSIONS) {
              setIsSavingSummerModeData(true);
              dispatch(
                saveSummerModeData({
                  salesForceEnrolmentId: enrolmentSfid,
                  shouldApplyIncentive,
                  appliedSummerModeIncentive: incentiveCode,
                  enrolmentId,
                  selectedSummerMode,
                  summerModeNewScheduleStartTime: '00:00:00',
                  summerModeNewScheduleDay: moment(activeDay).format('dddd'),
                  summerModeFrequency: frequencyChange.value,
                  // how do we handle onSubmitFailure?
                  onSubmitSuccess: () => {
                    setIsSavingSummerModeData(false);
                    setPending(false);
                    setActiveComponent(CHANGE_SCHEDULE_STEPS.B_CONFIRMED);
                  },
                  onSubmitFailed: () => {
                    setIsSavingSummerModeData(false);
                    setPending(false);
                    setActiveComponent(CHANGE_SCHEDULE_STEPS.B_CONFIRMED);
                  },
                })
              );
            } else {
              setPending(false);
              setActiveComponent(CHANGE_SCHEDULE_STEPS.B_CONFIRMED);
            }
          },
          frequency:
            frequencyChange.value === ENR_SESSION_FREQUENCY.MULTIPLE_SESSIONS && Number(selectedPerWeek) === 2
              ? 'Twice weekly'
              : frequencyChange.value,
          message: `${selectedPerWeek} sessions per week | ${additionalMessage}`,
          enrolmentType,
          tutorNumber,
        })
      );
    }
  };

  const flowBChooseContinue = () => {
    if (datePickerProps.value) {
      setErrors([]);
      setActiveComponent(CHANGE_SCHEDULE_STEPS.B_CONFIRMATION);
    } else if (!errors.includes('flow-b-choose-date')) {
      setErrors((curr) => [...curr, 'flow-b-choose-date']);
    }
  };

  if (isLoadingData) {
    return <InPageLoader />;
  }

  if (hasErrors) {
    return <PageError activePage="hub-enrolment-detail" />;
  }

  if (activeComponent === CHANGE_SCHEDULE_STEPS.A_CONFIRMED) {
    return (
      <EnrolmentManagementConfirmed
        mainHeading="Change confirmed"
        student={studentName}
        subject={enrolmentSubject}
        enrolmentId={enrolmentId}
        message="We are updating the change to your permanent session schedule.
            You may not see it reflected on your Hub enrolment details immediately,
            however you should receive an email confirming the details soon."
        tracking={TRACKING_CSP.SUCCESS}
        promoText={summerPromoConfirming.promoText}
        showLegalCopy={summerPromoConfirming.promoText && shouldApplyIncentive}
      />
    );
  }

  const containerClasses = 'container mx-auto mb-11 px-4 lg:mb-24';

  return (
    <EnrolmentLayout
      sectionHeading={`${studentName} ${enrolmentSubject}`}
      mainHeading={
        activeComponent !== CHANGE_SCHEDULE_STEPS.B_CONFIRMED ? 'Change schedule' : 'Schedule change requested'
      }
      enrolmentId={enrolmentId}
      tracking={activeComponent === CHANGE_SCHEDULE_STEPS.B_CONFIRMED ? TRACKING_CSP.SUCCESS : TRACKING_CSP.PAGE}
    >
      <Fragment>
        {activeComponent === CHANGE_SCHEDULE_STEPS.DEFAULT && (
          <>
            <div className={containerClasses}>
              <EnrolmentBrief tutorName={tutorName} tutorPhoto={tutorPhoto} enrolmentSchedule={enrolmentSchedule} />
              <div className="mb-5 lg:mb-7">
                <h2 className={classNames('lh-1-2 font-display text-xl font-bold', { 'mb-1': promoText })}>
                  {studentName}’s {enrolmentSubject} learning sessions are currently scheduled{' '}
                  {frequencyLabel(sessionFrequency)}.
                </h2>
                {promoText && <PromoText text={promoText} />}
              </div>

              <div className="flex flex-col">
                {frequencyOptions.map((option) => (
                  <ChoiceButton key={option.value} className="mb-3" onClick={() => chooseFrequencyChange(option)}>
                    {sessionFrequency === option.value ? `Keep doing ${option.label}` : `Change to ${option.label}`}
                  </ChoiceButton>
                ))}
              </div>
            </div>
          </>
        )}

        {activeComponent === CHANGE_SCHEDULE_STEPS.A_CHOOSE && (
          <Fragment>
            <div className={containerClasses}>
              <EnrolmentBrief tutorName={tutorName} tutorPhoto={tutorPhoto} enrolmentSchedule={enrolmentSchedule} />
              <div className="mb-5 lg:mb-7">
                <h2 className={classNames('lh-1-2 font-display text-xl font-bold', { 'mb-1': incentiveCode })}>
                  {heading}
                </h2>
                {promoText && <PromoText text={promoText} />}
              </div>

              <div className="mb-5">
                <DatePicker {...datePickerProps} />
              </div>
              <div className="flex items-center">
                <Checkbox
                  id="current tutor"
                  value={showCurrentTutorOnly}
                  onChange={setShowCurrentTutorOnly}
                  error={false}
                />
                <label htmlFor="current tutor" className="lh-1-33 cursor-pointer pl-2 text-lg">
                  {tutorName}&apos;s availability only
                </label>
              </div>
            </div>

            {isFetching && <InPageLoader position="relative" height="auto" />}
            {loadingError && (
              <div className="container mx-auto mb-12">
                <ErrorBlock errorMsg="There was a problem retrieving the data, please try again." />
              </div>
            )}
            {!isLoading && !loadingError && !isLoadingNextSession && (
              <Fragment>
                {/* if next session within 4 hours */}
                {nextSessionWith4Hrs && (
                  <div className="container mx-auto mb-4">
                    <Alert
                      type="warning"
                      body={`The change does not apply to the session scheduled for today at ${moment(
                        nextSession.startTime
                      )
                        .tz(timezone)
                        .format('h:mma')}.`}
                    />
                  </div>
                )}

                {!isEmpty(tabDays) && (
                  <div className="mb-lg-8 mb-11">
                    <div className="flex w-full overflow-y-auto">
                      <div className="min-w-[15px] flex-1 border-b border-grey-2" />
                      <div className="container flex flex-shrink">
                        {tabDays.map((d) => (
                          <Day key={d.dayRaw} dayRaw={d.dayRaw} activeDay={activeDay} clickHandler={dayClickHandler} />
                        ))}
                      </div>
                      <div className="flex-1 border-b border-grey-2" />
                    </div>
                  </div>
                )}

                {activeDay instanceof Date === true && (
                  <AvailabilitiesOfADayUnconnected
                    availabilities={altScheds}
                    day={activeDay}
                    currentTutor={tutorName}
                    currentTutorId={tutorSfId}
                    showCurrentTutorOnly={showCurrentTutorOnly}
                    clickHandler={onAvailabilityItemClick}
                    setShowCurrentTutorOnly={setShowCurrentTutorOnly}
                    timezone={timezone}
                  />
                )}
              </Fragment>
            )}
            {/*
          NOTE, we used to have a banner here: Looking to change a schedule that's not weekly
          Since we give customers options on change schedule entry page, the banner is not needed any more.
          Lorenzo suggested to remove it.
          */}
          </Fragment>
        )}
        {activeComponent === CHANGE_SCHEDULE_STEPS.A_CONFIRMATION && (
          <ConnectedChangeScheduleConfirmationCSP
            onChangeClick={onConfirmationChangeClick}
            onConfirmClick={onConfirmNewScheduleClick}
            frequency={frequencyChange.value}
            incentiveCode={incentiveCode}
            isSavingSummerModeData={isSavingSummerModeData}
            nextSessionIn4Hrs={nextSessionWith4Hrs ? nextSession : null}
          />
        )}

        {activeComponent === CHANGE_SCHEDULE_STEPS.B_CHOOSE && (
          <>
            <div className={containerClasses}>
              <EnrolmentBrief tutorName={tutorName} tutorPhoto={tutorPhoto} enrolmentSchedule={enrolmentSchedule} />

              <div className="w-1/2">
                <h2 className="lh-1-2 mb-4 font-display text-xl font-bold">Let&apos;s create your new schedule</h2>
                <h3 className="lh-1-2 mb-5 text-lg lg:mb-7">
                  Please note that we may need to match you with a new tutor to accommodate the schedule change.
                </h3>
                {promoText && <PromoText text={promoText} />}
              </div>

              <div className="mb-8">
                <div className="">
                  <label htmlFor="b-choose-new-schedule-from" className="mb-2 text-xs">
                    Start new schedule from
                  </label>
                  <DatePicker {...datePickerProps} labelText="" id="b-choose-new-schedule-from" />
                  {errors.includes('flow-b-choose-date') && (
                    <span className="my-2 text-xs text-red-5">Please select a date</span>
                  )}
                </div>
              </div>
              <Button size="lg" onClick={flowBChooseContinue}>
                Continue
              </Button>
            </div>
          </>
        )}

        {activeComponent === CHANGE_SCHEDULE_STEPS.B_CONFIRMATION && (
          <div className={containerClasses}>
            <EnrolmentBrief tutorName={tutorName} tutorPhoto={tutorPhoto} enrolmentSchedule={enrolmentSchedule} />

            <div className="">
              {pending ? (
                <div className="relative">
                  <InPageLoader position="absolute" />
                </div>
              ) : (
                <div className="lg:w-1/2">
                  <div className="mb-8">
                    <h2 className="lh-1-2 mb-8 font-display text-xl font-bold">
                      Confirm details of the change request
                    </h2>
                    {promoText &&
                      isIncentiveValid(incentiveCode, activeDay, { screen: PATH_HUB_ENROLMENT_CHANGE_SCHEDULE }) && (
                        <PromoText text={promoText} />
                      )}
                  </div>
                  <ChangeSummary
                    fromTitle="Change will start from"
                    fromText={moment(activeDay).format('dddd, MMMM D')}
                    toTitle="And frequency of sessions will be"
                    toText={upperFirst(frequencyChange.label)}
                  />
                  <h2 className="lh-1-2 mb-5 mt-10 font-display text-xl font-bold">Schedule preferences</h2>
                  <div className="mb-5">
                    <label htmlFor="b-confirmation-sessions-per-week" className="mb-2 text-xs">
                      Sessions per week
                    </label>
                    <Dropdown
                      placeholder="Please select"
                      id="b-confirmation-sessions-per-week"
                      options={sessionsPerWeek}
                      value={selectedPerWeek}
                      onChange={setSelectedPerWeek}
                    />
                    {errors.includes('flow-b-sessions-per-week') && (
                      <span className="my-2 text-xs text-red-5">Please select number of sessions per week</span>
                    )}
                  </div>
                  <Textarea
                    label="Day and time preferences"
                    placeholder="eg. Mondays at 2pm and Saturdays at 4pm"
                    value={additionalMessage}
                    onChange={setAdditionalMessage}
                    maxLength={800}
                  />
                </div>
              )}
            </div>
            <div className="pt-10">
              <Button size="lg" onClick={submitChangeToOther} disabled={pending}>
                Request schedule change
              </Button>
            </div>
          </div>
        )}

        {activeComponent === CHANGE_SCHEDULE_STEPS.B_CONFIRMED && (
          <div className={containerClasses}>
            <div className="text-xl">
              Thanks for submitting a request for a new schedule, our customer care team will follow up to confirm your
              new schedule.
            </div>
            {promoText &&
              isIncentiveValid(incentiveCode, activeDay, { screen: PATH_HUB_ENROLMENT_CHANGE_SCHEDULE }) && (
                <PromoText text={summerPromoConfirming.promoText} />
              )}
            <div className="pt-6">
              <LinkButton to={`${PATH_HUB_ENROLMENT_DETAIL}?token=${enrolmentId}`} size="xs" appearance="reverse">
                View {studentName}&apos;s {enrolmentSubject} enrolment
              </LinkButton>
            </div>
          </div>
        )}
      </Fragment>
      {promoText &&
        isIncentiveValid(incentiveCode, activeDay || new Date(), { screen: PATH_HUB_ENROLMENT_CHANGE_SCHEDULE }) &&
        !isSavingSummerModeData &&
        !isLoading && (
          <div className="container mx-auto px-4">
            <SummerPromoLegalCopy />
          </div>
        )}
    </EnrolmentLayout>
  );
};

export default compose(
  WithSelectedEnrolment(EnrolmentManagementHOC(HubEnrolmentChangeSchedule), HOC_PAGE_PARAM.CHANGE_SCHEDULE)
);
