
interface WithGlobalInfobarLayoutProps {
  children: React.ReactNode;
}

/**
 *  
 *  This is a HOC that wraps the children with the global infobar layout. Our Marketing Cloud campaigns can target the
 *  cz_global_infobar div to inject content into.
 * 
 */

const WithGlobalInfobarLayout: React.FC<WithGlobalInfobarLayoutProps> = ({ children }) => {
  return (
    <div id="global-layout">
      <div id="cz_global_infobar"></div>
      {children}
    </div>
  );
};

export default function withGlobalInfobar<P>(WrappedComponent: React.ComponentType<P>) {
  return (props: P) => (
    <WithGlobalInfobarLayout>
      <WrappedComponent {...props} />
    </WithGlobalInfobarLayout>
  );
}
