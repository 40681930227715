import BasePageTemplate from '../BasePageTemplate/BasePageTemplate';
import HeaderAndFooter from '../HeaderAndFooter/HeaderAndFooter';
import PageContent from '../PageContent/PageContent';

export interface BasePageLayoutProps {
  title?: string;
  children: React.ReactNode;
}
const BasePageLayout = ({ children, title}: BasePageLayoutProps) => {
  return (
    <BasePageTemplate>
      <HeaderAndFooter title={title}>
        <PageContent>
          {children}
        </PageContent>
      </HeaderAndFooter>
    </BasePageTemplate>
  );
};
export default BasePageLayout
