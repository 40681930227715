import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';

import ErrorBlock from '../../../common/ErrorBlock';
import ContactFormLayout from '../../../common/hub/ContactFormLayout';
import { useTextAreaProps, TextArea } from '../../../components/TextArea/TextArea';
import { RestrictedButton } from '../../../components/RestrictedButton/RestrictedButton';

import { getSelectedSession } from '../../../selectors/hubSelector';
import { submitCaseToSF } from '../../../actions/hub/hubContactPageActions';
import { PATH_HUB_COMPLETED_SESSIONS, PATH_HUB_CR_SUBMIT_CASE_SUCCESS } from '../../../util/pagePath';

const FormRequestRecordingSession = ({ selectedSession, isSubmitting, submitError, errorMsg, submit }) => {
  const history = useHistory();
  const textAreaProps = useTextAreaProps({
    id: 'text-tell-us-something',
    labelText: 'Is there anything else you\u2019d like to tell us?',
  });

  const message = textAreaProps.value;

  if (selectedSession === null || selectedSession === undefined) {
    history.push(PATH_HUB_COMPLETED_SESSIONS);
    return null;
  }

  const { uniqueEnrolmentId, studentSessionId, student, subject } = selectedSession;

  const onClickHandler = () => {
    const caseType = 'Feedback';
    const caseCategory = 'Request session recording';
    const caseDescription = `${caseCategory}. Other details: ${message}`;
    submit({
      uniqueEnrolmentId,
      message: caseDescription,
      caseSubject: `Contact request - ${caseType} - ${caseCategory}`,
      caseType,
      caseCategory,
      studentSession: studentSessionId,
      onSubmitSuccess: () => {
        history.push(PATH_HUB_CR_SUBMIT_CASE_SUCCESS, {
          message: `Your request for a session recording for ${student}'s ${subject} enrolment has been submitted successfully. We'll be in touch soon.`,
        });
      },
    });
  };

  return (
    <ContactFormLayout selectedSession={selectedSession}>
      <div className="px-5 pt-5 lg:px-20 lg:pt-12">
        {submitError && errorMsg && <ErrorBlock errorMsg={errorMsg} />}
        <form>
          <TextArea {...textAreaProps} className="mb-7" />
        </form>
        <div className="mb-4 text-center tracking-normal lg:mb-8">
          <RestrictedButton
            buttonText="Request recording"
            truthyChecks={[message.length > 0, !isSubmitting]}
            onClick={() => {
              onClickHandler();
            }}
          />
        </div>
      </div>
    </ContactFormLayout>
  );
};

FormRequestRecordingSession.propTypes = {
  selectedSession: PropTypes.shape({
    uniqueEnrolmentId: PropTypes.string.isRequired,
    sessionInfo: PropTypes.string.isRequired,
    studentSessionId: PropTypes.string.isRequired,
    student: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
  }).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitError: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    return {
      selectedSession: getSelectedSession(state),
      isSubmitting: state.ui.apiState.hubContactPage.isLoading,
      submitError: state.ui.apiState.hubContactPage.fetchError,
      errorMsg: state.ui.apiState.hubContactPage.errorMsg,
    };
  },
  (dispatch) => {
    return {
      submit: bindActionCreators(submitCaseToSF, dispatch),
    };
  }
)(FormRequestRecordingSession);
