import { ErrorText, Input, Label } from '@cluey/cluey-components';
import { EyeIcon, EyeSlashedIcon } from '../../../../../../icons';

export interface Props {
	id: 'newPassword' | 'newPasswordConfirmation';
	label: string;
	onChange(value: string, event: React.ChangeEvent): void;
	data: {
		error: string;
		valid: boolean;
		value: string;
		textVisible: boolean;
	};
	onEyeClick(target: 'newPassword' | 'newPasswordConfirmation'): void;
	className?: string;
}

function PasswordField({ id, data, label, onChange, onEyeClick, className }: Props) {
	return (
		<div className={`flex flex-col gap-1 ${className}`}>
			<Label id={id}>{label}</Label>
			<div className="relative">
				<Input
					id={id}
					className="w-full pr-9"
					onChange={onChange}
					value={data.value}
					error={!data.valid}
					type={data.textVisible ? 'text' : 'password'}
				/>
				<button
					className="absolute right-0 top-2/4 h-full translate-y-[-50%] px-3 hover:opacity-75"
					onClick={() => onEyeClick(id)}
					type="button"
				>
					<span className="sr-only">toggle password visibility</span>
					{data.textVisible ? <EyeIcon className="text-blue-5" /> : <EyeSlashedIcon className="text-blue-5" />}
				</button>
			</div>
			<ErrorText>{data.error}</ErrorText>
		</div>
	);
}

export default PasswordField;
