import { Alert, Button, Input, Pending } from "@cluey/cluey-components";
import { useEffect } from "react";
import { useGetCancellationReasons } from "../../../../../hooks/queries/useEnrolmentQuery";
import { Loader } from '../../../../../components/Loader'
import { filterCancelReasons } from "../../../../../util/helper/cancelEnrolment";
import { CancelReason } from "../../../../../types/hubInterfaces";
import update from 'immutability-helper';
import { TextArea, useTextAreaProps } from "../../../../../components/TextArea/TextArea";
import { ReasonCheckbox } from "./ReasonCheckbox";
import { useSelector } from "react-redux";
import { first } from "lodash";
import { CancelEnrolmentSummaryAlert } from "../../FormCancelEnrolment/CancelEnrolmentSummaryAlert";
import type { InitialState } from "../../../../../types/hubTypes";
import type { Props } from '../CancellationSteps';

const CancelReasons = (props: Props) => {
  const { 
    onSubmitCancelRequest,
    cancelSelection: { selectedDate, rating, selectedReasons, other },
    setCancelSelection,
    selectedEnrolment
  } = props;
  const { 
    data: cancelReasons,
    isLoading: isLoadingReasons,
    error
  } = useGetCancellationReasons();
  const {
    isLoading: isSubmitting,
  } = useSelector((state: InitialState) => state.ui.apiState.hubContactPage);

  const text = rating <= 3 ? "What didn't work for you?" : 'Why did you decide to cancel?';
  const reasons = filterCancelReasons({ cancelReasons: first(cancelReasons)?.values, rating });
  const textAreaProps = useTextAreaProps({
    id: 'text-tell-us-something',
    labelText: 'Message (optional)',
    placeholder: "Anything else you'd like us to know?",
  });

  useEffect(() => {
    setCancelSelection(current => update(current, {
      message: {
        $set: textAreaProps.value
      }
    }));
  }, [textAreaProps.value, setCancelSelection])

  const getInputError = () => {
    const otherSelected = !!selectedReasons.find(res => res.label === 'Other');
    if (otherSelected && !other.message) {
      return true;
    }
    return false;
  }

  const getCancelRequestDisabled = () => {
    if (!selectedDate || isSubmitting) {
      return true;
    }
    if (selectedReasons.length === 0) {
      return true;
    }
    if (getInputError()) {
      return true;
    }
    return false;
  }

  const onCheck = (reason: CancelReason) => {
    if (
      reason.label.toLowerCase() === 'other'
      && !!selectedReasons.find(res => res.label === reason.label)
    ) {
      onOtherChange('');
    }
    setCancelSelection(current => {
      return update(current, {
        selectedReasons: {
          $apply: (selectedReasons: Array<CancelReason>) => {
            if (selectedReasons.find(res => res.label === reason.label)) {
              return selectedReasons.filter(res => res.label !== reason.label);
            }
            return [...selectedReasons, reason];
          }
        }
      });
    })  
  }

  const onOtherChange = (text) => {
    setCancelSelection(current => update(current, {
      other: {
        message: {
          $set: text
        }
      }
    }))
  }
  
  return (
    <Pending loading={isLoadingReasons} loader={<Loader />}>
      { !!error 
        ? <Alert type='error' body={`There was a problem retrieving the cancellation reasons. ${error}` } />
        : ( 
          <div className="w-full max-w-md">
            <p className="text-blue-5 font-bold text-lg">{text}</p>
            <p className="font-bold text-grey-5">Please select all that apply</p>
            <div className="bg-grey-2 h-px my-4"></div>
            <div className="flex flex-col gap-2.5">
              {reasons.map((reason) => {
                const checked = !!selectedReasons.find(res => res.label === reason.label);
                return (
                  <ReasonCheckbox
                    key={reason.label}
                    checked={checked}
                    onCheck={onCheck}
                    reason={reason}
                  />
                )
              })}
            </div>
            <Input
              error={getInputError()}
              className="mt-2.5 w-full"
              placeholder="Please specify"
              value={other.message}
              onChange={onOtherChange}
            />
            <TextArea {...textAreaProps} />
            <CancelEnrolmentSummaryAlert
              selectedEnrolment={selectedEnrolment}
              selectedDate={selectedDate}
            />
            <div className="mb-4 text-center lg:mb-8">
              <Button size="lg" disabled={getCancelRequestDisabled()} onClick={onSubmitCancelRequest}>
                Request cancellation
              </Button>
            </div>
          </div>
        )
      }
    </Pending>
  )
}

export default CancelReasons;