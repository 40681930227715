import { union } from "lodash";
import parseStudentInformation from "./parseStudentInformation";

const specialLearningNeedMap = {
  'ADHD': 'ADHD',
  'Anxious': 'Anxiety',
  'Autistic Disorder': 'Autism',
  'Autism spectrum level 2-3': 'Autism',
  'Dyslexia': 'Dyslexia',
  'Dyscalculia': 'Dyscalculia',
  'Depression': 'Depression',
  'Dysgraphia': 'Dysgraphia',
  'Hearing Impairment': 'a Hearing Impairment',
  'Visual Impairment': 'a Visual Impairment',
}

export function mapSpecialLearningNeeds(specialLearningNeeds = []) {
  const mappedSpecialLearningNeeds = union(
    specialLearningNeeds
      .map(specialLearningNeed => specialLearningNeedMap[specialLearningNeed])
      .filter(result => result) // remove falsy/undefined values (in the case of special learning needs that are not defined in the map)
  ); 

  if (mappedSpecialLearningNeeds.length === 0) {
    return '';
  } else if (mappedSpecialLearningNeeds.length === 1) { 
    return 'Tutoring students with ' + mappedSpecialLearningNeeds[0];
  } else {
    const lastElement = mappedSpecialLearningNeeds.pop();
    const joinedElements = mappedSpecialLearningNeeds.join(', ');

    return 'Tutoring students with ' + joinedElements + ' and ' + lastElement;
  }
}

const studentAttributeMap = {
  'Easily distracted': 'Engaging to students who struggle to stay focused',
  'Mostly Attentive': 'Engaging students who can lose focus',
  'Highly focused': 'Keeping highly focused students engaged and challenged',
  'Shy': 'Building confidence in shy students',
  'Outgoing': 'Harnessing the energy of outgoing students',
  'Between Outgoing and Shy': 'Building confidence in students',
  'Creative': 'Connecting with creative students',
  'Performer': 'Connecting with creative students',
  'Sport/Outdoors': 'Connecting with active students',
  default: 'Connecting with students who have diverse interests',
};

export function mapStudentAttributes(studentAttributes) {
  const mappedStudentAttributes = union(studentAttributes.map(attribute => 
    studentAttributeMap.hasOwnProperty(attribute) ? studentAttributeMap[attribute] : studentAttributeMap.default
  ));
  
  // if null value (length = 0) return the default text https://clueylearning.atlassian.net/wiki/spaces/PED/pages/1160872007/Hub+-+Tutor+details+panel+content
  return mappedStudentAttributes.length === 0 ? [studentAttributeMap.default] : mappedStudentAttributes;
}

export default function mapStudentInformationToTutorDetails(studentInformation) {
  const { studyStyle, personality, interests, specialLearningNeeds } = parseStudentInformation(studentInformation);
  const studentAttributes = [
    ...(studyStyle || []),
    ...(personality || []),
    ...(interests || []),
  ];

  const skilledAt = mapStudentAttributes(studentAttributes);
  const highlyValuedFor = mapSpecialLearningNeeds(specialLearningNeeds);

  return {
    skilledAt,
    highlyValuedFor
  };
}