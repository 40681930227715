import env from '../../env';

export const getPortalAPIRequestParams = (pathPartial: string) => {
  return {
    url: `https://${env.REACT_APP_CLUEY_API_HOST_NAME}/customer-hub/${pathPartial}`,
    reqOptions: {
      host: env.REACT_APP_CLUEY_API_HOST_NAME,
      path: `customer-hub/${pathPartial}`,
    },
  };
};
