import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ClueyLogoBase, SiteTitle } from '@cluey/cluey-components';

import { userSignout } from '../../actions/hub/hubAuthPagesActions';
import {
  PATH_HUB_HOME,
  PATH_HUB_UPCOMING_SESSIONS,
  PATH_HUB_PLAN_DETAILS,
  PATH_HUB_SUPPORT,
  PATH_HUB_ACCOUNT_DETAILS,
} from '../../util/pagePath';
import ClueyLogo from '../../assets/images/cluey-logo.svg';

import Borger from '../../assets/images/atoms-icon-filled-hamburger.svg';
import Cross from '../../assets/images/atoms-icon-filled-cross.svg';
import { api } from '../../api';
import { AccountDetails } from '../../api/types/account';

interface HubHeaderProps {
  title?: string;
  hideMenu?: boolean;
  onLogoClick?: () => void;
}

const NAV_ITEMS = {
  Home: {
    label: 'Home',
    path: PATH_HUB_HOME,
  },
  Sessions: {
    label: 'Sessions',
    path: PATH_HUB_UPCOMING_SESSIONS,
  },
  Account: {
    label: 'Account',
    path: PATH_HUB_PLAN_DETAILS,
  },
  Support: {
    label: 'Support',
    path: PATH_HUB_SUPPORT,
  },
};

const getNavOptions = (userType: AccountDetails['userType'] | null) => {
  if (userType === 'Account') {
    return Object.values(NAV_ITEMS);
  } else {
    return [
      NAV_ITEMS.Home,
      {
        ...NAV_ITEMS.Account,
        path: PATH_HUB_ACCOUNT_DETAILS,
      },
    ];
  }
};

const HubHeader = ({ title, hideMenu = false, onLogoClick }: HubHeaderProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const menuOnHover = () => setDropdownOpen(true);
  const menuOnHoverExit = () => setDropdownOpen(false);
  const toggleMenu = () => setDropdownOpen(!dropdownOpen);

  const dispatch = useDispatch();
  const signOut = () => {
    dispatch(userSignout());
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  const { data: details } = api.account.details.useQuery();

  const navOptions = useMemo(() => getNavOptions(details?.userType), [details?.userType]);

  useEffect(() => {
    const closeMenu = (e: MouseEvent) => {
      if (dropdownOpen && dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
        setDropdownOpen(false);
      }
    };

    if (dropdownOpen) {
      document.addEventListener('click', closeMenu);
    }
    return () => {
      document.removeEventListener('click', closeMenu);
    };
  }, [dropdownOpen]);

  const renderLogoutButton = (
    <button
      className="flex h-10 items-center px-4 text-sm font-bold uppercase tracking-wider text-blue-5 hover:bg-white"
      onClick={signOut}
    >
      Log out
    </button>
  );

  const renderMenuButton = (
    <div
      className="flex h-full items-center lg:relative"
      ref={dropdownRef}
      onMouseEnter={menuOnHover}
      onMouseLeave={menuOnHoverExit}
    >
      <button
        className={classNames(
          'absolute right-0 top-0 flex h-14 w-14 cursor-pointer items-center gap-2 px-4 lg:static lg:h-full lg:w-auto lg:hover:bg-slate-1',
          {
            'bg-slate-1': dropdownOpen,
          }
        )}
        onClick={toggleMenu}
      >
        <img src={Borger} alt="" className={dropdownOpen ? 'hidden lg:block' : 'block'} />
        <img src={Cross} alt="" className={dropdownOpen ? 'block lg:hidden' : 'hidden'} />
        <span className="hidden font-bold text-blue-5 lg:block">Menu</span>
      </button>
      <nav
        className={classNames(
          'absolute left-0 right-0 top-[calc(100%+4px)] z-20 flex h-screen w-screen flex-col bg-slate-1 pb-5 pt-4 lg:left-auto lg:top-full lg:h-auto lg:w-44',
          {
            hidden: !dropdownOpen,
          }
        )}
      >
        {navOptions.map((option) => (
          <Link
            key={option.path}
            className="flex h-10 items-center px-4 text-sm font-bold uppercase tracking-wider text-blue-5 hover:bg-white"
            to={option.path}
          >
            {option.label}
          </Link>
        ))}
        {renderLogoutButton}
      </nav>
    </div>
  );

  return (
    <div className="sticky top-0 z-10 flex h-14 w-full border-b-4 border-blood-orange-4 bg-white lg:h-20">
      <header className="container mx-auto flex items-center justify-between lg:px-4">
        <div className="flex items-center px-4 lg:px-0">
          <Link to={PATH_HUB_HOME} onClick={onLogoClick}>
            <ClueyLogoBase src={ClueyLogo} />
          </Link>
          <span className="hidden">
            <SiteTitle title={title} />
          </span>
        </div>
        {hideMenu ? renderLogoutButton : renderMenuButton}
      </header>
    </div>
  );
};

export default HubHeader;
