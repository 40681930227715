import { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container } from '@cluey/cluey-components';
import moment from 'moment';
import Cookies from 'js-cookie';

import EnrolmentLayout from '../../../../common/hub/EnrolmentLayout';
import {
  isEnrolmentActiveCheck,
  isEnrolmentPausedCheck,
  isGroupSessionCheck,
  saveCancellingPeriod,
  willEnrolmentBePausedCheck,
} from '../../../../util/helpers';
import {
  PATH_HUB_CR_CHANGE_TUTOR,
  PATH_HUB_CR_PAUSE_SESSIONS,
  PATH_HUB_CR_CHANGE_CONTENT_FOCUS,
  PATH_HUB_ENROLMENT_CHANGE_SCHEDULE,
  PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP,
  PATH_HUB_ENROLMENT_MANAGE_PAUSE,
  PATH_HUB_CR_CANCEL_ENROLMENT,
  PATH_HUB_SUPPORT,
} from '../../../../util/pagePath';
import { fetchEnrolment } from '../../../../actions/hub/hubEnrolmentManagementActions';
import { COOKIE_SAVE_ENROLMENT } from '../../../../util/constants';
import {
  getSummerPromo,
  hasIncentiveFebruaryRestartedPack as hasIncentiveFebruaryRestartedPackCheck,
} from '../../../../selectors/summerModeSelector';
import ErrorBlock from '../../../../common/ErrorBlock';
import { getNextUpcomingSessionForEnrolment } from '../../../../selectors/hubSelector';
import { PromoText } from '../../PromoText';
import SummerPromoLegalCopy from '../../SummerMode/SummerPromoLegalCopy';
import { TRACKING_CE } from '../../../../util/trackingClasses';
import { useDispatch, useSelector } from 'react-redux';
import type { Enrolment } from '../../../../api/types/enrolment';
import posthog from 'posthog-js';
import { PostHogEvent } from '../../../../util/posthog';

export interface Props {
  selectedEnrolment: {
    resumeSessionDate?: string;
    timezone?: string;
  } & Enrolment;
  switchToForm(): void;
  pausePolicyLoading: boolean;
  enrolmentPolicyError?: unknown;
}

type Option = {
  cardTitle: string;
  cardCTA: string;
  cardCTAHref?: string;
  cardAction?: () => void;
  promoText?: string;
  trackingClass: string;
  posthogEvent?: PostHogEvent;
};

export const CancelEntry = ({ selectedEnrolment, switchToForm, pausePolicyLoading, enrolmentPolicyError }: Props) => {
  const {
    enrolmentId,
    enrolmentStatus,
    student,
    subject,
    enrolmentPausedFrom,
    enrolmentPausedTo,
    resumeSessionDate,
    timezone,
    type,
  } = selectedEnrolment;
  const history = useHistory();
  const dispatch = useDispatch();
  const nextUpcomingSession = useSelector(getNextUpcomingSessionForEnrolment({ enrolmentId }));
  const hasIncentiveFebruaryRestartedPack = useSelector(hasIncentiveFebruaryRestartedPackCheck);
  const summerPromo = useSelector((state) => getSummerPromo(state, { screen: PATH_HUB_CR_CANCEL_ENROLMENT }));

  if (saveCancellingPeriod() && hasIncentiveFebruaryRestartedPack) {
    let enrolmentsToSave = Cookies.get(COOKIE_SAVE_ENROLMENT);
    enrolmentsToSave = enrolmentsToSave ? JSON.parse(enrolmentsToSave) : [];
    enrolmentsToSave.push(enrolmentId);
    Cookies.set(COOKIE_SAVE_ENROLMENT, JSON.stringify(enrolmentsToSave), {
      expires: 7,
    });
  } else {
    Cookies.remove(COOKIE_SAVE_ENROLMENT);
  }

  const isActive = isEnrolmentActiveCheck({ enrolmentStatus });
  const isPaused = isEnrolmentPausedCheck({ enrolmentStatus });
  const isGroup = isGroupSessionCheck(type);
  const willBePaused = willEnrolmentBePausedCheck({
    enrolmentPausedFrom,
    enrolmentPausedTo,
  });

  const entryScreenOptions = useMemo(() => {
    const screenOptions: Option[] = [];
    /* enabled option for group enrolments in accordance with CRE-1019 */
    if (isPaused || willBePaused) {
      const resumeDate = moment(resumeSessionDate ?? nextUpcomingSession?.startTime)
        .tz(timezone)
        .format('MMM DD, YYYY');
      screenOptions.push({
        cardTitle: 'Change enrolment pause',
        cardCTA: `Resume on ${resumeDate} or with new schedule`,
        cardAction: () => {
          dispatch(fetchEnrolment({ enrolmentId }));
          posthog.capture('click_manage-enrolment_change-enrolment-pause');
          history.push(`${PATH_HUB_ENROLMENT_MANAGE_PAUSE}?token=${enrolmentId}`);
        },
        promoText: summerPromo.promoText,
        trackingClass: TRACKING_CE.OPTION_MANAGE_PAUSE,
      });
    }

    screenOptions.push({
      cardTitle: 'Change schedule',
      cardCTA: isActive ? 'Make a change to ongoing schedule' : 'Make a change to ongoing schedule and restart',
      cardAction: () => {
        const page = isGroup
          ? `${PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP}?token=${enrolmentId}`
          : `${PATH_HUB_ENROLMENT_CHANGE_SCHEDULE}?token=${enrolmentId}`;
        dispatch(fetchEnrolment({ enrolmentId }));
        posthog.capture('click_manage-enrolment_change-schedule');
        history.push(page);
      },
      promoText: isPaused || willBePaused ? summerPromo.promoText : '',
      trackingClass: TRACKING_CE.OPTION_CHANGE_SCHEDULE,
    });

    // NOTE, add change subject here
    screenOptions.push({
      cardTitle: 'Change tutor',
      cardCTA: 'Request a tutor change',
      cardCTAHref: `${PATH_HUB_CR_CHANGE_TUTOR}?token=${enrolmentId}`,
      trackingClass: TRACKING_CE.OPTION_CHANGE_TUTOR,
      posthogEvent: 'click_manage-enrolment_change-tutor',
    });

    /*
    Change content focus;
    This is only available to private enrolments
  */
    if (!isGroup) {
      screenOptions.push({
        cardTitle: 'Change content focus',
        cardCTA: 'Request to focus on specific topics or concepts',
        cardCTAHref: `${PATH_HUB_CR_CHANGE_CONTENT_FOCUS}?token=${enrolmentId}`,
        trackingClass: TRACKING_CE.OPTION_CHANGE_CONTENT,
        posthogEvent: 'click_manage-enrolment_change-content-focus',
      });
    }

    if (!isPaused && !willBePaused) {
      screenOptions.push({
        cardTitle: 'Pause enrolment',
        cardCTA: 'Take a break by pausing sessions',
        cardCTAHref: `${PATH_HUB_CR_PAUSE_SESSIONS}?token=${enrolmentId}`,
        trackingClass: TRACKING_CE.OPTION_PAUSE,
        posthogEvent: 'click_manage-enrolment_pause-enrolment',
      });
    }

    screenOptions.push({
      cardTitle: 'Cancel enrolment',
      cardCTA: "Let us know what didn't work for you",
      cardAction: () => {
        posthog.capture('click_manage-enrolment_cancel-enrolment');
        switchToForm();
      },
      trackingClass: TRACKING_CE.OPTION_CANCEL,
      posthogEvent: 'click_manage-enrolment_cancel-enrolment',
    });

    return screenOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    enrolmentId,
    isActive,
    isPaused,
    isGroup,
    willBePaused,
    resumeSessionDate,
    nextUpcomingSession,
    timezone,
    summerPromo,
  ]);

  return (
    <EnrolmentLayout
      sectionHeading={
        <Link className="text-xs font-bold uppercase" to={PATH_HUB_SUPPORT}>
          Support
        </Link>
      }
      mainHeading="Manage enrolment"
      enrolmentId={enrolmentId}
      isLoading={pausePolicyLoading}
    >
      <Container>
        <p className="mb-9 text-lg lg:w-8/12">
          How can we help with{' '}
          <b>
            {student}'s {subject}
          </b>{' '}
          enrolment?
        </p>
        {enrolmentPolicyError && (
          <div className="my-3">
            <ErrorBlock errorMsg="There was an error encountered while retrieving the pause policy." />
          </div>
        )}
        <div className="my-3 flex flex-col">
          {entryScreenOptions.map((option, index) => {
            const {
              cardTitle,
              cardCTA,
              cardCTAHref = null,
              cardAction,
              promoText = null,
              trackingClass,
              posthogEvent,
            } = option;
            const cardContent = (
              <>
                <h4 className="mb-3 text-xl font-bold text-grey-6">{cardTitle}</h4>
                <span className="text-lg text-primary group-hover:text-purple-6">{cardCTA}</span>
                {promoText && <PromoText text={promoText} />}
              </>
            );
            const cardClass = `option-card mb-3 block w-full rounded-lg border-none bg-slate-1 px-8 py-5 text-left hover:ring hover:ring-blue-1 focus:outline-none focus:ring focus:ring-blue-1 group ${trackingClass}`;
            if (cardCTAHref) {
              return (
                <Link
                  to={cardCTAHref}
                  className={cardClass}
                  key={index}
                  onClick={!!posthogEvent ? () => posthog.capture(posthogEvent) : undefined}
                >
                  {cardContent}
                </Link>
              );
            } else {
              return (
                <button type="button" className={cardClass} onClick={cardAction} key={index}>
                  {cardContent}
                </button>
              );
            }
          })}
        </div>
        {summerPromo.promoText && <SummerPromoLegalCopy />}
      </Container>
    </EnrolmentLayout>
  );
};

export default CancelEntry;
