import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const GraphContainer = ({
	leftTextTop,
	leftTextBottom,
	rightTextTop,
	rightTextBottom,
	children,
	showHR,
	customChildrenContainerClass,
	showChidlrenWrapperBg,
}) => {
	return (
		<div className="flex h-full flex-col overflow-hidden rounded-lg border border-grey-2">
			<div className="flex flex-col p-4 lg:flex-row">
				{(leftTextTop || leftTextBottom) && (
					<div className="mb-2 flex w-full flex-col lg:mb-0 lg:w-1/2">
						{leftTextTop && <h3 className="text-base font-bold leading-tight">{leftTextTop}</h3>}
						{leftTextBottom && <span className="text-2xl leading-[1.17]">{leftTextBottom}</span>}
					</div>
				)}
				{showHR && <hr className="w-100 d-lg-none mb-4" />}
				{(rightTextTop || rightTextBottom) && (
					<div className="flex w-full flex-col lg:w-1/2">
						{rightTextTop && <h3 className="text-base font-bold leading-tight">{rightTextTop}</h3>}
						{rightTextBottom && <span className="text-2xl leading-[1.17]">{rightTextBottom}</span>}
					</div>
				)}
			</div>
			<div
				className={classNames('flex min-h-[360px] grow flex-col', customChildrenContainerClass, {
					'bg-slate-1': showChidlrenWrapperBg,
				})}
			>
				{children}
			</div>
		</div>
	);
};

GraphContainer.propTypes = {
	leftTextTop: PropTypes.string,
	leftTextBottom: PropTypes.string,
	rightTextTop: PropTypes.string,
	rightTextBottom: PropTypes.string,
	showHR: PropTypes.bool,
	customChildrenContainerClass: PropTypes.string,
	showChidlrenWrapperBg: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	children: PropTypes.any,
};

GraphContainer.defaultProps = {
	leftTextTop: '',
	leftTextBottom: '',
	rightTextTop: '',
	rightTextBottom: '',
	showHR: false,
	showChidlrenWrapperBg: false,
	children: null,
	customChildrenContainerClass: null,
};

export default GraphContainer;
