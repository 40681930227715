import focusAreasIcon from '../../../../../assets/images/icon-light-bulb.svg';
import strengthsIcon from '../../../../../assets/images/icon-filled-muscles.svg';
import excelIcon from '../../../../../assets/images/icon-filled-lightning.svg';

import { UnitOrTopic } from '../../../../../api/types/assessment';

const OPPORTUNITY_TO_EXCEL_MESSAGE =
	"Congratulations on achieving a perfect score! Dedication and hard work truly shines through. Embrace the opportunities for continued growth and explore Cluey's excel programs to further enhance skills.";
const OPPORTUNITY_FOR_GROWTH_MESSAGE =
	"Don't be disheartened, remember that academic challenges are part of the learning journey and don't define one's capabilities or potential. Embrace it and treat it as an opportunity to identify areas for improvement.";

export interface CardProps {
	label: string;
	items: UnitOrTopic[];
	staticContent?: string | React.ReactNode;
	icon?: string;
	color?: string;
}

const Card = ({ label, items, icon, color = 'transparent', staticContent }: CardProps) => {
	return (
		<div className="flex-1 rounded-lg border border-grey-2" style={{ backgroundColor: color }}>
			<div className="flex flex-row items-center rounded-t-lg border-b border-grey-2 bg-white p-4">
				{icon && <img alt={`${label} icon`} src={icon} className="mr-2" style={{ height: 16, width: 16 }} />}
				<b className="text-lg">{label}</b>
			</div>
			<div>
				{items.length > 0 && !staticContent ? (
					<ul className="list-disc p-4 pl-10">
						{items.map((item, index) => (
							<li key={`performance_${index}_${item.name}`} className="mb-2 text-lg" style={{ lineHeight: '24px' }}>
								{item.name}
							</li>
						))}
					</ul>
				) : (
					staticContent
				)}
			</div>
		</div>
	);
};

export interface PerformanceHighlightsProps {
	strengths: UnitOrTopic[];
	focusAreas: UnitOrTopic[];
	score?: number;
}

const PerformanceHighlights = ({ strengths, focusAreas, score }: PerformanceHighlightsProps) => {
	const showOpportunityToExcel = score >= 100;
	const showOpportunityForGrowth = score <= 40;

	if (showOpportunityToExcel) {
		return (
			<div className="flex flex-col space-y-4 px-6 md:flex-row md:space-x-4 md:space-y-0">
				<Card
					label="Opportunity to excel"
					items={[]}
					staticContent={<div className="p-4">{OPPORTUNITY_TO_EXCEL_MESSAGE}</div>}
					icon={excelIcon}
					color="#FFEFBB"
				/>
			</div>
		);
	} else if (showOpportunityForGrowth) {
		return (
			<div className="flex flex-col space-y-4 px-6 md:flex-row md:space-x-4 md:space-y-0">
				<Card
					label="Opportunity for growth"
					items={[]}
					staticContent={<div className="p-4">{OPPORTUNITY_FOR_GROWTH_MESSAGE}</div>}
					icon={focusAreasIcon}
					color="#FCE4C7"
				/>
			</div>
		);
	}

	return (
		<div className="flex flex-col space-y-4 px-6 md:flex-row md:space-x-4 md:space-y-0">
			{strengths?.length > 0 && <Card label="Strengths" items={strengths} icon={strengthsIcon} color="#E8FAE9" />}
			{focusAreas?.length > 0 && <Card label="Focus areas" items={focusAreas} icon={focusAreasIcon} color="#FCE4C7" />}
		</div>
	);
};

export default PerformanceHighlights;
