type RocketChatErrorProps = {
    clearError: () => void;
}

const RocketChatError = ({clearError}: RocketChatErrorProps) => {

    const handleDismiss: React.MouseEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        clearError()
    };

  return (
    <div
      id="toast-default"
      className="fixed bottom-5 right-5 text-white flex w-full max-w-xs items-center rounded-lg bg-blue-5 p-4 shadow"
      role="alert"
      onClick={handleDismiss}
    >
      <div className="ml-3 text-sm font-normal">Live Chat isn't available, please try again later.</div>
      <button
        type="button"
        className="text-white hover:text-gray-900 focus:ring-gray-300  ml-auto inline-flex h-8 w-8 items-center justify-center rounded-lg  p-1.5 focus:ring-2 "
        data-dismiss-target="#toast-default"
        aria-label="Close"
      >
        <span className="">X</span>
        <span className="sr-only">Close</span>
      </button>
    </div>
  );
};

export default RocketChatError;
