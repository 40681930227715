import { AnchorButton, Alert, Container, LinkButton } from '@cluey/cluey-components';
import { useDispatch, useSelector } from 'react-redux';
import { RESCHEDULE_PAGE_REQUEST_TYPES } from '../../../../util/constants';
import EnrolmentLayout from '../../../../common/hub/EnrolmentLayout';
import {
  getRescheduleSessionRoute,
  filterUpcomingForEnrolment,
} from '../../../../util/helper';
import { getUpcomingSessions } from '../../../../selectors/hubSelector';
import { Loader } from '../../../../components/Loader';
import CreditPolicy from '../CreditPolicy/CreditPolicy';
import { PATH_HUB_HOME } from '../../../../util/pagePath';
import { submitCaseToSF } from '../../../../actions/hub/hubContactPageActions';
import { useParams } from 'react-router-dom';
import useRedirectInvalidSession from '../../../../hooks/session/useRedirectInvalidSession';
import { useGetSessionPolicy } from '../../../../hooks/queries/useSessionQuery';
import { PAYMENT_STATUS } from '../../../../types/hubEnums';
import { removeCancelledSession } from '../../../../actions/hub/sessionsActions';
import { SessionSummary } from './SessionSummary';
import { InitialState } from '../../../../types/hubTypes';
import type { FormFlowProps, Session } from '../../../../types/hubInterfaces';

interface Props {
  formFlowProps: FormFlowProps
}

const CancelSessionConfirm = ({ formFlowProps }: Props) => {
  const { sessionId } = useParams<{ sessionId: string; }>();
  const dispatch = useDispatch();
  const upcomingSessions: Array<Session> = useSelector(getUpcomingSessions);
  const { isLoading: cancelLoading, fetchError: cancelFailed } = useSelector((state: InitialState) => state.ui.apiState.hubContactPage);
  const sessionToSkip = upcomingSessions.find(sess => sess.studentSessionId === sessionId);
  const {
    data: sessionPolicy,
    isLoading: isPolicyLoading,
    error: policyError 
  } = useGetSessionPolicy({ sessionId: sessionToSkip?.studentSessionId });

  useRedirectInvalidSession(sessionToSkip)

  const getNextSession = () => {
    if (!sessionToSkip) {
      return null;
    }
    const filteredSessions = filterUpcomingForEnrolment(sessionToSkip.uniqueEnrolmentId, upcomingSessions);
    return filteredSessions.find(sess => sess.studentSessionId !== sessionToSkip.studentSessionId);
  }

  const onSkipConfirm = () => {
    dispatch(
      submitCaseToSF({
        action: RESCHEDULE_PAGE_REQUEST_TYPES.CANCEL,
        studentSession: sessionId,
        uniqueEnrolmentId: sessionToSkip.uniqueEnrolmentId,
        onSubmitSuccess: () => {
          formFlowProps.next({sessionToSkip, nextSession: getNextSession()});
          dispatch(removeCancelledSession({ cancelledSession: sessionToSkip }));
        },
      })
    );
  }

  const renderActionButton = () => {
    if (sessionPolicy?.reschedule?.isAllowed) {
      return (
        <AnchorButton
          className='block mt-7'
          size="lg"
          href={getRescheduleSessionRoute(sessionToSkip.studentSessionId)}
          target='self'>
          Reschedule session instead
        </AnchorButton>
      )
    } else {
      return (
        <LinkButton
          appearance='reverse'
          className='block mt-7'
          size="sm"
          to={PATH_HUB_HOME}>
          Home
        </LinkButton>
      )
    }
  }

  if (!sessionToSkip) {
    return null;
  }

  /*
    Caveat:
    The session policy api's cancelSession.isAllowed attribute shouldn't be used as a determinant 
    to whether the session can be cancelled or not.

    The only instance where the CancelSessionConfirm cancel session isn't rendered is if the session's policy's isAllowed is false
    and the session hasn't been paid for yet.
    Thus, regardless if isAllowed is true or false, as long as it has been paid for, the session can be cancelled.
  */
 const isOneOffRescheduled = sessionToSkip?.tutorRescheduleRequestStatusC === 'Approved';
  return (
    <EnrolmentLayout
      sectionHeading={`${sessionToSkip.student} ${sessionToSkip.subject}`}
      mainHeading={`Cancel session (inform ${sessionToSkip.tutor})`}
      showEnrolmentStatus={false}
      showSummerModeEnrolmentStatus={false}
      enrolmentId={sessionToSkip.uniqueEnrolmentId}
    >
      {cancelLoading || isPolicyLoading ? 
        <Loader />
        :
        <Container>
          <p className="text-grey-6 text-xl">Let {sessionToSkip?.tutor?.split(" ")[0]} know you won't be attending this session</p>
          {policyError && <Alert className='my-2' type='error' body={`There was an error retrieving this session's policy. ${policyError}`} />}
          {cancelFailed && (<Alert body="" type="error">There was an error with your cancel session request.</Alert>)}
          {sessionPolicy?.cancelSession?.isAllowed && sessionToSkip.paymentStatus !== PAYMENT_STATUS.PAID ?
            null
            : 
            <CreditPolicy sessionToSkip={sessionToSkip} sessionPolicy={sessionPolicy} isLoading={isPolicyLoading} />
          }
          {
            sessionToSkip.paymentStatus !== PAYMENT_STATUS.PAID && !sessionPolicy?.cancelSession?.isAllowed ?
              renderActionButton()
              :
              <SessionSummary
                sessionPolicy={sessionPolicy}
                sessionToSkip={sessionToSkip}
                skipFailed={cancelFailed}
                onSkipConfirm={onSkipConfirm}
                isOneOffRescheduled={isOneOffRescheduled}
              />
          }
        </Container>
      }
    </EnrolmentLayout>
  )
}

export default CancelSessionConfirm;