import initialState from './initialState';
import {
  SR_LOGIN_SET_URL_TOKEN, SR_LOGIN_RECEIVE_INFO,
  HUB_AUTH_USER_AUTHENTICATED, HUB_AUTH_USER_UN_AUTHENTICATED, HUB_AUTH_GET_USER_INFO_SUCCESS,
  HUB_GLOBAL_CLOCK_SKEW_DETECTED, HUB_AUTH_USER_AUTHENTICATION_ERROR,
} from '../actions/actionTypes';
import { AUTHENTICATION_STATUS } from '../util/constants';

export default function loginReducer(state = initialState.login, action) {
  const { type, payload } = action;
  switch (type) {
    case SR_LOGIN_SET_URL_TOKEN:
      return {
        ...state,
        initialToken: payload.token,
      };
    case SR_LOGIN_RECEIVE_INFO:
      return {
        ...state,
        packageCode: payload.packageCode,
        role: payload.role,
        customerId: payload.customerId,
        country: payload.country,
        credentials: {
          accessKey: payload.credentials.accesskey,
          secret: payload.credentials.secret,
          sessionToken: payload.credentials.sessionToken,
          expiration: payload.credentials.expiration,
          region: payload.credentials.region,
        },
      };
    case HUB_AUTH_USER_AUTHENTICATED:
      return {
        ...state,
        authenticationStatus: payload.authenticationStatus,
        role: payload.role,
        credentials: {
          ...state.credentials,
          accessKey: payload.credentials.accessKeyId,
          secret: payload.credentials.secretAccessKey,
          sessionToken: payload.credentials.sessionToken,
          expiration: payload.credentials.expiration,
          staffToken: payload.staffToken,
        },
      };
    case HUB_AUTH_GET_USER_INFO_SUCCESS:
      return {
        ...state,
        customerId: payload.customerId,
      };
    case HUB_AUTH_USER_UN_AUTHENTICATED:
      return {
        ...state,
        role: null,
        authenticationStatus: payload.authenticationStatus,
        credentials: {
          ...state.credentials,
          accessKey: null,
          secret: null,
          sessionToken: null,
          expiration: null,
          staffToken: null,
        },
      };
    case HUB_AUTH_USER_AUTHENTICATION_ERROR:
      return {
        ...state,
        role: null,
        authenticationStatus: AUTHENTICATION_STATUS.AUTHENTICATION_ERROR,
        credentials: {
          ...state.credentials,
          accessKey: null,
          secret: null,
          sessionToken: null,
          expiration: null,
          staffToken: null,
        },
      };
    
    case HUB_GLOBAL_CLOCK_SKEW_DETECTED:
      return {
        ...state,
        isClockSkewDetected: true,
      };
    default:
      return state;
  }
}
