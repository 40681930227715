import { ddLogger, ddLoggerLevel, logError } from '../logging';
import env from '../env';
import { FETCH_FAILED, INVALID_RESPONSE } from '../logging/errors';

export class PaymentService {
  static getMuleSoftEndpoint() {
    return `https://${env.REACT_APP_MULESOFT_DOMAIN}`;
  }

  static async setPrimaryPaymentMethod(zuoraAccountNumber, refId) {
    const url = `${this.getMuleSoftEndpoint()}/accounts/paymentMethods`;
    try {
      const body = {
        accountNumber: zuoraAccountNumber,
        defaultPaymentMethodId: refId,
      };

      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });

      if (response.status !== 202) {
        ddLogger({
          level: ddLoggerLevel.ERROR,
          label: INVALID_RESPONSE.message,
          data: {
            url, response, zuoraAccountNumber, refId,
          },
          error: new Error(INVALID_RESPONSE.message),
        });
        throw new Error('setPrimaryPaymentMethod failed');
      }

      const json = await response.json();

      return json;
    } catch (error) {
      logError('Error setting primary payment method', null, {});
      console.warn(error);
      ddLogger({
        level: ddLoggerLevel.ERROR,
        label: FETCH_FAILED.message,
        data: {
          url, error, zuoraAccountNumber, refId,
        },
        error: new Error(FETCH_FAILED.message),
      });
      throw new Error('There was an error setting up your new payment method. Please try again later or contact us.');
    }
  }

  static async validateCreditCard(refId) {
    console.log('[debug] validating credit card ', { refId });
    const url = `${this.getMuleSoftEndpoint()}/paymentmethods/verify`;
    try {
      const body = {
        paymentMethodId: refId,
      };

      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });

      if (response.status !== 200) {
        ddLogger({
          level: ddLoggerLevel.ERROR,
          label: INVALID_RESPONSE.message,
          data: {
            url, response, refId,
          },
          error: new Error(INVALID_RESPONSE.message),
        });
        throw new Error('validateCreditCard failed');
      }

      const json = await response.json();

      return json;
    } catch (error) {
      logError('Error validating credit card', null, {});
      console.warn(error);
      ddLogger({
        level: ddLoggerLevel.ERROR,
        label: FETCH_FAILED.message,
        data: {
          url, error, refId,
        },
        error: new Error(FETCH_FAILED.message),
      });
      throw new Error('We could not validate your credit card. Please try again later or contact us.');
    }
  }
}
