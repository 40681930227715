import env from '../env';
import { sendPostRequest } from './RequestService';
import { isTimeslotInClueyBusinessHour } from '../util/helper/availability';
import { getTimezoneFromStore } from '../selectors/hubSelector';

export class TutorAvailabilitiesService {
	static baseURL = `https://${env.REACT_APP_MULESOFT_DOMAIN}`;

	static async getAvailableSlotsForSession(sessionId, packageId, tutorInitiated = false, tutorSfid) {
		const availability = tutorInitiated ? ['regular', 'exclusive'] : ['regular'];
		const payload = tutorInitiated && !!tutorSfid ? {
			sessionId,
			packageId,
			availability,
			/* IMPORTANT: additionalAction must ONLY be included in the payload IF and ONLY IF it is a tutor-initiated reschedule */
			additionalAction: {
				name: 'autoRejectTutorRescheduleRequest',
				tutorSfid,
			},
		} : {
			sessionId,
			packageId,
			availability,
		};
		const url = `${TutorAvailabilitiesService.baseURL}/matching/available-slots-for-sessions`;
		const errorMessageForUser = 'There was a problem while retrieving some data.';

		try {
			const res = await sendPostRequest({
				url,
				payload,
				customErrorMsg: errorMessageForUser,
			});
			const timezone = getTimezoneFromStore();
			return res.json().then((result) => {
				return result.availableTimeslots.filter((timeslot) => {
					const { start, end } = timeslot;
					return isTimeslotInClueyBusinessHour({ start, end, timezone });
				});
			});
		} catch (error) {
			throw error;
		}
	}

	static async getAvailableSlotsForPackage(packageId, tutorId, rescheduleFrom, daysBetweenSessions) {
		const payload = {
			tutorId,
			packageId,
			daysBetweenSessions,
			rescheduleFrom,
		};
		const url = `${TutorAvailabilitiesService.baseURL}/matching/available-slots-for-package`;
		const errorMessageForUser = 'There was a problem while retrieving some data.';

		try {
			const res = await sendPostRequest({
				url,
				payload,
				customErrorMsg: errorMessageForUser,
			});

			const timezone = getTimezoneFromStore();
			return res.json().then((result) => {
				return result.availableTimeslots.filter((timeslot) => {
					const { start, end } = timeslot;
					return isTimeslotInClueyBusinessHour({ start, end, timezone });
				});
			});
		} catch (error) {
			throw error;
		}
	}

	static async getAvailableTimeSlotsOfTutor({
		tutorId,
		rescheduleFrom,
		rescheduleTo,
		studentTimeZone,
		tutorSessionId,
		tutorInitiated = false,
		sessionDuration,
	}) {
		const availability = tutorInitiated ? ['regular', 'exclusive'] : ['regular'];
		const sessionIds = [tutorSessionId];
		const payload = {
			tutors: [
				{
					tutorId,
					matchingRank: 1 /* always 1 */,
				},
			],
			sessionDuration: sessionDuration || 60,
			daysBetweenSessions: 7 /* regardless of frequency, this should always be 7 */,
			availableOccurences: 1 /* always 1 */,
			rescheduleFrom /* the selected date */,
			rescheduleTo /* the selected date + 23 hours 59 minutes and 59 seconds */,
			studentTimeZone,
			metadata: {
				availability,
				sessionIds,
			},
		};
		const url = `${TutorAvailabilitiesService.baseURL}/available-slots/find`;
		const errorMessageForUser = "There was a problem retrieving your tutor's schedule.";
		try {
			const res = await sendPostRequest({
				url,
				payload,
				customErrorMsg: errorMessageForUser,
			});

			const { availableTimeslots } = await res.json();
			const filteredResult = availableTimeslots.filter(({ start, end }) => {
				return isTimeslotInClueyBusinessHour({
					start,
					end,
					timezone: getTimezoneFromStore(),
					convertTimeslotsToUTC: true,
				});
			});
			return filteredResult;
		} catch (error) {
			throw error;
		}
	}
}
