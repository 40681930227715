import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '@cluey/cluey-components';

const FieldLabel = ({ id, labelText }) => {
  return (
    <Label id={id} size="base" className="font-body inline-block font-medium mb-1">{labelText}</Label>
  );
};

FieldLabel.propTypes = {
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
};

export default FieldLabel;
