import { useEffect, useMemo } from 'react';
import ErrorBlock from '../../../../common/ErrorBlock';
import { fetchUpcomingSessionsWithSTPH } from '../../../../actions/hub/hubSessionsPageActions';
import { sortBy } from 'lodash';
import { HubSessionsListItem } from '../../sessions/HubSessionsListItem';
import InPageLoader from '../../../../common/InPageLoader';
import moment from 'moment';
import { PATH_HUB_UPCOMING_SESSIONS } from '../../../../util/pagePath';
import SchoolTermListItem from '../../sessions/SchoolTermListItem';
import SectionHead from '../SectionHead';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import withHeadTag from '../../../../common/HeadComponent';
import env from '../../../../env';
import { gethubUpcomingSessionsPage } from '../../../../selectors/apiStateSelector';
import { api } from '../../../../api';
import { Session } from '../../../../api/types/session';
import { SchoolTerm } from '../../../../api/types/schoolTerm';

const isSchoolTerm = (session: Session | SchoolTerm): session is SchoolTerm => {
	return !session.hasOwnProperty('studentSessionId');
};

const HomeSessions = () => {
	const errorLoading = useSelector(gethubUpcomingSessionsPage).fetchError;
	const isMaintenanceMode = env.REACT_APP_MAINTENANCE_MODE;
	const dispatch = useDispatch();

	// <queries>
	const { data: sessions = [], isLoading: isLoadingSessions } = api.session.all.useQuery('upcoming', {
		select: (sessions) => {
			return sessions.filter((session) => moment(session.startTime).isSame(moment(), 'day'));
		},
	});
	const { data: schoolTermsData, isLoading: isLoadingSchoolTerms } = api.static.keyDates.useQuery();
	const { data: details, isLoading: isLoadingDetails } = api.account.details.useQuery();
	// </queries>

	const activeSpinner = isLoadingSessions || isLoadingSchoolTerms || isLoadingDetails;

	useEffect(() => {
		if (sessions && sessions.length === 0) {
			dispatch(fetchUpcomingSessionsWithSTPH);
		}
	}, [dispatch, sessions]);

	const sortedMixedSessionsAndTerms = useMemo(() => {
		if (sessions && sessions.length > 0 && schoolTermsData) {
			let filteredSchoolTerms: typeof schoolTermsData = [];
			if (schoolTermsData.length > 0) {
				filteredSchoolTerms = schoolTermsData.filter((st) => {
					return moment(st.startTime).diff(moment(), 'days') <= 7;
				});
			}

			const sortedSessions = sortBy(sessions, ['startTime', 'student', 'subject']);
			const lastSession = sortedSessions[sortedSessions.length - 1];
			const lastSessionMoment = moment(lastSession.startTime);
			const now = moment();
			const filteredSchoolTermsByLastSession = filteredSchoolTerms.filter((schoolTerm) => {
				const startMoment = moment(schoolTerm.startDate);
				return startMoment.isSameOrAfter(now) && startMoment.isSameOrBefore(lastSessionMoment, 'day');
			});
			const mixedSessionsAndTerms = [...sortedSessions, ...filteredSchoolTermsByLastSession];

			return sortBy(mixedSessionsAndTerms, ['startTime', 'student', 'subject']);
		} else {
			return [];
		}
	}, [sessions, schoolTermsData]);

	return (
		<>
			{activeSpinner && <InPageLoader position="relative" height="auto" />}
			{!activeSpinner && errorLoading && (
				<ErrorBlock errorMsg="Unable to load your upcoming sessions, please try again later." />
			)}
			{!activeSpinner && !errorLoading && details?.timezone && (
				<>
					{isMaintenanceMode ? (
						<div className="mb-lg-6 mt-lg-11 mb-4 mt-10">
							<h2 className="m-0 font-display text-xl font-bold leading-tight md:text-2xl lg:text-3xl">
								Today's sessions
							</h2>
						</div>
					) : (
						<SectionHead title="Today's sessions" linkName="View all" linkDes={PATH_HUB_UPCOMING_SESSIONS} />
					)}
					{sortedMixedSessionsAndTerms.length > 0 ? (
						<>
							{sortedMixedSessionsAndTerms.map((s) => {
								if (isSchoolTerm(s)) {
									return <SchoolTermListItem key={s.startDate + s.endDate} termItem={s} timezone={details.timezone} />;
								}
								return (
									<HubSessionsListItem
										key={s.uniqueEnrolmentId + s.startTime}
										session={s}
										timezone={details.timezone}
									/>
								);
							})}
						</>
					) : (
						<p>You have no upcoming sessions today.</p>
					)}
				</>
			)}
		</>
	);
};

export default compose(withHeadTag(HomeSessions));
