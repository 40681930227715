import { useEffect, useState } from 'react';
import moment from 'moment';
import { inflect } from '../../../../../util/helper/inflection';

interface Params {
	startTime: string;
}

export const useLiveUpdateSessionStatus = ({ startTime }: Params) => {
	const [status, setStatus] = useState('');

	useEffect(() => {
		let timeout: ReturnType<typeof setTimeout> | null = null;

		const countdown = () => {
			if (moment.utc().isSameOrAfter(startTime)) {
				return setStatus('In progress');
			}

			const statusWords: { momentDiff: moment.unitOfTime.Diff; noun: string }[] = [
				{ momentDiff: 'days', noun: 'day' },
				{ momentDiff: 'hours', noun: 'hr' },
				{ momentDiff: 'minutes', noun: 'min' },
			];

			const statusText = statusWords
				.map(({ momentDiff, noun }) => {
					const timeUnitLeftBeforeCommencement = moment(startTime).diff(moment.utc(), momentDiff) % (noun === 'hr' ? 24 : 60);
					const inflectedTimeUnit = inflect(noun, timeUnitLeftBeforeCommencement);
					return timeUnitLeftBeforeCommencement > 0 ? `${timeUnitLeftBeforeCommencement} ${inflectedTimeUnit}` : '';
				})
				.filter((el) => el.length > 0)
				.join(', ');

			setStatus(`In ${statusText}`);
			timeout = setTimeout(() => {
				countdown();
			}, 10000);
		};
		countdown();

		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	}, [startTime]);

	return status;
};
