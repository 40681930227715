import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const ButtonAsLink = ({ text, fontSize, onClickHandler, extraClasses }) => {
	return (
		<button
			type="button"
			role="link"
			className={classNames(
				'block rounded-none border-0 p-0 text-left leading-[1.14] tracking-normal text-primary hover:underline',
				fontSize,
				{ [extraClasses]: extraClasses !== null }
			)}
			onClick={onClickHandler}
		>
			{text}
		</button>
	);
};

ButtonAsLink.propTypes = {
	text: PropTypes.string.isRequired,
	fontSize: PropTypes.string,
	onClickHandler: PropTypes.func.isRequired,
	extraClasses: PropTypes.string,
};

ButtonAsLink.defaultProps = {
	fontSize: 'text-lg',
	extraClasses: null,
};
