export const PATH_NAME_REPORT = '/latest/';
export const PATH_SESSION_REPORT = PATH_NAME_REPORT;
export const PATH_UPCOMING_SESSIONS = '/portal/upcoming-sessions/';
export const PATH_RESCHEDULE_SESSION = '/portal/manage-sessions/';
export const PATH_RESCHEDULE_SESSION_OLD = '/portal/manage-sessions/main';

/**
 * Hub For Customers
 */
export const PATH_HUB_BASE = '/portal/customer/';
export const PATH_HUB_HOME = '/portal/customer/home/';

export const PATH_HUB_SESSIONS_PAGE = `${PATH_HUB_BASE}sessions/`;
export const PATH_HUB_UPCOMING_SESSIONS = `${PATH_HUB_SESSIONS_PAGE}upcoming/`;
export const PATH_HUB_COMPLETED_SESSIONS = `${PATH_HUB_SESSIONS_PAGE}completed/`;
export const PATH_HUB_CANCELLED_SESSIONS = `${PATH_HUB_SESSIONS_PAGE}cancelled/`;
export const PATH_HUB_ENROLMENTS_PAGE = `${PATH_HUB_BASE}enrolments/`;
export const PATH_HUB_ENROLMENTS = `${PATH_HUB_ENROLMENTS_PAGE}`;
export const PATH_HUB_ACCOUNT_PAGE = `${PATH_HUB_BASE}account/`;
export const PATH_HUB_CHANGE_PLAN = `${PATH_HUB_ACCOUNT_PAGE}change-plan/`;
export const PATH_HUB_ACCOUNT_DETAILS = `${PATH_HUB_BASE}account/account-details/`;
export const PATH_HUB_RESCHEDULE_SESSION_INFO = `${PATH_HUB_BASE}updates/reschedule-session-info/`;

export const PATH_HUB_PLAN_DETAILS = `${PATH_HUB_BASE}account/plan-details/`;
/* plan details */
export const PATH_HUB_INVOICE_HISTORY = `${PATH_HUB_BASE}account/invoice-history/`;
export const PATH_HUB_NO_ACTIVE_ENROLMENTS = `${PATH_HUB_BASE}no-active-enrolments/`;
export const PATH_HUB_ENROLMENT_DETAIL = `${PATH_HUB_BASE}enrolment/`;
export const PATH_HUB_ENROLMENT_CHANGE_SCHEDULE = `${PATH_HUB_BASE}enrolment/change-schedule/`;
export const PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_KEEP_PREV_TUTOR = `${PATH_HUB_ENROLMENT_CHANGE_SCHEDULE}keep-previous-tutor/`;
export const PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP = `${PATH_HUB_BASE}enrolment/change-group-schedule/`;
export const PATH_HUB_ENROLMENT_NAPLAN = `${PATH_HUB_BASE}enrolment/naplan/`;
/* pausing enrolments */
export const PATH_HUB_ENROLMENT_MANAGE_PAUSE = `${PATH_HUB_BASE}enrolment/manage-pause/`;
export const PATH_HUB_CR_PAUSE_SESSIONS = `${PATH_HUB_BASE}pause-sessions`;
export const PATH_HUB_SUMMER_PAUSE_ENROLMENT = `${PATH_HUB_BASE}summer-pause-enrolment`;
export const PATH_HUB_CR_PAUSE_SUMMER_CANCEL = `${PATH_HUB_BASE}pause-summer-cancel`;
export const PATH_HUB_POLICY = `${PATH_HUB_BASE}policy/:policyType`;
export const PATH_HUB_SERVICE_FEES = `${PATH_HUB_BASE}service-fees`;

export const PATH_HUB_SOCIAL_OAUTH_CALLBACK = `${PATH_HUB_BASE}callback/`;
export const PATH_HUB_SOCIAL_OAUTH_FAILED = `/portal/social-oauth-failed/`;
export const PATH_HUB_STAFF_LOGIN = `${PATH_HUB_BASE}staff-login/`;
export const PATH_HUB_SIGN_UP = `${PATH_HUB_BASE}signup-method/`;
export const PATH_HUB_SIGN_UP_CONFIRMATION = `${PATH_HUB_BASE}signup-complete/`;
export const PATH_HUB_SIGN_UP_ERROR = `${PATH_HUB_BASE}signup-error/`;
export const PATH_HUB_LOG_IN = `${PATH_HUB_BASE}login/`;
export const PATH_HUB_ONBOARDING = `${PATH_HUB_BASE}onboarding-signup/`;
export const PATH_HUB_FORGET_PASSWORD = `${PATH_HUB_BASE}forget-password/`;
export const PATH_HUB_RESET_PASSWORD_REQUEST = `${PATH_HUB_BASE}reset-password-request/`;
export const PATH_HUB_RESET_PASSWORD_REQ_SENT = `${PATH_HUB_BASE}reset-password-sent/`;
export const PATH_HUB_SET_NEW_PASSWORD = `${PATH_HUB_BASE}set-new-password/`;
export const PATH_HUB_SET_PASSWORD = `${PATH_HUB_BASE}reset-password/`;
export const PATH_HUB_NEW_PASSWORD_SUCCESS = `${PATH_HUB_BASE}new-password-success/`;
export const PATH_HUB_RESEND_SIGNUP_LINK_REQUEST = `${PATH_HUB_BASE}resend-sign-up-request/`;
export const PATH_HUB_RESEND_SIGNUP_LINK_REQ_SENT = `${PATH_HUB_BASE}signup-link-sent`;
export const PATH_HUB_ACTIVATE_ACCOUNT = `${PATH_HUB_BASE}activate-account/`;

export const PATH_HUB_TEST = `${PATH_HUB_BASE}dev-test-page/`;

export const PATH_HUB_CR = `${PATH_HUB_BASE}contact-request/`;
export const PATH_HUB_CR_REQUEST_OPTIONS = `${PATH_HUB_CR}request-options/`;
export const PATH_HUB_CR_PERMANENT_SCHEDULE_CHANGE = `${PATH_HUB_CR}permanent-schedule-change/`;
export const PATH_HUB_CR_CHANGE_TUTOR = `${PATH_HUB_CR}change-tutor/`;
export const PATH_HUB_CR_CANCEL_ENROLMENT = `${PATH_HUB_CR}cancel-enrolment/`;
export const PATH_HUB_CR_OTHER_ENROLMENT_CHANGE = `${PATH_HUB_CR}other-enrolment-change/`;
export const PATH_HUB_CR_PAYMENT_QUERY = `${PATH_HUB_CR}payment-query/`;
export const PATH_HUB_CR_OTHER_QUERY = `${PATH_HUB_CR}other/`;
export const PATH_HUB_CR_REPORT_ISSUE = `${PATH_HUB_CR}report-issue/`;
export const PATH_HUB_CR_TECHNICAL_ISSUE = `${PATH_HUB_CR}technical-issue/`;
export const PATH_HUB_CR_ADD_ENROLMENT = `${PATH_HUB_CR}add-enrolment/`;
export const PATH_HUB_CR_FEEDBACK = `${PATH_HUB_CR}feedback/`;
export const PATH_HUB_CR_OPT_IN_OUT = `${PATH_HUB_CR}practice/`;
export const PATH_HUB_CR_RESCHEDULE_SESSION = `${PATH_HUB_CR}reschedule-session/`;
export const PATH_HUB_CR_SKIP_SESSION = `${PATH_HUB_CR}skip-session/`;
export const PATH_HUB_CR_REQUEST_RECORDING = `${PATH_HUB_CR}request-recording/`;
export const PATH_HUB_CR_SUBMIT_CASE_SUCCESS = `${PATH_HUB_CR}success/`;
export const PATH_HUB_CR_CHANGE_CONTENT_FOCUS = `${PATH_HUB_CR}change-content/`;
export const PATH_HUB_CR_SWITCH_LEARNING_MODE = `${PATH_HUB_CR}switch-mode/`;
export const PATH_HUB_CR_ADD_SESSION = `${PATH_HUB_CR}add-session/`;
export const PATH_HUB_TTC_CHANGE_SESSION_KEEP_TUTOR = `${PATH_HUB_BASE}change-session-same-tutor/`;

// Feature Pages
export const PATH_HUB_MID_YEAR_PROGRESS = `${PATH_HUB_BASE}mid-year-progress/`;

/**
 * Hub For Schools
 */
export const PATH_SCHOOL_BASE = '/portal/school/';

// Enrolment
export const PATH_SCHOOL_UPCOMING_SESSIONS = `${PATH_SCHOOL_BASE}upcoming-sessions/`;
/*
  Stand-alone pages
*/
export const PATH_HUB_SELECT_SESSION = `${PATH_HUB_BASE}enrolment/:enrolmentId/sessions`;

export const PATH_HUB_ADD_ONE_OFF_SESSION = `${PATH_HUB_BASE}enrolment/:enrolmentId/add-one-off-session/`;
export const PATH_HUB_ADD_ONE_OFF_SESSION_CONFIRM = `${PATH_HUB_ADD_ONE_OFF_SESSION}confirm/`;
export const PATH_HUB_ADD_ONE_OFF_SESSION_SUCCESS = `${PATH_HUB_ADD_ONE_OFF_SESSION}success/`;
/*
  Pages with url params
*/
export const PATH_HUB_SKIP_SESSION = `${PATH_HUB_BASE}session/:sessionId/skip`;
export const PATH_HUB_RESCHEDULE_SESSION = `${PATH_HUB_BASE}session/:sessionId/reschedule`;
export const PATH_HUB_RESCHEDULE_SESSION_WITH_TUTOR = `${PATH_HUB_BASE}session/:sessionId/reschedule-request`;
export const PATH_HUB_SESSION_MESSAGE = `${PATH_HUB_BASE}session/:sessionId/session-message`;
export const PATH_NEW_YEAR_ENROLMENT_DETAIL = `${PATH_HUB_BASE}enrolment/:enrolmentId/new-year`;
export const PATH_NEW_YEAR_PROGRAM_OVERVIEW = `${PATH_HUB_BASE}program/:enrolmentId/:subject/overview`;
/*
  Manage service routes
*/
export const PATH_HUB_MANAGE_SERVICE = `${PATH_HUB_BASE}manage-service/`;
export const PATH_HUB_MS_SESSION_TIMES = `${PATH_HUB_MANAGE_SERVICE}session-times/:enrolmentId`;
export const PATH_HUB_MS_LEARNING_ADJUSTMENTS = `${PATH_HUB_MANAGE_SERVICE}learning-adjustments/:enrolmentId`;
export const PATH_HUB_MS_STUDENTS_AND_SUBJECTS = `${PATH_HUB_MANAGE_SERVICE}students-and-subjects`;
export const PATH_HUB_MS_BILLING_AND_PAYMENTS = `${PATH_HUB_MANAGE_SERVICE}billing-and-payments`;
export const PATH_HUB_SUPPORT = `${PATH_HUB_MANAGE_SERVICE}support`;

/*
  Assessment routes
*/
export const PATH_HUB_GENERATE_ASSESSMENT = `${PATH_HUB_BASE}assessment/generate/:assessmentSubject`;

/* 
DEPRECATED ROUTES
*/
export const PATH_HUB_BILLING_INFORMATION = `${PATH_HUB_BASE}account/billing-information/`;

/* 
Student routes
*/

export const PATH_HUB_STUDENT_PROFILE = `${PATH_HUB_BASE}students/:studentSfid/profile/`;
export const PATH_HUB_STUDENT_EDIT_PROFILE = `${PATH_HUB_BASE}students/:studentSfid/edit/`;
export const PATH_HUB_ADD_STUDENT = `${PATH_HUB_BASE}students/add-student`; // Do not add a trailing slash, it breaks the FormFlow component for some reason (TBD-1208)

/*
Assessment routes
*/
export const PATH_HUB_VIEW_ASSESSMENT = `${PATH_HUB_BASE}assessments/:token/`;

export const PATH_HUB_UPDATES = `${PATH_HUB_BASE}updates/`;

export const PATH_HUB_UPDATES_NEW_CUSTOMER_HUB = `${PATH_HUB_BASE}updates/new-customer-hub/`;

/*
Customer input routes
*/
export const PATH_HUB_UPDATES_CUSTOMER_INPUT = `${PATH_HUB_BASE}updates/upcoming-session-message/`;

/*
Cluey Coach
*/
export const PATH_HUB_AI_TUTOR = `${PATH_HUB_BASE}ai-tutor/:enrolmentId/`;
export const PATH_HUB_AI_TUTOR_FAQ = `${PATH_HUB_BASE}ai-tutor/:enrolmentId/faq/`;
export const PATH_HUB_UPDATES_AI_TUTOR = `${PATH_HUB_BASE}updates/cluey-coach-tutor/`;