/**
 * @typedef
 */
const DIGIT3ISOCODES = {
  australia: 'AUS',
  'new zealand': 'NZL',
};

export const getIsoCountryCode3 = (country) => {
  const countryLower = country.toLowerCase().replace(/-/g, '');
  const code = DIGIT3ISOCODES[countryLower];
  return code || DIGIT3ISOCODES.australia;
};
