import initialState from './initialState';
import { HUB_ZUORA_RESET_PARAMS, HUB_ZUORA_FETCH_ZUORA_FAILED, HUB_ZUORA_FETCH_ZUORA_SUCCESS } from '../actions/actionTypes';

export default function zuora(state = initialState.zuora, action) {
  switch (action.type) {
    case HUB_ZUORA_FETCH_ZUORA_SUCCESS: {
      return {
        ...state,
        error: '',
        params: action.payload,
      };
    }
    case HUB_ZUORA_FETCH_ZUORA_FAILED: {
      return {
        ...state,
        error: action.payload,
        params: null,
      };
    }
    case HUB_ZUORA_RESET_PARAMS: {
      return {
        ...state,
        error: '',
        params: null,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
