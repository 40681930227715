export function isSessionReportLoading(state) {
  const {
    activities,
  } = state.ui.apiState;
  return activities.firstFetch;
}

export const isKeepPreviousTutorPageLoading = (state) => {
  const { hubEnrolmentDetailPage } = state.ui.apiState;
  const { firstFetch, previousTutor, tutorDetail } = hubEnrolmentDetailPage;
  return firstFetch || previousTutor.firstFetch || tutorDetail.firstFetch;
};

export const isPreviousTutorAvailabilityLoading = (state) => {
  const { previousTutor, tutorDetail, tutorAvailability } = state.ui.apiState.hubEnrolmentDetailPage;
  return previousTutor.firstFetch || tutorDetail.firstFetch || tutorAvailability.firstFetch;
};

export const isPreviousTutorLoading = (state) => {
  const { previousTutor, tutorDetail } = state.ui.apiState.hubEnrolmentDetailPage;
  return previousTutor.firstFetch || tutorDetail.firstFetch;
};

export const isFormPauseSessionsLoading = (state) => {
  const { hubUpcomingSessionsPage, hubSchoolTermsPublicHolidays } = state.ui.apiState;
  return hubUpcomingSessionsPage.isLoading || hubSchoolTermsPublicHolidays.isLoading;
};

export const isManagePausePageLoadingOrSubmitting = (state) => {
  const { managePauseGetNewResumeSession, managePauseNewResumeDateRequest } = state.ui.apiState.hubEnrolmentDetailPage;
  return managePauseGetNewResumeSession.firstFetch || managePauseNewResumeDateRequest.firstFetch;
};
