import moment from "moment";
import { Session } from "../../../api/types/session";

export function getPossessiveForm(name: string | null | undefined): string {
	if (!name) {
		return `Your`;
	}

	let possessiveForm = '';
	if (name.endsWith('s')) {
		possessiveForm = `${name}'`;
	} else {
		possessiveForm = `${name}'s`;
	}

	return `${possessiveForm}`;
}

export default function generateSessionDescription(session: Session) {
	const studentName = session?.student;
	const tutorName = session?.tutor;
	return `${getPossessiveForm(studentName)} ${session.subject} session (with ${tutorName}) on ${moment(
		session?.startTime
	).format('dddd, MMM D [at] h:mma')} - ${moment(session?.endTime).format('h:mma')}`;
}
