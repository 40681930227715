import { z } from 'zod';
import { createApi } from '../core';
import { Holiday, SchoolTerm } from '../types/schoolTerm';

export const schoolTermApi = createApi.group(['schoolTerm'], (createKey) => {
	const everythingQuery = createApi.query({
		queryKey: createKey(['all']),
		queryFn: () => {
			return createApi.fetch({
				path: ['account', 'holidays'],
				method: 'GET',
				basePath: 'portalAPI',
				sign: true,
				validator: z.array(SchoolTerm),
			});
		},
		staleTime: createApi.time(20, 'hours'),
		refetchInterval: createApi.time(24, 'hours'),
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		placeholderData: [],
	});

	return {
		everything: everythingQuery,
		all: createApi.query({
			queryKey: createKey(['all']),
			staleTime: createApi.time(20, 'hours'),
			refetchInterval: createApi.time(24, 'hours'),
			queryFn: () => {
				console.log('woah');
				return everythingQuery.fetchQuery();
			},
			select: (data) => {
				return data.filter((term) => {
					return term.type && term.type === 'School Term';
				});
			},
		}),
		holidays: createApi.query({
			staleTime: createApi.time(20, 'hours'),
			refetchInterval: createApi.time(24, 'hours'),
			queryKey: createKey(['all']),
			queryFn: async () => {
				const schoolTerms = await everythingQuery.fetchQuery();
				let holidays: Holiday[] = [];
				schoolTerms.forEach((term) => {
					const parse = Holiday.safeParse(term);
					if (parse.success) {
						holidays.push(parse.data);
					}
				});
				return holidays;
			},
		}),
	};
});
