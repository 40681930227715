import { useQuery } from '@tanstack/react-query';
import { sendGetRequest } from '../../../../services/RequestService';
import { getPortalAPIRequestParams } from '../../../../services/PortalAPIServices/helper';
import { useSelector } from 'react-redux';
import { EstimatedNextPaymentWithPricingPlanId } from '../../../../types/hubInterfaces';
import { PricePlan } from '../../../../types/hubTypes';
import { without } from 'lodash';
import { InvoicePer } from '../../../../types/hubTypes';

export type GetEstimatedNextPaymentParams = {
    pricingPlans: Array<PricePlan>;
    invoicePer?: InvoicePer;
};

export type GetEstimatedNextPaymentWithSfId = GetEstimatedNextPaymentParams & {
    sfId: string;
};

export async function getEstimates({ sfId, pricingPlans, invoicePer }: GetEstimatedNextPaymentWithSfId):
    Promise<Array<EstimatedNextPaymentWithPricingPlanId>> {
    return Promise.all(without(pricingPlans, null, undefined).map(async ({ pricingPlanId }) => {
        const { url, reqOptions } = getPortalAPIRequestParams(`pricingPlan/previewPaymentDetails?sfId=${sfId}&pricingPlanId=${pricingPlanId}&invoicePer=${invoicePer}`);
        const result = await sendGetRequest({
            url,
            signRequest: true,
            reqOptions,
        });
        return {
            pricingPlanId,
            ...await result.json()
        };
    }));
}

function useGetNextPaymentEstimates({ pricingPlans, invoicePer }: GetEstimatedNextPaymentParams) {
    const { sfId, accountInvoicePer} = useSelector((state) => {
        // @ts-ignore
        const { customerSfid, invoicePer } = state.hubAccountPage.accountDetails;
        return { sfId: customerSfid, accountInvoicePer: invoicePer };
    });

    return useQuery({
        queryKey: ['GET_PRICE_ESTIMATE', pricingPlans],
        queryFn: () => getEstimates({ sfId, pricingPlans, invoicePer: invoicePer || accountInvoicePer }),
        enabled: !!sfId && !!pricingPlans && !!(invoicePer || accountInvoicePer),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });
};

export default useGetNextPaymentEstimates;