import { Container, Link, LinkButton } from '@cluey/cluey-components';
import { Link as RouterLink } from 'react-router-dom';
import { Student } from '../../../../api/types/student';
import classNames from 'classnames';
import moment from 'moment';
import upperFirst from 'lodash/upperFirst';
import iconTickGreen from '../../../../assets/images/icon-filled-tick-green.svg';
import { api } from '../../../../api';
import EnrolmentCard from '../../../../components/hub/enrolments/EnrolmentCard/EnrolmentCard';
import { ComputerIcon, PencilIcon } from '../../../../icons';
import { TutorIcon } from '../../../../icons/TutorIcon';
import { ENROLMENT_STATUS } from '../../../../types/hubEnums';

type LearningProfileProps = {
	student?: Student;
	loading: boolean;
	selectedSubject: string;
	enrolmentsQuery: ReturnType<typeof api.enrolment.all.useQuery>;
	accountDetailsQuery: ReturnType<typeof api.account.details.useQuery>;
};

const SUBJECT = {
	ENGLISH: 'english',
	MATHS: 'mathematics',
	BIOLOGY: 'biology',
	CHEMISTRY: 'chemistry',
	PHYSICS: 'physics',
} as const;
type SUBJECT = typeof SUBJECT[keyof typeof SUBJECT];

const getAvailableSubjects = (student: Student): string[] => {
	if (!student) return [];

	if (student.studentCountry === 'Australia' && (student.yearLevel === 'Year 11' || student.yearLevel === 'Year 12')) {
		return [SUBJECT.ENGLISH, SUBJECT.MATHS, SUBJECT.BIOLOGY, SUBJECT.CHEMISTRY, SUBJECT.PHYSICS];
	} else {
		return [SUBJECT.ENGLISH, SUBJECT.MATHS];
	}
};

const skeletonize = (loading: boolean) => (loading ? 'rounded-full bg-grey-2 animate-pulse text-transparent' : '');

const StudentInfoItem = ({
	loading,
	label,
	value,
	placeholder,
}: {
	loading: boolean;
	label: string;
	value?: string;
	placeholder?: string;
}) => {
	const spooky = skeletonize(loading);
	return (
		<>
			<div className={classNames(spooky)}>{label}</div>
			<div className={classNames('font-bold', spooky)}>{loading ? placeholder : value}</div>
		</>
	);
};

const SubjectEnrolmentsCards = ({
	enrolmentIds,
	enrolmentsQuery,
	accountDetailsQuery,
}: {
	enrolmentIds: Student['enrolments'][string];
	enrolmentsQuery: ReturnType<typeof api.enrolment.all.useQuery>;
	accountDetailsQuery: ReturnType<typeof api.account.details.useQuery>;
}) => {
	const { data: enrolments } = enrolmentsQuery;
	const { data: details } = accountDetailsQuery;
	const uniquEnrIds = enrolmentIds.map((enrolment) => enrolment.uniqueEnrolmentId);
	const currentEnrolments = enrolments?.filter((enrolment) => uniquEnrIds.includes(enrolment.enrolmentId)) || [];

	return (
		<div className="flex flex-col gap-4 py-8">
			{currentEnrolments.map((enrolment) => (
				<EnrolmentCard key={enrolment.enrolmentId} enrolment={enrolment} timezone={details.timezone} isPreview />
			))}
		</div>
	);
};

export const LearningProfilePreviewBase = ({
	student,
	loading,
	selectedSubject,
	enrolmentsQuery,
	accountDetailsQuery,
}: LearningProfileProps) => {
	const subjects = getAvailableSubjects(student);
	const currentSubject = selectedSubject && subjects.includes(selectedSubject) ? selectedSubject : subjects[0];
	const currentSubjectIds = student && student.enrolments[currentSubject] ? student.enrolments[currentSubject] : null;

	return (
		<Container className="pointer-events-none flex flex-col gap-6">
			<h2 className="font-display text-xl font-bold">Learning Profile</h2>
			<div className="flex flex-col overflow-hidden rounded-lg border border-grey-3 bg-grey-1 shadow-md">
				{/* Overview */}
				<div className="flex flex-col gap-4 px-4 sm:px-16 py-8 md:flex-row ">
					<div className="flex flex-row gap-4">
						<div className="shrink sm:flex-1 sm:shrink-0">
							<div className="flex h-32 w-32 flex-col items-center justify-center rounded-full bg-slate-2">
									<TutorIcon className="h-8 w-8 text-slate-4 mb-2" />
								<div className="text-sm text-slate-4">Joined Cluey on</div>
								<div className="font-display text-xl font-bold text-slate-4">
									{student ? moment(student.createdDate).format('MMM YY') : null}
								</div>
							</div>
						</div>
						<div className="shrink sm:flex-1 sm:shrink-0">
							<div className="flex h-32 w-32 flex-col items-center justify-center rounded-full bg-slate-2">
								<ComputerIcon className="h-8 w-8 text-slate-4" />
								<div className="text-sm text-slate-4">Sessions taken</div>
								<div className="font-display text-xl font-bold text-slate-4">{student?.sessionsCompleted}</div>
							</div>
						</div>
					</div>
					<div className="grid grid-cols-2 gap-x-4 gap-y-2">
						{/* Location */}
						<StudentInfoItem
							loading={loading}
							label="Location"
							value={
								student?.studentCountry === 'Australia' && student?.location
									? `${student.location} ${student.studentCountry}`
									: student?.studentCountry
							}
							placeholder="STATE Country"
						/>
						{/* Year */}
						<StudentInfoItem loading={loading} label="School Year" value={student?.yearLevel} placeholder="Year 0" />
						{/* School Name */}
						<StudentInfoItem
							loading={loading}
							label="School Name"
							value={student?.schoolName || 'Not entered'}
							placeholder="Something Something High School"
						/>
					</div>
					<div>
						{student?.studentId ? (
							<Link to="/" className="flex items-center gap-2 whitespace-nowrap border-b-2 border-transparent">
								<PencilIcon className="h-6 w-6 text-primary hover:text-blue-6" /> Edit Profile
							</Link>
						) : (
							<div className={skeletonize(true)}>Edit Profile</div>
						)}
					</div>
				</div>
				{/* Per Enrolment Tabs */}
				<div className="-mx-px flex">
					{getAvailableSubjects(student).map((subject) => (
						<RouterLink
							className={classNames(
								'flex h-[73px] flex-1 flex-col items-center justify-center rounded-t-lg border border-grey-3 py-4 text-center',
								currentSubject === subject ? 'border-b-white bg-white' : 'bg-slate-1'
							)}
							to="/"
							key={subject}
						>
							<span className="font-bold text-primary">{upperFirst(subject)}</span>
							{student && Object.keys(student.enrolments).includes(subject) ? (
								<div className="flex items-center justify-center gap-1">
									<img src={iconTickGreen} alt="Check" className="h-3 w-3" />
									Enrolled
								</div>
							) : null}
						</RouterLink>
					))}
				</div>
				{/* Enrolment Tab */}
				<div className="bg-white p-16">
					<div className="rounded bg-slate-4 px-4 py-1 font-display font-bold text-white">Enrolment details</div>
					{currentSubjectIds && currentSubjectIds.length ? (
						<SubjectEnrolmentsCards
							enrolmentIds={currentSubjectIds}
							enrolmentsQuery={enrolmentsQuery}
							accountDetailsQuery={accountDetailsQuery}
						/>
					) : (
						<>
							<p className="py-4">No current enrolment</p>
							<LinkButton to="/" appearance="reverse" className="!font-bold">
								Enrol now
							</LinkButton>
						</>
					)}
				</div>
			</div>
		</Container>
	);
};

const LearningProfilePreview = () => {
	const enrolmentsQuery = api.enrolment.all.useQuery('active', {
		queryKey: ['enrolment', 'active', 'preview'],
		enabled: false,
		placeholderData: [
			{
				cancelReason: null,
				costPerSession: 70,
				courseName: 'Mathematics',
				curriculum: 'NSW',
				customerNumber: 'customerNumber1',
				englishTextStudied: null,
				endTime: null,
				enrolmentCancelRequestedDate: null,
				enrolmentEndDate: null,
				enrolmentId: 'uniqueEnrolmentId1',
				enrolmentPausedFrom: null,
				enrolmentPausedTo: null,
				enrolmentSfid: 'enrolmentId1',
				enrolmentStartDate: '2023-07-19T00:00:00.000Z',
				enrolmentStatus: 'New Customer' as ENROLMENT_STATUS,
				lastname: 'Kelly',
				packageMasterCode: 'MathsAU005',
				packageSfid: 'packageId1',
				packageTutorSfid: 'packageTutorId1',
				packageType: null,
				schedule: [],
				sessions: [],
				sessionCount: '10',
				sessionFrequency: 'Weekly',
				sessionReportId: null,
				startTime: '2024-04-02T07:00:00.000Z',
				student: 'Charlie',
				studentInformation: '',
				studentSfid: 'studentId1',
				subject: 'Mathematics',
				tutor: 'Tia',
				tutorPhoto: '',
				tutorNumber: 'tutorNumber1',
				tutorSfId: 'tutorId1',
				type: 'Course',
				yearAsInteger: 5,
				yearLevel: 'Year 5',
			},
		],
	});
	const accountDetailsQuery = api.account.details.useQuery(undefined, {
		enabled: false,
		placeholderData: { timezone: 'Australia/Sydney' },
	});

	const student: Student = {
		studentId: 'studentId1',
		customerNumber: 'customerNumber1',
		firstName: 'Charlie',
		lastName: 'Kelly',
		studentCountry: 'Australia',
		location: 'NSW',
		yearLevel: 'Year 5',
		schoolName: 'Not entered',
		schoolId: '',
		learningNeeds: '',
		sessionsCompleted: '2',
		availableSubjects: ['Mathematics'],
		enrolments: {
			mathematics: [
				{
					sfId: 'enrolmentId1',
					uniqueEnrolmentId: 'uniqueEnrolmentId1',
				},
			],
		},
		createdDate: '2023-07-19T00:00:00.000Z',
	};

	return (
		<LearningProfilePreviewBase
			student={student}
			loading={false}
			selectedSubject="mathematics"
			enrolmentsQuery={enrolmentsQuery}
			accountDetailsQuery={accountDetailsQuery}
		/>
	);
};

export default LearningProfilePreview;
