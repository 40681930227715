import React, { useState, useEffect } from 'react';

import { LOCALSTORAGE_KEEP_ME_LOGGED_IN } from '../../../util/constants';
import { Checkbox } from '@cluey/cluey-components';

const KeepMeLoggedIn = () => {
	const [isChecked, setIsChecked] = useState(true);

	useEffect(() => {
		localStorage.setItem(LOCALSTORAGE_KEEP_ME_LOGGED_IN, true);
	}, []);

	const onClick = () => {
		setIsChecked(!isChecked);
		localStorage.setItem(LOCALSTORAGE_KEEP_ME_LOGGED_IN, !isChecked);
	};

	return (
		<div className="mb-5 pl-5">
			<Checkbox value={isChecked} onChange={onClick} />
		</div>
	);
};

// KeepMeLoggedIn.propTypes = {

// };

export default KeepMeLoggedIn;
