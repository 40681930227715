import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { Textarea } from '@cluey/cluey-components';
import FieldLabel from '../hub/FieldLabel';

// A custom hook to make it easier to get the necessary props for a TextArea
export function useTextAreaProps({ id, labelText, rows, placeholder }) {
  const [value, setValue] = useState('');

  // Note: It's preferred to come up with a non dynamic id to avoid DOM updates
  if (!id) {
    id = uuid();
  }

  return {
    id,
    labelText,
    rows,
    value,
    setValue,
    placeholder,
  };
}

export const TextArea = ({
  id,
  labelText,
  rows,
  maxLength,
  value,
  setValue,
  className,
  placeholder,
}) => {
  return (
    <section className={className}>
      {labelText && <FieldLabel id={id} labelText={labelText} />}
      <Textarea
        name="text"
        id={id}
        value={value}
        rows={rows}
        maxLength={maxLength}
        placeholder={placeholder}
        onChange={setValue}
      />
    </section>
  );
};

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  rows: PropTypes.number,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
};

TextArea.defaultProps = {
  labelText: null,
  rows: 5,
  value: null,
  className: null,
  maxLength: 800,
  placeholder: null,
};
