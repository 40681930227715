import initialState from '../../initialState';
import * as actionTypes from '../../../actions/actionTypes';

export default function triggerPaymentReducer(state = initialState.ui.apiState.triggerPayment, action) {
  switch (action.type) {
    case actionTypes.CF_TRIGGER_PAYMENT_START:
      return {
        ...state,
        isLoading: true,
        fetchError: false,
      };
    case actionTypes.CF_TRIGGER_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fetchError: false,
      };
    case actionTypes.CF_TRIGGER_PAYMENT_FAILED:
      return {
        ...state,
        isLoading: false,
        fetchError: true,
      };
    default:
      return state;
  }
}
