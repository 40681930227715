import { Anchor, Button, Checkbox, ErrorText, Field, Link } from '@cluey/cluey-components';
import { Auth2Layout, InlineLoader, withUnauthed } from '../common';
import { type FormEvent, useMemo, useState, useEffect } from 'react';
import { z } from 'zod';
import { PATH_HUB_LOG_IN } from '../../../../util/pagePath';
import { LoginWith } from '../LoginPage';
import iconGG from '../../../../assets/images/google.svg';
import iconFB from '../../../../assets/images/facebook.svg';
import { useHistory, useLocation } from 'react-router';
import Cookies from 'js-cookie';
import { Auth } from 'aws-amplify';
import type { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import tick from '../../../../assets/images/icon-tick-round.svg';
import { useMutation } from '@tanstack/react-query';
import env from '../../../../env';
import { PATHS, T_SIGNUP_ORIGIN, SIGNUP_ORIGIN, useSignupStore, decodeToken } from '.';
import isError from 'lodash/isError';
import withHeadTag from '../../../../common/HeadComponent';

const SignUpPageValidation = z.object({
  fname: z
    .string()
    .min(1, 'Please enter your first name.')
    .regex(/^[a-zA-Z\s0-9_\-']+$/, "Please enter only letters, numbers, spaces and any of these symbols: _-'."),
  lname: z
    .string()
    .min(1, 'Please enter your last name.')
    .regex(/^[a-zA-Z\s0-9_\-']+$/, "Please enter only letters, numbers, spaces and any of these symbols: _-'."),
  email: z.string().email('Please enter a valid email address.').min(1, 'Please enter your email address.'),
  agree: z.boolean().refine((v) => v, { message: 'Please agree to the terms and conditions.' }),
});
type SignUpPageValidationType = z.infer<typeof SignUpPageValidation>;
type SignUpPageErrors = Record<keyof SignUpPageValidationType, string>;

type SignUpPageData = {
  title: string;
  reasons: string[];
  action: string;
};

type SIGNUP_VARIANT_KEYS = T_SIGNUP_ORIGIN | 'standard';
const SIGNUP_VARIANTS: Record<SIGNUP_VARIANT_KEYS, SignUpPageData> = {
  standard: {
    title: 'Sign up with Cluey Hub',
    reasons: [
      'Access 90+ worksheets tailored for years 2 to 10',
      "Practice 1000's of Mathematics questions",
      'Trial personalised learning programs',
    ],
    action: 'Sign up',
  },
  worksheets: {
    title: 'Download worksheets directly from Cluey Hub',
    reasons: ['Access 90+ worksheets tailored for years 2 to 10', "Practice 1000's of Mathematics questions"],
    action: 'Access worksheets now',
  },
  assessments: {
    title: 'Sign up with Cluey Hub',
    reasons: [
      'FREE assessment for years 3 to 9 students',
      'Choose between English and Mathematics',
      'See assessment score',
    ],
    action: 'Sign up',
  },
};
const SIGNUP_DEFAULT: SIGNUP_VARIANT_KEYS = 'assessments';

const useSearchParam = (key: string) => {
  const { search } = useLocation();
  return useMemo(() => {
    const params = new URLSearchParams(search);
    return params.get(key);
  }, [search, key]);
};

const SignUpPageRaw = () => {
  const location = useLocation<{ loginPageEmail: string }>();
  const { loginPageEmail } = location.state || {};

  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState(loginPageEmail || '');
  const [agree, setAgree] = useState(false);
  const [errors, setErrors] = useState<SignUpPageErrors>({
    fname: '',
    lname: '',
    email: '',
    agree: '',
  });

  const history = useHistory();

  const { mutate, isLoading: loading } = useMutation({
    mutationFn: async ({ fname, lname, email }: { fname: string; lname: string; email: string }) => {
      const response = await fetch(
        `https://${env.REACT_APP_CLUEY_API_HOST_NAME}/customer-hub/customer/create/salesforce`,
        {
          method: 'POST',
          body: JSON.stringify({
            first_name: fname,
            last_name: lname,
            email,
            source: 'hub-sign-up',
            tracking: {},
          }),
        }
      );

      const data = await response.json();
      console.log('RESPONSE', data);
      useSignupStore.setState({ customerNumber: data.username });

      return z
        .object({
          message: z.literal('OK'),
          username: z.string(),
          token: z.string(),
        })
        .parse(data);
    },
    onSuccess: (data) => {
      useSignupStore.setState({
        username: data.username,
        email,
        token: data.token,
      });
      history.push(PATHS.VERIFY_PATH);
    },
    onError: (error) => {
      if (isError(error)) {
        if ((error as Error).message.includes('already exists')) {
          setErrors((curr) => ({ ...curr, email: 'This email address is already in use.' }));
        }
      }
    },
  });

  const origin = useSearchParam('origin');

  const pageInfo = useMemo(() => {
    const parsedOrigin = SIGNUP_ORIGIN.safeParse(origin);
    if (parsedOrigin.success) {
      useSignupStore.setState({ origin: parsedOrigin.data });
      return SIGNUP_VARIANTS[parsedOrigin.data];
    } else {
      return SIGNUP_VARIANTS[SIGNUP_DEFAULT];
    }
  }, [origin]);

  const fedAuthSignIn = (provider: `${CognitoHostedUIIdentityProvider}`) => {
    Cookies.set('socialOauthSourcePage', location.pathname);
    Cookies.set('socialOauthSource', provider);
    Auth.federatedSignIn({ provider: provider as CognitoHostedUIIdentityProvider });
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrors({
      fname: '',
      lname: '',
      email: '',
      agree: '',
    });

    const result = SignUpPageValidation.safeParse({ fname, lname, email, agree });

    if (result.success === true) {
      mutate({ fname, lname, email });
    } else {
      const errorFields = {
        fname: '',
        lname: '',
        email: '',
        agree: '',
      };

      result.error.errors.forEach((error) => {
        const field = error.path.join('.');
        errorFields[field] = error.message;
      });

      setErrors(errorFields);
    }
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);

    if (urlSearchParams.has('token')) {
      const token = urlSearchParams.get('token');
      if (token) {
        const decoded = decodeToken(token);
        if (decoded) {
          useSignupStore.setState({
            token,
            email: decoded.email,
            tempPwd: 'tmpPassword' in decoded ? decoded.tmpPassword : decoded.temporaryPassword,
          });
          history.push(PATHS.PASSWORD_PATH);
        } else {
          console.warn('Unable to decode token', token);
        }
      }
    }
  }, [location.search, history]);

  const links = (
    <div className="w-full flex-grow md:pl-20 [&>*]:pb-4">
      <p>
        Already have an account?{' '}
        <Link size="base" to={PATH_HUB_LOG_IN}>
          Login
        </Link>
      </p>
    </div>
  );

  return (
    <Auth2Layout title={pageInfo.title} links={links}>
      {pageInfo.reasons.length > 0 && (
        <ul className="flex flex-col gap-3 pb-9 text-lg">
          {pageInfo.reasons.map((reason) => (
            <li key={reason} className="flex items-center gap-2">
              <img src={tick} alt="" className="h-6 pr-1" />
              {reason}
            </li>
          ))}
        </ul>
      )}
      <form onSubmit={onSubmit} className="flex w-full flex-col items-start gap-6">
        <Field
          id="signup-fname"
          label="First name"
          name="fname"
          value={fname}
          onChange={(e) => {
            setFname(e);
            setErrors({ ...errors, fname: '' });
          }}
          type="text"
          className="w-full max-w-sm"
          error={errors.fname}
          disabled={loading}
        />
        <Field
          id="signup-lname"
          label="Last name"
          name="lname"
          value={lname}
          onChange={(e) => {
            setLname(e);
            setErrors({ ...errors, lname: '' });
          }}
          type="text"
          className="w-full max-w-sm"
          error={errors.lname}
          disabled={loading}
        />
        <Field
          id="signup-email"
          label="Email"
          name="email"
          value={email}
          onChange={(e) => {
            setEmail(e);
            setErrors({ ...errors, email: '' });
          }}
          type="email"
          className="w-full max-w-sm"
          error={errors.email}
          disabled={loading}
        />
        <div className="flex flex-col">
          <div className="flex items-center gap-4 pb-1">
            <Checkbox
              disabled={loading}
              id="signup-agree"
              value={agree}
              error={!!errors.agree}
              onChange={(e) => {
                setAgree(e);
                setErrors({ ...errors, agree: '' });
              }}
            />
            <label htmlFor="signup-agree">
              I agree to receive communication in accordance with Cluey's{' '}
              <Anchor href="https://clueylearning.com.au/privacy-policy/" size="base" target="_blank">
                Privacy Policy
              </Anchor>
            </label>
          </div>
          {errors.agree && <ErrorText>{errors.agree}</ErrorText>}
        </div>
        <Button
          size="lg"
          type="submit"
          disabled={loading}
          className="relative flex items-center justify-center max-sm:w-full"
        >
          <span className={loading ? 'opacity-0' : ''}>{pageInfo.action}</span>
          {loading && <InlineLoader className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />}
        </Button>
      </form>
      <div className="flex w-full items-center py-14">
        <div className="h-px flex-grow bg-slate-2"></div>
        <p className="px-4">Or</p>
        <div className="h-px flex-grow bg-slate-2"></div>
      </div>
      <div className="flex flex-col items-start gap-4">
        {/* Login with Facebook */}
        <LoginWith icon={iconFB} alt="facebook" onClick={() => fedAuthSignIn('Facebook')}>
          Sign up with Facebook
        </LoginWith>
        {/* Login with Google */}
        <LoginWith icon={iconGG} alt="google" onClick={() => fedAuthSignIn('Google')}>
          Sign up with Google
        </LoginWith>
      </div>
    </Auth2Layout>
  );
};

export const SignUpPage = withUnauthed(withHeadTag(SignUpPageRaw));
