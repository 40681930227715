import { DollarIcon } from '../../../icons/DollarIcon';
import { ExclamationMarkIcon, CreditCardIcon } from '../../../icons';
import { Session } from '../../../types/hubInterfaces';
import { isPaymentSucceed, isPaymentPending, isPaymentFailed } from '../../../util/helpers';
import { InvoiceService } from '../../../services/InvoicesService';
import useNextPaymentQuery from '../../../hooks/queries/useNextPaymentQuery';
import { useQuery } from '@tanstack/react-query';
import { PROMO_DISCOUNT_MAPPING } from '../../../util/discountsMapping';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { getDaysAway } from '../../../views/account/PricingPlanDetailsPage/components/NextPaymentSection/helper';
import { Link } from '@cluey/cluey-components';
import { PATH_HUB_INVOICE_HISTORY } from '../../../util/pagePath';

interface Props {
  session: Session;
}
// Will asynchronously fetch the invoice link from Zuora
// and render if found
const InvoiceLink = ({ session }: { session: Session }) => {
  const [viewClicked, setViewClicked] = useState(false);
  const zuoraInvoice = session.invoiceId;
  const {
    data: version,
    isLoading,
    fetchStatus,
  } = useQuery({
    queryKey: ['ZUORA_LINK', zuoraInvoice],
    queryFn: () => InvoiceService.getInvoiceFileInfo(zuoraInvoice),
    enabled: !!zuoraInvoice && viewClicked,
    retry: false,
  });
  useEffect(() => {
    if (version && version[0].url && viewClicked) {
      window.open(version[0].url, '_blank');
    }
  }, [version, viewClicked]);
  const loading = isLoading && fetchStatus !== 'idle';
  if (!zuoraInvoice) return null;
  const handleClick = (e) => {
    e.preventDefault();
    if (!viewClicked) setViewClicked(true);
    else if (version[0].url) {
      window.open(version[0].url, '_blank');
    }
  };
  return (
    <button onClick={handleClick} className="ml-1 text-blue-5">
      {loading ? '| Preparing...' : `| View invoice`}
    </button>
  );
};

const createPaymentMessage = (session: Session, nextPayment: any) => {
  const nextPaymentSessions = nextPayment?.sessionInfo || [];
  const includedInNextPayment =
    isPaymentPending(session.paymentStatus) &&
    nextPaymentSessions.map((ses) => ses.studentSessionId).find((id) => id === session.studentSessionId);
  let paymentMessage = null;
  if (isPaymentSucceed(session.paymentStatus)) {
    const promo = PROMO_DISCOUNT_MAPPING[(session.discountCode || '').toUpperCase()];
    paymentMessage = (
      <div>
        Paid{!!promo && ` ${promo}`}
        <InvoiceLink session={session} />
      </div>
    );
  } else if (includedInNextPayment) {
    paymentMessage = (
      <div className="text-grey-6">
        <span className="font-semibold">${nextPayment.total}</span> will be included in next payment on{' '}
        <span className="font-semibold">
          {moment(nextPayment.paymentDate).format('MMM D')}{' '}
          {getDaysAway(nextPayment?.paymentDate, {
            wrapperFunction: (content, daysAway) => `(${daysAway > 1 ? `in ${daysAway} days` : content})`,
          })}
        </span>
      </div>
    );
  } else if (isPaymentFailed(session.paymentStatus)) {
    paymentMessage = (
      <div>
        Payment not received. This may lead to session cancellation |{' '}
        <Link className="!text-base" to={PATH_HUB_INVOICE_HISTORY}>
          Update details
        </Link>
      </div>
    );
  } else {
    paymentMessage = 'Yet to be paid';
  }
  return { paymentMessage, includedInNextPayment };
};

const SessionPaymentInfo = ({ session }: Props) => {
  const { data } = useNextPaymentQuery();
  const content = createPaymentMessage(session, data);
  const getPaymentStatusIcon = ({ session }: { session: Session }) => {
    if (session.paymentStatus === 'Paid') {
      return <DollarIcon className="mt-0.5 md:mt-0" width={16} height={16} />;
    } else if (session.paymentStatus === 'Failed') {
      return <ExclamationMarkIcon className="text-red-5" />;
    } else {
      return <CreditCardIcon height={16} width={16} className="align-bottom text-green-5" />;
    }
  };

  return (
    <div className="payment-indicator">
      <div className="payment-indicator--paid mb-0 flex items-start gap-2 text-base md:items-center">
        {getPaymentStatusIcon({ session })}
        {content.paymentMessage}
      </div>
    </div>
  );
};
export default SessionPaymentInfo;
