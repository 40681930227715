import { AnchorButton, Container } from '@cluey/cluey-components';

import { ReactComponent as SuccessIcon } from '../../../assets/images/atoms-icon-filled-alert-tick.svg';
import BasePageLayout from '../../../templates/BasePageLayout/BasePageLayout';
import { PATH_HUB_PLAN_DETAILS } from '../../../util/pagePath';

const ChangePlanSuccess = () => {
  return (
    <BasePageLayout>
      <Container className="flex flex-col gap-y-9 mt-10 md:mt-32">
        <SuccessIcon className="m-auto" height={60} width={60} />
        <h1 className="font-display text-3xl font-bold text-center">Plan changed</h1>
        <p className="text-center mx-auto w-3/4">
          We hope this new plan is more suitable for your needs. Please be on the lookout for a confirmation email with
          the details of the change.
        </p>
        <AnchorButton
          href={PATH_HUB_PLAN_DETAILS}
          target="self"
          className="mt-3.5 mx-auto"
          appearance="reverse"
          size="lg"
        >
          See plan details
        </AnchorButton>
      </Container>
    </BasePageLayout>
  );
};

export default ChangePlanSuccess;
