import { combineReducers } from 'redux';

import login from './portal/loginReducer';
import summary from './portal/summaryReducer';
import studentProgress from './portal/studentProgressReducer';
import activities from './portal/activitiesReducer';
import upcomingSessions from './portal/upcomingSessionsReducer';
import contactUsFlow from './portal/contactUsFlowReducer';
import triggerPayment from './portal/triggerPaymentReducer';

import hubAuthPage from './hub/hubAuthPageReducer';
import hubSchoolTermsPublicHolidays from './hub/hubSchoolTermsPublicHolidaysReducer';
import hubUpcomingSessionsPage from './hub/hubUpcomingSessionsPageReducer';
import hubCompletedSessionsPage from './hub/hubCompletedSessionsPageReducer';
import hubActiveEnrolmentsPage from './hub/hubActiveEnrolmentsPageReducer';
import hubPastEnrolmentsPage from './hub/hubPastEnrolmentsPageReducer';
import hubEnrolmentDetailPage from './hub/hubEnrolmentDetailPageReducer';
import hubChangeSchedulePage from './hub/hubChangeSchedulePageReducer';
import hubAccountPage from './hub/hubAccountPageReducer';
import hubContactPage from './hub/hubContactPageReducer';
import hubChangeGroupPage from './hub/hubChangeGroupPageReducer';
import schoolsUpcomingSessions from './schools/schoolsUpcomingSessions';
import hubChangeSessionWithEnrolmentTutor from './hub/hubChangeSessionWithEnrolmentTutorReducer';
import summerMode from './summerMode/summerMode';

const apiStateReducer = combineReducers({
  login,
  summary,
  studentProgress,
  activities,
  upcomingSessions,
  contactUsFlow,
  triggerPayment,
  hubAuthPage,
  hubSchoolTermsPublicHolidays,
  hubUpcomingSessionsPage,
  hubCompletedSessionsPage,
  hubActiveEnrolmentsPage,
  hubPastEnrolmentsPage,
  hubEnrolmentDetailPage,
  hubChangeSchedulePage,
  hubAccountPage,
  hubContactPage,
  hubChangeGroupPage,
  schoolsUpcomingSessions,
  hubChangeSessionWithEnrolmentTutor,
  summerMode,
});

export default apiStateReducer;
