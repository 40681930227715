import { RemoveAttachmentButtonIcon } from './icons';

export function fileSizeString(sizeInBytes: number): string {
  const sizeInMB = sizeInBytes / 1024 / 1024;

  if (sizeInMB >= 1) {
    return `${sizeInMB.toFixed(2)} MB`;
  } else {
    const sizeInKB = sizeInBytes / 1024;
    return `${Math.round(sizeInKB)} KB`;
  }
}

export interface AttachmentPreviewProps {
  fileName: string;
  fileSize: number;
  originalFileSize: number;
  handleRemoveAttachment: () => void;
  renderPreview?: boolean;
  previewHref?: string;
}

const AttachmentPreview = ({ fileName, fileSize, originalFileSize, handleRemoveAttachment, renderPreview = false, previewHref }: AttachmentPreviewProps) => {
  return (
    <div className="my-3 rounded-xl bg-grey-2 p-4 max-w-[300px]">
      {renderPreview && (
        <div className="w-full mb-4">
          <a href={previewHref} target="_blank" rel="noopener noreferrer" className="">
            <img className="object-contain" src={previewHref} alt="attachment thumbnail" />
          </a>
        </div>
      )}
      <div className="flex flex-row items-center space-x-3">
        <div data-testid="attachment-file-name" className="flex-1 truncate font-bold" title={fileName}>
          {fileName}
        </div>
        <div data-testid="attachment-file-size" className="shrink-0">
          {fileSizeString(originalFileSize)}
        </div>
        <div data-testid="attachment-file-size" className="shrink-0">
          {fileSizeString(fileSize)}
        </div>
        <button
          data-testid="remove-attachment-button"
          type="button"
          onClick={handleRemoveAttachment}
          aria-label="remove attachment"
        >
          <RemoveAttachmentButtonIcon height={20} width={20} />
        </button>
      </div>
    </div>
  );
};

export default AttachmentPreview;
