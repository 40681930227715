import React from 'react';
// prettier-ignore
import {
  Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { LastLocationProvider } from '../hooks/last-location';
import * as PATHS from '../util/pagePath';
import ProtectedRoute from '../common/hub/ProtectedRoute';
import env from '../env';

import UpcomingSessionsPage from '../views/UpcomingSessionsPage';
import SessionReportLifecycle from '../SessionReportLifecycle';

// import HubSignupPage from '../views/hub/auth/HubSignupPage';
// import HubLogInPage from '../views/hub/auth/HubLogInPage';
// import HubResetPasswordRequestPage from '../views/hub/auth/HubResetPasswordRequestPage';
import HubSignupConfirmationPage from '../views/hub/auth/HubSignupConfirmationPage';
import HubSignupErrorPage from '../views/hub/auth/HubSignupErrorPage';
import HubResetPasswordSentPage from '../views/hub/auth/HubResetPasswordSentPage';
import HubSetNewPasswordPage from '../views/hub/auth/HubSetNewPasswordPage';
// import HubResetPasswordSuccessPage from '../views/hub/auth/HubResetPasswordSuccessPage';
// import HubResetPassword from '../views/hub/auth/HubResetPassword';
import HubOnboardingPage from '../views/hub/auth/HubOnboardingPage';
import HubStaffLoginPage from '../views/hub/auth/HubStaffLoginPage';
import HubSocialOauthCallback from '../views/hub/auth/HubSocialOauthCallback';
import HubResendSignupLinkRequestPage from '../views/hub/auth/HubResendSignupLinkRequestPage';
import HubResendSignupLinkSentPage from '../views/hub/auth/HubResendSignupLinkSentPage';

import { HomePage } from '../views/hub/home/HomePage';
import { LoginPage, SignUpRouter, ResetPasswordRequestPage } from '../views/hub/auth2';
import { ResetPassword } from '../views/hub/auth2/ResetPasswordRequestPage/ResetPassword';
import { ResetPasswordSuccess } from '../views/hub/auth2/ResetPasswordRequestPage/ResetPassword/ResetPasswordSuccess';

import RescheduleSessionInfo from '../components/hub/home/RescheduleSessionInfo';
import { UpcomingSessions } from '../views/hub/UpcomingSessions';
import { CompletedSessions } from '../views/hub/CompletedSessions';
import { CancelledSessions } from '../views/hub/CancelledSessions';
import HubEnrolmentsPage from '../views/hub/HubEnrolmentsPage';
import AccountDetailsPage from '../views/account/AccountDetailsPage';
import PricingPlanDetailsPage from '../views/account/PricingPlanDetailsPage/PricingPlanDetailsPage';
import ChangePricingPlanPage from '../views/account/ChangePricingPlanPage';
import PaymentBillingPage from '../views/account/PaymentBillingPage/PaymentBillingPage';
import { CancelSession } from '../views/hub/CancelSession';

import HubNoActiveEnrolmentsPage from '../views/hub/HubNoActiveEnrolmentsPage';
import { EnrolmentDetails } from '../views/hub/EnrolmentDetails';
import { SelectSession } from '../views/hub/SelectSession';
import HubRescheduleSession from '../views/hub/HubRescheduleSession/HubRescheduleSession';
import { ChangeEnrolmentSchedule } from '../views/hub/ChangeEnrolmentSchedule';
import HubEnrolmentNAPLAN from '../views/hub/HubEnrolmentNAPLAN';
import { ConnectedKeepPreviousTutorPageConnected } from '../views/hub/management/KeepPreviousTutorPage';
import { ConnectedManagePauseResumptionOptions as ManagePauseResumptionOptions } from '../views/hub/management/ManagePauseResumptionOptions';
import { PauseEnrolment } from '../views/hub/management/PauseEnrolment';
import { SummerPause } from '../views/hub/summerMode/SummerPause';
import { Policy } from '../views/Policy/Policy';
import { ServiceFees } from '../views/ServiceFees/ServiceFees';

import HubGeneralContactOptions from '../views/hub/contact/HubGeneralContactOptions';

import FormPermanentScheduleChange from '../views/hub/contact/FormPermanentScheduleChange';
import FormChangeTutor from '../views/hub/contact/FormChangeTutor';
import { CancelEnrolment } from '../views/hub/management/CancelEnrolment';
import FormOtherEnrolmentChangeRequest from '../views/hub/contact/FormOtherEnrolmentChangeRequest';
import FormPaymentQuery from '../views/hub/contact/FormPaymentQuery';
import FormOtherQuery from '../views/hub/contact/FormOtherQuery';
import FormTechnicalHelp from '../views/hub/contact/FormTechnicalHelp';
import FormFeedback from '../views/hub/contact/FormFeedback';
import FormSkipSession from '../views/hub/contact/FormSkipSession';
import FormOptInOut from '../views/hub/contact/FormOptInOut';
import FormAddNewEnrolment from '../views/hub/contact/FormAddNewEnrolment';
import FormRequestRecordingSession from '../views/hub/contact/FormRequestRecordingSession';
import FormRescheduleSession from '../views/hub/contact/FormRescheduleSession';
import HubContactSuccessPage from '../views/hub/contact/HubContactSuccessPage';
import { ConnectedFormSwitchLearningMode as FormSwitchLearningMode } from '../views/hub/contact/FormSwitchLearningMode';
import { FormAddSession } from '../views/hub/contact/FormAddSession';

import ManageServiceReportAnIssue from '../views/hub/featurePages/manageService/ManageServiceReportAnIssue/ManageServiceReportAnIssue';
import { ConnectedUpcomingSchoolSessions } from '../views/schools/UpcomingSchoolSessions';
import { ConnectedChangeSessionWithSameTutor } from '../views/hub/management/ChangeSessionWithSameTutor';
import { ConnectedHubEnrolmentChangeGroupSchedule } from '../views/hub/HubEnrolmentChangeGroupSchedule';
import FormChangeContentFocus from '../views/hub/contact/FormChangeContentFocus';
import { HubMaintenancePage } from '../views/hub/HubMaintenancePage';
import HubMidYearProgressPage from '../views/hub/featurePages/HubMidYearProgressPage';
import { AddOneOffSession } from '../views/hub/management/AddOneOffSession/AddOneOffSession';
/* Manage service components */

import ManageServiceBillingAndPayments from '../views/hub/featurePages/manageService/ManageServiceBillingAndPayments/ManageServiceBillingAndPayments';
import ManageServiceStudentsAndSubjects from '../views/hub/featurePages/manageService/ManageServiceStudentsAndSubjects/ManageServiceStudentsAndSubjects';
import { ManageServiceSessionTimes } from '../views/hub/featurePages/manageService/ManageServiceSessionTimes';
import { ManageServiceLearningAdjustments } from '../views/hub/featurePages/manageService/ManageServiceLearningAdjustments/ManageServiceLearningAdjustments';
import { Support } from '../views/Support/Support';
import HubActivateAccountPage from '../views/hub/auth/HubActivateAccountPage';
import { SummerPauseForCancel } from '../views/hub/summerMode/SummerPause/SummerPause';
import { GenerateAssessment } from '../views/hub/GenerateAssessment/GenerateAssessment';
import StudentProfile from '../views/hub/student/StudentProfile';
import EditStudentProfile from '../views/hub/student/EditStudentProfile';
import AddStudent from '../views/hub/student/AddStudent';
import ViewAssessment from '../views/hub/assessments/ViewAssessment';
import HubUpdates from '../views/hub/updates';
import NewCustomerHub from '../views/hub/updates/articles/NewCustomerHub';
import UpcomingSessionMessage from '../views/hub/updates/articles/UpcomingSessionMessage';
import ClueyCoachTutor from '../views/hub/updates/articles/ClueyCoachTutor';

import SessionMessage from '../views/hub/SessionMessage';
import LandingPage from '../views/ai-tutor/LandingPage';
import AITutorFAQ from '../views/ai-tutor/FAQ/AITutorFAQ';
import PageNotFound from '../components/routing/PageNotFound';
import ErrorBoundary from '../common/ErrorBoundary';

import useReinitializeBeacon from './useReinitializeBeacon';

const history = createBrowserHistory();

const hubBasePage = env.REACT_APP_MAINTENANCE_MODE ? (
  <ProtectedRoute path={PATHS.PATH_HUB_BASE} component={HubMaintenancePage} />
) : (
  <ProtectedRoute path={PATHS.PATH_HUB_BASE} component={HomePage} exact />
);

const PageTracker = () => {
  useReinitializeBeacon();
  return null;
};

const AppRouter = () => {

  return (
    <Router history={history}>
      <PageTracker />
      <ErrorBoundary>
        <LastLocationProvider>
          <Switch>
            <Route path={PATHS.PATH_SESSION_REPORT} component={SessionReportLifecycle} exact />
            <Route path={PATHS.PATH_UPCOMING_SESSIONS} component={UpcomingSessionsPage} exact />
            <Route path={PATHS.PATH_RESCHEDULE_SESSION} render={() => <Redirect to={PATHS.PATH_HUB_HOME} />} />
            <Route path={PATHS.PATH_RESCHEDULE_SESSION_OLD} render={() => <Redirect to={PATHS.PATH_HUB_HOME} />} />
            <Route path={PATHS.PATH_HUB_SIGN_UP} component={SignUpRouter} />
            <Route path={PATHS.PATH_HUB_SIGN_UP_CONFIRMATION} component={HubSignupConfirmationPage} exact />
            <Route path={PATHS.PATH_HUB_SIGN_UP_ERROR} component={HubSignupErrorPage} exact />
            <Route path={PATHS.PATH_HUB_LOG_IN} component={LoginPage} exact />
            <Route path={PATHS.PATH_HUB_RESET_PASSWORD_REQUEST} component={ResetPasswordRequestPage} />
            <Route path={PATHS.PATH_HUB_RESET_PASSWORD_REQ_SENT} component={HubResetPasswordSentPage} exact />
            {/* <Route path={PATHS.PATH_HUB_SET_PASSWORD} component={HubResetPassword} exact /> */}
            <Route path={PATHS.PATH_HUB_SET_PASSWORD} component={ResetPassword} exact />
            {/* <Route path={PATHS.PATH_HUB_NEW_PASSWORD_SUCCESS} component={HubResetPasswordSuccessPage} exact /> */}
            <Route path={PATHS.PATH_HUB_NEW_PASSWORD_SUCCESS} component={ResetPasswordSuccess} exact />
            <Route path={PATHS.PATH_HUB_SET_NEW_PASSWORD} component={HubSetNewPasswordPage} exact />
            <Route path={PATHS.PATH_HUB_ACTIVATE_ACCOUNT} component={HubActivateAccountPage} exact />
            <Route path={PATHS.PATH_HUB_STAFF_LOGIN} component={HubStaffLoginPage} exact />
            <Route path={PATHS.PATH_HUB_SOCIAL_OAUTH_CALLBACK} component={HubSocialOauthCallback} exact />
            <Route path={PATHS.PATH_HUB_RESEND_SIGNUP_LINK_REQUEST} component={HubResendSignupLinkRequestPage} exact />
            <Route path={PATHS.PATH_HUB_RESEND_SIGNUP_LINK_REQ_SENT} component={HubResendSignupLinkSentPage} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_ONBOARDING} component={HubOnboardingPage} exact />
            {hubBasePage}
            <ProtectedRoute path={PATHS.PATH_HUB_HOME} component={HomePage} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_RESCHEDULE_SESSION_INFO} component={RescheduleSessionInfo} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_SESSION_MESSAGE} component={SessionMessage} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_SESSIONS_PAGE} component={UpcomingSessions} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_UPCOMING_SESSIONS} component={UpcomingSessions} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_COMPLETED_SESSIONS} component={CompletedSessions} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_CANCELLED_SESSIONS} component={CancelledSessions} exact />
            <ProtectedRoute
              path={PATHS.PATH_HUB_ENROLMENTS_PAGE}
              component={() => <Redirect to={PATHS.PATH_HUB_HOME} />}
              exact
            />
            <ProtectedRoute path={PATHS.PATH_HUB_ENROLMENTS} component={HubEnrolmentsPage} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_ACCOUNT_PAGE} component={AccountDetailsPage} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_ACCOUNT_DETAILS} component={AccountDetailsPage} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_PLAN_DETAILS} component={PricingPlanDetailsPage} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_CHANGE_PLAN} component={ChangePricingPlanPage} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_INVOICE_HISTORY} component={PaymentBillingPage} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_NO_ACTIVE_ENROLMENTS} component={HubNoActiveEnrolmentsPage} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_ENROLMENT_DETAIL} component={EnrolmentDetails} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_SELECT_SESSION} component={SelectSession} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_SKIP_SESSION} component={CancelSession} />
            <ProtectedRoute path={PATHS.PATH_HUB_RESCHEDULE_SESSION} component={HubRescheduleSession} />
            <ProtectedRoute path={PATHS.PATH_HUB_RESCHEDULE_SESSION_WITH_TUTOR} component={HubRescheduleSession} />
            <ProtectedRoute
              path={`${PATHS.PATH_HUB_ENROLMENT_CHANGE_SCHEDULE}`}
              component={ChangeEnrolmentSchedule}
              exact
            />
            <ProtectedRoute
              path={`${PATHS.PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP}`}
              component={ConnectedHubEnrolmentChangeGroupSchedule}
              exact
            />
            <ProtectedRoute path={`${PATHS.PATH_HUB_ENROLMENT_NAPLAN}`} component={HubEnrolmentNAPLAN} exact />
            <ProtectedRoute
              path={`${PATHS.PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_KEEP_PREV_TUTOR}`}
              component={ConnectedKeepPreviousTutorPageConnected}
              exact
            />
            <ProtectedRoute
              path={`${PATHS.PATH_HUB_ENROLMENT_MANAGE_PAUSE}`}
              component={ManagePauseResumptionOptions}
              exact
            />
            <ProtectedRoute path={PATHS.PATH_HUB_CR} component={HubGeneralContactOptions} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_CR_REQUEST_OPTIONS} component={HubGeneralContactOptions} exact />
            {/* Forms */}
            <ProtectedRoute path={PATHS.PATH_HUB_CR_PAUSE_SESSIONS} component={PauseEnrolment} />
            <ProtectedRoute path={PATHS.PATH_HUB_SUMMER_PAUSE_ENROLMENT} component={SummerPause} />
            <ProtectedRoute path={PATHS.PATH_HUB_CR_PAUSE_SUMMER_CANCEL} component={SummerPauseForCancel} />
            <ProtectedRoute path={PATHS.PATH_HUB_POLICY} component={Policy} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_SERVICE_FEES} component={ServiceFees} exact />
            <ProtectedRoute
              path={PATHS.PATH_HUB_CR_PERMANENT_SCHEDULE_CHANGE}
              component={FormPermanentScheduleChange}
              exact
            />
            <ProtectedRoute path={PATHS.PATH_HUB_CR_CHANGE_TUTOR} component={FormChangeTutor} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_CR_CANCEL_ENROLMENT} component={CancelEnrolment} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_CR_CHANGE_CONTENT_FOCUS} component={FormChangeContentFocus} exact />
            <ProtectedRoute
              path={PATHS.PATH_HUB_CR_OTHER_ENROLMENT_CHANGE}
              component={FormOtherEnrolmentChangeRequest}
              exact
            />
            <ProtectedRoute path={PATHS.PATH_HUB_CR_PAYMENT_QUERY} component={FormPaymentQuery} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_CR_OTHER_QUERY} component={FormOtherQuery} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_CR_TECHNICAL_ISSUE} component={FormTechnicalHelp} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_CR_FEEDBACK} component={FormFeedback} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_CR_OPT_IN_OUT} component={FormOptInOut} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_CR_SKIP_SESSION} component={FormSkipSession} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_CR_ADD_ENROLMENT} component={FormAddNewEnrolment} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_CR_REQUEST_RECORDING} component={FormRequestRecordingSession} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_CR_RESCHEDULE_SESSION} component={FormRescheduleSession} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_CR_SUBMIT_CASE_SUCCESS} component={HubContactSuccessPage} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_CR_SWITCH_LEARNING_MODE} component={FormSwitchLearningMode} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_CR_ADD_SESSION} component={FormAddSession} exact />
            <ProtectedRoute
              path={PATHS.PATH_HUB_TTC_CHANGE_SESSION_KEEP_TUTOR}
              component={ConnectedChangeSessionWithSameTutor}
              exact
            />
            <ProtectedRoute path={PATHS.PATH_HUB_CR_REPORT_ISSUE} component={ManageServiceReportAnIssue} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_MID_YEAR_PROGRESS} component={HubMidYearProgressPage} exact />
            {/* <ProtectedRoute path={PATHS.PATH_NEW_YEAR_ENROLMENT_DETAIL} component={ConnectedNewYearEnrolmentDetailPage} />
          <ProtectedRoute path={PATHS.PATH_NEW_YEAR_PROGRAM_OVERVIEW} component={ConnectedNewYearProgramOverviewPage} /> */}
            <ProtectedRoute path={PATHS.PATH_HUB_SUPPORT} component={Support} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_ADD_ONE_OFF_SESSION} component={AddOneOffSession} />
            {/* Hub For Schools */}
            <Route path={PATHS.PATH_SCHOOL_UPCOMING_SESSIONS} component={ConnectedUpcomingSchoolSessions} exact />
            {/* Manage service pages */}
            <ProtectedRoute path={PATHS.PATH_HUB_MS_SESSION_TIMES} component={ManageServiceSessionTimes} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_GENERATE_ASSESSMENT} component={GenerateAssessment} />
            <ProtectedRoute
              path={PATHS.PATH_HUB_MS_LEARNING_ADJUSTMENTS}
              component={ManageServiceLearningAdjustments}
              exact
            />
            <ProtectedRoute
              path={PATHS.PATH_HUB_MS_BILLING_AND_PAYMENTS}
              component={ManageServiceBillingAndPayments}
              exact
            />
            <ProtectedRoute
              path={PATHS.PATH_HUB_MS_STUDENTS_AND_SUBJECTS}
              component={ManageServiceStudentsAndSubjects}
              exact
            />
            <ProtectedRoute path={PATHS.PATH_HUB_STUDENT_PROFILE} component={StudentProfile} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_STUDENT_EDIT_PROFILE} component={EditStudentProfile} exact />
            <ProtectedRoute path={PATHS.PATH_HUB_VIEW_ASSESSMENT} component={ViewAssessment} exact />
            {/* @TODO remove this after the add student page has been built */}
            <ProtectedRoute path={PATHS.PATH_HUB_ADD_STUDENT} component={AddStudent} />
            <ProtectedRoute exact path={PATHS.PATH_HUB_UPDATES} component={HubUpdates} />
            <ProtectedRoute path={PATHS.PATH_HUB_UPDATES_NEW_CUSTOMER_HUB} component={NewCustomerHub} />
            <ProtectedRoute path={PATHS.PATH_HUB_UPDATES_CUSTOMER_INPUT} component={UpcomingSessionMessage} />
            <ProtectedRoute path={PATHS.PATH_HUB_UPDATES_AI_TUTOR} component={ClueyCoachTutor} />
            <ProtectedRoute path={PATHS.PATH_HUB_AI_TUTOR_FAQ} component={AITutorFAQ} />
            <ProtectedRoute path={PATHS.PATH_HUB_AI_TUTOR} component={LandingPage} />
            <Route exact path="/" render={() => <Redirect to={PATHS.PATH_HUB_HOME} />} />
            <Route component={PageNotFound} />
          </Switch>
        </LastLocationProvider>
      </ErrorBoundary>
    </Router>
  );
};

export default AppRouter;
