import { useMutation } from '@tanstack/react-query';
import AuthService from '../../services/AuthService';
import { VERIFY_EMAIL_QKEY } from '../../util/constants/queryKeys';

function verifyEmail(email: string) {
  return AuthService.verifyEmail({ email });
}

interface Params {
  email: string;
  onSuccess(response: any): void;
  onError?(e: any): void;
}

export const useVerifyEmail = (params: Params) => {
  const { email, onError, onSuccess } = params;
  return useMutation({
    mutationKey: [VERIFY_EMAIL_QKEY, email],
    mutationFn: verifyEmail,
    onSuccess,
    onError,
  });
};
