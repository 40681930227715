/** @typedef */
export const ENR_SESSION_FREQUENCY = {
  WEEKLY: 'Weekly',
  FORTNIGHTLY: 'Fortnightly',
  TWICE_WEEKLY: 'Twice weekly',
  MULTIPLE_SESSIONS: 'Multiple sessions',
  OTHER: 'Other',
  ERROR: 'Error',
};

/** @typedef */
export const ENROLMENT_STATUS_TEXT = {
  OUTSIDE_POLICY_PAUSE_REQUESTED: 'Your pause extension request is being processed.',
  CANCEL_REQUESTED: 'Your cancellation request is being processed.',
  PENDING: 'Enrolment paused pending tutor change request',
  FINISHED: 'This enrolment has finished.',
};
