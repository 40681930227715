import { get } from 'lodash';
import { logError } from '../../logging';
import * as actionTypes from '../actionTypes';
import { CancelService } from '../../services/CancelService';
import type { CancelEnrolmentParams } from '../../types/hubInterfaces';

export const cancelEnrolment = ({
  rating,
  endDate,
  enrolmentId,
  message,
  otherMessage,
  onSubmitSuccess,
  selectedReasons,
  onSubmitFailed = () => {},
}: CancelEnrolmentParams & {
  onSubmitSuccess(): void;
  onSubmitFailed?(): void;
}) => (dispatch) => {
  dispatch({
    type: actionTypes.HUB_CR_SUBMIT_CONTACT_CASE_START,
  });

  const actions = {
    start: actionTypes.HUB_CR_SUBMIT_CONTACT_CASE_START,
    success: actionTypes.HUB_CR_SUBMIT_CONTACT_CASE_SUCCESS,
    failed: actionTypes.HUB_CR_SUBMIT_CONTACT_CASE_FAILED,
  };

  dispatch({
    type: actions.start,
  });

  const cancelService = new CancelService();

  return cancelService.cancelEnrolment({
    rating,
    enrolmentId,
    endDate,
    message,
    selectedReasons,
    otherMessage,
  })
    .then(() => {
      dispatch({ type: actions.success });
      onSubmitSuccess();
    })
    .catch((e) => {
      const errorMsg = get(e, 'message');
      dispatch({
        type: actions.failed,
        payload: {
          errorMsg,
        },
      });
      onSubmitFailed();
      logError(e, null, { message: `Unable to submit request cancel enrolment request to MuleSoft for enrolment Salesforce ID: ${enrolmentId}` });
    });
};
