import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { generatePath, useHistory } from 'react-router';
import { getAccountDetails } from '../../../../../selectors/hubSelector';
import { AccountDetails } from '../../../../../types/hubInterfaces';
import { api } from '../../../../../api';
import { PATH_HUB_GENERATE_ASSESSMENT } from '../../../../../util/pagePath';
import StudentAssessmentCards from './StudentAssessmentCards';
import { useMapToStudentAssessmentsEligibilities } from './hooks';
import { StudentAssessmentsEligibilities } from './types';

export interface AssessmentsOverviewWidgetBaseProps {
	studentAssessmentsEligibilities: StudentAssessmentsEligibilities[];
	multipleStudents: boolean;
	className?: string;
}

export const AssessmentsOverviewWidgetBase = ({
	studentAssessmentsEligibilities,
	multipleStudents,
	className,
}: AssessmentsOverviewWidgetBaseProps) => {
	const hasContent = studentAssessmentsEligibilities?.some((overview) => overview.shouldShow);
	const history = useHistory();

	if (!hasContent) {
		return null;
	}

	return (
		<div className={classNames('-mt-16 border-y border-grey-2 bg-blue-1', className)}>
			<div className="container mx-auto px-4 py-14 md:px-0">
				<div className="mb-4 font-bold">Recommended self-paced learning</div>
				{studentAssessmentsEligibilities?.map((data) => (
					<StudentAssessmentCards
						key={`${data?.student?.studentId}_assessments`}
						showStudentName={multipleStudents}
						data={data}
						handleGenerateAssessment={(student, subject) => {
							const path = generatePath(PATH_HUB_GENERATE_ASSESSMENT, { assessmentSubject: subject });
							history.push(path, { data: { student, subject } });
						}}
					/>
				))}
			</div>
		</div>
	);
};

const AssessmentsOverviewWidget = () => {
	const { country }: AccountDetails = useSelector(getAccountDetails);
	const { data: students, isLoading: isStudentsLoading, isSuccess } = api.student.all.useQuery();
	const hasFetchedStudents = isSuccess && !isStudentsLoading && students?.length > 0;

	api.assessment.all.useQueries(
		students?.map((student) => ({ customerNumber: student.customerNumber })),
		{ enabled: hasFetchedStudents }
	);

	// for each student, get their assessment eligibilities
	const studentAssessmentsEligibilities = useMapToStudentAssessmentsEligibilities(students || [], country);

	return (
		<AssessmentsOverviewWidgetBase multipleStudents studentAssessmentsEligibilities={studentAssessmentsEligibilities} />
	);
};

export default AssessmentsOverviewWidget;
