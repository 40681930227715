import initialState from './initialState';
import {
  CF_UPDATE_CONTACTUS_FLOW_TYPE, CF_UPDATE_CONTACTUS_FORM, CF_RESET_CONTACTUS_STATE, CF_SAVE_ENROLMENT_ID_FOR_SAF,
  CF_SET_SESSION_FOR_FORM, CF_SAVE_SELECTED_SESSIONS_FOR_HUB,
} from '../actions/actionTypes';

export default function contactUsFlow(state = initialState.contactUsFlow, action) {
  const { type, payload } = action;
  switch (type) {
    case CF_UPDATE_CONTACTUS_FLOW_TYPE:
      return {
        ...state,
        flowType: payload.flowType,
      };
    case CF_UPDATE_CONTACTUS_FORM:
      return {
        ...state,
        formScreen: {
          ...state.formScreen,
          title: payload.title,
          ciClass: payload.ciClass,
          caseCategory: payload.caseCategory,
          caseType: payload.caseType,
          recordType: payload.recordType,
        },
      };
    case CF_RESET_CONTACTUS_STATE:
      return {
        ...state,
        sessionDetail: '',
        studentSessionId: '',
        formScreen: {
          ...state.formScreen,
          title: null,
          ciClass: null,
        },
      };
    case CF_SAVE_ENROLMENT_ID_FOR_SAF:
      return {
        ...state,
        enrolmentId: payload.enrolmentId,
      };
    case CF_SET_SESSION_FOR_FORM:
      return {
        ...state,
        sessionDetail: payload.sessionDetail,
        studentSessionId: payload.studentSessionId,
      };
    case CF_SAVE_SELECTED_SESSIONS_FOR_HUB:
      return {
        ...state,
        selectedSessionForHubForm: {
          ...state.selectedSessionForHubForm,
          ...payload,
        },
      };
    default:
      return state;
  }
}
