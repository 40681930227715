import initialState from '../../initialState';
import * as actionTypes from '../../../actions/actionTypes';

export default function hubActiveEnrolmentsPageReducer(state = initialState.ui.apiState.hubActiveEnrolmentsPage, action) {
  switch (action.type) {
    case actionTypes.HUB_EP_FETCH_ACTIVE_ENROLMENTS_START:
      return {
        ...state,
        fetchError: false,
        isLoading: true,
        hasFetched: false,
        forceToReload: false,
      };
    case actionTypes.HUB_EP_FETCH_ACTIVE_ENROLMENTS_SUCCESS:
      return {
        ...state,
        fetchError: false,
        isLoading: false,
        hasFetched: true,
        forceToReload: false,
      };
    case actionTypes.HUB_EP_FETCH_ACTIVE_ENROLMENTS_FAILED:
      return {
        ...state,
        fetchError: true,
        isLoading: false,
        forceToReload: false,
      };
    case actionTypes.HUB_EP_FETCH_ACTIVE_ENROLMENTS_FAILURE_DEFINITIVE:
      return {
        ...state,
        retryEnabled: false,
        fetchError: true,
        isLoading: false,
        forceToReload: false,
      };
    case actionTypes.HUB_EP_CLEAR_ACTIVE_ENROLMENTS_TO_FORCE_RELOAD:
      return {
        ...state,
        forceToReload: true,
      };
    default:
      return state;
  }
}
