import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';
import { Checkbox as ClueyCheckbox } from '@cluey/cluey-components';

// A custom hook to make it easier to get the necessary props for an input field
export function useCheckboxProps({ id, labelText, customClasses }) {
  const [selectedCKB, setSelectedCKB] = useState(false);
  const [highlight, setHighlight] = useState(false);

  // Note: It's preferred to come up with a non dynamic id to avoid DOM updates
  if (!id) {
    id = uuid();
  }

  return {
    id,
    labelText,
    selectedCKB,
    setSelectedCKB,
    highlight,
    setHighlight,
    customClasses,
  };
}

export const Checkbox = ({
  id,
  labelText,
  selectedCKB,
  setSelectedCKB,
  customClasses,
}) => {
  return (
    <div className={classNames('flex items-center', customClasses)}>
      {/* NOTE, ClueyCheckbox could not maintain its width. */}
      <div className="h-8 w-8">
        <ClueyCheckbox
          id={id}
          value={selectedCKB}
          onChange={(val) => setSelectedCKB(val)}
        />
      </div>

      {labelText && (
        <label
          htmlFor={id}
          className="mt-2 mb-0 ml-2 cursor-pointer font-body text-xl leading-snug"
        >
          {labelText}
        </label>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  selectedCKB: PropTypes.bool,
  setSelectedCKB: PropTypes.func.isRequired,
  customClasses: PropTypes.string,
};

Checkbox.defaultProps = {
  selectedCKB: false,
  labelText: null,
  highlight: false,
  customClasses: '',
};
