import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import InPageLoader from '../../common/InPageLoader';
import ErrorBlock from '../../common/ErrorBlock';
import { redirectToFormWithEnrolmentId } from '../../actions/hub/hubEnrolmentManagementActions';
import { isEnrolmentCancelled, isEnrolmentFinished } from '../../util/helpers';
import { PATH_HUB_CR_CHANGE_CONTENT_FOCUS } from '../../util/pagePath';

import FilledTick from '../../assets/images/atoms-icon-filled-alert-tick.svg';
import { Anchor, ButtonAsLink } from '@cluey/cluey-components';
import { api } from '../../api';
import { EnrolmentDetail } from '../../api/types/enrolment';
import { naplanInfo } from '../../util/helper/helperNAPLAN';
import { useDispatch } from 'react-redux';

const H3 = ({ extraClasses, children }) => {
	return <h3 className={`pb-3 text-xs font-bold uppercase tracking-wide ${extraClasses}`}>{children}</h3>;
};

H3.propTypes = {
	extraClasses: PropTypes.string,
	children: PropTypes.node.isRequired,
};

H3.defaultProps = {
	extraClasses: '',
};

interface Props {
	enrolmentDetails: EnrolmentDetail;
}

const ProgramBlurb = ({ enrolmentDetails }: Props) => {
	const {
		enrolmentId,
		enrolmentSubject,
		enrolmentDiscipline: subject,
		enrolmentYearLevel: yearLevel,
		customerNumber: studentCustomerNumber,
		enrolmentStatus,
	} = enrolmentDetails;

	const dispatch = useDispatch();
	const history = useHistory();

	const { data: accountDetails, isLoading: loadingAccountDetails } = api.account.details.useQuery();

	const {
		data: programOverview,
		isLoading: isLoadingProgramOverview,
		isError: loadError,
	} = api.enrolment.programOverview.useQuery(
		{ studentCustomerNumber, subject },
		{
			enabled: !!studentCustomerNumber && !!subject,
		}
	);

	const isLoading = loadingAccountDetails || isLoadingProgramOverview;

	const isCancelledOrFinished = isEnrolmentCancelled({ enrolmentStatus }) || isEnrolmentFinished({ enrolmentStatus });
	if (isCancelledOrFinished) {
		return null;
	}

	if (isLoading) {
		return (
			<div className="mb-lg-11 container mb-10">
				<InPageLoader position="relative" height="auto" />
			</div>
		);
	}

	if (loadError) {
		return (
			<div className="mb-lg-11 container mb-10">
				<ErrorBlock errorMsg="Unable to load the course outcomes overview, please try again later." />
			</div>
		);
	}

	const infoNAPLAN = naplanInfo({
		enrolmentSubject,
		yearLevel,
		country: accountDetails?.country,
	});
	const { isNAPLAN, showNAPLAN, typeNAPLAN } = infoNAPLAN;
	console.log(infoNAPLAN);

	const onContentChange = () => {
		dispatch(
			redirectToFormWithEnrolmentId({
				enrolmentId,
				callback: () => {
					history.push(`${PATH_HUB_CR_CHANGE_CONTENT_FOCUS}?token=${enrolmentId}`);
				},
			})
		);
	};

	return (
		<div className="mb-10 lg:mb-11">
			<div className="flex flex-col rounded-lg border border-grey-2 bg-white lg:flex-row">
				<div className="lg:w-7/12">
					<div className="px-4 py-5 lg:py-8 lg:pl-20 lg:pr-14">
						<H3>
							{subject} in {yearLevel}
						</H3>
						{programOverview?.outcomesOverview && (
							<p className="text-sm leading-tight">{programOverview.outcomesOverview}</p>
						)}
					</div>
				</div>
				<div className="border-t border-grey-2 lg:w-5/12 lg:border-l lg:border-t-0">
					<div className="px-4 py-5 lg:px-14 lg:py-8">
						<H3>Content</H3>
						<ButtonAsLink onClick={onContentChange}>Request specific content to cover</ButtonAsLink>
						{isNAPLAN && showNAPLAN && (
							<div className='mt-6'>
								<H3>NAPLAN</H3>
								<div className="mb-3 flex items-center md:items-start rounded-lg bg-green-1 px-4 py-5">
									<img className="mr-3 align-top" src={FilledTick} alt="icon" width="20" height="20" />
									<p className="lh-1-14 mb-0 text-sm align-middle">NAPLAN {typeNAPLAN} revision and test simulation included</p>
								</div>
								<Anchor
									href="https://clueylearning.com.au/naplan-tutoring/#naplan-prep-description"
									target="_blank"
									className="text-sm text-blue-5 hover:underline"
								>
									What's included in NAPLAN prep?
								</Anchor>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProgramBlurb;
export { ProgramBlurb as StatelessProgramBlurb };
