import { Col, Grid } from '@cluey/cluey-components';
import { FC, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InitialState } from '../../../reducers/initialState';
import { AUTHENTICATION_STATUS } from '../../../util/constants';
import { useHistory } from 'react-router';
import * as PATHS from '../../../util/pagePath';
import clueyhubsvg from './cluey-hub.svg';
import { Auth } from 'aws-amplify';
import { FullPageLoader } from '../../../components/Loader/Loader';
import { ArrowBackIcon } from '../../../icons';

export const AUTH2_COLS = {
	LEFT: 7,
	RIGHT: 5,
} as const;

const loaderBits = new Array(8).fill(null);

export const InlineLoader: FC<{ className?: string }> = ({ className }) => {
	const position = className?.includes('absolute') ? '' : 'relative';
	return (
		<div className={`${position} h-6 w-6 ${className}`}>
			{loaderBits.map((_, index) => (
				<div
					key={index}
					className="absolute left-1/2 top-1/2 h-1 w-[2px] animate-hard-pulse rounded-t bg-blue-5"
					style={{
						transform: `translateX(-50%) translateY(-50%) rotate(-${45 * index}deg) translateY(-8px)`,
						animationDelay: `-${index * 0.2}s`,
						opacity: 0.1125 * (8 - index) + 0.1,
					}}
				></div>
			))}
		</div>
	);
};

export const useAuth = () => {
	return useSelector<InitialState>(
		(state) => state.login.authenticationStatus
	) as typeof AUTHENTICATION_STATUS[keyof typeof AUTHENTICATION_STATUS];
};

export const Auth2Layout: FC<PropsWithChildren<{ title: string; links?: ReactNode; headerLinks?: ReactNode }>> = ({
	children,
	title,
	links,
	headerLinks,
}) => {
	const authStatus = useAuth();
	const history = useHistory();

	useEffect(() => {
		if (authStatus === 'AUTHENTICATED') {
			history.replace(PATHS.PATH_HUB_HOME);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps -- only run on mount
	}, []);

	return (
		<div className="relative flex min-h-screen flex-col text-grey-6">
			<div className="h-1 w-screen bg-brand"></div>

			<Grid noGutter className="absolute left-0 top-0 -z-10 hidden h-full w-full flex-grow md:grid">
				<Col span={AUTH2_COLS.LEFT}></Col>
				<Col span={AUTH2_COLS.RIGHT} className="bg-slate-1 md:block xl:pt-16"></Col>
			</Grid>
			<div className="container mx-auto flex flex-grow flex-col">
				<Grid noGutter className="md:flex-grow">
					<Col
						span={AUTH2_COLS.LEFT}
						className="relative order-last flex flex-col items-start px-4 pb-12 pt-11 md:order-none md:pt-16 lg:pt-[160px]"
					>
						<div id="cz_login_banner"></div>
						<h1 className="max-w-md pb-9 font-display text-4xl font-extrabold leading-10">{title}</h1>
						<div className="absolute left-0 top-5 w-full px-4">{headerLinks}</div>
						{children}
					</Col>
					<Col
						span={AUTH2_COLS.RIGHT}
						className={`relative flex items-center gap-8 bg-slate-1 px-4 py-6 md:flex-col md:gap-12 md:pb-0 md:pt-16 lg:pt-[160px] ${
							!links ? 'max-sm:hidden' : ''
						}`}
					>
						<img src={clueyhubsvg} alt="Cluey Hub" className="h-[110px] w-[110px] md:h-[300px] md:w-[300px]" />
						{links}
						<div className="absolute left-1/2 top-0 -z-10 h-full w-screen -translate-x-1/2 bg-slate-1 md:hidden"></div>
					</Col>
				</Grid>
			</div>
		</div>
	);
};

export const BackButton = () => {
	const history = useHistory();
	return (
		<button onClick={() => history.goBack()} className="flex items-center justify-between gap-1">
			<ArrowBackIcon width={16} height={16} className="text-blue-5" />
			<span className="underline-offset-3 text-lg text-blue-5 underline">Back</span>
		</button>
	);
};

export const SkipAndSignIn = ({ onClick }: { onClick(): void }) => {
	return (
		<button onClick={onClick} className="flex justify-between gap-2">
			<span className="underline-offset-3 text-lg text-blue-5 underline">Skip and sign in</span>
			<ArrowBackIcon width={27} height={27} className="rotate-180 text-blue-5" />
		</button>
	);
};

export const useRedirectIfAuthenticated = () => {
	/* 
		Caveat: this hook should be mocked in tests, otherwise you'll receive an authentication
		error and the test suit will fail
	*/
	// @ts-ignore
	const state = useSelector(state => state.login.authenticationStatus);
	const history = useHistory();
	useEffect(() => {
		Auth.currentAuthenticatedUser().then((user) => {
			if (user && state !== AUTHENTICATION_STATUS.AUTHENTICATION_ERROR) {
				history.push(PATHS.PATH_HUB_HOME);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps -- only run on mount
	}, [state]);
};

export const withUnauthed = <T extends {}>(Component: FC<T>) => {
	return (props: T) => {
		const [loadingAuth, setLoadingAuth] = useState(false);
		const history = useHistory();
		// @ts-ignore
		const state = useSelector(state => state.login.authenticationStatus);

		useEffect(() => {
			Auth.currentAuthenticatedUser().then((user) => {
				if (user && state !== AUTHENTICATION_STATUS.AUTHENTICATION_ERROR) {
					history.push(PATHS.PATH_HUB_HOME);
				}
				else setLoadingAuth(false);
			});
		}, [history, state]);

		if (loadingAuth) return <FullPageLoader />;

		return <Component {...props} />;
	};
};
