import { get } from 'lodash';
import moment from 'moment';
import { logError } from '../../logging';
import { apiFetch, REQ_GET_GROUP_PACKAGES } from '../../services/backendApi';
import * as actionTypes from '../actionTypes';
import { submitCaseToSF } from './hubContactPageActions';

export const fetchGroupSessions = () => (dispatch, getState) => {
  dispatch({ type: actionTypes.HUB_EM_CG_FETCH_GROUP_SESSIONS_START });

  const state = getState();

  const payload = {
    yearlevel: get(state, 'hubEnrolmentDetailPage.enrolmentYearLevel'),
    curriculum: get(state, 'hubEnrolmentDetailPage.curriculum', ''),
    discipline: get(state, 'hubEnrolmentDetailPage.enrolmentDiscipline'),
  };

  return apiFetch(REQ_GET_GROUP_PACKAGES, state, payload)
    .then((json) => {
      let packages = [];
      if (
        Array.isArray(json.messages) &&
        json.messages.length &&
        json.messages[0].message &&
        Array.isArray(json.messages[0].message.packages)
      ) {
        const { packages: respPackage } = json.messages[0].message;
        packages = respPackage;
      }

      dispatch({
        type: actionTypes.HUB_EM_CG_FETCH_GROUP_SESSIONS_SUCCESS,
        payload: packages.filter(group => group.sessions.length),
      });
    })
    .catch((e) => {
      dispatch({ type: actionTypes.HUB_EM_CG_FETCH_GROUP_SESSIONS_FAILED, payload: 'Unable to fetch group sessions' });
      logError(e, null, { message: 'Unable to fetch group sessions' });
    });
};

export const clearGroupSessions = () => (dispatch) => {
  dispatch({ type: actionTypes.HUB_EM_CG_CLEAR_GROUP_SESSIONS });
};

export const changeGroup = ({
  enrolmentId, packageId, startDate, callback, isPaused, timezone,
}) => (dispatch) => {
  const caseType = 'Scheduling';
  const caseCategory = 'Permanent schedule change';

  const groupTiming = moment(startDate);
  const groupDay = groupTiming.format('dddd');
  const groupTime = groupTiming.tz(timezone).format('hh:mm A');
  const groupStartDate = groupTiming.format('YYYY-MM-DD');

  const message = isPaused
    ? `Request to extend pause and move to group on ${groupDay}s at ${groupTime}, effective from ${groupStartDate}`
    : `Request to move to group on ${groupDay}s at ${groupTime}, effective from ${groupStartDate}`;

  return dispatch(
    submitCaseToSF({
      // action: 'Move Group to Group', <-- the automated action
      action: 'Contact Request',
      uniqueEnrolmentId: enrolmentId,
      groupPackageCode: packageId,
      message,
      caseSubject: `Contact Request - ${caseType} - Change group`,
      caseType,
      caseCategory,
      startDate,
      page: 'change-group',
      onSubmitSuccess: callback,
    }),
  );
};

export const clearError = () => (dispatch) => {
  dispatch({ type: actionTypes.HUB_EM_CG_SUBMIT_GROUP_SUCCESS });
};
