import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Input } from '@cluey/cluey-components';

import { EMAIL_VALIDATION_RESULT } from '../../../util/constants';
import iconNo from '../../../assets/images/hubAuth/icon-cross--red.svg';
import iconYes from '../../../assets/images/hubAuth/icon-tick--green.svg';
import FieldLabel from '../FieldLabel';

const EmailField = ({
  emailValidation,
  saveEmail,
}) => {
  const [email, setEmail] = useState('');

  const onEmailChange = (val) => {
    const value = (val || '').toLowerCase();
    setEmail(value);
    saveEmail(value);
  };

  const isEmailValid = emailValidation === EMAIL_VALIDATION_RESULT.VALID;
  const isEmailInvalid = emailValidation === EMAIL_VALIDATION_RESULT.INVALID;

  return (
    <div className="mb-4">
      <FieldLabel id="user-email" labelText="Email" />
      <Input
        type="email"
        id="user-email"
        placeholder="Your email address"
        className={classNames('block w-full', isEmailInvalid && 'invalid')}
        value={email}
        onChange={onEmailChange}
      />
      { isEmailValid && (
        <div className="flex items-center mt-2 text-green-4">
          <img src={iconYes} alt="password valid" />
          <p className="mb-0 leading-none">Looks good</p>
        </div>
      ) }
      { isEmailInvalid && (
        <div className="flex items-center mt-2 text-red-5">
          <img src={iconNo} alt="password invalid" />
          <p className="mb-0 leading-none">Please enter a valid email</p>
        </div>
      ) }
    </div>
  );
};

EmailField.propTypes = {
  emailValidation: PropTypes.string.isRequired,
  saveEmail: PropTypes.func.isRequired,
};

export default EmailField;
