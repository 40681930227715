import initialState from './initialState';
import * as actionTypes from '../actions/actionTypes';

export default function hubChangeGroupPage(state = initialState.hubChangeGroupPage, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.HUB_EM_CG_FETCH_GROUP_SESSIONS_SUCCESS:
      return {
        ...state,
        groupAvailabilities: payload,
      };
    case actionTypes.HUB_EM_CG_CLEAR_GROUP_SESSIONS:
      return {
        ...state,
        groupAvailabilities: [],
      };
    default:
      return state;
  }
}
