import { ddLogger, ddLoggerLevel, logError } from '../logging';
import { DatesUtils } from '../util/dates';
import env from '../env';
import { INVALID_PAYLOAD } from '../logging/errors';
import moment from 'moment/moment';
import { sendPostRequest } from './RequestService';
import { SCHEDULE_DATE_FORMAT } from '../util/constants';
import { getDaysBetweenSessions } from '../util/helper';
import { isTimeslotInClueyBusinessHour } from '../util/helper/availability';
import { getTimezoneFromStore } from '../selectors/hubSelector';

export class ScheduleService {
  async changeSchedule({ enrolmentId, courseType, startDate, frequency, tutorId, message }) {
    const url = ScheduleService.getMuleSoftEndpoint(enrolmentId);

    const today = DatesUtils.getCurrentDateFormatted();

    const startDateFormatted = DatesUtils.convertDatesForMuleSoft(startDate);

    const customerMessage = message || `Request to change schedule from ${startDateFormatted}`;

    const payload = {
      sourceSystem: 'Hub',
      scheduleDetails: {
        packageType: (courseType || '').toLowerCase(),
        requestDate: today,
        effectiveFromDate: startDate,
        scheduleStartDate: startDate,
        frequency: (frequency || '').toLowerCase(),
        customerMessage,
        tutorId,
      },
    };

    const errorMessageForUser = 'There was a problem changing your schedule. Please try again later or contact us.';

    if (!ScheduleService.validateChangeSchedulePayload(payload)) {
      ddLogger({
        level: ddLoggerLevel.ERROR,
        label: INVALID_PAYLOAD.message,
        data: { url, ...payload },
        error: new Error(INVALID_PAYLOAD.message),
      });
      throw new Error(errorMessageForUser);
    }

    return sendPostRequest({
      url,
      payload,
      customErrorMsg: errorMessageForUser,
    });
  }

  static async getAvailableSlotsForSchedule({ packageId, rescheduleFrom, sessionFrequency }) {
    const daysBetweenSessions = getDaysBetweenSessions({ sessionFrequency });
    const url = `https://${env.REACT_APP_MULESOFT_DOMAIN}/matching/available-slots-for-package`;
    try {
      const res = await sendPostRequest({
        url,
        payload: {
          daysBetweenSessions,
          packageId,
          rescheduleFrom: new Date(rescheduleFrom).toISOString(),
        },
        customErrorMsg: 'alternate schedules retrieval failed.',
      });
      const timezone = getTimezoneFromStore();
      return res.json().then((result) => {
        return result.availableTimeslots
          .filter((timeslot) => {
            const { start, end } = timeslot;
            return isTimeslotInClueyBusinessHour({ start, end, timezone });
          })
          .map((sched) => {
            const { matchingRank = '', tutorId = '', tutorNumber = '', tutorName = '' } = sched.tutorsAvailable[0] ?? {};
            const [firstname] = tutorName?.split(' ');
            const start = moment.utc(sched.start);
            const end = moment.utc(sched.end);
            return {
              startDate: start,
              endDate: end,
              firstname,
              scheduleDate: moment.utc(sched.start).format(SCHEDULE_DATE_FORMAT).toUpperCase(),
              sessionDuration: end.diff(start, 'minutes'),
              tutorId,
              tutorNumber,
              tutorOrder: String(matchingRank),
            };
          });
      });
    } catch (error) {
      throw error;
    }
  }

  static validateChangeSchedulePayload(payload) {
    if (!payload) {
      logError('Schedule Service Error. No payload found', null, {});
      return false;
    }

    const validSourceSystem = ['hub', 'salesforce'];
    if (!payload.sourceSystem || !validSourceSystem.includes(payload.sourceSystem.toLowerCase())) {
      logError(`Schedule Service Error. Invalid sourceSystem ${payload.sourceSystem}`, null, {});
      return false;
    }

    if (!payload.scheduleDetails) {
      logError('Schedule Service Error. No scheduleDetails found in payload', null, {});
      return false;
    }

    if (!payload.scheduleDetails.requestDate || !DatesUtils.isValidDate(payload.scheduleDetails.requestDate)) {
      logError(`Schedule Service Error. Invalid requestDate ${payload.scheduleDetails.requestDate}`, null, {});
      return false;
    }

    if (
      !payload.scheduleDetails.effectiveFromDate ||
      !DatesUtils.isValidDate(payload.scheduleDetails.effectiveFromDate)
    ) {
      logError(
        `Schedule Service Error. Invalid effectiveFromDate ${payload.scheduleDetails.effectiveFromDate}`,
        null,
        {}
      );
      return false;
    }

    if (
      !payload.scheduleDetails.scheduleStartDate ||
      !DatesUtils.isValidDate(payload.scheduleDetails.scheduleStartDate)
    ) {
      logError(
        `Schedule Service Error. Invalid scheduleStartDate ${payload.scheduleDetails.scheduleStartDate}`,
        null,
        {}
      );
      return false;
    }

    const validFrequencies = ['weekly', 'fortnightly', 'multiple sessions'];
    if (
      !payload.scheduleDetails.frequency ||
      !validFrequencies.includes(payload.scheduleDetails.frequency.toLowerCase())
    ) {
      logError(`Schedule Service Error. Invalid sourceSystem ${payload.scheduleDetails.frequency}`, null, {});
      return false;
    }

    return true;
  }

  static getMuleSoftEndpoint(enrolmentId) {
    return `https://${env.REACT_APP_MULESOFT_DOMAIN}/enrolments/${enrolmentId}/schedules`;
  }
}
