import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from '@cluey/cluey-components';

function getButtonProps({ className, truthyChecks = [] }) {
  let disabled = false;
  let ariaDisabled = false;
  for (let i = 0; i < truthyChecks.length; i++) {
    if (!truthyChecks[i]) {
      disabled = true;
      className = classnames(className, 'pointer-events-none');
      ariaDisabled = 'true';
      break;
    }
  }

  return {
    disabled,
    className,
    'aria-disabled': ariaDisabled,
  };
}

export const RestrictedButton = ({
  buttonText,
  className,
  contentClassName,
  onClick,
  truthyChecks,
}) => {
  const buttonProps = getButtonProps({
    className,
    truthyChecks,
  });

  return (
    <Button
      size="lg"
      {...buttonProps}
      onClick={() => {
        if (buttonProps.disabled) {
          return;
        }
        onClick();
      }}
    >
      <div className={contentClassName}>{buttonText}</div>
    </Button>
  );
};

RestrictedButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  truthyChecks: PropTypes.arrayOf(PropTypes.bool).isRequired,
};
RestrictedButton.defaultProps = {
  className: 'p-0 tracking-normal',
  contentClassName: '',
};
