const STUDY_STYLES = ['Easily distracted', 'Mostly Attentive', 'Highly focused'];
const PERSONALITIES = ['Between Outgoing and Shy', 'Shy', 'Outgoing'];
const INTERESTS = ['Creative', 'Performer', 'Sport/Outdoors'];
const AUTISM_SUPPORT = [
  'Autistic Disorder',
  'Autism spectrum level 2-3',
];
const SPECIAL_LEARNING_NEEDS = [
  ...AUTISM_SUPPORT,
  'ADHD',
  'Anxious',
  'Dyslexia',
  'Dyscalculia',
  'Depression',
  'Dysgraphia',
  'Hearing Impairment',
  'Visual Impairment'
];
const ADDITIONAL_INFORMATION = [
  'Bullied',
  'High Parent Involvement',
  'Lacks Confidence',
  'Missed/Missing School',
  'Parent is a teacher' ,
  'Prefers schedule change',
  'Prefers tutor change',
  'Rapport Building Needed',
  'Special Personal Circumstances',
  'VIP',
]

export default function parseStudentInformation(studentInformation) {
  const studentInformationKeywords = studentInformation?.split(';');
  const studyStyle = studentInformationKeywords?.filter(keyword => STUDY_STYLES.includes(keyword));
  const personality = studentInformationKeywords?.filter(keyword => PERSONALITIES.includes(keyword));
  const interests = studentInformationKeywords?.filter(keyword => INTERESTS.includes(keyword));
  const specialLearningNeeds = studentInformationKeywords?.filter(keyword => SPECIAL_LEARNING_NEEDS.includes(keyword));
  const additionalInformation = studentInformationKeywords?.filter(keyword => ADDITIONAL_INFORMATION.includes(keyword));

  return {
    studyStyle,
    personality,
    interests,
    specialLearningNeeds,
    additionalInformation
  }
}