import { useEffect } from 'react';
import { Container, Link } from '@cluey/cluey-components';
import HubHeader from '../../../common/hub/HubHeader';
import HubFooter from '../../../common/hub/HubFooter/HubFooter';
import { PATH_HUB_SUPPORT } from '../../../util/pagePath';
import loudspeakerIcon from '../../../assets/images/loudspeaker-icon 2.svg';
import { scrollToTop } from '../../../util/helpers';
import articles, { Article } from './articles';

const HubUpdates = () => {
	useEffect(() => {
		scrollToTop();
	}, []);

	return (
		<>
			<HubHeader title="Hub" />
			<Container className="px-4 py-7 md:py-8 lg:mb-0">
				<Link className="mb-2 text-xs font-bold uppercase" to={PATH_HUB_SUPPORT}>
					Support
				</Link>
				<h2 className="mb-14 font-display text-4xl font-bold md:text-[40px] tracking-[1px]">Customer Hub updates</h2>
				<div className="flex flex-row pb-10 items-center">
					<img className="mr-10" alt="Announcement icon" src={loudspeakerIcon} />
					<div className="sm:w-1/2">
						<b>Learn more about the latest updates and what's new in Customer Hub</b>
					</div>
				</div>
        <div>
          {articles.map((article) => (
              <Article key={article.name} data={article} />
            ))}
        </div>
			</Container>
			<HubFooter />
		</>
	);
};

export default HubUpdates;
