import moment from 'moment-timezone';

const MULESOFT_FORMAT = 'YYYY-MM-DD';

export class DatesUtils {
  static generateDatesBetween({
    from, to, daysInterval, outputFormat, timezone = 'Australia/Sydney',
  }) {
    let shouldAdd;

    const dates = {
      from: moment.isMoment(from)
        ? from
        : moment(from)
          .tz(timezone, true)
          .utc(),
      to: moment.isMoment(to)
        ? to
        : moment(to)
          .tz(timezone, true)
          .utc(),
    };

    if (!dates.from.isValid()) {
      throw new Error(`Invalid from date ${from}`);
    }

    if (!dates.to.isValid()) {
      throw new Error(`Invalid to date ${to}`);
    }

    const response = [outputFormat ? dates.from.format(outputFormat) : dates.from.toISOString()];

    if (dates.from.isBefore(dates.to)) {
      shouldAdd = true;
    } else if (dates.from.isAfter(dates.to)) {
      shouldAdd = false;
    } else {
      return response;
    }

    let done = false;

    while (!done) {
      const nextDate = shouldAdd ? dates.from.add(daysInterval, 'days') : dates.from.subtract(daysInterval, 'days');
      const shouldPush = shouldAdd ? nextDate.isBefore(dates.to) : nextDate.isAfter(dates.to);

      if (shouldPush) {
        response.push(outputFormat ? nextDate.format(outputFormat) : nextDate.toISOString());
        dates.from = nextDate;
      } else {
        done = true;
      }
    }

    return response;
  }

  /*
   * All options should be generated based on the current resume date, plus or minus 1 week
   * All options should be between January and February.
   * All options should be in the future.
   */
  static generateReboardingResumeDatesOptions = (resumeFrom, timezone = 'Australia/Sydney') => {
    const dateTo = moment('2023-02-28').utc();
    const dateFrom = moment('2022-10-17').utc();
    const daysInterval = 7;

    const resumeDates = [];
    resumeDates.push(
      ...DatesUtils.generateDatesBetween({
        from: resumeFrom,
        to: dateTo,
        daysInterval,
        timezone,
      }),
    );
    resumeDates.push(
      ...DatesUtils.generateDatesBetween({
        from: resumeFrom,
        to: dateFrom,
        daysInterval,
        timezone,
      }),
    );
    resumeDates.sort();

    const tomorrowUTC = moment()
      .add(2, 'days')
      .utc();

    // Check dates after today and tomorrow and between Jan and Feb
    const filteredDates = resumeDates.filter(
      date => moment(date).isAfter(tomorrowUTC) && moment(date).isBetween(dateFrom, dateTo),
    );

    // Remove duplicates with Set
    return [...new Set(filteredDates)];
  };

  static convertToSydneyMidnight = (date) => {
    const result = moment(date)
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .tz('Australia/Sydney', true);

    return result;
  };

  static isValidDate(date) {
    const momentObject = moment(date);

    return momentObject.isValid();
  }

  static formatStringDate({ dateString, outputFormat, inputFormat }) {
    const defaultInputFormat = 'YYYY-MM-DD';
    const date = moment(dateString, inputFormat || defaultInputFormat);

    return date.format(outputFormat);
  }

  static formatISOStringDate({ ISODate, outputFormat }) {
    const defaultOutputFormat = 'YYYY-MM-DD';
    return moment(ISODate).format(outputFormat || defaultOutputFormat);
  }

  static getCurrentDate() {
    return moment();
  }

  static getCurrentUTCdate() {
    return moment.utc();
  }

  static getCurrentSydneyDate() {
    return moment().tz('Australia/Sydney');
  }

  static getCurrentDateFormatted(outputFormat) {
    return this.getCurrentDate().format(outputFormat || MULESOFT_FORMAT);
  }

  static getCurrentUTCDateFormatted(outputFormat) {
    const defaultOutputFormat = 'YYYY-MM-DD';
    return moment()
      .utc()
      .format(outputFormat || defaultOutputFormat);
  }

  static getTimeNowInSydney() {
    return moment.utc().tz('Australia/Sydney');
  }

  static convertDatesForMuleSoft(date, outputFormat) {
    const momentObject = moment(date);
    const format = outputFormat || MULESOFT_FORMAT;

    return DatesUtils.convertToSydneyMidnightAndUTC(momentObject).format(format);
  }

  static convertToSydneyMidnightAndUTC(momentObject) {
    const momentCopy = momentObject.clone();
    const time = momentCopy
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .tz('Australia/Sydney', true);

    return time.utc();
  }

  static convertDateToSydneyTime(date) {
    return moment.utc(date).tz('Australia/Sydney');
  }

  static convertDateToTimezone(date, timezone = 'Australia/Sydney') {
    return moment.utc(date).tz(timezone);
  }

  static isSameDay(from, to) {
    return moment(from).isSame(moment(to), 'day');
  }

  static isDayAfter(from, to) {
    return moment(from).isAfter(moment(to), 'day');
  }

  static isDayBefore(from, to) {
    return moment(from).isBefore(moment(to), 'day');
  }

  static convertFromTimezoneToUTC({
    stringDate, dateFormat, timezone, hour, minute,
  }) {
    const defaultTimeZone = 'Australia/Sydney';
    const result = moment.tz(stringDate, dateFormat, timezone || defaultTimeZone).utc();

    if (hour) {
      result.set({ hour });
    }

    if (minute) {
      result.set({ minute });
    }

    return result;
  }
}
