import { Checkbox } from "@cluey/cluey-components";
import { CancelReason } from "../../../../../../types/hubInterfaces";

interface Props {
  checked: boolean;
  onCheck(reason: CancelReason): void;
  reason: CancelReason;
}

const ReasonCheckbox = ({ reason, checked, onCheck }: Props) => {
  return (
    <div
      key={reason.label}
      className="flex gap-x-3 items-center">
      <div className="shrink-0">
        <Checkbox id={reason.label} onChange={() => onCheck(reason)} value={checked} />
      </div>
      <label className="cursor-pointer text-sm" htmlFor={reason.label}>{reason.label}</label>
    </div>
  )
};

export default ReasonCheckbox;