import { ReactNode, useRef, useState } from 'react';
import { MathfieldElement } from 'mathlive';
import { isEmpty, isNull } from 'lodash';
import { ExclamationMarkIcon, SpeechIcon } from '../../../icons';
import { ConversationLayout } from './ConversationLayout';
import { OptionButton } from './OptionButton';
import { CompressedImage, QuestionInputRef } from '../QuestionInput';
import useAiTutorContext from '../useAiTutorContext';
import useGetRemainingRequests from '../useGetRemainingRequests';
import { BetaVersionBanner } from '../BetaVersionBanner';
import posthog from 'posthog-js';

export interface CreatePromptProps {
  setPromptCallback(file: CompressedImage): void;
  backClick(): void;
}

export const PROMPTS_STARTER: Record<string, string> = {
  'What is the first step I should take to solve ____ ?': 'What is the first step I should take to solve ',
  'Can you check my work on this ____ ?': 'Can you check my work on this ',
  "I'm feeling confused and need help understanding the concept of ____":
    "I'm feeling confused and need help understanding the concept of ",
  'Can you show me how to solve this ____ ?': 'Can you show me how to solve this ',
};

export const CreatePrompt = ({ setPromptCallback, backClick }: CreatePromptProps) => {
  const { enrolment, question, setQuestion } = useAiTutorContext();
  const { status, data: remainingRequests } = useGetRemainingRequests(enrolment.enrolmentId);

  const [errorMessage, setErrorMessage] = useState<ReactNode>(null);
  const [file, setFile] = useState<CompressedImage | null>(null);
  const mathLiveFieldRef = useRef<MathfieldElement>();
  const disabled = remainingRequests <= 0 || status === 'loading';

  const extractTextFromLatex = (latexString: string) => {
    const match = latexString.match(/\\text\{(.*?)\}/);
    if (match) {
      return match[1];
    }
    return null;
  };

  const questionErrorMessage = (message?: string) => (
    <div className="flex flex-row items-start gap-x-1 font-body text-sm font-medium text-red-5">
      <div className="mt-1">
        <ExclamationMarkIcon className="text-red-5" width={12} height={12} />
      </div>
      <span className="text-sm text-red-5">{message || 'Write a question to ask Cluey Coach'}</span>
    </div>
  );

  return (
    <ConversationLayout
      backButton
      backButtonClick={() => backClick()}
      bottomActionLabel={
        <>
          <SpeechIcon width={24} height={24} />
          <span className="font-bold">Let's chat</span>
        </>
      }
      bottomActionDisabled={disabled}
      bottomActionClick={() => {
        if (
          !isEmpty(file) ||
          (!isEmpty(question) && !Object.values(PROMPTS_STARTER).includes(extractTextFromLatex(question)))
        ) {
          setPromptCallback(file);
          setQuestion(null);
          setErrorMessage(null);
        } else {
          setErrorMessage(questionErrorMessage());
        }
      }}
    >
      <div className="mt-8 flex flex-col gap-y-10">
        <BetaVersionBanner enrolmentId={enrolment.enrolmentId} />
        <div>
          <p className="mb-1 text-lg font-bold">What do you want to ask?</p>
          <p className="text-base">Choose a prompt starter or write your own question</p>
          <div className="relative mb-5 mt-4 grid grid-flow-row auto-rows-auto gap-3 md:grid-cols-2">
            {Object.keys(PROMPTS_STARTER).map((promptStarter) => (
              <OptionButton
                label={promptStarter}
                key={promptStarter}
                isSelected={question && question.includes(PROMPTS_STARTER[promptStarter])}
                onClick={() => {
                  setErrorMessage(null);
                  setQuestion(PROMPTS_STARTER[promptStarter]);
                  if (mathLiveFieldRef.current) {
                    mathLiveFieldRef.current.focus();
                    mathLiveFieldRef.current.setValue(PROMPTS_STARTER[promptStarter], { mode: 'text' });
                  }
                }}
              />
            ))}
          </div>
          <p className="mb-2 text-base">My question is</p>
          <QuestionInputRef
            disabled={disabled}
            ref={mathLiveFieldRef}
            value={mathLiveFieldRef && mathLiveFieldRef.current ? mathLiveFieldRef.current.getValue() : question}
            onInput={(event) => {
              const mathfield = event.target as MathfieldElement;
              setQuestion(mathfield.getValue());
              setErrorMessage(null);
            }}
            onFileChanged={(attachment, error) => {
              if (!isNull(error) && !isEmpty(error)) {
                setErrorMessage(questionErrorMessage(error.message));
              } else {
                posthog.capture('cluey-coach_add-attachment');
                setFile(attachment);
                setErrorMessage(null);
              }
            }}
            errorMessage={errorMessage}
            helperText={
              <span className="proxima-soft color-grey-5 text-base">
                You can attach a photo or screenshot of your question. Max file size is 10MB. Supported file types are
                JPG, PNG and BMP.
              </span>
            }
            showSubmitButton={false}
          />
        </div>
      </div>
    </ConversationLayout>
  );
};
