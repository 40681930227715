import { Alert, Button, Container } from '@cluey/cluey-components';
import { isNull } from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';

import { ReactComponent as FilledCoin } from '../../../assets/images/atoms-icon-filled-coin.svg';
import withHeadTag from '../../../common/HeadComponent';
import { FullPageLoader } from '../../../components/Loader/Loader';
import { PageTitle } from '../../../components/hub/PageTitle';
import BasePageLayout from '../../../templates/BasePageLayout/BasePageLayout';
import { scrollToTop } from '../../../util/helpers';
import { PATH_HUB_CHANGE_PLAN, PATH_HUB_PLAN_DETAILS } from '../../../util/pagePath';
import PaymentOptionSelector from '../components/PaymentOptionSelector/PaymentOptionSelector';
import PricingPlanComparison from './components/PricingPlanComparison';
import useChangePricingPlanContext, { ChangePricingPlanSteps } from './useChangePricingPlanContext';

const ChangePlanConfirmation = () => {
  const {
    loading,
    setCurrentStep,
    currentPricingPlan,
    upcomingPricingPlan,
    selectedPlan,
    setSelectedPlan,
    invoicePer,
    setInvoicePer,
    nextUnpaidSession,
    timezone,
    paidSessions,
    hasSeveralActiveEnrolments,
    planPreviewPaymentDetails,
    submitChangePlan,
    cancelChangePlan,
    changePlanError,
  } = useChangePricingPlanContext();
  useEffect(() => {
    scrollToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const planEstimate = planPreviewPaymentDetails.find(
    (planPreview) => planPreview.pricingPlanId === selectedPlan.pricingPlanId
  );
  const pricingPlans = [upcomingPricingPlan || currentPricingPlan, selectedPlan];

  return (
    <BasePageLayout>
      {loading && <FullPageLoader />}
      {!loading && !changePlanError && (
        <>
          <PageTitle
            title="Change plan confirmation"
            className="!pb-10"
            breadcrumbs={[
              { label: 'Account', to: PATH_HUB_PLAN_DETAILS },
              {
                label: 'Cluey Plans',
                to: PATH_HUB_CHANGE_PLAN,
                onClick: () => {
                  setSelectedPlan(null);
                  setCurrentStep(ChangePricingPlanSteps.CHOOSE_A_PLAN);
                },
              },
            ]}
          />
          <Container className="flex flex-col gap-y-10 !px-0">
            {planEstimate?.total && (
              <Alert type="info" className="!mb-0" title="Change of plan one-off payment" body={null}>
                <div className="mt-3 flex flex-col gap-y-4">
                  <p>
                    The {selectedPlan.pricingPlanName} plan requires {paidSessions.length + planEstimate?.sessionCount}{' '}
                    session{paidSessions.length + planEstimate?.sessionCount > 1 ? 's' : ''} paid in advance. You have{' '}
                    {paidSessions.length} remaining paid session{paidSessions.length > 1 ? 's' : ''} on your current
                    plan.
                  </p>
                  <p>
                    <b>
                      A one-off payment of ${planEstimate?.total} will be deducted from your account to cover the
                      additional {planEstimate?.sessionCount} session{planEstimate?.sessionCount > 1 && 's'} on your
                      next billing cycle. After this you will be charged $
                      {selectedPlan.privatePriceTier?.costPerSession} per session on your regular billing cycle.
                    </b>
                  </p>
                </div>
              </Alert>
            )}
            {changePlanError && (
              <Alert
                className="mt-4"
                type="error"
                body={`There was a problem changing your plan. ${changePlanError.message}`}
              />
            )}
            <div className="text-base">
              Your plan will change from{' '}
              <b>
                {!isNull(upcomingPricingPlan)
                  ? upcomingPricingPlan.pricingPlanName
                  : currentPricingPlan.pricingPlanName}
              </b>{' '}
              to <b>{selectedPlan.pricingPlanName}</b>. Your new plan payment will only start from the next unpaid
              session which is on <b>{moment(nextUnpaidSession?.startTime).tz(timezone).format('MMMM D')}</b>.
            </div>
            <div className="flex flex-row gap-x-2">
              <div className="flex">
                <FilledCoin className="h-7 w-7 my-auto" />
              </div>
              <div className="text-lg font-bold proxima-nova">
                Your new regular payment will be ${selectedPlan.privatePriceTier?.costPerSession} per session
              </div>
            </div>
            <div>
              <PricingPlanComparison pricingPlans={pricingPlans} showActionButton={false} showHeaders />
            </div>
          </Container>
          {hasSeveralActiveEnrolments && (
            <Container className="flex mt-[60px]">
              <PaymentOptionSelector onChange={setInvoicePer} initialValues={{ paymentOption: invoicePer }} />
            </Container>
          )}
          <Container className="flex mt-[60px]">
            <div className="w-full md:w-auto md:ml-auto flex flex-col md:flex-row-reverse gap-x-[10px] gap-y-2">
              <Button className="w-full md:w-auto" size="lg" onClick={submitChangePlan}>
                Confirm plan change
              </Button>
              <Button className="w-full md:w-auto" appearance="reverse" size="lg" onClick={cancelChangePlan}>
                Cancel
              </Button>
            </div>
          </Container>
        </>
      )}
    </BasePageLayout>
  );
};

export default withHeadTag(ChangePlanConfirmation);
