import initialState from './initialState';
import {
  CF_TRIGGER_PAYMENT_SUCCESS, CF_TRIGGER_PAYMENT_FAILED,
} from '../actions/actionTypes';

export default function triggerPayment(state = initialState.triggerPayment, action) {
  const { type, payload } = action;
  switch (type) {
    case CF_TRIGGER_PAYMENT_SUCCESS:
      return {
        ...state,
        succeededSessions: payload.succeededSessions,
      };
    case CF_TRIGGER_PAYMENT_FAILED:
      return {
        ...state,
        failedSessions: payload.failedSessions,
      };
    default:
      return state;
  }
}
