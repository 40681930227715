import FAQComponent from '../../../components/FAQComponent/FAQComponent';
import AppFAQAnswer from '../../../components/FAQComponent/components/FAQAnswer/AppFAQAnswer';
import {
  flexiPlanQuestions,
  generalPlanQuestions,
  saverPlanQuestions,
  saverPlusPlanQuestions,
  valuePlusPlanQuestions,
} from '../../../util/constants/faqContent';

const PaymentPolicyFAQ = () => {
  return (
    <FAQComponent
      title="Payment and policy FAQs"
      titleClassNames="!text-2xl mb-4 text-center md:!text-4xl"
      subtitleClassNames="text-center"
      questions={[
        generalPlanQuestions,
        flexiPlanQuestions,
        saverPlanQuestions,
        saverPlusPlanQuestions,
        valuePlusPlanQuestions,
      ]}
      itemConfig={{
        answerComponent: ({ item }) => <AppFAQAnswer answers={item.answers} />,
      }}
    />
  );
};

export default PaymentPolicyFAQ;
