import { Container, LinkButton } from '@cluey/cluey-components';
import EnrolmentLayout from '../../../../common/hub/EnrolmentLayout';
import { PATH_HUB_UPCOMING_SESSIONS } from '../../../../util/pagePath';
import { useHistory } from 'react-router-dom';
import useRedirectInvalidSession from '../../../../hooks/session/useRedirectInvalidSession';
import { useSelector } from 'react-redux';
import { getTimezone } from '../../../../selectors/hubSelector';
import { SessionDateSummary } from '../../../../components/EnrolmentManagement/SessionDateSummary';
import moment from 'moment';
import type { Session } from '../../../../types/hubInterfaces';

interface HistoryState {
  nextSession: Session;
  sessionToSkip: Session;
}

const Success = () => {
  const history = useHistory<HistoryState>();
  const { location: {state} } = history;
  const sessionToSkip = state?.sessionToSkip;
  const timezone: string = useSelector(getTimezone);
  const sessionStartTime = moment(sessionToSkip?.startTime).tz(timezone);
  const sessionEndTime = moment(sessionToSkip?.endTime).tz(timezone);
  useRedirectInvalidSession(sessionToSkip)

  if (!sessionToSkip) {
    return null;
  }

  return (
    <EnrolmentLayout
      sectionHeading={`${sessionToSkip.student} ${sessionToSkip.subject}`}
      mainHeading="Cancel confirmed"
      showEnrolmentStatus={false}
      showSummerModeEnrolmentStatus={false}
      enrolmentId={sessionToSkip.uniqueEnrolmentId}
    >
      <Container>
        <SessionDateSummary
          heading='Cancelled Session'
          sessionStartDate={sessionStartTime}
          sessionEndDate={sessionEndTime}
        />
        <p className="max-w-2xl mt-9 text-grey-6 text-xl">We have cancelled your upcoming session, you will see it reflected on your enrolment and session details soon. You will also receive an email confirming the details soon.</p>
        <LinkButton
          className='mt-7'
          size='xs'
          appearance='reverse'
          to={PATH_HUB_UPCOMING_SESSIONS}>
        view your sessions
        </LinkButton>
      </Container>
    </EnrolmentLayout>
  )
}

export default Success;