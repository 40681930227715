import { useState, Fragment } from 'react';
import classnames from 'classnames';
import { Button } from '@cluey/cluey-components';
import useWindowWidth from '../../hooks/common/useWindowWidth';
import { BREAKPOINTS } from '../../util/constants';

interface Props {
  children: JSX.Element | Array<JSX.Element>;
  forceExpand?: boolean;
}

/* @TODO add test */
const ShowMore = ({ children, forceExpand }: Props) => {
  const windowWidth = useWindowWidth();
  const [expanded, setExpanded] = useState(!!forceExpand);

  const expand = () => setExpanded(true);
  const collapse = () => setExpanded(false);

  const showMoreBtn = !(windowWidth >= BREAKPOINTS.sm);
  const btn = (
    <Button
      className='!block mt-3'
      appearance="reverse"
      onClick={expanded ? collapse : expand}
    >
      <span
        className={classnames(
          'icon icon text-primary',
          expanded ? 'icon-arrow-up' : 'icon-arrow-down'
        )}
      />
    </Button>
  );
  return (
    <Fragment>
      {(!showMoreBtn || (showMoreBtn && expanded)) && (
        <Fragment>
          {children}
          {showMoreBtn && expanded && !forceExpand && btn}
        </Fragment>
      )}
      {showMoreBtn && !expanded && btn}
    </Fragment>
  );
}

export { ShowMore };
