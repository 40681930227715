import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';

import withSelectedEnrolment from '../../../common/hub/NewWithSelectedEnrolment';
import ErrorBlock from '../../../common/ErrorBlock';
import ContactFormLayout from '../../../common/hub/ContactFormLayout';
import FormSubHeading from '../../../components/hub/contact/FormSubHeading';
import { useTextAreaProps, TextArea } from '../../../components/TextArea/TextArea';
import { RestrictedButton } from '../../../components/RestrictedButton/RestrictedButton';

import { submitCaseToSF } from '../../../actions/hub/hubContactPageActions';
import { PATH_HUB_CR_SUBMIT_CASE_SUCCESS, PATH_HUB_CR_PAYMENT_QUERY } from '../../../util/pagePath';

const FormPaymentQuery = ({ selectedEnrolment, isSubmitting, submitError, errorMsg, submit }) => {
  const history = useHistory();
  const { enrolmentId, studentSfid, student, subject } = selectedEnrolment;

  const textAreaProps = useTextAreaProps({
    id: 'text-how-can-we-help',
    labelText: 'How can we help you?',
  });

  const message = textAreaProps.value;

  const onClickHandler = () => {
    submit({
      uniqueEnrolmentId: enrolmentId,
      message: `Payment query. Other details: ${message}`,
      caseSubject: 'Contact request - Payment - Support Request',
      caseType: 'Payment',
      caseCategory: 'Support Request',
      onSubmitSuccess: () => {
        history.push(PATH_HUB_CR_SUBMIT_CASE_SUCCESS, {
          firstName: student,
          studentId: studentSfid,
          header: 'Query submitted',
          message: `Your payment query for ${student}'s ${subject} enrolment has been submitted successfully. We'll be in touch soon.`,
        });
      },
    });
  };

  return (
    <ContactFormLayout selectedEnrolment={selectedEnrolment}>
      <div className="px-5 pt-5 lg:px-20 lg:pt-12">
        <FormSubHeading />
        {submitError && errorMsg && <ErrorBlock errorMsg={errorMsg} />}
        <form>
          <TextArea {...textAreaProps} className="mb-7" />
        </form>
        <div className="mb-4 text-center tracking-normal lg:mb-8">
          <RestrictedButton
            buttonText="Submit query"
            truthyChecks={[message.length > 0, !isSubmitting]}
            onClick={() => {
              onClickHandler();
            }}
          />
        </div>
      </div>
    </ContactFormLayout>
  );
};

FormPaymentQuery.propTypes = {
  selectedEnrolment: PropTypes.shape({
    enrolmentId: PropTypes.string.isRequired,
    detail: PropTypes.string.isRequired,
    nextSession: PropTypes.string.isRequired,
    studentSfid: PropTypes.string.isRequired,
    student: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
  }).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitError: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    return {
      isSubmitting: state.ui.apiState.hubContactPage.isLoading,
      submitError: state.ui.apiState.hubContactPage.fetchError,
      errorMsg: state.ui.apiState.hubContactPage.errorMsg,
    };
  },
  (dispatch) => {
    return {
      submit: bindActionCreators(submitCaseToSF, dispatch),
    };
  }
)(withSelectedEnrolment(FormPaymentQuery, null, PATH_HUB_CR_PAYMENT_QUERY));
