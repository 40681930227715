import classNames from 'classnames';
import { COPYRIGHT } from '../../../util/constants';
import { Footer } from './Footer';
import { getCompanyDetailByCountry } from '../../../util/helpers';

import { LinkButton, Container } from '@cluey/cluey-components';
import { PATH_HUB_SUPPORT } from '../../../util/pagePath';
import { api } from '../../../api';

export interface Props {
	hasCTAAboveFooter?: boolean;
	hideContactUs?: boolean;
}

function HubFooter({ hasCTAAboveFooter, hideContactUs }: Props) {
	const { data: details } = api.account.details.useQuery();

	const showContactUs = !hideContactUs && details && details.userType !== 'Lead';

	return (
		<div
			className={classNames(
				`flex w-full ${hasCTAAboveFooter ? 'mt-5' : 'mt-9'}`,
				hasCTAAboveFooter && 'border-0',
				!hasCTAAboveFooter && 'border-t-2 border-grey-2'
			)}
		>
			<Container>
				<div
					className={classNames(
						'mb-4 flex flex-col items-start justify-between gap-5 text-grey-6 md:flex-row md:items-center lg:mb-5'
					)}
				>
					<section className="flex flex-col gap-x-20 md:flex-row">
						<Footer companyDetails={getCompanyDetailByCountry({ isNZ: false })} />
						<Footer companyDetails={getCompanyDetailByCountry({ isNZ: true })} />
					</section>
					{showContactUs && (
						<div className="text-left md:ml-auto md:text-right">
							<p className="mb-2 text-base font-bold leading-none">Need some help?</p>
							<LinkButton to={PATH_HUB_SUPPORT} appearance="reverse" size="xs">
								Support
							</LinkButton>
						</div>
					)}
				</div>
				<div className="border-t-2 border-grey-2 pb-11 pt-4 text-xs text-grey-6">&copy; {COPYRIGHT}</div>
			</Container>
		</div>
	);
}

export default HubFooter;
