import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const NewAccordion = ({
  isOpen, onToggle, title, subtitle, children,
}) => {
  return (
    <div className="border border-custom-3 rounded-lg overflow-hidden">
      <button
        type="button"
        onClick={onToggle}
        className="flex justify-between items-center p-5 md:px-10 w-full border-0 bg-slate-1 text-blue-5 fill-blue-5 focus:outline-none focus:text-blue-6 focus:bg-slate-1 focus:fill-blue-6 hover:text-blue-6 hover:bg-slate-1 hover:fill-blue-6"
      >
        <div className={classNames('flex flex-col items-start', isOpen && 'text-grey-6' )}>
          <div className="text-xl font-display font-bold">{title}</div>
          {subtitle && <div className="text-lg font-display">{subtitle}</div>}
        </div>
        <div className={classNames('rotate-0 transition-transform', { 'rotate-180': isOpen })}>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <path
              d="M16 23.467c-.521 0-1.04-.176-1.463-.53L.823 11.506C-.147 10.7-.28 9.257.53 8.288c.807-.969 2.247-1.1 3.218-.292l12.277 10.231 12.256-9.863c.983-.791 2.423-.636 3.214.347.791.983.636 2.421-.347 3.214L17.433 22.961c-.418.337-.925.506-1.433.506"
              transform="translate(-1097.000000, -1511.000000) translate(1097.000000, 1511.000000)"
            />
          </svg>
        </div>
      </button>
      <div className={classNames("p-5 md:px-10", isOpen && 'block', !isOpen && 'hidden')}>{children}</div>
    </div>
  );
};

NewAccordion.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
};

NewAccordion.defaultProps = {
  subtitle: '',
};

export const NewAccordionWithState = ({ title, subtitle, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onToggle = () => setIsOpen(!isOpen);

  return (
    <NewAccordion isOpen={isOpen} onToggle={onToggle} title={title} subtitle={subtitle}>
      {children}
    </NewAccordion>
  );
};

NewAccordionWithState.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
};

NewAccordionWithState.defaultProps = {
  subtitle: '',
};
