import { useCallback, useMemo } from 'react';
import { HamburgerButtonIcon, KeyboardButtonIcon, TakePhotoIcon, UploadIcon } from './icons';
import { Tooltip } from '../../../components/Tooltip/Tooltip';

export function createEventHandler(setSelectedFile: (file: File) => void): React.ChangeEventHandler<HTMLInputElement> {
  return (event) => {
    if (event.target.files && event.target.files[0]) {
      const image = event.target.files[0];

      const sizeInBytes = image.size;
      const sizeInMB = sizeInBytes / 1024 / 1024;
      const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];

      if (!allowedTypes.includes(image.type)) {
        alert('Only PNG and JPEG files are allowed');
        event.target.value = null;
        setSelectedFile(null);
      } else if (sizeInMB > 10) {
        alert('File size should be less than 10MB');
        event.target.value = null;
        setSelectedFile(null);
      } else {
        setSelectedFile(image);
      }
    }
  };
}

export interface QuestionToolbarProps {
  uploadFileInputRef: React.RefObject<HTMLInputElement>;
  takePhotoInputRef: React.RefObject<HTMLInputElement>;
  setSelectedFile: (image: File) => void;
  keyboardToggle: () => void;
  menuToggle: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
}

const QuestionToolbar = ({
  uploadFileInputRef,
  takePhotoInputRef,
  setSelectedFile,
  disabled = false,
  keyboardToggle,
  menuToggle,
}: QuestionToolbarProps) => {
  const handleInputOnChange: React.ChangeEventHandler<HTMLInputElement> = useMemo(
    () => createEventHandler(setSelectedFile),
    [setSelectedFile]
  );

  const handleUploadFile = useCallback(() => {
    if (uploadFileInputRef.current) {
      uploadFileInputRef.current.click();
    }
  }, [uploadFileInputRef]);

  const handleTakePhoto = useCallback(() => {
    if (takePhotoInputRef.current) {
      takePhotoInputRef.current.click();
    }
  }, [takePhotoInputRef]);

  return (
    <>
      <input
        id="upload-file-input"
        type="file"
        accept="image/*"
        onChange={handleInputOnChange}
        style={{ display: 'none' }}
        ref={uploadFileInputRef}
      />
      <input
        id="take-photo-input"
        type="file"
        accept="image/*"
        capture="environment" // Opens the camera directly on mobile
        onChange={handleInputOnChange}
        style={{ display: 'none' }}
        ref={takePhotoInputRef}
      />
      <div className="flex h-9 flex-row items-center justify-center space-x-3">
        <Tooltip content="Attach image" className="h-5 w-4">
          <label htmlFor="upload-file-input">
            <button disabled={disabled} type="button" onClick={handleUploadFile} aria-label="upload file">
              <UploadIcon disabled={disabled} height="20" width="16" />
            </button>
          </label>
        </Tooltip>
        <Tooltip content="Take photo" className="visible h-5 w-[17px] md:hidden">
          <label htmlFor="take-photo-input">
            <button disabled={disabled} type="button" onClick={handleTakePhoto} aria-label="take photo">
              <TakePhotoIcon disabled={disabled} height="20" width="17" />
            </button>
          </label>
        </Tooltip>
        <Tooltip content="Toggle virtual keyboard" className="h-5 w-5">
          <button disabled={disabled} type="button" onClick={keyboardToggle} aria-label="toggle virtual keyboard">
            <KeyboardButtonIcon disabled={disabled} />
          </button>
        </Tooltip>
        <Tooltip content="Menu" className="h-5 w-5">
          <button disabled={disabled} type="button" onClick={(event) => menuToggle(event)} aria-label="menu">
            <HamburgerButtonIcon disabled={disabled} height="20" width="20" />
          </button>
        </Tooltip>
      </div>
    </>
  );
};

export default QuestionToolbar;
