import { IconProps as Props } from '../types/hubInterfaces';
import { iconDefaults } from '.';
import classNames from 'classnames';

export const PencilIcon = (props: Props) => {
	const { width, height, className } = { ...iconDefaults, ...props };
	return (
		<svg
			className={classNames('fill-current', className)}
			xmlns="http://www.w3.org/2000/svg"
			width={`${width}`}
			height={`${height}`}
			viewBox="0 0 25 25"
		>
			<title>pencil icon</title>
			<path
				d="M19.097 18.996c.917 0 1.66.743 1.66 1.66 0 .918-.743 1.661-1.66 1.661H5.827c-.917 0-1.66-.743-1.66-1.66 0-.918.743-1.661 1.66-1.661h13.27zM8.891 3.25l8.794 8.794c.127.128.208.283.25.446.004.01.01.017.013.028l1.006 4.032c.128.516-.338.983-.855.855l-3.996-.998-.022-.006-.013-.003-.006-.003c-.173-.04-.337-.124-.47-.258L6.585 9.13l2.7-2.7c.238-.238.238-.625 0-.864s-.624-.239-.863 0l-2.701 2.7-.924-.924c-.383-.384-.383-1.004 0-1.388L7.504 3.25c.383-.383 1.004-.383 1.387 0z"
				transform="translate(-286 -1343) translate(286 1343)"
			/>
		</svg>
	);
};
