import { first, orderBy } from 'lodash';
import moment from 'moment';
import { ENROLMENT_SESSION_FREQUENCY } from '../../types/hubEnums';
import { DATE_FORMAT_1 } from '../constants';
import type { EnrolmentSession } from '../../types/hubInterfaces';

export function getNextSessionDate({ sessions, selectedDay, frequency }: {
  sessions: Array<EnrolmentSession>;
  selectedDay: string; /* in UTC */
  frequency: ENROLMENT_SESSION_FREQUENCY;
}) {
  const nextUpcomingSession = first(orderBy(sessions, ['start'], ['asc']));
  if (!nextUpcomingSession) {
    return null;
  }
  const nowMoment = moment.utc();
  const nextUpcomingMoment = moment.utc(nextUpcomingSession.start);
  const selectedDayMoment = moment.utc(selectedDay);
  const hourDiff = moment.duration(nextUpcomingMoment.diff(nowMoment)).asHours();
  /*
    if the enrolment has a session in the next 4 hours, the next session date will be
    1 week after the selected day or 2 weeks in the case of fortnightly enrolments
  */
  const offset = frequency === ENROLMENT_SESSION_FREQUENCY.FORTNIGHTLY ? 14 : 7;
  if (hourDiff <= 4 && nextUpcomingMoment.isSame(selectedDayMoment, 'week')) {
    return selectedDayMoment.add(offset, 'days').format(DATE_FORMAT_1);
  }
  return selectedDayMoment.format(DATE_FORMAT_1);
}

