export const INCENTIVE_JANUARY_RESTARTER_PACK =
  'INCENTIVE_JANUARY_RESTARTER_PACK';
export const INCENTIVE_FEBRUARY_RESTARTER_PACK =
  'INCENTIVE_FEBRUARY_RESTARTER_PACK';

export const SUMMER_15PCT_ALL_SESSIONS =
  'SUMMER_15PCT_ALL_SESSIONS';

export const APPLY_FEBRUARY_RESTARTER_PAYLOAD = {
  caseType: 'Payment',
  caseCategory: 'Support Request',
  caseSubject: 'Reboarding - Apply February 20% Discount',
  message:
    'This enrolment has been saved by the Hub! Instead of cancelling, the customer opted for a 20% discount on all the sessions’s enrolment in February. Please apply the discount in the system accordingly.',
  customErrorMsg: 'Apply February 20% Discount case creation failed',
};
