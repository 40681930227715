import { sendGetRequest } from '../../services/RequestService';
import { GET_ALLOWED_PRICING_PLANS } from '../../util/constants/queryKeys';
import { getPortalAPIRequestParams } from '../../services/PortalAPIServices/helper';
import { PricePlan, PricingPlans } from '../../types/hubTypes';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

export async function retrievePricingPlans(sfId: string) {
    const { url, reqOptions } = getPortalAPIRequestParams(`pricingPlan/availableAccountPricingPlans?sfId=${sfId}`);
    return sendGetRequest({
        url,
        signRequest: true,
        reqOptions,
    }).then((res) => {
        return res.json();
    }).then(data => {
        const { currentPlan, allowedPlansForTransition } = data;
        return {
            currentPlan,
            allowedPlansForTransition: allowedPlansForTransition.sort((a: PricePlan, b: PricePlan) => b.privatePriceTier.costPerSession - a.privatePriceTier.costPerSession)
        };
    });
}

const useRetrievePricingPlansQuery = () => {

    const sfid = useSelector((state) => {
        // @ts-ignore
        return state.hubAccountPage.accountDetails.customerSfid;
    });

    return useQuery<PricingPlans>({
        queryKey: [GET_ALLOWED_PRICING_PLANS],
        queryFn: () => retrievePricingPlans(sfid),
        enabled: !!sfid
    });

};

export default useRetrievePricingPlansQuery;