import type { FAQAnswerObject } from '../../types';

export type FAQAnswerProps = {
  answers: FAQAnswerObject[];
};

const FAQAnswer = ({ answers }: FAQAnswerProps) => {
  return (
    <div className="flex flex-col space-y-2 pb-4">
      {answers.map((answer, index) => (
        <div key={index} className="[&:not(:first-child)]:mt-5 flex flex-col">
          {answer.title && <div className="proxima-nova text-lg underline ">{answer.title}</div>}
          <div className="proxima-nova ml-2 text-base ">{answer.answer}</div>
        </div>
      ))}
    </div>
  );
};
export default FAQAnswer;
