import emptyFace from './assets/empty-face.svg';

export const HomeEmptyWidget = () => {
  return (
    <div className="container mx-auto flex flex-grow flex-col items-center justify-center px-4 py-36">
      <img src={emptyFace} alt="" />
      <p className="font-bold">You&apos;ve taken all the actions so far</p>
      <p className="text-center">Check back soon for new self-paced learning features.</p>
    </div>
  );
};
