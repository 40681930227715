import { useHistory, useLocation } from 'react-router';
import moment from 'moment';
import { ButtonAsLink, Container } from '@cluey/cluey-components';
import ErrorBlock from '../../../common/ErrorBlock';
import FormSubHeading from '../../../components/hub/contact/FormSubHeading';
import { useTextAreaProps, TextArea } from '../../../components/TextArea/TextArea';
import { RestrictedButton } from '../../../components/RestrictedButton/RestrictedButton';
import HubHeader from '../../../common/hub/HubHeader';
import HubFooter from '../../../common/hub/HubFooter/HubFooter';
import BackArrow from '../../../assets/images/hubContact/icon-filled-arrow-left.svg';
import { SESSION_TIME_FORMAT } from '../../../util/constants';
import { Loader } from '../../../components/Loader';
import { submitCaseToSF } from '../../../actions/hub/hubContactPageActions';
import { useActions } from '../../../util/hooks/useActions';
import { PATH_HUB_CR_SUBMIT_CASE_SUCCESS } from '../../../util/pagePath';
import { useSelector } from 'react-redux';
import { api } from '../../../api';
import { getSpecificActiveEnrolment } from '../../../selectors/enrolmentSelector';
import { Enrolment } from '../../../api/types/enrolment';

export const FormAddSession = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const enrolmentId = query.get('token');
  const enrolment: Enrolment = useSelector(getSpecificActiveEnrolment({ enrolmentId }));
  const { data: enrolmentDetails, isLoading } = api.enrolment.detail.useQuery({
    enrolmentId,
  });
  const { studentSfid, subject, student } = enrolment;
  const { studentName, enrolmentSubject, nextSessionDate, nextSessionEndDate } = enrolmentDetails || {};
  const title = `Add a session for ${studentName}'s ${enrolmentSubject} enrolment`;
  const textAreaProps = useTextAreaProps({
    id: 'text-how-can-we-help',
    labelText: 'How can we help you?',
  });
  const history = useHistory();
  const goBack = () => history.goBack();
  // @ts-ignore
  const timezone = useSelector((state) => state.hubUser?.timezone);
  const hasNextSession = moment(nextSessionDate).isValid() && moment(nextSessionEndDate).isValid();
  const nextSessionDateString = moment(nextSessionDate).tz(timezone).format('dddd, MMMM D');
  const nextSessionStartTimeString = moment(nextSessionDate).tz(timezone).format(SESSION_TIME_FORMAT);
  const nextSessionEndTimeString = moment(nextSessionEndDate).tz(timezone).format(SESSION_TIME_FORMAT);
  const subtitleNextSession = `Next session with ${studentName}`;
  const subtitleNextSessionDetails = `${nextSessionDateString} at ${nextSessionStartTimeString} - ${nextSessionEndTimeString}`;
  const submit = useActions(submitCaseToSF);
  const onClickHandler = () => {
    const caseType = 'Scheduling';
    const caseCategory = 'Change Session Frequency';
    const caseSubCategory = 'Add session';

    submit({
      uniqueEnrolmentId: enrolmentId,
      message: `Request for ${caseCategory} due to following reason: ${caseSubCategory}. Other details: ${textAreaProps.value}`,
      caseSubject: `Contact Request - ${caseType} - ${caseCategory}`,
      caseType,
      caseCategory,
      caseSubCategory,
      onSubmitSuccess: () => {
        history.push(PATH_HUB_CR_SUBMIT_CASE_SUCCESS, {
          firstName: student,
          studentId: studentSfid,
          message: `Your request to add a session for ${student}'s ${subject} enrolment has been submitted successfully. We'll be in touch soon.`,
        });
      },
    });
  };

  // @ts-ignore
  const isSubmitting = useSelector((state) => state.ui.apiState.hubContactPage.isLoading);
  // @ts-ignore
  const submitError = useSelector((state) => state.ui.apiState.hubContactPage.fetchError);
  // @ts-ignore
  const errorMsg = useSelector((state) => state.ui.apiState.hubContactPage.errorMsg);

  if (isLoading || isSubmitting) {
    return <Loader />;
  }

  return (
    <>
      <HubHeader title="Hub" />
      <Container>
        <div className="container mx-auto flex p-0 pt-5 lg:mb-10 lg:pt-10">
          <div className="content-container no-sidenav relative mx-auto w-full">
            <ButtonAsLink className="mx-4 mb-5 p-0 lg:mx-0" onClick={goBack}>
              <img src={BackArrow} alt="back" />
            </ButtonAsLink>
            <div className="mx-4 rounded-lg bg-slate-1 px-7 py-10 text-center text-grey-6 lg:mx-0 lg:mb-4 lg:px-6 lg:py-8">
              <p className="mb-0 text-xl leading-tight">{title}</p>
              {hasNextSession && (
                <div className="mt-4 border-t border-grey-2 pt-6">
                  <p className="mb-0 text-base leading-tight">{subtitleNextSession}</p>
                  <p className="mb-0 text-base font-bold leading-tight">{subtitleNextSessionDetails}</p>
                </div>
              )}
            </div>
            <div className="contact-form__content">
              <div className="px-5 pt-5 lg:px-20 lg:pt-12">
                <FormSubHeading />
                {submitError && errorMsg && <ErrorBlock errorMsg={errorMsg} />}
                <form>
                  <TextArea {...textAreaProps} className="mb-7" />
                </form>
                <div className="mb-4 text-center tracking-normal lg:mb-8">
                  <RestrictedButton
                    buttonText="Request change"
                    truthyChecks={[textAreaProps?.value?.length > 0]}
                    onClick={() => {
                      onClickHandler();
                    }}
                  />
                </div>
                <div className="mb-4">
                  <p className="italic text-grey-5">* Adding a session requires 2 business days to process</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <HubFooter />
    </>
  );
};
