import { forwardRef, useRef, useState, ReactNode, useImperativeHandle, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { isNull } from 'lodash';
import { MathfieldElement } from 'mathlive';
import MathField from '../../../components/MathField';
import AttachmentPreview from './AttachmentPreview';
import { Tooltip } from '../../../components/Tooltip/Tooltip';
import QuestionToolbar from './QuestionToolbar';
import { SubmitButtonIcon } from './icons';

import './index.css';
import posthog from 'posthog-js';

export type CompressedImage = {
  src: string;
  blob: Blob;
};

type MathliveMenuItems = { id: string; submenu?: MathliveMenuItems[] };

function dataURLToBlob(dataURL: string): Blob {
  // Split the data URL into parts
  const parts = dataURL.split(';base64,');
  const contentType = parts[0].split(':')[1]; // Get the content type (e.g., image/jpeg)
  const byteString = atob(parts[1]); // Decode the base64 string

  // Create an array to store the byte values
  const byteNumbers = new Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    byteNumbers[i] = byteString.charCodeAt(i);
  }

  // Create a Uint8Array to represent the binary data
  const byteArray = new Uint8Array(byteNumbers);

  // Create a Blob object from the binary data
  return new Blob([byteArray], { type: contentType });
}

async function compressImage(image: File): Promise<CompressedImage> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = (event) => {
      const fileReader = event.target as FileReader;

      if (fileReader.result) {
        const img = new Image();
        img.src = fileReader.result as string;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const MAX_WIDTH = 1280;
          canvas.width = img.width;
          canvas.height = img.height;

          if (img.width > MAX_WIDTH) {
            const scaleSize = MAX_WIDTH / img.width;
            canvas.width = MAX_WIDTH;
            canvas.height = img.height * scaleSize;
          }

          const ctx = canvas.getContext('2d');
          if (ctx) {
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            const srcEncoded = ctx.canvas.toDataURL('image/jpeg', 0.5);
            const blob = dataURLToBlob(srcEncoded);
            resolve({ src: srcEncoded, blob });
          } else {
            reject('Error compressing image: canvas context not available');
          }
        };
      } else {
        reject('Error reading file');
      }
    };
  });
}

export interface QuestionInputProps {
  value: string;
  onInput: (evt: React.FormEvent<MathfieldElement>) => void;
  onSubmit?: (value: string, attachment?: CompressedImage) => void;
  onFileChanged?: (file: CompressedImage, error?: Error) => void;
  disabled?: boolean;
  placeholder?: string;
  inputRef?: React.Ref<MathfieldElement>;
  errorMessage?: ReactNode;
  helperText?: ReactNode;
  showSubmitButton?: boolean;
}

export interface QuestionInputRefProps extends Omit<QuestionInputProps, 'inputRef'> {}

export const QuestionInputRef = forwardRef<MathfieldElement, QuestionInputRefProps>(
  (
    {
      value,
      onInput,
      disabled = false,
      onSubmit,
      placeholder,
      errorMessage,
      helperText,
      onFileChanged,
      showSubmitButton = true,
    }: QuestionInputRefProps,
    ref: React.Ref<MathfieldElement>
  ) => (
    <QuestionInput
      value={value}
      onInput={onInput}
      disabled={disabled}
      onSubmit={onSubmit}
      onFileChanged={onFileChanged}
      placeholder={placeholder}
      inputRef={ref}
      errorMessage={errorMessage}
      helperText={helperText}
      showSubmitButton={showSubmitButton}
    />
  )
);

const QuestionInput = ({
  value,
  onInput,
  disabled = false,
  onSubmit,
  placeholder,
  inputRef,
  errorMessage = null,
  helperText = null,
  onFileChanged,
  showSubmitButton = true,
}: QuestionInputProps) => {
  const mathLiveRef = useRef<MathfieldElement>(null);
  const [attachment, setAttachment] = useState<File | null>(null);
  const [compressedAttachmentBlob, setCompressedAttachmentBlob] = useState<Blob | null>(null);
  const [compressedAttachmentSrc, setCompressedAttachmentSrc] = useState<string | null>(null);
  const blobURL = compressedAttachmentBlob ? URL.createObjectURL(compressedAttachmentBlob) : undefined;

  const uploadFileInputRef = useRef(null);
  const takePhotoInputRef = useRef(null);

  const handleRemoveAttachment = () => {
    if (uploadFileInputRef.current) {
      uploadFileInputRef.current.value = '';
    }
    if (takePhotoInputRef.current) {
      takePhotoInputRef.current.value = '';
    }
    setAttachment(null);
    setCompressedAttachmentBlob(null);
    setCompressedAttachmentSrc(null);
    onFileChanged(null);
  };

  const fileSizeValidation = (blob: Blob): Error | null => {
    const sizeLimit = 500 * 1024;
    if (blob.size > sizeLimit) {
      handleRemoveAttachment();
      return new Error('File size is still too big after compression. Please try a different image.');
    }
    return null;
  };

  const handleSetAttachment = async (file: File) => {
    compressImage(file).then(({ src, blob }) => {
      /* 
        src is the base64 encoded image, which is what we will use to pass as the payload for the API request 
        blob is the compressed image blob, which is what we will use to preview the image (browser security prevents using the base64 directly as the href)
      */
      setAttachment(file);
      setCompressedAttachmentSrc(src);
      setCompressedAttachmentBlob(blob);
      onFileChanged({ src, blob }, fileSizeValidation(blob));
    });
  };

  const handleSubmit = () => {
    setAttachment(null);
    onSubmit(value, { src: compressedAttachmentSrc, blob: compressedAttachmentBlob });
    setCompressedAttachmentBlob(null);
    setCompressedAttachmentSrc(null);
  };

  useImperativeHandle(inputRef, () => mathLiveRef.current);

  useEffect(() => {
    if (mathLiveRef && mathLiveRef.current) {
      if (mathLiveRef.current.addEventListener) {
        // Check if the data to pasted needed $$ wrapping or not
        mathLiveRef.current.addEventListener('paste', (event) => {
          event.preventDefault();
          const copiedText = event.clipboardData.getData('text');
          mathLiveRef.current.insert(copiedText, { mode: copiedText.startsWith('$$') ? 'math' : 'text' });
        });

        // Insert matrix with math mode and change to math mode
        mathLiveRef.current.addEventListener('beforeinput', (event) => {
          if (event.data.includes('{pmatrix}')) {
            event.preventDefault();
            mathLiveRef.current.mode = 'math';
            mathLiveRef.current.insert(event.data, { mode: 'math' });
          }
        });
      }

      if (mathLiveRef.current.menuItems) {
        // Check if added mode menu is already existing
        const newMenuExists = mathLiveRef.current.menuItems.filter((menu: MathliveMenuItems) => menu.id === 'new-mode');
        if (!newMenuExists.length) {
          const menuItems = [
            {
              id: 'new-mode',
              label: 'Mode',
              submenu: [
                {
                  id: 'new-mode-math',
                  label: 'Math',
                  onMenuSelect: () => {
                    mathLiveRef.current.executeCommand(['switchMode', 'math']);
                  },
                  checked: () => mathLiveRef.current.mode === 'math',
                },
                {
                  id: 'new-mode-text',
                  label: 'Text',
                  onMenuSelect: () => {
                    mathLiveRef.current.executeCommand(['switchMode', 'text']);
                  },
                  checked: () => mathLiveRef.current.mode === 'text',
                },
              ],
            },
            ...mathLiveRef.current.menuItems,
          ];

          mathLiveRef.current.menuItems = menuItems.filter((item: MathliveMenuItems) => {
            if (item.id) {
              // Hide font, color, background related menu items
              const textRelatedMenuId = ['background-color', 'color', 'accent', 'decoration', 'variant'];
              if (textRelatedMenuId.includes(item.id)) {
                return false;
              }
              // Hide Compute Engine related and mode menu items
              if ((item.id.startsWith('ce-'), item.id === 'mode')) {
                return false;
              }
            }

            return true;
          });
        }
      }
    }

    const hideVirtualKeyboard = () => {
      if (window.mathVirtualKeyboard.visible) {
        posthog.capture('click_virtual-keyboard-toggle', { $showKeyboard: false });
        window.mathVirtualKeyboard.hide({ animate: true });
      }
    };
    document.addEventListener('focusout', hideVirtualKeyboard);

    return () => {
      document.removeEventListener('focusout', hideVirtualKeyboard);
    };
  }, [mathLiveRef]);

  const toggleVirtualKeyboard = useCallback(() => {
    posthog.capture('click_virtual-keyboard-toggle', { $showKeyboard: !window.mathVirtualKeyboard.visible });
    if (window.mathVirtualKeyboard.visible) {
      window.mathVirtualKeyboard.hide({ animate: true });
    } else {
      mathLiveRef.current.focus();
      window.mathVirtualKeyboard.show({ animate: true });
    }
  }, []);

  const openMathLiveMenu = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    posthog.capture('click_cluey-coach_open-mathlive-menu');
    mathLiveRef.current.showMenu({
      location: {
        x: event.clientX,
        y: event.clientY,
      },
      modifiers: {
        alt: false,
        control: false,
        shift: false,
        meta: false,
      },
    });
  }, []);

  return (
    <div>
      <div
        className={classnames(
          'flex flex-col items-center rounded border border-grey-3 px-3',
          !isNull(errorMessage) ? 'border-2 border-red-5 bg-red-1' : ''
        )}
      >
        <div className="flex w-full flex-row">
          <MathField
            disabled={disabled}
            placeholder={placeholder}
            className={classnames('w-full grow outline-none', !isNull(errorMessage) ? 'bg-red-1' : '')}
            onInput={onInput}
            ref={mathLiveRef}
            onKeyDown={(event) => {
              if (showSubmitButton && event.key === 'Enter' && value) {
                handleSubmit();
              }
            }}
          >
            {value}
          </MathField>
        </div>
        <div className="container mx-auto flex items-center justify-between">
          <div className="mr-3 items-start">
            <QuestionToolbar
              disabled={disabled}
              uploadFileInputRef={uploadFileInputRef}
              takePhotoInputRef={takePhotoInputRef}
              setSelectedFile={handleSetAttachment}
              keyboardToggle={toggleVirtualKeyboard}
              menuToggle={openMathLiveMenu}
            />
          </div>
          <Tooltip
            content="Submit question"
            className={classnames('h-5 w-5 items-end', showSubmitButton ? 'visible' : 'hidden')}
          >
            <button disabled={disabled} type="button" onClick={handleSubmit} aria-label="submit question">
              <SubmitButtonIcon disabled={disabled} />
            </button>
          </Tooltip>
        </div>
      </div>
      {!isNull(errorMessage) ? errorMessage : helperText}
      {attachment && (
        <AttachmentPreview
          fileName={attachment?.name}
          fileSize={compressedAttachmentBlob?.size}
          originalFileSize={attachment?.size}
          handleRemoveAttachment={handleRemoveAttachment}
          previewHref={blobURL}
          renderPreview={!!compressedAttachmentBlob}
        />
      )}
    </div>
  );
};

export default QuestionInput;
