import { POLICY_TYPE } from '../../types/hubEnums';
import { useQuery } from '@tanstack/react-query';
import { POLICY_QKEY } from '../../util/constants/queryKeys';
import PolicyService from '../../services/PolicyService';
import type { COUNTRY_MAP } from '../../types/hubTypes';

export interface Params {
  country: COUNTRY_MAP["COUNTRY_CODE"];
  policyType: POLICY_TYPE;
}
export const useGetPolicy = ({ country, policyType }: Params) => {
  return useQuery({
    queryKey: [POLICY_QKEY, country, policyType],
    queryFn: () => PolicyService.getPolicy({ country, policyType }),
    retry: 2,
    staleTime: 300000,
  })
}
