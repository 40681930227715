import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

import InPageLoader from '../../common/InPageLoader';
import { TopicsCovered } from './TopicsCovered';
import { TopicsNotCovered } from './TopicsNotCovered';

import {
  getCompletionProgress,
  getCoveredTopics,
  getNotCoveredTopics,
  getTimeSpent,
} from '../../selectors/studentProgressSelector';
import ModalOverview from './ModalOverview';
import DataNotAvailable from './DataNotAvailable';

const ModalContent = ({
  moduleId,
  coveredTopics,
  notCoveredTopics,
  moduleTitle,
  moduleCoverage,
  coverageIcon,
  topicsCovered,
  topicsCommencedCoverage,
  graphData,
  totalTimeSpentOnModuleBreakdown,
  totalTimeSpentOnModule,
  fetchError,
}) => {
  if (fetchError) {
    return (
      <DataNotAvailable
        firstLine="We are having some trouble loading your report"
        showReloadButton
        secondLine="Please try again in a few minutes"
      />
    );
  }
  if (!moduleId || coveredTopics.length === 0) {
    return <InPageLoader bgClass="bg-white" position="relative" />;
  }

  return (
    <Fragment>
      <ModalOverview
        title={moduleTitle}
        topicsCoveredCount={topicsCovered}
        leftIcon={coverageIcon}
        moduleCoverage={moduleCoverage}
        topicsCommencedCoverage={topicsCommencedCoverage}
        graphData={graphData}
        totalTimeSpentOnModule={totalTimeSpentOnModule}
        totalTimeSpentOnModuleBreakdown={totalTimeSpentOnModuleBreakdown}
      />

      <div className="bg-slate-1 px-4 pt-6 pb-8 lg:px-16 lg:pt-14 lg:pb-16">
        <TopicsCovered topics={coveredTopics} />
        {notCoveredTopics && notCoveredTopics.length > 0 && (
          <TopicsNotCovered topics={notCoveredTopics} />
        )}
      </div>
    </Fragment>
  );
};

ModalContent.propTypes = {
  moduleId: PropTypes.number,
  moduleTitle: PropTypes.string.isRequired,
  moduleCoverage: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  coverageIcon: PropTypes.string,
  topicsCommencedCoverage: PropTypes.number.isRequired,
  coveredTopics: PropTypes.arrayOf(
    PropTypes.shape({
      topic: PropTypes.string,
      topicTotalTimeSpent: PropTypes.string,
      topicPracticeTimeSpent: PropTypes.string,
      topicSessionTimeSpent: PropTypes.string,
      topicPracticeTotalQuestionsAssigned: PropTypes.number,
      topicPracticeQuestionsSubmitted: PropTypes.number,
      topicPracticeCompletion: PropTypes.number,
      topicPracticeQuestionsCorrect: PropTypes.number,
    })
  ),
  notCoveredTopics: PropTypes.arrayOf(PropTypes.string),
  topicsCovered: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  graphData: PropTypes.any,
  totalTimeSpentOnModuleBreakdown: PropTypes.string,
  totalTimeSpentOnModule: PropTypes.string,
  fetchError: PropTypes.bool.isRequired,
};

ModalContent.defaultProps = {
  moduleId: null,
  coveredTopics: [],
  notCoveredTopics: [],
  coverageIcon: '',
  topicsCovered: null,
  graphData: null,
  totalTimeSpentOnModuleBreakdown: null,
  totalTimeSpentOnModule: null,
};

export default connect((state) => {
  const moduleId = get(state, 'studentProgress.selectedModule.id', {});
  const modules = get(state, 'studentProgress.modules.covered.modules', []);
  const selectedModule =
    modules.find(
      (m) => m.moduleId === state.studentProgress.selectedModule.id
    ) || {};
  const { coverage, star } = getCompletionProgress({
    coverage: selectedModule.moduleCoverage,
  });
  const topicsCovered = get(
    state,
    'studentProgress.selectedModule.summary.topics.covered.total',
    null
  );
  const topicsCommencedCoverage = get(
    state,
    'studentProgress.selectedModule.cumulative.topicsCommencedCoverage',
    null
  );
  const graphData = get(
    state,
    'studentProgress.selectedModule.cumulative.graph',
    null
  );
  let timeSpentBreakdown = `across ${selectedModule.numberOfSessions} ${
    selectedModule.numberOfSessions > 1 ? 'sessions' : 'session'
  }`;
  if (selectedModule.numberOfPractices > 0) {
    timeSpentBreakdown += ` and ${
      selectedModule.numberOfPractices
    } independent practice${selectedModule.numberOfPractices > 1 ? 's' : ''}`;
  }
  return {
    moduleId,
    moduleTitle: selectedModule.module,
    topicsCovered,
    topicsCommencedCoverage,
    moduleCoverage: coverage,
    coverageIcon: star,
    coveredTopics: getCoveredTopics(state),
    notCoveredTopics: getNotCoveredTopics(state),
    graphData,
    totalTimeSpentOnModuleBreakdown: timeSpentBreakdown,
    totalTimeSpentOnModule: getTimeSpent(
      selectedModule.totalTimeSpentOnModuleMins,
      'h',
      'm'
    ),
    fetchError: state.ui.apiState.studentProgress.modal.fetchError,
  };
}, null)(ModalContent);
