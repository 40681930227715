import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { Button, ChoiceLink, Modal } from '@cluey/cluey-components';

import { getOneEnrolmentIdForSF } from '../../../selectors/hubSelector';
import {
  saveSelectedEnrolment,
  saveSelectedStudent,
} from '../../../actions/hub/hubContactPageActions';
import { fetchEnrolment } from '../../../actions/hub/hubEnrolmentManagementActions';
import { RadioItemsType } from '../../../types/hubPropTypes';
import { RadioPanelGroup } from '../../RadioPanelGroup/RadioPanelGroup';
import iconProfileSrc from '../../../assets/images/icon-profile.svg';
import {
  PATH_HUB_ENROLMENT_CHANGE_SCHEDULE,
  PATH_HUB_ENROLMENT_DETAIL,
  PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP,
  PATH_HUB_CR_PERMANENT_SCHEDULE_CHANGE,
  PATH_HUB_CR_ADD_ENROLMENT
} from '../../../util/pagePath';
import { HOC_PAGE_PARAM } from '../../../util/constants';

// A custom hook to return the state pairs required for the modal and radio
export function useSelectionModalProps() {
  const [showModal, setShowModal] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState(null);
  const toggleModal = () => setShowModal(!showModal);

  return {
    showModal,
    setShowModal,
    selectedRadio,
    setSelectedRadio,
    toggleModal,
  };
}

const SelectionModal = ({
  showModal,
  toggleModal,
  redirect,
  title,
  titleClassName,
  subtitle,
  radios,
  selectedRadio,
  setSelectedRadio,
  renderBelowTitle,
  isStudentSelection,
  saveSelectedEnrolmentId,
  saveSelectedStudentName,
  fetchEnrolmentForEM,
  enrolmentIdForStudentSelection,
  page,
  showBackBtn,
  cyClassName,
}) => {
  const history = useHistory();
  const isChangeScheduleFlow =
    [
      HOC_PAGE_PARAM.CHANGE_SCHEDULE,
      HOC_PAGE_PARAM.CHANGE_GROUP_SCHEDULE,
      HOC_PAGE_PARAM.PERMANENT_SCHEDULE_CHANGE,
    ].includes(page) ||
    [
      PATH_HUB_ENROLMENT_CHANGE_SCHEDULE,
      PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP,
      PATH_HUB_CR_PERMANENT_SCHEDULE_CHANGE,
    ].includes(redirect);
  const isEnrolmentDetailPage = page === HOC_PAGE_PARAM.ENROLMENT_DETAIL;

  const onContinueClick = (e) => {
    e.preventDefault();
    if (isEnrolmentDetailPage || page === HOC_PAGE_PARAM.DEFAULT) {
      history.push(`${PATH_HUB_ENROLMENT_DETAIL}?token=${selectedRadio}`);
    }

    if (isChangeScheduleFlow) {
      const selectedEnrolment = radios.find((enrolment) => {
        return enrolment.value === selectedRadio;
      });
      const { isGroup } = selectedEnrolment;

      if (isGroup) {
        saveSelectedEnrolmentId({
          enrolmentId: selectedRadio,
        });
        // history.push(PATH_HUB_CR_PERMANENT_SCHEDULE_CHANGE);
        fetchEnrolmentForEM({
          enrolmentId: selectedRadio,
        });
        history.push(PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP);
      } else {
        fetchEnrolmentForEM({ enrolmentId: selectedRadio });
        history.push(PATH_HUB_ENROLMENT_CHANGE_SCHEDULE);
      }
      return;
    }

    if (isStudentSelection) {
      saveSelectedStudentName({
        student: selectedRadio,
      });
      saveSelectedEnrolmentId({
        enrolmentId: enrolmentIdForStudentSelection,
      });
    } else {
      saveSelectedEnrolmentId({
        enrolmentId: selectedRadio,
      });
    }

    if (redirect != null) {
      history.push(`${redirect}?token=${selectedRadio}`);
    }
  };

  const titleclasses =
    titleClassName || 'font-body font-bold text-lg lg:text-2xl mb-2 lg:mb-4';

  return (
    <Modal open={showModal} setOpen={toggleModal}>
      <section
        className={`flex flex-col items-center overflow-y-auto bg-white p-4 lg:flex-row lg:items-start lg:py-8 lg:pl-6 lg:pr-24 ${cyClassName}`}
      >
        <div className="lg:mr-5">
          <div className="rounded-full bg-slate-2 p-5">
            <img src={iconProfileSrc} alt="profile" />
          </div>
        </div>
        <div className="w-full lg:mt-4">
          <div className="my-4 text-center lg:mt-0 lg:mb-10 lg:text-left">
            <h3 className={titleclasses}>{title}</h3>
            {typeof renderBelowTitle === 'function' && renderBelowTitle()}
            {subtitle && <p className="mb-4 text-lg font-bold">{subtitle}</p>}
          </div>
          <RadioPanelGroup
            groupName={uuid()}
            radios={radios}
            selectedRadio={selectedRadio}
            setSelectedRadio={setSelectedRadio}
          />
          <ChoiceLink className='mt-4' to={PATH_HUB_CR_ADD_ENROLMENT}>
            <div className='text-lg'>Add a new enrolment</div>
          </ChoiceLink>
          <section className="mt-5 flex flex-col items-center justify-center gap-3 lg:flex-row">
            <Button
              size="xs"
              onClick={onContinueClick}
              className="w-full max-w-[176px]"
              disabled={selectedRadio === null}
            >
              CONTINUE
            </Button>
            {showBackBtn && (
              <Button
                size="xs"
                appearance="reverse"
                className="w-full max-w-[176px]"
                onClick={toggleModal}
              >
                BACK
              </Button>
            )}
          </section>
        </div>
      </section>
    </Modal>
  );
};
SelectionModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  redirect: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleClassName: PropTypes.string,
  subtitle: PropTypes.string,
  selectedRadio: PropTypes.string,
  setSelectedRadio: PropTypes.func.isRequired,
  radios: RadioItemsType,
  renderBelowTitle: PropTypes.func,
  isStudentSelection: PropTypes.bool,
  saveSelectedEnrolmentId: PropTypes.func.isRequired,
  saveSelectedStudentName: PropTypes.func.isRequired,
  fetchEnrolmentForEM: PropTypes.func.isRequired,
  enrolmentIdForStudentSelection: PropTypes.string.isRequired,
  page: PropTypes.string,
  showBackBtn: PropTypes.bool,
  cyClassName: PropTypes.string,
};

SelectionModal.defaultProps = {
  titleClassName: null,
  subtitle: null,
  selectedRadio: null,
  radios: [],
  renderBelowTitle: null,
  isStudentSelection: false,
  redirect: null,
  page: HOC_PAGE_PARAM.DEFAULT,
  showBackBtn: false,
  cyClassName: null,
};

export default connect(
  (state) => {
    return {
      enrolmentIdForStudentSelection: getOneEnrolmentIdForSF(state),
    };
  },
  (dispatch) => {
    return {
      saveSelectedEnrolmentId: bindActionCreators(
        saveSelectedEnrolment,
        dispatch
      ),
      saveSelectedStudentName: bindActionCreators(
        saveSelectedStudent,
        dispatch
      ),
      fetchEnrolmentForEM: bindActionCreators(fetchEnrolment, dispatch),
    };
  }
)(SelectionModal);
