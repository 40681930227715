import { isEmpty, sortBy } from 'lodash';
import { useEffect } from 'react';
import moment from 'moment';
import { generatePath, useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import {
    getActiveEnrolments,
    getTimezone,
    getUpcomingSessions,
} from '../../../selectors/hubSelector';
import { useEnrolmentDetailQuery } from '../../../hooks/queries/useEnrolmentQuery';
import {
    PATH_HUB_HOME,
    PATH_HUB_SELECT_SESSION,
    PATH_HUB_SKIP_SESSION,
    PATH_HUB_UPCOMING_SESSIONS,
} from '../../../util/pagePath';
import { SCHEDULE_DATE_FORMAT } from '../../../util/constants';
import { useGetAvailableSlotsForSession } from '../../../hooks/queries/useGetAvailableSlotsForSession';

// deprecated - keeping this for now to use as reference in case something goes wrong with the new one
export const useRescheduleSessionState_deprecated = (tutorInitiated = false) => {
	const history = useHistory();
	const { sessionId } = useParams();
	const upcomingSessions = useSelector(getUpcomingSessions);

	const session = upcomingSessions.find((upcomingSession) => upcomingSession.studentSessionId === sessionId);
	const activeEnrolments = useSelector(getActiveEnrolments);
	const enrolment = activeEnrolments.find(
		(activeEnrolment) => activeEnrolment.enrolmentId === session?.uniqueEnrolmentId
	);
	const enrolmentDetails = useSelector((state) => state.hubEnrolmentDetailPage);
	const tutorName = tutorInitiated ? session?.tutor : enrolmentDetails?.tutorName;
	const tutorPhoto = tutorInitiated ? session?.tutorPhoto : enrolmentDetails?.tutorPhoto;
	const { enrolmentSchedule } = enrolmentDetails;
	const newScheduleTutor = useSelector((state) => state.hubEnrolmentDetailPage.tempTutorChange);
	const {
		isError: isLoadingAvailableSlotsError,
		isLoading: isLoadingAvailableSlots,
		data,
	} = useGetAvailableSlotsForSession(session?.tutorSessionId, enrolment?.packageSfid, tutorInitiated);

	const availableTimeslots = isEmpty(data)
		? []
		: sortBy(
				data.map((availability) => {
					/* CRE-377: as per Alexis, the API returns only the best matching tutor for now. That might change in
            the future (there could be more than 1 tutor available for a slot)
            */
					const tutor = availability.tutorsAvailable[0];

					const { matchingRank = '', tutorId = '', tutorName: timeslotTutorName = '', tutorNumber } = tutor;

					const [firstname] = timeslotTutorName?.split(' ');
					const start = moment.utc(availability.start);
					const end = moment.utc(availability.end);
					return {
						firstname,
						scheduleDate: moment.utc(availability.start).format(SCHEDULE_DATE_FORMAT).toUpperCase(),
						sessionDuration: end.diff(start, 'minutes'),
						tutorId,
						tutorNumber,
						tutorOrder: String(matchingRank),
						startDate: start,
						endDate: end,
						tutorsAvailable: availability.tutorsAvailable,
					};
				}),
				'sessionDate'
		  );
	const timezone = useSelector(getTimezone);
	const sectionHeading = `${session?.student} ${session?.subject}`;
	const sessionSchedule = moment(session?.startTime).utc().format();
	const now = moment();
	const daysDiff = moment(session?.startTime).diff(now, 'days');
	const oneWeekAway = daysDiff > 6;
	const rescheduleOptionsStart = oneWeekAway
		? moment(session?.startTime).subtract(6, 'days').utc().format()
		: now.utc().format();
	const enrolmentPath = enrolmentDetails?.enrolmentId
		? generatePath(PATH_HUB_SELECT_SESSION, { enrolmentId: enrolmentDetails?.enrolmentId })
		: '';
	const navigateToEnrolment = () => {
		if (enrolmentPath) {
			history.push(enrolmentPath);
		}
	};
	const pathToSkipSession = generatePath(PATH_HUB_SKIP_SESSION, { sessionId });

	const sessionRescheduleRequestStatus = session?.tutorRescheduleRequestStatusC || session?.rescheduleRequestStatus;
	const isTemporaryTutor = session?.tutorSfId !== session?.packageTutorSfid;
	const isRescheduled =
		sessionRescheduleRequestStatus === 'Approved' || (sessionRescheduleRequestStatus === null && isTemporaryTutor);

	useEffect(() => {
		if (!session) {
			history.push(PATH_HUB_HOME);
		} else if (tutorInitiated && (isRescheduled || isTemporaryTutor)) {
			history.push(PATH_HUB_UPCOMING_SESSIONS);
		}
	}, [session, history, tutorInitiated, isRescheduled, isTemporaryTutor]);

	return {
		enrolment,
		enrolmentDetails,
		enrolmentSchedule,
		newScheduleTutor,
		tutorName,
		tutorPhoto,
		sectionHeading,
		timezone,
		navigateToEnrolment,
		enrolmentPath,
		session,
		sessionSchedule,
		rescheduleOptionsStart,
		pathToSkipSession,
		isLoadingAvailableSlots,
		isLoadingAvailableSlotsError,
		availableTimeslots,
	};
};

export const getUpcomingDaysRange = ({ start, end }) => {
	const upcomingDays = [];
	const startingDay = start ? moment(start).utc() : moment();
	const rangeStart = moment(start).startOf('day');
	const rangeEnd = moment(end).endOf('day');
	const range = moment(rangeEnd).diff(rangeStart, 'days');

	if (moment(start).isValid() && moment(end).isValid()) {
		for (let i = 0; i <= range; i++) {
			if (i === range) {
				upcomingDays.push(rangeEnd.utc().format());
			} else {
				const day = moment(startingDay).add(i, 'days');
				upcomingDays.push(day.utc().format());
			}
		}
	}

	return upcomingDays;
};

export const useRescheduleSessionState = (tutorInitiated = false) => {
	const history = useHistory();
	const { sessionId } = useParams();
	const upcomingSessions = useSelector(getUpcomingSessions);

	const session = upcomingSessions.find((upcomingSession) => upcomingSession.studentSessionId === sessionId);
	const activeEnrolments = useSelector(getActiveEnrolments);
	const enrolment = activeEnrolments.find(
		(activeEnrolment) => activeEnrolment.enrolmentId === session?.uniqueEnrolmentId
	);
	const timezone = useSelector(getTimezone);
	const {
		isError: isLoadingAvailableSlotsError,
		isLoading: isLoadingAvailableSlots,
		data,
		isFetched,
		isFetchedAfterMount,
	} = useGetAvailableSlotsForSession(
		session?.tutorSessionId,
		enrolment?.packageSfid,
		tutorInitiated,
		session?.tutorSfId
	);
	const { isLoading: isLoadingEnrolmentDetails, data: enrolmentDetails } = useEnrolmentDetailQuery({
		enrolmentId: enrolment?.enrolmentId || '',
	});

	const sessionRescheduleRequestStatus = session?.tutorRescheduleRequestStatusC || session?.rescheduleRequestStatus;
	const isTemporaryTutor = session?.tutorSfId !== session?.packageTutorSfid;
	const isRescheduled =
		sessionRescheduleRequestStatus === 'Approved' || (sessionRescheduleRequestStatus === null && isTemporaryTutor);
	useEffect(() => {
		if (!session) {
			history.push(PATH_HUB_HOME);
		} else if (tutorInitiated && (isRescheduled || isTemporaryTutor)) {
			history.push(PATH_HUB_UPCOMING_SESSIONS);
		}
	}, [session, history, tutorInitiated, isRescheduled, isTemporaryTutor]);

	const newScheduleTutor = useSelector((state) => state.hubEnrolmentDetailPage.tempTutorChange);


	if (isLoadingEnrolmentDetails || !enrolmentDetails) {
		return {
			enrolment,
			enrolmentDetails,
			isLoadingEnrolmentDetails,
			enrolmentSchedule: enrolmentDetails?.enrolmentSchedule,
			newScheduleTutor: undefined,
			tutorName: undefined,
			tutorPhoto: undefined,
			sectionHeading: undefined,
			timezone,
			navigateToEnrolment: undefined,
			enrolmentPath: undefined,
			session,
			sessionSchedule: undefined,
			rescheduleOptionsStart: undefined,
			pathToSkipSession: undefined,
			isLoadingAvailableSlots,
			isLoadingAvailableSlotsError,
			availableTimeslots: undefined,
			isFetched,
			isFetchedAfterMount,
			upcomingDays: [],
		};
	}

	const tutorName = session?.tutor || enrolmentDetails?.tutorName;
	const tutorPhoto = session?.tutorPhoto || enrolmentDetails?.tutorPhoto;

	const availableTimeslots = isEmpty(data)
		? []
		: sortBy(
				data.map((availability) => {
					/* CRE-377: as per Alexis, the API returns only the best matching tutor for now. That might change in
            the future (there could be more than 1 tutor available for a slot)
            */
					const tutor = availability.tutorsAvailable[0] ?? {};

					const { matchingRank = '', tutorId = '', tutorName: timeslotTutorName = '', tutorNumber } = tutor ?? {};
					const [firstname] = timeslotTutorName?.split(' ');
					const start = moment.utc(availability.start);
					const end = moment.utc(availability.end);
					return {
						firstname,
						scheduleDate: moment.utc(availability.start).format(SCHEDULE_DATE_FORMAT).toUpperCase(),
						sessionDuration: end.diff(start, 'minutes'),
						tutorId,
						tutorNumber,
						tutorOrder: String(matchingRank),
						startDate: start,
						endDate: end,
						tutorsAvailable: availability.tutorsAvailable,
					};
				}),
				'sessionDate'
		  );

	const sectionHeading = `${session?.student} ${session?.subject}`;
	const sessionSchedule = moment(session?.startTime).utc().format();
	const now = moment();
	const daysDiff = moment(session?.startTime).diff(now, 'days');
	const oneWeekAway = daysDiff > 6;
	const rescheduleOptionsStart = oneWeekAway
		? moment(session?.startTime).subtract(6, 'days').utc().format()
		: now.utc().format();
	const enrolmentPath = enrolmentDetails?.enrolmentId
		? generatePath(PATH_HUB_SELECT_SESSION, { enrolmentId: enrolmentDetails?.enrolmentId })
		: '';
	const navigateToEnrolment = () => {
		if (enrolmentPath) {
			history.push(enrolmentPath);
		}
	};
	const pathToSkipSession = generatePath(PATH_HUB_SKIP_SESSION, { sessionId });
	const start = rescheduleOptionsStart;
	const end = moment(sessionSchedule).add(6, 'days').utc().format();
	const upcomingDaysRange = getUpcomingDaysRange({ start, end });
	const upcomingDaysRangeStart = upcomingDaysRange[0];
	const upcomingDaysRangeEnd = upcomingDaysRange[upcomingDaysRange.length - 1];
	const availabilitiesForCurrentTutor = (availableTimeslots || []).filter((a) => {
		return (
			a.tutorId === enrolment?.tutorSfId &&
			moment(a.startDate).isBetween(upcomingDaysRangeStart, upcomingDaysRangeEnd, 'day', '[]')
		);
	});
	const currentTutorHasAvailableTimeslots = availabilitiesForCurrentTutor.length > 0;


	return {
		enrolment,
		enrolmentDetails,
		enrolmentSchedule: enrolmentDetails?.enrolmentSchedule,
		newScheduleTutor,
		tutorName,
		tutorPhoto,
		sectionHeading,
		timezone,
		navigateToEnrolment,
		enrolmentPath,
		session,
		sessionSchedule,
		rescheduleOptionsStart,
		pathToSkipSession,
		isLoadingAvailableSlots,
		isLoadingAvailableSlotsError,
		availableTimeslots,
		currentTutorHasAvailableTimeslots,
		sessionRescheduleRequestStatus,
		isFetched,
		isFetchedAfterMount,
	};
};