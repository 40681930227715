import { useEffect } from 'react';
import { Button, Container } from '@cluey/cluey-components';

import withHeadTag from '../../../common/HeadComponent';
import HubHeader from '../../../common/hub/HubHeader';
import HubFooter from '../../../common/hub/HubFooter/HubFooter';
import { scrollToTop } from '../../../util/helpers';

import { PATH_HUB_HOME, PATH_HUB_STUDENT_PROFILE } from '../../../util/pagePath';
import { CheckIcon } from '../../../icons';
import { generatePath, useHistory } from 'react-router';

type HistoryState = {
  firstName: string;
  studentId: string;
  header?: string;
  message: string;
};

const HubContactSuccessPage = () => {
  const history = useHistory<HistoryState>();
  const { firstName, studentId, header, message } = history.location.state ?? {};

  useEffect(() => {
    scrollToTop();
  }, []);

  const onGoToProfileClick = () => {
    if (studentId) {
      const profilePath = generatePath(PATH_HUB_STUDENT_PROFILE, { studentSfid: studentId });
      history.push(profilePath);
    } else {
      history.push(PATH_HUB_HOME);
    }
  };

  return (
    <div className="hub flex h-full flex-col justify-between">
      <div>
        <HubHeader title="Hub" />
        <Container className="flex flex-col items-center gap-6 px-4 py-7 md:py-8 lg:mb-0">
          <div className="mt-10 flex flex-col items-center gap-2">
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-green-4">
              <CheckIcon className="text-white" width={30} height={30} />
            </div>
            <h1 className="text-xl font-bold">{header || 'Request submitted'}</h1>
          </div>
          <p className="px-10 text-center">{message}</p>
          <Button onClick={onGoToProfileClick} size="lg">
            Go {!!studentId ? `to ${firstName}'s profile` : 'Home'}
          </Button>
        </Container>
      </div>
      <HubFooter />
    </div>
  );
};

export default withHeadTag(HubContactSuccessPage);
export { HubContactSuccessPage as StatelessHubContactSuccessPage };
