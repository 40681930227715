interface Props {
  text: string;
  children?: JSX.Element | Array<JSX.Element>;
}

const ListItem = ({ text, children }: Props) => {
  return (
    <li className="ls-normal mb-2">
      <div className="mb-2">{text}</div>
      {children}
    </li>
  )
}

export default ListItem;