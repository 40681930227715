import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, LinkButton, ButtonAsLink } from '@cluey/cluey-components';
import { get } from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { useCheckboxProps, Checkbox } from '../Checkbox/Checkbox';
import { submitCaseToSF } from '../../actions/hub/hubContactPageActions';
import { saveEnrolmentIdForEnrolmentManagement } from '../../actions/hub/hubEnrolmentManagementActions';
import { isStaffCheck } from '../../selectors/loginSelector';

import { PATH_HUB_ENROLMENT_DETAIL } from '../../util/pagePath';
import optOutYear35 from '../../assets/images/people/opt-out-year-3-5.png';
import optOutYear79 from '../../assets/images/people/opt-out-year-7-9.png';

const NAPLANOptOut = ({
	student,
	enrolmentId,
	yearAsInteger,
	optNAPLAN,
	optOutNAPLAN,
	isSubmitting,
	submitError,
	errorMsg,
	isStaff,
	forceToReloadEnrolmentDetail,
}) => {
	const [error, setError] = useState('');
	const [optOutSucceed, setOptOutSucceed] = useState(false);
	const checkboxProps = useCheckboxProps({
		id: 'confirm to opt out NAPLAN preparation',
		labelText: `Yes, I’d prefer to continue focusing on ${student}’s existing areas of learning focus`,
	});
	const chooseToOptOut = checkboxProps.selectedCKB;
	const history = useHistory();

	useEffect(() => {
		if (chooseToOptOut) {
			setError('');
			checkboxProps.setHighlight(false);
		}
	}, [chooseToOptOut, checkboxProps]);

	useEffect(() => {
		if (submitError) {
			setError(errorMsg);
		}
	}, [submitError, errorMsg]);

	const optOutImg = yearAsInteger < 7 ? optOutYear35 : optOutYear79;

	const onOptOutHandler = () => {
		if (isStaff) {
			return;
		}

		if (!chooseToOptOut) {
			setError('Please confirm you want to SKIP NAPLAN prep');
			checkboxProps.setHighlight(true);
			return;
		}

		optOutNAPLAN({
			action: 'Move Enrolment',
			uniqueEnrolmentId: enrolmentId,
			startDate: moment().format('YYYY-MM-DD'),
			endDate: moment().format('YYYY-MM-DD'),
			onSubmitSuccess: () => {
				setOptOutSucceed(true);
				forceToReloadEnrolmentDetail({ enrolmentId: '' });
			},
		});
	};
	if (optNAPLAN || optOutSucceed) {
		return (
			<div className="bg-slate-1 pt-[43px] lg:pt-[67px]">
				<div className="container mx-auto">
					<div className="flex flex-col lg:flex-row">
						<div className="w-full lg:w-7/12">
							<h2 className="mb-4 font-display text-2xl font-bold leading-[1.17] lg:mb-2">
								NAPLAN preparation removed
							</h2>
							<p className="mb-4 text-xl leading-[1.2] lg:mb-5">
								{student}’s learning program will continue to focus on specific areas of need.
							</p>
							<p className="mb-4 text-xl leading-[1.2] lg:mb-5">
								If you change your mind, you can contact us at any time to discuss NAPLAN prep options.
							</p>
							<div className="mb-5 flex flex-col items-center lg:mb-20 lg:flex-row">
								<LinkButton
									to={`${PATH_HUB_ENROLMENT_DETAIL}?token=${enrolmentId}`}
									size="xs"
									appearance="reverse"
									className="uppercase"
								>
									{student}’S MATHEMATICS ENROLMENT
								</LinkButton>
							</div>
						</div>
						<div className="lg:ml-1/12 flex w-full">
							<div className="ml-auto h-[244px] w-[174px] lg:ml-0 lg:mt-auto lg:h-[230px] lg:w-[320px]">
								<img src={optOutImg} alt="student" className="w-[inherit] object-contain" />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="bg-slate-1 pt-[43px] lg:pt-[67px]">
			<div className="container mx-auto px-4">
				<div className="flex flex-col lg:flex-row">
					<div className="w-full lg:w-7/12">
						<h2 className="mb-lg-3 mb-5 font-display text-2xl font-bold leading-[1.17]">
							Prefer to skip NAPLAN preparation?
						</h2>
						<p className="mb-4 text-xl leading-[1.2] lg:mb-5">
							We can continue to focus on {student}’s specific areas of focus rather than transition to NAPLAN modules.
						</p>
						<div className="mb-6">
							<Checkbox {...checkboxProps} />
							{error && <p className="mb-0 ml-10 mt-2 text-xs font-medium text-red-5">{error}</p>}
						</div>
						<div className="mb-5 flex flex-col items-center lg:mb-20 lg:flex-row">
							<Button
								size="lg"
								className="mb-5 w-full lg:mb-0 lg:mr-5 lg:w-auto"
								onClick={onOptOutHandler}
								disabled={isSubmitting || isStaff}
							>
								Skip NAPLAN prep
							</Button>
							<ButtonAsLink size="sm" onClick={() => history.goBack()}>
								I&apos;d like to keep NAPLAN prep
							</ButtonAsLink>
						</div>
					</div>
					<div className="offset-lg-1 flex w-full">
						<div className="ml-lg-0 mt-lg-auto ml-auto h-[244px] w-[174px] lg:h-[230px] lg:w-[320px]">
							<img src={optOutImg} alt="student" className="w-[inherit] object-contain" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

NAPLANOptOut.propTypes = {
	student: PropTypes.string.isRequired,
	enrolmentId: PropTypes.string.isRequired,
	yearAsInteger: PropTypes.number.isRequired,
	optNAPLAN: PropTypes.bool.isRequired,
	optOutNAPLAN: PropTypes.func.isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	submitError: PropTypes.bool.isRequired,
	errorMsg: PropTypes.string.isRequired,
	isStaff: PropTypes.bool.isRequired,
	forceToReloadEnrolmentDetail: PropTypes.func.isRequired,
};

export default connect(
	(state) => {
		const uiState = get(state, 'ui.apiState.hubContactPage', {});
		const { isLoading, fetchError, errorMsg } = uiState;
		return {
			isSubmitting: isLoading,
			submitError: fetchError,
			errorMsg,
			isStaff: isStaffCheck(state),
		};
	},
	(dispatch) => {
		return {
			optOutNAPLAN: bindActionCreators(submitCaseToSF, dispatch),
			forceToReloadEnrolmentDetail: bindActionCreators(saveEnrolmentIdForEnrolmentManagement, dispatch),
		};
	}
)(NAPLANOptOut);

export { NAPLANOptOut as StatelessNAPLANOptOut };
