import moment from 'moment';
import { Container, Link } from '@cluey/cluey-components';
import loudspeakerIcon from '../../../../../assets/images/loudspeaker-icon 2.svg';
import { Article, getArticleById } from '../articles';
import ArticleLayout from '../ArticleLayout';
import { PATH_HUB_SUPPORT, PATH_HUB_UPDATES } from '../../../../../util/pagePath';

/* Where do I access this feature images */
import AccessSnapshotManager from '../../../../../assets/images/newHubComms/upcomingSessionMessage/Where-to-access-feature-1.png';
import AccessStudentProfile from '../../../../../assets/images/newHubComms/upcomingSessionMessage/Where-to-access-feature-2.png';
import AccessSessionsManage from '../../../../../assets/images/newHubComms/upcomingSessionMessage/Where-to-access-feature-3.png';
/* How to leave a message for the tutor images */
import PopupLetTutorKnow from '../../../../../assets/images/newHubComms/upcomingSessionMessage/How-to-leave-message-1.png';
import PopupNatureOfMessage from '../../../../../assets/images/newHubComms/upcomingSessionMessage/How-to-leave-message-2.png';
/* What your message is about */
import PopupMessageSpecific from '../../../../../assets/images/newHubComms/upcomingSessionMessage/What-is-message-about-1.png';
import PopupMessageFeeling from '../../../../../assets/images/newHubComms/upcomingSessionMessage/What-is-message-about-2.png';
import PopupMessageOther from '../../../../../assets/images/newHubComms/upcomingSessionMessage/What-is-message-about-3.png';

export interface SessionMessageProps {
	data: Article;
}

const UpcomingSessionMessageBase = ({ data }: SessionMessageProps) => {
	return (
		<ArticleLayout
			title={data.name}
			renderBreadcrumbs={
				<>
					<div className="mb-2">
						<Link className="text-xs font-bold uppercase" to={PATH_HUB_SUPPORT}>
							Support
						</Link>
						<span className="mx-2 text-xs font-bold">{'>'}</span>
						<Link className="text-xs font-bold uppercase" to={PATH_HUB_UPDATES}>
							Customer Hub updates
						</Link>
					</div>
				</>
			}
		>
			<Container className="px-4 pb-7 md:pb-8 lg:mb-0">
				<div className="flex flex-row items-center pb-10">
					<img className="mr-10" alt="Announcement icon" src={loudspeakerIcon} />
					<div className="sm:w-1/2">
						<div className="mb-2 text-sm">{moment(data.publishedAt).format('DD MMM YYYY')}</div>
						<b>{data.description}</b>
					</div>
				</div>
				<section className="my-10 rounded-lg border border-grey-3 p-10">
					<b className="">Page content</b>
					<ul className="ml-8 mt-5 list-disc">
						<li>
							<a href="#leave-message-tutor" className="underline underline-offset-4 hover:text-blue-5">
								Leave a message for the tutor
							</a>
						</li>
						<li>
							<a href="#where-to-access-feature" className="underline underline-offset-4 hover:text-blue-5">
								Where do I access this feature?
							</a>
						</li>
						<li>
							<a href="#how-to-leave-message" className="underline underline-offset-4 hover:text-blue-5">
								How do I leave a message for the tutor?
							</a>
						</li>
						<li>
							<a href="#message-about" className="underline underline-offset-4 hover:text-blue-5">
								What is your message about?
							</a>
							<ul className="ml-8 list-disc">
								<li>
									<a href="#message-about-topic" className="underline underline-offset-4 hover:text-blue-5">
										Request specific content or topic
									</a>
								</li>
								<li>
									<a href="#message-about-feeling" className="underline underline-offset-4 hover:text-blue-5">
										Provide an update on how the student is feeling
									</a>
								</li>
								<li>
									<a href="#message-about-other" className="underline underline-offset-4 hover:text-blue-5">
										Other
									</a>
								</li>
							</ul>
						</li>
					</ul>
					<span id="leave-message-tutor" />
				</section>
				<section className="pt-3 md:pt-20">
					<h2 className="mb-6 font-display text-[24px] font-bold">Leave a message for the tutor</h2>
					<div className="md:w-2/3">
						<div className="mb-2.5">
							You can now leave a message for the tutor for any enrolment as long as the next session has been
							scheduled. You can request specific content or topics be covered in the session, or let the tutor know how
							the student is feeling.
						</div>
						<div className="mb-2.5">
							Your messages will be displayed to the tutor attending the next session, even if there's been a tutor
							change, or if the session was rescheduled or canceled.
						</div>
						<div className="mb-2.5">
							Messages will only be displayed to the tutor attending the next session and not for future sessions.
						</div>
					</div>
					<br />
				</section>
			</Container>
			<Container className="px-4 pb-7 pt-3 md:pb-8 md:pt-20 lg:mb-0">
				<h2 id="where-to-access-feature" className="mb-6 font-display text-[24px] font-bold">
					Where do I access this feature?
				</h2>
				<div className="my-6 md:w-2/3">
					<div className="mb-2.5">
						Once logged into the Customer Hub, you can access the feature to leave a message for the tutor directly from
						the home screen.
					</div>
					<div>
						On the home screen, in the Snapshot's 'Upcoming sessions' section, select 'Manage' for any upcoming session.
					</div>
				</div>
				<img
					src={AccessSnapshotManager}
					className="rounded-lg shadow"
					alt="From the home screen, select 'Manage' for any upcoming sessions."
				/>
				<div className="mt-3 text-center text-sm">From the home screen, select 'Manage' for any upcoming sessions.</div>
				<br />
				<div className="my-6 md:w-2/3">
					<div className="mb-2.5">
						You can also access the feature to leave a message for the tutor directly from a student's profile page
						while managing the student's enrolment details and learning profile.
					</div>
					<div>
						From the home screen, select the 'See profile' button on the student to access their student profile page.
					</div>
				</div>
				<br />
				<img
					src={AccessStudentProfile}
					className="rounded-lg shadow"
					alt="From the home screen, select 'See profile'."
				/>
				<div className="mt-3 text-center text-sm">From the home screen, select 'See profile'.</div>
				<br />
				<div className="my-6 md:w-2/3">
					<div>
						Once on the student profile page, select 'Manage' for the upcoming session that you want to leave the
						message for the tutor.
					</div>
				</div>
				<br />
				<img
					src={AccessSessionsManage}
					className="rounded-lg shadow"
					alt="On the student profile page, select 'Manage' for any upcoming sessions."
				/>
				<div className="mt-3 text-center text-sm">
					On the student profile page, select 'Manage' for any upcoming sessions.
				</div>
				<br />
			</Container>
			<Container className="px-4 pb-7 pt-3 md:pb-8 md:pt-20 lg:mb-0">
				<h2 id="how-to-leave-message" className="mb-6 font-display text-[24px] font-bold">
					How do I leave a message for the tutor?
				</h2>
				<div className="my-6 md:w-2/3">
					<div>
						After selecting 'Manage', select the new option 'Let the tutor know something about this session' from the
						'Manage session' pop-up.
					</div>
				</div>
				<br />
				<img
					src={PopupLetTutorKnow}
					className="rounded-lg shadow"
					alt="A new option 'Let the tutor know something about this session' will be available in the 'Manage session'
					pop-up."
				/>
				<div className="mt-3 text-center text-sm">
					A new option 'Let the tutor know something about this session' will be available in the 'Manage session'
					pop-up.
				</div>
				<br />
				<div className="my-6 md:w-2/3">
					<div>
						A new page will load with the same title, where you will be asked a few questions to understand the nature
						of your message.
					</div>
				</div>
				<br />
				<img
					src={PopupNatureOfMessage}
					className="rounded-lg shadow"
					alt="You will be asked a few questions to understand the nature of your message."
				/>
				<div className="mt-3 text-center text-sm">
					You will be asked a few questions to understand the nature of your message.
				</div>
				<br />
			</Container>
			<Container className="px-4 pb-7 pt-3 md:pb-8 md:pt-20 lg:mb-0">
				<h2 id="message-about" className="mb-6 font-display text-[24px] font-bold">
					What is your message about?
				</h2>
				<div id="message-about-topic" className="mb-14">
					<h3 className="mb-4 font-display text-[18px] font-bold">Request specific content or topic</h3>
					<div className="mb-6 md:w-2/3">
						<div className="mb-2.5">This option is intended for specific content or topic requests for the tutor.</div>
						<div className="mb-2.5">
							Please note attachments cannot be uploaded with your message, but can be uploaded and shared directly with
							the tutor during the session.
						</div>
						<div className="mb-2.5">Your message will be displayed to the tutor before they join the next session.</div>
					</div>
					<br />
					<img
						src={PopupMessageSpecific}
						className="rounded-lg shadow"
						alt="Leave a message to let the tutor know about specific content or topic requests."
					/>
					<div className="mt-3 text-center text-sm">
						Leave a message to let the tutor know about specific content or topic requests.
					</div>
				</div>
				<div id="message-about-feeling" className="mb-14">
					<h3 className="mb-4 font-display text-[18px] font-bold">Provide an update on how the student is feeling</h3>
					<div className="mb-6 md:w-2/3">
						<div className="mb-2.5">
							This option can be used for both academic and non-academic related information regarding how the student
							is feeling that you want to let tutor know.
						</div>
						<div className="mb-2.5">Your message will be displayed to the tutor before they join the next session.</div>
					</div>
					<br />
					<img
						src={PopupMessageFeeling}
						className="rounded-lg shadow"
						alt="Leave a message to let the tutor know how the student is feeling."
					/>
					<div className="mt-3 text-center text-sm">
						Leave a message to let the tutor know how the student is feeling.
					</div>
				</div>
				<div id="message-about-other" className="mb-14">
					<h3 className="mb-4 font-display text-[18px] font-bold">Other</h3>
					<div className="mb-6 md:w-2/3">
						<div className="mb-2.5">
							This option is for any other types of messages you may want to share with the tutor.
						</div>
						<div className="mb-2.5">Your message will be displayed to the tutor before they join the next session.</div>
					</div>
					<br />
					<img
						src={PopupMessageOther}
						className="rounded-lg shadow"
						alt="Leave a message for the tutor for the next session."
					/>
					<div className="mt-3 text-center text-sm">Leave a message for the tutor for the next session.</div>
				</div>
			</Container>
		</ArticleLayout>
	);
};

const UpcomingSessionMessage = () => {
	return <UpcomingSessionMessageBase data={getArticleById('upcoming-session-message')} />;
};

export default UpcomingSessionMessage;
