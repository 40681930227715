import { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';

export type TMessage = {
  id: string;
  timestamp: string;
  sender: 'user' | 'tutor';
  content: string;
  attachment?: string;
};

export type TNewMessage = Omit<TMessage, 'id' | 'timestamp'> & {
  timestamp?: string;
};

function mapDefaultMessages(defaultMessages: TNewMessage[]) {
  return defaultMessages.map((message) => ({
    ...message,
    timestamp: message.timestamp || new Date().toISOString(),
    id: uuid(),
  }));
}

export default function useMessageStore(defaultMessages: TNewMessage[] = []) {
  const [messages, setMessages] = useState<TMessage[]>(mapDefaultMessages(defaultMessages));

  const addMessage = useCallback((newMessage: TNewMessage) => {
    const id = uuid();
    const message: TMessage = {
      id,
      timestamp: newMessage.timestamp || new Date().toISOString(),
      ...newMessage,
    };

    setMessages((prevMessages) => [...prevMessages, message]);

    return message;
  }, []);

  const updateMessage = useCallback((id: string, content: string) => {
    setMessages((prevMessages) =>
      prevMessages.map((message) => (message.id === id ? { ...message, content } : message))
    );
  }, []);

  const removeMessage = useCallback((id: string) => {
    setMessages((prevMessages) => prevMessages.filter((message) => message.id !== id));
  }, []);

  const clearMessages = useCallback(() => {
    setMessages([]);
  }, []);

  return { messages, addMessage, updateMessage, clearMessages, removeMessage };
}
