import initialState from './initialState';
import {
  HUB_CR_SAVE_SELECTED_ENROLMENT,
  HUB_CR_SAVE_SELECTED_SESSION,
  HUB_CR_SAVE_SELECTED_STUDENT,
  HUB_CR_PS_FETCH_RESUME_SESSION_SUCCESS,
  HUB_CR_PS_FETCH_RESUME_SESSION_FAILED,
  HUB_CR_PS_SAVE_AFFECTED_SESSIONS,
  HUB_CR_PS_SAVE_PAUSE_FROM_RESUME_FROM_DATE,
} from '../actions/actionTypes';

export default function hubContactPage(state = initialState.hubContactPage, action) {
  const { type, payload } = action;
  switch (type) {
    case HUB_CR_SAVE_SELECTED_ENROLMENT:
      return {
        ...state,
        selectedEnrolment: payload.selectedEnrolment,
      };
    case HUB_CR_SAVE_SELECTED_SESSION:
      return {
        ...state,
        selectedSession: payload.selectedSession,
      };
    case HUB_CR_SAVE_SELECTED_STUDENT:
      return {
        ...state,
        selectedStudent: payload.selectedStudent,
      };
    case HUB_CR_PS_FETCH_RESUME_SESSION_SUCCESS:
      return {
        ...state,
        pauseSessions: {
          ...state.pauseSessions,
          resumeSessionDate: payload.resumeSessionDate,
        },
      };
    case HUB_CR_PS_FETCH_RESUME_SESSION_FAILED:
      return {
        ...state,
        pauseSessions: {
          ...state.pauseSessions,
          resumeSessionDate: null,
        },
      };
    case HUB_CR_PS_SAVE_AFFECTED_SESSIONS:
      return {
        ...state,
        pauseSessions: {
          ...state.pauseSessions,
          affectedSessions: payload.affectedSessions,
        },
      };
    case HUB_CR_PS_SAVE_PAUSE_FROM_RESUME_FROM_DATE:
      return {
        ...state,
        pauseSessions: {
          ...state.pauseSessions,
          pauseFromDate: payload.pauseFromDate,
          resumeFromDate: payload.resumeFromDate,
        },
      };
    default:
      return state;
  }
}
