import { IconProps as Props } from '../types/hubInterfaces';
import { iconDefaults } from '.';
import classNames from 'classnames';

function SpeechIcon(props: Props) {
  const { width, height, className } = { ...iconDefaults, ...props };
  return (
    <svg
      width={`${width}`}
      height={`${height}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('fill-current', className)}
    >
      <path
        id="&#240;&#159;&#142;&#168; Icon &#208;&#161;olor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.50312 10.3683C6.50312 9.79092 6.978 9.32229 7.56312 9.32229C8.14824 9.32229 8.62312 9.79092 8.62312 10.3683C8.62312 10.9458 8.14824 11.4144 7.56312 11.4144C6.978 11.4144 6.50312 10.9458 6.50312 10.3683ZM11.8031 9.32231C11.218 9.32231 10.7431 9.79094 10.7431 10.3684C10.7431 10.9458 11.218 11.4144 11.8031 11.4144C12.3883 11.4144 12.8631 10.9458 12.8631 10.3684C12.8631 9.79094 12.3883 9.32231 11.8031 9.32231ZM16.0432 9.32231C15.458 9.32231 14.9832 9.79094 14.9832 10.3684C14.9832 10.9458 15.458 11.4144 16.0432 11.4144C16.6283 11.4144 17.1032 10.9458 17.1032 10.3684C17.1032 9.79094 16.6283 9.32231 16.0432 9.32231ZM20.2831 15.5986C20.2831 16.175 19.8071 16.6446 19.2231 16.6446H8.15032C7.57474 16.6446 7.0087 16.7995 6.51368 17.0924L3.32308 18.9815V5.13806C3.32308 4.56169 3.79902 4.09201 4.38308 4.09201H19.2231C19.8071 4.09201 20.2831 4.56169 20.2831 5.13806V15.5986ZM19.2231 2H4.38312C2.62988 2 1.20312 3.40799 1.20312 5.13816V20.8289C1.20312 21.2055 1.40876 21.5539 1.74054 21.739C1.90272 21.83 2.08292 21.875 2.26312 21.875C2.4518 21.875 2.64048 21.8258 2.80902 21.7254L7.60446 18.8864C7.76982 18.7881 7.9585 18.7368 8.15036 18.7368H19.2231C20.9764 18.7368 22.4031 17.3289 22.4031 15.5987V5.13816C22.4031 3.40799 20.9764 2 19.2231 2Z"
        fill="white"
      />
    </svg>
  );
}

export { SpeechIcon };
