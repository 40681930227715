import { useLocation, useParams } from 'react-router';
import { api } from '../../../../api';
import { Button, Heading4, Link, Pending } from '@cluey/cluey-components';
import { useForm } from 'react-hook-form';
import { ChevronDownIcon } from '../../../../icons';
import { PATH_HUB_HOME } from '../../../../util/pagePath';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { ddLogger, ddLoggerLevel } from '../../../../logging';
import { FETCH_FAILED } from '../../../../logging/errors';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { UpdateCustomerParams } from '../../../../api/services/account';
import { getYearlLevelsWithAssessment } from '../../../../util/helper/assessment';
import { ShadowContainer } from '../../../../components/ShadowContainer';
import { StudentInformationForm } from '../../../../components/hub/student/StudentInformationForm';
import type { AssessmentSubject } from '../../../../types/hubTypes';
import { StudentInformationFormData } from '../../../../components/hub/student/StudentInformationForm';
import { useEffect } from 'react';
import { StudentYearLevelForm } from '../../../../components/hub/student/StudentYearLevelForm/StudentYearLevelForm';
import { GenerateAssessmentFormProps, LocationState } from '../GenerateAssessment';
import HubFooter from '../../../../common/hub/HubFooter/HubFooter';
import HubHeader from '../../../../common/hub/HubHeader';
import { FullPageLoader } from '../../../../components/Loader/Loader';

interface Props {
	formFlowProps: GenerateAssessmentFormProps;
}

const GenerateAssessmentForm = ({ formFlowProps }: Props) => {
	const location = useLocation<LocationState>();
	const state = location.state;
	const student = state?.data?.student;
	const { assessmentSubject } = useParams<{ assessmentSubject: AssessmentSubject }>();
	const { data: accountDetails, isLoading: loadingAccountDetails } = api.account.details.useQuery();
	const { country, userType, customerSfid, email } = accountDetails ?? {};
	const yearlLevelsWithAssessment = getYearlLevelsWithAssessment({ country });

	const formUtils = useForm<GenerateAssessmentFormData>({
		resolver: zodResolver(GenerateAssessmentFormData),
		values: {
			location: accountDetails?.country,
			firstName: student?.firstName,
			lastName: student?.lastName,
			yearLevel: student?.yearLevel,
			schoolName: student?.schoolName,
		},
	});
	const {
		handleSubmit,
		clearErrors,
		setError,
		formState: { errors },
	} = formUtils;

	const { mutate, isLoading: generatingAssessment } = api.assessment.generate.useMutation();
	const { mutateAsync: updateLead, isLoading: updatingLead } = api.account.update.useMutation();
	const { mutateAsync: updateStudent, isLoading: updatingAccount } = api.student.update.useMutation();

	const isExistingStudent = userType === 'Account' && !!student;
	// This to auto submit for accounts when they have an existing student with enrolments
	useEffect(() => {
		if (isExistingStudent && formFlowProps.state.enrolments > 0) {
			const assessmentGenerationParams = {
				userIdentifierField: 'accountId' as const,
				userIdentifierFieldValue: formFlowProps.state.student.studentId,
				subject: assessmentSubject,
				countryCode: country,
				yearLevel: formFlowProps.state.student.yearLevel,
			};

			mutate(assessmentGenerationParams, {
				onSuccess: () => {
					formFlowProps.next({ ...state, yearLevel: student?.yearLevel });
				},
				onError: () => {
					setError('assessment', {
						type: 'server',
						message: 'There was a problem generating the assessment link. Please try again.',
					});
					window.scroll({
						top: document.body.scrollHeight,
						behavior: 'smooth',
					});
					ddLogger({
						level: ddLoggerLevel.ERROR,
						label: FETCH_FAILED.message,
						data: { ...assessmentGenerationParams, error: 'Assessment generation failed' },
						error: new Error(FETCH_FAILED.message),
					});
				},
			});
		}
	}, [formFlowProps, isExistingStudent, setError, assessmentSubject, country, mutate, state, student]);

	const onFormSubmit = handleSubmit(async (data: GenerateAssessmentFormData) => {
		if (userType === 'Account') {
			const studentNumber = student?.customerNumber;
			if (studentNumber && data.yearLevel !== student?.yearLevel) {
				await updateStudent(
					{
						customerType: 'Account',
						customerNumber: studentNumber,
						yearLevel: data.yearLevel,
					},
					{
						onSuccess: () => {
							api.student.all.invalidateQueries();
							api.account.details.invalidateQueries();
						},
					}
				);
			} else {
				setError('root', {
					message: 'There was a problem updating the student. Please try again.',
				});
			}
		} else {
			const updateParams = omitBy<UpdateCustomerParams>(
				{
					customerType: userType || 'Lead',
					email,
					studentFirstName: data.firstName,
					studentLastName: data.lastName,
					yearLevel: data.yearLevel,
					schoolId: data.schoolName,
					curriculum: data.location,
				},
				isNil
			);
			await updateLead(updateParams, {
				onSuccess: () => api.account.details.invalidateQueries(),
			});
		}
		if (yearlLevelsWithAssessment.includes(data.yearLevel)) {
			clearErrors('assessment');
			const assessmentGenerationParams = {
				userIdentifierField: userType === 'Lead' ? 'leadId' : 'accountId',
				userIdentifierFieldValue: student?.studentId || customerSfid,
				subject: assessmentSubject,
				countryCode: country,
				yearLevel: data.yearLevel,
			} as const;
			mutate(assessmentGenerationParams, {
				onSuccess: () => {
					formFlowProps.next({ 
					...state,
						yearLevel: data.yearLevel });
				},
				onError: () => {
					setError('assessment', {
						type: 'server',
						message: 'There was a problem generating the assessment link. Please try again.',
					});
					window.scroll({
						top: document.body.scrollHeight,
						behavior: 'smooth',
					});
					ddLogger({
						level: ddLoggerLevel.ERROR,
						label: FETCH_FAILED.message,
						data: { ...assessmentGenerationParams, error: 'Assessment generation failed' },
						error: new Error(FETCH_FAILED.message),
					});
				},
			});
		} else {
			formFlowProps.jump(3, { ...state, yearLevel: data.yearLevel });
		}
	});

	return (
		<>
			<HubHeader title="Hub" />
			<Pending
				loading={loadingAccountDetails || generatingAssessment || updatingLead || updatingAccount}
				loader={<FullPageLoader />}
			>
				<div className="container mx-auto px-4 py-7">
					<Link size="xs" className="font-bold uppercase tracking-widest" to={PATH_HUB_HOME}>
						Home
					</Link>
					<h1 className="mb-7 font-display text-[28px] font-bold">{`Student information for ${assessmentSubject} assessment`}</h1>
					<form className="w-full px-4 md:p-0" onSubmit={onFormSubmit}>
						<ShadowContainer className="px-7 py-10">
							<Heading4 className="text-grey-6">
								{isExistingStudent
									? `Confirm ${
											student?.firstName ? `${student?.firstName}'s` : 'their'
									  } school year:`
									: 'Before we proceed, please tell us a bit about about the student:'}
							</Heading4>
							{!isExistingStudent ? (
								<StudentInformationForm formUtils={formUtils} />
							) : (
								<StudentYearLevelForm formUtils={formUtils} />
							)}
						</ShadowContainer>
						{errors.assessment && <p className="mt-5 text-red-5">{errors.assessment.message}</p>}
						<Button className="mt-8 flex items-center justify-center gap-5 normal-case" type="submit" size="lg">
							<span>Generate {assessmentSubject} assessment link</span>
							<ChevronDownIcon className="rotate-[-90deg] text-white" />
						</Button>
						<Link className="mt-7 block" to={PATH_HUB_HOME}>
							Cancel
						</Link>
					</form>
				</div>
				<HubFooter />
			</Pending>
		</>
	);
};

export const GenerateAssessmentFormData = z.intersection(
	StudentInformationFormData,
	z.object({ assessment: z.string().optional() })
);

type GenerateAssessmentFormData = z.infer<typeof GenerateAssessmentFormData>;

export { GenerateAssessmentForm };
