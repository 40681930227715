import { useSelector } from "react-redux";
import { api } from "../../../api";
import { loginSelector } from '../../../selectors/loginSelector';
import { getAccountDetails } from '../../../selectors/hubSelector';


export type loginStateSlice = {
  initialToken: string | null;
  customerId: string | null;
  packageCode: string | null;
  role: string | null;
  authenticationStatus: string | null;
  isClockSkewDetected: boolean;
  country: string | null;
}
export default function useGetAssessments() {
  const { customerId } = useSelector(loginSelector) as loginStateSlice;
  const accountDetails = useSelector(getAccountDetails);

  const customerNumber = !!customerId && customerId === "SUPPORT" ? accountDetails?.customerNumber : customerId;
  const assessments = api.assessment.all.useQuery({ customerNumber }, { enabled: !!customerNumber});

  return assessments;
}