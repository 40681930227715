import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ButtonAsLink, Container } from '@cluey/cluey-components';
import { useHistory, useLocation } from 'react-router-dom';
import { useLastLocation } from '../../hooks/last-location';
import { isEmpty, get } from 'lodash';

import withHeadTag from '../HeadComponent';
import HubHeader from './HubHeader';
import HubFooter from './HubFooter/HubFooter';
import { scrollToTop } from '../../util/helpers';
import { resetContactUIState } from '../../actions/hub/hubContactPageActions';

import * as PATHS from '../../util/pagePath';
import BackArrow from '../../assets/images/hubContact/icon-filled-arrow-left.svg';
import { UNIQUE_ENROLMENT_ID_FOR_NEW_STUDENT } from '../../util/constants';

const PATH_REQUEST_NAME_MAPPING = {
  [PATHS.PATH_HUB_CR_PAUSE_SESSIONS]: 'Pause sessions for',
  [PATHS.PATH_HUB_CR_PERMANENT_SCHEDULE_CHANGE]: 'Change schedule for',
  [PATHS.PATH_HUB_CR_CHANGE_TUTOR]: 'Change tutor for',
  [PATHS.PATH_HUB_CR_CANCEL_ENROLMENT]: 'Cancel',
  [PATHS.PATH_HUB_CR_OTHER_ENROLMENT_CHANGE]: 'Other enrolment change for',
  [PATHS.PATH_HUB_CR_PAYMENT_QUERY]: 'Payment query for',
  [PATHS.PATH_HUB_CR_OTHER_QUERY]: 'Other request for',
  [PATHS.PATH_HUB_CR_TECHNICAL_ISSUE]: 'Technical issue for',
  [PATHS.PATH_HUB_CR_FEEDBACK]: 'Feedback on',
  [PATHS.PATH_HUB_CR_OPT_IN_OUT]: 'Practice for',
  [PATHS.PATH_HUB_CR_ADD_ENROLMENT]: 'Add enrolment for',
  [PATHS.PATH_HUB_CR_RESCHEDULE_SESSION]: 'Reschedule session',
  [PATHS.PATH_HUB_CR_SKIP_SESSION]: 'Skip session',
  [PATHS.PATH_HUB_CR_REQUEST_RECORDING]: 'Request recording',
};

const ContactFormLayout = ({
  page,
  pageTitle,
  children,
  resetUIState,
  selectedEnrolment,
  selectedSession,
  selectedStudent,
  customGoBack,
}) => {
  const location = useLocation();
  const { pathname } = location;
  const history = useHistory();
  const lastLocation = useLastLocation();

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    resetUIState();
  }, [resetUIState]);

  const goBackToHome = () => {
    history.push(PATHS.PATH_HUB_HOME);
  };

  const formatSubtitle = (topLine, bottomLine) => {
    return topLine || bottomLine ? (
      <div className="mt-4 border-t border-grey-2 pt-6">
        {topLine ? <p className="mb-0 text-base leading-tight">{topLine}</p> : ''}
        {bottomLine ? <p className="mb-0 text-base font-bold leading-tight">{bottomLine}</p> : ''}
      </div>
    ) : (
      ''
    );
  };

  const isContactOptionsPage = page === 'request-options';

  if (!isContactOptionsPage && isEmpty(selectedEnrolment) && isEmpty(selectedSession) && isEmpty(selectedStudent)) {
    const query = new URLSearchParams(location.search);
    const enrolmentIdToken = query.get('token');
    // if there is an enrolment id in the token query, don't redirect before we finish fetching the enrolment data
    if (!enrolmentIdToken) {
      goBackToHome();
    }
    return null;
  }

  let requestName = PATH_REQUEST_NAME_MAPPING[pathname];
  let requestSubtitle = '';
  let student = '';
  let subject = '';
  let nextSessionTutor = '';
  let nextSessionDetail = '';

  if (!isEmpty(selectedEnrolment)) {
    student = get(selectedEnrolment, 'student');
    subject = get(selectedEnrolment, 'subject');
    nextSessionTutor = get(selectedEnrolment, 'nextSessionTutor');
    nextSessionDetail = get(selectedEnrolment, 'nextSessionDetail');
    requestName = pageTitle
      ? `${pageTitle} ${student}'s ${subject} enrolment`
      : `${requestName} ${student}'s ${subject} enrolment`;
    if (nextSessionDetail.includes('Invalid date')) {
      requestSubtitle = null;
    } else {
      requestSubtitle = formatSubtitle(nextSessionTutor, nextSessionDetail);
    }
  }

  let addForNewStudent = false;
  if (!isEmpty(selectedStudent)) {
    student = get(selectedStudent, 'student');
    const enrolmentId = get(selectedStudent, 'enrolmentId');
    addForNewStudent = enrolmentId === UNIQUE_ENROLMENT_ID_FOR_NEW_STUDENT;
    const studentName = addForNewStudent ? 'an existing student' : student;
    requestName = `${requestName} ${studentName}`;
  }

  let sessionInfo = '';
  let tutor = '';
  let sessionTutor = '';
  if (!isEmpty(selectedSession)) {
    sessionInfo = get(selectedSession, 'sessionInfo');
    student = get(selectedSession, 'student');
    subject = get(selectedSession, 'subject');
    tutor = get(selectedSession, 'tutor');

    sessionTutor = `${requestName} with ${tutor}`;
    requestName = `${requestName} for ${student}’s ${subject} enrolment`;
    requestSubtitle = formatSubtitle(sessionTutor, sessionInfo);
  }

  const goBack = () => {
    if (pathname === PATHS.PATH_HUB_CR_REQUEST_OPTIONS) {
      if (!isEmpty(lastLocation)) {
        const { pathname: lastPathname } = lastLocation;
        if (lastPathname.includes(PATHS.PATH_HUB_CR)) {
          goBackToHome();
        } else {
          history.goBack();
        }
      } else {
        goBackToHome();
      }
    } else if (!isEmpty(lastLocation)) {
      const { pathname: lastPathname } = lastLocation;
      if (lastPathname === PATHS.PATH_HUB_CR_CANCEL_ENROLMENT) {
        history.push(`${PATHS.PATH_HUB_CR_CANCEL_ENROLMENT}?token=${selectedEnrolment.enrolmentId}`);
      } else if (lastPathname.includes(PATHS.PATH_HUB_ENROLMENT_CHANGE_SCHEDULE)) {
        goBackToHome();
      } else {
        history.goBack();
      }
    } else {
      goBackToHome();
    }
  };

  return (
    <div className="hub flex flex-col">
      <HubHeader title="Hub" />
      <Container>
        <div className="container mx-auto flex p-0 pt-5 lg:mb-10 lg:pt-10">
          <div className="content-container no-sidenav relative mx-auto w-full">
            <ButtonAsLink
              className="mx-4 mb-5 p-0 lg:mx-0"
              onClick={typeof customGoBack === 'function' ? customGoBack : goBack}
            >
              <img src={BackArrow} alt="back" />
            </ButtonAsLink>
            <div className="mx-4 rounded-lg bg-slate-1 px-7 py-10 text-center text-grey-6 lg:mx-0 lg:mb-4 lg:px-6 lg:py-8">
              {isContactOptionsPage && <h1 className="text-3xl font-medium lg:text-4xl">{pageTitle}</h1>}
              {!isContactOptionsPage && (
                <Fragment>
                  {requestName && <p className="mb-0 text-xl leading-tight">{requestName}</p>}
                  {requestSubtitle}
                </Fragment>
              )}
            </div>
            <div className="contact-form__content">{children}</div>
          </div>
        </div>
      </Container>
      <HubFooter />
    </div>
  );
};

ContactFormLayout.propTypes = {
  page: PropTypes.string,
  pageTitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  resetUIState: PropTypes.func.isRequired,
  selectedEnrolment: PropTypes.shape({
    enrolmentId: PropTypes.string,
    detail: PropTypes.string,
    nextSession: PropTypes.string,
    student: PropTypes.string,
    subject: PropTypes.string,
    nextSessionTutor: PropTypes.string,
    nextSessionDetail: PropTypes.string,
  }),
  selectedSession: PropTypes.shape({
    uniqueEnrolmentId: PropTypes.string,
    sessionInfo: PropTypes.string,
    studentSessionId: PropTypes.string,
  }),
  selectedStudent: PropTypes.shape({
    student: PropTypes.string,
    enrolmentId: PropTypes.string,
  }),
  customGoBack: PropTypes.func,
};

ContactFormLayout.defaultProps = {
  page: 'form',
  pageTitle: '',
  selectedEnrolment: {},
  selectedSession: {},
  selectedStudent: {},
  customGoBack: null,
};

export default connect(null, (dispatch) => {
  return {
    resetUIState: bindActionCreators(resetContactUIState, dispatch),
  };
})(withHeadTag(ContactFormLayout));
