import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import upperFirst from 'lodash/upperFirst';
import FilledExclamation from '../../../../../assets/images/icon-filled-alert-exclamation.svg';
import { Link as RouterLink } from 'react-router-dom';
import { Student } from '../../../../../api/types/student';
import { ChevronDownIcon } from '../../../../../icons';
import { PATH_HUB_ADD_STUDENT } from '../../../../../util/pagePath';

// TODO: Remove this when we have real data
const PLACEHOLDER_STUDENT_PATH = (studentId: string) => `/portal/customer/students/${studentId}/profile`;

export const StudentCardSkeleton = () => {
  return (
    <div className="flex animate-pulse flex-col gap-2 rounded-lg border border-grey-2 bg-grey-1 p-4">
      <div className="h-4 w-1/2 rounded-full bg-grey-2"></div>
      <div className="h-4 w-1/4 rounded-full bg-grey-2"></div>
      <div className="h-4 w-1/3 rounded-full bg-grey-2"></div>
    </div>
  );
};

export const StudentCard = ({ student, className }: { student: Student; className?: string }) => {
  return (
    <div
      className={classNames(
        'flex w-60 flex-shrink-0 flex-col gap-2 rounded-lg border border-grey-2 bg-white p-4 text-sm shadow-md md:w-auto lg:flex-shrink',
        className
      )}
    >
      <div className="font-bold">
        {student.firstName}
        {student.currentYearLevel && ` | ${student.currentYearLevel}`}
      </div>
      <div className="flex-grow">
        {!isEmpty(student.enrolments) ? (
          <>
            <p>Currently learning:</p>
            <p>{Object.keys(student.enrolments).map(upperFirst).join(', ')}</p>
          </>
        ) : (
          <p className="flex items-center">
            <img src={FilledExclamation} alt="warning" className="mr-2 h-4 w-4" /> No learning in progress
          </p>
        )}
      </div>
      <RouterLink
        to={PLACEHOLDER_STUDENT_PATH(student.studentId)}
        className="flex items-center justify-center gap-2 rounded border border-grey-2 px-3 py-[10px] text-center text-xs font-bold uppercase text-primary hover:bg-slate-1"
      >
        See Profile <ChevronDownIcon className="-rotate-90" height={10} width={10} />
      </RouterLink>
    </div>
  );
};

export const AddAStudentCard = () => {
  return (
    <RouterLink
      to={PATH_HUB_ADD_STUDENT}
      className="flex w-60 flex-shrink-0 snap-center flex-col items-center justify-center rounded-lg border-2 border-dashed border-grey-2 bg-white p-4 text-sm hover:bg-slate-1 md:w-auto"
    >
      <div className="font-bold text-primary underline">+ Add a student</div>
      <div className="text-center">Access unique content tailored to your specific needs</div>
    </RouterLink>
  );
};
