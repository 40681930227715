import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import classNames from 'classnames';

// A custom hook to make it easier to get the necessary props for graph legend
export function useGraphLegendProps() {
  const graphRef = useRef(null);
  const [legendItems, setLegendItems] = useState([]);
  const [, forceUpdate] = useState();

  return {
    graphRef,
    legendItems,
    setLegendItems,
    forceUpdate,
  };
}

export const GraphLegend = ({
  graphRef,
  legendItems,
  setLegendItems,
  forceUpdate,
  legendItemClasses,
}) => {
  useEffect(() => {
    if (!graphRef) {
      return () => {};
    }

    setLegendItems(graphRef.current.chartInstance.legend.legendItems);
    return () => {};
  }, [graphRef, setLegendItems]);

  if (!Array.isArray(legendItems) || legendItems.length <= 0) {
    return null;
  }

  const handleLegendClick = (datasetIndex) => {
    const chart = graphRef.current.chartInstance;
    chart.getDatasetMeta(datasetIndex).hidden =
      !chart.getDatasetMeta(datasetIndex).hidden;
    chart.update(); // re-draw chart to hide dataset
    forceUpdate({}); // re-draw component to update legend styles
  };

  return (
    <div className="flex flex-wrap p-8">
      {legendItems.map((item) => {
        const chart = graphRef.current.chartInstance;
        const isHidden = chart.getDatasetMeta(item.datasetIndex).hidden;
        const bgColor = get(item, 'fillStyle');
        return (
          <div
            role="presentation"
            className={classNames(
              `mb-2 flex cursor-pointer items-center ${legendItemClasses}`,
              { 'line-through': isHidden }
            )}
            key={item.datasetIndex}
            onClick={() => handleLegendClick(item.datasetIndex)}
          >
            <span
              className="mr-4 h-[3px] w-[30px]"
              style={{ background: bgColor }}
            />
            <span className="text-xs">{item.text}</span>
          </div>
        );
      })}
    </div>
  );
};

GraphLegend.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  graphRef: PropTypes.any.isRequired,
  legendItems: PropTypes.arrayOf(
    PropTypes.shape({
      fillStyle: PropTypes.string,
      datasetIndex: PropTypes.number,
      text: PropTypes.string,
    })
  ),
  setLegendItems: PropTypes.func.isRequired,
  forceUpdate: PropTypes.func.isRequired,
  legendItemClasses: PropTypes.string,
};

GraphLegend.defaultProps = {
  legendItems: [],
  legendItemClasses: '',
};
