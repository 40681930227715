import { ReactNode } from "react";
import { LinkButton } from "@cluey/cluey-components";
import { api } from "../../../../../../api";
import { PATH_HUB_GENERATE_ASSESSMENT } from "../../../../../../util/pagePath";
import { generatePath } from "react-router";

interface Props {
  image: string;
  icon: ReactNode;
  subject: string;
}

const HomeAssessmentCard = ({ image, icon, subject }: Props) => {
  const { data: details } = api.account.details.useQuery();
  const text = details.country === 'AU' ? 'Years 3 - 9' : 'Years 4 - 10';
  const generateAssessmentRoute = `${generatePath(PATH_HUB_GENERATE_ASSESSMENT, {assessmentSubject: subject})}`;

  return (
    <div className="shadow-md rounded overflow-hidden">
      <img src={image} alt="assessment-book-graphic" />
      <div className="p-7">
        <div className="flex gap-3 items-center flex-start">
          {icon}
          <div className="flex flex-col text-grey-6 gap-1">
            <span className="text-lg font-bold">{subject}</span>
            <span className="text-base">{text}</span>
          </div>
        </div>
        <LinkButton className="mt-5" to={generateAssessmentRoute}>Take {subject} assessment</LinkButton>
      </div>
    </div>
  )
}

export default HomeAssessmentCard;