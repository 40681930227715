import { Button, ButtonAsLink, Field, Form } from '@cluey/cluey-components';
import { PATHS, useSignupStore } from '.';
import { Auth2Layout, BackButton, InlineLoader } from '../common';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import { Redirect, useHistory } from 'react-router';
import { z } from 'zod';
import isError from 'lodash/isError';
import { PATH_HUB_RESET_PASSWORD_REQUEST } from '../../../../util/pagePath';

export const SignUpVerifyPage = () => {
  const { email, username, token } = useSignupStore(({ email, username, token }) => ({ email, username, token }));
  const [tempPassword, setTempPassword] = useState('');
  const history = useHistory();
  const [error, setError] = useState('');

  const { mutate, isLoading } = useMutation({
    mutationFn: async (tempPassword: string) => {
      // confirm signup with AWS, this verifies the account and creates a lead in sf
      await Auth.confirmSignUp(username, tempPassword);
      // decode the resulting token to grab the temp password
      try {
        const decoded = JSON.parse(Buffer.from(token, 'base64').toString());
        // sign in with the temp password, ready to change it
        return Auth.signIn(username, decoded.tmpPassword);
      } catch (error) {
        // handles an edge case where user signs up, but returns to the login page somehow and tries to login
        if (error instanceof Error && error.message.includes('Unexpected end of JSON input')) {
          history.push(PATHS.ROOT_PATH);
        } else {
          throw error;
        }
      }
    },
    onSuccess: () => {
      history.push(PATHS.PASSWORD_PATH);
    },
    onError: (error) => {
      // handles an edge case
      if (isError(error) && error.message.includes('User cannot be confirmed. Current status is CONFIRMED')) {
        history.push(PATH_HUB_RESET_PASSWORD_REQUEST, { emailParam: email });
      }
      setError(isError(error) ? error.message : 'Something went wrong. Please try again.');
    },
  });

  const handleSubmit = () => {
    const tempPasswordResult = z.string().min(1, 'Please enter your temporary password.').safeParse(tempPassword);
    if (tempPasswordResult.success === true) {
      mutate(tempPasswordResult.data.trim());
    } else {
      setError(tempPasswordResult.error.errors[0].message);
    }
  };

  const resendCode = () => {
    Auth.resendSignUp(username);
  };

  if (!username) return <Redirect to={PATHS.ROOT_PATH} />;

  return (
    <Auth2Layout
      title="Check your email"
      headerLinks={
        <div className="flex justify-between">
          <BackButton />
        </div>
      }
    >
      <p className="pb-8">
        Enter the temporary password we sent to <strong className="font-bold">{email || 'your email'}</strong> to create
        your account.
      </p>
      <Form onSubmit={handleSubmit} className="w-full pb-8">
        <Field
          label="Password"
          type="text"
          value={tempPassword}
          onChange={(value) => {
            setTempPassword(value);
            setError('');
          }}
          disabled={isLoading}
          className="w-full max-w-sm pb-8"
          error={error}
        />
        <Button
          type="submit"
          size="lg"
          disabled={isLoading}
          className="relative flex items-center justify-center max-sm:w-full"
        >
          <span className={isLoading ? 'opacity-0' : ''}>Continue</span>
          {isLoading && <InlineLoader className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />}
        </Button>
      </Form>
      <p>
        Didn't receive the password?{' '}
        <ButtonAsLink size="base" onClick={resendCode}>
          Resend email
        </ButtonAsLink>
      </p>
    </Auth2Layout>
  );
};
