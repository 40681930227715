import { FormField } from '../../../FormField';
import { HookFormTypeAhead } from '../../../HookFormTypeAhead';
import { Label } from '@cluey/cluey-components';
import { UseFormReturn } from 'react-hook-form';
import { BootlegSelect } from '../../../../views/hub/auth2/SignUp/components/BootlegSelect';
import { api } from '../../../../api';
import { useEffect, useMemo, useState } from 'react';
import range from 'lodash/range';
import { orderBy } from 'lodash';
import { AUSTRALIA_STATES } from '../../../../util/constants';
import { z } from 'zod';
import { StudentInformationLoader } from './StudentInformationLoader';
import classNames from 'classnames';
import type { Student } from '../../../../api/types/student';

interface Props {
	className?: string;
	student?: Student;
	loading?: boolean;
	title?: boolean;
	formUtils: UseFormReturn<StudentInformationFormData>;
}

const StudentInformationForm = ({ student, loading, formUtils, className, title }: Props) => {
	const {
		register,
		formState: { errors },
		watch,
		clearErrors,
		setValue,
	} = formUtils;
	const [schoolOptions, setSchoolOptions] = useState([]);
	const { data: accountDetails } = api.account.details.useQuery();
	const kiwi = !!student ? student?.studentCountry !== 'Australia' : accountDetails?.country === 'NZ';
	const defaultLocation = !!student ? student.location || undefined : kiwi ? 'NZ' : undefined;

	const locationOptions = useMemo(() => {
		const ops = orderBy(
			Object.entries(AUSTRALIA_STATES).map(([value, label]) => (
				<option key={value} value={value}>
					{label}
				</option>
			)),
			'key'
		);
		ops.unshift(
			<option key="default" value="default">
				Select
			</option>
		);
		return ops;
	}, []);

	const yearLevelOptions = useMemo(() => {
		const ops = (kiwi ? range(3, 12) : range(2, 13)).map((year) => {
			const yearValue = `Year ${year}`;
			return (
				<option key={year} value={yearValue}>
					{yearValue}
				</option>
			);
		});
		ops.unshift(
			<option key="default" value="default">
				Select
			</option>
		);
		return ops;
	}, [kiwi]);

	useEffect(() => {
		let ok = true;

		const getOptions = async () => {
			const schoolOptions = await import('../../../../assets/json/schools.json');
			return schoolOptions;
		};
		getOptions().then((module) => {
			if (ok) setSchoolOptions(module.default);
		});

		return () => {
			ok = false;
		};
	}, []);

	const state = watch('location', defaultLocation);
	const formattedSchoolNames = useMemo(() => {
		return schoolOptions
			.filter((op) => op.state === state)
			.map((op) => {
				const city = op.city ? ` (${op.city})` : '';
				return { value: op.id, label: `${op.name}${city}` };
			});
	}, [schoolOptions, state]);

	const onChange = () => clearErrors();

	if (loading) {
		return <StudentInformationLoader />;
	}

	return (
		<div className={classNames('flex max-w-md flex-col gap-5', className)}>
			{title && <div className="pb-4 text-xs font-bold uppercase tracking-[1px]">Student Information</div>}
			<FormField
				label="First name"
				id="firstName"
				error={errors.firstName ? errors.firstName?.message : undefined}
				defaultValue={student?.firstName}
				{...register('firstName', { required: true })}
			/>
			<FormField
				label="Last name"
				id="lastName"
				error={errors.lastName ? errors.lastName?.message : undefined}
				defaultValue={student?.lastName}
				{...register('lastName', { required: true })}
			/>
			{!kiwi && (
				<div>
					<Label>Location</Label>
					<BootlegSelect
						id="location"
						className="mt-2"
						{...register('location', { onChange, required: true })}
						defaultValue={!!student ? student?.location : null}
					>
						{locationOptions}
					</BootlegSelect>
					{errors.location && <p className="mt-1 text-xs text-red-5">{errors.location.message}</p>}
				</div>
			)}
			<div>
				<Label>School year</Label>
				<BootlegSelect
					id="yearLevel"
					className="mt-2"
					{...register('yearLevel', { onChange, required: true })}
					defaultValue={!!student ? student?.yearLevel : null}
				>
					{yearLevelOptions}
				</BootlegSelect>
				{errors.yearLevel && <p className="mt-1 text-xs text-red-5">{errors.yearLevel.message}</p>}
			</div>
			{!kiwi && (
				<HookFormTypeAhead
					label="School name (optional)"
					options={formattedSchoolNames}
					id="schoolName"
					name="schoolName"
					setValue={(value) => setValue('schoolName', value)}
					defaultValue={student?.schoolId}
				/>
			)}
		</div>
	);
};

export const StudentInformationFormData = z.object({
	firstName: z
		.string()
		.min(1, 'Please enter a valid first name')
		.regex(/^[a-zA-Z\s0-9_\-']+$/, "Please enter only letters, numbers, spaces and any of these symbols: _-'."),
	lastName: z
		.string()
		.min(1, 'Please enter a valid last name')
		.regex(/^[a-zA-Z\s0-9_\-']+$/, "Please enter only letters, numbers, spaces and any of these symbols: _-'."),
	location: z
		.string()
		.transform((val) => (val === 'default' ? undefined : val))
		.pipe(z.string({ required_error: 'Please select a location' })),
	yearLevel: z
		.string()
		.transform((val) => (val === 'default' ? undefined : val))
		.pipe(z.string({ required_error: 'Please select a school year' })),
	schoolName: z.string().optional().nullable(),
});
export type StudentInformationFormData = z.infer<typeof StudentInformationFormData>;

export { StudentInformationForm };
