import { Session } from "../../types/hubInterfaces"
import { HUB_SP_REMOVE_CANCELLED_SESSION } from '../../actions/actionTypes';
import { get } from 'lodash';

/* 
  The Sales Force cache database takes a few seconds to update the session
  record. Thus, refreshing any page that selects from hubSessionsPage might 
  yield an outdated result. Waiting for at least a minute before refreshing the page 
  usually resolves this problem
*/
export const removeCancelledSession = ({ cancelledSession} : {
  cancelledSession: Session
}) => (dispatch, getState) => {
  const upcoming = get(getState(), 'hubSessionsPage.upcomingSessions', []);
  const upcomingWithoutCancelled = upcoming.filter(session => {
    return session.studentSessionId !== cancelledSession.studentSessionId;
  });
  dispatch({ type: HUB_SP_REMOVE_CANCELLED_SESSION, payload: upcomingWithoutCancelled });
}