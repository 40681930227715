import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import { getStudents } from '../../selectors/upcomingSessionsSelector';
import {
  showUpcomingSessions,
  hideUpcomingSessions,
} from '../../actions/upcomingSessionsActions';

import EnrolmentItem from './EnrolmentItem';

class Welcome extends React.Component {
  constructor(props) {
    super(props);
    const { activeTabName, students } = this.props;

    this.isTabEnrolmentsActiveByDefault = activeTabName === 'enrolments';
    this.state = {
      tabSessionsActive: !this.isTabEnrolmentsActiveByDefault,
      tabEnrolmentsActive: this.isTabEnrolmentsActiveByDefault,
    };

    this.introText =
      'Here, you can view all of your enrolments and access the learning activity for each.';
    this.enrolmentsLayoutClass = this.useThreeColumns(students);
    this.sortedStudents = sortBy(students, ['name', 'subject']);
  }

  componentDidMount() {
    if (this.isTabEnrolmentsActiveByDefault) {
      this.props.hideUpcomingSessions();
    }
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      tabSessionsActive: nextProps.ucSessionsVisible,
      tabEnrolmentsActive: !nextProps.ucSessionsVisible,
    };
  }

  onSessionsTabClick = (e) => {
    e.preventDefault();
    this.setState({
      tabSessionsActive: true,
      tabEnrolmentsActive: false,
    });
    this.props.showUpcomingSessions();
  };

  onEnrolmentsTabClick = (e) => {
    e.preventDefault();
    this.setState({
      tabSessionsActive: false,
      tabEnrolmentsActive: true,
    });
    this.props.hideUpcomingSessions();
  };

  useThreeColumns = (enrolments) => {
    const enrolmentsNo = enrolments.length;
    if ([1, 2, 4].includes(enrolmentsNo)) {
      return false;
    }
    return true;
  };

  render() {
    const { name } = this.props;
    const { tabSessionsActive, tabEnrolmentsActive } = this.state;
    const navLinkBaseClasses =
      'block px-4 py-2 hover:no-underline focus:no-underline';
    const activeNavLinkClasses = 'text-white bg-primary';
    const tabSessionsClasses = classNames(
      navLinkBaseClasses,
      tabSessionsActive && activeNavLinkClasses
    );
    const tabEnrolmentsClasses = classNames(
      navLinkBaseClasses,
      tabEnrolmentsActive && activeNavLinkClasses
    );

    const contentSessionsClasses = classNames(
      'lg:block',
      tabSessionsActive && 'block',
      tabEnrolmentsActive && 'hidden'
    );
    const contentEnrolmentsClasses = classNames(
      'lg:block',
      tabEnrolmentsActive && 'block',
      tabSessionsActive && 'hidden'
    );

    const enrolmentsClasses = classNames('flex flex-wrap mt-5 enrolments', {
      'enrolments--three-columns': this.enrolmentsLayoutClass,
    });

    return (
      <div className="welcome w-full bg-slate-1 text-custom-5">
        <div className="container mx-auto p-4 pb-6 lg:py-10">
          <ul className="mb-6 flex list-none flex-wrap rounded border border-primary bg-white pl-0 lg:hidden">
            <li className="w-1/2 text-center">
              <a
                href="#tab-sessions"
                className={tabSessionsClasses}
                onClick={this.onSessionsTabClick}
              >
                Sessions
              </a>
            </li>
            <li className="w-1/2 text-center">
              <a
                href="#tab-enrolments"
                className={tabEnrolmentsClasses}
                onClick={this.onEnrolmentsTabClick}
              >
                Enrolments
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div className={contentSessionsClasses} id="tab-sessions">
              <h3 className="relative mb-3 inline-block pr-10 text-xl">
                Welcome, {name}
              </h3>
              <p className="m-0">
                Introducing your new view for upcoming learning sessions. Here,
                you can manage your schedule for the next 4 weeks or submit a
                request for additional changes.
              </p>
            </div>
            <div className={contentEnrolmentsClasses} id="tab-enrolments">
              <p className="m-0 lg:hidden">{this.introText}</p>
              <div className={enrolmentsClasses}>
                {this.sortedStudents.length > 0 &&
                  this.sortedStudents.map((s) => {
                    const {
                      name: studentName,
                      type,
                      subject,
                      practiceLink,
                      sessionReportId,
                      uniqueEnrolmentId,
                    } = s;
                    return (
                      <EnrolmentItem
                        key={uniqueEnrolmentId}
                        name={studentName}
                        type={type}
                        subject={subject}
                        sessionReportId={sessionReportId}
                        practiceLink={practiceLink}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Welcome.propTypes = {
  activeTabName: PropTypes.string,
  name: PropTypes.string,
  students: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      subject: PropTypes.string.isRequired,
      uniqueEnrolmentId: PropTypes.string.isRequired,
      sessionReportId: PropTypes.string,
      practiceLink: PropTypes.string,
    })
  ).isRequired,
  showUpcomingSessions: PropTypes.func.isRequired,
  hideUpcomingSessions: PropTypes.func.isRequired,
  ucSessionsVisible: PropTypes.bool.isRequired,
};

Welcome.defaultProps = {
  name: null,
  activeTabName: null,
};

export default connect(
  (state) => {
    return {
      name: state.upcomingSessions.name,
      students: getStudents(state),
      ucSessionsVisible: state.ui.apiState.upcomingSessions.ucSessionsVisible,
    };
  },
  (dispatch) => {
    return {
      showUpcomingSessions: bindActionCreators(showUpcomingSessions, dispatch),
      hideUpcomingSessions: bindActionCreators(hideUpcomingSessions, dispatch),
    };
  }
)(Welcome);

export { Welcome as PresentationalWelcome };
