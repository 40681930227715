import { get } from 'lodash';

export const getCustomerNumber = (state) => {
  let customerNumber = get(state, 'login.customerId', '');

  if (customerNumber) {
    return customerNumber;
  }

  customerNumber = get(state, 'upcomingSessions.customerNumber');
  return customerNumber;
};
