import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DATE_FORMAT_1 } from '../../../util/constants';

const SchoolTermListItem = ({ termItem, timezone }) => {
  const { startTime, state, schoolTerm, isTermStartDate } = termItem;

  const displayDate = moment(startTime).tz(timezone).format(DATE_FORMAT_1);

  return (
    <div className="mt-8 mb-7 flex flex-col text-center font-body text-xs">
      <span className="font-bold uppercase">
        {state} {schoolTerm} {isTermStartDate ? 'starts' : 'ends'}
      </span>
      <span>{displayDate}</span>
    </div>
  );
};

SchoolTermListItem.propTypes = {
  termItem: PropTypes.shape({
    startTime: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    schoolTerm: PropTypes.string.isRequired,
    isTermStartDate: PropTypes.bool.isRequired,
  }).isRequired,
  timezone: PropTypes.string.isRequired,
};

export default SchoolTermListItem;
