import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

const Day = ({
  dayRaw,
  activeDay,
  clickHandler,
  dayFormat,
  dateFormat,
  dateFormatMobile,
}) => {
  console.info();
  const active = moment(dayRaw).isSame(moment(activeDay), 'day');

  const day = moment(dayRaw).format(dayFormat).toUpperCase();
  const date = moment(dayRaw).format(dateFormat);
  const dateMobile = moment(dayRaw).format(dateFormatMobile);

  return (
    <button
      type="button"
      className={classNames(
        `flex flex-grow flex-col items-center rounded-t-lg border border-grey-2 py-4 px-2 text-center text-sm font-bold`,
        active
          ? 'border-b-0 text-grey-6 drop-shadow-2xl'
          : 'bg-slate-1 text-primary hover:text-purple-6'
      )}
      onClick={() => {
        clickHandler(dayRaw);
      }}
    >
      <span className="block">{day}</span>
      <span className="hidden whitespace-nowrap md:block">{date}</span>
      <span
        className="block whitespace-nowrap md:hidden min-w-[60px]"
      >
        {dateMobile}
      </span>
    </button>
  );
};

Day.propTypes = {
  // eslint-disable-next-line
  dayRaw: PropTypes.any.isRequired,
  // eslint-disable-next-line
  activeDay: PropTypes.any.isRequired,
  clickHandler: PropTypes.func.isRequired,
  dayFormat: PropTypes.string,
  dateFormat: PropTypes.string,
  dateFormatMobile: PropTypes.string,
};

Day.defaultProps = {
  dayFormat: 'ddd',
  dateFormat: 'D MMMM',
  dateFormatMobile: 'D MMM',
};

export default Day;
