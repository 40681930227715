import { createApi } from '../core';
import { SchoolTerm } from '../types/schoolTerm';

export const staticApi = createApi.group(['static'], (createKey) => {
  return {
    keyDates: createApi.query({
      queryKey: createKey(['keyDates']),
      queryFn: () => {
        return createApi.fetch({
          path: ['account', 'holidays'],
          method: 'GET',
          validator: SchoolTerm.array(),
          basePath: 'portalAPI',
          sign: true,
        });
      },
      staleTime: createApi.time(1, 'days'),
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }),
  };
});
