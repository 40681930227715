import React from 'react';
import { Container, Link } from '@cluey/cluey-components';
import HubHeader from '../../../../common/hub/HubHeader';
import HubFooter from '../../../../common/hub/HubFooter/HubFooter';
import { PATH_HUB_SUPPORT } from '../../../../util/pagePath';

export interface ArticleLayoutProps {
	children: React.ReactNode;
	title: string;
	renderBreadcrumbs?: React.ReactNode;
}
const ArticleLayout = ({ title, children, renderBreadcrumbs }: ArticleLayoutProps) => {
	return (
		<>
			<HubHeader title="Hub" />
			<Container className="px-4 pt-7 md:pt-8 lg:mb-0">
				{renderBreadcrumbs ? (
					renderBreadcrumbs
				) : (
					<div className="mb-2">
						<Link className="text-xs font-bold uppercase" to={PATH_HUB_SUPPORT}>
							Support
						</Link>
					</div>
				)}
				<h1 className="mb-16 font-display text-4xl font-bold md:text-[40px]">{title}</h1>
			</Container>
			{children}
			<HubFooter />
		</>
	);
};

export default ArticleLayout;
