import { get, isEmpty } from 'lodash';

import * as actionTypes from '../actionTypes';
import {
	REQ_PUT_HUB_ACCOUNT_DETAILS,
	REQ_GET_HUB_BILLING_INFORMATION,
	REQ_GET_HUB_INVOICE_HISTORY,
} from '../../services/backendApi';
import { interactWithAccount } from './interactWithAccount';
import { asyncACChargentOrder, asyncACProcessPayment } from './asyncActionCreators';
import moment from 'moment';
import { logError } from '../../logging';
import { fetchSummerModeData } from '../summerModeActions';
import { setLogUserAttributes } from '../../logging/datadog';
import { api } from '../../api';

// eslint-disable-next-line valid-jsdoc
/**
 * @deprecated This function has been deprecated in favor of api/services/account.details
 *
 */
export const fetchAccountDetails = () => async (dispatch) => {
	dispatch({ type: actionTypes.HUB_AP_CLEAR_ACCOUNT_DETAILS_STATUS_MESSAGE });
	dispatch({
		type: actionTypes.HUB_AP_FETCH_ACCOUNT_DETAILS_START,
	});

	try {
		const accountDetails = await api.account.details.fetchQuery();

		if (!accountDetails) throw new Error('Account details could not be retrieved.');

		let timezone = accountDetails.timezone;

		if (timezone === null) {
			timezone = moment.tz.guess();
		}

		const customerLinkId = accountDetails.customerLinkId;

		// Filling out remaining attributes for datadog
		// previously set a limited set of attributes once cognito resolved
		// see: src/actions/hubAuthPagesActions.getUserInfo
		setLogUserAttributes({
			id: accountDetails.customerSfid,
			customerNumber: accountDetails.customerNumber,
			customerLinkId: accountDetails.customerLinkId,
			account_timezone: accountDetails.timezone,
			machine_timezone: moment.tz.guess(),
			country: accountDetails.country,
		});

		dispatch({
			type: actionTypes.HUB_GLOBAL_GET_USER_TIMEZONE_SUCCESS,
			payload: {
				timezone,
				customerLinkId,
			},
		});
		dispatch(fetchSummerModeData({ customerLinkId }));

		if (window.dataLayer) {
			window.dataLayer.push({ customerId: accountDetails.customerSfid });
			window.dataLayer.push({ event: 'LoginSuccessful' });
		} else {
			console.warn('window.dataLayer is not defined. Cannot initialize GA.');
		}

		dispatch({
			type: actionTypes.HUB_AP_FETCH_ACCOUNT_DETAILS_SUCCESS,
			payload: accountDetails,
		});
	} catch (e) {
		const timezone = moment.tz.guess();
		dispatch({
			type: actionTypes.HUB_GLOBAL_GET_USER_TIMEZONE_SUCCESS,
			payload: {
				timezone,
			},
		});

		const errorMsg =
			typeof e.message === 'string' ? e.message.replace(/"/g, '') : 'Your account details could not be retrieved.';
		dispatch({
			type: actionTypes.HUB_AP_FETCH_ACCOUNT_DETAILS_FAILED,
			payload: errorMsg,
		});
		logError(e, null, { message: errorMsg });
	}
};

export const updateAccountDetails =
	({ requestPayload, successActionPayload }) =>
	(dispatch) => {
		return dispatch(
			interactWithAccount({
				startActionType: actionTypes.HUB_AP_UPDATE_ACCOUNT_DETAILS_START,
				requestPayload,
				successActionType: actionTypes.HUB_AP_UPDATE_ACCOUNT_DETAILS_SUCCESS,
				successActionPayload,
				failedActionType: actionTypes.HUB_AP_UPDATE_ACCOUNT_DETAILS_FAILED,
				errorMsg: 'There was a problem updating your account details, please try again.',
				useErrorMsgFromCaughtError: true,
				endpointType: REQ_PUT_HUB_ACCOUNT_DETAILS,
			})
		);
	};

export const fetchBillingInformation = () => (dispatch, getState) => {
	dispatch({
		type: actionTypes.HUB_AP_CLEAR_BILLING_INFORMATION_STATUS_MESSAGE,
	});

	const state = getState();
	const { ...billingInformation } = get(state, ['hubAccountPage', 'billingInformation'], {});
	if (!isEmpty(billingInformation)) {
		// Don't dispatch the fetching of billing information if already there
		return Promise.resolve(billingInformation);
	}

	return dispatch(
		interactWithAccount({
			startActionType: actionTypes.HUB_AP_FETCH_BILLING_INFORMATION_START,
			successActionType: actionTypes.HUB_AP_FETCH_BILLING_INFORMATION_SUCCESS,
			failedActionType: actionTypes.HUB_AP_FETCH_BILLING_INFORMATION_FAILED,
			errorMsg: 'Your billing information could not be retrieved.',
			endpointType: REQ_GET_HUB_BILLING_INFORMATION,
		})
	);
};

export const fetchInvoiceHistory = () => (dispatch) => {
	dispatch(
		interactWithAccount({
			startActionType: actionTypes.HUB_AP_FETCH_INVOICE_HISTORY_START,
			successActionType: actionTypes.HUB_AP_FETCH_INVOICE_HISTORY_SUCCESS,
			failedActionType: actionTypes.HUB_AP_FETCH_INVOICE_HISTORY_FAILED,
			errorMsg: 'Your invoice history could not be retrieved.',
			endpointType: REQ_GET_HUB_INVOICE_HISTORY,
		})
	);
};

// updateBillingInformation performs a triple API call.
// 1) To the chargent order
// 2) To process payment
export const updateBillingInformation = (cardDetails) => (dispatch) => {
	dispatch(asyncACChargentOrder()).then(() => {
		return dispatch(asyncACProcessPayment(cardDetails));
	});
};
