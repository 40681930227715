import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import { Form, Pending } from '@cluey/cluey-components';
import capitalize from 'lodash/capitalize';
import { Loader } from '../../../components/Loader';

import ErrorBlock from '../../../common/ErrorBlock';
import ContactFormLayout from '../../../common/hub/ContactFormLayout';
import FormSubHeading from '../../../components/hub/contact/FormSubHeading';
import { useDropdownProps, Dropdown } from '../../../components/Dropdown/Dropdown';
import { useTextAreaProps, TextArea } from '../../../components/TextArea/TextArea';
import { RestrictedButton } from '../../../components/RestrictedButton/RestrictedButton';

import { submitCaseToSF } from '../../../actions/hub/hubContactPageActions';
import { PATH_HUB_CR_SUBMIT_CASE_SUCCESS } from '../../../util/pagePath';
import { UNIQUE_ENROLMENT_ID_FOR_NEW_STUDENT } from '../../../util/constants';
import { getSelectedStudent } from '../../../selectors/hubSelector';
import { isNZCheck } from '../../../util/helper/accountDetails';
import { useAvailableSubjectsQuery } from '../../../hooks/queries/useEnrolmentQuery';
import yearOptions from '../../../util/constants/yearOptions';
import { api } from '../../../api';

const stateOptions = [
  {
    text: 'New South Wales',
    value: 'New South Wales',
  },
  {
    text: 'Australian Capital Territory',
    value: 'Australian Capital Territory',
  },
  {
    text: 'Western Australia',
    value: 'Western Australia',
  },
  {
    text: 'South Australia',
    value: 'South Australia',
  },
  {
    text: 'Northern Territory',
    value: 'Northern Territory',
  },
  {
    text: 'Queensland',
    value: 'Queensland',
  },
  {
    text: 'Tasmania',
    value: 'Tasmania',
  },
  {
    text: 'Victoria',
    value: 'Victoria',
  },
];

const FormAddNewEnrolment = ({ country, isNZUser, selectedStudent, isSubmitting, submitError, errorMsg, submit }) => {
  const history = useHistory();
  const { data, isLoading: isStudentsLoading, isSuccess } = api.student.all.useQuery();
  const students = data || [];
  const initialStudent = students.find((student) => student.studentId === history.location.state?.studentId);
  const mappedStudents = students.map((student) => ({
    text: `${student.firstName} ${student.lastName}`,
    value: student.studentId,
  }));
  const studentDropdownProps = useDropdownProps({
    id: 'select-student',
    options: mappedStudents,
    labelText: 'Select student',
    placeholder: 'Select student',
    defaultValue: initialStudent?.studentId,
  });

  const selectYearProps = useDropdownProps({
    id: 'select-year',
    options: isNZUser ? yearOptions.NEW_ZEALAND : yearOptions.AUSTRALIA,
    labelText: 'Select year',
    placeholder: 'Select a year',
    defaultValue: history.location.state?.yearLevel,
  });

  const { isLoading: subjectOptionsLoading, data: subjectOptions } = useAvailableSubjectsQuery({
    year: selectYearProps.selectedOption?.split(' ')[1] ?? 11,
    country,
  });

  const subjectsSelection = Array.isArray(subjectOptions) ? subjectOptions : [];
  const selectSubjectProps = useDropdownProps({
    id: 'select-subject',
    options: subjectsSelection?.map((op) => ({ text: op, value: op })) ?? [],
    labelText: 'Select subject',
    placeholder: 'Select a subject',
    defaultValue: capitalize(history.location.state?.subject || ''),
  });

  const selectStateProps = useDropdownProps({
    id: 'select-state',
    options: stateOptions,
    labelText: 'Select state',
    placeholder: 'Select a state',
    defaultValue: history.location.state?.location,
  });

  const textAreaProps = useTextAreaProps({
    id: 'text-tell-us-something',
    labelText:
      "Please tell us the date you would like to start your sessions and your preferred day of the week and time. If there is anything else you'd like to tell us, please also include below.",
  });

  if (selectedStudent === null || selectedStudent === undefined) {
    history.goBack();
    return null;
  }

  const { student, enrolmentId } = selectedStudent;
  const dropdownPreSelected = enrolmentId === UNIQUE_ENROLMENT_ID_FOR_NEW_STUDENT;
  const studentDropdownSelection = students.find(
    (student) => student.studentId === studentDropdownProps.selectedOption
  );
  const firstname = dropdownPreSelected ? studentDropdownSelection?.firstName || '' : student;
  const lastname = dropdownPreSelected ? studentDropdownSelection?.lastName || '' : student;
  const selectedYear = selectYearProps.selectedOption;
  const selectedSubject = selectSubjectProps.selectedOption;
  const selectedState = selectStateProps.selectedOption;
  const message = textAreaProps.value;

  const onClickHandler = () => {
    const caseType = 'Onboarding';
    const caseCategory = 'Existing customer';
    const caseSubCategory = 'New enrolment';
    const studentName = dropdownPreSelected ? `${firstname} ${lastname}` : student;
    const caseDescription = `New${
      selectedState ? ` ${selectedState}` : ''
    } ${selectedYear} ${selectedSubject} enrolment requested for ${studentName}.
    Other details: ${message}`;

    const requestPayload = {
      message: caseDescription,
      caseSubject: `Contact request - ${caseType} - ${caseCategory} - ${caseSubCategory}`,
      caseType,
      caseCategory,
      caseSubCategory,
      onSubmitSuccess: () => {
        history.push(PATH_HUB_CR_SUBMIT_CASE_SUCCESS, {
          studentId: studentDropdownSelection.studentId,
          firstName: studentDropdownSelection.firstName,
          message: `We'll be enrolling ${studentDropdownSelection.firstName} into ${
            isNZUser ? '' : selectedState
          } ${selectedYear} ${selectedSubject}. We'll be in touch soon.`,
        });
      },
    };

    if (!dropdownPreSelected) {
      requestPayload.uniqueEnrolmentId = enrolmentId;
    }
    submit(requestPayload);
  };

  return (
    <ContactFormLayout selectedStudent={selectedStudent}>
      <Pending loading={subjectOptionsLoading} loader={<Loader />}>
        <div className="px-5 pt-5 lg:px-20 lg:pt-12">
          <FormSubHeading />
          {submitError && errorMsg && <ErrorBlock errorMsg={errorMsg} />}
          <Form>
            {isStudentsLoading ? <Loader /> : null}
            {dropdownPreSelected && isSuccess && (
              <div className="mb-6">
                <Dropdown {...studentDropdownProps} />
              </div>
            )}
            <div className="mb-6 items-center lg:flex">
              <div className="mb-6 w-full lg:mb-0 lg:mr-3 lg:w-1/3">
                <Dropdown {...selectYearProps} />
              </div>
              <div className="mb-6 w-full lg:mb-0 lg:mr-3 lg:w-1/3">
                <Dropdown {...selectSubjectProps} />
              </div>
              {!isNZUser && (
                <div className="w-full lg:w-1/3">
                  <Dropdown {...selectStateProps} />
                </div>
              )}
            </div>
            <TextArea {...textAreaProps} className="mb-6" />
          </Form>
          <div className="mb-4 text-center tracking-normal lg:mb-8">
            <RestrictedButton
              buttonText="Request change"
              truthyChecks={[
                firstname.length > 0,
                lastname.length > 0,
                !!selectedYear,
                !!selectedSubject,
                /* At the moment, NZ customers may not be able to select a specific state  */
                isNZUser ? true : !!selectedState,
                !isSubmitting,
              ]}
              onClick={() => {
                onClickHandler();
              }}
            />
          </div>
        </div>
      </Pending>
    </ContactFormLayout>
  );
};

FormAddNewEnrolment.propTypes = {
  country: PropTypes.string.isRequired,
  isNZUser: PropTypes.bool.isRequired,
  selectedStudent: PropTypes.shape({
    student: PropTypes.string.isRequired,
    enrolmentId: PropTypes.string.isRequired,
  }).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitError: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    const { ui } = state;
    const { country } = state.hubAccountPage.accountDetails;
    return {
      country,
      isNZUser: isNZCheck({ country }),
      selectedStudent: getSelectedStudent(state),
      isSubmitting: ui.apiState.hubContactPage.isLoading,
      submitError: ui.apiState.hubContactPage.fetchError,
      errorMsg: ui.apiState.hubContactPage.errorMsg,
    };
  },
  (dispatch) => {
    return {
      submit: bindActionCreators(submitCaseToSF, dispatch),
    };
  }
)(FormAddNewEnrolment);

export { FormAddNewEnrolment as StatelessFormAddNewEnrolment };
