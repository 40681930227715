import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { mapFeedbackResponseToEmojiName } from '../../util/constants';
import { EmojiRatingModal } from '../EmojiRatingModal/EmojiRatingModal';

export class StudentFeedback extends React.Component {
  render() {
    const {
      feedbackText,
      studentName,
      isEmoji,
      rating,
      openEmojiModal,
      isEmojiModalOpen,
      closeEmojiModal,
      showStudentNoRatingMessage,
    } = this.props;
    const textWithBr =
      feedbackText && feedbackText.trim().replace(/\n/g, '<br />');
    const wasStudentFeedbackLeft = !!rating;
    return (
      <Fragment>
        <div>
          <div className="card-subTitle pt-3 font-bold">STUDENT FEEDBACK</div>
          {/* eslint-disable-next-line */}
          {textWithBr && (
            <div
              className="break-words"
              dangerouslySetInnerHTML={{
                __html: `&ldquo;${textWithBr}&rdquo;`,
              }}
            />
          )}
          {!textWithBr && (
            <div>
              {studentName} did not provide any feedback for the session.
            </div>
          )}
        </div>
        <div className="mr-4 flex whitespace-nowrap pt-4 md:ml-auto">
          {isEmoji && rating && (
            <Fragment>
              <img
                src={`https://static.clueylearning.com/emoji/emoji-${mapFeedbackResponseToEmojiName(
                  rating
                )}.svg`}
                alt="emoji"
                className="emoji-item"
              />
              <div className="flex flex-col items-center pl-1">
                <div>&ldquo;{rating}!&rdquo;</div>
                <button
                  type="button"
                  className="btn btn-link emoji-modal"
                  onClick={openEmojiModal}
                >
                  (What&rsquo;s this?)
                </button>
              </div>
            </Fragment>
          )}
          {!isEmoji && rating && (
            <div className="flex flex-col pl-1">
              <div className="card-subTitle pt-3 font-bold">STUDENT RATING</div>
              <div>{rating}/5</div>
            </div>
          )}
        </div>
        {!wasStudentFeedbackLeft && showStudentNoRatingMessage && (
          <div className="whitespace-nowrap md:ml-auto">
            <div className="card-subTitle pt-3 font-bold">STUDENT RATING</div>
            Not provided
          </div>
        )}
        {isEmoji && (
          <EmojiRatingModal
            open={isEmojiModalOpen}
            closeModalHandler={closeEmojiModal}
          />
        )}
      </Fragment>
    );
  }
}

StudentFeedback.propTypes = {
  feedbackText: PropTypes.string,
  studentName: PropTypes.string.isRequired,
  rating: PropTypes.string,
  isEmoji: PropTypes.bool,
  openEmojiModal: PropTypes.func,
  closeEmojiModal: PropTypes.func,
  isEmojiModalOpen: PropTypes.bool,
  showStudentNoRatingMessage: PropTypes.bool,
};

StudentFeedback.defaultProps = {
  feedbackText: null,
  rating: null,
  isEmoji: null,
  openEmojiModal: () => undefined,
  closeEmojiModal: () => undefined,
  isEmojiModalOpen: false,
  showStudentNoRatingMessage: true,
};
