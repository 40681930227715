/*
 * Since customers are redirected to hub, this is deprecated. (Not 100% sure)
 * Will remove this component if QA find no issues/usage.
 * */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import { REFUND_TYPES, RESCHEDULE_PAGE_REQUEST_TYPES } from '../../util/constants';
import { getSessionDetail } from '../../util/helpers';
import GoBack from './GoBack';
import ContactScreenWrap from './ContactScreenWrap';
import { SessionInfo } from './ContactChangeSession';
import ContactPhone from './ContactPhone';
import { TRACKING_SS } from '../../util/trackingClasses';

const getPageProps = (requestType, changedSession) => {
  const defaultPageProps = {
    screenTitle: 'Your message has been sent',
    textContent: 'Someone from the Customer Care team will be in touch shortly.',
    showMailIcon: true,
    sessionInfoHeader: '',
  };

  switch (requestType) {
    case RESCHEDULE_PAGE_REQUEST_TYPES.RESCHEDULE:
      return {
        ...defaultPageProps,
        screenTitle: 'Your request is being processed',
        textContent:
          'Sit tight! We’ll send you an email confirming your new session time in a few minutes. Have a great session!',
        showMailIcon: false,
        sessionInfoHeader: 'Your requested next session is',
      };
    case RESCHEDULE_PAGE_REQUEST_TYPES.CANCEL:
      if (isEmpty(changedSession)) {
        return {
          ...defaultPageProps,
          screenTitle: 'Your session has been skipped',
          textContent: 'Have a great day!',
          showMailIcon: false,
          sessionInfoHeader:
            'This is the last session in your enrolment - please contact Cluey if you wish to re-enrol.',
        };
      }

      return {
        ...defaultPageProps,
        screenTitle: 'Your session has been skipped',
        textContent: 'Have a great day!',
        showMailIcon: false,
        sessionInfoHeader: 'YOUR NEXT LEARNING SESSION',
      };
    default:
      return defaultPageProps;
  }
};

const ContactSuccess = ({ requestType, changedSession, studentName, timezone, subject, refundPolicy }) => {
  const { screenTitle, textContent, showMailIcon, sessionInfoHeader } = getPageProps(requestType, changedSession);

  const isLastSessionCancelled = isEmpty(changedSession);

  const changedSessionInfo = !showMailIcon && !isLastSessionCancelled ? getSessionDetail(changedSession, timezone) : '';

  const infoHeaderClasses = classNames(
    'mb-2 text-sm font-bold',
    { 'md:text-base uppercase': !isLastSessionCancelled },
    { 'md:text-lg lg:text-xl': isLastSessionCancelled }
  );
  const title = studentName && subject ? `${studentName}, ${subject}` : '';
  const skipWithNoRefundNoReschedule =
    [
      REFUND_TYPES.NO_REFUND_NO_RESCHEDULE,
      REFUND_TYPES.NO_REFUND_RESCHEDULED_ONCE,
      REFUND_TYPES.NO_REFUND_YES_RESCHEDULE,
    ].findIndex((policy) => policy === refundPolicy) >= 0;
  return (
    <Fragment>
      <GoBack title={title} />
      <ContactScreenWrap ciClass="ci--tick" h1Text={screenTitle}>
        <p
          className={classNames(
            'mx-auto mb-2',
            refundPolicy && TRACKING_SS.DEFAULT,
            skipWithNoRefundNoReschedule && TRACKING_SS.NO_REFUND
          )}
        >
          {textContent}
        </p>
        {showMailIcon && <div className="mail mx-auto mt-10" />}
        {!showMailIcon && (
          <SessionInfo
            title={sessionInfoHeader}
            titleClasses={infoHeaderClasses}
            selectedSessionDetail={changedSessionInfo}
          />
        )}
      </ContactScreenWrap>
      <ContactPhone />
    </Fragment>
  );
};

ContactSuccess.propTypes = {
  requestType: PropTypes.string,
  changedSession: PropTypes.shape({
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    tutorName: PropTypes.string,
    sessionId: PropTypes.string,
  }),
  studentName: PropTypes.string,
  timezone: PropTypes.string,
  subject: PropTypes.string,
  refundPolicy: PropTypes.string,
};

ContactSuccess.defaultProps = {
  requestType: '',
  changedSession: {
    startTime: '',
    endTime: '',
    tutorName: '',
    sessionId: '',
  },
  studentName: '',
  timezone: '',
  subject: '',
  refundPolicy: '',
};

export default connect((state) => {
  const { ui, rescheduleSession } = state;
  const { studentName, timezone, subject, changedSession, refundPolicy } = rescheduleSession;
  return {
    requestType: ui.apiState.contactUsFlow.requestType,
    changedSession,
    studentName,
    timezone,
    subject,
    refundPolicy,
  };
}, null)(ContactSuccess);

export { ContactSuccess as PresentationalContactSuccess };
