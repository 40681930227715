import { Button } from '@cluey/cluey-components';
import iconInformation from '../../../assets/images/icon-information.svg';
import { useContext, useState } from 'react';
import { usePostHogCapture } from '../../../util/posthog';
import { useRocketChatContext } from '../../../util/RocketChat/RocketChatProvider';
import SessionMessageForm from './SessionMessageForm';
import RadioButton from './RadioButton';
import { SessionMessageContext } from './SessionMessage';

const SessionSpecificToggle = () => {
	const capture = usePostHogCapture();
	const { isSubmitting } = useContext(SessionMessageContext);
	const { maximizeWidget } = useRocketChatContext();

	const [isSessionSpecific, setIsSessionSpecific] = useState(null);

	const handleRadioChange = (event) => {
		const value: 'yes' | 'no' = event.target.value;
		capture(`click_add-session-message_is-session-specific-${value}`, {
			value,
		});

		setIsSessionSpecific(value === 'yes');
	};

	const handleOpenSupportChat = () => {
		capture('click_add-session-message_open-support-chat');
		maximizeWidget();
	};

	return (
		<div>
			<div className="mb-4 text-lg font-bold">Is your message just for this session?</div>
			<div className="flex flex-col gap-y-4">
				<RadioButton
					disabled={isSubmitting}
					id="session-specific_yes"
					name="session-specific"
					value="yes"
					checked={isSessionSpecific === true}
					onChange={handleRadioChange}
					label="Yes, my message is just for this session. For example, let the tutor know about specific topics to focus on."
				/>
				<RadioButton
					disabled={isSubmitting}
					id="session-specific_no"
					name="session-specific"
					value="no"
					checked={isSessionSpecific === false}
					onChange={handleRadioChange}
					label="No, my message is about something else"
				/>
				{/* Don't render anything if isSessionSpecific is null (initial state, no radio button selected) */}
				{isSessionSpecific === true && (
					<>
						<div className="rounded-lg bg-blue-1 px-7 py-4">
							<div className="flex flex-col gap-y-4">
								<div className="flex flex-row gap-x-4">
									<img src={iconInformation} alt="Information icon" height={20} width={20} />
									<b>Your feedback will only be visible to the tutor attending the upcoming session</b>
								</div>
							</div>
						</div>
						<SessionMessageForm />
					</>
				)}
				{isSessionSpecific === false && (
					<div className="rounded-lg bg-blue-1 px-7 py-4">
						<div className="flex flex-col gap-y-4">
							<div className="flex flex-row gap-x-4">
								<img src={iconInformation} alt="Information icon" height={20} width={20} />
								<b>Cluey support chat can assist you</b>
							</div>
							<div>Ask a question, leave a message or chat with a support specialist.</div>
							<div>
								<Button appearance="reverse" size="xs" onClick={handleOpenSupportChat}>
									Open support chat
								</Button>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default SessionSpecificToggle;
