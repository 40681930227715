import React from 'react';
import moment from 'moment';
import { DATE_FORMAT_TYPE_2 } from './constants';
import {
  INCENTIVE_JANUARY_RESTARTER_PACK,
  INCENTIVE_FEBRUARY_RESTARTER_PACK,
  SUMMER_15PCT_ALL_SESSIONS,
} from './constants/incentives';
import {
  PATH_HUB_CR_CANCEL_ENROLMENT,
  PATH_HUB_CR_PAUSE_SESSIONS,
  PATH_HUB_ENROLMENT_CHANGE_SCHEDULE,
  PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP,
  PATH_HUB_ENROLMENT_MANAGE_PAUSE,
} from './pagePath';
import { SUMMER_OFF_BOARDING_END_DATE } from './flags';

/**
 * @readonly
 * @typedef
 */
export const SELECTED_SUMMER_MODE = {
  MAINTAIN: 'Maintain frequency (1-to-1)',
  INCREASE: 'Increase frequency (1-to-1)',
  DECREASE: 'Decrease frequency (1-to-1)',
  GROUP: 'Group Maintain frequency',
  PAUSE: 'Pause',
};

/**
 * @readonly
 * @typedef
 */
export const SUMMER_MODE_FREQUENCY = {
  MULTIPLE: 'Multiple sessions',
  WEEKLY: 'Weekly',
  FORTNIGHTLY: 'Fortnightly',
};

export const completedSummerModeCopy = (enrolment, isDetailPage = false) => {
  if (enrolment.selectedSummerMode === SELECTED_SUMMER_MODE.PAUSE) {
    // is Pausing
    const pauseFrom = moment(enrolment.summerPauseFrom).format(DATE_FORMAT_TYPE_2);
    const pauseTo = moment(enrolment.summerPauseTo).add(1, 'day').format(DATE_FORMAT_TYPE_2);

    const bodyText = isDetailPage
      ? 'This enrolment will be paused'
      : `${enrolment.name}'s ${enrolment.subject} enrolment will be paused`;
    return {
      body: (
        <>
          {bodyText} <span className="font-weight-bold">from {pauseFrom}</span> and resume{' '}
          <span className="font-weight-bold">on {pauseTo}</span>
        </>
      ),
      action: enrolment.selectedSummerMode,
      id: enrolment.uniqueEnrolmentId,
    };
  }
  if (enrolment.selectedSummerMode === SELECTED_SUMMER_MODE.GROUP) {
    // is Group maintain
    const newScheduleStartTime = moment
      .utc(enrolment.summerModeNewScheduleStartTime, 'hh:mm')
      .tz('Australia/Sydney')
      .format('h:mma');
    /*
      the body must be aligned with NextYearEnrolmentsAlerts component
      the Summer Mode New Schedule Start Time is in UTC in Sales force; convert it to Australia time
    */
    const nextYearStartDate = moment(enrolment.summerModeAdditionalDetails);
    const body = isDetailPage
      ? `From ${nextYearStartDate.format('MMM D')} ${enrolment.name} will continue weekly ${
          enrolment.discipline
        } group sessions ${enrolment.summerModeNewScheduleDay}s at ${newScheduleStartTime}`
      : `From Jan 1 to Jan 31, ${enrolment.name} will continue weekly ${enrolment.subject} group sessions`;
    return {
      body,
      title: 'Holiday Learning',
      subtitle: `${enrolment.summerModeNewScheduleDay}s at ${newScheduleStartTime}`,
      action: enrolment.selectedSummerMode,
      id: enrolment.uniqueEnrolmentId,
    };
  }
  if (enrolment.selectedSummerMode && enrolment.summerModeFrequency) {
    // is Private 1-1
    const frequencyLower = enrolment.summerModeFrequency.toLowerCase();
    if (frequencyLower === SUMMER_MODE_FREQUENCY.WEEKLY || frequencyLower === SUMMER_MODE_FREQUENCY.FORTNIGHTLY) {
      // will be going to / continuing at weekly or fortnightly
      const newScheduleStartTime = moment(enrolment.summerModeNewScheduleStartTime, 'HH:MM').format('h:mma');

      const body = `From ${enrolment.summerPauseFrom ? 'Jan 1' : 'Dec 18'} to Jan 31, ${enrolment.name} will be 
      ${isDetailPage ? 'learning' : enrolment.subject} ${enrolment.summerModeFrequency}`;

      return {
        body,
        title: 'Holiday Learning',
        subtitle: `${enrolment.summerModeNewScheduleDay}s at ${newScheduleStartTime}`,
        action: enrolment.selectedSummerMode,
        id: enrolment.uniqueEnrolmentId,
      };
    }
    // will be doing twice weekly or multiple sessions
    return {
      title: 'Holiday Learning',
      body: `From ${enrolment.summerPauseFrom ? 'Jan 1' : 'Dec 18'} to Jan 31, ${enrolment.name} will continue ${
        isDetailPage ? 'learning' : enrolment.subject
      } sessions`,
      subtitle: 'Your schedule for summer will be updated accordingly',
      action: enrolment.selectedSummerMode,
      id: enrolment.uniqueEnrolmentId,
    };
  }
  // Couldn't figure it out, so here's an empty string
  return null;
};

const promoByCode = {
  [INCENTIVE_JANUARY_RESTARTER_PACK]: {
    getPromoText: (confirming) =>
      confirming
        ? 'Sessions in January will be 20% off'
        : 'Get 20% off sessions in January if you restart before Term 1',
    validate: (date) => moment(date).isSameOrBefore(moment('2022-01-30')),
  },
  [INCENTIVE_FEBRUARY_RESTARTER_PACK]: {
    getPromoText: (confirming) =>
      confirming ? 'Sessions in February will be 20% off' : 'Get 20% off sessions in February',
    validate: (date) => moment(date).isSameOrBefore(moment('2022-02-28')),
  },
  [SUMMER_15PCT_ALL_SESSIONS]: {
    getPromoText: (confirming) =>
      confirming ? 'Sessions in January will be 15% off*' : 'Get 15% off all January sessions*',
    validate: (date, options) => {
      let expiryDate = '';

      if (options?.screen === PATH_HUB_CR_CANCEL_ENROLMENT) {
        expiryDate = SUMMER_OFF_BOARDING_END_DATE;
      }
      if (
        options?.screen === PATH_HUB_ENROLMENT_CHANGE_SCHEDULE ||
        options?.screen === PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP ||
        options?.screen === PATH_HUB_ENROLMENT_MANAGE_PAUSE ||
        options?.screen === PATH_HUB_CR_PAUSE_SESSIONS ||
        options?.screen === 'new-year-enrolment-detail'
      ) {
        expiryDate = '2023-01-31';
      }
      return moment(date).isSameOrBefore(moment(expiryDate), 'day');
    },
  },
};

/**
 * Determine whether incentive code is valid
 * @param {string} incentiveCode
 * @param {string} chosenISOTime
 * @param {object} options
 * @returns {boolean}
 */
export const isIncentiveValid = (incentiveCode, chosenISOTime, options) => {
  if (promoByCode[incentiveCode]) {
    return promoByCode[incentiveCode].validate(chosenISOTime, options);
  }

  return false;
};

/**
 * Determine whether incentive code is active
 * @param {string} incentiveCode
 * @param {object} options
 * @returns {boolean}
 */
export const isIncentiveActive = (incentiveCode, options) =>
  isIncentiveValid(incentiveCode, moment().toISOString(), options);

/**
 * Get corresponding copy for restarter incentive code
 * @param {string} incentiveCode
 * @param {boolean} [confirming]
 * @returns {string}
 */
export const restarterIncentiveCopy = (incentiveCode, confirming) => {
  if (promoByCode[incentiveCode]) {
    return promoByCode[incentiveCode].getPromoText(confirming);
  }

  return promoByCode[SUMMER_15PCT_ALL_SESSIONS].getPromoText(confirming);
};
