import { useQuery } from '@tanstack/react-query';
import { minBy, orderBy } from 'lodash';
import moment from 'moment';
import { InvoiceService } from '../../services/InvoicesService';
import { GET_INVOICE_HISTORY_QKEY } from '../../util/constants/queryKeys';

export const mapInvoiceHistoryResponse = (data = []) => {
  if (data) {
    const invoiceHistory = orderBy(
      [
        ...(data.c2gInvoice?.map((invoice) => ({ ...invoice, type: 'legacy' })) || []),
        ...(data.zuoraInvoice?.map((invoice) => ({ ...invoice, type: 'zuora' })) || []),
      ],
      'invoiceDate',
      'desc'
    );

    const nextChargeDate = minBy(
      data.nextChargeDates?.map((item) => item.nextChargeDate),
      (item) => moment(item).unix()
    );

    return {
      invoiceHistory,
      nextChargeDate,
    };
  } else {
    return {
      invoiceHistory: [],
      nextChargeDate: '',
    };
  }
};

const useInvoiceHistoryQuery = (enabled = true) => {
  const query = useQuery({
    queryKey: [GET_INVOICE_HISTORY_QKEY],
    queryFn: () => InvoiceService.getInvoiceHistory(),
    staleTime: 600000,
    enabled,
    retry: 0,
  });

  const data = query?.data ?? [];
  const mappedData = mapInvoiceHistoryResponse(data);

  return {
    ...query,
    mappedData,
  };
};

export default useInvoiceHistoryQuery;
