import { IconProps as Props } from "../types/hubInterfaces";
import { iconDefaults } from ".";
import classNames from 'classnames';
const ComputerIcon = (props: Props) => {
  const { width, height, className } = { ...iconDefaults, ...props };
  return (
    <svg className={classNames('fill-current', className)} xmlns="http://www.w3.org/2000/svg" width={`${width}`} height={`${height}`} viewBox="0 0 28 28">
      <title>computer icon</title>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.6811 20.7896C17.6811 20.4715 17.9388 20.2137 18.2569 20.2137H24.9039C25.5655 20.2133 26.1018 19.6769 26.1018 19.0152V6.33229C26.1018 5.67017 25.565 5.1333 24.903 5.1333H3.09704C2.43498 5.1333 1.89861 5.67017 1.89861 6.33229V19.0152C1.89861 19.6769 2.43453 20.2133 3.09614 20.2137H9.74355C10.0612 20.2137 10.3189 20.4715 10.3189 20.7896C10.3189 21.1073 10.0612 21.3655 9.74355 21.3655H6.71911V21.3733H0.465124C0.208285 21.3733 0 21.5811 0 21.8384V22.8686C0 23.125 0.208285 23.3333 0.465124 23.3333H27.5353C27.7917 23.3333 28 23.125 28 22.8686V21.8384C28 21.5811 27.7917 21.3733 27.5353 21.3733H21.2813V21.3655H18.2569C17.9388 21.3655 17.6811 21.1073 17.6811 20.7896Z" />
    </svg>
  )
}
export { ComputerIcon };