import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { SUBJECT_MATHS, SUBJECT_ENGLISH, STRAND_SECTION_HEADER_KICKOFF_KEY } from '../../util/constants';

const sectionTitlesBySubject = {
  [SUBJECT_MATHS]: {
    1: 'Number systems',
    2: 'Addition and subtraction',
    3: 'Multiplication and division',
  },
  [SUBJECT_ENGLISH]: {
    1: 'Conventions of grammar and punctuation',
    2: 'Conventions of language and literature',
    3: 'Reading, comprehension and writing',
  },
  [STRAND_SECTION_HEADER_KICKOFF_KEY]: {
    [SUBJECT_MATHS]: {
      1: 'Operations with numbers',
      2: 'Measurement and Geometry',
      3: 'Statistics and Probability',
    },
    [SUBJECT_ENGLISH]: {
      1: 'Reading',
      2: 'Writing',
      3: 'Conventions of language and literature',
    },
  },
};

export function StrandSection({
  tutorFeedback, studentFeedback, title, hideStudentSection,
}) {
  return (
    <Fragment>
      <div className="w-full my-auto rounded text-center section-title font-bold">{title}</div>
      <div className="my-5 tutor-feedback">{tutorFeedback}</div>
      {!hideStudentSection && (
        <Fragment>
          <div className="student-self-evaluation font-bold">Student self evaluation</div>
          <div className="mb-5 student-feedback">{studentFeedback}</div>
        </Fragment>
      )}
    </Fragment>
  );
}

StrandSection.propTypes = {
  tutorFeedback: PropTypes.string.isRequired,
  studentFeedback: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  hideStudentSection: PropTypes.string.isRequired,
};


export class StrandCard extends React.Component {
  getSectionTitle(subject, index, key) {
    if (!key) {
      return (sectionTitlesBySubject[subject] && sectionTitlesBySubject[subject][index]) || '';
    }
    return (sectionTitlesBySubject[key][subject] && sectionTitlesBySubject[key][subject][index]) || '';
  }

  render() {
    const {
      tutorFeedback1,
      tutorFeedback2,
      tutorFeedback3,
      studentFeedback1,
      studentFeedback2,
      studentFeedback3,
      subject,
      studentName,
      title,
      hideStudentSection,
      sectionHeadersKey,
    } = this.props;
    const defaultStudentFeedback = `${studentName} did not provide any feedback`;
    return (
      <div className="pb-3 strand">
        <div className="pt-3 pb-2 card-subTitle font-bold">{title}</div>
        {tutorFeedback1 && (
          <StrandSection
            studentFeedback={studentFeedback1 || defaultStudentFeedback}
            title={this.getSectionTitle(subject, 1, sectionHeadersKey)}
            tutorFeedback={tutorFeedback1}
            hideStudentSection={hideStudentSection}
          />
        )}

        {tutorFeedback2 && (
          <StrandSection
            studentFeedback={studentFeedback2 || defaultStudentFeedback}
            title={this.getSectionTitle(subject, 2, sectionHeadersKey)}
            tutorFeedback={tutorFeedback2}
            hideStudentSection={hideStudentSection}
          />
        )}

        {tutorFeedback3 && (
          <StrandSection
            studentFeedback={studentFeedback3 || defaultStudentFeedback}
            title={this.getSectionTitle(subject, 3, sectionHeadersKey)}
            tutorFeedback={tutorFeedback3}
            hideStudentSection={hideStudentSection}
          />
        )}
      </div>
    );
  }
}

StrandCard.propTypes = {
  tutorFeedback1: PropTypes.string,
  tutorFeedback2: PropTypes.string,
  tutorFeedback3: PropTypes.string,
  studentFeedback1: PropTypes.string,
  studentFeedback2: PropTypes.string,
  studentFeedback3: PropTypes.string,
  subject: PropTypes.string.isRequired,
  studentName: PropTypes.string.isRequired,
  title: PropTypes.string,
  hideStudentSection: PropTypes.bool,
  sectionHeadersKey: PropTypes.string,
};

StrandCard.defaultProps = {
  tutorFeedback1: null,
  tutorFeedback2: null,
  tutorFeedback3: null,
  studentFeedback1: null,
  studentFeedback2: null,
  studentFeedback3: null,
  hideStudentSection: false,
  title: 'PROGRAM REPORT',
  sectionHeadersKey: null,
};
