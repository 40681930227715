import { Container, Pending } from '@cluey/cluey-components';
import { CountryTabs } from '../../common/CountryTabs/CountryTabs';
import EnrolmentLayout from '../../common/hub/EnrolmentLayout';
import Frame from 'react-frame-component';
import { getAccountDetails } from '../../selectors/hubSelector';
import { Loader } from '../../components/Loader';
import { PATH_HUB_SUPPORT } from '../../util/pagePath';
import { POLICY_NAME_MAP } from '../../types/hubEnums';
import { AccountDetails, PolicyType } from '../../types/hubInterfaces';
import { useEffect, useRef, useState } from 'react';
import { useGetPolicy } from '../../hooks/queries/usePolicyQuery';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { getWebsiteDomainByCountry } from '../../util/helper/website';

const Policy = () => {
  const { policyType } = useParams<PolicyType>();
  const { country }: AccountDetails = useSelector(getAccountDetails);
  const containerRef = useRef<HTMLDivElement>();
  const [frameHeight, setFrameHeight] = useState(0);
  const [frameMounted, setFrameMounted] = useState(Date.now())
  const [policyCountry, setPolicyCountry] = useState(country);
  const { data, isError, isLoading } = useGetPolicy({ country: policyCountry, policyType });

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(_ => {
        const { height } = containerRef.current.getBoundingClientRect();
        setFrameHeight(height + 50);
      });
      resizeObserver.observe(containerRef.current);
    }
  }, [frameMounted])

  const onPolicyContainerClick = (event) => {
    event.preventDefault();
    let target = event.target as HTMLAnchorElement;
    if (target.tagName === 'A') {
      let url = target.getAttribute('href');
      /* handle hrefs that are relative */
      if (!target.getAttribute('href').match(/(mailto|http)/)) {
        url = `${getWebsiteDomainByCountry(policyCountry)}${url}`;
      }
      let context = url.includes('mailto') ? '_self' : '_blank';
      window.open(url, context);
    }
  };

  return (
    <EnrolmentLayout
      sectionHeading="Support"
      sectionHeadingLink={PATH_HUB_SUPPORT}
      mainHeading={POLICY_NAME_MAP[policyType]}
      showEnrolmentStatus={false}
      showSummerModeEnrolmentStatus={false}
    >
      <Container className='flex flex-col gap-y-10'>
        <Pending error={isError || (data?.data?.status && data?.data?.status !== 200)} loading={isLoading} loader={<Loader />}>
          <CountryTabs activeCountry={policyCountry} onTabClick={(country) => setPolicyCountry(country)} />
          {/* 
            contentDidMount is invoked every time a different iframe content is loaded;
            the resize observer must be re-initialized thus
          */}
          <Frame spellCheck={false} width="100%" height={`${frameHeight}px`} contentDidMount={() => setFrameMounted(Date.now())}>
            <link rel="stylesheet" href="https://clueylearning.com.au/wp-content/themes/clueytheme/style.css" />
            <div
              ref={containerRef}
              dangerouslySetInnerHTML={{ __html: data?.content?.rendered }}
              onClick={onPolicyContainerClick}
              /* Can't use tailwind here since this element is in a different browsing context */
              style={{ outline: 'none' }}
            ></div>
          </Frame>
        </Pending>
      </Container>
    </EnrolmentLayout>
  )
}
export { Policy }