import checkIcon from '../../../../../assets/images/atoms-icon-filled-alert-tick-orange.svg'
import { Button, Link } from '@cluey/cluey-components';
import { getRescheduleSessionRoute } from '../../../../../util/helper';
import { SessionDateSummary } from "../../../../../components/EnrolmentManagement/SessionDateSummary";
import type { Session, SessionPolicy } from '../../../../../types/hubInterfaces';

const SessionSummary = ({ sessionPolicy, sessionToSkip, skipFailed, onSkipConfirm, isOneOffRescheduled }: {
  sessionPolicy: SessionPolicy;
  sessionToSkip: Session;
  skipFailed: boolean;
  onSkipConfirm(): void;
  isOneOffRescheduled?: boolean;
}) => {
  return (
    <div className='flex flex-col justify-between border rounded-lg border-grey-2 bg-white mt-6 sm:flex-row'>
      <div className='px-6 py-7 lg:px-14 lg:py-12'>
        <SessionDateSummary
          heading='Cancel session'
          sessionStartDate={sessionToSkip?.startTime}
          sessionEndDate={sessionToSkip?.endTime}
          isOneOffRescheduled={isOneOffRescheduled}
        />
        <Button className='mt-8' disabled={skipFailed} onClick={onSkipConfirm} size="lg">Confirm cancel session</Button>
        <section className='mt-6'>
          <div className='inline-flex flex-col gap-y-2'>
            {sessionPolicy?.reschedule?.isAllowed && (<Link size="lg" to={getRescheduleSessionRoute(sessionToSkip.studentSessionId)}>Reschedule session instead</Link>)}
          </div>
        </section>
      </div>
      <div className="border-t border-grey-2 bg-slate-1 px-6 py-7 lg:w-5/12 lg:border-t-0 lg:border-l lg:px-14 lg:py-12">
        <div className="mb-6 flex items-center border-b border-grey-2 pb-6 lg:mb-7 lg:pb-7">
          <img
            src={`${sessionToSkip.tutorPhoto}?v=1`}
            alt="tutor avatar"
            className="mr-lg-6 mr-5 h-[60px] w-[60px] rounded-full border border-grey-3"
          />
          <span className="lh-1-33 mb-1 text-lg font-bold text-grey-6">
            Let {sessionToSkip?.tutor?.split(" ")[0]} know you can't make it
          </span>
        </div>
        <div className='text-grey-6'>
          <div className='text-xs font-bold uppercase'>Why is this important?</div>
          <div className='mt-3 flex flex-col gap-y-2'>
            {[
              'Opens up their calendar',
              'Avoids tutor waiting in session',
              'Enables them to tutor another student'
            ].map((text, index) => {
              return (
                <div key={`skip-reason-${index}`} className='flex space-between gap-x-2 items-center'>
                  <img src={checkIcon} alt="check-orange" />
                  <div className='text-xs'>{text}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SessionSummary;