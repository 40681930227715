import { SkeletonLoader } from '../../../SkeletonLoader';
import range from 'lodash/range';
import { specialLearningNeeds } from '.';

const SpecialNeedsLoader = () => {
	return (
		<div>
			<SkeletonLoader className="h-6 w-28" loading={true} />
			<SkeletonLoader className="mt-6 h-12 w-64 md:w-80" loading={true} />
			<div className="mt-6 flex flex-col items-start gap-4">
				{range(specialLearningNeeds.length).map((index) => {
					return (
						<div key={index}>
							<SkeletonLoader className="h-8 w-32" loading={true} />
						</div>
					);
				})}
			</div>
		</div>
	);
};

export { SpecialNeedsLoader };
