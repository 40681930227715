import { Fragment, useEffect, useState } from "react";
import { api } from "../../../api";
import HubPageLayout from "../../../common/hub/HubPageLayout";
import InPageLoader from '../../../common/InPageLoader';
import PageError from '../../../common/PageError';
import SessionsPageContentHeadNav from '../../../components/hub/sessions/SessionsPageContentHeadNav';
import HubCompletedSessionsListItem from '../../../components/hub/sessions/HubCompletedSessionsListItem';
import RecordsPagination from '../../../components/hub/RecordsPagination';
import { getSessionsInViewByIndex } from "../../../util/helpers";
import { NO_OF_SESSIONS_PER_PAGE } from '../../../util/constants';
import { orderBy } from "lodash";

const CompletedSessions = () => {
  const [{ allSessions, sessionsInView, totalSessions, startIndex }, setSessionDetails] = useState({
    allSessions: [],
    totalSessions: 0,
    sessionsInView: [],
    startIndex: 0,
  });

  const { 
    data: completedSessions = [],
    isLoading: loadingCompletedSessions,
    isError: errorLoading
   } = api.session.all.useQuery('completed');
  const { data: accountDetails, isLoading: loadingAccountDetails } = api.account.details.useQuery();
  const timezone = accountDetails?.timezone ?? 'Australia/Sydney';

  useEffect(() => {
    if (completedSessions.length > 0 && allSessions.length === 0) {
      const sortedSessions = orderBy(
        completedSessions,
        'startTime',
        'desc'
      );

      const sessionsInView = getSessionsInViewByIndex(
        sortedSessions,
        startIndex
      );

      setSessionDetails(current => {
        return {
          ...current,
          allSessions: sortedSessions,
          totalSessions: sortedSessions.length,
          sessionsInView,  
        }
      })
    }
  }, [allSessions, completedSessions, startIndex])

  const activeSpinner = loadingCompletedSessions || loadingAccountDetails;
  let display = null;
  if (completedSessions?.length === 0 && !activeSpinner) {
    display = <p>You have not completed any sessions.</p>;
  }

  if (Array.isArray(sessionsInView) && sessionsInView.length > 0) {
    display = (
      <ul className="list-none pl-0">
        {sessionsInView.map((s) => (
          <HubCompletedSessionsListItem
            key={s.uniqueEnrolmentId + s.startTime}
            session={s}
            timezone={timezone}
          />
        ))}
      </ul>
    );
  }

  const handlePaginationClick = (newStartIndex) => {
    setSessionDetails(current => {
      return {
        ...current,
        sessionsInView: getSessionsInViewByIndex(current.allSessions, newStartIndex),
        startIndex: newStartIndex,
      }
    })
  };

  return (
    <HubPageLayout>
      {activeSpinner && <InPageLoader position="absolute" />}
      {!activeSpinner && errorLoading && (
        <PageError activePage="hub-page" retryEnabled={false} />
      )}
      {!activeSpinner && !errorLoading && (
        <Fragment>
          <SessionsPageContentHeadNav />
          {display}
          <RecordsPagination
            recordsTotal={totalSessions}
            noOfRecordsPerPage={NO_OF_SESSIONS_PER_PAGE}
            handlePaginationClick={handlePaginationClick}
          />
        </Fragment>
      )}
    </HubPageLayout>
  );
}

export default CompletedSessions;