import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import rick from '../../assets/images/people/hod-primary-english.png';
import brenton from '../../assets/images/people/hod-secondary-english.png';
import voss from '../../assets/images/people/hod-chemistry.png';
import suzanne from '../../assets/images/people/le-secondary-maths.png';

import {
  SUBJECT_CHEMISTRY,
  SUBJECT_ENGLISH,
  SUBJECT_MATHS,
  SUBJECT_BIOLOGY,
  SUBJECT_PHYSICS,
  COUNTRY,
} from '../../util/constants';

export const deptHeads = {
  rick: {
    imgSrc: rick,
    name: 'Rick Molineux',
    title: 'Head of Primary',
    credentials: 'BA MEd',
  },
  suzanne: {
    imgSrc: suzanne,
    name: 'Suzanne Walker',
    title: 'Head of Secondary Mathematics',
    credentials: 'BSc, G.DipEd',
  },
  brenton: {
    imgSrc: brenton,
    name: 'Brenton Boswell',
    title: 'Head of Secondary English',
    credentials: 'BA, LLB, G.Dip Ed, G.Dip Legal Studies',
  },
  voss: {
    imgSrc: voss,
    name: 'Dr Voss Gibson',
    title: 'Head of Science',
    credentials: 'BSc, MSc, PhD',
  },
};

const YEAR_CATEGORY_DEFAULT = 'DEFAULT';
const YEAR_CATEGORY_PRIMARY = 'PRIMARY';
const YEAR_CATEGORY_SECONDARY = 'SECONDARY';
const YEAR_CATEGORY_SENIOR = 'SENIOR';
const YEAR_CATEGORY_INTERMEDIATE = 'INTERMEDIATE';

const expertMapping = {
  [COUNTRY.AUSTRALIA]: {
    [SUBJECT_ENGLISH]: {
      [YEAR_CATEGORY_DEFAULT]: deptHeads.rick,
      [YEAR_CATEGORY_PRIMARY]: deptHeads.rick,
      [YEAR_CATEGORY_SECONDARY]: deptHeads.brenton,
      [YEAR_CATEGORY_SENIOR]: deptHeads.brenton,
    },
    [SUBJECT_MATHS]: {
      [YEAR_CATEGORY_DEFAULT]: deptHeads.rick,
      [YEAR_CATEGORY_PRIMARY]: deptHeads.rick,
      [YEAR_CATEGORY_SECONDARY]: deptHeads.suzanne,
      [YEAR_CATEGORY_SENIOR]: deptHeads.suzanne,
    },
    [SUBJECT_CHEMISTRY]: {
      [YEAR_CATEGORY_DEFAULT]: deptHeads.voss,
    },
    [SUBJECT_BIOLOGY]: {
      [YEAR_CATEGORY_DEFAULT]: deptHeads.voss,
    },
    [SUBJECT_PHYSICS]: {
      [YEAR_CATEGORY_DEFAULT]: deptHeads.voss,
    },
  },
  [COUNTRY.NEW_ZEALAND]: {
    [SUBJECT_ENGLISH]: {
      [YEAR_CATEGORY_DEFAULT]: deptHeads.rick,
      [YEAR_CATEGORY_PRIMARY]: deptHeads.rick,
      [YEAR_CATEGORY_INTERMEDIATE]: deptHeads.rick,
      [YEAR_CATEGORY_SECONDARY]: deptHeads.brenton,
    },
    [SUBJECT_MATHS]: {
      [YEAR_CATEGORY_DEFAULT]: deptHeads.rick,
      [YEAR_CATEGORY_PRIMARY]: deptHeads.rick,
      [YEAR_CATEGORY_INTERMEDIATE]: deptHeads.rick,
      [YEAR_CATEGORY_SECONDARY]: deptHeads.suzanne,
    },
    [SUBJECT_CHEMISTRY]: {
      [YEAR_CATEGORY_DEFAULT]: deptHeads.voss,
    },
    [SUBJECT_BIOLOGY]: {
      [YEAR_CATEGORY_DEFAULT]: deptHeads.voss,
    },
    [SUBJECT_PHYSICS]: {
      [YEAR_CATEGORY_DEFAULT]: deptHeads.voss,
    },
  },
};

const COUNTRY_YEAR_CATEGORY = {
  [COUNTRY.AUSTRALIA]: {
    2: YEAR_CATEGORY_PRIMARY,
    3: YEAR_CATEGORY_PRIMARY,
    4: YEAR_CATEGORY_PRIMARY,
    5: YEAR_CATEGORY_PRIMARY,
    6: YEAR_CATEGORY_PRIMARY,
    7: YEAR_CATEGORY_SECONDARY,
    8: YEAR_CATEGORY_SECONDARY,
    9: YEAR_CATEGORY_SECONDARY,
    10: YEAR_CATEGORY_SECONDARY,
    11: YEAR_CATEGORY_SENIOR,
    12: YEAR_CATEGORY_SENIOR,
  },
  [COUNTRY.NEW_ZEALAND]: {
    3: YEAR_CATEGORY_PRIMARY,
    4: YEAR_CATEGORY_PRIMARY,
    5: YEAR_CATEGORY_PRIMARY,
    6: YEAR_CATEGORY_PRIMARY,
    7: YEAR_CATEGORY_INTERMEDIATE,
    8: YEAR_CATEGORY_INTERMEDIATE,
    9: YEAR_CATEGORY_SECONDARY,
    10: YEAR_CATEGORY_SECONDARY,
    11: YEAR_CATEGORY_SECONDARY,
    12: YEAR_CATEGORY_SECONDARY,
    13: YEAR_CATEGORY_SECONDARY,
  },
};

export function getHeadBySubject({ subject, yearLevel, country = 'au' }) {
  if (!subject || !yearLevel) {
    return {};
  }

  const lowerCasedCountry = (country || '').toLowerCase();
  const lowerCasedSubject = (subject || '').toLowerCase();

  const yearCategory = COUNTRY_YEAR_CATEGORY[lowerCasedCountry][yearLevel] || YEAR_CATEGORY_DEFAULT;
  return (
    expertMapping[lowerCasedCountry][lowerCasedSubject][yearCategory] ||
    expertMapping[lowerCasedCountry][lowerCasedSubject][YEAR_CATEGORY_DEFAULT]
  );
}

export function HeadOfDeptCard({ subject, yearLevel, country, wrapperClasses, imgClasses }) {
  const data = getHeadBySubject({ subject, yearLevel, country });
  if (isEmpty(data)) {
    return null;
  }
  const { imgSrc, name, subject: sub, credentials, title } = data;
  return (
    <div className={wrapperClasses}>
      <div className="flex flex-col pt-6 text-[14px] lg:ml-3">
        <span className="font-bold">{name}</span>
        <span>{title}</span>
        <span>{sub}</span>
        <span>{credentials}</span>
      </div>
      <img src={imgSrc} alt={name} className={`h-[126px] ${imgClasses}`} />
    </div>
  );
}

HeadOfDeptCard.propTypes = {
  subject: PropTypes.string.isRequired,
  yearLevel: PropTypes.number.isRequired,
  country: PropTypes.string,
  wrapperClasses: PropTypes.string,
  imgClasses: PropTypes.string,
};

HeadOfDeptCard.defaultProps = {
  country: COUNTRY.AUSTRALIA,
  wrapperClasses: 'flex w-full lg:w-1/3 justify-between lg:justify-start lg:flex-row-reverse',
  imgClasses: '',
};
