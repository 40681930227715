import { z } from 'zod';
import { ENROLMENT_SESSION_FREQUENCY } from '../../types/hubEnums';
import { getDaysBetweenSessions } from '../../util/helper/enrolment';
import { createApi } from '../core';
import { getTimezoneFromStore } from '../../selectors/hubSelector';
import { isTimeslotInClueyBusinessHour } from '../../util/helper/availability';
import moment from 'moment';
import { SCHEDULE_DATE_FORMAT } from '../../util/constants';

const Tutor = z.object({
  matchingRank: z.number(),
  tutorId: z.string(),
  tutorName: z.string(),
  tutorNumber: z.string(),
});

const Slot = z.object({
  start: z.string(),
  end: z.string(),
  tutorsAvailable: Tutor.array(),
});

export const scheduleApi = createApi.group(['schedule'], (createKey) => {
  return {
    availableSlots: createApi.query({
      queryKey: createKey(['availableSlots']),
      queryFn: async ({
        packageId,
        rescheduleFrom,
        sessionFrequency,
      }: {
        packageId: string;
        rescheduleFrom: string;
        sessionFrequency: ENROLMENT_SESSION_FREQUENCY;
      }) => {
        const daysBetweenSessions = getDaysBetweenSessions({ sessionFrequency });

        const response = await createApi.fetch({
          path: ['matching', 'available-slots-for-package'],
          method: 'POST',
          body: {
            daysBetweenSessions,
            packageId,
            rescheduleFrom: new Date(rescheduleFrom).toISOString(),
          },
          validator: z.object({
            availableTimeslots: Slot.array(),
          }),
          basePath: 'portalAPI',
          sign: true,
        });

        const timezone = getTimezoneFromStore();

        return response.availableTimeslots
          .filter(
            ({ start, end, tutorsAvailable }) =>
              isTimeslotInClueyBusinessHour({ start, end, timezone, convertTimeslotsToUTC: false }) &&
              tutorsAvailable.length
          )
          .map((schedule) => {
            const { matchingRank, tutorId, tutorNumber, tutorName } = schedule.tutorsAvailable[0] || {};
            const [firstname] = tutorName.split(' ');
            const start = moment.utc(schedule.start);
            const end = moment.utc(schedule.end);
            return {
              startDate: start,
              endDate: end,
              firstname,
              scheduleDate: moment.utc(schedule.start).format(SCHEDULE_DATE_FORMAT).toUpperCase(),
              sessionDuration: end.diff(start, 'minutes'),
              tutorId,
              tutorNumber,
              tutorOrder: String(matchingRank),
            };
          });
      },
    }),
  };
});
