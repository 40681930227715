import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { TopBannerLayout } from '../TopBannerLayout';
import { Link } from '@cluey/cluey-components';
import {
  PATH_HUB_ENROLMENT_DETAIL,
  PATH_SCHOOL_BASE,
  PATH_SCHOOL_UPCOMING_SESSIONS,
} from '../../util/pagePath';
import { shouldShowReviewEnrolmentBanner } from '../../selectors/summerModeSelector';

export const ReviewEnrolmentBanner = ({ hideReviewEnrolmentBanner }) => {
  const location = useLocation();
  const { pathname } = location;
  const isSchoolHub = [
    PATH_SCHOOL_BASE,
    PATH_SCHOOL_UPCOMING_SESSIONS,
  ].includes(pathname);
  if (hideReviewEnrolmentBanner || isSchoolHub) {
    return null;
  }

  const left = (
    <p className="m-0 font-display text-xl font-bold text-grey-6">
      New school year starting soon!
    </p>
  );

  const right = (
    <Link
      to={PATH_HUB_ENROLMENT_DETAIL}
      className="font-body font-bold text-primary hover:underline"
    >
      Manage your enrolments and schedules
    </Link>
  );
  return <TopBannerLayout left={left} right={right} />;
};

ReviewEnrolmentBanner.propTypes = {
  hideReviewEnrolmentBanner: PropTypes.bool,
};

ReviewEnrolmentBanner.defaultProps = {
  hideReviewEnrolmentBanner: true,
};

export const ConnectedReviewEnrolmentBanner = connect((state) => {
  return {
    hideReviewEnrolmentBanner: !shouldShowReviewEnrolmentBanner(state),
  };
}, null)(ReviewEnrolmentBanner);
