import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import beginJourneyImage from '../../assets/images/star-image.svg';


class TimelineBegin extends Component {
  render() {
    const {
      name,
    } = this.props;
    return (
      <div className="timeline-begin flex flex-col items-center">
        <h3 className="text-base md:text-2xl text-slate-4 relative tracking-wide font-body">
          {name} began learning with Cluey!
        </h3>
        <img className="mb-8" src={beginJourneyImage} alt="Begin of Journey with Cluey" />
      </div>
    );
  }
}

TimelineBegin.propTypes = {
  name: PropTypes.string.isRequired,
};


function mapStateToProps(state) {
  return {
    name: state.report.studentName,
  };
}

export { TimelineBegin as PresentationalTimelineBegin };
export default connect(mapStateToProps, null)(TimelineBegin);
