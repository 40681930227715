import { forwardRef, type ForwardedRef } from 'react';

type Props = {
	height?: number;
	width?: number;
	title?: string;
	className?: string;
};

const AlertExclamationIconRaw = ({ height, width, title, ...props }: Props, ref: ForwardedRef<SVGSVGElement>) => (
	<svg
		{...props}
		ref={ref}
		height={height || width}
		width={width || height}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 25"
		fillRule="evenodd"
		fill="currentColor"
	>
		{title ? <title>{title}</title> : null}
		<path
			d="M13.091 13.642c0 .604-.489 1.095-1.091 1.095s-1.091-.49-1.091-1.095V9.263c0-.604.489-1.095 1.091-1.095s1.091.49 1.091 1.095v4.38zM12 18.022c-.602 0-1.091-.491-1.091-1.096 0-.604.489-1.094 1.091-1.094s1.091.49 1.091 1.094c0 .605-.489 1.095-1.091 1.095zm11.522-.764l-8.37-13.924C14.498 2.248 13.32 1.6 12 1.6s-2.498.648-3.152 1.734L.478 17.258c-.62 1.032-.638 2.274-.049 3.32.632 1.125 1.86 1.822 3.201 1.822h16.74c1.342 0 2.57-.697 3.2-1.822.59-1.046.571-2.288-.048-3.32z"
			transform="translate(-311 -273) translate(311 273.5)"
		/>
	</svg>
);

export const AlertExclamationIcon = forwardRef(AlertExclamationIconRaw);
