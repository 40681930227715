import React, { type ComponentPropsWithoutRef } from 'react';
import { AlertExclamationIcon } from './AlertExclamationIcon'; 
import classNames from 'classnames';

export type ErrorTextProps = ComponentPropsWithoutRef<'span'> & {
	icon?: boolean;
};

export const ErrorText = ({ className, icon, children, ...props }: ErrorTextProps) => {
	return (
		<span {...props} className={classNames('text-xs text-red-5', { 'flex items-start gap-1': icon }, className)}>
			{icon && <AlertExclamationIcon height={11} className="mt-0.5 inline-block flex-shrink-0 text-red-5" />}
			{children}
		</span>
	);
};

export default ErrorText;
