import React, { Fragment } from 'react';
import { Bar } from 'react-chartjs-2';
import { get } from 'lodash';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SectionHeader from './SectionHeader';
import ModulesList from './ModulesList';
import { SummaryCard } from './SummaryCard';
import { SmallBox } from './SmallBox';
import GraphContainer from './GraphContainer';
import { getPracticeGraphOptions } from './ChartsOptionConfiguration';
import { getPracticeData, getYearLevelAsNumber, hasModules as wereModulesCovered } from '../../selectors/studentProgressSelector';
import { useGraphLegendProps, GraphLegend } from './GraphLegend';
import { getHeadBySubject } from './HeadOfDeptCard';

import alert from '../../assets/images/icon-filled-alert-exclamation.svg';
import redAlert from '../../assets/images/icon-filled-alert-exclamation-red.svg';
import greenTick from '../../assets/images/icon-filled-tick-green.svg';
import DataNotAvailable from './DataNotAvailable';

const THRESHOLD_WARNING = 75;
const THRESHOLD_ERROR = 25;

const MsgWithStaff = ({ message, staffImg }) => {
  return (
    <div className="flex items-end mt-3">
      <p className="pr-4 pr-lg-10 self-baseline">
        “{message}“
      </p>
      {staffImg && <img src={staffImg} alt="staff" className="w-auto h-[126px]" />}
    </div>
  );
};

MsgWithStaff.propTypes = {
  message: PropTypes.string.isRequired,
  staffImg: PropTypes.node.isRequired,
};

const PracticeSummary = ({
  subject,
  yearLevel,
  totalQuestionsAssigned,
  totalQuestionsSubmitted,
  practiceCompletion,
  moduleListData,
  hasModules,
  maxTimeSpent,
  averagePerSession,
  graphData,
  studentName,
  country,
}) => {
  const {
    graphRef, legendItems, setLegendItems, forceUpdate,
  } = useGraphLegendProps();

  const data = getHeadBySubject({ subject, yearLevel, country });
  const { imgSrc } = data;
  const options = getPracticeGraphOptions(maxTimeSpent);
  return (
    <Fragment>
      <SectionHeader
        text="At Cluey, we believe that practice is a critical component of the learning process. Practice enables students to consolidate their learning and build their skills and confidence."
        title="Practice"
      />
      <div className="flex flex-col lg:flex-row mb-5">
        <div className="w-full lg:w-1/2 lg:pr-5 mb-5 lg:mb-0">
          <SummaryCard
            title="Practice questions completion"
            subTitleBold={`${practiceCompletion}%`}
            subTitle={`(${totalQuestionsSubmitted} of ${totalQuestionsAssigned})`}
            className="w-full bg-white min-h-full"
          >
            {practiceCompletion <= THRESHOLD_WARNING && (
              <Fragment>
                <SmallBox
                  iconBackgroundClass="bg-green-1"
                  leftText="Submitted"
                  rightText={`${totalQuestionsSubmitted}`}
                />
                <SmallBox
                  icon={practiceCompletion > THRESHOLD_ERROR ? alert : redAlert}
                  iconBackgroundClass={classNames(practiceCompletion > THRESHOLD_ERROR ? 'bg-yellow-1' : 'bg-red-1')}
                  leftText="Not submitted"
                  rightText={`${totalQuestionsAssigned -
                    totalQuestionsSubmitted}`}
                />
                <MsgWithStaff
                  message={
                    practiceCompletion > THRESHOLD_ERROR ?
                      `This level of completed practice is a good sign of effort and application. 
                      Attempting more practice will build skills and confidence and enable tutors to provide more targeted support.`
                      : `We recommend more focus on practice to build confidence and engagement. 
                      Practice helps to consolidate learning and also enables tutors to identify areas that need more support.`
                  }
                  staffImg={imgSrc}
                />
              </Fragment>
            )}
            {practiceCompletion > THRESHOLD_WARNING && (
              <Fragment>
                <SmallBox
                  icon={greenTick}
                  iconBackgroundClass="bg-green-1"
                  leftText="Submitted"
                  rightText={`${totalQuestionsSubmitted}`}
                />
                <SmallBox
                  iconBackgroundClass="bg-yellow-1"
                  leftText="Not submitted"
                  rightText={`${totalQuestionsAssigned -
                  totalQuestionsSubmitted}`}
                />
                <MsgWithStaff
                  message={
                    `This level of completed practice is a great sign of motivation and commitment.
                    Practice helps to consolidate learning and also enables tutors to identify areas that need more support.`
                  }
                  staffImg={imgSrc}
                />
              </Fragment>
            )}
          </SummaryCard>
        </div>
        {hasModules && (
          <div className="w-full lg:w-1/2">
            <ModulesList {...moduleListData}
              maxElementToShow={4}
              context="practice"
              trackingShowMoreClass="track-show-more-practice"
              trackingViewDetailsClass="track-view-details-practice" />
          </div>
        )}
      </div>
      <div className="flex min-h-[370px]">
        <div className="flex flex-col grow w-full">
          <GraphContainer
            leftTextTop="Average practice per week"
            leftTextBottom={`${averagePerSession} minutes`}
            customChildrenContainerClass={averagePerSession === 0 ? 'flex items-center' : ''}
          >
            { averagePerSession > 0 && (
              <Fragment>
                <div className="flex flex-col grow overflow-hidden practice-graph max-h-[360px]">
                  <Bar
                    ref={graphRef}
                    data={graphData}
                    options={options}
                  />
                </div>
                <GraphLegend
                  graphRef={graphRef}
                  legendItems={legendItems}
                  setLegendItems={setLegendItems}
                  forceUpdate={forceUpdate}
                  legendItemClasses="w-full md:w-1/2 lg:w-1/4"
                />
              </Fragment>
            ) }
            {averagePerSession === 0 && (
              <div className="w-full px-10 mx-10 py-6">
                <DataNotAvailable firstLine="No practice time"
                  secondLine={`${studentName} hasn’t spent any time on assigned practice. Their practice time breakdown will be available once they begin.`} />
              </div>
            )}
          </GraphContainer>
        </div>
      </div>
    </Fragment>
  );
};

PracticeSummary.propTypes = {
  subject: PropTypes.string.isRequired,
  yearLevel: PropTypes.number.isRequired,
  totalQuestionsAssigned: PropTypes.number.isRequired,
  totalQuestionsSubmitted: PropTypes.number.isRequired,
  practiceCompletion: PropTypes.number.isRequired,
  hasModules: PropTypes.bool.isRequired,
  moduleListData: PropTypes.shape({
    listTitle: PropTypes.shape({
      title: PropTypes.string,
    }),
    listItems: PropTypes.arrayOf(
      PropTypes.shape({
        itemTitle: PropTypes.string,
        itemCTAText: PropTypes.string,
        itemCTAAction: PropTypes.func,
        itemContent: PropTypes.string,
        itemDataTop: PropTypes.string,
        itemDataBottom: PropTypes.string,
        moduleId: PropTypes.number,
      }),
    ),
  }),
  // eslint-disable-next-line react/forbid-prop-types
  graphData: PropTypes.any,
  maxTimeSpent: PropTypes.number,
  averagePerSession: PropTypes.number,
  studentName: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
};
PracticeSummary.defaultProps = {
  moduleListData: {
    listTitle: {},
    listItems: [],
  },
  graphData: {},
  maxTimeSpent: 0,
  averagePerSession: 0,
};
export default connect((state) => {
  const summary = get(state, 'studentProgress.summary');
  const cumulative = get(state, 'studentProgress.cumulative');
  return {
    totalQuestionsAssigned: summary.totalQuestionsAssigned,
    totalQuestionsSubmitted: summary.totalQuestionsSubmitted,
    practiceCompletion: summary.practiceCompletion,
    moduleListData: getPracticeData(state),
    hasModules: wereModulesCovered(state),
    graphData: cumulative.practiceGraph,
    maxTimeSpent: cumulative.practiceMaxTimeSpent,
    averagePerSession: cumulative.practiceTimeAverage,
    subject: state.report.subject,
    yearLevel: getYearLevelAsNumber(state),
    studentName: state.report.studentName,
  };
}, null)(PracticeSummary);
