import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { LOCALSTORAGE_KEEP_ME_LOGGED_IN } from '../../../../../../util/constants';
import { ReactComponent as CheckIcon } from '../../../../../../assets/images/icon-tick--primary.svg'

const KeepMeLoggedIn = () => {
  const [isChecked, setIsChecked] = useState(true);

  useEffect(() => {
    localStorage.setItem(LOCALSTORAGE_KEEP_ME_LOGGED_IN, "true");
  }, []);

  const onClick = () => {
    setIsChecked(!isChecked);
    localStorage.setItem(LOCALSTORAGE_KEEP_ME_LOGGED_IN, String(!isChecked));
  };

  return (
    <label
      onClick={onClick}
      htmlFor="keep-me-logged-in"
      className='cursor-pointer flex gap-3 items-center'
    >
      <span
        className='border-2 w-6 h-6 border-blue-5 rounded flex justify-center items-center'>
        <CheckIcon className={classNames(isChecked ? 'block' : 'hidden')} width={15} height={15} />
      </span>
      <span className='text-grey-6 select-none'>Keep me logged in</span>
      <input id="keep-me-logged-in" type="hidden" />
    </label>
  );
};
export default KeepMeLoggedIn;
