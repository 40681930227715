import { useEffect, useState } from 'react';
import { compose } from 'redux';

import withSelectedEnrolment from '../../../../common/hub/NewWithSelectedEnrolment';
import { CancelEntry } from '../../../../components/EnrolmentManagement/CancelEnrolment/CancelEntry';
import { FormCancelEnrolment } from '../../../../components/EnrolmentManagement/CancelEnrolment/FormCancelEnrolment';
import { isEnrolmentCancelRequested, isEnrolmentPausedCheck, isGroupSessionCheck } from '../../../../util/helpers';
import { TRACKING_CE } from '../../../../util/trackingClasses';
import { EnrolmentManagementConfirmed } from '../../../../components/EnrolmentManagement/EnrolmentManagementConfirmed';
import { fetchEnrolments } from '../../../../actions/hub/hubEnrolmentsPageActions';
import { saveEnrolmentIdForEnrolmentManagement } from '../../../../actions/hub/hubEnrolmentManagementActions';
import { useSearchParams } from '../../../../util/hooks/useSearchParams';
import { useDispatch, useSelector } from 'react-redux';
import { useGetSessionPolicy } from '../../../../hooks/queries/useSessionQuery';
import { useHistory } from 'react-router';
import {
  PATH_HUB_CR_PAUSE_SUMMER_CANCEL,
  PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP,
  PATH_HUB_ENROLMENT_MANAGE_PAUSE,
  PATH_HUB_CR_CANCEL_ENROLMENT,
} from '../../../../util/pagePath';
import { summerPauseInsteadOfCancelEnabled } from '../../../../selectors/enrolmentSelector';
import { Enrolment } from '../../../../api/types/enrolment';

const CANCEL_FLOW_STEPS = {
  ENTRY: 'ENTRY',
  FORM: 'FORM',
  CONFIRMED: 'CONFIRMED',
};

interface Props {
  selectedEnrolment: Enrolment;
}

export const CancelEnrolment = ({ selectedEnrolment }: Props) => {
  const { enrolmentId, enrolmentStatus, student, subject, enrolmentSfid, type } = selectedEnrolment;
  const dispatch = useDispatch();
  const summerPauseEnabled = useSelector(summerPauseInsteadOfCancelEnabled);
  const { isLoading: pausePolicyLoading, error: policyError } = useGetSessionPolicy({
    enrolmentId: enrolmentSfid,
  });
  const isCancelRequested = isEnrolmentCancelRequested({ enrolmentStatus });
  const query = useSearchParams();
  const goToCancelForm = query.get('view') === 'form';
  const history = useHistory();

  let defaultView = CANCEL_FLOW_STEPS.ENTRY;
  if (isCancelRequested) {
    defaultView = CANCEL_FLOW_STEPS.CONFIRMED;
  } else if (goToCancelForm) {
    defaultView = CANCEL_FLOW_STEPS.FORM;
  } else {
    defaultView = CANCEL_FLOW_STEPS.ENTRY;
  }

  const [activeView, setActiveView] = useState(defaultView);
  useEffect(() => {
    setActiveView(defaultView);
  }, [defaultView]);

  const cancelEntrySwitchTo = () => {
    if (summerPauseEnabled) {
      const isEnrolmentPaused = isEnrolmentPausedCheck({ enrolmentStatus });
      const isGroup = isGroupSessionCheck(type);
      const redirectPath = isGroup ? PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP : PATH_HUB_ENROLMENT_MANAGE_PAUSE;
      if (isEnrolmentPaused) {
        history.push(`${redirectPath}?token=${enrolmentId}`);
      } else {
        history.push(`${PATH_HUB_CR_PAUSE_SUMMER_CANCEL}?token=${enrolmentId}`);
      }
    } else {
      setActiveView(CANCEL_FLOW_STEPS.FORM);
    }
  };

  switch (activeView) {
    case CANCEL_FLOW_STEPS.CONFIRMED:
      return (
        <EnrolmentManagementConfirmed
          mainHeading="Cancellation request received"
          student={student}
          subject={subject}
          enrolmentId={enrolmentId}
          tracking={TRACKING_CE.SUCCESS}
          message="We have received your request to cancel your enrolment -
            you will receive a confirmation email with the details shortly."
          cleanUp={
            isCancelRequested
              ? () => {}
              : () => {
                  dispatch(fetchEnrolments('active'));
                  dispatch(saveEnrolmentIdForEnrolmentManagement({ enrolmentId: '' }));
                }
          }
        />
      );
    case CANCEL_FLOW_STEPS.FORM:
      return (
        <FormCancelEnrolment
          selectedEnrolment={selectedEnrolment}
          goBack={() => {
            setActiveView(CANCEL_FLOW_STEPS.ENTRY);
          }}
          submitSuccessCallback={() => {
            setActiveView(CANCEL_FLOW_STEPS.CONFIRMED);
          }}
          enrolmentPolicyError={policyError}
        />
      );
    default:
      return (
        <CancelEntry
          selectedEnrolment={selectedEnrolment}
          switchToForm={cancelEntrySwitchTo}
          pausePolicyLoading={pausePolicyLoading}
          enrolmentPolicyError={policyError}
        />
      );
  }
};

export default compose(withSelectedEnrolment(CancelEnrolment, null, PATH_HUB_CR_CANCEL_ENROLMENT));
