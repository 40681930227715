import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Anchor, Container, Link } from '@cluey/cluey-components';

import withHeadTag from '../../../common/HeadComponent';
import HubBasePageLayout from '../../../common/hub/HubBasePageLayout';
import { RedirectToEnrolmentReport } from '../../../components/hub/enrolments/RedirectToEnrolmentReport';
import { PATH_HUB_HOME } from '../../../util/pagePath';
import {
  BenefitsList,
  VideoSections,
} from '../../../util/constants/midYearProcess';
import Selina from '../../../assets/images/people/Selina.png';
import { getActiveEnrolments } from '../../../selectors/hubSelector';

const SectionTitle = ({ children, className }) => (
  <h2 className={`font-display text-2xl font-semibold ${className}`}>
    {children}
  </h2>
);

SectionTitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

SectionTitle.defaultProps = {
  className: '',
};

const VideoSection = ({ sectionId, title, videos }) => {
  return (
    <div
      className={classNames('border-t-2 border-grey-2 pt-14 lg:pb-14')}
      id={sectionId}
      data-testid={sectionId}
    >
      <SectionTitle className="relative mb-24 after:absolute after:-bottom-4 after:block after:h-[3px] after:w-16 after:bg-blood-orange-4 after:content-['']">
        {title}
      </SectionTitle>
      {videos.map((video) => {
        const { src, quote, author, alignRight } = video;
        return (
          <div
            key={src}
            className={classNames(
              'mb-6 lg:flex lg:justify-center',
              alignRight && 'lg:flex-row',
              !alignRight && 'lg:flex-row-reverse'
            )}
          >
            <div className="mb-5 flex items-center justify-center px-4 font-body lg:mb-0 lg:w-1/2 lg:px-20">
              <div className="relative flex flex-col text-center before:absolute before:-top-12 before:-left-6 before:block before:text-[64px] before:font-extrabold before:italic before:text-yellow-3 before:content-['“'] lg:before:-top-16 lg:before:-left-14 lg:before:text-[100px]">
                <p className="mb-4 text-2xl italic">{quote}</p>
                <p className="text-base-sm">- {author}</p>
              </div>
            </div>
            <div className="relative mb-14 w-full overflow-hidden pt-[56.25%] lg:mb-0 lg:w-1/2 lg:pt-[28%]">
              <iframe
                className="absolute top-0 left-0 h-full w-full"
                src={src}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        );
      })}
    </div>
  );
};

VideoSection.propTypes = {
  sectionId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      quote: PropTypes.object,
      author: PropTypes.string.isRequired,
      alignRight: PropTypes.bool.isRequired,
    })
  ).isRequired,
};

const HubMidYearProgressPage = ({ enrolments }) => {
  return (
    <HubBasePageLayout>
      <>
        <script src="https://www.youtube.com/iframe_api" />
        <div className="bg-slate-1">
          <Container className="h-[410px] lg:h-[430px]">
            <div className="flex flex-col py-10">
              <Link
                className="self-start mb-2 !text-2xs font-semibold uppercase tracking-wider"
                to={PATH_HUB_HOME}
              >
                Home
              </Link>
              <h1 className="mb-5 font-display text-[28px] font-bold leading-tight lg:text-4xl">
                Mid-year progress
              </h1>
              <p className="font-body text-xl lg:w-1/2 lg:text-lg">
                With half the year already over, it's a good time to check on
                how your child is progressing with their learning.
              </p>
            </div>
            <div className="rounded-lg border border-grey-2 bg-white lg:flex lg:flex-row-reverse">
              <div className="-mt-12 flex justify-center px-6 lg:-mt-20 lg:w-1/2 lg:px-14">
                <img src={Selina} alt="Dr Selina Samuels" />
              </div>
              <div className="p-6 lg:w-1/2 lg:py-14 lg:pr-0 lg:pl-14">
                <SectionTitle className="mb-4 lg:mb-8">
                  The Cluey approach
                </SectionTitle>
                <p className="mb-5">
                  We designed the Cluey approach to learning based on what
                  global research and our experience told us would provide the
                  greatest benefits to our students.
                </p>
                <p>
                  We are delighted to hear from our families about how Cluey
                  supports their children's education and offers benefits to the
                  entire household.
                </p>
              </div>
            </div>
          </Container>
        </div>
        {/* To keep the layout, please adjust the margin top here if div.rounded-lg border border-grey-2 bg-white lg:flex lg:flex-row-reverse has changes */}
        <Container className="mt-[480px] mb-10 lg:mt-[150px]">
          {!isEmpty(enrolments) && (
            <div className="mb-14 flex flex-col gap-5 lg:flex-row lg:flex-wrap">
              {enrolments.map((enrolment) => (
                <div
                  key={enrolment.sessionReportId}
                  className="w-full lg:w-[calc(50%-10px)]"
                >
                  <RedirectToEnrolmentReport
                    student={enrolment.student}
                    subject={enrolment.subject}
                    sessionReportId={enrolment.sessionReportId}
                    gaTags="&utm_medium=referral&utm_source=hubmid_year_progress"
                  />
                </div>
              ))}
            </div>
          )}

          <div className="mb-14">
            <SectionTitle className="mb-10 lg:mb-5">
              Here’s a reminder of the benefits Cluey parents say they get:
            </SectionTitle>
            <ul>
              {BenefitsList.map((item) => (
                <li
                  className="lg: mb-5 mb-3 flex items-start"
                  key={item.href}
                  data-testid={item.testId}
                >
                  <img src={item.icon} alt="icon" className="mr-4" />
                  <Anchor href={item.href} size="lg">
                    {item.text}
                  </Anchor>
                </li>
              ))}
            </ul>
          </div>
          {VideoSections.map((vs) => (
            <VideoSection
              key={vs.id}
              sectionId={vs.id}
              title={vs.title}
              videos={vs.videos}
            />
          ))}
        </Container>
      </>
    </HubBasePageLayout>
  );
};

HubMidYearProgressPage.propTypes = {
  enrolments: PropTypes.arrayOf(
    PropTypes.shape({
      student: PropTypes.string.isRequired,
      subject: PropTypes.string.isRequired,
      sessionReportId: PropTypes.string.isRequired,
    })
  ),
};

HubMidYearProgressPage.defaultProps = {
  enrolments: [],
};

export default connect((state) => {
  return {
    enrolments: getActiveEnrolments(state),
  };
}, null)(withHeadTag(HubMidYearProgressPage));

export { HubMidYearProgressPage as StatelessHubMidYearProgressPage };
