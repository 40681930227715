import { useQuery } from '@tanstack/react-query';
import { TutorService } from '../../services/PortalAPIServices/TutorService';
import { GET_TUTOR_INFO_QKEY } from '../../util/constants/queryKeys';

export const useTutorInfoQuery = (tutorNumber, enabled = true) => {
  return useQuery({
    queryKey: [GET_TUTOR_INFO_QKEY, tutorNumber],
    queryFn: () => TutorService.getTutorInfo({ tutorNumber }),
    staleTime: 600000,
    enabled: enabled && !!tutorNumber,
  });
};
