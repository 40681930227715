import AccountBillingInformation from '../../../components/hub/account/AccountBillingInformation/AccountBillingInformation';
import AccountBalance from '../components/AccountBalance';
import useBillingInformationQuery from '../../../hooks/queries/useBillingInformationQuery';
import InvoicesList from '../components/InvoicesList/InvoicesList';
import AccountPageLayout from '../../../templates/AccountPageLayout/AccountPageLayout';
import PaymentOptionSelector from '../components/PaymentOptionSelector/PaymentOptionSelector';
import { useState } from 'react';
import { Alert, Button, Container } from '@cluey/cluey-components';
import useAccountDetailsQuery from '../../../hooks/queries/useAccountDetailsQuery';
import { useSelector } from 'react-redux';
import { getActiveEnrolments } from '../../../selectors/hubSelector';
import { InvoicePer } from '../../../types/hubTypes';
import { useInvoicePerMutation } from '../hooks'; 

const PaymentBillingPage = () => {
  const {
    data: billingInformation,
    isLoading: isLoadingBillingInformation,
    error: getBillingInformationError,
  } = useBillingInformationQuery();
  const activeEnrolments = useSelector(getActiveEnrolments);

  const { data: accountDetails, isLoading: isLoadingAccountDetails } = useAccountDetailsQuery();
  const [paymentOptionUpdate, setPaymentOptionUpdate] = useState<InvoicePer | null>(accountDetails?.invoicePer);

  const { isError, isLoading, isSuccess, mutate: updateInvoicePer } = useInvoicePerMutation();
  const handleSubmitPaymentOption = () => {
    if (!paymentOptionUpdate) return;
    const submissionPayload = {
      invoicePer: paymentOptionUpdate,
    };
    updateInvoicePer(submissionPayload);
  };

  return (
    <AccountPageLayout loading={isLoadingBillingInformation || isLoadingAccountDetails}>
      <Container>
        {isSuccess && <Alert type="success" className="mb-4" body="Your payment option has been updated." />}
        {isError && <Alert type="error" className="mb-4" body="There was an error saving your payment preference." />}
        <h2 className="mb-0 font-display text-2xl font-bold">Payment and billing details</h2>
        <AccountBillingInformation
          billingInformation={billingInformation}
          error={getBillingInformationError}
        />
        <AccountBalance
          billingInformation={billingInformation}
          error={getBillingInformationError}
        />
        {activeEnrolments.length > 1 && (
          <>
            <PaymentOptionSelector
              onChange={setPaymentOptionUpdate}
              initialValues={{
                // @ts-ignore
                paymentOption: accountDetails?.invoicePer,
              }}
            />
            <div className="pt-4">
              <Button
                size="lg"
                onClick={handleSubmitPaymentOption}
                // @ts-ignore
                disabled={isLoading || !paymentOptionUpdate || accountDetails?.invoicePer === paymentOptionUpdate}
              >
                {isLoading ? 'Saving...' : 'Save'}
              </Button>
            </div>
          </>
        )}
        <InvoicesList />
      </Container>
    </AccountPageLayout>
  );
};

export default PaymentBillingPage;
