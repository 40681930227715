import initialState from '../../initialState';
import * as actionTypes from '../../../actions/actionTypes';

export default function hubCompletedSessionsPageReducer(state = initialState.ui.apiState.hubCompletedSessionsPage, action) {
  switch (action.type) {
    case actionTypes.HUB_SP_FETCH_COMPLETED_SESSIONS_START:
      return {
        ...state,
        fetchError: false,
        isLoading: true,
      };
    case actionTypes.HUB_SP_FETCH_COMPLETED_SESSIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasFetched: true,
      };
    case actionTypes.HUB_SP_FETCH_COMPLETED_SESSIONS_FAILED:
      return {
        ...state,
        fetchError: true,
        isLoading: false,
      };
    case actionTypes.HUB_SP_FETCH_COMPLETED_SESSIONS_FAILURE_DEFINITIVE:
      return {
        ...state,
        fetchError: true,
        isLoading: false,
        retryEnabled: false,
      };
    default:
      return state;
  }
}
