export class CustomError extends Error {
  statusCode: number;
  timestamp: Date;
}

export class ApiError extends CustomError {
  endpoint: string;

  constructor(message: string, endpoint: string) {
    super(message);
    this.name = 'ApiError';
    this.statusCode = 500;
    this.endpoint = endpoint;
    this.timestamp = new Date();
  }
}

export class ValidationError extends CustomError {
  constructor(message: string) {
    super(message);
    this.name = 'ValidationError';
    this.statusCode = 400;
    this.timestamp = new Date();
  }
}

export class NotFoundError extends CustomError {
  resourceId: string;

  constructor(message: string, id?: string) {
    super(message);
    this.name = 'NotFoundError';
    this.statusCode = 404;
    this.resourceId = id || '';
    this.timestamp = new Date();
  }
}
