import {
	PATH_HUB_RESCHEDULE_SESSION_INFO,
	PATH_HUB_UPDATES_NEW_CUSTOMER_HUB,
	PATH_HUB_UPDATES_CUSTOMER_INPUT,
	PATH_HUB_UPDATES_AI_TUTOR,
} from '../../../../util/pagePath';

export const ARTICLE_IDS = [
  'new-customer-hub',
  'reschedule-session-info',
  'upcoming-session-message',
  'tutor-cluey-coach',
] as const;

export type ArticleIdType = typeof ARTICLE_IDS[number];

export type Article = {
	id: ArticleIdType;
	publishedAt: string;
	name: string;
	description: string;
	path: string;
};

const articles: Article[] = [
	{
		id: 'tutor-cluey-coach',
		publishedAt: '2024-11-20',
		name: 'Anytime tutoring help with Cluey Coach',
		description:
			'Year 11 and 12 students in New South Wales, Australia will have access to our new AI tutor, Cluey Coach, at any time to help with tutoring needs.',
		path: PATH_HUB_UPDATES_AI_TUTOR,
	},
	{
		id: 'upcoming-session-message',
		publishedAt: '2024-07-30',
		name: 'Leave a message for the tutor about the next session',
		description:
			'You can now leave a message for the tutor to request specific content or topics for the next session, or let them know how the student is feeling.',
		path: PATH_HUB_UPDATES_CUSTOMER_INPUT,
	},
	{
		id: 'new-customer-hub',
		publishedAt: '2024-04-30',
		name: 'Welcome to the new Customer Hub',
		description: "Customer Hub has a new look! Find out what's new and how managing sessions has changed.",
		path: PATH_HUB_UPDATES_NEW_CUSTOMER_HUB,
	},
	{
		id: 'reschedule-session-info',
		publishedAt: '2023-09-08',
		name: "Reschedule with your tutor when they can't make it to a session",
		description:
			"When your tutor can't make a regular session, they'll offer you additional times to reschedule the session.",
		path: PATH_HUB_RESCHEDULE_SESSION_INFO,
	},
];

export function getArticleById(id: string): Article | undefined {
	return articles.find((article) => article.id === id);
}

export default articles;
