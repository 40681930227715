import { FAQAnswerProps } from './FAQAnswer';

const AppFAQAnswer = ({ answers }: FAQAnswerProps) => {
	return (
		<div className="flex flex-col space-y-2 pb-4 last:pb-3">
			{answers.map((answer, index) => {
				return (
					<div key={index} className="ml-6 flex flex-col first:my-0 [&:first-child>div]:mt-0">
						
						{answer.title && <div className="proxima-nova my-4 text-sm font-bold">{answer.title}</div>}
						<div className="proxima-nova text-base">{answer.answer}</div>
					</div>
				);
			})}
		</div>
	);
};
export default AppFAQAnswer;
