import { ReactNode } from 'react';
import { HomeEmptyWidget } from './HomeEmpty';
import { LearningAdvisorWidget } from './LearningAdvisor'; 
import { api } from '../../../../api';
import { Loader } from '../../../../components/Loader/Loader';
import { HomeAssessmentsWidget } from './HomeAssessmentsWidget';
import { getCanBeAssessed } from '../../../../util/helper/assessment';
import { HomeSubjectsWidget } from './HomeSubjectsWidget';
import { SnapshotWidget } from './SnapshotWidget/SnapshotWidget';
import useGetAssessments from '../useGetAssessments';
import { first } from 'lodash';
import AssessmentsOverviewWidget from './AssessmentsOverviewWidget';
import AssessmentReportWidget from './AssessmentReportWidget';
import AITutorWidget from './AITutorWidget/AITutorWidget';

export type HomeWidgetsProps = {
	isLead: boolean;
};

export function getTokenFromUrl(url) {
	try {
		// Create a URL object
		const parsedUrl = new URL(url);

		// Use URLSearchParams to get the query parameters
		const params = new URLSearchParams(parsedUrl.search);

		// Get the token
		return params.get('token');
	} catch (error) {
		console.error('Invalid URL:', error.message);
		// Handle invalid URL case here, e.g., return null or a default value
		return null;
	}
}

export const HomeWidgets = ({ isLead }: HomeWidgetsProps) => {
  const widgets: ReactNode[] = []; 
  const { data: details } = api.account.details.useQuery();
  const { data: assessments, isLoading: loadingAssessments } = useGetAssessments();
  const canBeAssessed = getCanBeAssessed({ country: details?.country, yearLevel: details?.yearLevel });
  const latestAssessment = first(assessments);
  const studentLink = latestAssessment?.studentLink || ''; 
  const token = getTokenFromUrl(studentLink); /* eslint-disable-line */


	if (loadingAssessments)
		return (
			<div className="mx-auto my-20">
				<Loader />
			</div>
		);

 
  if (!isLead) {
		widgets.push(<SnapshotWidget key="snapshot" />);
		widgets.push(<AITutorWidget key="ai-tutor" />);
		widgets.push(<AssessmentsOverviewWidget key="assessments-overview" />);
  } else {
    if (assessments?.length === 0 && (!details?.yearLevel || canBeAssessed)) {
     widgets.push(<HomeAssessmentsWidget key="assessments" />);
    } else if (latestAssessment?.status === 'Completed') {
      widgets.push(<AssessmentReportWidget key="assessment-report" token={token} studentLink={studentLink} />)
    }
  }

	return (
		<div className="flex flex-grow flex-col gap-16">
			{widgets.length ? widgets : <HomeEmptyWidget />}
			<HomeSubjectsWidget className="container m-auto mb-8 px-4" />
			{isLead && <LearningAdvisorWidget />}
		</div>
	);
};
