import initialState from '../initialState';
import * as actionTypes from '../../actions/actionTypes';

export default function appViewReducer(state = initialState.ui.appView, action) {
  switch (action.type) {
    case actionTypes.APP_UPDATE_VIEW:
      return action.payload.appView;
    default:
      return state;
  }
}
