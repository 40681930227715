import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withHeadTag from '../../../common/HeadComponent';
import AuthPageLayout from '../../../common/hub/AuthPageLayout';
import InPageLoader from '../../../common/InPageLoader';
import ResetPasswordResetLink from '../../../components/hub/auth/ResetPasswordResetLink';

import mailSent from '../../../assets/images/hubAuth/mail-sent.svg';

const HubResetPasswordSentPage = ({ inPageSpinner }) => {
	return (
		<AuthPageLayout
			title="Thanks for your request"
			subtitle="If your email address is associated with a Cluey account, you'll receive an email with a link to reset your password."
			imgIcon={mailSent}
			imgIconAlt="mail sent"
			imgIconWrapClass="icon-wrap--sail"
		>
			{inPageSpinner && <InPageLoader position="relative" />}
			{!inPageSpinner && (
				<ResetPasswordResetLink
					btnClasses="block p-0 mt-10 text-primary text-[15px] font-body tracking-normal"
					btnText="Didn’t receive the link? Re-send"
				/>
			)}
		</AuthPageLayout>
	);
};

HubResetPasswordSentPage.propTypes = {
	inPageSpinner: PropTypes.bool.isRequired,
};

HubResetPasswordSentPage.defaultProps = {};

export default connect((state) => {
	const { ui } = state;
	return {
		inPageSpinner: ui.apiState.hubAuthPage.isLoading,
	};
}, null)(withHeadTag(HubResetPasswordSentPage));

export { HubResetPasswordSentPage as StatelessHubResetPasswordSentPage };
