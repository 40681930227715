import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export function SummaryCard({
  title, subTitle, body, bodyClass, children, className, subTitleBold,
}) {
  return (
    <div className={classNames('w-full flex flex-col border border-grey-2 rounded-lg overflow-hidden', className)}>
      <div className="flex flex-col p-4">
        <span className="font-bold text-base">{title}</span>
        <div className="flex">
          {subTitleBold && (<span className="font-bold text-2xl">{subTitleBold}&nbsp;</span>)}<span className="text-2xl">{subTitle}</span>
        </div>

      </div>
      <div className={classNames('flex flex-col px-4 pt-4 h-full', bodyClass)}>
        {body && (<div className="flex grow"><p className="text-[18px]">{body}</p></div>)}
        {children}
      </div>
    </div>
  );
}

SummaryCard.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  subTitleBold: PropTypes.string,
  body: PropTypes.string,
  bodyClass: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};
SummaryCard.defaultProps = {
  title: '',
  subTitle: '',
  subTitleBold: '',
  body: '',
  bodyClass: '',
  children: null,
  className: '',
};
