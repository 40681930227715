import { LinkButton } from '@cluey/cluey-components';
import { generatePath } from 'react-router-dom';
import { ConversationLayout } from './VirtualCoach/ConversationLayout';
import { PATH_HUB_HOME, PATH_HUB_ADD_ONE_OFF_SESSION } from '../../util/pagePath';
import useAiTutorContext from './useAiTutorContext';
import { BetaVersionBanner } from './BetaVersionBanner';

export const UsedUpLimitMsg = () => {
  const { enrolment } = useAiTutorContext();

  return (
    <ConversationLayout bodyClassName="min-h-[600px]" bottomAction={false}>
      <div className="flex flex-col gap-y-6 ">
        <BetaVersionBanner enrolmentId={enrolment.enrolmentId} />
        <div className="flex flex-col gap-x-4 gap-y-3 md:flex-row">
          <LinkButton
            to={PATH_HUB_HOME}
            size="lg"
            className="group flex w-full place-content-center !px-6 !py-1.5 !tracking-[1px]"
            appearance="reverse"
          >
            <p className="text-base font-bold">Return to hub</p>
          </LinkButton>
          <LinkButton
            to={generatePath(PATH_HUB_ADD_ONE_OFF_SESSION, { enrolmentId: enrolment.enrolmentId })}
            size="lg"
            className="group flex w-full place-content-center !border !px-5 !py-1.5 !tracking-[1px]"
          >
            <p className="text-base font-bold">Book one-off session</p>
          </LinkButton>
        </div>
      </div>
    </ConversationLayout>
  );
};
