import { useState } from "react";
import { Alert } from "@cluey/cluey-components";
import { CancelledSessionCard } from "./CancelledSessionCard";
import { Fragment } from "react";
import HubPageLayout from "../../../common/hub/HubPageLayout";
import { getSessionsInViewByIndex } from '../../../util/helpers';
import { Loader } from '../../../components/Loader';
import RecordsPagination from '../../../components/hub/RecordsPagination';
import SessionsPageContentHeadNav from '../../../components/hub/sessions/SessionsPageContentHeadNav';
import { NO_OF_SESSIONS_PER_PAGE } from '../../../util/constants';
import { CancelledSession } from "../../../types/hubInterfaces";
import { api } from "../../../api";

const CancelledSessions = () => {
  const [pageIndex, setPageIndex] = useState(0);
  const { 
    data: cancelledSessions,
    isError: cancelledSessionsError,
    isLoading: cancelledSessionsLoading 
   } = api.session.all.useQuery('cancelled');

  const getSessionsInView = (): Array<CancelledSession> => {
    return getSessionsInViewByIndex(cancelledSessions ?? [], pageIndex);
  }

  return (
    <HubPageLayout>
      {
        cancelledSessionsLoading ? 
          <Loader />
          :
          <Fragment>
            <SessionsPageContentHeadNav />
            {cancelledSessionsError && <Alert type="error" body="There was an error retrieving your cancelled sessions" /> }
            {!cancelledSessionsError && (
              cancelledSessions?.length === 0 ?
                <p>You have no cancelled sessions.</p>
                :
                <Fragment>
                  <div className="flex flex-col gap-y-2">
                    {getSessionsInView().map((session) => (
                      <CancelledSessionCard key={`${session.studentSessionId}-${session.startTime}`} session={session} />
                    ))}
                  </div>
                  <RecordsPagination
                    recordsTotal={cancelledSessions?.length}
                    noOfRecordsPerPage={NO_OF_SESSIONS_PER_PAGE}
                    handlePaginationClick={(index: number) => setPageIndex(index)}
                  />
                </Fragment>
            )}
          </Fragment>
      }
    </HubPageLayout>
  )
}

export default CancelledSessions;