import { generatePath } from 'react-router';
import { Loader } from '../../../../components/Loader';
import { Session, SessionPolicy } from '../../../../types/hubInterfaces';
import { getAlertData } from '../../../../util/helper/cancelSession';
import { PolicyAlert } from '../../../../components/PolicyAlert';
import { PATH_HUB_PLAN_DETAILS } from '../../../../util/pagePath';

interface Props {
  isLoading: boolean;
  sessionToSkip: Session;
  sessionPolicy: SessionPolicy;
}

const CreditPolicy = ({ sessionPolicy, sessionToSkip, isLoading }: Props) => {
  const { alertType, body, title, color } = getAlertData({ sessionPolicy, sessionToSkip });
  return (
    isLoading ? 
    <Loader />
    :
    <PolicyAlert
      title={title}
      body={body}
      alertType={alertType}
      color={color}
      href={generatePath(PATH_HUB_PLAN_DETAILS)}
    />
  )
}

export default CreditPolicy;