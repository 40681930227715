import initialState from './initialState';
import * as actionTypes from '../actions/actionTypes';

export default function hubUser(state = initialState.hubUser, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.HUB_AUTH_SAVE_EMAIL_TEMPPWD:
      return {
        ...state,
        email: payload.email,
        tempPassword: payload.tempPassword,
      };
    case actionTypes.HUB_AUTH_SIGN_UP_SUCCESS:
      return {
        ...state,
        tempPassword: '',
      };
    case actionTypes.HUB_AUTH_SET_NEW_PASSWORD_START:
    case actionTypes.HUB_AUTH_RESET_PASSWORD_REQUEST_SUCCESS:
    case actionTypes.HUB_AUTH_RESEND_SIGNUP_LINK_REQUEST_SUCCESS:
      return {
        ...state,
        email: payload.email,
      };
    case actionTypes.HUB_AUTH_GET_USER_INFO_SUCCESS:
      return {
        ...state,
        name: payload.name,
      };
    case actionTypes.HUB_GLOBAL_GET_USER_TIMEZONE_SUCCESS:
      return {
        ...state,
        timezone: payload.timezone,
        customerLinkId: payload.customerLinkId,
      };
    default:
      return state;
  }
}
