import { logError } from '../logging';
import * as types from './actionTypes';
import { apiFetch, REQ_GET_UPCOMING_SESSIONS, REQ_GET_UPCOMING_SESSIONS_TOKEN } from '../services/backendApi';
import { saveEnrolmentIdForSAF } from './contactUsFlowActions';
import { fetchSummerModeData } from './summerModeActions';

// eslint-disable-next-line valid-jsdoc
/**
 * @deprecated This function has been deprecated in favor of api/services/session.all
 *
 */
export const fetchUpcomingSessions = () => (dispatch, getState) => {
  dispatch({ type: types.US_FETCH_SESSIONS_START, payload: null });

  const tokenMatch = document.URL.match(/token=([^&#]*)/);
  const tokenParam = tokenMatch && tokenMatch[1];
  if (!tokenParam) {
    dispatch({ type: types.US_FETCH_SESSIONS_FAILED });
    return null;
  }
  dispatch({
    type: types.US_UPDATE_UPCOMING_SESSIONS_TOKEN,
    payload: { token: tokenParam },
  });

  return apiFetch(REQ_GET_UPCOMING_SESSIONS, getState())
    .then((json) => {
      dispatch({ type: types.US_FETCH_SESSIONS_SUCCESS, payload: json });
      // NOTE, just comment out for future use
      dispatch(fetchSummerModeData({ customerLinkId: tokenParam }));
    })
    .catch((e) => {
      dispatch({ type: types.US_FETCH_SESSIONS_FAILED });
      logError(e, null, { message: 'Unable to fetch upcoming sessions' });
    });
};

export const updateStudentsFilters = (payload) => (dispatch) => {
  dispatch({
    type: types.US_UPDATE_STUDENTS_FILTERS,
    payload: payload.updatedStudentsNames,
  });
};

export const getParentTokenWithSessionReportToken = (token) => {
  return (dispatch, getState) => {
    return apiFetch(REQ_GET_UPCOMING_SESSIONS_TOKEN, getState())
      .then((json) => {
        const { customerLink, uniqueEnrolmentId } = json;
        dispatch({ type: types.US_UPDATE_UPCOMING_SESSIONS_TOKEN, payload: { token: customerLink } });
        dispatch(saveEnrolmentIdForSAF({ enrolmentId: uniqueEnrolmentId }));
        // NOTE, just comment out for future use
        dispatch(fetchSummerModeData({ customerLinkId: customerLink }));
      })
      .catch((e) => {
        logError(e, null, {
          message: `Get token for upcoming sessions page failed with ${token}`,
        });
      });
  };
};

export const showUpcomingSessions = () => (dispatch) => {
  dispatch({
    type: types.US_SHOW_UPCOMING_SESSIONS,
  });
};

export const hideUpcomingSessions = () => (dispatch) => {
  dispatch({
    type: types.US_HIDE_UPCOMING_SESSIONS,
  });
};
