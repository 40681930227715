import { Button } from '@cluey/cluey-components';
import { TRACKING_PE } from '../../util/trackingClasses';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { PATH_HUB_CR_CANCEL_ENROLMENT } from '../../util/pagePath';
import { redirectToFormWithEnrolmentId } from '../../actions/hub/hubEnrolmentManagementActions';

interface Props {
  enrolmentId: string;
}

const PreferToStopSessions = ({ enrolmentId }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div className="bg-slate-1 pb-16 pt-10 text-center">
      <p className="mx-9 mb-4 font-display text-xl font-bold lg:text-2xl">Prefer to cancel sessions completely?</p>
      <Button
        size="lg"
        appearance="reverse"
        className={TRACKING_PE.SUMMER_CANCEL_CTA}
        onClick={() => {
          dispatch(
            redirectToFormWithEnrolmentId({
              enrolmentId,
              callback: () => {
                history.push(`${PATH_HUB_CR_CANCEL_ENROLMENT}?view=form&token=${enrolmentId}`);
              },
            })
          );
        }}
      >
        Cancel enrolment
      </Button>
    </div>
  );
};

export { PreferToStopSessions };
