import { get } from 'lodash';
import { logError, ddLogger, ddLoggerLevel } from '../../logging';
import getRequestOptions from './getRequestOptions';
import { logFailedResponse, handleNotOk, handleInvalidJson } from './performApiFetchHelpers';
import { FETCH_NOT_OK, FETCH_FAILED } from '../../logging/errors';

/**
 * Fetch helper. Used in goalsActions and Practice Assignment,
 * and ready to be used in other fetch contexts like CourseNav, PracticeNav,
 * srLogin, refreshLogin, tracking and Feedback
 * @param  {Object} apiOptions                    Options object
 * @param  {Object} state                         Redux State, used in error logging
 * @param  {?Object} requestPayload               Optional request payload
 * @param  {Function} dispatch                    Redux function, used to retrieve new cognnito credential
 * @return {Promise<jsonResponse>}                Promise resolved with the response, parsed from JSON
 */
const performApiFetch = async (apiOptions, state, requestPayload, dispatch) => {
  const {
    logContext: message = 'No context specified',
    validateResponseStatus = ({ ok }) => ok,
    signRequest = true,
    validateResponse = () => true,
    host,
    path,
    method,
    isDefinitiveFail,
    bubbleOriginalMessage,
    region,
  } = apiOptions;
  const {
    url,
    reqOptions,
  } = await getRequestOptions(signRequest, state, dispatch, {
    host, path, method, region,
  }, requestPayload);

  const helpersConfig = ({
    url, reqOptions, message, isDefinitiveFail, bubbleOriginalMessage,
  });

  const customerIdentifier = {
    upcomingSessionsToken: get(state, 'upcomingSessions.token', ''),
    schoolUpcomingSessionsToken: get(state, 'schoolUpcomingSessions.customerNumber', ''),
    LAToken: get(state, 'login.initialToken', ''),
    rescheduleSessionToken: get(state, 'rescheduleSession.token', ''),
    hubCustomerId: get(state, 'login.customerId', ''),
  };

  // We will return a promise or reject depending on the result of fetch
  return fetch(url, reqOptions)
    // Fetch has failed
    .catch((e) => { // FETCH Exception (e instanceof TypeError and e.message === 'Failed to fetch')
      logError(e, { reqOptions }, { message });
      ddLogger({
        level: ddLoggerLevel.ERROR,
        label: FETCH_FAILED.message,
        data: {
          url, ...reqOptions, message, ...customerIdentifier,
        },
        error: new Error(FETCH_FAILED.message),
      });
      throw e;
    })
    // We have received the response correctly, even if it's not a 2XX status
    // First, we throw if the response is not a 2XX
    .then((response) => {
      const { ok, status } = response;

      if (validateResponseStatus({ ok, status })) {
        return response;
      }

      ddLogger({
        level: ddLoggerLevel.ERROR,
        label: FETCH_NOT_OK.message,
        data: {
          url, ...reqOptions, ...response, ...customerIdentifier,
        },
        error: new Error(FETCH_NOT_OK.message),
      });

      return handleNotOk(helpersConfig)(response);
    })
    .then((response) => {
      const backupResponse = response.clone();
      return response.json()
        .catch((e) => {     // JSON parsing failed
          logError(e, { reqOptions }, { message });
          logFailedResponse(helpersConfig)(backupResponse);
          throw e;
        });
    })
    // JSON parsing worked
    .then(json => (validateResponse(json) ? json : handleInvalidJson(helpersConfig)(json)));
};

export default performApiFetch;
