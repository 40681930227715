import AccountPageNav from '../../views/account/components/AccountPageNav/AccountPageNav';
import { BasePageLayoutProps } from '../BasePageLayout/BasePageLayout';
import BasePageTemplate from '../BasePageTemplate/BasePageTemplate';
import HeaderAndFooter from '../HeaderAndFooter/HeaderAndFooter';
import PageContent from '../PageContent/PageContent';
import { FullPageLoader } from '../../components/Loader/Loader';

interface AccountPageLayoutProps extends BasePageLayoutProps {
  loading?: boolean;
}
const AccountPageLayout = ({ children, title, loading = false }: AccountPageLayoutProps) => {
  return (
    <BasePageTemplate>
      <HeaderAndFooter title={title}>
        <PageContent>
          <AccountPageNav />
          {loading ? (
              <FullPageLoader />
          ) : (
            children
          )}
        </PageContent>
      </HeaderAndFooter>
    </BasePageTemplate>
  );
};
export default AccountPageLayout;
