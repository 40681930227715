import { ReactNode } from 'react';
import { Badge } from '@cluey/cluey-components';
import moment from 'moment';
import { DATE_FORMAT_TYPE_14 } from '../../../../util/constants';
import { CancelledSession } from '../../../../types/hubInterfaces';
import { getTimezone } from '../../../../selectors/hubSelector';
import { useSelector } from 'react-redux';
import { SessionDetails } from '../../../../components/hub/sessions/HubSessionsListItem/SessionDetails';
import { TTBM_CUSTOMER_NUMBER, DATE_FORMAT_TYPE_13 } from '../../../../util/constants';
import { getPkgTutorNameByEnrolmentId } from '../../../../selectors/hubEnrolmentsSelector';
import { formatSession } from '../../../../util/helpers';
import { ExclamationMarkIcon } from '../../../../icons';

interface Props {
  session: CancelledSession;
}

const CancelledSessionCard = ({ session }: Props) => {
  const { tutor, cancellationDateTime, startTime, endTime, initiatedBy, student, subject, uniqueEnrolmentId, tutorNumber } = session;
  const timezone: string = useSelector(getTimezone);
  const dateCancelled = moment(cancellationDateTime).tz(timezone).format(DATE_FORMAT_TYPE_14);
  const getFormattedTime = (time) => moment(time).tz(timezone).format('h:mma');
  const timeFrame = `${getFormattedTime(startTime)} - ${getFormattedTime(endTime)}`;
  const packageTutor = useSelector((state) => getPkgTutorNameByEnrolmentId(state, uniqueEnrolmentId));
  const displayTutor = tutorNumber === TTBM_CUSTOMER_NUMBER ? packageTutor : tutor;
  const { date } = formatSession({
    session,
    timezone,
    sessionDateFormat: DATE_FORMAT_TYPE_13,
    useSemiColon: false,
  });
  const cancelText = initiatedBy?.toLowerCase() === 'student' 
    ? `Session cancelled on ${dateCancelled} | $${session.calculatedCreditAmount} Credit`
    : `Session cancelled by Cluey | $${session.calculatedCreditAmount ?? 0} Credit`;

  let badge: ReactNode | null = null;
	if (session.tutorRescheduleRequestStatusC === 'Requested') {
		badge = <Badge className="rounded-xl !bg-slate-3 py-px">Rescheduled with Tutor</Badge>;
	}

	if (session.tutorRescheduleRequestStatusC === 'Approved') {
		badge = <Badge className="rounded-xl py-px">Tutor Can't Make It</Badge>;
	}

	if (session.enrolmentType === 'Trial') {
    badge = <Badge className="rounded-xl !bg-slate-3 py-px">Trial</Badge>;
  }

  return (
    <div className="flex flex-col gap-1 rounded bg-custom-7 py-4 px-6 text-grey-6">
      <div className="flex flex-col items-start justify-between gap-1 lg:flex-row lg:items-center">
        <SessionDetails student={student} subject={subject} tutor={displayTutor} />
        {badge}
      </div>
      <span className="font-bold uppercase text-xs">{date}</span>
      <div className="text-base font-normal">{timeFrame}</div>
      <div className="flex gap-x-2 text-base items:start md:items-center">
        <ExclamationMarkIcon width={16} height={16} className='mt-1 md:mt-0 text-red-5' />
        <span>
          {cancelText}
        </span>
      </div>
    </div>
  );
};

export default CancelledSessionCard;