// TODO: will move all enrolment management related selectors from hubSelector here

import { get, isEmpty } from 'lodash';
import { willEnrolmentBePausedCheck } from '../util/helpers';
import { getNAPLANInfo } from '../util/helper/helperNAPLAN';
import {
  PATH_HUB_ENROLMENT_DETAIL,
  PATH_HUB_CR_PAUSE_SUMMER_CANCEL,
  PATH_HUB_CR_CANCEL_ENROLMENT,
} from '../util/pagePath';
import { getActiveEnrolments, getTerm123PauseSessionsFormData } from './hubSelector';
import moment, { Moment } from 'moment';
import { allowSummerHolidayPause } from './schoolTerms/schoolTermsSelectors';

export const getNAPLAN = (state, page = '') => {
  const enrolment = get(state, 'hubEnrolmentDetailPage', {});
  if (isEmpty(enrolment)) {
    return { showNAPLAN: false, typeNAPLAN: '', optNAPLAN: false };
  }

  const { enrolmentSubject, packageMasterCode, enrolmentType: courseType } = enrolment;
  return getNAPLANInfo({
    enrolmentSubject,
    packageMasterCode,
    courseType,
    checkDate: page === PATH_HUB_ENROLMENT_DETAIL,
  });
};

export const getSpecificActiveEnrolment =
  ({ enrolmentId }) =>
  (state) => {
    const activeEnrolments = getActiveEnrolments(state);
    return activeEnrolments.find((enr) => enr.enrolmentId === enrolmentId);
  };

export const getHolidayPauseDuration = (
  state
): {
  pauseStart: Moment;
  pauseEnd: Moment;
} => {
  const term = getTerm123PauseSessionsFormData(state);
  const { endDate: termEndDate, nextTermStartDate } = term;
  /* 
  if the pause is initiated before the school holidays, the start date must be the day after
  the current term endDate; otherwise the startDate should be the current day
  */
  const endOfHoliday = moment(nextTermStartDate).subtract(1, 'days');
  if (moment().isSameOrBefore(termEndDate, 'day')) {
    return {
      pauseStart: moment(termEndDate).add(1, 'days'),
      pauseEnd: endOfHoliday,
    };
  } else {
    return {
      pauseStart: moment(),
      pauseEnd: endOfHoliday,
    };
  }
};

export const willEnrolmentBePaused = (state) => {
  const enrolmentPausedFrom = get(state, 'hubEnrolmentDetailPage.enrolmentPausedFrom', '');
  const enrolmentPausedTo = get(state, 'hubEnrolmentDetailPage.enrolmentPausedTo', '');
  return willEnrolmentBePausedCheck({ enrolmentPausedFrom, enrolmentPausedTo });
};

export const getEnrolmentWillBePausedResumeSession = (state, isDetailPage = false) => {
  const hubEnrolmentsWithResumeSession = state.hubEnrolmentsWithResumeSession || [];
  let enrolmentId = get(state, 'hubContactPage.selectedEnrolment', '');
  let enrolmentPausedFrom = get(state, 'hubContactPage.enrolmentPausedFrom', '');

  if (isDetailPage) {
    enrolmentId = get(state, 'hubEnrolmentDetailPage.enrolmentId', '');
    enrolmentPausedFrom = get(state, 'hubEnrolmentDetailPage.enrolmentPausedFrom', '');
  }

  if (isEmpty(hubEnrolmentsWithResumeSession) || !enrolmentId) {
    return {
      pauseFrom: enrolmentPausedFrom,
      resumeFrom: null,
    };
  }

  const found =
    hubEnrolmentsWithResumeSession.find((e) => {
      return e.enrolmentId === enrolmentId;
    }) || {};

  const { resumeSessionDate = null } = found;
  if (resumeSessionDate) {
    return {
      pauseFrom: enrolmentPausedFrom,
      resumeFrom: resumeSessionDate,
    };
  }

  return {
    pauseFrom: enrolmentPausedFrom,
    resumeFrom: null,
  };
};

export function summerPauseInsteadOfCancelEnabled(state) {
  return allowSummerHolidayPause(state);
}

export const getCancelEnrolmentBasePath = (state) => {
  const summerModeEnabled = summerPauseInsteadOfCancelEnabled(state);

  if (summerModeEnabled) {
    return PATH_HUB_CR_PAUSE_SUMMER_CANCEL;
  } else {
    return PATH_HUB_CR_CANCEL_ENROLMENT;
  }
};
