import React from 'react';
import PropTypes from 'prop-types';

export class GenericTextSection extends React.Component {
  render() {
    const {
      title,
      text,
      messageWhenEmpty,
      hideWhenEmpty,
    } = this.props;

    const textWithBr = text && text.trim().replace(/\n/g, '<br />');
    if (hideWhenEmpty && !textWithBr) {
      return null;
    }

    return (
      <div className="pb-3">
        {title && <div className="pt-3 card-subTitle font-bold">{title}</div>}
        {/* eslint-disable-next-line */}
        {textWithBr && <div className="break-words" dangerouslySetInnerHTML={{ __html: `&ldquo;${textWithBr}&rdquo;` }} />}
        {!textWithBr && messageWhenEmpty && <div>messageWhenEmpty</div>}
      </div>
    );
  }
}

GenericTextSection.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  messageWhenEmpty: PropTypes.string,
  hideWhenEmpty: PropTypes.bool,
};

GenericTextSection.defaultProps = {
  title: null,
  messageWhenEmpty: null,
  hideWhenEmpty: false,
  text: null,
};
