import { useMemo } from 'react';
import { Container } from '@cluey/cluey-components';
import { api } from '../../../../api';
import { LinkButton, Link } from '@cluey/cluey-components';
import {
  PATH_HUB_HOME,
  PATH_HUB_CR_ADD_ENROLMENT,
  PATH_HUB_MS_SESSION_TIMES,
  PATH_HUB_ENROLMENT_MANAGE_PAUSE,
} from '../../../../util/pagePath';
import { Redirect, generatePath, useHistory, useParams } from 'react-router';
import HubHeader from '../../../../common/hub/HubHeader';
import { PlusIcon } from '../../../../icons';
import { PATH_HUB_ADD_STUDENT, PATH_HUB_UPCOMING_SESSIONS } from '../../../../util/pagePath';
import first from 'lodash/first';
import { HubSessionsListItem } from '../../../../components/hub/sessions/HubSessionsListItem';
import { SkeletonLoader } from '../../../../components/SkeletonLoader';
import isNil from 'lodash/isNil';
import { LearningProfile } from './LearningProfile';
import { HubSessionListSkeletonItem } from '../../../../components/hub/sessions/HubSessionsListItem/HubSessionListSkeletonItem';
import { z } from 'zod';
import { StudentInformationFormData } from '../../../../components/hub/student/StudentInformationForm';
import { SpecialNeedsData } from '../../../../components/hub/student/SpecialNeeds';
import HubFooter from '../../../../common/hub/HubFooter/HubFooter';
import StudentAssessmentsOverview from './StudentAssessmentsOverview';
import withHeadTag from '../../../../common/HeadComponent';
import { ENROLMENT_STATUS } from '../../../../types/hubEnums';
import { useDispatch } from 'react-redux';
import { isEnrolmentPausedCheck, isGroupSessionCheck } from '../../../../util/helpers';
import { fetchEnrolment } from '../../../../actions/hub/hubEnrolmentManagementActions';
import useScrollToTop from '../../../../util/hooks/useScrollToTop';
import { useFeatureFlagEnabled, useFeatureFlagPayload } from 'posthog-js/react';
import moment from 'moment';
import { CodeCampPromo } from '../../../../components/hub/home/CodeCampPromo';

type CodeCampPromo = {
  startDate: string;
  endDate: string;
  yearLevels: string[];
};

const StudentProfile = () => {
  useScrollToTop();
  const { studentSfid } = useParams<{ studentSfid: string }>();
  const dispatch = useDispatch();
  const { data: students, isLoading: loadingStudents } = api.student.all.useQuery();
  const { data: accountDetails, isLoading: loadingAccountDetails } = api.account.details.useQuery();
  const { data: studentEnrolments, isLoading: loadingEnrolments } = api.enrolment.all.useQuery('active', {
    select: (data) => data.filter((enrolment) => enrolment.studentSfid === studentSfid),
  });
  const { data: sessions = [], isLoading: isLoadingSessions } = api.session.all.useQuery('upcoming');
  const history = useHistory();

  const student = students?.find((student) => student.studentId === studentSfid);

  const everyEnrolmentNextSession = useMemo(() => {
    return studentEnrolments
      ?.map((enrolment) => {
        const firstSession = first(enrolment.sessions);
        return sessions.find((sesh) => sesh.studentSessionId === firstSession?.studentSessionId);
      })
      .filter((sesh) => !isNil(sesh));
  }, [studentEnrolments, sessions]);

  const codeCampPromoFlagEnabled = useFeatureFlagEnabled('summer-pause-code-camp-promo');
  const codeCampPromo = useFeatureFlagPayload('summer-pause-code-camp-promo') as CodeCampPromo;

  const isDuringCodeCampPromo = moment().isBetween(codeCampPromo?.startDate, codeCampPromo?.endDate, 'day', '[]');
  const codeCampPromoEligibleEnrolments = studentEnrolments?.filter((enrolment) => {
    const isYearLevelEligible = codeCampPromo?.yearLevels.includes(enrolment.yearLevel);
    return isYearLevelEligible;
  });

  const isAU = accountDetails?.country === 'AU';
  const showCodeCampPromo =
    codeCampPromoFlagEnabled && isDuringCodeCampPromo && codeCampPromoEligibleEnrolments?.length > 0 && isAU;
  console.log('showCodeCampPromo ', {
    showCodeCampPromo,
    isDuringCodeCampPromo,
    codeCampPromoEligibleEnrolments,
    isAU,
    codeCampPromo,
  });

  if (!loadingStudents && !student) {
    return <Redirect to={PATH_HUB_HOME} />;
  }

  const onEnrolNow = () => {
    const { firstName, lastName, yearLevel, location, studentId } = student ?? {};
    history.push(PATH_HUB_CR_ADD_ENROLMENT, {
      firstName,
      lastName,
      yearLevel: isNil(yearLevel) ? undefined : yearLevel,
      location: isNil(location) ? undefined : location,
      studentId,
    });
  };

  const hasUpcomingSession = everyEnrolmentNextSession?.length > 0;
  const cancelRequestedEnrolments =
    studentEnrolments?.filter((enrolment) => enrolment.enrolmentStatus === ENROLMENT_STATUS.CANCEL_REQUESTED) || [];

  return (
    <div className="pb-24">
      <HubHeader title="Hub" />
      <Container className="px-4 py-7 md:py-8 lg:mb-0">
        <div className="flex flex-col items-start justify-between md:flex-row md:items-center">
          <div>
            <SkeletonLoader className="h-5 w-9" loading={loadingStudents}>
              <Link className="text-xs font-bold uppercase" to={PATH_HUB_HOME}>
                home
              </Link>
            </SkeletonLoader>
            <SkeletonLoader className="mt-2 h-10 w-72" loading={loadingStudents}>
              <h1 className="font-display text-[28px] font-bold">
                {student?.firstName} {student?.lastName}
              </h1>
            </SkeletonLoader>
          </div>
          <SkeletonLoader className="h-8 w-40" loading={loadingStudents}>
            <LinkButton
              to={PATH_HUB_ADD_STUDENT}
              appearance="reverse"
              className="mt-8 flex items-center justify-between gap-2 !font-bold md:mt-0"
            >
              <PlusIcon width={14} height={14} />
              <span className="uppercase">Add a student</span>
            </LinkButton>
          </SkeletonLoader>
        </div>
        <div className="mt-8">
          <SkeletonLoader className="h-7 w-28" loading={isLoadingSessions}>
            <h2 className="text-xl font-bold">Sessions</h2>
          </SkeletonLoader>
          <div className="mt-4 flex flex-col gap-y-4 rounded-lg border border-grey-3 px-4 py-4 shadow-md md:px-20 md:py-14">
            <SkeletonLoader className="h-4 w-14" loading={isLoadingSessions}>
              <h2 className="text-xs font-bold uppercase">Upcoming sessions</h2>
            </SkeletonLoader>
            <div className="flex flex-col items-start justify-between text-sm md:flex-row md:items-center">
              <SkeletonLoader className="h-5 w-32" loading={loadingAccountDetails || isLoadingSessions}>
                <span>Times are in your account timezone ({accountDetails?.timezone})</span>
              </SkeletonLoader>
              <SkeletonLoader className="h-5 w-14" loading={loadingAccountDetails || isLoadingSessions}>
                {hasUpcomingSession && (
                  <Link className="mt-2.5 text-base md:mt-0" to={PATH_HUB_UPCOMING_SESSIONS}>
                    View all sessions
                  </Link>
                )}
              </SkeletonLoader>
            </div>
            {loadingEnrolments || isLoadingSessions ? (
              <HubSessionListSkeletonItem />
            ) : (
              <div className="flex flex-col gap-y-4">
                {hasUpcomingSession &&
                  everyEnrolmentNextSession.map((session) => (
                    <HubSessionsListItem
                      key={session.studentSessionId}
                      session={session}
                      timezone={accountDetails.timezone}
                    />
                  ))}
                {cancelRequestedEnrolments.map((enrolment) => {
                  const enrolmentId = enrolment.enrolmentId || '';
                  const isPaused = isEnrolmentPausedCheck({ enrolmentStatus: enrolment.enrolmentStatus });
                  const isGroup = isGroupSessionCheck(enrolment.type);
                  const sessionsAndScheduleOptionsPath = !!enrolmentId
                    ? generatePath(PATH_HUB_MS_SESSION_TIMES, { enrolmentId })
                    : '';
                  const managePausePath = `${PATH_HUB_ENROLMENT_MANAGE_PAUSE}?token=${enrolmentId}`;
                  const clearPreviouslySelectedEnrolment = () => {
                    dispatch(fetchEnrolment({ enrolmentId })); // see: components/hub/enrolments/EnrolmentCard/EnrolmentCard.tsx line 88 for explanation
                  };
                  const manageEnrolment = () => {
                    if (isPaused) {
                      clearPreviouslySelectedEnrolment();
                    }
                    history.push(isPaused && !isGroup ? managePausePath : sessionsAndScheduleOptionsPath);
                  };

                  return (
                    <div className="flex flex-col items-start justify-between rounded-lg border border-dashed border-slate-3 px-7 py-5 md:flex-row md:items-center">
                      <div className="flex flex-row flex-wrap items-center gap-2">
                        <div className="text-base font-bold">{student?.firstName}</div>
                        <div className="h-3 w-0.5 rounded-full bg-slate-4 opacity-40"></div>
                        <p>
                          {enrolment.yearLevel} {enrolment.subject} {enrolment.tutor && `(with ${enrolment.tutor})`}
                        </p>
                        <p className="font-bold">Cancellation requested</p>
                      </div>
                      <button className="mt-2 self-start text-blue-5 md:mt-0" onClick={manageEnrolment}>
                        Manage
                      </button>
                    </div>
                  );
                })}
                {!hasUpcomingSession && cancelRequestedEnrolments.length < 1 && (
                  <div className="flex flex-col items-start justify-between rounded-lg border border-dashed border-slate-3 px-7 py-5 md:flex-row md:items-center">
                    <div>
                      <span className="text-base font-bold">{student?.firstName}</span>
                      <span> &nbsp;|&nbsp; No active enrolment</span>
                    </div>
                    <button className="text-blue-5" onClick={onEnrolNow}>
                      + Enrol now
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Container>
      <StudentAssessmentsOverview student={student} />
      {showCodeCampPromo && (
        <Container className="my-4">
          <CodeCampPromo isStudentProfilePage />
        </Container>
      )}
      <LearningProfile loading={loadingStudents} student={student} />
      <div className="h-64"></div>
      <HubFooter />
    </div>
  );
};

export const StudentProfileData = z.intersection(StudentInformationFormData, SpecialNeedsData);
export type StudentProfileData = z.infer<typeof StudentProfileData>;

const StudentProfileWithHeadTag = withHeadTag(StudentProfile);

export { StudentProfileWithHeadTag as StudentProfile };
