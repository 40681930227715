import iconCalendar from '../../../assets/images/icon-calendar--orange.svg';
import { getTimezone } from '../../../selectors/hubSelector';
import { useSelector } from 'react-redux';
import { DATE_FORMAT_TYPE_1, SESSION_TIME_FORMAT } from '../../../util/constants';
import moment from 'moment';

interface Props {
  heading: string;
  sessionStartDate: string | moment.Moment;
  sessionEndDate: string | moment.Moment;
  isOneOffRescheduled?: boolean;
}

const SessionDateSummary = ({ heading, sessionStartDate, sessionEndDate, isOneOffRescheduled }: Props) => {
  const timezone: string = useSelector(getTimezone);
  const date = moment(sessionStartDate)
  .tz(timezone)
  .format(DATE_FORMAT_TYPE_1);
  const time = `${moment(sessionStartDate)
    .tz(timezone)
    .format(SESSION_TIME_FORMAT)}
    - ${moment(sessionEndDate).tz(timezone).format(SESSION_TIME_FORMAT)}`;
  return (
    <div>
      <h3 className="pb-3 text-xs font-bold text-grey-6 uppercase tracking-wide">{heading}</h3>
      <div className="flex items-start">
        <img src={iconCalendar} alt="icon" className="mr-5 lg:mr-6" />
        <div className="text-left text-grey-6">
          <p className="text-lg leading-tight">{date}</p>
          <p className="text-lg leading-tight lg:mb-4">{time}</p>
          {isOneOffRescheduled && (
            <p>One-off rescheduled session</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default SessionDateSummary;