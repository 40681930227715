import { Alert, Modal, Pending } from '@cluey/cluey-components';
import { Fragment, useState } from 'react';
import { generatePath, useHistory } from 'react-router';
import moment from 'moment';
import { capitalize, orderBy } from 'lodash';
import {
  PATH_HUB_CR_CHANGE_TUTOR,
  PATH_HUB_CR_PAUSE_SESSIONS,
  PATH_HUB_ENROLMENT_CHANGE_SCHEDULE,
  PATH_HUB_RESCHEDULE_SESSION,
  PATH_HUB_SKIP_SESSION,
  PATH_HUB_CR_OTHER_QUERY,
  PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP,
  PATH_HUB_SESSION_MESSAGE,
} from '../../../util/pagePath';
import { DATE_FORMAT_TYPE_4, SESSION_TIME_FORMAT } from '../../../util/constants';
import { OptionCard, OptionCardContent } from '../../EnrolmentManagement/OptionCard';
import { useActions } from '../../../util/hooks/useActions';
import { fetchEnrolment } from '../../../actions/hub/hubEnrolmentManagementActions';
import { isGroupSessionCheck } from '../../../util/helpers';
import { IntentIntro } from '../../IntentIntro';
import { Loader } from '../../../components/Loader';
import { useGetSessionPolicy } from '../../../hooks/queries/useSessionQuery';
import type { Session } from '../../../types/hubInterfaces';
import { api } from '../../../api';
import posthog from 'posthog-js';
import { useSelector } from 'react-redux';
import { getCancelEnrolmentBasePath } from '../../../selectors/enrolmentSelector';

function useNextUpcomingSession(uniqueEnrolmentId: string) {
  const { data: upcomingSessions = [] } = api.session.all.useQuery('upcoming', {
    select: (data) => {
      const filteredSessions = data.filter((s) => {
        return moment(s.startTime).isSameOrAfter(new Date(), 'day') && s.uniqueEnrolmentId === uniqueEnrolmentId;
      });
      return orderBy(filteredSessions, ['startTime'], ['asc']);
    },
  });
  const nextUpcomingSession = upcomingSessions[0];

  return nextUpcomingSession;
}

interface Props {
  session: Session;
  timezone: string;
  open: boolean;
  closeModal(): void;
}

const ChangeSessionModal = ({ session, timezone, open, closeModal }: Props) => {
  const {
    student,
    tutor,
    subject,
    startTime: sessionStartDate,
    endTime: sessionEndDate,
    uniqueEnrolmentId,
    studentSessionId,
  } = session;
  const cancelEnrolmentBasePath = useSelector(getCancelEnrolmentBasePath);
  const nextUpcomingSession = useNextUpcomingSession(uniqueEnrolmentId);
  const isNextUpcomingSession = studentSessionId === nextUpcomingSession?.studentSessionId;
  const getEnrolmentDetails = useActions(fetchEnrolment);
  const history = useHistory();
  const startTime = moment(sessionStartDate).tz(timezone).format(SESSION_TIME_FORMAT);
  const endTime = moment(sessionEndDate).tz(timezone).format(SESSION_TIME_FORMAT);
  const sessionDay = moment(sessionStartDate).format(DATE_FORMAT_TYPE_4);
  const possessiveNoun = student ? `${student}'s` : 'your';
  const noun = subject ? `${subject} session` : 'session';
  const sessionIdParam = studentSessionId ? { sessionId: studentSessionId } : undefined;
  const pathWithSessionId = (path: string) => (sessionIdParam ? generatePath(path, sessionIdParam) : '');
  const redirectToWithToken = (path: string, uniqueEnrolmentId: string, queryParam?: URLSearchParams) => {
    if (queryParam) {
      history.push(`${path}?${queryParam.toString()}&token=${uniqueEnrolmentId}`);
    } else {
      history.push(`${path}?token=${uniqueEnrolmentId}`);
    }
  };
  const bodyText = `${capitalize(
    possessiveNoun
  )} ${noun} (with ${tutor}) on ${sessionDay} at ${startTime} - ${endTime}`;

  const isGroupSession = isGroupSessionCheck(session.type);
  const {
    data: sessionPolicy,
    isLoading: isPolicyLoading,
    error: policyError,
  } = useGetSessionPolicy({ sessionId: session?.studentSessionId, enabled: open });
  const canBeRescheduled = sessionPolicy?.reschedule?.isAllowed;

  const createHeading = () => {
    if (policyError) {
      return (
        <Alert
          className="mt-2"
          type="error"
          body={`There was an error retrieving this session's policy. ${policyError}`}
        />
      );
    }
    if (canBeRescheduled) {
      return <IntentIntro className="mb-10">{bodyText}</IntentIntro>;
    } else {
      return (
        <Fragment>
          <IntentIntro className="mb-10">{bodyText}</IntentIntro>
          <div className="mb-10 text-xs font-bold uppercase text-red-5">
            {sessionPolicy?.reschedule?.externalMessage}
          </div>
        </Fragment>
      );
    }
  };

  return (
    <Modal open={open} setOpen={closeModal} closeOnBackground accent closeButton>
      <Pending loading={isPolicyLoading} loader={<Loader />}>
        <div className="px-12 py-14">
          <h2 className="pb-6 font-display text-3xl font-bold tracking-[-0.02rem]">Manage session</h2>
          {createHeading()}
          <OptionCard
            linkTo={pathWithSessionId(PATH_HUB_RESCHEDULE_SESSION)}
            disabled={!canBeRescheduled}
            capturePosthogEvent={
              canBeRescheduled ? () => posthog.capture('click_manage-session_reschedule-session') : undefined
            }
          >
            <OptionCardContent disabled={!canBeRescheduled}>Reschedule this session</OptionCardContent>
          </OptionCard>
          <OptionCard
            linkTo={pathWithSessionId(PATH_HUB_SKIP_SESSION)}
            disabled={false}
            capturePosthogEvent={() => posthog.capture('click_manage-session_cancel-session')}
          >
            <OptionCardContent disabled={false}>Cancel this session</OptionCardContent>
          </OptionCard>
          {isNextUpcomingSession && (
            <OptionCard
              linkTo={generatePath(PATH_HUB_SESSION_MESSAGE, { sessionId: studentSessionId })}
              capturePosthogEvent={() => posthog.capture('click_manage-session_add-session-message')}
            >
              <OptionCardContent>Let the tutor know something about this session</OptionCardContent>
            </OptionCard>
          )}

          <h3 className="mb-4 mt-7 font-display text-2xl font-bold">Ongoing sessions</h3>

          <OptionCard
            onClickHandler={() => {
              getEnrolmentDetails({ enrolmentId: uniqueEnrolmentId });
              posthog.capture('click_manage-session_change-schedule');
              history.push(
                isGroupSession
                  ? PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP
                  : `${PATH_HUB_ENROLMENT_CHANGE_SCHEDULE}?token=${uniqueEnrolmentId}`
              );
            }}
          >
            <OptionCardContent>Change ongoing schedule</OptionCardContent>
          </OptionCard>

          <OptionCard
            onClickHandler={() => {
              posthog.capture('click_manage-session_pause-enrolment');
              redirectToWithToken(PATH_HUB_CR_PAUSE_SESSIONS, uniqueEnrolmentId);
            }}
          >
            <OptionCardContent>Pause enrolment</OptionCardContent>
          </OptionCard>

          <OptionCard
            onClickHandler={() => {
              posthog.capture('click_manage-session_cancel-enrolment');
              redirectToWithToken(cancelEnrolmentBasePath, uniqueEnrolmentId, new URLSearchParams({ view: 'form' }));
            }}
          >
            <OptionCardContent>Cancel enrolment</OptionCardContent>
          </OptionCard>

          <OptionCard
            onClickHandler={() => {
              posthog.capture('click_manage-session_change-tutor');
              redirectToWithToken(PATH_HUB_CR_CHANGE_TUTOR, uniqueEnrolmentId);
            }}
          >
            <OptionCardContent>{`Provide feedback, or request a change regarding ${possessiveNoun} tutor`}</OptionCardContent>
          </OptionCard>

          <OptionCard
            onClickHandler={() => {
              posthog.capture('click_manage-session_something-else');
              redirectToWithToken(PATH_HUB_CR_OTHER_QUERY, uniqueEnrolmentId);
            }}
          >
            <OptionCardContent>Something else</OptionCardContent>
          </OptionCard>
        </div>
      </Pending>
    </Modal>
  );
};

export const useChangeSessionModal = () => {
  const [open, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  return {
    open,
    openModal,
    closeModal,
    ChangeSessionModal,
  };
};

export default ChangeSessionModal;
