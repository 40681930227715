import React from 'react';
import ClueyLoaderWhite from '../assets/images/cluey-loader-white.gif';

export const Loader = () => {
  return (
    <div className="flex justify-center">
      <img src={ClueyLoaderWhite} alt="Loading" width="100px" height="100px" />
    </div>
  );
};
