import * as actionTypes from './actionTypes';

export const setExceptionType =
  ({ exceptionType }) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.RS_RESCHEDULE_SESSION_EXCEPTION,
      payload: {
        exceptionType,
      },
    });
  };
