import classNames from 'classnames';
import { isNumber } from 'lodash';
import { useMemo } from 'react';
import ErrorText from '../../../components/ErrorText';

export interface TextBoxProps {
	value: string;
	handleOnChange: (value: string) => void;
	maxLength?: number;
	error?: string;
}

const errorClassNames = 'border-red-5 bg-red-2';

const TextBox = ({ maxLength, handleOnChange, value, error }: TextBoxProps) => {
	const charactersLeft = useMemo(() => maxLength - value.length, [maxLength, value]);

	return (
		<div>
			<div className="mb-1.5 text-sm font-medium">Provide your message</div>
			<textarea
				className={classNames(
					'h-[180px] w-full max-w-[536px] resize-none rounded border border-grey-2 p-4',
					error && errorClassNames
				)}
				onChange={(e) => handleOnChange(e.target.value)}
				maxLength={maxLength}
			/>
			{error && (
				<ErrorText className="mt-1.5" icon>
					{error}
				</ErrorText>
			)}
			{!error && isNumber(maxLength) && (
				<div className="mt-1.5 text-sm font-medium">{`Characters left ${charactersLeft}/255`}</div>
			)}
		</div>
	);
};

export default TextBox;
