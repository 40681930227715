import { z } from 'zod';
import { createApi } from '../core';
import { Student } from '../types/student';

export type StudentParams = {
	customerType: 'Account' | 'Lead';
	customerNumber: string; // "S1015631353A33383A32:
	firstname: string;
	lastname: string;
	curriculum: string; // "NSW" or "QLD"
	yearLevel: string; // "5" and not "Year 5"
	schoolId: string; // "0011I00000VJmC1QAL"
	specialLearningNeeds?: string;
};

export type CreateStudentParams = Omit<StudentParams, 'customerType' | 'customerNumber'>;

export type UpdateStudentParams = Pick<StudentParams, 'customerType' | 'customerNumber'> &
	Partial<Omit<StudentParams, 'customerType' | 'customerNumber'>>;

export const studentApi = createApi.group(['students'], (createKey) => {
	const config = {
		queryFn: async () => {
			const response = await createApi.fetch({
				path: ['students'],
				method: 'GET',
				basePath: 'portalAPI',
				sign: true,
				validator: z.object({ data: z.array(Student) }),
			});
			return response.data;
		},
		staleTime: createApi.time(1, 'hours'),
		refetchInterval: createApi.time(2, 'hours'),
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	};

	return {
		all: createApi.query({
			...config,
			queryKey: createKey(['all']),
		}),
		update: createApi.mutation({
			mutationFn: (params: Partial<UpdateStudentParams>) => {
				try {
					return createApi.fetch({
						path: ['customer', 'details'],
						method: 'PUT',
						body: params,
						validator: z.unknown(),
						basePath: 'portalAPI',
						sign: true,
					});
				} catch (error) {
					console.error('THROWN', error);
				}
			},
		}),
		create: createApi.mutation({
			mutationFn: (params: Partial<CreateStudentParams>) => {
				try {
					return createApi.fetch({
						path: ['students'],
						method: 'POST',
						body: params,
						validator: z.unknown(),
						basePath: 'portalAPI',
						sign: true,
					});
				} catch (error) {
					throw error;
				}
			},
		}),
	};
});
