import { generatePath } from 'react-router';

import {
  PATH_SESSION_REPORT,
  PATH_HUB_CR_CHANGE_CONTENT_FOCUS,
  PATH_HUB_ENROLMENT_NAPLAN,
  PATH_HUB_CR_OPT_IN_OUT,
  PATH_HUB_CR_FEEDBACK,
  PATH_HUB_CR_CHANGE_TUTOR,
  PATH_HUB_MS_SESSION_TIMES,
} from '../../../../../util/pagePath';
import { STUDENT_PROGRESS_TAB_VIEWS } from '../../../../../util/constants';
import { ELEMENT_TYPES, INTENT_OPTION } from '../../../../../types/hubEnums';

interface GenerateLearningAdjustmentsOptions {
  isAccountOwner: boolean;
  studentName: string;
  sessionReportToken: string;
  enrolmentId: string;
  showNAPLAN: boolean;
  isGroup: boolean;
  showPractice: boolean;
}

export const generateLearningAdjustmentsOptions = ({
  isAccountOwner,
  studentName,
  sessionReportToken,
  enrolmentId,
  showNAPLAN,
  isGroup,
  showPractice
}: GenerateLearningAdjustmentsOptions): Array<INTENT_OPTION> => {
  const possessiveNoun = isAccountOwner ? 'your' : `${studentName}'s`;

  const options = [];
  options.push({
    label: `Review ${possessiveNoun} session feedback and recordings`,
    linkTo: `${PATH_SESSION_REPORT}?token=${sessionReportToken}&activeTab=${STUDENT_PROGRESS_TAB_VIEWS.SESSION_REPORT}`,
    type: ELEMENT_TYPES.ANCHOR,
  });
  options.push({
    label: `Review ${possessiveNoun} session learning progress overview`,
    linkTo: `${PATH_SESSION_REPORT}?token=${sessionReportToken}&activeTab=${STUDENT_PROGRESS_TAB_VIEWS.PROGRESS_OVERVIEW}`,
    type: ELEMENT_TYPES.ANCHOR,
  });
  options.push({
    label: `Provide input into content and topics covered in ${possessiveNoun} sessions`,
    linkTo: `${PATH_HUB_CR_CHANGE_CONTENT_FOCUS}?token=${enrolmentId}`,
    type: ELEMENT_TYPES.LINK,
  });

  if (showNAPLAN) {
    options.push({
      label: 'Manage NAPLAN learning options',
      linkTo: `${PATH_HUB_ENROLMENT_NAPLAN}?token=${enrolmentId}`,
      type: ELEMENT_TYPES.LINK,
    });
  }

  showPractice && options.push({
    label: "Let the tutor know whether you'd like to opt in or out of assigned practice",
    linkTo: `${PATH_HUB_CR_OPT_IN_OUT}?token=${enrolmentId}`,
    type: ELEMENT_TYPES.LINK,
  });
  options.push({
    label: `Provide feedback for ${possessiveNoun} tutor`,
    linkTo: `${PATH_HUB_CR_FEEDBACK}?token=${enrolmentId}`,
    type: ELEMENT_TYPES.LINK,
  });
  !isGroup && options.push({
    label: `Request a change to ${possessiveNoun} tutor`,
    linkTo: `${PATH_HUB_CR_CHANGE_TUTOR}?token=${enrolmentId}`,
    type: ELEMENT_TYPES.LINK,
  });
  options.push({
    label: 'Make changes to session times, schedules or frequency',
    linkTo: generatePath(PATH_HUB_MS_SESSION_TIMES, { enrolmentId }),
    type: ELEMENT_TYPES.LINK,
  });

  return options;
};
