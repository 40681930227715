import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import errorIcon from '../assets/images/schedule-session-icon.svg';

const Error = ({ firstLine, secondLine, children }) => {
  return (
    <div className="m-auto flex flex-col items-center py-4 text-center text-grey-6 lg:py-10">
      <img src={errorIcon} alt="error" className="mb-5" />
      {firstLine && <span className="mb-2 text-xl font-bold">{firstLine}</span>}
      {secondLine && <span className="mb-5 text-lg">{secondLine}</span>}
      {children}
    </div>
  );
};

Error.propTypes = {
  firstLine: PropTypes.string,
  secondLine: PropTypes.string,
  children: PropTypes.node,
};

Error.defaultProps = {
  firstLine: null,
  secondLine: null,
  children: null,
};

export default Error;

export const ErrorMsg = ({ text, extraClasses }) => {
  return (
    <p
      className={classNames(
        'mb-0 text-xs font-medium tracking-normal text-red-5',
        extraClasses
      )}
    >
      {text}
    </p>
  );
};

ErrorMsg.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
  ]).isRequired,
  extraClasses: PropTypes.string,
};

ErrorMsg.defaultProps = {
  extraClasses: '',
};
