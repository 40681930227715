import { IconProps as Props } from "../types/hubInterfaces";
import { iconDefaults } from ".";
import classNames from 'classnames';

function CreditCardIcon(props: Props) {
  const { width, height, className } = { ...iconDefaults, ...props };
  return (
    <svg className={classNames('fill-current', className)} xmlns="http://www.w3.org/2000/svg" width={`${width}`} height={`${height}`} viewBox="0 0 16 16">
      <title>credit card icon</title>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.21981 10.2125H4.36648C3.97414 10.2125 3.65314 9.88498 3.65314 9.48464C3.65314 9.0843 3.97414 8.75675 4.36648 8.75675H7.21981C7.61214 8.75675 7.93314 9.0843 7.93314 9.48464C7.93314 9.88498 7.61214 10.2125 7.21981 10.2125ZM11.4998 10.2125H10.0731C9.6808 10.2125 9.3598 9.88499 9.3598 9.48465C9.3598 9.08431 9.6808 8.75676 10.0731 8.75676H11.4998C11.8921 8.75676 12.2131 9.08431 12.2131 9.48465C12.2131 9.88499 11.8921 10.2125 11.4998 10.2125ZM13.6398 10.9404C13.6398 11.3415 13.3202 11.6683 12.9265 11.6683H2.93979C2.54603 11.6683 2.22646 11.3415 2.22646 10.9404V7.30095H13.6398V10.9404ZM2.22646 5.11728C2.22646 4.71621 2.54603 4.38939 2.93979 4.38939H12.9265C13.3202 4.38939 13.6398 4.71621 13.6398 5.11728V5.84517H2.22646V5.11728ZM12.9265 2.93359H2.9398C1.75995 2.93359 0.799805 3.91334 0.799805 5.11727V10.9404C0.799805 12.1443 1.75995 13.1241 2.9398 13.1241H12.9265C14.1063 13.1241 15.0665 12.1443 15.0665 10.9404V5.11727C15.0665 3.91334 14.1063 2.93359 12.9265 2.93359Z" />
    </svg>
  )
}

export { CreditCardIcon };