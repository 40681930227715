import React, { useState } from 'react';
import Flatpickr from 'react-flatpickr';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import classnames from 'classnames';
import './flatpickr.scss';

const defaultOptions = {
  enableTime: false,
  minDate: 'today',
  altInput: true,
  altFormat: 'l, j F Y',
  dateFormat: 'Y-m-d',
  disableMobile: true,
};

export const displayDate = (dateObj) => {
  if (dateObj instanceof Date === false) {
    return '';
  }

  const dayAsMonthNumber = dateObj.getDate();
  const normalisedMonthNumber = dateObj.getMonth() + 1;
  const yearAsNumber = dateObj.getFullYear();

  return `${dayAsMonthNumber}/${normalisedMonthNumber}/${yearAsNumber}`;
};

// A custom hook to make it easier to get the necessary props for an datepicker field
export function useDatePickerProps({
  id,
  defaultDate,
  options = {},
  placeholder,
  labelText,
  customInputClasses,
  mutedText,
  mutedLabelText,
}) {
  const [value, setValue] = useState(defaultDate ?? '');
  const [highlightClass, setHighlightClass] = useState('');

  // Note: It's preferred to come up with a non dynamic id to avoid DOM updates
  if (!id) {
    id = uuid();
  }

  return {
    id,
    value,
    setValue,
    options: { ...defaultOptions, ...options },
    placeholder,
    labelText,
    customInputClasses,
    mutedLabelText,
    mutedText,
    highlightClass,
    setHighlightClass,
  };
}

export const DatePicker = ({
  id,
  placeholder,
  labelText,
  value,
  setValue,
  customInputClasses,
  options,
  mutedLabelText,
  mutedText,
  highlightClass,
}) => {
  // Use className "custom-select" when you want it to look like a dropdown
  return (
    <section className="flex flex-col">
      {labelText && (
        <div className="pb-2">
          <label htmlFor={id}>{labelText}</label>
          {mutedLabelText && <span className="ml-3 text-xs text-grey-4">{mutedLabelText}</span>}
        </div>
      )}

      <Flatpickr
        id={id}
        placeholder={placeholder}
        className={classnames(
          customInputClasses,
          'bg-white, rounded border border-grey-3 px-2 py-2 focus:outline-none focus:ring focus:ring-blue-3',
          mutedText && 'mb-3',
          highlightClass
        )}
        data-enable-time
        value={value}
        onChange={(date) => {
          setValue(date);
        }}
        options={options}
      />
      {mutedText && <span className="text-xs text-grey-4">{mutedText}</span>}
    </section>
  );
};

DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.instanceOf(Date))]),
  setValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  labelText: PropTypes.string,
  customInputClasses: PropTypes.string,
  options: PropTypes.shape({
    enableTime: PropTypes.bool,
    minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    altInput: PropTypes.bool,
    altFormat: PropTypes.string,
    dateFormat: PropTypes.string,
    disableMobile: PropTypes.bool,
  }),
  mutedLabelText: PropTypes.string,
  mutedText: PropTypes.string,
  highlightClass: PropTypes.string,
};
DatePicker.defaultProps = {
  value: '',
  labelText: null,
  placeholder: '',
  customInputClasses: '',
  options: defaultOptions,
  mutedLabelText: null,
  mutedText: null,
  highlightClass: '',
};
