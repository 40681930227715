import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { PASSWORD_VALIDATION_RESULT } from '../../../../util/constants';
import iconNo from '../../../../assets/images/hubAuth/icon-cross--red.svg';
import iconYes from '../../../../assets/images/hubAuth/icon-tick--green.svg';

const PWDValidationResult = ({ pwdValidation }) => {
	return (
		<Fragment>
			{pwdValidation === PASSWORD_VALIDATION_RESULT.MATCH && (
				<div className="mt-2 flex items-center text-green-4">
					<img src={iconYes} alt="password valid" />
					<p className="mb-0 leading-none">Looks good</p>
				</div>
			)}
			{pwdValidation === PASSWORD_VALIDATION_RESULT.INVALID && (
				<div className="mt-2 flex items-center text-red-5">
					<img src={iconNo} alt="password invalid" />
					<p className="mb-0 leading-none">
						Please enter at least 8 characters with 1 uppercase letter, 1 lowercase letter and 1 number
					</p>
				</div>
			)}
			{pwdValidation === PASSWORD_VALIDATION_RESULT.NOT_MATCH && (
				<div className="mt-2 flex items-center text-red-5">
					<img src={iconNo} alt="password invalid" />
					<p className="mb-0 leading-none">The password entered doesn{'\u2019'}t match</p>
				</div>
			)}
		</Fragment>
	);
};

PWDValidationResult.propTypes = {
	pwdValidation: PropTypes.string.isRequired,
};

export default PWDValidationResult;
