import { Confirm } from './Confirm/Confirm';
import { DateAndTimeSelection } from './DateAndTimeSelection/DateAndTimeSelection';
import { FormFlow } from '../../../../components/FormFlow/FormFlow';
import { PATH_HUB_HOME } from '../../../../util/pagePath';
import { Success } from './Success/Success';
import { useEffect } from 'react'; 
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getSpecificActiveEnrolment } from '../../../../selectors/enrolmentSelector';

export interface Params {
  enrolmentId?: string
}

const AddOneOffSession = () => {
  const { enrolmentId } = useParams<Params>();
  const enrolment = useSelector(getSpecificActiveEnrolment({ enrolmentId }));
  const history = useHistory();

  const formElements = {
    '': DateAndTimeSelection,
    '/confirm': Confirm,
    '/success': Success
  }

  useEffect(() => {
    if (!enrolment) {
      history.push(PATH_HUB_HOME);
    }
  }, [enrolment, history])

  return <FormFlow elements={formElements} />
};

export { AddOneOffSession };