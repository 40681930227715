import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { generatePath, Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router';

const join = (base, path) => {
	return base.charAt(base.length - 1) === '/' ? base.slice(0, -1) + path : base + '/' + path;
};

export const FormFlow = (props) => {
	const history = useHistory();
	const params = useParams();
	const { path } = useRouteMatch();
	const { elements, onFinish, onCancel, state, setState } = props;
	const entries = useMemo(() => Object.entries(elements), [elements]);

	return (
		<Switch>
			{entries.map(([elementPath, Element], index) => {
				const jump = (toIndex, state) => {
					const newPath = generatePath(join(path, entries[toIndex][0]), params);
					history.push(newPath, state);
				};

				const next = (state) => {
					if (index === entries.length - 1) {
						onFinish();
					} else {
						jump(index + 1, state);
					}
				};

				const previous = (state) => {
					if (index === 0) {
						onCancel();
					} else {
						jump(index - 1, state);
					}
				};

				return (
					<Route key={path} exact path={join(path, elementPath)}>
						<Element
							formFlowProps={{
								basePath: elementPath,
								index,
								next,
								previous,
								jump,
								state,
								setState,
							}}
						/>
					</Route>
				);
			})}
			<Route>
				<Redirect to={generatePath(join(path, entries[0][0]), params)} />
			</Route>
		</Switch>
	);
};

FormFlow.propTypes = {
	elements: PropTypes.objectOf(PropTypes.elementType).isRequired,
	onFinish: PropTypes.func,
	onCancel: PropTypes.func,
	state: PropTypes.any, // eslint-disable-line react/forbid-prop-types
	setState: PropTypes.func,
};

FormFlow.defaultProps = {
	state: {},
	onCancel: () => {},
	onFinish: () => {},
	setState: () => {},
};

export const FormFlowProps = {
	basePath: PropTypes.string.isRequired,
	index: PropTypes.string.isRequired,
	next: PropTypes.func.isRequired,
	previous: PropTypes.func.isRequired,
	jump: PropTypes.func.isRequired,
	state: PropTypes.any,
	setState: PropTypes.func,
};
