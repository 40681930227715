import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import { Form, Tooltip } from '@cluey/cluey-components';

import withSelectedEnrolment from '../../../common/hub/NewWithSelectedEnrolment';
import ContactFormLayout from '../../../common/hub/ContactFormLayout';
import { useRadioGroupProps, RadioGroup } from '../../../components/RadioGroup/RadioGroup';
import { useTextAreaProps, TextArea } from '../../../components/TextArea/TextArea';
import { submitCaseToSF } from '../../../actions/hub/hubContactPageActions';
import FormSubHeading from '../../../components/hub/contact/FormSubHeading';
import { RestrictedButton } from '../../../components/RestrictedButton/RestrictedButton';
import ErrorBlock from '../../../common/ErrorBlock';
import { PATH_HUB_CR_SUBMIT_CASE_SUCCESS, PATH_HUB_CR_TECHNICAL_ISSUE } from '../../../util/pagePath';
import iconInformation from '../../../assets/images/icon-information.svg';

const FormTechnicalHelp = ({ selectedEnrolment, isSubmitting, submitError, errorMsg, submit }) => {
  const groupName = 'contact-form-technical-help';
  const radios = [
    {
      text: 'Request a tech check',
      value: 'Tech check',
      key: `${groupName}-category-1`,
      renderNextToLabel: () => (
        <div className="ml-2 flex items-start">
          <Tooltip content="A system hardware test on your computer can be performed by Cluey upon request">
            <img id="account-billing-information-cvv-tooltip" alt="information" src={iconInformation} />
          </Tooltip>
        </div>
      ),
      containerClassName: 'flex items-center cursor-pointer',
      wrapper: true,
      wrapperClassName: 'flex items-center mb-2',
    },
    {
      text: 'Report an ongoing technical issue',
      value: 'Platform',
      key: `${groupName}-category-2`,
    },
  ];

  const radioGroupProps = useRadioGroupProps({ groupName, radios });
  const textAreaProps = useTextAreaProps({
    id: `${groupName}-textarea`,
    labelText: 'Is there anything else you’d like to tell us?',
  });

  const { enrolmentId, studentSfid, student, subject } = selectedEnrolment;
  const history = useHistory();
  const onClickHandler = () => {
    const caseType = 'Technology';
    const caseCategory = radioGroupProps.selectedRadio;

    let message = `${caseCategory}. Other details: ${textAreaProps.value}`;
    if (caseCategory === 'Tech check') {
      message = `Request for ${caseCategory}. Other details: ${textAreaProps.value}`;
    }
    submit({
      uniqueEnrolmentId: enrolmentId,
      message,
      caseSubject: `Contact Request - ${caseType} - ${caseCategory}`,
      caseType,
      caseCategory,
      onSubmitSuccess: () => {
        history.push(PATH_HUB_CR_SUBMIT_CASE_SUCCESS, {
          firstName: student,
          studentId: studentSfid,
          message: `${
            caseCategory === 'Tech check'
              ? 'Your request for technical check'
              : 'Your report of an ongoing technical issue'
          } for ${student}'s ${subject} enrolment has been submitted successfully. We'll be in touch soon.`,
        });
      },
    });
  };

  return (
    <ContactFormLayout selectedEnrolment={selectedEnrolment}>
      <div className="px-5 pt-5 lg:px-20 lg:pt-12">
        <FormSubHeading />
        {submitError && errorMsg && <ErrorBlock errorMsg={errorMsg} />}
        <Form>
          <RadioGroup {...radioGroupProps} className="mb-9" />
          <TextArea {...textAreaProps} className="mb-7" />
          <div className="mb-4 text-center tracking-normal lg:mb-8">
            <RestrictedButton
              buttonText="Request help"
              truthyChecks={[!!radioGroupProps.selectedRadio, !isSubmitting]}
              onClick={onClickHandler}
            />
          </div>
        </Form>
      </div>
    </ContactFormLayout>
  );
};

FormTechnicalHelp.propTypes = {
  selectedEnrolment: PropTypes.shape({
    enrolmentId: PropTypes.string.isRequired,
    studentSfid: PropTypes.string.isRequired,
    student: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
  }).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitError: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    return {
      isSubmitting: state.ui.apiState.hubContactPage.isLoading,
      submitError: state.ui.apiState.hubContactPage.fetchError,
      errorMsg: state.ui.apiState.hubContactPage.errorMsg,
    };
  },
  (dispatch) => {
    return {
      submit: bindActionCreators(submitCaseToSF, dispatch),
    };
  }
)(withSelectedEnrolment(FormTechnicalHelp, null, PATH_HUB_CR_TECHNICAL_ISSUE));

export { FormTechnicalHelp as StatelessFormTechnicalHelp };
