import { Location } from 'history';
import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import posthog from 'posthog-js';

const LastLocationCtx = createContext<Location>({
	search: '',
	pathname: '',
	hash: '',
	state: {},
});

export const LastLocationProvider: FC<PropsWithChildren> = ({ children }) => {
	const location = useLocation();
	const [route, setRoute] = useState({
		prev: { ...location },
		next: { ...location },
	});

	useEffect(() => {
		setRoute((curr) => ({
			prev: curr.next,
			next: { ...location },
		}));
		posthog?.capture('$pageview');
	}, [location]);

	return <LastLocationCtx.Provider value={route.prev}>{children}</LastLocationCtx.Provider>;
};

export const useLastLocation = () => {
	return useContext(LastLocationCtx);
};
