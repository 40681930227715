import classNames from "classnames";

interface Props {
  className?: string;
  text: string;
}

const AffectedSessionTitle = ({ className, text }: Props) => {
  return (
    text ? <p className={classNames("ls-1px mb-3 text-xs font-bold uppercase", className)}>{text}</p> : null
  )
}
export default AffectedSessionTitle;