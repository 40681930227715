import React, { Children, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import { Radio } from './Radio';
import { RadioItemsType } from '../../types/hubPropTypes';
import { Label } from '@cluey/cluey-components';

// A custom hook to make it easier to get the necessary props for a radio group
// Just provide the radios!
export function useRadioGroupProps({
  radios = [],
  groupName,
  labelText,
  mutedText,
  onChangeHandler,
  defaultSelection = null,
}) {
  const [selectedRadio, setSelectedRadio] = useState(defaultSelection);

  // Note: It's preferred to come up with a non dynamic groupName to avoid DOM updates
  if (!groupName) {
    groupName = uuid();
  }

  return {
    radios,
    groupName,
    labelText,
    selectedRadio,
    setSelectedRadio,
    mutedText,
    onChangeHandler,
  };
}

export const RadioGroup = ({
  groupName,
  labelText,
  radios,
  selectedRadio,
  setSelectedRadio,
  className,
  mutedText,
  onChangeHandler,
}) => {
  return (
    <section
      className={className}
      onChange={(e) => {
        setSelectedRadio(e.target.value);
        if (typeof onChangeHandler === 'function') {
          onChangeHandler(e.target.value);
        }
      }}
    >
      {labelText && (
        <Label
          id={groupName}
          size="base"
          className="mb-2 inline-block font-body font-medium"
        >
          {labelText}
          {mutedText && <p className="text-xs text-custom-4">{mutedText}</p>}
        </Label>
      )}
      {Children.toArray(
        radios.map((radio) => {
          return (
            <Radio
              {...radio}
              key={radio.value}
              groupName={groupName}
              groupValue={selectedRadio}
              checked={selectedRadio === radio.value}
            />
          );
        })
      )}
    </section>
  );
};

RadioGroup.propTypes = {
  groupName: PropTypes.string.isRequired,
  selectedRadio: PropTypes.string,
  setSelectedRadio: PropTypes.func.isRequired,
  radios: RadioItemsType,
  className: PropTypes.string,
  labelText: PropTypes.string,
  mutedText: PropTypes.string,
  onChangeHandler: PropTypes.func,
};
RadioGroup.defaultProps = {
  selectedRadio: null,
  radios: [],
  className: null,
  labelText: null,
  mutedText: null,
  onChangeHandler: null,
};
