import { Link } from 'react-router-dom';
import { Button } from '@cluey/cluey-components';
import { PATH_HUB_HOME } from '../../util/pagePath';

const PageNotFound = () => {
	return (
		<div className="flex h-[100%] flex-col items-center justify-center">
      <h1 className="text-[120px] line-height-[100px] text-grey-3 font-display font-bold">404</h1>
			<h2 className="text-[40px] line-height-[48px] font-bold text-grey-6 font-display -mt-10 mb-9">Page Not Found</h2>
			<div className="mb-9 text-lg">
				Oops we can't seem to find the page you're looking for.
			</div>
			<Button appearance="reverse" size="lg">
				<Link to={PATH_HUB_HOME}>Back to home</Link>
			</Button> 
		</div>
	);
};

export default PageNotFound;
