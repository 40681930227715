import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Modal } from '@cluey/cluey-components';

import { bindActionCreators } from 'redux';
import { topicModalClosed } from '../../actions/studentProgressActions';

// A custom hook to return the state pairs required for the modal and radio
export function useModalProps() {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);

  return {
    showModal,
    setShowModal,
    toggleModal,
  };
}

const DetailsModal = ({ showModal, toggleModal, alignItems, children }) => {
  return (
    <Modal open={showModal} setOpen={toggleModal} closeButton>
      <div
        className={classNames(
          'mt-14 flex max-h-[80vh] flex-col overflow-y-auto p-0 text-grey-6',
          alignItems
        )}
      >
        {children}
      </div>
    </Modal>
  );
};

DetailsModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  alignItems: PropTypes.string,
};

DetailsModal.defaultProps = {
  alignItems: '',
};

export default connect(null, (dispatch) => {
  return {
    onClose: bindActionCreators(topicModalClosed, dispatch),
  };
})(DetailsModal);
