import { generatePath } from 'react-router';
import {
  PATH_HUB_BASE,
  PATH_HUB_SELECT_SESSION,
  PATH_HUB_RESCHEDULE_SESSION,
  PATH_HUB_SKIP_SESSION,
  PATH_NEW_YEAR_PROGRAM_OVERVIEW,
} from '../pagePath';
import { Session, SessionPolicy } from '../../types/hubInterfaces';

export const getNewYearProgramOverviewPage = ({enrolmentId, subject}: {
  enrolmentId: string;
  subject: string;
}) => {
  return generatePath(PATH_NEW_YEAR_PROGRAM_OVERVIEW, {enrolmentId, subject});
}

export const getSkipSessionRoute = (studentSessionId: string) => {
  return generatePath(`${PATH_HUB_SKIP_SESSION}`, { sessionId: studentSessionId });
};

export const getRescheduleSessionRoute = (studentSessionId: string) => {
  return generatePath(`${PATH_HUB_RESCHEDULE_SESSION}`, { sessionId: studentSessionId });
};

export const getSelectedSessionRedirectPath = (
    isGroup: boolean,
    session: Session,
    sessionPolicy: SessionPolicy
  ) => {
  /*
    for group sessions, they will be immediately redirected to the confirm skip session
    page. The option to reschedule is only available to private sessions

    if the session starts within an hour, redirect them to the skip session page; otherwise, redirect
    them to the reschedule page
  */

  if (!session) {
    return PATH_HUB_BASE;
  }
  /* generate path raises an exception on an undefined studentSessionId; "invalid" will redirect the user to the home page */
  const skipRoute = getSkipSessionRoute(session.studentSessionId || 'invalid');
  const rescheduleRoute = getRescheduleSessionRoute(session.studentSessionId || 'invalid');
  if (isGroup) {
    return skipRoute;
  } else {
    return sessionPolicy?.reschedule?.isAllowed ? rescheduleRoute : skipRoute;
  }
}

export const getSessionSelectionRedirectPath = (enrolmentId: string) => {
  if (!enrolmentId) {
    return PATH_HUB_BASE;
  }
  return generatePath(PATH_HUB_SELECT_SESSION, { enrolmentId });
}
