import { api } from '../../api';
import { ApiError, NotFoundError, ValidationError } from './errors';

export type TGetRemainingRequestsResult = {
  status: 'loading' | 'ok' | 'error';
  data?: number;
  error?: unknown;
};

export default function useGetRemainingRequests(enrolmentId?: string): TGetRemainingRequestsResult {
  if (!enrolmentId) {
    return {
      status: 'error',
      error: new ValidationError('Enrolment ID is required'),
      data: undefined,
    };
  }
  const { data, isLoading, isFetching, isError, error } = api.enrolment.all.useQuery('active');

  if (isLoading || isFetching) {
    return {
      status: 'loading',
      data: undefined,
    };
  } else if (isError) {
    return {
      status: 'error',
      error: new ApiError(error instanceof Error ? error.message : (error as string), 'api.enrolment.all'),
      data: undefined,
    };
  }

  const enrolment = data?.find((enrolment) => enrolment.enrolmentId === enrolmentId);

  if (!enrolment) {
    return {
      status: 'error',
      error: new NotFoundError('Enrolment not found', enrolmentId),
      data: undefined,
    };
  }

  return {
    status: 'ok',
    data: enrolment.aiTutor.requestsLeft || 0,
  };
}
