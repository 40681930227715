import { range } from 'lodash';

const createYearOptions = ({start, end}) => {
  return range(start, end + 1).map(year => {
    const text = `Year ${year}`;
    return { text, value: text };
  })
}

export default {
  AUSTRALIA: createYearOptions({start: 2, end: 12}),
  NEW_ZEALAND: createYearOptions({start: 3, end: 11})
}