import { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import moment from 'moment-timezone';
import { Button } from '@cluey/cluey-components';
import TagManager from 'react-gtm-module';

import { isSessionReportLoading } from '../../selectors/loaderSelector';
import { SessionTimeline } from '../../components/timeline/SessionTimeline';
import { SessionTimelineItem } from '../../components/timeline/SessionTimelineItem';
import { requestActivities } from '../../actions/index';
import { ActivityItem } from '../../components/ActivityItem';
import { NextSession } from '../../components/NextSession';
import TimelineBegin from '../../components/timeline/TimelineBegin';
import {
	CARD_TYPE_SESSION,
	CARD_TYPE_GROUP,
	ICON_TYPE,
	CARD_TYPE_STRAND,
	CARD_TYPE_KICK_OFF,
	ROLE_STUDENT,
	CARD_TYPE_FEEDBACK,
	SESSION_TIME_FORMAT,
} from '../../util/constants';
import BackToTop from '../../components/BackToTop/index';
import {
	getNextActivity,
	getCurrentPractices,
	getLatestPastSession,
	getPastSessions,
} from '../../selectors/activitySelector';
import { getRetryEnabled, getFetchError } from '../../selectors/errorSelector';
import { isNZUserPageLA as isNZUserCheck } from '../../selectors/loginSelector';

import PageError from '../../common/PageError';
import env from '../../env';

// Please Remove at some point 😭
import '../../index.scss';

import InPageLoader from '../../common/InPageLoader';
import { useDispatch, useSelector } from 'react-redux';
import useWindowWidth from '../../hooks/common/useWindowWidth';
import { BREAKPOINTS } from '../../util/constants';
import { InitialState } from '../../types/hubTypes';
import { PracticeActivity } from '../../types/hubInterfaces';

const pastActivitiesTitleClasses =
	'past-activities-title text-xl md:text-2xl font-bold font-body text-left text-slate-4 pt-0.5';

const ReportPage = () => {
	const dispatch = useDispatch();
	const windowWidth = useWindowWidth();
	const ref = useRef(null);
	const [labelClassName, setLabelClassName] = useState(pastActivitiesTitleClasses);
	const pastSessions: any = useSelector(getPastSessions);
	const nextSession: any = useSelector(getNextActivity);
	const currentPractices: Array<PracticeActivity> = useSelector(getCurrentPractices);
	const latestPastSession: any = useSelector(getLatestPastSession);
	const {
		pageSize,
		currentPage,
		totalNumberOfPages,
		totalNumberOfActivities,
		fetchError,
		isLoading: activeSpinner,
	} = useSelector((state: InitialState) => state.ui.apiState.activities);
	const isLastLoad = currentPage === totalNumberOfPages;
	const activitiesLeft = totalNumberOfActivities - currentPage * pageSize;
	const retryEnabled = useSelector(getRetryEnabled);
	const errorLoading = useSelector(getFetchError);
	const { customerId, packageCode, role } = useSelector((state: InitialState) => state.login);

	const showFullLoader = useSelector(isSessionReportLoading);
	const isNZUser: boolean = useSelector(isNZUserCheck);

	useEffect(() => {
		dispatch(requestActivities());
	}, [dispatch]);

	useEffect(() => {
		if (env.REACT_APP_ENABLE_GOOGLE_TAG_MANAGER) {
			const tagManagerArgs = {
				dataLayer: {
					customer_number: customerId,
					package_code: packageCode,
					role,
				},
			};
			TagManager.dataLayer(tagManagerArgs);
		}
	}, [customerId, packageCode, role]);

	useEffect(() => {
		const scrollY = () => {
			if (windowWidth < BREAKPOINTS.sm && ref.current && ref.current.getBoundingClientRect().top < 20) {
				// if pastActivities label is within 20px from the top under sm breakpoint then we change its style to card header
				setLabelClassName('card-headerWithMsg');
			} else {
				setLabelClassName(pastActivitiesTitleClasses);
			}
		};
		window.addEventListener('scroll', scrollY);
		return () => window.removeEventListener('scroll', scrollY);
	}, [windowWidth]);

	const loadMore = () => dispatch(requestActivities());
	const timezone = nextSession && nextSession.timezone;
	if (showFullLoader) {
		return <InPageLoader bgClass="bg-white" position="relative" />;
	}
	if (errorLoading) {
		return <PageError retryEnabled={retryEnabled} />;
	}
	return (
		<Fragment>
			<div className="rounded-md">
				<div className="track-session-reports-container container mx-auto px-4 py-0">
					<BackToTop />
					<SessionTimeline>
						{nextSession.startTime && (
							<SessionTimelineItem
								eventText={nextSession.startTime}
								subEventTitle={
									timezone
										? moment(nextSession.startTime).tz(timezone).format(SESSION_TIME_FORMAT)
										: moment(nextSession.startTime).format(SESSION_TIME_FORMAT)
								}
								iconClass="icon-people"
								key={nextSession.sessionId}
							>
								<NextSession nextSession={nextSession} isLocalUserStudent={role === ROLE_STUDENT} isNZUser={isNZUser} />
							</SessionTimelineItem>
						)}
						{currentPractices &&
							currentPractices.map((cp) => {
								return (
									<SessionTimelineItem
										scrollToId="latest"
										eventText={cp.startTime}
										subEventTitle={
											cp.lastVisited
												? `last visited ${
														timezone
															? moment(cp.lastVisited).tz(timezone).format('D MMMM')
															: moment(cp.lastVisited).format('D MMMM')
												  }`
												: 'not visited yet'
										}
										iconClass={`icon-${ICON_TYPE[cp.type]}`}
										key={cp.sessionId}
									>
										<ActivityItem activityItem={cp} header="Current practice" />
									</SessionTimelineItem>
								);
							})}
						{latestPastSession && (
							<SessionTimelineItem
								scrollToId={currentPractices ? null : 'latest'}
								eventText={latestPastSession.startTime}
								subEventTitle={
									timezone
										? moment(latestPastSession.startTime).tz(timezone).format(SESSION_TIME_FORMAT)
										: moment(latestPastSession.startTime).format(SESSION_TIME_FORMAT)
								}
								iconClass={`icon-${ICON_TYPE[latestPastSession.type]}`}
								key={latestPastSession.sessionId}
							>
								<ActivityItem activityItem={latestPastSession} header="Latest session" expandedByDefault />
							</SessionTimelineItem>
						)}
						<SessionTimelineItem />
						{pastSessions && pastSessions.length > 0 && (
							<div
								ref={ref}
								className={classnames(labelClassName, windowWidth < BREAKPOINTS.sm && 'sticky-top', 'mt-7', 'mb-8')}
							>
								Past activities
							</div>
						)}
						{pastSessions.map((activity) => {
							let subEventTitle = 'not visited yet';
							if (
								[CARD_TYPE_GROUP, CARD_TYPE_SESSION, CARD_TYPE_STRAND, CARD_TYPE_KICK_OFF, CARD_TYPE_FEEDBACK].includes(
									activity.type
								)
							) {
								subEventTitle = timezone
									? moment(activity.startTime).tz(timezone).format(SESSION_TIME_FORMAT)
									: moment(activity.startTime).format(SESSION_TIME_FORMAT);
							} else {
								if (activity.lastVisited) {
									subEventTitle = `last visited ${
										timezone
											? moment(activity.lastVisited).tz(timezone).format('D MMMM')
											: moment(activity.lastVisited).format('D MMMM')
									}`;
								}
							}
							return (
								<SessionTimelineItem
									eventText={activity.startTime}
									subEventTitle={subEventTitle}
									iconClass={`icon-${ICON_TYPE[activity.type]}`}
									key={activity.sessionId}
								>
									<ActivityItem activityItem={activity} />
								</SessionTimelineItem>
							);
						})}

						{isLastLoad && <SessionTimelineItem iconClass="icon-star" />}
					</SessionTimeline>
					{isLastLoad && <TimelineBegin className="float-right" />}

					{/* load more btn */}
					{!isLastLoad && (
						<Fragment>
							<div className="load-more-circle block rounded-full bg-danger md:hidden" />
							<div className="load-more flex flex-col items-center py-8">
								<Button size="xs" onClick={loadMore}>
									{activeSpinner && (
										<span className="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true" />
									)}
									{!fetchError &&
										`Load ${Math.min(pageSize, activitiesLeft)} more ${
											Math.min(pageSize, activitiesLeft) === 1 ? 'session' : 'sessions'
										}`}
									{fetchError &&
										`ReLoad ${Math.min(pageSize, activitiesLeft)} more ${
											Math.min(pageSize, activitiesLeft) === 1 ? 'session' : 'sessions'
										}`}
								</Button>
								{activitiesLeft > 0 && (
									<p className="card-text card-text-legend pt-2">
										{`there ${activitiesLeft === 1 ? 'is' : 'are'} ${activitiesLeft} more ${
											activitiesLeft === 1 ? 'session' : 'sessions'
										} `}
									</p>
								)}
							</div>
						</Fragment>
					)}
				</div>
			</div>
		</Fragment>
	);
};

function mapStateToProps(state) {
	return {
		showFullLoader: isSessionReportLoading(state),
		isNZUser: isNZUserCheck(state),
		sessionReportToken: state.login.initialToken,
		studentName: state.report.studentName,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		requestActivities: bindActionCreators(requestActivities, dispatch),
	};
}

ReportPage.defaultProps = {
	pastSessions: [],
	nextSession: null,
	requestActivities: () => undefined,
	latestPastSession: null,
	currentPractices: [],
	pageSize: 0,
	isLastLoad: null,
	activitiesLeft: 0,
};

const ConnectedReportPage = connect(mapStateToProps, mapDispatchToProps)(ReportPage);
export default ConnectedReportPage;
