export const REFUND_POLICY_QKEY = 'refund-policy';
export const AVAILABLE_SLOTS_FOR_SESSION_QKEY = 'available-slots-for-session';
export const POLICY_QKEY = 'policy';
export const GET_ENROLMENTS_QKEY = 'enrolments';
export const GET_ENROLMENT_DETAIL_QKEY = 'enrolment-detail';
export const GET_AVAILABLE_SUBJECTS_QKEY = 'available-subject';
export const GET_PROGRAM_OVERVIEW_QKEY = 'program-overview';
export const GET_PROGRAM_DETAIL_QKEY = 'program-detail';
export const GET_ENROLMENT_PREVIOUS_TUTOR_QKEY = 'previous tutor';
export const GET_TUTOR_INFO_QKEY = 'get-tutor-info';
export const AVAILABLE_SLOTS_FOR_PACKAGE_QKEY = 'available-slots-for-package';
export const AVAILABLE_SLOTS_FOR_SCHEDULE_QKEY = 'available-slots-for-schedule';
export const GET_INVOICE_HISTORY_QKEY = 'invoice-history';
export const GET_BILLING_INFORMATION_QKEY = 'billing-information';
export const GET_ACCOUNT_DETAILS = 'account-details';
export const GET_CURRENT_PRICING_PLAN = 'current-pricing-plan';
export const GET_NEXT_PAYMENT = 'get-next-payment';
export const GET_ALLOWED_PRICING_PLANS = 'allowed-pricing-plans';
export const GET_ACCOUNT_BALANCE_DETAILS = 'account-balance-details';
export const GET_AVAILABLE_TIME_SLOTS_OF_TUTOR_QKEY = 'available-time-slots-of-tutor';
export const GET_SESSION_POLICY_QKEY = 'get-session-policy';
export const GET_CANCELLATION_REASONS = 'get-cancellation-reasons';
export const GET_RESUME_DATE = 'get-resume-date';

// MUTATIONS
export const UPDATE_INVOICE_PER_QKEY = 'update-invoice-per';
export const GET_BULK_SESSION_POLICY_QKEY = 'get-bulk-session-policy';
export const CANCELLED_SESIONS_QKEY = 'cancelled-sessions';
export const GET_SESSIONS_QKEY = 'get-sessions';

export const VERIFY_EMAIL_QKEY = 'verify-email';
