import { Fragment } from 'react';
import { getEnrolmentPaused, getWillEnrolmentBePaused } from './enrolment';
import { DATE_FORMAT_TYPE_3 } from '../constants';
import moment from 'moment';
import { AlertLevel } from '@cluey/cluey-components';
import { BulkPolicy, UserPricingPlan } from '../../types/hubTypes';
import update from 'immutability-helper';
import { inflect } from './inflection';
import { first, isNumber, orderBy } from 'lodash';
import type { Enrolment } from '../../api/types/enrolment';
import type { CancelReason, Session, SessionPolicy } from '../../types/hubInterfaces';

export function getCancellationDetails({ bulkSessionPolicy }: {
  bulkSessionPolicy: BulkPolicy
}): {
  creditOrRefund: number;
  forfeiture: number;
  forfeitedSessions: Array<string>;
} {
  const { creditOrRefund, forfeiture, forfeitedSessions } = Object.entries(bulkSessionPolicy ?? {}).reduce((acc, [sessionId, policy]) => {
    const refund = Number(policy.cancelSession?.amountToRefund);
    const forfeit = Number(policy.cancelSession?.cancellationFee);
    return update(acc, {
      $merge: {
        creditOrRefund: acc.creditOrRefund + refund,
        forfeiture: acc.forfeiture + forfeit,
        forfeitedSessions: forfeit > 0 ? [...acc.forfeitedSessions, sessionId] : acc.forfeitedSessions
      }
    });
  }, {creditOrRefund: 0, forfeiture: 0, forfeitedSessions: []});

  return {
    creditOrRefund,
    forfeiture,
    forfeitedSessions
  }
}

export function filterCancelReasons ({ cancelReasons, rating }: {
  cancelReasons: Array<CancelReason> | undefined;
  rating: number;
}) {
  if (!cancelReasons) {
    return [];
  }

  return cancelReasons.filter(({ apiname }) => {
    const ratingVisibility = apiname.split('_').map(s => Number(s)).filter(n => isNumber(n));
    return ratingVisibility.includes(rating);
  })
}

export function getCancelMessage({ selectedReasons, otherMessage, message }: {
  selectedReasons: Array<CancelReason>;
  otherMessage: string;
  message: string;
}) {
  const otherSelected = !!selectedReasons.find(res => res.label === 'Other');
  const otherText = otherSelected ? `[Other] ${otherMessage} ` : '';
  const messageText = message.length > 0 ? `[Message] ${message}` : '';
  return otherText + messageText;
}

export function getConcatenatedCancelReason({ selectedReasons }: {
  selectedReasons: Array<CancelReason>;
}) {
  return selectedReasons.map(({ apiname }) => apiname).join(';');
}

export function getFinalSession({ selectedDate, enrolmentId, timezone, upcomingSessions }: {
  selectedDate: string;
  enrolmentId: string;
  timezone: string;
  upcomingSessions: Array<Session>;
}) {
  const sessions = upcomingSessions?.filter(sess => {
    return (
      sess.uniqueEnrolmentId === enrolmentId
      && moment(sess.endTime).tz(timezone).isBefore(selectedDate, 'day')
    )
  });
  return first(orderBy(sessions, ['startTime'], ['desc']));
}

export const getPauseSuggestionVisibility = ({ policy, enrolment, holidayPauseAllowed }: {
  policy: SessionPolicy;
  enrolment: Enrolment;
  holidayPauseAllowed: boolean;
}) => {
  const { enrolmentStatus, enrolmentPausedFrom, enrolmentPausedTo } = enrolment;
  const enrolmentPaused = getEnrolmentPaused({ enrolmentStatus });
  const enrolmentWillBePaused = getWillEnrolmentBePaused({ enrolmentPausedFrom, enrolmentPausedTo });
  if (enrolmentPaused || enrolmentWillBePaused || !policy) {
    return false;
  }

  if (policy.pause?.isAllowed && policy.pause?.pauseAllowed === 'Anytime') {
    return true;
  } else {
    return holidayPauseAllowed;
  }
}

export const getAlertData = ({ 
  sessionsOnOrAfterCancellationDate,
  selectedDate,
  activeEnrolments,
  bulkSessionPolicy,
  timezone,
  currentPricingPlan
}: {
  sessionsOnOrAfterCancellationDate: Array<Session>;
  selectedDate: string;
  activeEnrolments: Array<Enrolment>;
  bulkSessionPolicy: BulkPolicy;
  timezone: string;
  currentPricingPlan: UserPricingPlan
}): {
  title: string;
  alertType: AlertLevel;
  body: string | JSX.Element;
} => {
  const sessionsCount = sessionsOnOrAfterCancellationDate.length;
  const cancelDate = moment(selectedDate).format(DATE_FORMAT_TYPE_3);
  const { creditOrRefund, forfeiture, forfeitedSessions } = getCancellationDetails({ bulkSessionPolicy });
  const alertType = activeEnrolments.length === 1 && forfeiture === 0 ? 'success' : 'warning';
  const title = `${sessionsCount} ${inflect('session', sessionsCount)} already paid after ${cancelDate}`;
  const body = (function() {
    if (alertType === 'success') {
      return `$${creditOrRefund} session ${inflect('fee', sessionsCount)} as well as unused credits will be refunded back to your payment method.`;
    } else {
      if (forfeiture > 0) {
        const latestSessionToForfeit = sessionsOnOrAfterCancellationDate.find(({ studentSessionId }) => {
          return studentSessionId === first(forfeitedSessions);
        })
        const forfeitDate = moment(latestSessionToForfeit?.startTime).tz(timezone).format(DATE_FORMAT_TYPE_3);
        return(
          <Fragment>
            <p>Select a date after {forfeitDate} to avoid losing ${forfeiture} in session {inflect('fee', sessionsCount)}.</p>
            <p className='mt-4'>You are currently on the {currentPricingPlan?.pricingPlanName} plan, and the notice period to cancel is {currentPricingPlan?.noticePeriodForChangeCancelPlan}.</p>
            <p className='mb-4'>You will forfeit paid session fees if you cancel during your notice period.</p>
          </Fragment>
        )
      } else {
        return `$${creditOrRefund} session ${inflect('fee', sessionsCount)} will be credited to your account.`;
      }
    }
  })();

  return {
    title,
    alertType,
    body
  }
}

export const getCancelEnrolmentSummaryAlertData = ({ 
  bulkSessionPolicy,
  enrolmentId,
  upcomingSessions,
  timezone,
  selectedDate
}: 
  {
    bulkSessionPolicy: BulkPolicy;
    enrolmentId: string;
    upcomingSessions: Array<Session>;
    timezone: string;
    selectedDate: string;
  }) => {
  const { forfeiture } = getCancellationDetails({ bulkSessionPolicy });
  const finalSession = getFinalSession({ selectedDate, enrolmentId, timezone, upcomingSessions });
  const firstSentence = (function() {
    return !!finalSession
      ? <p>Your last session will be on {moment(finalSession.startTime).tz(timezone).format(DATE_FORMAT_TYPE_3)}.</p>
      : <p>You will have no further sessions on this enrolment.</p>;
  })();
  const secondSentence = (function() {
    return forfeiture > 0
      ? `You will be forfeiting $${forfeiture} in session fees.`
      : null;
  })();
  const alertType: AlertLevel = forfeiture === 0 ? 'success' : 'warning';
  return {
    alertType,
    body: (
      <Fragment>
        {firstSentence}
        {secondSentence}
      </Fragment>
    )
  }
}