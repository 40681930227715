import { logError } from '../logging';
import * as actionTypes from './actionTypes';
import {
  apiFetch,
  REQ_GET_STUDENT_PROGRESS_SUMMARY,
  REQ_GET_STUDENT_PROGRESS_MODULE_DETAIL,
} from '../services/backendApi';
import {
  REQ_GET_DISCIPLINE_LIST,
  REQ_GET_OUTCOME_NEXT_YEAR,
} from '../services/backendApi/endpointTypes';

export const fetchStudentProgressData = () => (dispatch, getState) => {
  dispatch({
    type: actionTypes.STPG_FETCH_STUDENT_PROGRESS_DATA_START,
    payload: null,
  });
  return apiFetch(REQ_GET_STUDENT_PROGRESS_SUMMARY, getState())
    .then((json) => {
      dispatch({
        type: actionTypes.STPG_FETCH_STUDENT_PROGRESS_DATA_SUCCESS,
        payload: json,
      });
    })
    .catch((e) => {
      dispatch({ type: actionTypes.STPG_FETCH_STUDENT_PROGRESS_DATA_FAILED });
      logError(e, null, { message: 'Unable to fetch student progress data' });
    });
};

export const saveSelectedModuleId = ({ moduleId }) => (dispatch) => {
  dispatch({
    type: actionTypes.STPG_SAVE_SELECTED_MODULE_ID,
    payload: {
      moduleId,
    },
  });
};

export const fetchModuleDetail = () => (dispatch, getState) => {
  dispatch({
    type: actionTypes.STPG_FETCH_SELECTED_MODULE_DETAIL_START,
    payload: null,
  });
  return apiFetch(REQ_GET_STUDENT_PROGRESS_MODULE_DETAIL, getState())
    .then((json) => {
      dispatch({
        type: actionTypes.STPG_FETCH_SELECTED_MODULE_DETAIL_SUCCESS,
        payload: json,
      });
    })
    .catch((e) => {
      dispatch({ type: actionTypes.STPG_FETCH_SELECTED_MODULE_DETAIL_FAILED });
      logError(e, null, { message: 'Unable to fetch module detail data' });
    });
};

export const handleViewDetailClick = ({ moduleId }) => {
  return (dispatch) => {
    dispatch(saveSelectedModuleId({ moduleId }));

    return dispatch(fetchModuleDetail());
  };
};

export const fetchDisciplineList = () => (dispatch, getState) => {
  return apiFetch(REQ_GET_DISCIPLINE_LIST, getState())
    .then((discipline) => {
      dispatch({
        type: actionTypes.STPG_FETCH_DISCIPLINE_LIST_SUCCESS,
        payload: discipline,
      });
    })
    .catch((e) => {
      logError(e, null, { message: 'Unable to fetch fetchDisciplineList' });
      dispatch({
        type: actionTypes.STPG_FETCH_DISCIPLINE_LIST_FAILED,
        payload: {},
      });
    });
};

export const updateSelectedDiscipline = disciplineIndex => (
  dispatch,
  getState,
) => {
  const state = getState();
  return dispatch({
    type: actionTypes.STPG_UPDATE_SELECTED_DISCIPLINE,
    payload: {
      disciplineIndex,
      discipline: {
        ...state.studentProgress.disciplineList[disciplineIndex],
      },
    },
  });
};

export const fetchOutcomeNextYear = () => (dispatch, getState) => {
  return apiFetch(REQ_GET_OUTCOME_NEXT_YEAR, getState())
    .then((json) => {
      dispatch({
        type: actionTypes.STPG_FETCH_OUTCOME_NEXT_YEAR_SUCCESS,
        payload: json,
      });
    })
    .catch((e) => {
      dispatch({ type: actionTypes.STPG_FETCH_OUTCOME_NEXT_YEAR_FAILED });
      logError(e, null, { message: 'Unable to fetch next year description' });
    });
};

export const setTabbedViewActiveTab = activeTab => (dispatch) => {
  return dispatch({
    type: actionTypes.STPG_UPDATE_TABBED_VIEW_ACTIVE_TAB,
    payload: {
      activeTab,
    },
  });
};

export const topicModalClosed = () => (dispatch) => {
  return dispatch({
    type: actionTypes.STPG_TOPIC_MODAL_CLOSED,
  });
};
