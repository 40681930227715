import env from '../env';
import { ddLogger, ddLoggerLevel, logError } from '../logging';
import { SessionPolicy } from '../types/hubInterfaces';
import { BulkPolicy, GetSessionsFilter, UpdateRequestStatusPayload } from '../types/hubTypes';
import { sendGetRequest, sendPostRequest } from './RequestService';

class SessionService {
  static async createOneOffSession(payload) {
    const url = `https://${env.REACT_APP_CLUEY_API_HOST_NAME}/customer-hub/enrolment/addSession`;
    try {
      const res = await sendPostRequest({
        url,
        reqOptions: {
          host: env.REACT_APP_CLUEY_API_HOST_NAME,
          path: `customer-hub/enrolment/addSession`,
        },
        signRequest: true,
        payload,
        customErrorMsg: "There was an error encountered in creating a one-off session on the selected date.",
      });
      return res.json();
    } catch(error) {
      throw error;
    }
  }

  static async getSessionPolicy({ recordId }: {
    recordId: string;
  }): Promise<SessionPolicy> {
    const url = `https://${env.REACT_APP_API_SF_HOST_NAME}/Customer/services/apexrest/checksessionpolicy`;
    try {
      const res = await sendPostRequest({
        url,
        payload: {
          action: "policy",
          recordId,
          sourceSystem: "Hub"
        },
        customErrorMsg: "Session policy retrieval failed.",
      })
      return res.json();
    } catch(error) {
      throw error;
    }
  }

  static async getBulkSessionPolicy({ recordIds }: {
    recordIds: Array<string>;
  }): Promise<BulkPolicy> {
    const url = `https://${env.REACT_APP_API_SF_HOST_NAME}/Customer/services/apexrest/checksessionpolicy`;
    try {
      const res = await sendPostRequest({
        url,
        payload: {
          action: "bulkpolicy",
          recordIds,
          sourceSystem: "Hub"
        },
        customErrorMsg: "Bulk session policy retrieval failed.",
      })
      return res.json();
    } catch(error) {
      throw error;
    }
  }

  static async getSessions({
    filter
  }: {
    filter: GetSessionsFilter
  }) {
    const portalHost = env.REACT_APP_CLUEY_API_HOST_NAME;
    const path = `/customer-hub/sessions?filter=${filter}`;
    const url = `https://${portalHost}${path}`;
    try {
      const res = await sendGetRequest({
        url,
        payload: {},
        reqOptions: {
          host: portalHost,
          path,
        },
        signRequest: true,
        customErrorMsg: `${filter} sessions retrieval failed.`,
      })
      return res.json();
    } catch(error) {
      throw error;
    }
  }

  static async updateRescheduleRequestStatus(payload: UpdateRequestStatusPayload) {
    const baseUrl = `https://${env.REACT_APP_CLUEY_API_HOST_NAME}/customer-hub`;
    const url = `${baseUrl}/reschedule/updateRequestStatus`;

    try {
      const res = await sendPostRequest({
        url,
        payload,
        reqOptions: {
          host: env.REACT_APP_CLUEY_API_HOST_NAME,
          path: `customer-hub/reschedule/updateRequestStatus`,
        },
        signRequest: true,
        customErrorMsg: "Reschedule request status update failed."
      });
      return res.json();
    } catch(error) {
      const timestamp = new Date().toISOString();
      logError('Failed to update reschedule request status', {payload, timestamp}, {});
      ddLogger({
        level: ddLoggerLevel.ERROR,
        label: "Patch failed",
        data: {
          payload,
          timestamp
        },
        error: new Error("Patch failed"),
      });
      throw error;
    }
  }
}

export default SessionService;