import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export class SessionGoals extends React.Component {
  render() {
    const { goals, tutorName, studentName } = this.props;
    const sortedGoals = goals.sort((a, b) => {
      const aV = a.isComplete ? 1 : 0;
      const bV = b.isComplete ? 1 : 0;
      return bV - aV;
    });

    return (
      <Fragment>
        <div className="pt-3 card-subTitle font-bold">SESSION GOALS</div>
        {sortedGoals && sortedGoals.map((item) => {
          return (
            <div key={item.id} className="flex items-center break-words">
              <i className={classnames('icon', (item.isComplete ? 'icon-check-circle text-icon-tick' : 'icon-shape-circle text-icon-untick'), 'pr-3', 'align-middle')} />
              <div>
                {item.goal}
              </div>
            </div>
          );
        })}
        {(!sortedGoals || sortedGoals.length === 0) && (
          <div>{studentName} and {tutorName} did not set learning goals for the session.</div>
        )}
      </Fragment>
    );
  }
}

SessionGoals.propTypes = {
  goals: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    goal: PropTypes.string.isRequired,
    isComplete: PropTypes.bool.isRequired,
  })),
  tutorName: PropTypes.string.isRequired,
  studentName: PropTypes.string.isRequired,
};

SessionGoals.defaultProps = {
  goals: [],
};
