export const PROMO_DISCOUNT_MAPPING = {
  '2FOR20': '20% off the first two sessions',
  NZ2FOR20: '20% off the first two sessions',
  '2FOR30': '30% off the first two sessions',
  NZ2FOR30: '30% off the first two sessions',
  '2FOR50': '50% off the first two sessions',
  NZ2FOR50: '50% off the first two sessions',
  '4FOR30': '30% off the first four sessions',
  NZ4FOR30: '30% off the first four sessions',
  '10FOR10': '10% off the first ten sessions',
  NZ10FOR10: '10% off the first ten sessions',
  TRIAL64: '20% off first session price',
  NZTRIAL56: '20% off first session price',
  TRIAL40: '50% off first session price',
  NZTRIAL35: '50% off first session price',
  TRIAL00: 'Free first session',
  NZTRIAL00: 'Free first session',
  STAFF25: '25% off sessions for Staff',
  FAMILY10: '10% off sessions for Family and Friends',
  SUMMER30OFF: '30% off all sessions during summer',
  SUMMER15OFF: '15% off all sessions during summer',
  SUMMER2FOR20: '20% off 2 sessions in Jan',
};
