import { ReactNode, useState } from 'react';
import classnames from 'classnames';
import { Button } from '@cluey/cluey-components';
import { Enrolment } from '../../../../../api/types/enrolment';
import externalLinkIcon from '../../../../../assets/images/icon-external-link.svg';
import { GenerateClickHandler } from './types';
import { ChevronDownIcon } from '../../../../../icons';
import { last } from 'lodash';
import posthog from 'posthog-js';

export type AITutorCardProps = {
  student: string;
  enrolments: Enrolment[];
  handleRedirect: GenerateClickHandler;
};

export const AITutorCard = ({ student, enrolments, handleRedirect }: AITutorCardProps) => {
  let desktop: ReactNode, mobile: ReactNode;

  const [enrolmentCursor, setEnrolmentCursor] = useState(0);
  const enrolmentsToShow = enrolments.slice(enrolmentCursor, enrolmentCursor + 3) || [];
  const multipleEnrolments = enrolments.length >= 3;
  const lastEnrolment = last(enrolments) === last(enrolmentsToShow);

  const renderEnrolmentCard = (enrolment: Enrolment, multipleEnrolment: boolean = false) => {
    return (
      <div
        key={`${enrolment.enrolmentId}_ai_tutor_card`}
        className={classnames(
          'flex justify-between',
          'rounded-lg border border-grey-2 bg-white p-6 shadow md:space-y-0',
          multipleEnrolment ? 'flex-col' : 'flex-row md:items-center'
        )}
      >
        <div className={classnames('justify-items-start', multipleEnrolment ? 'mb-4 md:mb-5' : 'pr-5')}>
          <span className="text-left text-lg font-bold">{`${enrolment.yearLevel} ${enrolment.subject}`}</span>
        </div>
        <div className={classnames('justify-items-end', multipleEnrolment && 'object-bottom')}>
          <Button
            type="button"
            size="sm"
            className={classnames(
              'group flex w-full place-content-center !border text-xs !font-bold uppercase !tracking-[1px] ',
              multipleEnrolment ? 'md:w-fit' : 'md:w-60'
            )}
            appearance="reverse"
            onClick={() => {
              posthog.capture('click_home_cluey-coach', {
                $enrolment: enrolment,
              });
              handleRedirect(enrolment.enrolmentId);
            }}
          >
            <span>Go to Cluey Coach</span>
            <img
              className="ml-2 h-4 w-4 group-hover:fill-white group-hover:brightness-0 group-hover:invert "
              alt="external link icon"
              src={externalLinkIcon}
            />
          </Button>
        </div>
      </div>
    );
  };

  desktop = (
    <>
      <div
        className={classnames(
          'relative grid grid-flow-row auto-rows-auto gap-6',
          enrolments.length === 2 && 'grid-cols-2',
          multipleEnrolments && 'grid-cols-3'
        )}
      >
        {multipleEnrolments && enrolmentCursor > 0 && (
          <div className="absolute right-full top-[60%] -translate-y-1/2 pr-5">
            <button
              type="button"
              onClick={() => setEnrolmentCursor(enrolmentCursor - 1)}
              className="flex h-10 w-10 items-center justify-center rounded border border-grey-2 hover:bg-slate-1"
            >
              <ChevronDownIcon className="rotate-90 text-primary" />
              <span className="sr-only">Previous enrolment</span>
            </button>
          </div>
        )}
        {enrolmentsToShow.map((enrolment) => renderEnrolmentCard(enrolment, multipleEnrolments))}
        {enrolmentsToShow.length > 2 && !lastEnrolment && (
          <div className="absolute left-full top-[60%] -translate-y-1/2 pl-5">
            <button
              type="button"
              onClick={() => setEnrolmentCursor(enrolmentCursor + 1)}
              className="flex h-10 w-10 items-center justify-center rounded border border-grey-2 hover:bg-slate-1"
            >
              <ChevronDownIcon className="-rotate-90 text-primary" />
              <span className="sr-only">Next enrolment</span>
            </button>
          </div>
        )}
      </div>
    </>
  );

  mobile = (
    <>
      <div className="grid auto-rows-auto gap-3">
        {enrolments.map((enrolment) => renderEnrolmentCard(enrolment, true))}
      </div>
    </>
  );

  return (
    <div className="mb-6">
      <div className="mb-3 text-base font-bold">{student}</div>
      {/* Desktop View */}
      <div className="hidden md:grid">{desktop}</div>
      {/* Mobile View */}
      <div className="md:hidden">{mobile}</div>
    </div>
  );
};
