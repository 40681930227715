import { generatePath, useHistory } from 'react-router-dom';
import { Button } from '@cluey/cluey-components';
import { Enrolment } from '../../../../api/types/enrolment';
import { VirtualCoachIcon } from '../../../../icons/VirtualCoachIcon';
import externalLinkIcon from '../../../../assets/images/icon-external-link.svg';
import { PATH_HUB_AI_TUTOR } from '../../../../util/pagePath';
import { VirtualCoachBotIcon } from '../../../../icons';
import posthog from 'posthog-js';

export interface StudentAITutorCardProps {
  enrolment: Enrolment;
}

export const StudentAITutorCard = ({ enrolment }: StudentAITutorCardProps) => {
  const history = useHistory();

  return (
    <div
      key={`${enrolment.enrolmentId}_ai_tutor_card`}
      className="flex flex-col space-y-4 rounded-lg border border-grey-2 bg-white p-6 shadow md:flex-row md:items-center md:space-y-0"
    >
      <div className="mb-0 flex flex-col gap-y-7 md:flex-row md:gap-x-12">
        <div className="my-auto flex flex-col gap-y-7">
          <div className="flex-1">
            <p className="mb-4 flex items-center font-display font-bold">
              <VirtualCoachIcon width={40} height={40} />
              <span className="ml-3 text-xl">Cluey Coach</span>
            </p>
            <p className="text-lg">
              Get help any time with personalised support, study questions, explanations, and more.
            </p>
          </div>
          <div>
            <Button
              type="button"
              size="sm"
              className="group flex w-full place-content-center !border text-xs !font-bold uppercase !tracking-[1px] md:w-auto"
              appearance="reverse"
              onClick={() => {
                posthog.capture('click_student-profile_cluey-coach', {
                  $enrolment: enrolment,
                });
                history.push(generatePath(PATH_HUB_AI_TUTOR, { enrolmentId: enrolment.enrolmentId }));
              }}
            >
              <span>Go to Cluey Coach</span>
              <img
                className="ml-2 h-4 w-4 group-hover:fill-white group-hover:brightness-0 group-hover:invert"
                alt="external link icon"
                src={externalLinkIcon}
              />
            </Button>
          </div>
        </div>
        <div className="mx-auto md:ml-auto md:min-w-[100px]">
          <VirtualCoachBotIcon width={100} height={120} />
        </div>
      </div>
    </div>
  );
};
