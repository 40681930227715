import initialState from '../../initialState';
import * as actionTypes from '../../../actions/actionTypes';

export default function activitiesReducer(state = initialState.ui.apiState.activities, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SR_REMOVE_ALL_ACTIVITIES:
      return {
        ...state,
        currentPage: null,
        nextPage: null,
        pageSize: null,
      };
    case actionTypes.SR_REQUEST_ACTIVITIES_START:
      return {
        ...state,
        fetchError: false,
        isLoading: true,
      };
    case actionTypes.SR_REQUEST_ACTIVITIES_SUCCESS:
      return {
        ...state,
        firstFetch: false,
        isLoading: false,
        currentPage: payload.currentPage,
        nextPage: payload.nextPage,
        pageSize: payload.pageSize,
        totalNumberOfActivities: payload.totalNumberOfActivities,
        totalNumberOfPages: payload.totalNumberOfPages,
      };
    case actionTypes.SR_REQUEST_ACTIVITIES_FAILED:
      return {
        ...state,
        fetchError: true,
        isLoading: false,
        firstFetch: false,
      };
    default:
      return state;
  }
}
