import { ReactComponent as UploadEnabledIcon } from '../../../assets/images/icon-upload.svg';
import { ReactComponent as UploadDisabledIcon } from '../../../assets/images/icon-upload-disabled.svg';
import { ReactComponent as TakePhotoEnabledIcon } from '../../../assets/images/icon-camera.svg';
import { ReactComponent as TakePhotoDisabledIcon } from '../../../assets/images/icon-camera-disabled.svg';
import { ReactComponent as RemoveAttachmentIcon } from '../../../assets/images/icon-filled-cross.svg';
import { ReactComponent as SubmitIcon } from '../../../assets/images/icon-filled-arrow-up.svg';
import { ReactComponent as SubmitDisabledIcon } from '../../../assets/images/icon-arrow-up-disabled.svg';
import { ReactComponent as KeyboardIcon } from '../../../assets/images/icon-filled-keyboard.svg';
import { ReactComponent as KeyboardDisabledIcon } from '../../../assets/images/icon-filled-keyboard-disabled.svg';
import { ReactComponent as HamburgerIcon } from '../../../assets/images/atoms-icon-filled-hamburger.svg';
import { ReactComponent as HamburgerDisabledIcon } from '../../../assets/images/atoms-icon-filled-hamburger-disabled.svg';

interface ButtonIconProps extends React.PropsWithChildren {
  disabled?: boolean;
  height?: string | number;
  width?: string | number;
}

const ButtonIcon =
  ({ EnabledIcon, DisabledIcon }) =>
  ({ disabled, ...props }: ButtonIconProps) => {
    const Icon = disabled ? DisabledIcon : EnabledIcon;
    return <Icon {...props} />;
  };

export const UploadIcon = ButtonIcon({ EnabledIcon: UploadEnabledIcon, DisabledIcon: UploadDisabledIcon });
export const TakePhotoIcon = ButtonIcon({ EnabledIcon: TakePhotoEnabledIcon, DisabledIcon: TakePhotoDisabledIcon });
export const RemoveAttachmentButtonIcon = ButtonIcon({
  EnabledIcon: RemoveAttachmentIcon,
  DisabledIcon: RemoveAttachmentIcon,
});
export const SubmitButtonIcon = ButtonIcon({ EnabledIcon: SubmitIcon, DisabledIcon: SubmitDisabledIcon });
export const KeyboardButtonIcon = ButtonIcon({ EnabledIcon: KeyboardIcon, DisabledIcon: KeyboardDisabledIcon });
export const HamburgerButtonIcon = ButtonIcon({ EnabledIcon: HamburgerIcon, DisabledIcon: HamburgerDisabledIcon });
