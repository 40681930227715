import { z } from 'zod';
import { createApi } from '../core';

const SendQuestionRequestPayload = z.object({
  enrolmentId: z.string(),
  question: z.string(),
  topicKey: z.string(),
  chatId: z.string().optional().optional(),
  imageBase64: z.string().optional(),
});
export type SendQuestionRequestPayload = z.infer<typeof SendQuestionRequestPayload>;

const QuestionResponse = z.object({
  requestsMade: z.number(),
  requestsLeft: z.number(),
  chatId: z.string(),
  reply: z.string(),
});

export const virtualCoachApi = createApi.group(['virtualCoach'], () => {
  return {
    askQuestion: createApi.mutation({
      mutationFn: (params: SendQuestionRequestPayload) => {
        try {
          return createApi.fetch({
            path: ['cluey-coach', 'ask'],
            method: 'POST',
            body: {
              enrolment_id: params.enrolmentId,
              question: params.question,
              topic_key: params.topicKey,
              chat_id: params.chatId,
              base64_image: params.imageBase64,
              steam: true,
            },
            validator: QuestionResponse,
            basePath: 'newPortalAPI',
            sign: true,
            attachIdToken: true,
          });
        } catch (error) {
          throw error;
        }
      },
    }),
  };
});
