import { Link } from 'react-router-dom';
import { Button } from '@cluey/cluey-components';
import iconInformation from '../../../assets/images/icon-information.svg';
import { PATH_HUB_SUPPORT } from '../../../util/pagePath';
import { useContext, useState } from 'react';
import { usePostHogCapture } from '../../../util/posthog';
import { useRocketChatContext } from '../../../util/RocketChat/RocketChatProvider';
import SessionSpecificToggle from './SessionSpecificToggle';
import RadioButton from './RadioButton';
import { SessionMessageContext } from './SessionMessage';

const SupportRelatedToggle = () => {
	const { isSubmitting } = useContext(SessionMessageContext);
	const capture = usePostHogCapture();
	const { maximizeWidget } = useRocketChatContext();

	const [isSupportRelated, setIsSupportRelated] = useState(null);

	const handleRadioChange = (event) => {
		const value: 'yes' | 'no' = event.target.value;
		capture(`click_add-session-message_is-support-related-${value}`, {
			value,
		});
		setIsSupportRelated(value);
	};

	const handleOpenSupportChat = () => {
		capture('click_add-session-message_open-support-chat');
		maximizeWidget();
	};

	return (
		<div>
			<b className="mb-2 text-lg">Is your message support related?</b>
			<div className="mb-6">For example, changing the session time or needing help getting into a session.</div>
			<div className="flex flex-col gap-y-4">
				<RadioButton
					disabled={isSubmitting}
					id="support-related_yes"
					name="support-related"
					value="yes"
					checked={isSupportRelated === 'yes'}
					onChange={handleRadioChange}
					label="Yes, my message is support related"
				/>
				<RadioButton
					disabled={isSubmitting}
					id="support-related_no"
					name="support-related"
					value="no"
					checked={isSupportRelated === 'no'}
					onChange={handleRadioChange}
					label="No, my message is about the session"
				/>
				{isSupportRelated === 'yes' && (
					<div className="rounded-lg bg-blue-1 px-7 py-4">
						<div className="flex flex-col gap-y-4">
							<div className="flex flex-row gap-x-4">
								<img src={iconInformation} alt="Information icon" height={20} width={20} />
								<b>Support related message</b>
							</div>
							<div>
								If your message is <span className="font-bold">not for the tutor</span> or is{' '}
								<span className="font-bold">support related</span>, visit the{' '}
								<Link to={PATH_HUB_SUPPORT} className="text-blue-5 underline underline-offset-4 hover:text-purple-5">
									Support page
								</Link>{' '}
								for FAQs, or use Cluey's support chat to assist you.
							</div>
							<div>
								<Button appearance="reverse" size="xs" onClick={handleOpenSupportChat}>
									Open support chat
								</Button>
							</div>
						</div>
					</div>
				)}
				{isSupportRelated === 'no' && <SessionSpecificToggle />}
			</div>
		</div>
	);
};

export default SupportRelatedToggle;
