import { Link } from 'react-router-dom';
import { Button } from '@cluey/cluey-components';
import alertExclamation from '../../assets/images/icon-filled-alert-exclamation--red.svg';
import { PATH_HUB_HOME } from '../../util/pagePath';

export interface ErrorPageProps {
	title?: string;
	description?: string;
  buttonLabel?: string;
  buttonLinkTo?: string;
}

const ErrorPage = ({
	title = 'Error message',
	description = 'Describe what the error is and what the user can do to resolve it.',
  buttonLabel = "Button",
  buttonLinkTo = PATH_HUB_HOME
}: ErrorPageProps) => {
	return (
		<div className="flex h-[100%] flex-col items-center justify-center">
			<img src={alertExclamation} alt="icon" className="h-[60px]" />
			<h1 className="font-display text-[40px] font-bold mb-6">{title}</h1>
			<div className="text-lg mb-9">{description}</div>
			<Button size="lg">
				<Link to={buttonLinkTo}>{buttonLabel}</Link>
			</Button>
		</div>
	);
};

export default ErrorPage;
