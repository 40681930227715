import alertIcon from '../../../../../assets/images/icon-filled-alert-exclamation.svg';
import moment from 'moment';
import { DATE_FORMAT_TYPE_1, SESSION_TIME_FORMAT } from '../../../../../util/constants';
import type { Session } from '../../../../../types/hubInterfaces';

interface Props {
  session: Session;
  timezone: string;
}

const SessionExcludedAlert = ({ session, timezone }: Props) => {
  const convertedDate = moment.utc(session.startTime).tz(timezone);
  const convertedTime = convertedDate.format(SESSION_TIME_FORMAT);
  const text = convertedDate.isSame(moment.tz(timezone), 'day')
    ? `The pause will not include the session scheduled for today at ${convertedTime}.`
    : `The pause will not include the session scheduled on ${convertedDate.format(DATE_FORMAT_TYPE_1)} at ${convertedTime}.`;
  return (
    <div className="mb-4 block min-w-[320px] rounded-lg bg-yellow-1 p-3 md:min-w-[350px]">
      <div className="flex items-start text-grey-6">
        <img
          src={alertIcon}
          alt="icon"
          className="mr-2"
          width={12}
          height={12}
        />
        <div className="text-xs font-bold">
          <p className="mb-0 leading-snug">
            {text}
          </p>
        </div>
      </div>
    </div>
  )
}

export default SessionExcludedAlert;