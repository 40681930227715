import sortBy from 'lodash/sortBy';
import env from '../env';
import { ddLogger, ddLoggerLevel, logError } from '../logging';
import { FETCH_FAILED } from '../logging/errors';
import { sendGetRequest } from './RequestService';

export class InvoiceService {
  static getMuleSoftEndpoint() {
    return `https://${env.REACT_APP_MULESOFT_DOMAIN}`;
  }

  static getPortalAPIRequestParams(path) {
    const PORTAL_API_HOST = env.REACT_APP_CLUEY_API_HOST_NAME;
    return {
      url: `https://${PORTAL_API_HOST}/${path}`,
      reqOptions: {
        host: PORTAL_API_HOST,
        path,
      },
    };
  }

  static getInvoiceFileEndpoint(invoiceId) {
    return `${this.getMuleSoftEndpoint()}/invoices/${invoiceId}/files`;
  }

  static getFilePath(fileId) {
    return `${this.getMuleSoftEndpoint()}/files/${fileId}`;
  }

  /**
   * Get Invoice File Info
   * @param {string} invoiceId
   * @returns {Promise}
   */
  static async getInvoiceFileInfo(invoiceId) {
    try {
      const response = await fetch(this.getInvoiceFileEndpoint(invoiceId));

      const json = await response.json();

      if (!json.success) throw new Error(json.message);

      const files = json.invoiceFiles;

      const simplified = files.map((fileInfo) => {
        const fileIdMatch = fileInfo.pdfFileUrl.match(/\/v1\/files\/([a-zA-Z0-9]+)/);
        const url = fileIdMatch ? this.getFilePath(fileIdMatch[1]) : 'Not available';

        return {
          version: fileInfo.versionNumber,
          url,
        };
      });

      return sortBy(simplified, 'version').reverse();
    } catch (error) {
      logError(`Error fetching file data for invoice ${invoiceId}`, null, {});
      console.warn(error);
      ddLogger({
        level: ddLoggerLevel.ERROR,
        label: FETCH_FAILED.message,
        data: { invoiceId, error },
        error: new Error(FETCH_FAILED.message),
      });
      throw new Error('There was an error getting information about your invoice. Please try again later or contact us.');
    }
  }

  static async getInvoiceHistory() {
    try {
      const { url, reqOptions } = InvoiceService.getPortalAPIRequestParams('customer-hub/account/invoices', ); // replace with new Mule endpoint after it is implemented
      const res = await sendGetRequest({ 
        url,
        signRequest: true,
        reqOptions,
        customErrorMsg: 'Your invoice history could not be retrieved.',
      });

      if (res.status !== 200) {
        throw new Error(res.message);
      }

      return res.json();
    } catch (error) {
      logError(`Error fetching invoice history`, null, {});
      console.warn(error);
      ddLogger({
        level: ddLoggerLevel.ERROR,
        label: FETCH_FAILED.message,
        data: { error },
        error: new Error(FETCH_FAILED.message),
      });
      throw new Error('Your invoice history could not be retrieved.');
    }
  }

  static async getAccountBalanceDetails(zuoraId) {
    try {
      const url = `${this.getMuleSoftEndpoint()}/billingAccounts/${zuoraId}`;
      const res = await fetch(url);

      if (res.status !== 200) {
        throw new Error(res.message);
      }

      return res.json();
    } catch (error) {
      logError(`Error fetching account balance details`, null, {});
      console.warn(error);
      ddLogger({
        level: ddLoggerLevel.ERROR,
        label: FETCH_FAILED.message,
        data: { error },
        error: new Error(FETCH_FAILED.message),
      });
      throw new Error('Your account balance information could not be retrieved.');
    }
  }
}
