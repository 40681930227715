import { useEffect, useState } from "react";
import throttle from 'lodash/throttle';

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = throttle(() => setWidth(window.innerWidth), 100);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return width;
}
export default useWindowWidth;