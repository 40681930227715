import { PropsWithChildren } from 'react';
import HubHeader from '../../common/hub/HubHeader';
import HubFooter from '../../common/hub/HubFooter/HubFooter';

const HeaderAndFooter = ({ children, title = "Hub" }: PropsWithChildren<{title?: string }>) => (
    <>
    <HubHeader title={title} />
    {children}
    <HubFooter />
    </>
);

export default HeaderAndFooter;
