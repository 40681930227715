import { get } from 'lodash';
import { getIsoCountryCode3 } from '../util/constants/countries';

export const getZuoraPrefill = (state) => {
  const { billToCity, billToPostcode, billToCountry } = get(state, 'hubAccountPage.billingInformation', {});
  const prefill = {};
  if (billToCity) prefill.creditCardCity = billToCity;
  if (billToPostcode) prefill.creditCardPostalCode = billToPostcode;
  if (billToCountry) prefill.creditCardCountry = getIsoCountryCode3(billToCountry);
  return prefill;
};
