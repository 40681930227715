import { IconProps as Props } from "../types/hubInterfaces";
import { iconDefaults } from ".";
import classNames from 'classnames';

function CheckIcon(props: Props) {
  const { width, height, className } = { ...iconDefaults, ...props };
  return (
    <svg className={classNames('fill-current', className)} xmlns="http://www.w3.org/2000/svg" width={`${width}`} height={`${height}`} viewBox="0 0 24 24">
      <title>check icon</title>
      <path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
    </svg>
  )
}

export { CheckIcon };