import { PropsWithChildren, useState } from 'react';
import classnames from 'classnames';
import { Button, ButtonAsLink } from '@cluey/cluey-components';
import { ChevronDownIcon } from '../../../icons';
import chatIcon from '../../../assets/images/icon-chat-white.svg';
import useAiTutorContext, { UserFlowSteps } from '../useAiTutorContext';
import { NewChatModal } from './NewChatModal';

export interface ConversationLayoutProps extends PropsWithChildren {
  headerAction?: boolean;
  headerActionDisabled?: boolean;
  backButton?: boolean;
  backButtonClick?(): void;
  bottomAction?: boolean;
  bottomActionLabel?: React.ReactNode;
  bottomActionDisabled?: boolean;
  bottomActionClick?(): void;
  bodyClassName?: string;
}

export const ConversationLayout = ({
  children,
  headerAction = false,
  headerActionDisabled = false,
  backButtonClick,
  backButton = false,
  bottomAction = true,
  bottomActionLabel,
  bottomActionDisabled = false,
  bottomActionClick,
  bodyClassName,
}: ConversationLayoutProps) => {
  const { setChatId, setCurrentStep, clearMessages, enrolment, setQuestion, setTopic } = useAiTutorContext();
  const [openModal, setOpenModal] = useState(false);

  const handleNewChat = () => {
    setChatId(null);
    clearMessages();
    setCurrentStep(UserFlowSteps.CHOOSE_A_TOPIC);
    setQuestion(null);
    setTopic(null);
  };

  return (
    <>
      <div className="flex w-full flex-col justify-between md:flex-row">
        <h1 className="my-10 font-display text-[28px] font-bold md:text-[40px]">
          {enrolment.yearLevel} {enrolment.subject}
        </h1>
        {headerAction && (
          <Button
            className="flex h-12 place-content-center items-center md:h-12"
            size="lg"
            disabled={headerActionDisabled}
            onClick={() => setOpenModal(true)}
          >
            <div className="flex flex-row justify-center space-x-4">
              <img alt="chat" src={chatIcon} />
              <span>Start a new chat</span>
            </div>
          </Button>
        )}
      </div>
      <div
        className={classnames(
          'container flex flex-col justify-between gap-y-8 rounded-lg bg-white p-6 shadow-lg md:p-10',
          bodyClassName
        )}
      >
        <div className="flex-grow">
          {backButton && (
            <ButtonAsLink className="flex items-center justify-around gap-2 p-0" onClick={backButtonClick}>
              <ChevronDownIcon width={20} height={20} className="rotate-[90deg] text-blue-5" />
              <span className="text-lg font-bold">Back</span>
            </ButtonAsLink>
          )}
          {children}
        </div>
        {bottomAction && (
          <div className="bg-gray-100 sticky bottom-6 mt-auto md:bottom-10">
            <Button
              className="flex w-full items-center justify-center gap-4 normal-case"
              size="lg"
              disabled={bottomActionDisabled}
              onClick={bottomActionClick}
            >
              {bottomActionLabel || (
                <>
                  <span>Next</span>
                  <ChevronDownIcon className="rotate-[-90deg] text-white" />
                </>
              )}
            </Button>
          </div>
        )}
      </div>
      <NewChatModal open={openModal} setOpen={setOpenModal} startNewChat={handleNewChat} />
    </>
  );
};
