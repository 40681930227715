import { isNumber } from "lodash";
import pluralize from "../../../../../util/pluralize";

export default function getMistakes(questionsCorrect?: number, questionsTotal?: number): string | null {
  if (!isNumber(questionsCorrect) || !isNumber(questionsTotal)) {
    return null;
  };
  const difference = questionsTotal - questionsCorrect;
  return `${difference} ${pluralize('question', difference)} incorrect`;
}
