import PropTypes, {
  arrayOf, shape, string, bool, number, node, oneOfType,
} from 'prop-types';

export const SessionType = shape({
  student: string.isRequired,
  startTime: string.isRequired,
  endTime: string.isRequired,
  uniqueEnrolmentId: string.isRequired,
  subject: string.isRequired,
  tutor: string.isRequired,
  type: string.isRequired,
  sessionToken: string.isRequired,
  paymentStatus: string.isRequired,
  studentSessionId: string.isRequired,
  isNextSession: bool,
  recordingLink: string,
});

export const SessionsType = arrayOf(SessionType);

export const SessionsDefault = [];

export const EnrolmentType = shape({
  cancelReason: string,
  costPerSession: number,
  courseName: string,
  endTime: string,
  enrolmentCancelRequestedDate: string,
  enrolmentEndDate: string,
  enrolmentId: string,
  enrolmentPausedFrom: string,
  enrolmentPausedTo: string,
  enrolmentSfid: string,
  enrolmentStartDate: string,
  enrolmentStatus: string,
  nextSessionDetail: string,
  nextSessionTutor: string,
  packageMasterCode: string,
  resumeSessionDate: string,
  schedule: arrayOf(string),
  sessionCount: string,
  sessionFrequency: string,
  sessionReportId: string,
  startTime: string,
  student: string,
  subject: string,
  timezone: string,
  tutor: string,
  tutorNumber: string,
  tutorSfid: string,
  type: string,
  yearAsInteger: number,
  yearLevel: string,
});

export const EnrolmentsType = arrayOf(EnrolmentType);

export const EnrolmentsDefault = [];

export const AccountDetailsType = shape({
  firstName: string.isRequired,
  lastName: string.isRequired,
  email: string.isRequired,
  phone: string.isRequired,
});

export const PaymentScheduleItemType = shape({
  student: string.isRequired,
  nextPaymentDate: string.isRequired,
  subject: string.isRequired,
  type: string.isRequired,
  enrolmentStatus: string.isRequired,
  daysBeforePayment: number.isRequired,
  costPerSession: number.isRequired,
});
export const PaymentScheduleType = arrayOf(PaymentScheduleItemType);

export const BillingInformationType = {
  accountNumber: string.isRequired,
  balance: number.isRequired,
  billToCity: string,
  billToCountry: string,
  billToPostcode: string,
  billToState: string,
  creditBalance: string,
  creditCardType: string.isRequired,
  customerId: string.isRequired,
  endingIn: string.isRequired,
  name: string.isRequired,
  phone: string,
  zuoraId: string.isRequired,
};

export const InvoiceType = shape({
  invoiceDate: string.isRequired,
  invoiceAmount: number.isRequired,
  invoiceNumber: string.isRequired,
  viewInvoiceLink: string.isRequired,
});
export const InvoiceHistoryType = arrayOf(shape(InvoiceType));

const ListItemType = shape({
  text: node.isRequired,
  value: oneOfType([string.isRequired, number.isRequired]),
  isGroup: bool,
});

export const RadioItemType = ListItemType;
export const RadioItemsType = arrayOf(shape(RadioItemType));

export const DropdownItemType = ListItemType;
export const DropdownItemsType = arrayOf(shape(DropdownItemType));

export const SelectionModalOptionsType = arrayOf(shape(ListItemType));

export const SelectionModalOptionsDefault = [];

export const enrolmentScheduleType = arrayOf(
  PropTypes.shape({
    dayOfWeek: string.isRequired,
    timeOfDay: string.isRequired,
  }),
);

export const enrolmentScheduleDefault = [];

export const infoNAPLANType = shape({
  showNAPLAN: bool,
  typeNAPLAN: string,
  optNAPLAN: bool,
});

export const schoolTermsType = arrayOf(
  shape({
    type: string,
    state: string,
    schoolTerm: string,
    isTermStartDate: bool,
    startTime: string,
  }),
);

export const groupSessionType = shape({
  groupTime: string,
  groupDay: string,
  groupDate: string,
});

export const groupPackageType = arrayOf(
  shape({
    yearlevel: string,
    upcomingSessions: number,
    totalCost: number,
    startDate: string,
    skipHolidays: bool,
    sessions: arrayOf(groupSessionType),
    sessionPerWeek: number,
    sessionDuration: number,
    sessionCost: number,
    packageUniqueId: string,
    packageType: string,
    packageMasterId: string,
    isTestPrep: bool,
    isConfirmed: bool,
    holdingPackageId: string,
    discipline: string,
    curriculum: string,
    courseName: string,
    courseDuration: number,
  }),
);


export const ActiveEnrolmentsPropType = PropTypes.shape({
  enrolmentId: PropTypes.string,
  enrolmentSfid: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  enrolmentStartDate: PropTypes.string,
  enrolmentEndDate: PropTypes.string,
  subject: PropTypes.string,
  tutor: PropTypes.string,
  tutorNumber: PropTypes.string,
  type: PropTypes.string,
  sessionReportId: PropTypes.string,
  enrolmentStatus: PropTypes.string,
  enrolmentPausedTo: PropTypes.string,
  enrolmentPausedFrom: PropTypes.string,
  sessionFrequency: PropTypes.string,
  cancelReason: PropTypes.string,
  enrolmentCancelRequestedDate: PropTypes.string,
  costPerSession: PropTypes.number,
  sessionCount: PropTypes.string,
  yearLevel: PropTypes.string,
  packageMasterCode: PropTypes.string,
  packageSfid: PropTypes.string,
  yearAsInteger: PropTypes.number,
  schedule: PropTypes.arrayOf(PropTypes.string), // not sure if array of string
});
