import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PageHead = ({
  title,
  containerClasses,
  titleClasses,
  wrap,
  children,
}) => {
  return (
    <div className="relative mb-7">
      <div
        className={classNames(
          'container mx-auto p-0 lg:flex lg:items-end',
          wrap ? 'flex-wrap' : 'flex-nowrap',
          containerClasses
        )}
      >
        <h2
          className={classNames(
            'flex-grow border-b pb-3 font-display text-2xl font-bold text-black lg:text-3xl',
            wrap ? 'border-transparent lg:border-slate-2' : 'border-slate-2',
            titleClasses
          )}
        >
          {title}
        </h2>
        {children}
      </div>
    </div>
  );
};

PageHead.defaultProps = {
  containerClasses: '',
  titleClasses: '',
  wrap: false,
};

PageHead.propTypes = {
  title: PropTypes.string.isRequired,
  containerClasses: PropTypes.string,
  titleClasses: PropTypes.string,
  children: PropTypes.node.isRequired,
  wrap: PropTypes.bool,
};

export default PageHead;
