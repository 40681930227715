import iconTutor from '../../assets/images/atoms-icon-filled-tutor-2.svg';
import iconConfidence from '../../assets/images/atoms-icon-filled-confidence.svg';
import iconComputer from '../../assets/images/atoms-icon-filled-computer.svg';
import iconCompass from '../../assets/images/atoms-icon-filled-compass.svg';
import { PATH_HUB_MID_YEAR_PROGRESS } from '../pagePath';

export const MidYearProgressHomeAlertData = {
  title: "Check your child's mid-year progress",
  CTALabel: 'See it here',
  CTAHref: `${PATH_HUB_MID_YEAR_PROGRESS}?utm_medium=referral&utm_source=hubhome_alert`,
  visibleUntil: '2022-07-31',
};

export const VideoSectionOne = 'mid-year-greater-subject-knowledge';
export const VideoSectionTwo = 'mid-year-build-confidence';
export const VideoSectionThree = 'mid-year-how-to-learn';
export const VideoSectionFour = 'mid-year-access-from-anywhere';

export const BenefitsList = [
  {
    icon: iconTutor,
    text: 'Students have greater subject knowledge and improved school grades',
    href: `#${VideoSectionOne}`,
    testId: `scroll-to-${VideoSectionOne}`,
  },
  {
    icon: iconConfidence,
    text: 'Students build their confidence and have fun',
    href: `#${VideoSectionTwo}`,
    testId: `scroll-to-${VideoSectionTwo}`,
  },
  {
    icon: iconComputer,
    text: 'Students learn how to learn',
    href: `#${VideoSectionThree}`,
    testId: `scroll-to-${VideoSectionThree}`,
  },
  {
    icon: iconCompass,
    text: 'Safe access from anywhere, convenience for busy parents',
    href: `#${VideoSectionFour}`,
    testId: `scroll-to-${VideoSectionFour}`,
  },
];

export const VideoSections = [
  {
    id: VideoSectionOne,
    title: 'Students have greater subject knowledge and improved school grades',
    videos: [
      {
        src: 'https://www.youtube.com/embed/rHG2uX6jkNw?rel=0',
        quote: (
          <>
            Set <strong>goals</strong> to see what <strong>strategies</strong>{' '}
            we can come up with.
          </>
        ),
        author: 'Natiq, tutor',
        alignRight: false,
      },
      {
        src: 'https://www.youtube.com/embed/z0yZ4QiU-S4?rel=0',
        quote: (
          <>
            <strong>Confidence</strong> and <strong>processes</strong> to move
            through the school content quickly.
          </>
        ),
        author: 'Luci, parent',
        alignRight: false,
      },
    ],
  },
  {
    id: VideoSectionTwo,
    title: 'Students build their confidence and have fun',
    videos: [
      {
        src: 'https://www.youtube.com/embed/8e018d5D_44?rel=0',
        quote: (
          <>
            <strong>Now I can put my hand up</strong> thinking that I can get it
            right.
          </>
        ),
        author: 'Harley, student',
        alignRight: true,
      },
      {
        src: 'https://www.youtube.com/embed/3DSkgPVJo2s?rel=0',
        quote: (
          <>
            Showing students that they all be the <strong>superheroes</strong>{' '}
            that they want to be.
          </>
        ),
        author: 'Daniel, tutor',
        alignRight: true,
      },
    ],
  },
  {
    id: VideoSectionThree,
    title: 'Students learn how to learn',
    videos: [
      {
        src: 'https://www.youtube.com/embed/N4WjABZxwVw?rel=0',
        quote: (
          <>
            They really <strong>help me in tests to not sweat it</strong> or
            rush through the questions.
          </>
        ),
        author: 'Henry, student',
        alignRight: false,
      },
      {
        src: 'https://www.youtube.com/embed/XKLr4EhXodg?rel=0',
        quote: (
          <>
            I request the recorded link…they can watch it and go{' '}
            <strong>‘that’s what I’m meant to do.’</strong>
          </>
        ),
        author: 'Billi, parent',
        alignRight: false,
      },
    ],
  },
  {
    id: VideoSectionFour,
    title: 'Safe access from anywhere, convenience for busy parents',
    videos: [
      {
        src: 'https://www.youtube.com/embed/tlBWpjor5Bc?rel=0',
        quote: (
          <>
            Selection process for tutors… I love the idea that you guys are able
            to <strong>vet a large number of applicants.</strong>
          </>
        ),
        author: 'Alli, parent',
        alignRight: true,
      },
      {
        src: 'https://www.youtube.com/embed/jVCcvHDdjSQ?rel=0',
        quote: (
          <>
            <strong>We’ve got busy schedules…</strong> we can be cooking while
            he’s doing it, it’s perfect.
          </>
        ),
        author: 'Carl, parent',
        alignRight: true,
      },
    ],
  },
];
