import { generatePath, useHistory } from 'react-router-dom';
import { ButtonAsLink } from '@cluey/cluey-components';
import { PATH_HUB_RESCHEDULE_SESSION_WITH_TUTOR } from '../util/pagePath';
import { ReactComponent as FilledAlertInfo } from '../assets/images/icon-filled-alert-info-custom-fill.svg';
import { EnrolmentSession, Session } from '../types/hubInterfaces';

function getRescheduleRequestStatus(session: EnrolmentSession | Session) {
  if ('tutorRescheduleRequestStatusC' in session) {
    return session.tutorRescheduleRequestStatusC;
  } else if ('rescheduleRequestStatus' in session) {
    return session.rescheduleRequestStatus;
  } else {
    return undefined;
  }
}


interface RescheduleRequestAlertProps {
  session: EnrolmentSession | Session;
  // eslint-disable-next-line react/no-unused-prop-types
  tutorName: string; // this is used in getTutorName() but eslint can't figure that out
}

function getTutorName(props: RescheduleRequestAlertProps) {
  if ('tutor' in props.session) {
    return props.session.tutor;
  } else {
    return props.tutorName
  }
}

const RescheduleRequestAlert = (props: RescheduleRequestAlertProps) => {
  const {session} = props;
  const requestStatus = getRescheduleRequestStatus(session);

  const history = useHistory();
  if (!session || !(requestStatus === 'Requested')) {
    return null;
  }

  const sessionId = session.studentSessionId;
  const reschedulePath = sessionId 
    ? generatePath(PATH_HUB_RESCHEDULE_SESSION_WITH_TUTOR, {
        sessionId
      })
    : '';

  return (
    <div className="my-4 rounded-lg bg-yellow-1 px-8 py-4">
      <div className="flex flex-row space-x-2">
        <div>
          <FilledAlertInfo fill="#F6A11D" />
        </div>
        <div>
          <div className="font-bold">{`${getTutorName(props)} can't make ${'uniqueEnrolmentId' in session ? 'this' : 'a'} session`}</div>

          <div>
            <ButtonAsLink onClick={() => history.push(reschedulePath)}>Reschedule now</ButtonAsLink>
          </div>
        </div>
      </div>
    </div>
  );
};


export default RescheduleRequestAlert;
