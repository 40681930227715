import classNames from 'classnames';
import { CheckIcon } from '../../icons';

interface Props {
  title: string | JSX.Element;
  textLines: Array<string | JSX.Element>;
  customClassNames: string;
  renderSubtitle: JSX.Element;
}

const BaseList = ({ title, textLines, customClassNames, renderSubtitle }: Props) => {
  const renderTitle =
    typeof title === 'string' ? (
      <p className="mb-4 font-display text-xl font-bold text-grey-6 lg:text-2xl">{title}</p>
    ) : (
      title
    );
  return (
    <div className={classNames('w-full rounded-lg', customClassNames)}>
      {renderTitle}
      {renderSubtitle}
      {textLines && (
        <ul className="flex flex-col text-lg md:mr-4">
          {textLines.map((t, index) => {
            return (
              <li key={`pause_benefits_${index}`} className="mt-1 flex items-start gap-2.5 text-grey-6">
                <CheckIcon className="flex-shrink-0 text-green-4" width={24} height={24} />
                {t}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

BaseList.defaultProps = {
  title: null,
  textLines: [],
  customClassNames: '',
  renderSubtitle: null,
};

export default BaseList;
