/* eslint-disable valid-jsdoc */
import { QueryClient, useMutation, useQueries, useQuery } from '@tanstack/react-query';
import type { UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';

import type { z } from 'zod';
import { BASE_PATH, BASE_PATHS } from './paths';
import { authenticate } from './auth';
import env from '../env';

type ApiFetchParams<ResponseData> = {
	/** The path segments of the api route */
	path: string[];
	/** The HTTP method to use */
	method: RequestInit['method'];
	/** The Zod schema to validate the response data */
	validator: z.ZodSchema<ResponseData>;
	disableValidation?: boolean;
	/** The body of the request */
	body?: Record<string, string | number | boolean | object>;
	/** The headers of the request */
	headers?: Record<string, string>;
	/** The query parameters of the request */
	query?: Record<string, string | number | boolean>;
	/** The base path of the request */
	basePath: BASE_PATH;
	/** Whether or not to sign request */
	sign?: boolean;
	/** Attach ID token from session to the header as authorization */
	attachIdToken?: boolean;
	/** Valid Status Code or Codes */
	status?: number | number[];
};

/**
 * Fetch data from an API
 */
const createApiFetch = async <ResponseData>({
	path,
	method,
	validator,
	disableValidation = false,
	body,
	headers,
	query,
	basePath,
	sign,
	attachIdToken,
	status,
}: ApiFetchParams<ResponseData>) => {
	// Build the URL
	const url = new URL(path.join('/'), BASE_PATHS[basePath]);

	// Add query params
	if (query) {
		Object.entries(query).forEach(([key, value]) => {
			url.searchParams.append(key, String(value));
		});
	}

	// Build the request options
	const requestOptions = {
		host: url.host,
		path: query ? url.pathname + '?' + url.searchParams.toString() : url.pathname,
		region: 'ap-southeast-2',
		service: 'execute-api',
		method,
		headers: {
			'Content-Type': 'application/json',
			...headers,
		},
		body: body ? JSON.stringify(body) : undefined,
	};

	// If needed, grab auth and sign the request
	if (sign) {
		await authenticate(requestOptions, attachIdToken);
	}

	// Make the request
	const response = await fetch(url.toString(), requestOptions);

	if (status) {
		if (Array.isArray(status)) {
			if (!status.includes(response.status)) {
				throw new Error(`Status code ${response.status} not in [${status.join(', ')}]`);
			}
		} else {
			if (response.status !== status) {
				throw new Error(`Status code ${response.status} not ${status}`);
			}
		}
	} else if (!response.ok) {
		const errorData = await response.json();
		if (typeof errorData === 'string') {
			throw new Error(errorData);
		}
		if (errorData && errorData.errors && errorData.errors.length > 0) {
			throw new Error(errorData.errors[0].message);
		}
		if (errorData && errorData.message) {
			throw new Error(errorData.message);
		}
		throw new Error(response.statusText || 'An error occurred');
	}

	// Grab the JSON data
	const data = await response.json();

	// Validate the data and return it
	// return validator.parse(data);

	return !disableValidation && (env.NODE_ENV === 'development' || env.NODE_ENV === 'staging')
		? validator.parse(data)
		: (data as z.infer<typeof validator>);
};

type CreateApiQueryParams<QueryType, ParamsType> = Omit<
	UseQueryOptions<QueryType, unknown, QueryType>,
	'queryFn' | 'queryKey'
> & {
	queryKey: string[];
	queryFn: (params: ParamsType, context: ReturnType<NonNullable<UseQueryOptions['queryFn']>>) => Promise<QueryType>;
};

type OptionOverride<QueryType> = Omit<UseQueryOptions<QueryType, unknown, QueryType>, 'queryFn' >;

const queryClient = new QueryClient();

/** Get the API's query client */
const getApiQueryClient = () => queryClient;

if (env.NODE_ENV === 'development') {
	queryClient.setDefaultOptions({
		queries: {
			retry: 0,
		},
	});
}

/**
 * Stringify params to be used as a query key by sorting first
 */
const stringifyParams = <T extends Record<string, unknown>>(params: T) => {
	return JSON.stringify(
		(Object.keys(params) as Array<keyof T>).sort().reduce<Partial<T>>((acc, key) => {
			acc[key] = params[key];
			return acc;
		}, {})
	);
};

const hasObjectParams = <T>(params: T): params is T & Record<string, unknown> => {
	return typeof params === 'object' && params !== null;
};

/**
 * Create an API query
 */
const createApiQuery = <QueryType, ParamsType = void>({
	queryFn,
	queryKey,
	...options
}: CreateApiQueryParams<QueryType, ParamsType>) => {
	const fullKey = (params?: ParamsType) => {
		return hasObjectParams(params)
			? [...queryKey, stringifyParams(params)]
			: typeof params === 'string'
			? [...queryKey, params]
			: queryKey;
	};

	return {
		/** React Query hook to handle the API fetch */
		useQuery(params: ParamsType, optionOverride?: OptionOverride<QueryType>) {
			return useQuery<QueryType, unknown, QueryType>({
				...options,
				queryKey: fullKey(params),
				queryFn: (context) => queryFn(params, context),
				...optionOverride,
			});
		},
		/** React Query hook to handle multiple API fetches at once */
		useQueries(paramsArr: ParamsType[], optionOverride?: OptionOverride<QueryType>) {
			return useQueries({
					queries: paramsArr?.map<UseQueryOptions<QueryType, unknown, QueryType>>((params) => ({
							...options,
							queryKey: fullKey(params),
							queryFn: (context) => queryFn(params, context),
							...optionOverride,
					})) || [],
			});
		},
		/** Asynchronously fetch API endpoint without needing to be in a Component */
		fetchQuery(params: ParamsType, optionOverride?: OptionOverride<QueryType>) {
			return getApiQueryClient().fetchQuery<QueryType, unknown, QueryType>({
				...options,
				queryKey: fullKey(params),
				queryFn: (context) => queryFn(params, context),
				...optionOverride,
			});
		},
		/** Asynchronously fetch API endpoint before you'll need it, won't throw errors or return data */
		prefetchQuery(params: ParamsType, optionOverride?: OptionOverride<QueryType>) {
			return getApiQueryClient().prefetchQuery<QueryType, unknown, QueryType>({
				...options,
				queryKey: fullKey(params),
				queryFn: (context) => queryFn(params, context),
				...optionOverride,
			});
		},
		/** Invalidate the all queries or provide params to scope your invalidation */
		invalidateQueries(params?: ParamsType) {
			return getApiQueryClient().invalidateQueries({
				queryKey: fullKey(params),
			});
		},
		/** Get the query key for a given set of params or lackthereof */
		getKey(params?: ParamsType) {
			return fullKey(params);
		},
		/** Get the query data for a given set of params */
		getQueryData(params: ParamsType) {
			return getApiQueryClient().getQueryData<QueryType>(fullKey(params));
		},
		/** Set the query data for a given set of params */
		setQueryData(params: ParamsType, data: QueryType) {
			return getApiQueryClient().setQueryData<QueryType>(fullKey(params), data);
		},
		/** Cancel all queries or provide params to scope your cancellation */
		cancelQueries(params: ParamsType) {
			return getApiQueryClient().cancelQueries(fullKey(params));
		},
	};
};

type MutationOptionOverride<ParamsType, DataType> = Omit<
	UseMutationOptions<DataType, unknown, ParamsType>,
	'mutationFn'
>;

type CreateApiMutationParams<ParamsType, DataType> = Omit<
	UseMutationOptions<DataType, unknown, ParamsType>,
	'mutationFn'
> &
	Required<Pick<UseMutationOptions<DataType, unknown, ParamsType>, 'mutationFn'>>;

const createApiMutation = <ParamsType, DataType>(options: CreateApiMutationParams<ParamsType, DataType>) => {
	return {
		/** React Query mutation hook to handle the API call */
		useMutation(optionOverride?: MutationOptionOverride<ParamsType, DataType>) {
			return useMutation({
				...options,
				...optionOverride,
			});
		},
		/** Asynchronously call the API without needing to be in a Component */
		async fetchMutation(params: ParamsType) {
			return options.mutationFn(params);
		},
	};
};

const queryKeySet = new Set<string>();

const createApiKey = (key: string[], aknowledgeDuplicate?: boolean) => {
	const stringKey = JSON.stringify(key);

	if (!aknowledgeDuplicate && queryKeySet.has(stringKey)) {
		// eslint-disable-next-line no-console
		console.warn(
			`Duplicate key detected: ${stringKey}\nAknowledge this by passing \`true\` as the second argument to createApi.key`
		);
	}

	queryKeySet.add(stringKey);

	return (additionalParts: string[]) => {
		return [...key, ...additionalParts];
	};
};

const createApiGroup = <T>(
	rootKey: string[],
	factory: (createKey: ReturnType<typeof createApiKey>) => T,
	aknowledgeDuplicate?: boolean
) => {
	return {
		...factory(createApiKey(rootKey, aknowledgeDuplicate)),
		invalidateQueries(keys?: string[]) {
			return getApiQueryClient().invalidateQueries({
				queryKey: keys ? [...rootKey, ...keys] : rootKey,
			});
		},
		getKey() {
			return rootKey;
		},
		cancelQueries(keys?: string[]) {
			return getApiQueryClient().cancelQueries(keys ? [...rootKey, ...keys] : rootKey);
		},
	} as const;
};

const createApiTime = (time: number, unit: 'milliseconds' | 'seconds' | 'minutes' | 'hours' | 'days') => {
	switch (unit) {
		case 'milliseconds':
			return time;
		case 'seconds':
			return time * 1000;
		case 'minutes':
			return time * 1000 * 60;
		case 'hours':
			return time * 1000 * 60 * 60;
		case 'days':
			return time * 1000 * 60 * 60 * 24;
	}
};

const createApiRoot = <T>(shape: T) => {
	return {
		...shape,
		invalidateQueries(keys?: string[]) {
			return getApiQueryClient().invalidateQueries(keys ? { queryKey: keys } : undefined);
		},
		useContext() {
			return {
				queryClient: getApiQueryClient(),
			};
		},
		getQueryClient() {
			return getApiQueryClient();
		},
	} as const;
};

export const createApi = {
	// create a query
	query: createApiQuery,
	// create a mutation
	mutation: createApiMutation,
	// create a query key -- really only for one off queries, prefer `createApi.group`
	key: createApiKey,
	// create a time value -- e.g. 5 seconds
	time: createApiTime,
	// create a http fetch for use in queries and mutations
	fetch: createApiFetch,
	// create a group of queries and mutations -- preferred over `createApi.key`
	group: createApiGroup,
	// create a root api object -- only use this once
	root: createApiRoot,
	// get the query client of the api -- prefer invalidations via the api queries
	client: getApiQueryClient(),
};
