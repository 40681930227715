import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import FilledAlertInfo from '../../assets/images/icon-filled-alert-info.svg';

export const ContextualAlert = ({
  style, title, body, subtitle, cta, ctaWrap,
}) => {
  return (
    <div className={classNames('flex items-start bg-[#ecfafa] rounded-lg px-7 py-6', style, { '!items-center': !title && !subtitle })}>
      <div className="flex-grow-0">
        <img src={FilledAlertInfo} alt="Info" />
      </div>
      <div className={classNames('flex-grow flex flex-wrap ml-5 gap-4 items-center', { 'flex-col !items-start': ctaWrap })}>
        <div className="flex-grow text-[#383c40]">
          {title && <div className="text-xs font-bold tracking-[1px] uppercase pb-2">{title}</div>}
          <div className={classNames('text-base leading-5', { 'font-bold': typeof body === 'string' })}>{body}</div>
          {subtitle && <div className="text-base">{subtitle}</div>}
        </div>
        {cta && (
          <div className="flex-grow-0 text-sm leading-[1.14]">
            <Link to={cta.to}>{cta.text}</Link>
          </div>
        )}
      </div>
    </div>
  );
};

ContextualAlert.propTypes = {
  style: PropTypes.string,
  cta: PropTypes.shape({ to: PropTypes.string, text: PropTypes.string, onClick: PropTypes.func }),
  title: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  subtitle: PropTypes.string,
  ctaWrap: PropTypes.bool,
};

ContextualAlert.defaultProps = {
  style: 'alert',
  cta: undefined,
  title: '',
  subtitle: '',
  ctaWrap: false,
};
