import React from 'react';
import PropTypes from 'prop-types';
import { GenericTextSection } from './GenericTextSection';

export class TutorWhatsNext extends React.Component {
  render() {
    const { text } = this.props;
    return (
      <GenericTextSection
        text={text}
        title="COMING UP NEXT"
        hideWhenEmpty
      />
    );
  }
}

TutorWhatsNext.propTypes = {
  text: PropTypes.string,
};

TutorWhatsNext.defaultProps = {
  text: null,
};
