import { useQuery } from '@tanstack/react-query';
import { sendGetRequest } from '../../services/RequestService';
import { GET_CURRENT_PRICING_PLAN } from '../../util/constants/queryKeys';
import { getPortalAPIRequestParams } from '../../services/PortalAPIServices/helper';
import { useSelector } from 'react-redux';
import { AccountPricingPlan } from '../../types/hubTypes';

export async function getPricingPlan(id: string) {
    const { url, reqOptions } = getPortalAPIRequestParams(`pricingPlan/account?sfId=${id}`);
    return sendGetRequest({
        url,
        signRequest: true,
        reqOptions,
    }).then((res) => res.json());
}

const useAccountPricingPlanQuery = () => {

    const sfid = useSelector((state) => {
        // @ts-ignore
        return state.hubAccountPage.accountDetails.customerSfid;
    });

    return useQuery<AccountPricingPlan>({
        queryKey: [GET_CURRENT_PRICING_PLAN],
        queryFn: () => getPricingPlan(sfid),
        enabled: !!sfid
    });
};

export default useAccountPricingPlanQuery;