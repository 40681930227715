import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SectionHeader = ({
  title, text, smallerTitle, renderRightsideElement, alignRightsideElement, showBottomBorder, extraWrapperClasses,
}) => {
  return (
    <div className={classNames(extraWrapperClasses, { 'border-b border-grey-2': showBottomBorder })}>
      <div className={classNames('flex flex-col lg:flex-row', alignRightsideElement)}>
        <div className="w-full lg:w-3/4 lg:pr-6">
          <h1 className={classNames('mb-2 font-bold text-grey-6 font-display', smallerTitle ? 'text-base lg:text-xl' : 'text-3xl')}>
            {title}
          </h1>
          <div
            className="mb-6 w-16 h-1 bg-blood-orange-4"
          />
          <p className="text-xl leading-6 mb-11">{text}</p>
        </div>
        { typeof renderRightsideElement === 'function' && renderRightsideElement() }
      </div>
    </div>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  smallerTitle: PropTypes.bool,
  renderRightsideElement: PropTypes.func,
  alignRightsideElement: PropTypes.string,
  showBottomBorder: PropTypes.bool,
  extraWrapperClasses: PropTypes.string,
};

SectionHeader.defaultProps = {
  title: '',
  text: '',
  smallerTitle: false,
  renderRightsideElement: null,
  alignRightsideElement: '',
  showBottomBorder: false,
  extraWrapperClasses: '',
};

export default SectionHeader;
