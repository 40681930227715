import { z } from 'zod';

const envBoolean = z.string().transform((value) => {
	if (value.toLowerCase() === 'true') {
		return true;
	}
	if (value.toLowerCase() === 'false') {
		return false;
	}
	throw new Error('Invalid boolean');
});

export const envSchema = z.object({
	// NODE ENV
	NODE_ENV: z.string().default('production'),
	// API
	REACT_APP_ENVIRONMENT: z.string(),
	REACT_APP_VERSION: z.string().default('0.0.0'),
	REACT_APP_API_PROTOCOL: z.string(),
	REACT_APP_API_LOGIN_HOST_NAME: z.string(),
	REACT_APP_SESSION_REPORT_HOST_NAME: z.string(),
	REACT_APP_PORTAL_API_HOST_NAME: z.string(),
	REACT_APP_CLUEY_API_HOST_NAME: z.string(),
	REACT_APP_NEW_CLUEY_API_HOST_NAME: z.string(),
	// Salesforce
	REACT_APP_API_SF_HOST_NAME: z.string(),
	REACT_APP_API_ENROLMENT_SF_HOST_NAME: z.string(),
	// static content
	REACT_APP_CDN_STATIC_CONTENT: z.string(),
	// summer mode
	REACT_APP_API_SUMMER_MODE_HOST_NAME: z.string(),
	// Zuora
	REACT_APP_ZUORA_PAYMENT_PAGE_ID: z.string(),
	REACT_APP_ZUORA_PAYMENT_PAGE_URL: z.string(),
	REACT_APP_ZUORA_PAYMENT_PAGE_ID_NZ: z.string(),
	REACT_APP_ZUORA_PAYMENT_PAGE_URL_NZ: z.string(),
	// MuleSoft
	REACT_APP_MULESOFT_DOMAIN: z.string(),
	// Datadog
	REACT_APP_DATADOG_ENV: z.string(),
	REACT_APP_DATADOG_RUM_ENABLED: envBoolean,
	REACT_APP_DATADOG_LOG_ENABLED: envBoolean,
	REACT_APP_DATADOG_APPLICATION_ID: z.string(),
	REACT_APP_DATADOG_CLIENT_TOKEN: z.string(),
	REACT_APP_DATADOG_SERVICE: z.string(),
	REACT_APP_DATADOG_SITE: z.string(),
	REACT_APP_DATADOG_RECORD_SESSION: envBoolean,
	// Tokens
	REACT_APP_REFRESH_TOKEN_TIMEOUT: z.coerce.number(),
	REACT_APP_REFRESH_TOKEN_ON_FAILURE_TIMEOUT: z.coerce.number(),
	// Google Tag Manager
	REACT_APP_ENABLE_GOOGLE_TAG_MANAGER: envBoolean,
	// Maintenance Mode
	REACT_APP_MAINTENANCE_MODE: envBoolean,
	// Posthog
	REACT_APP_POSTHOG_ENABLED: envBoolean,
	REACT_APP_POSTHOG_API_HOST: z.string().url(),
	REACT_APP_POSTHOG_API_KEY: z.string(),
	REACT_APP_POSTHOG_ENVIRONMENT: z.string(),
	// Cognito
	REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID: z.string(),
	REACT_APP_AWS_COGNITO_REGION: z.string(),
	REACT_APP_AWS_COGNITO_USER_POOL_ID: z.string(),
	REACT_APP_AWS_COGNITO_USER_POOL_WEBCLIENT_ID: z.string(),
	REACT_APP_AWS_OAUTH_DOMAIN: z.string(),
	REACT_APP_AWS_OAUTH_REDIRECT_SIGNIN: z.string(),
	REACT_APP_AWS_OAUTH_REDIRECT_SIGNOUT: z.string(),
	// Website
	REACT_APP_AUSTRALIA_WEBSITE_DOMAIN: z.string(),
	REACT_APP_NEW_ZEALAND_WEBSITE_DOMAIN: z.string(),
	// RocketChat
	REACT_APP_ROCKET_CHAT_ENABLED: envBoolean,
	REACT_APP_ROCKET_CHAT_BASE_URL: z.string(),
	// Optional
	REACT_APP_DEFAULT_TOKEN: z.string().optional(),
});

// Since strict mode disabled zod will infer everything as partial
// If we enable strict mode this isn't required.
export type Env = Required<z.infer<typeof envSchema>>;
// @ts-ignore
const env: Env = envSchema.parse({
	// NODE ENV
	NODE_ENV: process.env.NODE_ENV,
	// API
	REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
	REACT_APP_VERSION: process.env.REACT_APP_VERSION,
	REACT_APP_API_PROTOCOL: process.env.REACT_APP_API_PROTOCOL,
	REACT_APP_API_LOGIN_HOST_NAME: process.env.REACT_APP_API_LOGIN_HOST_NAME,
	REACT_APP_SESSION_REPORT_HOST_NAME: process.env.REACT_APP_SESSION_REPORT_HOST_NAME,
	REACT_APP_PORTAL_API_HOST_NAME: process.env.REACT_APP_PORTAL_API_HOST_NAME,
	REACT_APP_CLUEY_API_HOST_NAME: process.env.REACT_APP_CLUEY_API_HOST_NAME,
	REACT_APP_NEW_CLUEY_API_HOST_NAME: process.env.REACT_APP_NEW_CLUEY_API_HOST_NAME,
	// Salesforce
	REACT_APP_API_SF_HOST_NAME: process.env.REACT_APP_API_SF_HOST_NAME,
	REACT_APP_API_ENROLMENT_SF_HOST_NAME: process.env.REACT_APP_API_ENROLMENT_SF_HOST_NAME,
	// static content
	REACT_APP_CDN_STATIC_CONTENT: process.env.REACT_APP_CDN_STATIC_CONTENT,
	// summer mode
	REACT_APP_API_SUMMER_MODE_HOST_NAME: process.env.REACT_APP_API_SUMMER_MODE_HOST_NAME,
	// Zuora
	REACT_APP_ZUORA_PAYMENT_PAGE_ID: process.env.REACT_APP_ZUORA_PAYMENT_PAGE_ID,
	REACT_APP_ZUORA_PAYMENT_PAGE_URL: process.env.REACT_APP_ZUORA_PAYMENT_PAGE_URL,
	REACT_APP_ZUORA_PAYMENT_PAGE_ID_NZ: process.env.REACT_APP_ZUORA_PAYMENT_PAGE_ID_NZ,
	REACT_APP_ZUORA_PAYMENT_PAGE_URL_NZ: process.env.REACT_APP_ZUORA_PAYMENT_PAGE_URL_NZ,
	// MuleSoft
	REACT_APP_MULESOFT_DOMAIN: process.env.REACT_APP_MULESOFT_DOMAIN,
	// Datadog
	REACT_APP_DATADOG_RUM_ENABLED: process.env.REACT_APP_DATADOG_RUM_ENABLED,
	REACT_APP_DATADOG_LOG_ENABLED: process.env.REACT_APP_DATADOG_LOG_ENABLED,
	REACT_APP_DATADOG_ENV: process.env.REACT_APP_DATADOG_ENV,
	REACT_APP_DATADOG_APPLICATION_ID: process.env.REACT_APP_DATADOG_APPLICATION_ID,
	REACT_APP_DATADOG_CLIENT_TOKEN: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
	REACT_APP_DATADOG_SERVICE: process.env.REACT_APP_DATADOG_SERVICE,
	REACT_APP_DATADOG_SITE: process.env.REACT_APP_DATADOG_SITE,
	REACT_APP_DATADOG_RECORD_SESSION: process.env.REACT_APP_DATADOG_RECORD_SESSION,
	// Tokens
	REACT_APP_REFRESH_TOKEN_TIMEOUT: process.env.REACT_APP_REFRESH_TOKEN_TIMEOUT,
	REACT_APP_REFRESH_TOKEN_ON_FAILURE_TIMEOUT: process.env.REACT_APP_REFRESH_TOKEN_ON_FAILURE_TIMEOUT,
	// Google Tag Manager
	REACT_APP_ENABLE_GOOGLE_TAG_MANAGER: process.env.REACT_APP_ENABLE_GOOGLE_TAG_MANAGER,
	// Maintenance Mode
	REACT_APP_MAINTENANCE_MODE: process.env.REACT_APP_MAINTENANCE_MODE,
	// Posthog
	REACT_APP_POSTHOG_ENABLED: process.env.REACT_APP_POSTHOG_ENABLED,
	REACT_APP_POSTHOG_API_HOST: process.env.REACT_APP_POSTHOG_API_HOST,
	REACT_APP_POSTHOG_API_KEY: process.env.REACT_APP_POSTHOG_API_KEY,
	REACT_APP_POSTHOG_ENVIRONMENT: process.env.REACT_APP_POSTHOG_ENVIRONMENT,
	// Cognito
	REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
	REACT_APP_AWS_COGNITO_REGION: process.env.REACT_APP_AWS_COGNITO_REGION,
	REACT_APP_AWS_COGNITO_USER_POOL_ID: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
	REACT_APP_AWS_COGNITO_USER_POOL_WEBCLIENT_ID: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEBCLIENT_ID,
	REACT_APP_AWS_OAUTH_DOMAIN: process.env.REACT_APP_AWS_OAUTH_DOMAIN,
	REACT_APP_AWS_OAUTH_REDIRECT_SIGNIN: process.env.REACT_APP_AWS_OAUTH_REDIRECT_SIGNIN,
	REACT_APP_AWS_OAUTH_REDIRECT_SIGNOUT: process.env.REACT_APP_AWS_OAUTH_REDIRECT_SIGNOUT,
	// Website
	REACT_APP_AUSTRALIA_WEBSITE_DOMAIN: process.env.REACT_APP_AUSTRALIA_WEBSITE_DOMAIN,
	REACT_APP_NEW_ZEALAND_WEBSITE_DOMAIN: process.env.REACT_APP_NEW_ZEALAND_WEBSITE_DOMAIN,
	// RocketChat
	REACT_APP_ROCKET_CHAT_ENABLED: process.env.REACT_APP_ROCKET_CHAT_ENABLED,
	REACT_APP_ROCKET_CHAT_BASE_URL: process.env.REACT_APP_ROCKET_CHAT_BASE_URL,
	// Optional
	REACT_APP_DEFAULT_TOKEN: process.env.REACT_APP_DEFAULT_TOKEN,
});

export default env;

// const devOnlyVariables = ['REACT_APP_DEFAULT_TOKEN'];

// const disabledValuesInDev = [
//   //   'REACT_APP_ENABLE_GOOGLE_TAG_MANAGER',
//   // 'REACT_APP_DATADOG_RUM_ENABLED',
//   // 'REACT_APP_DATADOG_LOG_ENABLED',
// ];

// const currentEnv = process.env.NODE_ENV;
// Object.entries(process.env).forEach(([key, value]) => {
//   if (typeof value === 'undefined') {
//     return;
//   }
//   if (devOnlyVariables.includes(key) && currentEnv !== 'development') {
//     return;
//   }
//   env[key] = value;

//   // And an extra parsing to support FALSE in .env files
//   if (env[key] === 'false' || env[key] === 'FALSE' || env[key] === '0') {
//     env[key] = false;
//   }
//   if (env[key] === 'true' || env[key] === 'TRUE' || env[key] === '1') {
//     env[key] = true;
//   }
// });
// if (currentEnv === 'development') {
//   console.warn('%cFollowing env vars have been automatically set to false in dev', 'font-size: 18px;');
//   disabledValuesInDev.forEach((key) => {
//     console.log(key);
//     env[key] = false;
//   });
// }
// export default env;
