import { ELEMENT_TYPES } from "../../../../../types/hubEnums";
import {
    PATH_HUB_INVOICE_HISTORY,
    PATH_HUB_CR_PAYMENT_QUERY,
} from "../../../../../util/pagePath";
import { MS_BILLING_AND_PAYMENTS_CHOICES } from "./enums";
import {
    EXTERNAL_PATH_CLUEY_LEARNING,
    EXTERNAL_PATH_COUNTRY_NZ,
    EXTERNAL_PATH_COUNTRY_AU,
    EXTERNAL_PATH_SERVICE_FEES,
    EXTERNAL_PATH_REFUND_POLICY
} from "../../../../../util/externalPaths";

interface Choice {
    text: string;
    to: string;
    element?: ELEMENT_TYPES;
    external?: boolean;
}

export function useGetChoices(country?: string): Array<Choice> {
    const {
        UPDATE_PAYMENT_AND_BILLING_DETAILS,
        VIEW_INVOICES_AND_STATEMENTS,
        QUERY_A_PAYMENT,
        REQUEST_CREDIT_REFUND,
        REVIEW_SERVICE_FEES,
        REVIEW_REFUND_POLICY,
        FOLLOW_UP_ON_A_CREDIT_REQUEST,
        REVIEW_PAYMENT_METHOD_OPTIONS,
    } = MS_BILLING_AND_PAYMENTS_CHOICES;
    return [
        { text: UPDATE_PAYMENT_AND_BILLING_DETAILS, to: PATH_HUB_INVOICE_HISTORY },
        { text: VIEW_INVOICES_AND_STATEMENTS, to: PATH_HUB_INVOICE_HISTORY },
        { text: QUERY_A_PAYMENT, to: PATH_HUB_CR_PAYMENT_QUERY, element: ELEMENT_TYPES.BUTTON },
        { text: REQUEST_CREDIT_REFUND, to: PATH_HUB_CR_PAYMENT_QUERY, element: ELEMENT_TYPES.BUTTON },
        { text: FOLLOW_UP_ON_A_CREDIT_REQUEST, to: PATH_HUB_CR_PAYMENT_QUERY, element: ELEMENT_TYPES.BUTTON },
        {
            text: REVIEW_SERVICE_FEES,
            to: `${EXTERNAL_PATH_CLUEY_LEARNING}${country?.toLowerCase() === "nz" ? EXTERNAL_PATH_COUNTRY_NZ : EXTERNAL_PATH_COUNTRY_AU}${EXTERNAL_PATH_SERVICE_FEES}`,
            element: ELEMENT_TYPES.ANCHOR
        },
        {
            text: REVIEW_REFUND_POLICY,
            to: `${EXTERNAL_PATH_CLUEY_LEARNING}${country?.toLowerCase() === "nz" ? EXTERNAL_PATH_COUNTRY_NZ : EXTERNAL_PATH_COUNTRY_AU}${EXTERNAL_PATH_REFUND_POLICY}`,
            element: ELEMENT_TYPES.ANCHOR
        },
        { text: REVIEW_PAYMENT_METHOD_OPTIONS, to: PATH_HUB_INVOICE_HISTORY },
    ];
};