import { ddLogger, ddLoggerLevel, logError } from '../logging';
import { DatesUtils } from '../util/dates';
import env from '../env';
import { sendPostRequest } from './RequestService';
import { INVALID_PAYLOAD } from '../logging/errors';
import moment from 'moment';

export const PAUSE_TYPES = {
  New: 'new',
  Extend: 'extend',
  RestartEarly: 'restart-early',
  RestartFuture: 'restart-future',
};

export class PauseService {
  async createNewPause({ endDate, startDate, enrolmentId, preventCancel }) {
    /* 
      startDate, endDate, and requestDate should be in YYYY-MM-DD format
      these dates should not be in UTC, but should be in the user's account timezone

      before MuleSoft converts the date to datetime, it will add 00:00:00 to the date:
      "startDate": (vars.pauseEvent.suspensionDetails.startDate as Date ++ "T00:00:00.00Z") as DateTime
    */
    const payload = {
      suspensionDetails: [
        {
          endDate: moment(endDate).format('YYYY-MM-DD'),
          startDate: moment(startDate).format('YYYY-MM-DD'),
          requestDate: moment().format('YYYY-MM-DD'),
          type: PAUSE_TYPES.New,
          enrolmentId,
          customerMessage: !preventCancel
            ? this.createCustomerMessage({
                text: 'Request to pause',
                startDate,
                endDate,
              })
            : 'Summer prevent cancel',
        },
      ],
    };

    return this.submitPausePayload(payload);
  }

  async changePause({ endDate, startDate, enrolmentId, doNotOffsetEndDate = false }) {
    const payload = {
      suspensionDetails: [
        {
          endDate: doNotOffsetEndDate
            ? moment(endDate).format('YYYY-MM-DD')
            : moment(endDate).subtract(1, 'days').format('YYYY-MM-DD'),
          startDate: moment(startDate).format('YYYY-MM-DD'),
          requestDate: moment().format('YYYY-MM-DD'),
          type: PAUSE_TYPES.Extend,
          enrolmentId,
          customerMessage: this.createCustomerMessage({
            startDate,
            endDate,
            text: 'Request to change pause',
          }),
        },
      ],
    };

    return this.submitPausePayload(payload);
  }

  createCustomerMessage({ text, startDate, endDate }) {
    const customerMessageDateFormat = 'DD-MM-YYYY';
    const customerMessageDates = {
      start: moment(startDate).format(customerMessageDateFormat),
      end: moment(endDate).format(customerMessageDateFormat),
    };

    return `${text} from ${customerMessageDates.start} to ${customerMessageDates.end}`;
  }

  async submitPausePayload(payload) {
    const url = PauseService.getMuleSoftEndpoint();
    const errorMessageForUser = 'There was a problem confirming your pause. Please try again later or contact us.';

    if (!PauseService.validatePausePayload(payload)) {
      ddLogger({
        level: ddLoggerLevel.ERROR,
        label: INVALID_PAYLOAD.message,
        data: { url, ...payload },
        error: new Error(INVALID_PAYLOAD.message),
      });
      throw new Error(errorMessageForUser);
    }

    return sendPostRequest({
      url,
      payload,
      customErrorMsg: errorMessageForUser,
    });
  }

  static validatePausePayload(payload) {
    if (!payload) {
      logError('Pause Service Error. No payload found', null, {});
      return false;
    }

    if (!payload.suspensionDetails) {
      logError('Pause Service Error. No suspension details found in payload', null, {});
      return false;
    }

    if (!payload.suspensionDetails.length) {
      logError("Pause Service Error. Suspension details can't be empty in payload", null, {});
      return false;
    }

    let index = 0;

    index = payload.suspensionDetails.findIndex((suspensionDetail) => !suspensionDetail.endDate);
    if (index >= 0) {
      logError(`Pause Service Error. End date in suspensionDetails[${index}] can't be empty`, null, {});
      return false;
    }

    index = payload.suspensionDetails.findIndex(
      (suspensionDetail) => !DatesUtils.isValidDate(suspensionDetail.endDate)
    );
    if (index >= 0) {
      logError(
        `Pause Service Error. End date in suspensionDetails[${index}] ${payload.suspensionDetails[index].endDate} is not valid}`,
        null,
        {}
      );
      return false;
    }

    index = payload.suspensionDetails.findIndex((suspensionDetail) => !suspensionDetail.startDate);
    if (index >= 0) {
      logError(`Pause Service Error. Start date in suspensionDetails[${index}] can't be empty`, null, {});
      return false;
    }

    index = payload.suspensionDetails.findIndex(
      (suspensionDetail) => !DatesUtils.isValidDate(suspensionDetail.startDate)
    );
    if (index >= 0) {
      logError(
        `Pause Service Error. Start date in suspensionDetails[${index}] ${payload.suspensionDetails[index].startDate} is not valid}`,
        null,
        {}
      );
      return false;
    }

    index = payload.suspensionDetails.findIndex((suspensionDetail) => !suspensionDetail.requestDate);
    if (index >= 0) {
      logError(`Pause Service Error. Request date in suspensionDetails[${index}] can't be empty`, null, {});
      return false;
    }

    index = payload.suspensionDetails.findIndex(
      (suspensionDetail) => !DatesUtils.isValidDate(suspensionDetail.requestDate)
    );
    if (index >= 0) {
      logError(
        `Pause Service Error. Request date in suspensionDetails[${index}] ${payload.suspensionDetails[index].requestDate} is not valid}`,
        null,
        {}
      );
      return false;
    }

    index = payload.suspensionDetails.findIndex((suspensionDetail) => !suspensionDetail.type);
    if (index >= 0) {
      logError(`Pause Service Error. Type in suspensionDetails[${index}] can't be empty`, null, {});
      return false;
    }

    index = payload.suspensionDetails.findIndex((suspensionDetail) => !suspensionDetail.enrolmentId);
    if (index >= 0) {
      logError(`Pause Service Error. Enrolment Id in suspensionDetails[${index}] can't be empty`, null, {});
      return false;
    }

    index = payload.suspensionDetails.findIndex((suspensionDetail) => !suspensionDetail.customerMessage);
    if (index >= 0) {
      logError(`Pause Service Error. Customer message in suspensionDetails[${index}] can't be empty`, null, {});
      return false;
    }

    return true;
  }

  static getMuleSoftEndpoint() {
    return `https://${env.REACT_APP_MULESOFT_DOMAIN}/enrolments/pauses`;
  }

  static getManagePauseType({ currentEndDate, newEndDate }) {
    if (!currentEndDate) {
      throw new Error('No current end date found');
    }

    if (!newEndDate) {
      throw new Error('No new end date found');
    }

    const today = DatesUtils.convertDatesForMuleSoft(DatesUtils.getCurrentSydneyDate());

    if (DatesUtils.isSameDay(today, newEndDate)) {
      return PAUSE_TYPES.RestartEarly;
    }

    if (DatesUtils.isDayAfter(newEndDate, currentEndDate)) {
      return PAUSE_TYPES.Extend;
    }

    if (DatesUtils.isDayBefore(newEndDate, currentEndDate)) {
      return PAUSE_TYPES.RestartFuture;
    }

    return '';
  }
}
