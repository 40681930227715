import moment from 'moment';
import { DatesUtils } from '../dates';
import { DATE_FORMAT_TYPE_1 } from '../../util/constants';
import type { Session } from '../../types/hubInterfaces';

export const filterUpcomingForEnrolment = (enrolmentId, upcomingSessions): Array<Session> => {
  return upcomingSessions.filter((session) => {
    return session.uniqueEnrolmentId === enrolmentId;
  });
};

export const formatSessionAffectedByPause = ({ time, timezone, isRestart = false }): string => {
  if (isRestart) {
    return `• ${moment(time).tz(timezone, true).format(DATE_FORMAT_TYPE_1)}`;
  }

  return `• ${moment(time).tz(timezone).format(DATE_FORMAT_TYPE_1)} at
    ${moment(time).tz(timezone).format('h:mma')} -
    ${moment(time).tz(timezone).add(60, 'minute').format('h:mma')}`;
}

export const sessionStartsInAnHour = (session, timezone = 'Australia/Sydney') => {
  const timeNowInTimezone = DatesUtils.convertDateToTimezone(DatesUtils.getCurrentUTCdate(), timezone);
  const startInTimezone = DatesUtils.convertDateToTimezone(session.startTime, timezone);

  return startInTimezone.diff(timeNowInTimezone, 'minutes') <= 60;
};

export const sessionStartsInLessThanADay = ({ session, timezone = 'Australia/Sydney' }: {
  session: Session;
  timezone: string;
}) => {
  const timeNowInTimezone = DatesUtils.convertDateToTimezone(DatesUtils.getCurrentUTCdate(), timezone);
  const startInTimezone = DatesUtils.convertDateToTimezone(session.startTime, timezone);
  return startInTimezone.diff(timeNowInTimezone, 'hours') < 24;
}

export const CHANGE = 'Change';
export const CANT_MAKE_IT = "Can't make it";

export const getCTAtext = (session, timezone = 'Australia/Sydney') => {
  if (!session) {
    return CHANGE;
  }

  return sessionStartsInAnHour(session, timezone) ? CANT_MAKE_IT : CHANGE;
};
