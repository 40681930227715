import { ButtonAsLink } from '@cluey/cluey-components';
import { ReactComponent as IconCalendar } from '../../assets/images/icon-calendar--orange.svg';
import { ReactComponent as DisabledCalendar } from '../../assets/images/icon-calendar--darkgrey.svg';
import { TRACKING_TTC } from '../../util/trackingClasses';
import { DATE_FORMAT_TYPE_11, SESSION_TIME_FORMAT } from '../../util/constants';
import moment from 'moment';

interface Props {
  scheduleDate: string;
  sessionDuration: number;
  timezone: string;
  goBackToTutorAvailabilityScreen?(): void;
  title?: string;
  disabled?: boolean;
  changeLabel?: string;
}

const NewSessionSchedule = ({
  title = 'Reschedule session',
  disabled = false,
  scheduleDate,
  sessionDuration,
  timezone,
  goBackToTutorAvailabilityScreen,
  changeLabel,
}: Props) => {
  const nextSessionDay = moment(scheduleDate).tz(timezone).format(DATE_FORMAT_TYPE_11);
  const nextSessionStartTime = moment(scheduleDate).tz(timezone).format(SESSION_TIME_FORMAT);
  const nextSessionEndTime = moment(scheduleDate)
    .tz(timezone)
    .add(sessionDuration, 'minutes')
    .format(SESSION_TIME_FORMAT);
  const nextSessionTime = `${nextSessionStartTime} - ${nextSessionEndTime}`;
  return (
    <div>
      <h4 className="mb-5 text-xs font-bold uppercase tracking-wider">{title}</h4>
      <div className="mb-8 flex items-start lg:mb-11">
        {disabled ? (
          <DisabledCalendar alt="icon" className="mr-5 lg:mr-6 h-[26px] w-[26px]" />
        ) : (
          <IconCalendar alt="icon" className="mr-5 lg:mr-6" />
        )}
        <div className="text-left">
          <p className="lh-1-33 mb-0 text-lg">{nextSessionDay}</p>
          <p className="lh-1-33 text-lg">{nextSessionTime}</p>
          {typeof goBackToTutorAvailabilityScreen === 'function' && !disabled && (
            <ButtonAsLink className={TRACKING_TTC.CTA_SELECT_NEW_TIME} onClick={goBackToTutorAvailabilityScreen}>
              {changeLabel || 'Change'}
            </ButtonAsLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewSessionSchedule;
