import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import ModalContent from './ModalContent';
import DetailsModal, { useModalProps } from './DetailsModal';
import { handleViewDetailClick } from '../../actions/studentProgressActions';
import star0 from '../../assets/images/progressStars/star-0.svg';
import useWindowWidth from '../../hooks/common/useWindowWidth';
import { BREAKPOINTS } from '../../util/constants';

const SingleItem = ({ item, isLast, clickHandler, modalProps, trackingClass, context }) => {
	const {
		itemImg,
		itemTitle,
		itemSubtitle,
		itemCTAText,
		itemCTAAction,
		itemContent,
		itemDataTop,
		itemDataBottom,
		notCoveredModules,
		moduleId,
	} = item;
	let listItemClasses = 'flex items-start mb-4 pb-5 border-b border-grey-2';
	if (isLast) {
		listItemClasses = 'flex items-start pb-5 border-0';
	}
	if (!isEmpty(notCoveredModules)) {
		return (
			<li key={notCoveredModules} className={listItemClasses}>
				<img src={star0} alt="icon" className="mr-3 h-auto w-[30px]" />
				<p className="m-0 text-xs text-custom-4">{notCoveredModules}</p>
			</li>
		);
	}

	return (
		<li key={itemTitle} className={listItemClasses}>
			{itemImg && <img src={itemImg} alt="icon" className="mr-3 h-auto w-[30px]" />}
			<div className="flex flex-col items-start pr-1">
				{itemTitle && (
					<div>
						<p className={classNames('mb-2 font-bold', { 'mb-1': !itemSubtitle }, { 'mb-2': itemSubtitle })}>
							{itemTitle}
						</p>
						{itemSubtitle && <p className="mb-1 text-sm leading-snug">{itemSubtitle}</p>}
					</div>
				)}
				{itemCTAText && itemCTAAction && (
					<button
						id={['track', moduleId, context].join('-')}
						type="button"
						className={`border-0 bg-transparent p-0 text-xs font-bold uppercase tracking-[1px] text-primary ${trackingClass}`}
						onClick={() => {
							if (moduleId) {
								clickHandler({ moduleId });
							}
							modalProps.toggleModal();
						}}
					>
						{itemCTAText}
					</button>
				)}
				{itemContent && <p className="mb-0">{itemContent}</p>}
			</div>
			{(itemDataTop || itemDataBottom) && (
				<div className="ml-auto flex flex-col items-end">
					{itemDataTop && <p className="mb-0 text-sm">{itemDataTop}</p>}
					{itemDataBottom && <p className="mb-0 whitespace-nowrap text-xs text-custom-4">{itemDataBottom}</p>}
				</div>
			)}
		</li>
	);
};

SingleItem.propTypes = {
	modalProps: PropTypes.shape({
		toggleModal: PropTypes.func.isRequired,
	}).isRequired,
	item: PropTypes.shape({
		itemImg: PropTypes.node,
		itemTitle: PropTypes.string,
		itemSubtitle: PropTypes.string,
		itemCTAText: PropTypes.string,
		itemCTAAction: PropTypes.func,
		itemContent: PropTypes.string,
		itemDataTop: PropTypes.string,
		itemDataBottom: PropTypes.string,
		notCoveredModules: PropTypes.string,
		moduleId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	}),
	clickHandler: PropTypes.func.isRequired,
	isLast: PropTypes.bool,
	trackingClass: PropTypes.string,
	context: PropTypes.string,
};

SingleItem.defaultProps = {
	isLast: false,
	item: {},
	trackingClass: null,
	context: null,
};

const ModulesList = ({
	selfContain,
	listTitle,
	listItems,
	clickHandler,
	maxElementToShow,
	trackingShowMoreClass,
	trackingViewDetailsClass,
	context,
}) => {
	console.log('ModulesList component refactored');
	const windowWidth = useWindowWidth();
	const modalProps = useModalProps();
	const { title, subTitle } = listTitle;
	// Note, when using storybook to build layout, please do const isSmallDevice = false/true;
	// const isSmallDevice = false;
	const isSmallDevice = windowWidth <= BREAKPOINTS.md;
	const [isShowMoreExpanded, setIsShowMoreExpanded] = useState(isSmallDevice);
	const firstElements = listItems.slice(0, maxElementToShow);
	const restOfElements = listItems.slice(maxElementToShow);

	return (
		<Fragment>
			<ul
				className={classNames(
					'mb-0 min-h-[100px]',
					{ 'rounded-lg border border-grey-2 p-4': selfContain },
					{ 'p-0': !selfContain }
				)}
			>
				{(title || subTitle) && (
					<li className="mb-4 flex flex-col items-start border-b border-grey-2 pb-3">
						{title && <h3 className="text-base font-bold">{title}</h3>}
						{subTitle && <span className="text-sm leading-[1.14]">{subTitle}</span>}
					</li>
				)}
				{firstElements.length > 0 &&
					React.Children.toArray(
						firstElements.map((item, index) => {
							return (
								<SingleItem
									key={item.moduleId}
									clickHandler={clickHandler}
									isLast={index === listItems.length - 1}
									modalProps={modalProps}
									item={item}
									context={context}
									trackingClass={`${trackingViewDetailsClass} ${['track', item.moduleId].join('-')}`}
								/>
							);
						})
					)}

				{restOfElements.length > 0 && (
					<Fragment>
						{!isShowMoreExpanded && (
							<button
								type="button"
								className={`btn btn-link border-0 p-0 text-xs font-bold uppercase tracking-[1px] text-primary ${trackingShowMoreClass}`}
								onClick={() => setIsShowMoreExpanded(!isShowMoreExpanded)}
							>
								Show more
							</button>
						)}
						{isShowMoreExpanded &&
							React.Children.toArray(
								restOfElements.map((item, index) => {
									return (
										<SingleItem
											key={item.moduleId}
											clickHandler={clickHandler}
											isLast={index === restOfElements.length - 1}
											modalProps={modalProps}
											item={item}
											context={context}
											trackingClass={`${trackingViewDetailsClass} ${['track', item.moduleId].join('-')}`}
										/>
									);
								})
							)}
					</Fragment>
				)}
			</ul>
			<DetailsModal {...modalProps}>
				<ModalContent />
			</DetailsModal>
		</Fragment>
	);
};

ModulesList.propTypes = {
	selfContain: PropTypes.bool,
	listTitle: PropTypes.shape({
		title: PropTypes.string,
		subTitle: PropTypes.string,
	}),
	listItems: PropTypes.arrayOf(
		PropTypes.shape({
			itemImg: PropTypes.node,
			itemTitle: PropTypes.string,
			itemCTAText: PropTypes.string,
			itemCTAAction: PropTypes.func,
			itemContent: PropTypes.string,
			itemDataTop: PropTypes.string,
			itemDataBottom: PropTypes.string,
			notCoveredModules: PropTypes.string,
			moduleId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		})
	),
	clickHandler: PropTypes.func.isRequired,
	maxElementToShow: PropTypes.number,
	trackingShowMoreClass: PropTypes.string,
	trackingViewDetailsClass: PropTypes.string,
	context: PropTypes.string,
};

ModulesList.defaultProps = {
	selfContain: true,
	listTitle: {},
	listItems: [],
	maxElementToShow: 100,
	trackingShowMoreClass: '',
	trackingViewDetailsClass: '',
	context: '',
};
// NOTE: Alexis wants to remove 'Show' more buttom, so maxElementToShow is set to 100 to avoid any code change

export default connect(
	(state) => {
		return {
			selectedModule: state.studentProgress.selectedModule,
		};
	},
	(dispatch) => {
		return {
			clickHandler: bindActionCreators(handleViewDetailClick, dispatch),
		};
	}
)(ModulesList);
