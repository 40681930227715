import { get } from 'lodash';

import { logError } from '../../logging';
import * as actionTypes from '../actionTypes';
import { api } from '../../api';

export const fetchSessions = filterType => async (dispatch) => {
  let startActionType = '';
  let successActionType = '';
  let failedActionType = '';
  let errorMsg = '';

  if (filterType === 'upcoming') {
    startActionType = actionTypes.HUB_SP_FETCH_UPCOMING_SESSIONS_START;
    successActionType = actionTypes.HUB_SP_FETCH_UPCOMING_SESSIONS_SUCCESS;
    failedActionType = actionTypes.HUB_SP_FETCH_UPCOMING_SESSIONS_FAILED;
    errorMsg = 'Unable to fetch upcoming sessions for hub';
  } else if (filterType === 'completed') {
    startActionType = actionTypes.HUB_SP_FETCH_COMPLETED_SESSIONS_START;
    successActionType = actionTypes.HUB_SP_FETCH_COMPLETED_SESSIONS_SUCCESS;
    failedActionType = actionTypes.HUB_SP_FETCH_COMPLETED_SESSIONS_FAILED;
    errorMsg = 'Unable to fetch completed sessions for hub';
  }

  dispatch({ type: startActionType });

  try {
    const sessions = await api.session.all.fetchQuery(filterType === 'upcoming' ? 'upcoming' : "completed")
  
    if(!sessions) throw new Error('Sessions could not be retrieved.')
  
    dispatch({ type: successActionType, payload: sessions });
  } catch (e) {
    dispatch({ type: failedActionType });
    logError(e, null, { message: errorMsg });
    
  }
};

export const fetchUpcomingSessions = () => (dispatch, getState) => {
  const state = getState();
  const upcomingSessions = get(
    state,
    ['hubSessionsPage', 'upcomingSessions'],
    [],
  );

  const hasFetched = get(state, 'ui.apiState.hubUpcomingSessionsPage.hasFetched', false);
  const retryEnabled = get(state, 'ui.apiState.hubUpcomingSessionsPage.retryEnabled', false);
  if (upcomingSessions.length > 0 || hasFetched || !retryEnabled) {
    // Don't dispatch the fetching of upcoming sessions if already there
    return Promise.resolve(upcomingSessions);
  }

  return dispatch(fetchSessions('upcoming'));
};

export const fetchCompletedSessions = () => (dispatch, getState) => {
  const state = getState();
  const completedSessions = get(
    state,
    ['hubSessionsPage', 'completedSessions'],
    [],
  );

  const hasFetched = get(state, 'ui.apiState.hubCompletedSessionsPage.hasFetched', false);
  const retryEnabled = get(state, 'ui.apiState.hubCompletedSessionsPage.retryEnabled', false);
  if (completedSessions.length > 0 || hasFetched || !retryEnabled) {
    // Don't dispatch the fetching of completed sessions if already there
    return Promise.resolve(completedSessions);
  }

  return dispatch(fetchSessions('completed'));
};

export const fetchSchoolTermsPublicHoliday = () => async (dispatch) => {

  dispatch({ type: actionTypes.HUB_SP_FETCH_SCHOOL_TERMS_PUBLIC_HOLIDAYS_START });

  try {

    console.log('fetching school terms and public holidays')

    const schoolTerms = await api.schoolTerm.all.fetchQuery();

    console.log('school terms and public holidays fetched')

    if (!schoolTerms) throw new Error('School terms and public holidays could not be retrieved.');

    dispatch({
      type: actionTypes.HUB_SP_FETCH_SCHOOL_TERMS_PUBLIC_HOLIDAYS_SUCCESS,
      payload: schoolTerms,
    });
  } catch (e) {
    dispatch({ type: actionTypes.HUB_SP_FETCH_SCHOOL_TERMS_PUBLIC_HOLIDAYS_FAILED });
    logError(e, null, { message: 'Failed to fetch school terms and public holidays' });
  }
}

// Note: if fetching upcoming sessions fails, we don't need to fetch school terms/public holidays
export const fetchUpcomingSessionsWithSTPH = () => async (dispatch) => {
  return Promise.all([dispatch(fetchUpcomingSessions()), dispatch(fetchSchoolTermsPublicHoliday())]);
};
