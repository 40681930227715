import ChangePlanConfirmation from './ChangePlanConfirmation';
import ChangePlanSuccess from './ChangePlanSuccess';
import ChangePricingPlanPage from './ChangePricingPlanPage';
import useChangePricingPlanContext, {
  ChangePricingPlanContextProvider,
  ChangePricingPlanSteps,
} from './useChangePricingPlanContext';

export const ChangePricingPlanBase = () => {
  const { currentStep } = useChangePricingPlanContext();

  switch (currentStep) {
    case ChangePricingPlanSteps.CONFIRM_CHANGES:
      return <ChangePlanConfirmation />;
    case ChangePricingPlanSteps.CHANGES_SUCCESS:
      return <ChangePlanSuccess />;
    case ChangePricingPlanSteps.CHOOSE_A_PLAN:
    default:
      return <ChangePricingPlanPage />;
  }
};

const ChangePricingPlan = () => {
  return (
    <ChangePricingPlanContextProvider>
      <ChangePricingPlanBase />
    </ChangePricingPlanContextProvider>
  );
};

export default ChangePricingPlan;
