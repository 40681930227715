/*
 * NOTE, we don't use this form any more so will skip the TailwindCSS integration.
 * */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';

import withSelectedEnrolment from '../../../common/hub/NewWithSelectedEnrolment';
import ErrorBlock from '../../../common/ErrorBlock';
import ContactFormLayout from '../../../common/hub/ContactFormLayout';
import FormSubHeading from '../../../components/hub/contact/FormSubHeading';
import { useDatePickerProps, DatePicker, displayDate } from '../../../components/DatePicker/DatePicker';
import { useDropdownProps, Dropdown } from '../../../components/Dropdown/Dropdown';
import { useTextAreaProps, TextArea } from '../../../components/TextArea/TextArea';
import { RestrictedButton } from '../../../components/RestrictedButton/RestrictedButton';
// import RedirectToForm from '../../../components/hub/contact/RedirectToForm';

import { submitCaseToSF } from '../../../actions/hub/hubContactPageActions';
import {
  // PATH_HUB_CR_PAUSE_SESSIONS,
  PATH_HUB_CR_SUBMIT_CASE_SUCCESS,
} from '../../../util/pagePath';
import { getSessionAvailableDayOptions, getSessionAvailableTimeOptions } from '../../../util/helpers';
import { HOC_PAGE_PARAM } from '../../../util/constants';

import AlertIcon from '../../../assets/images/hubContact/icon-filled-alert-info.svg';

const FormPermanentScheduleChange = ({ selectedEnrolment, isSubmitting, submitError, errorMsg, submit }) => {
  const history = useHistory();
  const { enrolmentId, studentSfid, student, subject } = selectedEnrolment;

  const datePickerProps = useDatePickerProps({
    labelText: 'Effective from',
    placeholder: 'Select start date',
    customInputClasses: 'w-full',
    options: {
      minDate: new Date().fp_incr(2),
    },
  });
  const effectiveDate = Array.isArray(datePickerProps.value) ? displayDate(datePickerProps.value[0]) : '';

  const selectDayProps = useDropdownProps({
    id: 'select-day',
    options: getSessionAvailableDayOptions(),
    labelText: 'Preferred day for sessions',
    placeholder: 'Select day',
  });
  const selectedDay = selectDayProps.selectedOption;

  const selectTimeProps = useDropdownProps({
    id: 'select-time',
    options: getSessionAvailableTimeOptions(),
    labelText: 'Preferred time for sessions',
    placeholder: 'Select time',
  });
  const selectedTime = selectTimeProps.selectedOption;

  const textAreaProps = useTextAreaProps({
    id: 'text-tell-us-something',
    labelText: 'Message',
    placeholder: 'Anything else you’d like us to know?',
  });
  const message = textAreaProps.value;

  const onClickHandler = () => {
    const caseType = 'Scheduling';
    const caseCategory = 'Permanent schedule change';
    const caseDescription = `Request for ${caseCategory} effective from ${effectiveDate}, preferred day and time
    being ${selectedDay} ${selectedTime}. Other details: ${message}`;

    submit({
      uniqueEnrolmentId: enrolmentId,
      message: caseDescription,
      caseSubject: `Contact request - ${caseType} - ${caseCategory}`,
      caseType,
      caseCategory,
      onSubmitSuccess: () => {
        history.push(PATH_HUB_CR_SUBMIT_CASE_SUCCESS, {
          firstName: student,
          studentId: studentSfid,
          message: `Your schedule change request for ${student}'s ${subject} enrolment has been submitted successfully. We'll be in touch soon.`,
        });
      },
    });
  };

  return (
    <ContactFormLayout selectedEnrolment={selectedEnrolment}>
      <div className="px-5 pt-5 lg:px-20 lg:pt-12">
        <FormSubHeading />
        {submitError && errorMsg && <ErrorBlock errorMsg={errorMsg} />}
        <div className="d-flex align-items-start mb-lg-11 mb-9">
          <img src={AlertIcon} alt="alert" className="mr-3" />
          <p className="mb-0 text-lg leading-[1.33] tracking-tight">
            We may need to match you with a new tutor to accommodate the schedule change.
          </p>
        </div>
        <form>
          <div className="mb-7">
            <DatePicker {...datePickerProps} />
          </div>
          <div className="mb-7">
            <Dropdown {...selectDayProps} />
          </div>
          <div className="mb-7">
            <Dropdown {...selectTimeProps} />
          </div>

          <TextArea {...textAreaProps} className="mb-7" maxLength={700} />
        </form>
        <div className="mb-4 text-center tracking-normal lg:mb-8">
          <RestrictedButton
            buttonText="Request change"
            truthyChecks={[!!effectiveDate, !!selectedDay, !!selectedTime, !isSubmitting]}
            onClick={() => {
              onClickHandler();
            }}
          />
        </div>
      </div>
      {/* <RedirectToForm */}
      {/*  intro="Looking to take a break over the holidays?" */}
      {/*  ctaLink={PATH_HUB_CR_PAUSE_SESSIONS} */}
      {/*  ctaText="Pause sessions" */}
      {/* /> */}
    </ContactFormLayout>
  );
};

FormPermanentScheduleChange.propTypes = {
  selectedEnrolment: PropTypes.shape({
    enrolmentId: PropTypes.string.isRequired,
    studentSfid: PropTypes.string.isRequired,
    student: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
  }).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitError: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    return {
      isSubmitting: state.ui.apiState.hubContactPage.isLoading,
      submitError: state.ui.apiState.hubContactPage.fetchError,
      errorMsg: state.ui.apiState.hubContactPage.errorMsg,
    };
  },
  (dispatch) => {
    return {
      submit: bindActionCreators(submitCaseToSF, dispatch),
    };
  }
)(withSelectedEnrolment(FormPermanentScheduleChange, HOC_PAGE_PARAM.PERMANENT_SCHEDULE_CHANGE));
