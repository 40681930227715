export const NAPLAN_PACKAGE_MASTER_CODES = [
  'Course257',
  'Course310',
  'Course349',
  'Course259',
  'Course312',
  'Course308',
  'Course369',
  'Course370',
  'Course414',
  'Course415',
  'English001',
  'English054',
  'English012',
  'English056',
  'ProgramdRQWsNEGlG',
  'Programpm4LvaVL7H',
  'groupMaths031',
  'groupMaths033',
  'groupMaths035',
  'groupMaths037',
  'groupEnglish031',
  'groupEnglish033',
  'groupEnglish035',
  'groupEnglish037',
  'Course310-T',
  'Course366',
  'Course312-T',
  'Course366-7',
  'Course366-9',
  'English096',
  'English001-E',
  'English096-5',
  'English012-E',
  'English099',
  'English015',
  'English099-9',
];

export const OPTED_NAPLAN_PACKAGE_MASTER_CODES = [
  'Course418',
  'Course424',
  'Course425',
  'Course419',
  'Course426',
  'Course427',
  'Course420',
  'Course421',
  'Course422',
  'Course423',
  'English100',
  'English102',
  'English101',
  'English103',
  'ProgramO3rs8unRHn',
  'ProgramgNDDuJedvW',
  'groupMaths040',
  'groupMaths041',
  'groupMaths042',
  'groupMaths043',
  'groupEnglish040',
  'groupEnglish041',
  'groupEnglish042',
  'groupEnglish043',
  'Course430',
  'Course431',
  'Course432',
  'Course433',
  'Course434',
  'English104',
  'English105',
  'English106',
  'English107',
  'English108',
  'English109',
  'English110',
];
