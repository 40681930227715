import { IconProps as Props } from "../types/hubInterfaces";
import { iconDefaults } from ".";
import classNames from 'classnames';

function ExclamationMarkIcon(props: Props) {
  const { width, height, className } = { ...iconDefaults, ...props };
  return (
    <svg className={classNames('fill-current', className)} xmlns="http://www.w3.org/2000/svg" width={`${width}`} height={`${height}`} viewBox="0 0 16 16">
      <title>exclamation mark icon</title>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.72732 9.09448C8.72732 9.49734 8.40148 9.8243 8 9.8243C7.59852 9.8243 7.27268 9.49734 7.27268 9.09448V6.17518C7.27268 5.77232 7.59852 5.44535 8 5.44535C8.40148 5.44535 8.72732 5.77232 8.72732 6.17518V9.09448ZM8 12.0138C7.59852 12.0138 7.27268 11.6868 7.27268 11.2839C7.27268 10.8811 7.59852 10.5541 8 10.5541C8.40148 10.5541 8.72732 10.8811 8.72732 11.2839C8.72732 11.6868 8.40148 12.0138 8 12.0138ZM15.6812 11.5051L10.1012 2.22245C9.66556 1.49846 8.88005 1.06641 8 1.06641C7.11995 1.06641 6.33444 1.49846 5.89878 2.22245L0.318802 11.5051C-0.0943147 12.1933 -0.106679 13.0209 0.286072 13.7186C0.707189 14.4682 1.52542 14.9331 2.42002 14.9331H13.58C14.4746 14.9331 15.2928 14.4682 15.7139 13.7186C16.1067 13.0209 16.0943 12.1933 15.6812 11.5051Z" />
    </svg>
  )
}

export { ExclamationMarkIcon };