import classNames from "classnames";
import { Alert, AlertLevel, AnchorButton } from "@cluey/cluey-components";

export interface Props {
  alertType: AlertLevel;
  title: string;
  body?: string;
  color: '' | 'bg-yellow-1' | 'bg-blue-1';
  href: string;
}

const PolicyAlert = ({
  alertType,
  title,
  body,
  color,
  href,
}: Props) => {
  return (
    <div
      className={classNames('flex flex-col mt-7 rounded-lg gap-x-3.5 lg:flex-row', color)}
      role="alert">
      <Alert
        body={null}
        className={classNames('grow mb-0 lg:pr-0', !!body ? 'items-start' : 'items-center')}
        title={title}
        type={alertType}>
          {!!body && <p className="mt-2.5">{body}</p>}
      </Alert>
      <div className='basis-auto pl-6 pr-6 py-5 text-left lg:basis-60 lg:text-right lg:pl-0'>
        <AnchorButton 
          className='self-start'
          href={href}
          size="xs"
          appearance='reverse'
          target="blank"
          >
          View my plan
        </AnchorButton>
      </div>
    </div>
  )
}

export default PolicyAlert;