import initialState from '../../initialState';
import {
  STPG_FETCH_STUDENT_PROGRESS_DATA_START,
  STPG_FETCH_STUDENT_PROGRESS_DATA_SUCCESS,
  STPG_FETCH_STUDENT_PROGRESS_DATA_FAILED,
  STPG_FETCH_SELECTED_MODULE_DETAIL_FAILED,
  STPG_FETCH_SELECTED_MODULE_DETAIL_START,
  STPG_FETCH_SELECTED_MODULE_DETAIL_SUCCESS,
  STPG_FETCH_DISCIPLINE_LIST_FAILED,
  STPG_FETCH_DISCIPLINE_LIST_SUCCESS,
} from '../../../actions/actionTypes';

export default function studentProgressReducer(state = initialState.ui.apiState.studentProgress, action) {
  switch (action.type) {
    case STPG_FETCH_STUDENT_PROGRESS_DATA_START:
      return {
        ...state,
        firstFetch: true,
        fetchError: false,
      };
    case STPG_FETCH_STUDENT_PROGRESS_DATA_SUCCESS:
      return {
        ...state,
        firstFetch: false,
        fetchError: false,
      };
    case STPG_FETCH_STUDENT_PROGRESS_DATA_FAILED:
      return {
        ...state,
        fetchError: true,
        firstFetch: false,
      };
    case STPG_FETCH_SELECTED_MODULE_DETAIL_FAILED:
      return {
        ...state,
        modal: {
          ...state.modal,
          fetchError: true,
        },
      };
    case STPG_FETCH_DISCIPLINE_LIST_FAILED:
      return {
        ...state,
        discipline: {
          ...state.discipline,
          fetchError: true,
          firstFetch: false,
        },
      };
    case STPG_FETCH_DISCIPLINE_LIST_SUCCESS:
      return {
        ...state,
        discipline: {
          ...state.discipline,
          fetchError: false,
          firstFetch: false,
        },
      };
    case STPG_FETCH_SELECTED_MODULE_DETAIL_START:
    case STPG_FETCH_SELECTED_MODULE_DETAIL_SUCCESS:
      return {
        ...state,
        modal: {
          ...state.modal,
          fetchError: false,
        },
      };
    default:
      return state;
  }
}
