import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import { isStaffCheck } from '../../../selectors/loginSelector';
import { updateSchedule } from '../../../actions/hub/hubChangeSchedulePageActions';
import iconCalendar from '../../../assets/images/icon-calendar--orange.svg';
import { SESSION_TIME_FORMAT } from '../../../util/constants';
import ErrorBlock from '../../../common/ErrorBlock';
import { TRACKING_TTC } from '../../../util/trackingClasses';
import { ButtonAsLink, Button } from '@cluey/cluey-components';
import { getNextSessionDate } from '../../../util/helper/changeEnrolmentSchedule';

const ConfirmationScreenNextSession = ({
	isStaff,
	timezone,
	enrolmentSfid,
	selectedDay,
	sessionDuration,
	tutorNumber,
	enrolmentType,
	onConfirmClick,
	confirmChange,
	customConfirmHandler,
	customConfirmCTALabel,
	goBackToTutorAvailabilityScreen,
	isSubmitting,
	isSubmissionFailed,
	errorMsg,
	sectionTitle,
	frequency,
	startDate,
}) => {
	const sessions = useSelector((state) => state.hubEnrolmentDetailPage.sessions);
	const nextSessionDateFormatted = getNextSessionDate({ sessions, selectedDay, frequency });
	const nextSessionTime = `${moment(startDate).tz(timezone).format(SESSION_TIME_FORMAT)}
    - ${moment(startDate).tz(timezone).add(sessionDuration, 'minutes').format(SESSION_TIME_FORMAT)}`;
	/* startDate is already in UTC; the superfluous, utc invocation is merely for clarity */
	const startTimeUTC = startDate.utc();
	// inserted .clone() call in between because .add() mutates the original moment
	const endTimeUTC = moment(startTimeUTC).clone().add(sessionDuration, 'minutes');

	const onClickHandler =
		typeof customConfirmHandler === 'function'
			? () => {
					customConfirmHandler({
						startDate: startTimeUTC.toISOString(),
						endDate: endTimeUTC.toISOString(),
						frequency,
					});
			  }
			: () => {
					confirmChange({
						uniqueEnrolmentId: enrolmentSfid,
						startDate: startTimeUTC.toISOString(),
						endDate: endTimeUTC.toISOString(),
						customerNumber: tutorNumber,
						onSuccess: () => {
							onConfirmClick(startDate.utc().format('HH:mm:ss.SSS[Z]'));
						},
						frequency,
						tutorNumber,
						enrolmentType,
					});
			  };
	const btnLabel = customConfirmCTALabel || 'Confirm new schedule';

	return (
		<div className="mb-10 flex flex-col items-start">
			<h3 className="mb-5 text-xs font-bold uppercase tracking-wider">{sectionTitle}</h3>
			<div className="mb-8 flex items-start lg:mb-11">
				<img src={iconCalendar} alt="icon" className="mr-5 lg:mr-6" />
				<div className="text-left">
					<p className="mb-0 text-lg leading-[1.33]">{nextSessionDateFormatted}</p>
					<p className="text-lg leading-[1.33]">{nextSessionTime}</p>
					{typeof goBackToTutorAvailabilityScreen === 'function' && (
						<ButtonAsLink className={TRACKING_TTC.CTA_SELECT_NEW_TIME} onClick={goBackToTutorAvailabilityScreen}>
							Change
						</ButtonAsLink>
					)}
				</div>
			</div>
			{isSubmissionFailed && <ErrorBlock errorMsg={errorMsg} />}
			<Button
				size="lg"
				className={classNames('w-full lg:mt-5 lg:w-auto', {
					'cursor-not-allowed': isStaff || isSubmitting,
				})}
				disabled={isStaff || isSubmitting}
				onClick={() => {
					onClickHandler();
				}}
			>
				{btnLabel}
			</Button>
		</div>
	);
};

ConfirmationScreenNextSession.propTypes = {
	isStaff: PropTypes.bool.isRequired,
	timezone: PropTypes.string.isRequired,
	enrolmentSfid: PropTypes.string.isRequired,
	selectedDay: PropTypes.string.isRequired,
	sessionDuration: PropTypes.number.isRequired,
	tutorNumber: PropTypes.string.isRequired,
	enrolmentType: PropTypes.string.isRequired,
	onConfirmClick: PropTypes.func,
	confirmChange: PropTypes.func.isRequired,
	customConfirmHandler: PropTypes.func,
	customConfirmCTALabel: PropTypes.string,
	goBackToTutorAvailabilityScreen: PropTypes.func,
	isSubmitting: PropTypes.bool,
	isSubmissionFailed: PropTypes.bool,
	errorMsg: PropTypes.string,
	sectionTitle: PropTypes.string,
	frequency: PropTypes.string,
	startDate: PropTypes.string.isRequired,
};

ConfirmationScreenNextSession.defaultProps = {
	onConfirmClick: () => {},
	customConfirmHandler: null,
	customConfirmCTALabel: null,
	goBackToTutorAvailabilityScreen: null,
	isSubmitting: false,
	isSubmissionFailed: false,
	errorMsg: null,
	sectionTitle: 'Next session on new schedule',
	frequency: 'Weekly',
};

export default connect(
	(state) => {
		return {
			isStaff: isStaffCheck(state),
		};
	},
	(dispatch) => {
		return {
			confirmChange: bindActionCreators(updateSchedule, dispatch),
		};
	}
)(ConfirmationScreenNextSession);

export const ChangeSessionConfirmationScreenNextSession = connect(
	(state) => {
		const { firstFetch, fetchError, errorMsg } =
			state.ui.apiState.hubChangeSessionWithEnrolmentTutor.changeSessionRequest;
		return {
			isStaff: isStaffCheck(state),
			isSubmitting: firstFetch,
			isSubmissionFailed: fetchError,
			errorMsg,
		};
	},
	(dispatch) => {
		return {
			confirmChange: bindActionCreators(updateSchedule, dispatch),
		};
	}
)(ConfirmationScreenNextSession);

ChangeSessionConfirmationScreenNextSession.propTypes = {
	isStaff: PropTypes.bool.isRequired,
	timezone: PropTypes.string.isRequired,
	enrolmentId: PropTypes.string.isRequired,
	selectedDay: PropTypes.string.isRequired,
	scheduleDate: PropTypes.string.isRequired,
	sessionDuration: PropTypes.number.isRequired,
	tutorNumber: PropTypes.string.isRequired,
	onConfirmClick: PropTypes.func,
	confirmChange: PropTypes.func,
	customConfirmHandler: PropTypes.func,
	customConfirmCTALabel: PropTypes.string,
	goBackToTutorAvailabilityScreen: PropTypes.func.isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	isSubmissionFailed: PropTypes.bool.isRequired,
	errorMsg: PropTypes.string,
	sectionTitle: PropTypes.string,
};

ChangeSessionConfirmationScreenNextSession.defaultProps = {
	onConfirmClick: () => {},
	confirmChange: () => {},
	customConfirmHandler: null,
	customConfirmCTALabel: null,
	errorMsg: null,
	sectionTitle: 'Next session',
};
