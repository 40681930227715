import isError from 'lodash/isError';
import isString from 'lodash/isString';
import { ZodError } from 'zod';

const formatZodIssue = (issue: ZodError['issues'][number]) => {
  let path = '';

  issue.path.forEach((pathItem) => {
    if (typeof pathItem === 'string') {
      path += `.${pathItem}`;
    } else {
      path += `[${pathItem}]`;
    }
  });

  return {
    message: issue.message,
    path: path.slice(1),
  };
};

const createZodErrorSet = (issues: ZodError) => {
  const errorSet = new Set<string>();

  issues.issues.forEach((issue) => {
    errorSet.add(issue.message);
  });

  return errorSet;
};

export const formatError = (error: unknown): string => {
  if (isError(error)) {
    if (error instanceof ZodError) {
      // console log it neatly
      console.error('Zod Validation Error', error.issues.map(formatZodIssue));
      const errors = Array.from(createZodErrorSet(error));
      return `Validation ${errors.length > 1 ? 'Errors' : 'Error'}: ${errors.join(', ')}`;
    } else {
      return (error as Error).message;
    }
  } else if (isString(error)) {
    return error.toString();
  } else {
    return 'Unknown Error';
  }
};
