import moment from 'moment';
import { Container, Link } from '@cluey/cluey-components';
import loudspeakerIcon from '../../../../../assets/images/loudspeaker-icon 2.svg';
import { Article, getArticleById } from '../articles';
import ArticleLayout from '../ArticleLayout';
import { PATH_HUB_SUPPORT, PATH_HUB_UPDATES } from '../../../../../util/pagePath';

/* Where do I access this feature */
import HomeAIWidget from '../../../../../assets/images/newHubComms/clueyCoachTutor/Where-to-access-1.png';
import HomeSnapshotProfile from '../../../../../assets/images/newHubComms/clueyCoachTutor/Where-to-access-2.png';
import StudentProfileAIWidget from '../../../../../assets/images/newHubComms/clueyCoachTutor/Where-to-access-3.png';
/* How do I get started */
import SelectTopic from '../../../../../assets/images/newHubComms/clueyCoachTutor/How-to-get-started-1.png';
import SelectPrompt from '../../../../../assets/images/newHubComms/clueyCoachTutor/How-to-get-started-2.png';
/* What do you want to ask Cluey Coach */
import CreatePrompt from '../../../../../assets/images/newHubComms/clueyCoachTutor/What-to-ask-1.png';
import MathKeyboard from '../../../../../assets/images/newHubComms/clueyCoachTutor/What-to-ask-2.png';
import Conversation from '../../../../../assets/images/newHubComms/clueyCoachTutor/What-to-ask-3.png';
/* FAQ */
import FAQ from '../../../../../assets/images/newHubComms/clueyCoachTutor/FAQ-1.png';

export interface ClueyCoachTutorProps {
  data: Article;
}

const ClueyCoachTutorBase = ({ data }: ClueyCoachTutorProps) => {
  return (
    <ArticleLayout
      title={data.name}
      renderBreadcrumbs={
        <>
          <div className="mb-2">
            <Link className="text-xs font-bold uppercase" to={PATH_HUB_SUPPORT}>
              Support
            </Link>
            <span className="mx-2 text-xs font-bold">{'>'}</span>
            <Link className="text-xs font-bold uppercase" to={PATH_HUB_UPDATES}>
              Customer Hub updates
            </Link>
          </div>
        </>
      }
    >
      <Container className="px-4 pb-7 md:pb-8 lg:mb-0">
        <div className="flex flex-row items-center pb-10">
          <img className="mr-10" alt="Announcement icon" src={loudspeakerIcon} />
          <div className="sm:w-1/2">
            <div className="mb-2 text-sm">{moment(data.publishedAt).format('DD MMM YYYY')}</div>
            <b>{data.description}</b>
          </div>
        </div>
        <section className="my-5 rounded-lg border border-grey-3 p-10 md:my-10">
          <b className="">Page content</b>
          <ul className="ml-8 mt-5 list-disc">
            <li>
              <a href="#tutoring-help-cluey-coach" className="underline underline-offset-4 hover:text-blue-5">
                Anytime tutoring help with Cluey Coach
              </a>
            </li>
            <li>
              <a href="#where-to-acccess" className="underline underline-offset-4 hover:text-blue-5">
                Where do I access this feature?
              </a>
            </li>
            <li>
              <a href="#how-to-get-started" className="underline underline-offset-4 hover:text-blue-5">
                How do I get started?
              </a>
            </li>
            <li>
              <a href="#what-to-ask" className="underline underline-offset-4 hover:text-blue-5">
                What do you want to ask Cluey Coach?
              </a>
            </li>
            <li>
              <a href="#faqs" className="underline underline-offset-4 hover:text-blue-5">
                Frequently asked questions (FAQs)
              </a>
            </li>
          </ul>
        </section>
        <section className="py-10 md:py-20" id="tutoring-help-cluey-coach">
          <h2 className="mb-6 font-display text-2xl font-bold">Anytime tutoring help with Cluey Coach</h2>
          <div className="my-10 md:my-6 md:w-2/3">
            <div>
              Our new AI tutor, Cluey Coach, can help with your tutoring needs any time, even during after hours when
              tutors are unavailable.
            </div>
            <br />
            <div>
              For our beta launch, Cluey Coach will be available for Year 11 and 12 students in New South Wales,
              Australia for Mathematics, Biology, Chemistry and Physics subjects.
            </div>
            <br />
            <div>
              Cluey Coach can guide you through how to approach a question, breakdown complex concepts or provide
              step-by-step guidance on how to solve a problem when you're unsure and can't join a session with a tutor.
            </div>
            <br />
            <div>You can also ask Cluey Coach to check your work for accuracy to test your knowledge and skills.</div>
          </div>
        </section>
        <section className="py-10 md:py-20" id="where-to-acccess">
          <h2 className="mb-6 font-display text-2xl font-bold">Where do I access this feature?</h2>
          <div className="mt-6 mb-10 md:mb-14 md:w-2/3">
            <div>Once logged into the Customer Hub, you can access Cluey Coach directly from the home screen.</div>
            <br />
            <div>
              On the home screen, you will find Cluey Coach underneath Snapshot. Each enrolled subject will have a
              separate link to access Cluey Coach. Make sure to select the corresponding subject you want help with when
              accessing Cluey Coach.
            </div>
          </div>
          <img
            src={HomeAIWidget}
            className="rounded-lg shadow"
            alt="From the home screen, select 'Go to Cluey Coach' for the subject you want help with."
          />
          <div className="mt-3 text-center text-sm">
            From the home screen, select 'Go to Cluey Coach' for the subject you want help with.
          </div>
          <div className="my-10 md:mb-14 md:w-2/3">
            <div>
              You can also access Cluey Coach directly from your student profile page while managing your enrolment
              details and learning profile.
            </div>
            <br />
            <div>From the home screen, select 'See profile' to access your student profile page.</div>
          </div>
          <img
            src={HomeSnapshotProfile}
            className="rounded-lg shadow"
            alt="From the home screen, select 'See profile' under the Snapshot section."
          />
          <div className="mt-3 text-center text-sm">
            From the home screen, select 'See profile' under the Snapshot section.
          </div>
          <div className="my-10 md:mb-14 md:w-2/3">
            <div>
              Once on the student profile page, select an eligible subject in the Learning profile and select 'Go to
              Cluey Coach'.
            </div>
            <br />
            <div>
              Students must be enrolled in an eligible Year 11 or 12 subject (Mathematics, Biology, Chemistry or
              Physics) to have access to Cluey Coach.
            </div>
          </div>
          <img
            src={StudentProfileAIWidget}
            className="rounded-lg shadow"
            alt="On the student profile page, select an eligible enrolled subject to access Cluey Coach."
          />
          <div className="mt-3 text-center text-sm">
            On the student profile page, select an eligible enrolled subject to access Cluey Coach.
          </div>
        </section>
        <section className="py-10 md:py-20" id="how-to-get-started">
          <h2 className="mb-6 font-display text-2xl font-bold">How do I get started?</h2>
          <div className="mt-6 mb-10 md:mb-14 md:w-2/3">
            <div>
              After selecting 'Go to Cluey Coach', you will be greeted by Cluey Coach and be asked to select which topic
              you want help with. If unsure, select 'I'm not sure' then 'Next'.
            </div>
          </div>
          <img
            src={SelectTopic}
            className="rounded-lg shadow"
            alt="Select the topic you want help with. If unsure, select 'I'm not sure' then 'Next'."
          />
          <div className="mt-3 text-center text-sm">
            Select the topic you want help with. If unsure, select 'I'm not sure' then 'Next'.
          </div>
          <div className="my-10 md:mb-14 md:w-2/3">
            <div>
              The free beta version has a limit of 30 requests per day per enrolment. You will see how many requests you
              have left for the day, and a link to learn how to get the most out of your requests.
            </div>
          </div>
          <img
            src={SelectPrompt}
            className="rounded-lg shadow"
            alt="The free beta version has a limit of 30 requests per day per enrolment."
          />
          <div className="mt-3 text-center text-sm">
            The free beta version has a limit of 30 requests per day per enrolment.
          </div>
        </section>
        <section className="py-10 md:py-20" id="what-to-ask">
          <h2 className="mb-6 font-display text-2xl font-bold">What do you want to ask Cluey Coach?</h2>
          <div className="mt-6 mb-10 md:mb-14 md:w-2/3">
            <div>To help you get started, you can choose a prompt starter or write your own question.</div>
            <br />
            <div>
              You can attach a photo of your question by selecting the paperclip icon, although screenshots work best.
              You can also take a photo of your question by selecting the camera icon when accessing Cluey Coach on a
              mobile device.
            </div>
          </div>
          <img
            src={CreatePrompt}
            className="rounded-lg shadow"
            alt="To help you get started, you can choose a prompt starter or write your own question."
          />
          <div className="mt-3 text-center text-sm">
            To help you get started, you can choose a prompt starter or write your own question.
          </div>
          <div className="my-10 md:mb-14 md:w-2/3">
            <div>
              Input of mathematic expressions and special symbols is supported with the integrated virtual keyboard.
              Select the keyboard icon for manual input.
            </div>
            <br />
            <div>Once you're ready, select 'Let's chat' to start a chat with Cluey Coach.</div>
          </div>
          <img
            src={MathKeyboard}
            className="rounded-lg shadow"
            alt="Input of mathematic expressions and special symbols is supported with the integrated virtual keyboard."
          />
          <div className="mt-3 text-center text-sm">
            Input of mathematic expressions and special symbols is supported with the integrated virtual keyboard.
          </div>
          <div className="my-10 md:mb-14 md:w-2/3">
            <div>
              Once the chat has started, you can ask follow up questions and Cluey Coach will guide you through your
              requests.
            </div>
            <br />
            <div>
              At the top of the chat screen, you will see how many requests you have left for the day, and a link to
              learn how to get the most out of your requests.
            </div>
            <br />
            <div>
              Remember, Cluey Coach can make mistakes! Check important information. Be smart online and never share
              private or personal information.
            </div>
          </div>
          <img
            src={Conversation}
            className="rounded-lg shadow"
            alt="Once the chat has started, you can ask follow up questions and Cluey Coach will guide you through your requests."
          />
          <div className="mt-3 text-center text-sm">
            Once the chat has started, you can ask follow up questions and Cluey Coach will guide you through your
            requests.
          </div>
        </section>
        <section className="py-10 md:py-20" id="faqs">
          <h2 className="mb-6 font-display text-2xl font-bold">Frequently asked questions (FAQs)</h2>
          <div className="mt-6 mb-10 md:mb-14 md:w-2/3">
            <div>
              Have questions or need help with Cluey Coach? Navigate to the FAQs page from the Menu to find information
              on how to write a good prompt, request limits and troubleshooting.
            </div>
          </div>
          <img src={FAQ} className="rounded-lg shadow" alt="Navigate to the FAQs page from the Menu." />
          <div className="mt-3 text-center text-sm">Navigate to the FAQs page from the Menu.</div>
        </section>
      </Container>
    </ArticleLayout>
  );
};

const ClueyCoachTutor = () => {
  return <ClueyCoachTutorBase data={getArticleById('tutor-cluey-coach')} />;
};

export default ClueyCoachTutor;
