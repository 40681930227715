import { omitBy, isUndefined } from 'lodash';
import { sendPatchRequest } from './RequestService';
import env from '../env';

export class SummerModeService {
  static getMuleSoftEndpoint(salesForceEnrolmentId) {
    return `https://${env.REACT_APP_MULESOFT_DOMAIN}/enrolments/${salesForceEnrolmentId}`;
  }

  async updateSummerModeData({
    salesForceEnrolmentId,
    enrolmentId,
    selectedSummerMode,
    summerPauseFrom,
    summerPauseTo,
    summerModeHolidayPractice,
    summerModeNewScheduleStartTime,
    summerModeNewScheduleDay,
    summerModeFrequency,
    summerModeHoldingGroup,
    appliedSummerModeIncentive,
    summerModeAdditionalDetails,
    shouldApplyIncentive,
  }) {
    const requestPayload = {
      enrolmentId,
      selectedSummerMode,
      summerPauseFrom,
      summerPauseTo,
      summerModeHolidayPractice,
      summerModeNewScheduleStartTime,
      summerModeNewScheduleDay,
      summerModeFrequency,
      summerModeHoldingGroup,
      summerModeAdditionalDetails,
    };

    if (appliedSummerModeIncentive && shouldApplyIncentive) {
      requestPayload.appliedSummerModeIncentive = appliedSummerModeIncentive;
    }

    const payload = omitBy(requestPayload, isUndefined);

    const errorMessageForUser = 'There was a problem updating your summer mode details.';

    const url = SummerModeService.getMuleSoftEndpoint(salesForceEnrolmentId);
    return sendPatchRequest({
      url,
      payload,
      customErrorMsg: errorMessageForUser,
    });
  }
}
