import { Alert, Container, Link } from '@cluey/cluey-components';
import moment from 'moment';
import { useEffect } from 'react';

import withHeadTag from '../../../common/HeadComponent';
import InPageLoader from '../../../common/InPageLoader';
import PageError from '../../../common/PageError';
import { PageTitle } from '../../../components/hub/PageTitle';
import BasePageLayout from '../../../templates/BasePageLayout/BasePageLayout';
import { scrollToTop } from '../../../util/helpers';
import { PATH_HUB_PLAN_DETAILS } from '../../../util/pagePath';
import PaymentPolicyFAQ from '../components/PaymentPolicyFAQ';
import PricingPlanComparison from './components/PricingPlanComparison';
import useChangePricingPlanContext from './useChangePricingPlanContext';

const ChangePricingPlanPage = () => {
  const {
    loading,
    isError,
    currentPricingPlan,
    upcomingPricingPlan,
    allowedPlansForTransition,
    nextUnpaidSession,
    timezone,
    paidSessions,
  } = useChangePricingPlanContext();
  useEffect(() => {
    scrollToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BasePageLayout>
      {loading && <InPageLoader position="relative" height="auto" bgClass="bg-transparent" />}
      {!loading && isError && <PageError activePage="hub-page" retryEnabled={false} />}
      {!loading && !isError && (
        <>
          <PageTitle
            title="Cluey plans"
            className="!pb-10"
            breadcrumbs={[{ label: 'Account', to: PATH_HUB_PLAN_DETAILS }]}
          />
          <Container className="!px-0">
            {!!paidSessions.length && (
              <>
                <Alert
                  type="info"
                  className="!mb-0"
                  title={
                    allowedPlansForTransition?.length > 0
                      ? `You have ${paidSessions.length} remaining paid sessions on your current ${currentPricingPlan?.pricingPlanName} plan`
                      : 'You have no pricing plans to transition to'
                  }
                  body={null}
                >
                  <div className="mt-3">
                    {upcomingPricingPlan ? (
                      <span>
                        New plan payment will only start from the next unpaid session which is on{' '}
                        <b>{moment(upcomingPricingPlan?.effectiveFromDate).tz(timezone).format('MMMM D')}</b>.
                      </span>
                    ) : (
                      <span>
                        Any new plan can only start from the next unpaid session which is on{' '}
                        <b>{moment(nextUnpaidSession?.startTime).tz(timezone).format('MMMM D')}</b>.
                      </span>
                    )}
                  </div>
                </Alert>
              </>
            )}
            <div className="mb-4 mt-6 flex md:my-6">
              <div className="flex-col">
                <div className="text-base">
                  <Link className="!text-base underline" to={PATH_HUB_PLAN_DETAILS}>
                    Review your current plan
                  </Link>
                  <div className="mt-2">Change plan to fit your needs</div>
                </div>
              </div>
            </div>
          </Container>
          <section className="pb-10 lg:mb-0 lg:pb-[60px]">
            <PricingPlanComparison
              pricingPlans={[
                currentPricingPlan,
                ...allowedPlansForTransition.sort(
                  (plan1, plan2) => plan1.privatePriceTier.costPerSession - plan2.privatePriceTier.costPerSession
                ),
              ]}
            />
          </section>
          <Container>
            <PaymentPolicyFAQ />
          </Container>
        </>
      )}
    </BasePageLayout>
  );
};

export default withHeadTag(ChangePricingPlanPage);
