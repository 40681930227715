import { z } from 'zod';

export const AccountDetails = z.object({
	firstName: z.string(),
	lastName: z.string(),
	email: z.string(),
	phone: z.string().nullable(),
	curriculum: z.string().nullish(),
	customerNumber: z.string(),
	customerSfid: z.string(),
	customerLinkId: z.string().nullable(),
	timezone: z.string(),
	country: z.enum(['AU', 'NZ']),
	invoicePer: z.enum(['Account', 'Enrolment']),
	schoolId: z.string().nullish(),
	studentFirstName: z.string().nullish(),
	studentLastName: z.string().nullish(),
	yearLevel: z.string().nullish(),
	userType: z.enum(['Lead', 'Account']),
});
export type AccountDetails = z.infer<typeof AccountDetails>;
