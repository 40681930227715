import { z } from 'zod';

export const PaymentStatus = z.enum([
	'Pending Payment',
	'Paid',
	'Billed',
	'Processing',
	'Partial Refund',
	'Full Refund',
	'Failed',
	'Requested',
	'Pending',
]);
export const PaymentType = z.enum(['Invoice', 'PrePaid']);
export const DiscountStatus = z.enum(['Active', 'Inactive', 'Expired', 'Already used']);
export const RescheduleRequestStatus = z.enum(['Approved', 'Rejected', 'Requested', 'Request Not Actioned']);

export const Session = z.object({
	automatedTutorChange: z.boolean(),
	calculatedCreditAmount: z.number().nullish(),
	cancellationDateTime: z.string().nullish(),
	creditType: z.string().nullish(),
	discountCode: z.string().nullable(),
	discountPercentage: z.number().nullish(),
	enrolmentType: z.enum(['Trial', '']).nullish(),
	invoiceId: z.string().nullish(),
	invoiceName: z.string().nullish(),
	endTime: z.string(),
	initiatedBy: z.string().nullish(),
	isBundled: z.boolean().nullish(),
	nextPaymentDate: z.string(),
	paymentDate: z.string().nullable(),
	paymentStatus: PaymentStatus,
	paymentType: PaymentType.nullable(),
	recordingLink: z.string().nullable(),
	rescheduledWithinDeadline: z.boolean(),
	sessionReportId: z.string().nullable(),
	sessionToken: z.string(),
	sessionsInBundle: z.union([z.number(), z.string()]).nullish(),
	sessionDiscountActive: z.boolean().nullish(),
	sessionDiscountName: z.string().nullable(),
	sessionDiscountPercentage: z.number().nullable(),
	sessionDiscountStatus: DiscountStatus.nullable(),
	startTime: z.string(),
	statusCode: z.string().nullish(),
	student: z.string(),
	studentSessionId: z.string(),
	subject: z.string(),
	tutor: z.string(),
	tutorNumber: z.string(),
	tutorSessionId: z.string(),
	tutorPhoto: z.string().nullable(),
	type: z.string(),
	uniqueEnrolmentId: z.string(),
	tutorRescheduleRequestStatusC: RescheduleRequestStatus.nullable(),
	tutorSfId: z.string(),
	packageTutorSfid: z.string(),
});
export type Session = z.infer<typeof Session>;
