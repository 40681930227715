import { get, sortBy } from 'lodash';
import moment from 'moment';
import { parsePackageName } from '../util/helpers';

const getStudent = (students, uniqueEnrolmentId, sessionToken = '') => {
  const student = students.find(stu => stu.uniqueEnrolmentId === uniqueEnrolmentId);

  let studentEntry;
  if (student) {
    studentEntry = {
      name: `${student.name} ${student.lastName.charAt(0)}`,
      sessionReportLink: student.sessionReportLink,
      sessionReportId: student.sessionReportId,
      sessionManagementLink: student.sessionManagementLink,
      practiceLink: student.practiceLink,
      uniqueEnrolmentId,
      sessionToken,
    };
  } else {
    studentEntry = {
      name: `${student.name}`,
      sessionReportLink: '',
      sessionReportId: '',
      sessionManagementLink: '',
      practiceLink: '',
      uniqueEnrolmentId,
      sessionToken,
    };
  }

  return studentEntry;
};

export const getLearningGroups = (state) => {
  const students = get(state, 'schoolsUpcomingSessions.students', []);
  const sessions = get(state, 'schoolsUpcomingSessions.sessions', []);

  const filtered = sessions.filter((session) => {
    const endTime = moment(session.endTime);
    const now = moment();
    return endTime.isAfter(now);
  });
  const sorted = sortBy(filtered, session => moment(session.startTime).unix());

  const learningGroups = {};
  sorted.forEach(
    ({
      packageName,
      startTime,
      endTime,
      subject,
      tutor,
      tutorState,
      tutorPhoto,
      tutorType,
      tutorDescription,
      uniqueEnrolmentId,
      sessionToken,
      yearAsInteger,
    }) => {
      const studentEntry = getStudent(students, uniqueEnrolmentId, sessionToken);

      const existingLearningGroup = learningGroups[packageName];
      if (existingLearningGroup) {
        // Check times map for our session time
        const sessionTime = existingLearningGroup.times[startTime];
        if (sessionTime) {
          // We found it, so we now append our student entry to the array of students
          sessionTime.students = [...sessionTime.students, studentEntry];
        } else {
          // It must not yet exists, so we create it.
          existingLearningGroup.times[startTime] = {
            startTime,
            endTime,
            tutor,
            students: [studentEntry],
          };
        }

        // Now we'll need to check if this student is already recorded
        const studentFound = existingLearningGroup.students.find(student => student.uniqueEnrolmentId === uniqueEnrolmentId);

        if (!studentFound) {
          existingLearningGroup.students = [...existingLearningGroup.students, studentEntry];
        }
      } else {
        // Session hasn't been made into a learning group just yet, so let's do that now

        const packageInfo = parsePackageName(packageName);

        const lgroup = {
          packageName,
          // times is created as a map, will be converted to an array with Object.values() before returning.
          times: {
            [startTime]: {
              startTime,
              endTime,
              tutor,
              students: [studentEntry],
            },
          },
          subject,
          tutor,
          tutorState,
          tutorPhoto,
          tutorType,
          tutorDescription,
          students: [studentEntry],
          yearLevel: yearAsInteger || -1,
          scheduledTime: packageInfo
            ? moment(`${packageInfo.dayOfWeek} ${packageInfo.timeOfDay}`, 'ddd hh:mm A').format('dddd[s at] h:mma')
            : '',
        };

        learningGroups[packageName] = lgroup;
      }
    },
  );

  const listOfGroups = sortBy(Object.values(learningGroups), ['yearLevel', 'subject']);
  listOfGroups.forEach((group) => {
    group.students = sortBy(group.students, 'name');
    // group.times = Object.values(group.times).map(time => ({
    //   ...time,
    //   students: time.students.map(studentId => group.students.find(student => student.uniqueEnrolmentId === studentId)),
    // }));
    group.times = Object.values(group.times);
  });
  console.log(listOfGroups);
  return listOfGroups;
};

export const getTodaySessions = (state) => {
  const allSessions = get(state, 'schoolsUpcomingSessions.sessions', []);
  const allStudents = get(state, 'schoolsUpcomingSessions.students', []);

  // Grab all sessions happening today
  const todaysSessions = allSessions.filter((session) => {
    const startTime = moment(session.startTime);
    const endTime = moment(session.endTime);
    const now = moment();
    return endTime.isAfter(now) && startTime.isSame(now, 'date');
  });

  // Group up students into useable sessions
  const displaySessions = {};
  todaysSessions.forEach((session) => {
    const display = displaySessions[session.packageName];
    const student = getStudent(allStudents, session.uniqueEnrolmentId, session.sessionToken);
    if (display) {
      // Already Exists, We just need to add the student name
      display.students = [...display.students, student];
    } else {
      // Display Session doesn't already exist so we'll need to add it

      const newDisplay = {
        startTime: session.startTime,
        endTime: session.endTime,
        subject: session.subject,
        tutor: session.tutor,
        packageName: session.packageName,
        students: [student],
        yearLevel: session.yearAsInteger || -1,
      };
      displaySessions[session.packageName] = newDisplay;
    }
  });

  const sessions = Object.values(displaySessions);
  sessions.forEach((session) => {
    session.students = sortBy(session.students, 'name');
  });
  return sortBy(sessions, session => moment(session.startTime).unix());
};
