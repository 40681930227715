import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ChangeSessionConfirmationScreenNextSession as ConfirmationScreenNextSession } from '../ChangeScheduleConfirmation/ConfirmationScreenNextSession';

import { changeSession as changeSessionAction } from '../../../actions/hub/hubEnrolmentManagementActions';
import { setTutorTypeDefault } from '../../../util/helpers';
import TutorDetailsCompact from '../../TutorInfo/TutorDetailsCompact';
import { useEnrolmentDetailQuery } from '../../../hooks/queries/useEnrolmentQuery';
import mapStudentInformationToTutorDetails from '../../TutorInfo/mapStudentInformationToTutorDetails';
import { TTBM_CUSTOMER_NUMBER } from '../../../util/constants';

const ChangeSessionTimeConfirmation = ({
	enrolmentId,
	enrolmentSfid,
	tutorNumber,
	tutorName,
	tutorState,
	tutorType,
	tutorPhoto,
	tutorDescription,
	onConfirmClick,
	onChangeClick,
	onSubmissionError,
	selectedDay,
	selectedTime,
	sessionDuration,
	sessionId,
	timezone,
	changeSession,
	enrolmentType,
}) => {
	const { data } = useEnrolmentDetailQuery({ enrolmentId });
	const enrolmentDetail = data || {};
	const { studentInformation, englishTextStudied } = enrolmentDetail;
	const { skilledAt, highlyValuedFor } = mapStudentInformationToTutorDetails(studentInformation);
	const isMatchingTutor = tutorNumber === TTBM_CUSTOMER_NUMBER;
	return (
		<div className="container mx-auto mb-10">
			<h2 className="mb-6 text-xl font-normal">Please review and confirm the new session time</h2>
			<div className="flex flex-col overflow-hidden rounded-lg border border-grey-2 bg-white lg:flex-row">
				<div className="!lg:pl-[10%] w-full px-5 py-6 lg:w-1/2">
					<ConfirmationScreenNextSession
						enrolmentId={enrolmentId}
						enrolmentSfid={enrolmentSfid}
						tutorNumber={tutorNumber}
						enrolmentType={enrolmentType}
						selectedDay={selectedDay}
						scheduleDate={selectedTime}
						sessionDuration={sessionDuration}
						timezone={timezone}
						goBackToTutorAvailabilityScreen={onChangeClick}
						customConfirmCTALabel="Confirm session change"
						customConfirmHandler={({ startDate, endDate }) => {
							changeSession({
								enrolmentId,
								sessionId,
								startDate,
								endDate,
								customerNumber: tutorNumber,
								onSuccess: onConfirmClick,
								onError: onSubmissionError,
							});
						}}
					/>
				</div>
				<div className="w-full lg:w-1/2 lg:pl-[10%]">
					<div className="h-full border-t border-[#e6e8e8] bg-slate-1 px-5 py-6 lg:border-l lg:border-t-0 lg:pb-[44px] lg:pl-[60px] lg:pr-[60px] lg:pt-14">
						<div className="mb-5 flex items-center border-b border-grey-2 pb-5 lg:mb-6 lg:pb-6">
							<img
								src={`${tutorPhoto}?v=1`}
								alt="tutor avatar"
								className="mr-4 h-[60px] w-[60px] rounded-full border border-grey-3 lg:mr-5"
							/>
							<div className="flex flex-col">
								<span className="mb-1 text-lg font-bold leading-snug">{tutorName}</span>
								<span className="text-base leading-snug">
									{tutorState} {setTutorTypeDefault({ tutorType })}
								</span>
							</div>
						</div>
						{!isMatchingTutor && (
							<>
								<TutorDetailsCompact
									highlyValuedFor={highlyValuedFor}
									skilledAt={skilledAt}
									englishTextStudied={englishTextStudied}
								/>
								<div className="mb-3">
									<span className="mb-3 text-xs font-bold uppercase leading-none tracking-[1px]">
										A LITTLE ABOUT {tutorName}…
									</span>
									<p className="text-xs leading-relaxed">{tutorDescription}</p>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

ChangeSessionTimeConfirmation.propTypes = {
	enrolmentId: PropTypes.string.isRequired,
	enrolmentSfid: PropTypes.string.isRequired,
	tutorNumber: PropTypes.string.isRequired,
	tutorName: PropTypes.string.isRequired,
	tutorState: PropTypes.string.isRequired,
	tutorType: PropTypes.string.isRequired,
	tutorPhoto: PropTypes.string.isRequired,
	tutorDescription: PropTypes.string.isRequired,
	onConfirmClick: PropTypes.func.isRequired,
	onChangeClick: PropTypes.func.isRequired,
	onSubmissionError: PropTypes.func.isRequired,
	selectedDay: PropTypes.string.isRequired,
	selectedTime: PropTypes.string.isRequired,
	sessionDuration: PropTypes.number.isRequired,
	sessionId: PropTypes.string.isRequired,
	timezone: PropTypes.string.isRequired,
	changeSession: PropTypes.func.isRequired,
	enrolmentType: PropTypes.string.isRequired,
};

export const ConnectedChangeSessionTimeConfirmation = connect(
	(state) => {
		const {
			enrolmentId,
			tutorName,
			tutorState,
			tutorPhoto,
			tutorType,
			tutorDescription,
			tutorNumber,
			enrolmentSfid,
			enrolmentType,
		} = state.hubEnrolmentDetailPage;
		const {
			selectedDay,
			selectedTime,
			sessionDuration = 60,
			sessionId,
		} = state.hubChangeSessionWithEnrolmentTutor.selectedDayTime;

		return {
			enrolmentId,
			enrolmentSfid,
			tutorNumber,
			tutorName,
			tutorState,
			tutorType,
			tutorPhoto,
			tutorDescription,
			selectedDay,
			selectedTime,
			sessionDuration,
			sessionId,
			timezone: state.hubUser.timezone,
			enrolmentType,
		};
	},
	(dispatch) => {
		return {
			changeSession: bindActionCreators(changeSessionAction, dispatch),
		};
	}
)(ChangeSessionTimeConfirmation);

export { ChangeSessionTimeConfirmation as StatelessChangeSessionTimeConfirmation };
