import { useEffect } from 'react';
import { AnchorButton, Container } from '@cluey/cluey-components';
import classNames from 'classnames';
import EnrolmentLayout from '../../../common/hub/EnrolmentLayout';
import { PATH_HUB_ENROLMENT_DETAIL } from '../../../util/pagePath';
import { PromoText } from '../PromoText';
import SummerPromoLegalCopy from '../SummerMode/SummerPromoLegalCopy';

interface Props {
  mainHeading: string;
  student: string;
  subject: string;
  enrolmentId: string;
  message: string | Array<string>;
  tracking: string;
  ctaTracking?: string;
  children?: JSX.Element | Array<JSX.Element>;
  cleanUp?(): void;
  promoText?: string;
  showLegalCopy?: boolean;
}

const EnrolmentManagementConfirmed = ({
  mainHeading,
  student,
  subject,
  enrolmentId,
  message,
  tracking,
  ctaTracking,
  cleanUp,
  promoText,
  showLegalCopy,
  children,
}: Props) => {

  useEffect(() => {
    return () => {
      if (typeof cleanUp === 'function') {
        cleanUp();
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <EnrolmentLayout
      sectionHeading={`${student} ${subject}`}
      mainHeading={mainHeading}
      enrolmentId={enrolmentId}
      tracking={tracking}
    >
      <Container>
        {children}
        <p className="lh-1-2 mb-5 text-xl md:w-3/4 lg:mb-8 lg:w-1/2">
          {Array.isArray(message) && message.map((info) => info)}
          {!Array.isArray(message) && message}
        </p>
        {promoText && (
          <div className="mb-4">
            <PromoText text={promoText} />
          </div>
        )}
        <AnchorButton
          size="xs"
          appearance="reverse"
          className={classNames({
            [ctaTracking]: ctaTracking !== null,
          })}
          href={`${PATH_HUB_ENROLMENT_DETAIL}?token=${enrolmentId}`}
          target='self'
        >
          view {student}&apos;s {subject} enrolment
        </AnchorButton>
      </Container>

      {showLegalCopy && (
        <Container>
          <SummerPromoLegalCopy />
        </Container>
      )}
    </EnrolmentLayout>
  );
};

export default EnrolmentManagementConfirmed;