/*
* Since customers are redirected to hub, this is deprecated. (Not 100% sure)
* Will update this component if QA find any issues/usage.
* */
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import GoBack from './GoBack';
import ContactScreenWrap from './ContactScreenWrap';
import ContactPhone from './ContactPhone';
import { submitContactForm, saveEnrolmentIdForSAF } from '../../actions/contactUsFlowActions';
import * as CONSTANTS from '../../util/constants';
import * as PATHS from '../../util/pagePath';
import { getCustomerNumber } from '../../selectors/portalSelector';

const ContactForm = ({
  sessionDetail,
  title,
  ciClass,
  isLoading,
  fetchError,
  students,
  submit,
  updateEnrolmentIdForSAF,
  rescheduleSessionToken,
  customerNumber,
}) => {
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [message, setMessage] = useState();
  const [uniqueEnrolmentId, setUniqueEnrolmentId] = useState();
  const location = useLocation();

  useEffect(() => {
    if (students.length > 0) {
      // Need any enrolment id for SAF so cases can be linked to correct contacts
      const aStudent = students.find((s) => {
        return s.uniqueEnrolmentId.length > 0;
      });
      setUniqueEnrolmentId(aStudent.uniqueEnrolmentId);
    } else if (rescheduleSessionToken !== null) {
      setUniqueEnrolmentId(rescheduleSessionToken);
    }
  }, [students, rescheduleSessionToken]);

  const onTextEnter = (e) => {
    const enteredContent = e.target.value;
    if (enteredContent.length > 0) {
      setIsBtnDisabled(false);
      setMessage(e.target.value);
    } else {
      setIsBtnDisabled(true);
    }
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    let caseOrigin = '';
    if (location.pathname === PATHS.PATH_UPCOMING_SESSIONS) {
      caseOrigin = CONSTANTS.SAF_CASE_ORIGIN_UPCOMING_SESSIONS;
    } else if (location.pathname === PATHS.PATH_SESSION_REPORT) {
      caseOrigin = CONSTANTS.SAF_CASE_ORIGIN_SESSION_REPORT;
    } else if (location.pathname === PATHS.PATH_RESCHEDULE_SESSION
      || location.pathname === PATHS.PATH_RESCHEDULE_SESSION_OLD) {
      caseOrigin = CONSTANTS.SAF_CASE_ORIGIN_RESCHEDULE_SESSION;
    }

    if (uniqueEnrolmentId !== undefined) {
      updateEnrolmentIdForSAF({ enrolmentId: uniqueEnrolmentId });
    }

    submit({
      message,
      caseOrigin,
      customerNumber,
    });
  };

  const innerH3Text = sessionDetail.length > 0 ? sessionDetail : 'Please specify student and enrolment you are enquiring about';

  const submitBtnClasses = classNames('btn btn-primary py-5', { 'disabled cursor-not-allowed': isBtnDisabled || isLoading });
  return (
    <Fragment>
      <GoBack />
      <ContactScreenWrap
        ciClass={`ci--circle-border ${ciClass}`}
        h1Text={title}
      >
        { fetchError && (
          <p className="mb-4 mx-auto msg--error">Failed to submit your request. Please try again later.</p>
        )}
        <div className="p-4 p-md-10 rounded bg-pale-grey inner">
          <form
            action="#"
            className="m-auto"
            onSubmit={onFormSubmit}
          >
            <h3 className="text-center mb-5 mb-lg-9">{innerH3Text}</h3>
            <div className="form-group mb-5 mb-md-6 mb-lg-7 ">
              <label htmlFor="inquiry-detail" className="d-none">Enter inquiry detail</label>
              <textarea
                id="inquiry-detail"
                className="form-control p-4 p-md-5 p-lg-6 m-auto"
                maxLength="1000"
                rows="5"
                placeholder="Enter details here (required)"
                required
                onChange={onTextEnter}
              />
            </div>
            <button
              type="submit"
              className={submitBtnClasses}
              disabled={isBtnDisabled || isLoading}
            >
              Send message
            </button>
          </form>
        </div>
      </ContactScreenWrap>
      <ContactPhone />
    </Fragment>
  );
};

ContactForm.propTypes = {
  sessionDetail: PropTypes.string,
  title: PropTypes.string.isRequired,
  ciClass: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchError: PropTypes.bool.isRequired,
  students: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      subject: PropTypes.string.isRequired,
      uniqueEnrolmentId: PropTypes.string.isRequired,
      sessionReportId: PropTypes.string,
      practiceLink: PropTypes.string,
    }),
  ),
  submit: PropTypes.func.isRequired,
  updateEnrolmentIdForSAF: PropTypes.func.isRequired,
  rescheduleSessionToken: PropTypes.string,
  customerNumber: PropTypes.string,
};

ContactForm.defaultProps = {
  sessionDetail: '',
  students: [],
  rescheduleSessionToken: null,
  customerNumber: '',
};

export default connect(
  (state) => {
    const {
      contactUsFlow, ui, upcomingSessions, rescheduleSession,
    } = state;
    return {
      sessionDetail: contactUsFlow.sessionDetail,
      title: contactUsFlow.formScreen.title,
      ciClass: contactUsFlow.formScreen.ciClass,
      isLoading: ui.apiState.contactUsFlow.isLoading,
      fetchError: ui.apiState.contactUsFlow.fetchError,
      students: upcomingSessions.students,
      rescheduleSessionToken: rescheduleSession.token,
      customerNumber: getCustomerNumber(state),
    };
  },
  (dispatch) => {
    return {
      submit: bindActionCreators(submitContactForm, dispatch),
      updateEnrolmentIdForSAF: bindActionCreators(saveEnrolmentIdForSAF, dispatch),
    };
  },
)(ContactForm);
