import { connect } from 'react-redux';
import { getNextYearEnrolments } from '../../selectors/summerModeSelector';
import { ContextualAlert } from '../ContextualAlert/ContextualAlert';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
const NextYearEnrolmentsAlerts = ({nextYearEnrolments}) => {
  /* 
    This banner will only be shown in 2022
    the body must be aligned with summerCopy
    the Summer Mode New Schedule Start Time is in UTC in Sales force; convert it to Australia time
  */
  return (
      nextYearEnrolments.length > 0 && moment().year() === 2022 ? (
        <div className='flex flex-col gap-y-2'>
          {nextYearEnrolments.map(enrolment => {
            const nextYearStartDate = moment(enrolment.summerModeAdditionalDetails);
            const newScheduleStartTime = moment.utc(enrolment.summerModeNewScheduleStartTime, 'hh:mm').tz('Australia/Sydney').format('h:mma');
            const body = `From ${nextYearStartDate.format('MMM D')} ${enrolment.name} will continue weekly ${enrolment.discipline} group sessions ${enrolment.summerModeNewScheduleDay}s at ${newScheduleStartTime}`
            return (
              <ContextualAlert
                key={enrolment.sfId}
                body={body}
                title="Holiday Learning"
                cta={null}
              />
            )
          })}
        </div>
    ) : null
  )
}

NextYearEnrolmentsAlerts.propTypes = {
  nextYearEnrolments: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    nextSessions: PropTypes.arrayOf(PropTypes.shape({
      day_of_week: PropTypes.number,
      day_of_week_name: PropTypes.string,
      time: PropTypes.string,
    })),
    sfId: PropTypes.string,
    summerModeAdditionalDetails: PropTypes.string,
    uniqueEnrolmentId: PropTypes.string,
  })),
}

NextYearEnrolmentsAlerts.defaultProps = {
  nextYearEnrolments: [],
}

export default connect((state) => {
  return {
    nextYearEnrolments: getNextYearEnrolments(state),
  }
})(NextYearEnrolmentsAlerts);