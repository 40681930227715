import performApiFetch from './performApiFetch';
import apiOptionsPool from './optionsPool';
import { logError, WARNING } from '../../logging';

export {
  REQ_GET_LOGIN,
  REQ_GET_SUMMARY,
  REQ_GET_ACTIVITIES,
  REQ_GET_UPCOMING_SESSIONS,
  REQ_GET_UPCOMING_SESSIONS_TOKEN,
  REQ_POST_TO_CONTACT,
  REQ_POST_CREATE_NEW_CASE,
  REQ_POST_CUSTOMER_TRIGGER_PAYMENT,
  REQ_POST_RESCHEDULE_SESSION,
  REQ_POST_CANCEL_SESSION,
  REQ_POST_FETCH_REFUND_POLICY,
  REQ_GET_HUB_UPCOMING_SESSIONS,
  REQ_GET_HUB_COMPLETED_SESSIONS,
  REQ_GET_HUB_SCHOOL_TERMS_PUBLIC_HOLIDAYS,
  REQ_GET_HUB_ACTIVE_ENROLMENTS,
  REQ_GET_HUB_PAST_ENROLMENTS,
  REQ_GET_HUB_ENROLMENT_DETAIL,
  REQ_GET_TUTOR_INFO,
  REQ_POST_HUB_NEW_SCHEDULE,
  REQ_GET_HUB_ACCOUNT_DETAILS,
  REQ_PUT_HUB_ACCOUNT_DETAILS,
  REQ_GET_HUB_BILLING_INFORMATION,
  REQ_POST_HUB_SF_CHARGENT_ORDER,
  REQ_POST_HUB_SF_PROCESS_PAYMENT,
  REQ_GET_HUB_INVOICE_HISTORY,
  REQ_POST_HUB_CREATE_NEW_CASE_SF,
  REQ_POST_HUB_RESEND_SIGNUP_LINK,
  REQ_GET_STUDENT_PROGRESS_SUMMARY,
  REQ_GET_STUDENT_PROGRESS_MODULE_DETAIL,
  REQ_POST_HUB_REQUEST_TO_RESET_PASSWORD,
  REQ_GET_PAST_TUTOR,
  REQ_GET_TUTOR_AVAILABILITY,
  REQ_GET_SELECTED_TUTOR_INFO,
  REQ_GET_HUB_PAUSE_SESSIONS_RESUME_SESSION,
  REQ_GET_GROUP_PACKAGES,
} from './endpointTypes';

/**
 * Fetches the specified endpoint
 * @param {string} endpointType             One of the types exported from apiEndpointTypes
 * @param {Object} state                    Redux state
 * @param {Object | void} requestPayload    Optional object with payload to be sent to server
 * @param  {Function} dispatch              Redux function, used to retrieve new cognnito credential
 * @return {Promise<Object, Error>}         Promise that resolves to the response payload
 */
export function apiFetch(endpointType, state, requestPayload, dispatch) {
  let apiOptions = apiOptionsPool[endpointType];
  if (!apiOptions) {
    const errorMessage = `Unknown endpointType ${endpointType}`;
    logError(errorMessage, null, { level: WARNING });
    return Promise.reject(new Error(errorMessage));
  }
  if (!apiOptions.host || !apiOptions.path || !apiOptions.method) {
    const errorMessage = `Incomplete apiOptions object for endpointType ${endpointType}`;
    logError(errorMessage, null, { level: WARNING });
    return Promise.reject(new Error(errorMessage));
  }
  if (typeof apiOptions.preprocessOptions === 'function') {
    apiOptions = apiOptions.preprocessOptions(apiOptions, { requestPayload });
  }
  return performApiFetch(apiOptions, state, requestPayload, dispatch);
}
