import {
	PATH_HUB_CR_OTHER_ENROLMENT_CHANGE,
	PATH_HUB_CR_PAUSE_SESSIONS,
	PATH_HUB_CR_RESCHEDULE_SESSION,
	PATH_HUB_CR_SKIP_SESSION,
	PATH_HUB_ENROLMENT_CHANGE_SCHEDULE,
} from './pagePath';

export const CUSTOMER_CARE_CONTACT_PHONE = '02 8311 3964';
export const CUSTOMER_CARE_CONTACT_PHONE_2 = '(02) 8311 3964';
export const CUSTOMER_SUPPORT_EMAIL = 'support@clueylearning.com';
export const NO_OF_SESSIONS_PER_PAGE = 10;
export const NO_OF_INVOICE_HISTORY_PER_PAGE = 10;

export const CARD_TYPE_SESSION = 'SESSION';
export const CARD_TYPE_PRACTICE = 'PRACTICE';
export const CARD_TYPE_FEEDBACK = 'FEEDBACK';
export const CARD_TYPE_TEST_SIMULATION = 'TEST_SIMULATION';
export const CARD_TYPE_GROUP = 'GROUP';
export const CARD_TYPE_STRAND = 'STRAND';
export const CARD_TYPE_KICK_OFF = 'KICKOFF';

export const ICON_TYPE = {
	[CARD_TYPE_SESSION]: 'person',
	[CARD_TYPE_PRACTICE]: 'pencil',
	[CARD_TYPE_TEST_SIMULATION]: 'clock',
	[CARD_TYPE_FEEDBACK]: 'message-circle',
	[CARD_TYPE_GROUP]: 'people',
	[CARD_TYPE_STRAND]: 'report',
	[CARD_TYPE_KICK_OFF]: 'report',
};

export const AUSTRALIA_STATES = {
	NSW: 'New South Wales',
	VIC: 'Victoria',
	QLD: 'Queensland',
	WA: 'Western Australia',
	SA: 'South Australia',
	TAS: 'Tasmania',
	ACT: 'Australian Capital Territory',
	NT: 'Northern Territory',
} as const;

// Any updates on breakpoints plz also update breakpoints in custom-bootstrap.scss $grid-breakpoints
export const BREAKPOINTS = {
	xs: 0,
	sm: 576,
	md: 768,
	lg: 1024,
	xl: 1200,
	xxl: 1440,
};

export const EMOJI_LIST = [
	{
		emojiName: 'grinning-with-big-eyes',
		emojiValue: 'Learned a lot',
	},
	{
		emojiName: 'grinning',
		emojiValue: 'Fun',
	},
	{
		emojiName: 'blushing',
		emojiValue: 'Encouraging',
	},
	{
		emojiName: 'neutral',
		emojiValue: 'Just OK',
	},
	{
		emojiName: 'fearful',
		emojiValue: 'Too Hard',
	},
	{
		emojiName: 'smirking',
		emojiValue: 'Too Easy',
	},
	{
		emojiName: 'sleeping',
		emojiValue: 'Boring',
	},
	{
		emojiName: 'unamused',
		emojiValue: 'Not Good',
	},
];

export function mapFeedbackResponseToEmojiName(response) {
	const emoji = EMOJI_LIST.find((e) => e.emojiValue.toLowerCase() === response.toLowerCase());
	return emoji.emojiName;
}

export const SF_TAG_STUDENT_RATING_FEEDBACK = 'studentRating';
export const SF_TAG_STUDENT_EMOJI_FEEDBACK = 'studentExperienceEmoji';
export const SF_TAG_TUTOR_RECOMMENDATIONS = 'tutorRecommendations';
export const SF_TAG_TUTOR_RECOMMENDATIONS_GROUP_NAPLAN = 'tutorRecommendationsGroupNAPLAN';
export const SF_TAG_TUTOR_FEEDBACK = 'tutorFeedback';
export const SF_TAG_STUDENT_FEEDBACK = 'studentFeedback';
export const SF_TAG_TUTOR_COMING_UP_NEXT = 'tutorWhatsNext';
export const SF_TAG_TUTOR_ENGLISH_STRAND_1 = 'tutorEvaluationEnglishStrand1';
export const SF_TAG_TUTOR_ENGLISH_STRAND_2 = 'tutorEvaluationEnglishStrand2';
export const SF_TAG_TUTOR_ENGLISH_STRAND_3 = 'tutorEvaluationEnglishStrand3';
export const SF_TAG_TUTOR_MATHS_STRAND_1 = 'tutorEvaluationMathsStrand1';
export const SF_TAG_TUTOR_MATHS_STRAND_2 = 'tutorEvaluationMathsStrand2';
export const SF_TAG_TUTOR_MATHS_STRAND_3 = 'tutorEvaluationMathsStrand3';

export const SF_TAG_STUDENT_ENGLISH_STRAND_1 = 'studentEvaluationEnglishStrand1';
export const SF_TAG_STUDENT_ENGLISH_STRAND_2 = 'studentEvaluationEnglishStrand2';
export const SF_TAG_STUDENT_ENGLISH_STRAND_3 = 'studentEvaluationEnglishStrand3';
export const SF_TAG_STUDENT_MATHS_STRAND_1 = 'studentEvaluationMathsStrand1';
export const SF_TAG_STUDENT_MATHS_STRAND_2 = 'studentEvaluationMathsStrand2';
export const SF_TAG_STUDENT_MATHS_STRAND_3 = 'studentEvaluationMathsStrand3';
export const SF_TAG_KICK_OFF_MATHS = 'kickoffOutcomeMaths';
export const SF_TAG_KICK_OFF_ENGLISH_STRAND_1 = 'kickoffOutcomeEnglishStrand1';
export const SF_TAG_KICK_OFF_ENGLISH_STRAND_2 = 'kickoffOutcomeEnglishStrand2';
export const SF_TAG_KICK_OFF_ENGLISH_STRAND_3 = 'kickoffOutcomeEnglishStrand3';

export const ENROLMENT_STATUS_ACTIVE = 'ACTIVE';
export const ENROLMENT_STATUS_FINISHED = 'FINISHED';
export const ENROLMENT_STATUS_CANCELLED = 'CANCELLED';

export const SUBJECT_MATHS = 'mathematics';
export const SUBJECT_ENGLISH = 'english';
export const SUBJECT_CHEMISTRY = 'chemistry';
export const SUBJECT_BIOLOGY = 'biology';
export const SUBJECT_PHYSICS = 'physics';
export const STRAND_SECTION_HEADER_KICKOFF_KEY = 'STRAND_SECTION_HEADER_KICKOFF_KEY';

export const NAPLAN_NUMERACY = 'Numeracy';
export const NAPLAN_LITERACY = 'Literacy';

export const VIDEO_STATUS_ARCHIVED = 'VIDEO_ARCHIVED';

export const ROLE_STUDENT = 'STUDENT';
export const ROLE_STAFF = 'STAFF';
export const ROLE_TUTOR = 'TUTOR';
export const ROLE_PORTAL_USER = 'PORTAL_USER';
export const SF_SESSION_TYPE_SESSION = 'Session';
export const SF_SESSION_TYPE_GROUP = 'Group Session';

// app view constants
export const APP_VIEW_DEFAULT = 'APP_VIEW_DEFAULT';
export const APP_VIEW_CONTACT_OPTIONS = 'APP_VIEW_CONTACT_OPTIONS';
export const APP_VIEW_CONTACT_FORM = 'APP_VIEW_CONTACT_FORM';
export const APP_VIEW_CONTACT_RESCHEDULE_REQUEST = 'APP_VIEW_CONTACT_RESCHEDULE_REQUEST';
export const APP_VIEW_CONTACT_CANCEL_REQUEST = 'APP_VIEW_CONTACT_CANCEL_REQUEST';
export const APP_VIEW_CONTACT_SUCCESS = 'APP_VIEW_CONTACT_SUCCESS';

// contact us flow types
export const APP_CONTACT_FLOW_GENERAL = 'APP_CONTACT_FLOW_GENERAL';
export const APP_CONTACT_FLOW_SPECIFIC = 'APP_CONTACT_FLOW_SPECIFIC';
export const APP_CONTACT_FLOW_SPECIFIC_RSP = 'APP_CONTACT_FLOW_SPECIFIC_RSP';

// SAF stands for SalesForce
export const SAF_CASE_ORIGIN_SESSION_REPORT = 'Learning Activity';
export const SAF_CASE_ORIGIN_UPCOMING_SESSIONS = 'Upcoming Sessions';
export const SAF_CASE_ORIGIN_RESCHEDULE_SESSION = 'Manage Sessions';
// Note, we will need to add more SAF_CASE_ORIGIN here for hub. The value of those constants will depend on SF.

// contact options
const SF_CASE_CATEGORY = {
	TEMPORARY_SCHEDULE_CHANGE: 'Temporary schedule change',
	SKIP_CANCEL_SESSION: 'Skip/Cancel Session',
	PAUSE_SESSIONS: 'Pause sessions',
	PERMANENT_SCHEDULE_CHANGE: 'Permanent schedule change',
	PAYMENT_ENQUIRY: 'Support Request',
	OTHER: 'Other',
};

const SF_CASE_TYPE = {
	SCHEDULING: 'Scheduling',
	PAYMENT: 'Payment',
	FEEDBACK: 'Feedback',
};

const SF_RECORD_TYPE = {
	SUPPORT: 'Support',
	CANCELLATION: 'Cancellation',
};

export const GENERAL_CONTACT_OPTIONS = [
	{
		label: 'Reschedule a session',
		ciClass: 'ci--calendar-2',
		caseCategory: SF_CASE_CATEGORY.TEMPORARY_SCHEDULE_CHANGE,
		caseType: SF_CASE_TYPE.SCHEDULING,
		recordType: SF_RECORD_TYPE.SUPPORT,
	},
	{
		label: 'Skip a session',
		ciClass: 'ci--calendar-1',
		caseCategory: SF_CASE_CATEGORY.SKIP_CANCEL_SESSION,
		caseType: SF_CASE_TYPE.SCHEDULING,
		recordType: SF_RECORD_TYPE.SUPPORT,
	},
	{
		label: 'Skip consecutive sessions (Pause)',
		ciClass: 'ci--pause',
		caseCategory: SF_CASE_CATEGORY.PAUSE_SESSIONS,
		caseType: SF_CASE_TYPE.SCHEDULING,
		recordType: SF_RECORD_TYPE.SUPPORT,
	},
	{
		label: 'Permanent schedule change',
		ciClass: 'ci--calendar-3',
		caseCategory: SF_CASE_CATEGORY.PERMANENT_SCHEDULE_CHANGE,
		caseType: SF_CASE_TYPE.SCHEDULING,
		recordType: SF_RECORD_TYPE.SUPPORT,
	},
	{
		label: 'Payment enquiry',
		ciClass: 'ci--payment',
		caseCategory: SF_CASE_CATEGORY.PAYMENT_ENQUIRY,
		caseType: SF_CASE_TYPE.PAYMENT,
		recordType: SF_RECORD_TYPE.SUPPORT,
	},
	{
		label: 'Other',
		ciClass: 'ci--chat',
		caseCategory: SF_CASE_CATEGORY.OTHER,
		caseType: SF_CASE_TYPE.FEEDBACK,
		recordType: SF_RECORD_TYPE.SUPPORT,
	},
];

export const SPECIFIC_CONTACT_OPTIONS = [
	{
		label: 'Reschedule this session',
		ciClass: 'ci--calendar-2',
		caseCategory: SF_CASE_CATEGORY.TEMPORARY_SCHEDULE_CHANGE,
		caseType: SF_CASE_TYPE.SCHEDULING,
		recordType: SF_RECORD_TYPE.SUPPORT,
		sessionForm: PATH_HUB_CR_RESCHEDULE_SESSION,
	},
	{
		label: 'Skip this session',
		ciClass: 'ci--calendar-1',
		caseCategory: SF_CASE_CATEGORY.SKIP_CANCEL_SESSION,
		caseType: SF_CASE_TYPE.SCHEDULING,
		recordType: SF_RECORD_TYPE.SUPPORT,
		sessionForm: PATH_HUB_CR_SKIP_SESSION,
	},
	{
		label: 'Skip consecutive sessions (Pause)',
		ciClass: 'ci--pause',
		caseCategory: SF_CASE_CATEGORY.PAUSE_SESSIONS,
		caseType: SF_CASE_TYPE.SCHEDULING,
		recordType: SF_RECORD_TYPE.SUPPORT,
		enrolmentForm: PATH_HUB_CR_PAUSE_SESSIONS,
	},
	{
		label: 'Permanent schedule change',
		ciClass: 'ci--calendar-3',
		caseCategory: SF_CASE_CATEGORY.PERMANENT_SCHEDULE_CHANGE,
		caseType: SF_CASE_TYPE.SCHEDULING,
		recordType: SF_RECORD_TYPE.SUPPORT,
		changeSchedule: PATH_HUB_ENROLMENT_CHANGE_SCHEDULE,
	},
	{
		label: 'Other',
		ciClass: 'ci--chat',
		caseCategory: SF_CASE_CATEGORY.OTHER,
		caseType: SF_CASE_TYPE.FEEDBACK,
		recordType: SF_RECORD_TYPE.SUPPORT,
		enrolmentForm: PATH_HUB_CR_OTHER_ENROLMENT_CHANGE,
	},
];

export const SPECIFIC_CONTACT_OPTIONS_ACTIONS = {
	SKIP_SESSION: 'SKIP_SESSION',
};

export const SPECIFIC_CONTACT_OPTIONS_RSP = [
	{
		label: 'Skip this session',
		ciClass: 'ci--calendar-1',
		action: SPECIFIC_CONTACT_OPTIONS_ACTIONS.SKIP_SESSION,
		caseCategory: SF_CASE_CATEGORY.SKIP_CANCEL_SESSION,
		caseType: SF_CASE_TYPE.SCHEDULING,
		recordType: SF_RECORD_TYPE.SUPPORT,
	},
	{
		label: 'Skip consecutive sessions (Pause)',
		ciClass: 'ci--pause',
		caseCategory: SF_CASE_CATEGORY.PAUSE_SESSIONS,
		caseType: SF_CASE_TYPE.SCHEDULING,
		recordType: SF_RECORD_TYPE.SUPPORT,
		enrolmentForm: PATH_HUB_CR_PAUSE_SESSIONS,
	},
	{
		label: 'Permanent schedule change',
		ciClass: 'ci--calendar-3',
		caseCategory: SF_CASE_CATEGORY.PERMANENT_SCHEDULE_CHANGE,
		caseType: SF_CASE_TYPE.SCHEDULING,
		recordType: SF_RECORD_TYPE.SUPPORT,
		changeSchedule: PATH_HUB_ENROLMENT_CHANGE_SCHEDULE,
	},
	{
		label: 'Other',
		ciClass: 'ci--chat',
		caseCategory: SF_CASE_CATEGORY.OTHER,
		caseType: SF_CASE_TYPE.FEEDBACK,
		recordType: SF_RECORD_TYPE.SUPPORT,
		enrolmentForm: PATH_HUB_CR_OTHER_ENROLMENT_CHANGE,
	},
];

export const TODAY_SESSION_CONSTANTS = {
	STATUS_INIT: '',
	STATUS_SOON: 'Starting soon',
	STATUS_IN_PROGRESS: 'In progress',
};

export const RESCHEDULE_PAGE_EXCEPTION_TYPES = {
	NO_EXCEPTION: 'NO_EXCEPTION',
	NO_TUTOR_AVAILABILITY: 'NO_TUTOR_AVAILABILITY',
	TIME_ZONE_CHANGE: 'TIME_ZONE_CHANGE',
	SESSION_TIMEOUT: 'SESSION_TIMEOUT',
	RESCHEDULING_NOT_ALLOWED: 'RESCHEDULING_NOT_ALLOWED',
	INVALID_ENROLMENT_ID: 'INVALID_ENROLMENT_ID',
	FINISHED_OR_CANCELLED_ENROLMENT: 'FINISHED_OR_CANCELLED_ENROLMENT',
	CONSECUTIVE_SESSIONS_IN_THE_NEXT_48_HOURS: 'CONSECUTIVE_SESSIONS_IN_THE_NEXT_48_HOURS',
	ALREADY_RESCHEDULED_ONCE: 'ALREADY_RESCHEDULED_ONCE',
	SYSTEM_NOT_AVAILABLE: 'SYSTEM_NOT_AVAILABLE',
};

export const RESCHEDULE_PAGE_REQUEST_TYPES = {
	RESCHEDULE: 'Reschedule',
	CANCEL: 'Cancel',
};

export const RESCHEDULE_PAGE_STATUS_CODES = {
	C00: 'C00',
	C01: 'C01',
	C02: 'C02',
	C03: 'C03',
	C04: 'C04',
	C05: 'C05',
	C06: 'C06',
	R00: 'R00',
	R01: 'R01',
	R02: 'R02',
	R03: 'R03',
	R04: 'R04',
	R05: 'R05',
	R06: 'R06',
	E01: 'E01',
	E99: 'E99',
};

export const REFUND_TYPES = {
	NO_POLICY: 'NO_POLICY',
	FULL_REFUND: 'FULL_REFUND',
	PARTIAL_REFUND: 'PARTIAL_REFUND',
	NO_REFUND_NO_RESCHEDULE: 'NO_REFUND_NO_RESCHEDULE',
	NO_REFUND_YES_RESCHEDULE: 'NO_REFUND_YES_RESCHEDULE',
	NO_REFUND_RESCHEDULED_ONCE: 'NO_REFUND_RESCHEDULED_ONCE',
};

export const AWS_COGNITO_RESPONSE = {
	OK: 'ok',
	RESET_PASSWORD_REQUIRED: 'reset-password-required',
	NOT_AUTHORIZED_EXCEPTION: 'NotAuthorizedException',
	EXPIRED_CODE_EXCEPTION: 'ExpiredCodeException',
	CODE_MISMATCH_EXCEPTION: 'CodeMismatchException',
	TEMP_PASSWORD_EXPIRED: 'TEMP_PASSWORD_EXPIRED',
};

export const EXPIRED_CODE_EXCEPTION_MSG = 'This password reset link has already been used.';

export const ACCOUNT_TYPE = {
	ACCOUNT: 'Account',
	LEAD: 'Lead',
} as const;

export const AUTHENTICATION_STATUS = {
	INIT: 'INIT',
	AUTHENTICATED: 'AUTHENTICATED',
	UN_AUTHENTICATED: 'UN_AUTHENTICATED',
	AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR',
} as const;

export const EMAIL_VALIDATION_RESULT = {
	INIT: 'INIT',
	VALID: 'VALID',
	INVALID: 'INVALID',
};

export const PASSWORD_VALIDATION_RESULT = {
	INIT: 'INIT',
	VALID: 'VALID',
	INVALID: 'INVALID',
	MATCH: 'MATCH',
	NOT_MATCH: 'NOT_MATCH',
};

export const ENROLMENT_SELECTION_MODAL_TITLE = 'Please select the enrolment your request relates to';
export const STUDENT_SELECTION_MODAL_TITLE = 'Who is the new enrolment for?';

export const UNIQUE_ENROLMENT_ID_FOR_NEW_STUDENT = 'UNIQUE_ENROLMENT_ID_FOR_NEW_STUDENT';

export const LOCALSTORAGE_KEEP_ME_LOGGED_IN = 'ClueyHubKeepMeLoggedIn';
export const LOCALSTORAGE_SHOW_NEW_HUB_COMMS_BANNER = 'show-new-hub-comms-banner';
export const LOCALSTORAGE_VERSION_NEW_HUB_COMMS_BANNER = 'version-new-hub-comms-banner';

export const TEMP_PASSWORD_EXPIRED_ERROR = 'Temporary password has expired and must be reset by an administrator.';

export const CARD_TYPES = {
	VISA: 'VisaCard',
	MASTER: 'MasterCard',
	AMEX: 'AMEXCard',
};

export const SPECIAL_DATE_TYPE = {
	PUBLIC_HOLIDAY: 'public holiday',
	CLUEY_HOLIDAY: 'cluey holiday',
	SCHOOL_TERM: 'school term',
	SUMMER_ALERT: 'summer alert',
};

export const AU_SCHOOL_TERMS = {
	term1: 'term 1',
	term2: 'term 2',
	term3: 'term 3',
	term4: 'term 4',
};

export const SESSION_AVAILABLE_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const SESSION_AVAILABLE_TIMES = [
	'6:00',
	'6:30',
	'7:00',
	'7:30',
	'8:00',
	'8:30',
	'9:00',
	'9:30',
	'10:00',
	'10:30',
	'11:00',
	'11:30',
	'12:00',
	'12:30',
	'13:00',
	'13:30',
	'14:00',
	'14:30',
	'15:00',
	'15:30',
	'16:00',
	'16:30',
	'17:00',
	'17:30',
	'18:00',
	'18:30',
	'19:00',
	'19:30',
	'20:00',
	'20:30',
	'21:00',
	'21:30',
	'22:00',
];

export const ENROLMENT_STATUSES = {
	ACTIVE: 'active',
	CANCELLED: 'cancelled',
	CANCELLED_REQUESTED: 'cancel requested',
	PAUSED: 'paused',
	PENDING: 'pending',
	FINISHED: 'finished',
	NEW_CUSTOMER: 'new customer',
	WILL_BE_PAUSED: 'will be paused', // NOTE, this is a status just used in FE.
};

export const ENROLMENT_MANAGEMENT_STEPS = {
	DEFAULT: 'DEFAULT',
	KEEP_EXISTING_SCHEDULE: 'KEEP_EXISTING_SCHEDULE',
	CONFIRMATION: 'CONFIRMATION',
	CONFIRMED: 'CONFIRMED',
	ERROR: 'ERROR',
};

export const CHANGE_SCHEDULE_STEPS = {
	DEFAULT: 'DEFAULT',
	A_CHOOSE: 'A_CHOOSE',
	A_CONFIRMATION: 'A_CONFIRMATION',
	A_CONFIRMED: 'A_CONFIRMED',
	B_CHOOSE: 'B_CHOOSE',
	B_CONFIRMATION: 'B_CONFIRMATION',
	B_CONFIRMED: 'B_CONFIRMED',
	ERROR: 'ERROR',
};

// This is decided by api response.
export const SCHEDULE_DATE_FORMAT = 'ddd HH:mm';
export const TUTOR_AVAILABILITY_FORMAT = 'YYYY-MM-DD HH:mm';
// This is decided by product needs.
export const RESUME_SESSION_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const SESSION_TIME_FORMAT = 'h:mma';
export const HUB_SESSION_DATE_FORMAT = 'dddd, MMM D';
export const DATE_FORMAT_1 = 'dddd D MMMM'; // Will replace all appearance later
export const PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT = 'YYYY-MM-DD';
export const SF_CASES_DESCRIPTION_DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_FORMAT_TYPE_1 = 'dddd, MMMM DD'; // Tuesday, April 13
export const DATE_FORMAT_TYPE_2 = 'MMMM DD, YYYY'; // Tuesday 13, 2021
export const DATE_FORMAT_TYPE_3 = 'dddd, MMMM D'; // Sunday, September 5
export const DATE_FORMAT_TYPE_4 = 'dddd, MMM D'; // Sunday, Sep 5
export const DATE_FORMAT_TYPE_5 = 'ddd, MMM D'; // Sat, Nov 26
export const DATE_FORMAT_TYPE_6 = 'MMM D'; // Nov, 6
export const DATE_FORMAT_TYPE_7 = 'dddd D MMM, YYYY'; // Friday 9 Jul, 2023
export const DATE_FORMAT_TYPE_8 = 'dddd, D MMMM'; // Friday, 7 July
export const DATE_FORMAT_TYPE_9 = 'YYYY-MM-DD HH:mm:ss'; // 2023-03-11 12:59:59
export const DATE_FORMAT_TYPE_10 = 'D MMMM YYYY'; /* 10 May 2023 */
export const DATE_FORMAT_TYPE_11 = 'ddd D MMM'; /* Sat 10 May */
export const DATE_FORMAT_TYPE_12 = 'dddd D MMMM'; /* Saturday 10 May */
export const DATE_FORMAT_TYPE_13 = 'ddd, D MMM YYYY';
export const DATE_FORMAT_TYPE_14 = 'D MMMM YY'; /* 10 May 23 */
export const PAUSED_ENROLMENT_RESUME_DATE_FORMAT = 'dddd D MMMM, YYYY'; // Thursday 1 July, 2021
export const CHANGE_GROUP_CONFIRM_FORMAT = 'dddd, MMMM D';
export const DATE_FORMAT_YEAR = 'YYYY';

// Student Progress Tabbed view
export const STUDENT_PROGRESS_URL_PARAM_TAB = 'activeTab';
export const STUDENT_PROGRESS_TAB_VIEWS = {
	PROGRESS_OVERVIEW: 'progress-overview',
	SESSION_REPORT: 'session-reports',
	ASSESSMENTS: 'assessments',
};

export const HOC_PAGE_PARAM = {
	DEFAULT: 'DEFAULT',
	CHANGE_SCHEDULE: 'CHANGE_SCHEDULE',
	PERMANENT_SCHEDULE_CHANGE: 'PERMANENT_SCHEDULE_CHANGE',
	CANCEL_ENROLMENT: 'CANCEL_ENROLMENT',
	CHANGE_GROUP_SCHEDULE: 'CHANGE_GROUP_SCHEDULE',
	ENROLMENT_DETAIL: 'ENROLMENT_DETAIL',
	SUMMER_PAUSE: 'SUMMER_PAUSE',
	SUMMER_PAUSE_FOR_CANCEL: 'SUMMER_PAUSE_FOR_CANCEL',
} as const;

export { PAUSE_SESSIONS_BY_FREQUENCY, PAUSE_SESSIONS_UNITS } from './constants/pauseSessionsConsts';
export { ENROLMENT_STATUS_TEXT } from './constants/enrolmentConstants';

export const COPYRIGHT = `Copyright ${new Date().getFullYear()}, Cluey Learning`;
export const COOKIE_SAVE_ENROLMENT = 'save_enrolments';
export const COUNTRY = {
	AUSTRALIA: 'au',
	NEW_ZEALAND: 'nz',
};

export const PRICE_CHANGE_VIEWED_TOKEN = 'PRICE_CHANGE_VIEWED_TOKEN';
//NOTE, this is the tutor customer number for placeholder tutor.
//Since we always copy SF uat from SF prod, this customer number will be the same in uat and prod
export const TTBM_CUSTOMER_NUMBER = 'T12905144';

export const WEBSITE_DOMAIN = {
	AU_DOMAIN: 'https://clueylearning.com.au',
	NZ_DOMAIN: 'https://clueylearning.co.nz',
};

export const MOBILE_NUMBER_DUPLICATE_ERROR_MSG =
	'This number is already in use with a different account. Please sign in with that account or add a different phone number.';
