import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

const SectionTitle = ({ title }) => {
  if (isEmpty(title)) {
    return null;
  }

  return (
    <h2 className="mb-2 font-display text-xl font-bold lg:text-2xl">
      {title}
    </h2>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SectionTitle;
