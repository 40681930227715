import moment from "moment";
import { sortBy } from 'lodash';
import { getUpcomingSessions } from './hubSelector';
import type { Session } from "../types/hubInterfaces";
import { PAYMENT_STATUS } from "../types/hubEnums";
/* 
  used to determine whether a one-off session is eligible for a discount;

  a one-off session is considered eligible if either the session before or after
  it has a discount regardless of the discount status

  a discount can be attached to a session, but will have a percentage of 0;
  this is the case when ops adds a discount that has "No Discount" in its name

  the discount will be administered by ops through a Sales Force case originating from
  MuleSoft
*/
export const getOneOffSessionDiscountEligibility =
  ({ enrolmentId, date }) => 
  (state) => {
    const sessions = getUpcomingSessions(state);

    const filterPredicate = (session) => session.uniqueEnrolmentId === enrolmentId;
    const completedSessions = state.hubSessionsPage?.completedSessions ?? [];
    const filteredCompleted = completedSessions.filter(filterPredicate);

    const sortedSesions: Array<Session> = sortBy(
      [
        ...sessions.filter(filterPredicate),
        ...filteredCompleted,
        {startTime: date}
      ],
      (session) => moment(session.startTime).unix()
    );

    const selectedDateIndex = sortedSesions.findIndex(sess => 
      sess.startTime === date && sess.uniqueEnrolmentId === undefined
    );
    const previousSession = sortedSesions[selectedDateIndex - 1];
    const nextSession = sortedSesions[selectedDateIndex + 1];

    return Number(previousSession?.sessionDiscountPercentage) > 0 || Number(nextSession?.sessionDiscountPercentage) > 0;
  }

export const getSession = ({ studentSessionId }) => 
  (state) => {
    const sessions: Array<Session> = [...getUpcomingSessions(state), ...state.hubSessionsPage?.completedSessions ?? []];
    return sessions.find(sess => sess.studentSessionId === studentSessionId);
  }

export const getSessionsOnDateAndBeyond = 
  ({ enrolmentId, date, timezone, additionalSessions = [] }: {
    enrolmentId: string;
    date: string;
    timezone: string;
    additionalSessions?: Array<Session>;
  }) => 
  (state) => {
    if (!enrolmentId || !date) {
      return [];
    }
    const allSessions: Array<Session> = [...additionalSessions, ...getUpcomingSessions(state)];
    const filteredSessions = allSessions.filter(sess => {
      return sess.uniqueEnrolmentId === enrolmentId 
        && moment(sess.startTime).tz(timezone).isSameOrAfter(date, 'day')
        && sess.paymentStatus === PAYMENT_STATUS.PAID;
    });
    return sortBy(filteredSessions, (sess) => moment(sess.startTime).unix());
  }
