import { RequestPage } from "./RequestPage";
import { ResetEmailSent } from "./ResetEmailSent";
import { FormFlow } from '../../../../components/FormFlow/FormFlow';

function ResetPasswordRequestPage() {
  const formElements = {
    '': RequestPage,
    '/reset-email-sent': ResetEmailSent,
  }

  return <FormFlow elements={formElements} />
}

export default ResetPasswordRequestPage;