import { useQuery } from '@tanstack/react-query';
import {
	GET_AVAILABLE_SUBJECTS_QKEY,
	GET_ENROLMENT_PREVIOUS_TUTOR_QKEY,
	GET_PROGRAM_DETAIL_QKEY,
	GET_PROGRAM_OVERVIEW_QKEY,
	GET_CANCELLATION_REASONS,
	GET_RESUME_DATE,
} from '../../util/constants/queryKeys';
import { EnrolmentService } from '../../services/PortalAPIServices/EnrolmentService';
import { CancelService } from '../../services/CancelService';
import { api } from '../../api';

// eslint-disable-next-line valid-jsdoc
/**
 * @deprecated This function has been deprecated in favor of api/services/enrolment.all
 *
 */
// note: active enrolments include paused enrolments (status === 'Paused')
export const useEnrolmentsQuery = (filter: Parameters<typeof api.enrolment.all.useQuery>[0]) => {
	return api.enrolment.all.useQuery(filter);
};

// eslint-disable-next-line valid-jsdoc
/**
 * @deprecated This function has been deprecated in favor of api/services/enrolment.detail
 *
 */
export const useEnrolmentDetailQuery = ({ enrolmentId }) => {
	return api.enrolment.detail.useQuery(
		{ enrolmentId },
		{
			enabled: !!enrolmentId,
		}
	);
};

export const useGetCancellationReasons = () => {
	return useQuery({
		queryKey: [GET_CANCELLATION_REASONS],
		queryFn: () => CancelService.getCancellationReasons(),
		refetchOnWindowFocus: false,
	});
};

/* The resume date from this api is in the NSW timezone; it could either be AEST or AEDT depending on the current season */
export const useGetResumeDate = ({ enrolmentId, pauseToDate }: {
  enrolmentId: string;
  pauseToDate: string;
}) => {
  return useQuery({
    queryKey: [GET_RESUME_DATE, enrolmentId, pauseToDate],
    queryFn: () => EnrolmentService.getResumeDate({ enrolmentId, pauseToDate }),
    enabled: !!enrolmentId && !!pauseToDate,
    refetchOnWindowFocus: false
  })
}

export const useAvailableSubjectsQuery = ({ year, country = 'au' }) => {
	return useQuery({
		queryKey: [GET_AVAILABLE_SUBJECTS_QKEY, year, country],
		queryFn: () => EnrolmentService.getAvailableSubjects({ year, country }),
		staleTime: 60 * 60 * 1000,
	});
};

export const useProgramOverviewQuery = ({ studentCustomerNumber, subject }) => {
	return useQuery({
		queryKey: [GET_PROGRAM_OVERVIEW_QKEY, studentCustomerNumber, subject],
		queryFn: () => EnrolmentService.getProgramOverview({ studentCustomerNumber, subject }),
		staleTime: 60 * 60 * 1000,
	});
};

export const useProgramDetailQuery = ({ year, country = 'au', subject, courseName, curriculum }) => {
	return useQuery({
		queryKey: [GET_PROGRAM_DETAIL_QKEY, year, country, subject, courseName, curriculum],
		queryFn: () => EnrolmentService.getProgramDetail({ year, country, subject, courseName, curriculum }),
		staleTime: 60 * 60 * 1000,
	});
};

export const usePreviousTutorQuery = ({ enrolmentId, hasPackageTutorChange }) => {
	return useQuery({
		queryKey: [GET_ENROLMENT_PREVIOUS_TUTOR_QKEY, enrolmentId],
		queryFn: () => EnrolmentService.getPreviousTutor({ enrolmentId, hasPackageTutorChange }),
		staleTime: 60 * 60 * 1000,
	});
};
