import { IconProps as Props } from "../types/hubInterfaces";
import { iconDefaults } from ".";
import classNames from 'classnames';

function CheckCircleIcon(props: Props) {
  const { width, height, className } = { ...iconDefaults, ...props };
  return (
    <svg className={classNames('fill-current', className)} xmlns="http://www.w3.org/2000/svg" width={`${width}`} height={`${height}`} viewBox="0 0 60 60">
      <title>check circle icon</title>
      <path fillRule="evenodd" clipRule="evenodd" d="M42.885 22.815L29.181 40.815C28.617 41.556 27.744 41.994 26.814 42H26.793C25.872 42 25.002 41.574 24.432 40.848L17.136 31.527C16.116 30.225 16.344 28.338 17.649 27.318C18.951 26.295 20.841 26.523 21.861 27.831L26.76 34.089L38.112 19.182C39.114 17.865 40.995 17.607 42.318 18.612C43.635 19.617 43.89 21.498 42.885 22.815ZM0 30C0 46.566 13.431 60 30 60C46.569 60 60 46.566 60 30C60 13.431 46.569 0 30 0C13.431 0 0 13.431 0 30Z" />
    </svg>
  )
}

export { CheckCircleIcon };


