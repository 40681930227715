import React from 'react';
import { useLocation } from 'react-router-dom';

import PageHead from '../PageHead';
import { HeadNavLink } from '../../headNavLink/headNavLink';
import {
  PATH_HUB_UPCOMING_SESSIONS,
  PATH_HUB_COMPLETED_SESSIONS,
  PATH_HUB_CANCELLED_SESSIONS
} from '../../../util/pagePath';

const SessionsPageContentHeadNav = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <PageHead title="Sessions" wrap>
      <div className="flex">
        <HeadNavLink
          to={PATH_HUB_UPCOMING_SESSIONS}
          name="Upcoming"
          isActive={currentPath === PATH_HUB_UPCOMING_SESSIONS}
        />
        <HeadNavLink
          to={PATH_HUB_COMPLETED_SESSIONS}
          name="Completed"
          isActive={currentPath === PATH_HUB_COMPLETED_SESSIONS}
        />
        <HeadNavLink
          to={PATH_HUB_CANCELLED_SESSIONS}
          name="Cancelled"
          isActive={currentPath === PATH_HUB_CANCELLED_SESSIONS}
        />
      </div>
    </PageHead>
  );
};

export default SessionsPageContentHeadNav;
