import { Container } from '@cluey/cluey-components';
import { Button, Link } from '@cluey/cluey-components';
import { PATH_HUB_STUDENT_PROFILE } from '../../../../util/pagePath';
import { useParams, generatePath, useHistory } from 'react-router';
import HubHeader from '../../../../common/hub/HubHeader';
import { SkeletonLoader } from '../../../../components/SkeletonLoader';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { StudentInformationForm } from '../../../../components/hub/student/StudentInformationForm';
import { api } from '../../../../api';
import isEmpty from 'lodash/isEmpty';
import { StudentProfileData } from '../StudentProfile/StudentProfile';
import type { UpdateStudentParams } from '../../../../api/services/student';
import { InlineLoader } from '../../auth2/common';
import HubFooter from '../../../../common/hub/HubFooter/HubFooter';
import { convertHubToSfLearningNeeds, parseLearningNeeds } from '../../../../util/learning-needs';
import withHeadTag from '../../../../common/HeadComponent';

const EditStudentProfile = () => {
	const { studentSfid } = useParams<{ studentSfid: string }>();
	const { data: students, isLoading: loadingStudents, isFetching: fetchingStudents } = api.student.all.useQuery();
	const { data: accountDetails, isLoading: loadingAccountDetails } = api.account.details.useQuery();
	const { mutate: updateStudent, isLoading: updatingStudent } = api.student.update.useMutation();
	const student = students?.find((student) => student.studentId === studentSfid);
	const profilePath = generatePath(PATH_HUB_STUDENT_PROFILE, { studentSfid });
	const kiwi = !!student ? student?.studentCountry !== 'Australia' : accountDetails?.country === 'NZ';
	const defaultLocation = !!student ? student.location || undefined : kiwi ? 'NZ' : undefined;
	const history = useHistory();

	const formUtils = useForm<StudentProfileData>({
		resolver: zodResolver(StudentProfileData),
		values: {
			location: defaultLocation,
			firstName: student?.firstName,
			lastName: student?.lastName,
			yearLevel: student?.yearLevel,
			specialLearningNeeds: parseLearningNeeds(student?.learningNeeds || ''),
		},
	});

	const onSubmit = formUtils.handleSubmit(async (data: StudentProfileData) => {
		formUtils.clearErrors('root');
		const { firstName, lastName, yearLevel, schoolName, specialLearningNeeds, location } = data;
		const params: UpdateStudentParams = {
			customerType: accountDetails?.userType,
			customerNumber: student?.customerNumber,
			firstname: firstName,
			lastname: lastName,
			curriculum: location,
			yearLevel,
			schoolId: schoolName,
			specialLearningNeeds: !isEmpty(specialLearningNeeds)
				? convertHubToSfLearningNeeds(specialLearningNeeds).join(';')
				: ' ',
		};

		updateStudent(params, {
			onSuccess: async () => {
				await api.student.all.invalidateQueries();
				const profilePath = generatePath(PATH_HUB_STUDENT_PROFILE, { studentSfid });
				history.push(profilePath);
			},
			onError: (error) => {
				formUtils.setError('root', {
					message: `There was a problem updating your profile. Please try again. ${error}`,
				});
			},
		});
	});

	return (
		<div>
			<HubHeader title="Hub" />
			<Container className="px-4 py-7 md:py-8 lg:mb-0">
				<div className="flex flex-col gap-8">
					<div className="">
						<SkeletonLoader className="h-5 w-9" loading={loadingStudents}>
							<Link className="text-xs font-bold uppercase tracking-[1px]" to={profilePath}>
								Student Profile
							</Link>
						</SkeletonLoader>
						<SkeletonLoader className="mt-2 h-10 w-72" loading={loadingStudents}>
							<h1 className="font-display text-[28px] font-bold">
								{student?.firstName} {student?.lastName}
							</h1>
						</SkeletonLoader>
					</div>
					<form onSubmit={onSubmit} className="flex flex-col gap-10">
						<div className="rounded border border-grey-2 px-20 py-16 shadow-md">
							<StudentInformationForm
								formUtils={formUtils}
								loading={loadingStudents || loadingAccountDetails}
								student={student}
								title
							/>
						</div>
						{formUtils.formState.errors?.root && (
							<p className="mt-1 text-xs text-red-5">{formUtils.formState.errors?.root.message}</p>
						)}
						<SkeletonLoader className="mt-8 h-[46px] w-64" loading={loadingStudents || loadingAccountDetails}>
							<Button
								disabled={updatingStudent || fetchingStudents}
								type="submit"
								size="lg"
								className="mt-8 flex w-full items-center justify-center md:w-[355px]"
							>
								{updatingStudent || fetchingStudents ? (
									<InlineLoader />
								) : (
									<span>Save and go back to student profile</span>
								)}
							</Button>
						</SkeletonLoader>
						<SkeletonLoader className="mt-8 h-4 w-32" loading={loadingStudents || loadingAccountDetails}>
							{!updatingStudent && !fetchingStudents && (
								<Link className="block" to={profilePath} size="lg">
									Cancel
								</Link>
							)}
						</SkeletonLoader>
					</form>
				</div>
			</Container>
			<HubFooter />
		</div>
	);
};

const EditStudentProfileWitHeadTag = withHeadTag(EditStudentProfile);

export { EditStudentProfileWitHeadTag as EditStudentProfile };
