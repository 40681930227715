import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClueyLogo from '../../assets/images/cluey-logo.svg';

import mailIcon from '../../assets/images/icon-mail--large.svg';
import { scrollToTop } from '../../util/helpers';
import { resetAuthUIState } from '../../actions/hub/hubAuthPagesActions';

const AuthPageLayout = ({
  title,
  subtitle,
  imgIcon,
  imgIconAlt,
  imgIconWrapClass,
  children,
  resetAuthUIStates,
}) => {
  useEffect(() => {
    scrollToTop();
    resetAuthUIStates();
  }, [resetAuthUIStates]);

  return (
    <div className="w-full h-full border-t-4 border-secondary hub">
      <div className="flex h-full m-auto md:p-10 lg:p-0 auth">
        <div className="hidden lg:block relative w-full lg:w-1/2 pt-10 bg-slate-1 auth__left">
          <div className={`icon-wrap ${imgIconWrapClass}`}>
            <img src={imgIcon} className="icon-mail" alt={imgIconAlt} />
          </div>
        </div>
        <div className="w-full md:w-2/3 md:ml-1/6 lg:w-1/2 lg:ml-0 px-4 md:px-0 lg:px-10 pt-10 pt-xl-0 auth__right">
          <div className="container mx-auto h-full p-0 relative">
            <div className="w-full content">
              <header className="w-full mb-10">
                <img src={ClueyLogo} alt="Cluey Logo" />
              </header>
              <div className="w-full m-auto">
                <h1 className="font-body text-2xl font-bold mb-4">{ title }</h1>
                <p className="text-base mb-3">{ subtitle }</p>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AuthPageLayout.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  imgIcon: PropTypes.node,
  imgIconAlt: PropTypes.string,
  imgIconWrapClass: PropTypes.string,
  children: PropTypes.node.isRequired,
  resetAuthUIStates: PropTypes.func.isRequired,
};

AuthPageLayout.defaultProps = {
  imgIcon: mailIcon,
  imgIconAlt: 'icon mail',
  imgIconWrapClass: 'icon-wrap--salomie',
};

export default connect(
  null,
  (dispatch) => {
    return {
      resetAuthUIStates: bindActionCreators(resetAuthUIState, dispatch),
    };
  },
)(AuthPageLayout);
