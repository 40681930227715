import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export class FeedbackBox extends React.Component {
  render() {
    const {
      title,
      subTitle,
      icon,
    } = this.props;
    return (
      <div className="md:mr-3 bg-light flex mt-4 items-center feedback-box">
        <div className="circle bg-danger rounded-full ml-2">
          <i className={classnames('icon', icon, 'text-white')} />
        </div>
        <div className="flex flex-col pl-3">
          <div className="text-label">{title}</div>
          <div>{subTitle}</div>
        </div>
      </div>
    );
  }
}

FeedbackBox.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  icon: PropTypes.string,
};

FeedbackBox.defaultProps = {
  title: null,
  subTitle: null,
  icon: null,
};
