import { SkeletonLoader } from '../../../SkeletonLoader';
import range from 'lodash/range';

const StudentInformationLoader = () => {
	return (
		<div className="mt-10 flex h-32 max-w-md flex-col gap-5">
			{range(5).map((index) => (
				<SkeletonLoader key={`student-info-field-loader-${index}`} className="h-[66px] w-full" loading={true} />
			))}
		</div>
	);
};

export { StudentInformationLoader };
