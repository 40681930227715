import {
  isPaymentFailed,
  isPaymentSucceed,
  isPaymentPending as isPaymentPendingCheck
} from '../../../../../util/helpers';
import type { Session } from "../../../../../types/hubInterfaces"
export const useGetSessionPaymentDetails = ({
  paymentStatus
}: Session) => {
  return {
    isPaymentError: isPaymentFailed(paymentStatus),
    isSessionPaid: isPaymentSucceed(paymentStatus),
    isPaymentPending: isPaymentPendingCheck(paymentStatus)
  }
}