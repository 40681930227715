import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import { ButtonAsLink } from '@cluey/cluey-components';

import { requestToResetPassword } from '../../../actions/hub/hubAuthPagesActions';
import { PATH_HUB_RESET_PASSWORD_REQ_SENT } from '../../../util/pagePath';

const ResetPasswordResetLink = ({
  btnClasses,
  btnText,
  email,
  sendResetPasswordRequest,
}) => {
  const history = useHistory();

  const onResendRequest = () => {
    sendResetPasswordRequest({
      email,
      onSuccess: () => {
        history.push(PATH_HUB_RESET_PASSWORD_REQ_SENT);
      },
    });
  };

  return (
    <ButtonAsLink
      size="xs"
      className={btnClasses}
      onClick={onResendRequest}
    >
      {btnText}
    </ButtonAsLink>
  );
};

ResetPasswordResetLink.propTypes = {
  btnClasses: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  sendResetPasswordRequest: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    return {
      email: state.hubUser.email,
    };
  },
  (dispatch) => {
    return {
      sendResetPasswordRequest: bindActionCreators(requestToResetPassword, dispatch),
    };
  },
)(ResetPasswordResetLink);
