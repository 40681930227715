import { Student } from '../../../../../../api/types/student';
import { GenerateAssessmentHandler, StudentAssessmentsEligibilities } from '../types';
import EligibleAssessmentCards from './EligibleAssessmentCards';
import OngoingAssessmentCards from './OngoingAssessmentCards';

export interface StudentAssessmentCardProps {
	handleGenerateAssessment: GenerateAssessmentHandler;
	data?: StudentAssessmentsEligibilities;
	showStudentName?: boolean;
}

export type StudentWithAssessmentEligibilities = Student & { assessmentEligibilities: string[] };

export const StudentAssessmentCardsBase = ({
	handleGenerateAssessment,
	data,
	showStudentName = true,
}: StudentAssessmentCardProps) => {
	const { englishAssessment, mathematicsAssessment, assessmentEligibilities } = data || {};
	if (data?.shouldShow) {
		return (
			<div className="mb-4">
				{showStudentName && <div className="mb-3 text-lg font-bold">{data?.student?.firstName}</div>}
				<div className="flex flex-col items-center space-y-4">
					<OngoingAssessmentCards assessment={englishAssessment} studentAvailableSubjects={data?.student?.availableSubjects || []} />
					<OngoingAssessmentCards assessment={mathematicsAssessment} studentAvailableSubjects={data?.student?.availableSubjects || []} />
					<EligibleAssessmentCards
						handleGenerateAssessment={handleGenerateAssessment}
						student={data?.student}
						studentAssessmentEligibilities={assessmentEligibilities}
					/>
				</div>
			</div>
		);
	} else {
		return null;
	}
};

type StudentAssessmentCardsProps = Omit<
	StudentAssessmentCardProps,
	'studentAssessmentEligibilities' | 'assessments' | 'englishAssessment' | 'mathematicsAssessment'
>;

const StudentAssessmentCards = ({
	handleGenerateAssessment,
	data,
	showStudentName = true,
}: StudentAssessmentCardsProps) => {
	return (
		<StudentAssessmentCardsBase
			handleGenerateAssessment={handleGenerateAssessment}
			data={data}
			showStudentName={showStudentName}
		/>
	);
};

export default StudentAssessmentCards;
