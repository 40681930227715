import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Anchor } from '@cluey/cluey-components';
import env from '../../env';
import { getSummerDiscount, showSummerModeBannerOrPromo } from '../../selectors/summerModeSelector';
import { TopBannerLayout } from '../TopBannerLayout';

export const LinkToWizard = ({ wizardToken }) => {
  return (
    <Anchor
      size="base"
      href={`https://${env.REACT_APP_API_SUMMER_MODE_HOST_NAME}${wizardToken}`}
      className="text-center"
    >
      Manage schedule options, finish or pause
    </Anchor>
  );
};

LinkToWizard.propTypes = {
  wizardToken: PropTypes.string.isRequired,
};

export const TopBanner = ({ discount, wizardToken }) => {
  const discountComponent = discount ? (
    <div className="lg:flex lg:items-center">
      <div className="mb-2 mr-3 inline-block rounded-lg bg-red-4 px-3 py-2 text-xl font-semibold leading-tight text-white lg:mb-0">
        Save {discount}
      </div>
      <p className="m-0 font-display text-xl font-semibold text-grey-6">with summer holiday learning</p>
    </div>
  ) : (
    <p className="m-0 font-display text-xl font-semibold text-grey-6">
      Continue or take a break over the summer holidays
    </p>
  );

  return <TopBannerLayout left={discountComponent} right={<LinkToWizard wizardToken={wizardToken} />} />;
};

TopBanner.propTypes = {
  discount: PropTypes.string,
  wizardToken: PropTypes.string.isRequired,
};

TopBanner.defaultProps = {
  discount: '',
};

const ConnectedTopBannerComponent = ({ discount, wizardToken, show }) => {
  return show && wizardToken && <TopBanner discount={discount} wizardToken={wizardToken} />;
};

ConnectedTopBannerComponent.propTypes = {
  discount: PropTypes.string,
  wizardToken: PropTypes.string,
  show: PropTypes.bool,
};

ConnectedTopBannerComponent.defaultProps = {
  discount: '',
  wizardToken: '',
  show: false,
};

export const ConnectedTopBanner = connect((state) => {
  const { summerMode } = state;
  return {
    wizardToken: summerMode.wizardToken,
    discount: getSummerDiscount(state),
    show: showSummerModeBannerOrPromo(state),
  };
})(ConnectedTopBannerComponent);
