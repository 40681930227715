import { z } from 'zod';
import { GetSessionsFilter } from '../../types/hubTypes';
import { createApi } from '../core';
import { Session } from '../types/session';

const CreateMessageRequestPayload = z.object({
	category: z.string(),
	message: z.string(),
	uniqueEnrolmentId: z.string(),
	sessionId: z.string(),
});
export type CreateMessageRequestPayload = z.infer<typeof CreateMessageRequestPayload>;

const CreateMessageResponse = z.object({
	messageId: z.number(),
	category: z.string(),
	message: z.string(),
	uniqueEnrolmentId: z.string(),
	sessionId: z.string().nullable(),
});

export const sessionApi = createApi.group(['session'], (createKey) => {
	return {
		all: createApi.query({
			queryKey: createKey(['all']),
			queryFn: (filter: GetSessionsFilter) => {
				return createApi.fetch({
					path: ['sessions'],
					method: 'GET',
					query: {
						filter,
					},
					sign: true,
					validator: Session.array(),
					basePath: 'portalAPI',
				});
			},
			staleTime: createApi.time(1, 'minutes'),
			refetchInterval: createApi.time(5, 'minutes'),
		}),
		createMessage: createApi.mutation({
			mutationFn: (params: CreateMessageRequestPayload) => {
				try {
					return createApi.fetch({
						path: ['session-message'],
						method: 'POST',
						body: params,
						validator: CreateMessageResponse,
						basePath: 'portalAPI',
						sign: true,
					});
				} catch (error) {
					throw error;
				}
			},
		}),
	};
});
