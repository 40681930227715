import React from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Dropdown } from '../Dropdown/Dropdown';

import {
  fetchStudentProgressData,
  updateSelectedDiscipline,
} from '../../actions/studentProgressActions';
import { requestActivities } from '../../actions';

function buildText(yearLevel, discipline, mode) {
  return `${yearLevel}: ${discipline} ${mode === 'Group' ? '(Group)' : '(Private 1-to-1)'}`;
}

const DisciplineList = ({
  data, updateDisciplineIndex, fetchProgressData, fetchActivities, useEOY,
}) => {
  const options = data.map((d, idx) => {
    return {
      value: `${idx}`,
      text: buildText(d.yearLevel, d.discipline, d.mode),
    };
  });
  let selectedOptionIndex = data.findIndex(d => (useEOY ? d.currentEoy === true : d.currentSelection === true));
  if (selectedOptionIndex < 0) {
    // no eoy found
    selectedOptionIndex = data.findIndex(d => (d.currentSelection === true));
  }
  return (
    <Dropdown
      id="discipline-list"
      selectedOption={selectedOptionIndex >= 0 ? selectedOptionIndex : null}
      options={options}
      defaultValue={0}
      setSelectedOption={(index) => {
        updateDisciplineIndex(parseInt(index, 10));
        fetchProgressData();
        fetchActivities(true);
      }}
    />
  );
};

DisciplineList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      currentSelection: PropTypes.bool,
      discipline: PropTypes.string,
      mode: PropTypes.string,
      yearLevel: PropTypes.string,
    }),
  ),
  updateDisciplineIndex: PropTypes.func.isRequired,
  fetchProgressData: PropTypes.func.isRequired,
  fetchActivities: PropTypes.func.isRequired,
  useEOY: PropTypes.bool.isRequired,
};
DisciplineList.defaultProps = {
  data: [],
};

function mapDispatchToProps(dispatch) {
  return {
    updateDisciplineIndex: bindActionCreators(
      updateSelectedDiscipline,
      dispatch,
    ),
    fetchProgressData: bindActionCreators(fetchStudentProgressData, dispatch),
    fetchActivities: bindActionCreators(requestActivities, dispatch),
  };
}

export default connect((state) => {
  return {
    data: state.studentProgress.disciplineList,
    useEOY: state.report.useEoyToPickDiscipline,
  };
}, mapDispatchToProps)(DisciplineList);
