import { ReactNode } from 'react';
import { Loader } from '../../../../components/Loader/Loader';
import { Link } from 'react-router-dom';

type IconCardProps = {
  loading?: boolean;
  icon?: ReactNode;
  title?: ReactNode;
  header?: ReactNode;
  description?: ReactNode;
  link?: { to: string; text: string };
  children?: ReactNode;
  containerClassName?: string;
};

const IconCard = ({ loading, icon, title, header, description, link, containerClassName, children }: IconCardProps) => {
  return (
    <div className={`flex-1 flex-col px-8 py-6 ${containerClassName}`}>
      <div className={`h-[100%] items-center justify-center ${loading ? 'flex' : 'hidden'}`}>
        <Loader />
      </div>
      <div className={`${loading && 'invisible'}`}>
        {title && <div className="text-xs font-bold uppercase">{title}</div>}
        <div className="mt-3 flex flex-row">
          {icon && <div className="flex">{icon}</div>}
          <div className="ml-4 flex flex-col">
            {header && <h3 className="flex font-bold">{header}</h3>}
            {description && <p>{description}</p>}
            {link && <Link className="text-sm text-blue-5" to={link.to}>{link.text}</Link>}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IconCard;
