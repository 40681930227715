import { combineReducers } from 'redux';
import login from './loginReducer';
import report from './reportReducer';
import ui from './uiReducer';
import diagnostics from './diagnosticsReducer';
import activities from './activitiesReducer';
import nextSession from './nextSessionReducer';
import upcomingSessions from './upcomingSessionsReducer';
import contactUsFlow from './contactUsFlowReducer';
import triggerPayment from './triggerPaymentReducer';
import hubUser from './hubUserReducer';
import hubSessionsPage from './hubSessionsPageReducer';
import hubEnrolmentsPage from './hubEnrolmentsPageReducer';
import hubEnrolmentDetailPage from './hubEnrolmentDetailPageReducer';
import hubChangeSchedulePage from './hubChangeSchedulePageReducer';
import hubAccountPage from './hubAccountPageReducer';
import hubContactPage from './hubContactPageReducer';
import studentProgress from './studentProgressReducer';
import hubEnrolmentsWithResumeSession from './hubEnrolmentsWithResumeSessionReducer';
import hubChangeGroupPage from './hubChangeGroupPageReducer';
import schoolsUpcomingSessions from './schoolsUpcomingSessionsReducer';
import hubChangeSessionWithEnrolmentTutor from './hubChangeSessionWithEnrolmentTutorReducer';
import summerMode from './summerModeReducer';
import zuora from './zuoraReducer';

const rootReducer = combineReducers({
  login,
  report,
  ui,
  diagnostics,
  activities,
  nextSession,
  upcomingSessions,
  contactUsFlow,
  triggerPayment,
  hubUser,
  hubSessionsPage,
  hubEnrolmentsPage,
  hubEnrolmentDetailPage,
  hubChangeGroupPage,
  hubChangeSchedulePage,
  hubAccountPage,
  hubContactPage,
  schoolsUpcomingSessions,
  studentProgress,
  hubEnrolmentsWithResumeSession,
  hubChangeSessionWithEnrolmentTutor,
  summerMode,
  zuora,
});

export default rootReducer;
