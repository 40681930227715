import { sendGetRequest } from '../RequestService';
import { getPortalAPIRequestParams } from './helper';

export class EnrolmentService {
  static async getEnrolments(filter = 'active') {
    const path = `enrolments?filter=${filter}`;
    const { url, reqOptions } = getPortalAPIRequestParams(path);
    const res = await sendGetRequest({
      url,
      signRequest: true,
      reqOptions,
      customErrorMsg: `Unable to fetch enrolments (filter=${filter})`,
    });
    return res.json();
  }

  static async getEnrolmentDetail({ enrolmentId }) {
    if (!enrolmentId) {
      throw new Error('something went wrong!');
    }

    const path = `enrolment/${enrolmentId}`;
    const { url, reqOptions } = getPortalAPIRequestParams(path);

    try {
      const res = await sendGetRequest({
        url,
        signRequest: true,
        reqOptions,
        customErrorMsg: `Unable to fetch enrolment detail - ${enrolmentId}`,
      });
      return res.json();
    } catch (error) {
      throw error;
    }
  }

  static async getAvailableSubjects({ year, country = 'au' }) {
    if (!year || !country) {
      throw new Error('something went wrong!');
    }

    const path = `availableSubjects?year=${year}&country=${country.toLowerCase()}`;
    const { url, reqOptions } = getPortalAPIRequestParams(path);

    try {
      const res = await sendGetRequest({
        url,
        signRequest: true,
        reqOptions,
        customErrorMsg: `Unable to fetch available subject - ${year} & ${country}`,
      });
      return res.json();
    } catch (error) {
      throw error;
    }
  }

  static async getProgramOverview({ studentCustomerNumber, subject }) {
    if (!studentCustomerNumber || !subject) {
      throw new Error('something went wrong!');
    }

    const path = `reporting/${studentCustomerNumber}/${subject}`;
    const { url, reqOptions } = getPortalAPIRequestParams(path);

    try {
      const res = await sendGetRequest({
        url,
        signRequest: true,
        reqOptions,
        customErrorMsg: `Unable to fetch program overview - ${studentCustomerNumber} & ${subject}`,
      });
      return res.json();
    } catch (error) {
      throw error;
    }
  }

  static async getProgramDetail({ year, country, subject, courseName, curriculum }) {
    if (!year || !country || !subject || !courseName || !curriculum) {
      throw new Error('something went wrong!');
    }

    const path = `programDetail?year=${year}&country=${country.toLowerCase()}&subject=${subject}&courseName=${courseName}&curriculum=${curriculum}`;
    const { url, reqOptions } = getPortalAPIRequestParams(path);
    try {
      const res = await sendGetRequest({
        url,
        signRequest: true,
        reqOptions,
        customErrorMsg: `Unable to fetch program detail - ${year}, ${country}, ${subject}, ${courseName}, ${curriculum}}`,
      });
      return res.json();
    } catch (error) {
      throw error;
    }
  }

  static async getPreviousTutor({ enrolmentId, hasPackageTutorChange }) {
    if (!enrolmentId) {
      throw new Error('something went wrong!');
    }

    if (!hasPackageTutorChange) {
      return Promise.resolve({});
    }

    const path = `change/tutor/enrolment/${enrolmentId}`;
    const { url, reqOptions } = getPortalAPIRequestParams(path);
    try {
      const res = await sendGetRequest({
        url,
        signRequest: true,
        reqOptions,
        customErrorMsg: `Unable to fetch tutor change history - ${enrolmentId}`,
      });
      return res.json();
    } catch (error) {
      throw error;
    }
  }

  static async getResumeDate({ enrolmentId, pauseToDate }: {
    enrolmentId: string;
    pauseToDate: string;
  }) {
    const path = `/salesforce/Customer/services/apexrest/getSessionStartDate?enrolid=${enrolmentId}&pausetodate=${pauseToDate}`;
    const { url, reqOptions } = getPortalAPIRequestParams(path);
    const res = await sendGetRequest({
      url,
      signRequest: true,
      reqOptions,
      customErrorMsg: `Unable to retrieve resume date for enrolment`,
    });
    return res.json();
  }
}
