import { IconProps as Props } from "../types/hubInterfaces";
import { iconDefaults } from ".";
import classNames from 'classnames';

function OpenInNewTabIcon(props: Props) {
  const { width, height, className } = { ...iconDefaults, ...props };
  return (
    <svg className={classNames('fill-current', className)} xmlns="http://www.w3.org/2000/svg" width={`${width}`} height={`${height}`} viewBox="0 0 18 18">
      <title>open in new tab icon</title>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.0385 10.6578C12.5075 10.6578 12.0769 11.0453 12.0769 11.5232V14.1193H3.42308V6.33087H6.30769C6.83864 6.33087 7.26923 5.94361 7.26923 5.46548C7.26923 4.98736 6.83864 4.6001 6.30769 4.6001H3.42308C2.36088 4.6001 1.5 5.37489 1.5 6.33087V14.1193C1.5 15.075 2.36088 15.8501 3.42308 15.8501H12.0769C13.1391 15.8501 14 15.075 14 14.1193V11.5232C14 11.0445 13.5703 10.6578 13.0385 10.6578ZM16.2257 2.34589C16.0564 2.19398 15.823 2.1001 15.5662 2.1001H10.897C10.3814 2.1001 9.98946 2.47585 9.98946 2.91616C9.98946 3.37982 10.4073 3.73222 10.923 3.73222H13.3378L6.77358 9.72806C6.40881 10.0561 6.40881 10.5877 6.77358 10.9157C7.13836 11.2447 7.73076 11.2447 8.09554 10.9167L14.6323 4.9655V7.13817C14.6323 7.60183 15.0505 7.95424 15.5399 7.95424C16.0293 7.95424 16.5 7.60262 16.5 7.13817V2.91616C16.5 2.70782 16.3949 2.4979 16.2257 2.34589Z" />
    </svg>
  )
}

export { OpenInNewTabIcon };