import { useSelector } from 'react-redux';
import { Link, generatePath } from 'react-router-dom';

import { api } from '../../../api';
import type { Enrolment } from '../../../api/types/enrolment';
import InPageLoader from '../../../common/InPageLoader';
import PageError from '../../../common/PageError';
import EnrolmentLayout from '../../../common/hub/EnrolmentLayout';
import withSelectedEnrolment from '../../../common/hub/NewWithSelectedEnrolment';
import LearningProgress from '../../../components/EnrolmentManagement/LearningProgress';
import ProgramBlurb from '../../../components/EnrolmentManagement/ProgramBlurb';
import ScheduleDetails from '../../../components/EnrolmentManagement/ScheduleDetails';
import { getSessionReportId } from '../../../selectors/hubSelector';
import { showSummerHolidayPauseBanner } from '../../../selectors/schoolTerms/schoolTermsSelectors';
import { PATH_HUB_HOME, PATH_HUB_STUDENT_PROFILE } from '../../../util/pagePath';
import { TRACKING_EDP } from '../../../util/trackingClasses';

interface Props {
  selectedEnrolment: Enrolment;
}

const EnrolmentDetails = ({ selectedEnrolment }: Props) => {
  const { enrolmentId, studentSfid } = selectedEnrolment;
  const { data: enrolmentDetails, isLoading, isError: loadingError } = api.enrolment.detail.useQuery({ enrolmentId });

  const {
    studentName = '',
    enrolmentDiscipline: subject = '',
    enrolmentYearLevel: yearLevel = '',
  } = enrolmentDetails ?? {};

  const sessionReportId = useSelector((state) => getSessionReportId(state, enrolmentId));
  const showSummerModeEnrolmentStatus = useSelector(showSummerHolidayPauseBanner);

  let display = <InPageLoader />;
  if (loadingError) {
    return <PageError activePage="hub-enrolment-detail" />;
  }

  if (!isLoading && !loadingError) {
    display = (
      <div className="bg-slate-1 py-7 md:py-10">
        <div className="container mx-auto px-4">
          <ProgramBlurb enrolmentDetails={enrolmentDetails} />
          <ScheduleDetails />
          <LearningProgress reportToken={sessionReportId} />
        </div>
      </div>
    );
  }

  return (
    <EnrolmentLayout
      sectionHeading={
        <>
          <Link className="text-xs font-bold uppercase" to={PATH_HUB_HOME}>
            Home
          </Link>
          <span className="mb-3 text-xs font-bold lg:mb-2 px-2">&gt;</span>
          <Link className="text-xs font-bold uppercase" to={generatePath(PATH_HUB_STUDENT_PROFILE, { studentSfid })}>
            {studentName}
          </Link>
        </>
      }
      mainHeading={`${yearLevel} ${subject}`}
      showEnrolmentStatus={!showSummerModeEnrolmentStatus}
      showSummerModeEnrolmentStatus={showSummerModeEnrolmentStatus}
      tracking={TRACKING_EDP.PAGE}
    >
      {display}
    </EnrolmentLayout>
  );
};

export default withSelectedEnrolment(EnrolmentDetails);
