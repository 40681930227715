import { useSelector } from 'react-redux';
import { Student } from '../../../../api/types/student';
import { AssessmentsOverviewWidgetBase } from '../../home/widgets/AssessmentsOverviewWidget/AssessmentsOverviewWidget';
import { useMapToStudentAssessmentsEligibilities } from '../../home/widgets/AssessmentsOverviewWidget/hooks';
import { getAccountDetails } from '../../../../selectors/hubSelector';
import { AccountDetails } from '../../../../types/hubInterfaces';
import { api } from '../../../../api';

export interface StudentAssessmentsOverviewProps {
	student?: Student;
}

const StudentAssessmentsOverview = ({ student }: StudentAssessmentsOverviewProps) => {
	const { country }: AccountDetails = useSelector(getAccountDetails);

	const { isLoading } = api.assessment.all.useQuery(
		{ customerNumber: student?.customerNumber },
		{ enabled: !!student?.customerNumber }
	);
	const studentAssessmentsEligibilities = useMapToStudentAssessmentsEligibilities(student ? [student] : [], country);

	if (isLoading) {
		return null;
	}

	return (
		<AssessmentsOverviewWidgetBase
			multipleStudents={false}
			studentAssessmentsEligibilities={studentAssessmentsEligibilities}
			className="mb-12 mt-8"
		/>
	);
};

export default StudentAssessmentsOverview;
