import { get, isEmpty } from 'lodash';
import { logError } from '../../logging';
import * as actionTypes from '../actionTypes';
import {
  apiFetch,
  REQ_POST_HUB_CREATE_NEW_CASE_SF,
  REQ_GET_HUB_PAUSE_SESSIONS_RESUME_SESSION,
} from '../../services/backendApi';
import { isGroupSessionCheck } from '../../util/helpers';
import { PATH_HUB_ENROLMENT_CHANGE_SCHEDULE } from '../../util/pagePath';

export const resetContactUIState = () => (dispatch) => {
  dispatch({ type: actionTypes.HUB_CR_RESET_CONTACT_CASE_STATE });
};

export const saveSelectedEnrolment =
  ({ enrolmentId }) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.HUB_CR_SAVE_SELECTED_ENROLMENT,
      payload: {
        selectedEnrolment: enrolmentId,
      },
    });
  };

export const saveSelectedSession =
  ({ session }) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.HUB_CR_SAVE_SELECTED_SESSION,
      payload: {
        selectedSession: session,
      },
    });
  };

export const saveSelectedStudent =
  ({ student }) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.HUB_CR_SAVE_SELECTED_STUDENT,
      payload: {
        selectedStudent: student,
      },
    });
  };

export const submitCaseToSF =
  ({
    action = 'Contact Request',
    startDate,
    endDate,
    uniqueEnrolmentId,
    studentSession,
    caseCategory,
    relatedBillingContact,
    targetSession,
    message,
    caseSubject,
    caseSubCategory,
    caseType,
    caseRecordType = 'Support',
    caseRequestFrom,
    onSubmitSuccess,
    onSubmitFailed = () => {},
    page = '',
    groupPackageCode,
    requestActions = {},
    customerNumber = '',
    customErrorMsg = '',
  }) =>
  (dispatch, getState) => {
    dispatch({
      type: actionTypes.HUB_CR_SUBMIT_CONTACT_CASE_START,
    });

    const { login } = getState();
    const customerId = customerNumber || get(login, 'customerId', null);

    const requestPayload = {
      startDate,
      endDate,
      action,
      uniqueEnrolmentId,
      caseOrigin: 'Hub',
      studentSession,
      caseCategory,
      relatedBillingContact,
      targetSession,
      message,
      caseSubject,
      caseSubCategory,
      caseType,
      caseRecordType,
      caseRequestFrom,
      customerNumber: customerId,
      groupPackageCode,
    };

    let actions = {
      start: actionTypes.HUB_CR_SUBMIT_CONTACT_CASE_START,
      success: actionTypes.HUB_CR_SUBMIT_CONTACT_CASE_SUCCESS,
      failed: actionTypes.HUB_CR_SUBMIT_CONTACT_CASE_FAILED,
    };

    if (page === 'manage-pause') {
      actions = {
        start: actionTypes.HUB_EM_MP_SUBMIT_NEW_RESUME_DATE_START,
        success: actionTypes.HUB_EM_MP_SUBMIT_NEW_RESUME_DATE_SUCCESS,
        failed: actionTypes.HUB_EM_MP_SUBMIT_NEW_RESUME_DATE_FAILED,
      };
    } else if (page === 'change-group') {
      actions = {
        start: actionTypes.HUB_EM_CG_SUBMIT_GROUP_START,
        success: actionTypes.HUB_EM_CG_SUBMIT_GROUP_SUCCESS,
        failed: actionTypes.HUB_EM_CG_SUBMIT_GROUP_FAILED,
      };
    }

    if (!isEmpty(requestActions)) {
      actions = requestActions;
    }

    dispatch({
      type: actions.start,
    });

    return apiFetch(
      REQ_POST_HUB_CREATE_NEW_CASE_SF,
      getState(),
      requestPayload,
      dispatch
    )
      .then(() => {
        dispatch({ type: actions.success });
        onSubmitSuccess();
      })
      .catch((e) => {
        const errorMsg = get(e, 'message');
        dispatch({
          type: actions.failed,
          payload: {
            errorMsg,
          },
        });
        onSubmitFailed();
        logError(e, null, {
          message: `Unable to submit request: ${action} to Salesforce for enrolment: ${uniqueEnrolmentId}. ${customErrorMsg}`,
        });
      });
  };

export const accessEnrolmentForm =
  ({
    enrolmentIdToken = null,
    history = null,
    isPermanentScheduleChangeForm = false,
  } = {}) =>
  (dispatch, getState) => {
    const { hubEnrolmentsPage } = getState();
    const { activeEnrolments } = hubEnrolmentsPage;
    //  Note, if there are more than one active enrolment, there will be a select model popup
    let enrolment = activeEnrolments.length === 1 ? activeEnrolments[0] : {};

    if (enrolmentIdToken) {
      enrolment = (activeEnrolments || []).find(
        (e) => e.enrolmentId === enrolmentIdToken
      );
    }

    if (isEmpty(enrolment)) {
      return null;
    }

    const { enrolmentId, type } = enrolment;
    if (isPermanentScheduleChangeForm) {
      const isGroup = isGroupSessionCheck(type);
      if (isGroup) {
        return dispatch(saveSelectedEnrolment({ enrolmentId }));
      }
      //  Note, redirect users to Permanent Schedule change contact request form
      //  till we get change group schedule page done.
      history.push(PATH_HUB_ENROLMENT_CHANGE_SCHEDULE);
      return null;
    }

    return dispatch(saveSelectedEnrolment({ enrolmentId }));
  };

export const getResumeSession =
  ({ enrolmentId, pauseTo, page = '' } = {}) =>
  (dispatch, getState) => {
    let actions = {
      start: actionTypes.HUB_CR_PS_FETCH_RESUME_SESSION_START,
      success: actionTypes.HUB_CR_PS_FETCH_RESUME_SESSION_SUCCESS,
      failed: actionTypes.HUB_CR_PS_FETCH_RESUME_SESSION_FAILED,
    };
    let requestPayload = {};

    if (enrolmentId && pauseTo) {
      if (page === 'manage-pause') {
        actions = {
          start: actionTypes.HUB_EM_MP_FETCH_RESUME_SESSION_START,
          success: actionTypes.HUB_EM_MP_FETCH_RESUME_SESSION_SUCCESS,
          failed: actionTypes.HUB_EM_MP_FETCH_RESUME_SESSION_FAILED,
        };
      } else {
        actions = {
          start: actionTypes.HUB_EWP_FETCH_RESUME_SESSION_START,
          success: actionTypes.HUB_EWP_FETCH_RESUME_SESSION_SUCCESS,
          failed: actionTypes.HUB_EWP_FETCH_RESUME_SESSION_FAILED,
        };
      }
      requestPayload = { enrolmentId, pauseTo };
    }

    dispatch({
      type: actions.start,
    });

    return apiFetch(
      REQ_GET_HUB_PAUSE_SESSIONS_RESUME_SESSION,
      getState(),
      requestPayload,
      dispatch
    )
      .then((json) => {
        const resumeSessionDate = get(json, 'messages[0].message', '');
        const severity = get(json, 'messages[0].severity', '');
        if (severity === 'INFO') {
          dispatch({
            type: actions.success,
            payload: {
              enrolmentId,
              resumeSessionDate,
              isRestart: false,
            },
          });
        } else {
          dispatch({
            type: actions.failed,
          });
          logError(new Error(), null, {
            message: 'Salesforce returns invalid resume session date',
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actions.failed,
        });
        logError(e, null, {
          message: 'Unable to fetch resume date from Salesforce',
        });
      });
  };

export const saveAffectedSessions =
  ({ affectedSessions }) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.HUB_CR_PS_SAVE_AFFECTED_SESSIONS,
      payload: {
        affectedSessions,
      },
    });
  };

export const savePauseFromResumeFromDate =
  ({ pauseFromDate = new Date(), resumeFromDate = new Date() }) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.HUB_CR_PS_SAVE_PAUSE_FROM_RESUME_FROM_DATE,
      payload: {
        pauseFromDate,
        resumeFromDate,
      },
    });
  };
