import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SectionHeader from './SectionHeader';
import { SummaryCard } from './SummaryCard';
import tutorIcon from '../../assets/images/icon-filled-tutor-2.svg';
import pencilIcon from '../../assets/images/icon-filled-pencil.svg';
import exclamationMarkIcon from '../../assets/images/icon-filled-alert-exclamation.svg';
import tickIcon from '../../assets/images/icon-filled-tick-green.svg';
import crossIcon from '../../assets/images/icon-filled-cross.svg';

import { SmallBox } from './SmallBox';

export function TopicCovered({
  topic,
  topicTotalTimeSpent,
  topicPracticeTimeSpent,
  topicSessionTimeSpent,
  topicPracticeTotalQuestionsAssigned,
  topicPracticeQuestionsSubmitted,
  topicPracticeCompletion,
  topicPracticeQuestionsCorrect,
}) {
  if (!topic) {
    return null;
  }
  return (
    <React.Fragment>
      <SectionHeader title={topic} smallerTitle />
      <div className="mb-16 flex flex-col text-grey-6 lg:mb-12 lg:flex-row lg:gap-4">
        <div
          className={classNames(
            'mb-3 flex md:mb-0',
            topicPracticeTotalQuestionsAssigned > 0
              ? 'w-full lg:w-1/2'
              : 'w-full'
          )}
        >
          <SummaryCard
            title="Time spent"
            subTitleBold={topicTotalTimeSpent}
            className="w-full bg-white"
            bodyClass="pb-2"
          >
            <SmallBox
              icon={tutorIcon}
              iconBackgroundClass="bg-secondary"
              leftText="Tutoring"
              rightText={topicSessionTimeSpent}
            />
            {topicPracticeTotalQuestionsAssigned > 0 && (
              <SmallBox
                icon={pencilIcon}
                iconBackgroundClass="bg-secondary"
                leftText="Practising"
                rightText={topicPracticeTimeSpent}
              />
            )}
          </SummaryCard>
        </div>
        {topicPracticeTotalQuestionsAssigned > 0 && (
          <div className="flex w-full lg:w-1/2">
            <SummaryCard
              title="Practice questions completed"
              subTitleBold={`${topicPracticeCompletion}%`}
              subTitle={`(${topicPracticeQuestionsSubmitted} of ${topicPracticeTotalQuestionsAssigned})`}
              className="w-full bg-white"
              bodyClass="pb-2"
            >
              <SmallBox
                icon={tickIcon}
                iconBackgroundClass="bg-green-1"
                leftText="Correct"
                rightText={`${topicPracticeQuestionsCorrect}`}
              />
              <SmallBox
                icon={crossIcon}
                iconBackgroundClass="bg-red-1"
                leftText="Incorrect"
                rightText={`${
                  topicPracticeQuestionsSubmitted -
                  topicPracticeQuestionsCorrect
                }`}
              />
              <SmallBox
                icon={exclamationMarkIcon}
                iconBackgroundClass="bg-yellow-1"
                leftText="Not submitted"
                rightText={`${
                  topicPracticeTotalQuestionsAssigned -
                  topicPracticeQuestionsSubmitted
                }`}
              />
            </SummaryCard>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

TopicCovered.propTypes = {
  topic: PropTypes.string.isRequired,
  topicTotalTimeSpent: PropTypes.string,
  topicPracticeTimeSpent: PropTypes.string,
  topicSessionTimeSpent: PropTypes.string,
  topicPracticeTotalQuestionsAssigned: PropTypes.number.isRequired,
  topicPracticeQuestionsSubmitted: PropTypes.number,
  topicPracticeCompletion: PropTypes.number,
  topicPracticeQuestionsCorrect: PropTypes.number,
};

TopicCovered.defaultProps = {
  topicTotalTimeSpent: '',
  topicPracticeTimeSpent: '',
  topicSessionTimeSpent: '',
  topicPracticeQuestionsSubmitted: 0,
  topicPracticeCompletion: 0,
  topicPracticeQuestionsCorrect: '',
};
