import { ComponentProps } from 'react';
import classNames from 'classnames';

const SkeletonLoader = ({ loading, className, children, ...props }: { loading: boolean } & ComponentProps<'div'>) => {
  return loading
    ? <div
      {...props}
      className={classNames('rounded-lg', loading && 'animate-pulse bg-grey-2 text-transparent', className)}
    />
    : <>{children}</>
}

export { SkeletonLoader }