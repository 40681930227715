export const HubSessionListSkeletonItem = () => {
	return (
		<div className="flex animate-pulse justify-between rounded-lg border border-grey-2 bg-grey-1 px-7 py-4 shadow-sm">
			<div className="flex flex-col items-start gap-2">
				<p className="animate-pulse rounded-full bg-grey-2 text-transparent">Student | Subject (with Tutor)</p>
				<div className="animate-pulse rounded-full bg-grey-2 text-xs font-bold uppercase tracking-wide text-transparent">
					Wed, 18 Dec 2023
				</div>
				<p className="animate-pulse rounded-full bg-grey-2 text-transparent">7:00pm -- 8:00pm</p>
				<div className="flex gap-2">
					<div className="h-6 w-6 animate-pulse rounded-full bg-grey-2"></div>
					<p className="animate-pulse rounded-full bg-grey-2 text-transparent">Paid | View Invoice</p>
				</div>
			</div>
			<div className="flex flex-col items-end gap-2">
				<div className="animate-pulse rounded-full bg-grey-2 text-right text-transparent">
					In 3 days, 4 hrs, 57 mins
				</div>
				<div className="animate-pulse rounded-full bg-grey-2 text-right text-transparent">Manage</div>
			</div>
		</div>
	);
};
