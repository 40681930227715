import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AnchorButton } from '@cluey/cluey-components';
import SectionHeader from './SectionHeader';
import { fetchOutcomeNextYear } from '../../actions/studentProgressActions';

const NewYearBanner = ({ fetchOutcomes, nextYearLevel, outcomesOverview, customClass, enrolmentUrl }) => {
	useEffect(() => {
		async function fetchData() {
			await fetchOutcomes();
		}
		fetchData();
		// eslint-disable-next-line
	}, []);
	if (!nextYearLevel || !outcomesOverview) {
		return null;
	}
	const d = new Date();
	const month = d.getMonth();
	let year = d.getFullYear();

	if (month === 11) {
		// Currently December
		year += 1; // show next year
	}

	return (
		<div className="w-full bg-slate-1 text-grey-6">
			<div className={classNames('container mx-auto', customClass)}>
				<SectionHeader
					title={`What’s coming up in ${nextYearLevel}`}
					text={outcomesOverview}
					renderRightsideElement={() => (
						<div className="w-full lg:w-1/3">
							<div className="box-shadow-8px-025 flex flex-col rounded-lg border-t-8 border-blood-orange-4 bg-white px-8 py-9 text-center">
								<h3 className="mb-1 text-2xl leading-[1.17]">Get ready for {year}</h3>
								<p className="mb-6 text-xl leading-[1.2]">Manage your enrolment</p>
								<div>
									<AnchorButton href={enrolmentUrl} target="_blank" size="lg">
										Manage Enrolment
									</AnchorButton>
								</div>
							</div>
						</div>
					)}
					alignRightsideElement="items-start"
				/>
			</div>
		</div>
	);
};
NewYearBanner.propTypes = {
	fetchOutcomes: PropTypes.func.isRequired,
	nextYearLevel: PropTypes.string,
	outcomesOverview: PropTypes.string,
	customClass: PropTypes.string,
	enrolmentUrl: PropTypes.string,
};
NewYearBanner.defaultProps = {
	outcomesOverview: null,
	nextYearLevel: null,
	customClass: '',
	enrolmentUrl: null,
};
export default connect(
	(state) => {
		return {
			outcomesOverview: state.studentProgress.outcomesOverview,
			nextYearLevel: state.studentProgress.nextYearLevel,
			enrolmentUrl: state.studentProgress.enrolmentDetailsUrl,
		};
	},
	(dispatch) => {
		return {
			fetchOutcomes: bindActionCreators(fetchOutcomeNextYear, dispatch),
		};
	}
)(NewYearBanner);
