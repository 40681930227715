import { Fragment, ReactNode } from 'react';
import classnames from 'classnames';
import { Button, AnchorButton } from '@cluey/cluey-components';
import useWindowWidth from '../../hooks/common/useWindowWidth';
import { BREAKPOINTS } from '../../util/constants';

interface Props {
  headerMsg: string;
  btnMsg: string;
  children: ReactNode;
  programChangeFeedback: ReactNode;
  sessionTitle?: ReactNode |string;
  title: string;
  isBtnActive: boolean;
  buttonLinkUrl: string;
  id: string;
  trackingClass: string;
  styleClass: string;
}

const CardItem = (props: Props) => {
  const {
    children,
    programChangeFeedback,
    sessionTitle,
    title,
    headerMsg,
    btnMsg,
    isBtnActive,
    buttonLinkUrl,
    id,
    trackingClass,
    styleClass,
  } = props;
  const windowWidth = useWindowWidth();
  const showBtn = windowWidth >= BREAKPOINTS.md && btnMsg;

  return (
    <div
      id={id}
      className={classnames('shadow-md', styleClass, trackingClass)}
    >
      {headerMsg && (
        <div
          className={classnames(
            'card-headerWithMsg',
            windowWidth < BREAKPOINTS.sm && 'sticky-top'
          )}
        >
          {headerMsg}
        </div>
      )}
      {!headerMsg && <div className="rounded-0 h-1 bg-third" />}
      <div className="border-top-0 ml-0 rounded-bl rounded-br border border-custom-3 py-3 pl-6 pr-7 md:ml-0 md:px-10">
        {sessionTitle && <div className="pt-2 pb-4 text-sm">{sessionTitle}</div>}
        {programChangeFeedback}
        <h3 className="text-slate_shade_04 mb-1 text-xl font-bold">
          {title}
        </h3>
        <div
          className={classnames(
            'flex',
            showBtn && 'flex-row',
            showBtn && 'justify-between'
          )}
        >
          <div
            className={classnames(
              showBtn && 'flex w-auto',
              'card-text',
              !showBtn && 'w-full'
            )}
          >
            {children}
          </div>
          {showBtn && (
            <div className="flex items-start">
              {isBtnActive ? (
                <AnchorButton
                  href={buttonLinkUrl}
                  target="blank"
                  size="xs"
                  appearance="reverse"
                >
                  {btnMsg}
                </AnchorButton>
              ) : (
                <Fragment>
                  <Button
                    appearance="reverse"
                    size="xs"
                    disabled
                    onClick={() => {}}
                  >
                    {btnMsg}
                  </Button>
                  <p className="card-text card-text-legend pt-3">
                    (Available 15 mins before session)
                  </p>
                </Fragment>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

CardItem.defaultProps = {
  programChangeFeedback: null,
  headerMsg: undefined,
  btnMsg: undefined,
  isBtnActive: true,
  buttonLinkUrl: null,
  id: null,
  trackingClass: null,
  styleClass: null,
};

export default CardItem;
