import { useCallback, useEffect, useRef } from 'react';
import { TMessage } from '../../useMessageStore';

export default function useScrollToBottom(messages: TMessage[]) {
  const messageListRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = useCallback(() => {
    const chatContainer = messageListRef.current;
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }, []);

  const handleImageLoading = useCallback(() => {
    const chatContainer = messageListRef.current;

    const images = chatContainer.querySelectorAll('img');

    const imagePromises = Array.from(images).map((img) => {
      return new Promise((resolve) => {
        if (img.complete) {
          resolve(undefined); // Image is already loaded
        } else {
          img.onload = resolve; // Wait for the image to load
        }
      });
    });

    // Once all images have loaded, scroll to the bottom
    Promise.all(imagePromises).then(() => {
      scrollToBottom();
    });
  }, [scrollToBottom]);

  useEffect(() => {
    handleImageLoading();
  }, [messages, handleImageLoading]);

  return messageListRef;
}
