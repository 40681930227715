import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchEnrolment } from '../../actions/hub/hubEnrolmentManagementActions';

const EnrolmentManagementHOC = (Component) => {
  const ComposedComponent = (props) => {
    const { enrolmentId, fetchEnrolmentDetail } = props;
    useEffect(() => {
      fetchEnrolmentDetail({ enrolmentId });
    }, [fetchEnrolmentDetail, enrolmentId]);

    return (<Component {...props} />);
  };

  ComposedComponent.propTypes = {
    enrolmentId: PropTypes.string.isRequired,
    fetchEnrolmentDetail: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
  };

  return connect(
    (state) => {
      const { enrolmentId } = state.hubEnrolmentDetailPage;
      return {
        enrolmentId,
      };
    },
    (dispatch) => {
      return {
        fetchEnrolmentDetail: bindActionCreators(fetchEnrolment, dispatch),
      };
    },
  )(ComposedComponent);
};

export default EnrolmentManagementHOC;
