import classNames from 'classnames';
import moment from 'moment';
import tickIcon from '../../../../assets/images/icon-filled-tick-green-2.svg';
import externalLinkIcon from '../../../../assets/images/icon-external-link.svg';
import { StudentAssessmentsEligibilities } from '../../home/widgets/AssessmentsOverviewWidget/types';
import { SUBJECT } from './LearningProfile';
import { AnchorButton, Button } from '@cluey/cluey-components';
import { getTokenFromUrl } from '../../home/widgets';
import { generatePath, useHistory } from 'react-router';
import { PATH_HUB_GENERATE_ASSESSMENT, PATH_HUB_VIEW_ASSESSMENT } from '../../../../util/pagePath';
import { Student } from '../../../../api/types/student';

const Status = ({ status }: { status: string }) => {
	let color = '';
	let labelColor = 'text-black';
	let statusLabel = '';
	if (status === 'Completed') {
		color = 'bg-green-5';
		statusLabel = 'Completed';
		labelColor = 'text-white';
	} else if (
		status === 'In Progress' ||
		status === 'Not Accessed' ||
		status === 'Accessed' ||
		status === 'Incomplete'
	) {
		color = 'bg-yellow-3';
		statusLabel = 'In Progress';
	} else {
		return null;
	}
	return (
		<div className={classNames(color, labelColor, 'rounded-2xl px-2 py-1 text-xs font-bold uppercase text-black tracking-1px')}>
			{statusLabel}
		</div>
	);
};

const AssessmentCard = ({
	student,
	subject,
	yearLevel,
	studentAssessmentsEligibilities,
}: {
	student: Student;
	subject: string;
	yearLevel: string;
	studentAssessmentsEligibilities: StudentAssessmentsEligibilities;
}) => {
	const mathAssessment = studentAssessmentsEligibilities.mathematicsAssessment;
	const englishAssessment = studentAssessmentsEligibilities.englishAssessment;
	const subjectIsMaths = subject === SUBJECT.MATHS;
	const assessmentStatus = subjectIsMaths ? mathAssessment?.status : englishAssessment?.status;
	const assessment = subjectIsMaths ? mathAssessment : englishAssessment;
	const questionsCorrect = assessment?.questionsCorrect;
	const questionsTotal = assessment?.questionsTotal;
	const score = Math.round((questionsCorrect / questionsTotal) * 100);
	const history = useHistory();

	if (assessmentStatus === 'Completed') {
		const token = getTokenFromUrl(assessment?.studentLink || '');
		const path = generatePath(PATH_HUB_VIEW_ASSESSMENT, { token });
		return (
			<div className="flex flex-col gap-6 rounded-lg bg-grey-1 px-8 py-6">
				<div className="flex flex-row justify-between">
					<div>
						<div className="text-xl font-bold">{`${student?.firstName} scored ${score}% in ${yearLevel} ${assessment.subject} assessment`}</div>
						<div>Completed on {moment(assessment.completedAt).format('ddd DD MMM YYYY')}</div>
					</div>

					<div>
						<Status status={assessmentStatus} />
					</div>
				</div>

				<div className="flex flex-row items-center">
					<img alt="questions correct icon" src={tickIcon} className="mr-1" />
					<div className="font-bold">{`${questionsCorrect} out of ${questionsTotal} questions correct`}</div>
				</div>

				<div>
					<AnchorButton className="group" href={path} target="blank" appearance="reverse">
						<div className="flex flex-row items-center">
							<div className="mt-[2px] font-bold">See assessment details</div>
							<img className="ml-2 h-4 w-4 group-hover:brightness-0 group-hover:invert group-hover:fill-[#004ab9]" alt="external link icon" src={externalLinkIcon} />
						</div>
					</AnchorButton>
				</div>
			</div>
		);
	} else if (
		assessmentStatus === 'Not Accessed' ||
		assessmentStatus === 'Accessed' ||
		assessmentStatus === 'Incomplete'
	) {
		const questionsCompleted = assessment?.questionsCompleted || 0;
		return (
			<div className="flex flex-col gap-6 rounded-lg bg-grey-1 px-8 py-6">
				<div className="flex flex-row justify-between">
					<div>
						<div className="text-xl font-bold">{`${yearLevel} ${assessment.subject} assessment`}</div>
						<div>{`${questionsCompleted} out of ${questionsTotal} questions completed`}</div>
					</div>

					<div>
						<Status status={assessmentStatus} />
					</div>
				</div>

				<div>
					<AnchorButton className="group" href={assessment.studentLink} target="blank" appearance="reverse">
						<div className="flex flex-row items-center button-with-icon">
							<div className="mt-[2px]">Continue assessment</div>
							<img className="ml-2 h-4 w-4 group-hover:brightness-0 group-hover:invert group-hover:fill-[#004ab9]" alt="external link icon" src={externalLinkIcon} />
						</div>
					</AnchorButton>
				</div>
			</div>
		);
	}

	return (
		<div className="flex flex-col gap-4">
			<div>No current assessment</div>
			<div>
				<Button
					type="button" 
					className="uppercase !font-bold"
					appearance="reverse"
					onClick={() => {
						const path = generatePath(PATH_HUB_GENERATE_ASSESSMENT, { assessmentSubject: subject });
						history.push(path, { data: { student, subject } });
					}}
				>
					<span>{`Generate ${yearLevel} ${subject} assessment link`}</span>
				</Button>
			</div>
		</div>
	);
};

export default AssessmentCard;
