import React from 'react';

export const BlockLoader = () => {
  return (
    <div className="relative flex justify-around items-center block-loader-container full-page-loading">
      <span className="block cluey-loading" />
      <span className="block cluey-loading" />
      <span className="block cluey-loading" />
      <span className="block cluey-loading" />
      <span className="block cluey-loading" />
      <span className="block cluey-loading" />
    </div>
  );
};
