import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const InPageLoader = ({ position, height, bgClass }) => {
  const loaderClasses = classNames(
    bgClass,
    'w-full h-full flex flex-col justify-center items-center full-page-loading',
    { fixed: position === 'fixed' },
    { absolute: position === 'absolute' },
    { relative: position === 'relative' },
    { 'h-full': height === '100' },
    { 'h-auto': height === 'auto' }
  );
  return (
    <div className={loaderClasses}>
      <div className="flex justify-center items-center w-full cluey-loading-block">
        <span className="block cluey-loading" />
      </div>
    </div>
  );
};

InPageLoader.propTypes = {
  position: PropTypes.string,
  height: PropTypes.string,
  bgClass: PropTypes.string,
};

InPageLoader.defaultProps = {
  position: 'fixed',
  height: '100',
  bgClass: 'bg-slate-1',
};

export default InPageLoader;
