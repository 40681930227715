import moment from 'moment';
import { scrollToTop } from '../../../util/helpers';
import { FAQQuestions } from '../../../components/FAQComponent/types';
import usedUpRequests from '../../../assets/images/virtualCoach/faq/used-up-requests.png';

export const aiTutorFaq: FAQQuestions = {
  id: ['ai-tutor-faq'],
  questions: {
    whatToAskVirtualCoach: {
      question: 'What questions should I ask Cluey Coach?',
      priority: 1,
      answer: (
        <div>
          <div>
            Writing a great prompt is the best way to get the most out of your study (and your request limit) with
            Cluey Coach.
          </div>
          <br />
          <div>
            A great prompt should be clear, specific, and providing context. Here's a guide to crafting an effective
            prompt:
          </div>
          <br />
          <div>
            <ol className="list-inside list-decimal space-y-4">
              <li>
                <b>Define your goal</b>: Specify what you want to achieve. Are you looking for an explanation or a
                summary of the concept? Are you struggling to understand a specific step? Or do you need examples or
                practice problems? Be specific and explicit in what you are trying to get help with. For example:
                <ul className="list-outside list-disc space-y-4 ps-5 pt-4">
                  <li>
                    "I'm learning about Newton's Laws of Motion. Can you help me understand how to apply Newton's Second
                    Law to a problem? For example, how do I calculate the acceleration of a 10 kg object if a net force
                    of 50 N is applied to it? Also, can you explain how this relates to the concept of inertia?"
                  </li>
                  <li>
                    "I'm preparing for my Biology exam and need help understanding the concept of natural selection.
                    Could you provide an example of how natural selection works in a population of organisms, and
                    explain the difference between stabilising, directional, and disruptive selection?"
                  </li>
                  <li>
                    "Can you provide me with six practice problems on quadratic equations? Please include a mix of
                    simple, intermediate, and challenging problems, and provide step-by-step instructions to reach the
                    solution for each."
                  </li>
                </ul>
              </li>
              <li>
                <b>Provide context and ask specific questions</b>: Being as specific as you can helps Cluey Coach
                understand exactly what you need. Be sure to mention any relevant details or provide background
                information. This can include what you already know or specific areas where you're struggling. If you
                have particular questions or topics you're unsure about, include them in your prompt. For example:
                <ul className="list-outside list-disc space-y-4 ps-5 pt-4">
                  <li>
                    "I'm studying the process of photosynthesis. Could you explain how the light-dependent and
                    light-independent reactions work, and how they contribute to the overall process? I'm particularly
                    confused about the role of ATP and NADPH in the Calvin cycle."
                  </li>
                  <li>
                    "I'm struggling with the concept of differentiation, particularly how to apply it to finding the
                    maximum and minimum points of a function. Could you explain how to differentiate f(x)=3x3−5x2+2xf(x)
                    = 3x^3 - 5x^2 + 2xf(x)=3x3−5x2+2x and how to determine the critical points and their nature?"
                  </li>
                  <li>
                    "I need help with the concept of electromagnetic waves. Could you explain how to calculate the
                    wavelength of a wave if the frequency is 5.0 × 10^14 Hz, and the speed of light is 3.0 × 10^8 m/s?
                    Also, can you briefly explain the relationship between wavelength, frequency, and wave speed?"
                  </li>
                </ul>
              </li>
              <li>
                <b>State your preferred format</b>: If you have a preferred format for the response (e.g. bullet points,
                a step-by-step guide, a summary), mention it. For example:
                <ul className="list-outside list-disc space-y-4 ps-5 pt-4">
                  <li>
                    "List the key steps for solving a quadratic equation using the quadratic formula in bullet points."
                  </li>
                  <li>"Could you give me a step-by-step guide on how photosynthesis occurs?"</li>
                  <li>"Can you provide a brief summary of Newton's First Law of Motion in 3-4 sentences?"</li>
                </ul>
              </li>
            </ol>
          </div>
          <br />
          <div>
            By following these guidelines, you can craft prompts that will help you get the most relevant and useful
            responses from Cluey Coach and maximise your request limit.
          </div>
        </div>
      ),
    },
    usedUpMyRequests: {
      question: 'I have used up my requests but I still need help. What are my options?',
      priority: 2,
      answer: (
        <>
          <div>You can book one-off sessions with a tutor to get extra help outside your regular session schedule.</div>
          <br />
          <div>
            To book a session, select 'Session times' from the 'Support' menu in the Hub and choose 'Add a one-off
            session' to book.
          </div>
        </>
      ),
    },
    usedUpMyLimit: {
      question: 'I have used up my limit for the day. When does it restart?',
      priority: 3,
      answer: (
        <>
          <div>
            Your limit will reset at the start of the day 12AM AEST (
            {moment.tz('00:00', 'HH:mm', 'Australia/Sydney').local().format('hh:mm A')} local time).
          </div>
        </>
      ),
    },
    somethingNotWorking: {
      question: 'Something is not working. What should I do?',
      priority: 3,
      answer: (
        <>
          <div>
            Not getting the responses you expect to your question or prompt? See{' '}
            <u
              className="cursor-pointer"
              onClick={() => {
                scrollToTop();
              }}
            >
              What questions should I ask Cluey Coach?
            </u>{' '}
            to learn more about writing a good prompt.
          </div>
          <br />
          <div>
            Unable to access Cluey Coach? You may have used up your limit for the day if you see the following
            message:
            <div className='p-3'>
              <img className="rounded-lg" src={usedUpRequests} alt="Used up requests message" />
            </div>
          </div>
          <div>If something else is not working, let us know by contacting Cluey Support via the chat in the Hub.</div>
        </>
      ),
    },
  },
};
