import { forwardRef, useImperativeHandle, useState } from 'react';
import { Button } from '@cluey/cluey-components';
import { CancelRating } from './CancelRating';
import { CancelReasons } from './CancelReasons';
import type { CancelReason } from '../../../../types/hubInterfaces';
import type { Enrolment } from '../../../../api/types/enrolment';

export type State = {
  selectedDate: string;
  rating: number;
  selectedReasons: Array<CancelReason>;
  message: string;
  other: {
    message: string;
  }
}

export interface Props {
  onSubmitCancelRequest(): void;
  cancelSelection: State;
  setCancelSelection: React.Dispatch<React.SetStateAction<State>>;
  selectedEnrolment: Enrolment;
}

const CancellationSteps = forwardRef(({ 
  onSubmitCancelRequest,
  cancelSelection,
  setCancelSelection,
  selectedEnrolment
}: Props, ref) => {
  const [stepIndex, setStepIndex] = useState(0);
  useImperativeHandle(ref, () => {
    return {
      getStepIndex: () => stepIndex,
      goBack: () => setStepIndex(stepIndex - 1)
    }
  }, [stepIndex])

  const steps = [
    <CancelRating
      rating={cancelSelection.rating}
      setCancelSelection={setCancelSelection}
    />,
    <CancelReasons
      onSubmitCancelRequest={onSubmitCancelRequest}
      cancelSelection={cancelSelection}
      setCancelSelection={setCancelSelection}
      selectedEnrolment={selectedEnrolment}
    />,
  ]
  const onNextClick = () => setStepIndex(stepIndex + 1);

  return (
    <div className='mb-32 mt-5'>
      <div className='flex justify-center'>
        {steps[stepIndex]}
      </div>
      {stepIndex + 1 < steps.length && (
        <div className='text-center'>
          <Button
            disabled={cancelSelection.rating === 0 || !(!!cancelSelection.selectedDate)}
            appearance='reverse'
            onClick={onNextClick}>
            Next
          </Button>
        </div>
      )}
    </div>
  );
});

export default CancellationSteps;