import initialState from './initialState';
import * as actionTypes from '../actions/actionTypes';

export default function studentProgressReducer(
  state = initialState.studentProgress,
  action,
) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.STPG_FETCH_DISCIPLINE_LIST_SUCCESS:
      return {
        ...state,
        disciplineList: payload.disciplineList || [],
      };
    case actionTypes.STPG_UPDATE_SELECTED_DISCIPLINE:
      return {
        ...state,
        disciplineList: state.disciplineList.map((d, idx) => {
          return {
            ...d,
            currentSelection: idx === action.payload.disciplineIndex,
          };
        }),
        outcomesOverview: null,
        nextYearLevel: null,
        enrolmentDetailsUrl: null,
      };
    case actionTypes.STPG_FETCH_STUDENT_PROGRESS_DATA_SUCCESS:
      return {
        ...state,
        summary: payload.summary,
        modules: payload.modules,
        cumulative: payload.cumulative,
      };
    case actionTypes.STPG_SAVE_SELECTED_MODULE_ID:
      return {
        ...state,
        selectedModule: {
          ...state.selectedModule,
          id: payload.moduleId,
        },
      };
    case actionTypes.STPG_FETCH_SELECTED_MODULE_DETAIL_SUCCESS:
      return {
        ...state,
        selectedModule: {
          ...state.selectedModule,
          ...payload,
        },
      };
    case actionTypes.STPG_TOPIC_MODAL_CLOSED:
      return {
        ...state,
        selectedModule: {},
      };
    case actionTypes.STPG_FETCH_OUTCOME_NEXT_YEAR_SUCCESS: {
      return {
        ...state,
        outcomesOverview: payload.outcomesOverview,
        nextYearLevel: payload.yearLevel,
        enrolmentDetailsUrl: payload.enrolmentDetailsUrl,
      };
    }
    case actionTypes.STPG_FETCH_OUTCOME_NEXT_YEAR_FAILED: {
      return {
        ...state,
        outcomesOverview: null,
      };
    }
    default:
      return state;
  }
}
