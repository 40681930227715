import { combineReducers } from 'redux';

import appLifecycle from './uiReducers/appLifecycleReducer';
import apiState from './uiReducers/apiStateReducer';
import appView from './uiReducers/appViewReducer';

const uiReducer = combineReducers({
  appLifecycle,
  apiState,
  appView,
});

export default uiReducer;
