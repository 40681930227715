import moment from 'moment-timezone';
import isNull from 'lodash/isNull';
import { ENR_SESSION_FREQUENCY } from "../../../../util/constants/enrolmentConstants";
import { PAUSED_ENROLMENT_RESUME_DATE_FORMAT } from '../../../../util/constants';

export interface GenerateSessionDescriptionOptions {
  startTime: string;
  sessionFrequency: string;
  timezone: string;
  isPaused: boolean;
  resumptionDate?: string | null | undefined;
  enrolmentPausedTo: string;
}

export default function generateSessionDescription(options: GenerateSessionDescriptionOptions) {
  const { startTime, sessionFrequency, timezone, isPaused, resumptionDate, enrolmentPausedTo } = options;
  const sessionStartTime = moment(startTime);
  /*
  If the enrolment is paused:
    - Use resumptionDate
    - 'resumptionDate' will be null if we failed to fetch the resume date (due to request/network error, or similar), in that case use 'enrolmentPausedTo'
  If the enrolment is active:
    - Use sessionStartTime
    - 'sessionStartTime' can be null, which means its 'schedule' property is also likely an empty array
*/
  if (isNull(startTime)) {
    return 'There are no ongoing sessions scheduled for this enrolment'; // To confirm in shoulder check. Currently in the enrolment details page, if there is no schedule the change schedule CTAs are not displayed.
  } else if (sessionStartTime.isValid) {
    if (isPaused) {
      if (resumptionDate) {
        // resumptionDate is in AU time
        const time = moment(resumptionDate).tz('Australia/Sydney', true).tz(timezone);
        return time.format(`[Sessions paused and set to resume on] ddd, MMM D [at] h:mma`);
      }
      return moment(enrolmentPausedTo).tz('Australia/Sydney', true).tz(timezone).format(`[This enrolment is paused until] ${PAUSED_ENROLMENT_RESUME_DATE_FORMAT}`); 
    }
    const sessionsWeeklyOrBiweekly = sessionFrequency === ENR_SESSION_FREQUENCY.FORTNIGHTLY ? 'every other' : 'every';
    const format = (sessionFrequency === ENR_SESSION_FREQUENCY.MULTIPLE_SESSIONS || sessionFrequency === ENR_SESSION_FREQUENCY.TWICE_WEEKLY)
      ? '[Multiple sessions per week]'
      : `[Sessions ${sessionsWeeklyOrBiweekly}] dddd [at] h:mma`;
    return sessionStartTime.tz(timezone).format(format);
  } else {
    return 'Invalid session schedule';
  }
}