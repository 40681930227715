import React from 'react';
import PropTypes from 'prop-types';
import { AffectedSessionTitle } from './PauseSessions/AffectedSessions/AffectedSessionTitle';
import { ListItem } from './PauseSessions/AffectedSessions/ListItem';

export const ChangeSummary = ({ fromTitle, fromText, toTitle, toText, incentive }) => {
	return (
		<div className="mb-5 border-l-2 border-grey-2 pl-5">
			<div className="pb-2">
				<AffectedSessionTitle text={fromTitle} />
				<ul className="leading-[1.33]">
					<ListItem text={`• ${fromText}`} />
				</ul>
			</div>

			<div className="pb-2">
				<AffectedSessionTitle text={toTitle} />
				<ul className="list-unstyled text-[15px] leading-[1.33]">
					<ListItem text={`• ${toText}`} />
					{incentive && <p className="text-punch font-weight-bold text-uppercase ml-3 text-[11px]">{incentive}</p>}
				</ul>
			</div>
		</div>
	);
};

ChangeSummary.propTypes = {
	fromTitle: PropTypes.string.isRequired,
	fromText: PropTypes.string.isRequired,
	toTitle: PropTypes.string.isRequired,
	toText: PropTypes.string.isRequired,
	incentive: PropTypes.string,
};

ChangeSummary.defaultProps = {
	incentive: '',
};
