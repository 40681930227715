import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { ELEMENT_TYPES } from '../../types/hubEnums';

const OPTION_CARD_STYLES =
	'mb-3 block w-full rounded-lg border-none bg-slate-1 px-7 py-6 text-left hover:ring hover:ring-blue-1 focus:outline-none focus:ring focus:ring-blue-1 group';

export const OptionCard = ({ onClickHandler, linkTo, openInNewTab, children, className, disabled, type, capturePosthogEvent }) => {
	const classes = classNames(
		OPTION_CARD_STYLES,
		className,
		{ 'cursor-not-allowed': disabled },
		{ 'option-card': !disabled }
	);
	if (type === ELEMENT_TYPES.ANCHOR) {
		return (
			<a href={linkTo} target="_blank" rel="noreferrer" className={classes}>
				{children}
			</a>
		);
	}

	if (linkTo && !disabled) {
		return (
			<Link to={linkTo} className={classes} target={openInNewTab ? '_blank' : undefined} onClick={capturePosthogEvent}>
				{children}
			</Link>
		);
	}

	return (
		<button type="button" onClick={onClickHandler} className={classes} disabled={disabled}>
			{children}
		</button>
	);
};

export const OptionCardContent = ({ children, disabled }) =>
	disabled ? (
		<span className="text-lg font-bold !leading-[28px] text-grey-4">{children}</span>
	) : (
		<span className="text-lg font-bold !leading-[28px] text-blue-5 hover:text-purple-5">{children}</span>
	);

OptionCardContent.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

OptionCardContent.defaultProps = {
  disabled: false,
};

OptionCard.propTypes = {
  onClickHandler: PropTypes.func,
  linkTo: PropTypes.string,
  openInNewTab: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
	capturePosthogEvent: PropTypes.func,
};

OptionCard.defaultProps = {
  onClickHandler: () => {},
  linkTo: null,
  openInNewTab: false,
  className: '',
  disabled: false,
  type: ELEMENT_TYPES.DEFAULT,
	capturePosthogEvent: undefined,
};
