import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory, Link } from 'react-router-dom';
import moment from 'moment';

import {
	fetchSessionTutor as fetchSessionTutorAction,
	redirectToFormWithEnrolmentId,
} from '../../../actions/hub/hubEnrolmentManagementActions';
import alertIcon from '../../../assets/images/icon-filled-alert-info.svg';
import { PATH_HUB_CR_CHANGE_TUTOR, PATH_HUB_TTC_CHANGE_SESSION_KEEP_TUTOR } from '../../../util/pagePath';
import { TRACKING_TTC } from '../../../util/trackingClasses';
import InPageLoader from '../../../common/InPageLoader';
import ErrorBlock from '../../../common/ErrorBlock';
import { setTutorTypeDefault } from '../../../util/helpers';
import { isGroupEnrolment } from '../../../selectors/hubSelector';
import { DATE_FORMAT_1, DATE_FORMAT_TYPE_4, TTBM_CUSTOMER_NUMBER } from '../../../util/constants';
import { ButtonAsLink } from '@cluey/cluey-components';
import { useEnrolmentDetailQuery } from '../../../hooks/queries/useEnrolmentQuery';
import mapStudentInformationToTutorDetails from '../../TutorInfo/mapStudentInformationToTutorDetails';
import TutorDetailsCompact from '../../TutorInfo/TutorDetailsCompact';

const PackageTutorCard = ({ enrolmentTutorPhoto, enrolmentTutorSectionTitle, enrolmentTutorSectionText, children }) => {
	const baseClasses = `px-6 py-7 lg:py-[44px] lg:px-[60px]`;
	return (
		<div className={`border-t border-grey-1 ${baseClasses}`}>
			<div className="mb-5 flex items-center">
				<img
					src={enrolmentTutorPhoto}
					alt="tutor avatar"
					className="mr-5 h-[60px] w-[60px] rounded-full border border-grey-3 lg:mr-6"
				/>
				<div className="flex flex-col">
					<span className="mb-1 text-lg font-bold leading-[1.33]">{enrolmentTutorSectionTitle}</span>
					<span className="leading-[1.33]">{enrolmentTutorSectionText}</span>
				</div>
			</div>
			{children}
		</div>
	);
};

PackageTutorCard.propTypes = {
	enrolmentTutorPhoto: PropTypes.string.isRequired,
	enrolmentTutorSectionTitle: PropTypes.string.isRequired,
	enrolmentTutorSectionText: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

const TempTutor = ({
	isLoadingTempTutor,
	isLoadingTempTutorFailed,
	isGroup,
	nextSessionTutorNumber,
	enrolmentId,
	studentName,
	enrolmentTutor,
	sessionTutor,
	sessionsWithTempTutorChange,
	nextSessionDateWithTempTutor,
	nextSessionDateWithCurrentTutor,
	timezone,
	redirectToForm,
	fetchSessionTutor,
}) => {
	useEffect(() => {
		fetchSessionTutor({
			tutorNumber: nextSessionTutorNumber,
		});
		return undefined;
	}, [nextSessionTutorNumber, fetchSessionTutor]);

	const baseClasses = `px-6 py-7 lg:py-[44px] lg:px-[60px]`;
	const { tutorName, tutorState, tutorType, tutorPhoto, tutorDescription } = sessionTutor;
	const { tutorName: enrolmentTutorName, tutorPhoto: enrolmentTutorPhoto } = enrolmentTutor;

	const {
		isLoading: isLoadingEnrolmentDetail,
		data,
		error: enrolmentDetailError,
	} = useEnrolmentDetailQuery({ enrolmentId });
	const enrolmentDetail = data || {};
	const { studentInformation, englishTextStudied } = enrolmentDetail;
	const { skilledAt, highlyValuedFor } = mapStudentInformationToTutorDetails(studentInformation);

	const isSingleSessionAffected = sessionsWithTempTutorChange === 1;

	// Figure out the copy

	let tempTutorChangeNotice;
	if (isSingleSessionAffected) {
		const session = moment(nextSessionDateWithTempTutor).tz(timezone).format(DATE_FORMAT_TYPE_4);
		tempTutorChangeNotice = `${enrolmentTutorName} can’t make ${studentName}’s next session on ${session}.
    We’ve paired ${studentName} with a temporary tutor for this session.`;
	} else {
		tempTutorChangeNotice = `${enrolmentTutorName} will be taking a short break and will be unavailable for ${studentName}’s next ${sessionsWithTempTutorChange} sessions. 
    We’ve paired ${studentName} with a temporary tutor for these sessions.`;
	}

	const enrolmentTutorSectionTitle = isSingleSessionAffected
		? `Prefer to keep ${enrolmentTutorName}?`
		: `${enrolmentTutorName}'s return`;

	let multiSessionsAffectedSectionText;
	if (nextSessionDateWithCurrentTutor) {
		const time = moment(nextSessionDateWithCurrentTutor).tz(timezone).format(DATE_FORMAT_1);
		multiSessionsAffectedSectionText = `${enrolmentTutorName} will be back for your sessions from ${time}`;
	} else {
		multiSessionsAffectedSectionText = `${enrolmentTutorName} will be back for your sessions`;
	}
	const enrolmentTutorSectionText = isSingleSessionAffected
		? `${enrolmentTutorName} may be able to take the session on another day this week.`
		: multiSessionsAffectedSectionText;

	const history = useHistory();

	const onRequestChangeClick = () => {
		redirectToForm({
			enrolmentId,
			callback: () => {
				history.push(`${PATH_HUB_CR_CHANGE_TUTOR}?token=${enrolmentId}`);
			},
		});
	};

	const showChangeSessionCTA = isSingleSessionAffected && !isGroup;
	const isMatchingTutor = nextSessionTutorNumber === TTBM_CUSTOMER_NUMBER;

	return (
		<div className="h-full overflow-hidden rounded-lg border border-grey-2">
			{!isMatchingTutor && (
				<div className={`flex items-start bg-blue-1 ${baseClasses}`}>
					<img src={alertIcon} alt="alert" width="20" height="20" className="mr-3" />
					<div>
						<h5 className="mb-4 font-bold leading-[1.25]">Upcoming tutor change</h5>
						<p className="mb-0 leading-[1.25]">{tempTutorChangeNotice}</p>
					</div>
				</div>
			)}
			<div className={`${baseClasses}`}>
				{(isLoadingTempTutor || isLoadingEnrolmentDetail) && <InPageLoader position="relative" height="auto" />}
				{(isLoadingTempTutorFailed || enrolmentDetailError) && (
					<ErrorBlock errorMsg="Unable to load your temporary tutor detail, please try again later." />
				)}
				{!isLoadingTempTutor && !isLoadingTempTutorFailed && (
					<Fragment>
						<div className="mb-6 flex items-center border-b border-grey-2 pb-6 lg:mb-7 lg:pb-7">
							<img
								src={`${tutorPhoto}?v=1`}
								alt="tutor avatar"
								className="mr-5 h-[60px] w-[60px] rounded-full border border-grey-3 lg:mr-6"
							/>
							<div className="flex flex-col">
								<span className="mb-1 text-lg font-bold leading-[1.33]">{tutorName}</span>
								<span className="leading-[1.33]">
									{tutorState} {setTutorTypeDefault({ tutorType })}
								</span>
							</div>
						</div>
						{!isMatchingTutor && (
							<TutorDetailsCompact
								highlyValuedFor={highlyValuedFor}
								skilledAt={skilledAt}
								englishTextStudied={englishTextStudied}
							/>
						)}
						{tutorDescription && !isMatchingTutor && (
							<div className="mb-4 border-b border-grey-1 pb-4">
								<span className="mb-3 text-xs font-bold uppercase leading-none tracking-[1px]">
									A LITTLE ABOUT {tutorName}…
								</span>
								<p className="text-xs leading-[1.67]">{tutorDescription}</p>
							</div>
						)}
						{!isGroup && !isMatchingTutor && <ButtonAsLink onClick={onRequestChangeClick}>Request change</ButtonAsLink>}
					</Fragment>
				)}
			</div>

			{showChangeSessionCTA && (
				<PackageTutorCard
					enrolmentTutorSectionText={enrolmentTutorSectionText}
					enrolmentTutorSectionTitle={enrolmentTutorSectionTitle}
					enrolmentTutorPhoto={enrolmentTutorPhoto}
				>
					<Link
						to={`${PATH_HUB_TTC_CHANGE_SESSION_KEEP_TUTOR}?token=${enrolmentId}`}
						className={`
              text-sm leading-[1.14] tracking-normal ${TRACKING_TTC.CTA_CHANGE_SINGLE_SESSION}
              cursor-pointer text-sm text-blue-5 hover:text-purple-6 hover:underline disabled:opacity-60
            `}
					>
						Check {enrolmentTutorName}&apos;s availability and reschedule
					</Link>
				</PackageTutorCard>
			)}
		</div>
	);
};

TempTutor.propTypes = {
	isLoadingTempTutor: PropTypes.bool.isRequired,
	isLoadingTempTutorFailed: PropTypes.bool.isRequired,
	isGroup: PropTypes.bool.isRequired,
	nextSessionTutorNumber: PropTypes.string.isRequired,
	enrolmentId: PropTypes.string.isRequired,
	studentName: PropTypes.string.isRequired,
	enrolmentTutor: PropTypes.shape({
		tutorName: PropTypes.string,
		tutorPhoto: PropTypes.string,
	}).isRequired,
	sessionTutor: PropTypes.shape({
		tutorName: PropTypes.string,
		tutorState: PropTypes.string,
		tutorPhoto: PropTypes.string,
		tutorType: PropTypes.string,
		tutorDescription: PropTypes.string,
	}).isRequired,
	sessionsWithTempTutorChange: PropTypes.number.isRequired,
	nextSessionDateWithTempTutor: PropTypes.string,
	nextSessionDateWithCurrentTutor: PropTypes.string,
	timezone: PropTypes.string.isRequired,
	redirectToForm: PropTypes.func.isRequired,
	fetchSessionTutor: PropTypes.func.isRequired,
};

TempTutor.defaultProps = {
	nextSessionDateWithTempTutor: null,
	nextSessionDateWithCurrentTutor: null,
};

export { TempTutor as PresentationalTempTutor };
export const ConnectedTempTutor = connect(
	(state) => {
		const { firstFetch, fetchError } = state.ui.apiState.hubEnrolmentDetailPage.tempTutorChange;
		const {
			enrolmentId,
			studentName,
			tutorName,
			tutorPhoto,
			tempTutorChange: sessionTutor,
		} = state.hubEnrolmentDetailPage;

		return {
			isLoadingTempTutor: firstFetch,
			isLoadingTempTutorFailed: fetchError,
			isGroup: isGroupEnrolment(state),
			enrolmentId,
			studentName,
			enrolmentTutor: {
				tutorName,
				tutorPhoto,
			},
			sessionTutor,
			timezone: state.hubUser.timezone,
		};
	},
	(dispatch) => {
		return {
			redirectToForm: bindActionCreators(redirectToFormWithEnrolmentId, dispatch),
			fetchSessionTutor: bindActionCreators(fetchSessionTutorAction, dispatch),
		};
	}
)(TempTutor);
