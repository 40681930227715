import React, { Fragment } from 'react';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, Route } from 'react-router-dom';
import { FullPageLoader } from '../../components/Loader/Loader';
import PageError from '../PageError';

import { isDataLoadedToInitApp } from '../../selectors/hubSelector';
import { getCredentials, userSignout } from '../../actions/hub/hubAuthPagesActions';
import { AUTHENTICATION_STATUS, LOCALSTORAGE_KEEP_ME_LOGGED_IN } from '../../util/constants';
import { PATH_HUB_LOG_IN, PATH_HUB_ONBOARDING } from '../../util/pagePath';

import withGlobalInfobar from '../../v2/common/global-infobar/withGlobalInfobar';

const ProtectedRoute = (props) => {
  const { authenticationStatus, checkUserStatus, signOut, location, isClockSkewDetected, isDataReady, path } = props;

  if (
    authenticationStatus !== AUTHENTICATION_STATUS.AUTHENTICATED &&
    authenticationStatus !== AUTHENTICATION_STATUS.AUTHENTICATION_ERROR
  ) {
    // Note, when landing on onboarding page with KEEP_ME_LOGGED_IN unticked,
    // since user is still UNAUTHENTICATED, they will be redirected to log in form.
    // That's why we need to exclude onboarding page
    if (localStorage.getItem(LOCALSTORAGE_KEEP_ME_LOGGED_IN) === 'false' && location.pathname !== PATH_HUB_ONBOARDING) {
      signOut();
      return;
    }

    const staffToken = Cookies.get('staffToken');
    // Note: staff login issue fix -
    if (staffToken) {
      signOut();
    } else {
      checkUserStatus();
    }
  }

  const Component = props.component;

  if (isClockSkewDetected) {
    return <PageError activePage="redirect-clock-skew-handle" />;
  }

  return (
    <Fragment>
      {(authenticationStatus === AUTHENTICATION_STATUS.INIT || !isDataReady) && <FullPageLoader />}
      {authenticationStatus === AUTHENTICATION_STATUS.AUTHENTICATED && isDataReady && (
        <Route path={path} component={withGlobalInfobar(Component)} />
      )}
      {authenticationStatus === AUTHENTICATION_STATUS.UN_AUTHENTICATED ||
        (authenticationStatus === AUTHENTICATION_STATUS.AUTHENTICATION_ERROR && <Redirect to={PATH_HUB_LOG_IN} />)}
    </Fragment>
  );
};

ProtectedRoute.propTypes = {
  authenticationStatus: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  isClockSkewDetected: PropTypes.bool.isRequired,
  isDataReady: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  path: PropTypes.string.isRequired,
  checkUserStatus: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    const { login } = state;
    return {
      authenticationStatus: login.authenticationStatus,
      isClockSkewDetected: login.isClockSkewDetected,
      isDataReady: isDataLoadedToInitApp(state),
    };
  },
  (dispatch) => {
    return {
      checkUserStatus: bindActionCreators(getCredentials, dispatch),
      signOut: bindActionCreators(userSignout, dispatch),
    };
  }
)(ProtectedRoute);
