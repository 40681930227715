import { Container } from '@cluey/cluey-components';
import { PropsWithChildren } from 'react';

const PageContent = ({ children }: PropsWithChildren) => (
  <Container className="max-w-6xl mb-20 flex flex-grow lg:mb-0 !px-5">
    <div className="relative w-full pt-5 lg:pt-11">{children}</div>
  </Container>
);

export default PageContent;
