import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory, Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { ButtonAsLink } from '@cluey/cluey-components';

import InPageLoader from '../../common/InPageLoader';
import { isPreviousTutorLoading } from '../../selectors/loaderSelector';
import { isPreviousTutorLoadingFailed } from '../../selectors/errorSelector';
import { redirectToFormWithEnrolmentId } from '../../actions/hub/hubEnrolmentManagementActions';
import { isEnrolmentCancelled, isEnrolmentFinished, isGroupEnrolment } from '../../selectors/hubSelector';
import { PATH_HUB_CR_CHANGE_TUTOR, PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_KEEP_PREV_TUTOR } from '../../util/pagePath';
import { isTutorResigned, setTutorTypeDefault } from '../../util/helpers';

import noTutorAvatar from '../../assets/images/no-tutor-avatar.png';
import alertIcon from '../../assets/images/icon-filled-alert-info.svg';
import { getEnrolmentSchedule } from '../../selectors/enrolmentManagementSelector';
import { DATE_FORMAT_1, TTBM_CUSTOMER_NUMBER } from '../../util/constants';
import { useEnrolmentDetailQuery } from '../../hooks/queries/useEnrolmentQuery';
import mapStudentInformationToTutorDetails from '../TutorInfo/mapStudentInformationToTutorDetails';
import TutorDetailsCompact from '../TutorInfo/TutorDetailsCompact';

const Tutor = ({
	isLoadingPastTutor,
	isPastTutorLoaded,
	isGroup,
	isCancelledOrFinished,
	enrolmentId,
	redirectToForm,
	pastTutor,
	studentName,
	enrolmentSchedule,
	timezone,
	sessionWithNewTutor,
	hasPermanentTutorChange,
	tutorChangeReason,
}) => {
	const { isLoading: isLoadingTutorInfo, data } = useEnrolmentDetailQuery({ enrolmentId });
	const enrolmentDetail = data || {};
	const {
		studentInformation,
		tutorName,
		tutorState,
		tutorPhoto,
		tutorType,
		tutorDescription,
		tutorNumber,
		englishTextStudied,
	} = enrolmentDetail;
	const { skilledAt, highlyValuedFor } = mapStudentInformationToTutorDetails(studentInformation);

	const history = useHistory();
	if (isEmpty(tutorName) || isCancelledOrFinished) {
		return (
			<div className="rounded-lg border border-grey-2 px-5 py-6 lg:p-10">
				<div className="flex flex-col items-center">
					<img
						src={noTutorAvatar}
						alt="tutor avatar"
						className="mb-4 h-[60px] w-[60px] rounded-full border border-[#d8d9d8] lg:h-[77px] lg:w-[77px]"
					/>
					<span className="mb-1 text-lg font-bold leading-snug">No tutor</span>
					{isCancelledOrFinished && (
						<p className="text-center text-base leading-snug">
							A new tutor will be assigned if you wish to re-activate the enrolment.
						</p>
					)}
				</div>
			</div>
		);
	}

	const onRequestChangeClick = () => {
		redirectToForm({
			enrolmentId,
			callback: () => {
				history.push(`${PATH_HUB_CR_CHANGE_TUTOR}?token=${enrolmentId}`);
			},
		});
	};

	if (isLoadingPastTutor || isLoadingTutorInfo) {
		return (
			<div className="overflow-hidden rounded-lg border border-grey-1">
				<InPageLoader position="relative" height="auto" />
			</div>
		);
	}

	const baseClasses = `px-5 py-6 !lg:px-11 !lg:py-[60px]`;
	const { tutorName: pastTutorName, tutorPhoto: pastTutorPhoto } = pastTutor;
	const schedules = enrolmentSchedule.join(', ');

	const { previousSessionWithNewTutor, nextSessionWithNewTutor } = sessionWithNewTutor || {};

	const hadSessionWithNewTutor =
		!!previousSessionWithNewTutor && moment(previousSessionWithNewTutor).tz(timezone).isSameOrBefore(moment(), 'hour');
	const newTutorEffectiveDate =
		nextSessionWithNewTutor && moment(nextSessionWithNewTutor).tz(timezone).format(DATE_FORMAT_1);

	const showPrevTutor = !isTutorResigned({ tutorChangeReason });
	const isMatchingTutor = tutorNumber === TTBM_CUSTOMER_NUMBER;
	const showTopNotification =
		hasPermanentTutorChange && isPastTutorLoaded && !hadSessionWithNewTutor && !isMatchingTutor;
	// hide entry to keep previous flow for bug fixing
	const showBottomCTA =
		hasPermanentTutorChange && showPrevTutor && isPastTutorLoaded && !isGroup && !hadSessionWithNewTutor;

	return (
		<div className="h-full overflow-hidden rounded-lg border border-grey-2">
			{showTopNotification && (
				<div className={`flex items-start bg-blue-1 ${baseClasses}`}>
					<img src={alertIcon} alt="alert" width="20" height="20" className="mr-3" />
					<div>
						<h5 className="mb-4 font-bold leading-tight">Upcoming tutor change</h5>
						<p className="mb-0 leading-tight">
							Due to changes in availability, {pastTutorName} will no longer be able to take sessions on {schedules}.
							We’ve paired {studentName} with a new tutor who will take sessions from {newTutorEffectiveDate}.
						</p>
					</div>
				</div>
			)}
			<div className={`${baseClasses}`}>
				<div className="mb-6 flex items-center border-b border-grey-2 pb-6 lg:mb-7 lg:pb-7">
					<img
						src={`${tutorPhoto}?v=1`}
						alt="tutor avatar"
						className="mr-5 h-[60px] w-[60px] rounded-full border-[#d8d9d8] lg:mr-6 lg:h-[77px] lg:w-[77px]"
					/>
					<div className="flex flex-col">
						<span className="mb-1 text-lg font-bold leading-snug">{tutorName}</span>
						<span className="leading-snug">
							{tutorState} {setTutorTypeDefault({ tutorType })}
						</span>
					</div>
				</div>
				{!isMatchingTutor && (
					<TutorDetailsCompact
						highlyValuedFor={highlyValuedFor}
						skilledAt={skilledAt}
						englishTextStudied={englishTextStudied}
					/>
				)}
				{!isMatchingTutor && tutorDescription && (
					<div className="mb-4 border-b border-grey-1 pb-4">
						<span className="mb-3 text-xs font-bold uppercase leading-none tracking-[1px]">
							A LITTLE ABOUT {tutorName}…
						</span>
						<p className="text-xs leading-relaxed">{tutorDescription}</p>
					</div>
				)}
				{!isGroup && !isMatchingTutor && <ButtonAsLink onClick={onRequestChangeClick}>Request change</ButtonAsLink>}
			</div>
			{showBottomCTA && (
				<div className={`border-t border-grey-1 ${baseClasses}`}>
					<div className="mb-5 flex items-center">
						<img
							src={pastTutorPhoto}
							alt="tutor avatar"
							className="mr-5 h-[60px] w-[60px] rounded-full border-[#d8d9d8] lg:mr-6 lg:h-[77px] lg:w-[77px]"
						/>
						<div className="flex flex-col">
							<span className="mb-1 text-lg font-bold leading-snug">Prefer to keep {pastTutorName}?</span>
							<span className="leading-snug">
								Select a new ongoing schedule from {pastTutorName}’s updated availability.
							</span>
						</div>
					</div>
					<Link
						to={`${PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_KEEP_PREV_TUTOR}?token=${enrolmentId}`}
						className="cursor-pointer text-sm leading-[1.14] tracking-normal text-blue-5 hover:text-purple-6 hover:underline disabled:opacity-60"
					>
						View availability and change schedule
					</Link>
				</div>
			)}
		</div>
	);
};

Tutor.propTypes = {
	isLoadingPastTutor: PropTypes.bool.isRequired,
	isPastTutorLoaded: PropTypes.bool.isRequired,
	enrolmentSchedule: PropTypes.arrayOf(PropTypes.string),
	isGroup: PropTypes.bool.isRequired,
	isCancelledOrFinished: PropTypes.bool.isRequired,
	enrolmentId: PropTypes.string.isRequired,
	redirectToForm: PropTypes.func.isRequired,
	pastTutor: PropTypes.shape({
		tutorName: PropTypes.string,
		tutorPhoto: PropTypes.string,
	}),
	studentName: PropTypes.string.isRequired,
	timezone: PropTypes.string.isRequired,
	sessionWithNewTutor: PropTypes.shape({
		nextSessionWithNewTutor: PropTypes.string,
	}),
	hasPermanentTutorChange: PropTypes.bool,
	tutorChangeReason: PropTypes.string,
};

Tutor.defaultProps = {
	pastTutor: {},
	enrolmentSchedule: [],
	sessionWithNewTutor: {},
	hasPermanentTutorChange: false,
	tutorChangeReason: null,
};

export default connect(
	(state) => {
		const {
			enrolmentId,
			alternativeTutor: pastTutor,
			studentName,
			sessionWithNewTutor,
			automatedTutorChange: hasPermanentTutorChange,
			tutorChangeReason,
		} = state.hubEnrolmentDetailPage;
		const { timezone } = state.hubUser;
		return {
			isLoadingPastTutor: isPreviousTutorLoading(state),
			isPastTutorLoaded: !isPreviousTutorLoadingFailed(state),
			studentName,
			enrolmentId,
			enrolmentSchedule: getEnrolmentSchedule(state),
			isGroup: isGroupEnrolment(state),
			isCancelledOrFinished: isEnrolmentCancelled(state) || isEnrolmentFinished(state),
			pastTutor,
			timezone,
			sessionWithNewTutor,
			hasPermanentTutorChange,
			tutorChangeReason,
		};
	},
	(dispatch) => {
		return {
			redirectToForm: bindActionCreators(redirectToFormWithEnrolmentId, dispatch),
		};
	}
)(Tutor);
