import env from '../env';
import type { datadogLogs as IDatadogLogs } from '@datadog/browser-logs';
import type { datadogRum as IDatadogRum } from '@datadog/browser-rum-slim';

let logs: typeof IDatadogLogs | null = null;

type User = Parameters<typeof IDatadogLogs.setUser>[0];

let userAttributes: User | null = null;

type LogQueue = {
  log: Parameters<typeof IDatadogLogs['logger']['log']>[];
  info: Parameters<typeof IDatadogLogs['logger']['info']>[];
  warn: Parameters<typeof IDatadogLogs['logger']['warn']>[];
  error: Parameters<typeof IDatadogLogs['logger']['error']>[];
};

const logQueue: LogQueue = {
  log: [],
  info: [],
  warn: [],
  error: [],
};

type LogParams<Level extends keyof LogQueue> = Parameters<typeof IDatadogLogs['logger'][Level]>;

const logger = {
  log: (...args: LogParams<'log'>) => {
    if (logs) {
      logs.logger.log(...args);
    } else {
      logQueue.log.push(args);
    }
  },
  info: (...args: LogParams<'info'>) => {
    if (logs) {
      logs.logger.info(...args);
    } else {
      logQueue.info.push(args);
    }
  },
  warn: (...args: LogParams<'warn'>) => {
    if (logs) {
      logs.logger.warn(...args);
    } else {
      logQueue.warn.push(args);
    }
  },
  error: (...args: LogParams<'error'>) => {
    if (logs) {
      logs.logger.error(...args);
    } else {
      logQueue.error.push(args);
    }
  },
};

let datadogLogsFetchPromise: ReturnType<typeof fetchDatadogLogs> | null = null;

const getDatadogLogs = async () => {
  if (logs) {
    return logs;
  } else if (datadogLogsFetchPromise) {
    return datadogLogsFetchPromise;
  } else {
    datadogLogsFetchPromise = fetchDatadogLogs();
    return datadogLogsFetchPromise;
  }
};

export const fetchDatadogLogs = () =>
  new Promise((resolve, reject) => {
    import('@datadog/browser-logs')
      .then(({ datadogLogs }) => {
        logs = datadogLogs;

        logs.init({
          clientToken: env.REACT_APP_DATADOG_CLIENT_TOKEN,
          site: env.REACT_APP_DATADOG_SITE,
          forwardErrorsToLogs: true, // forwarding console.error logs, uncaught exceptions and network errors to Datadog.
          forwardConsoleLogs: [],
          sessionSampleRate: 100,
          service: env.REACT_APP_DATADOG_SERVICE,
          env: env.REACT_APP_DATADOG_ENV,
          version: env.REACT_APP_VERSION,
        });

        logs.onReady(() => {
          console.log('%c[Datadog logs initialized]', 'color: #f07124; font-weight: bold');
          if (userAttributes) {
            logs.setUser(userAttributes);
          }
          logQueue.info.forEach((args) => logs.logger.info(...args));
          logQueue.log.forEach((args) => logs.logger.log(...args));
          logQueue.warn.forEach((args) => logs.logger.warn(...args));
          logQueue.error.forEach((args) => logs.logger.error(...args));

          resolve(logs);
        });
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });

export const datadogLogs = {
  logger,
  get: getDatadogLogs,
};

export const setLogUserAttributes = (attributes: User) => {
  if(rum) {
    rum.setUser(attributes);
  }
  if (logs) {
    logs.setUser(attributes);
  } else {
    userAttributes = attributes;
  }
};

let rum: typeof IDatadogRum | null = null;

const rumErrors: Parameters<typeof IDatadogRum['addError']>[] = [];

export const setError = (error: Parameters<typeof IDatadogRum['addError']>[0]) => {
  if (rum) {
    rum.addError(error);
  } else {
    rumErrors.push([error]);
  }
};

let datadogRumFetchPromise: ReturnType<typeof fetchDatadogRum> | null = null;

const getDatadogRum = async () => {
  if (rum) {
    return rum;
  } else if (datadogRumFetchPromise) {
    return datadogRumFetchPromise;
  } else {
    datadogRumFetchPromise = fetchDatadogRum();
    return datadogRumFetchPromise;
  }
};

export const fetchDatadogRum = () =>
  new Promise((resolve, reject) => {
    import('@datadog/browser-rum-slim')
      .then(({ datadogRum }) => {
        rum = datadogRum;

        rum.init({
          applicationId: env.REACT_APP_DATADOG_APPLICATION_ID,
          clientToken: env.REACT_APP_DATADOG_CLIENT_TOKEN,
          site: env.REACT_APP_DATADOG_SITE,
          service: env.REACT_APP_DATADOG_SERVICE,
          version: env.REACT_APP_VERSION,
          trackInteractions: true,
          trackFrustrations: true,
          trackLongTasks: true,
          sessionSampleRate: 100,
          replaySampleRate: 0,
          allowedTracingUrls: [/https:\/\/.*\.clueylearn\.com/],
          env: env.REACT_APP_DATADOG_ENV,
        });

        rum.onReady(() => {
          console.log('%c[Datadog rum initialized]', 'color: #f07124; font-weight: bold');
          if (userAttributes) {
            rum.setUser(userAttributes);
          }
          if (env.REACT_APP_DATADOG_RECORD_SESSION) {
            rum.startSessionReplayRecording();
          }
          rumErrors.forEach((args) => rum.addError(...args));

          resolve(rum);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });

export const datadogRum = {
  setError,
  get: getDatadogRum,
};
