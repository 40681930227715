import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { enrolmentScheduleType, enrolmentScheduleDefault } from '../../types/hubPropTypes';
import { SCHEDULE_DATE_FORMAT, SESSION_TIME_FORMAT } from '../../util/constants';

export const EnrolmentBrief = ({
	tutorName,
	tutorPhoto,
	enrolmentSchedule,
	shownPreviousTutor,
	leftColumnTitle,
	leftColumnContent,
	renderSchedule,
	additionalInfo,
}) => {
	return (
		<div className="mb-10 rounded-lg bg-slate-1 px-6 py-5 lg:mb-11 lg:px-16">
			<div className="flex flex-col items-center justify-between rounded-lg lg:flex-row ">
				<div className="w-full border-b border-grey-3 pb-5 lg:border-none lg:pb-0">
					<span className="mb-2 text-xs font-bold uppercase tracking-[1px]">{leftColumnTitle}</span>
					{enrolmentSchedule.length > 0 &&
						enrolmentSchedule.map(
							renderSchedule ||
								((s) => {
									const date = `${moment
										.utc(s.schedule, SCHEDULE_DATE_FORMAT)
										.format(`dddd[s at] ${SESSION_TIME_FORMAT}`)}`;

									return (
										<span key={date} className="block text-lg leading-[1.33]">
											{date}
										</span>
									);
								})
						)}
					{leftColumnContent && <span className="block text-lg leading-[1.33]">{leftColumnContent}</span>}
				</div>
				<div className="flex w-full flex-row-reverse items-center pt-6 lg:flex-row lg:pt-0">
					{tutorPhoto && (
						<img
							src={tutorPhoto}
							alt="tutor avatar"
							className="ml-auto h-10 w-10 rounded-full border border-[#d8d9d8] lg:mr-6 lg:h-[60px] lg:w-[60px]"
						/>
					)}
					{tutorName && (
						<div className="flex flex-col">
							<span className="mb-2 text-xs font-bold uppercase tracking-[1px]">
								{shownPreviousTutor ? 'Tutor' : 'Current tutor'}
							</span>
							<span className="text-lg leading-[1.33]">{tutorName}</span>
						</div>
					)}
				</div>
			</div>
			{additionalInfo && (
				<div>
					<div className="my-5 w-full border-b border-grey-3 lg:pb-0" />
					{additionalInfo}
				</div>
			)}
		</div>
	);
};

EnrolmentBrief.propTypes = {
	tutorName: PropTypes.string,
	tutorPhoto: PropTypes.string,
	enrolmentSchedule: enrolmentScheduleType,
	shownPreviousTutor: PropTypes.bool,
	leftColumnTitle: PropTypes.string,
	leftColumnContent: PropTypes.string,
	renderSchedule: PropTypes.func,
	additionalInfo: PropTypes.node,
};

EnrolmentBrief.defaultProps = {
	tutorName: '',
	tutorPhoto: '',
	enrolmentSchedule: enrolmentScheduleDefault,
	shownPreviousTutor: false,
	leftColumnTitle: 'current schedule',
	leftColumnContent: '',
	renderSchedule: undefined,
	additionalInfo: null,
};
