import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Radio as ClueyRadio } from '@cluey/cluey-components';

export const Radio = ({
  groupName,
  text,
  value,
  checked,
  disabled,
  renderBelowLabel,
  renderNextToLabel,
  containerClassName,
  wrapper,
  wrapperClassName,
  subText,
  textBelowSubText,
  inLineSubText,
  onChange = () => {},
  muteSubText,
}) => {
  const id = `${groupName}__${value}`;
  // const labelClasses = classNames(
  //   'cluey-radio__wrapper',
  //   labelClassName,
  //   { 'cursor-pointer': !disabled },
  //   { 'cursor-not-allowed opacity-disabled': disabled },
  //   subText && 'flex-column align-items-start'
  // );

  const subTextClasses = classNames(
    'text-xs',
    { 'text-custom-4': muteSubText },
    textBelowSubText && 'mb-2',
    !textBelowSubText && 'mb-0'
  );
  const textBelowSubTextClasses = classNames(
    'text-xs text-red-5 uppercase font-bold',
    'mb-0'
  );

  const labelEl = (
    <Fragment>
      <div className={classNames('flex items-center', containerClassName)}>
        <ClueyRadio
          id={id}
          value={value}
          group={groupName}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <label
          htmlFor={id}
          className="flex cursor-pointer flex-col items-start pl-4"
        >
          <div>
            <span>{text} </span>
            {inLineSubText && (
              <span className={subTextClasses}>{inLineSubText}</span>
            )}
          </div>
          {subText && <p className={subTextClasses}>{subText}</p>}
          {textBelowSubText && (
            <p className={textBelowSubTextClasses}>{textBelowSubText}</p>
          )}
        </label>
        {typeof renderNextToLabel === 'function' && renderNextToLabel()}
      </div>
      {typeof renderBelowLabel === 'function' && (
        <div className="cluey-radio__below-option-content">
          {renderBelowLabel({ checked })}
        </div>
      )}
    </Fragment>
  );

  if (wrapper) {
    return <div className={wrapperClassName}>{labelEl}</div>;
  }

  return labelEl;
};
Radio.propTypes = {
  groupName: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  containerClassName: PropTypes.string,
  wrapper: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  renderBelowLabel: PropTypes.func,
  renderNextToLabel: PropTypes.func,
  subText: PropTypes.string,
  textBelowSubText: PropTypes.string,
  inLineSubText: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  onChange: PropTypes.func,
  muteSubText: PropTypes.bool,
};
Radio.defaultProps = {
  labelClassName: 'text-base font-body mb-0',
  checked: false,
  disabled: false,
  containerClassName: 'flex items-start cursor-pointer mb-4',
  wrapper: false,
  wrapperClassName: '',
  renderBelowLabel: null,
  renderNextToLabel: null,
  subText: null,
  textBelowSubText: null,
  inLineSubText: null,
  muteSubText: true,
  groupValue: undefined,
};
