/* eslint-disable */
export enum MS_BILLING_AND_PAYMENTS_CHOICES {
  UPDATE_PAYMENT_AND_BILLING_DETAILS = 'Update payment and billing details',
  VIEW_INVOICES_AND_STATEMENTS = 'View invoices and statements',
  QUERY_A_PAYMENT = 'Query a payment',
  REQUEST_CREDIT_REFUND = 'Request a credit or refund',
  REVIEW_SERVICE_FEES = 'Review service fees',
  REVIEW_REFUND_POLICY = 'Review refund policy',
  FOLLOW_UP_ON_A_CREDIT_REQUEST = 'Follow up on a credit or refund',
  REVIEW_PAYMENT_METHOD_OPTIONS = 'Review payment method options',
}
