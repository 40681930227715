import { Fragment } from 'react';
import { AffectedSessionTitle } from '../AffectedSessions/AffectedSessionTitle';
import { isEmpty } from 'lodash';
import { ListItem } from '../AffectedSessions/ListItem';
import moment, { Moment } from 'moment';
import { formatSessionAffectedByPause } from '../../../../util/helper/sessions';
import { DATE_FORMAT_TYPE_1 } from '../../../../util/constants';
import type { Session } from '../../../../types/hubInterfaces';

interface Props {
  sessions: Array<Session>;
  timezone: string;
  resumeSessionDate: string;
  pauseFromDate: string | Moment;
  isManagePauseConfirmed?: boolean;
  isRestart?: boolean;
}

const AffectedSessionsSummary = ({
  pauseFromDate,
  resumeSessionDate,
  sessions,
  timezone,
  isManagePauseConfirmed,
  isRestart,
}: Props) => {
  const formatDate = (date) => {
    return `• ${moment(date).tz(timezone).format(DATE_FORMAT_TYPE_1)}`;
  };


  return (
    <Fragment>
      {!isEmpty(sessions) && (
        <div className="mb-5 lg:mb-8">
          <AffectedSessionTitle className='mt-8' text="Pause for these sessions" />
          <ul className="fs-15px list-unstyled leading-snug">
            {sessions.map((s, index) => {
              const { startTime } = s;
              return (
                <ListItem key={`${startTime}-${index}`} text={formatSessionAffectedByPause({ time: startTime, timezone })} />
              );
            })}
          </ul>
        </div>
      )}

      {isEmpty(sessions) && pauseFromDate && (
        <div className="mb-5 lg:mb-8">
          <AffectedSessionTitle className='mt-8' text={`PAUSE${isManagePauseConfirmed ?"D" : ""} FROM`} />
          <ul className="fs-15px list-unstyled leading-snug">
            <ListItem text={formatDate(pauseFromDate)} />
          </ul>
        </div>
      )}

      {resumeSessionDate &&
        !resumeSessionDate.toLowerCase().includes('invalid date') &&
        !resumeSessionDate.toLowerCase().includes('error') && (
          <div className='mb-8'>
            {isManagePauseConfirmed ? (
              <AffectedSessionTitle text="Sessions will resume from" />
            ) : (
              <AffectedSessionTitle text="AND RESUME FROM" />
            )}
            <ul className="fs-15px list-unstyled leading-snug">
              <ListItem
                text={formatSessionAffectedByPause({
                  time: resumeSessionDate,
                  timezone,
                  isRestart,
                })}
              />
            </ul>
          </div>
        )}
    </Fragment>
  );
}

export default AffectedSessionsSummary;