import React from 'react';
import PropTypes from 'prop-types';

const EnrolmentItem = ({
  name,
  type,
  subject,
  sessionReportId,
  practiceLink,
}) => {
  const isGroupSession = type.toLowerCase() === 'group';

  return (
    <div className="px-6 py-4 mb-4 bg-white border rounded border-slate-3 font-bold enrolment">
      <div className="relative">
        <span>{ name }, enrolled in { subject }</span>
        {
          isGroupSession && (
            <span className="py-1 px-3 group">Group</span>
          )
        }
      </div>
      <div className="actions">
        <a
          href={`/latest/?token=${sessionReportId}`}
          className="inline-block text-primary hover:underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          View learning activity
        </a>
        {
          practiceLink !== null && (
            <a
              href={practiceLink}
              className="hidden lg:inline-block ml-3 pl-3 text-primary hover:underline relative"
              target="_blank"
              rel="noopener noreferrer"
            >
              Visit practice
            </a>
          )
        }
      </div>
    </div>
  );
};

EnrolmentItem.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  sessionReportId: PropTypes.string,
  practiceLink: PropTypes.string,
};

EnrolmentItem.defaultProps = {
  sessionReportId: null,
  practiceLink: null,
};

export default EnrolmentItem;
