import { SR_STARTUP } from '../actions/sessionReportAppLifecycleSteps';
import {
  APP_VIEW_DEFAULT,
  APP_CONTACT_FLOW_GENERAL,
  AUTHENTICATION_STATUS,
  STUDENT_PROGRESS_TAB_VIEWS,
  STUDENT_PROGRESS_URL_PARAM_TAB,
} from '../util/constants';

const query = new URLSearchParams(document.location.search);

const initialState = {
  report: {
    studentName: null,
    subject: null,
    yearLevel: null,
    mode: null,
    discipline: null,
    startingDate: null,
    eoy: false,
    activeTab: query.get(STUDENT_PROGRESS_URL_PARAM_TAB) || STUDENT_PROGRESS_TAB_VIEWS.SESSION_REPORT,
    // this value will be set to false after another discipline is chosen from the dropdown
    useEoyToPickDiscipline: Boolean(query.get('eoy')),
    enrolledSubjects: [
      /*
            {
            subject: 'Math',
            token: 'XXXX'
             */
    ],
    firstSessionDate: null, // timestamp date
    completedSessions: 0, // number
  },
  login: {
    initialToken: null,
    customerId: null,
    packageCode: null,
    role: null,
    authenticationStatus: AUTHENTICATION_STATUS.INIT,
    isClockSkewDetected: false,
    country: null,
    credentials: {
      accessKey: null,
      secret: null,
      sessionToken: null,
      expiration: null,
      region: null,
      staffToken: null,
    },
  },
  ui: {
    appLifecycle: {
      currentStep: SR_STARTUP,
    },
    apiState: {
      summary: {
        firstFetch: true,
        retryEnabled: true,
        fetchError: false,
      },
      studentProgress: {
        firstFetch: true,
        retryEnabled: true,
        fetchError: false,
        modal: {
          fetchError: false,
        },
        discipline: {
          firstFetch: true,
          fetchError: false,
        },
      },
      activities: {
        firstFetch: true,
        retryEnabled: true,
        fetchError: false,
        isLoading: false,
        currentPage: null,
        nextPage: null,
        pageSize: null,
        totalNumberOfActivities: null,
        totalNumberOfPages: null,
      },
      login: {
        firstFetch: true,
        retryEnabled: true,
        fetchError: false,
      },
      upcomingSessions: {
        firstFetch: true,
        retryEnabled: true,
        fetchError: false,
        isLoading: false,
        ucSessionsVisible: true,
      },
      contactUsFlow: {
        isLoading: false,
        fetchError: false,
        requestType: '',
      },
      triggerPayment: {
        isLoading: false,
        fetchError: false,
      },
      hubAuthPage: {
        retryEnabled: false,
        fetchError: false,
        isLoading: false,
        errorMsg: '',
      },
      hubUpcomingSessionsPage: {
        retryEnabled: true,
        fetchError: false,
        isLoading: false,
        hasFetched: false,
      },
      hubCompletedSessionsPage: {
        retryEnabled: true,
        fetchError: false,
        isLoading: false,
        hasFetched: false,
      },
      hubSchoolTermsPublicHolidays: {
        retryEnabled: true,
        fetchError: false,
        isLoading: false,
        hasFetched: false,
      },
      hubActiveEnrolmentsPage: {
        retryEnabled: true,
        fetchError: false,
        isLoading: false,
        hasFetched: false,
        forceToReload: false,
      },
      hubPastEnrolmentsPage: {
        retryEnabled: true,
        fetchError: false,
        isLoading: false,
        hasFetched: false,
      },
      hubEnrolmentDetailPage: {
        firstFetch: true,
        retryEnabled: true,
        fetchError: false,
        courseOverview: {
          firstFetch: true,
          fetchError: false,
        },
        progressSummary: {
          firstFetch: true,
          fetchError: false,
        },
        previousTutor: {
          firstFetch: false,
          fetchError: false,
        },
        tutorDetail: {
          firstFetch: false,
          fetchError: false,
        },
        tutorAvailability: {
          firstFetch: false,
          fetchError: false,
          errorMsg: null,
        },
        managePauseGetNewResumeSession: {
          firstFetch: false,
          fetchError: false,
        },
        managePauseNewResumeDateRequest: {
          firstFetch: false,
          fetchError: false,
        },
        tempTutorChange: {
          firstFetch: false,
          fetchError: false,
        },
      },
      hubChangeSchedulePage: {
        firstFetch: false,
        retryEnabled: true,
        fetchError: false,
      },
      hubChangeGroupPage: {
        firstFetch: false,
        fetchError: false,
        changingGroup: {
          isLoading: false,
          fetchError: false,
        },
      },
      hubAccountPage: {
        fetchError: false,
        isLoading: false,
      },
      hubContactPage: {
        retryEnabled: true,
        fetchError: false,
        isLoading: false,
        isSubmitted: false,
        errorMsg: '',
        resumeSessionDate: {
          firstFetch: true,
          fetchError: false,
        },
      },
      hubPauseSessionsPrefilled: {
        fetchResumeSession: {
          firstFetch: false,
          fetchError: false,
        },
        submitPauseRequest: {
          firstFetch: false,
          fetchError: false,
        },
      },
      hubChangeSessionWithEnrolmentTutor: {
        tutorAvailabilities: {
          firstFetch: true,
          fetchError: false,
          errorMsg: null,
        },
        changeSessionRequest: {
          firstFetch: false,
          fetchError: false,
          errorMsg: null,
        },
      },
      schoolsUpcomingSessions: {
        firstFetch: false,
        isLoading: false,
        fetchError: false,
      },
      summerMode: {
        codeCamp: {
          isSubmitting: false,
          isSubmitted: false,
          isSubmissionFailed: false,
        },
        saveSummerModeData: {
          isSubmitting: false,
          isSubmitted: false,
          isSubmissionFailed: false,
        },
      },
    },
    appView: APP_VIEW_DEFAULT,
  },
  diagnostics: {
    clockSkew: false,
    timeOffset: 0,
  },
  nextSession: {
    timezone: null,
    tutorName: null,
    startTime: null,
    endTime: null,
    duration: null,
    sessionId: null,
    manageSessionLink: {
      token: null,
      host: null,
      link: null,
    },
  },

  upcomingSessions: {
    token: null,
    filteredStudentsNames: [],
    name: null,
    timezone: 'Australia/Sydney',
    role: null,
    students: [],
    holidays: [],
    sessions: [],
    customerNumber: null,
  },

  contactUsFlow: {
    flowType: APP_CONTACT_FLOW_GENERAL,
    sessionDetail: '',
    // just need any enrolment id of a household or a student so salesforce could link the case to correct contact
    enrolmentId: '',
    studentSessionId: '',
    formScreen: {
      title: null,
      ciClass: null,
      caseCategory: '',
      caseType: '',
      recordType: '',
    },
    selectedSessionForHubForm: {
      uniqueEnrolmentId: null,
      student: null,
      subject: null,
      studentSessionId: null,
      startTime: null,
      endTime: null,
      tutor: null,
    },
  },

  triggerPayment: {
    succeededSessions: [],
    failedSessions: [],
  },
  refundPolicy: {},

  hubUser: {
    name: '',
    email: '',
    tempPassword: '',
    timezone: '',
    customerLinkId: '',
  },

  hubSessionsPage: {
    upcomingSessions: [],
    completedSessions: [],
    schoolTermsPublicHolidays: [],
  },

  hubEnrolmentsPage: {
    activeEnrolments: [],
    pastEnrolments: [],
    timestampOfFetchingResumeSessions: null,
  },

  hubEnrolmentDetailPage: {
    enrolmentId: '',
    enrolmentSfid: '',
    enrolmentPausedFrom: null,
    enrolmentPausedTo: null,
    enrolmentSchedule: [],
    enrolmentStatus: null,
    enrolmentSubject: '',
    enrolmentDiscipline: '',
    enrolmentType: null,
    enrolmentYearLevel: '',
    nextPaymentDate: null,
    nextSessionDate: null,
    nextSessionEndDate: null,
    nextSessionStudentSessionId: '',
    packageSfid: '',
    sessionFrequency: '',
    studentName: '',
    tutorDescription: null,
    tutorName: null,
    tutorNumber: '',
    tutorSfId: '',
    tutorPhoto: null,
    tutorState: null,
    tutorType: null,
    courseOverview: {},
    progressSummary: {},
    customerNumber: null,
    specialAttributes: null,
    yearAsInteger: 0,
    automatedTutorChange: false,
    alternativeTutor: {
      tutorNumber: '',
      tutorName: '',
      tutorState: '',
      tutorPhoto: '',
      tutorType: '',
      tutorDescription: '',
      tutorAvailability: [],
    },
    sessionWithNewTutor: {
      nextSessionWithNewTutor: null,
      previousSessionWithNewTutor: null,
    },
    selectedSchedule: {
      tutorNumber: null,
      selectedDay: null,
      scheduleDate: '',
      sessionDuration: 60,
    },
    managePause: {
      resumeSessionDate: null,
      isRestart: false,
    },
    tempTutorChange: {
      tutorNumber: '',
      tutorName: '',
      tutorState: '',
      tutorPhoto: '',
      tutorType: '',
      tutorDescription: '',
    },
  },

  hubChangeSchedulePage: {
    enrolmentId: '',
    tutorAvailabilities: [],
    selectedSchedule: {
      selectedDay: null,
      scheduleDate: '',
      sessionDuration: '',
    },
    selectedTutor: {
      tutorNumber: '',
      tutorName: '',
      tutorState: '',
      tutorPhoto: '',
      tutorType: '',
      tutorDescription: '',
    },
  },

  hubChangeGroupPage: {
    groupAvailabilities: [],
  },

  hubEnrolmentsWillBePaused: [],
  hubPauseSessionsPrefilled: {
    student: '',
    subject: '',
    enrolmentId: '',
    tutorName: '',
    noOfSessionsToPause: 0,
    resumeSessionDate: null,
    pauseToDate: null,
  },
  hubEnrolmentsWithResumeSession: [],
  hubChangeSessionWithEnrolmentTutor: {
    tutorAvailabilities: [],
    selectedDayTime: {
      selectedDay: null,
      selectedTime: null,
      sessionId: null,
    },
  },

  // TODO: Need to combine with hubEnrolmentDetailPage
  hubEnrolmentManagement: {
    enrolmentId: '',
    change: {},
    pause: {},
    cancel: {},
  },

  hubAccountPage: {
    accountDetails: {},
    accountDetailsErrorMsg: null,
    paymentSchedule: [],
    billingInformation: {
      billingInformationSucceedMsg: '',
      zuoraId: '',
    },
    invoiceHistory: null,
    nextChargeDate: null,
    billingInformationErrorMsg: '',
  },

  hubContactPage: {
    selectedEnrolment: '',
    selectedSession: null,
    selectedStudent: '',
    pauseSessions: {
      affectedSessions: [],
      resumeFromDate: null,
      resumeSessionDate: null, // NOTE, even this is called ***Date, it has session time.
      pauseFromDate: null,
    },
  },

  studentProgress: {
    selectedModule: {},
    summary: {},
    modules: {},
    cumulative: {},
    disciplineList: [],
    outcomesOverview: null,
    nextYearLevel: null,
    enrolmentDetailsUrl: null,
  },

  schoolsUpcomingSessions: {
    name: '',
    timezone: '',
    customerNumber: '',
    role: '',
    students: [],
    sessions: [],
    holidays: [],
  },

  summerMode: {
    summerIncentivesAndPromotions: '',
  },

  zuora: {
    error: '',
    params: null,
  },

  activities: [
    /*
        // TYPE: learning session
        {
          type: 'SESSION|PRACTICE|', <-- not for test simulation
          tutorName: 'DemoTutor', <-- move it to report
          sessionDuration: '45 minutes', <-- session start, session end
          sessionGoals: [
            { id: 1, goal: 'How to use participles', isComplete: true },
            { id: 2, goal: 'Write sentences with verbs', isComplete: false },
          ],
          tutorFeedback: '“A fantastical final session of the year for Angus! We spent today’s
            lesson writing a review for a book that he loves at home: Weirdo. He was able to write strong
            arguments on the positive aspects of the book and happily wrote several sentences detailing why
            the book would be great for a recommendation. His spelling has markedly improved and his structure
            in sentences is more varied as well. Great achievement so far!”',
          studentFeedback: {
            text: '“I learned a lot about relating verbs and used them.”',
            emojiName: 'grinning',
            emojiValue: 'Helpful',
          },
        }
         */
  ],
};

export type InitialState = typeof initialState;
export default initialState;
