import { generatePath, Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { BasicCard, ButtonAsLink } from '@cluey/cluey-components';
import { useDispatch, useSelector } from 'react-redux';
import calendarIcon from '../../../../assets/images/icon-calendar--orange.svg';
import reportIcon from '../../../../assets/images/icon-filled-report.svg';
import noTutorAvatar from '../../../../assets/images/no-tutor-avatar.png';
import {
	PATH_HUB_ENROLMENT_DETAIL,
	PATH_SESSION_REPORT,
	PATH_HUB_MS_SESSION_TIMES,
	PATH_HUB_ENROLMENT_MANAGE_PAUSE,
	PATH_HUB_RESCHEDULE_SESSION_WITH_TUTOR,
} from '../../../../util/pagePath';
import { STUDENT_PROGRESS_TAB_VIEWS } from '../../../../util/constants';
import { useTutorInfoModal } from '../../../../components/TutorInfo/TutorInfoModal';
import { Enrolment } from '../../../../api/types/enrolment';
import { PausedEnrolment } from '../../../../types/hubInterfaces';
import { getSessionReportId, getPausedEnrolments } from '../../../../selectors/hubSelector';
import { fetchEnrolment } from '../../../../actions/hub/hubEnrolmentManagementActions';
import EnrolmentCardDetail from './EnrolmentCardDetail';
import generateSessionDescription from './generateSessionDescription';
import {
	isEnrolmentPausedCheck,
	isEnrolmentCancelled,
	isEnrolmentFinished,
	isGroupSessionCheck,
} from '../../../../util/helpers';
import { ReactComponent as FilledAlertInfo } from '../../../../assets/images/icon-filled-alert-info-custom-fill.svg';

export interface EnrolmentCardProps {
	enrolment: Enrolment;
	timezone: string;
	isPreview?: boolean;
}

function EnrolmentCard(props: EnrolmentCardProps) {
	const { enrolment, timezone = '', isPreview = false } = props;
	const {
		yearLevel,
		subject,
		tutor,
		tutorPhoto,
		tutorNumber,
		sessionCount,
		enrolmentId,
		sessionFrequency,
		startTime,
		enrolmentStatus,
		enrolmentPausedTo,
		type,
		sessions,
	} = enrolment;
	const isPaused = isEnrolmentPausedCheck({ enrolmentStatus });
	const isFinished = isEnrolmentCancelled({ enrolmentStatus }) || isEnrolmentFinished({ enrolmentStatus });
	const isGroup = isGroupSessionCheck(type);

	const sessionReportId = useSelector((state) => getSessionReportId(state, enrolmentId));
	const sessionsAndScheduleOptionsPath = generatePath(PATH_HUB_MS_SESSION_TIMES, { enrolmentId });
	const managePausePath = `${PATH_HUB_ENROLMENT_MANAGE_PAUSE}?token=${enrolmentId}`;
	const reviewFeedbackPath = `${PATH_SESSION_REPORT}?token=${sessionReportId}&activeTab=${STUDENT_PROGRESS_TAB_VIEWS.SESSION_REPORT}`;
	const history = useHistory();
	const tutorToBeMatched = tutor === 'Tutor' || !tutor;
	const tutorDescription = isFinished
		? 'No tutor'
		: tutorToBeMatched
		? 'Tutor to be matched'
		: `${tutor} is the current tutor`;
	const tutorIcon = isFinished ? noTutorAvatar : tutorPhoto || noTutorAvatar;
	const pausedEnrolments: PausedEnrolment[] = useSelector(getPausedEnrolments);
	const resumptionDate = isPaused
		? pausedEnrolments.find((pausedEnrolment) => pausedEnrolment.enrolmentId === enrolment.enrolmentId)
				?.resumeSessionDate
		: undefined;

	const sessionsDescription = generateSessionDescription({
		startTime,
		sessionFrequency,
		timezone,
		isPaused,
		resumptionDate,
		enrolmentPausedTo,
	});
	const { open, openModal, closeModal, TutorInfoModal } = useTutorInfoModal();
	const dispatch = useDispatch();
	const clearPreviouslySelectedEnrolment = () => {
		/*
    fix for CRE-1010: before redirecting to Manage Pause,
    fetch the enrolment to avoid a bug where the user is redirected to pause-sessions (likely because the previously selected enrolment isn't paused)
    and then to manage-pause
    */
		dispatch(fetchEnrolment({ enrolmentId }));
	};
	let manageLabel = null;
	if (isGroup) {
		manageLabel = 'Manage Schedule';
	} else if (isPaused) {
		manageLabel = 'Manage Pause';
	} else {
		manageLabel = 'Manage or add a session';
	}
	const upcomingRescheduleRequestedSession = sessions?.find(
		(session) => session.rescheduleRequestStatus === 'Requested'
	);
	const isRescheduleRequested =
		sessions && upcomingRescheduleRequestedSession && upcomingRescheduleRequestedSession.studentSessionId;

	const affectedSessionDate = upcomingRescheduleRequestedSession?.start
		? moment(upcomingRescheduleRequestedSession.start).format('[on] dddd D MMM')
		: '';

	return (
		<>
			<TutorInfoModal tutorNumber={tutorNumber} open={open} closeModal={closeModal} enrolment={enrolment} />
			<BasicCard className="rounded border-2 border-grey-2">
				<div className="flex flex-1 flex-col">
					{isRescheduleRequested && (
						<section className="align-items-center flex flex-1 flex-row space-x-4 bg-yellow-1 px-6 py-4">
							<div>
								<FilledAlertInfo fill="#F6A11D" />
							</div>
							<div className="flex-1 font-bold">{`${tutor} can't make it ${affectedSessionDate}`}</div>
							<ButtonAsLink
								onClick={() => {
									if (!isPreview) {
										history.push(
											generatePath(PATH_HUB_RESCHEDULE_SESSION_WITH_TUTOR, {
												sessionId: upcomingRescheduleRequestedSession.studentSessionId,
											})
										);
									}
								}}
								className="text-right  lg:min-w-[192px]"
							>
								<p className="text-lg">Reschedule now</p>
							</ButtonAsLink>
						</section>
					)}
					<div className="flex flex-col lg:flex-row">
						<section className="flex-row bg-grey-1 p-6 lg:w-1/4">
							<div>
								<p className="font-display text-xl font-bold text-black">{`${yearLevel} ${subject}`}</p>
							</div>
							<div className="mt-2">
								{isPreview ? (
									<p className="font-bold uppercase text-blue-5">Full details</p>
								) : (
									<Link to={`${PATH_HUB_ENROLMENT_DETAIL}?token=${enrolmentId}`}>
										<p className="font-bold uppercase text-blue-5">Full details</p>
									</Link>
								)}
							</div>
						</section>
						<section className="flex-grow flex-row bg-grey-1 p-5 lg:w-3/4">
							<EnrolmentCardDetail
								renderIcon={<img src={tutorIcon} alt="tutor icon" className="h-7 w-7 rounded-full" />}
								description={tutorDescription}
								hideAction={isFinished}
								actionLabel="Tutor Profile"
								disableAction={tutorToBeMatched}
								onActionClicked={() => {
									// open new Tutor Popup (CRE-365) TO-DO
									if (!isPreview) {
										openModal();
									}
								}}
							/>
							<div className="flex-grow border-grey-2" style={{ borderBottomWidth: 1 }} />
							<EnrolmentCardDetail
								renderIcon={<img src={calendarIcon} alt="schedule icon" />}
								description={sessionsDescription}
								hideAction={isFinished}
								actionLabel={manageLabel}
								onActionClicked={() => {
									if (!isPreview) {
										// go to ManageServiceSessionTimes
										if (isPaused) {
											clearPreviouslySelectedEnrolment();
										}

										history.push(isPaused && !isGroup ? managePausePath : sessionsAndScheduleOptionsPath);
									}
								}}
							/>
							<div className="flex-grow border-grey-2" style={{ borderBottomWidth: 1 }} />
							<EnrolmentCardDetail
								renderIcon={<img src={reportIcon} alt="report icon" />}
								description={`${sessionCount} sessions completed`}
								actionLabel="Review Feedback"
								onActionClicked={() => {
									if (!isPreview) {
										// go to Learning Activity
										window.open(reviewFeedbackPath, '_blank');
									}
								}}
							/>
						</section>
					</div>
				</div>
			</BasicCard>
		</>
	);
}

export default EnrolmentCard;
