import { sendGetRequest } from '../../services/RequestService';
import { getPortalAPIRequestParams } from '../../services/PortalAPIServices/helper';
import { api } from '../../api';

// eslint-disable-next-line valid-jsdoc
/**
 * @deprecated This function has been deprecated in favor of api/services/account.details
 *
 */
export async function getAccountDetails() {
	const { url, reqOptions } = getPortalAPIRequestParams(`account/details`);
	return sendGetRequest({
		url,
		signRequest: true,
		reqOptions,
	}).then((res) => res.json());
}
const useAccountDetailsQuery = () => {
	return api.account.details.useQuery();
};

export default useAccountDetailsQuery;
