import { useMutation } from "@tanstack/react-query";
import { getPortalAPIRequestParams } from "../../services/PortalAPIServices/helper";
import { sendPostRequest } from '../../services/RequestService';

interface Params {
    onError?(e: any): void;
    onSuccess(): void;
}
interface ChangePlanPayload {
    accountId: string, pricingPlanId: string, effectiveFrom: string;
}
export async function postChangePricingPlan(payload: ChangePlanPayload) {
    const { url, reqOptions } = getPortalAPIRequestParams(`pricingPlan/change`);
    return sendPostRequest({
        url,
        signRequest: true,
        payload,
        reqOptions,
    }).then((res) => {
        const status = res.status;
        if (status === 200) {
            return res.json();
        }
        else {
            throw new Error('Please contact support.');
        }
    });
}


export const useChangePlanMutation = (params: Params) => {
    const { onError, onSuccess } = params;

    return useMutation({
        mutationKey: ['changePricingPlan'],
        mutationFn: postChangePricingPlan,
        onSuccess,
        onError
    });
};