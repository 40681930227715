import React from 'react';
import PropTypes from 'prop-types';

import InfoModalLayout from './InfoModalLayout';
import calendar from '../../../../assets/images/hubContact/calendar.svg';
import infoImg from '../../../../assets/images/hubContact/reschedule-example.png';
import { PATH_HUB_UPCOMING_SESSIONS } from '../../../../util/pagePath';

const GeneralChangeSessionModal = ({ isModalOpen, toggleModal }) => {
  return (
    <InfoModalLayout
      isModalOpen={isModalOpen}
      toggleModal={toggleModal}
      modalIcon={calendar}
      title="Reschedule or skip a session"
      renderIntro={() => (
        <p className="mb-0 font-body text-base lg:text-lg">
          You can reschedule or skip a session in the Upcoming Sessions section.
          Just find the session you’d like to modify and click the “
          <span className="font-bold">CHANGE</span>” button. If you have
          questions, you can always contact us.
        </p>
      )}
      infoImg={infoImg}
      redirectText="Go to Upcoming sessions"
      redirectLink={PATH_HUB_UPCOMING_SESSIONS}
    />
  );
};

GeneralChangeSessionModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default GeneralChangeSessionModal;
