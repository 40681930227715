import {
  get, isEmpty, orderBy,
} from 'lodash';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';


import Star0 from '../assets/images/progressStars/star-0.svg';
import Star25 from '../assets/images/progressStars/star-1-25.svg';
import Star50 from '../assets/images/progressStars/star-26-50.svg';
import Star75 from '../assets/images/progressStars/star-51-75.svg';
import Start99 from '../assets/images/progressStars/star-76-99.svg';
import Start100 from '../assets/images/progressStars/star-100.svg';

momentDurationFormatSetup(moment);

export const getCompletionProgress = ({ coverage }) => {
  let star = null;
  if (coverage > 5 && coverage <= 25) {
    star = Star25;
  } else if (coverage > 25 && coverage <= 50) {
    star = Star50;
  } else if (coverage > 50 && coverage <= 75) {
    star = Star75;
  } else if (coverage > 75 && coverage < 100) {
    star = Start99;
  } else if (coverage === 100) {
    star = Start100;
  } else {
    star = Star0;
  }

  return {
    coverage: `${coverage}%`,
    star,
  };
};

export const getTimeSpent = (timeInMins, hourUnit, minuteUnit, showMinIfZero = false) => {
  const m = moment.duration(timeInMins, 'minutes');
  if (timeInMins > 60) {
    if (showMinIfZero || timeInMins % 60 !== 0) {
      return `${m.format(`h[${hourUnit}] m[${minuteUnit}]`)}`;
    }
    return `${m.format(`h[${hourUnit}]`)}`;
  }
  return `${m.format(`m[${minuteUnit}]`)}`;
};

export function getModules(state) {
  const coveredModules = get(state, 'studentProgress.modules.covered');
  const notCoveredModules = get(state, 'studentProgress.modules.notCovered');
  const studentName = get(state, 'report.studentName', 'student');

  const listTitle = {
    title: 'Units covered',
    subTitle: 'The amount of time spent on each concept will differ for each student, and reflect their tailored learning program.',
  };
  let listItems = [];

  if (!isEmpty(coveredModules)) {
    const { total, modules } = coveredModules;
    if (total) {
      const modulesSorted = orderBy(modules, 'moduleTimeSpentMins', 'desc');
      listItems = modulesSorted.map((m) => {
        const {
          module, moduleId, moduleTimeSpentMins, moduleCoverage, moduleTotalTopics,
        } = m;
        const itemSubtitle = module && module.toLowerCase().includes('cluey kick-off') ?
          `Content used to determine the best starting point for ${studentName}.` : '';

        const itemCTAText = moduleTotalTopics && moduleTotalTopics > 1 ?
          `VIEW ALL ${moduleTotalTopics} TOPICS` : 'VIEW TOPIC';

        const { coverage, star } = getCompletionProgress({
          coverage: moduleCoverage,
        });
        const timeSpent = getTimeSpent(moduleTimeSpentMins, 'h', 'm');
        return {
          itemImg: star,
          itemTitle: module,
          itemSubtitle,
          itemCTAText,
          itemCTAAction: () => {},
          itemDataTop: timeSpent,
          itemDataBottom: `${coverage} complete`,
          moduleId,
          moduleTimeSpentMins,
        };
      });
    }
  }

  if (!isEmpty(notCoveredModules)) {
    const { total, modules } = notCoveredModules;
    if (total && total > 0) {
      const notCoveredModuleNames = modules.map(m => m.module);
      const lastItem = notCoveredModuleNames.pop();
      const names =
        notCoveredModuleNames.length > 0
          ? `${notCoveredModuleNames.join(', ')} and ${lastItem}`
          : `${lastItem}`;
      let content = `There are ${total} other units in the curriculum not covered yet: ${names}.`;
      if (total <= 1) {
        content = `There is ${total} other unit in the curriculum not covered yet: ${names}.`;
      }
      listItems.push({
        notCoveredModules: content,
      });
    }
  }
  return {
    listTitle,
    listItems,
  };
}

export const getClueyContentTimeSpent = (state) => {
  const coveredModules = get(state, 'studentProgress.modules.covered');
  if (isEmpty(coveredModules)) {
    return '0';
  }

  const { modules } = coveredModules;
  let totalTimeSpentMins = 0;
  modules.forEach((m) => {
    totalTimeSpentMins += m.moduleTimeSpentMins;
  });

  return totalTimeSpentMins === 0 ? '0' : getTimeSpent(totalTimeSpentMins, 'h', 'm');
};

export const getSchoolBasedTasksSummary = (state) => {
  const improvisedTime = get(
    state,
    'studentProgress.summary.totalTimeSpentImprovisedMins',
    null,
  );
  const studentName = get(state, 'report.studentName', 'student');

  if (improvisedTime && improvisedTime > 0) {
    const timeSpent = getTimeSpent(improvisedTime, 'h', 'm');
    return {
      content: `We have also supported ${studentName} with their school work, which they uploaded during sessions.`,
      timeSpent,
    };
  }
  return {
    content: 'Personal content (eg. uploaded by student).',
    timeSpent: '',
  };
};

export const getPracticeData = (state) => {
  const coveredModules = get(state, 'studentProgress.modules.covered');
  if (isEmpty(coveredModules)) {
    return {};
  }


  const { modules } = coveredModules;
  if (!modules || modules.length === 0) {
    return {};
  }
  // filter out the modules without any practice assigned to it
  const modulesWithPractice = modules.filter(m => m.modulePracticeTotalQuestionsAssigned > 0);
  if (!modulesWithPractice || modulesWithPractice.length === 0) {
    return {};
  }

  const listTitle = {
    title: 'Practice questions completion per unit',
  };
  let listItems = [];
  const modulesWithPracticeSorted = orderBy(modulesWithPractice, 'moduleTimeSpentMins', 'desc');
  listItems = modulesWithPracticeSorted.map((m) => {
    const {
      module,
      moduleId,
      modulePracticeTotalQuestionsAssigned,
      modulePracticeQuestionsSubmitted,
    } = m;
    return {
      itemTitle: module,
      itemCTAText: 'view details',
      itemCTAAction: () => {},
      itemDataTop: `${modulePracticeQuestionsSubmitted} of ${modulePracticeTotalQuestionsAssigned}`,
      moduleId,
    };
  });

  return {
    listTitle,
    listItems,
  };
};

export const getCoveredTopics = (state) => {
  const covered = get(
    state,
    'studentProgress.selectedModule.summary.topics.covered.topics',
    [],
  );

  if (covered.length === 0) {
    return [];
  }

  return covered.map((t) => {
    return {
      topic: t.topic,
      topicTotalTimeSpent: getTimeSpent(
        t.topicTotalTimeSpentMinutes,
        'h',
        'm',
      ),
      topicPracticeTimeSpent: getTimeSpent(
        t.topicPracticeTimeSpentMinutes,
        'h',
        'm',
      ),
      topicSessionTimeSpent: getTimeSpent(
        t.topicSessionTimeSpentMinutes,
        'h',
        'm',
      ),
      topicPracticeTotalQuestionsAssigned:
        t.topicPracticeTotalQuestionsAssigned,
      topicPracticeQuestionsSubmitted: t.topicPracticeQuestionsSubmitted,
      topicPracticeCompletion: t.topicPracticeCompletion,
      topicPracticeQuestionsCorrect: t.topicPracticeQuestionsCorrect,
    };
  });
};

export const getNotCoveredTopics = (state) => {
  const notCovered = get(
    state,
    'studentProgress.selectedModule.summary.topics.notCovered.topics',
    [],
  );

  if (notCovered.length === 0) {
    return [];
  }

  return notCovered.map(t => t.topic);
};

export const getYearLevelAsNumber = (state) => {
  const yearLevel = get(state, 'report.yearLevel', null);
  if (!yearLevel) {
    return null;
  }

  return parseInt(yearLevel.split(' ')[1], 10);
};

export function isEndOfTheYear(state) {
  return state.report.eoy;
}

export function showPracticeData(state) {
  const totalQuestionsAssigned = get(
    state,
    'studentProgress.summary.totalQuestionsAssigned',
    0,
  );
  return parseInt(totalQuestionsAssigned, 10) > 0;
}

export function showSummarySection(state) {
  const totalSessions = get(
    state,
    'studentProgress.summary.totalSessionsCompleted',
    0,
  );
  return parseInt(totalSessions, 10) > 0;
}

export function hasModules(state) {
  const { listItems } = getModules(state);
  return listItems.filter(li => li.moduleId !== 'improvised').length > 0;
}
