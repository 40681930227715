import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { CancelEnrolmentAlert } from './CancelEnrolmentAlert';
import ErrorBlock from '../../../../common/ErrorBlock';
import ContactFormLayout from '../../../../common/hub/ContactFormLayout';
import FormSubHeading from '../../../hub/contact/FormSubHeading';
import { useDatePickerProps, DatePicker } from '../../../DatePicker/DatePicker';
import { cancelEnrolment } from '../../../../actions/hub/hubCancelActions';
import { useDispatch, useSelector } from 'react-redux';
import { InitialState } from '../../../../types/hubTypes';
import { CancellationDeterrent } from '../CancellationDeterrent';
import { CancellationSteps } from '../CancellationSteps';
import update from 'immutability-helper';
import type { Enrolment } from '../../../../api/types/enrolment';

interface Props {
  selectedEnrolment: Enrolment;
  goBack(): void;
  submitSuccessCallback(): void;
  enrolmentPolicyError?: unknown;
}

/* @TODO update test */

const FormCancelEnrolment = ({
  selectedEnrolment,
  goBack,
  submitSuccessCallback,
  enrolmentPolicyError
}: Props) => {
  const { enrolmentSfid } = selectedEnrolment;
  const dispatch = useDispatch();
  const ref = useRef(null);
  const previousRatingRef = useRef(0);
  const {
    fetchError: submitError,
    errorMsg,
  } = useSelector((state: InitialState) => state.ui.apiState.hubContactPage);

  const datePickerProps = useDatePickerProps({
    labelText: 'Select a date to cancel from',
    placeholder: 'Select start date',
    customInputClasses: 'w-full',
    options: {
      enableTime: false,
      minDate: new Date().fp_incr(1),
      altInput: true,
      altFormat: 'F j, Y',
      dateFormat: 'Y-m-d',
    },
  });
  const selectedDate =
    Array.isArray(datePickerProps.value) && datePickerProps.value[0] instanceof Date === true
      ? moment(datePickerProps.value[0]).format('YYYY-MM-DD')
      : '';

  const [cancelSelection, setCancelSelection] = useState({
    selectedDate,
    rating: 0,
    selectedReasons: [],
    message: '',
    other: {
      message: ''
    }
  });

  useEffect(() => {
    if (cancelSelection.rating <= 3 && previousRatingRef.current >= 4
      || cancelSelection.rating >= 4 && previousRatingRef.current <= 3) {
      setCancelSelection(current => update(current, {
        selectedReasons: {
          $set: []
        }
      }))
    }
  }, [cancelSelection.rating])
  useEffect(() => {previousRatingRef.current = cancelSelection.rating}, [cancelSelection.rating]);
  useEffect(() => {
    setCancelSelection(current => update(current, {
      selectedDate: {
        $set: selectedDate
      }
    }));
  }, [selectedDate]);

  // Change here
  const onClickHandler = () => {
    dispatch(
      cancelEnrolment({
        rating: cancelSelection.rating,
        enrolmentId: enrolmentSfid,
        selectedReasons: cancelSelection.selectedReasons,
        otherMessage: cancelSelection.other.message,
        message: cancelSelection.message,
        endDate: selectedDate,
        onSubmitSuccess: submitSuccessCallback,
      })
    )
  };

  const onGoBack = () => {
    if (ref.current.getStepIndex() > 0) {
      ref.current.goBack();
      window.scrollTo(0, document.body.scrollHeight);
    } else {
      goBack();
    }
  }

  return (
    <ContactFormLayout selectedEnrolment={selectedEnrolment} customGoBack={onGoBack}>
      <div className="px-5 pt-5 lg:px-20 lg:pt-14">
        {submitError && errorMsg && <ErrorBlock errorMsg={errorMsg} />}
        {enrolmentPolicyError && <ErrorBlock errorMsg="There was an error encountered while retrieving the pause policy." />}
        <CancellationDeterrent />
        <FormSubHeading />
        <p className='mb-5 text-grey-5'>We're sorry to see you go. We need some information from you to process your request.</p>
        <form>
          <div className="mb-3">
            <DatePicker {...datePickerProps} />
          </div>
          <CancelEnrolmentAlert
            selectedDate={selectedDate}
            selectedEnrolment={selectedEnrolment}
          />
          <CancellationSteps
            ref={ref}
            onSubmitCancelRequest={onClickHandler}
            cancelSelection={cancelSelection}
            setCancelSelection={setCancelSelection}
            selectedEnrolment={selectedEnrolment}
          />
        </form>
      </div>
    </ContactFormLayout>
  );
};

export default FormCancelEnrolment;