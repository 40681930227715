import { Auth2Layout } from "../../common";
import { Pending } from "@cluey/cluey-components";
import { useDispatch, useSelector } from "react-redux";
import { requestToResetPassword } from '../../../../../actions/hub/hubAuthPagesActions';
import { useHistory } from "react-router";
import { InlineLoader } from "../../common";
import { useRedirectIfAuthenticated } from "../../common";
import type { InitialState } from "../../../../../types/hubTypes";

function ResetEmailSent() {
  useRedirectIfAuthenticated();
  const dispatch = useDispatch();
  const history = useHistory<{email: string}>();
  const { location: { state } } = history;
  const { email } = state ?? {};
  const {
    isLoading: loading
  } = useSelector((state: InitialState) => state.ui.apiState.hubAuthPage);

  const onClick = () => {
    if (email) {
      dispatch(requestToResetPassword({
        email,
        onSuccess: () => undefined,
      }))
    }
  }

  return (
    <Auth2Layout title="Password reset email sent">
      <Pending loading={loading} loader={<InlineLoader />}>
        <div className="text-grey-6">
          <p>
            If your email address is associated with a Cluey account,
            you'll receive an email with a link to reset your password.
          </p>
          <p className="mt-4">
            Didn't receive the reset link? <button onClick={onClick} className="text-blue-5 underline underline-offset-2">Resend email</button>
          </p>
        </div>
      </Pending>
    </Auth2Layout>
  )
}

export default ResetEmailSent;