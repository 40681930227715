import { Container, LinkButton, Link } from "@cluey/cluey-components";
import { PATH_HUB_CHANGE_PLAN, PATH_HUB_ENROLMENT_DETAIL, PATH_HUB_SUMMER_PAUSE_ENROLMENT } from "../../../../../util/pagePath";
import { SessionPolicy } from "../../../../../types/hubInterfaces";
import ReasonsToPause from '../../../../../components/hub/ReasonsToPause';

interface Props {
  enrolmentId: string;
  sessionPolicy: SessionPolicy;
  suggestSummerPause: boolean;
}
const PauseUnavailable = ({ enrolmentId, sessionPolicy, suggestSummerPause }: Props) => {
  const { policyName } = sessionPolicy;
  return (
    <Container>
      {suggestSummerPause && (
        <div className="mb-10 rounded-lg bg-slate-1 px-4 pt-2 pb-2 md:px-10 md:pt-4">
          <ReasonsToPause
            title="Take a break for summer public school holidays"
            showSubText={false}
          />
          <div className='mt-5 mb-6 text-grey-6'>
            <LinkButton className='mt-4' appearance='reverse' to={`${PATH_HUB_SUMMER_PAUSE_ENROLMENT}?token=${enrolmentId}`}>Pause for summer here</LinkButton>
          </div>
        </div>
      )}
      <div className="flex flex-col gap-3 text-grey-6">
        <p className='text-xl font-bold'>Besides public school holidays, your {policyName} plan doesn't offer the flexibility to pause sessions during school terms.</p>
        {!suggestSummerPause && (<p>You will be able to pause for the upcoming public school holidays soon.</p>)}
        <p>Want more flexibility? Try <Link className="!text-base underline underline-offset-2" to={PATH_HUB_CHANGE_PLAN}>switching to the Flexi plan.</Link></p>
      </div>
      <LinkButton
        appearance="reverse"
        className="mt-7"
        to={`${PATH_HUB_ENROLMENT_DETAIL}?token=${enrolmentId}`}>
          Home
      </LinkButton>
    </Container>
  )
}

export default PauseUnavailable;