import React, { useState, useRef, useLayoutEffect, Fragment, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper6/react';
import SwiperCore, { Navigation } from 'swiper6/core';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { SchoolSessionCard } from '../../components/schools/SchoolSessionCard';
import { SchoolsPageLayout } from '../../components/schools/SchoolsPageLayout';

import 'swiper6/swiper.min.css';
import 'swiper6/components/navigation/navigation.min.css';
import { NewAccordionWithState } from '../../components/NewAccordion/NewAccordion';
import { CopyToClipboard } from '../../components/CopyToClipboard/CopyToClipboard';
import { fetchUpcomingSessions as actionFetchUpcomingSessions } from '../../actions/schools/schoolsUpcomingSessionsActions';
import { getLearningGroups, getTodaySessions } from '../../selectors/schoolsUpcomingSessionsSelector';
import InPageLoader from '../../common/InPageLoader';
import PageError from '../../common/PageError';
import { PATH_SESSION_REPORT } from '../../util/pagePath';
import withHeadTag from '../../common/HeadComponent';

SwiperCore.use([Navigation]);

const SWIPER_THRESHOLD = 3;

const H4 = ({ children }) => <h4 className="mb-2 text-xs font-bold uppercase tracking-[1px]">{children}</h4>;

H4.propTypes = {
	children: PropTypes.node.isRequired,
};

const UpcomingSchoolSessions = ({
	sessions,
	learningGroups,
	// holidays,
	fetchUpcomingSessions,
	isLoading,
	firstFetch,
	fetchError,
}) => {
	const [navigation, setNavigation] = useState(false);

	const prevRef = useRef(null);
	const nextRef = useRef(null);

	useEffect(() => {
		if (!firstFetch) fetchUpcomingSessions();
	}, [firstFetch, fetchUpcomingSessions]);

	useLayoutEffect(() => {
		setNavigation(sessions.length > SWIPER_THRESHOLD ? { nextEl: nextRef.current, prevEl: prevRef.current } : false);
	}, [sessions]);

	const swiperButtonClasses = classnames(
		'btn btn-link hidden',
		{ 'md:block': sessions.length > SWIPER_THRESHOLD },
		'fill-blue-5 hover:fill-blue-6 disabled:fill-black'
	);
	const swiperContainerClasses = classnames(
		{
			'md:hidden': sessions.length <= SWIPER_THRESHOLD,
		},
		sessions.length === 1 ? 'hidden' : 'flex'
	);

	let view;
	if (isLoading) {
		view = <InPageLoader />;
	} else if (fetchError) {
		view = <PageError activePage="" />;
	} else {
		view = (
			<div className="container mx-auto">
				{/* Upcoming Sessions */}
				<div className="relative mb-7 w-full px-0 pb-10">
					{sessions.length > 0 ? (
						<Fragment>
							<div className={swiperContainerClasses}>
								{/* eslint-disable-next-line */}
								<button ref={prevRef} className={swiperButtonClasses}>
									<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
										<path
											d="M16 24c-.521 0-1.04-.176-1.463-.53L.823 12.04C-.147 11.233-.28 9.791.53 8.822c.807-.97 2.247-1.1 3.218-.293l12.277 10.232 12.256-9.864c.983-.79 2.423-.635 3.214.348.791.983.636 2.42-.347 3.214L17.433 23.495c-.418.336-.925.505-1.433.505"
											transform="translate(-253.000000, -329.000000) translate(253.000000, 329.000000) translate(16.000000, 16.000000) rotate(-270.000000) translate(-16.000000, -16.000000)"
										/>
									</svg>
								</button>
								<Swiper
									navigation={navigation}
									breakpoints={{
										768: { slidesPerView: 2 },
										1200: { slidesPerView: 3 },
									}}
									slidesPerView="auto"
									className="pl-3 pr-[70px] md:pl-0 md:pr-0"
								>
									{sessions.map((session) => (
										<SwiperSlide className="" key={session.packageName}>
											<div className="mr-5 flex h-full md:mx-3">
												<SchoolSessionCard session={session} containerClasses="border-grey-2 rounded-lg" />
											</div>
										</SwiperSlide>
									))}
								</Swiper>
								{/* eslint-disable-next-line */}
								<button ref={nextRef} className={swiperButtonClasses}>
									<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
										<path
											d="M16 24c-.521 0-1.04-.176-1.463-.53L.823 12.04C-.147 11.233-.28 9.791.53 8.822c.807-.97 2.247-1.1 3.218-.293l12.277 10.232 12.256-9.864c.983-.79 2.423-.635 3.214.348.791.983.636 2.42-.347 3.214L17.433 23.495c-.418.336-.925.505-1.433.505"
											transform="translate(-1158.000000, -329.000000) translate(1158.000000, 329.000000) translate(16.000000, 16.000000) rotate(-90.000000) translate(-16.000000, -16.000000)"
										/>
									</svg>
								</button>
							</div>
							{sessions.length <= SWIPER_THRESHOLD && (
								<div className={classnames('px-3 md:flex', sessions.length <= 1 ? 'flex' : 'hidden ')}>
									{sessions.map((session) => (
										<div className="col flex" key={session.packageName}>
											<SchoolSessionCard session={session} containerClasses="border-grey-2 rounded-lg" />
										</div>
									))}
								</div>
							)}
						</Fragment>
					) : (
						<h3 className="px-4 font-display text-lg">
							No sessions today. You can review group details, reports and share practice links with students below.
						</h3>
					)}
				</div>
				<div className="w-full">
					<div className="mb-5 border-b border-grey-2" />
				</div>
				{/* Learning Groups */}
				<div className="w-full pt-10">
					<h2 className="mb-7 font-display text-3xl font-bold text-grey-6">All learning groups</h2>
					{learningGroups.map((group) => {
						return (
							<div className="py-1" key={group.packageName}>
								<NewAccordionWithState
									title={`Year ${group.yearLevel > -1 ? group.yearLevel : 'UNKNOWN'} ${group.subject}`}
									subtitle={group.scheduledTime || moment(group.times[0].startTime).format('dddd[s at] h:mma')}
								>
									<>
										<div className="flex flex-col text-grey-6 md:flex-row md:gap-x-3">
											<div className="flex w-full flex-col py-5 md:w-1/2">
												<H4>Sessions</H4>
												<SchoolSessionCard
													session={{
														startTime: group.times[0].startTime,
														endTime: group.times[0].endTime,
														students: group.times[0].students,
														tutor: group.tutor,
														yearLevel: group.yearLevel,
													}}
													nextSession={group.times[1]}
													showNext
													title="date"
													containerClasses="grow border-grey-2 rounded-lg"
												/>
											</div>
											<div className="flex w-full flex-col py-5 md:w-1/2">
												<H4>Tutor</H4>
												<div className="grow overflow-hidden rounded-lg border border-grey-2">
													<div className="px-5 py-6 lg:px-[60px] lg:py-[44px]">
														<div
															className={classnames('flex items-center', { 'pb-5 lg:pb-6': group.tutorDescription })}
														>
															<img
																src={`${group.tutorPhoto}?v=1`}
																alt="tutor avatar"
																className="mr-4 h-[60px] w-[60px] rounded-full border border-grey-2 lg:mr-5"
															/>
															<div className="flex flex-col">
																<span className="mb-0.5 text-lg font-bold leading-snug">{group.tutor}</span>
																<span className="text-base leading-snug">
																	{/* {group.tutorState} {group.tutorType} */}
																	{group.tutorType !== null
																		? `${group.tutorState} ${group.tutorType}`
																		: 'Tutor to be assigned'}
																</span>
															</div>
														</div>
														{group.tutorDescription && (
															<div className="mb-3 border-t border-grey-2 pt-5 lg:pt-6">
																<span className="mb-2 text-xs font-bold uppercase leading-none tracking-[1px]">
																	A LITTLE ABOUT {group.tutor}…
																</span>
																<p className="text-xs leading-[1.67]">{group.tutorDescription}</p>
															</div>
														)}
													</div>
												</div>
											</div>
										</div>
										<div className="w-full py-5">
											<H4>Student Details</H4>
											<div className="rounded-lg border border-grey-2">
												{group.students.map((student, studentIndex) => (
													<div
														className={classnames('flex flex-col px-5 py-4 md:flex-row md:px-7 md:py-5', {
															'border-b border-grey-2': studentIndex < group.students.length - 1,
														})}
														key={student.uniqueEnrolmentId}
													>
														<div className="flex w-1/3 items-start justify-start font-display text-sm font-bold leading-4">
															{student.name}
														</div>
														<div className="flex w-1/3 items-start justify-start leading-4">
															{student.sessionReportId && (
																<Link
																	to={`${PATH_SESSION_REPORT}?token=${student.sessionReportId}`}
																	target="_blank"
																	className="font-display text-sm font-normal text-primary hover:underline"
																>
																	View learning activity
																</Link>
															)}
														</div>
														<div className="flex w-1/3 items-start justify-start">
															{student.practiceLink && (
																<CopyToClipboard
																	text={student.practiceLink}
																	label="Share practice link"
																	buttonClasses="text-sm font-display font-normal py-0 tracking-normal text-left leading-4"
																/>
															)}
														</div>
													</div>
												))}
											</div>
										</div>
									</>
								</NewAccordionWithState>
							</div>
						);
					})}
				</div>
			</div>
		);
	}

	return <SchoolsPageLayout heading="Today's group sessions">{view}</SchoolsPageLayout>;
};

UpcomingSchoolSessions.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	sessions: PropTypes.arrayOf(PropTypes.any),
	// eslint-disable-next-line react/forbid-prop-types
	learningGroups: PropTypes.arrayOf(PropTypes.any),
	// holidays: PropTypes.arrayOf(PropTypes.any),
	fetchUpcomingSessions: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	firstFetch: PropTypes.bool.isRequired,
	fetchError: PropTypes.bool.isRequired,
};

UpcomingSchoolSessions.defaultProps = {
	sessions: [],
	learningGroups: [],
	// holidays: [],
};

export { UpcomingSchoolSessions as PresentationalUpcomingSchoolSessions };
export const ConnectedUpcomingSchoolSessions = connect(
	(state) => {
		const { firstFetch, isLoading, fetchError } = state.ui.apiState.schoolsUpcomingSessions;
		const { holidays } = state.schoolsUpcomingSessions;

		return {
			sessions: getTodaySessions(state),
			learningGroups: getLearningGroups(state),
			holidays,
			isLoading,
			firstFetch,
			fetchError,
		};
	},
	(dispatch) => {
		return {
			fetchUpcomingSessions: bindActionCreators(actionFetchUpcomingSessions, dispatch),
		};
	}
)(withHeadTag(UpcomingSchoolSessions));
