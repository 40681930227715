import { Container, Pending } from '@cluey/cluey-components';
import { DatePicker, useDatePickerProps } from '../../../../../components/DatePicker/DatePicker';
import { DATE_FORMAT_TYPE_9, PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT } from '../../../../../util/constants';
import { EnrolmentBrief } from '../../../../../components/EnrolmentManagement/EnrolmentBrief';
import EnrolmentLayout from '../../../../../common/hub/EnrolmentLayout';
import { fetchCompletedSessions } from '../../../../../actions/hub/hubSessionsPageActions';
import { FormFlowProps } from '../../../../../types/hubInterfaces'; 
import { getTimezone } from '../../../../../selectors/hubSelector';
import { Loader } from '../../../../../components/Loader';
import moment from 'moment';
import { TimeSlots } from '../TimeSlots/TimeSlots';
import { useEnrolmentDetailQuery } from '../../../../../hooks/queries/useEnrolmentQuery';
import { useGetAvailableTimeSlotsOfTutor } from '../../../../../hooks/queries/useGetAvailability'; 
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { willEnrolmentBePausedCheck } from '../../../../../util/helpers';
import { useEffect } from 'react';
import type { Params } from '../AddOneOffSession';
import { ENROLMENT_STATUS } from '../../../../../types/hubEnums';

interface Props {
  formFlowProps: FormFlowProps
}

interface State {
  currentSelectedDate: string;
}

const DateAndTimeSelection = ({ formFlowProps }: Props) => {
  const { enrolmentId } = useParams<Params>();
  const dispatch = useDispatch();
  const history = useHistory<State>();
  const { location: { state } } = history;
  const { currentSelectedDate } = state ?? {};
  const studentTimeZone: string = useSelector(getTimezone);
  const { data: enrolmentDetail, isLoading, error } = useEnrolmentDetailQuery({ enrolmentId });

  const {
    effectiveFromDate,
    enrolmentDiscipline,
    enrolmentPausedFrom,
    enrolmentPausedTo,
    enrolmentSchedule,
    enrolmentStatus,
    studentName,
    tutorName,
    tutorSfId,
    tutorPhoto
  } = enrolmentDetail ?? {};
  const willEnrolmentBePaused = willEnrolmentBePausedCheck({ enrolmentPausedFrom, enrolmentPausedTo });
  const getMaxDate = () => {
    const cancelRequested = enrolmentStatus === ENROLMENT_STATUS.CANCEL_REQUESTED;
    const maxDate = cancelRequested ? effectiveFromDate : enrolmentPausedFrom;
    if (cancelRequested || willEnrolmentBePaused) {
      return moment(maxDate).subtract(1, 'day').format(PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT);
    } else {
      /*
        User may only pick a date up to 4 weeks. 28 (+ 1) is needed
        so the 28th day is included
      */
      return moment().add(29, 'days').format(PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT);
    }
  }

  const datePickerProps = useDatePickerProps({
    defaultDate: currentSelectedDate ? [currentSelectedDate] : null,
    labelText: 'Date',
    placeholder: 'Select date',
    options: {
      maxDate: getMaxDate(),
      minDate: moment().add(2, 'days').format(PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT),
    },
  });
  const selectedDate = datePickerProps.value[0];

  /* 
    convert the "reschedule from" and "reschedule to" dates to midnight,
    for instance, if the chosen date is March 12, 2023
    
    rescheduleFrom: "2023-03-11 12:59:59"
    rescheduleTo: "2023-03-12 12:59:59"
  */
  const { data: tutorTimeSlots, isLoading: isTutorTimeSlotsLoading } = useGetAvailableTimeSlotsOfTutor({ 
    tutorId: tutorSfId,
    rescheduleFrom: moment(selectedDate).tz(studentTimeZone).startOf('day').utc().subtract(1, 'second').format(DATE_FORMAT_TYPE_9),
    rescheduleTo: !!selectedDate ? moment(selectedDate).add(1, 'day').tz(studentTimeZone).startOf('day').utc().subtract(1, 'second').format(DATE_FORMAT_TYPE_9) : null,
    studentTimeZone
  });

  useEffect(() => {
    dispatch(fetchCompletedSessions());
  }, [dispatch])

  return (
    <EnrolmentLayout
      error={error}
      isLoading={isLoading}
      sectionHeading={`${studentName} ${enrolmentDiscipline}`}
      mainHeading="Add a one-off session"
      enrolmentId={enrolmentId}
      showEnrolmentStatus={false}
      showSummerModeEnrolmentStatus={false}
    >
      <Container>
        <EnrolmentBrief tutorName={tutorName} tutorPhoto={tutorPhoto} enrolmentSchedule={enrolmentSchedule} />
        <p className='text-xl font-bold'>When would you like to have a session with {tutorName}?</p>
        <p className='mt-4 text-grey-5'>Please note that we require 48 hours' notice to schedule an additional one-off session and it must take place within the next 4 weeks.</p>
        <div className='mt-8 max-w-md'>
          <DatePicker {...datePickerProps} />
        </div>
        <div className='mt-14'>
          <Pending loading={isTutorTimeSlotsLoading && !!selectedDate} loader={<Loader />}>
            {!!selectedDate && (
              <TimeSlots
                timeSlotCallback={(timeSlot) => {
                  formFlowProps.next({ currentSelectedDate: selectedDate, enrolmentDetail, timeSlot });
                }}
                tutorName={tutorName}
                selectedDate={selectedDate}
                timeSlots={tutorTimeSlots}
              />
            )}
          </Pending>
        </div>
      </Container>
    </EnrolmentLayout>
  )
}

export { DateAndTimeSelection };