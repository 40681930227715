import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';

import { Bar } from 'react-chartjs-2';

import SectionHeader from './SectionHeader';
import ModulesList from './ModulesList';
import { useGraphLegendProps, GraphLegend } from './GraphLegend';
import { getCVGraphOptions } from './ChartsOptionConfiguration';
import {
	getModules,
	getSchoolBasedTasksSummary,
	getClueyContentTimeSpent,
} from '../../selectors/studentProgressSelector';

import clueyLogo from '../../assets/images/logo-brank-mark.svg';
import schoolIcon from '../../assets/images/atoms-icon-filled-school.svg';

const SectionHead = ({ icon, iconAlt, title, textBelowTitle, textRight }) => {
	return (
		<Fragment>
			<img src={icon} alt={iconAlt} className="mr-3 h-[30px] w-[30px]" />
			<div>
				<h2 className="mb-1 font-display text-xl font-bold leading-tight">{title}</h2>
				<p className="mb-0 text-lg leading-snug">{textBelowTitle}</p>
			</div>
			<span className="ml-auto text-2xl">{textRight}</span>
		</Fragment>
	);
};

SectionHead.propTypes = {
	icon: PropTypes.node.isRequired,
	iconAlt: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	textBelowTitle: PropTypes.string.isRequired,
	textRight: PropTypes.string.isRequired,
};

const CurriculumProgressSummary = ({
	moduleListData,
	clueyContentTimeSpent,
	schoolTasksSum,
	cvGraphData,
	subject,
	studentName,
}) => {
	const { graphRef, legendItems, setLegendItems, forceUpdate } = useGraphLegendProps();

	const options = getCVGraphOptions();

	return (
		<Fragment>
			<SectionHeader
				text={`To address ${studentName}’s learning goals and build skills in ${subject}, we have focused on topics and concepts from the following curriculum units.`}
				title="Learning Focus"
			/>
			<div className="flex flex-col overflow-hidden rounded-lg border border-grey-2 p-4">
				<div className="flex w-full">
					<SectionHead
						icon={clueyLogo}
						iconAlt="Cluey Logo"
						title="Cluey content"
						textBelowTitle={`Created and curated by us to help ${studentName} cover specific topics and concepts.`}
						textRight={clueyContentTimeSpent}
					/>
				</div>
				<hr className="mb-6 mt-4 w-full text-grey-2" />
				<div className="flex flex-col lg:flex-row">
					<div className="mb-5 w-full lg:mb-0 lg:w-1/2 lg:pr-4">
						<ModulesList
							{...moduleListData}
							selfContain={false}
							context="curriculum"
							trackingShowMoreClass="track-show-more-curriculum"
							trackingViewDetailsClass="track-view-details-curriculum"
						/>
					</div>
					<div className="flex w-full grow flex-col lg:w-1/2">
						<div className="w-full text-center">
							<h3 className="mb-2 text-base font-bold">Learning growth</h3>
							<p className="mb-0 text-sm leading-[1.14]">Cumulative completion of the units covered</p>
						</div>
						<div className="flex max-h-[600px] min-h-[300px] grow flex-col overflow-hidden">
							<Bar ref={graphRef} data={cvGraphData} options={options} />
						</div>
						<GraphLegend
							graphRef={graphRef}
							legendItems={legendItems}
							setLegendItems={setLegendItems}
							forceUpdate={forceUpdate}
							legendItemClasses="w-full lg:w-1/2"
						/>
					</div>
				</div>
			</div>
			{!isEmpty(schoolTasksSum.timeSpent) && (
				<div className="mt-4 flex flex-nowrap overflow-hidden rounded-lg border border-grey-2 p-4">
					<SectionHead
						icon={schoolIcon}
						iconAlt="iogo"
						title="School-based tasks"
						textBelowTitle={schoolTasksSum.content}
						textRight={schoolTasksSum.timeSpent}
					/>
				</div>
			)}
		</Fragment>
	);
};

CurriculumProgressSummary.propTypes = {
	moduleListData: PropTypes.shape({
		listTitle: PropTypes.shape({
			title: PropTypes.string,
			subTitle: PropTypes.string,
		}),
		listItems: PropTypes.arrayOf(
			PropTypes.shape({
				itemImg: PropTypes.node,
				itemTitle: PropTypes.string,
				itemCTAText: PropTypes.string,
				itemCTAAction: PropTypes.func,
				itemContent: PropTypes.string,
				itemDataTop: PropTypes.string,
				itemDataBottom: PropTypes.string,
				notCoveredModules: PropTypes.string,
				moduleId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			})
		),
	}),
	clueyContentTimeSpent: PropTypes.string.isRequired,
	schoolTasksSum: PropTypes.shape({
		content: PropTypes.string,
		timeSpent: PropTypes.string,
	}),
	subject: PropTypes.string.isRequired,
	studentName: PropTypes.string.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	cvGraphData: PropTypes.any,
};

CurriculumProgressSummary.defaultProps = {
	moduleListData: {
		listTitle: {},
		listItems: [],
	},
	schoolTasksSum: {
		connect: '',
		timeSpent: '',
	},
	cvGraphData: {},
};

export default connect((state) => {
	const cumulative = get(state, 'studentProgress.cumulative');
	return {
		moduleListData: getModules(state),
		clueyContentTimeSpent: getClueyContentTimeSpent(state),
		schoolTasksSum: getSchoolBasedTasksSummary(state),
		cvGraphData: cumulative.cvGraph,
		subject: state.report.discipline,
		studentName: state.report.studentName,
	};
}, null)(CurriculumProgressSummary);
