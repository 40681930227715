import initialState from '../../initialState';
import * as actionTypes from '../../../actions/actionTypes';

export default function hubChangeSessionWithEnrolmentTutor(
  state = initialState.ui.apiState.hubChangeSessionWithEnrolmentTutor,
  action,
) {
  switch (action.type) {
    case actionTypes.HUB_TTC_FETCH_CURRENT_TUTOR_AVAILABILITY_START:
      return {
        ...state,
        tutorAvailabilities: {
          firstFetch: true,
          fetchError: false,
          errorMsg: null,
        },
      };
    case actionTypes.HUB_TTC_FETCH_CURRENT_TUTOR_AVAILABILITY_SUCCESS:
    case actionTypes.HUB_TTC_CHANGE_SESSION_WITH_ENROLMENT_TUTOR_CLEAN:
      return {
        ...state,
        tutorAvailabilities: {
          firstFetch: false,
          fetchError: false,
          errorMsg: null,
        },
      };
    case actionTypes.HUB_TTC_FETCH_CURRENT_TUTOR_AVAILABILITY_FAILED:
      return {
        ...state,
        tutorAvailabilities: {
          firstFetch: false,
          fetchError: true,
          errorMsg: action.payload.errorMsg,
        },
      };
    case actionTypes.HUB_TTC_RESCHEDULE_SESSION_START:
      return {
        ...state,
        changeSessionRequest: {
          firstFetch: true,
          fetchError: false,
          errorMsg: null,
        },
      };
    case actionTypes.HUB_TTC_RESCHEDULE_SESSION_SUCCESS:
      return {
        ...state,
        changeSessionRequest: {
          firstFetch: false,
          fetchError: false,
          errorMsg: null,
        },
      };
    case actionTypes.HUB_TTC_RESCHEDULE_SESSION_FAILED:
      return {
        ...state,
        changeSessionRequest: {
          firstFetch: false,
          fetchError: true,
          errorMsg: action.payload.errorMsg,
        },
      };
    default:
      return state;
  }
}
