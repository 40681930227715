import './LoaderStyles.scss';
import classNames from 'classnames';

type LoaderProps = {
	className?: string;
	size?: number;
};
type FullPageLoaderProps = LoaderProps & {
	loaderClassName?: string;
};

const LoaderBall = ({ size = 15, index }: { size: number; index: number }) => {
	return (
		<div
			id={`ball-${index}`}
			style={{ height: `${size}px`, width: `${size}px` }}
			className="height mx-1 my-0 inline-block rounded-full"
		></div>
	);
};

export const Loader = ({ className, size }: LoaderProps) => {
	return (
		<div className={className}>
			{Array.from(Array(5).keys()).map((index) => (
				<LoaderBall key={index} size={size} index={index} />
			))}
		</div>
	);
};

export const FullPageLoader = ({ className, loaderClassName, size = 10 }: FullPageLoaderProps) => {
	return (
		<div
			className={classNames('fixed left-0 top-0 flex h-full w-full items-center justify-center', className)}
			data-testid="loader"
		>
			<Loader size={Number(size)} className={loaderClassName} />
		</div>
	);
};
