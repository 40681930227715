import { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';

interface Props {
	options?: Array<{
		label: string;
		value: string;
	}>;
	id: string;
	label: string;
	name: string;
	error?: string;
	setValue(value: string): void;
	defaultValue?: string;
}

const HookFormTypeAhead = ({ options, name, label, error, id, setValue, defaultValue }: Props) => {
	const [selectOptions, setSelectOptions] = useState(options);
	const [selectOpen, setSelectOpen] = useState(false);
	const hideSelect = () => setSelectOpen(false);
	const inputRef = useRef<HTMLInputElement>(null);
	const inputValue = inputRef.current?.value;

	const onListItemClick = (value, label) => {
		setValue(value);
		inputRef.current.value = label;
		setTimeout(() => hideSelect, 300);
	};

	const onFilterChange = debounce((event) => {
		setSelectOptions(options.filter(({ label }) => label.toLowerCase().includes(event.target.value.toLowerCase())));
		if (event.target.value) {
			setSelectOpen(true);
		}
	}, 300);

	useEffect(() => {
		setSelectOptions(options);
	}, [options]);

	useEffect(() => {
		if (!!defaultValue) {
			const defaultOption = options.find((option) => option.value === defaultValue);
			if (defaultOption) {
				inputRef.current.value = defaultOption.label;
			}
		}
	}, [defaultValue, options]);

	return (
		<label htmlFor={id} className="relative flex w-full flex-col text-grey-5">
			<span className="text-xs">{label}</span>
			<input
				ref={inputRef}
				autoComplete="off"
				className={classNames(
					'mt-2 rounded border px-2 py-2 focus:outline-none focus:ring focus:ring-blue-4/40',
					error ? 'border-red-5' : 'border-grey-3 focus:border-blue-4'
				)}
				type="text"
				name={name}
				onChange={onFilterChange}
				id={id}
				onClick={() => {
					if (!selectOpen && inputValue) {
						setSelectOpen(true);
					}
				}}
				onBlur={() => {
					setTimeout(() => hideSelect(), 150);
				}}
			/>
			{error && <p className="mt-1 text-xs text-red-5">{error}</p>}
			<ul
				className={classNames(
					'absolute z-50 max-h-52 w-full translate-y-[72px] cursor-pointer flex-col overflow-y-auto rounded border border-grey-3 bg-white py-2',
					selectOpen ? 'flex' : 'hidden'
				)}
			>
				{selectOptions.map(({ value, label }) => {
					return (
						<li onClick={() => onListItemClick(value, label)} key={value} className="p-2 text-grey-6 hover:bg-grey-1">
							{label}
						</li>
					);
				})}
				{selectOptions.length === 0 && <li className="p-2 text-grey-6">No results found.</li>}
			</ul>
		</label>
	);
};

export { HookFormTypeAhead };
