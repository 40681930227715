import { createContext, useEffect, useState, useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import { InitialState } from '../../types/hubTypes';
import RocketChatError from './RocketChatError';
import { capture } from '../posthog';
import env from '../../env';

export type RocketChatProps = {
	guestName?: string;
	accountId?: string;
	page?: string;
	department?: string;
	title?: string;
};
export type WindowRocketChat = typeof window.RocketChat;

export type RocketChatContextType = {
	toggleMinMax: () => void;
	minimizeWidget: () => void;
	maximizeWidget: () => void;
	toggleDisplayWidget: () => void;
	setCustomField: (key: string, value: string) => void;
	setGuestName: (name: string) => void;
	setGuestEmail: (email: string) => void;
	showWidget: () => void;
	hideWidget: () => void;
	addDisplayWidget: () => void;
	rocketChatEnabled: boolean;
	widgetRef: React.RefObject<HTMLDivElement>;
};

const RocketChatContext = createContext<RocketChatContextType | null>(undefined);

const RocketChatProvider = (props) => {
	const rocketChatEnabled = process.env.REACT_APP_ROCKET_CHAT_ENABLED === 'true';
	const { children } = props || {};
	const [displayWidget, setDisplayWidget] = useState(true);
	const [serverError, setServerError] = useState(false);
	const [hidingWidget, setHidingWidget] = useState(false);
	const [minimizedWidget, setMinimizedWidget] = useState(true);
	const widgetRef = useRef(null);
	const accountDetails = useSelector(
		(
			state: InitialState & {
				hubAccountPage: {
					accountDetails: {
						customerSfid?: string;
						firstName?: string;
						lastName?: string;
						email?: string;
					};
				};
			}
		) => state.hubAccountPage.accountDetails
	);
	const isLead = 'userType' in accountDetails && accountDetails.userType === 'Lead';

	useEffect(() => {
		try {
			if (rocketChatEnabled && accountDetails.customerSfid && displayWidget && !isLead && !widgetRef.current) {
				// TODO move urls to env
				const serverUrl = env.REACT_APP_ROCKET_CHAT_BASE_URL;

				window.RocketChat = function (c) {
					window.RocketChat._.push(c);
				};
				window.RocketChat._ = [];
				window.RocketChat.url = serverUrl;
				// Get first script tag in the page
				const firstScriptTag = document.getElementsByTagName('script')[0];

				const rocketChatScript = document.createElement('script');

				rocketChatScript.async = true;
				rocketChatScript.src = `${serverUrl}/rocketchat-livechat.min.js?_=201903270000`;

				rocketChatScript.onload = function () {
					window.RocketChat(function () {
						// Basic styling and department
						this.initialize({
							theme: {
								color: '#004BB9',
								fontColor: '#FFFFFF',
								iconColor: '#004BB9',
							},
							department: 'support_customer_hub',
							language: 'en',
						});

						//   Add all the user details here
						this.registerGuest({
							token: accountDetails.customerSfid, // The token field is for creating unique sessions
							name: `${accountDetails.firstName} ${accountDetails.lastName}`,
							email: accountDetails.email,
							department: 'botpress',
							customFields: [
								{ key: 'sf_account_id', value: accountDetails.customerSfid, overwrite: true },
								{ key: 'department_name', value: 'support_customer_hub', overwrite: true },
								{
									key: 'salesforce_link',
									value: `https://cluey.lightning.force.com/lightning/r/Account/${accountDetails.customerSfid}/view`,
									overwrite: true,
								},
							],
						});

						this.showWidget();

						this.onChatMaximized(function () {
							capture('click_rocket-chat-open');
							setMinimizedWidget(false);
						});
						this.onChatMinimized(function () {
							capture('click_rocket-chat-close');
							setMinimizedWidget(true);
						});

						widgetRef.current = document.querySelector('.rocketchat-widget');
					});
				};

				rocketChatScript.onerror = function (e) {
					console.error('Error loading RocketChat');
					console.error(e);
					setServerError(true);
				};
				firstScriptTag.parentNode.insertBefore(rocketChatScript, firstScriptTag);
			}
		} catch (e) {
			console.error('Error loading RocketChat');
			console.error(e);
			setServerError(true);
		}
	}, [accountDetails, displayWidget, rocketChatEnabled, isLead, widgetRef]);

	const setCustomField = (key: string, value: string) => {
		rocketChatEnabled && window.RocketChat &&
			window.RocketChat(function () {
				this.setCustomField(key, value);
			});
	};

	const setGuestName = (name: string) => {
		rocketChatEnabled && window.RocketChat &&
			window.RocketChat(function () {
				this.setGuestName(name);
			});
	};

	const setGuestEmail = (email: string) => {
		rocketChatEnabled && window.RocketChat &&
			window.RocketChat(function () {
				this.setGuestEmail(email);
			});
	};

	const showWidget = () => {
		if (rocketChatEnabled && window.RocketChat) {
			widgetRef.current.style.display = '';

			window.RocketChat(function () {
				this.showWidget();
			});
		}
	};

	const hideWidget = () => {
		if (rocketChatEnabled && window.RocketChat) {
			widgetRef.current.style.display = 'none';

			window.RocketChat(function () {
				this.hideWidget();
			});
		}
	};

	const maximizeWidget = () => {
		rocketChatEnabled && window.RocketChat &&
			window.RocketChat(function () {
				this.maximizeWidget();
			});
		setMinimizedWidget(false);
	};

	const minimizeWidget = () => {
		rocketChatEnabled && window.RocketChat &&
			window.RocketChat(function () {
				this.minimizeWidget();
			});
		setMinimizedWidget(true);
	};

	const toggleMinMax = () => {
		if (!displayWidget) {
			addDisplayWidget();
		} else {
			if (minimizedWidget) {
				maximizeWidget();
			} else {
				minimizeWidget();
			}
		}
	};

	const addDisplayWidget = () => {
		if (displayWidget) {
			toggleMinMax();
		}
		setDisplayWidget(true);
	};

	const toggleDisplayWidget = () => {
		if (hidingWidget) {
			showWidget();
			maximizeWidget();
			setHidingWidget(false);
		} else {
			hideWidget();
			setHidingWidget(true);
		}
	};

	const clearError = () => {
		setServerError(false);
	};

	return (
		<RocketChatContext.Provider
			value={{
				toggleMinMax,
				minimizeWidget,
				maximizeWidget,
				toggleDisplayWidget,
				addDisplayWidget,
				setCustomField,
				setGuestName,
				setGuestEmail,
				showWidget,
				hideWidget,
				rocketChatEnabled,
				widgetRef,
			}}
		>
			{children}
			{serverError && <RocketChatError clearError={clearError} />}
		</RocketChatContext.Provider>
	);
};

const useRocketChatContext = () => {
	const context = useContext(RocketChatContext);
	return context;
};

export { RocketChatProvider, RocketChatContext, useRocketChatContext };
