import { generatePath } from 'react-router';
import { getActiveEnrolments, getSessionReportId } from '../../selectors/hubSelector';
import {
  PATH_HUB_CR_CANCEL_ENROLMENT,
  PATH_HUB_CR_TECHNICAL_ISSUE,
  PATH_HUB_MS_LEARNING_ADJUSTMENTS,
  PATH_HUB_MS_SESSION_TIMES,
  PATH_SESSION_REPORT,
  PATH_HUB_CR_REPORT_ISSUE,
} from '../../util/pagePath';
import { SR_LOGIN_RESET } from '../../actions/actionTypes';
import { STUDENT_PROGRESS_TAB_VIEWS } from '../../util/constants';
import { ELEMENT_TYPES, SUPPORT_OPTIONS } from '../../types/hubEnums';
import { OptionCard } from '../EnrolmentManagement/OptionCard';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSelectEnrolmentModal } from '../Modals/SelectEnrolmentModal/SelectEnrolmentModal';
import { useState } from 'react';
import update from 'immutability-helper';
import type { Enrolment } from '../../types/hubInterfaces';
import type { ServiceOptionsListType } from '../../types/hubTypes';
import posthog from 'posthog-js';
import { PostHogEvent } from '../../util/posthog';
import { getCancelEnrolmentBasePath } from '../../selectors/enrolmentSelector';

interface RedirectPath {
  path: string;
  parameterized: boolean;
  withEnrolmentIdToken: boolean;
  withSessionReportIdToken: boolean;
  withFormViewToken: boolean;
}

export interface Option {
  title: ServiceOptionsListType;
  linkName: string;
  element: ELEMENT_TYPES;
  linkTo?: string;
  openInNewTab?: boolean;
  posthogEvent?: PostHogEvent;
}

interface Props {
  options: Array<Option>;
  additionalOptionComponents?: Array<JSX.Element>;
}

const ServiceOptionsList = ({ options, additionalOptionComponents }: Props) => {
  const activeEnrolments: Array<Enrolment> = useSelector(getActiveEnrolments);
  const state = useSelector((state) => state);
  const cancelEnrolmentBasePath = useSelector(getCancelEnrolmentBasePath);
  const dispatch = useDispatch();
  const resetSessionReport = () => dispatch({ type: SR_LOGIN_RESET });
  const initialRedirectPath = {
    path: '',
    parameterized: true,
    withEnrolmentIdToken: false,
    withSessionReportIdToken: false,
    withFormViewToken: false,
  };
  const [redirectPath, setRedirectPath] = useState<RedirectPath>(initialRedirectPath);
  const history = useHistory();
  const { open, openModal, closeModal, SelectEnrolmentModal } = useSelectEnrolmentModal();

  const updateRedirectPath = (title: ServiceOptionsListType): RedirectPath => {
    let updatedPath = { ...initialRedirectPath };
    switch (title) {
      case SUPPORT_OPTIONS.SESSION_TIMES:
        updatedPath = update(updatedPath, { $merge: { path: PATH_HUB_MS_SESSION_TIMES } });
        break;
      case SUPPORT_OPTIONS.LEARNING_FEEDBACK:
        updatedPath = update(updatedPath, {
          $merge: {
            path: PATH_SESSION_REPORT,
            withSessionReportIdToken: true,
          },
        });
        break;
      case SUPPORT_OPTIONS.LEARNING_ADJUSTMENTS:
        updatedPath = update(updatedPath, { $merge: { path: PATH_HUB_MS_LEARNING_ADJUSTMENTS } });
        break;
      case SUPPORT_OPTIONS.TECHNOLOGY:
        updatedPath = update(updatedPath, {
          $merge: { path: PATH_HUB_CR_TECHNICAL_ISSUE, withEnrolmentIdToken: true },
        });
        break;
      case SUPPORT_OPTIONS.MANAGE_ENROLMENT:
        updatedPath = update(updatedPath, {
          $merge: { path: PATH_HUB_CR_CANCEL_ENROLMENT, withEnrolmentIdToken: true },
        });
        break;
      case SUPPORT_OPTIONS.CANCEL_ENROLMENT:
        updatedPath = update(updatedPath, {
          $merge: {
            path: cancelEnrolmentBasePath,
            withEnrolmentIdToken: true,
            withFormViewToken: true,
          },
        });
        break;
      case SUPPORT_OPTIONS.REPORT_ISSUE:
        updatedPath = update(updatedPath, { $merge: { path: PATH_HUB_CR_REPORT_ISSUE, withEnrolmentIdToken: true } });
        break;
    }
    setRedirectPath(updatedPath);
    return updatedPath;
  };

  const createPath = ({
    enrolmentId,
    path,
    withEnrolmentIdToken,
    withSessionReportIdToken,
    withFormViewToken,
    sessionReportId,
  }): string => {
    if (withSessionReportIdToken) {
      return `${path}?token=${sessionReportId}&activeTab=${STUDENT_PROGRESS_TAB_VIEWS.SESSION_REPORT}`;
    }
    if (withFormViewToken) {
      return `${path}?view=form&token=${enrolmentId}`;
    }
    if (withEnrolmentIdToken) {
      return `${path}?token=${enrolmentId}`;
    }
    return path;
  };

  const [shouldOpenInNewTab, setShouldOpenInNewTab] = useState(false);

  const onOptionCardClick = (title, optionIndex, posthogEvent = '') => {
    if (!!posthogEvent) {
      posthog.capture(posthogEvent);
    }
    setShouldOpenInNewTab(options[optionIndex].openInNewTab);
    const { parameterized, path, withEnrolmentIdToken, withSessionReportIdToken, withFormViewToken } =
      updateRedirectPath(title);
    if (withSessionReportIdToken) {
      resetSessionReport();
    }
    if (activeEnrolments.length === 1) {
      const enrolmentId = activeEnrolments[0].enrolmentId;
      const sessionReportId = getSessionReportId(state, enrolmentId);
      const redirectPath = generatePath(
        createPath({
          enrolmentId,
          path,
          withEnrolmentIdToken,
          withSessionReportIdToken,
          withFormViewToken,
          sessionReportId,
        }),
        parameterized ? { enrolmentId } : undefined
      );

      if (options[optionIndex].openInNewTab) {
        window.open(redirectPath, '_blank');
        closeModal();
      } else {
        history.push(redirectPath);
      }
    } else {
      openModal();
    }
  };

  return (
    <section className="text-grey-6">
      <SelectEnrolmentModal
        closeModal={closeModal}
        openInNewTab={shouldOpenInNewTab}
        enrolmentLinkTo={({ enrolmentId }) => {
          const { path, parameterized, withEnrolmentIdToken, withSessionReportIdToken, withFormViewToken } =
            redirectPath;
          const sessionReportId = getSessionReportId(state, enrolmentId);
          return generatePath(
            createPath({
              enrolmentId,
              path,
              withEnrolmentIdToken,
              withSessionReportIdToken,
              withFormViewToken,
              sessionReportId,
            }),
            parameterized ? { enrolmentId } : undefined
          );
        }}
        open={open}
      />
      <div className="mt-6">
        {options.map(({ element, linkName, linkTo, title, posthogEvent }, index) => (
          <OptionCard
            key={title}
            linkTo={linkTo}
            onClickHandler={
              element === ELEMENT_TYPES.BUTTON ? () => onOptionCardClick(title, index, posthogEvent) : undefined
            }
            capturePosthogEvent={
              !!posthogEvent
                ? () => {
                    posthog.capture(posthogEvent);
                  }
                : undefined
            }
          >
            <div className="text-xl font-bold">{title}</div>
            <div className="mt-2 text-lg text-blue-5">{linkName}</div>
          </OptionCard>
        ))}
        {additionalOptionComponents}
      </div>
    </section>
  );
};

export { ServiceOptionsList };
