import React, { Suspense, lazy, useEffect, useRef, useState } from 'react';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';

import { store } from './store/configureStore';
import WindowEvents from './WindowEvents';
import env from './env';
import AppRouter from './router/AppRouter';
import { QueryClientProvider } from '@tanstack/react-query';
import { RocketChatProvider } from './util/RocketChat/RocketChatProvider';
import { api } from './api';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PostHogProvider } from 'posthog-js/react';
import { posthog } from './util/posthog';
import 'mathlive';

if (env.REACT_APP_ENABLE_GOOGLE_TAG_MANAGER) {
  const tagManagerArgs = {
    gtmId: 'GTM-5WLMP44',
    dataLayer: {},
  };

  TagManager.initialize(tagManagerArgs);
}

const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then((d) => ({
    default: d.ReactQueryDevtools,
  }))
);

const ReactRoot = () => {
  const mountedRef = useRef(false);
  const [showDevtools, setShowDevtools] = useState(false);

  useEffect(() => {
    if (!mountedRef.current) {
      // This line could be moved to windowEvents.js, when it's in the same bundle
      // generated by CRA
      if (process.env.NODE_ENV === 'development') {
        window.removeEventListener('beforeunload', WindowEvents.beforeUnloadListener);
      }

      // integrate Marketing Cloud Interaction Studio
      const head = document.querySelector('head');
      const mcScript = document.createElement('script');
      const scriptUrl =
        env.REACT_APP_ENVIRONMENT === 'production'
          ? '//cdn.evgnet.com/beacon/clueylearningptyltd/cluey/scripts/evergage.min.js'
          : '//cdn.evgnet.com/beacon/clueylearningptyltd/engage/scripts/evergage.min.js';

      const dataset = env.REACT_APP_ENVIRONMENT === 'production' ? 'cluey' : 'engage';
      mcScript.setAttribute('src', scriptUrl);
      mcScript.setAttribute('type', 'text/javascript');

      mcScript.onload = () => {
        console.log('mc script loaded, try without initializing here ', dataset);
        if (window.SalesforceInteractions) {
          // window.SalesforceInteractions.init({
          //   account: 'clueylearningptyltd',
          //   endpoint: scriptUrl,
          //   dataset,
          // });
        } else {
          console.error('SalesforceInteractions library not loaded');
        }
      };

      // append the script tag only if it isn't present; hot reload causes duplication hence the checking
      if (!document.querySelector(`script[src="${scriptUrl}"`)) {
        head.appendChild(mcScript);
      }

      mountedRef.current = true;

      // Enable devtools in production
      window.toggleDevtools = () => {
        setShowDevtools((curr) => {
          console.log('Devtools', curr ? 'disabled' : 'enabled');
          return !curr;
        });
      };
    }
  }, []);

  return (
    <PostHogProvider client={posthog}>
      <QueryClientProvider client={api.getQueryClient()}>
        <Provider store={store}>
          <RocketChatProvider>
            <AppRouter />
          </RocketChatProvider>
          <ReactQueryDevtools />
          {showDevtools && (
            <Suspense fallback={null}>
              <ReactQueryDevtoolsProduction />
            </Suspense>
          )}
        </Provider>
      </QueryClientProvider>
    </PostHogProvider>
  );
};

export default ReactRoot;
