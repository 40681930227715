import initialState from '../../initialState';
import * as actionTypes from '../../../actions/summerModeActionTypes';

export default function summerMode(state = initialState.ui.apiState.summerMode, action) {
  switch (action.type) {
    case actionTypes.SM_SAVE_CODE_CAMP_POSTCODE_START:
      return {
        ...state,
        codeCamp: {
          ...state.codeCamp,
          isSubmitting: true,
          isSubmitted: false,
          isSubmissionFailed: false,
        },
      };
    case actionTypes.SM_SAVE_CODE_CAMP_POSTCODE_SUCCESS:
      return {
        ...state,
        codeCamp: {
          ...state.codeCamp,
          isSubmitting: false,
          isSubmitted: true,
          isSubmissionFailed: false,
        },
      };
    case actionTypes.SM_SAVE_CODE_CAMP_POSTCODE_FAILED:
      return {
        ...state,
        codeCamp: {
          ...state.codeCamp,
          isSubmitting: false,
          isSubmitted: false,
          isSubmissionFailed: true,
        },
      };
    case actionTypes.SM_SAVE_SUMMER_MODE_DATA_START:
      return {
        ...state,
        saveSummerModeData: {
          ...state.saveSummerModeData,
          isSubmitting: true,
          isSubmitted: false,
          isSubmissionFailed: false,
        },
      };
    case actionTypes.SM_SAVE_SUMMER_MODE_DATA_SUCCESS:
      return {
        ...state,
        saveSummerModeData: {
          ...state.saveSummerModeData,
          isSubmitting: false,
          isSubmitted: true,
          isSubmissionFailed: false,
        },
      };
    case actionTypes.SM_SAVE_SUMMER_MODE_DATA_FAILED:
      return {
        ...state,
        saveSummerModeData: {
          ...state.saveSummerModeData,
          isSubmitting: false,
          isSubmitted: false,
          isSubmissionFailed: true,
        },
      };
    default:
      return state;
  }
}
