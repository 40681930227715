import React, { ReactNode, useEffect } from 'react';
import { Link, Pending, Container } from '@cluey/cluey-components';
import classNames from 'classnames';

import withHeadTag from '../HeadComponent';
import HubHeader from './HubHeader';
import HubFooter from './HubFooter/HubFooter';
import EnrolmentStatus from '../../components/EnrolmentManagement/EnrolmentStatus';
import { ConnectedEnrolmentStatusSummerMode as EnrolmentStatusSummerMode } from '../../components/EnrolmentManagement/SummerMode/EnrolmentStatusSummerMode';

import { PATH_HUB_ENROLMENT_DETAIL, PATH_HUB_HOME } from '../../util/pagePath';
import { isHolidayLearningAlertOn, scrollToTop } from '../../util/helpers';
import { EnrolmentStatusReboarding } from '../../components/EnrolmentManagement/EnrolmentStatusReboarding';
import { Loader } from '../../components/Loader';
import { formatError } from '../../util/errors';

type EnrolmentLayoutProps = {
	isLoading?: boolean;
	error?: unknown;
	sectionHeading?: ReactNode;
	mainHeading?: ReactNode;
	subHeading?: ReactNode;
	showEnrolmentStatus?: boolean;
	showSummerModeEnrolmentStatus?: boolean;
	enrolmentId?: string;
	children?: ReactNode;
	tracking?: string;
	hasCTAAboveFooter?: boolean;
	sectionHeadingLink?: string;
};

const EnrolmentLayout = ({
	isLoading = false,
	error,
	sectionHeading,
	mainHeading,
	subHeading,
	showEnrolmentStatus = false,
	showSummerModeEnrolmentStatus = false,
	enrolmentId = '',
	children,
	tracking = null,
	hasCTAAboveFooter = false,
	sectionHeadingLink = null,
}: EnrolmentLayoutProps) => {
	useEffect(() => {
		scrollToTop();
	}, []);

	const linkTo = enrolmentId ? `${PATH_HUB_ENROLMENT_DETAIL}?token=${enrolmentId}` : PATH_HUB_HOME;

	const shouldShowEnrolmentStatusReboarding =
		!showSummerModeEnrolmentStatus && showEnrolmentStatus && isHolidayLearningAlertOn();

	return (
		<div
			className={classNames('flex min-h-screen flex-col', {
				[tracking]: tracking !== null,
			})}
		>
			<HubHeader title="Hub" />
			<Pending loading={isLoading} error={error ? formatError(error) : undefined} loader={<Loader />}>
				<div className="mb-20 flex flex-grow flex-col lg:mb-0">
					<Container className="px-4 py-7 md:py-10 lg:mb-0">
						{sectionHeading && (
							<Link
								size="xs"
								className="mb-3 font-bold uppercase tracking-widest lg:mb-2"
								to={sectionHeadingLink ?? linkTo}
							>
								{sectionHeading}
							</Link>
						)}
						{mainHeading && (
							<h1 className="cy-enrolment-title mb-2 mt-4 font-display text-4xl font-bold md:text-[40px]">
								{mainHeading}
							</h1>
						)}
						{subHeading && <span className="block text-2xl md:text-3xl">{subHeading}</span>}

						{showEnrolmentStatus && <EnrolmentStatus />}
						{showSummerModeEnrolmentStatus && <EnrolmentStatusSummerMode />}
						{shouldShowEnrolmentStatusReboarding && <EnrolmentStatusReboarding />}
					</Container>
					{children}
				</div>
			</Pending>
			<HubFooter hasCTAAboveFooter={hasCTAAboveFooter} />
		</div>
	);
};

export default withHeadTag(EnrolmentLayout);
