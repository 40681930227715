import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import moment from 'moment';
import { AnchorButton } from '@cluey/cluey-components';
import { getHolidays } from '../../selectors/upcomingSessionsSelector';

import { TODAY_SESSION_CONSTANTS } from '../../util/constants';
import { formatSession, isHolidayCheck, isSessionLinkValid } from '../../util/helpers';
import { getRescheduleSessionRoute, getSkipSessionRoute } from '../../util/helper';
import { capture } from '../../util/posthog';

class SessionsListItem extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			statusText: TODAY_SESSION_CONSTANTS.STATUS_INIT,
			joinSessionInfoText: 'You can join 15 mins prior to session',
			joinTextVisible: true,
			changeCTAEnaled: true,
		};

		const { timezone, session, holidays } = this.props;

		const { startTime, type, student, studentSessionId } = session;

		this.isGroupSession = this.isGroupSessionCheck(type);

		const { isHoliday, indicatorText } = isHolidayCheck(startTime, holidays);
		this.isHoliday = isHoliday;
		this.indicatorText = indicatorText;

		const { date, detail } = formatSession({
			session,
			timezone,
		});
		this.date = date;
		this.detail = detail;

		this.detailForContactFlow = `${date}: ${student}, ${detail}`;
		this.studentSessionId = studentSessionId;
	}

	componentDidMount() {
		const { isTodaySession } = this.props;
		const { startTime, endTime } = this.props.session;
		this.liveUpdateTimeout = null;

		if (isTodaySession) {
			const initTimeout = this.getLiveUpdateTimeoutOffset({
				startTime,
				endTime,
			});
			const initState = this.getTodaySessionNewState({ startTime, endTime });
			this.setState(initState);

			const liveUpdateTimeoutCB = () => {
				const updatedTimeout = this.getLiveUpdateTimeoutOffset({
					startTime,
					endTime,
				});
				const updatedState = this.getTodaySessionNewState({
					startTime,
					endTime,
				});
				this.setState(updatedState);
				if (updatedTimeout !== 0) {
					this.liveUpdateTimeout = window.setTimeout(liveUpdateTimeoutCB, updatedTimeout);
				}
			};

			this.liveUpdateTimeout = window.setTimeout(liveUpdateTimeoutCB, initTimeout);
		}
	}

	componentWillUnmount() {
		clearTimeout(this.liveUpdateTimeout);
	}

	getLiveUpdateTimeoutOffset = ({ startTime, endTime }) => {
		const now = new Date();
		const minutesToStart = moment(startTime).diff(now, 'minutes');
		const minutesToEnd = moment(endTime).diff(now, 'minutes');
		let timeoutOffset = 0;

		if (minutesToStart > 60) {
			timeoutOffset = 30 * 60 * 1000;
		} else if (minutesToStart > 40 && minutesToStart <= 60) {
			timeoutOffset = 10 * 60 * 1000;
		} else if (minutesToStart <= 40 && minutesToStart > 5) {
			timeoutOffset = 1 * 60 * 1000;
		} else if (minutesToStart <= 5 && minutesToEnd >= 0) {
			timeoutOffset = 30 * 1000;
		} else if (minutesToEnd < 0) {
			timeoutOffset = 0;
		}

		return timeoutOffset;
	};

	getTodaySessionNewState = ({ startTime, endTime }) => {
		const now = new Date();
		const minutesToStart = moment(startTime).diff(now, 'minutes');
		const minutesToEnd = moment(endTime).diff(now, 'minutes');

		let statusText = TODAY_SESSION_CONSTANTS.STATUS_INIT;
		let joinTextVisible = true;
		let joinCTAEnabled = false;
		let changeCTAEnaled = true;
		let joinCTAMobileEnabled = true;
		let hideJoinCTA = false;

		if (minutesToStart < 30 && minutesToStart >= 15) {
			statusText = TODAY_SESSION_CONSTANTS.STATUS_SOON;
		} else if (minutesToStart < 15 && minutesToStart > 0) {
			statusText = TODAY_SESSION_CONSTANTS.STATUS_SOON;
			joinTextVisible = false;
			joinCTAEnabled = true;
		} else if (minutesToStart === 0) {
			const secondsToStart = moment(startTime).diff(now, 'seconds');
			if (secondsToStart > 0) {
				statusText = TODAY_SESSION_CONSTANTS.STATUS_SOON;
			} else {
				statusText = TODAY_SESSION_CONSTANTS.STATUS_IN_PROGRESS;
			}

			joinTextVisible = false;
			joinCTAEnabled = true;
			changeCTAEnaled = false;
		} else if (minutesToStart < 0 && minutesToEnd > 0) {
			statusText = TODAY_SESSION_CONSTANTS.STATUS_IN_PROGRESS;
			joinTextVisible = false;
			joinCTAEnabled = true;
			changeCTAEnaled = false;
		} else if (minutesToEnd === 0) {
			const secondsToEnd = moment(endTime).diff(now, 'seconds');
			if (secondsToEnd > 0) {
				statusText = TODAY_SESSION_CONSTANTS.STATUS_IN_PROGRESS;
				joinCTAEnabled = true;
			} else {
				statusText = TODAY_SESSION_CONSTANTS.STATUS_INIT;
				joinCTAEnabled = false;
				joinCTAMobileEnabled = false;
				hideJoinCTA = true;
			}

			joinTextVisible = false;
			changeCTAEnaled = false;
		} else if (minutesToStart < 0 && minutesToEnd < 0) {
			statusText = TODAY_SESSION_CONSTANTS.STATUS_INIT;
			joinTextVisible = false;
			joinCTAEnabled = false;
			changeCTAEnaled = false;
			joinCTAMobileEnabled = false;
			hideJoinCTA = true;
		}

		return {
			statusText,
			joinTextVisible,
			joinCTAEnabled,
			changeCTAEnaled,
			joinCTAMobileEnabled,
			hideJoinCTA,
		};
	};

	isGroupSessionCheck = (courseType) => {
		return courseType.toLowerCase() === 'group' || courseType.toLowerCase() === 'holding group';
	};

	render() {
		const { isSingleStudent, isTodaySession, session, isNZUser } = this.props;
		const { student, sessionToken } = session;

		const { statusText, joinSessionInfoText, joinTextVisible, changeCTAEnaled } = this.state;
		const isSessionTokenValid = isSessionLinkValid({
			sessionLink: sessionToken,
		});

		const liClasses = classNames('list-group-item py-5 px-6', {
			private: !this.isGroupSession,
		});
		const studentClasses = classNames('block', { 'mb-2': !isSingleStudent }, 'student');

		const manageSessionLink = this.isGroupSession
			? getSkipSessionRoute(this.studentSessionId)
			: getRescheduleSessionRoute(this.studentSessionId);

		return (
			<li className={liClasses}>
				<div className="inner lg:flex lg:items-center">
					<span className="date relative mb-3 flex items-center">
						<span>{this.date}</span>
						{statusText.length > 0 && <span className="status hidden px-3 py-1 lg:inline-block">{statusText}</span>}
						{this.isHoliday && (
							<div className="holiday-indicator inline-block cursor-pointer" role="button" tabIndex={0}>
								<span className="hidden px-4 py-3 font-normal">{this.indicatorText}</span>
							</div>
						)}
					</span>
					<span className={studentClasses}>{!isSingleStudent && student}</span>
					<span className="detail mb-3 block">
						<span>{this.detail}</span>
						{isTodaySession && joinTextVisible && (
							<span className="join-session-info-text mt-2 hidden lg:block">({joinSessionInfoText})</span>
						)}
					</span>
					{statusText.length > 0 && <span className="status mb-3 block px-3 py-1 lg:hidden">{statusText}</span>}
					{this.isGroupSession && (
						<div className="type block text-right">
							<span className="px-3 py-1">Group</span>
						</div>
					)}
					<div className="actions block text-right">
						{isTodaySession && (
							<Fragment>
								<AnchorButton
									href={sessionToken}
									className={classNames('cta--min-width cta--join hidden w-auto uppercase lg:inline-block', {
										'disabled cursor-not-allowed': !isSessionTokenValid,
									})}
									onClick={() => capture('click_session-join__upcoming-sessions-page')}
									target="_self"
									role="button"
									size="xs"
									appearance="reverse"
								>
									Join Session
								</AnchorButton>
								<span className="mb-3 block lg:hidden">Join session from a desktop computer</span>
							</Fragment>
						)}
						{!isNZUser && (
							<AnchorButton
								href={manageSessionLink}
								appearance="reverse"
								target="blank"
								className={classNames(
									'w-full lg:ml-3 lg:w-auto',
									!changeCTAEnaled && 'disabled invisible hidden cursor-not-allowed lg:inline-block'
								)}
							>
								Change
							</AnchorButton>
						)}
					</div>
				</div>
			</li>
		);
	}
}

SessionsListItem.propTypes = {
	isSingleStudent: PropTypes.bool.isRequired,
	session: PropTypes.shape({
		student: PropTypes.string.isRequired,
		startTime: PropTypes.string.isRequired,
		endTime: PropTypes.string.isRequired,
		subject: PropTypes.string.isRequired,
		tutor: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		studentSessionId: PropTypes.string.isRequired,
		sessionToken: PropTypes.string,
		isNextSession: PropTypes.bool,
		uniqueEnrolmentId: PropTypes.string.isRequired,
	}).isRequired,
	timezone: PropTypes.string,
	isTodaySession: PropTypes.bool,
	holidays: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string.isRequired,
			startDate: PropTypes.string.isRequired,
			endDate: PropTypes.string.isRequired,
			state: PropTypes.string.isRequired,
		})
	),
	isNZUser: PropTypes.bool,
};

SessionsListItem.defaultProps = {
	timezone: 'Australia/Sydney',
	isTodaySession: false,
	holidays: [],
	isNZUser: false,
};

export default connect((state) => {
	return {
		timezone: state.upcomingSessions.timezone,
		holidays: getHolidays(state),
	};
}, null)(SessionsListItem);
