import classnames from 'classnames';
import { AnchorButton } from '@cluey/cluey-components';
import { CardItem } from '../../common/CardItem';
import { getRescheduleSessionRoute } from '../../util/helper';
import useWindowWidth from '../../hooks/common/useWindowWidth';
import { BREAKPOINTS } from '../../util/constants';

interface Props {
  nextSession: {
    tutorName: string;
    duration: number;
    startTime: string;
    sessionId: string;
  };
  isLocalUserStudent: boolean;
  isNZUser: boolean;
}

const NextSession = ({ nextSession, isLocalUserStudent, isNZUser }: Props) => {
  const windowWidth = useWindowWidth();
  const { tutorName, duration, sessionId } = nextSession;
  const displayLink = isLocalUserStudent && sessionId && !isNZUser;
  const isXSmallDevice = windowWidth < BREAKPOINTS.sm;

  return (
    <CardItem title="Next learning session" styleClass="next-session-card">
      <div className="flex-row justify-between md:flex">
        <div>
          <div>- {duration} minute session</div>
          <div>- Tutor: {tutorName}</div>
        </div>
        {displayLink && (
          <div className={classnames('flex items-start', isXSmallDevice && 'flex-col')}>
            <AnchorButton
              href={getRescheduleSessionRoute(sessionId)}
              appearance="reverse"
              target="blank"
              className="w-full !no-underline"
            >
              Change
            </AnchorButton>
          </div>
        )}
      </div>
    </CardItem>
  );
}

export { NextSession };
