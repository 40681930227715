import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CardItem } from '../../common/CardItem';

export class PracticeTSCard extends React.Component {
  render() {
    const {
      id,
      isTestSimulation,
      name,
      header,
      btnLink,
      questionAssigned,
      questionSubmitted,
      numberOfVisits,
      lastVisited,
      startTime,
    } = this.props;
    const allQCompleted = questionSubmitted === questionAssigned;
    const cardTitle = isTestSimulation ? `Test simulation (${name})` : `Practice ${name ? `(${name})` : ''}`;
    // if older than 2 months do not display the button to access the session
    const isSessionTooOld = moment().subtract(2, 'months').isAfter(moment(startTime));
    const btnMsg = isTestSimulation ? 'Visit test simulation' : 'Visit practice';
    return (
      <CardItem id={id} title={cardTitle} btnMsg={isSessionTooOld ? null : btnMsg} headerMsg={header} buttonLinkUrl={btnLink} trackingClass="ga-timeline-item">
        <ul className="list-unstyled pb-0 mb-0">
          <li>
            {allQCompleted && questionAssigned > 1 && `- All ${questionAssigned} questions completed`}
            {allQCompleted && questionAssigned === 1 && `- ${questionSubmitted} of ${questionAssigned} question completed`}
            {!allQCompleted && `- ${questionSubmitted} of ${questionAssigned} questions completed`}
          </li>
          <li>
            {!numberOfVisits && '- Not visited yet'}
            {numberOfVisits && `- ${numberOfVisits} visit${numberOfVisits > 1 ? 's' : ''}, last visited on ${moment(lastVisited).format('D MMM')}`}
          </li>
        </ul>
      </CardItem>
    );
  }
}

PracticeTSCard.propTypes = {
  isTestSimulation: PropTypes.bool,
  name: PropTypes.string,
  header: PropTypes.string,
  btnLink: PropTypes.string,
  questionAssigned: PropTypes.number,
  questionSubmitted: PropTypes.number,
  numberOfVisits: PropTypes.number,
  lastVisited: PropTypes.string,
  id: PropTypes.string,
  startTime: PropTypes.string,
};

PracticeTSCard.defaultProps = {
  isTestSimulation: false,
  name: null,
  header: null,
  btnLink: null,
  questionAssigned: null,
  questionSubmitted: null,
  numberOfVisits: null,
  lastVisited: null,
  id: null,
  startTime: null,
};
