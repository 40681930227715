import { MathfieldElement, MathfieldElementAttributes } from 'mathlive';
import React, { forwardRef } from 'react';
import './styles.css';

export type MathFieldProps = React.DetailedHTMLProps<React.HTMLAttributes<MathfieldElement>, MathfieldElement> &
  MathfieldElementAttributes & {
    placeholder?: string;
    disabled?: boolean;
    className?: string;
    class?: string;
  };

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'math-field': MathFieldProps;
    }
  }
}

/* 
  passing className as a prop won't spread it down to the underlying textbox element, but inline styles will work.
*/
const MathField = forwardRef<MathfieldElement, MathFieldProps>(
  ({ children, disabled = false, className, ...props }: MathFieldProps, ref: React.Ref<MathfieldElement>) => {
    return (
      <math-field
        aria-label="mathfield"
        read-only={disabled ? disabled : undefined}
        smart-mode="true"
        ref={ref}
        class={className}
        {...props}
      >
        {children}
      </math-field>
    );
  }
);

export default MathField;
