import { generatePath } from "react-router";
import { ELEMENT_TYPES, POLICY, POLICY_TYPE } from "../../types/hubEnums";
import { PATH_HUB_POLICY } from '../../util/pagePath';
import {
    EXTERNAL_PATH_CLUEY_LEARNING,
    EXTERNAL_PATH_COUNTRY_NZ,
    EXTERNAL_PATH_COUNTRY_AU,
    EXTERNAL_PATH_SERVICE_FEES,
    EXTERNAL_PATH_REFUND_POLICY
} from '../../util/externalPaths';
interface Choice {
    text: string;
    to: string;
    elementType?: ELEMENT_TYPES;
}

export function useGetPolicies(country?: string): Array<Choice> {
    return [
        { text: `${POLICY.PRIVACY} policy`, to: generatePath(PATH_HUB_POLICY, { policyType: POLICY_TYPE.PRIVACY }) },
        {
            text: `${POLICY.REFUND} policy`, to: `${EXTERNAL_PATH_CLUEY_LEARNING}${country?.toLowerCase() === "nz" ? EXTERNAL_PATH_COUNTRY_NZ : EXTERNAL_PATH_COUNTRY_AU}${EXTERNAL_PATH_REFUND_POLICY}`,
            elementType: ELEMENT_TYPES.ANCHOR
        },
        { text: POLICY.STUDENT_AGREEMENT, to: generatePath(PATH_HUB_POLICY, { policyType: POLICY_TYPE.STUDENT_AGREEMENT }) },
        { text: POLICY.DATA_COLLECTION, to: generatePath(PATH_HUB_POLICY, { policyType: POLICY_TYPE.DATA_COLLECTION }) },
        {
            text: POLICY.SERVICE_FEES,
            to: `${EXTERNAL_PATH_CLUEY_LEARNING}${country?.toLowerCase() === "nz" ? EXTERNAL_PATH_COUNTRY_NZ : EXTERNAL_PATH_COUNTRY_AU}${EXTERNAL_PATH_SERVICE_FEES}`,
            elementType: ELEMENT_TYPES.ANCHOR
        },
    ];
}