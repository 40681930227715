import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Link } from '@cluey/cluey-components';

import { isKeepPreviousTutorPageLoading } from '../../../selectors/loaderSelector';
import { isKeepPreviousTutorPageLoadingFailed } from '../../../selectors/errorSelector';
import {
  initPreviousTutorAvailabilityPage,
  redirectToEnrolmentManagement,
  redirectToFormWithEnrolmentId,
  saveSelectedSchedule,
} from '../../../actions/hub/hubEnrolmentManagementActions';
import {
  isGroupEnrolment,
  isEnrolmentFinished,
  isEnrolmentCancelled,
  getActiveEnrolments,
} from '../../../selectors/hubSelector';
import { useGetAvailableSlotsForPackage } from '../../../hooks/queries/useGetAvailableSlotsForPackage';
import InPageLoader from '../../../common/InPageLoader';
import PageError from '../../../common/PageError';
import EnrolmentLayout from '../../../common/hub/EnrolmentLayout';
import ErrorBlock from '../../../common/ErrorBlock';
import { ButtonAsLink } from '../../../common/ButtonAsLink';
import { EnrolmentBrief } from '../../../components/EnrolmentManagement/EnrolmentBrief';
import { TutorAvailability } from '../../../components/EnrolmentManagement/TutorAvailability';
import { EnrolmentManagementConfirmed } from '../../../components/EnrolmentManagement/EnrolmentManagementConfirmed';
import { ConnectedChangeScheduleConfirmation } from '../../../components/EnrolmentManagement/ChangeScheduleConfirmation';

import { ENROLMENT_MANAGEMENT_STEPS, SCHEDULE_DATE_FORMAT } from '../../../util/constants';
import {
  PATH_HUB_ENROLMENT_DETAIL,
  PATH_HUB_ENROLMENT_CHANGE_SCHEDULE,
  PATH_HUB_CR_CHANGE_TUTOR,
} from '../../../util/pagePath';
import { enrolmentScheduleDefault, enrolmentScheduleType } from '../../../types/hubPropTypes';
import { TRACKING_KPT } from '../../../util/trackingClasses';
import { getNextSessionByEnrolmentId } from '../../../selectors/priceChangeSelector';
import { SessionType } from '../../../types/hubPropTypes';

export const Exception = ({
  text,
  customLink,
  customLinkText,
  customLinkTrackingClass,
  buttonLinkText,
  buttonLinkHandler,
  buttonLinkTrackingClass,
}) => {
  return (
    <div className="container mx-auto">
      <p className="mb-4 text-lg leading-snug tracking-tight md:mb-6">{text}</p>
      <Link to={customLink} size="lg" className="mb-2 inline-block" tracking={customLinkTrackingClass}>
        {customLinkText}
      </Link>
      <ButtonAsLink text={buttonLinkText} onClickHandler={buttonLinkHandler} extraClasses={buttonLinkTrackingClass} />
    </div>
  );
};

Exception.propTypes = {
  text: PropTypes.string.isRequired,
  customLink: PropTypes.string.isRequired,
  customLinkText: PropTypes.string.isRequired,
  customLinkTrackingClass: PropTypes.string,
  buttonLinkText: PropTypes.string.isRequired,
  buttonLinkHandler: PropTypes.func.isRequired,
  buttonLinkTrackingClass: PropTypes.string,
};

Exception.defaultProps = {
  customLinkTrackingClass: null,
  buttonLinkTrackingClass: null,
};

const KeepPreviousTutorPage = ({
  isPageLoading,
  isPageLoadingFailed,
  studentName,
  enrolmentSubject,
  currentTutorName,
  prevTutor,
  sessionWithNewTutor,
  timezone,
  enrolmentSchedule,
  loadData,
  redirectToEMP,
  redirectToForm,
  saveSchedule,
  hasPermanentTutorChange,
  doRedirect,
  nextSession,
}) => {
  const [showErrorScreen, setShowErrorScreen] = useState(false);
  const [activeComponent, setActiveComponent] = useState(ENROLMENT_MANAGEMENT_STEPS.DEFAULT);
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const enrolmentId = query.get('token');
  const activeEnrolments = useSelector(getActiveEnrolments);
  const enrolment = activeEnrolments.find((activeEnrolment) => activeEnrolment.enrolmentId === enrolmentId);
  const {
    isError: isTutorAvailabilityLoadingFailed,
    isLoading: isTutorAvailabilityLoading,
    data,
  } = useGetAvailableSlotsForPackage(
    enrolment?.packageSfid,
    prevTutor?.tutorSfId,
    enrolment?.startTime,
    enrolment?.sessionFrequency
  );
  const prevTutorAvailability =
    data?.reduce((result, availability) => {
      const tutor = availability.tutorsAvailable?.find((tutor) => tutor.tutorId === prevTutor?.tutorSfId);
      if (tutor) {
        const { matchingRank = '', tutorId = '', tutorNumber = '', tutorName = '' } = tutor ?? {};
        const [firstname] = tutorName?.split(' ');
        const start = moment.utc(availability.start);
        const end = moment.utc(availability.end);
        result.push({
          firstname,
          scheduleDate: moment.utc(availability.start).format(SCHEDULE_DATE_FORMAT).toUpperCase(),
          sessionDuration: end.diff(start, 'minutes'),
          tutorId,
          tutorNumber,
          tutorOrder: String(matchingRank),
          startDate: start,
          endDate: end,
        });
      }
      return result;
    }, []) || [];
  const numberOfDaysToLoad = 13;

  useEffect(() => {
    if (isEmpty(enrolmentId)) {
      setShowErrorScreen(true);
      return undefined;
    }
    loadData({ enrolmentId });
    return undefined;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (doRedirect) {
      redirectToEMP({
        enrolmentId,
        callback: () => {
          history.push(`${PATH_HUB_ENROLMENT_DETAIL}?token=${enrolmentId}`);
        },
      });
    }
  }, [doRedirect, redirectToEMP, enrolmentId, history]);

  if (isPageLoading) {
    return <InPageLoader />;
  }

  if (isPageLoadingFailed || showErrorScreen) {
    return <PageError activePage="hub-enrolment-detail" />;
  }
  let mainHeading = null;
  const showDefault = activeComponent === ENROLMENT_MANAGEMENT_STEPS.DEFAULT;
  const showConfirmation = activeComponent === ENROLMENT_MANAGEMENT_STEPS.CONFIRMATION;
  const showConfirmed = activeComponent === ENROLMENT_MANAGEMENT_STEPS.CONFIRMED;

  let showEnrolmentBrief = true;
  let display = null;
  const { tutorName: prevTutorName, tutorNumber: prevTutorNumber, tutorPhoto: prevTutorPhoto } = prevTutor;
  switch (true) {
    case showConfirmation:
      mainHeading = 'Change schedule';
      break;
    case showConfirmed:
      mainHeading = 'Change confirmed';
      break;
    case !hasPermanentTutorChange:
      mainHeading = 'Change schedule to meet previous tutor’s availability';
      break;
    default:
      mainHeading = `Change schedule to meet ${prevTutorName}'s availability`;
  }

  const onChangeScheduleClick = () => {
    redirectToEMP({
      enrolmentId,
      callback: () => {
        history.push(PATH_HUB_ENROLMENT_CHANGE_SCHEDULE);
      },
    });
  };

  const onChangeTutorClick = () => {
    redirectToForm({
      enrolmentId,
      callback: () => {
        history.push(PATH_HUB_CR_CHANGE_TUTOR);
      },
    });
  };

  // NOTE: selected enrolment has not had a system-led tutor change]
  if (!hasPermanentTutorChange) {
    showEnrolmentBrief = false;
    display = (
      <Exception
        text="Unfortunately we cannot display your previous tutor’s availability."
        customLink={`${PATH_HUB_ENROLMENT_DETAIL}?token=${enrolmentId}`}
        customLinkText="View your enrolment details"
        buttonLinkText="Change your ongoing schedule"
        buttonLinkHandler={onChangeScheduleClick}
      />
    );
  }

  const { previousSessionWithNewTutor, nextSessionWithNewTutor } = sessionWithNewTutor;
  const tutorAvailabilityExpired =
    previousSessionWithNewTutor || moment(nextSessionWithNewTutor).isSameOrBefore(moment().add(2, 'day'));
  // NOTE: selected enrolment has had a system-led tutor change, but < 2 days from tutor changeover date
  if (!display && tutorAvailabilityExpired) {
    display = (
      <Exception
        text={`Unfortunately we can no longer show you ${prevTutorName}’s availability.`}
        customLink={`${PATH_HUB_ENROLMENT_DETAIL}?token=${enrolmentId}`}
        customLinkText="View your enrolment details"
        buttonLinkText="Change your ongoing schedule"
        buttonLinkHandler={onChangeScheduleClick}
      />
    );
  }

  // NOTE: If no availability from the (tutor changeover date + 6 days) timeframe for the previous package tutor
  if (!display && Array.isArray(prevTutorAvailability) && isEmpty(prevTutorAvailability)) {
    display = (
      <Exception
        text={`Unfortunately ${prevTutorName} has no session availability.`}
        customLink={`${PATH_HUB_ENROLMENT_DETAIL}?token=${enrolmentId}`}
        customLinkText={`Keep existing schedule with ${currentTutorName}`}
        customLinkTrackingClass={TRACKING_KPT.KEEP_NEW_TUTOR}
        buttonLinkText="Keep existing schedule with a different tutor"
        buttonLinkHandler={onChangeTutorClick}
        buttonLinkTrackingClass={TRACKING_KPT.CHANGE_TUTOR}
      />
    );
  }

  // NOTE: If any availability in the (tutor changeover date + 6 days) timeframe
  if (!display && Array.isArray(prevTutorAvailability) && !isEmpty(prevTutorAvailability)) {
    const onAvailabilityItemClick = ({ selectedDay, scheduleDate }) => {
      saveSchedule({
        selectedDay,
        scheduleDate,
        tutorNumber: prevTutorNumber,
      }).then(() => {
        setActiveComponent(ENROLMENT_MANAGEMENT_STEPS.CONFIRMATION);
      });
    };

    display = (
      <TutorAvailability
        numberOfDaysToShow={numberOfDaysToLoad}
        startDay={nextSessionWithNewTutor}
        availability={prevTutorAvailability}
        nextSessionId={nextSession.studentSessionId}
        timezone={timezone}
        enrolmentId={enrolmentId}
        prevTutorName={prevTutorName}
        currentTutorName={currentTutorName}
        onChangeTutorClick={onChangeTutorClick}
        onAvailabilityItemClick={onAvailabilityItemClick}
      />
    );
  }

  if (showConfirmation) {
    showEnrolmentBrief = false;

    const onConfirmationChangeClick = () => {
      setActiveComponent(ENROLMENT_MANAGEMENT_STEPS.DEFAULT);
    };

    const onConfirmNewScheduleClick = () => {
      setActiveComponent(ENROLMENT_MANAGEMENT_STEPS.CONFIRMED);
    };

    display = (
      <ConnectedChangeScheduleConfirmation
        onChangeClick={onConfirmationChangeClick}
        onConfirmClick={onConfirmNewScheduleClick}
      />
    );
  }

  if (showConfirmed) {
    return (
      <EnrolmentManagementConfirmed
        mainHeading={mainHeading}
        student={studentName}
        subject={enrolmentSubject}
        enrolmentId={enrolmentId}
        message="We have updated your permanent session schedule,
        you will see it reflected on your enrolment details soon and
        you will also receive an email confirming the details soon."
        tracking={TRACKING_KPT.PAGE}
      />
    );
  }

  const showMainContent = !isTutorAvailabilityLoading && !isTutorAvailabilityLoadingFailed;

  return (
    <EnrolmentLayout
      sectionHeading={`${studentName} ${enrolmentSubject}`}
      mainHeading={mainHeading}
      enrolmentId={enrolmentId}
      tracking={TRACKING_KPT.PAGE}
    >
      {showDefault && (
        <Fragment>
          {isTutorAvailabilityLoading && <InPageLoader position="relative" height="auto" />}
          {isTutorAvailabilityLoadingFailed && (
            <div className="container mx-auto">
              <ErrorBlock errorMsg={`Unable to load ${prevTutorName}'s availability. Please try again later`} />
            </div>
          )}
          {showMainContent && showEnrolmentBrief && (
            <div className="container mx-auto">
              <EnrolmentBrief
                tutorName={prevTutorName}
                tutorPhoto={prevTutorPhoto}
                enrolmentSchedule={enrolmentSchedule}
                shownPreviousTutor
              />
            </div>
          )}
        </Fragment>
      )}

      {showMainContent && display}
    </EnrolmentLayout>
  );
};

KeepPreviousTutorPage.propTypes = {
  isPageLoading: PropTypes.bool.isRequired,
  isPageLoadingFailed: PropTypes.bool.isRequired,
  studentName: PropTypes.string,
  enrolmentSubject: PropTypes.string,
  currentTutorName: PropTypes.string,
  prevTutor: PropTypes.shape({
    tutorName: PropTypes.string,
    tutorNumber: PropTypes.string,
    tutorPhoto: PropTypes.string,
    tutorAvailability: PropTypes.arrayOf(PropTypes.string),
    tutorSfId: PropTypes.string,
  }),
  sessionWithNewTutor: PropTypes.shape({
    nextSessionWithNewTutor: PropTypes.string,
    previousSessionWithNewTutor: PropTypes.string,
  }),
  timezone: PropTypes.string,
  enrolmentSchedule: enrolmentScheduleType,
  loadData: PropTypes.func.isRequired,
  redirectToEMP: PropTypes.func.isRequired,
  redirectToForm: PropTypes.func.isRequired,
  saveSchedule: PropTypes.func.isRequired,
  hasPermanentTutorChange: PropTypes.bool,
  doRedirect: PropTypes.bool,
  nextSession: SessionType.isRequired,
};

KeepPreviousTutorPage.defaultProps = {
  studentName: null,
  enrolmentSubject: null,
  currentTutorName: null,
  prevTutor: {},
  sessionWithNewTutor: {},
  timezone: null,
  enrolmentSchedule: enrolmentScheduleDefault,
  hasPermanentTutorChange: false,
  doRedirect: false,
};

export { KeepPreviousTutorPage as PresentationalKeepPreviousTutorPage };
export const ConnectedKeepPreviousTutorPageConnected = connect(
  (state) => {
    const {
      studentName,
      enrolmentId,
      enrolmentSubject,
      alternativeTutor: prevTutor,
      sessionWithNewTutor,
      tutorName,
      enrolmentSchedule,
      automatedTutorChange: hasPermanentTutorChange,
    } = state.hubEnrolmentDetailPage;

    return {
      isPageLoading: isKeepPreviousTutorPageLoading(state),
      isPageLoadingFailed: isKeepPreviousTutorPageLoadingFailed(state),
      studentName,
      enrolmentSubject,
      nextSession: getNextSessionByEnrolmentId(state, enrolmentId),
      currentTutorName: tutorName,
      prevTutor,
      sessionWithNewTutor,
      timezone: state.hubUser.timezone,
      enrolmentSchedule,
      hasPermanentTutorChange,
      doRedirect: isGroupEnrolment(state) || isEnrolmentCancelled(state) || isEnrolmentFinished(state),
    };
  },
  (dispatch) => {
    return {
      loadData: bindActionCreators(initPreviousTutorAvailabilityPage, dispatch),
      redirectToEMP: bindActionCreators(redirectToEnrolmentManagement, dispatch),
      redirectToForm: bindActionCreators(redirectToFormWithEnrolmentId, dispatch),
      saveSchedule: bindActionCreators(saveSelectedSchedule, dispatch),
    };
  }
)(KeepPreviousTutorPage);
