import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';

import ErrorBlock from '../../../common/ErrorBlock';
import ContactFormLayout from '../../../common/hub/ContactFormLayout';
import FormSubHeading from '../../../components/hub/contact/FormSubHeading';
import { useDatePickerProps, DatePicker, displayDate } from '../../../components/DatePicker/DatePicker';
import { useDropdownProps, Dropdown } from '../../../components/Dropdown/Dropdown';
import { useRadioGroupProps, RadioGroup } from '../../../components/RadioGroup/RadioGroup';
import { useTextAreaProps, TextArea } from '../../../components/TextArea/TextArea';
import { RestrictedButton } from '../../../components/RestrictedButton/RestrictedButton';

import { getSelectedSession } from '../../../selectors/hubSelector';
import { submitCaseToSF } from '../../../actions/hub/hubContactPageActions';
import { PATH_HUB_UPCOMING_SESSIONS, PATH_HUB_CR_SUBMIT_CASE_SUCCESS } from '../../../util/pagePath';
import { getSessionAvailableTimeOptions } from '../../../util/helpers';

const groupName = 'contact-form-reschedule-session';
const radios = [
  {
    text: 'Student unavailable',
    value: 'Student unavailable',
    key: `${groupName}-category-1`,
  },
  {
    text: 'Holidays',
    value: 'Holidays',
    key: `${groupName}-category-2`,
  },
  {
    text: 'Other',
    value: 'Other',
    key: `${groupName}-category-4`,
  },
];

const FormRescheduleSession = ({ selectedSession, isSubmitting, submitError, errorMsg, submit }) => {
  const history = useHistory();
  console.log(selectedSession);

  const datePickerProps = useDatePickerProps({
    labelText: 'Select a new date and time',
    placeholder: 'Please select a date',
    options: {
      minDate: new Date().fp_incr(1),
    },
  });
  const selectedDate = typeof datePickerProps.value === 'object' ? displayDate(datePickerProps.value[0]) : '';

  const selectTimeProps = useDropdownProps({
    id: 'select-time',
    options: getSessionAvailableTimeOptions(),
    labelText: '',
    placeholder: 'Select a time',
  });
  const selectedTime = selectTimeProps.selectedOption;

  const radioGroupProps = useRadioGroupProps({
    labelText: 'Select a reason for rescheduling this session',
    groupName,
    radios,
  });
  const rescheduleReason = radioGroupProps.selectedRadio;

  const textAreaProps = useTextAreaProps({
    id: 'text-tell-us-something',
    labelText: 'Is there anything else you\u2019d like to tell us?',
  });
  const message = textAreaProps.value;

  if (selectedSession === null || selectedSession === undefined) {
    history.push(PATH_HUB_UPCOMING_SESSIONS);
    return null;
  }

  const { uniqueEnrolmentId, studentSessionId } = selectedSession;

  const onClickHandler = () => {
    const caseType = 'Scheduling';
    const caseCategory = 'Temporary schedule change';
    const caseSubCategory = rescheduleReason;
    const caseDescription = `Request for ${caseCategory} to ${selectedDate} ${selectedTime} due to ${rescheduleReason}. Other details: ${message}`;

    submit({
      uniqueEnrolmentId,
      message: caseDescription,
      caseSubject: `Contact request - ${caseType} - ${caseCategory} - ${caseSubCategory}`,
      caseType,
      caseCategory,
      caseSubCategory,
      studentSession: studentSessionId,
      onSubmitSuccess: () => {
        history.push(PATH_HUB_CR_SUBMIT_CASE_SUCCESS);
      },
    });
  };

  return (
    <ContactFormLayout selectedSession={selectedSession}>
      <div className="px-5 pt-5 lg:px-20 lg:pt-12">
        <FormSubHeading />
        {submitError && errorMsg && <ErrorBlock errorMsg={errorMsg} />}
        <form>
          <div className="mb-6 items-end lg:flex">
            <div className="mb-6 w-full p-0 lg:mb-0 lg:w-1/2 lg:pr-3">
              <DatePicker {...datePickerProps} />
            </div>
            <div className="w-full p-0 lg:w-1/2 lg:pl-3">
              <Dropdown {...selectTimeProps} />
            </div>
          </div>
          <div className="mb-6">
            <RadioGroup {...radioGroupProps} />
          </div>

          <TextArea {...textAreaProps} className="mb-6" />
        </form>
        <div className="mb-4 text-center tracking-normal lg:mb-8">
          <RestrictedButton
            buttonText="Request change"
            truthyChecks={[!!selectedDate, !!rescheduleReason, !!selectedTime, !isSubmitting]}
            onClick={() => {
              onClickHandler();
            }}
          />
        </div>
      </div>
    </ContactFormLayout>
  );
};

FormRescheduleSession.propTypes = {
  selectedSession: PropTypes.shape({
    uniqueEnrolmentId: PropTypes.string.isRequired,
    studentSessionId: PropTypes.string.isRequired,
  }).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitError: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    return {
      selectedSession: getSelectedSession(state),
      isSubmitting: state.ui.apiState.hubContactPage.isLoading,
      submitError: state.ui.apiState.hubContactPage.fetchError,
      errorMsg: state.ui.apiState.hubContactPage.errorMsg,
    };
  },
  (dispatch) => {
    return {
      submit: bindActionCreators(submitCaseToSF, dispatch),
    };
  }
)(FormRescheduleSession);

export { FormRescheduleSession as StatelessFormRescheduleSession };
