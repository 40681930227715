import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { PRICING_PLAN_ROWS, useHasActiveGroupEnrolments } from '../../helpers';
import { useCollapsible } from '../../hooks';
import useChangePricingPlanContext from '../useChangePricingPlanContext';
import { ComparisonPolicyRow } from './ComparisonPolicyRow';

export type HeaderCardProps = {
  opened: boolean;
  plan: any;
  className?: string;
  showHeaders?: boolean;
};

const HeaderCard = ({ opened, plan, className, showHeaders }: HeaderCardProps) => {
  const { maxHeights } = useChangePricingPlanContext();
  const hasGroupEnrolments = useHasActiveGroupEnrolments();
  const ref = useRef(null);
  const height = useCollapsible({ collapsed: !opened, ref, maxHeights });
  const [infoBlockHeight, setInfoBlockHeight] = useState('auto');

  useEffect(() => {
    setInfoBlockHeight(`${maxHeights?.infoBlock}px`);
  }, [maxHeights]);
  return (
    <div
      ref={ref}
      className={classnames(
        'mt-3.5 hidden w-auto shrink-0 overflow-hidden rounded-lg transition-height duration-300 ease-in-out md:block',
        className
      )}
      style={{ height }}
    >
      {showHeaders && <div className='h-8'></div>}
      {/* INFO BLOCK */}
      <div style={{ height: infoBlockHeight }}></div>
      {/* POLICY BLOCK */}
      <div className="flex w-full flex-col items-end justify-end">
        <ComparisonPolicyRow key="header-planPolicy" className="!h-11 !px-0">
          <div className="text-right text-2xs font-bold tracking-[1px]">PLAN POLICY</div>
        </ComparisonPolicyRow>
        {PRICING_PLAN_ROWS.map((row) => {
          return (
            <ComparisonPolicyRow row={row} key={'header-' + plan.pricingPlanName + '-' + row.header} className="!px-0">
              <div className="text-right text-sm font-bold">{row.getHeader(plan, hasGroupEnrolments)}</div>
            </ComparisonPolicyRow>
          );
        })}
      </div>
    </div>
  );
};

export default HeaderCard;
