import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

export const CustomLink = ({ to, text, customClasses }) => {
	return (
		<Link to={to} className={classNames('d-inline-block mb-4 text-lg', { [customClasses]: customClasses !== null })}>
			{text}
		</Link>
	);
};

CustomLink.propTypes = {
	to: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	customClasses: PropTypes.string,
};

CustomLink.defaultProps = {
	customClasses: null,
};
