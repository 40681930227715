import { Button } from '@cluey/cluey-components';
import { Student } from '../../../../../../api/types/student';
import { GenerateAssessmentHandler } from '../types';

export interface EligibleAssessmentCardsProps {
	student: Student;
	studentAssessmentEligibilities: string[];
	handleGenerateAssessment: GenerateAssessmentHandler;
}

const EligibleAssessmentCards = ({
	student,
	studentAssessmentEligibilities,
	handleGenerateAssessment,
}: EligibleAssessmentCardsProps) => {
	return (
		<>
			{studentAssessmentEligibilities.map((assessmentSubject) => {
				return (
					<div
						key={`${student?.firstName}_${student?.yearLevel}_${assessmentSubject}_assessment`}
						className="flex w-full flex-col md:items-center space-y-4 rounded-lg border border-grey-2 bg-white p-4 shadow md:flex-row md:space-y-0"
					>
						<div className="flex-1">
							<span>Take a free assessment in </span>
							<span className="font-bold">{`${student?.yearLevel} ${assessmentSubject}`}</span>
							<span> to get scored</span>
						</div>
						<div>
							<Button
								type="button"
								size="sm"
								className="uppercase text-xs !font-bold !tracking-[1px] !border"
								appearance="reverse"
								onClick={() => {
									handleGenerateAssessment(student, assessmentSubject);
								}}
							>
								<span>Generate assessment</span>
							</Button>
						</div>
					</div>
				);
			})}
		</>
	);
};

export default EligibleAssessmentCards;
