import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation, useHistory } from 'react-router-dom';
import { get } from 'lodash';
import Cookies from 'js-cookie';

import InPageLoader from '../../../common/InPageLoader';
import PageError from '../../../common/PageError';
import AuthPageLayout from '../../../common/hub/AuthPageLayout';
import ErrorBlock from '../../../common/ErrorBlock';
import { getCredentials } from '../../../actions/hub/hubAuthPagesActions';
import { PATH_HUB_HOME } from '../../../util/pagePath';
import { setStaffToken } from '../../../api/auth';

const jwtDecode = require('jwt-decode');

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const isQueryParamValid = (value) => {
  return value !== '' && value !== null;
};

export const calculateClockDrift = (iatAccessToken, iatIdToken) => {
  const now = Math.floor(new Date() / 1000);
  const iat = Math.min(iatAccessToken, iatIdToken);
  return now - iat;
};

const HubStaffLoginPage = ({
  errorMsg,
  isClockSkewDetected,
  checkUserStatus,
}) => {
  const history = useHistory();
  const [showPageError, setShowPageError] = useState(false);

  const query = useQuery();
  useEffect(() => {
    const userName = query.get('UserName');
    const staffToken = query.get('staffToken');
    const accessToken = query.get('AccessToken');
    const idToken = query.get('IdToken');
    const refreshToken = query.get('RefreshToken');

    if (isQueryParamValid(userName)
    && isQueryParamValid(staffToken)
    && isQueryParamValid(accessToken)
    && isQueryParamValid(idToken)
    && isQueryParamValid(refreshToken)) {
      // set locallocalStorage
      const idTokenData = jwtDecode(idToken);
      const username = get(idTokenData, 'cognito:username');
      const iatIdToken = get(idTokenData, 'iat');

      const accessTokenData = jwtDecode(accessToken);
      const clientId = get(accessTokenData, 'client_id');
      const iatAccessToken = get(accessTokenData, 'iat');

      if (isQueryParamValid(clientId)) {
        localStorage.setItem(`CognitoIdentityServiceProvider.${clientId}.LastAuthUser`, username);
        localStorage.setItem(`CognitoIdentityServiceProvider.${clientId}.${username}.idToken`, idToken);
        localStorage.setItem(`CognitoIdentityServiceProvider.${clientId}.${username}.accessToken`, accessToken);
        localStorage.setItem(`CognitoIdentityServiceProvider.${clientId}.${username}.refreshToken`, refreshToken);
        localStorage.setItem(`CognitoIdentityServiceProvider.${clientId}.${username}.clockDrift`, `${calculateClockDrift(iatAccessToken, iatIdToken)}`);

        checkUserStatus({
          staffToken,
          onSuccess: () => {
            Cookies.set('staffToken', staffToken);
            setStaffToken(staffToken)
            history.push(PATH_HUB_HOME);
          },
        });
      } else {
        setShowPageError(true);
      }
    } else {
      setShowPageError(true);
    }
  }, [query, setShowPageError, checkUserStatus, history]);

  if (isClockSkewDetected) {
    return <PageError activePage="redirect-clock-skew-handle" />;
  }

  return (
    <Fragment>
      { !showPageError && errorMsg === '' && <InPageLoader /> }
      { showPageError && <PageError activePage="hub-staff-login-page" /> }
      { !showPageError && (
        <AuthPageLayout
          title="Log into the Cluey Hub as a Cluey staff"
          subtitle="View users' sessions, enrolments and account settings."
        >
          <ErrorBlock errorMsg={errorMsg} />
        </AuthPageLayout>
      )}
    </Fragment>
  );
};

HubStaffLoginPage.propTypes = {
  errorMsg: PropTypes.string,
  isClockSkewDetected: PropTypes.bool.isRequired,
  checkUserStatus: PropTypes.func.isRequired,
};

HubStaffLoginPage.defaultProps = {
  errorMsg: '',
};

export default connect(
  (state) => {
    return {
      errorMsg: state.ui.apiState.hubAuthPage.errorMsg,
      isClockSkewDetected: state.login.isClockSkewDetected,
    };
  },
  (dispatch) => {
    return {
      checkUserStatus: bindActionCreators(getCredentials, dispatch),
    };
  },
)(HubStaffLoginPage);
