import HubHeader from '../../../../common/hub/HubHeader';
import { FormFlow } from '../../../../components/FormFlow/FormFlow';
import { AddStudentInformation } from './AddStudentInformation';
import { AddStudentSpecialNeeds } from './AddStudentSpecialNeeds/AddStudentSpecialNeeds';
import { useForm, FormProvider } from 'react-hook-form';
import { StudentProfileData } from '../StudentProfile/StudentProfile';
import { AddStudentSuccess } from './AddStudentSuccess';
import { zodResolver } from '@hookform/resolvers/zod';
import { api } from '../../../../api';
import { Container } from '@cluey/cluey-components';
import { ShadowContainer } from '../../../../components/ShadowContainer';
import { StudentInformationLoader } from '../../../../components/hub/student/StudentInformationForm/StudentInformationLoader';
import { SkeletonLoader } from '../../../../components/SkeletonLoader';
import { useEffect } from 'react';
import HubFooter from '../../../../common/hub/HubFooter/HubFooter';
import withHeadTag from '../../../../common/HeadComponent';

const AddStudent = () => {
	const { data: accountDetails, isLoading: loadingAccountDetails } = api.account.details.useQuery();
	const kiwi = accountDetails?.country === 'NZ';

	const formElements = {
		'': AddStudentInformation,
		'special-needs': AddStudentSpecialNeeds,
		success: AddStudentSuccess,
	};

	const formUtils = useForm<StudentProfileData>({
		resolver: zodResolver(StudentProfileData),
	});

	const { reset } = formUtils;
	useEffect(() => {
		reset({
			location: kiwi ? 'NZ' : undefined,
		});
	}, [reset, kiwi]);

	if (loadingAccountDetails) {
		return (
			<Container className="px-4 py-7 md:py-8 lg:mb-0">
				<SkeletonLoader className="h-[18px] w-20" loading={loadingAccountDetails} />
				<SkeletonLoader className="mt-4 h-6 w-32" loading={loadingAccountDetails} />
				<ShadowContainer className="mt-10 p-6 md:p-0">
					<SkeletonLoader className="h-6 w-full md:w-72" loading={loadingAccountDetails} />
					<StudentInformationLoader />
				</ShadowContainer>
			</Container>
		);
	}

	return (
		<FormProvider {...formUtils}>
			<div className="flex h-full flex-col justify-between">
				<div>
					<HubHeader title="Hub" />
					<FormFlow elements={formElements} />
				</div>
				<HubFooter />
			</div>
		</FormProvider>
	);
};

const AddStudentWithHeadTag = withHeadTag(AddStudent);

export { AddStudentWithHeadTag as AddStudent };
