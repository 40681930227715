import { Auth } from 'aws-amplify';
import { get } from 'lodash';
import { AWS_COGNITO_RESPONSE, TEMP_PASSWORD_EXPIRED_ERROR } from '../util/constants';
import posthog from 'posthog-js';
import getAppVersion from '../util/getAppVersion';

export const logIn = async ({ email, password, completeSignup = false }) => {
	try {
		const response = await Auth.signIn(email, password);
		const ChallengeName = get(response, 'challengeName');
		if (ChallengeName === 'NEW_PASSWORD_REQUIRED') {
			return {
				status: AWS_COGNITO_RESPONSE.RESET_PASSWORD_REQUIRED,
			};
		}

		return response;
	} catch (e) {
		const code = get(e, 'code');
		if (code === 'NotAuthorizedException' && completeSignup) {
			const message = get(e, 'message');
			// Note, we have to reply on checking error message since the response code is not specific enough
			if (message === TEMP_PASSWORD_EXPIRED_ERROR) {
				throw new Error(message);
			}

			return {
				status: AWS_COGNITO_RESPONSE.NOT_AUTHORIZED_EXCEPTION,
			};
		}
		throw e;
	}
};

export const completeSignupByChangingPwd = async ({ email, tempPassword, newPassword }) => {
	const user = await Auth.signIn(email, tempPassword);
	const response = await Auth.completeNewPassword(user, newPassword);
	return response;
};

export const getCurrentCredentials = async () => {
  const user = await Auth.currentAuthenticatedUser();
	const tokenOrigin = localStorage.getItem('aws-cognito-origin');
  const timeDiff = Math.abs(
		tokenOrigin === 'next-hub' ? user.signInUserSession.clockDrift : user.signInUserSession.clockDrift * 1000
	);

  const clockSkew = timeDiff > 240000; // 4 mins

	if (clockSkew) {
		throw new Error('Clock skew detected');
	}

	const res = await Auth.currentUserCredentials();
	const authenticated = get(res, 'authenticated', false);

	if (!authenticated) {
		const exceptionMsg = get(res, 'message', '');
		throw new Error(exceptionMsg);
	}
	return res;
};

export const getCurrentUserInfo = async () => {
	const user = await Auth.currentUserInfo();
	console.log('User', user);
	const customerId = get(user, 'attributes.custom:customerId', null);
	if (customerId) {
		posthog.identify(customerId, { $customer_id: customerId, $chub_version: getAppVersion() });
	}
	return user;
};

export const signOut = async () => {
	const res = await Auth.signOut();
	posthog.reset();
	return res;
};

export const forgotPassword = async (username) => {
	const res = await Auth.forgotPassword(username);
	return res;
};

export const forgotPasswordSubmit = async ({ username, code, password }) => {
	const res = await Auth.forgotPasswordSubmit(username, code, password);
	return res;
};
