import env from '../env';

export default function getZuoraURLParams(country: string) {
	if (country === 'NZ') {
		return {
			pageId: env.REACT_APP_ZUORA_PAYMENT_PAGE_ID_NZ,
			url: env.REACT_APP_ZUORA_PAYMENT_PAGE_URL_NZ,
		};
	} else {
		return {
			pageId: env.REACT_APP_ZUORA_PAYMENT_PAGE_ID,
			url: env.REACT_APP_ZUORA_PAYMENT_PAGE_URL,
		};
	}
}
