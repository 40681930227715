export function getRetryEnabled(state) {
  let retryFlag = false;
  Object.keys(state.ui.apiState).forEach((key) => {
    const { retryEnabled, fetchError } = state.ui.apiState[key];
    if (fetchError) {
      retryFlag = retryEnabled;
    }
  });
  return retryFlag;
}

export function getFetchError(state) {
  const {
    summary, activities, login,
  } = state.ui.apiState;
  return (summary.fetchError || activities.fetchError || login.fetchError);
}

export const isKeepPreviousTutorPageLoadingFailed = (state) => {
  return state.ui.apiState.hubEnrolmentDetailPage.fetchError;
};

export const isPreviousTutorAvailabilityLoadingFailed = (state) => {
  const { previousTutor, tutorDetail, tutorAvailability } = state.ui.apiState.hubEnrolmentDetailPage;
  return previousTutor.fetchError || tutorDetail.fetchError || tutorAvailability.fetchError;
};

export const isPreviousTutorLoadingFailed = (state) => {
  const { previousTutor, tutorDetail } = state.ui.apiState.hubEnrolmentDetailPage;
  return previousTutor.fetchError || tutorDetail.fetchError;
};

export const isFormPauseSessionsLoadingFailed = (state) => {
  const { hubUpcomingSessionsPage, hubSchoolTermsPublicHolidays } = state.ui.apiState;
  return !hubUpcomingSessionsPage.hasFetched && !hubSchoolTermsPublicHolidays.hasFetched;
};

export const isManagePausePageLoadingOrSubmittingFailed = (state) => {
  const { managePauseGetNewResumeSession, managePauseNewResumeDateRequest } = state.ui.apiState.hubEnrolmentDetailPage;
  return managePauseGetNewResumeSession.fetchError || managePauseNewResumeDateRequest.fetchError;
};
