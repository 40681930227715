import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ButtonAsLink } from '@cluey/cluey-components';

export const CopyToClipboard = ({ text, label, buttonClasses }) => {
  const [clicked, setClicked] = useState(false);

  const timeoutRef = useRef(-1);

  const onClick = () => {
    const input = document.createElement('input');
    document.body.appendChild(input);
    input.value = text;
    input.select();
    document.execCommand('copy');
    input.remove();
    setClicked(true);
    timeoutRef.current = setTimeout(() => setClicked(false), 1000);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef);
    };
  }, []);

  const computedClasses = classnames(
    'inline-block',
    !clicked && 'hover:text-blue-6',
    buttonClasses
  );

  return (
    <ButtonAsLink onClick={onClick} className={computedClasses}>
      {clicked ? 'Copied to clipboard!' : label}
    </ButtonAsLink>
  );
};

CopyToClipboard.propTypes = {
  text: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  buttonClasses: PropTypes.string,
};

CopyToClipboard.defaultProps = {
  buttonClasses: '',
};
