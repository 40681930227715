import { Container, LinkButton, Link } from '@cluey/cluey-components';
import rescheduleBannerGraphic from '../../../assets/images/reschedule-banner-graphic-filled.svg';
import { PATH_HUB_UPCOMING_SESSIONS, PATH_HUB_HOME } from '../../../util/pagePath';
import HubHeader from '../../../common/hub/HubHeader';
import HubFooter from '../../../common/hub/HubFooter/HubFooter';
/* eslint-disable react/jsx-curly-brace-presence */
const RescheduleSessionInfo = () => {
	return (
		<div className="flex min-h-screen flex-col">
			<HubHeader title="tit" />
			<Container>
				<div className="mb-0 w-full pt-5 lg:pt-11">
					<Link size="xs" className="mb-3 font-bold uppercase tracking-widest lg:mb-2" to={PATH_HUB_HOME}>
						Home
					</Link>
					<div className="lg:w-2/3">
						<h1 className="cy-enrolment-title mb-2 mt-4 font-display text-3xl font-bold md:text-4xl">
							{`Reschedule with your tutor when they can’t make it to a session`}
						</h1>
					</div>
					<div className="mt-10 flex flex-col items-center md:flex-row">
						<div className="mb-6 flex-1 md:-ml-3 md:mb-0 md:mr-3">
							<img height="179" width="305" src={rescheduleBannerGraphic} alt="" />
						</div>
						<div className="mb-12 flex-1 space-y-6 font-bold md:px-6">
							<p>
								When your tutor can’t make one of your regular sessions and provides us more than 48 hours of notice, we
								will now offer you the ability reschedule that session with your tutor.
							</p>
							<p>{`You can choose a time from your tutor’s availability and change the session time so you won’t have to change your tutor for that session.`}</p>
						</div>
					</div>
				</div>
			</Container>
			<div className="-mb-9 bg-[#F2F5FA]">
				<Container className="pb-20 pt-12">
					<div className="flex flex-col md:flex-row">
						<div className="flex-1 space-y-4 md:pr-4 md:pt-12">
							<h3 className="mb-2 text-lg font-bold md:pt-8">{`We'll provide more options`}</h3>
							<p>{`From today, if your tutor is unable to attend a single session, you’ll receive a notification from us with a request
                to reschedule your session to another time that’s convenient for both you and your tutor.`}</p>
							<h3 className="mb-2 pt-8 text-lg font-bold">{`You'll have fewer tutor changes`}</h3>
							<p>We understand that tutor changes can be inconvenient and tough on your child.</p>
							<p>{`We’ve heard your feedback and we’ve been working on ways to reduce the number of tutor changes you might experience and the inconvenience and difficulties associated with these changes.`}</p>
							<div className="hidden pt-8 md:block">
								<LinkButton className="mt-4" to={PATH_HUB_UPCOMING_SESSIONS} size="lg">
									See upcoming sessions
								</LinkButton>
							</div>
						</div>
						<div className="mt-8 flex-1">
							<div className="rounded-lg bg-white p-8 pt-12">
								<h3 className="mb-2 text-lg font-bold">How will it work?</h3>
								<ul className="list-disc space-y-4 pl-4 pt-2">
									<li>{`When your tutor is unable to make a single session, they’ll be invited to add some extra timeslots for you to choose from in addition to their regular availability.`}</li>

									<li>{`You’ll be sent a notification advising that your tutor won’t be able to make the session, and asking you to have a look at some of your tutor’s available timeslots.`}</li>

									<li>{`You can choose any available time that suits you in the tutor’s calendar for that week to have your session.`}</li>

									<li>{`If you’re not able to reschedule, or there aren’t any suitable times with your tutor, or we don’t hear back from you by the day before your session, we’ll assign a temporary tutor so that your session goes ahead at your regular time.`}</li>

									<li>{`Please note, this will only apply when your tutor is unable to attend a single session and they have provided us with 48 hours' notice of the change. If your tutor is unable to attend multiple consecutive sessions, or we receive less than 48 hours of notice, we will assign a temporary tutor to any affected sessions. `}</li>
								</ul>
							</div>
						</div>
					</div>
				</Container>
			</div>
			<div className="block bg-blue-5 md:hidden">
				<LinkButton corners="none" className="w-full rounded-none" to={PATH_HUB_UPCOMING_SESSIONS} size="lg">
					See upcoming sessions
				</LinkButton>
			</div>
			<div className="hidden md:block">
				<HubFooter />
			</div>
		</div>
	);
};

export default RescheduleSessionInfo;
