import moment from 'moment';
import { ReactElement, createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { AccountDetails } from '../../../api/types/account';
import useGetNextPaymentEstimates from '../../../hooks/account/queries/useEstimateNextPaymentQuery/useEstimateNextPaymentQuery';
import { useChangePlanMutation } from '../../../hooks/mutations/useChangePlanMutation';
import useAccountDetailsQuery from '../../../hooks/queries/useAccountDetailsQuery';
import useAccountPricingPlanQuery from '../../../hooks/queries/useAccountPricingPlanQuery';
import useNextPaymentQuery from '../../../hooks/queries/useNextPaymentQuery';
import useRetrievePricingPlansQuery from '../../../hooks/queries/useRetrievePricingPlansQuery';
import { getNextUnpaidSession, getTimezone, getUpcomingSessions } from '../../../selectors/hubSelector';
import { PAYMENT_STATUS } from '../../../types/hubEnums';
import { EstimatedNextPaymentWithPricingPlanId, Session } from '../../../types/hubInterfaces';
import { BillingQuery, InvoicePer, PricePlan, UserPricingPlan } from '../../../types/hubTypes';
import { useHasSeveralActiveEnrolments } from '../helpers';
import { useInvoicePerMutation } from '../hooks';

export enum ChangePricingPlanSteps {
  CHOOSE_A_PLAN = 'CHOOSE_A_PLAN',
  CONFIRM_CHANGES = 'CONFIRM_CHANGES',
  CHANGES_SUCCESS = 'CHANGES_SUCCESS',
}

export type RowHeight = {
  [key: string]: number;
};

export type ChangePricingPlanContextProps = {
  currentStep: ChangePricingPlanSteps;
  setCurrentStep: (step: ChangePricingPlanSteps) => void;
  isError: boolean;
  loading: boolean;
  selectedPlan: PricePlan;
  setSelectedPlan: (selectedPlan: PricePlan) => void;
  maxHeights: RowHeight;
  setMaxHeights: (maxHeight: RowHeight) => void;
  invoicePer: InvoicePer;
  setInvoicePer: (invodePer: InvoicePer) => void;
  allowedPlansForTransition: PricePlan[];
  currentPricingPlan: UserPricingPlan;
  upcomingPricingPlan: UserPricingPlan;
  nextPayment: BillingQuery;
  accountDetails: AccountDetails;
  nextUnpaidSession: Session;
  timezone: string;
  hasSeveralActiveEnrolments: boolean;
  paidSessions: Session[];
  planPreviewPaymentDetails: EstimatedNextPaymentWithPricingPlanId[];
  isPlanPreviewPaymentDetailsLoading: boolean;
  submitChangePlan: () => void;
  cancelChangePlan: () => void;
  changePlanError: Error;
};

export const ChangePricingPlanContext = createContext<ChangePricingPlanContextProps | null>(undefined);

export const ChangePricingPlanContextProvider = ({ children }: { children: ReactElement }) => {
  const [currentStep, setCurrentStep] = useState(ChangePricingPlanSteps.CHOOSE_A_PLAN);
  const [selectedPlan, setSelectedPlan] = useState<PricePlan>(null);
  const [invoicePer, setInvoicePer] = useState<InvoicePer | null>(null);
  const [maxHeights, setMaxHeights] = useState<RowHeight>({});

  const { data: nextPayment } = useNextPaymentQuery();
  const { data: accountDetails } = useAccountDetailsQuery();
  const {
    data: allowedPricingPlans,
    isLoading: isLoadingAllowedPricingPlans,
    isError: isErrorAllowedPricingPlans,
  } = useRetrievePricingPlansQuery();
  const { allowedPlansForTransition } = allowedPricingPlans || { allowedPlansForTransition: [] };
  const {
    data: accountPricingPlan,
    isLoading: isLoadingAccountPricingPlan,
    isError: isErrorAccountPricingPlan,
  } = useAccountPricingPlanQuery();
  const { currentPricingPlan, upcomingPricingPlan } = accountPricingPlan || {
    currentPricingPlan: null,
    upcomingPricingPlan: null,
  };
  const { data: planPreviewPaymentDetails, isLoading: isPlanPreviewPaymentDetailsLoading } = useGetNextPaymentEstimates(
    {
      pricingPlans: allowedPlansForTransition,
    }
  );

  const { mutate: updateInvoicePer } = useInvoicePerMutation();
  const {
    mutate: planMutation,
    isLoading: isLoadingPlanMutation,
    error: changePlanError,
    isError: isErrorPlanMutation,
    reset: resetPlanMutation,
  } = useChangePlanMutation({
    onSuccess: () => {
      setSelectedPlan(null);
      setCurrentStep(ChangePricingPlanSteps.CHANGES_SUCCESS);
    },
  });
  const submitChangePlan = () => {
    const payload = {
      accountId: currentPricingPlan.account,
      pricingPlanId: selectedPlan.pricingPlanId,
      effectiveFrom: moment(nextUnpaidSession?.startTime).tz(timezone).format('YYYY-MM-DD'),
    };
    planMutation(payload);
    updateInvoicePer({ invoicePer });
  };
  const cancelChangePlan = () => {
    resetPlanMutation();
    setSelectedPlan(null);
    setCurrentStep(ChangePricingPlanSteps.CHOOSE_A_PLAN);
  };

  const nextUnpaidSession = useSelector(getNextUnpaidSession);
  const timezone = useSelector(getTimezone);
  const hasSeveralActiveEnrolments = useHasSeveralActiveEnrolments();
  const paidSessions = useSelector(getUpcomingSessions).filter(
    (sesh: Session) => sesh.paymentStatus === PAYMENT_STATUS.PAID
  );

  const isError = isErrorAccountPricingPlan || isErrorAllowedPricingPlans || isErrorPlanMutation;
  const loading = isLoadingAccountPricingPlan || isLoadingAllowedPricingPlans || isLoadingPlanMutation;

  useEffect(() => {
    setInvoicePer(accountDetails?.invoicePer);
  }, [accountDetails]);

  return (
    <ChangePricingPlanContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        isError,
        loading,
        selectedPlan,
        setSelectedPlan,
        invoicePer,
        setInvoicePer,
        maxHeights,
        setMaxHeights,
        allowedPlansForTransition,
        currentPricingPlan,
        upcomingPricingPlan,
        nextPayment,
        accountDetails,
        nextUnpaidSession,
        timezone,
        hasSeveralActiveEnrolments,
        paidSessions,
        planPreviewPaymentDetails,
        isPlanPreviewPaymentDetailsLoading,
        submitChangePlan,
        cancelChangePlan,
        changePlanError,
      }}
    >
      {children}
    </ChangePricingPlanContext.Provider>
  );
};

export default function useChangePricingPlanContext() {
  const context = useContext(ChangePricingPlanContext);

  return context;
}
