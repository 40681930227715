import classNames from 'classnames';
import { useEffect, useRef } from 'react';

import useChangePricingPlanContext from '../useChangePricingPlanContext';

export type ComparisonPolicyRowProps = {
  children: React.ReactNode;
  className?: string;
  row?: {
    header: string;
  };
};

export const ComparisonPolicyRow = ({ row, children, className }: ComparisonPolicyRowProps) => {
  const { maxHeights, setMaxHeights } = useChangePricingPlanContext();

  const ref = useRef(null);
  const minHeight = maxHeights?.[row?.header];

  useEffect(() => {
    const height = ref.current?.offsetHeight || 0;
    if (row) {
      if (height && setMaxHeights && (!minHeight || height > minHeight)) {
        setMaxHeights({ ...maxHeights, [row.header]: height });
      }
    }
  }, [ref, maxHeights, minHeight, row, setMaxHeights]);

  return (
    <div
      ref={ref}
      className={classNames('overflow-hidden px-4 py-3', className)}
      style={{
        minHeight,
      }}
    >
      {children}
    </div>
  );
};
