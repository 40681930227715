import { Button } from '@cluey/cluey-components';
import { Assessment } from '../../../../../../api/types/assessment';
import { isValidUrl } from '../functions';
import { generatePath, useHistory } from 'react-router';
import { PATH_HUB_VIEW_ASSESSMENT } from '../../../../../../util/pagePath';
import { getTokenFromUrl } from '../..';

export interface OnGoingAssessmentCardProps {
	assessment?: Assessment;
	studentAvailableSubjects: string[];
}

const OngoingAssessmentCards = ({ assessment, studentAvailableSubjects }: OnGoingAssessmentCardProps) => {
	const history = useHistory();
	const cardClassNames =
		'flex w-full flex-col md:items-center space-y-4 rounded-lg border border-grey-2 bg-white p-4 shadow md:flex-row md:space-y-0';
	if (assessment?.status === 'Completed') {
		if (studentAvailableSubjects?.includes(assessment?.subject)) {
			return null;
		}
		const score = Math.round((assessment?.questionsCorrect / assessment?.questionsTotal) * 100);
		const token = getTokenFromUrl(assessment?.studentLink || '');
		const path = generatePath(PATH_HUB_VIEW_ASSESSMENT, { token });
		return (
			<div className={cardClassNames}>
				<div className="flex-1">
					<span>Your</span>
					<span className="font-bold">{` ${assessment?.yearLevel} ${assessment?.subject} `}</span>
					<span>assessment is completed and you scored</span>
					<span className="font-bold">{` ${score}%`}</span>
				</div>
				<div>
					<Button
						type="button"
						size="sm"
						className="!border text-xs !font-bold uppercase !tracking-[1px]"
						appearance="reverse"
						onClick={() => {
							history.push(path);
						}}
					>
						<span>See results</span>
					</Button>
				</div>
			</div>
		);
	} else if (
		assessment?.status === 'Incomplete' ||
		assessment?.status === 'Not Accessed' ||
		assessment?.status === 'Accessed'
	) {
		const questionsRemaining = (assessment?.questionsTotal || 0) - (assessment?.questionsCompleted || 0);
		return (
			<div className={cardClassNames}>
				<div className="flex-1">
					<span>{`${questionsRemaining} out of ${
						assessment?.questionsTotal || 0
					} questions remaining to complete your`}</span>
					<span className="font-bold">{` ${assessment?.yearLevel} ${assessment?.subject} `}</span>
					<span>assessment</span>
				</div>
				<div>
					<Button
						type="button"
						size="sm"
						className="!border text-xs !font-bold uppercase !tracking-[1px]"
						appearance="reverse"
						disabled={!isValidUrl(assessment?.studentLink)}
						onClick={() => {
							window.open(assessment.studentLink, '_self');
						}}
					>
						<span>Continue assessment</span>
					</Button>
				</div>
			</div>
		);
	}
	return null;
};

export default OngoingAssessmentCards;
