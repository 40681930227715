import { useMutation } from "@tanstack/react-query";
import SessionService from '../../services/SessionService';

interface Params {
  /* 
    anything with "recipient" in its name concerns the recipient of the email notification
    it should be whoever is currently logged-in
  */
  data: {
    tutorId: string;
    enrolmentId: string;
    customerId: string;
    recipientFirstname: string;
    recipientTimezone: string;
    recipientCountry: string;
    studentFirstName: string;
    studentName: string;
    studentYearLevel: string;
    studentCurriculum: string;
    programSubject: string;
    sessionStartDateTime: string;
    sessionEndDateTime: string;
    tutorFirstName: string;
    tutorTimezone: string;
    tutorPhoto: string;
  },
  onSuccess(): void;
}

// eslint-disable-next-line valid-jsdoc
/**
 * @deprecated This function has been deprecated in favor of api/services/enrolment.oneOffSession
 *
 */
export const useCreateOneOffSession = (params: Params) => {
  const { data: payload, onSuccess } = params;

  return useMutation(
		async () => {
			const reply = await SessionService.createOneOffSession(payload);
			return reply.data;
		},
		{
			onSuccess,
		}
	);
}