import { FAQQuestions } from '../../components/FAQComponent/types';

export const generalPlanQuestions: FAQQuestions = {
  id: ['general-plan'],
  questions: {
    howAmICharged: {
      question: 'How am I charged for my Cluey plan?',
      priority: 1,
      answer:
        'Your registered payment method is charged according to the plan you choose. When you first sign up with Cluey, you will be charged for sessions booked in the first two weeks. After your first session is completed, your ongoing weekly charge will commence. This means that from that point forward, you will be charged weekly for the upcoming sessions, two weeks in advance. This payment structure ensures that you have continuous access to our tutoring services, providing you with uninterrupted support for your learning journey.',
    },
    canIReschedule: {
      question: 'Can I reschedule a session?',
      priority: 1,
      answer: (
        <div>
          <div>
            You can reschedule a one-to-one tutoring session free of charge up to 4 hours before the session's scheduled
            start time. To do so, navigate to the session you wish to reschedule in your Hub account and select
            'change.' Select the option to reschedule the session and choose a new day and time that works best for you.
          </div>
          <br />
          <div>Things to keep in mind:</div>
          <br />
          <div>
            <ul className="list-inside list-disc space-y-4">
              <li>Rescheduling must be done at least 4 hours before the scheduled start time.</li>
              <li>The rescheduled session must occur before the following session.</li>
              <li>
                If the same tutor is unavailable to cover your preferred rescheduled time, a replacement tutor must be
                selected.
              </li>
              <li>
                Group sessions cannot be rescheduled on demand due to the coordination required for multiple students.
              </li>
            </ul>
          </div>
        </div>
      ),
    },
    myChildIsSick: {
      question: "What happens if my child gets sick and can't attend the session?",
      answer: (
        <div>
          <div>
            If your child gets sick on the day of the session, you have the possibility to reschedule it up to 4 hours
            before the session's scheduled start time. To do so, navigate to the session you wish to reschedule in your
            Hub account and select ‘change.’ Select the option to reschedule the session and choose a new day and time
            that works best for you.
          </div>
          <br />
          <div>Things to keep in mind:</div>
          <br />
          <div>
            <ul className="list-inside list-disc space-y-4">
              <li>Rescheduling must be done at least 4 hours before the scheduled start time.</li>
              <li>The rescheduled session must occur before the following session.</li>
              <li>
                If the same tutor is unavailable to cover your preferred rescheduled time, a replacement tutor must be
                selected.
              </li>
              <li>
                Group sessions cannot be rescheduled on demand due to the coordination required for multiple students
              </li>
            </ul>
          </div>
        </div>
      ),
    },
    whatIfWeAreLate: {
      question: "What should I do if we are running late and can't connect to a session on time?",
      answer: (
        <div>
          <div>
            If you find yourselves running late and unable to connect to a session on time, don't worry. Our tutors
            understand that unforeseen circumstances can arise. They will wait up to 25 minutes from the scheduled start
            time for you to join the session. However, please note that the session will still run its normal duration
            and will not be extended due to a late start. We encourage you to make every effort to join the session as
            soon as possible to maximize the session time.
          </div>
          <br />
          <div>
            If you are not in the session at the start time, you will receive an automatic call from us. If you are
            having technical difficulties, be sure to answer this call so we can help. Otherwise, you can contact our
            Customer Care team from the Learning Platform so that we can provide any necessary assistance and keep your
            tutor informed.
          </div>
        </div>
      ),
    },
    whatIfTheTutorNoShows: {
      question: 'What is the policy if the tutor fails to attend a session?',
      answer:
        'If a tutor cannot attend a session and we cannot arrange a replacement tutor, you will receive a full credit to be used for a future session.',
    },
    doIGetCreditForCancelledSessions: {
      question: 'Do I get a credit if Cluey cancels a session?',
      answer:
        'There are rare instances where we may need to cancel a session, such as a last-minute tutor cancellation when a replacement cannot be found or in the event of a technical outage. In such cases, we will provide you with a full credit for a future session.',
    },
    howCanIChangeMyPlan: {
      question: 'How can I change my plan?',
      answer:
        "You can change your plan (for example, switching from Saver to Saver Plus) by clicking on the 'Change plan' button at the bottom of your Plan Details page. Please note: the new price will take effect from the next unpaid session. Sessions you have already paid for will not be impacted.",
    },
    whatHappensToBilling: {
      question: 'What happens to my billing if I change my plan?',
      answer:
        "Changing your plan, such as switching from Saver to Saver Plus, generally won't impact your billing cycle. Once your new plan comes into effect, your ongoing weekly charge will be for the sessions scheduled for the week after next, based on the price of your new plan. Please note: the new price will take effect from the next unpaid session. Sessions you have already paid for will not be impacted.",
    },
  },
};

export const saverPlusPlanQuestions: FAQQuestions = {
  title: 'Saver Plus',
  questions: {
    howMuchAmICharged: {
      question: 'How much am I going to be charged?',
      priority: 2,
      answer:
        'You can check the amount and the date of the next payment on your Plan Details page. Your ongoing weekly charge with Cluey is based on the sessions scheduled in 4 weeks. Each week, you pay in advance for the sessions that are scheduled 4 weeks ahead. ',
    },
    canICancelFreeOfCharge: {
      question: 'Am I allowed to cancel any session free of charge?',
      priority: 2,
      answer: (
        <div>
          <ul className="list-inside list-disc space-y-4">
            <li>
              You cannot cancel a session free of charge on the Saver Plus plan. Once paid, a session needs to be used
              before the next scheduled session.
            </li>
            <li>No credit will be applied to the account if the student misses the session.</li>
            <li>
              However, you can still reschedule a session if needed, provided you do so at least 4 hours before the
              session's scheduled start time.
            </li>
          </ul>
        </div>
      ),
    },
    howDoIPause: {
      question: 'How do I pause an enrolment?',
      priority: 2,
      answer: (
        <div>
          <ul className="list-inside list-disc space-y-4">
            <li>
              Pausing sessions is only available during the public school holidays in your state of residence. The pause
              option will be available in your Hub 4 weeks before the start of the public school holidays.
            </li>
            <li>During the term holidays, you can opt to pause Cluey sessions for 2 weeks.</li>
            <li>You can pause sessions for up to 6 weeks for the summer holidays.</li>
          </ul>
        </div>
      ),
    },
  },
};

export const saverPlanQuestions: FAQQuestions = {
  title: 'Saver',
  questions: {
    howMuchAmICharged: {
      question: 'How much am I going to be charged?',
      priority: 2,
      answer:
        'You can check the amount and the date of the next payment on your Plan Details page. Your ongoing weekly charge with Cluey is based on the sessions scheduled for the week after next. Each week, you pay in advance for the sessions that are scheduled two weeks ahead. ',
    },
    canICancelFreeOfCharge: {
      question: 'Am I allowed to cancel any session free of charge?',
      priority: 2,
      answer: (
        <div>
          <ul className="list-inside list-disc space-y-4">
            <li>
              You cannot cancel a session free of charge on the Saver plan. Once paid, a session needs to be used before
              the next scheduled session.
            </li>
            <li>No credit will be applied to the account if the student misses the session.</li>
            <li>
              However, you can still reschedule a session if needed, provided you do so at least 4 hours before the
              session's scheduled start time.
            </li>
          </ul>
        </div>
      ),
    },
    howDoIPause: {
      question: 'How do I pause an enrolment?',
      priority: 2,
      answer: (
        <div>
          <ul className="list-inside list-disc space-y-4">
            <li>
              Pausing sessions is only available during the public school holidays in your state of residence. The pause
              option will be available in your Hub 4 weeks before the start of the public school holidays.
            </li>
            <li>During the term holidays, you can opt to pause Cluey sessions for 2 weeks.</li>
            <li>You can pause sessions for up to 6 weeks for the summer holidays.</li>
          </ul>
        </div>
      ),
    },
  },
};

export const flexiPlanQuestions: FAQQuestions = {
  title: 'Flexi',
  questions: {
    howMuchAmICharged: {
      question: 'How much am I going to be charged?',
      priority: 2,
      answer:
        'You can check the amount and the date of the next payment on your Plan Details page. Your ongoing weekly charge with Cluey is based on the sessions scheduled for the week after next. Each week, you pay in advance for the sessions that are scheduled two weeks ahead. ',
    },
    canICancelFreeOfCharge: {
      question: 'Am I allowed to cancel any session free of charge?',
      priority: 2,
      answer: (
        <div>
          <ul className="list-inside list-disc space-y-4">
            <li>
              You can cancel any session up to 48 hours before the session's scheduled start time and get a full credit
              to use for a future session.
            </li>
            <li>Sessions that are cancelled within 48 hours are not eligible for a credit.</li>
            <li>
              You can also reschedule a session rather than cancel it, provided you do so at least 4 hours before the
              session's scheduled start time.
            </li>
            <li>
              To change or cancel a session, navigate to the session you wish to cancel in your Hub account and select
              'change.' You can select the cancel option or reschedule the session for a new day and time that works
              best for you.
            </li>
          </ul>
        </div>
      ),
    },
    howDoIPause: {
      question: 'How do I pause an enrolment?',
      priority: 2,
      answer: (
        <div>
          <ul className="list-inside list-disc space-y-4">
            <li>You can pause sessions anytime for a period of up to 4 weeks.</li>
            <li>Additionally, you can pause for up to 6 weeks during the summer holidays.</li>
          </ul>
        </div>
      ),
    },
  },
};

export const valuePlusPlanQuestions: FAQQuestions = {
	title: 'Value Max',
	questions: {
		howMuchAmICharged: {
			question: 'How much am I going to be charged?',
			priority: 2,
			answer:
				'You can check the amount and the date of the next payment on your Plan Details page. Your ongoing weekly charge with Cluey is based on the sessions scheduled in 6 weeks. Each week, you pay in advance for the sessions that are scheduled 6 weeks ahead.',
		},
		canICancelFreeOfCharge: {
			question: 'Am I allowed to cancel any session free of charge?',
			priority: 2,
			answer: (
				<div>
					<ul className="list-inside list-disc space-y-4">
						<li>
							You cannot cancel a session free of charge on the Value Max plan. Once paid, a session needs to be used
							before the next scheduled session.
						</li>
						<li>No credit will be applied to the account if the student misses the session.</li>
						<li>
							However, you can still reschedule a session if needed, provided you do so at least 4 hours before the
							session's scheduled start time.
						</li>
					</ul>
				</div>
			),
		},
    howDoIPause: {
			question: 'How do I pause an enrolment?',
			priority: 2,
			answer: (
				<div>
					<ul className="list-inside list-disc space-y-4">
						<li>
              Pausing sessions is only available during the public school holidays in your state of residence. 
              The pause option will be available in your Hub 6 weeks before the start of the public school holidays.
            </li>
						<li>During the term holidays, you can opt to pause Cluey sessions for 2 weeks.</li>
            <li>You can pause sessions for up to 6 weeks for the summer holidays.</li>
					</ul>
				</div>
			),
		},
	},
};
