import { apiFetch } from '../../services/backendApi';
import { REQ_FETCH_ZUORA_PARAMS } from '../../services/backendApi/endpointTypes';
import getZuoraURLParams from '../../util/getZuoraURLParams';
import {
	HUB_ZUORA_RESET_PARAMS,
	HUB_ZUORA_FETCH_ZUORA_FAILED,
	HUB_ZUORA_FETCH_ZUORA_SUCCESS,
	HUB_AP_BILLING_INFO_SUCCESS,
} from '../actionTypes';

export const fetchZuoraParams = () => (dispatch, getState) => {
	const country = getState().hubAccountPage.accountDetails.country;
	const zuoraURLParams = getZuoraURLParams(country);
	const payload = {
		pageId: zuoraURLParams.pageId,
		pageUrl: zuoraURLParams.url,
	};

	return apiFetch(REQ_FETCH_ZUORA_PARAMS, getState(), payload, dispatch)
		.then((data) => {
			const params = {
				id: payload.pageId,
				key: data.key,
				signature: data.signature,
				style: 'inline',
				submitEnabled: true,
				tenantId: data.tenantId,
				token: data.token,
				url: payload.pageUrl,
			};

			dispatch({ type: HUB_ZUORA_FETCH_ZUORA_SUCCESS, payload: params });
		})
		.catch(() => {
			dispatch({ type: HUB_ZUORA_FETCH_ZUORA_FAILED, payload: 'Failed to load payment form, please try again later' });
		});
};

export const resetZuoraParams = () => (dispatch) => {
	dispatch({ type: HUB_ZUORA_RESET_PARAMS });
};

export const setZuoraSuccessMsg = () => (dispatch) => {
	dispatch({
		type: HUB_AP_BILLING_INFO_SUCCESS,
		payload: 'We’re processing your new payment details, they will update shortly.',
	});
};
