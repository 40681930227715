import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { RadioPanel } from './RadioPanel';
import { RadioItemsType } from '../../types/hubPropTypes';

export const RadioPanelGroup = ({
  groupName,
  className,
  radios,
  selectedRadio,
  setSelectedRadio,
}) => {
  return (
    <section className={className}>
      {radios.map((radio, i) => {
        const isLast = i === radios.length - 1;
        return (
          <div
            className={classnames({ 'mb-4 lg:mb-5': !isLast })}
            key={radio.value}
          >
            <RadioPanel
              {...radio}
              key={radio.value}
              groupName={groupName}
              checked={selectedRadio === radio.value}
              setSelectedRadio={setSelectedRadio}
            />
          </div>
        );
      })}
    </section>
  );
};

RadioPanelGroup.propTypes = {
  className: PropTypes.string,
  groupName: PropTypes.string.isRequired,
  selectedRadio: PropTypes.string,
  setSelectedRadio: PropTypes.func.isRequired,
  radios: RadioItemsType,
};
RadioPanelGroup.defaultProps = {
  className: null,
  selectedRadio: null,
  radios: [],
};
