import React from 'react';
import { useLocation } from 'react-router';
import HubSocialOauthErrorLayout from '../../../common/hub/HubSocialOauthErrorLayout';
import Error from '../../../common/Error';
import { getWebsiteDomainByTZ } from '../../../util/helper/website';

export const OAUTH_ERROR_NO_ACCOUNT = 'no-account';

const HubSocialOauthErrorPage = () => {
  const websiteDomain = getWebsiteDomainByTZ();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const error = query.get('error');

  const renderNoAccountError = (
    <Error 
      firstLine="It looks like this email address is not associated with a Cluey account." 
      secondLine={(
        <>
          {'Please refer to our '}
          <a
            href={websiteDomain}
            rel="noopener noreferrer"
            className="text-blue-6 underline"
            target="_blank"
          >
            website
          </a>
          {' if you wish to explore our services.'}
        </>
      )} 
    />
  );

  const renderGeneralError = (
    <Error firstLine="We cannot log you in via the selected third party at this time. Please try again later." />
  );

  return (
    <HubSocialOauthErrorLayout>
      {error === OAUTH_ERROR_NO_ACCOUNT ? renderNoAccountError : renderGeneralError}
    </HubSocialOauthErrorLayout>
  );
}

export default HubSocialOauthErrorPage