import { Link } from '@cluey/cluey-components';
import { api } from '../../../../../api';
import { PATH_HUB_UPCOMING_SESSIONS } from '../../../../../util/pagePath';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { ReactNode, useMemo, useState } from 'react';
import { ChevronDownIcon } from '../../../../../icons';
import { HubSessionListSkeletonItem } from '../../../../../components/hub/sessions/HubSessionsListItem/HubSessionListSkeletonItem';
import { formatError } from '../../../../../util/errors';
import { AddAStudentCard, StudentCard, StudentCardSkeleton } from './student-card';
import { EnrolmentSession, SnapshotUpcomingItem } from './enrolment-session';
import { hasCancelRequested, hasTrial } from './common';
import { ENROLMENT_STATUS } from '../../../../../types/hubEnums';

export const SnapshotStudents = () => {
	const [studentCursor, setStudentCursor] = useState(0);

	const {
		data: students,
		isLoading: isStudentsLoading,
		error: studentsError,
	} = api.student.all.useQuery(undefined, {
		select: (data) => sortBy(data, ['firstName', 'lastName']),
	});

	const studentsToShow = students?.slice(studentCursor, studentCursor + 3) || [];

	let desktop: ReactNode, mobile: ReactNode;

	if (studentsError) {
		// Error States
		desktop = (
			<div className="col-span-3 flex w-full flex-col gap-2 py-2">
				<div className="flex items-center justify-center">Something went wrong 😭</div>
				<pre className="text-center text-xs">{formatError(studentsError)}</pre>
			</div>
		);
		mobile = desktop;
	} else if (isStudentsLoading) {
		// Loading States
		desktop = (
			<>
				<StudentCardSkeleton />
				<StudentCardSkeleton />
				<StudentCardSkeleton />
			</>
		);
		mobile = <StudentCardSkeleton />;
	} else {
		// All is well, show content!
		desktop = (
			<>
				{studentCursor > 0 && (
					<div className="absolute right-full top-1/2 -translate-y-1/2 pr-5">
						<button
							type="button"
							onClick={() => setStudentCursor(studentCursor - 1)}
							className="flex h-10 w-10 items-center justify-center rounded border border-grey-2 hover:bg-slate-1"
						>
							<ChevronDownIcon className="rotate-90 text-primary" />
							<span className="sr-only">Previous Student</span>
						</button>
					</div>
				)}
				{studentsToShow.map((student) => (
					<StudentCard key={student.studentId} student={student} />
				))}
				{studentsToShow.length < 3 && <AddAStudentCard />}
				{studentsToShow.length > 2 && (
					<div className="absolute left-full top-1/2 -translate-y-1/2 pl-5">
						<button
							type="button"
							onClick={() => setStudentCursor(studentCursor + 1)}
							className="flex h-10 w-10 items-center justify-center rounded border border-grey-2 hover:bg-slate-1"
						>
							<ChevronDownIcon className="-rotate-90 text-primary" />
							<span className="sr-only">Next Student</span>
						</button>
					</div>
				)}
			</>
		);
		mobile = (
			<div className="flex snap-x gap-4 overflow-x-auto pb-4 md:hidden">
				{students?.map((student) => (
					<StudentCard key={student.studentId} student={student} className="snap-center" />
				))}
				<AddAStudentCard />
			</div>
		);
	}

	return (
		<div className="flex flex-col gap-4">
			<div className="text-xs font-bold uppercase tracking-[1px]">Students</div>
			{/* Desktop View */}
			<div className="relative hidden grid-cols-3 gap-8 md:grid">{desktop}</div>
			{/* Mobile View */}
			<div className="relative h-36 md:hidden">{mobile}</div>
		</div>
	);
};

export const SnapshotSessions = () => {
	const { data: accountDetails } = api.account.details.useQuery();
	const { data: students, isLoading: isStudentsLoading, error: studentsError } = api.student.all.useQuery();
	const {
		data: upcomingSessions,
		isLoading: isSessionsLoading,
		error: sessionsError,
	} = api.session.all.useQuery('upcoming', {
		select: (data) => sortBy(data, ['startTime'], ['asc']),
	});
	const { data: enrolments, isLoading: isLoadingEnrolments } = api.enrolment.all.useQuery('active');

	const upcomingItems = useMemo(() => {
		if (!upcomingSessions) return [];

		// in an emergency we can show the first 5 sessions
		if (!students) return upcomingSessions.slice(0, 5).map((session) => ({ type: 'session', session }));

		let withSessions: SnapshotUpcomingItem[] = [];
		let withoutSessions: SnapshotUpcomingItem[] = [];

		students.forEach((student) => {
			// flatten all enrolmentIds for the student
			const enrolmentIds = Object.values(student.enrolments).flat();

			// if there are no enrolments, show the student card
			if (enrolmentIds.length === 0) {
				withoutSessions.push({
					type: 'student',
					student,
				});
			} else {
				let missedEnrolments = 0;
				// get the next sessiopn for each of the student's enrolments
				enrolmentIds.forEach(({ uniqueEnrolmentId }) => {
					// fyi, upcomingSessions is sorted by select in useQuery
					const session = upcomingSessions.find((session) => session.uniqueEnrolmentId === uniqueEnrolmentId);
					if (session) {
						withSessions.push({
							type: 'session',
							session,
						});
					} else {
						// no upcoming sessions for this enrolment, check if it's active
						const enrolment = enrolments?.find((enrolment) => enrolment.enrolmentId === uniqueEnrolmentId);
						if (
							enrolment &&
							enrolment.enrolmentStatus !== ENROLMENT_STATUS.CANCELLED &&
							enrolment.enrolmentStatus !== ENROLMENT_STATUS.FINISHED &&
							(hasCancelRequested(enrolment) || hasTrial(enrolment))
						) {
							withoutSessions.push({
								type: 'student',
								enrolment,
								student,
							});
						} else {
							// if we can't find any valid enrolments mark it as missed
							missedEnrolments++;
						}
					}
				});

				if (missedEnrolments >= enrolmentIds.length) {
					// if all enrolments have no upcoming sessions or can't find a valid enrolment, show the student card
					withoutSessions.push({
						type: 'student',
						student,
					});
				}
			}
		});

		return [...sortBy(withSessions, ['session.startTime', 'session.student', 'session.subject']), ...withoutSessions];
	}, [upcomingSessions, students, enrolments]);

	let view: ReactNode;
	if (sessionsError) {
		view = (
			<div className="col-span-3 flex w-full flex-col gap-2 py-2">
				<div className="flex items-center justify-center">Something went wrong 😭</div>
				<pre className="text-center text-xs">{formatError(studentsError || sessionsError)}</pre>
			</div>
		);
	} else if (isSessionsLoading || isLoadingEnrolments || isStudentsLoading) {
		view = (
			<>
				<HubSessionListSkeletonItem />
				<HubSessionListSkeletonItem />
				<HubSessionListSkeletonItem />
			</>
		);
	} else if (isEmpty(upcomingItems)) {
		view = <p className="text-center">No upcoming sessions</p>;
	} else {
		view = upcomingItems.map((item) => (
			<EnrolmentSession
				key={item.type === 'session' ? item.session.studentSessionId : item.student.studentId}
				timezone={accountDetails.timezone}
				{...item}
			/>
		));
	}

	return (
		<div className="flex flex-col gap-4">
			<div className="text-xs font-bold uppercase tracking-[1px]">Upcoming Sessions</div>
			<div className="flex flex-col justify-between gap-4 lg:flex-row lg:items-center">
				{accountDetails ? (
					<p className="text-sm">Times are in your account timezone ({accountDetails.timezone})</p>
				) : (
					<p className="animate-pulse rounded-full bg-slate-2 text-sm text-transparent">
						Times are in your account timezone
					</p>
				)}
				<Link size="base" to={PATH_HUB_UPCOMING_SESSIONS}>
					View all sessions
				</Link>
			</div>
			<div className="flex flex-col gap-4">{view}</div>
		</div>
	);
};

export const SnapshotWidget = () => {
	return (
		<section className="bg-slate-1">
			<div className="container mx-auto px-4 pb-16 pt-10 md:px-0">
				<h2 className="pb-8 font-display text-[28px] font-bold">Snapshot</h2>
				<div className="flex flex-col gap-16 rounded-lg border border-slate-2 bg-white px-4 py-8 shadow-md md:px-20 md:py-14">
					{/* Upcoming Sessions Section */}
					<SnapshotSessions />
					{/* Students Section */}
					<SnapshotStudents />
				</div>
			</div>
		</section>
	);
};
