import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { DATE_FORMAT_1 } from '../../util/constants';

const ListItemSchoolTerm = ({ termItem, timezone }) => {
  const { startTime, state, schoolTerm, isTermStartDate } = termItem;
  const formatDate = (time) => {
    return moment(time).tz(timezone).format(DATE_FORMAT_1);
  };

  const displayDate = formatDate(startTime);

  return (
    <li className="list-group-item bg-blood-orange-1 px-6 py-3">
      <span className="mr-1 inline-block font-bold">{displayDate}:</span>
      <span className="mr-1 inline-block uppercase">{state}</span>
      <span className="mr-1 inline-block capitalize">{schoolTerm}</span>
      <span className="mr-1 inline-block capitalize">
        {isTermStartDate ? 'starts' : 'ends'}
      </span>
    </li>
  );
};

ListItemSchoolTerm.propTypes = {
  termItem: PropTypes.shape({
    startTime: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    schoolTerm: PropTypes.string.isRequired,
    isTermStartDate: PropTypes.bool.isRequired,
  }).isRequired,
  timezone: PropTypes.string,
};

ListItemSchoolTerm.defaultProps = {
  timezone: 'Australia/Sydney',
};

export default connect((state) => {
  return {
    timezone: state.upcomingSessions.timezone,
  };
}, null)(ListItemSchoolTerm);
