import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';

import withSelectedEnrolment from '../../../common/hub/NewWithSelectedEnrolment';
import ContactFormLayout from '../../../common/hub/ContactFormLayout';
import { useRadioGroupProps, RadioGroup } from '../../../components/RadioGroup/RadioGroup';
import { useTextAreaProps, TextArea } from '../../../components/TextArea/TextArea';
import { submitCaseToSF } from '../../../actions/hub/hubContactPageActions';
import FormSubHeading from '../../../components/hub/contact/FormSubHeading';
import ErrorBlock from '../../../common/ErrorBlock';
import { PATH_HUB_CR_SUBMIT_CASE_SUCCESS, PATH_HUB_CR_OPT_IN_OUT } from '../../../util/pagePath';
import { Button } from '@cluey/cluey-components';

const groupName = 'practice-form';
const radios = [
  {
    text: 'Yes',
    value: 'opt into',
    key: `${groupName}-category-1`,
  },
  {
    text: 'No',
    value: 'opt out of',
    key: `${groupName}-category-2`,
  },
];

const FormOptInOut = ({ selectedEnrolment, isSubmitting, submitError, errorMsg, submit }) => {
  const radioGroupProps = useRadioGroupProps({ groupName, radios });
  const textAreaProps = useTextAreaProps({
    id: `${groupName}-textarea`,
    labelText: "Is there anything else you'd like to tell us?",
  });

  const { enrolmentId, studentSfid, student, subject } = selectedEnrolment;
  const history = useHistory();
  const onClickHandler = () => {
    const caseType = 'Feedback';
    const caseCategory = 'Session';
    const caseSubCategory = 'Session content';
    submit({
      uniqueEnrolmentId: enrolmentId,
      caseSubject: `Contact Request - Practice`,
      message: `Request to ${radioGroupProps.selectedRadio} practice for selected enrolment. Details: ${textAreaProps.value}`,
      caseType,
      caseCategory,
      caseSubCategory,
      onSubmitSuccess: () => {
        history.push(PATH_HUB_CR_SUBMIT_CASE_SUCCESS, {
          firstName: student,
          studentId: studentSfid,
          message: `Your preference for practice in ${student}'s ${subject} enrolment has been submitted successfully. We'll be in touch soon.`,
        });
      },
    });
  };

  return (
    <ContactFormLayout selectedEnrolment={selectedEnrolment}>
      <div className="px-5 pt-5 lg:px-20 lg:pt-12">
        <FormSubHeading />
        {submitError && errorMsg && <ErrorBlock errorMsg={errorMsg} />}
        <form>
          <RadioGroup {...radioGroupProps} className="mb-9" />
          <TextArea {...textAreaProps} className="mb-7" />
          <div className="mb-4 text-center tracking-normal lg:mb-8">
            <Button
              size="lg"
              disabled={!radioGroupProps.selectedRadio || !textAreaProps.value || isSubmitting}
              onClick={onClickHandler}
            >
              Submit feedback
            </Button>
          </div>
        </form>
      </div>
    </ContactFormLayout>
  );
};

FormOptInOut.propTypes = {
  selectedEnrolment: PropTypes.shape({
    enrolmentId: PropTypes.string.isRequired,
    studentSfid: PropTypes.string.isRequired,
    student: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
  }).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitError: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    return {
      isSubmitting: state.ui.apiState.hubContactPage.isLoading,
      submitError: state.ui.apiState.hubContactPage.fetchError,
      errorMsg: state.ui.apiState.hubContactPage.errorMsg,
    };
  },
  (dispatch) => {
    return {
      submit: bindActionCreators(submitCaseToSF, dispatch),
    };
  }
)(withSelectedEnrolment(FormOptInOut, null, PATH_HUB_CR_OPT_IN_OUT));
