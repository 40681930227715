import { ddLogger, ddLoggerLevel, logError } from '../../logging';
import { FETCH_FAILED } from '../../logging/errors';
import { sendGetRequest } from '../RequestService';
import { getPortalAPIRequestParams } from './helper';

export class BillingInformationService {
  static async getBillingInformation() {
    try {
      const { url, reqOptions } = getPortalAPIRequestParams('account/billing');
      const res = await sendGetRequest({
        url,
        signRequest: true,
        reqOptions,
        customErrorMsg: 'Your billing information could not be retrieved.',
      });

      return res.json();
    } catch (error) {
      logError(`Error fetching billing information`, null, {});
      console.warn(error);
      ddLogger({
        level: ddLoggerLevel.ERROR,
        label: FETCH_FAILED.message,
        data: { error },
        error: new Error(FETCH_FAILED.message),
      });
      throw new Error(
        'There was an error getting information about your billing information. Please try again later or contact us.'
      );
    }
  }
}
