import React from 'react';
import { useLocation } from 'react-router-dom';

import * as PATHS from '../../../util/pagePath';

const getSubHeadingByPath = (path) => {
    let subHeading = '';
    switch (path) {
        case PATHS.PATH_HUB_CR_FEEDBACK:
            subHeading = 'What is your feedback about?';
            break;
        case PATHS.PATH_HUB_CR_OPT_IN_OUT:
            subHeading = 'Would you like to have practice assigned for this enrolment?';
            break;
        case PATHS.PATH_HUB_CR_CANCEL_ENROLMENT:
            subHeading = 'Cancel enrolment';
            break;
        case PATHS.PATH_HUB_CR_PERMANENT_SCHEDULE_CHANGE:
            subHeading = 'Change schedule';
            break;
        case PATHS.PATH_HUB_CR_CHANGE_TUTOR:
            subHeading = 'Change tutor';
            break;
        case PATHS.PATH_HUB_CR_PAYMENT_QUERY:
            subHeading = 'Payment query';
            break;
        case PATHS.PATH_HUB_CR_OTHER_QUERY:
            subHeading = 'Other Enquiry';
            break;
        case PATHS.PATH_HUB_CR_RESCHEDULE_SESSION:
            subHeading = 'Reschedule session';
            break;
        case PATHS.PATH_HUB_CR_SKIP_SESSION:
            subHeading = 'Skip session';
            break;
        case PATHS.PATH_HUB_CR_ADD_ENROLMENT:
            subHeading = 'New enrolment details';
            break;
        default:
            break;
    }

    return subHeading;
};

const FormSubHeading = () => {
    const location = useLocation();
    const subHeading = getSubHeadingByPath(location.pathname);
    return (
        <div>
            {subHeading.length > 0 && <h4 className="font-display text-xl font-bold lg:text-2xl">{subHeading}</h4>}
        </div>
    );
};

FormSubHeading.propTypes = {};

export default FormSubHeading;
