import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { scrollToTop } from '../../util/helpers';

import arrowRight from '../../assets/images/icon-filled-arrow-right-no-stem.svg';
import arrowLeft from '../../assets/images/icon-filled-arrow-left-no-stem.svg';

const RecordsPagination = ({ recordsTotal, noOfRecordsPerPage, handlePaginationClick }) => {
	const [activePageIndex, setActivePageIndex] = useState(1);
	const totalPages = Math.ceil(recordsTotal / noOfRecordsPerPage);
	if (totalPages <= 1) {
		return null;
	}

	const handleClick = (newActivePageIndex) => {
		const startIndex = (newActivePageIndex - 1) * noOfRecordsPerPage;
		handlePaginationClick(startIndex);
		setActivePageIndex(newActivePageIndex);
		// console.log(newActivePageIndex);
		scrollToTop();
	};

	// const baseClasses =
	//   'page-item border border-ice rounded tracking-[1px] text-xs text-outer-space py-0 font-body font-weight-bold text-uppercase';
	// const prevBtnClasses = classNames(baseClasses, 'page-item--prev', { disabled: activePageIndex === 1 });
	// const nextBtnClasses = classNames(baseClasses, 'page-item--next', { disabled: activePageIndex === totalPages });
	// console.log(activePageIndex);
	const buttonClasses =
		'border border-grey-3 rounded text-3xs py-2 font-bold uppercase px-3 disabled:opacity-40 bg-white hover:bg-slate-1 transition-colors';
	return (
		<div className="pagination mt-4 flex justify-between">
			<button
				type="button"
				disabled={activePageIndex === 1}
				className={buttonClasses}
				onClick={() => handleClick(activePageIndex - 1)}
			>
				<div className="flex justify-between">
					<img src={arrowLeft} alt="prev page" />
					<span className="ml-3 uppercase tracking-widest">Previous</span>
				</div>
			</button>
			<button
				type="button"
				disabled={activePageIndex === totalPages}
				className={buttonClasses}
				onClick={() => handleClick(activePageIndex + 1)}
			>
				<div className="flex justify-between">
					<span className="mr-3 uppercase tracking-widest">Next</span>
					<img src={arrowRight} alt="prev page" />
				</div>
			</button>
		</div>
	);
};

RecordsPagination.propTypes = {
	recordsTotal: PropTypes.number.isRequired,
	noOfRecordsPerPage: PropTypes.number.isRequired,
	handlePaginationClick: PropTypes.func.isRequired,
};

export default RecordsPagination;
