import React from 'react';

export const SchoolsFooter = () => {
  return (
    <footer className="py-10 text-grey-6">
      <div className="py-10">
        <div className="border-t border-grey-2" />
      </div>
      <div className="container mx-auto">
        <div className="w-full md:w-1/2 pt-10">
          <div className="text-xl font-bold font-display">Need further support with your school group learning?</div>
          <div className="text-lg pt-1 font-body">
            Your Cluey School Support Officer is here to assist you - send them a message at{' '}
            <a className="text-primary hover:underline" href="mailto:schools@clueylearning.com">schools@clueylearning.com</a> or call on{' '}
            <a className="text-primary hover:underline" href="tel:0272025879">02 7202 5879</a>
          </div>
        </div>
      </div>
    </footer>
  );
};
