import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import moment from 'moment';
import { groupBy, sortBy } from 'lodash';
import TagManager from 'react-gtm-module';
import env from '../env';

import withHeadTag from '../common/HeadComponent';
import { getSessions } from '../selectors/upcomingSessionsSelector';
import { fetchUpcomingSessions } from '../actions/upcomingSessionsActions';
import { getRetryEnabled } from '../selectors/errorSelector';
import { isNZUserPageUS } from '../selectors/loginSelector';

import BackToTop from '../components/BackToTop/index';
import PageError from '../common/PageError';
import FullscreenLoader from '../common/FullscreenLoader';
import { PageLayout } from '../common/PageLayout/PageLayout';
import Welcome from '../components/UpcomingSessions/Welcome';
import SectionLayout from '../common/SectionLayout';
import SessionsList from '../components/SessionsList';

import ContactOptions from '../components/ContactUs/ContactOptions';
import ContactForm from '../components/ContactUs/ContactForm';
import ContactSuccess from '../components/ContactUs/ContactSuccess';

import { PaymentFailureAlertBox } from '../components/hub/home/InfoBox';

import {
  APP_VIEW_DEFAULT, APP_VIEW_CONTACT_OPTIONS, APP_VIEW_CONTACT_FORM, APP_VIEW_CONTACT_SUCCESS,
} from '../util/constants';
import { PAGE_NAME_UPCOMING_SESSIONS } from '../util/pageTitle';
import { getPaymentFailureAlertInfo } from '../util/helper';
import NextYearEnrolmentsAlerts from '../components/EnrolmentManagement/NextYearEnrolmentsAlerts';

class UpcomingSessionsPage extends React.Component {
  constructor(props) {
    super(props);
    const activeTabMatch = document.URL.match(/activeTab=([^&#]*)/);
    this.activeTabName = activeTabMatch && activeTabMatch[1];
    this.state = {
      isParentView: false,
    };
  }

  componentDidMount() {
    this.props.fetchUpcomingSessions();
  }

  componentDidUpdate(prevProps) {
    const { role, customerNumber } = this.props;
    if (
      prevProps.role !== role &&
      prevProps.customerNumber !== customerNumber &&
      role != null &&
      customerNumber != null &&
      env.REACT_APP_ENABLE_GOOGLE_TAG_MANAGER
    ) {
      const tagManagerArgs = {
        dataLayer: {
          household_number: customerNumber,
        },
      };

      if (role === 'Parent / Guardian') {
        tagManagerArgs.dataLayer.hub_view = 'Parent';
      } else {
        tagManagerArgs.dataLayer.hub_view = 'Student';
      }

      TagManager.dataLayer(tagManagerArgs);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.role === 'Parent / Guardian' && prevState.isParentView === false) {
      return { isParentView: true };
    }
    return {};
  }

  groupSessions = (sessions) => {
    const groupedSessions = groupBy(sessions, (s) => {
      if (moment(s.startTime).isSame(new Date(), 'day')) {
        s.isTodaySession = true;
        return 'today';
      }
      return 'upcoming';
    });

    return {
      todaysSessions: groupedSessions.today || [],
      upcomingSessions: groupedSessions.upcoming || [],
    };
  };

  render() {
    const {
      activeSpinner,
      errorLoading,
      retryEnabled,
      showSessions,
      sessions,
      ucSessionsVisible,
      showContactOptions,
      showContactForm,
      showContactSuccess,
      isNZUser,
    } = this.props;

    const students = Array.isArray(this.props.students) ? this.props.students : [];

    const { isParentView } = this.state;
    const sortedSessions = sortBy(sessions, ['startTime', 'student', 'subject']);
    students
      .map((student) => {
        const enrolmentId = student.uniqueEnrolmentId;
        return sortedSessions.findIndex((session) => {
          const { uniqueEnrolmentId, type, startTime } = session;
          return (
            uniqueEnrolmentId === enrolmentId &&
            (type.toLowerCase() === 'course' || type.toLowerCase() === 'program') &&
            moment(startTime).isAfter(new Date(), 'seconds')
          );
        });
      })
      .filter(index => index >= 0)
      .forEach((index) => {
        sortedSessions[index].isNextSession = true;
      });

    const paymentFailureInfo = getPaymentFailureAlertInfo(sortedSessions);
    const isMaitenanceMode = env.REACT_APP_MAINTENANCE_MODE;

    const { todaysSessions, upcomingSessions } = this.groupSessions(sortedSessions);
    const upcomingSectionClasses = classNames('rounded-lg c-section--with-top', { 'd-none d-lg-block': !ucSessionsVisible });

    return (
      <Fragment>
        {activeSpinner && <FullscreenLoader isOpen />}

        {errorLoading && <PageError activePage="upcoming-sessions-page" retryEnabled={retryEnabled} />}

        {!errorLoading && (
          <PageLayout
            header={{ show: true, title: `${PAGE_NAME_UPCOMING_SESSIONS}` }}
            footer={{ show: true }}
            isNZUser={isNZUser}
          >
            {showSessions && (
              <Fragment>
                <BackToTop />
                <Welcome activeTabName={this.activeTabName} />
                <SectionLayout sectionClasses={upcomingSectionClasses}>
                  {paymentFailureInfo && isParentView && !isNZUser && !isMaitenanceMode && (
                    <PaymentFailureAlertBox message={paymentFailureInfo} />
                  )}

                  {todaysSessions.length > 0 && (
                    <SessionsList
                      title="Today’s learning sessions"
                      sessions={todaysSessions}
                      isTodaySection
                      isNZUser={isNZUser}
                    />
                  )}
                  {upcomingSessions.length > 0 && (
                    <SessionsList
                      title="Upcoming learning sessions"
                      sessions={upcomingSessions}
                      isTodaySection={false}
                      isNZUser={isNZUser}
                    />
                  )}
                </SectionLayout>
              </Fragment>
            )}
            <div className="lg:px-10">
              <div className="container mx-auto px-4">
                <NextYearEnrolmentsAlerts />
              </div>
            </div>
            {showContactOptions && <ContactOptions />}
            {showContactForm && <ContactForm />}
            {showContactSuccess && <ContactSuccess />}
          </PageLayout>
        )}
      </Fragment>
    );
  }
}

UpcomingSessionsPage.propTypes = {
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      student: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      uniqueEnrolmentId: PropTypes.string,
      subject: PropTypes.string,
      tutor: PropTypes.string,
      type: PropTypes.string,
      sessionToken: PropTypes.string,
    }),
  ),
  students: PropTypes.arrayOf(
    PropTypes.shape({
      uniqueEnrolmentId: PropTypes.string,
    }),
  ),
  fetchUpcomingSessions: PropTypes.func.isRequired,
  retryEnabled: PropTypes.bool.isRequired,
  errorLoading: PropTypes.bool.isRequired,
  activeSpinner: PropTypes.bool.isRequired,
  showSessions: PropTypes.bool.isRequired,
  showContactOptions: PropTypes.bool.isRequired,
  showContactForm: PropTypes.bool.isRequired,
  showContactSuccess: PropTypes.bool.isRequired,
  ucSessionsVisible: PropTypes.bool.isRequired,
  role: PropTypes.string,
  customerNumber: PropTypes.string,
  isNZUser: PropTypes.bool.isRequired,
};

UpcomingSessionsPage.defaultProps = {
  students: [],
  sessions: [],
  role: null,
  customerNumber: null,
};

export default connect(
  (state) => {
    const { ui, upcomingSessions } = state;
    const { appView, apiState } = ui;
    const { isLoading, fetchError } = apiState.upcomingSessions;
    return {
      ucSessionsVisible: state.ui.apiState.upcomingSessions.ucSessionsVisible,
      sessions: getSessions(state),
      students: upcomingSessions.students,
      retryEnabled: getRetryEnabled(state),
      errorLoading: fetchError || !Array.isArray(upcomingSessions.students) || upcomingSessions.students.length === 0,
      activeSpinner: isLoading,
      showSessions: !isLoading && !fetchError && appView === APP_VIEW_DEFAULT,
      showContactOptions: appView === APP_VIEW_CONTACT_OPTIONS,
      showContactForm: appView === APP_VIEW_CONTACT_FORM,
      showContactSuccess: appView === APP_VIEW_CONTACT_SUCCESS,
      role: upcomingSessions.role,
      customerNumber: upcomingSessions.customerNumber,
      isNZUser: isNZUserPageUS(state),
    };
  },
  (dispatch) => {
    return {
      fetchUpcomingSessions: bindActionCreators(fetchUpcomingSessions, dispatch),
    };
  },
)(withHeadTag(UpcomingSessionsPage));
