import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import withSelectedEnrolment from '../../../common/hub/NewWithSelectedEnrolment';
import ErrorBlock from '../../../common/ErrorBlock';
import ContactFormLayout from '../../../common/hub/ContactFormLayout';
import FormSubHeading from '../../../components/hub/contact/FormSubHeading';
import { useTextAreaProps, TextArea } from '../../../components/TextArea/TextArea';
import { RestrictedButton } from '../../../components/RestrictedButton/RestrictedButton';

import { submitCaseToSF } from '../../../actions/hub/hubContactPageActions';
import { PATH_HUB_CR_SUBMIT_CASE_SUCCESS, PATH_HUB_CR_OTHER_ENROLMENT_CHANGE } from '../../../util/pagePath';
import { PAUSE_OUTSIDE_POLICY } from '../../../util/cancelEnrolmentReasons';
import { DatesUtils } from '../../../util/dates';
import { useSearchParams } from '../../../util/hooks/useSearchParams';

const FormOtherEnrolmentChangeRequest = ({ selectedEnrolment, isSubmitting, submitError, errorMsg, submit }) => {
  const history = useHistory();
  const { enrolmentId, studentSfid, student, subject } = selectedEnrolment;

  const textAreaProps = useTextAreaProps({
    id: 'text-how-can-we-help',
    labelText: 'How can we help you?',
  });

  const message = textAreaProps.value;

  const query = useSearchParams();
  const subjectFromQuery = query.get('subject');
  const isPauseOutsidePolicy = (subjectFromQuery || '').includes(PAUSE_OUTSIDE_POLICY.caseSubCategory);
  const pageTitle = isPauseOutsidePolicy ? 'Extend Pause for' : '';

  const onClickHandler = () => {
    const {
      caseType = 'Feedback',
      caseRecordType = '',
      caseCategory = 'Other',
      caseSubCategory = '',
    } = isPauseOutsidePolicy ? PAUSE_OUTSIDE_POLICY : {};

    const requestPayload = {
      uniqueEnrolmentId: enrolmentId,
      message: `Request for other enrolment change. Other details: ${message}`,
      caseSubject: subjectFromQuery || 'Contact request - Feedback - Other',
      caseType,
      caseRecordType,
      caseCategory,
      caseSubCategory,
      onSubmitSuccess: () => {
        history.push(PATH_HUB_CR_SUBMIT_CASE_SUCCESS, {
          firstName: student,
          studentId: studentSfid,
          message: `Your request for an enrolment change in ${student}'s ${subject} has been submitted successfully. We'll be in touch soon.`,
        });
      },
    };

    if (isPauseOutsidePolicy) {
      requestPayload.endDate = DatesUtils.convertToSydneyMidnight(moment()).utc().toISOString();
    }

    submit(requestPayload);
  };

  return (
    <ContactFormLayout pageTitle={pageTitle} selectedEnrolment={selectedEnrolment}>
      <div className="px-5 pt-5 lg:px-20 lg:pt-12">
        <FormSubHeading />
        {submitError && errorMsg && <ErrorBlock errorMsg={errorMsg} />}
        <form>
          <TextArea {...textAreaProps} className="mb-7" />
        </form>
        <div className="mb-4 text-center tracking-normal lg:mb-8">
          <RestrictedButton
            buttonText="Request change"
            truthyChecks={[message.length > 0, !isSubmitting]}
            onClick={() => {
              onClickHandler();
            }}
          />
        </div>
      </div>
    </ContactFormLayout>
  );
};

FormOtherEnrolmentChangeRequest.propTypes = {
  selectedEnrolment: PropTypes.shape({
    enrolmentId: PropTypes.string.isRequired,
    studentSfid: PropTypes.string.isRequired,
    student: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
  }).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitError: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    return {
      isSubmitting: state.ui.apiState.hubContactPage.isLoading,
      submitError: state.ui.apiState.hubContactPage.fetchError,
      errorMsg: state.ui.apiState.hubContactPage.errorMsg,
    };
  },
  (dispatch) => {
    return {
      submit: bindActionCreators(submitCaseToSF, dispatch),
    };
  }
)(withSelectedEnrolment(FormOtherEnrolmentChangeRequest, null, PATH_HUB_CR_OTHER_ENROLMENT_CHANGE));
