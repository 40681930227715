import env from '../env';
import { srLogin } from './authActions';

export const startup = () => (dispatch) => {
  const tokenMatch = document.URL.match(/token=([^&#]*)/);
  let tokenParam = tokenMatch && tokenMatch[1];
  if (!tokenParam && env.REACT_APP_DEFAULT_TOKEN) {
    tokenParam = env.REACT_APP_DEFAULT_TOKEN;
  }
  dispatch(srLogin(tokenParam));
};
