import moment from 'moment';

import {
  CARD_TYPE_SESSION,
  CARD_TYPE_PRACTICE,
  CARD_TYPE_FEEDBACK,
  CARD_TYPE_TEST_SIMULATION,
  CARD_TYPE_GROUP,
  CARD_TYPE_STRAND, CARD_TYPE_KICK_OFF,
} from '../util/constants';

export function getNextActivity(state) {
  return state.nextSession;
}

export function getCurrentPractices(state) {
  const nextActivity = getNextActivity(state) || {};
  const activitiesWithoutLatest = state.activities.filter(a => a.sessionId !== nextActivity.sessionId);
  const currentPractices = [];
  let iterate = true;
  activitiesWithoutLatest.forEach((ac) => {
    if (iterate && [CARD_TYPE_TEST_SIMULATION, CARD_TYPE_PRACTICE].includes(ac.type)) {
      currentPractices.push(ac);
    } else {
      iterate = false;
    }
  });
  return currentPractices;
}

export function getLatestPastSession(state) {
  return state.activities.find((a) => {
    return [CARD_TYPE_SESSION, CARD_TYPE_FEEDBACK, CARD_TYPE_GROUP, CARD_TYPE_STRAND, CARD_TYPE_KICK_OFF].includes(a.type)
      && (moment(a.startTime).unix() < moment().unix());
  });
}

export function getPastSessions(state) {
  const latestPastSession = getLatestPastSession(state);
  const currentPractices = getCurrentPractices(state);
  const currentPracticeIds = currentPractices.map(cp => cp.sessionId);
  return state.activities.filter((a) => {
    return (moment(a.startTime).unix() <= moment().unix())
    && a.sessionId !== (latestPastSession && latestPastSession.sessionId)
    && !currentPracticeIds.includes(a.sessionId);
  });
}
