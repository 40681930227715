import { useQuery } from '@tanstack/react-query';
import { GET_AVAILABLE_TIME_SLOTS_OF_TUTOR_QKEY } from '../../util/constants/queryKeys';
import { TutorAvailabilitiesService } from '../../services/TutorAvailabilitiesService';

interface GetAvailableTimeSlotsOfTutorParams {
  tutorId: string;
  rescheduleFrom: string;
  rescheduleTo: string;
  studentTimeZone: string;
}

export const useGetAvailableTimeSlotsOfTutor = (params: GetAvailableTimeSlotsOfTutorParams) => {
  const { rescheduleTo, tutorId } = params;

  return useQuery({
    queryKey: [GET_AVAILABLE_TIME_SLOTS_OF_TUTOR_QKEY, rescheduleTo, tutorId],
    queryFn: () => TutorAvailabilitiesService.getAvailableTimeSlotsOfTutor(params),
    staleTime: 300000,
    enabled: !!rescheduleTo
  });
}