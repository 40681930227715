import { Button, Container } from '@cluey/cluey-components';
import moment from 'moment';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
	changeSession,
	fetchSessionTutor as fetchSessionTutorAction,
} from '../../../actions/hub/hubEnrolmentManagementActions';
import EnrolmentLayout from '../../../common/hub/EnrolmentLayout';
import InPageLoader from '../../../common/InPageLoader';
import { FormFlowProps } from '../../../components/FormFlow/FormFlow';
import mapStudentInformationToTutorDetails from '../../../components/TutorInfo/mapStudentInformationToTutorDetails';
import { useEnrolmentDetailQuery } from '../../../hooks/queries/useEnrolmentQuery';
import { TTBM_CUSTOMER_NUMBER } from '../../../util/constants';
import { useActions } from '../../../util/hooks/useActions';
import { PATH_HUB_HOME } from '../../../util/pagePath';
import { useRescheduleSessionState } from './useRescheduleSessionState';
import TutorDetailsCompact from '../../../components/TutorInfo/TutorDetailsCompact';
import { NewSessionSchedule } from '../../../common/NewSessionSchedule';
import { ErrorMsg } from '../../../common/Error';
import { useCheckTimeslotAvailability } from '../../../hooks/mutations/useCheckTimeslotAvailability';
import { useUpdateRescheduleRequestStatus } from '../../../hooks/mutations/useUpdateRescheduleRequestStatus';
import noTutorAvatar from '../../../assets/images/no-tutor-avatar.png';
import { getTimezone } from '../../../selectors/hubSelector';
import { setTutorTypeDefault } from '../../../util/helpers';
import confirmReschedule from './confirmReschedule';

const HubRescheduleSessionConfirmationPage = (props) => {
	const { formFlowProps } = props;
	const history = useHistory();
  const { location: { state } } = history;
	const { previous, next } = formFlowProps;
	const {
		scheduleDate,
		tutorNumber,
		sessionDuration,
		isTempTutor,
		tutorInitiated,
		tutorId: selectedTimeslotTutorId,
	} = state;
	const rescheduleSession = useActions(changeSession);

	const { timezone, sectionHeading, session, newScheduleTutor } = useRescheduleSessionState();
	const originalSessionDuration = moment(session?.endTime).diff(moment(session?.startTime), 'minutes');
	const { tutorPhoto, tutorName, tutorDescription } = newScheduleTutor ?? {};

	const fetchSessionTutor = useActions(fetchSessionTutorAction);
	const isFetchingSessionTutor = useSelector(
		(state) => state.ui.apiState.hubEnrolmentDetailPage.tempTutorChange.firstFetch
	);

	useEffect(() => {
		if (state?.tutorNumber) {
			fetchSessionTutor({
				tutorNumber: state.tutorNumber,
			});
		}
		return undefined;
	}, [state, fetchSessionTutor]);

	const { enrolmentId, tutorState, tutorType } = useSelector((state) => state.hubEnrolmentDetailPage);
	const studentTimeZone = useSelector(getTimezone);
	const { isLoading: isLoadingEnrolmentDetail, data } = useEnrolmentDetailQuery({ enrolmentId });
	const enrolmentDetail = data || {};
	const { studentInformation, englishTextStudied } = enrolmentDetail;
	const studentName = enrolmentDetail?.studentName || '';
	const { skilledAt, highlyValuedFor } = mapStudentInformationToTutorDetails(studentInformation);
	const scheduleEndDate = moment(scheduleDate).add(sessionDuration, 'minutes').toISOString();

	const goBackToTutorAvailabilityScreen = () => {
		previous();
	};

	useEffect(() => {
		if (!scheduleDate) {
		history.push(PATH_HUB_HOME);
		}
	}, [scheduleDate, previous, history]);

	const [isLoading, setIsLoading] = useState(false);
	const [submitError, setSubmitError] = useState('');
	const [timeslotAvailabilityError, setTimeslotAvailabilityError] = useState('');
	const onSuccess = () => {

		next({
			...state,
			scheduleDate,
			sessionDuration,
			isTempTutor,
		});
		setIsLoading(false);
	};

	const onError = () => {
		setIsLoading(false);
		setSubmitError('Something went wrong. Please try again later.');
	};
	const updateRequestStatus = () => {
		const updateRequestStatusPayload = {
			studentSessionId: session.studentSessionId,
			enrolmentId,
			requestStatus: 'Rejected',
		};
		mutate(updateRequestStatusPayload);
	};
	const createESSLCase = () => {
		rescheduleSession({
			enrolmentId,
			sessionId: session.studentSessionId,
			startDate: scheduleDate,
			endDate: scheduleEndDate,
			customerNumber: tutorNumber,
			onSuccess,
			onError,
		});
	};
	const { mutate } = useUpdateRescheduleRequestStatus({
		onSettled: onSuccess,
		onError,
	});
	const { mutate: validateTimeslotAvailability } = useCheckTimeslotAvailability({
		onSuccess: (data) => {
			if (data && data.tutorsAvailable.find((tutor) => tutor.tutorId === selectedTimeslotTutorId)) {
				confirmReschedule({
					submitError,
					setSubmitError,
					tutorNumber,
					setIsLoading,
					shouldUpdateRequestStatus: tutorInitiated && isTempTutor,
					updateRequestStatus,
					createESSLCase,
				});
			} else {
				setIsLoading(false);
				setTimeslotAvailabilityError('The selected timeslot is no longer available. Please choose a different time.');
			}
		},
		onError,
	});
	const onConfirm = () => {
		setIsLoading(true);
		/* 
        No need to validate tutor availability during the timeslot if isTempTutor is true
          - isTempTutor is only set to true when the customer presses the "Allocate a temporary tutor and keep the session time" 
            CTA for tutor-initiated reschedule requests.
          - isTempTutor is set to false when the customer selects a timeslot (regardless if it is the package tutor or a different tutor) 
        */
		if (isTempTutor) {
			confirmReschedule({
				submitError,
				setSubmitError,
				tutorNumber,
				setIsLoading,
				shouldUpdateRequestStatus: tutorInitiated,
				updateRequestStatus,
				createESSLCase,
			});
		} else {
			const payload = {
				tutorId: selectedTimeslotTutorId,
				rescheduleFrom: scheduleDate,
				rescheduleTo: scheduleEndDate,
				studentTimeZone,
				tutorSessionId: session.tutorSessionId,
				tutorInitiated,
				sessionDuration,
			};
			validateTimeslotAvailability(payload);
		}
	};
	const isMatchingTutor = state?.tutorNumber === TTBM_CUSTOMER_NUMBER;

	if (isLoading || isFetchingSessionTutor || isLoadingEnrolmentDetail) {
		return <InPageLoader />;
	}

	return (
		<EnrolmentLayout
			sectionHeading={sectionHeading}
			mainHeading={isTempTutor ? 'Keep session with a temporary tutor' : `Reschedule this session with ${tutorName}`}
			showEnrolmentStatus={false}
			showSummerModeEnrolmentStatus={false}
		>
			<Container>
				<h3 className="pb-6 text-xl">Please review and confirm{!isTempTutor && ' the rescheduled session time'}:</h3>
				<div className="flex flex-col overflow-hidden rounded-lg border border-grey-2 bg-white lg:flex-row">
					<div className="px-6 py-7 lg:w-7/12 lg:px-14 lg:py-12">
						<div className="mb-10 flex flex-col items-start">
							{isTempTutor && tutorInitiated ? (
								<NewSessionSchedule
									title="Session"
									scheduleDate={session?.startTime}
									sessionDuration={originalSessionDuration}
									timezone={timezone}
									goBackToTutorAvailabilityScreen={goBackToTutorAvailabilityScreen}
									changeLabel={`Reschedule with ${tutorName}`}
								/>
							) : (
								<>
									<div className="border-1 mb-8 w-full border-b border-grey-2">
										<NewSessionSchedule
											title="Original session time"
											scheduleDate={session?.startTime}
											sessionDuration={originalSessionDuration}
											timezone={timezone}
											goBackToTutorAvailabilityScreen={goBackToTutorAvailabilityScreen}
											disabled
										/>
									</div>
									<div className="mt-4  w-full">
										<NewSessionSchedule
											title="One-off session time"
											scheduleDate={scheduleDate}
											sessionDuration={sessionDuration}
											timezone={timezone}
											goBackToTutorAvailabilityScreen={goBackToTutorAvailabilityScreen}
										/>
									</div>
								</>
							)}
							{submitError && (
								<div className="px-2 py-4 lg:pb-0 lg:pl-0">
									<ErrorMsg text={submitError} />
								</div>
							)}
							{timeslotAvailabilityError && (
								<div className="px-2 py-4 lg:pb-0 lg:pl-0">
									<ErrorMsg text={timeslotAvailabilityError} />
								</div>
							)}
							<Button
								disabled={timeslotAvailabilityError}
								size="lg"
								className="w-full lg:mt-5 lg:w-auto"
								onClick={onConfirm}
							>
								{tutorInitiated && isTempTutor ? 'Confirm' : 'Confirm reschedule'}
							</Button>
						</div>
					</div>
					<div className="border-t border-grey-2 bg-slate-1 px-6 py-7 lg:w-5/12 lg:border-l lg:border-t-0 lg:px-14 lg:py-12">
						<div className="mb-6 flex items-center border-b border-grey-2 pb-6 lg:mb-7 lg:pb-7">
							<img
								src={isTempTutor ? noTutorAvatar : `${tutorPhoto}?v=1`}
								alt="tutor avatar"
								className="mr-lg-6 mr-5 h-[60px] w-[60px] rounded-full border border-grey-3"
							/>
							<div className="flex flex-col">
								<span className="lh-1-33 mb-1 text-lg font-bold">{isTempTutor ? 'Temporary tutor' : tutorName}</span>
								<span className="lh-1-33">
									{tutorState} {setTutorTypeDefault({ tutorType })}
								</span>
							</div>
						</div>
						{isTempTutor && tutorInitiated && (
							<div>
								{`We'll allocate a temporary tutor for ${
									studentName ? studentName + "'s" : 'your'
								} upcoming session. Once we've allocated a tutor, we'll send you an email with their details included.`}
							</div>
						)}
						{!isMatchingTutor && !isTempTutor && (
							<>
								<TutorDetailsCompact
									highlyValuedFor={highlyValuedFor}
									skilledAt={skilledAt}
									englishTextStudied={englishTextStudied}
								/>
								<div className="mb-4">
									<span className="lh-1 mb-3 text-xs font-bold uppercase tracking-wider">
										A LITTLE ABOUT {tutorName}…
									</span>
									<p className="lh-1-67 text-xs">{tutorDescription}</p>
								</div>
							</>
						)}
					</div>
				</div>
			</Container>
		</EnrolmentLayout>
	);
};

HubRescheduleSessionConfirmationPage.propTypes = {
	formFlowProps: FormFlowProps.isRequired,
};

export default HubRescheduleSessionConfirmationPage;
