import { Container } from '@cluey/cluey-components';
import EnrolmentLayout from '../../../../../common/hub/EnrolmentLayout';
import { PATH_HUB_CR_REPORT_ISSUE } from '../../../../../util/pagePath';
import { useSelectEnrolmentModal } from '../../../../../components/Modals/SelectEnrolmentModal/SelectEnrolmentModal';
import { getReportIssueMenuOptions } from './helpers';
import { useHistory } from 'react-router';
import { IntentIntro } from '../../../../../components/IntentIntro';
import { OptionCard, OptionCardContent } from '../../../../../components/EnrolmentManagement/OptionCard';

function ManageServiceReportAnIssue() {
  const history = useHistory();
  const enrolmentToken = new URLSearchParams(history.location.search).get('token');
  const { SelectEnrolmentModal } = useSelectEnrolmentModal();
  const choices = getReportIssueMenuOptions();
  return (
    <EnrolmentLayout
      sectionHeading="Home"
      mainHeading="Report an issue"
      showEnrolmentStatus={false}
      showSummerModeEnrolmentStatus={false}
    >
      <SelectEnrolmentModal
        enrolmentLinkTo={(enrolment) => `${PATH_HUB_CR_REPORT_ISSUE}?token=${enrolment.enrolmentId}`}
        open={!enrolmentToken}
      />
      <Container>
        <IntentIntro>Report any issues to help us improve our service.</IntentIntro>
        <ul className="list-none">
          {choices.map(({ text, to, element }) => {
            return (
              <li key={text}>
                <OptionCard linkTo={`${to}?token=${enrolmentToken}`} type={element}>
                  <OptionCardContent>{text}</OptionCardContent>
                </OptionCard>
              </li>
            );
          })}
        </ul>
      </Container>
    </EnrolmentLayout>
  );
}

export default ManageServiceReportAnIssue;
