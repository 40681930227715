import { useMutation } from "@tanstack/react-query";
import SessionService from '../../services/SessionService';
import { UpdateRequestStatusPayload } from "../../types/hubTypes";

interface Params {
  payload: UpdateRequestStatusPayload;
  onSuccess: () => void;
  onError?: () => void;
  onSettled?: () => void;
}

export const useUpdateRescheduleRequestStatus = (params: Params) => {
  const { onSuccess, onError, onSettled } = params;

  return useMutation({
    mutationFn: async (payload: UpdateRequestStatusPayload) => {
			const reply = await SessionService.updateRescheduleRequestStatus(payload);

			return reply.data;
		},
    onSuccess,
    onError,
    onSettled,
  })
}