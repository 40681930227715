import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { STUDENT_PROGRESS_TAB_VIEWS } from '../../util/constants';
import styles from '../../scss/StudentProgress/TabbedViewController.module.scss';

const createIsActiveTab = (tab) => (activeTab) => activeTab === tab;

const TabbedViewController = ({ activeTab, setActiveTab }) => {
	const isProgressOverviewViewActive = createIsActiveTab(STUDENT_PROGRESS_TAB_VIEWS.PROGRESS_OVERVIEW);
	const isSessionReportViewActive = createIsActiveTab(STUDENT_PROGRESS_TAB_VIEWS.SESSION_REPORT);

	const baseClasses = `px-5 py-5 lg:py-5 fs-14px font-bold ${styles.tabBtn}`;
	const btnPOverviewClasses = classNames(
		baseClasses,
		'mr-1',
		{ [`text-grey-6 ${styles.tabBtnActive}`]: isProgressOverviewViewActive(activeTab) },
		{ 'text-primary': !isProgressOverviewViewActive(activeTab) }
	);
	const btnSReportClasses = classNames(
		baseClasses,
		'mr-1',
		{ [`text-grey-6 ${styles.tabBtnActive}`]: isSessionReportViewActive(activeTab) },
		{ 'text-primary': !isSessionReportViewActive(activeTab) }
	);

	return (
		<div className={`lg:ml-1/12 flex w-full justify-center lg:w-2/3 lg:justify-end ${styles.tabs}`}>
			<button
				type="button"
				className={btnPOverviewClasses}
				onClick={() => {
					setActiveTab(STUDENT_PROGRESS_TAB_VIEWS.PROGRESS_OVERVIEW);
				}}
			>
				Progress overview
			</button>
			<button
				type="button"
				className={btnSReportClasses}
				onClick={() => {
					setActiveTab(STUDENT_PROGRESS_TAB_VIEWS.SESSION_REPORT);
				}}
			>
				Session reports
			</button>
		</div>
	);
};

TabbedViewController.propTypes = {
	activeTab: PropTypes.string.isRequired,
	setActiveTab: PropTypes.func.isRequired,
};

export default TabbedViewController;
