import { z } from 'zod';

export const Holiday = z.object({
	endDate: z.string(),
	schoolTerm: z.string().nullable(),
	startDate: z.string(),
	state: z.string(),
	type: z.enum(['Public Holiday', 'Cluey Holiday', 'School Term', 'Summer Alert']),
});
export type Holiday = z.infer<typeof Holiday>;

export const SchoolTerm = z.object({
	isTermStartDate: z.boolean().optional(),
	startTime: z.string().optional(),
	endDate: z.string().optional(),
	schoolTerm: z.string().nullable(),
	state: z.string(),
	startDate: z.string().optional(),
	type: z.enum(['Public Holiday', 'Cluey Holiday', 'School Term', 'Summer Alert']),
});
export type SchoolTerm = z.infer<typeof SchoolTerm>;
