import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import { ButtonAsLink } from '@cluey/cluey-components';
// Need to import the correct action
import { resendSignupLink } from '../../../actions/hub/hubAuthPagesActions';
import { PATH_HUB_RESEND_SIGNUP_LINK_REQ_SENT } from '../../../util/pagePath';

const ResendSignupLink = ({
  btnClasses,
  btnText,
  email,
  resendSignupLinkRequest,
}) => {
  const history = useHistory();

  const onResendRequest = () => {
    resendSignupLinkRequest({
      email,
      onSuccess: () => {
        history.push(PATH_HUB_RESEND_SIGNUP_LINK_REQ_SENT);
      },
    });
  };

  return (
    <ButtonAsLink
      className={btnClasses}
      onClick={onResendRequest}
    >
      {btnText}
    </ButtonAsLink>
  );
};

ResendSignupLink.propTypes = {
  btnClasses: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  resendSignupLinkRequest: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    return {
      email: state.hubUser.email,
    };
  },
  (dispatch) => {
    return {
      resendSignupLinkRequest: bindActionCreators(resendSignupLink, dispatch),
    };
  },
)(ResendSignupLink);
