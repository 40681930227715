import * as TITLES from './pageTitle';
import * as PATHS from './pagePath';

export const PATH_TO_TITLE_MAPPING = {
	[PATHS.PATH_UPCOMING_SESSIONS]: TITLES.PAGE_NAME_UPCOMING_SESSIONS,
	[PATHS.PATH_RESCHEDULE_SESSION]: TITLES.PAGE_TITLE_RESCHEDULE_SESSION,
	[PATHS.PATH_RESCHEDULE_SESSION_OLD]: TITLES.PAGE_TITLE_RESCHEDULE_SESSION,
	[PATHS.PATH_HUB_BASE]: TITLES.PAGE_TITLE_HUB_HOME,
	[PATHS.PATH_HUB_HOME]: TITLES.PAGE_TITLE_HUB_HOME,
	[PATHS.PATH_HUB_SESSIONS_PAGE]: TITLES.PAGE_TITLE_HUB_UPCOMING_SESSIONS,
	[PATHS.PATH_HUB_UPCOMING_SESSIONS]: TITLES.PAGE_TITLE_HUB_UPCOMING_SESSIONS,
	[PATHS.PATH_HUB_COMPLETED_SESSIONS]: TITLES.PAGE_TITLE_HUB_COMPLETED_SESSIONS,
	[PATHS.PATH_HUB_ENROLMENTS_PAGE]: TITLES.PAGE_TITLE_HUB_ENROLMENTS,
	[PATHS.PATH_HUB_ENROLMENTS]: TITLES.PAGE_TITLE_HUB_ENROLMENTS,
	[PATHS.PATH_HUB_ACCOUNT_PAGE]: TITLES.PAGE_TITLE_HUB_ACCOUNT_DETAILS,
	[PATHS.PATH_HUB_ACCOUNT_DETAILS]: TITLES.PAGE_TITLE_HUB_ACCOUNT_DETAILS,
	[PATHS.PATH_HUB_BILLING_INFORMATION]: TITLES.PAGE_TITLE_HUB_INVOICE_HISTORY,
	[PATHS.PATH_HUB_INVOICE_HISTORY]: TITLES.PAGE_TITLE_HUB_INVOICE_HISTORY,
	[PATHS.PATH_HUB_SIGN_UP]: TITLES.PAGE_TITLE_HUB_SIGN_UP,
	[PATHS.PATH_HUB_SIGN_UP_CONFIRMATION]: TITLES.PAGE_TITLE_HUB_SIGN_UP_CONFIRMATION,
	[PATHS.PATH_HUB_LOG_IN]: TITLES.PAGE_TITLE_HUB_LOG_IN,
	[PATHS.PATH_HUB_RESET_PASSWORD_REQUEST]: TITLES.PAGE_TITLE_HUB_RESET_PASSWORD_REQUEST,
	[PATHS.PATH_HUB_RESET_PASSWORD_REQ_SENT]: TITLES.PAGE_TITLE_HUB_RESET_PASSWORD_REQ_SENT,
	[PATHS.PATH_HUB_RESEND_SIGNUP_LINK_REQUEST]: TITLES.PAGE_TITLE_HUB_RESEND_SIGNUP_LINK_REQUEST,
	[PATHS.PATH_HUB_RESEND_SIGNUP_LINK_REQ_SENT]: TITLES.PAGE_TITLE_HUB_RESEND_SIGNUP_LINK_REQ_SENT,
	[PATHS.PATH_HUB_SET_NEW_PASSWORD]: TITLES.PAGE_TITLE_HUB_SET_NEW_PASSWORD,
	[PATHS.PATH_HUB_SET_PASSWORD]: TITLES.PAGE_TITLE_HUB_SET_NEW_PASSWORD,
	[PATHS.PATH_HUB_NEW_PASSWORD_SUCCESS]: TITLES.PAGE_TITLE_HUB_NEW_PASSWORD_SUCCESS,
	[PATHS.PATH_HUB_STAFF_LOGIN]: TITLES.PAGE_TITLE_HUB_STAFF_LOGIN,
	[PATHS.PATH_HUB_CR_PAUSE_SESSIONS]: TITLES.PAGE_TITLE_HUB_CR_PAUSE_SESSIONS,
	[PATHS.PATH_HUB_POLICY]: TITLES.PAGE_TITLE_HUB_POLICY,
	[PATHS.PATH_HUB_SERVICE_FEES]: TITLES.PAGE_TITLE_HUB_SERVICE_FEES,
	[PATHS.PATH_HUB_CR_PERMANENT_SCHEDULE_CHANGE]: TITLES.PAGE_TITLE_HUB_CR_PERMANENT_SCHEDULE_CHANGE,
	[PATHS.PATH_HUB_CR_REQUEST_OPTIONS]: TITLES.PAGE_TITLE_HUB_CR_REQUEST_OPTIONS,
	[PATHS.PATH_HUB_CR_CHANGE_TUTOR]: TITLES.PAGE_TITLE_HUB_CR_CHANGE_TUTOR,
	[PATHS.PATH_HUB_CR_CANCEL_ENROLMENT]: TITLES.PAGE_TITLE_HUB_CR_CANCEL_ENROLMENT,
	[PATHS.PATH_HUB_CR_OTHER_ENROLMENT_CHANGE]: TITLES.PAGE_TITLE_HUB_CR_OTHER_ENROLMENT_CHANGE,
	[PATHS.PATH_HUB_CR_FEEDBACK]: TITLES.PAGE_TITLE_HUB_CR_FEEDBACK,
	[PATHS.PATH_HUB_CR_OPT_IN_OUT]: TITLES.PAGE_TITLE_HUB_CR_OPT_IN,
	[PATHS.PATH_HUB_CR_RESCHEDULE_SESSION]: TITLES.PAGE_TITLE_HUB_CR_RESCHEDULE_SESSION,
	[PATHS.PATH_HUB_CR_SKIP_SESSION]: TITLES.PAGE_TITLE_HUB_CR_SKIP_SESSION,
	[PATHS.PATH_HUB_CR_PAYMENT_QUERY]: TITLES.PAGE_TITLE_HUB_CR_PAYMENT_QUERY,
	[PATHS.PATH_HUB_CR_OTHER_QUERY]: TITLES.PAGE_TITLE_HUB_CR_OTHER_QUERY,
	[PATHS.PATH_HUB_CR_TECHNICAL_ISSUE]: TITLES.PAGE_TITLE_HUB_CR_TECHNICAL_ISSUE,
	[PATHS.PATH_HUB_CR_ADD_ENROLMENT]: TITLES.PAGE_TITLE_HUB_CR_ADD_ENROLMENT,
	[PATHS.PATH_HUB_CR_REQUEST_RECORDING]: TITLES.PAGE_TITLE_HUB_CR_REQUEST_RECORDING,
	[PATHS.PATH_HUB_CR_CHANGE_CONTENT_FOCUS]: TITLES.PAGE_TITLE_HUB_CR_CHANGE_CONTENT_FOCUS,
	[PATHS.PATH_HUB_CR_SWITCH_LEARNING_MODE]: TITLES.PAGE_TITLE_HUB_CR_SWITCH_LEARNING_MODE,
	[PATHS.PATH_HUB_CR_ADD_SESSION]: TITLES.PAGE_TITLE_HUB_CR_ADD_SESSION,
	[PATHS.PATH_HUB_NO_ACTIVE_ENROLMENTS]: TITLES.PAGE_TITLE_HUB_NO_ACTIVE_ENROLMENTS,
	[PATHS.PATH_HUB_ENROLMENT_DETAIL]: TITLES.PAGE_TITLE_HUB_ENROLMENT_DETAIL,
	[PATHS.PATH_HUB_ENROLMENT_CHANGE_SCHEDULE]: TITLES.PAGE_TITLE_HUB_ENROLMENT_CHANGE_SCHEDULE,
	[PATHS.PATH_HUB_ENROLMENT_NAPLAN]: TITLES.PAGE_TITLE_HUB_ENROLMENT_NAPLAN,
	[PATHS.PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_KEEP_PREV_TUTOR]:
		TITLES.PAGE_TITLE_HUB_ENROLMENT_CHANGE_SCHEDULE_KEEP_PREV_TUTOR,
	[PATHS.PATH_HUB_ENROLMENT_MANAGE_PAUSE]: TITLES.PAGE_TITLE_HUB_ENROLMENT_MANAGE_PAUSE,
	[PATHS.PATH_HUB_TTC_CHANGE_SESSION_KEEP_TUTOR]: TITLES.PAGE_TITLE_HUB_TTC_CHANGE_SESSION_KEEP_TUTOR,
	[PATHS.PATH_SCHOOL_BASE]: TITLES.PAGE_TITLE_SCHOOL_BASE,
	[PATHS.PATH_SCHOOL_UPCOMING_SESSIONS]: TITLES.PAGE_TITLE_SCHOOL_UPCOMING_SESSIONS,
	[PATHS.PATH_HUB_MID_YEAR_PROGRESS]: TITLES.PAGE_TITLE_HUB_MID_YEAR_PROGRESS,
	[PATHS.PATH_HUB_SELECT_SESSION]: TITLES.PAGE_TITLE_HUB_SELECT_SESSION,
	[PATHS.PATH_HUB_RESCHEDULE_SESSION]: TITLES.PAGE_TITLE_HUB_RESCHEDULE_A_SESSION,
	[PATHS.PATH_HUB_SKIP_SESSION]: TITLES.PAGE_TITLE_HUB_SKIP_A_SESSION,
	[PATHS.PATH_NEW_YEAR_ENROLMENT_DETAIL]: TITLES.PAGE_TITLE_NEW_YEAR_ENROLMENT_DETAIL,
	[PATHS.PATH_HUB_MS_SESSION_TIMES]: TITLES.PAGE_TITLE_HUB_MS_SESSION_TIMES,
	[PATHS.PATH_HUB_MS_LEARNING_ADJUSTMENTS]: TITLES.PAGE_TITLE_HUB_MS_LEARNING_ADJUSTMENTS,
	[PATHS.PATH_HUB_MS_BILLING_AND_PAYMENTS]: TITLES.PAGE_TITLE_HUB_MS_BILLING_AND_PAYMENTS,
	[PATHS.PATH_HUB_MS_STUDENTS_AND_SUBJECTS]: TITLES.PAGE_TITLE_HUB_MS_STUDENTS_AND_SUBJECTS,
	[PATHS.PATH_HUB_SUPPORT]: TITLES.PAGE_TITLE_HUB_SUPPORT,
	[PATHS.PATH_HUB_ADD_ONE_OFF_SESSION]: TITLES.PAGE_TITLE_HUB_ADD_ONE_OFF_SESSION,
	[PATHS.PATH_HUB_ADD_ONE_OFF_SESSION_CONFIRM]: TITLES.PAGE_TITLE_HUB_ADD_ONE_OFF_SESSION_CONFIRM,
	[PATHS.PATH_HUB_ADD_ONE_OFF_SESSION_SUCCESS]: TITLES.PAGE_TITLE_HUB_ADD_ONE_OFF_SESSION_SUCCESS,
	[PATHS.PATH_HUB_STUDENT_PROFILE]: TITLES.PATH_TITLE_HUB_STUDENT_PROFILE,
	[PATHS.PATH_HUB_STUDENT_EDIT_PROFILE]: TITLES.PATH_TITLE_HUB_STUDENT_PROFILE_EDIT,
	[PATHS.PATH_HUB_ADD_STUDENT]: TITLES.PATH_TITLE_HUB_STUDENT_PROFILE_EDIT,
	[PATHS.PATH_HUB_VIEW_ASSESSMENT]: TITLES.PATH_TITLE_HUB_ASSESSMENT_VIEW,
	[PATHS.PATH_HUB_GENERATE_ASSESSMENT]: TITLES.PATH_TITLE_HUB_ASSESSMENT_GENERATE,
};
