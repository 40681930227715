import initialState from './initialState';
import {
  SM_FETCH_SUMMER_MODE_DATA_FAILED,
  SM_FETCH_SUMMER_MODE_DATA_SUCCESS,
} from '../actions/summerModeActionTypes';

export default function summerMode(state = initialState.summerMode, action) {
  const { type, payload } = action;
  switch (type) {
    case SM_FETCH_SUMMER_MODE_DATA_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case SM_FETCH_SUMMER_MODE_DATA_FAILED:
      return {};
    default:
      return state;
  }
}
