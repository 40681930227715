import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';

import withSelectedEnrolment from '../../../common/hub/NewWithSelectedEnrolment';
import ContactFormLayout from '../../../common/hub/ContactFormLayout';
import { useRadioGroupProps, RadioGroup } from '../../../components/RadioGroup/RadioGroup';
import { useTextAreaProps, TextArea } from '../../../components/TextArea/TextArea';
import { submitCaseToSF } from '../../../actions/hub/hubContactPageActions';
import FormSubHeading from '../../../components/hub/contact/FormSubHeading';
import { RestrictedButton } from '../../../components/RestrictedButton/RestrictedButton';
import ErrorBlock from '../../../common/ErrorBlock';
import { PATH_HUB_CR_SUBMIT_CASE_SUCCESS, PATH_HUB_CR_CHANGE_TUTOR } from '../../../util/pagePath';

const groupName = 'contact-form-change-tutor';
const radios = [
  {
    text: 'Tutor was not the right fit',
    value: 'Tutor was not the right fit',
    key: `${groupName}-category-1`,
  },
  {
    text: 'Tutor went too slow/fast',
    value: 'Tutor went too slow/fast',
    key: `${groupName}-category-2`,
  },
  {
    text: 'Tutor is no longer available',
    value: 'Tutor is no longer available',
    key: `${groupName}-category-3`,
  },
  {
    text: 'Other',
    value: 'Other',
    key: `${groupName}-category-4`,
  },
];

const FormChangeTutor = ({ selectedEnrolment, isSubmitting, submitError, errorMsg, submit }) => {
  const radioGroupProps = useRadioGroupProps({
    groupName,
    radios,
    labelText: 'Select a reason for changing your tutor',
  });
  const textAreaProps = useTextAreaProps({
    id: `${groupName}-textarea`,
    labelText: 'Is there anything else you’d like to tell us?',
  });

  const { enrolmentId, studentSfid, student, subject } = selectedEnrolment;
  const history = useHistory();
  const onClickHandler = () => {
    const caseType = 'Scheduling';
    const caseCategory = 'Tutor change';
    const caseSubCategory = radioGroupProps.selectedRadio;

    submit({
      uniqueEnrolmentId: enrolmentId,
      message: `Request for ${caseCategory} due to following reason: ${caseSubCategory}. Other details: ${textAreaProps.value}`,
      caseSubject: `Contact request - ${caseType} - ${caseCategory} - ${caseSubCategory}`,
      caseType,
      caseCategory,
      caseSubCategory,
      onSubmitSuccess: () => {
        history.push(PATH_HUB_CR_SUBMIT_CASE_SUCCESS, {
          studentId: studentSfid,
          firstName: student,
          message: `Your request to change the tutor for ${student}'s ${subject} enrolment has been submitted successfully. We'll be in touch soon.`,
        });
      },
    });
  };

  return (
    <ContactFormLayout selectedEnrolment={selectedEnrolment}>
      <div className="px-5 pt-5 lg:px-20 lg:pt-12">
        <FormSubHeading />
        {submitError && errorMsg && <ErrorBlock errorMsg={errorMsg} />}
        <form>
          <RadioGroup {...radioGroupProps} className="mb-9" />
          <TextArea {...textAreaProps} className="mb-7" />
          <div className="mb-4 text-center tracking-normal lg:mb-8">
            <RestrictedButton
              buttonText="Request change"
              truthyChecks={[!!radioGroupProps.selectedRadio, !isSubmitting]}
              onClick={onClickHandler}
            />
          </div>
        </form>
      </div>
    </ContactFormLayout>
  );
};

FormChangeTutor.propTypes = {
  selectedEnrolment: PropTypes.shape({
    enrolmentId: PropTypes.string.isRequired,
    studentSfid: PropTypes.string.isRequired,
    student: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
  }).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitError: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    return {
      isSubmitting: state.ui.apiState.hubContactPage.isLoading,
      submitError: state.ui.apiState.hubContactPage.fetchError,
      errorMsg: state.ui.apiState.hubContactPage.errorMsg,
    };
  },
  (dispatch) => {
    return {
      submit: bindActionCreators(submitCaseToSF, dispatch),
    };
  }
)(withSelectedEnrolment(FormChangeTutor, null, PATH_HUB_CR_CHANGE_TUTOR));

export { FormChangeTutor as StatelessFormChangeTutor };
