import { useEffect } from 'react';
import { useParams } from 'react-router';
import { api } from '../../api';
import HubPageLayout from '../../common/hub/HubPageLayout';
import { FullPageLoader } from '../../components/Loader/Loader';
import ErrorPage from '../../components/routing/ErrorPage';
import PageNotFound from '../../components/routing/PageNotFound';
import { useRocketChatContext } from '../../util/RocketChat/RocketChatProvider';
import Layout from './Layout';
import { VirtualCoach } from './VirtualCoach';
import { AiTutorContextProvider } from './useAiTutorContext';
import { useFeatureFlagEnabled } from 'posthog-js/react';

function useEnrolment(enrolmentId?: string) {
  const { data, isLoading, isError, isSuccess } = api.enrolment.all.useQuery('active');
  const enrolment = data?.find((enrolment) => enrolment.enrolmentId === enrolmentId);

  return {
    enrolment,
    isLoading,
    isError,
    isSuccess,
  };
}

const LandingPage = () => {
  const clueyCoachEnabled = useFeatureFlagEnabled('cluey-coach-enabled');
  const { enrolmentId } = useParams<{ enrolmentId: string }>();
  const { enrolment, isError, isLoading, isSuccess } = useEnrolment(enrolmentId);
  const { hideWidget, showWidget } = useRocketChatContext();

  useEffect(() => {
    hideWidget();

    return () => {
      showWidget();
    };
  });

  if (isLoading) {
    return <FullPageLoader />;
  } else if (isError) {
    return (
      <HubPageLayout>
        <ErrorPage
          title="Network error"
          description="There was a problem while attempting to retrieve data from the server. Please try again later."
          buttonLabel="Back to home"
        />
      </HubPageLayout>
    );
  } else if (!clueyCoachEnabled || (isSuccess && (!enrolment || !enrolment.aiTutor?.allowed))) {
    return (
      <HubPageLayout>
        <PageNotFound />
      </HubPageLayout>
    );
  }

  return (
    <Layout>
      <AiTutorContextProvider enrolment={enrolment}>
        <VirtualCoach />
      </AiTutorContextProvider>
    </Layout>
  );
};

export default LandingPage;
