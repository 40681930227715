import React from 'react';
import PropTypes from 'prop-types';

import HubHeader from './HubHeader';
import HubFooter from './HubFooter/HubFooter';

const HubBasePageLayout = ({ children }) => {
  return (
    <div className="flex flex-col text-grey-6">
      <HubHeader title="Hub" />
      {children}
      <HubFooter />
    </div>
  );
};

HubBasePageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HubBasePageLayout;
