import Markdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import clueyLogoAvatar from '../../../../assets/images/virtualCoach/cluey-coach-bot-avatar.png';

import './Message.css';

export interface MessageProps {
  content: string;
  attachment?: string;
  sender?: 'user' | 'tutor';
}

const patchLatexForKaTeX = (latex) => {
  return latex
    .replace(/\\bigm\{\|}/g, '\\left.\\right|') // Replace \bigm{|}
    .replace(/\\dfrac/g, '\\frac'); // Optionally replace \dfrac with \frac
};

const Message = ({ content, sender = 'user', attachment }: MessageProps) => {
  if (sender === 'tutor') {
    return (
      <div className="flex flex-row space-x-3 self-start py-5">
        <img src={clueyLogoAvatar} alt="Cluey logo" className="h-6 w-6 rounded-full" />
        <div className="w-full self-start">
          <Markdown remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex]}>
            {content}
          </Markdown>
        </div>
      </div>
    );
  }
  return (
    <div className="w-full self-end rounded-xl bg-slate-1 px-5 py-3 md:w-fit md:max-w-[70%]">
      <Markdown remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex]}>
        {content ? `$${patchLatexForKaTeX(content)}$` : ''}
      </Markdown>
      {attachment && (
        <div className="mb-4 mt-3 max-h-[150px] max-w-[100px] md:max-h-[300px] md:max-w-[300px]">
          <a href={attachment} target="_blank" rel="noopener noreferrer" className="">
            <img className="object-contain" src={attachment} alt="attachment thumbnail" />
          </a>
        </div>
      )}
    </div>
  );
};

export default Message;
