import initialState from '../../initialState';
import * as actionTypes from '../../../actions/actionTypes';

export default function schoolsUpcomingSessions(state = initialState.ui.apiState.schoolsUpcomingSessions, action) {
  const { type } = action;
  switch (type) {
    case actionTypes.SCHOOLS_US_FETCH_HOUSEHOLD_START:
      return {
        ...state,
        firstFetch: true,
        isLoading: true,
        fetchError: false,
      };
    case actionTypes.SCHOOLS_US_FETCH_HOUSEHOLD_FAILED:
      return {
        ...state,
        firstFetch: true,
        isLoading: false,
        fetchError: true,
      };
    case actionTypes.SCHOOLS_US_FETCH_HOUSEHOLD_SUCCESS:
      return {
        ...state,
        firstFetch: true,
        isLoading: false,
        fetchError: false,
      };
    default:
      return state;
  }
}
