import { useEffect } from 'react';
import { scrollToTop } from '../helpers';

const useScrollToTop = () => {
	useEffect(() => {
		scrollToTop();
	}, []);
};

export default useScrollToTop;
