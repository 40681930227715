import { SCHOOL_TERM } from '../../types/hubEnums';
import moment from 'moment';
import { SchoolTerm } from '../../api/types/schoolTerm';
import { SchoolHolidayTerm } from '../../types/hubTypes';
import orderBy from 'lodash/orderBy';
import minBy from 'lodash/minBy';
import { SPECIAL_DATE_TYPE } from '../constants';

/* @TODO continue; tag corresponding selector as deprecated */
export function convertSchoolTerms({ terms }: { terms: Array<SchoolTerm> }): Array<SchoolTerm> {
  if (terms && terms.length > 0) {
    return terms
      .filter((term) => {
        return moment(term.endDate).isSameOrAfter(moment()) && term.type === 'School Term';
      })
      .map((st) => {
        return [
          {
            type: st.type,
            startTime: st.startDate,
            state: st.state,
            schoolTerm: st.schoolTerm,
            isTermStartDate: true,
          },
          {
            type: st.type,
            startTime: st.endDate,
            state: st.state,
            schoolTerm: st.schoolTerm,
            isTermStartDate: false,
          },
        ];
      })
      .flat();
  }

  return [];
}

export const getNearestTerm = ({
  upcomingTerms,
  term,
  order,
}: {
  upcomingTerms: Array<SchoolHolidayTerm>;
  term: SCHOOL_TERM;
  order: 'asc' | 'desc';
}) => {
  const allTerms = orderBy(
    upcomingTerms.filter((t) => t.schoolTerm === term),
    (term) => moment.utc(term.startDate).year(),
    [order]
  );
  return minBy(allTerms, (term) => Math.abs(moment.utc().diff(moment.utc(term.startDate), 'month')));
};

/**
 * Returns the current term based on the current date. If there is no ongoing term, returns undefined.
 *
 * @param {Type} upcomingTermsAndHolidays - The list of upcoming terms and holidays.
 * @param {Type} overrideCurrentDate - For testing only. Overrides the reference used for currentDate. If the date string is invalid this is effectively useless.
 * @returns {Type} School term
 */
export function getCurrentTerm(upcomingTermsAndHolidays: Array<SchoolHolidayTerm>, overrideCurrentDate?: string) {
  const upcomingSchoolTerms = upcomingTermsAndHolidays.filter((term) => {
    return (term.type || '').toLowerCase() === SPECIAL_DATE_TYPE.SCHOOL_TERM;
  });

  const currentDateOverride = overrideCurrentDate ? moment(overrideCurrentDate).utc() : moment().utc();
  const currentDate = currentDateOverride.isValid() ? currentDateOverride : moment().utc();
  const currentTerm = upcomingSchoolTerms.find((term) => {
    const startDate = moment(term.startDate);
    const endDate = moment(term.endDate);
    return currentDate.isBetween(startDate, endDate.add(1, 'days'));
  });
  return currentTerm;
}

/**
 * If there is no ongoing term, returns the next nearest term.
 * If the current term is term 4, returns term 4 of the next year.
 * Otherwise, returns the next term by incrementing the current term's schoolTerm property.
 *
 * @param {Type} upcomingTermsAndHolidays - The list of upcoming terms and holidays.
 * @param {Type} overrideCurrentDate - For testing only. Overrides the reference used for currentDate. If the date string is invalid this is effectively useless.
 * @returns {Type} School term
 */
export function getNextTerm(upcomingTermsAndHolidays: Array<SchoolHolidayTerm>, overrideCurrentDate?: string) {
  const currentTerm = getCurrentTerm(upcomingTermsAndHolidays, overrideCurrentDate);
  const upcomingSchoolTerms = upcomingTermsAndHolidays.filter((term) => {
    return (term.type || '').toLowerCase() === SPECIAL_DATE_TYPE.SCHOOL_TERM;
  });

  // if no current term, return the nearest term
  if (!currentTerm) {
    const currentDate = overrideCurrentDate ? moment(overrideCurrentDate).utc() : moment().utc();

    return minBy(upcomingSchoolTerms, (term) => Math.abs(currentDate.diff(moment.utc(term.startDate), 'days')));
    // if current term is term 4, return term 1 of the next year
  } else if (currentTerm.schoolTerm === SCHOOL_TERM.TERM_4) {
    const currentYear = moment(currentTerm.startDate).year();
    const nextYearTerm1 = upcomingSchoolTerms.find((term) => {
      return term.schoolTerm === SCHOOL_TERM.TERM_1 && moment(term.startDate).year() === currentYear + 1;
    });

    return nextYearTerm1;
    // else return the next term by incrementing the current term by 1
  } else {
    const nextTerm = upcomingSchoolTerms.find((term) => {
      const currentTermInt = parseInt(currentTerm.schoolTerm.split(' ')[1]); // gets the numerical value, "Term 1" -> 1, "Term 4" -> 4
      return term.schoolTerm === `Term ${currentTermInt + 1}`;
    });
    return nextTerm;
  }
}
