import { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import moment from 'moment';

import { RadioGroup, useRadioGroupProps } from '../../../../RadioGroup/RadioGroup';
import { DatePicker, useDatePickerProps } from '../../../../DatePicker/DatePicker';
import { ErrorMsg } from '../../../../../common/Error';
import { Loader } from '../../../../Loader/Loader';

import { savePauseFromResumeFromDate as savePauseFromResumeFromDateAction } from '../../../../../actions/hub/hubContactPageActions';
import { getSummerPromo } from '../../../../../selectors/summerModeSelector';
import { Alert, Button, Pending } from '@cluey/cluey-components';
import { getUpcomingTerms } from '../../../../../selectors/schoolTerms/schoolTermsSelectors';
import { PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT } from '../../../../../util/constants';
import { PATH_HUB_CR_PAUSE_SESSIONS } from '../../../../../util/pagePath';
import SummerPromoLegalCopy from '../../../SummerMode/SummerPromoLegalCopy';
import { TRACKING_PE } from '../../../../../util/trackingClasses';
import { InitialState } from '../../../../../types/hubTypes';
import { useGetSessionPolicy } from '../../../../../hooks/queries/useSessionQuery';
import { getSummerPauseMinAndMaxDates } from '../../../../../util/helper/pause';
import { getNearestTerm, getNextTerm } from '../../../../../util/helper/schoolTerm';
import { SCHOOL_TERM } from '../../../../../types/hubEnums';

export const SPECIFIC_RESUME_DATE = 'Specific date';
export const TERM4_PAUSE_SESSIONS_OPTIONS_VALUE = {
  beforeTerm: 'Before Term 1 (Week of Jan 17)',
  startTerm: 'Start of Term 1 (Week of Jan 31)',
  duringTerm: 'During Term 1 (Week of Feb 14)',
};
const groupName = 'contact-form-pause-session';

const SPECIFIC_PAUSE_START_DATE = 'Select date';

const RESUME_OPTIONS_GROUP = 'RESUME_OPTIONS_GROUP';

interface Props {
  student: string;
  enrolmentId: string;
  enrolmentSfid: string;
  isSubmitting: boolean;
  preventCancel?: boolean;
  submit(params: { startDate: string; endDate: string; summerModeHolidayPractice: boolean }): void;
  enrolmentPausedFrom: string;
  willBePaused: boolean;
}

const Term4SummerPause = ({
  enrolmentSfid,
  isSubmitting,
  preventCancel = false,
  submit,
  enrolmentPausedFrom,
  willBePaused,
}: Props) => {
  const {
    data: enrolmentPolicy,
    isLoading: pausePolicyLoading,
    error: policyError,
  } = useGetSessionPolicy({
    enrolmentId: enrolmentSfid,
  });
  const isFlexi =
    enrolmentPolicy?.policyName === 'Flexi' ||
    enrolmentPolicy?.policyName?.toLowerCase().includes('flexi') ||
    ['Flexi', 'Flexi 23', 'Flexi 24'].includes(enrolmentPolicy?.policyName);

  const schoolTerms = useSelector(getUpcomingTerms);
  const { minDate, maxPauseDate, maxDate } = getSummerPauseMinAndMaxDates({
    upcomingTerms: schoolTerms,
    enrolmentPolicy,
    schoolTerms,
    preventCancel,
  });

  const dispatch = useDispatch();
  const summerPromo = useSelector((state: InitialState) =>
    getSummerPromo(state, { screen: PATH_HUB_CR_PAUSE_SESSIONS })
  );

  const [pauseStartDate, setPauseStartDate] = useState(null);
  const [resumeExactDate, setResumeExactDate] = useState();

  const [validationError, setValidationError] = useState(null);
  const [pauseFromInputError, setPauseFromInputError] = useState(null);
  const [resumeFromInputError, setResumeFromInputError] = useState(null);

  const tomorrow = moment().add(1, 'day').format(PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT);
  const pauseDatePicker = useDatePickerProps({
    id: `${groupName}-datepicker`,
    placeholder: 'Select start date',
    options: {
      mode: 'single',
      minDate,
      maxDate: maxPauseDate,
    },
  });
  const pauseFromPicked = get(pauseDatePicker, ['value', '0']);

  const resumeMinOnPause = (function () {
    if (!!pauseStartDate) {
      return moment(pauseStartDate).add(1, 'day').format(PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT);
    } else {
      return !!enrolmentPausedFrom
        ? moment(enrolmentPausedFrom).add(1, 'day').format(PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT)
        : tomorrow;
    }
  })();

  const resumeDatePickerProps = useDatePickerProps({
    id: `${groupName}-resume-datepicker`,
    placeholder: 'Select resume date',
    options: {
      mode: 'single',
      minDate: resumeMinOnPause,
      maxDate,
    },
  });
  const resumeDatePicked = get(resumeDatePickerProps, ['value', '0']);

  const setPauseFromDateInputError = () => {
    pauseDatePicker.setHighlightClass('highlight');
    setPauseFromInputError('Please select a date');
  };

  const clearPauseFromDateInputError = () => {
    pauseDatePicker.setHighlightClass('');
    setPauseFromInputError('');
  };

  const setResumeFromDateInputError = (errorMsg = 'Please select a date') => {
    resumeDatePickerProps.setHighlightClass('highlight');
    setResumeFromInputError(errorMsg);
  };

  const clearResumeFromDateInputError = () => {
    resumeDatePickerProps.setHighlightClass('');
    setResumeFromInputError('');
  };

  const term4 = getNearestTerm({ upcomingTerms: schoolTerms, term: SCHOOL_TERM.TERM_4, order: 'desc' });
  const { endDate: term4EndDate } = term4;

  const startOfSummerHolidays = moment(term4EndDate).add(1, 'day').format(PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT);

  const selectedPauseFromOption = SPECIFIC_PAUSE_START_DATE;

  // Resume options
  const resumeOptions = [];
  // since we are in Term 4, getNextTerm() should return Term 1 of the next year
  const term1 = getNextTerm(schoolTerms);
  const { startDate: term1StartDate } = term1;

  const resumeOnStartOfTerm1 = moment(term1StartDate).format(PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT);
  resumeOptions.push({
    text: 'Start of Term 1',
    value: resumeOnStartOfTerm1,
    key: `${RESUME_OPTIONS_GROUP}-category-2`,
    subText: `Week of ${moment(term1StartDate).format('D MMMM')}`,
  });

  resumeOptions.push({
    text: 'Select date',
    value: SPECIFIC_RESUME_DATE,
    key: `${RESUME_OPTIONS_GROUP}-category-3`,
    // eslint-disable-next-line react/prop-types
    renderBelowLabel: ({ checked }) => {
      return checked ? (
        <Fragment>
          <div className="mb-7 w-full p-0 md:mb-10 lg:w-1/2">
            <div className="w-full md:w-1/2">
              <span className="mb-2 block text-xs font-medium">Effective to:</span>
              <DatePicker {...resumeDatePickerProps} />
            </div>
            {resumeFromInputError && <ErrorMsg text={resumeFromInputError} extraClasses="mt-1" />}
          </div>
        </Fragment>
      ) : null;
    },
  });
  const resumeRadioGroupProps = useRadioGroupProps({
    groupName: RESUME_OPTIONS_GROUP,
    radios: resumeOptions,
    defaultSelection: !isFlexi ? resumeOnStartOfTerm1 : undefined,
  });

  const selectedResumeOption = resumeRadioGroupProps.selectedRadio;

  useEffect(() => {
    if (pauseStartDate && resumeExactDate) {
      setValidationError(null);
    }
    return undefined;
  }, [pauseStartDate, resumeExactDate]);

  useEffect(() => {
    if (selectedPauseFromOption === startOfSummerHolidays) {
      setPauseStartDate(startOfSummerHolidays);
    } else if (selectedPauseFromOption === SPECIFIC_PAUSE_START_DATE && pauseFromPicked) {
      clearPauseFromDateInputError();
      setPauseStartDate(pauseFromPicked);
    } else if (moment(selectedPauseFromOption).isValid()) {
      setPauseStartDate(selectedPauseFromOption);
    }
    return undefined;
    // eslint-disable-next-line
  }, [selectedPauseFromOption, pauseFromPicked]);

  useEffect(() => {
    if (selectedResumeOption !== SPECIFIC_RESUME_DATE) {
      setResumeExactDate(selectedResumeOption);
    } else if (resumeDatePicked) {
      setResumeExactDate(resumeDatePicked);
      clearResumeFromDateInputError();
    }
    return undefined;
    // eslint-disable-next-line
  }, [resumeDatePicked, selectedResumeOption, isFlexi]);

  useEffect(() => {
    if (
      selectedPauseFromOption === SPECIFIC_PAUSE_START_DATE &&
      pauseStartDate &&
      selectedResumeOption === SPECIFIC_RESUME_DATE &&
      resumeExactDate
    ) {
      if (moment(resumeExactDate).isSameOrBefore(moment(pauseStartDate))) {
        setResumeFromDateInputError('Please select a valid resume date');
      } else {
        clearResumeFromDateInputError();
        setValidationError(null);
      }
    }
    return undefined;
    // eslint-disable-next-line
  }, [pauseStartDate, resumeExactDate, selectedPauseFromOption, selectedResumeOption]);

  useEffect(() => {
    const pauseStart = (function () {
      if (selectedPauseFromOption === SPECIFIC_PAUSE_START_DATE) {
        return pauseFromPicked;
      }
      return selectedPauseFromOption || enrolmentPausedFrom;
    })();

    const pauseEnd = (function () {
      if (selectedResumeOption === SPECIFIC_RESUME_DATE) {
        return resumeDatePicked;
      }
      return selectedResumeOption;
    })();

    if (moment(pauseStart).isValid() && moment(pauseEnd).isValid()) {
      if (moment(pauseEnd).isSameOrBefore(moment(pauseStart))) {
        setValidationError("The effectivity date can't be earlier or be the same day as the resumption date.");
      } else {
        setValidationError(null);
      }
    }
  }, [
    pauseFromPicked,
    selectedPauseFromOption,
    resumeDatePicked,
    selectedResumeOption,
    enrolmentPausedFrom,
    pauseStartDate,
    resumeExactDate,
  ]);

  const onSubmitHandler = () => {
    if (!pauseStartDate && !resumeExactDate) {
      setValidationError('Please select a period to pause your sessions.');
      return;
    }

    if (!selectedPauseFromOption && !willBePaused) {
      setValidationError('Please select a date to pause your sessions.');
      return;
    } else if (selectedPauseFromOption === SPECIFIC_PAUSE_START_DATE && !pauseStartDate && !willBePaused) {
      setPauseFromDateInputError();
      return;
    } else {
      clearPauseFromDateInputError();
    }

    if (!selectedResumeOption) {
      setValidationError('Please select a date to resume your sessions.');
      return;
    } else if (selectedResumeOption === SPECIFIC_RESUME_DATE && !resumeExactDate) {
      setResumeFromDateInputError();
    } else {
      clearResumeFromDateInputError();
      setValidationError(null);
    }

    if (validationError) {
      return;
    }

    dispatch(
      savePauseFromResumeFromDateAction({
        pauseFromDate: pauseStartDate,
        resumeFromDate: resumeExactDate,
      })
    );

    const payload = {
      startDate: willBePaused ? moment(enrolmentPausedFrom).format('YYYY-MM-DD') : pauseStartDate,
      endDate: resumeExactDate,
      summerModeHolidayPractice: false,
    };

    submit(payload);
  };

  if (!!policyError) {
    return (
      <Alert className="!mb-10" type="error" body={null}>
        Unable to proceed with pausing the enrolment. There was an error acquiring the enrolment policy.
      </Alert>
    );
  }

  return (
    <Pending loading={pausePolicyLoading} loader={<Loader className="my-20" />}>
      {!willBePaused && (
        <section className="mb-10">
          <h4 className="mb-5 font-display text-xl font-bold">When would you like to pause sessions?</h4>
          <div className="mb-7 w-full p-0 md:mb-10 lg:w-1/2">
            <div className="w-full md:w-1/2">
              <span className="mb-2 block text-xs font-medium">Effective from:</span>
              <DatePicker {...pauseDatePicker} />
            </div>
            {pauseFromInputError && <ErrorMsg text={pauseFromInputError} extraClasses="mt-1" />}
          </div>
        </section>
      )}
      <section className="mb-10">
        <h4 className="mb-1.5 font-display text-xl font-bold">When do you want to resume sessions?</h4>
        <p className="mb-6">You can resume anytime before the start of Term 1 based on your plan</p>
        <RadioGroup {...resumeRadioGroupProps} />
      </section>
      <div className="mb-4 text-left">
        {validationError && <ErrorMsg text={validationError} extraClasses="!mb-2" />}
        <Button
          size="lg"
          disabled={isSubmitting || !!resumeFromInputError || !!validationError}
          onClick={onSubmitHandler}
          className={TRACKING_PE.SUMMER_CONFIRM_PAUSE}
        >
          {willBePaused ? 'Update pause' : 'Confirm pause'}
        </Button>
      </div>
      {summerPromo.promoText && (
        <div className="mb-20 flex flex-col lg:flex-row">
          <SummerPromoLegalCopy />
        </div>
      )}
    </Pending>
  );
};

export { Term4SummerPause as PresentationalTerm4PauseSessionsSummerMode };
export default Term4SummerPause;
