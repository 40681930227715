import { useMemo, Suspense } from 'react';
import { CodeCampIcon, CompoundBookIcon, CompoundCalculatorIcon, CompoundFlaskIcon } from '../../../../../icons';
import { api } from '../../../../../api';
import classNames from 'classnames';

interface Props {
	className?: string;
}

const HomeSubjectsWidget = ({ className }: Props) => {
	const { data: accountDetails } = api.account.details.useQuery();
	const kiwi = accountDetails?.country === 'NZ';
	const cards = useMemo(() => {
		const dimension = 28;
		const standardIconColor = 'text-blood-orange-4';
		const text = {
			AU: {
				languages: 'Programs for years 2 to 12, with free assessments for years 3 to 9.',
				science: 'Programs for Years 11 to 12.',
			},
			NZ: {
				languages: 'Programs for years 3 to 11, with free assessments for years 4 to 10.',
				science: '',
			},
		};
		const data = [
			{
				id: 'subject-card-english',
				icon: <CompoundBookIcon className={classNames(standardIconColor)} width={dimension} height={dimension} />,
				subject: 'English',
				text: text[kiwi ? 'NZ' : 'AU'].languages,
				href: kiwi
					? 'https://clueylearning.co.nz/online-english-tutoring/'
					: 'https://clueylearning.com.au/online-english-tutoring/',
			},
			{
				id: 'subject-card-mathematics',
				icon: <CompoundCalculatorIcon className={classNames(standardIconColor)} width={dimension} height={dimension} />,
				subject: 'Mathematics',
				text: text[kiwi ? 'NZ' : 'AU'].languages,
				href: kiwi
					? 'https://clueylearning.co.nz/online-maths-tutoring'
					: 'https://clueylearning.com.au/online-maths-tutoring',
			},
			{
				id: 'subject-card-biology',
				icon: <CompoundFlaskIcon className={classNames(standardIconColor)} width={dimension} height={dimension} />,
				subject: 'Biology',
				text: text[kiwi ? 'NZ' : 'AU'].science,
				href: 'https://clueylearning.com.au/online-biology-tutoring',
			},
			{
				id: 'subject-card-chemistry',
				icon: <CompoundFlaskIcon className={classNames(standardIconColor)} width={dimension} height={dimension} />,
				subject: 'Chemistry',
				text: text[kiwi ? 'NZ' : 'AU'].science,
				href: 'https://clueylearning.com.au/online-chemistry-tutoring',
			},
			{
				id: 'subject-card-physics',
				icon: <CompoundFlaskIcon className={classNames(standardIconColor)} width={dimension} height={dimension} />,
				subject: 'Physics',
				text: text[kiwi ? 'NZ' : 'AU'].science,
				href: 'https://clueylearning.com.au/online-physics-tutoring',
			},
			{
				id: 'subject-card-codecamp',
				icon: <CodeCampIcon width={dimension} height={dimension} />,
				subject: 'Code Camp Online',
				text: 'Find camps near you.',
				href: 'https://www.codecamp.com.au',
			},
		];

		if (kiwi) {
			return data.slice(0, 2);
		}

		return data;
	}, [kiwi]);

	return (
		<div className={classNames('flex flex-col gap-4 text-xl text-grey-6', className)}>
			<p className="font-display font-bold">Cluey offers tutoring on a wide range of subjects</p>
			<p className="text-lg">Did you know that Cluey can support you in a wide range of subjects?</p>
			<div className={classNames('grid grid-cols-1 gap-6', kiwi ? 'md:grid-cols-2' : 'md:grid-cols-3')}>
				{cards.map((card) => (
					<a
						key={`${card.subject}-anchor`}
						id={card.id}
						href={`${card.href}?utm_source=cluey-hub&utm_medium=subject-cards&utm_campaign=new-hub-homepage`}
						target="_blank"
						rel="noreferrer"
						className={classNames(
							'flex h-32 shrink-0 basis-[30%] flex-col items-start gap-2 rounded-lg border border-grey-3 p-6 text-grey-6 hover:text-grey-6'
						)}
					>
						<div className="flex items-center gap-2">
							<Suspense>{card.icon}</Suspense>
							<span className="text-lg font-bold">{card.subject}</span>
						</div>
						<p className="text-left text-sm">{card.text}</p>
					</a>
				))}
			</div>
		</div>
	);
};

export { HomeSubjectsWidget };
