import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useSelector } from 'react-redux';
import { useHistory, generatePath } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { ButtonAsLink, Link } from '@cluey/cluey-components';
import { getSpecificActiveEnrolment } from '../../selectors/enrolmentSelector';

import {
  redirectToFormWithEnrolmentId,
  redirectToEnrolmentManagement,
} from '../../actions/hub/hubEnrolmentManagementActions';
import {
  isEnrolmentCancelled,
  isEnrolmentPaused,
  isGroupEnrolment,
  isEnrolmentFinished,
} from '../../selectors/hubSelector';
import { getEnrolmentSchedule } from '../../selectors/enrolmentManagementSelector';
import {
  PATH_HUB_CR_PAUSE_SESSIONS,
  PATH_HUB_UPCOMING_SESSIONS,
  PATH_HUB_ENROLMENT_CHANGE_SCHEDULE,
  PATH_HUB_ENROLMENT_MANAGE_PAUSE,
  PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP,
  PATH_HUB_ADD_ONE_OFF_SESSION,
} from '../../util/pagePath';

import iconReview from '../../assets/images/icon-outline-review.svg';
import { willEnrolmentBePaused } from '../../selectors/enrolmentSelector';
import RescheduleRequestAlert from '../../common/RescheduleRequestAlert';

const OnGoingSchedule = ({
  enrolmentId,
  enrolmentSchedule,
  isGroup,
  isCancelledOrFinished,
  isPaused,
  willBePaused,
  redirectToCSP,
  redirectToForm,
}) => {
  const history = useHistory();
  const enrolment = useSelector(getSpecificActiveEnrolment({ enrolmentId }));
  const reschedulableSession = enrolment?.sessions?.find((session) => session.rescheduleRequestStatus === 'Requested');
  const isRescheduleRequested = !!reschedulableSession;

  const onChangeClick = (e) => {
    e.preventDefault();
    const redirectPage = isGroup ? PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP : PATH_HUB_ENROLMENT_CHANGE_SCHEDULE;
    redirectToCSP({
      enrolmentId,
      callback: () => {
        history.push(`${redirectPage}?token=${enrolmentId}`);
      },
    });
  };

  const onPauseClick = () => {
    if (isPaused || willBePaused) {
      history.push(`${PATH_HUB_ENROLMENT_MANAGE_PAUSE}?token=${enrolmentId}`);
    } else {
      redirectToForm({
        enrolmentId,
        callback: () => {
          history.push(`${PATH_HUB_CR_PAUSE_SESSIONS}?token=${enrolmentId}`);
        },
      });
    }
  };
  console.log({ isCancelledOrFinished, enrolmentSchedule });
  const showSchedule = !isCancelledOrFinished && Array.isArray(enrolmentSchedule) && !isEmpty(enrolmentSchedule);
  const message = isCancelledOrFinished
    ? 'There are no sessions scheduled for this enrolment.'
    : 'There are no ongoing sessions scheduled for this enrolment.';

  return (
    <div className="mt-lg-9">
      <h3 className="pb-3 text-xs font-bold uppercase tracking-wide">ongoing schedule</h3>
      <div className="flex items-start">
        <img src={iconReview} alt="icon" className="mr-5 lg:mr-6" />
        <div className="text-left">
          {!showSchedule && (
            <Fragment>
              <p className="text-base font-bold leading-tight lg:mb-4">No session schedule</p>
              <p className="mb-1 text-base-sm leading-5">{message}</p>
              {!isGroup && !isPaused && !isCancelledOrFinished && (
                <Link to={generatePath(PATH_HUB_ADD_ONE_OFF_SESSION, { enrolmentId })}>Add a one-off session</Link>
              )}
            </Fragment>
          )}
          {showSchedule && (
            <Fragment>
              {enrolmentSchedule.map((s) => {
                return (
                  <p key={`${s}`} className="mb-2 text-lg font-bold leading-tight">
                    {s}
                  </p>
                );
              })}
              {isRescheduleRequested && (
                <RescheduleRequestAlert tutorName={enrolment.tutor} session={reschedulableSession} />
              )}
              <div className="flex flex-col items-start gap-1 lg:gap-2">
                {!isGroup && !isPaused && (
                  <Link to={generatePath(PATH_HUB_ADD_ONE_OFF_SESSION, { enrolmentId })}>Add a one-off session</Link>
                )}
                <ButtonAsLink onClick={onChangeClick} className="track-hub-enrolment-details-change-schedule">
                  {isGroup ? 'Change group' : 'Change'}
                </ButtonAsLink>
                {isGroup && !isPaused && !willBePaused && <ButtonAsLink onClick={onPauseClick}>Pause</ButtonAsLink>}
                {!isGroup && (
                  <ButtonAsLink onClick={onPauseClick}>
                    {isPaused || willBePaused ? 'Manage pause' : 'Pause'}
                  </ButtonAsLink>
                )}
                <Link to={PATH_HUB_UPCOMING_SESSIONS}>View upcoming sessions</Link>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

OnGoingSchedule.propTypes = {
  enrolmentId: PropTypes.string.isRequired,
  enrolmentSchedule: PropTypes.arrayOf(PropTypes.string),
  isGroup: PropTypes.bool.isRequired,
  isCancelledOrFinished: PropTypes.bool.isRequired,
  isPaused: PropTypes.bool.isRequired,
  willBePaused: PropTypes.bool.isRequired,
  redirectToCSP: PropTypes.func.isRequired,
  redirectToForm: PropTypes.func.isRequired,
};

OnGoingSchedule.defaultProps = {
  enrolmentSchedule: [],
};

export default connect(
  (state) => {
    const { enrolmentId } = state.hubEnrolmentDetailPage;
    return {
      enrolmentId,
      enrolmentSchedule: getEnrolmentSchedule(state),
      isGroup: isGroupEnrolment(state),
      isCancelledOrFinished: isEnrolmentCancelled(state) || isEnrolmentFinished(state),
      isPaused: isEnrolmentPaused(state),
      willBePaused: willEnrolmentBePaused(state),
    };
  },
  (dispatch) => {
    return {
      redirectToCSP: bindActionCreators(redirectToEnrolmentManagement, dispatch),
      redirectToForm: bindActionCreators(redirectToFormWithEnrolmentId, dispatch),
    };
  }
)(OnGoingSchedule);
