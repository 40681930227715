import CodeCampLogo from '../../../assets/images/summerMode/code-camp-logo-blue.svg';
import CodeCampVector from '../../../assets/images/code-camp-vector.png';
import CodeCampPromoImage from '../../../assets/images/summerMode/CodeCampPromo2024.png';
import { useEnrolmentsQuery } from '../../../hooks/queries/useEnrolmentQuery';
import { z } from 'zod';
import classNames from 'classnames';
import { TRACKING_CC } from '../../../util/trackingClasses';
import useAccountDetailsQuery from '../../../hooks/queries/useAccountDetailsQuery';
import { sendPostRequest } from '../../../services/RequestService';
import { getPortalAPIRequestParams } from '../../../services/PortalAPIServices/helper';
import { summerPauseInsteadOfCancelEnabled } from '../../../selectors/enrolmentSelector';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// TODO - UPDATE URL FOR 2024
const CODECAMP_URL =
  'https://www.codecamp.com.au/camps?utm_source=cluey-hub&utm_campaign=summer-offer-cluey&utm_term=50-off&last_source=cluey-hub&last_campaign=summer-offer-cluey&last_offer=50-off';

const INTERACTION_NAME = 'Code Camp promo summer';

export const CodeCampPromo = ({ isStudentProfilePage = false }) => {
  // replace with api query, e.g.
  // const { data: account } = api.accounts.details.useQuery();
  const { data: account } = useAccountDetailsQuery();

  // replace with api mutation, e.g.
  // const { mutate } = api.interactions.useMutation();
  const hubInteractionsTemp = () => {
    if (account) {
      const { url, reqOptions } = getPortalAPIRequestParams(`customer/interaction`);

      const payload = {
        currentDateTime: new Date().toISOString(),
        downloadItemUrl: CODECAMP_URL,
        downloadItemName: INTERACTION_NAME,
        customerType: 'Account',
        downloadItemType: INTERACTION_NAME,
      };

      sendPostRequest({
        url,
        payload,
        signRequest: true,
        reqOptions: {
          ...reqOptions,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (response.status === 200) {
            console.log('Hub interaction recorded');
          } else {
            console.log('Hub interaction failed to record');
          }
        })
        .catch((err) => {
          console.log('Hub interaction failed to record', err);
        });
    }
  };

  return (
    <a
      href={CODECAMP_URL}
      target="_blank"
      rel="noreferrer"
      onClick={hubInteractionsTemp}
      className={classNames(
        'code-camp-radial-gradient group relative flex h-[360px] w-full flex-col-reverse overflow-hidden rounded-lg border border-grey-1 shadow-sm md:h-[200px] md:flex-row-reverse',
        isStudentProfilePage ? TRACKING_CC.STUDENT_PROFILE : TRACKING_CC.FIND_A_CAMP
      )}
    >
      <div className="absolute left-0 top-0 h-full w-full" style={{ backgroundImage: `url(${CodeCampVector})` }}></div>
      <div className="relative flex-1">
        <img src={CodeCampPromoImage} className="absolute -bottom-0 right-0 w-[70%] md:-bottom-2 md:w-[65%]" alt="" />
      </div>
      <div className="relative flex flex-1 flex-col justify-end">
        <div className="absolute left-0 top-0 flex h-full w-56 flex-1 flex-col items-start justify-between px-8 py-3 md:w-64">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="absolute left-0 top-0 w-full"
            viewBox="0 0 253 46"
            fill="none"
          >
            <path
              d="M182.399 46V45.965C194.528 45.7698 205.886 40.9302 214.441 32.232L253 -7H0V46H182.399Z"
              fill="white"
            />
          </svg>
          <div className="relative w-3/4">
            <img src={CodeCampLogo} alt="code camp" className="w-full" />
          </div>
        </div>
        <div className="m px-8 pb-6 pt-12 font-codecamp tracking-wider text-white">
          <div className="text-xl " style={{ textShadow: '1px 1px 20px rgba(0,0,0,0.4)' }}>
            As a Cluey customer, you can save $50 on holiday camps all summer long!
          </div>
          <div
            className="flex items-center gap-2 text-xl group-hover:underline"
            style={{ textShadow: '1px 1px 20px rgba(0,0,0,0.4)' }}
          >
            <span className="text-lg">Get my code!</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" className="inline h-4 w-4">
              <path
                fill="currentColor"
                d="M5 7.5c-.163 0-.325-.055-.457-.166L.257 3.763C-.046 3.51-.087 3.06.166 2.757c.252-.303.702-.344 1.005-.092l3.837 3.198 3.83-3.083c.307-.247.757-.198 1.004.109s.199.756-.108 1.004l-4.286 3.45c-.13.104-.29.157-.448.157"
                transform="translate(-334 -1195) translate(282 1184) translate(52 11) rotate(-90 5 5)"
              />
            </svg>
          </div>
        </div>
      </div>
    </a>
  );
};

CodeCampPromo.propTypes = {
  isStudentProfilePage: PropTypes.bool,
};

export const ConnectedCodeCampPromo = () => {
  const { data: enrolments } = useEnrolmentsQuery('active');

  const isSummerMode = useSelector(summerPauseInsteadOfCancelEnabled);

  if (!isSummerMode) return null;

  const hasApplicableEnrolments = Array.isArray(enrolments)
    ? enrolments.some((enrolment) => {
        const parse = z.object({ yearLevel: z.string() }).safeParse(enrolment);
        if (parse.success) {
          const match = parse.data.yearLevel.match(/Year ([0-9]+)/);
          if (match) {
            const yearLevel = parseInt(match[1]);
            return yearLevel <= 6;
          }
        }
        return false;
      })
    : false;

  return hasApplicableEnrolments ? <CodeCampPromo /> : null;
};
