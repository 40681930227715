import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Container } from '@cluey/cluey-components';
import classNames from 'classnames';
import { isEmpty, sortBy } from 'lodash';
import moment from 'moment';
import { SESSION_TIME_FORMAT } from '../../util/constants';

const getAvailabilitiesForCurrentTutor = ({ availabilities, currentTutorId }) => {
	return availabilities.filter((a) => {
		return a.tutorId === currentTutorId;
	});
};

// a and b are dates in UTC
const isSameDayInTimezone = (a, b, timezone = 'Australia/Sydney') => {
	return moment(a).tz(timezone).isSame(moment(b).tz(timezone), 'day');
};

const getAvailabilitiesForADay = ({ availabilities, timezone, day }) => {
	const selectedDay = moment(day).tz(timezone, true).utc();
	return availabilities.filter((a) => {
		const availabilityDate = moment(a.startDate);
		return isSameDayInTimezone(selectedDay, availabilityDate, timezone);
	});
};

const formatDateAndSort = ({ availabilities, timezone }) => {
	const dateFormatted = availabilities.map((a) => {
		const availabilityDate = moment.utc(a.startDate).tz(timezone);
		a.sessionTime = availabilityDate;
		a.displayTime = availabilityDate.format(SESSION_TIME_FORMAT);
		return a;
	});
	return sortBy(dateFormatted, 'sessionTime');
};

export const AvailabilitiesOfADayUnconnected = ({
  day,
  currentTutor,
  currentTutorId,
  showCurrentTutorOnly,
  setShowCurrentTutorOnly,
  availabilities,
  timezone,
  clickHandler,
  tutorInitiated = false,
  isFirstSession,
}) => {
	const [showAllTutor, setShowAllTutor] = useState(!showCurrentTutorOnly);
	const [availabilitiesInView, setAvailabilitiesInView] = useState([]);

	useEffect(() => {
		setShowAllTutor(!showCurrentTutorOnly);
	}, [showCurrentTutorOnly, day]);

	useEffect(() => {
		if (!isEmpty(availabilities)) {
			const availabilitiesForCurrentDay = getAvailabilitiesForADay({
				availabilities,
				timezone,
				day,
			});

			if (isEmpty(availabilitiesForCurrentDay)) {
				setAvailabilitiesInView(availabilitiesForCurrentDay);
			} else {
				const availabilitiesFiltered = showAllTutor
					? availabilitiesForCurrentDay
					: getAvailabilitiesForCurrentTutor({
							availabilities: availabilitiesForCurrentDay,
							currentTutorId,
					  });

				const sorted = formatDateAndSort({
					availabilities: availabilitiesFiltered,
					timezone,
				});
				setAvailabilitiesInView(sorted);
			}
		}
	}, [day, currentTutor, currentTutorId, showAllTutor, availabilities, timezone]);

	if (day instanceof Date !== true) {
		return null;
	}

  const customerIsReschedulingFirstSession = (!tutorInitiated && isFirstSession)
  if (isEmpty(availabilitiesInView)) {
    if (showAllTutor || customerIsReschedulingFirstSession) {
      const text = customerIsReschedulingFirstSession 
        ? `Unfortunately ${currentTutor} has no availability on this day, please select another day.` 
        : "Unfortunately there is no availability on this day, please select another day."
      return (
        <Container>
          <p className="mb-5 text-lg leading-snug tracking-tight lg:mb-4">
            {text}
          </p>
        </Container>
      );
    }
    return (
      <Container className="mb-8">
        <div className="">
          <p className="mb-5 text-lg leading-snug tracking-tight lg:mb-4">
            Unfortunately {currentTutor} has no availability on this day, please
            select another day or view times available with other tutors.
          </p>
        </div>
        {!tutorInitiated && <Button
          size="xs"
          appearance="reverse"
          className="block p-0 font-bold uppercase"
          onClick={() => {
            setShowAllTutor(true);
            setShowCurrentTutorOnly(false);
          }}
        >
          VIEW TIMES AVAILABLE WITH ALL TUTORS
        </Button>}
      </Container>
    );
  }

	return (
		<div className="container mx-auto mb-8 px-4 lg:px-0">
			{availabilitiesInView.map((a) => {
				const {
					firstname: tutorName,
					scheduleDate,
					sessionDuration,
					tutorNumber,
					tutorId,
					displayTime,
					startDate /* This is the unconverted UTC start from the api */,
				} = a;
				const tutorClasses = classNames('w-1/2 text-right text-sm leading-[1.14]', {
					'font-bold': tutorName === currentTutor,
				});

				return (
					<button
						key={`${displayTime}-${tutorName}`}
						className="mb-3 flex w-full cursor-pointer items-center justify-between rounded-lg border-2 border-slate-1 bg-slate-1 px-4 py-3 transition-colors hover:border-slate-2"
						type="button"
						tabIndex={-1}
						onClick={() => {
							clickHandler({
								selectedDay: moment(day).format('D MMMM YYYY'),
								scheduleDate,
								sessionDuration,
								tutorNumber,
								tutorId,
								startDate,
							});
						}}
					>
						<span className="text-lg font-bold text-primary lg:text-2xl">{displayTime}</span>
						<span className={tutorClasses}>{tutorName} available</span>
					</button>
				);
			})}
		</div>
	);
};

AvailabilitiesOfADayUnconnected.propTypes = {
  isFirstSession: PropTypes.bool,
  day: PropTypes.instanceOf(Date).isRequired,
  currentTutor: PropTypes.string.isRequired,
  currentTutorId: PropTypes.string,
  showCurrentTutorOnly: PropTypes.bool,
  setShowCurrentTutorOnly: PropTypes.func.isRequired,
  availabilities: PropTypes.arrayOf(
    PropTypes.shape({
      firstname: PropTypes.string,
      tutorNumber: PropTypes.string,
      scheduleDate: PropTypes.string,
    })
  ),
  timezone: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  tutorInitiated: PropTypes.bool,
};

AvailabilitiesOfADayUnconnected.defaultProps = {
  isFirstSession: false,
  showCurrentTutorOnly: false,
  currentTutorId: '',
  availabilities: [],
  tutorInitiated: false,
};

export default connect((state) => {
	return {
		availabilities: state.hubChangeSchedulePage.tutorAvailabilities,
		timezone: state.hubUser.timezone,
	};
}, null)(AvailabilitiesOfADayUnconnected);
