import { Button, Form, Pending } from '@cluey/cluey-components';
import { Auth2Layout, BackButton, InlineLoader } from '../common';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import { PATHS, decodeToken, tokenPassword, useSignupStore } from '.';
import { Redirect, useHistory } from 'react-router';
import { PATH_HUB_HOME, PATH_HUB_RESET_PASSWORD_REQUEST } from '../../../../util/pagePath';
import { userLogin } from '../../../../actions/hub/hubAuthPagesActions';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import { PasswordField } from '../ResetPasswordRequestPage/ResetPassword/PasswordField';
import isError from 'lodash/isError';
import { api } from '../../../../api';
import { InitialState } from '../../../../reducers/initialState';

const PasswordValidation = z
  .string()
  .min(8, 'Password must be at least 8 characters long.')
  .refine((value) => !/[^A-Za-z\d@$!%*?&#^]+/.test(value), 'Password can only contain special characters @$!%*?&#^')
  .refine(
    (value) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&#^]{8,}$/.test(value),
    'Password must contain at least 1 uppercase letter, 1 lowercase letter and 1 number.'
  );

export const SignUpPasswordPage = () => {
  const [password, setPassword] = useState('');
  const history = useHistory();
  const { origin, email } = useSignupStore(({ origin, email }) => ({ origin, email }));
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const signingIn = useSelector((state: InitialState) => state.ui.apiState.hubAuthPage.isLoading);
  const {
    mutate,
    isLoading,
    error: authError,
  } = useMutation({
    mutationFn: async (newPassword: string) => {
      try {
        // grab the current user from Auth
        const user = await Auth.currentAuthenticatedUser();
        // Decode the token stored in the store
        const token = decodeToken(useSignupStore.getState().token);
        // Execute an Auth password change
        await Auth.changePassword(user, tokenPassword(token), newPassword);
        useSignupStore.setState({ token: '' });
      } catch (error) {
        if (error === 'The user is not authenticated') {
          // Must have arrived via the legacy signup method
          const { email, tempPwd, clearState } = useSignupStore.getState();
          if (!tempPwd) {
            // edge case
            history.push(PATH_HUB_RESET_PASSWORD_REQUEST, { emailParam: email });
          }
          // sign them in, then change their password straight away
          const user = await Auth.signIn(email, tempPwd);
          await Auth.completeNewPassword(user, newPassword);
          // Dispatch redux useLogin action
          await userLogin({ email, password: newPassword })(dispatch);
          // clear the state -- not needed anymore
          clearState();
        } else {
          throw error;
        }
      }
    },
    onSuccess: async () => {
      try {
        const data = await api.account.details.fetchQuery();
        if (data?.phone) {
          // TODO: redirect to the worksheet page
          history.push(origin === 'worksheets' ? PATH_HUB_HOME : PATH_HUB_HOME);
        } else {
          history.push(PATHS.CONTACT_PATH);
        }
      } catch {
        console.info('Failed to fetch user details, redirecting to contact page');
        history.push(PATHS.CONTACT_PATH);
      } finally {
        /* 
					This seems counterintuitive, but is needed flow to prevent skipping of the contact page.
					It's because the userLogin action modifies the "authenticated" redux state, which is
					checked by a useEffect hook onMount in /views/hub/auth2/common.ts.
	
					On rendering the contact page, the authenticated state must first be unauthenticated to prevent
					forgoing the update phone number page.
	
					To achieve this, redirection must precede the userLogin action.
				*/
        await userLogin({ email, password })(dispatch);
      }
    },
  });

  const handleSubmit = () => {
    const parseResult = PasswordValidation.safeParse(password);
    if (parseResult.success === true) {
      mutate(parseResult.data);
    } else {
      setError(parseResult.error.errors[0].message);
    }
  };

  if (!email) return <Redirect to={PATHS.ROOT_PATH} />;

  return (
    <Auth2Layout
      title="Create your password"
      headerLinks={
        <div className="flex justify-between">
          <BackButton />
        </div>
      }
    >
      <Pending
        loading={signingIn}
        loader={
          <div className="flex h-full w-full items-center justify-center">
            <InlineLoader />
          </div>
        }
      >
        <p className="pb-6 font-bold">Create your own password now. It should include:</p>
        <ul className="list-disc pl-8">
          <li>At least 8 characters </li>
          <li>At least 1 uppercase letter </li>
          <li>At least 1 lowercase letter </li>
          <li>At least 1 number</li>
        </ul>
        <Form onSubmit={handleSubmit} className="w-full py-8">
          <PasswordField
            id="newPassword"
            label="Password"
            onChange={(value) => {
              setPassword(value);
              setError('');
            }}
            data={{
              error:
                error ||
                (isError(authError) ? (authError as Error).message : typeof authError === 'string' && authError) ||
                '',
              valid: error === '',
              value: password,
              textVisible: showPassword,
            }}
            onEyeClick={() => setShowPassword((curr) => !curr)}
            className="max-w-sm pb-16"
          />
          <Button type="submit" size="lg" disabled={isLoading} className="relative flex items-center justify-center">
            <span className={isLoading ? 'opacity-0' : ''}>Continue</span>
            {isLoading && <InlineLoader className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />}
          </Button>
        </Form>
      </Pending>
    </Auth2Layout>
  );
};
