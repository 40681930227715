import initialState from './initialState';
import { SR_LOGIN_RECEIVE_INFO } from '../actions/actionTypes';

export default function diagnosticsReducer(state = initialState.diagnostics, action) {
  switch (action.type) {
    case SR_LOGIN_RECEIVE_INFO:
      return {
        ...state,
        clockSkew: action.payload.clockSkew.skew,
        timeOffset: action.payload.clockSkew.timeOffset,
      };
    default:
      return state;
  }
}
