import { BasicCard } from '@cluey/cluey-components';
import { ReactComponent as FilledCoin } from '../../../../../assets/images/atoms-icon-filled-coin.svg';
import { ReactComponent as TutorSession } from '../../../../../assets/images/atoms-icon-filled-tutor-session.svg';
import { PATH_HUB_SESSIONS_PAGE } from '../../../../../util/pagePath';
import IconCard from '../../../components/IconCard/IconCard';
import { useSelector } from 'react-redux';
import { getUpcomingSessions, getTimezoneFromStore } from '../../../../../selectors/hubSelector';
import { Session } from '../../../../../types/hubInterfaces';
import moment from 'moment';
import useNextPaymentQuery from '../../../../../hooks/queries/useNextPaymentQuery';
import { getDaysAway, getForStudentString, getFormattedWeekRange } from './helper';
import { inflect } from '../../../../../util/helper/inflection';
import useAccountDetailsQuery from '../../../../../hooks/queries/useAccountDetailsQuery';

const SESSIONS_LINK = { to: PATH_HUB_SESSIONS_PAGE, text: 'View sessions' };

export type NextPaymentSectionProps = {
  nextPayment: any;
};
const PaidSessionsCard = () => {
  const upcomingSessions = useSelector(getUpcomingSessions);
  const userTz = useSelector(getTimezoneFromStore);
  //   Get all sessions after right now, that have been paid for
  const paidSessions = upcomingSessions
    .filter((session: Session) => {
      const sessionDate = moment(session.startTime);
      return sessionDate.isSameOrAfter(moment()) && session.paymentStatus === 'Paid';
    })
    .sort((a: Session, b: Session) => moment(b.startTime).diff(moment(a.startTime)));
  const cardProps = paidSessions?.length
    ? {
        header: `${paidSessions?.length} ${inflect('Session', paidSessions?.length)}`,
        description: `already paid until ${moment(paidSessions[0]?.startTime).tz(userTz).format('MMM D')}`,
      }
    : {
        header: `No sessions`,
        description: `already paid`,
      };

  return (
    <IconCard
      title="PAID SESSIONS"
      containerClassName=" border-b md:border-b-0 md:border-r border-grey-2"
      icon={<TutorSession className="h-6 w-6" />}
      link={SESSIONS_LINK}
      {...cardProps}
    />
  );
};

const NextPaymentCard = () => {
  const { data: nextPayment, isLoading: isLoadingNextPayment, isError: isErrorNextPayment } = useNextPaymentQuery();
  const { data: accountDetails } = useAccountDetailsQuery();
  const hasNextPaymentDate = !!nextPayment?.paymentDate;
  const errorProps = {
    header: 'Error loading next payment',
    description: 'Please try again later',
  };

  const cardProps = hasNextPaymentDate
    ? {
        title: `NEXT PAYMENT: ${moment(nextPayment?.paymentDate).format('MMM D')} ${getDaysAway(
          nextPayment?.paymentDate,
          { wrapperFunction: (content, daysAway) => `(${daysAway > 1 ? `IN ${daysAway} DAYS` : content})` }
        )}`,
        header: `$${nextPayment?.total} for ${nextPayment?.sessionCount} ${inflect(
          'session',
          nextPayment?.sessionCount
        )}`,
        description: `booked in the ${getFormattedWeekRange(nextPayment)} ${getForStudentString({accountDetails, nextPayment})}`,
      }
    : {
        header: `View sessions for more details.`,
      };

  return (
    <IconCard
      title="NEXT PAYMENT"
      loading={isLoadingNextPayment}
      icon={<FilledCoin className="h-6 w-6" />}
      link={SESSIONS_LINK}
      {...(isErrorNextPayment ? errorProps : cardProps)}
    />
  );
};

const NextPaymentSection = () => {
  return (
    <BasicCard className="mb-8">
      <div className="flex w-full flex-col md:flex-row">
        <PaidSessionsCard />
        <NextPaymentCard />
      </div>
    </BasicCard>
  );
};
export default NextPaymentSection;
