export const DEFAULT_TITLE = 'Cluey Learning';

export const PAGE_NAME_SESSION_REPORT = 'Learning activity';
export const PAGE_NAME_UPCOMING_SESSIONS = 'Upcoming sessions';
export const PAGE_NAME_RESCHEDULE_SESSION = 'Change session';

export const PAGE_TITLE_RESCHEDULE_SESSION = 'Change Session';

// New Portal
export const PAGE_TITLE_HUB_HOME = 'Hub - Homepage';
export const PAGE_TITLE_HUB_UPCOMING_SESSIONS = 'Hub - Upcoming sessions';
export const PAGE_TITLE_HUB_COMPLETED_SESSIONS = 'Hub - Completed sessions';
export const PAGE_TITLE_HUB_ENROLMENTS = 'Hub - Enrolments';
export const PAGE_TITLE_HUB_ACCOUNT_DETAILS = 'Hub - Account details';
export const PAGE_TITLE_HUB_BILLING_INFORMATION = 'Hub - Billing information';
export const PAGE_TITLE_HUB_INVOICE_HISTORY = 'Hub - Invoice history';
export const PAGE_TITLE_HUB_PAYMENT_SCHEDULE = 'Hub - Payment schedule';
export const PAGE_TITLE_HUB_NO_ACTIVE_ENROLMENTS = 'Hub - No active enrolments';
export const PAGE_TITLE_HUB_ENROLMENT_DETAIL = 'Hub - Enrolment Detail';
export const PAGE_TITLE_HUB_ENROLMENT_CHANGE_SCHEDULE = 'Hub - Change Schedule';
export const PAGE_TITLE_HUB_ENROLMENT_NAPLAN = 'Hub - NAPLAN';
export const PAGE_TITLE_HUB_ENROLMENT_CHANGE_SCHEDULE_KEEP_PREV_TUTOR = 'Hub - Keep previous tutor';
export const PAGE_TITLE_HUB_ENROLMENT_MANAGE_PAUSE = 'Hub - Manage pause';

export const PAGE_TITLE_HUB_SIGN_UP = 'Hub - Sign up';
export const PAGE_TITLE_HUB_SIGN_UP_CONFIRMATION = 'Hub - Sign up';
export const PAGE_TITLE_HUB_LOG_IN = 'Hub - Login';
export const PAGE_TITLE_HUB_STAFF_LOGIN = 'Hub - Staff Login';
export const PAGE_TITLE_HUB_ONBOARDING = 'Hub - Onboarding';
export const PAGE_TITLE_HUB_RESET_PASSWORD_REQUEST = 'Reset password request';
export const PAGE_TITLE_HUB_RESET_PASSWORD_REQ_SENT = 'Reset password sent';
export const PAGE_TITLE_HUB_SET_NEW_PASSWORD = 'Set new password';
export const PAGE_TITLE_HUB_NEW_PASSWORD_SUCCESS = 'New password success';
export const PAGE_TITLE_HUB_RESEND_SIGNUP_LINK_REQUEST = 'Resend sign up link request';
export const PAGE_TITLE_HUB_RESEND_SIGNUP_LINK_REQ_SENT = 'Sign up link resent';

export const PAGE_TITLE_HUB_CR_PAUSE_SESSIONS = 'Pause sessions';
export const PAGE_TITLE_HUB_POLICY = 'Policy';
export const PAGE_TITLE_HUB_SERVICE_FEES = 'Service fees';
export const PAGE_TITLE_HUB_CR_REQUEST_OPTIONS = 'Request options';
export const PAGE_TITLE_HUB_CR_PERMANENT_SCHEDULE_CHANGE = 'Make a permanent schedule change';
export const PAGE_TITLE_HUB_CR_CHANGE_TUTOR = 'Change tutor';
export const PAGE_TITLE_HUB_CR_CANCEL_ENROLMENT = 'Cancel enrolment';
export const PAGE_TITLE_HUB_CR_OTHER_ENROLMENT_CHANGE = 'Other enrolment change';
export const PAGE_TITLE_HUB_CR_PAYMENT_QUERY = 'Payment query';
export const PAGE_TITLE_HUB_CR_OTHER_QUERY = 'Other query';
export const PAGE_TITLE_HUB_CR_TECHNICAL_ISSUE = 'Technical issue';
export const PAGE_TITLE_HUB_CR_ADD_ENROLMENT = 'Add enrolment';
export const PAGE_TITLE_HUB_CR_FEEDBACK = 'Feedback';
export const PAGE_TITLE_HUB_CR_OPT_IN = 'Practice';
export const PAGE_TITLE_HUB_CR_RESCHEDULE_SESSION = 'Reschedule this session';
export const PAGE_TITLE_HUB_CR_SKIP_SESSION = 'Skip this session';
export const PAGE_TITLE_HUB_CR_REQUEST_RECORDING = 'Request recording';
export const PAGE_TITLE_HUB_CR_CHANGE_CONTENT_FOCUS = 'Change content focus';
export const PAGE_TITLE_HUB_CR_SWITCH_LEARNING_MODE = 'Switch learning mode';
export const PAGE_TITLE_HUB_CR_ADD_SESSION = 'Add session';

export const PAGE_TITLE_HUB_TTC_PAUSE_SESSIONS = 'Pause sessions';
export const PAGE_TITLE_HUB_TTC_CHANGE_SESSION_KEEP_TUTOR = 'Change session with the same tutor';

export const PAGE_TITLE_SCHOOL_BASE = 'School Hub';
export const PAGE_TITLE_SCHOOL_UPCOMING_SESSIONS = PAGE_TITLE_SCHOOL_BASE;

export const PAGE_TITLE_HUB_PRICE_CHANGE = 'Price Change';
export const PAGE_TITLE_HUB_MID_YEAR_PROGRESS = 'Mid Year Progress';

export const PAGE_TITLE_HUB_SELECT_SESSION = 'Select a session';
export const PAGE_TITLE_HUB_RESCHEDULE_A_SESSION = 'Reschedule upcoming session';
export const PAGE_TITLE_HUB_SKIP_A_SESSION = 'Skip upcoming session';
export const PAGE_TITLE_NEW_YEAR_ENROLMENT_DETAIL = 'New Year Enrolment Detail';
export const PAGE_TITLE_HUB_MS_SESSION_TIMES = 'Manage session times and schedules';
export const PAGE_TITLE_HUB_MS_LEARNING_ADJUSTMENTS = 'Manage learning support';
export const PAGE_TITLE_HUB_MS_STUDENTS_AND_SUBJECTS = 'Manage students and subjects';
export const PAGE_TITLE_HUB_MS_BILLING_AND_PAYMENTS = 'Manage billing and payments';
export const PAGE_TITLE_HUB_SUPPORT = 'Manage learning support';

export const PAGE_TITLE_HUB_ADD_ONE_OFF_SESSION = 'Add session - Select';
export const PAGE_TITLE_HUB_ADD_ONE_OFF_SESSION_CONFIRM = 'Add session - Confirm ';
export const PAGE_TITLE_HUB_ADD_ONE_OFF_SESSION_SUCCESS = 'Add session - Success';

export const PATH_TITLE_HUB_STUDENT_PROFILE = 'Student Profile';
export const PATH_TITLE_HUB_STUDENT_PROFILE_EDIT = 'Edit Student Profile';
export const PATH_TITLE_HUB_STUDENT_PROFILE_ADD = 'Add Student';

export const PATH_TITLE_HUB_ASSESSMENT_VIEW = 'View Assessment';
export const PATH_TITLE_HUB_ASSESSMENT_GENERATE = 'Generate Assessment';
