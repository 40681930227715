import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, LinkButton } from '@cluey/cluey-components';

const InfoModalLayout = ({
  isModalOpen,
  toggleModal,
  modalIcon,
  title,
  renderIntro,
  infoImg,
  redirectText,
  redirectLink,
}) => {
  const titleclasses = 'font-body font-bold text-lg lg:text-2xl mb-2 lg:mb-4';

  return (
    <Modal open={isModalOpen} setOpen={toggleModal}>
      <div className="lg:px-5">
        <section className="flex flex-col items-center pt-2 lg:flex-row lg:items-start lg:py-7 lg:pr-24">
          <div className="lg:mr-4">
            <div className="rounded-full bg-slate-2 p-4">
              <img src={modalIcon} alt="modal icon" />
            </div>
          </div>
          <div className="w-full lg:mr-3">
            <div className="mt-4 text-center lg:mt-0 lg:text-left">
              <h3 className={titleclasses}>{title}</h3>
              {typeof renderIntro === 'function' && renderIntro()}
            </div>
          </div>
        </section>

        {infoImg && (
          <div className="w-full">
            <img
              src={infoImg}
              alt="how to change a session"
              className="w-full"
            />
          </div>
        )}
        <section className="my-4 flex flex-col items-center justify-center lg:my-7 lg:flex-row">
          <LinkButton
            size="xs"
            to={redirectLink}
            className="mb-3 w-full max-w-[240px] font-medium uppercase lg:mr-3 lg:mb-0"
          >
            {redirectText}
          </LinkButton>
          <Button
            size="xs"
            appearance="reverse"
            className="w-full max-w-[240px] font-medium uppercase"
            onClick={() => {
              toggleModal();
            }}
          >
            BACK
          </Button>
        </section>
      </div>
    </Modal>
  );
};

InfoModalLayout.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  modalIcon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  renderIntro: PropTypes.func.isRequired,
  infoImg: PropTypes.string,
  redirectText: PropTypes.string.isRequired,
  redirectLink: PropTypes.string.isRequired,
};

InfoModalLayout.defaultProps = {
  infoImg: '',
};

export default InfoModalLayout;
