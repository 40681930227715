import classNames from 'classnames';
import { Button } from '@cluey/cluey-components';
import { ReactNode } from 'react';

export interface Props {
  icon: ReactNode;
  leftText: ReactNode;
  linkText?: string;
  customClass?: string;
  onClickLink?(): void;
  children?: JSX.Element | Array<JSX.Element>;
}

const ItemHeader = ({ 
  icon,
  leftText,
  onClickLink,
  linkText,
  customClass = '',
  children,
}: Props) => {
  return (
    <div className={classNames('flex w-full justify-between rounded-t-lg mb-2', customClass)}>
      <div className="flex items-center gap-2">
        {icon && (typeof icon === 'string' ? <img width={20} height={20} src={icon} alt="icon" /> : icon)}
        <span className="text-sm">{leftText}</span>
      </div>
      {linkText && onClickLink && (
        <div className="flex">
          <Button appearance="reverse" size="xs" onClick={onClickLink}>
            {linkText}
          </Button>
        </div>
      )}
      {children}
    </div>
  );
};

export default ItemHeader;