import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';

import { updateAppView, resetConactFlowState } from '../../actions/contactUsFlowActions';
import * as TITLES from '../../util/pageTitle';
import * as PATHS from '../../util/pagePath';
import * as CONSTANTS from '../../util/constants';

const GoBack = ({
  goBackToOptions,
  goBack,
  resetContactFlowState,
  title,
}) => {
  const location = useLocation();
  let goBackToMainText = '';
  switch (location.pathname) {
    case PATHS.PATH_UPCOMING_SESSIONS:
      goBackToMainText = TITLES.PAGE_NAME_UPCOMING_SESSIONS;
      break;
    case PATHS.PATH_RESCHEDULE_SESSION:
      goBackToMainText = TITLES.PAGE_NAME_RESCHEDULE_SESSION;
      break;
    case PATHS.PATH_RESCHEDULE_SESSION_OLD:
      goBackToMainText = TITLES.PAGE_NAME_RESCHEDULE_SESSION;
      break;
    default:
      goBackToMainText = TITLES.PAGE_NAME_SESSION_REPORT;
  }

  const onGoBackToMainClick = (e) => {
    e.preventDefault();
    goBack({ appView: CONSTANTS.APP_VIEW_DEFAULT });
    resetContactFlowState();
  };

  const onGoBackToOptionsClick = (e) => {
    e.preventDefault();
    goBack({ appView: CONSTANTS.APP_VIEW_CONTACT_OPTIONS });
  };

  return (
    <div className="text-center pt-4 lg:pt-7 pb-10 bg-slate-1 c-section--banner">
      <div className="font-body c-section--go-back">
        { title !== null && <span className="block mb-3 font-bold text-lg md:text-xl lg:text3xl">{title}</span> }
        <span className="block mb-3 text-sm uppercase font-bold">Go Back to</span>
        <button
          type="button"
          className="py-0 px-2 border-0 bg-transparent font-bold relative"
          onClick={onGoBackToMainClick}
        >
          { goBackToMainText }
        </button>
        {
          goBackToOptions && (
            <button
              type="button"
              className="py-0 px-2 border-0 bg-transparent font-bold relative"
              onClick={onGoBackToOptionsClick}
            >
              Enquiry options
            </button>
          )
        }
      </div>
    </div>
  );
};

GoBack.propTypes = {
  goBackToOptions: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
  resetContactFlowState: PropTypes.func.isRequired,
  title: PropTypes.string,
};

GoBack.defaultProps = {
  title: null,
};

export default connect(
  (state) => {
    return {
      goBackToOptions: state.ui.appView === CONSTANTS.APP_VIEW_CONTACT_FORM,
    };
  },
  (dispatch) => {
    return {
      goBack: bindActionCreators(updateAppView, dispatch),
      resetContactFlowState: bindActionCreators(resetConactFlowState, dispatch),
    };
  },
)(GoBack);
