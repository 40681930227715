import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ChevronDownIcon } from '../../../../../../icons';

export const AccordionBase = ({
  isOpen, onToggle, title, subtitle, children, showBottomBorder
}) => {
  const chevronClasses = classNames('transition-transform', isOpen ? 'rotate-180' : 'rotate-0')

  return (
    <div className="hover:bg-slate-1 px-6">
      <button
        type="button"
        onClick={onToggle}
        className={classNames("pt-3 flex justify-between items-center w-full", !isOpen && showBottomBorder ? 'border-b border-grey-2 pb-3' : '')}
      >
        <div className={classNames('flex flex-col items-start w-full text-left', isOpen && 'text-grey-6')}>
          {typeof title === 'string' ? <div className="text-lg font-display font-bold">{title}</div> : title}
          {typeof subtitle === 'string' ? <div className="text-lg font-display">{subtitle}</div> : subtitle}
        </div>
        <div className={classNames(chevronClasses)}>
          <ChevronDownIcon width={15} height={15} />
        </div>
      </button>
      <div className={classNames("mt-2 pb-3", showBottomBorder && 'border-b border-grey-2',isOpen && 'block', !isOpen && 'hidden')}>{children}</div>
    </div>
  );
};

AccordionBase.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  showBottomBorder: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

AccordionBase.defaultProps = {
  subtitle: '',
};

const Accordion = ({ title, subtitle, children, showBottomBorder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onToggle = () => setIsOpen(!isOpen);

  return (
    <AccordionBase isOpen={isOpen} onToggle={onToggle} title={title} subtitle={subtitle} showBottomBorder={showBottomBorder}>
      {children}
    </AccordionBase>
  );
};

Accordion.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  showBottomBorder: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Accordion.defaultProps = {
  subtitle: '',
};

export default Accordion;