
import { get } from 'lodash';
import * as actionTypes from '../actionTypes';
import { setStaffToken } from '../../api/auth';

import {
  AUTHENTICATION_STATUS, ROLE_STAFF, ROLE_PORTAL_USER,
} from '../../util/constants';


export const saveCredentials = params => (dispatch) => {
  const { onSuccess = () => {}, staffToken, credentials } = params || {};
  const payload = {};
  payload.authenticationStatus = AUTHENTICATION_STATUS.AUTHENTICATED;
  payload.credentials = credentials;

  if (staffToken) {
    payload.role = ROLE_STAFF;
    payload.staffToken = staffToken;
    setStaffToken(staffToken);
  } else {
    payload.role = ROLE_PORTAL_USER;
    payload.staffToken = null;
    setStaffToken(null);
  }

  dispatch({
    type: actionTypes.HUB_AUTH_USER_AUTHENTICATED,
    payload,
  });

  onSuccess();
};

export const handleGetCredentialsFailed = e => (dispatch) => {
  const errorMsg = get(e, 'message', e);

  setStaffToken(null);

  dispatch({
    type: actionTypes.HUB_AUTH_USER_AUTHENTICATION_ERROR,
    payload: {
      authenticationStatus: AUTHENTICATION_STATUS.AUTHENTICATION_ERROR,
      errorMsg,
    },
  });
};

export const handleClockSkewDetected = () => (dispatch) => {
  dispatch({ type: actionTypes.HUB_GLOBAL_CLOCK_SKEW_DETECTED });
};
