import { useQuery } from "@tanstack/react-query"
import { TutorAvailabilitiesService } from "../../services/TutorAvailabilitiesService"
import { AVAILABLE_SLOTS_FOR_PACKAGE_QKEY } from "../../util/constants/queryKeys"
import { getDaysBetweenSessions } from "../../util/helper";

// eslint-disable-next-line valid-jsdoc
/**
 * @deprecated This function has been deprecated in favor of api/services/schedule.availableSlots
 *
 */
export const useGetAvailableSlotsForPackage = (packageId, tutorId, rescheduleFrom, sessionFrequency) => {
  const daysBetweenSessions = getDaysBetweenSessions({ sessionFrequency });

  return useQuery({
    queryKey: [AVAILABLE_SLOTS_FOR_PACKAGE_QKEY, packageId],
    queryFn: () => TutorAvailabilitiesService.getAvailableSlotsForPackage(packageId, tutorId, rescheduleFrom, daysBetweenSessions),
    staleTime: 300000,
    enabled: !!packageId,
  });
}