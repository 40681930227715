import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from '../components/Loader/Loader';

export default function FullscreenLoader({ isOpen }) {
	return (
		<React.Fragment>
			{isOpen && (
				<div className="full-page-loading fixed flex h-full w-full flex-col items-center justify-center bg-light">
					<div className="cluey-loading-block flex w-full items-center justify-center">
						<Loader className="pb-8" />
					</div>
					<p>Loading your Cluey history, this may take a few seconds...</p>
				</div>
			)}
		</React.Fragment>
	);
}

FullscreenLoader.propTypes = {
	isOpen: PropTypes.bool.isRequired,
};
