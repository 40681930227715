import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { collapseBox, getOffsetX } from '@cluey/cluey-components/dist/helpers';
import { useFadeInOut } from '@cluey/cluey-components/dist/hooks';

type TooltipPosition = 'top' | 'bottom';

export interface TooltipProps extends React.PropsWithChildren {
  content: string;
  position?: TooltipPosition;
  className?: string;
  testing?: string;
}

const getTooltipOffsetX = (ref: React.MutableRefObject<HTMLElement | null>) => {
  if (ref.current) {
    const rect = ref.current.getBoundingClientRect();
    const margins = getOffsetX(rect, ref.current.offsetWidth, document.body.offsetWidth);
    return collapseBox(margins);
  } else {
    return `0px 0px 0px 0px`;
  }
};

// needs more work so tooltip doesn't overflow outside page
export const Tooltip = ({ children, content, className, position = 'top', testing }: TooltipProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { display, animateIn } = useFadeInOut(showTooltip);

  const tooltipRef = useRef<HTMLDivElement | null>(null);

  return (
    <span
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      onFocus={() => setShowTooltip(true)}
      onBlur={() => setShowTooltip(false)}
      className={classNames('relative', className)}
    >
      {children}
      {display && (
        <div
          ref={tooltipRef}
          className={classNames(
            'absolute left-1/2 my-2 flex w-[300px] -translate-x-1/2 items-end justify-center transition-opacity',
            position === 'top' ? 'bottom-full' : 'top-full',
            animateIn ? 'opacity-100' : 'opacity-0'
          )}
          data-testid={testing && 'tooltip-container'}
        >
          <div
            className="relative rounded bg-black bg-opacity-75 px-2 py-1 text-sm text-white"
            style={{ margin: getTooltipOffsetX(tooltipRef) }}
          >
            {content}
          </div>
          <div
            className={classNames(
              'absolute left-1/2 w-4 -translate-x-1/2 overflow-hidden px-1',
              position === 'top' ? 'top-full' : 'bottom-full'
            )}
            data-testid={testing && 'tooltip-arrow-container'}
          >
            <div
              className={`h-2 w-2  rotate-45 bg-black bg-opacity-75 ${
                position === 'top' ? '-translate-y-1/2' : 'translate-y-1/2'
              }`}
              data-testid={testing && 'tooltip-arrow'}
            ></div>
          </div>
        </div>
      )}
    </span>
  );
};
