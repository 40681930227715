import { useRef } from 'react';
import { ReactComponent as Minus } from '../../../../assets/images/atoms-icon-filled-minus.svg';
import type { ParsedQuestionAndAnswers } from '../../types';
import FAQAnswer from '../FAQAnswer/FAQAnswer';
import { useAnimateInOut } from '@cluey/cluey-components/dist/hooks';
import { Anchor } from '@cluey/cluey-components';
import { useMaxHeight } from './helper';
import { api } from '../../../../api';
import { WEBSITE_DOMAIN } from '../../../../util/constants';

export type FAQItemProps = {
  id: string;
  item: ParsedQuestionAndAnswers;
  itemConfig?: ItemConfig;
};
export type ItemConfig = {
  answerComponent?: (props: Omit<FAQItemProps, 'itemConfig'>) => JSX.Element;
};
const FAQItem = ({ id, item, itemConfig }: FAQItemProps) => {
  const { answerComponent } = itemConfig || {};
  const { question, answers } = item;
  const { animateIn, toggle, display } = useAnimateInOut(false);
  const contentsRef = useRef<HTMLDivElement | null>(null);
  const contentsMaxHeight = useMaxHeight(contentsRef, animateIn);
  const { data: details } = api.account.details.useQuery();
  const href = `${WEBSITE_DOMAIN[`${details.country}_DOMAIN`]}/service-fees`;

  const toggleAnswer = (e) => {
    e.preventDefault();
    toggle();
  };

  return (
    <div className="flex flex-col">
      <button
        id={`faq-button-${id}`}
        onClick={toggleAnswer}
        className="flex flex-row items-center border-t-2 border-grey-2 py-3"
      >
        <div className="relative mr-3 h-[12px] w-[12px] ">
          <Minus className="absolute mr-3 h-[12px] w-[12px]" />
          <Minus
            className="absolute mr-3 h-[12px] w-[12px]"
            style={{
              transition: 'transform 150ms ease-in-out',
              transform: `rotate(${display ? '0deg' : '90deg'})`,
            }}
          />
        </div>
        <div className="proxima-nova text-left text-lg text-blue-5 ">{question}</div>
      </button>

      <div
        ref={contentsRef}
        className={`overflow-hidden ${display ? 'visible' : 'invisible'} `}
        id={`faq-panel-${id}`}
        data-testid={`faq-panel-${id}`}
        aria-labelledby={`faq-button-${id}`}
        style={{
          transition: 'max-height 150ms ease-in-out',
          maxHeight: contentsMaxHeight,
        }}
      >
        {question === 'How much am I going to be charged?' && (
          <p className="proxima-nova mb-4 ml-7 text-base-sm italic ">
            Please note that A credit card surcharge may apply to cover the processing fees associated with the type of
            card used for payment. Refer to{' '}
            <Anchor className="underline" href={href} target="_blank">
              Cluey Service Fees
            </Anchor>{' '}
            for more details.
          </p>
        )}
        {answerComponent?.({ id, item }) || <FAQAnswer answers={answers} />}
      </div>
    </div>
  );
};
export default FAQItem;
