import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router';
import isNil from 'lodash/isNil';
import { Enrolment } from '../../../../../api/types/enrolment';
import { Session } from '../../../../../api/types/session';
import { Student } from '../../../../../api/types/student';
import { HubSessionsListItem } from '../../../../../components/hub/sessions/HubSessionsListItem';
import { ENROLMENT_STATUS } from '../../../../../types/hubEnums';
import { PATH_HUB_CR_ADD_ENROLMENT, PATH_HUB_ENROLMENT_MANAGE_PAUSE, PATH_HUB_MS_SESSION_TIMES } from '../../../../../util/pagePath';
import { fetchEnrolment } from '../../../../../actions/hub/hubEnrolmentManagementActions';
import { isEnrolmentPausedCheck, isGroupSessionCheck } from '../../../../../util/helpers';
import { hasCancelRequested, hasTrial } from './common';

type SnapshotUpcomingSession = {
	type: 'session';
	session: Session;
};

type SnapshotUpcomingStudent = {
	type: 'student';
	enrolment?: Enrolment;
	student: Student;
};

export type SnapshotUpcomingItem = SnapshotUpcomingSession | SnapshotUpcomingStudent;

type EnrolmentSessionProps = {
	timezone: string;
} & SnapshotUpcomingItem;

const getEnrolmentInfo = (enrolment: Enrolment) => {
	if (hasTrial(enrolment)) {
		return (
			<>
				<p>
					{enrolment.yearLevel} {enrolment.subject} trial completed.
				</p>
				<p className="font-bold">No active ongoing enrolment</p>
			</>
		);
	} else if (hasCancelRequested(enrolment)) {
		return (
			<>
				<p>
					{enrolment.yearLevel} {enrolment.subject} {enrolment.tutor && `(with ${enrolment.tutor})`}
				</p>
				<p className="font-bold">Cancellation requested</p>
			</>
		);
	}
	return null;
};

export const EnrolmentSession = (props: EnrolmentSessionProps) => {
	const history = useHistory();
	const dispatch = useDispatch();
	if (props.type === 'student') {
		const enrolmentInfo = props.enrolment ? getEnrolmentInfo(props.enrolment) : null;
		const { firstName, lastName, yearLevel, location, studentId } = props.student;
		const onEnrolNow = () => {
			history.push(PATH_HUB_CR_ADD_ENROLMENT, {
				firstName,
				lastName,
				yearLevel: isNil(yearLevel) ? undefined : yearLevel,
				location: isNil(location) ? undefined : location,
				studentId,
			});
		};
		const enrolmentId = props.enrolment?.enrolmentId || '';
		const isPaused = isEnrolmentPausedCheck({ enrolmentStatus: props.enrolment?.enrolmentStatus });
		const isGroup = isGroupSessionCheck(props.enrolment?.type);
		const sessionsAndScheduleOptionsPath = !!enrolmentId ? generatePath(PATH_HUB_MS_SESSION_TIMES, { enrolmentId }) : '';
		const managePausePath = `${PATH_HUB_ENROLMENT_MANAGE_PAUSE}?token=${enrolmentId}`;
		const clearPreviouslySelectedEnrolment = () => {
			dispatch(fetchEnrolment({ enrolmentId })); // see: components/hub/enrolments/EnrolmentCard/EnrolmentCard.tsx line 88 for explanation
		}
		const manageEnrolment = () => {
			if (isPaused) {
				clearPreviouslySelectedEnrolment();
			}
			history.push(isPaused && !isGroup ? managePausePath : sessionsAndScheduleOptionsPath);
		}

		return (
			<div className="flex flex-col justify-between gap-2 rounded-lg border-2 border-dashed border-grey-2 px-7 py-4 md:flex-row md:items-center">
				<div className="flex flex-1 flex-col flex-wrap md:flex-row">
					<div className="flex flex-1 flex-wrap items-center gap-2">
						<p className="font-bold">{props.student.firstName}</p>
						<div className="h-3 w-0.5 rounded-full bg-slate-4 opacity-40"></div>
						{enrolmentInfo ?? <p className="">No active enrolment</p>}
					</div>
					{(props.enrolment ? props.enrolment.enrolmentStatus !== ENROLMENT_STATUS.CANCEL_REQUESTED : true) && (
						<button className="self-start text-blue-5 mt-2 md:mt-0" onClick={onEnrolNow}>
							+ Enrol now
						</button>
					)}
					{(props.enrolment ? props.enrolment.enrolmentStatus === ENROLMENT_STATUS.CANCEL_REQUESTED : false) && (
						<button className="self-start text-blue-5 mt-2 md:mt-0" onClick={manageEnrolment}>
							Manage
						</button>
					)}
				</div>
			</div>
		);
	} else {
		return <HubSessionsListItem session={props.session} timezone={props.timezone} />;
	}
};
