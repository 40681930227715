import { Fragment, useEffect } from 'react';
import moment from 'moment';
import { formatSessionAffectedByPause } from '../../../../util/helper/sessions';
import { AffectedSessionTitle } from './AffectedSessionTitle';
import { ListItem } from './ListItem';
import { isEmpty } from 'lodash';
import InPageLoader from '../../../../common/InPageLoader';
import { useDispatch, useSelector } from 'react-redux';
import {
  savePauseFromResumeFromDate as saveResumeFromDate,
  getResumeSession,
} from '../../../../actions/hub/hubContactPageActions';
import { InitialState } from '../../../../types/hubTypes';
import { DatesUtils } from '../../../../util/dates';
import { RESUME_SESSION_DATE_FORMAT } from '../../../../util/constants';
import type { Session } from '../../../../types/hubInterfaces';

interface Props {
  resumeFromDate: string;
  sessions: Array<Session>;
  timezone: string;
}

const AffectedSessions = ({ resumeFromDate, sessions, timezone }: Props) => {
  const dispatch = useDispatch();
  const { firstFetch: isLoadingResumeSessionDate, fetchError: loadResumeSessionDateFailed } = useSelector(
    (state: InitialState) => state.ui.apiState.hubContactPage.resumeSessionDate
  );
  const { resumeSessionDate } = useSelector((state: InitialState) => state.hubContactPage.pauseSessions);

  useEffect(() => {
    if (resumeFromDate) {
      const dateFormatted = moment(resumeFromDate).tz(timezone, true).format('YYYY-MM-DD');

      dispatch(
        saveResumeFromDate({
          resumeFromDate: dateFormatted,
        })
      );

      dispatch(getResumeSession());
    }

    return undefined;
    // eslint-disable-next-line
  }, [resumeFromDate, sessions]);

  if (isLoadingResumeSessionDate) {
    return <InPageLoader position="relative" height="auto" />;
  }

  const resumeFromSectionTitle = isEmpty(sessions) ? 'RESUME FROM' : 'AND RESUME FROM';
  // NOTE: resumeSessionDate comes from Salesforce and uses Sydney timezone
  const resumeSessionDateUTC = DatesUtils.convertFromTimezoneToUTC({
    stringDate: resumeSessionDate,
    dateFormat: RESUME_SESSION_DATE_FORMAT,
    timezone: 'Australia/Sydney',
  });

  return (
    <Fragment>
      <div className="min-w-[320px] border-l-2 border-grey-2 pl-5 md:min-w-[350px]">
        {!isEmpty(sessions) && (
          <div className="mb-5">
            <AffectedSessionTitle text="PAUSE WILL INCLUDE THESE SESSIONS" />
            <ul className="text-sm leading-snug">
              {sessions.map((s, index) => {
                const { startTime } = s;
                return (
                  <ListItem
                    key={`${startTime}-${index}`}
                    text={formatSessionAffectedByPause({ time: startTime, timezone })}
                  />
                );
              })}
            </ul>
          </div>
        )}

        {!loadResumeSessionDateFailed &&
          resumeSessionDate &&
          !resumeSessionDate.toLowerCase().includes('invalid date') &&
          !resumeSessionDate.toLowerCase().includes('error') && (
            <div>
              <AffectedSessionTitle text={resumeFromSectionTitle} />
              <ul className="fs-15px list-unstyled leading-snug">
                <ListItem text={formatSessionAffectedByPause({ time: resumeSessionDateUTC, timezone })} />
              </ul>
            </div>
          )}
      </div>
    </Fragment>
  );
};

export default AffectedSessions;
