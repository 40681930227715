import { datadogLogs, datadogRum } from './datadog';
import { INFO, WARNING, ERROR } from './levels';
import * as errors from './errors';

export { INFO, WARNING, ERROR };

const defaults = {
  level: ERROR,
  unexpected: false,
  message: undefined,
};

export function logError(error, extraData, options, logToDatadog = false) {
  let newOptions = { ...defaults, ...options };
  let exception;
  if (error instanceof Error) {
    // native error
    exception = error;
    if (newOptions.message) {
      exception.message = `${exception.message} - ${newOptions.message}`;
    }
  } else if (Object.values(errors).indexOf(error) !== -1) {
    // Predefined error
    newOptions = {
      ...error,
      ...newOptions,
      message: newOptions.message ? `${error.message} - ${newOptions.message}` : error.message,
    };
    exception = new Error(newOptions.message);
  } else if (typeof error === 'string') {
    // Error message
    exception = new Error(newOptions.message);
  }

  if (logToDatadog) {
    datadogRum.setError(exception, {
      extraData,
    });
    datadogLogs.logger.error(exception.message, extraData);
  }

  console.error(exception, extraData, newOptions);
  console.error(error);
}

export const ddLoggerLevel = {
  DEBUG: 'DEBUG',
  WARN: 'WARN',
  ERROR: 'ERROR',
  INFO: 'INFO',
  LOG: 'LOG',
};

export const ddLogger = ({ level, label, data, error }) => {
  console.log({
    level,
    label,
    data,
    error,
  });
  // datadog rum ux monitoring
  if (error instanceof Error) {
    datadogRum.setError(error, {
      ...data,
    });
  }

  // datadog log
  switch (level) {
    case ddLoggerLevel.DEBUG:
      return datadogLogs.logger.debug(label, data);
    case ddLoggerLevel.WARN:
      return datadogLogs.logger.warn(label, data);
    case ddLoggerLevel.ERROR:
      return datadogLogs.logger.error(label, data);
    case ddLoggerLevel.INFO:
      return datadogLogs.logger.info(label, data);
    case ddLoggerLevel.LOG:
    default:
      return datadogLogs.logger.log(label, data);
  }
};

export default {
  errors,
  logError,
  ddLogger,
  ddLoggerLevel,
};
