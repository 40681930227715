interface Props {
  student: string;
  subject: string;
  tutor: string;
}

const SessionDetails = ({ student, subject, tutor }: Props) => {
  return (
    <span className="p-0 text-left text-base">
      <span className="font-bold">{student}</span>
      <span> | {subject} (with {tutor})</span>
    </span>
  )
}

export default SessionDetails;