import { Anchor, BasicCard, Heading2, Link } from '@cluey/cluey-components';
import EnrolmentLayout from '../../common/hub/EnrolmentLayout';
import { Container } from '@cluey/cluey-components';
import {
  PATH_HUB_ACCOUNT_DETAILS,
  PATH_HUB_MS_BILLING_AND_PAYMENTS,
  PATH_HUB_MS_STUDENTS_AND_SUBJECTS,
  PATH_HUB_UPDATES,
} from '../../util/pagePath';
import { ServiceOptionsList } from '../../components/ServiceOptionsList/ServiceOptionsList';
import { ELEMENT_TYPES, SUPPORT_OPTIONS } from '../../types/hubEnums';
import { useGetPolicies } from './hooks';
import Tick from '../../assets/images/icon-filled-tick.svg';
import { useSelector } from 'react-redux';
import { ReactComponent as ChatIcon } from '../../assets/images/atoms-icon-outline-rocket-chat.svg';
import { useRocketChatContext } from '../../util/RocketChat/RocketChatProvider';
import { OptionCard } from '../../components/EnrolmentManagement/OptionCard';
import { stringifyBusinessHours, getChatSupportHours } from '../../util/RocketChat/helpers';
import { useMemo } from 'react';
import InPageLoader from '../../common/InPageLoader';
import { getTimezone } from '../../selectors/hubSelector';
import { PostHogEvent } from '../../util/posthog';

export type SupportOption = {
  title: SUPPORT_OPTIONS;
  linkName: string;
  element: ELEMENT_TYPES;
  requiresActive: boolean;
  posthogEvent?: PostHogEvent;
  openInNewTab?: boolean;
  linkTo?: string;
}

const supportOptions: SupportOption[] = [
  {
    title: SUPPORT_OPTIONS.SESSION_TIMES,
    linkName: 'Reschedule, cancel or add a one-off session',
    element: ELEMENT_TYPES.BUTTON,
    requiresActive: true,
    posthogEvent: 'click_support_session-times',
  },
  {
    title: SUPPORT_OPTIONS.MANAGE_ENROLMENT,
    linkName: 'Change schedule, pause enrolment or request a change',
    element: ELEMENT_TYPES.BUTTON,
    requiresActive: true,
    posthogEvent: 'click_support_manage-enrolment',
  },
  {
    title: SUPPORT_OPTIONS.LEARNING_FEEDBACK,
    linkName: 'Review session recordings, feedback or reports',
    element: ELEMENT_TYPES.BUTTON,
    openInNewTab: true,
    requiresActive: false,
    posthogEvent: 'click_support_learning-feedback',
  },
  {
    title: SUPPORT_OPTIONS.LEARNING_ADJUSTMENTS,
    linkName: 'Manage tutor, learning program or content',
    element: ELEMENT_TYPES.BUTTON,
    requiresActive: true,
    posthogEvent: 'click_support_learning-adjustments',
  },
  {
    title: SUPPORT_OPTIONS.STUDENTS_AND_SUBJECTS,
    linkName: 'Enrol a student into a subject',
    element: ELEMENT_TYPES.LINK,
    linkTo: PATH_HUB_MS_STUDENTS_AND_SUBJECTS,
    requiresActive: false,
    posthogEvent: 'click_support_add-a-subject',
  },
  {
    title: SUPPORT_OPTIONS.BILLING_AND_PAYMENTS,
    linkName: 'Review invoices and manage payment methods',
    element: ELEMENT_TYPES.LINK,
    linkTo: PATH_HUB_MS_BILLING_AND_PAYMENTS,
    requiresActive: false,
    posthogEvent: 'click_support_billing-and-payments',
  },
  {
    title: SUPPORT_OPTIONS.ACCOUNT_DETAILS,
    linkName: 'Manage personal contact details',
    element: ELEMENT_TYPES.LINK,
    linkTo: PATH_HUB_ACCOUNT_DETAILS,
    requiresActive: false,
    posthogEvent: 'click_support_account-details',
  },
  {
    title: SUPPORT_OPTIONS.TECHNOLOGY,
    linkName: 'Review technical requirements or get help accessing sessions',
    element: ELEMENT_TYPES.BUTTON,
    requiresActive: true,
    posthogEvent: 'click_support_technology',
  },
  {
    title: SUPPORT_OPTIONS.CANCEL_ENROLMENT,
    linkName: 'Let us know what didn\'t work for you',
    element: ELEMENT_TYPES.BUTTON,
    requiresActive: true,
    posthogEvent: 'click_support_cancel-enrolment',
  },
  {
    title: SUPPORT_OPTIONS.REPORT_ISSUE,
    linkName: 'Let us know if you encounter any problems',
    element: ELEMENT_TYPES.BUTTON,
    requiresActive: true,
    posthogEvent: 'click_support_report-an-issue',
  },
  {
    title: SUPPORT_OPTIONS.CUSTOMER_HUB_UPDATES,
    linkName: "Learn more about the latest updates and what's new in Customer Hub",
    element: ELEMENT_TYPES.LINK,
    linkTo: PATH_HUB_UPDATES,
    requiresActive: false,
    posthogEvent: 'click_support_customer-hub-updates',
  },
];

const InfoItem = ({ children }: React.PropsWithChildren) => (
  <div className="flex ">
    <img src={Tick} alt="Tick Mark" className="mr-4" />
    <div className="text-lg">{children}</div>
  </div>
);

type AccountDetailsSelectorShape = {
  hubAccountPage: {
    accountDetails: {
      country: string;
      userType: 'Account' | 'Lead'
    };
  };
  hubEnrolmentsPage: {
    activeEnrolments?: unknown[];
  };
  ui: {
    apiState: {
      hubActiveEnrolmentsPage: {
        isLoading: boolean;
      };
    };
  };
};

const Support = () => {
  const { accountDetails, activeEnrolmentCount, activeEnrolmentsLoading } = useSelector(
    (state: AccountDetailsSelectorShape) => ({
      accountDetails: state.hubAccountPage.accountDetails,
      activeEnrolmentCount: state.hubEnrolmentsPage.activeEnrolments?.length || 0,
      activeEnrolmentsLoading: state.ui.apiState.hubActiveEnrolmentsPage.isLoading,
    })
  );
  
  const timezone = useSelector(getTimezone);
  const policies = useGetPolicies(accountDetails?.country);
  const { addDisplayWidget, rocketChatEnabled } = useRocketChatContext();
  const chatButton = rocketChatEnabled && accountDetails.userType === "Account" && (
    <OptionCard key="rocket-chat" onClickHandler={addDisplayWidget}>
      <div className="flex flex-row">
        <div className="flex flex-col">
          <div className="text-xl font-bold">Can't find what you are looking for?</div>
          <div className="mt-2 text-xl text-blue-5">Chat with us {stringifyBusinessHours(getChatSupportHours(), timezone)}</div>
        </div>
        <div className="ml-auto flex items-center justify-center">
          <ChatIcon className="flex" />
        </div>
      </div>
    </OptionCard>
  );

  const options = useMemo(() => {
    return activeEnrolmentCount > 0 ? supportOptions : supportOptions.filter((opt) => !opt.requiresActive);
  }, [activeEnrolmentCount]);

  return (
    <EnrolmentLayout
      sectionHeading="Home"
      mainHeading="Support"
      showEnrolmentStatus={false}
      showSummerModeEnrolmentStatus={false}
    >
      <Container>
        <BasicCard>
          <div className="py-5 px-8">
            <div className="mb-2 text-2xl font-bold">How can we help?</div>
            <div className="flex flex-col  gap-y-2">
              <InfoItem>Please tell us what you need help with by selecting one of the following options.</InfoItem>
              <InfoItem>
                Most of the options are self-service which means you will be guided to make the change yourself.
              </InfoItem>
              <InfoItem>
                In some cases, you won't be able to make the change yourself but you will be invited to raise a request
                for one of our customer care agents to review and get back to you.
              </InfoItem>
            </div>
          </div>
        </BasicCard>
        <section>
          {activeEnrolmentsLoading ? (
            <InPageLoader position="relative" />
          ) : (
            <ServiceOptionsList options={options} additionalOptionComponents={[chatButton]} />
          )}
        </section>
        <section className="mt-11">
          <Heading2>Policies</Heading2>
          <ul className="flex flex-col gap-y-2">
            {policies.map(({ text, to, elementType }) => (
              <li key={text}>
                {elementType === ELEMENT_TYPES.ANCHOR ? (
                  <Anchor href={to} target="_blank">
                    {text}
                  </Anchor>
                ) : (
                  <Link to={to}>{text}</Link>
                )}
              </li>
            ))}
          </ul>
        </section>
      </Container>
    </EnrolmentLayout>
  );
};

export { Support };
