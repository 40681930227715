import { sendGetRequest } from '../RequestService';
import { getPortalAPIRequestParams } from './helper';

export class TutorService {
  static async getTutorInfo({ tutorNumber }) {
    if (!tutorNumber) {
      throw new Error('something went wrong!');
    }

    const path = `tutorinfo/${tutorNumber}`;
    const { url, reqOptions } = getPortalAPIRequestParams(path);

    try {
      const res = await sendGetRequest({
        url,
        signRequest: true,
        reqOptions,
        customErrorMsg: `Unable to fetch tutor information for tutor with tutor number ${tutorNumber}`,
      });
      return res.json();
    } catch (error) {
      throw error;
    }
  }
}
