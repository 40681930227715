import { isEmpty } from 'lodash';
import initialState from './initialState';
import { SR_REMOVE_ALL_ACTIVITIES, SR_REQUEST_ACTIVITIES_SUCCESS } from '../actions/actionTypes';

export default function nextSessionReducer(
  state = initialState.nextSession,
  action,
) {
  switch (action.type) {
    case SR_REMOVE_ALL_ACTIVITIES:
      return {};
    case SR_REQUEST_ACTIVITIES_SUCCESS: {
      if (isEmpty(action.payload.nextSessionData) || action.payload.nextSessionData.message) {
        return state;
      }
      const {
        timezone,
        tutorName,
        startTime,
        endTime,
        duration,
        manageSessionLink,
        studentSessionId,
        sessionType,
      } = action.payload.nextSessionData;
      const { token, host, link } = manageSessionLink;

      return {
        ...state,
        timezone,
        tutorName,
        startTime,
        endTime,
        duration,
        sessionType,
        sessionId: studentSessionId,
        manageSessionLink: {
          token,
          host,
          link,
        },
      };
    }

    default:
      return state;
  }
}
