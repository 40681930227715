import 'katex/dist/katex.min.css';

import { useRef } from 'react';
import { useParams } from 'react-router';
import { MathfieldElement } from 'mathlive';
import { isEmpty, isNull } from 'lodash';
import posthog from 'posthog-js';
import { CompressedImage, QuestionInputRef } from '../../QuestionInput';
import useGetRemainingRequests from '../../useGetRemainingRequests';
import Message from './Message';
import useScrollToBottom from './useScrollToBottom';
import RequestLimitAlert from '../../RequestLimitAlert';
import { ConversationLayout } from '../ConversationLayout';
import useAiTutorContext from '../../useAiTutorContext';
import { TMessage } from '../../useMessageStore';
import { api } from '../../../../api';
import { ExclamationMarkIcon } from '../../../../icons';

const EMPTY_QUESTION_MESSAGE = 'Write a question to ask Cluey Coach';

const Conversation = () => {
  const mathLiveFieldRef = useRef<MathfieldElement>();
  const { enrolmentId } = useParams<{ enrolmentId: string }>();
  const { status, data: remainingRequests } = useGetRemainingRequests(enrolmentId);
  const { messages, addMessage, updateMessage, removeMessage, chatId, question, setQuestion, topic, error, setError } =
    useAiTutorContext();
  const disabled = remainingRequests <= 0 || status === 'loading';

  const scrollAnchor = useScrollToBottom(messages);

  const { mutate } = api.virtualCoach.askQuestion.useMutation({
    onMutate: () => {
      const message = addMessage({
        sender: 'tutor',
        content: "I'm thinking...",
        timestamp: new Date().toISOString(),
      });

      return { tutorMessage: message }; // the returned value of onMutate is stored in the mutation's context (3rd argument of onSuccess)
    },
    onSuccess: async (response, _, context: { tutorMessage: TMessage }) => {
      updateMessage(context.tutorMessage.id, response.reply);
      mathLiveFieldRef.current.focus();
      api.enrolment.all.invalidateQueries('active');
      setError(null);
    },
    onError: (error: Error, _, context: { tutorMessage: TMessage }) => {
      removeMessage(context.tutorMessage.id);
      setError(error);
    },
  });

  return (
    <ConversationLayout
      headerAction
      headerActionDisabled={disabled}
      bottomAction={false}
      bodyClassName="!gap-y-0 !pb-0"
    >
      <div>
        <RequestLimitAlert enrolmentId={enrolmentId} />
        <div
          ref={scrollAnchor}
          className="my-3 flex max-h-[500px] flex-col space-y-3 overflow-y-auto overflow-x-hidden"
        >
          {messages.map((message) => {
            return (
              <Message
                key={message.id}
                content={message.content}
                attachment={message.attachment}
                sender={message.sender}
              />
            );
          })}
        </div>
        <div className="sticky bottom-0 mt-auto bg-white">
          <QuestionInputRef
            disabled={disabled}
            value={question}
            placeholder="\text{Ask a follow up question}"
            ref={mathLiveFieldRef}
            onInput={(evt) => {
              const mathfield = evt.target as MathfieldElement;
              setQuestion(mathfield.getValue());
            }}
            onFileChanged={(_, fileError) => {
              setError(!isNull(fileError) ? fileError : null);
            }}
            onSubmit={(value, attachment: CompressedImage) => {
              if (isEmpty(value)) {
                setError(new Error(EMPTY_QUESTION_MESSAGE));
              } else {
                addMessage({
                  content: value,
                  attachment: !isNull(attachment.blob) ? URL.createObjectURL(attachment.blob) : undefined,
                  sender: 'user',
                });
                setQuestion('');
                setError(null);
                mutate({
                  enrolmentId,
                  question: value,
                  topicKey: topic.topicKey,
                  chatId,
                  imageBase64: attachment.src || undefined,
                });
                posthog.capture('cluey-coach_submit-question', {
                  $question: question,
                  $topic: topic.topicKey,
                  $chatId: chatId,
                  $hasAttachment: !!attachment?.src,
                });
              }
            }}
            errorMessage={
              error ? (
                <div className="flex flex-row items-start gap-x-1 font-body text-sm font-medium text-red-5">
                  <div className="mt-1">
                    <ExclamationMarkIcon className="text-red-5" width={12} height={12} />
                  </div>
                  <span className="text-sm text-red-5">
                    {error.message || 'Oops! Something went wrong'}.{' '}
                    {error.message.includes(EMPTY_QUESTION_MESSAGE) ? '' : 'Please try again later.'}
                  </span>
                </div>
              ) : null
            }
          />
          <div className="p-2 text-center text-sm text-grey-5">
            Cluey Coach can make mistakes. Check important information. Be smart online. Never share private or personal
            information.
          </div>
        </div>
      </div>
    </ConversationLayout>
  );
};

export default Conversation;
