import { get } from 'lodash';
import { AUTHENTICATION_STATUS } from '../util/constants';
import { isGroupSessionCheck } from '../util/helpers';

const isAuthenticated = (state) => {
  const authenticationStatus = get(state, 'login.authenticationStatus');
  return authenticationStatus === AUTHENTICATION_STATUS.AUTHENTICATED;
};

export function getDiscipline(state) {
  return isAuthenticated(state) ?
    encodeURIComponent(get(state, 'hubEnrolmentDetailPage.enrolmentDiscipline', '')) :
    encodeURIComponent(get(state, 'report.discipline', ''));
}

export function getSubject(state) {
  return isAuthenticated(state) ?
    encodeURIComponent(get(state, 'hubEnrolmentDetailPage.enrolmentSubject', '')) :
    encodeURIComponent(get(state, 'report.subject', ''));
}

const getEnrolmentYearLevel = (state) => {
  const discipline = get(state, 'hubEnrolmentDetailPage.enrolmentDiscipline', '');
  const enrolmentYearLevel = get(state, 'hubEnrolmentDetailPage.enrolmentYearLevel');
  if (discipline && discipline.toLowerCase().includes('chemistry') &&
    (enrolmentYearLevel.includes('11') || enrolmentYearLevel.includes('12'))) {
    return encodeURIComponent('Year 11/12');
  }
  return encodeURIComponent(enrolmentYearLevel);
};

export function getYearLevel(state) {
  return isAuthenticated(state) ?
    getEnrolmentYearLevel(state) :
    encodeURIComponent(get(state, 'report.yearLevel', ''));
}

const getEnrolmentType = (state) => {
  const type = get(state, 'hubEnrolmentDetailPage.enrolmentType', '');
  return isGroupSessionCheck(type) ? 'Group' : '1-to-1';
};

export function getCourseMode(state) {
  return isAuthenticated(state) ?
    encodeURIComponent(getEnrolmentType(state)) :
    encodeURIComponent(get(state, 'report.mode', ''));
}

export function getStudentId(state) {
  return isAuthenticated(state) ?
    encodeURIComponent(get(state, 'hubEnrolmentDetailPage.customerNumber', '')) :
    encodeURIComponent(get(state, 'login.customerId', ''));
}
