import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@cluey/cluey-components';

export const ClueyModal = ({ open, setOpen, children, closeOnBackground }) => {
  return (
    <Modal closeOnBackground={closeOnBackground} open={open} setOpen={setOpen} closeButton={true} accent={true} className="!px-0 !pb-0 !pt-16 lg:!pt-24">
      {children}
    </Modal>
  );
};

ClueyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  closeOnBackground: PropTypes.bool,
};

ClueyModal.defaultProps = {
  closeOnBackground: true,
}