import { Button, Modal } from '@cluey/cluey-components';
import posthog from 'posthog-js';

export interface NewChatModalProps {
  open: boolean;
  setOpen(value: boolean): void;
  startNewChat(): void;
}

export const NewChatModal = ({ open, setOpen, startNewChat }: NewChatModalProps) => {
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      className="z-10 m-auto w-full max-w-sm !p-10 md:max-w-md md:!p-[60px] lg:max-w-[560px]"
      accent={false}
      closeButton
      closeOnBackground
    >
      <h2 className="proxima-nova text-left text-4xl font-bold">Start a new chat?</h2>
      <p className="my-10 text-lg">
        You will not be able to continue to edit your current chat once a new chat has started.
      </p>
      <div className="flex flex-col gap-x-3 gap-y-3 md:flex-row">
        <Button
          type="button"
          size="lg"
          className="group flex w-full place-content-center !border-0 !px-5 !py-3"
          appearance="reverse"
          onClick={() => {
            posthog.capture('click_cluey-coach_cancel-new-chat');
            setOpen(false);
          }}
        >
          <span className="text-lg font-bold">Stay on current chat</span>
        </Button>
        <Button
          type="button"
          size="lg"
          className="group flex w-full place-content-center !border !px-[35px] !py-3"
          onClick={() => {
            posthog.capture('click_cluey-coach_new-chat');
            startNewChat();
          }}
        >
          <span className="text-lg font-bold">Start a new chat</span>
        </Button>
      </div>
    </Modal>
  );
};
