import { Anchor } from '@cluey/cluey-components';
import phoneIconSvg from './assets/phone-icon.svg';
import { api } from '../../../../api';

export const LearningAdvisorWidget = () => {
  const { data: details } = api.account.details.useQuery();

  return (
    <div className="container mx-auto px-4 pb-4">
      <div className="rounded bg-slate-1 px-6 py-4">
        <p className="pb-1 font-bold">Want to learn more about how we can support your learning?</p>
        <div className="flex items-center gap-2">
          <img src={phoneIconSvg} alt="" />
          <p className="hidden sm:inline">
            Talk to a Learning Advisor on{' '}
            <span className="font-bold">{details && details.country === 'NZ' ? '0800 005 370' : '1300 182 000'}</span>
          </p>
          <p className="flex flex-col sm:hidden">
            <span>Talk to a Learning Advisor on</span>
            {details && details.country === 'AU' ? (
              <Anchor href="tel:1300182000">1300 182 000</Anchor>
            ) : (
              <Anchor href="tel:0800005370">0800 005 370</Anchor>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
