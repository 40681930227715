import React from 'react';
import PropTypes from 'prop-types';

const IconBox = ({ icon, children }) => {
  return (
    <div className="flex items-center h-full border border-grey-2 rounded-lg p-4 lg:p-5 font-display">
      <img src={icon} alt="icon" className="mr-6 w-6 h-6 lg:w-9 lg: h-9" />
      {children}
    </div>
  );
};

IconBox.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
};

IconBox.defaultProps = {
  icon: null,
};

export default IconBox;
