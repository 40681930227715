import { Alert, Pending } from "@cluey/cluey-components";
import { Loader } from '../../../../../components/Loader';
import { useSelector } from "react-redux";
import { getSessionsOnDateAndBeyond } from "../../../../../selectors/sessionSelector";
import { getTimezone } from "../../../../../selectors/hubSelector";
import { useGetSessions } from "../../../../../hooks/queries/useSessionQuery";
import { ENROLMENT_STATUS } from "../../../../../types/hubEnums";
import { getWillEnrolmentBePaused } from "../../../../../util/helper/enrolment";
import { useGetBulkSessionPolicy } from "../../../../../hooks/queries/useSessionQuery";
import { getCancelEnrolmentSummaryAlertData } from "../../../../../util/helper/cancelEnrolment";
import { api } from "../../../../../api";
import orderBy from "lodash/orderBy";
import moment from "moment";
import type { Enrolment } from "../../../../../api/types/enrolment";

interface Props {
  selectedEnrolment: Enrolment;
  selectedDate: string;
}

function CancelEnrolmentSummaryAlert({ selectedEnrolment, selectedDate }: Props) {
  const { enrolmentId, enrolmentStatus, enrolmentPausedFrom, enrolmentPausedTo } = selectedEnrolment;
  const timezone = useSelector(getTimezone);
  const { data: upcomingSessions = [], isLoading: loadingUpcomingSessions } = api.session.all.useQuery('upcoming', {
    select: (data) => {
      const filteredSessions = data.filter(s => {
        return moment(s.startTime).isSameOrAfter(new Date(), 'day');
      });
      return orderBy(filteredSessions, ['startTime'], ['asc']);
    }
  });
  const pausedOrWillBe = enrolmentStatus === ENROLMENT_STATUS.PAUSED || getWillEnrolmentBePaused({ 
    enrolmentPausedFrom,
    enrolmentPausedTo,
  });

  const { data: pausedSessions, isLoading: isPausedSessionsLoading } = useGetSessions({ 
    filter: 'paused',
    enabled: pausedOrWillBe,
  });

  const sessionsOnOrAfterCancellationDate = useSelector(getSessionsOnDateAndBeyond({
    date: selectedDate,
    enrolmentId,
    timezone,
    additionalSessions: pausedSessions ?? []
  }));

  const { data: bulkSessionPolicy, isLoading: bulkSessionPolicyLoading, error: bulkPolicyError } = useGetBulkSessionPolicy({
    sessionIds: sessionsOnOrAfterCancellationDate.map(session => session.studentSessionId),
    enabled: sessionsOnOrAfterCancellationDate.length > 0,
  });

  if (bulkPolicyError) {
    return (<Alert body="" type="error">There was an error retrieving the enrolment cancellation details.</Alert>)
  }

  const { 
    alertType,
    body
   } = getCancelEnrolmentSummaryAlertData({ 
    bulkSessionPolicy,
    enrolmentId,
    upcomingSessions,
    timezone,
    selectedDate
   });

  return (
    <Pending
      loading={
        (bulkSessionPolicyLoading && sessionsOnOrAfterCancellationDate.length > 0) ||
        (isPausedSessionsLoading && pausedOrWillBe) ||
        loadingUpcomingSessions
      }
      loader={<Loader />}>
      <Alert
        className="!my-7"
        type={alertType}
        title={null}
        body={null}>
          {body}
      </Alert>
    </Pending>
  )
}

export default CancelEnrolmentSummaryAlert;