import { get } from 'lodash';
import { logError } from '../../logging';
import * as actionTypes from '../actionTypes';
import { PauseService } from '../../services/PauseService';

// Submit to Mulesoft
export const submitNewPause =
  ({ startDate, endDate, enrolmentId, onSubmitSuccess, onSubmitFailed = () => {}, preventCancel = false }) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.HUB_CR_SUBMIT_CONTACT_CASE_START,
    });

    const actions = {
      start: actionTypes.HUB_CR_SUBMIT_CONTACT_CASE_START,
      success: actionTypes.HUB_CR_SUBMIT_CONTACT_CASE_SUCCESS,
      failed: actionTypes.HUB_CR_SUBMIT_CONTACT_CASE_FAILED,
    };

    dispatch({
      type: actions.start,
    });

    const pauseService = new PauseService();

    return pauseService
      .createNewPause({ enrolmentId, startDate, endDate, preventCancel })
      .then(() => {
        dispatch({ type: actions.success });
        onSubmitSuccess();
      })
      .catch((e) => {
        const errorMsg = get(e, 'message');
        dispatch({
          type: actions.failed,
          payload: {
            errorMsg,
          },
        });
        onSubmitFailed();
        logError(e, null, {
          message: `Unable to submit request new pause to MuleSoft for enrolment Salesforce ID: ${enrolmentId}`,
        });
      });
  };

export const submitChangePause =
  ({ enrolmentId, currentPauseStart, selectedResumeDate, callback, doNotOffsetEndDate = false }) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.HUB_CR_SUBMIT_CONTACT_CASE_START,
    });
    const pauseService = new PauseService();
    /*
          currentPauseStart is what's in Sales Force's Pause From field
      
          currentResumeDate is the UTC + 0 midnight format of what's the current
          resume date in customer hub UI; for instance, for an account in Australia/Sydney timezone
          the currentResumeDate will be 2023-06-04T14:00:00.000Z if it's June 5th in customer hub manage pause
      
          selectedResumeDate is the UTC + 0 midnight format of what's the selected
          new resume date in customer hub
        */
    return pauseService
      .changePause({
        enrolmentId,
        startDate: currentPauseStart,
        endDate: selectedResumeDate,
        doNotOffsetEndDate,
      })
      .then(() => {
        dispatch({ type: actionTypes.HUB_CR_SUBMIT_CONTACT_CASE_SUCCESS });
        callback();
      })
      .catch((e) => {
        console.error(e);
        const errorMsg = get(e, 'message');
        dispatch({
          type: actionTypes.HUB_CR_SUBMIT_CONTACT_CASE_FAILED,
          payload: {
            errorMsg,
          },
        });
        logError(e, null, {
          message: `Unable to submit request new pause to MuleSoft for enrolment Salesforce ID: ${enrolmentId}`,
        });
      });
  };
