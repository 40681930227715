import moment from 'moment';
import { isEmpty, sortBy } from 'lodash';
import {
  DATE_FORMAT_1,
  ENROLMENT_STATUSES,
  NO_OF_SESSIONS_PER_PAGE,
  PASSWORD_VALIDATION_RESULT,
  SESSION_AVAILABLE_DAYS,
  SESSION_AVAILABLE_TIMES,
  SESSION_TIME_FORMAT,
  SPECIAL_DATE_TYPE,
} from './constants';
import { validatePassword } from './validation/password';
import { PAUSE_OUTSIDE_POLICY } from './cancelEnrolmentReasons';
import { SUMMER_OFF_BOARDING_END_DATE } from './flags';
import { CompanyDetails } from '../types/hubInterfaces';

export const scrollToTop = () => {
  window.scroll({ top: 0, behavior: 'smooth' });
};

/**
 * Format session object
 * @param  {Object} session                    session object
 * @param  {String} timezone                   timezone
 * @param  {String} sessionDateFormat          sessionDateFormat
 * @return {Object}                            formatted session object with new properties
 */
export const formatSession = ({ session, timezone, sessionDateFormat = DATE_FORMAT_1, useSemiColon = true }) => {
  const sessionTimeFormat = SESSION_TIME_FORMAT;
  const { startTime, endTime, subject, tutor } = session;
  const sessionDate = moment(startTime).tz(timezone).format(sessionDateFormat);
  session.date = sessionDate;

  const sessionTime = `${moment(startTime).tz(timezone).format(sessionTimeFormat)} – ${moment(endTime)
    .tz(timezone)
    .format(sessionTimeFormat)}`;

  let sessionDetail = `${subject} with ${tutor}: ${sessionTime}`;
  if (!useSemiColon) {
    sessionDetail = `${subject} with ${tutor} at ${sessionTime}`;
  }
  session.detail = sessionDetail;
  session.time = sessionTime;
  return session;
};

export const getSessionDetail = (session, timezone) => {
  const sessionDateFormat = 'dddd D MMM';
  const sessionTimeFormat = SESSION_TIME_FORMAT;
  const { startTime, endTime, tutorName } = session;

  const sessionDate = moment(startTime).tz(timezone).format(sessionDateFormat);
  const sessionTime = `${moment(startTime).tz(timezone).format(sessionTimeFormat)} - ${moment(endTime)
    .tz(timezone)
    .format(sessionTimeFormat)}`;

  return `${sessionDate} from ${sessionTime} with ${tutorName}`;
};

/**
 * check whether a session is a group session
 * @param  {String} courseType                   courseType
 * @return {Boolean}                              true: if it is a group session; false: if it is not
 */

export const isGroupSessionCheck = (courseType) => {
  return courseType && (courseType.toLowerCase() === 'group' || courseType.toLowerCase() === 'holding group');
};

/**
 * return new timeout offset for today's session
 * @param  {Object}  {startTime, endTime}
 * @return {String}  timeoutOffset
 */
export const getLiveUpdateTimeoutOffset = ({ startTime, endTime }) => {
  const now = new Date();
  const minutesToStart = moment(startTime).diff(now, 'minutes');
  const minutesToEnd = moment(endTime).diff(now, 'minutes');
  let timeoutOffset = 0;

  if (minutesToStart > 60) {
    timeoutOffset = 30 * 60 * 1000;
  } else if (minutesToStart > 40 && minutesToStart <= 60) {
    timeoutOffset = 10 * 60 * 1000;
  } else if (minutesToStart <= 40 && minutesToStart > 5) {
    timeoutOffset = 1 * 60 * 1000;
  } else if (minutesToStart <= 5 && minutesToEnd >= 0) {
    timeoutOffset = 30 * 1000;
  } else if (minutesToEnd < 0) {
    timeoutOffset = 0;
  }

  return timeoutOffset;
};

/**
 * return new state for today's session
 * @param  {Object}  {startTime, endTime}
 * @return {Object}
 */

export const isPaymentFailed = (paymentStatus) => {
  return (paymentStatus || '').toLowerCase() === 'failed';
};

export const isPaymentPending = (paymentStatus) => {
  return (paymentStatus || '').toLowerCase() === 'pending payment';
};

export const isPaymentSucceed = (paymentStatus) => {
  return (paymentStatus || '').toLowerCase() === 'paid';
};

export const isSessionBilled = (paymentStatus) => {
  return (paymentStatus || '').toLowerCase() === 'billed';
};

export const isSessionPrePaid = (paymentType) => {
  return (paymentType || '').toLowerCase() === 'prepaid';
};

/**
 * Get sessions to be displayed in view
 * @param  {Array} sortedSessions                sortedSessions array
 * @param  {Number} startIndex                   startIndex
 * @return {Array}                            Sessions to be displayed in view
 */
export const getSessionsInViewByIndex = (sortedSessions, startIndex) => {
  const totalSessions = sortedSessions.length;
  if (startIndex >= totalSessions) {
    return [];
  }

  return sortedSessions.slice(startIndex, startIndex + NO_OF_SESSIONS_PER_PAGE);
};

export function isNotAnArrayOrIsEmptyArray(value) {
  return !Array.isArray(value) || value.length === 0;
}

// Displays a number to a specified number of DP. Takes a number, returns a string.
export function displayNumberToSpecifiedDP({ decimalPoints = 0, value = null } = {}) {
  if (!value) {
    return '';
  }
  return value.toFixed(decimalPoints);
}

export const isHolidayCheck = (sessionStartTime, holidays) => {
  const sessionTime = moment(sessionStartTime);

  const ch = holidays.find((h) => {
    const { type, startDate, endDate } = h;
    return (
      type.toLowerCase() === SPECIAL_DATE_TYPE.CLUEY_HOLIDAY &&
      sessionTime.isSameOrAfter(startDate, 'day') &&
      sessionTime.isSameOrBefore(endDate, 'day')
    );
  });

  const isClueyHoliday = ch !== undefined;
  const clueyHolidayIndicatorText = ch !== undefined ? 'Cluey is closed' : '';

  if (isClueyHoliday) {
    return {
      isHoliday: isClueyHoliday,
      indicatorText: clueyHolidayIndicatorText,
      isClueyHoliday: true,
      isPublicHoliday: false,
    };
  }
  const ph = holidays.find((h) => {
    const { type, startDate, endDate } = h;
    return (
      type &&
      type.toLowerCase() === SPECIAL_DATE_TYPE.PUBLIC_HOLIDAY.toLowerCase() &&
      sessionTime.isSameOrAfter(startDate, 'day') &&
      sessionTime.isSameOrBefore(endDate, 'day')
    );
  });

  const isPublicHoliday = ph !== undefined;
  const publicHolidayIndicatorText = ph !== undefined ? `${ph.state} Public Holiday - Cluey is open` : '';

  if (isPublicHoliday) {
    return {
      isHoliday: isPublicHoliday,
      indicatorText: publicHolidayIndicatorText,
      isClueyHoliday: false,
      isPublicHoliday: true,
    };
  }

  return {
    isHoliday: false,
    indicatorText: '',
  };
};

/**
 * Filter school term dates
 * @param  {SchoolTerm[]} schoolTerms                schoolTerms
 * @param  {String} lastSessionDate           lastSessionDate
 * @return {SchoolTerm[]}                            Sessions to be displayed in view
 */
export const filterSchoolTerms = (schoolTerms, lastSessionDate) => {
  return schoolTerms.filter((schoolTerm) => {
    return (
      moment(schoolTerm.startTime).isSameOrAfter(moment()) &&
      moment(schoolTerm.startTime).isSameOrBefore(lastSessionDate, 'day')
    );
  });
};

export const getSessionAvailableDayOptions = () => {
  return SESSION_AVAILABLE_DAYS.map((day) => {
    return {
      text: day,
      value: day,
    };
  });
};

export const getSessionAvailableTimeOptions = () => {
  return SESSION_AVAILABLE_TIMES.map((time) => {
    return {
      text: time,
      value: time,
    };
  });
};

export const isClueyHolidayCheck = (startTime, clueyHolidays) => {
  if (clueyHolidays.length === 0) {
    return false;
  }

  const sessionTime = moment(startTime);

  const ch = clueyHolidays.find((h) => {
    const { type, startDate, endDate } = h;
    return (
      type.toLowerCase() === SPECIAL_DATE_TYPE.CLUEY_HOLIDAY &&
      sessionTime.isSameOrAfter(startDate, 'day') &&
      sessionTime.isSameOrBefore(endDate, 'day')
    );
  });

  return ch !== undefined;
};

export const formatTimeToAMPM = (time) => {
  if (isEmpty(time)) {
    return null;
  }
  const hm = time.split(':');
  const h = parseInt(hm[0], 0);
  if (h >= 22) {
    return `${h - 12}:${hm[1]}pm`;
  }
  if (h > 12) {
    return `0${h - 12}:${hm[1]}pm`;
  }
  if (h === 12) {
    return `00:${hm[1]}am`;
  }
  if (h >= 10) {
    return `${h}:${hm[1]}am`;
  }
  return `0${h}:${hm[1]}am`;
};

export const passwordValidatorHelper = ({ passwordOrg, passwordRetyped, setRetypedPWDValidation }) => {
  if (passwordOrg !== '' && passwordRetyped !== '') {
    if (!validatePassword(passwordRetyped)) {
      setRetypedPWDValidation(PASSWORD_VALIDATION_RESULT.INVALID);
      return;
    }

    if (passwordOrg === passwordRetyped) {
      setRetypedPWDValidation(PASSWORD_VALIDATION_RESULT.MATCH);
      return;
    }
    setRetypedPWDValidation(PASSWORD_VALIDATION_RESULT.NOT_MATCH);
    return;
  }

  if (passwordRetyped === '') {
    setRetypedPWDValidation(PASSWORD_VALIDATION_RESULT.INIT);
  }
};

export const isSessionLinkValid = ({ sessionLink }) => {
  return !!sessionLink && (sessionLink || '').toLowerCase() !== 'not available';
};

export const getAffectedSessions = ({ enrolmentId, sessions, start, end }) => {
  if (!enrolmentId || isEmpty(sessions) || !start || !end) {
    return [];
  }

  const sortedSessions = sortBy(sessions, 'startTime');

  return sortedSessions.filter((s) => {
    const { uniqueEnrolmentId, startTime } = s;
    return (
      uniqueEnrolmentId === enrolmentId &&
      moment(startTime).isSameOrAfter(moment(start), 'day') &&
      moment(startTime).isSameOrBefore(moment(end), 'day')
    );
  });
};

export const isEnrolmentPausedCheck = ({ enrolmentStatus }) => {
  return !!enrolmentStatus && enrolmentStatus.toLowerCase() === ENROLMENT_STATUSES.PAUSED;
};

export const willEnrolmentBePausedCheck = ({ enrolmentPausedFrom, enrolmentPausedTo }) => {
  return (
    !!enrolmentPausedFrom &&
    moment(enrolmentPausedFrom).isAfter(moment()) &&
    !!enrolmentPausedTo &&
    moment().isBefore(moment(enrolmentPausedTo))
  );
};

export const isEnrolmentCancelRequested = ({ enrolmentStatus }) => {
  return !!enrolmentStatus && enrolmentStatus.toLowerCase() === ENROLMENT_STATUSES.CANCELLED_REQUESTED;
};

export const isEnrolmentCancelled = ({ enrolmentStatus }) => {
  return !!enrolmentStatus && enrolmentStatus.toLowerCase() === ENROLMENT_STATUSES.CANCELLED;
};

export const isEnrolmentFinished = ({ enrolmentStatus }) => {
  return !!enrolmentStatus && enrolmentStatus.toLowerCase() === ENROLMENT_STATUSES.FINISHED;
};

// NOTE, may need to update cancellation reason after pulling from SF
export const isEnrolmentOutsidePolicyPauseRequested = ({ enrolmentStatus, cancelReason }) => {
  return isEnrolmentCancelRequested({ enrolmentStatus }) && cancelReason === PAUSE_OUTSIDE_POLICY.caseSubCategory;
};

export const isEnrolmentActiveCheck = ({ enrolmentStatus }) => {
  return (
    !!enrolmentStatus &&
    [ENROLMENT_STATUSES.ACTIVE, ENROLMENT_STATUSES.NEW_CUSTOMER].includes(enrolmentStatus.toLowerCase())
  );
};

export const getEnrolmentResumeSession = ({ hubEnrolmentsWithResumeSession, enrolmentId }) => {
  if (isEmpty(hubEnrolmentsWithResumeSession)) {
    return {};
  }

  return (
    hubEnrolmentsWithResumeSession.find((e) => {
      return e.enrolmentId === enrolmentId;
    }) || {}
  );
};

/**
 * @typedef PackageInfo
 * @property {string} packageType
 * @property {number} yearLevel
 * @property {string} subject
 * @property {string} dayOfWeek
 * @property {string} timeOfDay
 * @property {string} tutor
 */

/**
 * Parse SF Package name into usable information
 * @param {string} packageName
 * @returns {(PackageInfo|null)}
 */
export const parsePackageName = (packageName) => {
  const match = packageName.match(
    /^([A-Z]+) - Y(\d+) - ([A-Za-z0-9]+) - ([A-Za-z]{3}) - (\d\d:\d\d (?:A|P)M)(?: - (.+))?$/
  );
  if (match) {
    const [, packageType, yearLevelStr, subject, dayOfWeek, timeOfDay, tutor] = match;

    return {
      packageType,
      yearLevel: parseInt(yearLevelStr, 10),
      subject,
      dayOfWeek,
      timeOfDay,
      tutor,
    };
  }
  return null;
};

export const isTutorResigned = ({ tutorChangeReason }) => {
  return (tutorChangeReason || '').toLowerCase() === 'resignation';
};

export const setTutorTypeDefault = ({ tutorType }) => {
  return (tutorType || '').toLowerCase() === 'other' ? '' : tutorType;
};

export const getPrefilledPauseSessionsInfo = ({ enrolmentId, sessions, activeEnrolments }) => {
  const defaultReturn = {
    hasTempTutorChange: false,
    student: null,
    subject: null,
    enrolmentTutorName: null,
    noOfSessionsToPause: 0,
  };

  if (!enrolmentId || isEmpty(sessions) || isEmpty(activeEnrolments)) {
    return defaultReturn;
  }

  const currentEnrolment = activeEnrolments.filter((e) => enrolmentId === e.enrolmentId);

  if (isEmpty(currentEnrolment)) {
    return defaultReturn;
  }

  const { student, subject, tutor: enrolmentTutorName, tutorNumber: enrolmentTutorId } = currentEnrolment[0] || {};

  const sessionsOfEnrolment = sessions.filter(Boolean).filter((s) => {
    return s.uniqueEnrolmentId === enrolmentId && moment(s.startTime).isAfter(moment(), 'minutes');
  });

  if (isEmpty(sessionsOfEnrolment)) {
    return defaultReturn;
  }
  const sortedSessions = sortBy(sessionsOfEnrolment, 'startTime');

  const sessionsWithTempTutor = sortedSessions.filter((s) => {
    return !!s.automatedTutorChange && s.tutorNumber !== enrolmentTutorId;
  });

  return {
    hasTempTutorChange: !isEmpty(sessionsWithTempTutor),
    student,
    subject,
    enrolmentTutorName,
    noOfSessionsToPause: sessionsWithTempTutor.length,
  };
};

export const getCompanyDetailByCountry = ({ isNZ = false }): CompanyDetails => {
  if (isNZ) {
    return {
      address: '430 Victoria Street, Hamilton Central',
      country: 'New Zealand',
      postcode: 'Hamilton, 3204',
      phone: '09 802 2361',
      businessNumber: 'NZBN: 9429049336856',
    };
  }

  return {
    address: 'L2 / 117 Clarence St, Sydney',
    country: 'Australia',
    postcode: 'NSW 2000',
    phone: '1300 182 000',
    businessNumber: 'ABN: 33 620 549 019',
  };
};

// NOTE: Don't quite remember all business requirements for this part.
// Please keep the logic
export const shouldShowHolidayPromo = ({ showSummerModePromo }) => {
  const showMarketingPromo = false;
  return !showMarketingPromo && showSummerModePromo;
};

// NOTE
// Change this before deploying to production
/**
 * DEPRECATED
 * Is summer mode enabled?
 * @returns {boolean}
 */
export const isSummerModeOn = () => {
  return moment().isSameOrBefore(moment(SUMMER_OFF_BOARDING_END_DATE), 'day');
};

export const saveCancellingPeriod = () => moment().isSameOrBefore(moment('2022-02-28'), 'day');

// Banner to be displayed from Jan 15 to Jan 30.
export const isHolidayLearningAlertOn = () => moment().isBetween('2022-01-14', '2022-01-31', 'day');

/**
 * Turn group object into ISO Date String
 * @param {{ groupTime: {string}, groupDay: {string}, groupDate: {string}}} group
 * @returns {moment.Moment}
 */
export const getGroupSessionMoment = (group) => {
  return moment(`${group.groupDate} ${group.groupTime}`, 'YYYY-MM-DD hh:mm A');
};

/**
 * Format Phone Number into +614 123 432 212
 * @param {string} phone
 * @returns {string}
 */
export const formatPhoneNumber = (phone) => {
  if (phone[0] === '0') {
    // likely an australian region code
    if (phone[1] === '4') {
      // australian mobile
      const match = phone.match(/(\d{4})(\d{3})(\d{3})/);
      if (match) {
        return `${match[1]} ${match[2]} ${match[3]}`;
      }
      return phone;
    }
    // likely australian landline
    const match = phone.match(/(\d{2})(\d{4})(\d{4})/);
    if (match) {
      return `(${match[1]}) ${match[2]} ${match[3]}`;
    }
    return phone;
  }

  const noPlus = phone[0] !== '+' ? `+${phone}` : phone;
  let formatted = '';
  for (let i = 0; i < noPlus.length; i++) {
    if (i > 0 && i % 3 === 0) {
      formatted += ` ${noPlus[i]}`;
    } else {
      formatted += noPlus[i];
    }
  }
  return `${formatted}`;
};

export const formatAmountWithCurrency = (amount, currency) => {
  const formatter = new Intl.NumberFormat('en-au', {
    style: 'currency',
    currency,
  });

  return formatter.format(amount);
};
