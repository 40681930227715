import moment from 'moment';
import { Session } from '../../types/hubInterfaces';

export default function isSessionJoinable(session: Session) {
	const now = moment();
	const inFifteenMinutes = now.clone().add(15, 'minutes');
	const sessionStartTime = moment(session.startTime);
	const sessionEndTime = moment(session.endTime);

	return (
		sessionStartTime.isSame(now, 'day') && // session is today AND
		now.isBefore(sessionEndTime) && // session has not ended AND
		(now.isSameOrAfter(sessionStartTime, 'minutes') || // session has started OR
			sessionStartTime.isSameOrBefore(inFifteenMinutes, 'minutes')) // session is starting in 15 minutes
	);
}
