import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { SchoolsFooter } from './SchoolsFooter';
import { SchoolsHeader } from './SchoolsHeader';
import { HUB_SESSION_DATE_FORMAT } from '../../util/constants';

export const SchoolsPageLayout = ({ children, heading, subHeading }) => {
	return (
		<div>
			<SchoolsHeader />
			{(heading || subHeading) && (
				<div className="container mx-auto pb-5 pt-10 font-display text-grey-6">
					{heading && (
						<Fragment>
							<div className="mb-2 block text-xs font-bold uppercase tracking-[1px] lg:mb-1">
								{moment().format(HUB_SESSION_DATE_FORMAT)}
							</div>
							<h1 className="mb-0 text-2xl font-bold md:text-3xl lg:text-4xl">{heading}</h1>
						</Fragment>
					)}
					{subHeading && <span className="block text-lg">{subHeading}</span>}
				</div>
			)}
			{children}
			<SchoolsFooter />
		</div>
	);
};

SchoolsPageLayout.propTypes = {
	children: PropTypes.node.isRequired,
	heading: PropTypes.string,
	subHeading: PropTypes.string,
};

SchoolsPageLayout.defaultProps = {
	heading: '',
	subHeading: '',
};
