import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from '@cluey/cluey-components';
import Day from '../../../components/EnrolmentManagement/Day';
import { AvailabilitiesOfADayUnconnected } from '../../../components/EnrolmentManagement/AvailabilitiesOfADay';
import moment from 'moment';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import EnrolmentLayout from '../../../common/hub/EnrolmentLayout';
import { Alert, Checkbox, Container, ButtonAsLink } from '@cluey/cluey-components';
import { EnrolmentBrief } from '../../../components/EnrolmentManagement/EnrolmentBrief';
import InPageLoader from '../../../common/InPageLoader';
import ErrorBlock from '../../../common/ErrorBlock';
import { isLoadingEnrolmentDetails } from '../../../selectors/hubSelector';
import { fetchEnrolment } from '../../../actions/hub/hubEnrolmentManagementActions';
import { PATH_HUB_ENROLMENT_DETAIL, PATH_HUB_RESCHEDULE_SESSION, PATH_HUB_SESSIONS_PAGE } from '../../../util/pagePath';
import { FormFlow, FormFlowProps } from '../../../components/FormFlow/FormFlow';
import HubRescheduleSessionConfirmationPage from './HubRescheduleSessionConfirmationPage';
import HubRescheduleSessionSuccessPage from './HubRescheduleSessionSuccessPage';
import { useGetSessionPolicy } from '../../../hooks/queries/useSessionQuery';
import { Loader } from '../../../components/Loader';
import { useRescheduleSessionState } from './useRescheduleSessionState';

const getUpcomingDays = ({ activeDay, onChange, start, end }) => {
	const days = [];
	const startingDay = start ? moment(start).utc() : moment();
	const rangeStart = moment(start).hours(0).minutes(0).seconds(0);
	const rangeEnd = moment(end).hours(0).minutes(0).seconds(0);
	const range = moment(rangeEnd).diff(rangeStart, 'days');

	if (moment(start).isValid() && moment(end).isValid()) {
		for (let i = 0; i <= range; i++) {
			const day = moment(startingDay).add(i, 'days');
			days.push(
				<Day dateFormat="D MMM" key={day} dayRaw={day.toDate()} activeDay={activeDay} clickHandler={onChange} />
			);
		}
	}

	return days;
};

const SelectSchedule = (props) => {
	const { formFlowProps } = props;
	const history = useHistory();
	const navigateToSessionsPage = () => {
		history.push(PATH_HUB_SESSIONS_PAGE);
	};
	const { next, state } = formFlowProps;
	const { tutorInitiated } = state;
	const onDaySelected = (day) => {
		setActiveDay(day);
	};

  const [showCurrentTutorOnly, setShowCurrentTutorOnly] = useState(tutorInitiated);
 
  const onAvailabilityItemClick = (item) => {
    const { selectedDay, sessionDuration, tutorNumber, startDate, tutorId } = item;
    next({
      ...state,
      scheduleDate: startDate.toISOString(),
      selectedDay,
      sessionDuration,
      tutorNumber,
      tutorId,
      isTempTutor: false,
      shouldProceed: true,
    });

	};

	const isLoading = useSelector(isLoadingEnrolmentDetails);

	const {
		enrolment,
		enrolmentDetails,
		enrolmentSchedule,
		timezone,
		tutorName,
		tutorPhoto,
		sectionHeading,
		session,
		sessionSchedule,
		rescheduleOptionsStart,
		isLoadingAvailableSlots,
		isLoadingAvailableSlotsError: loadingError,
		availableTimeslots,
		currentTutorHasAvailableTimeslots,
		sessionRescheduleRequestStatus,
	} = useRescheduleSessionState(tutorInitiated);
	const start = rescheduleOptionsStart;
	const end = moment(sessionSchedule).add(6, 'days').utc().format();
	const [activeDay, setActiveDay] = useState(moment(rescheduleOptionsStart).startOf('day').toDate());
	// since rescheduleOptionsStart is initially today (default value) and it changes once enrolment details have been fetched, we need to update activeDay as well
	useEffect(() => {
		if (moment(activeDay).isBefore(moment(rescheduleOptionsStart).startOf('day'))) {
			setActiveDay(moment(rescheduleOptionsStart).startOf('day').toDate());
		}
	}, [rescheduleOptionsStart, activeDay]);
	const upcomingDays = getUpcomingDays({ activeDay, onChange: onDaySelected, start, end });
	const { sessionId } = useParams();
	const { data: sessionPolicy, error: policyError } = useGetSessionPolicy({ sessionId });
	const canBeRescheduled = sessionPolicy?.reschedule?.isAllowed;

	const onTempTutorClick = () => {
		const { selectedDay, sessionDuration, tutorNumber, startTime, endTime, tutorId } = session;
		const duration = !sessionDuration ? moment(endTime).diff(startTime, 'minutes') : sessionDuration;

		next({
			...state,
			scheduleDate: startTime,
			selectedDay,
			sessionDuration: duration,
			tutorNumber,
			tutorId,
			isTempTutor: true,
		});
	};
	const dispatch = useDispatch();
	useEffect(() => {
		if (enrolment && !enrolmentDetails?.enrolmentId && !isLoading) {
			dispatch(fetchEnrolment({ enrolmentId: enrolment.enrolmentId }));
		}
	}, [enrolment, enrolment?.enrolmentId, enrolmentDetails?.enrolmentId, isLoading, dispatch]);

	const pageTitle = tutorInitiated ? 'Reschedule session' : 'Reschedule upcoming session';

	const rescheduleAllowedHours = parseInt(sessionPolicy?.reschedule.allowedHours || -1);
	const filteredAvailableTimeslots = useMemo(() => {
		return rescheduleAllowedHours && availableTimeslots
			? availableTimeslots.filter((timeslot) => {
					const timeslotStart = moment(timeslot.startDate);
					const now = moment();

					return timeslotStart.diff(now, 'hours') >= rescheduleAllowedHours;
			  })
			: availableTimeslots;
	}, [rescheduleAllowedHours, availableTimeslots]);

  const sessionNumber = parseInt(session.sessionNumber, 10);
  const isFirstSession = sessionNumber === 1;
  const tutorAllocationType = isFirstSession ? 'new' : 'temporary';

  useEffect(() => {
    if (tutorInitiated || isFirstSession) {
      setShowCurrentTutorOnly(true);
    }
  }, [tutorInitiated, isFirstSession])

  if (isLoading || isLoadingAvailableSlots) {
    return (
      <EnrolmentLayout
        sectionHeading={sectionHeading}
        mainHeading={pageTitle}
        showEnrolmentStatus={false}
        showSummerModeEnrolmentStatus={false}
      >
        <Loader />
      </EnrolmentLayout>
    );
  }

  const studentName = enrolmentDetails?.studentName || 'you';
  const sessionOwner = enrolmentDetails?.studentName ? `${studentName}'s` : 'your';
  const isRescheduleRequestCancelled =
    sessionRescheduleRequestStatus === null && session?.tutorSfId === enrolmentDetails?.tutorSfId;

  const renderAdditionalInfoContent = isRescheduleRequestCancelled ? (
    <div className="mb-4">
      <div>
        {`Good news! ${sessionOwner} tutor is now available again and will take ${sessionOwner} session at the usual time.
                Please ignore our previous messages, there is no need to reschedule.`}
			</div>
			<div className="mt-8">
				<Button size="lg" onClick={navigateToSessionsPage}>
					See session
				</Button>
			</div>
		</div>
	) : (
		<>
			<div>
				{`${sessionOwner} tutor ${tutorName} can't make it to one of their upcoming sessions together, 
                and is available at alternative times to take ${sessionOwner} session. To reschedule to one of these times,
                please select the best time from the list below.`}
      </div>
      <div className="mt-6">
        {`If none of these times will work for ${
          studentName === 'you' ? studentName : 'you and ' + studentName
        }, we can also keep ${sessionOwner} session at the current time
                and allocate a ${tutorAllocationType} tutor instead.`}
      </div>
      <div className="mt-6 w-full">
        {tutorInitiated && currentTutorHasAvailableTimeslots && (
          <div className="flex flex-col md:flex-row md:items-center">
            <ButtonAsLink onClick={onTempTutorClick}>
              {/* //href={generatePath(PATH_HUB_RESCHEDULE_TEMPORARY_TUTOR, { sessionId })} > */}
              <p className="text-lg">{`Allocate a ${tutorAllocationType} tutor and keep the session time`}</p>
            </ButtonAsLink>
          </div>
        )}
      </div>
    </>
  );
  const renderAdditionalInfo = tutorInitiated ? renderAdditionalInfoContent : undefined;
  const isRescheduled = (sessionToCheck) =>
    (sessionToCheck?.tutorRescheduleRequestStatusC === null &&
      sessionToCheck?.tutorSfId !== sessionToCheck?.packageTutorSfid) ||
    sessionToCheck?.tutorRescheduleRequestStatusC === 'Approved' ||
    sessionToCheck?.tutorRescheduleRequestStatusC === 'Rejected';

	const getEnrolmentBriefTutorLabel = () => {
		if (isRescheduleRequestCancelled) {
			return 'Tutor';
		} else if (tutorInitiated) {
			return `${tutorName} is unavailable for this session`;
		} else {
			return undefined;
		}
	};

	const shouldRenderAvailabilities = tutorInitiated ? currentTutorHasAvailableTimeslots : true;

  return (
    <EnrolmentLayout
      sectionHeading={sectionHeading}
      mainHeading={pageTitle}
      showEnrolmentStatus={false}
      showSummerModeEnrolmentStatus={false}
    >
      <Container>
        <section>
          <EnrolmentBrief
            shownPreviousTutor={isRescheduleRequestCancelled || tutorInitiated} // this is to display 'Tutor' as the label instead of 'Current Tutor'
            leftColumnTitle={getEnrolmentBriefTutorLabel()}
            tutorInitiated={tutorInitiated}
            tutorName={tutorName || ''}
            tutorPhoto={tutorPhoto}
            enrolmentSchedule={enrolmentSchedule}
            renderSchedule={() => {
              const schedule = session?.startTime
                ? `${moment(session?.startTime).tz(timezone).format('dddd, D MMMM [at] h:mma')}`
                : '';
              return (
                <>
                  <span key={`${schedule}`} className="block text-lg leading-[1.33]">
                    {schedule}
                  </span>
                  {isRescheduled(session) && <span>One-off rescheduled session</span>}
                </>
              );
            }}
            additionalInfo={renderAdditionalInfo}
          />
        </section>
        {!canBeRescheduled && (
          <section className="container mx-auto mb-8">
            <Alert type="warning">
              {sessionPolicy?.reschedule?.externalMessage || "The session can't be rescheduled because of an error"}
            </Alert>
          </section>
        )}
        {policyError && (
          <Alert
            className="mt-2"
            type="error"
            body={`There was an error retrieving this session's policy. ${policyError}`}
          />
        )}
        {tutorInitiated && !loadingError && !currentTutorHasAvailableTimeslots && (
          <section className="text-red-5">{`This session can no longer be rescheduled with ${tutorName}.`}</section>
        )}
        {canBeRescheduled && !isRescheduleRequestCancelled && !policyError && shouldRenderAvailabilities && (
          <>
            <section className="container mx-auto mb-8 px-4">
              <h3 className="cy-enrolment-title mb-4 font-display text-lg font-bold md:text-xl">
                {`If you want to reschedule${
                  tutorInitiated ? ` with ${tutorName}` : ''
                }, select a one-off session time below:`}
              </h3>
              {(!tutorInitiated && !isFirstSession) && (
                <div className="flex items-center">
                  <Checkbox
                    id="current tutor"
                    value={showCurrentTutorOnly}
                    onChange={setShowCurrentTutorOnly}
                    error={false}
                  />
                  <label htmlFor="current tutor" className="cursor-pointer pl-2 text-lg leading-[1.33]">
                    {tutorName}&apos;s availability only
                  </label>
                </div>
              )}
            </section>
            <section>
              {isLoading && <InPageLoader position="relative" height="auto" />}
              {loadingError && (
                <div className="container mx-auto mb-12">
                  <ErrorBlock errorMsg="There was a problem retrieving the data, please try again." />
                </div>
              )}
              {!isLoading && !loadingError && (
                <>
                  {!isEmpty(upcomingDays) && (
                    <div className="mb-4">
                      <div className="flex w-full overflow-y-auto">
                        <div className="min-w-[15px] flex-1 border-b border-grey-2"></div>
                        <div className="container flex flex-shrink">{upcomingDays}</div>
                        <div className="flex-1 border-b border-grey-2"></div>
                      </div>
                    </div>
                  )}
                  {activeDay instanceof Date === true && (
                    <AvailabilitiesOfADayUnconnected
                      isFirstSession={isFirstSession}
                      tutorInitiated={tutorInitiated}
                      availabilities={filteredAvailableTimeslots}
                      timezone={timezone}
                      day={activeDay}
                      currentTutor={tutorName}
                      currentTutorId={enrolment?.tutorSfId}
                      showCurrentTutorOnly={showCurrentTutorOnly}
                      clickHandler={onAvailabilityItemClick}
                      setShowCurrentTutorOnly={setShowCurrentTutorOnly}
                    />
                  )}
                </>
              )}
            </section>
          </>
        )}
        {!tutorInitiated && (
          <Link
            to={`${PATH_HUB_ENROLMENT_DETAIL}?token=${enrolment?.enrolmentId}`}
            className="cursor-pointer text-lg text-blue-5 hover:text-purple-6 hover:underline"
          >
            <p>Keep existing session time</p>
          </Link>
        )}
      </Container>
    </EnrolmentLayout>
  );
};

SelectSchedule.propTypes = {
	formFlowProps: FormFlowProps.isRequired,
	tutorInitiated: PropTypes.bool,
};

SelectSchedule.defaultProps = {
	tutorInitiated: false,
};

const formElements = {
	'': SelectSchedule,
	confirm: HubRescheduleSessionConfirmationPage,
	success: HubRescheduleSessionSuccessPage,
};

export const HubRescheduleSession = () => {
	const { sessionId } = useParams();
	const enrolment = useSelector((state) => state.hubEnrolmentDetailPage);
	const sessionToReschedule = enrolment?.sessions?.find((s) => s.studentSessionId === sessionId);
	const tutorInitiated = sessionToReschedule?.rescheduleRequestStatus === 'Requested';
	const history = useHistory();

	const onFinish = () => {
		history.push(PATH_HUB_RESCHEDULE_SESSION);
	};

	const onCancel = () => {
		history.push(PATH_HUB_RESCHEDULE_SESSION);
	};

	const initialState = {
		selectedTime: '',
		tutorInitiated,
	};

	const [state, setState] = useState(initialState);

	// tutorInitiated is false by default (when data hasn't been loaded yet)
	useEffect(() => {
		// perform this check to prevent infinite loop
		if (tutorInitiated && !state.tutorInitiated) {
			setState((s) => ({
				...s,
				tutorInitiated,
			}));
		}
	}, [tutorInitiated, state.tutorInitiated]);

	return <FormFlow state={state} setState={setState} onFinish={onFinish} onCancel={onCancel} elements={formElements} />;
};

export default HubRescheduleSession;
