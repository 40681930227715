import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { Container } from '@cluey/cluey-components';

import EnrolmentLayout from '../../../../common/hub/EnrolmentLayout';
import { useEnrolmentDetailQuery } from '../../../../hooks/queries/useEnrolmentQuery';
import {
  PATH_HUB_HOME,
  PATH_HUB_SELECT_SESSION,
  PATH_HUB_ENROLMENT_CHANGE_SCHEDULE,
  PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP,
  PATH_HUB_CR_PAUSE_SESSIONS,
  PATH_HUB_ADD_ONE_OFF_SESSION,
} from '../../../../util/pagePath';
import { AccountDetails } from '../../../../types/hubInterfaces';
import { getAccountDetails } from '../../../../selectors/hubSelector';
import { ELEMENT_TYPES } from '../../../../types/hubEnums';
import { isGroupSessionCheck, isEnrolmentPausedCheck } from '../../../../util/helpers';
import { OptionCard, OptionCardContent } from '../../../../components/EnrolmentManagement/OptionCard';
import { IntentIntro } from '../../../../components/IntentIntro';
import { History } from 'history';
import posthog from 'posthog-js';
import { PostHogEvent } from '../../../../util/posthog';
import { getCancelEnrolmentBasePath } from '../../../../selectors/enrolmentSelector';

type Option = {
  label: string;
  linkTo?: string;
  onClickHandler?: () => void;
  type: ELEMENT_TYPES;
  posthogEvent?: PostHogEvent;
};

const generateOptions = ({
  enrolmentId,
  tutorName,
  enrolmentType,
  enrolmentStatus,
  history,
  cancelEnrolmentBasePath,
}: {
  enrolmentId: string;
  tutorName: string;
  enrolmentType: string;
  enrolmentStatus: string;
  history: History;
  cancelEnrolmentBasePath: string;
}): Option[] => {
  const isGroup = isGroupSessionCheck(enrolmentType);
  const isPaused = isEnrolmentPausedCheck({ enrolmentStatus });
  const changeSchedulePath = isGroup ? PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP : PATH_HUB_ENROLMENT_CHANGE_SCHEDULE;
  const options: Option[] = [
    {
      label: 'Reschedule a session',
      onClickHandler: () => {
        history.push(generatePath(PATH_HUB_SELECT_SESSION, { enrolmentId }), { action: 'reschedule' });
      },
      type: ELEMENT_TYPES.BUTTON,
      posthogEvent: 'click_session-times_reschedule-session',
    },
    {
      label: 'Cancel a session',
      onClickHandler: () => {
        history.push(generatePath(PATH_HUB_SELECT_SESSION, { enrolmentId }), { action: 'cancel' });
      },
      type: ELEMENT_TYPES.BUTTON,
      posthogEvent: 'click_session-times_cancel-session',
    },
    {
      label: `Add a one-off session with ${tutorName}`,
      linkTo: generatePath(PATH_HUB_ADD_ONE_OFF_SESSION, { enrolmentId }),
      type: ELEMENT_TYPES.LINK,
      posthogEvent: 'click_session-times_add-one-off-session',
    },
    {
      label: 'Manage ongoing session times and frequency',
      linkTo: `${changeSchedulePath}?token=${enrolmentId}`,
      type: ELEMENT_TYPES.LINK,
      posthogEvent: 'click_session-times_change-schedule',
    },
    {
      label: 'Pause enrolment',
      linkTo: `${PATH_HUB_CR_PAUSE_SESSIONS}?token=${enrolmentId}`,
      type: ELEMENT_TYPES.LINK,
      posthogEvent: 'click_session-times_pause-enrolment',
    },
    {
      label: 'Cancel enrolment',
      linkTo: `${cancelEnrolmentBasePath}?view=form&token=${enrolmentId}`,
      type: ELEMENT_TYPES.LINK,
      posthogEvent: 'click_session-times_cancel-enrolment',
    },
  ];
  // Add a one-off session is only available for private active enrolments
  if (isGroup || isPaused) {
    return options.filter((op) => !op.label.includes('Add a one-off session'));
  }

  return options;
};

export const ManageServiceSessionTimes = () => {
  const { enrolmentId } = useParams<{ enrolmentId: string }>();
  const { data: enrolmentDetail, isLoading, error } = useEnrolmentDetailQuery({ enrolmentId });
  const history = useHistory();
  if (!enrolmentId) {
    history.push(PATH_HUB_HOME);
  }

  const {
    studentName,
    customerNumber: studentCustomerNumber,
    enrolmentDiscipline,
    enrolmentType,
    enrolmentStatus,
    tutorName,
  } = enrolmentDetail ?? {};

  const { customerNumber }: AccountDetails = useSelector(getAccountDetails);
  const possessiveNoun = customerNumber === studentCustomerNumber ? 'your' : `${studentName}'s`;
  const cancelEnrolmentBasePath = useSelector(getCancelEnrolmentBasePath);
  const options = generateOptions({
    enrolmentId,
    tutorName,
    enrolmentType,
    enrolmentStatus,
    history,
    cancelEnrolmentBasePath,
  });
  return (
    <EnrolmentLayout
      isLoading={isLoading}
      error={error}
      sectionHeading={`${studentName}'s ${enrolmentDiscipline}`}
      mainHeading="Session times and schedules"
      enrolmentId={enrolmentId}
      showEnrolmentStatus={false}
      showSummerModeEnrolmentStatus={false}
    >
      <Container>
        <IntentIntro>How can we help with {possessiveNoun} session schedule?</IntentIntro>
        <ul className="list-none">
          {options.map(({ label, linkTo, onClickHandler, type, posthogEvent }) => {
            return (
              <li key={label}>
                <OptionCard
                  linkTo={linkTo}
                  onClickHandler={() => {
                    if (!!posthogEvent) {
                      posthog.capture(posthogEvent);
                    }
                    onClickHandler?.();
                  }}
                  type={type}
                  capturePosthogEvent={
                    !!posthogEvent
                      ? () => {
                          posthog.capture(posthogEvent);
                        }
                      : undefined
                  }
                >
                  <OptionCardContent>{label}</OptionCardContent>
                </OptionCard>
              </li>
            );
          })}
        </ul>
      </Container>
    </EnrolmentLayout>
  );
};
