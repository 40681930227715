import { LinkButton, Container } from '@cluey/cluey-components';
import { ClockIcon } from '../../../../icons';
import EnrolmentLayout from '../../../../common/hub/EnrolmentLayout';
import { useHistory } from 'react-router';
import { PATH_HUB_HOME } from '../../../../util/pagePath';

interface State {
  yearLevel: string;
}

const GenerateAssessmentFailed = () => {
  const history = useHistory<State>();
  const { location: { state } } = history;
  const { yearLevel } = state ?? {};

  return (
    <EnrolmentLayout>
      <Container className='flex flex-col items-center gap-5 text-grey-6'>
        <ClockIcon width={40} height={40} className='text-blood-orange-4' />
        <p className='font-bold text-grey-6'>No assessment available for {yearLevel} yet</p>
        <p>Check back soon for new self-paced learning features.</p>
        <LinkButton
          size="lg"
          className="normal-case"
          to={PATH_HUB_HOME}>
          <span>Back to homepage</span>
        </LinkButton>
      </Container>
    </EnrolmentLayout>
  )
}

export { GenerateAssessmentFailed }