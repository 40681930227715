import { logError } from '../../logging';
import { FETCH_NOT_OK, FETCH_RESPONSE_DATA, FETCH_INVALID_DATA } from '../../logging/errors';

/**
 * Reads the given response and logs it
 * @param  {Response} response  Fetch Response object
 * @return {void}
 */
export const logFailedResponse = ({ reqOptions, message }) => (response) => {
  response.text()
    .then((responseBodyText) => {
      return logError(
        FETCH_RESPONSE_DATA,
        {
          responseBodyText,
          responseHeaders: Array.from(response.headers),
          reqOptions,
        },
        { message },
      );
    })
    .catch(e => logError(e, { reqOptions }, { message }));
};

/**
 * Handles a response with a status different than 2XX
 * Logs it, returns a rejected Promise
 * @param  {Response} response      Fetch API Response helper
 * @return {Promise}                Rejected Promise
 */

export const handleNotOk = ({
  isDefinitiveFail, url, reqOptions, message, bubbleOriginalMessage = false,
}) => (
  response,
) => {
  logError(
    FETCH_NOT_OK,
    { reqOptions },
    {
      message: `Context: ${message}, return code: ${response.status} ${response.statusText} for url ${url}, method ${reqOptions.method}`,
    },
  );
  logFailedResponse({ reqOptions, message })(response.clone());
  return new Promise((_, reject) => {
    response
      .text()
      .then((responseBodyText) => {
        const error = new Error(bubbleOriginalMessage ? responseBodyText : FETCH_NOT_OK.message);
        if (isDefinitiveFail && isDefinitiveFail({ status: response.status, ok: response.ok })) {
          error.statusCode = response.status;
        }
        reject(error);
      })
      .catch(reject);
  });
};

/**
 * Handles a Json that is not valid.
 * Logs and returns a rejected Promise
 * @param  {Object} json Json object parsed from the fetch response
 * @return {Promise}     Rejected Promise
 */
export const handleInvalidJson = ({ reqOptions, message }) => (json) => {
  logError(FETCH_INVALID_DATA, { reqOptions, json }, { message });
  return Promise.reject(new Error(FETCH_INVALID_DATA.message));
};
