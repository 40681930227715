import { useEffect } from 'react';
import { isNull } from 'lodash';
import { SelectTopic } from './SelectTopic';
import { CreatePrompt } from './CreatePrompt';
import Conversation from './Conversation';
import useAiTutorContext, { UserFlowSteps } from '../useAiTutorContext';
import { scrollToTop } from '../../../util/helpers';
import { CompressedImage } from '../QuestionInput';
import { UsedUpLimitMsg } from '../UsedUpLimitMsg';
import { TMessage } from '../useMessageStore';
import { api } from '../../../api';
import posthog from 'posthog-js';

export const VirtualCoach = () => {
  const context = useAiTutorContext();
  const {
    addMessage,
    updateMessage,
    removeMessage,
    setChatId,
    currentStep,
    setCurrentStep,
    question,
    topic,
    enrolment,
    setError,
  } = context;

  useEffect(() => {
    scrollToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (enrolment) {
      posthog.capture('cluey-coach_initialize', { $enrolment: enrolment });
    }
  }, [enrolment]);

  const { mutate } = api.virtualCoach.askQuestion.useMutation({
    onMutate: () => {
      const initialResponse = addMessage({
        sender: 'tutor',
        content: "I'm thinking...",
        timestamp: new Date().toISOString(),
      });

      setCurrentStep(UserFlowSteps.CONTINUE_CHATTING);
      return { initialResponse };
    },
    onSuccess: async (response, _, context: { initialResponse: TMessage }) => {
      updateMessage(context.initialResponse.id, response.reply);
      setChatId(response.chatId);
      api.enrolment.all.invalidateQueries('active');
      setError(null);
    },
    onError: (error: Error, _, context: { initialResponse: TMessage }) => {
      removeMessage(context.initialResponse.id);
      setError(error);
    },
  });

  switch (currentStep) {
    case UserFlowSteps.CONTINUE_CHATTING:
      return <Conversation />;
    case UserFlowSteps.CREATE_PROMPT:
      return (
        <CreatePrompt
          setPromptCallback={(attachment: CompressedImage) => {
            addMessage({
              sender: 'user',
              content: question,
              attachment: attachment && !isNull(attachment.blob) ? URL.createObjectURL(attachment.blob) : undefined,
              timestamp: new Date().toISOString(),
            });
            mutate({
              enrolmentId: enrolment.enrolmentId,
              question,
              topicKey: topic.topicKey,
              imageBase64: attachment && !isNull(attachment.src) ? attachment.src : undefined,
            });
            posthog.capture('cluey-coach_submit-question', {
              $question: question,
              $topic: topic.topicKey,
              $hasAttachment: !!attachment,
            });
          }}
          backClick={() => {
            setCurrentStep(UserFlowSteps.CHOOSE_A_TOPIC);
          }}
        />
      );
    case UserFlowSteps.RESTRICTED:
      return <UsedUpLimitMsg />;
    default:
      return (
        <SelectTopic
          setTopicCallback={(selectedTopic) => {
            posthog.capture('click_cluey-coach_select-topic', {
              $topic: selectedTopic.topicKey,
            });
            setCurrentStep(UserFlowSteps.CREATE_PROMPT);
          }}
        />
      );
  }
};
