import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { getActiveEnrolments, getActiveGroupEnrolments } from '../../selectors/hubSelector';
import { PRICING_PLAN_POLICY_HEADERS } from '../../types/hubEnums';
import { PricePlan } from '../../types/hubTypes';

export const PRICING_PLAN_ROWS: Array<{
  header: PRICING_PLAN_POLICY_HEADERS;
  getHeader: (plan: PricePlan, hasGroupEnrolments?: boolean) => JSX.Element | PRICING_PLAN_POLICY_HEADERS;
  getContent: (plan: PricePlan, hasGroupEnrolments?: boolean) => React.ReactNode;
}> = [
  {
    header: PRICING_PLAN_POLICY_HEADERS.CANCELLING_A_SESSION,
    getHeader: () => PRICING_PLAN_POLICY_HEADERS.CANCELLING_A_SESSION,
    getContent: ({ skipAllowed, skipFullCreditUpToHours }) =>
      skipAllowed && skipFullCreditUpToHours
        ? `Full credit if at least ${skipFullCreditUpToHours}h notice`
        : 'No credit',
  },
  {
    header: PRICING_PLAN_POLICY_HEADERS.PAUSING_SESSIONS,
    getHeader: () => PRICING_PLAN_POLICY_HEADERS.PAUSING_SESSIONS,
    getContent: ({ pauseAllowed }) => pauseAllowed,
  },
  {
    header: PRICING_PLAN_POLICY_HEADERS.PAYMENT_TERMS,
    getHeader: () => PRICING_PLAN_POLICY_HEADERS.PAYMENT_TERMS,
    getContent: ({ paymentTerm }) => paymentTerm,
  },
  {
    header: PRICING_PLAN_POLICY_HEADERS.RESCHEDULING_A_SESSION,
    getHeader: ({ rescheduleUpToHours }, hasGroupEnrolments?) => {
      return (
        <div className="flex flex-col">
          <div>Rescheduling a session</div>
          <div className="font-normal md:hidden">At least {rescheduleUpToHours}h notice</div>
          {hasGroupEnrolments && <div className="hidden md:block">As a group</div>}
        </div>
      );
    },
    getContent: ({ rescheduleUpToHours }, hasGroupEnrolments?) => {
      return (
        <ul className={classNames({ 'md:list-disc md:pl-5': hasGroupEnrolments })}>
          {hasGroupEnrolments && <li className="block font-bold md:hidden">As a group</li>}
          <li className="hidden md:list-item">At least {rescheduleUpToHours}h notice</li>
          {hasGroupEnrolments && <li>Not allowed</li>}
        </ul>
      );
    },
  },
  {
    header: PRICING_PLAN_POLICY_HEADERS.CHANGE_OR_CANCEL_PLAN,
    getHeader: () => PRICING_PLAN_POLICY_HEADERS.CHANGE_OR_CANCEL_PLAN,
    getContent: ({ noticePeriodForChangeCancelPlan }) => noticePeriodForChangeCancelPlan,
  },
];

export const useHasActiveGroupEnrolments = () => !!useSelector(getActiveGroupEnrolments)?.length;
export const useHasSeveralActiveEnrolments = () => useSelector(getActiveEnrolments).length > 1;

export const isLegacyPlan = (plan?: PricePlan) =>
  ['a0D3s00000CpYILEA3', 'a0D3s00000CpYIQEA3', 'a5V74000000B3guEAC', 'a5V74000000B3hEEAS'].includes(
    plan?.pricingPlanId
  );
