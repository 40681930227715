import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@cluey/cluey-components';

import PWDValidationResult from './password/PWDValidationResult';
import showPwdIcon from '../../../assets/images/hubAuth/icon-slashed-eye.svg';
import pwdVisibleIcon from '../../../assets/images/hubAuth/icon-eye-on.svg';
import { PASSWORD_VALIDATION_RESULT } from '../../../util/constants';
import classNames from 'classnames';
import FieldLabel from '../FieldLabel';

const FIELD_TYPE_PASSWORD = 'password';
const FIELD_TYPE_TEXT = 'text';
const getNewFieldType = (fieldType) => {
  return fieldType === FIELD_TYPE_PASSWORD ? FIELD_TYPE_TEXT : FIELD_TYPE_PASSWORD;
};

const RetypedPasswordField = ({
  fieldLabel,
  fieldId,
  retypedPWDValidation,
  savePassword,
  placeholderText,
}) => {
  const [password, setPassword] = useState('');
  const [passwordType, setPasswordType] = useState(FIELD_TYPE_PASSWORD);

  const onPasswordChange = (value) => {
    setPassword(value);
    savePassword(value);
  };

  const onPasswordTypeChangeClick = (e) => {
    e.preventDefault();
    const newPasswordType = getNewFieldType(passwordType);
    setPasswordType(newPasswordType);
  };

  const isInvalid = retypedPWDValidation === PASSWORD_VALIDATION_RESULT.INVALID;

  return (
    <div className="mb-4">
      <div className="flex items-center">
        <FieldLabel id={fieldId} labelText={fieldLabel} />
      </div>
      <div className="relative">
        <Input
          type={passwordType}
          name={fieldId}
          id={fieldId}
          placeholder={placeholderText}
          autoComplete="off"
          className={classNames('block w-full', isInvalid && 'invalid')}
          value={password}
          onChange={onPasswordChange}
          invalid={isInvalid}
        />
        <button
          className="p-0 absolute right-[7px] bottom-[7px]"
          onClick={onPasswordTypeChangeClick}
        >
          {
            passwordType === FIELD_TYPE_PASSWORD && <img src={showPwdIcon} alt="show password" />
          }
          {
            passwordType === FIELD_TYPE_TEXT && <img src={pwdVisibleIcon} alt="hide password" />
          }
        </button>
      </div>
      <PWDValidationResult
        pwdValidation={retypedPWDValidation}
      />
    </div>
  );
};

RetypedPasswordField.propTypes = {
  fieldLabel: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  retypedPWDValidation: PropTypes.string.isRequired,
  savePassword: PropTypes.func.isRequired,
  placeholderText: PropTypes.string,
};

RetypedPasswordField.defaultProps = {
  placeholderText: 'Please enter your password',
};

export default RetypedPasswordField;
