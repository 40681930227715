import initialState from './initialState';
import {
  US_FETCH_SESSIONS_SUCCESS, US_UPDATE_STUDENTS_FILTERS,
  US_UPDATE_UPCOMING_SESSIONS_TOKEN,
} from '../actions/actionTypes';

export default function upcomingSessions(state = initialState.upcomingSessions, action) {
  switch (action.type) {
    case US_FETCH_SESSIONS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case US_UPDATE_STUDENTS_FILTERS:
      return {
        ...state,
        filteredStudentsNames: action.payload,
      };
    case US_UPDATE_UPCOMING_SESSIONS_TOKEN:
      return {
        ...state,
        token: action.payload.token,
      };
    default:
      return state;
  }
}
