import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@cluey/cluey-components';

import iconTick from '../../assets/images/icon-filled-tick.svg';
import BaseList from '../List/BaseList';
import { PATH_HUB_CR_PAUSE_SESSIONS } from '../../util/pagePath';

const ReasonsToPause = ({ title, showLinkToPause, showSubText }) => {
  const textLines = [
    'Don’t pay until learning restarts. Any credit for sessions you have already paid for will be automatically applied once you start again.',
    'Retain access to session reports and recordings',
  ];
  textLines.push('Resume your sessions easily when you\'re ready');
  return (
    <Fragment>
      <BaseList
        icon={iconTick}
        title={title}
        textLines={textLines}
        customClassNames={showLinkToPause || showSubText ? 'mt-6' : 'my-6'}
      />
      {showSubText && (
        <p className="ml-7 mb-8 mt-2 block text-xs">
          Breaks may result in a new tutor being assigned on resumption.
        </p>
      )}
      {showLinkToPause && (
        <div className="ml-8 mb-8">
          <Link
            size="xs"
            className="font-bold uppercase tracking-widest"
            to={PATH_HUB_CR_PAUSE_SESSIONS}
          >
            Pause sessions instead
          </Link>
        </div>
      )}
    </Fragment>
  );
};

ReasonsToPause.propTypes = {
  title: PropTypes.string,
  showLinkToPause: PropTypes.bool,
  showSubText: PropTypes.bool,
};
ReasonsToPause.defaultProps = {
  title: 'Pause sessions',
  showLinkToPause: false,
  showSubText: false,
};

export default ReasonsToPause;
