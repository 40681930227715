import React from 'react';
import { createRoot } from 'react-dom/client';
import './tailwind.css';
import { Auth } from 'aws-amplify';
import ReactRoot from './ReactRoot';
import { datadogLogs, datadogRum } from './logging/datadog';
import env from './env';
import getAppVersion from './util/getAppVersion';

console.log('version: ', getAppVersion());
console.log('env: ', env.NODE_ENV, process.env.NODE_ENV);

Auth.configure({
  // REQUIRED - Amazon Cognito Identity Pool ID
  identityPoolId: env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  // REQUIRED - Amazon Cognito Region
  region: env.REACT_APP_AWS_COGNITO_REGION,
  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: env.REACT_APP_AWS_COGNITO_USER_POOL_WEBCLIENT_ID,
  oauth: {
    domain: env.REACT_APP_AWS_OAUTH_DOMAIN,
    scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    redirectSignIn: env.REACT_APP_AWS_OAUTH_REDIRECT_SIGNIN,
    redirectSignOut: env.REACT_APP_AWS_OAUTH_REDIRECT_SIGNOUT,
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
});

if (env.REACT_APP_DATADOG_RUM_ENABLED) {
  datadogRum.get().catch(console.error);
} else {
  console.log('DATADOG RUM disabled');
}

if (env.REACT_APP_DATADOG_LOG_ENABLED) {
  datadogLogs.get().catch(console.error);
} else {
  console.log('DATADOG LOGS disabled');
}

const container = document.getElementById('root')
const root = createRoot(container);
root.render(<ReactRoot />);