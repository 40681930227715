import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { getOneTerm } from '../../../selectors/hubSelector';
import { AU_SCHOOL_TERMS } from '../../../util/constants';

const SummerPromoLegalCopy = ({ thisYearTerm4 }) => {
  const term4EndWeekSaturdayFormatted = moment(thisYearTerm4.endDate).day('Saturday').format('MMM DD');
  return (
    <p className="w-full text-xs lg:w-1/2 text-grey-6">
      *The discount will be applied to every session booked during the summer holiday period (
      {term4EndWeekSaturdayFormatted} - Jan 31). This special offer cannot be combined with another promotion.
    </p>
  );
};

SummerPromoLegalCopy.propTypes = {
  thisYearTerm4: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect((state) => {
  // just get 2022 term 4.
  return {
    thisYearTerm4: getOneTerm(state, AU_SCHOOL_TERMS.term4, '2022'),
  };
}, null)(SummerPromoLegalCopy);
