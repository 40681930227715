import { createApi } from './core';
import { accountApi } from './services/account';
import { enrolmentApi } from './services/enrolment';
import { scheduleApi } from './services/schedule';
import { schoolTermApi } from './services/schoolTerm';
import { sessionApi } from './services/session';
import { staticApi } from './services/static';
import { assessmentApi } from './services/assessment';
import { studentApi } from './services/student';
import { virtualCoachApi } from './services/virtualCoach';

export const api = createApi.root({
	enrolment: enrolmentApi,
	session: sessionApi,
	schedule: scheduleApi,
	static: staticApi,
	account: accountApi,
	schoolTerm: schoolTermApi,
	assessment: assessmentApi,
	student: studentApi,
	virtualCoach: virtualCoachApi,
});
