import { AlertLevel } from '@cluey/cluey-components';
import { get } from 'lodash';
import { PAYMENT_STATUS } from '../../types/hubEnums';
import type { Session, SessionPolicy } from '../../types/hubInterfaces';

export const getAlertData = ({ sessionToSkip, sessionPolicy }: {
  sessionPolicy: SessionPolicy;
  sessionToSkip: Session;
}): {
  alertType: AlertLevel;
  body: string;
  title: string;
  color: 'bg-blue-1' | 'bg-yellow-1';
} => {
  let alertType, title, color: 'bg-blue-1' | 'bg-yellow-1' | '' = '';
  let fullCreditHours = get(sessionPolicy, 'cancelSession.fullCreditHours');
  let partialCreditHours = get(sessionPolicy, 'cancelSession.partialCreditHours');

  if (sessionToSkip.paymentStatus !== PAYMENT_STATUS.PAID) {
    return {
      title: 'Your current plan does not allow cancelling this session',
      body: sessionPolicy?.reschedule?.isAllowed ? 'Try rescheduling the session instead' : '',
      color: 'bg-yellow-1',
      alertType: 'warning'
    }
  }

  switch(sessionPolicy?.cancelSession?.credit) {
    case 'full':
      alertType = 'info';
      title = `Your current plan allows cancellation with full credit up to ${fullCreditHours} hours before the session`;
      color = 'bg-blue-1';
      break;
    case 'partial':
      title = `Your current plan allows cancellation with partial credit up to ${partialCreditHours} hours before the session`;
      color = 'bg-yellow-1';
      alertType = 'warning'
      break;
    case 'none':
      title = sessionPolicy?.cancelSession.isAllowed ? `Your current plan does not allow free of charge cancellation when the session is within the next ${partialCreditHours} hours` : 'Your current plan does not allow free of charge cancellation';
      color = 'bg-yellow-1';
      alertType = 'warning';
      break;
  }
  return {
    alertType,
    body: sessionPolicy?.cancelSession?.externalMessage,
    title,
    color
  }
}