import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { HUB_SESSION_DATE_FORMAT } from '../../util/constants';
import { CopyToClipboard } from '../CopyToClipboard/CopyToClipboard';
import iconClockGreen from '../../assets/images/icon-clock--green.svg';
import iconClockOrange from '../../assets/images/icon-clock--orange.svg';
import iconCalendar from '../../assets/images/icon-calendar--darkgrey.svg';

/**
 * Determine styling of card based on time
 * @param {moment.Moment} start
 * @param {moment.Moment} end
 * @returns {{ style: {string}, icon: {string}, text: {string}}}
 */
const statusClasses = (start, end) => {
	const now = moment();
	if (start.isBefore(now) && end.isSameOrAfter(now)) {
		return {
			style: 'bg-green-1',
			icon: iconClockGreen,
			text: 'In progress...',
		};
	}
	if (start.isSameOrBefore(now.add(15, 'minutes'))) {
		return {
			style: 'bg-yellow-1',
			icon: iconClockOrange,
			text: 'Starting soon!',
		};
	}
	return {
		style: 'text-black bg-slate-1',
		icon: iconCalendar,
		text: 'Upcoming session',
	};
};

export const SchoolSessionCard = ({ session, containerClasses, nextSession, title }) => {
	const start = moment(session.startTime);
	const end = moment(session.endTime);
	const cardStyle = statusClasses(start, end);

	const titleContent =
		title === 'date' ? start.format(HUB_SESSION_DATE_FORMAT) : `Year ${session.yearLevel} ${session.subject}`;

	return (
		<div
			className={`flex shrink grow basis-0 flex-col overflow-hidden border border-custom-3 text-grey-6 ${containerClasses}`}
		>
			<div className={`flex w-full items-center px-3 py-2 ${cardStyle.style}`}>
				<img src={cardStyle.icon} alt="status icon" className="h-[20px] px-3" />
				<div className="grow text-sm">{cardStyle.text}</div>
			</div>
			<div className="grow px-5 py-3">
				<div className="font-body text-lg font-bold">{titleContent}</div>
				<div className="font-body text-[15px]">
					{start.format('h:mma')} - {end.format('h:mma')} with {session.tutor}
				</div>
				<ul className="flex list-none flex-col py-3">
					{session.students.map((student) => (
						<li key={student.uniqueEnrolmentId} className="flex items-center py-1">
							<span>{student.name}</span>
							{student.sessionToken && (
								<CopyToClipboard
									text={student.sessionToken}
									label="share session link"
									buttonClasses="uppercase text-xs font-body font-bold py-0"
								/>
							)}
						</li>
					))}
				</ul>
			</div>
			{nextSession && (
				<div className="border-ice border-t px-5 py-3">
					<div className="text-xs font-bold uppercase tracking-[1px]">Following session</div>
					<div className="py-3 text-sm">
						{`${moment(nextSession.startTime).format('dddd, MMM D [at] h:mma')} with ${nextSession.tutor}`}
					</div>
				</div>
			)}
		</div>
	);
};

SchoolSessionCard.propTypes = {
	session: PropTypes.shape({
		startTime: PropTypes.string,
		endTime: PropTypes.string,
		subject: PropTypes.string,
		tutor: PropTypes.string,
		packageName: PropTypes.string,
		students: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string,
				sessionLink: PropTypes.string,
			})
		),
		yearLevel: PropTypes.number,
	}).isRequired,
	containerClasses: PropTypes.string,
	nextSession: PropTypes.shape({
		startTime: PropTypes.string,
		endTime: PropTypes.string,
		tutor: PropTypes.string,
	}),
	title: PropTypes.oneOf(['subject', 'date']),
};

SchoolSessionCard.defaultProps = {
	containerClasses: '',
	nextSession: null,
	title: 'subject',
};
