import React from 'react';
import PropTypes from 'prop-types';
import { GenericTextSection } from './GenericTextSection';

export class TutorFeedback extends React.Component {
  render() {
    const { feedbackText, tutorName } = this.props;
    return (
      <GenericTextSection
        text={feedbackText}
        title="TUTOR FEEDBACK"
        messageWhenEmpty={`${tutorName} did not provide any feedback for the session.`}
      />
    );
  }
}

TutorFeedback.propTypes = {
  feedbackText: PropTypes.string,
  tutorName: PropTypes.string.isRequired,
};

TutorFeedback.defaultProps = {
  feedbackText: null,
};
