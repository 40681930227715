import { Container } from '@cluey/cluey-components';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchAccountDetails } from '../../../actions/hub/hubAccountPageActions';
import { fetchAllEnrolments } from '../../../actions/hub/hubEnrolmentsPageActions';
import withHeadTag from '../../../common/HeadComponent';
import PageError from '../../../common/PageError';
import useAccountPricingPlanQuery from '../../../hooks/queries/useAccountPricingPlanQuery';
import AccountPageLayout from '../../../templates/AccountPageLayout/AccountPageLayout';
import PaymentPolicyFAQ from '../components/PaymentPolicyFAQ';
import { isLegacyPlan } from '../helpers';
import NextPaymentSection from './components/NextPaymentSection/NextPaymentSection';
import PricingPlanDetailsSection from './components/PricingPlanDetailsSection/PricingPlanDetailsSection';

const fetchData = () => (dispatch) => {
  return dispatch(fetchAccountDetails()).then(() => {
    return dispatch(fetchAllEnrolments());
  });
};

const PricingPlanDetailsPage = () => {
  const {
    data: accountPricingPlan,
    isLoading: isLoadingAccountPricingPlan,
    isError: isErrorAccountPricingPlan,
  } = useAccountPricingPlanQuery();
  const { currentPricingPlan, upcomingPricingPlan } = accountPricingPlan || {};
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchData);
  }, [dispatch]);

  return (
    <AccountPageLayout loading={isLoadingAccountPricingPlan}>
      <Container>
        {!isLoadingAccountPricingPlan && isErrorAccountPricingPlan && (
          <PageError activePage="hub-page" retryEnabled={false} />
        )}
        {!isLoadingAccountPricingPlan && !isErrorAccountPricingPlan && (
          <section className="mb-[60px]">
            <NextPaymentSection />
            <PricingPlanDetailsSection plan={upcomingPricingPlan || currentPricingPlan} />
          </section>
        )}
        {!isLegacyPlan(currentPricingPlan) && <PaymentPolicyFAQ />}
      </Container>
    </AccountPageLayout>
  );
};

export default withHeadTag(PricingPlanDetailsPage);
