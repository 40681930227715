import { ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
	children: ReactNode | Array<ReactNode>;
	className?: string;
}

const ShadowContainer = ({ children, className }: Props) => {
	return (
		<div
			className={classNames(
				'rounded-lg border border-grey-2 p-0 shadow-none md:px-14 md:py-10 md:shadow-md',
				className
			)}
		>
			{children}
		</div>
	);
};

export { ShadowContainer };
