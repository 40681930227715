import { Auth } from 'aws-amplify';
import aws4 from 'aws4';

type RequestOptions = {
  host: string;
  path: string;
  region: string;
  service: string;
  method: RequestInit['method'];
  headers: Record<string, string>;
  body?: string;
};

let staffToken: string | null = null;

export const setStaffToken = (token: string | null) => {
  staffToken = token;
};

export const authenticate = async (request: RequestOptions, attachIdToken: boolean = false) => {
  const auth = await Auth.currentCredentials();
  const session = await Auth.currentSession();
  const idToken = session.getIdToken().getJwtToken();

  if (staffToken) {
    request.headers['stafftoken'] = staffToken;
  }

  if (attachIdToken && idToken) {
    request.headers['authorization'] = `Bearer ${idToken}`;
  } else {
    aws4.sign(request, auth);
  }
};
