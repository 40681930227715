// keep previous tutor page
export const TRACKING_KPT = {
  PAGE: 'previous-tutor-availability',
  CHANGE_TUTOR: 'previous-tutor-change-tutor',
  KEEP_NEW_TUTOR: 'previous-tutor-keep-new-tutor',
} as const;

// change schedule page
export const TRACKING_CSP = {
  PAGE: 'hub-change-schedule',
  CURRENT_TUTOR_AVAILABILITY: 'change-schedule-view-current-tutor-only',
  OTHER_FREQUENCY_CHANGE: 'change-schedule-other-frequency-contact',
  SUCCESS: 'hub-change-schedule-success-view-enrolment',
} as const;

// ship sessions
export const TRACKING_SS = {
  DEFAULT: 'gtm-skip-confirmation',
  NO_REFUND: 'gtm-skip-without-refund',
} as const;

// manage pause
export const TRACKING_MP = {
  PAGE: 'hub-manage-pause',
  CONFIRM: 'hub-manage-pause-confirm-update',
  CTA_CONTACT: 'hub-manage-pause-contact-us',
  CTA_CHANGE_SCHEDULE: 'hub-manage-pause-change-schedule',
  SUCCESS: 'hub-manage-pause-success-view-enrolment',
} as const;

// pause enrolment
export const TRACKING_PE = {
  SUCCESS: 'gtm-hub-confirm-pause',
  SUMMER_CONFIRM_PAUSE: 'pause_eoy_confirm_pause',
  SUMMER_CANCEL_CTA: 'pause_eoy_cancel',
} as const;

// cancel enrolment
export const TRACKING_CE = {
  SUCCESS: 'gtm-hub-confirm-cancel',
  OPTION_PAUSE: 'cancel_intercept_pause',
  OPTION_MANAGE_PAUSE: 'cancel_intercept_manage_pause',
  OPTION_SWITCH_TO_GROUP: 'cancel_intercept_switch_to_group',
  OPTION_SWITCH_TO_PRIVATE: 'cancel_intercept_switch_to_private',
  OPTION_CHANGE_TUTOR: 'cancel_intercept_tutor_change',
  OPTION_CHANGE_CONTENT: 'cancel_intercept_content_change',
  OPTION_CHANGE_SCHEDULE: 'cancel_intercept_change_schedule',
  OPTION_CANCEL: 'cancel_intercept_cancel',
} as const;

// enrolment detail
export const TRACKING_EDP = {
  PAGE: 'hub-enrolment-details',
} as const;

// NAPLAN
export const TRACKING_NAPLAN = {
  PAGE: 'hub-naplan-details',
} as const;

// temp tutor change
export const TRACKING_TTC = {
  CTA_CHANGE_SINGLE_SESSION: 'hub-enrolment-details-change-session-same-tutor',
  CTA_PAUSE_MULTIPLE_SESSIONS: 'hub-enrolment-details-pause-temp',
  PAGE_PAUSE_MULTIPLE_SESSIONS: 'hub-pause-temp',
  CONFIRM__PAUSE_MULTIPLE_SESSIONS: 'hub-confirm-pause-temp',
  PAGE_CHANGE_SINGLE_SESSION: 'hub-change-session-same-tutor',
  AVAILABILITY_CHANGE_SINGLE_SESSION: 'hub-timeslot-same-tutor',
  CTA_KEEP_CURRENT_TIME: 'hub-keep-time-new-tutor',
  SKIP_CURRENT_SESSION: 'hub-skip-session-wait-for-current-tutor',
  CONFIRMATION_CHANGE_SINGLE_SESSION: 'hub-change-session-confirmation',
  CONFIRM_ACTION_CHANGE_SINGLE_SESSION: 'hub-confirm-session-change',
  CTA_SELECT_NEW_TIME: 'hub-change-session-confirmation-go-back',
  SUCCESS_CHANGE_SINGLE_SESSION: 'hub-change-session-success',
  SUCCESS_CTA_ENROLMENT_DETAIL: 'hub-change-session-success-view-enrolment',
  ERROR_CHANGE_SINGLE_SESSION: 'hub-change-session-error-return',
} as const;

//code camp
export const TRACKING_CC = {
  FIND_A_CAMP: 'hub_pause_code_camp_promo_proceed',
  SKIP: 'hub_pause_code_camp_promo_skip',
  CLOSE: 'hub_pause_code_camp_promo_close',
  HOME_PROMO: 'hub-home-code-camp-promo-link',
  STUDENT_PROFILE: 'hub-student-profile-code-camp-promo-link', // not sure if marketing has this but I added it anyway
} as const;
