import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import ClueyLogo from '../../assets/images/cluey-logo.svg';

export const SchoolsHeader = () => {
  const location = useLocation();

  return (
    <div className="flex w-full bg-white border-b-4 border-secondary header-container">
      <header className="container mx-auto flex items-center header">
        <Link to={`${location.pathname}${location.search}`}>
          <img src={ClueyLogo} alt="Cluey Logo" className="w-[80px] h-[30px] lg:w-[106px] lg:h-[40px]" />
        </Link>
        <span
          className="ml-4 pl-5 md:pl-4 font-display text-lg md:text-xl lg:text-2xl font-bold
        text-secondary uppercase border-l-4 border-grey-2 leading-none"
        >
          School Hub
        </span>
      </header>
    </div>
  );
};
