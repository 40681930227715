import initialState from './initialState';
import {
  HUB_TTC_FETCH_CURRENT_TUTOR_AVAILABILITY_SUCCESS,
  HUB_TTC_FETCH_CURRENT_TUTOR_AVAILABILITY_FAILED,
  HUB_TTC_CHANGE_SESSION_WITH_ENROLMENT_TUTOR_CLEAN,
  HUB_TTC_SAVE_SELECTED_SESSION_DAY_TIME,
} from '../actions/actionTypes';

export default function hubChangeSessionWithEnrolmentTutor(state = initialState.hubChangeSessionWithEnrolmentTutor, action) {
  switch (action.type) {
    case HUB_TTC_FETCH_CURRENT_TUTOR_AVAILABILITY_SUCCESS:
      return {
        ...state,
        tutorAvailabilities: action.payload.tutorAvailability,
      };
    case HUB_TTC_FETCH_CURRENT_TUTOR_AVAILABILITY_FAILED:
    case HUB_TTC_CHANGE_SESSION_WITH_ENROLMENT_TUTOR_CLEAN:
      return {
        ...state,
        tutorAvailabilities: [],
      };
    case HUB_TTC_SAVE_SELECTED_SESSION_DAY_TIME:
      return {
        ...state,
        selectedDayTime: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
