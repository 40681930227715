import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@cluey/cluey-components';
import { useHistory } from 'react-router';

import PageHead from '../PageHead';
import { PATH_HUB_CR_ADD_ENROLMENT, PATH_HUB_MS_STUDENTS_AND_SUBJECTS } from '../../../util/pagePath';
import { STUDENT_SELECTION_MODAL_TITLE } from '../../../util/constants';
import SelectionModal, {
  useSelectionModalProps,
} from '../contact/SelectionModal';
import { getStudentsAsTextValuePair } from '../../../selectors/hubSelector';
import {
  SelectionModalOptionsDefault,
  SelectionModalOptionsType,
} from '../../../types/hubPropTypes';


const EnrolmentsPageContentHeadNav = ({ students }) => {
  const {
    showModal,
    selectedRadio,
    setSelectedRadio,
    toggleModal,
  } = useSelectionModalProps();

  const history = useHistory();

  const addNewEnrolmentClickHandler = () => {
    history.push(PATH_HUB_MS_STUDENTS_AND_SUBJECTS)
  };

  return (
    // use lg:h-[54px] to stop the PageHead jumping
    // this is height of Sessions/Account head
    <PageHead title="Enrolments" containerClasses="flex lg:h-[54px]">
      <div className="border-b border-slate-2 pb-3">
        <Button
          appearance="reverse"
          size="xs"
          onClick={addNewEnrolmentClickHandler}
        >
          <span className="lg:hidden">Add</span>
          <span className="hidden lg:block">Add new enrolment</span>
        </Button>
      </div>
      <SelectionModal
        showModal={showModal}
        toggleModal={toggleModal}
        redirect={PATH_HUB_CR_ADD_ENROLMENT}
        selectedRadio={selectedRadio}
        setSelectedRadio={setSelectedRadio}
        radios={students}
        title={STUDENT_SELECTION_MODAL_TITLE}
        isStudentSelection
        showBackBtn
      />
    </PageHead>
  );
};

EnrolmentsPageContentHeadNav.propTypes = {
  students: SelectionModalOptionsType,
};

EnrolmentsPageContentHeadNav.defaultProps = {
  students: SelectionModalOptionsDefault,
};

export default connect((state) => {
  return {
    students: getStudentsAsTextValuePair(state),
  };
}, null)(EnrolmentsPageContentHeadNav);
