import { IconProps as Props } from "../types/hubInterfaces";
import { iconDefaults } from ".";
import classNames from 'classnames';

function DollarIcon(props: Props) {
  const { width, height, className } = { ...iconDefaults, ...props };
  return (
    <svg className={classNames('fill-current', className)} xmlns="http://www.w3.org/2000/svg" width={`${width}`} height={`${height}`} viewBox="0 0 16 16">
      <title>dollar icon</title>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.84725 11.5287V12.5331C8.84725 12.915 8.53567 13.2466 8.14343 13.2466C7.74153 13.2466 7.41961 12.915 7.41961 12.5331V11.5404C6.7599 11.4498 6.21255 11.2295 5.76103 10.9134C5.58007 10.7928 5.45943 10.5919 5.45943 10.3606C5.45943 9.98868 5.76103 9.68709 6.12294 9.68709C6.26356 9.68709 6.3942 9.72707 6.50484 9.80772C6.96739 10.129 7.52025 10.3406 8.19375 10.3406C8.97789 10.3406 9.35979 9.96869 9.35979 9.58644C9.35979 9.08356 8.7766 8.91294 8.0028 8.73199C6.90707 8.48038 5.49976 8.17878 5.49976 6.68117C5.49976 5.73744 6.19153 4.9471 7.41961 4.72513V3.73281C7.41961 3.34057 7.74153 3.01933 8.14343 3.01933C8.53567 3.01933 8.84725 3.34057 8.84725 3.73281V4.71789C9.3329 4.79751 9.78615 4.95986 10.2246 5.21354C10.4155 5.31418 10.5562 5.53512 10.5562 5.77639C10.5562 6.13796 10.2546 6.43955 9.903 6.43955C9.77202 6.43955 9.65138 6.39957 9.56108 6.33925C9.0482 6.048 8.51568 5.90703 8.04313 5.90703C7.29897 5.90703 6.9574 6.18863 6.9574 6.59052C6.9574 7.04273 7.52025 7.18405 8.29439 7.36466C9.40012 7.61593 10.8074 7.94785 10.8074 9.43582C10.8074 10.485 10.1653 11.3112 8.84725 11.5287ZM8.13343 1.59961C4.52536 1.59961 1.6001 4.52487 1.6001 8.1326C1.6001 11.741 4.52536 14.6663 8.13343 14.6663C11.7415 14.6663 14.6668 11.741 14.6668 8.1326C14.6668 4.52487 11.7415 1.59961 8.13343 1.59961Z" />
    </svg>
  )
}

export { DollarIcon };