import { orderBy } from "lodash";
import { BreakdownTopic } from "../../../../../api/types/assessment";
import lightBulbIcon from "../../../../../assets/images/icon-light-bulb.svg";
import greenCheckIcon from "../../../../../assets/images/icon-green-check.svg";
import getMistakes from "./getMistakes";
import hasMistakes from "./hasMistakes";

export type TopicClickHandler = (topic: BreakdownTopic, unitName: string) => void;

export interface TopicProps {
  data: BreakdownTopic;
  onTopicClicked: TopicClickHandler;
  unitName: string;
}

export const Topic = ({ data, onTopicClicked, unitName }: TopicProps) => {
  const topicHasMistakes = hasMistakes(data.summary?.questionsCorrect, data.summary?.questionsTotal);
  return (
    <button className="flex flex-row text-left" type="button" onClick={() => onTopicClicked(data, unitName)}>
      <div className="pt-1 mr-2">
      <img 
        alt="topic performance icon"
        src={data.summary?.questionsCorrect >= data.summary?.questionsTotal ? greenCheckIcon : lightBulbIcon}
        style={{ height: 16, width: 16 }}
      />
      </div>
      <div>
        <div className="underline underline-offset-4">{data.topicName}</div>
        {topicHasMistakes && <div className="text-sm">{getMistakes(data.summary?.questionsCorrect, data?.summary?.questionsTotal)}</div>}
      </div>
    </button>
  );
}

export interface TopicsProps {
  data: BreakdownTopic[];
  onTopicClicked: TopicClickHandler;
  unitName: string;
}

const Topics = ({ data, onTopicClicked, unitName }: TopicsProps) => {
  const dataByName = orderBy(data, 'topicName', 'asc');
  return (
    <div>
      {dataByName.map((topic, index) => {
        return (
          <div key={`topic_${index}_${topic.topicName}`} className="mb-3">
            <Topic data={topic} onTopicClicked={onTopicClicked} unitName={unitName} />
          </div>
        );
      })}
    </div>
  )
}

export default Topics;