import { Link } from 'react-router-dom';
import { Modal, Pending } from '@cluey/cluey-components';
import { OptionCard, OptionCardContent } from '../../EnrolmentManagement/OptionCard';
import { useState } from 'react';
import { isGroupSessionCheck } from '../../../util/helpers';
import { IntentIntro } from '../../IntentIntro';
import { useHistory } from 'react-router';
import { PATH_HUB_MS_STUDENTS_AND_SUBJECTS } from '../../../util/pagePath';
import { Enrolment } from '../../../api/types/enrolment';
import { api } from '../../../api';
import { Loader } from '../../Loader/Loader';

interface Props {
  enrolmentLinkTo(enrolment: Enrolment): string;
  closeModal?(): void;
  open: boolean;
  openInNewTab: boolean;
  onSelect?(enrolment: Enrolment, link: string): void;
  position: 'top' | 'middle' | 'bottom';
}

const SelectEnrolmentModal = (props: Props) => {
  const { data: enrolments, isLoading: loadingEnrolments } = api.enrolment.all.useQuery('active');
  const { open, enrolmentLinkTo, closeModal, openInNewTab, onSelect, position = 'middle' } = props;
  const history = useHistory();
  const hasEnrolments = enrolments?.length > 0;
  return (
    <Modal
      open={open}
      setOpen={closeModal}
      closeOnBackground
      className="z-10"
      accent
      closeButton
      position={position}
    >
      <Pending
        loading={loadingEnrolments}
        loader={<Loader className="min-h-[100px] flex items-center justify-center" />}>
        <div className="py-8 px-8">
          {hasEnrolments ? (
            <>
              <h2 className="pb-6 font-display text-2xl font-bold tracking-[-0.02rem] md:text-4xl lg:text-[40px] lg:leading-[48px]">
                {SELECT_ENROLMENT_MODAL_HEADING}
              </h2>
              <IntentIntro className="mb-10">Which enrolment do you need to manage?</IntentIntro>
              <ul className="list-none">
                {Array.isArray(enrolments) && (enrolments || []).map((enrolment) => {
                  const isGroupSession = isGroupSessionCheck(enrolment.type);
                  const courseNameAndType = `${enrolment.student}'s ${enrolment.subject} ${
                    isGroupSession ? '(Group)' : '(1-1)'
                  }`;
                  const linkTo = enrolmentLinkTo(enrolment) || '';
                  const onEnrolmentSelected = () => {
                    closeModal();
                    if (openInNewTab) {
                      window.open(linkTo);
                    } else {
                      history.push(linkTo);
                    }
                    onSelect?.(enrolment, linkTo);
                  }

                  return (
                    <li key={enrolment.enrolmentId}>
                      <OptionCard onClickHandler={onEnrolmentSelected} openInNewTab={openInNewTab}>
                        <OptionCardContent>{courseNameAndType}</OptionCardContent>
                      </OptionCard>
                    </li>
                  );
                })}
              </ul>
            </>
          ) : (
            <>
              <IntentIntro className="mb-0">
                <span>You have no active enrolments. </span>
                <span className="ml-2">
                  <Link to={PATH_HUB_MS_STUDENTS_AND_SUBJECTS} className="text-blue-5 hover:text-purple-6 hover:underline">
                    Add students or subjects.
                  </Link>
                </span>
              </IntentIntro>
            </>
          )}
        </div>
      </Pending>
    </Modal>
  );
};

SelectEnrolmentModal.defaultProps = {
  openInNewTab: false,
  onSelect: undefined,
  position: "middle",
};

export const useSelectEnrolmentModal = () => {
  const [open, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  return {
    open,
    openModal,
    closeModal,
    SelectEnrolmentModal,
  };
};

export const SELECT_ENROLMENT_MODAL_HEADING = 'Select Enrolment';
export default SelectEnrolmentModal;
