import moment from 'moment';
import { isEmpty, range, sortBy } from 'lodash';
import { Session, SessionPolicy } from '../../types/hubInterfaces';
import { PAUSE_ALLOWED } from '../../types/hubEnums';
import { SchoolHolidayTerm } from '../../types/hubTypes';
import { getCurrentTerm, getNextTerm } from './schoolTerm';

/* get affected sessions of pause attempt */
export const getAffectedSessionsByQty = ({ enrolmentId, sessions, qty, ignore4WeeksRestriction = false }) => {
  if (!enrolmentId || isEmpty(sessions) || !qty) {
    return [];
  }

  const sortedSessions = sortBy(sessions, 'startTime');
  // when pause next * sessions, exclude today's sessions
  const filteredSessions = sortedSessions.filter((s) => {
    const { uniqueEnrolmentId, startTime } = s;
    const isEnrolment = uniqueEnrolmentId === enrolmentId;
    const isAfterToday = moment(startTime).isAfter(moment(), 'day');
    const isIn4Weeks = moment(startTime).isSameOrBefore(moment().add(4, 'weeks'), 'day');

    return ignore4WeeksRestriction ? isEnrolment && isAfterToday : isEnrolment && isAfterToday && isIn4Weeks;
  });

  return filteredSessions.splice(0, qty);
};

export const getPauseRange = ({ allowedWeeks }: { allowedWeeks: string | number }) => {
  if (!allowedWeeks) {
    return [];
  }
  return range(0, Number(allowedWeeks)).map((week) => ({
    text: week + 1,
    value: week + 1,
  }));
};

export const getTodaySessions = ({ enrolmentId, sessions }): Array<Session> => {
  if (!enrolmentId || isEmpty(sessions)) {
    return [];
  }

  return sessions.filter((s) => {
    const { uniqueEnrolmentId, startTime } = s;
    return uniqueEnrolmentId === enrolmentId && moment(startTime).isSame(moment(), 'day');
  });
};

export const getSessionsWithin48Hours = ({
  enrolmentId,
  sessions,
  timezone,
}: {
  enrolmentId: string;
  sessions: Array<Session>;
  timezone: string;
}): Array<Session> => {
  if (!enrolmentId || isEmpty(sessions)) {
    return [];
  }

  return sessions.filter((s) => {
    const { uniqueEnrolmentId, startTime } = s;
    const theNext2Days = moment.tz(timezone).startOf('day').add(2, 'days');
    return uniqueEnrolmentId === enrolmentId && moment.utc(startTime).tz(timezone).isSameOrBefore(theNext2Days, 'day');
  });
};

export const getSummerPauseMinAndMaxDates = ({
  upcomingTerms,
  enrolmentPolicy,
  schoolTerms,
  preventCancel,
}: {
  upcomingTerms: Array<SchoolHolidayTerm>;
  enrolmentPolicy: SessionPolicy;
  schoolTerms: Array<SchoolHolidayTerm>;
  preventCancel: boolean;
}) => {
  if (!enrolmentPolicy || !schoolTerms) {
    return {
      minDate: null,
      maxPauseDate: null,
      maxDate: null,
    };
  }
  const term4 = getCurrentTerm(upcomingTerms);
  const term1 = getNextTerm(upcomingTerms);

  const startOfSummer = moment.utc(term4?.endDate).add(1, 'days');
  const endOfSummer = moment.utc(term1?.startDate).subtract(1, 'days');
  if (!startOfSummer.isValid() || !endOfSummer.isValid()) {
    return {
      minDate: null,
      maxPauseDate: null,
      maxDate: null,
    };
  }
  const {
    pause: { pauseAllowed },
  } = enrolmentPolicy;

  const minDate = preventCancel
    ? moment.utc().add(1, 'day').format('YYYY-MM-DD')
    : moment.max([startOfSummer, moment.utc().add(1, 'day')]).format('YYYY-MM-DD');
  if (pauseAllowed === PAUSE_ALLOWED.ANYTIME) {
    const resumeDate = moment('2025-02-28').format('YYYY-MM-DD');

    return {
      minDate,
      /*
        For flexi, the Friday of [end of summer + allowed weeks (4)] is Feb 28.
      */
      maxPauseDate: endOfSummer.format('YYYY-MM-DD'),
      maxDate: resumeDate,
    };
  } else {
    return {
      minDate,
      maxPauseDate: endOfSummer.format('YYYY-MM-DD'),
      maxDate: moment(term1?.startDate).format('YYYY-MM-DD'),
    };
  }
};
