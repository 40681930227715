import initialState from '../../initialState';
import * as actionTypes from '../../../actions/actionTypes';

export default function hubEnrolmentDetailPageReducer(state = initialState.ui.apiState.hubEnrolmentDetailPage, action) {
  switch (action.type) {
    case actionTypes.HUB_EP_FETCH_ENROLMENT_DETAIL_START:
      return {
        ...state,
        firstFetch: true,
        fetchError: false,
      };
    case actionTypes.HUB_EP_FETCH_ENROLMENT_DETAIL_SUCCESS:
      return {
        ...state,
        firstFetch: false,
        fetchError: false,
      };
    case actionTypes.HUB_EP_FETCH_ENROLMENT_DETAIL_FAILED:
      return {
        ...state,
        firstFetch: false,
        fetchError: true,
        previousTutor: {
          ...state.previousTutor,
          firstFetch: false,
          fetchError: true,
        },
        tutorDetail: {
          ...state.tutorDetail,
          firstFetch: false,
          fetchError: true,
        },
      };
    case actionTypes.HUB_EP_FETCH_COURSE_OVERVIEW_START:
      return {
        ...state,
        courseOverview: {
          ...state.courseOverview,
          firstFetch: true,
          fetchError: false,
        },
      };
    case actionTypes.HUB_EP_FETCH_COURSE_OVERVIEW_SUCCESS:
      return {
        ...state,
        courseOverview: {
          ...state.courseOverview,
          firstFetch: false,
          fetchError: false,
        },
      };
    case actionTypes.HUB_EP_FETCH_COURSE_OVERVIEW_FAILED:
      return {
        ...state,
        courseOverview: {
          ...state.courseOverview,
          firstFetch: false,
          fetchError: true,
        },
      };
    case actionTypes.HUB_EP_FETCH_PROGRESS_SUMMARY_START:
      return {
        ...state,
        progressSummary: {
          ...state.progressSummary,
          firstFetch: true,
          fetchError: false,
        },
      };
    case actionTypes.HUB_EP_FETCH_PROGRESS_SUMMARY_SUCCESS:
      return {
        ...state,
        progressSummary: {
          ...state.progressSummary,
          firstFetch: false,
          fetchError: false,
        },
      };
    case actionTypes.HUB_EP_FETCH_PROGRESS_SUMMARY_FAILED:
      return {
        ...state,
        progressSummary: {
          ...state.progressSummary,
          firstFetch: false,
          fetchError: true,
        },
      };
    case actionTypes.HUB_EM_FETCH_PREVIOUS_TUTOR_INFO_START:
      return {
        ...state,
        previousTutor: {
          ...state.previousTutor,
          firstFetch: true,
          fetchError: false,
        },
      };
    case actionTypes.HUB_EM_FETCH_PREVIOUS_TUTOR_INFO_SUCCESS:
      return {
        ...state,
        previousTutor: {
          ...state.previousTutor,
          firstFetch: false,
          fetchError: false,
        },
      };
    case actionTypes.HUB_EM_FETCH_PREVIOUS_TUTOR_INFO_FAILED:
      return {
        ...state,
        previousTutor: {
          ...state.previousTutor,
          firstFetch: false,
          fetchError: true,
        },
      };
    case actionTypes.HUB_EM_FETCH_PREVIOUS_TUTOR_INFO_NOT_NEEDED:
      return {
        ...state,
        previousTutor: {
          ...state.previousTutor,
          firstFetch: false,
          fetchError: false,
        },
        tutorDetail: {
          ...state.tutorDetail,
          firstFetch: false,
          fetchError: false,
        },
      };
    case actionTypes.HUB_EM_FETCH_TUTOR_INFO_START:
      return {
        ...state,
        tutorDetail: {
          ...state.tutorDetail,
          firstFetch: true,
          fetchError: false,
        },
      };
    case actionTypes.HUB_EM_FETCH_TUTOR_INFO_SUCCESS:
      return {
        ...state,
        tutorDetail: {
          ...state.tutorDetail,
          firstFetch: false,
          fetchError: false,
        },
      };
    case actionTypes.HUB_EM_FETCH_TUTOR_INFO_FAILED:
      return {
        ...state,
        tutorDetail: {
          ...state.tutorDetail,
          firstFetch: false,
          fetchError: true,
        },
      };
    case actionTypes.HUB_EM_FETCH_TUTOR_AVAILABILITY_START:
      return {
        ...state,
        tutorAvailability: {
          ...state.tutorAvailability,
          firstFetch: true,
          fetchError: false,
        },
      };
    case actionTypes.HUB_EM_FETCH_TUTOR_AVAILABILITY_SUCCESS:
      return {
        ...state,
        tutorAvailability: {
          ...state.tutorAvailability,
          firstFetch: false,
          fetchError: false,
        },
      };
    case actionTypes.HUB_EM_FETCH_TUTOR_AVAILABILITY_FAILED:
      return {
        ...state,
        tutorAvailability: {
          ...state.tutorAvailability,
          firstFetch: false,
          fetchError: true,
        },
      };
    case actionTypes.HUB_EM_MP_FETCH_RESUME_SESSION_START:
      return {
        ...state,
        managePauseGetNewResumeSession: {
          ...state.managePauseGetNewResumeSession,
          firstFetch: true,
          fetchError: false,
        },
      };
    case actionTypes.HUB_EM_MP_SUBMIT_NEW_RESUME_DATE_START:
      return {
        ...state,
        managePauseNewResumeDateRequest: {
          ...state.managePauseNewResumeDateRequest,
          firstFetch: true,
          fetchError: false,
        },
      };
    case actionTypes.HUB_EM_MP_FETCH_RESUME_SESSION_SUCCESS:
      return {
        ...state,
        managePauseGetNewResumeSession: {
          ...state.managePauseGetNewResumeSession,
          firstFetch: false,
          fetchError: false,
        },
      };
    case actionTypes.HUB_EM_MP_SUBMIT_NEW_RESUME_DATE_SUCCESS:
      return {
        ...state,
        managePauseNewResumeDateRequest: {
          ...state.managePauseNewResumeDateRequest,
          firstFetch: false,
          fetchError: false,
        },
      };
    case actionTypes.HUB_EM_MP_FETCH_RESUME_SESSION_FAILED:
      return {
        ...state,
        managePauseGetNewResumeSession: {
          ...state.managePauseGetNewResumeSession,
          firstFetch: false,
          fetchError: true,
        },
      };
    case actionTypes.HUB_EM_MP_SUBMIT_NEW_RESUME_DATE_FAILED:
      return {
        ...state,
        managePauseNewResumeDateRequest: {
          ...state.managePauseNewResumeDateRequest,
          firstFetch: false,
          fetchError: true,
        },
      };
    case actionTypes.HUB_TTC_FETCH_SESSION_TUTOR_START:
      return {
        ...state,
        tempTutorChange: {
          ...state.tempTutorChange,
          firstFetch: true,
          fetchError: false,
        },
      };
    case actionTypes.HUB_TTC_FETCH_SESSION_TUTOR_SUCCESS:
      return {
        ...state,
        tempTutorChange: {
          ...state.tempTutorChange,
          firstFetch: false,
          fetchError: false,
        },
      };
    case actionTypes.HUB_TTC_FETCH_SESSION_TUTOR_FAILED:
      return {
        ...state,
        tempTutorChange: {
          ...state.tempTutorChange,
          firstFetch: false,
          fetchError: true,
        },
      };
    default:
      return state;
  }
}
