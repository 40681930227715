function confirmReschedule({
  submitError, setSubmitError, tutorNumber, setIsLoading,
  shouldUpdateRequestStatus, updateRequestStatus, createESSLCase,
}) {
  if (submitError) {
      //
      setIsLoading(false);
  } else if (!tutorNumber) {
      setSubmitError('There was a problem with your request. Please try again later.');
      setIsLoading(false);
  } else if (shouldUpdateRequestStatus) {
      setIsLoading(true);
      updateRequestStatus();
  } else {
      setIsLoading(true);
      createESSLCase();
  }
}

export default confirmReschedule;