import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import InPageLoader from '../../common/InPageLoader';
import Error from '../../common/Error';
import { NextOnGoingSchedule } from './ChangeScheduleConfirmation/NextOnGoingSchedule';
import ConfirmationScreenNextSession from './ChangeScheduleConfirmation/ConfirmationScreenNextSession';
import iconCalendar from '../../assets/images/icon-calendar--orange.svg';

import { fetchTutorInfo, updateNewScheduleFailedHandler } from '../../actions/hub/hubChangeSchedulePageActions';

import { isIncentiveValid } from '../../util/summerCopy';
import { getSummerPromo } from '../../selectors/summerModeSelector';
import { PATH_HUB_ENROLMENT_CHANGE_SCHEDULE } from '../../util/pagePath';
import { PromoText } from './PromoText';
import TutorDetailsCompact from '../TutorInfo/TutorDetailsCompact';
import { useEnrolmentDetailQuery } from '../../hooks/queries/useEnrolmentQuery';
import mapStudentInformationToTutorDetails from '../TutorInfo/mapStudentInformationToTutorDetails';
import { TTBM_CUSTOMER_NUMBER, DATE_FORMAT_1, SESSION_TIME_FORMAT } from '../../util/constants';
import { setTutorTypeDefault } from '../../util/helpers';

const ChangeScheduleConfirmation = ({
	isLoading,
	loadingError,
	enrolmentId,
	enrolmentSfid,
	tutorNumber,
	tutorName,
	tutorState,
	tutorType,
	tutorPhoto,
	tutorDescription,
	onChangeClick,
	onConfirmClick,
	fetchTutor,
	showConfirmation,
	selectedDay,
	scheduleDate,
	sessionDuration,
	timezone,
	frequency,
	enrolmentType,
	summerPromo,
	isSavingSummerModeData,
	startDate,
	nextSessionIn4Hrs,
}) => {
	const { incentiveCode, promoText } = summerPromo;
	const [incentiveValid, setIncentiveValid] = useState(false);
	const { isLoading: isLoadingEnrolmentDetail, data } = useEnrolmentDetailQuery({ enrolmentId });
	const enrolmentDetail = data || {};
	const { studentInformation, englishTextStudied } = enrolmentDetail;
	const { skilledAt, highlyValuedFor } = mapStudentInformationToTutorDetails(studentInformation);
	useEffect(() => {
		fetchTutor();
	}, [fetchTutor]);

	useEffect(() => {
		setIncentiveValid(
			incentiveCode && isIncentiveValid(incentiveCode, selectedDay, { screen: PATH_HUB_ENROLMENT_CHANGE_SCHEDULE })
		);
	}, [incentiveCode, selectedDay]);

	if (isLoading || isSavingSummerModeData || isLoadingEnrolmentDetail) {
		return <InPageLoader position="relative" height="auto" />;
	}

	if (loadingError) {
		return (
			<div className="container mx-auto px-4 lg:px-0">
				<Error firstLine="We are having trouble processing your request" secondLine="Please try again in a few minutes">
					<button type="button" className="btn btn-primary btn-large" onClick={() => showConfirmation()}>
						Return to previous page
					</button>
				</Error>
			</div>
		);
	}
	const isMatchingTutor = tutorNumber === TTBM_CUSTOMER_NUMBER;

	return (
		<div className="container mx-auto mb-11 px-4">
			<div className="mb-7">
				<h2 className="fs-20px font-weight-400">Please review and confirm the new schedule</h2>
				{promoText && incentiveValid && (
					<div className="mb-4">
						<PromoText text={promoText} />
					</div>
				)}
			</div>
			<div className="flex flex-col overflow-hidden rounded-lg border border-grey-2 bg-white lg:flex-row">
				<div className="px-6 py-7 lg:w-7/12 lg:px-14 lg:py-12">
					<NextOnGoingSchedule
						selectedDay={selectedDay}
						scheduleDate={scheduleDate}
						timezone={timezone}
						onChangeClick={onChangeClick}
						startDate={startDate}
					/>
					{nextSessionIn4Hrs && (
						<div className="mb-10 flex flex-col items-start border-b border-grey-2 pb-10">
							<h3 className="mb-5 text-xs font-bold uppercase tracking-wider">Next Session</h3>
							<div className="mb-8 flex items-start lg:mb-11">
								<img src={iconCalendar} alt="icon" className="mr-5 lg:mr-6" />
								<div className="text-left">
									<p className="lh-1-33 mb-0 text-lg">
										{moment(nextSessionIn4Hrs.startTime).tz(timezone).format(DATE_FORMAT_1)}
									</p>
									<p className="lh-1-33 text-lg">
										{`${moment(nextSessionIn4Hrs.startTime).tz(timezone).format(SESSION_TIME_FORMAT)} - ${moment(
											nextSessionIn4Hrs.endTime
										)
											.tz(timezone)
											.format(SESSION_TIME_FORMAT)}`}
									</p>
								</div>
							</div>
						</div>
					)}
					<ConfirmationScreenNextSession
						enrolmentId={enrolmentId}
						enrolmentSfid={enrolmentSfid}
						tutorNumber={tutorNumber}
						enrolmentType={enrolmentType}
						selectedDay={selectedDay}
						scheduleDate={scheduleDate}
						sessionDuration={sessionDuration}
						timezone={timezone}
						onConfirmClick={onConfirmClick}
						frequency={frequency}
						startDate={startDate}
					/>
				</div>
				<div className="border-t border-grey-2 bg-slate-1 px-6 py-7 lg:w-5/12 lg:border-l lg:border-t-0 lg:px-14 lg:py-12">
					<div className="mb-6 flex items-center border-b border-grey-2 pb-6 lg:mb-7 lg:pb-7">
						<img
							src={`${tutorPhoto}?v=1`}
							alt="tutor avatar"
							className="mr-lg-6 mr-5 h-[60px] w-[60px] rounded-full border border-grey-3"
						/>
						<div className="flex flex-col">
							<span className="lh-1-33 mb-1 text-lg font-bold">{tutorName}</span>
							<span className="lh-1-33">
								{tutorState} {setTutorTypeDefault({ tutorType })}
							</span>
						</div>
					</div>
					{!isMatchingTutor && (
						<>
							<TutorDetailsCompact
								highlyValuedFor={highlyValuedFor}
								skilledAt={skilledAt}
								englishTextStudied={englishTextStudied}
							/>
							<div className="mb-4">
								<span className="lh-1 mb-3 text-xs font-bold uppercase tracking-wider">
									A LITTLE ABOUT {tutorName}…
								</span>
								<p className="lh-1-67 text-xs">{tutorDescription}</p>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

ChangeScheduleConfirmation.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	loadingError: PropTypes.bool.isRequired,
	enrolmentId: PropTypes.string.isRequired,
	enrolmentSfid: PropTypes.string.isRequired,
	tutorNumber: PropTypes.string.isRequired,
	tutorName: PropTypes.string.isRequired,
	tutorState: PropTypes.string.isRequired,
	tutorType: PropTypes.string.isRequired,
	tutorPhoto: PropTypes.string.isRequired,
	tutorDescription: PropTypes.string.isRequired,
	onChangeClick: PropTypes.func.isRequired,
	onConfirmClick: PropTypes.func.isRequired,
	fetchTutor: PropTypes.func.isRequired,
	showConfirmation: PropTypes.func.isRequired,
	selectedDay: PropTypes.string.isRequired,
	scheduleDate: PropTypes.string.isRequired,
	sessionDuration: PropTypes.number.isRequired,
	timezone: PropTypes.string.isRequired,
	frequency: PropTypes.string,
	enrolmentType: PropTypes.string.isRequired,
	summerPromo: PropTypes.shape({
		incentiveCode: PropTypes.string,
		promoText: PropTypes.string,
	}),
	isSavingSummerModeData: PropTypes.bool,
	startDate: PropTypes.instanceOf(Date).isRequired /* a momentjs object */,
	nextSessionIn4Hrs: PropTypes.any, //eslint-disable-line
};

ChangeScheduleConfirmation.defaultProps = {
	frequency: 'Weekly',
	summerPromo: {
		incentiveCode: '',
		promoText: '',
	},
	isSavingSummerModeData: false,
	nextSessionIn4Hrs: null,
};

export const ConnectedChangeScheduleConfirmationCSP = connect(
	(state) => {
		const { ui, hubEnrolmentDetailPage, hubChangeSchedulePage, hubUser } = state;
		const { firstFetch, fetchError } = ui.apiState.hubChangeSchedulePage;
		const { enrolmentId, enrolmentSfid, enrolmentType } = hubEnrolmentDetailPage;
		const {
			selectedDay,
			scheduleDate,
			sessionDuration,
			tutorNumber,
			tutorName,
			tutorState,
			tutorPhoto,
			tutorType,
			tutorDescription,
			startDate,
		} = hubChangeSchedulePage.selectedSchedule;

		return {
			isLoading: firstFetch,
			loadingError: fetchError,
			enrolmentId,
			enrolmentSfid,
			tutorNumber,
			tutorName,
			tutorState,
			tutorType,
			tutorPhoto,
			tutorDescription,
			selectedDay,
			scheduleDate,
			sessionDuration,
			timezone: hubUser.timezone,
			enrolmentType,
			summerPromo: getSummerPromo(state, { screen: PATH_HUB_ENROLMENT_CHANGE_SCHEDULE }),
			startDate,
		};
	},
	(dispatch) => {
		return {
			fetchTutor: bindActionCreators(fetchTutorInfo, dispatch),
			showConfirmation: bindActionCreators(updateNewScheduleFailedHandler, dispatch),
		};
	}
)(ChangeScheduleConfirmation);

export const ConnectedChangeScheduleConfirmation = connect(
	(state) => {
		const { enrolmentId, selectedSchedule, alternativeTutor, enrolmentSfid } = state.hubEnrolmentDetailPage;
		const { tutorName, tutorState, tutorPhoto, tutorType, tutorDescription } = alternativeTutor;
		const { selectedDay, scheduleDate, sessionDuration = 60, tutorNumber } = selectedSchedule;

		return {
			isLoading: false,
			loadingError: false,
			enrolmentId,
			enrolmentSfid,
			tutorNumber,
			tutorName,
			tutorState,
			tutorType,
			tutorPhoto,
			tutorDescription,
			selectedDay,
			scheduleDate,
			sessionDuration,
			timezone: state.hubUser.timezone,
			summerPromo: getSummerPromo(state, { screen: PATH_HUB_ENROLMENT_CHANGE_SCHEDULE }),
		};
	},
	(dispatch) => {
		return {
			fetchTutor: () => {},
			showConfirmation: bindActionCreators(updateNewScheduleFailedHandler, dispatch),
		};
	}
)(ChangeScheduleConfirmation);
