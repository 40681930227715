import { orderBy } from 'lodash';
import { AsessmentReportBreakdown } from '../../../../../api/types/assessment';
import Accordion from './Accordion';
import Topics, { TopicClickHandler } from './Topics';
import getScore from './getScore';

interface PerformanceBreakdownProps {
  data: AsessmentReportBreakdown[];
  onTopicClicked: TopicClickHandler;
}

const PerformanceBreakdown = ({ data, onTopicClicked }: PerformanceBreakdownProps) => {
  const dataByHighestScore = orderBy(data, 'summary.percentage', 'desc');
  
  return (
    <div>
      {dataByHighestScore.map(
        (section, index) => {
          const { summary, unitName, topics } = section;
          const isLastItem = index === data.length - 1;
          return (
          <Accordion
            key={`breakdown_${index}`}
            title={unitName}
            showBottomBorder={!isLastItem}
            subtitle={
              <div className="w-full flex flex-row justify-start">
                <div className="font-bold">{`${summary.percentage}%`}</div>
                <div className="border-r border-grey-2 mx-2" />
                <div className="flex-1 text-left">{getScore(summary.questionsCorrect, summary.questionsTotal)}</div>
              </div>
            }
          >
            <div>
              <Topics data={topics || []} onTopicClicked={onTopicClicked} unitName={unitName} />
            </div>
          </Accordion>
        )}
      )}
    </div>
  );
}


export default PerformanceBreakdown;