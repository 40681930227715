import { CompanyDetails } from "../../../types/hubInterfaces"
interface Props {
  companyDetails: CompanyDetails
}
const Footer = ({companyDetails: {country, address, postcode, businessNumber}}: Props) => {
  return (
    <div>
      <h6 className="mb-0 pt-6 pb-2 text-2xs font-bold uppercase tracking-[1px]">{country}</h6>
      <div className="flex flex-col">
        <div>{address}</div>
        <div>{postcode}</div>
        <div>{businessNumber}</div>
      </div>
    </div>
  )
}

export { Footer }