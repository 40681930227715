import React from 'react';
import { Link, LinkButton } from '@cluey/cluey-components';

interface SectionHeadBase {
  title: string;
}

interface SectionHeadLink extends SectionHeadBase {
  linkName: string;
  linkDes: string;
}

interface SectionHeadMultiple extends SectionHeadBase {
  links: { name: string; dest: string; cta?: boolean }[];
}

const SectionHead = ({ title, ...props }: SectionHeadLink | SectionHeadMultiple) => {
  return (
    <div className="mt-10 mb-4 flex items-center justify-between lg:mt-11 lg:mb-6">
      <h2 className="m-0 font-display text-xl font-bold leading-3 md:text-2xl lg:text-3xl">{title}</h2>
      {'linkName' in props ? (
        <Link to={props.linkDes} noUnderline className="font-bold" size="base">
          {props.linkName}
        </Link>
      ) : (
        <div className="flex items-center gap-4">
          {props.links.map((link) =>
            link.cta ? (
              <LinkButton to={link.dest} className="font-bold" key={link.name}>
                {link.name}
              </LinkButton>
            ) : (
              <Link to={link.dest} noUnderline className="font-bold" size="base" key={link.name}>
                {link.name}
              </Link>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default SectionHead;
