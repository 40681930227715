import { Button } from '@cluey/cluey-components';
import classnames from 'classnames';
import { useEffect, useRef } from 'react';

import { Loader } from '../../../../components/Loader/Loader';
import { PRICING_PLAN_POLICY_HEADERS } from '../../../../types/hubEnums';
import { PricePlan } from '../../../../types/hubTypes';
import { PRICING_PLAN_ROWS, useHasActiveGroupEnrolments } from '../../helpers';
import useChangePricingPlanContext, { ChangePricingPlanSteps } from '../useChangePricingPlanContext';
import { ComparisonPolicyRow } from './ComparisonPolicyRow';

export type ComparisonCardProps = {
  opened: boolean | string;
  plan: PricePlan;
  chipContent?: string;
  showHeaders?: boolean;
  showActionButton?: boolean;
  cardCount?: number;
};

const ComparisonCard = ({
  opened,
  plan,
  chipContent,
  showHeaders,
  showActionButton,
  cardCount,
}: ComparisonCardProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const elementRefs = useRef({
    infoBlock: useRef<HTMLDivElement>(null),
    planName: useRef<HTMLDivElement>(null),
  });

  const {
    currentPricingPlan,
    upcomingPricingPlan,
    selectedPlan,
    setSelectedPlan,
    setCurrentStep,
    maxHeights,
    setMaxHeights,
    isPlanPreviewPaymentDetailsLoading,
  } = useChangePricingPlanContext();
  const hasGroupEnrolments = useHasActiveGroupEnrolments();

  const isCurrent = currentPricingPlan?.pricingPlanName === plan.pricingPlanName;
  const isUpcoming = upcomingPricingPlan?.pricingPlanName === plan.pricingPlanName;
  const isSelectedPlan = selectedPlan?.pricingPlanName === plan.pricingPlanName;

  useEffect(() => {
    Object.entries(elementRefs.current).forEach(([key, ref]) => {
      if (ref.current) {
        if (Number(ref.current?.offsetHeight) > (maxHeights?.[key] || 0)) {
          setMaxHeights({ ...maxHeights, [key]: Number(ref.current?.offsetHeight) });
        }
      }
    });
  }, [elementRefs, maxHeights, setMaxHeights]);

  return (
    <div
      className={classnames(
        'mb-2 w-full overflow-hidden rounded-lg',
        cardCount >= 3 && 'md:w-2/6',
        cardCount <= 2 && 'md:w-1/2'
      )}
    >
      {showHeaders && (
        <div
          className={classnames(
            'py-[6px] text-center font-bold text-sm text-white',
            (isCurrent || isUpcoming) && !isSelectedPlan && 'bg-slate-5',
            isSelectedPlan && 'bg-blood-orange-4'
          )}
        >
          <b className="mx-auto">
            {(isCurrent || isUpcoming) && !isSelectedPlan && 'Current plan'}
            {isSelectedPlan && 'New plan'}
          </b>
        </div>
      )}
      <div
        ref={ref}
        className={classnames(
          'relative w-full border border-grey-3 transition-height duration-300 ease-in-out md:mt-0',
          showHeaders ? '' : 'mt-3.5 rounded-lg'
        )}
      >
        {/* INFO BLOCK */}
        <div ref={elementRefs.current.infoBlock} className="flex w-full flex-col gap-y-3 overflow-hidden p-1 px-4 pt-4">
          {chipContent && (
            <span
              className={classnames(
                'rounded-lg bg-blood-orange-4 p-2 text-sm font-bold leading-3 text-white md:top-4 md:h-auto md:p-2',
                showHeaders ? 'ml-auto' : 'absolute -top-3 right-4'
              )}
            >
              {chipContent}
            </span>
          )}
          <div
            className={classnames(
              'mt-2 flex flex-row items-center justify-between gap-x-2 md:flex-col md:items-start',
              showHeaders ? (!!chipContent ? 'md:-mt-7' : 'md:mt-3') : 'md:mt-10'
            )}
          >
            <div
              ref={elementRefs.current.planName}
              style={{ height: `${maxHeights?.planName}px` }}
              className="proxima-nova text-2xl font-bold text-grey-6"
            >
              {plan.pricingPlanName}
            </div>
            <div className="flex flex-row items-center gap-x-2 md:flex-col">
              <div className="proxima-nova text-5xl font-bold text-grey-6 ">
                <span className="align-top text-lg">$</span>
                {plan?.privatePriceTier?.costPerSession}
              </div>
              <div className="text-sm text-grey-5">Per session</div>
            </div>
          </div>
          {showActionButton && !isPlanPreviewPaymentDetailsLoading && (
            <Button
              size="lg"
              className="mt-4 w-full !px-0 !text-base md:mt-0 md:!text-lg"
              disabled={isCurrent || isPlanPreviewPaymentDetailsLoading}
              onClick={() => {
                setSelectedPlan(isUpcoming ? currentPricingPlan : plan);
                setCurrentStep(ChangePricingPlanSteps.CONFIRM_CHANGES);
                setMaxHeights(null);
              }}
            >
              {isCurrent ? 'Current plan' : isUpcoming ? 'Cancel change' : 'Select plan'}
            </Button>
          )}
          {showActionButton && isPlanPreviewPaymentDetailsLoading && <Loader size={10} className="my-3" />}
        </div>
        {/* POLICY BLOCK */}
        <div className="mt-10 flex w-full flex-col overflow-hidden md:mt-0">
          <ComparisonPolicyRow key={plan.pricingPlanName + '-Policy'} className="h-auto border-grey-2 md:!h-14">
            <div className="block text-xs font-bold uppercase md:invisible">Plan Policy</div>
          </ComparisonPolicyRow>
          {PRICING_PLAN_ROWS.map((row) => (
            <ComparisonPolicyRow
              row={row}
              key={plan.pricingPlanName + '-' + row.getHeader(plan)}
              className={classnames('flex flex-col gap-y-1 odd:bg-white even:bg-grey-1 md:flex-auto', {
                'rounded-b-lg': !opened && row.header === PRICING_PLAN_POLICY_HEADERS.PAUSING_SESSIONS,
              })}
            >
              <div className="text-sm font-bold text-grey-5 md:hidden">{row.getHeader(plan, hasGroupEnrolments)}</div>
              <div className="text-sm text-grey-6">{row.getContent(plan, hasGroupEnrolments)}</div>
            </ComparisonPolicyRow>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ComparisonCard;
