import { z } from 'zod';

export const Assessment = z.object({
	studentId: z.string(),
	createdAt: z.string(),
	completedAt: z.string().nullable(),
  country: z.string().nullable().optional(),
	subjectYearLevel: z.string().nullable(),
	subject: z.string().nullable(),
	yearLevel: z.string().nullable(),
	status: z.enum(['Accessed', 'Incomplete', 'Completed', 'Not Accessed']),
	questionsCorrect: z.number().nullable(),
	questionsCompleted: z.number().nullable(),
	questionsTotal: z.number().nullable(),
	assessmentResult: z.string().nullable(),
	studentLink: z.string(),
	enrolmentId: z.string().nullable(),
});
export type Assessment = z.infer<typeof Assessment>;

export const UnitOrTopic = z.object({
  type: z.enum(['unit', 'topic']),
  name: z.string(),
});
export type UnitOrTopic = z.infer<typeof UnitOrTopic>;

export const Summary = z.object({
  questionsCorrect: z.number().nullable(),
  questionsCompleted: z.number().nullable(),
  questionsTotal: z.number().nullable(),
  percentage: z.number().nullable(),
});
export type Summary = z.infer<typeof Summary>;

export const AssessmentReportSummary = Summary.extend({
  focusAreas: UnitOrTopic.array(),
  strengths: UnitOrTopic.array(),
});
export type AssessmentReportSummary = z.infer<typeof AssessmentReportSummary>;


export const BreakdownTopicDetail = z.object({
  conceptName: z.string(),
  isCorrectAnswer: z.boolean().nullable(),
});
export type BreakdownTopicDetail = z.infer<typeof BreakdownTopicDetail>

export const BreakdownTopic = z.object({
 details: BreakdownTopicDetail.array(),
 summary: Summary,
 topicName: z.string(),
});
export type BreakdownTopic = z.infer<typeof BreakdownTopic>

export const AsessmentReportBreakdown = z.object({
  unitName: z.string(),
  summary: Summary,
  topics: BreakdownTopic.array(),
});
export type AsessmentReportBreakdown = z.infer<typeof AsessmentReportBreakdown>

export const AssessmentReport = z.object({
  assessmentId: z.string().nullable(),
  assessmentName: z.string().nullable(),
  assessmentToken: z.string().nullable(),
  customerId: z.string().nullable(),
  assessmentNumber: z.union([z.string(), z.number()]).nullable(),
  lastUpdated: z.string().nullable(),
  started: z.string().nullable(),
  summary: AssessmentReportSummary,
  breakdown: AsessmentReportBreakdown.array().nullable(),
}).array();
export type AssessmentReport = z.infer<typeof AssessmentReport>;
