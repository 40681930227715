import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { isUndefined } from 'lodash';
import { Dropdown as ClueyDropdown } from '@cluey/cluey-components';
import FieldLabel from '../hub/FieldLabel';

import { DropdownItemsType } from '../../types/hubPropTypes';
import ChevronDown from '../../assets/images/simple-arrow-down.svg';

// A custom hook to make it easier to get the necessary props for a dropdown
// Just provide the options!
export function useDropdownProps({
  id,
  options = [],
  placeholder = 'Please select',
  labelText,
  defaultValue,
  containerClasses,
  disabled,
}) {
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  useEffect(() => {
    if (isUndefined(selectedOption)) {
      setSelectedOption(defaultValue);
    }
  }, [selectedOption, defaultValue]);
  // Note: It's preferred to come up with a non dynamic id to avoid DOM updates
  if (!id) {
    id = uuid();
  }

  return {
    id,
    options,
    placeholder,
    labelText,
    defaultValue,
    selectedOption,
    setSelectedOption,
    containerClasses,
    disabled,
  };
}

export const Dropdown = ({
  id,
  options,
  selectedOption,
  setSelectedOption,
  labelText,
  defaultValue,
  containerClasses,
  disabled,
}) => {
  return (
    <section className={`text-base ${containerClasses}`}>
      {labelText && <FieldLabel id={id} labelText={labelText} />}
      {/* TODO: NEED TO REPLACE WITH CLUEY COMPONENT*/}
      <ClueyDropdown
        id={id}
        value={selectedOption || defaultValue}
        disabled={disabled}
        options={options.map((option) => ({
          value: option.value,
          label: option.text,
        }))}
        chevronDown={<img src={ChevronDown} alt="open" />}
        onChange={(value, e) => {
          e.stopPropagation();
          setSelectedOption(value);
        }}
      />
      {/* <CustomInput
        type="select"
        id={id}
        onChange={(e) => {
          e.stopPropagation();
          setSelectedOption(e.target.value);
        }}
        value={selectedOption || defaultValue}
        disabled={disabled}
        className="w-full h-full pl-3 pr-7 bg-white border border-grey-3 rounded"
      >
        <option value="" hidden>
          {placeholder}
        </option>
        {options.map((option) => {
          const { text, ...otherProps } = option;
          return (
            <option {...otherProps} className="text-base" key={option.value}>
              {text}
            </option>
          );
        })}
      </CustomInput> */}
    </section>
  );
};

Dropdown.propTypes = {
  id: PropTypes.string.isRequired,
  selectedOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSelectedOption: PropTypes.func.isRequired,
  options: DropdownItemsType,
  labelText: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  containerClasses: PropTypes.string,
  disabled: PropTypes.bool,
};

Dropdown.defaultProps = {
  selectedOption: '',
  options: [],
  labelText: null,
  defaultValue: '',
  placeholder: '',
  containerClasses: '',
  disabled: false,
};
