import { Button, ButtonAsLink, Container, Link } from '@cluey/cluey-components';
import { ShadowContainer } from '../../../../../components/ShadowContainer';
import { SpecialNeeds } from '../../../../../components/hub/student/SpecialNeeds';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { FormFlowProps } from '../../../../../types/hubInterfaces';
import { ArrowBackIcon, ChevronDownIcon } from '../../../../../icons';
import { PATH_HUB_HOME, PATH_HUB_CR_ADD_ENROLMENT } from '../../../../../util/pagePath';
import { api } from '../../../../../api';
import isEmpty from 'lodash/isEmpty';
import { InlineLoader } from '../../../auth2/common';
import { useHistory } from 'react-router';
import type { CreateStudentParams } from '../../../../../api/services/student';
import { convertHubToSfLearningNeeds } from '../../../../../util/learning-needs';
import { StudentProfileData } from '../../StudentProfile/StudentProfile';
import { zodResolver } from '@hookform/resolvers/zod';

interface Props {
	formFlowProps: FormFlowProps;
}

type HistoryState = {
	enrolStudent?: boolean;
	firstName: string;
	lastName: string;
	yearLevel: string;
	location?: string;
	schoolName?: string;
};

const AddStudentSpecialNeeds = ({ formFlowProps }: Props) => {
	const { jump } = formFlowProps;
	const { mutate: createStudent, isLoading: creatingStudent } = api.student.create.useMutation();
	const history = useHistory<HistoryState>();
	const enrolStudent = history.location.state?.enrolStudent;

	// useFormContext seems to be bugged, the AddStudent component is remounted when navigating to this subroute
	// so formUtils.getValues() returns empty, calling formUtils.setValue will cause another remounting of AddStudent
	const formUtils = useForm<StudentProfileData>({
		defaultValues: {
			...history.location.state,
			specialLearningNeeds: [],
		},
		resolver: zodResolver(StudentProfileData),
	});
	
	const { firstName, lastName, yearLevel, location } = formUtils.getValues();
	const { refetch } = api.student.all.useQuery();
	const loading = creatingStudent || (formUtils.formState.isSubmitted && !formUtils.formState.errors.root);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	useEffect(() => {
		if (!firstName) {
			jump(0);
		}
	}, [firstName, jump]);

	const pollStudentsApi = (attempts: number) => {
		if (attempts >= 20) {
			history.push(PATH_HUB_HOME);
		}
		setTimeout(async () => {
			const { data } = await refetch();
			const newStudent = data.find(
				(student) => student.firstName === firstName && lastName === student.lastName && yearLevel === student.yearLevel
			);
			if (!!newStudent) {
				if (enrolStudent) {
					history.push(PATH_HUB_CR_ADD_ENROLMENT, {
						firstName,
						lastName,
						yearLevel,
						location,
					});
				} else {
					formFlowProps.next({ newStudent });
				}
				return;
			}
			pollStudentsApi(attempts + 1);
		}, 5000);
	};

	const resetLearningNeeds = () => {
		formUtils.setValue('specialLearningNeeds', []);
	};

	const submit = formUtils.handleSubmit(async () => {
		formUtils.clearErrors('root');
		const { firstName, lastName, yearLevel, schoolName, specialLearningNeeds, location } = formUtils.getValues();
		const params: CreateStudentParams = {
			firstname: firstName,
			lastname: lastName,
			curriculum: location,
			yearLevel,
			schoolId: schoolName,
			specialLearningNeeds: !isEmpty(specialLearningNeeds)
				? convertHubToSfLearningNeeds(specialLearningNeeds).join(';')
				: null,
		};
		createStudent(params, {
			onSuccess: async () => {
				pollStudentsApi(0);
			},
			onError: (error) => {
				formUtils.setError('root', {
					message: `There was an error encountered adding the student. Please try again. ${error}`,
				});
			},
		});
	});

	const skipSpecialNeeds = () => {
		resetLearningNeeds();
		submit();
	};

	return (
		<Container className="flex flex-col gap-6 px-4 py-7 md:py-8 lg:mb-0">
			<div>
				<Link className="text-xs font-bold uppercase" to={PATH_HUB_HOME}>
					Home
				</Link>
				<h1 className="text-[28px] font-bold">Special learning needs</h1>
			</div>
			{loading ? (
				<InlineLoader />
			) : (
				<ButtonAsLink onClick={skipSpecialNeeds} className="flex items-center gap-1.5">
					<span>Skip this step</span>
					<ArrowBackIcon className="rotate-[-180deg] text-blue-5" width={20} height={20} />
				</ButtonAsLink>
			)}
			<ShadowContainer className="p-6 md:p-0">
				<SpecialNeeds student={formUtils.getValues()} formUtils={formUtils} />
			</ShadowContainer>
			{formUtils.formState.errors?.root && (
				<p className="mt-1 text-xs text-red-5">{formUtils.formState.errors?.root.message}</p>
			)}
			<Button
				onClick={submit}
				disabled={loading}
				className="mt-3 flex  w-full items-center justify-center gap-5 self-start normal-case md:w-[256px]"
				size="lg"
			>
				{loading ? (
					<InlineLoader />
				) : (
					<>
						<span>Save and continue</span>
						<ChevronDownIcon className="rotate-[-90deg] text-white" />
					</>
				)}
			</Button>
			{!loading && (
				<Link className="mt-3 block text-xs" to={PATH_HUB_HOME}>
					Cancel
				</Link>
			)}
		</Container>
	);
};

export { AddStudentSpecialNeeds };
