import * as types from './actionTypes';
import { apiFetch, REQ_GET_SUMMARY, REQ_GET_ACTIVITIES } from '../services/backendApi';

export const requestSummary = () => (dispatch, getState) => {
  return apiFetch(REQ_GET_SUMMARY, getState())
    .then((json) => {
      dispatch({ type: types.SR_REQUEST_SUMMARY_SUCCESS, payload: json });
    })
    .catch(() => {
      dispatch({ type: types.SR_REQUEST_SUMMARY_FAILED });
    });
};

export const removeAllActivities = () => (dispatch) => {
  dispatch({ type: types.SR_REMOVE_ALL_ACTIVITIES, payload: null });
};

export const requestActivities = (removePreviousActivities = false) => (dispatch, getState) => {
  if (removePreviousActivities) {
    dispatch(removeAllActivities());
  }
  dispatch({ type: types.SR_REQUEST_ACTIVITIES_START, payload: null });
  return apiFetch(REQ_GET_ACTIVITIES, getState())
    .then((json) => {
      dispatch({ type: types.SR_REQUEST_ACTIVITIES_SUCCESS, payload: json });
    })
    .catch(() => {
      dispatch({ type: types.SR_REQUEST_ACTIVITIES_FAILED });
    });
};
