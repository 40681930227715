import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SectionTitle from './SectionTitle';
import OnGoingSchedule from './OnGoingSchedule';
import { NextSession } from './NextSession';
import Tutor from './Tutor';
import { ConnectedTempTutor as TempTutor } from './TempTutorChange/TempTutor';

import { isGroupEnrolment } from '../../selectors/hubSelector';
import { getTempTutorChangeInfo } from '../../selectors/enrolmentManagementSelector';

const ScheduleDetails = ({ isGroup, tutorInfo }) => {
  const {
    hasTempTutorChange,
    sessionsWithTempTutorChange,
    nextSessionTutorNumber,
    nextSessionDateWithCurrentTutor,
    nextSessionDateWithTempTutor,
  } = tutorInfo;
  return (
    <div className="mb-8 lg:mb-11">
      <SectionTitle
        title={`${isGroup ? 'Group' : 'Private'} tutoring sessions`}
      />
      <div className="flex flex-col rounded-lg border border-grey-2 bg-white lg:flex-row">
        <div className="px-4 py-5 lg:w-7/12 lg:py-12 lg:pr-14 lg:pl-20">
          <OnGoingSchedule />
          <NextSession />
        </div>
        <div className="py-5 px-4 lg:w-5/12 lg:pr-6 lg:pl-0">
          {hasTempTutorChange ? (
            <TempTutor
              sessionsWithTempTutorChange={sessionsWithTempTutorChange}
              nextSessionTutorNumber={nextSessionTutorNumber}
              nextSessionDateWithTempTutor={nextSessionDateWithTempTutor}
              nextSessionDateWithCurrentTutor={nextSessionDateWithCurrentTutor}
            />
          ) : (
            <Tutor />
          )}
        </div>
      </div>
    </div>
  );
};

ScheduleDetails.propTypes = {
  isGroup: PropTypes.bool.isRequired,
  tutorInfo: PropTypes.shape({
    hasTempTutorChange: PropTypes.bool,
    enrolmentTutor: PropTypes.string,
    nextSessionTutor: PropTypes.string,
    nextSessionTutorNumber: PropTypes.string,
    nextSessionDateWithTempTutor: PropTypes.string,
    sessionsWithTempTutorChange: PropTypes.number,
    nextSessionDateWithCurrentTutor: PropTypes.string,
  }).isRequired,
};

export default connect((state) => {
  return {
    isGroup: isGroupEnrolment(state),
    tutorInfo: getTempTutorChangeInfo(state),
  };
}, null)(ScheduleDetails);
