import React from 'react';
import PageError from '../../common/PageError';

const HubNoActiveEnrolmentsPage = () => {
  return (
    <PageError activePage="hub-no-active-enrolments" />
  );
};

export default HubNoActiveEnrolmentsPage;
