import { logError } from '../../logging';
import { apiFetch, REQ_GET_UPCOMING_SESSIONS } from '../../services/backendApi';
import * as actionTypes from '../actionTypes';

export const fetchUpcomingSessions = () => (dispatch, getState) => {
  dispatch({ type: actionTypes.SCHOOLS_US_FETCH_HOUSEHOLD_START });

  const tokenMatch = document.URL.match(/token=([^&#]*)/);
  const tokenParam = tokenMatch && tokenMatch[1];

  if (!tokenParam) {
    dispatch({ type: actionTypes.SCHOOLS_US_FETCH_HOUSEHOLD_FAILED });
    return null;
  }
  dispatch({ type: actionTypes.US_UPDATE_UPCOMING_SESSIONS_TOKEN, payload: { token: tokenParam } });

  return apiFetch(REQ_GET_UPCOMING_SESSIONS, getState())
    .then((json) => {
      dispatch({ type: actionTypes.SCHOOLS_US_FETCH_HOUSEHOLD_SUCCESS, payload: json });
    })
    .catch((e) => {
      dispatch({ type: actionTypes.SCHOOLS_US_FETCH_HOUSEHOLD_FAILED });
      logError(e, null, { message: 'Unable to fetch upcoming sesions for school' });
    });
};
