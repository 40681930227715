import { useQuery } from '@tanstack/react-query';
import {
  GET_SESSION_POLICY_QKEY,
  GET_BULK_SESSION_POLICY_QKEY,
  GET_SESSIONS_QKEY,
} from '../../util/constants/queryKeys';
import SessionService from '../../services/SessionService';
import type { GetSessionsFilter } from '../../types/hubTypes';
import type { Session } from '../../types/hubInterfaces';

export const useGetSessionPolicy = ({
  sessionId,
  enrolmentId,
  enabled = true,
}: {
  sessionId?: string;
  enrolmentId?: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [GET_SESSION_POLICY_QKEY, sessionId, enrolmentId],
    queryFn: () => SessionService.getSessionPolicy({ recordId: sessionId ?? enrolmentId }),
    retry: 2,
    staleTime: 300000,
    enabled: enabled && (!!sessionId || !!enrolmentId),
    refetchOnWindowFocus: process.env.NODE_ENV !== 'development',
  });
};

export const useGetBulkSessionPolicy = ({ sessionIds, enabled }: { sessionIds: Array<string>; enabled: boolean }) => {
  return useQuery({
    queryKey: [GET_BULK_SESSION_POLICY_QKEY, sessionIds],
    queryFn: () => SessionService.getBulkSessionPolicy({ recordIds: sessionIds }),
    retry: 2,
    staleTime: 300000,
    enabled,
    refetchOnWindowFocus: process.env.NODE_ENV !== 'development',
  });
};

export const useGetSessions = ({ filter, enabled }: { filter: GetSessionsFilter; enabled: boolean }) => {
  return useQuery<Array<Session>>({
    queryKey: [GET_SESSIONS_QKEY, filter],
    queryFn: () => SessionService.getSessions({ filter }),
    retry: 2,
    staleTime: 300000,
    enabled,
    refetchOnWindowFocus: process.env.NODE_ENV !== 'development',
  });
};

// eslint-disable-next-line valid-jsdoc
/**
 * @deprecated This function has been deprecated in favor of api/services/session.all
 *
 */
export const useGetNextSession = ({ enrolmentId, enabled }: { enrolmentId: string; enabled: boolean }) => {
  return useQuery<Session>({
    queryKey: [GET_SESSIONS_QKEY, 'upcoming', enrolmentId, 'next'],
    queryFn: async () => {
      const sessions = (await SessionService.getSessions({ filter: 'upcoming' })) as unknown as Session[]; // yuck -- need to use zod to validate from API
      const sessionsForEnrolment = sessions
        .filter((session) => session.uniqueEnrolmentId === enrolmentId && new Date(session.startTime) > new Date())
        .sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());
      return sessionsForEnrolment[0];
    },
    retry: 2,
    staleTime: 300000,
    enabled,
    refetchOnWindowFocus: process.env.NODE_ENV !== 'development',
  });
};
