import classNames from 'classnames';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { scrollToTop } from '../../util/helpers';

const BackToTop = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = debounce(() => setScrollY(window.scrollY), 500);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])

  
  return (
    <button
      type="button"
      className={classNames(
        'bg-primary flex justify-center items-center mb-5 lg:mb-4',
        'rounded shadow-2xl fixed bottom-0 right-16 z-10 transition-all ease-in-out opacity-100',
        'sm:w-11 sm:h-11 md:w-14 md:h-14',
        {'invisible': scrollY < 400, 'opacity-0': scrollY < 400}
      )}
      onClick={scrollToTop}>
      <i className="icon icon-chevron-down text-white !text-5xl rotate-180" />
    </button>
  )
}

export default BackToTop;