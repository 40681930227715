import { useEffect, useRef } from 'react';
import { Container } from '@cluey/cluey-components';
import { Loader } from '../../../../components/Loader/Loader';
import EnrolmentLayout from '../../../../common/hub/EnrolmentLayout';
import { api } from '../../../../api';
import first from 'lodash/first';
import { Assessment } from '../../../../api/types/assessment';
import { useHistory } from 'react-router';
import { GenerateAssessmentFormProps, LocationState } from '../GenerateAssessment';

interface Props {
	formFlowProps: GenerateAssessmentFormProps;
}


const useHistoricAssessments = (accountId: string) => {
	const { data: assessments, refetch } = api.assessment.all.useQuery({ customerNumber: accountId });
	const rendered = useRef(false);
	const initialAssessments = useRef<string[]>([]);

	useEffect(() => {
		if (!rendered.current && assessments) {
			initialAssessments.current = assessments.map((assessment) => assessment.studentLink);
			rendered.current = true;
		}
	}, [assessments]);

	return {
		refetch,
		hasNewAssessments: (assessments: Assessment[]) => {
			return !!assessments?.some((assessment) => !initialAssessments.current.includes(assessment.studentLink));
		},
	};
};

const GenerateAssessmentOngoing = ({ formFlowProps }: Props) => {
	const { data: details } = api.account.details.useQuery();
	const history = useHistory<LocationState>();
	const {
		location: { state },
	} = history;
	const { yearLevel, } = state ?? {};
	const { refetch, hasNewAssessments } = useHistoricAssessments(state?.data?.student?.customerNumber || '');

	useEffect(() => {
		const pollAssessmentsApi = (attempts: number, refetchedAssessments: Array<Assessment>) => {
			if (attempts >= 10) {
				formFlowProps.jump(4, { ...state, yearLevel });
				return;
			}
			if (hasNewAssessments(refetchedAssessments)) {
				formFlowProps.next({ ...state, studentLink: first(refetchedAssessments)?.studentLink, yearLevel });
				return;
			}
			setTimeout(async () => {
				const { data } = await refetch();
				pollAssessmentsApi(attempts + 1, data);
			}, 5000);
		};
		if (details) {
			setTimeout(() => {
				pollAssessmentsApi(1, []);
			}, 15000);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [details, refetch]);

	return (
		<EnrolmentLayout>
			<Container className="flex flex-col items-center gap-5 text-grey-6">
				<Loader />
				<p className="font-bold text-grey-6">Please wait while we generate the assessment link</p>
				<p>This can take up to a minute.</p>
			</Container>
		</EnrolmentLayout>
	);
};

export { GenerateAssessmentOngoing };
