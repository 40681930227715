import { IconProps as Props } from '../types/hubInterfaces';
import { iconDefaults } from '.';
import classNames from 'classnames';

function ChevronDownIcon(props: Props) {
	const { width, height, className } = { ...iconDefaults, ...props };
	return (
		<svg
			className={classNames('fill-current', className)}
			width={`${width}`}
			height={`${height}`}
			viewBox="0 0 16 16"
			fill="none"
		>
			<title>Chevron Down icon</title>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.00005 11.7334C7.73947 11.7334 7.48004 11.6454 7.2686 11.4682L0.411332 5.75354C-0.0732481 5.35008 -0.139535 4.62889 0.265044 4.14428C0.66848 3.65967 1.38849 3.59453 1.87422 3.99798L8.01262 9.11378L14.1407 4.182C14.6322 3.78654 15.3522 3.86426 15.7476 4.35572C16.1431 4.84719 16.0653 5.5661 15.5739 5.9627L8.71663 11.4808C8.50748 11.6488 8.25377 11.7334 8.00005 11.7334Z"
			/>
		</svg>
	);
}

export { ChevronDownIcon };
