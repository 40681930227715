import { Fragment, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import useWindowWidth from '../../../hooks/common/useWindowWidth';
import { BREAKPOINTS } from '../../../util/constants';
import throttle from 'lodash/throttle';

interface Props {
  id: string;
  children: JSX.Element | Array<JSX.Element> | string;
  className: string;
  eventText: string;
  subEventTitle: string;
  iconClass: string;
}

const SessionTimelineItem = (props: Props) => {
  const windowWidth = useWindowWidth();
  const [visible, setVisible] = useState(false);
  const itemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const createOnChange = (hide: boolean) => throttle(() => {
      if (itemRef.current) {
        const { top } = itemRef.current.getBoundingClientRect();
        if (top < window.innerHeight) {
          setVisible(true);
        } else if (hide) {
          setVisible(false);
        }
      }
    }, 100);

    createOnChange(true)();

    const onChange = createOnChange(false);

    window.addEventListener('scroll', onChange);
    window.addEventListener('resize', onChange);

    return () => {
      window.removeEventListener('scroll', onChange);
      window.removeEventListener('resize', onChange);
    };
  }, []);

  const { id, children, eventText, className, subEventTitle, iconClass } = props;
  const currentYear = new Date().getFullYear();
  const eventTextFiltered =
    eventText &&
    (moment(eventText).year() === currentYear
      ? moment(eventText).format('D MMMM')
      : moment(eventText).format('D MMMM YYYY'));
  return (
    <div id={id} ref={itemRef} className={classnames(className, 'entry', children === '' ? 'no-children' : '')}>
      <>
        <div className="title pr-10">
          {iconClass && (
            <div className="circle border bg-white">
              <i className={classnames('icon', iconClass)} />
            </div>
          )}
          <div className={`${visible ? 'bounce-in' : 'hidden'}`}>
            <div className="label ml-9 pr-0 md:ml-0 lg:ml-3 lg:pr-4">
              <div className="timeline-dateTime-md block w-full pl-10 text-left md:hidden">
                <span className="whitespace-nowrap pl-2 font-bold md:pl-0">
                  {eventTextFiltered}
                  {subEventTitle && <Fragment>, {subEventTitle}</Fragment>}
                </span>
              </div>
              <div className="hidden whitespace-normal text-right md:block">
                <p className="timeline-date m-0 font-bold">{eventTextFiltered}</p>
                {subEventTitle && (
                  <p className={classnames('timeline-time', windowWidth < BREAKPOINTS.lg && 'whitespace-normal')}>
                    {subEventTitle}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        {children && (
          <div className="body mb-9 pl-8 md:pl-0">
            <div className={`body-container ${visible ? 'bounce-in' : 'is-hidden'}`}>{children}</div>
          </div>
        )}
      </>
    </div>
  );
};

SessionTimelineItem.defaultProps = {
  id: '',
  children: '',
  className: '',
  eventText: null,
  visibilitySensorProps: { partialVisibility: true, offset: { bottom: 50 } },
  subEventTitle: null,
  iconClass: '',
  scrollToId: null,
};

export { SessionTimelineItem };
