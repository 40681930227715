import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@cluey/cluey-components';

const SectionLayout = ({ sectionClasses, children }) => {
  return (
    <div className={`c-section w-full p-4 lg:p-10 ${sectionClasses}`}>
      <Container>{children}</Container>
    </div>
  );
};

SectionLayout.propTypes = {
  sectionClasses: PropTypes.string,
  children: PropTypes.node.isRequired,
};

SectionLayout.defaultProps = {
  sectionClasses: '',
};

export default SectionLayout;
