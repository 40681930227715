import initialState from '../../initialState';
import * as actionTypes from '../../../actions/actionTypes';

export default function loginReducer(state = initialState.ui.apiState.login, action) {
  switch (action.type) {
    case actionTypes.SR_LOGIN_RECEIVE_INFO:
      return {
        ...state,
        firstFetch: false,
      };
    case actionTypes.SR_LOGIN_API_FAILURE:
      return {
        ...state,
        fetchError: true,
        firstFetch: false,
      };
    case actionTypes.SR_LOGIN_API_FAILURE_DEFINITIVE:
      return {
        ...state,
        retryEnabled: false,
        fetchError: true,
        firstFetch: false,
      };
    default:
      return state;
  }
}
