import { RefObject, forwardRef } from "react";
import { ChangeHandler } from "react-hook-form";
import classNames from "classnames";

interface Props {
  id: string;
  label: string;
  name: string;
  onChange?: ChangeHandler; 
  onBlur?: ChangeHandler;
  error?: string;
  defaultValue?: string;
}

/** @deprecated - I will be replaced in cluey-components v2 */
const FormField = forwardRef(({ id, label, name, onChange, onBlur, error, defaultValue }: Props, ref: RefObject<HTMLInputElement>) => {
  return (
    <label className="flex flex-col text-grey-5 w-full" htmlFor={id}>
      <span className="text-xs">{label}</span>
      <input
        ref={ref}
        className={classNames(
          'rounded border px-2 py-2 focus:outline-none focus:ring focus:ring-blue-4/40 mt-2',
          error ? 'border-red-5' : 'border-grey-3 focus:border-blue-4'
        )}
        type="text"
        name={name}
        id={id}
        onChange={onChange}
        onBlur={onBlur}
        defaultValue={defaultValue}
      />
      {error && <p className="text-red-5 text-xs mt-1">{error}</p>}
    </label>
  )
});

export { FormField }