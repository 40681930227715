import moment from 'moment-timezone';

export const isTimeslotInClueyBusinessHour = ({
  start,
  end,
  timezone,
  convertTimeslotsToUTC = false,
}: {
  start: string;
  end: string;
  timezone: string;
  convertTimeslotsToUTC: boolean;
}) => {
  const timeFormat = 'HH:mm:ss';
  const startTime = moment(
    convertTimeslotsToUTC ?
      moment.utc(start).tz(timezone).format(timeFormat)
      : 
      moment(start).tz(timezone).format(timeFormat), timeFormat);
  const endTime = moment(
    convertTimeslotsToUTC ?
      moment.utc(end).tz(timezone).format(timeFormat)
      :
      moment(end).tz(timezone).format(timeFormat), timeFormat);
  const clueyStart = moment('06:00:00', timeFormat);
  const clueyEnd = moment('23:00:00', timeFormat);

  // NOTE, please don't use isBetween. It will exclude 06:00:00 and 23:00:00
  return (
    startTime.isSameOrAfter(clueyStart) &&
    startTime.isSameOrBefore(clueyEnd) &&
    endTime.isSameOrAfter(clueyStart) &&
    endTime.isSameOrBefore(clueyEnd)
  );
};
