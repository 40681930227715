import { useLayoutEffect, useRef } from "react";

const DisableInteractiveElements = ({ children }: { children: React.ReactNode }) => {
	const wrapperRef = useRef(null);

	useLayoutEffect(() => {
		const tabbableElements = wrapperRef.current.querySelectorAll('a, button');

		tabbableElements.forEach((element) => {
			element.setAttribute('tabindex', '-1');
			element.classList.add('pointer-events-none');
		});

		return () => {
			tabbableElements.forEach((element) => {
				element.removeAttribute('tabindex');
				element.classList.remove('pointer-events-none');
			});
		};
	}, []); // Empty dependency array ensures this effect runs only once after the component mounts

	return <div ref={wrapperRef}>{children}</div>;
};

export default DisableInteractiveElements;
