import { FormFlow } from "../../../components/FormFlow/FormFlow";
import { CancelSessionConfirm } from "./Confirm";
import Success from './Success/Success';
const CancelSession = () => {
  const formElements = {
    '': CancelSessionConfirm,
    'success': Success,
  }
  
  return (
    <FormFlow elements={formElements} />
  )
}

export default CancelSession;