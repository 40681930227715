import { useQuery } from '@tanstack/react-query';
import { sendGetRequest } from '../../services/RequestService';
import { GET_NEXT_PAYMENT } from '../../util/constants/queryKeys';
import { getPortalAPIRequestParams } from '../../services/PortalAPIServices/helper';
import { useSelector } from 'react-redux';
import { BillingQuery } from '../../types/hubTypes';

export async function getNextPayment(id: string) {
    const { url, reqOptions } = getPortalAPIRequestParams(`pricingPlan/nextPaymentDetails?sfId=${id}`);
    return sendGetRequest({
        url,
        signRequest: true,
        reqOptions,
    }).then((res) => res.json());
}

const useNextPaymentQuery = () => {

    const sfid = useSelector((state) => {
        // @ts-ignore
        return state.hubAccountPage.accountDetails.customerSfid;
    });

    return useQuery<BillingQuery>({
        queryKey: [GET_NEXT_PAYMENT],
        queryFn: () => getNextPayment(sfid),
        enabled: !!sfid,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    });
};

export default useNextPaymentQuery;