import { Button, Container, Link } from '@cluey/cluey-components';
import { Link as RouterLink } from 'react-router-dom';
import { capitalize } from 'lodash';
import { Student } from '../../../../api/types/student';
import classNames from 'classnames';
import { useSearchParams } from '../../../../util/hooks/useSearchParams';
import { generatePath, useHistory, useLocation } from 'react-router';
import moment from 'moment';
import upperFirst from 'lodash/upperFirst';
import isNil from 'lodash/isNil';
import iconTickGreen from '../../../../assets/images/icon-filled-tick-green.svg';
import { api } from '../../../../api';
import EnrolmentCard from '../../../../components/hub/enrolments/EnrolmentCard/EnrolmentCard';
import { ComputerIcon, PencilIcon } from '../../../../icons';
import { TutorIcon } from '../../../../icons/TutorIcon';
import {
  PATH_HUB_COMPLETED_SESSIONS,
  PATH_HUB_CR_ADD_ENROLMENT,
  PATH_HUB_STUDENT_EDIT_PROFILE,
} from '../../../../util/pagePath';
import { AccountDetails } from '../../../../api/types/account';
import { useSelector } from 'react-redux';
import { getAccountDetails } from '../../../../selectors/hubSelector';
import { useMapToStudentAssessmentsEligibilities } from '../../home/widgets/AssessmentsOverviewWidget/hooks';
import AssessmentCard from './AssessmentCard';
import { SkeletonLoader } from '../../../../components/SkeletonLoader';
import iconInformation from '../../../../assets/images/icon-information.svg';
import { StudentAITutorCard } from './StudentAITutorCard';
import { useFeatureFlagEnabled } from 'posthog-js/react';

type LearningProfileProps = {
  student?: Student;
  loading: boolean;
};

export const SUBJECT = {
  ENGLISH: 'english',
  MATHS: 'mathematics',
  BIOLOGY: 'biology',
  CHEMISTRY: 'chemistry',
  PHYSICS: 'physics',
} as const;
type SUBJECT = typeof SUBJECT[keyof typeof SUBJECT];

const getAvailableSubjects = (student: Student): string[] => {
  if (!student) return [];

  if (student.studentCountry === 'Australia' && (student.yearLevel === 'Year 11' || student.yearLevel === 'Year 12')) {
    return [SUBJECT.ENGLISH, SUBJECT.MATHS, SUBJECT.BIOLOGY, SUBJECT.CHEMISTRY, SUBJECT.PHYSICS];
  } else {
    return [SUBJECT.ENGLISH, SUBJECT.MATHS];
  }
};

const skeletonize = (loading: boolean) => (loading ? 'rounded-full bg-grey-2 animate-pulse text-transparent' : '');

const StudentInfoItem = ({
  loading,
  label,
  value,
  placeholder,
}: {
  loading: boolean;
  label: string;
  value?: string;
  placeholder?: string;
}) => {
  const spooky = skeletonize(loading);
  return (
    <>
      <div className={classNames(spooky)}>{label}</div>
      <div className={classNames('font-bold', spooky)}>{loading ? placeholder : value}</div>
    </>
  );
};

const SubjectEnrolmentsCards = ({ enrolmentIds }: { enrolmentIds: Student['enrolments'][string] }) => {
  const { data: enrolments } = api.enrolment.all.useQuery('active');
  const { data: details } = api.account.details.useQuery();
  const uniquEnrIds = enrolmentIds.map((enrolment) => enrolment.uniqueEnrolmentId);
  const currentEnrolments = enrolments?.filter((enrolment) => uniquEnrIds.includes(enrolment.enrolmentId)) || [];
  const clueyCoachEnabled = useFeatureFlagEnabled('cluey-coach-enabled');
  return (
    <div className="flex flex-col gap-4 pb-8">
      {currentEnrolments.map((enrolment) => (
        <>
          <EnrolmentCard key={enrolment.enrolmentId} enrolment={enrolment} timezone={details.timezone} />
          {enrolment.aiTutor.allowed && clueyCoachEnabled && <StudentAITutorCard enrolment={enrolment} />}
        </>
      ))}
    </div>
  );
};

export const LearningProfile = ({ student, loading }: LearningProfileProps) => {
  const searchParams = useSearchParams();
  const { pathname } = useLocation();

  // Sus out the subjects
  const subjects = getAvailableSubjects(student);
  const tabParam = searchParams.get('subject');
  const currentSubject = tabParam && subjects.includes(tabParam) ? tabParam : subjects[0];
  const currentSubjectIds = student && student.enrolments[currentSubject] ? student.enrolments[currentSubject] : null;

  const { country }: AccountDetails = useSelector(getAccountDetails);

  const { isLoading: isLoadingAssessments } = api.assessment.all.useQuery({ customerNumber: student?.customerNumber });
  // we're reusing a function meant for multiple students but in this case we know we only always have one student so we use [0]
  const studentAssessmentsEligibilities = useMapToStudentAssessmentsEligibilities(student ? [student] : [], country)[0];
  const history = useHistory();
  const { firstName, lastName, yearLevel, location, studentId } = student ?? {};
  const onEnrolNow = () => {
    history.push(PATH_HUB_CR_ADD_ENROLMENT, {
      firstName,
      lastName,
      yearLevel: isNil(yearLevel) ? undefined : yearLevel,
      location: isNil(location) ? undefined : location,
      subject: currentSubject,
      studentId,
    });
  };
  const showAssessmentsSection =
    // shouldShow from useMapStudentAssessmentsEligibilities does not take into account the active enrolments of the student
    // so we need to check if the student is enrolled in the subject they are viewing
    studentAssessmentsEligibilities?.shouldShow && !student?.availableSubjects?.includes(capitalize(currentSubject));

  const { data: pastEnrolments } = api.enrolment.all.useQuery('past');
  const pastEnrolmentsOfStudent = pastEnrolments?.filter(
    (enrolment) => enrolment.studentSfid === student?.studentId && enrolment.subject?.toLowerCase() === currentSubject
  );
  const renderCompletedSessionsBanner =
    pastEnrolmentsOfStudent?.length > 0 ? (
      <div className="mb-8 mt-2 rounded-lg bg-blue-1 px-7 py-4">
        <div className="flex flex-row">
          <img src={iconInformation} alt="Information icon" height={20} width={20} className="mr-4" />
          <b>Looking for previous completed sessions?</b>
        </div>
        <div className="mt-4">
          <span>To see completed session feedback and details, go to </span>
          <Link className="!text-base underline underline-offset-4" to={PATH_HUB_COMPLETED_SESSIONS}>
            Completed sessions
          </Link>
        </div>
      </div>
    ) : null;

  return (
    <Container className="flex flex-col gap-6">
      <h2 className="font-display text-xl font-bold">Learning Profile</h2>
      <div className="flex flex-col overflow-hidden rounded-lg border border-grey-3 bg-grey-1 shadow-md">
        {/* Overview */}
        <div className="flex gap-4 px-16 py-8">
          <div className="flex-grow">
            <div className="flex h-32 w-32 flex-col items-center rounded-full bg-slate-2">
              <div className="flex h-1/2 items-center justify-center">
                <TutorIcon className="h-8 w-8 text-slate-4" />
              </div>
              <div className="text-sm text-slate-4">Joined Cluey on</div>
              <div className="font-display text-xl font-bold text-slate-4">
                {student ? moment(student.createdDate).format('MMM YY') : null}
              </div>
            </div>
          </div>
          <div className="flex-grow">
            <div className="flex h-32 w-32 flex-col items-center rounded-full bg-slate-2">
              <div className="flex h-1/2 items-center justify-center">
                <ComputerIcon className="h-8 w-8 text-slate-4" />
              </div>
              <div className="text-sm text-slate-4">Sessions taken</div>
              <div className="font-display text-xl font-bold text-slate-4">{student?.sessionsCompleted}</div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-x-4 gap-y-2">
            {/* Location */}
            <StudentInfoItem
              loading={loading}
              label="Location"
              value={
                student?.studentCountry === 'Australia' && student?.location
                  ? `${student.location} ${student.studentCountry}`
                  : student?.studentCountry
              }
              placeholder="STATE Country"
            />
            {/* Year */}
            {student?.currentYearLevel && (
              <StudentInfoItem
                loading={loading}
                label="School Year"
                value={student?.currentYearLevel}
                placeholder="Year 0"
              />
            )}
            {/* School Name */}
            <StudentInfoItem
              loading={loading}
              label="School Name"
              value={student?.schoolName || 'Not entered'}
              placeholder="School Name"
            />
          </div>
          <div>
            {student?.studentId ? (
              <Link
                to={generatePath(PATH_HUB_STUDENT_EDIT_PROFILE, { studentSfid: student.studentId })}
                className="flex items-center gap-2 whitespace-nowrap border-b-2 border-transparent"
              >
                <PencilIcon className="h-6 w-6 text-primary hover:text-blue-6" /> Edit Profile
              </Link>
            ) : (
              <div className={skeletonize(true)}>Edit Profile</div>
            )}
          </div>
        </div>
        {/* Per Enrolment Tabs */}
        <div className="-mx-px flex">
          {getAvailableSubjects(student).map((subject) => (
            <RouterLink
              className={classNames(
                'flex h-[73px] flex-1 flex-col items-center justify-center rounded-t-lg border border-grey-3 py-4 text-center',
                currentSubject === subject ? 'border-b-white bg-white' : 'bg-slate-1'
              )}
              to={`${pathname}?subject=${subject.toLowerCase()}`}
              key={subject}
            >
              <span className="font-bold text-primary">{upperFirst(subject)}</span>
              {student && Object.keys(student.enrolments).includes(subject) ? (
                <div className="flex items-center justify-center gap-1">
                  <img src={iconTickGreen} alt="Check" className="h-3 w-3" />
                  Enrolled
                </div>
              ) : null}
            </RouterLink>
          ))}
        </div>
        {/* Enrolment Tab */}
        <div className="bg-white p-6 md:p-16">
          <div className="mb-7 rounded bg-slate-4 px-4 py-1 font-display text-xl font-bold text-white">
            Enrolment details
          </div>
          {currentSubjectIds && currentSubjectIds.length ? (
            <SubjectEnrolmentsCards enrolmentIds={currentSubjectIds} />
          ) : (
            <>
              <p className="pb-5">No current enrolment</p>
              <Button onClick={onEnrolNow} appearance="reverse" className="mb-8 !font-bold">
                Enrol now
              </Button>
              {renderCompletedSessionsBanner}
            </>
          )}
          {showAssessmentsSection ? (
            <>
              <SkeletonLoader className="h-5 w-full" loading={isLoadingAssessments}>
                <div className="mb-7 mt-4 rounded bg-slate-4 px-4 py-1 font-display text-xl font-bold text-white">
                  Assessments
                </div>
              </SkeletonLoader>
              <SkeletonLoader className="mt-4 h-5 w-2/3" loading={isLoadingAssessments}>
                <AssessmentCard
                  student={student}
                  subject={currentSubject}
                  yearLevel={student?.yearLevel || ''}
                  studentAssessmentsEligibilities={studentAssessmentsEligibilities}
                />
              </SkeletonLoader>
              <SkeletonLoader className="mt-4 h-5 w-2/3" loading={isLoadingAssessments} />
            </>
          ) : null}
        </div>
      </div>
    </Container>
  );
};
