import { Button, Container } from '@cluey/cluey-components';
import { CheckIcon } from '../../../../../icons';
import { useEffect } from 'react';
import { useHistory, generatePath } from 'react-router';
import { Student } from '../../../../../api/types/student';
import { PATH_HUB_HOME, PATH_HUB_STUDENT_PROFILE } from '../../../../../util/pagePath';
import type { FormFlowProps } from '../../../../../types/hubInterfaces';

interface Props {
	formFlowProps: FormFlowProps;
}

type HistoryState = {
	newStudent: Student;
};

const AddStudentSuccess = ({ formFlowProps }: Props) => {
	const history = useHistory<HistoryState>();
	const newStudent = history.location.state?.newStudent;
	const {firstName, lastName} = newStudent || {}

	const { jump } = formFlowProps;
	useEffect(() => {
		if (!firstName) {
			jump(0);
		}
	}, [firstName, jump]);

	const onGoToProfileClick = () => {
		if (newStudent) {
			const profilePath = generatePath(PATH_HUB_STUDENT_PROFILE, { studentSfid: newStudent.studentId });
			history.push(profilePath);
		} else {
			history.push(PATH_HUB_HOME);
		}
	};

	return (
		<Container className="flex flex-col items-center gap-6 px-4 py-7 md:py-8 lg:mb-0">
			<div className="mt-10 flex flex-col items-center gap-2">
				<div className="flex h-10 w-10 items-center justify-center rounded-full bg-green-4">
					<CheckIcon className="text-white" width={30} height={30} />
				</div>
				<h1 className="text-xl font-bold">Welcome {firstName}</h1>
			</div>
			<p>
				We've created a student profile for {firstName} {lastName}.
			</p>
			<Button onClick={onGoToProfileClick} size="lg">
				Go to {firstName}'s profile
			</Button>
		</Container>
	);
};

export { AddStudentSuccess };
