import { ENROLMENT_SESSION_FREQUENCY, ENROLMENT_STATUS } from "../../types/hubEnums";
import moment from "moment";
import { SCHEDULE_DATE_FORMAT, SESSION_TIME_FORMAT } from "../constants";

export const getDaysBetweenSessions = ({ sessionFrequency }: {
  sessionFrequency: ENROLMENT_SESSION_FREQUENCY;
}) => {
  switch(sessionFrequency) {
    case ENROLMENT_SESSION_FREQUENCY.WEEKLY:
      return 7;
    case ENROLMENT_SESSION_FREQUENCY.FORTNIGHTLY:
      return 14;
    case ENROLMENT_SESSION_FREQUENCY.MULTIPLE_SESSIONS:
      return 4;
    case ENROLMENT_SESSION_FREQUENCY.OTHER:
    default:
      return 7;
  }
}

export const getEnrolmentPaused = ({ enrolmentStatus }) => {
  return !!enrolmentStatus && enrolmentStatus.toLowerCase() === ENROLMENT_STATUS.PAUSED.toLowerCase();
};

export const getWillEnrolmentBePaused = ({ enrolmentPausedFrom, enrolmentPausedTo }) => {
  return (
    !!enrolmentPausedFrom &&
    moment(enrolmentPausedFrom).isAfter(moment()) &&
    !!enrolmentPausedTo &&
    moment().isBefore(moment(enrolmentPausedTo))
  );
};

export const getEnrolmentSchedule = ({ enrolmentSchedule }: {
  enrolmentSchedule: Array<{
    dayOfWeek?: string;
    schedule?: string;
    timeOfDay?: string;
  }>;
}) => {
  if (enrolmentSchedule?.length < 1) {
    return [];
  }

  return enrolmentSchedule.map((s) => {
    const date = `${moment.utc(s.schedule, SCHEDULE_DATE_FORMAT).format('dddd')}s`;
    const time = moment.utc(s.schedule, SCHEDULE_DATE_FORMAT).format(SESSION_TIME_FORMAT);
    return `${date} at ${time}`;
  });
};