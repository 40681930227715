import { Modal } from "@cluey/cluey-components";
import { BreakdownTopic } from "../../../../../api/types/assessment";
import classNames from "classnames";
import { orderBy } from "lodash";

export interface ConceptCardProps {
  conceptName: string;
  isCorrectAnswer: boolean;
}

export const ConceptCard = ({ conceptName, isCorrectAnswer }: ConceptCardProps) => {
  const baseClassNames = "mb-3 p-6 rounded-lg"
  const correctAnswerClassNames = "border border-grey-3"
  const incorrectAnswerClassNames = "bg-red-1"

  return (
    <div className={classNames(baseClassNames, isCorrectAnswer ? correctAnswerClassNames : incorrectAnswerClassNames)}>
      <div className="text-grey-5 text-lg">{conceptName}</div>
      {!isCorrectAnswer && (
        <div className="mt-4 font-bold text-red-5 text-base">Answered incorrectly</div>
      )}
    </div>
  )
}

export type TopicModalData = [BreakdownTopic, string];

export interface TopicDetailsModalProps {
  assessmentName: string;
  data: TopicModalData | null;
  modalOpen: boolean;
  closeModal: () => void;
}

const TopicDetailsModal = ({ data, modalOpen, closeModal, assessmentName }: TopicDetailsModalProps) => {
  const [topic, unitName] = data || [];
  const sortedConcepts = topic ? orderBy(topic?.details, 'conceptName', 'asc') : [];
  
  return (
    <Modal
    open={modalOpen}
    closeOnBackground
    setOpen={(value) => {
      if (!value) {
        closeModal();
      }
    }}
    className="border-t-8 border-blood-orange-4 max-w-[680px]"
    closeButton
  >
    <div className="p-12">
      <div className="mb-10">
        <div className="font-bold text-2xl">{assessmentName}</div>
        <div className="text-xl">{unitName}</div>
      </div>
      <div>
        <div className="font-bold text-xl mb-4">{topic?.topicName}</div>
        {sortedConcepts?.map((concept, index) => {
          return (
            <ConceptCard key={`topic_${index}_${concept.conceptName}`} conceptName={concept.conceptName} isCorrectAnswer={concept.isCorrectAnswer} />
          )
        })}
      </div>
    </div>
  </Modal>
  );
}

export default TopicDetailsModal;