/**
 * We use this as objects to pass to logError(), instead of Custom Error classes
 * Besides the mandatory key 'message', the keys 'level' and 'unexpected' can be set
 */

export const FETCH_RESPONSE_DATA = {
  message: 'This is the response body of the last FETCH_* error',
};

export const FETCH_INVALID_DATA = {
  message: 'Fetch has retrieved invalid data',
};

export const WINDOW_ON_ERROR = {
  message: 'Error captured by window.onerror',
};

export const STATE_PIECE_MISSING = {
  message: 'State piece missing',
};

export const FETCH_NOT_OK = {
  message: 'Fetch response returned a status different than 2xx',
};

export const FETCH_FAILED = {
  message: 'Fetch failed',
};

export const INVALID_PAYLOAD = {
  message: 'Invalid payload',
};

export const INVALID_RESPONSE = {
  message: 'Invalid response',
};
