import moment from 'moment';
import { Article as ArticleType } from './articles';
import { Link } from 'react-router-dom';

export interface ArticleProps {
	data: ArticleType;
}

const Article = ({ data }: ArticleProps) => {
	const { publishedAt, path } = data;
	return (
		<div className="w-full border-t border-grey-3 py-10 text-left">
			<Link to={path}>
				<div className="mb-2 text-sm">{moment(publishedAt).format('DD MMM YYYY')}</div>
				<h2 className="mb-6 text-xl font-bold">{data.name}</h2>
				<p className="text-lg pr-[20%]">{data.description}</p>
				<div className="mt-2 underline text-blue-5">Read more</div>
			</Link>
		</div>
	);
};

export default Article;
