import { lazy } from 'react';

export const iconDefaults = {
  width: 17,
  height: 17,
  className: '',
};

export { ArrowBackIcon } from './ArrowBackIcon';
export { CheckIcon } from './CheckIcon';
export { ClockIcon } from './ClockIcon';
export { CreditCardIcon } from './CreditCardIcon';
export { ExclamationMarkIcon } from './ExclamationMarkIcon';
export { CompoundFlaskIcon } from './CompoundFlaskIcon';
export { EyeIcon } from './EyeIcon';
export { EyeSlashedIcon } from './EyeSlashedIcon';
export { DollarIcon } from './DollarIcon';
export { CheckCircleIcon } from './CheckCircleIcon';
export { OpenInNewTabIcon } from './OpenInNewTabIcon';
export { ChevronDownIcon } from './ChevronDownIcon';
export { CompoundBookIcon } from './CompoundBookIcon';
export { CompoundCalculatorIcon } from './CompoundCalculatorIcon';
export { ComputerIcon } from './ComputerIcon';
export { PlusIcon } from './PlusIcon';
export { RetryIcon } from './RetryIcon';
export { PencilIcon } from './PencilIcon';
export { SpeechIcon } from './SpeechIcon';
export { VirtualCoachBotIcon } from './VirtualCoachBotIcon';
export { VirtualCoachIcon } from './VirtualCoachIcon';
export const CodeCampIcon = lazy(() => import('./CodeCampIcon'));
