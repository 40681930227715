import { useMutation } from '@tanstack/react-query';
import { TutorAvailabilitiesService } from '../../services/TutorAvailabilitiesService';

export type CheckTimeslotAvailabilityPayload = {
  tutorId: string;
  rescheduleFrom: string;
  rescheduleTo: string;
  studentTimeZone: string;
  sessionDuration?: number;
}

interface CheckTimeslotAvailabilityParams {
  payload: CheckTimeslotAvailabilityPayload
  onSuccess?: (data: any, variables: CheckTimeslotAvailabilityPayload, context: unknown) => void;
  onError?: (error: unknown, variables: CheckTimeslotAvailabilityPayload, context: unknown) => void;
  onSettled?: (data: any, error: unknown, variables: CheckTimeslotAvailabilityPayload, context: unknown) => void;
}

export const useCheckTimeslotAvailability = (params: CheckTimeslotAvailabilityParams) => {
  const { onSuccess, onError, onSettled } = params;
  return useMutation({
    mutationFn: async (payload: CheckTimeslotAvailabilityPayload) => {
      const reply = await TutorAvailabilitiesService.getAvailableTimeSlotsOfTutor(payload);
      return reply[0];
    },
    onSuccess,
    onError,
    onSettled,
  })

}