import { get, sortBy } from 'lodash';
import moment from 'moment';
import { getGroupSessionMoment } from '../util/helpers';

export const getGroupSessions = (state) => {
  const groupSessions = get(state, 'hubChangeGroupPage.groupAvailabilities');

  const wDayNum = groupSessions.map((group) => {
    let dayNum = parseInt(moment(group.startDate).format('d'), 10);
    if (dayNum === 0) dayNum = 7;
    group.day = dayNum;
    return group;
  });

  return sortBy(wDayNum, 'day');
};

export const getChangeGroupSessions = (state) => {
  const groupSessions = get(state, 'hubChangeGroupPage.groupAvailabilities', []);

  // const sessionOptions = groupSessions
  //   .filter(session => session.sessions.length)
  //   .map((session) => {
  //     const startDateObj = moment(session.startDate);
  //     const dayNum = parseInt(startDateObj.format('d'), 10);
  //     return {
  //       ...session,
  //       dayNumber: dayNum === 0 ? 7 : dayNum,
  //       dayName: startDateObj.format('dddd'),
  //     };
  //   });

  const sessionOptions = groupSessions
    .map((session) => {
      // programmatically create x sessions
      const startOfMarch = moment('2022-03-01');

      // determines whether to calculate sessions based on a final date
      // or by taking the next two sessions provided by SF
      const calcOnDate = startOfMarch.diff(moment(), 'weeks') > 2;

      let newSessions = [];
      if (calcOnDate) {
        const rootSession = session.sessions[0];
        if (rootSession) {
          let iDate = getGroupSessionMoment(rootSession);
          const minDate = moment().add(2, 'days');
          while (iDate.isBefore(startOfMarch)) {
            if (iDate.isSameOrAfter(minDate)) {
              newSessions = [...newSessions, { ...rootSession, groupDate: iDate.format('YYYY-MM-DD') }];
              iDate = iDate.add(1, 'week');
            }
          }
        }
      } else {
        newSessions = session.sessions.slice(0, 2);
      }

      const startDate = moment(session.startDate);
      const dayNum = parseInt(startDate.format('d'), 10);

      return {
        ...session,
        sessions: newSessions,
        dayNumber: dayNum === 0 ? 7 : dayNum,
        dayName: startDate.format('dddd'),
      };
    })
    .filter((session) => {
      return session.sessions.length > 0;
    });

  return sortBy(sessionOptions, 'dayNumber');
};
