import { FormFlow } from '../../../../components/FormFlow/FormFlow';
// import { PauseConfirm } from "./PauseConfirm"; // broke the flow for Term123PauseSessions, but works for summer mode Term 4
import PauseConfirmOld from './PauseConfirm/PauseConfirmOld'; // does not have changes for the new summer pause changes
import { PauseSuccess } from './PauseSuccess';

const FormPauseSessions = () => {
  const formElements = {
    '': PauseConfirmOld,
    success: PauseSuccess,
  };
  return <FormFlow elements={formElements} />;
};

export default FormPauseSessions;
