import React from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import Cookies from 'js-cookie';
import TagManager from 'react-gtm-module';

import { logError } from '../../logging';
import * as actionTypes from '../actionTypes';
import {
	logIn,
	completeSignupByChangingPwd,
	getCurrentCredentials,
	getCurrentUserInfo,
	signOut,
	forgotPasswordSubmit,
} from '../../services/awsAuth';
import {
	AWS_COGNITO_RESPONSE,
	AUTHENTICATION_STATUS,
	EXPIRED_CODE_EXCEPTION_MSG,
	CUSTOMER_CARE_CONTACT_PHONE_2,
	TEMP_PASSWORD_EXPIRED_ERROR,
} from '../../util/constants';
import {
	PATH_HUB_LOG_IN,
	PATH_HUB_NEW_PASSWORD_SUCCESS,
	PATH_HUB_RESEND_SIGNUP_LINK_REQUEST,
} from '../../util/pagePath';
import { saveCredentials, handleGetCredentialsFailed, handleClockSkewDetected } from './HubHelperActions';
import {
	apiFetch,
	REQ_POST_HUB_RESEND_SIGNUP_LINK,
	REQ_POST_HUB_REQUEST_TO_RESET_PASSWORD,
} from '../../services/backendApi';
import env from '../../env';
import { fetchAllEnrolments } from './hubEnrolmentsPageActions';
import { fetchAccountDetails } from './hubAccountPageActions';
import { setLogUserAttributes } from '../../logging/datadog';
import { api } from '../../api';
import clearLocalStorage from '../../util/clearLocalStorage';
import getAppVersion from '../../util/getAppVersion';
import { posthog } from '../../util/posthog';

const MSG_WITH_RESEND_SIGNUP_LINK = [
	'It looks like you have not completed up the sign up process. You can ',
	<Link to={PATH_HUB_RESEND_SIGNUP_LINK_REQUEST}>request to have the sign up link re-sent</Link>,
	` or call Customer Care for assistance on ${CUSTOMER_CARE_CONTACT_PHONE_2}.`,
];

export const resetAuthUIState = () => (dispatch) => {
	dispatch({ type: actionTypes.HUB_AUTH_RESET_AUTH_UI_STATE });
};

export const saveUserEmailAndTempPWD =
	({ email, tempPassword }) =>
	(dispatch) => {
		dispatch({
			type: actionTypes.HUB_AUTH_SAVE_EMAIL_TEMPPWD,
			payload: { email, tempPassword },
		});
	};

export const getUserInfo = () => (dispatch) => {
	dispatch({ type: actionTypes.HUB_AUTH_GET_USER_INFO_START });
	return getCurrentUserInfo()
		.then((user) => {
			const name = get(user, 'attributes.name');
			const customerId = get(user, 'attributes.custom:customerId');
			posthog.identify(customerId, { $customer_id: customerId, $chub_version: getAppVersion() });
			dispatch({
				type: actionTypes.HUB_AUTH_GET_USER_INFO_SUCCESS,
				payload: {
					name,
					customerId,
				},
			});

			// Set what we know now, and update later when we get the full user details
			// see: src/actions/hub/hubAccountPageActions.fetchAccountDetails
			setLogUserAttributes({
				customerNumber: customerId,
				email: get(user, 'attributes.email', undefined),
			});

			if (env.REACT_APP_ENABLE_GOOGLE_TAG_MANAGER) {
				TagManager.dataLayer({
					dataLayer: {
						household_number: customerId,
					},
				});
			}
		})
		.catch(() => {
			dispatch({ type: actionTypes.HUB_AUTH_GET_USER_INFO_FAILED });
		});
};

export const initCompleteSignup =
	({ email, password, onRequireNewPassword = () => {}, onException = () => {} }) =>
	(dispatch) => {
		dispatch({ type: actionTypes.HUB_AUTH_FIRST_LOG_IN_START });
		const completeSignup = true;
		return logIn({ email, password, completeSignup })
			.then((response) => {
				dispatch({ type: actionTypes.HUB_AUTH_FIRST_LOG_IN_SUCCESS });

				if (response.status === AWS_COGNITO_RESPONSE.RESET_PASSWORD_REQUIRED) {
					onRequireNewPassword();
				}

				if (response.status === AWS_COGNITO_RESPONSE.NOT_AUTHORIZED_EXCEPTION) {
					onException();
				}
			})
			.catch((e) => {
				let errorMsg = get(e, 'message');
				if (errorMsg === TEMP_PASSWORD_EXPIRED_ERROR) {
					errorMsg = MSG_WITH_RESEND_SIGNUP_LINK;
				}

				dispatch({
					type: actionTypes.HUB_AUTH_FIRST_LOG_IN_FAILED,
					payload: {
						errorMsg,
					},
				});

				logError(errorMsg, null, {
					email,
					message: `User using email ${email} failed to init sign up process`,
				});
			});
	};

export const completeSignup =
	({ email, tempPassword, newPassword, callback }) =>
	(dispatch) => {
		dispatch({ type: actionTypes.HUB_AUTH_FIRST_LOG_IN_START });
		return completeSignupByChangingPwd({ email, tempPassword, newPassword })
			.then(() => {
				dispatch({ type: actionTypes.HUB_AUTH_SIGN_UP_SUCCESS });
				dispatch(getUserInfo());
				callback();
			})
			.catch((e) => {
				const errorMsg = get(e, 'message', '');
				console.info('error:', e);
				dispatch({
					type: actionTypes.HUB_AUTH_FIRST_LOG_IN_FAILED,
					payload: {
						errorMsg,
					},
				});
				logError(errorMsg, null, {
					email,
					message: 'Unable to complete user sign up',
				});
			});
	};

export const getCredentials = (params) => async (dispatch) => {
	try {
		const credentials = await getCurrentCredentials();

		// Let's prefetch a few things :)
		api.schoolTerm.all.prefetchQuery();
		api.enrolment.all.prefetchQuery('past');
		api.session.all.prefetchQuery('upcoming');
		api.student.all.prefetchQuery();

		dispatch(saveCredentials({ ...params, credentials }));
		dispatch(getUserInfo());
		dispatch(fetchAccountDetails());
		dispatch(fetchAllEnrolments());
	} catch (e) {
		const error = get(e, 'message');
		if (error === 'Clock skew detected') {
			clearLocalStorage();
			Cookies.remove('staffToken');
			dispatch(handleClockSkewDetected());
		} else {
			dispatch(handleGetCredentialsFailed(e));
		}
	}
};

export const loginSuccess = () => (dispatch) => {
	dispatch({ type: actionTypes.HUB_AUTH_LOG_IN_SUCCESS });
};

export const userLogin =
	({ email, password, onSuccess = () => {} }) =>
	(dispatch) => {
		dispatch({ type: actionTypes.HUB_AUTH_LOG_IN_START });

		return logIn({ email, password })
			.then(() => {
				dispatch(getCredentials()).then(() => {
					onSuccess();
					dispatch({ type: actionTypes.HUB_AUTH_LOG_IN_SUCCESS });
				});
			})
			.catch((e) => {
				let errorMsg = get(e, 'message', '');
				if (errorMsg === 'User is disabled.') {
					errorMsg = `Your login doesn't appear to be working, please contact Customer Care on ${CUSTOMER_CARE_CONTACT_PHONE_2}.`;
				} else if (errorMsg === 'User does not exist.') {
					errorMsg = "We don't have an account associated with this email, please try again.";
				}

				dispatch({
					type: actionTypes.HUB_AUTH_LOG_IN_FAILED,
					payload: {
						errorMsg,
					},
				});
				logError(errorMsg, null, {
					email,
					message: `User using email ${email} failed to log in`,
				});
			});
	};

function filterByPrefix(strings, prefix) {
	return strings.filter((str) => str.startsWith(prefix));
}

function clearAuthCookies() {
	const cookies = Cookies.get();
	const authCookies = filterByPrefix(Object.keys(cookies), 'CognitoIdentityServiceProvider');
	console.log('[debug] Clearing auth cookies');
	authCookies.forEach((cookie) => Cookies.remove(cookie));

	Cookies.remove('staffToken');
}

const signoutCleanAndRedirect = ({ isResetPassword = false } = {}) => {
	clearLocalStorage();
	clearAuthCookies();

	if (isResetPassword) {
		window.location = PATH_HUB_NEW_PASSWORD_SUCCESS;
	} else {
		window.location = PATH_HUB_LOG_IN;
	}
};

export const userSignout =
	({ isResetPassword = false } = {}) =>
	(dispatch) => {
		dispatch({
			type: actionTypes.HUB_AUTH_USER_UN_AUTHENTICATED,
			payload: {
				authenticationStatus: AUTHENTICATION_STATUS.INIT,
			},
		});
		return signOut()
			.then(() => {
				signoutCleanAndRedirect({ isResetPassword });
			})
			.catch((e) => {
				// Note: not sure what to expect here
				const errorMsg = get(e, 'message', '');
				logError(errorMsg, null, { message: `Sign out error: ${e} ` });
				signoutCleanAndRedirect({ isResetPassword });
			});
	};

export const requestToResetPassword =
	({ email, onSuccess, onError }) =>
	(dispatch, getState) => {
		dispatch({ type: actionTypes.HUB_AUTH_RESET_PASSWORD_REQUEST_START });
		return apiFetch(REQ_POST_HUB_REQUEST_TO_RESET_PASSWORD, getState(), {
			email,
		})
			.then(() => {
				dispatch({
					type: actionTypes.HUB_AUTH_RESET_PASSWORD_REQUEST_SUCCESS,
					payload: {
						email,
					},
				});
				onSuccess();
			})
			.catch((e) => {
				const errorMsg = get(
					e,
					'message',
					`Unable to reset your password, please try again later or contact Customer Care on ${CUSTOMER_CARE_CONTACT_PHONE_2}.`
				);
				dispatch({
					type: actionTypes.HUB_AUTH_RESET_PASSWORD_REQUEST_FAILED,
					payload: {
						errorMsg,
					},
				});
				logError(errorMsg, null, {
					email,
					message: `Failed to send reset password request: ${email}`,
				});
				onError?.(errorMsg);
			});
		// NOTE: use cognito built-in to reset password
		// return forgotPassword(email)
		//   .then(() => {
		//     dispatch({
		//       type: actionTypes.HUB_AUTH_RESET_PASSWORD_REQUEST_SUCCESS,
		//       payload: {
		//         email,
		//       },
		//     });
		//     onSuccess();
		//   })
		//   .catch((e) => {
		//     let errorMsg = get(e, 'message', '');
		//     if (errorMsg === 'User password cannot be reset in the current state.') {
		//       errorMsg = MSG_WITH_RESEND_SIGNUP_LINK;
		//     }
		//
		//     dispatch({
		//       type: actionTypes.HUB_AUTH_RESET_PASSWORD_REQUEST_FAILED,
		//       payload: {
		//         errorMsg,
		//       },
		//     });
		//     logError(errorMsg, null, {
		//       message: `Failed to send reset password request: ${email}`,
		//     });
		//   });
	};

export const setNewPassword =
	({ username, code, password, onSuccess }) =>
	(dispatch) => {
		dispatch({
			type: actionTypes.HUB_AUTH_SET_NEW_PASSWORD_START,
			payload: {
				email: username,
			},
		});
		return forgotPasswordSubmit({ username, code, password })
			.then(() => {
				dispatch({ type: actionTypes.HUB_AUTH_SET_NEW_PASSWORD_SUCCESS });
				onSuccess();
			})
			.catch((e) => {
				const errorCode = get(e, 'code', '');
				let errorMsg = get(e, 'message', '');

				const CODE_EXCEPTIONS = [
					AWS_COGNITO_RESPONSE.EXPIRED_CODE_EXCEPTION,
					AWS_COGNITO_RESPONSE.CODE_MISMATCH_EXCEPTION,
				];

				if (CODE_EXCEPTIONS.includes(errorCode)) {
					errorMsg = EXPIRED_CODE_EXCEPTION_MSG;
				}

				dispatch({
					type: actionTypes.HUB_AUTH_SET_NEW_PASSWORD_FAILED,
					payload: {
						errorMsg,
					},
				});
				logError(errorMsg, null, {
					email: username,
					message: `Failed to set new password: ${username}`,
				});
			});
	};

export const resendSignupLink =
	({ email, onSuccess }) =>
	(dispatch, getState) => {
		dispatch({ type: actionTypes.HUB_AUTH_RESEND_SIGNUP_LINK_REQUEST_START });

		const requestPayload = {
			email,
		};
		return apiFetch(REQ_POST_HUB_RESEND_SIGNUP_LINK, getState(), requestPayload, dispatch)
			.then(() => {
				dispatch({
					type: actionTypes.HUB_AUTH_RESEND_SIGNUP_LINK_REQUEST_SUCCESS,
					payload: {
						email,
					},
				});
				onSuccess();
			})
			.catch((e) => {
				const errorMsg = get(e, 'message', '');
				dispatch({
					type: actionTypes.HUB_AUTH_RESEND_SIGNUP_LINK_REQUEST_FAILED,
					payload: {
						errorMsg,
					},
				});
				logError(errorMsg, null, {
					email,
					message: `Failed to resend signup link: ${email}`,
				});
			});
	};

export const resetPassword =
	({ email, tempPassword, newPassword }) =>
	(dispatch) => {
		dispatch({
			type: actionTypes.HUB_AUTH_SET_NEW_PASSWORD_START,
			payload: {
				email,
			},
		});
		// utilize to reset password
		return completeSignupByChangingPwd({ email, tempPassword, newPassword })
			.then(() => {
				// NOTE, if calling userSignout({ isResetPassword: true }) here, it will never be called. Need to investigate
				signoutCleanAndRedirect({ isResetPassword: true });
			})
			.catch((e) => {
				const errorMsg = get(e, 'message', '');
				dispatch({
					type: actionTypes.HUB_AUTH_SET_NEW_PASSWORD_FAILED,
					payload: {
						errorMsg,
					},
				});
				logError(errorMsg, null, {
					email,
					message: 'Unable to reset password',
				});
			});
	};
