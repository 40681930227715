import welcomeFaceSvg from './assets/welcome-face.svg';

export const WelcomeHero = () => {
  return (
    <div className="bg-gradient-to-br from-blue-1 to-yellow-1 md:h-72 md:bg-gradient-to-r">
      <div className="container mx-auto flex h-full items-center justify-center px-5 pb-12 md:px-0 md:pb-0">
        <div className="flex flex-col pt-10 md:flex-row md:gap-8 md:pt-16">
          <img src={welcomeFaceSvg} className="h-24 w-24 md:h-[200px] md:w-[200px]" alt="" />
          <div className="flex flex-col">
            <h1 className="pb-4 font-display text-4xl font-extrabold md:text-[40px]">Welcome to Cluey!</h1>
            <h2 className="text-lg">
              Here&apos;s where you can access free self-paced learning and other Cluey learning support. So check back
              regularly as we release new features.
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};
