import range from 'lodash/range';

export const getCanBeAssessed = ({ yearLevel, country }: 
  { 
    yearLevel: string;
    country: 'NZ' | 'AU';
  }
  ) => {
  const yearlLevelsWithAssessment = getYearlLevelsWithAssessment({ country });
  return yearlLevelsWithAssessment.includes(yearLevel);
}

export const getYearlLevelsWithAssessment = ({ country }: { country: 'NZ' | 'AU'}) => {
  return (country === "NZ" ? range(4, 11) : range(3, 10)).map((yearLevel) => `Year ${yearLevel}`);
}