import moment from 'moment';
import { ddLogger, ddLoggerLevel } from '../logging';
import { FETCH_FAILED, INVALID_RESPONSE } from '../logging/errors';
import { ROLE_STAFF } from '../util/constants';
import { getCurrentCredentials } from './awsAuth';
import { store } from '../store/configureStore';
import { saveCredentials } from '../actions/hub/HubHelperActions';

const aws4 = require('aws4');

const sendRequest = async ({ url, method, payload, customErrorMsg, signRequest, reqOptions }) => {
  let response;

  try {
    const isPostOrPatch = method === 'POST' || method === 'PATCH';
    let requestOptions = {};

    if (signRequest) {
      const state = store.getState();
      let { secret: secretAccessKey, accessKey: accessKeyId, sessionToken, expiration } = state?.login?.credentials;
      const isTokenExpired = moment(expiration).isBefore(moment());

      if (isTokenExpired || !secretAccessKey || !accessKeyId || !sessionToken) {
        const credentials = await getCurrentCredentials();
        ({ secretAccessKey, accessKeyId, sessionToken } = credentials);
        store.dispatch(saveCredentials({ credentials }));
      }

      if (state?.login?.role === ROLE_STAFF) {
        const { staffToken } = state?.login?.credentials;

        reqOptions.headers = {
          ...reqOptions.headers,
          stafftoken: staffToken,
        };
      }
      requestOptions = {
        ...reqOptions,
        method,
        region: 'ap-southeast-2',
        service: 'execute-api',
        body: isPostOrPatch ? JSON.stringify(payload) : undefined,
      };

      aws4.sign(requestOptions, {
        secretAccessKey,
        accessKeyId,
        sessionToken,
      });
    } else {
      payload.sourceSystem = 'Hub';
      requestOptions = {
        method,
        headers: isPostOrPatch ? { 'Content-Type': 'application/json' } : {},
        body: isPostOrPatch ? JSON.stringify(payload) : undefined,
      };
    }

    response = await fetch(url, requestOptions);
    const customError = new Error(customErrorMsg);

    if (!response) {
      ddLogger({
        level: ddLoggerLevel.ERROR,
        label: INVALID_RESPONSE.message,
        data: { url, ...payload, response },
        error: new Error(INVALID_RESPONSE.message),
      });
      throw customError;
    }
    if (response.status === 500) {
      throw customError;
    }

    return response;
  } catch (error) {
    console.warn(error);
    const { message } = error;
    if (message !== customErrorMsg) {
      ddLogger({
        level: ddLoggerLevel.ERROR,
        label: FETCH_FAILED.message,
        data: { url, ...payload, error },
        error: new Error(FETCH_FAILED.message),
      });
    }
    throw error;
  }
};

export const sendGetRequest = async ({ url, payload, customErrorMsg, signRequest = false, reqOptions = {} }) => {
  return sendRequest({
    url,
    method: 'GET',
    payload,
    customErrorMsg,
    signRequest,
    reqOptions,
  });
};

export const sendPostRequest = async ({ url, payload, customErrorMsg, signRequest = false, reqOptions = {} }) => {
  return sendRequest({
    url,
    method: 'POST',
    payload,
    customErrorMsg,
    signRequest,
    reqOptions,
  });
};

export const sendPatchRequest = async ({ url, payload, customErrorMsg, signRequest = false, reqOptions = {} }) => {
  return sendRequest({
    url,
    method: 'PATCH',
    payload,
    customErrorMsg,
    signRequest,
    reqOptions,
  });
};
