import { Assessment } from "../../../../../api/types/assessment";
import { CheckCircleIcon, CompoundBookIcon, CompoundCalculatorIcon, OpenInNewTabIcon } from "../../../../../icons";
import { api } from "../../../../../api";
import { Anchor, LinkButton } from '@cluey/cluey-components';
import { PATH_HUB_ACCOUNT_DETAILS } from '../../../../../util/pagePath';
import classNames from 'classnames';
import { HeroDesktopOnly } from "./HeroDesktopOnly";

interface Props {
  assessment: Assessment
}

const AssessmentHero = ({ assessment }: Props) => {
  const { data: accountDetails } = api.account.details.useQuery();
  const { questionsTotal, questionsCompleted, questionsCorrect, status, subjectYearLevel, studentLink } = assessment;

  const getGradient = () => {
    if (status === 'Completed') {
      return 'from-green-1 to-green-2 ';
    } else {
      return 'from-blue-1 to-yellow-1';
    }
  }

  return (
    <div className={classNames("py-10 px-5 bg-gradient-to-r flex items-center justify-center", getGradient())} role="banner">
      <div className="container shadow-md bg-white rounded py-8 text-grey-6 px-7 md:h-auto">
        <div className="flex flex-col md:flex-row gap-5 justify-start items-start md:items-center h-full">
          {
            status === 'Completed'
            ? <CheckCircleIcon width={45} height={45} className="text-green-5" />
            : (
              <div className="flex items-center">
                <CompoundBookIcon width={45} height={45} className='text-blue-4' />
                <CompoundCalculatorIcon className="relative top-3 right-2.5 text-blood-orange-4" width={45} height={45} />
              </div>
            )
          }
          <div className="flex flex-col gap-3">
            <p className="font-bold text-xl">
              {
                status === 'Completed'
                ? `You scored ${(Math.round((questionsCorrect / questionsTotal) * 100))}% in the ${subjectYearLevel} assessment`
                : `Complete your ${subjectYearLevel} assessment to get scored`
              }
            </p>
            {
              status === 'Completed'
              ? (<div className="text-sm flex flex-col gap-1.5">
                  <p>You answered {questionsCorrect} out of {questionsTotal} questions correctly.</p>
                  <p>{accountDetails.phone 
                    ? `A Learning Advisor will give you a call on ${accountDetails.phone} soon to discuss possible further learning.`
                    : 'Add your contact number now so a Learning Advisor can give you a call to discuss possible further learning.'
                  }</p>
                </div>)
              : (<p className="text-sm">
                  {questionsTotal - questionsCompleted} out of {questionsTotal} questions to go, complete it today.
                </p>)
            }
            {
              status === 'Completed'
              ? (<>
                <div className="flex gap-4">
                  {!accountDetails.phone && (
                    <LinkButton
                      className='self-start w-full lg:w-auto'
                      to={PATH_HUB_ACCOUNT_DETAILS}>
                      Update contact details
                    </LinkButton>
                  )}
                </div>
              </>)
              : (<>
                  <HeroDesktopOnly />
                  <Anchor
                    className="hidden lg:flex gap-3 bg-blue-5 self-start text-white py-1.5 px-5 items-center rounded-3xl hover:text-white"
                    href={studentLink}
                    target="blank">
                    <span>Continue assessment</span>
                    <OpenInNewTabIcon />
                  </Anchor>
                </>)
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export { AssessmentHero };