import { isNumber } from "lodash";
import pluralize from "../../../../../util/pluralize";

export default function getScore(questionsCorrect?: number, questionsTotal?: number): string | null {
  if (!isNumber(questionsCorrect) || !isNumber(questionsTotal)) {
    return null;
  };

  return `${questionsCorrect} out of ${questionsTotal} ${pluralize('question', questionsTotal)} correct`;
}
