import { useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ButtonAsLink } from '@cluey/cluey-components';
import {ReactComponent as FilledDocument} from '../../../../assets/images/atoms-icon-filled-document.svg';

import { InvoiceType } from '../../../../types/hubPropTypes';
import { isGroupSessionCheck } from '../../../../util/helpers';
import { InvoiceService } from '../../../../services/InvoicesService';
import { BlockLoader } from '../../../../common/BlockLoader';
import { ErrorMsg } from '../../../../common/Error';
import { PATH_HUB_CR_PAYMENT_QUERY } from '../../../../util/pagePath';
import { getActiveEnrolments } from '../../../../selectors/hubSelector';
import { Enrolment } from '../../../../types/hubInterfaces';

export type InvoiceProps = {
  invoice: InvoiceType;
  onClick: () => void;
};

const Invoice = ({ invoice, onClick }: InvoiceProps) => {

  const [loading, setLoading] = useState(false);
  const [loadInvoiceFailed, setLoadInvoiceFailed] = useState(false);
  const [zuoraLink, setZuoraLink] = useState('');
  const activeEnrolments: Enrolment[] = useSelector(getActiveEnrolments);

  const history = useHistory();

  const onZuoraClick = async () => {
    if (!zuoraLink) {
      setLoading(true);
      try {
        const version = await InvoiceService.getInvoiceFileInfo(invoice.invoiceId);
        if (version) {
          setZuoraLink(version[0].url);
          window.open(version[0].url, '_blank');
        }
        setLoading(false);
      } catch (error) {
        setLoadInvoiceFailed(true);
        setLoading(false);
      }
    } else {
      window.open(zuoraLink, '_blank');
    }
  };

  let invoiceTarget;
  const formatAmount = (amount: number) => {
    return amount ? `$${amount.toFixed(2)}` : '';
    };
    const invoiceAmount = formatAmount(invoice.totalAmount);
    const remainingBalance = invoice.balance2 || 0
    const remainingBalanceFormatted = formatAmount(remainingBalance);
  if (invoice.type === 'zuora') {
    invoiceTarget = (
      <>
        <ButtonAsLink className="text-left font-bold md:text-[16px]" onClick={onZuoraClick} disabled={loading}>
          {loading ? <BlockLoader /> : `Tax invoice #${invoice.invoiceNumber}`}
        </ButtonAsLink>
        {loadInvoiceFailed && <ErrorMsg text="This invoice is currently not available. Please try again later." />}
      </>
    );
  } else {
    invoiceTarget = (
      <div className="flex flex-col lg:flex-row lg:gap-4">
        <div className="ls-normal lh-1-14 block border-0 p-0 text-left text-base font-bold">
          Tax Invoice #{invoice.invoiceNumber}
        </div>
        <ButtonAsLink
          onClick={() => {
            if (activeEnrolments.length === 1) {
              history.push(`${PATH_HUB_CR_PAYMENT_QUERY}?token=${activeEnrolments[0].enrolmentId}`);
            } else {
              onClick();
            }
          }}
        >
          Contact support to access this invoice
        </ButtonAsLink>
      </div>
    );
  }
const invoiceIconClass = remainingBalance ? 'fill-blood-orange-4' : 'fill-green-5';
  return (
    <li className="flex border-b border-grey-1 pb-4 pt-4">
      <div className="py-1">
        <FilledDocument className={invoiceIconClass} />
      </div>
      <div className="flex flex-grow flex-col py-1 px-3">
        {invoiceTarget}
        {invoice.studentName && (
          <span className="text-base">
            {invoice.studentName}&apos;s {isGroupSessionCheck(invoice.enrolmentType) ? 'Group' : '1-to-1 Private'}{' '}
            {invoice.enrolmentSubject}
         </span>
        )}
        <div className="text-[16px] text-blue-5">
            {remainingBalance ? "Unpaid" : "Paid"} - {remainingBalance ? remainingBalanceFormatted : invoiceAmount}
        </div>
        <div className="text-xs text-grey-6">
          {moment(invoice.invoiceDate).format('D MMM YYYY')}
        </div>
      </div>
    </li>
  );
};

export default Invoice