import React, { useEffect, useState } from 'react';
import { zxcvbn, zxcvbnOptions, ZxcvbnResult } from '@zxcvbn-ts/core';
import { isNull } from 'lodash';

const PASSWORD_STRENGTH_MAPPING = {
  0: 'Weak',
  1: 'Weak',
  2: 'Weak',
  3: 'Good',
  4: 'Strong',
};

export const INDICATOR_CLASS = {
  WEAK: 'w-1/3 h-full bg-red-5',
  GOOD: 'w-2/3 h-full bg-yellow-3',
  STRONG: 'w-full h-full bg-green-4',
};

const styles = {
  0: INDICATOR_CLASS.WEAK,
  1: INDICATOR_CLASS.WEAK,
  2: INDICATOR_CLASS.WEAK,
  3: INDICATOR_CLASS.GOOD,
  4: INDICATOR_CLASS.STRONG,
};

const loadOptions = async () => {
  const zxcvbnCommonPackage = await import('@zxcvbn-ts/language-common');
  const zxcvbnEnPackage = await import('@zxcvbn-ts/language-en');

  return {
    dictionary: {
      ...zxcvbnCommonPackage.dictionary,
      ...zxcvbnEnPackage.dictionary,
    },
    graphs: zxcvbnCommonPackage.adjacencyGraphs,
    translations: zxcvbnEnPackage.translations,
  };
};

const checkPasswordStrength = async (password: string): Promise<ZxcvbnResult> => {
  const options = await loadOptions();
  zxcvbnOptions.setOptions(options);
  const results = zxcvbn(password);
  return results;
};

const PasswordStrength = ({ password, testId }: { password: string; testId?: string }) => {
  const [result, setResult] = useState<ZxcvbnResult>(null);

  useEffect(() => {
    const checkPwStrength = async () => {
      const pwResult = await checkPasswordStrength(password);
      return pwResult;
    };
    checkPwStrength().then((res) => {
      setResult(res);
    });
  });

  return (
    <div className="mt-2 flex items-center">
      {!isNull(result) ? (
        <>
          {' '}
          <div className="h-[5px] w-1/2 overflow-hidden rounded bg-slate-3">
            <div className={styles[result.score]} data-testid={testId} />
          </div>
          <div className="ml-4 text-xs">{PASSWORD_STRENGTH_MAPPING[result.score]}</div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PasswordStrength;
