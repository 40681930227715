import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@cluey/cluey-components';

export const TopBannerLayout = ({ left, right }) => {
  return (
    <div className="w-full bg-yellow-2">
      <Container className="py-md-7 mx-auto flex flex-col py-5 md:flex-row md:items-center">
        <div className="flex items-start md:items-center">{left}</div>
        <div className="flex items-center md:ml-auto md:mr-3">{right}</div>
      </Container>
    </div>
  );
};

TopBannerLayout.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
};

TopBannerLayout.defaultProps = {
  left: null,
  right: null,
};
