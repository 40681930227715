import React from 'react';
import PropTypes from 'prop-types';

export function TopicsNotCovered({ topics }) {
  return (
    <div className="border-t border-grey-3 pt-12">
      <div className="flex font-display">
        <h1 className="text-xl font-bold md:text-2xl lg:text-3xl">
          Topics not covered
        </h1>
      </div>
      <div className="flex pt-4 pl-6 font-body">
        <div className="w-2/3 p-0 text-base lg:text-lg">
          <ul>
            {topics.map((t) => {
              return (
                <li className="mb-1 list-disc tracking-tight lg:mb-3" key={t}>
                  {t}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

TopicsNotCovered.propTypes = {
  // @todo update prop type based on topic data-structure
  // eslint-disable-next-line react/forbid-prop-types
  topics: PropTypes.array,
};
TopicsNotCovered.defaultProps = {
  topics: [],
};
