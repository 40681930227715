import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { ButtonAsLink } from '@cluey/cluey-components';
import { SessionType } from '../../../types/hubPropTypes';

import { formatSession } from '../../../util/helpers';

import SessionPaymentInfo from './SessionPaymentInfo';
import { saveSelectedSession } from '../../../actions/hub/hubContactPageActions';
import {
  PATH_HUB_CR_REQUEST_RECORDING,
  PATH_NAME_REPORT,
} from '../../../util/pagePath';
import {
  STUDENT_PROGRESS_TAB_VIEWS,
  STUDENT_PROGRESS_URL_PARAM_TAB,
  DATE_FORMAT_TYPE_13,
  TTBM_CUSTOMER_NUMBER
} from '../../../util/constants';
import { SessionDetails } from './HubSessionsListItem/SessionDetails';
import { useSelector } from 'react-redux';
import { getPkgTutorNameByEnrolmentId } from '../../../selectors/hubEnrolmentsSelector';

const CustomLink = ({ link, label }) => (
  <a
    href={link}
    rel="noopener noreferrer"
    target="_blank"
    className="lh-1 mb-2 block text-right text-primary hover:underline"
  >
    {label}
  </a>
);

CustomLink.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

const HubCompletedSessionsListItem = ({
  session,
  timezone,
  saveSelectedSessionToState,
}) => {
  const {
    student,
    subject,
    uniqueEnrolmentId,
    sessionReportId,
    tutor,
    recordingLink,
    tutorNumber,
    startTime,
    endTime
  } = session;
  const getFormattedTime = (time) => moment(time).tz(timezone).format('h:mma');
  const timeFrame = `${getFormattedTime(startTime)} - ${getFormattedTime(endTime)}`;
  const packageTutor = useSelector((state) => getPkgTutorNameByEnrolmentId(state, uniqueEnrolmentId));
  const displayTutor = tutorNumber === TTBM_CUSTOMER_NUMBER ? packageTutor : tutor;
  const liClasses = 'flex flex-col gap-1 rounded bg-custom-7 py-4 px-6 text-grey-6';

  const history = useHistory();
  const onClickHandler = () => {
    saveSelectedSessionToState({
      session,
    });

    history.push(PATH_HUB_CR_REQUEST_RECORDING);
  };

  const { date } = formatSession({
    session,
    timezone,
    sessionDateFormat: DATE_FORMAT_TYPE_13,
    useSemiColon: false,
  });

  const secondItem =
    recordingLink && recordingLink != null ? (
      <CustomLink link={recordingLink} label="Watch session recording" />
    ) : (
      <ButtonAsLink
        size="base"
        className="ls-normal lh-1-14 border-radius-0 block w-full p-0 text-left md:text-right font-body text-primary hover:underline"
        onClick={onClickHandler}
      >
        Request session recording
      </ButtonAsLink>
    );

  return (
    <li className={liClasses}>
      <div className="flex flex-col md:flex-row w-full justify-between">
        <div className='flex flex-col gap-1'>
          <SessionDetails
            student={student}
            subject={subject}
            tutor={displayTutor}
          />
          <span className="font-bold uppercase text-xs">{date}</span>
          <div className="text-base font-normal">{timeFrame}</div>
        </div>
        <div className="flex flex-col items-start md:items-end">
          <CustomLink
            link={`${PATH_NAME_REPORT}?token=${sessionReportId}&${STUDENT_PROGRESS_URL_PARAM_TAB}=${STUDENT_PROGRESS_TAB_VIEWS.SESSION_REPORT}`}
            label="View learning activities"
          />
          {secondItem}
        </div>
      </div>
      <SessionPaymentInfo session={session} />
    </li>
  );
};

HubCompletedSessionsListItem.propTypes = {
  session: SessionType.isRequired,
  timezone: PropTypes.string.isRequired,
  saveSelectedSessionToState: PropTypes.func.isRequired,
};

export default connect(null, (dispatch) => {
  return {
    saveSelectedSessionToState: bindActionCreators(
      saveSelectedSession,
      dispatch
    ),
  };
})(HubCompletedSessionsListItem);

export { HubCompletedSessionsListItem as StatelessHubCompletedSessionsListItem };
