import React from 'react';
import { CUSTOMER_CARE_CONTACT_PHONE } from '../../util/constants';

const ContactPhone = () => {
  return (
    <div className="text-center py-7 c-section--phone">
      <p className="mb-0 m-auto font-bold">
        <span className="ci ci--phone" x-ms-format-detection="none">Call us on: { CUSTOMER_CARE_CONTACT_PHONE }</span>
      </p>
    </div>
  );
};

export default ContactPhone;
