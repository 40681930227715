/*
 * Since customers are redirected to hub, this is deprecated. (Not 100% sure)
 * Will remove this component if QA find no issues/usage.
 * */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@cluey/cluey-components';
import moment from 'moment';
import classNames from 'classnames';

import { changeSession } from '../../actions/contactUsFlowActions';
import { RESCHEDULE_PAGE_REQUEST_TYPES } from '../../util/constants';
import { getSessionDetail } from '../../util/helpers';
import GoBack from './GoBack';
import ContactScreenWrap from './ContactScreenWrap';
import ContactPhone from './ContactPhone';
import peopleIcon from '../../assets/images/peopleIcon.svg';

export const SessionInfo = ({ title, titleClasses, selectedSessionDetail }) => {
	return (
		<div className="mb-6 max-w-[740px] rounded border border-slate-3 p-3 md:mx-auto md:flex md:items-center md:p-5 lg:px-10">
			<img
				className="mb-2 inline h-[23px] w-[23px] md:ml-10 md:mr-6 md:h-[37px] md:w-[37px]"
				src={peopleIcon}
				alt="people"
			/>
			<div className="md:flex md:flex-col md:items-start">
				<span className={titleClasses}>{title}</span>
				<span className="block text-base font-bold lg:text-xl">{selectedSessionDetail}</span>
			</div>
		</div>
	);
};

SessionInfo.propTypes = {
	title: PropTypes.string.isRequired,
	titleClasses: PropTypes.string,
	selectedSessionDetail: PropTypes.string.isRequired,
};

SessionInfo.defaultProps = {
	titleClasses: 'block uppercase mb-2 text-base font-bold',
};

const ContactChangeSession = ({
	studentName,
	timezone,
	subject,
	currentSession,
	selectedSession,
	rescheduleSession,
	isSubmitting,
}) => {
	const confirmReschedule = () => {
		rescheduleSession({ requestType: RESCHEDULE_PAGE_REQUEST_TYPES.RESCHEDULE });
	};

	const showReminder = moment()
		.tz(timezone)
		.isAfter(moment(currentSession.startTime).subtract(1, 'day').startOf('day'));

	const currentSessionDetail = getSessionDetail(currentSession, timezone);

	const selectedSessionDetail = getSessionDetail(selectedSession, timezone);

	const confirmBtnClasses = classNames(
		'text-center text-base md:text-lg lg:text-2xl py-3 px-6 md:py-4 md:px-10 font-bold tracking-normal',
		{ 'disabled cursor-not-allowed pointer-events-none': isSubmitting }
	);

	return (
		<Fragment>
			<GoBack title={`${studentName}, ${subject}`} />
			<ContactScreenWrap ciClass="ci--bg-moon-raker ci--calendar-change" h1Text="Please confirm your change">
				{showReminder && (
					<div className="contact-notice contact-notice--reminder mb-3 bg-custom-1 p-3 md:mx-auto md:flex md:items-center md:p-5 lg:p-6">
						<span className="contact-notice__type mb-2 inline-block rounded border border-primary px-7 py-1 text-base font-bold uppercase md:mb-0">
							Reminder
						</span>
						<p className="contact-notice__msg mx-auto mb-0 text-base md:ml-5 lg:ml-6">
							Please note that this session can only be rescheduled once, as per our
							<a
								className="pl-1 text-primary"
								href="https://clueylearning.com.au/service-fees/"
								target="_blank"
								rel="noopener noreferrer nofollow"
								id="cta-new-service-fees-policy"
							>
								service fees policy
							</a>
							.
						</p>
					</div>
				)}
				<div className="mb-4 text-center">
					<span className="mx-auto block text-sm font-medium md:text-base lg:text-xl">
						You are rescheduling this session
					</span>
					<span className="mx-auto block text-base font-bold md:text-lg">{currentSessionDetail}</span>
				</div>
				<SessionInfo title="New session time" selectedSessionDetail={selectedSessionDetail} />

				<Button onClick={confirmReschedule} className={confirmBtnClasses} disabled={isSubmitting}>
					Confirm and book session
				</Button>
			</ContactScreenWrap>
			<ContactPhone />
		</Fragment>
	);
};

ContactChangeSession.propTypes = {
	studentName: PropTypes.string.isRequired,
	timezone: PropTypes.string.isRequired,
	subject: PropTypes.string.isRequired,
	currentSession: PropTypes.shape({
		startTime: PropTypes.string.isRequired,
		endTime: PropTypes.string.isRequired,
		tutorName: PropTypes.string.isRequired,
	}).isRequired,
	selectedSession: PropTypes.shape({
		startTime: PropTypes.string.isRequired,
		endTime: PropTypes.string.isRequired,
		tutorName: PropTypes.string.isRequired,
		sessionId: PropTypes.string.isRequired,
	}).isRequired,
	rescheduleSession: PropTypes.func.isRequired,
	isSubmitting: PropTypes.bool.isRequired,
};

export default connect(
	(state) => {
		const { ui, rescheduleSession } = state;
		const { studentName, timezone, subject, currentSession, selectedSession } = rescheduleSession;
		return {
			studentName,
			timezone,
			subject,
			currentSession,
			selectedSession,
			isSubmitting: ui.apiState.contactUsFlow.isLoading,
		};
	},
	(dispatch) => {
		return {
			rescheduleSession: bindActionCreators(changeSession, dispatch),
		};
	}
)(ContactChangeSession);

export { ContactChangeSession as PresentationalContactChangeSession };
