/* eslint-disable */
import type { COUNTRY_KEYS, COUNTRY_MAP, PRICE_MAP } from "./hubTypes";
export const COUNTRY: Record<COUNTRY_KEYS, COUNTRY_MAP> = {
  AUSTRALIA: {
    COUNTRY_CODE: 'AU',
    NAME: 'Australia'
  },
  NEW_ZEALAND: {
    COUNTRY_CODE: 'NZ',
    NAME: 'New Zealand'
  }
}

export enum ELEMENT_TYPES {
  ANCHOR = 'anchor',
  BUTTON = 'button',
  LINK = 'link',
  DEFAULT = 'default',
}

export enum ENROLMENT_SESSION_FREQUENCY {
  WEEKLY = 'Weekly',
  FORTNIGHTLY = 'Fortnightly',
  TWICE_WEEKLY = 'Twice weekly',
  MULTIPLE_SESSIONS = 'Multiple sessions',
  OTHER = 'Other',
  ERROR = 'Error',
}

export enum ENROLMENT_STATUS {
  ACTIVE = 'Active',
  CANCEL_REQUESTED = 'Cancel Requested',
  CANCELLED = 'Cancelled',
  FINISHED = 'Finished',
  NEW_CUSTOMER = 'New Customer',
  PAUSED = 'Paused',
  PRE_ENROLMENT = 'Pre-enrolment',
}

export enum MANAGE_SERVICE_OPTIONS {
  SESSION_TIMES = 'Session times',
  LEARNING_FEEDBACK = 'Learning feedback',
  LEARNING_ADJUSTMENTS = 'Learning adjustments',
  STUDENTS_AND_SUBJECTS = 'Add a subject',
  BILLING_AND_PAYMENTS = 'Billing and payments',
  SOMETHING_ELSE = 'Something else?',
}

export interface INTENT_OPTION {
  label: string;
  onClickHandler?(): void;
  linkTo?: string;
  type: string;
}

export enum PAUSE_ALLOWED {
  ANYTIME = 'Anytime',
  SCHOOL_HOLIDAYS = 'School Holidays'
}

export enum PAUSE_FORM {
  STANDARD = 'Standard Pause Form',
  SUMMER = 'Summer Pause Form'
}

export enum PAYMENT_STATUS {
  PENDING_PAYMENT = 'Pending Payment',
  PAID = 'Paid',
  BILLED = 'Billed',
  PROCESSING = 'Processing',
  PARTIAL_REFUND = 'Partial Refund',
  FULL_REFUND = 'Full Refund',
  FAILED = 'Failed'
}

export enum POLICY {
  PRIVACY = 'Privacy',
  REFUND = 'Refund',
  STUDENT_AGREEMENT = 'Student agreement',
  DATA_COLLECTION = 'Data collection',
  SERVICE_FEES = 'Service fees'
}

export enum POLICY_TYPE {
  PRIVACY = 'privacy',
  REFUND = 'refund',
  STUDENT_AGREEMENT = 'student-agreement',
  DATA_COLLECTION = 'data-collection'
}

export const POLICY_ID_MAP = {
  [POLICY_TYPE.PRIVACY]: '601',
  [POLICY_TYPE.REFUND]: '626',
  [POLICY_TYPE.STUDENT_AGREEMENT]: '629',
  [POLICY_TYPE.DATA_COLLECTION]: '33729'
}

export const POLICY_NAME_MAP = {
  [POLICY_TYPE.PRIVACY]: 'Privacy Policy',
  [POLICY_TYPE.REFUND]: 'Refund Policy and Procedure',
  [POLICY_TYPE.STUDENT_AGREEMENT]: 'Student Agreement',
  [POLICY_TYPE.DATA_COLLECTION]: 'Data Collection'
}

/* @TODO investigate adding portal-api for serving this data */
export const PRICE: Record<COUNTRY_KEYS, PRICE_MAP> = {
  AUSTRALIA: {
    PRIVATE: 85,
    GROUP: 60
  },
  NEW_ZEALAND: {
    PRIVATE: 80,
    GROUP: 55
  }
}

export enum PRICING_PLAN_POLICY_HEADERS {
  CANCELLING_A_SESSION = 'Cancelling a session',
  PAUSING_SESSIONS = 'Pausing sessions',
  PAYMENT_TERMS = 'Payment terms',
  RESCHEDULING_A_SESSION = 'Rescheduling a session',
  CHANGE_OR_CANCEL_PLAN = 'Change/cancel plan',
}

export const CANCELLATION_FEE: Record<COUNTRY_KEYS, PRICE_MAP> = {
  AUSTRALIA: {
    PRIVATE: 40,
    GROUP: 10
  },
  NEW_ZEALAND: {
    PRIVATE: 35,
    GROUP: 10
  }
}

export enum RESCHEDULE_REQUEST_STATUS {
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  REQUESTED = 'Requested',
  REQUEST_NOT_ACTIONED = 'Request Not Actioned'
}

export enum SCHOOL_TERM {
  TERM_1 = 'Term 1',
  TERM_2 = 'Term 2',
  TERM_3 = 'Term 3',
  TERM_4 = 'Term 4'
};

export enum SCHOOL_TERM_TYPE {
  'Public Holiday',
  'Cluey Holiday',
  'School Term',
  'Summer Alert'
}

export enum SUPPORT_OPTIONS {
  SESSION_TIMES = 'Session times',
  LEARNING_FEEDBACK = 'Learning feedback',
  LEARNING_ADJUSTMENTS = 'Learning adjustments',
  STUDENTS_AND_SUBJECTS = 'Add a subject',
  BILLING_AND_PAYMENTS = 'Billing and payments',
  ACCOUNT_DETAILS = 'Account details',
  TECHNOLOGY = 'Technology',
  MANAGE_ENROLMENT = 'Manage enrolment',
  CANCEL_ENROLMENT = 'Cancel enrolment',
  REPORT_ISSUE = 'Report an issue',
  CUSTOMER_HUB_UPDATES = 'Customer Hub updates',
}