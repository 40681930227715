import { get } from "lodash";

export function extractEmailAndTempPassword(queryParams: URLSearchParams) {
  const token = queryParams.get('token');
  if (!token) {
    return {
      token: null,
      email: null,
      tempPassword: null
    }
  }
  const tokenDecoded = JSON.parse(window.atob(token));
  const email = get(tokenDecoded, 'email', '');
  const tempPassword = get(tokenDecoded, 'temporaryPassword', '');
  return {
    token,
    email,
    tempPassword
  }
}