import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withHeadTag from '../../../common/HeadComponent';
import AuthPageLayout from '../../../common/hub/AuthPageLayout';
import InPageLoader from '../../../common/InPageLoader';
import ErrorBlock from '../../../common/ErrorBlock';
import ResendSignupLink from '../../../components/hub/auth/ResendSignupLink';

import mailSent from '../../../assets/images/hubAuth/mail-sent.svg';

const HubResendSignupLinkSentPage = ({ inPageSpinner, pageError, errorMsg }) => {
	return (
		<AuthPageLayout
			title="Email has been sent!"
			subtitle="Please check your email and click on the link provided to sign up to Cluey Hub."
			imgIcon={mailSent}
			imgIconAlt="mail sent"
			imgIconWrapClass="icon-wrap--sail"
		>
			{inPageSpinner && <InPageLoader position="relative" />}
			{pageError && errorMsg && <ErrorBlock errorMsg={errorMsg} />}
			{!inPageSpinner && (
				<ResendSignupLink
					btnClasses="block p-0 mt-10 text-primary font-body tracking-normal"
					btnText="Didn’t receive the link? Re-send"
				/>
			)}
		</AuthPageLayout>
	);
};

HubResendSignupLinkSentPage.propTypes = {
	inPageSpinner: PropTypes.bool.isRequired,
	pageError: PropTypes.bool.isRequired,
	errorMsg: PropTypes.string,
};

HubResendSignupLinkSentPage.defaultProps = {
	errorMsg: '',
};

export default connect((state) => {
	const { ui } = state;
	return {
		inPageSpinner: ui.apiState.hubAuthPage.isLoading,
		pageError: ui.apiState.hubAuthPage.fetchError,
		errorMsg: ui.apiState.hubAuthPage.errorMsg,
	};
}, null)(withHeadTag(HubResendSignupLinkSentPage));

export { HubResendSignupLinkSentPage as StatelessHubResendSignupLinkSentPage };
