import { get, isEmpty } from 'lodash';
import { logError } from '../../logging';
import * as actionTypes from '../actionTypes';
import {
  apiFetch,
  REQ_GET_SELECTED_TUTOR_INFO,
} from '../../services/backendApi';
import { saveSelectedEnrolment } from './hubContactPageActions';
import { fetchEnrolment } from './hubEnrolmentManagementActions';
import { ScheduleService } from '../../services/ScheduleService';

export const updateSchedule = ({
  uniqueEnrolmentId, startDate, onSuccess, frequency, message, tutorNumber, enrolmentType,
}) => (dispatch) => {
  dispatch({ type: actionTypes.HUB_CSP_UPDATE_SCHEDULE_START });

  const scheduleService = new ScheduleService();

  const enrolmentId = uniqueEnrolmentId;
  const tutorId = tutorNumber;
  const courseType = enrolmentType === 'Group' ? 'group' : 'Private';

  return scheduleService.changeSchedule({
    enrolmentId, courseType, startDate, frequency, tutorId, message,
  })
    .then(() => {
      dispatch({ type: actionTypes.HUB_CSP_UPDATE_SCHEDULE_SUCCESS });
      onSuccess();
    })
    .catch((e) => {
      dispatch({ type: actionTypes.HUB_CSP_UPDATE_SCHEDULE_FAILED });
      logError(e, null, { message: 'Unable to update schedule' });
    });
};

export const redirectToChangeSchedule = ({ enrolmentId, callback }) => (dispatch) => {
  dispatch({
    type: actionTypes.HUB_CSP_SAVE_ENROLMENT_ID,
    payload: { enrolmentId },
  });
  dispatch(saveSelectedEnrolment({ enrolmentId }));
  return callback();
};

export const saveSelectedSchedule = ({
  selectedDay, scheduleDate, sessionDuration, tutorNumber, sessionFrequency, startDate
}) => (
  dispatch,
) => {
  return Promise.all([
    Promise.resolve(
      dispatch({
        type: actionTypes.HUB_CSP_SAVE_SELECTED_SCHEDULE,
        payload: {
          selectedDay,
          scheduleDate,
          sessionDuration,
          tutorNumber,
          sessionFrequency,
          startDate
        },
      }),
    ),
    Promise.resolve(
      dispatch({
        type: actionTypes.HUB_EM_SAVE_SELECTED_TUTOR_SCHEDULE,
        payload: {
          tutorNumber,
          selectedDay,
          scheduleDate,
          sessionDuration,
          sessionFrequency,
        },
      }),
    ),
  ]);
};

export const fetchTutorInfo = () => (dispatch, getState) => {
  dispatch({ type: actionTypes.HUB_CSP_FETCH_SELECTED_TUTOR_INFO_START });

  return apiFetch(REQ_GET_SELECTED_TUTOR_INFO, getState())
    .then((json) => {
      dispatch({ type: actionTypes.HUB_CSP_FETCH_SELECTED_TUTOR_INFO_SUCCESS, payload: json });
    })
    .catch((e) => {
      dispatch({ type: actionTypes.HUB_CSP_FETCH_SELECTED_TUTOR_INFO_FAILED });
      logError(e, null, { message: 'Get tutor info failed' });
    });
};

export const accessChangeSchedulePage = ({ enrolmentIdToken = null }) => (dispatch, getState) => {
  const { hubEnrolmentsPage } = getState();
  const activeEnrolments = get(hubEnrolmentsPage, 'activeEnrolments', []);
  //  Note, if there are more than one active enrolment, there will be a select model popup
  let enrolment = activeEnrolments.length === 1 ? activeEnrolments[0] : {};

  if (enrolmentIdToken) {
    enrolment = activeEnrolments.find(enr => enr.enrolmentId === enrolmentIdToken);
  }

  if (isEmpty(enrolment)) {
    return null;
  }

  const { enrolmentId } = enrolment;
  // Note: Since we are going to add change group schedule handling later, this part need to be done in
  // enrolment management
  dispatch(fetchEnrolment({ enrolmentId }));
  return dispatch(saveSelectedEnrolment({ enrolmentId }));
};

export const updateNewScheduleFailedHandler = () => (dispatch) => {
  dispatch({ type: actionTypes.HUB_CSP_UPDATE_SCHEDULE_FAILED_SHOW_NEW_SCHEDULE_CONFIRMATION });
};

export const resetChangeSchedulePage = () => (dispatch) => {
  dispatch({ type: actionTypes.HUB_EM_CS_RESET_PAGE_STATE });
};
