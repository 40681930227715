import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { PageLayout } from '../common/PageLayout/PageLayout';
import TabbedViewController from '../components/StudentProgress/TabbedViewController';
import StudentProgress from './StudentProgress';
import { ReportPage } from './ReportPage';

import { PAGE_NAME_SESSION_REPORT } from '../util/pageTitle';
import {
	APP_VIEW_CONTACT_FORM,
	APP_VIEW_CONTACT_OPTIONS,
	APP_VIEW_CONTACT_SUCCESS,
	APP_VIEW_DEFAULT,
	STUDENT_PROGRESS_TAB_VIEWS,
} from '../util/constants';

import DisciplineList from '../components/StudentProgress/DisciplineList';
import { setTabbedViewActiveTab } from '../actions/studentProgressActions';
import { scrollToTop } from '../util/helpers';
import DataNotAvailable from '../components/StudentProgress/DataNotAvailable';
import ContactOptions from '../components/ContactUs/ContactOptions';
import ContactForm from '../components/ContactUs/ContactForm';
import ContactSuccess from '../components/ContactUs/ContactSuccess';
import { isNZUserPageLA as isNZUserCheck } from '../selectors/loginSelector';

const StudentProgressAndReport = ({
	studentName,
	startingDate,
	activeTab,
	setActiveTab,
	disciplineFetchError,
	showContactForm,
	showContactOptions,
	showContactSuccess,
	showDefaultView,
	disciplineListLength,
	isNZUser,
}) => {

	const [lastActiveTab, setLastActiveTab] = useState(activeTab);
	const isSessionReportViewActive = activeTab === STUDENT_PROGRESS_TAB_VIEWS.SESSION_REPORT;
	if (activeTab !== lastActiveTab) {
		setLastActiveTab(activeTab);
		scrollToTop();
	}

	const renderActiveTabContent = () => {
		if (disciplineFetchError || disciplineListLength <= 0) {
			return null;
		}

		if (isSessionReportViewActive) {
			return <ReportPage />;
		}

		return <StudentProgress />;
	};

	return (
		<PageLayout
			header={{ show: true, title: `${PAGE_NAME_SESSION_REPORT}` }}
			footer={{ show: true }}
			bodyContainerCustomClass="relative z-0"
			isNZUser={isNZUser}
		>
			{showDefaultView && (
				<Fragment>
					{!disciplineFetchError && disciplineListLength > 0 && (
						<div className="sticky-top w-full border-b border-grey-2 bg-slate-1 font-display">
							<div className="container mx-auto px-4 pb-16 pt-5 lg:pb-4 lg:pt-9">
								<div className="relative flex flex-col items-end text-grey-6 lg:flex-row">
									<div className="w-full lg:w-7/12">
										<h1 className="text-xl font-bold leading-tight">
											{studentName}&apos;s learning progress, since {startingDate}
										</h1>
										<DisciplineList />
									</div>
									<TabbedViewController activeTab={activeTab} setActiveTab={setActiveTab} />
								</div>
							</div>
						</div>
					)}
					{disciplineFetchError && (
						<div className="my-8 py-8">
							<DataNotAvailable
								firstLine="We are having some trouble loading your report"
								secondLine="Please try again in a few minutes"
								showReloadButton
							/>
						</div>
					)}
					{!disciplineFetchError && disciplineListLength === 0 && (
						<div className="my-8 py-8">
							<DataNotAvailable
								firstLine="No Learning Activity available yet"
								secondLine="Your Learning Activity will become available once a session is completed"
							/>
						</div>
					)}
					{renderActiveTabContent()}
				</Fragment>
			)}
			{showContactOptions && <ContactOptions />}
			{showContactForm && <ContactForm />}
			{showContactSuccess && <ContactSuccess />}
		</PageLayout>
	);
};

StudentProgressAndReport.propTypes = {
	studentName: PropTypes.string,
	startingDate: PropTypes.string,
	setActiveTab: PropTypes.func.isRequired,
	activeTab: PropTypes.string.isRequired,
	disciplineFetchError: PropTypes.bool.isRequired,
	showDefaultView: PropTypes.bool.isRequired,
	showContactOptions: PropTypes.bool.isRequired,
	showContactForm: PropTypes.bool.isRequired,
	showContactSuccess: PropTypes.bool.isRequired,
	disciplineListLength: PropTypes.number.isRequired,
	isNZUser: PropTypes.bool.isRequired,
};

StudentProgressAndReport.defaultProps = {
	studentName: '',
	startingDate: '',
};

export default connect(
	(state) => {
		const { appView } = state.ui;
		return {
			studentName: state.report.studentName,
			startingDate: moment(state.report.startingDate).format('MMM YYYY'),
			activeTab: state.report.activeTab,
			disciplineFetchError: state.ui.apiState.studentProgress.discipline.fetchError,
			showDefaultView: appView === APP_VIEW_DEFAULT,
			showContactOptions: appView === APP_VIEW_CONTACT_OPTIONS,
			showContactForm: appView === APP_VIEW_CONTACT_FORM,
			showContactSuccess: appView === APP_VIEW_CONTACT_SUCCESS,
			disciplineListLength: state.studentProgress.disciplineList.length,
			isNZUser: isNZUserCheck(state),
		};
	},
	(dispatch) => {
		return {
			setActiveTab: bindActionCreators(setTabbedViewActiveTab, dispatch),
		};
	}
)(StudentProgressAndReport);
