export const SM_FETCH_SUMMER_MODE_DATA_START = 'SM_FETCH_SUMMER_MODE_DATA_START';
export const SM_FETCH_SUMMER_MODE_DATA_SUCCESS = 'SM_FETCH_SUMMER_MODE_DATA_SUCCESS';
export const SM_FETCH_SUMMER_MODE_DATA_FAILED = 'SM_FETCH_SUMMER_MODE_DATA_FAILED';

export const SM_SAVE_SUMMER_MODE_DATA_START = 'SM_SAVE_SUMMER_MODE_DATA_START';
export const SM_SAVE_SUMMER_MODE_DATA_SUCCESS = 'SM_SAVE_SUMMER_MODE_DATA_SUCCESS';
export const SM_SAVE_SUMMER_MODE_DATA_FAILED = 'SM_SAVE_SUMMER_MODE_DATA_FAILED';

export const SM_SAVE_CODE_CAMP_POSTCODE_START = 'SM_SAVE_CODE_CAMP_POSTCODE_START';
export const SM_SAVE_CODE_CAMP_POSTCODE_SUCCESS = 'SM_SAVE_CODE_CAMP_POSTCODE_SUCCESS';
export const SM_SAVE_CODE_CAMP_POSTCODE_FAILED = 'SM_SAVE_CODE_CAMP_POSTCODE_FAILED';
