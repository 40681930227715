import { LOCALSTORAGE_SHOW_NEW_HUB_COMMS_BANNER } from './constants';


export default function clearLocalStorage() {
	// Save the value of the key for showing the new hub comms banner
	const persistKey = localStorage.getItem(LOCALSTORAGE_SHOW_NEW_HUB_COMMS_BANNER);

	// Clear the entire local storage
	localStorage.clear();

	// Restore the saved value
	if (persistKey !== null) {
		localStorage.setItem(LOCALSTORAGE_SHOW_NEW_HUB_COMMS_BANNER, persistKey);
	}
}
