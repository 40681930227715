import PropTypes from 'prop-types';
import starIcon from '../../assets/images/atoms-icon-filled-star.svg';
import ribbonIcon from '../../assets/images/atoms-icon-filled-ribbon.svg';

const TutorDetailsCompact = (props) => {
	const { highlyValuedFor, skilledAt, englishTextStudied } = props;
	return (
		<>
			{highlyValuedFor && (
				<div className="mb-2">
					<span className="mb-3 text-xs font-bold uppercase leading-none tracking-[1px]">HIGHLY VALUED FOR</span>
					{englishTextStudied ? (
						<div className="flex flex-row items-start py-3">
							<img src={ribbonIcon} className="mr-3 h-5" alt="bullet point icon" />
							<p className="text-xs leading-relaxed">{englishTextStudied}</p>
						</div>
					) : null}
					<div className="flex flex-row items-start pt-3">
						<img src={starIcon} className="mr-3 h-4" alt="bullet point icon" />
						<p className="text-xs leading-relaxed">{highlyValuedFor}</p>
					</div>
				</div>
			)}
			{skilledAt?.length > 0 && (
				<div className="mb-2">
					<span className="mb-3 text-xs font-bold uppercase leading-none tracking-[1px]">SKILLED AT</span>
					<ul>
						{skilledAt.map((item, index) => {
							return (
								<li key={`skilled-at-${index}`} className="flex flex-row items-start">
									<p className="text-xs leading-relaxed">{item}</p>
								</li>
							);
						})}
					</ul>
				</div>
			)}
		</>
	);
};

TutorDetailsCompact.propTypes = {
	highlyValuedFor: PropTypes.string,
	skilledAt: PropTypes.arrayOf(PropTypes.string),
	englishTextStudied: PropTypes.string,
};

TutorDetailsCompact.defaultProps = {
	highlyValuedFor: '',
	skilledAt: [],
	englishTextStudied: '',
};

export default TutorDetailsCompact;
