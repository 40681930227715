import { Container } from '@cluey/cluey-components';
import Header from './Header';

const Layout = ({ children }: React.PropsWithChildren) => {
  return (
    <div className="flex min-h-screen flex-col">
      <Header title="Cluey Coach" />
      <Container className="mb-20 flex flex-grow lg:mb-0">
        <div className="relative w-full pt-5">{children}</div>
      </Container>
    </div>
  );
};

export default Layout;
