import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import { Button, Link } from '@cluey/cluey-components';
import moment from 'moment';
import classNames from 'classnames';

import { getTempTutorChangeInfo } from '../../../selectors/enrolmentManagementSelector';
import {
	fetchEnrolment as initChangeSessionWithSameTutorPage,
	saveSelectedDayAndTime as saveSelectedDayAndTimeAction,
} from '../../../actions/hub/hubEnrolmentManagementActions';
import InPageLoader from '../../../common/InPageLoader';
import PageError from '../../../common/PageError';
import EnrolmentLayout from '../../../common/hub/EnrolmentLayout';
import ErrorBlock from '../../../common/ErrorBlock';
import { EnrolmentBrief } from '../../../components/EnrolmentManagement/EnrolmentBrief';
import {
	ENROLMENT_MANAGEMENT_STEPS,
	SESSION_TIME_FORMAT,
	DATE_FORMAT_TYPE_3,
	SCHEDULE_DATE_FORMAT,
	TUTOR_AVAILABILITY_FORMAT,
} from '../../../util/constants';
import { TutorAvailability } from '../../../components/EnrolmentManagement/TutorAvailability';
import { ConnectedChangeSessionTimeConfirmation } from '../../../components/EnrolmentManagement/TempTutorChange/ChangeSessionTimeConfirmation';
import { EnrolmentManagementConfirmed } from '../../../components/EnrolmentManagement/EnrolmentManagementConfirmed';
import { CustomLink } from '../../../components/EnrolmentManagement/CustomLink';
import {
	PATH_HUB_ENROLMENT_DETAIL,
	PATH_HUB_SERVICE_FEES,
	PATH_HUB_TTC_CHANGE_SESSION_KEEP_TUTOR,
} from '../../../util/pagePath';
import { TRACKING_TTC } from '../../../util/trackingClasses';
import { isSessionLinkValid } from '../../../util/helpers';
import { getSkipSessionRoute } from '../../../util/helper';
import { useGetAvailableSlotsForSession } from '../../../hooks/queries/useGetAvailableSlotsForSession';
import { getActiveEnrolments, getUpcomingSessions } from '../../../selectors/hubSelector';

const ChangeSessionWithSameTutor = ({
	isPageLoading,
	isPageLoadingFailed,
	studentName,
	enrolmentSubject,
	nextSessionDate,
	enrolmentTutor,
	sessionLink,
	nextSessionInfo,
	timezone,
	fetchData,
	saveSelectedDayAndTime,
}) => {
	const [showErrorScreen, setShowErrorScreen] = useState(false);
	const [activeView, setActiveView] = useState(ENROLMENT_MANAGEMENT_STEPS.DEFAULT);
	const query = new URLSearchParams(useLocation().search);
	const enrolmentId = query.get('token') || 'ENR123456';
	const { nextSessionId, nextSessionTutor, hasTempTutorChange, nextSessionDateWithTempTutor, subsequentSessionTime } =
		nextSessionInfo;
	const upcomingSessions = useSelector(getUpcomingSessions);
	const session = upcomingSessions.find((upcomingSession) => upcomingSession.studentSessionId === nextSessionId);
	const activeEnrolments = useSelector(getActiveEnrolments);
	const enrolment = activeEnrolments.find((activeEnrolment) => activeEnrolment.enrolmentId === enrolmentId);
	const {
		isError: isLoadingAvailabilityFailed,
		isLoading: isLoadingAvailability,
		data,
	} = useGetAvailableSlotsForSession(session?.tutorSessionId, enrolment?.packageSfid);
	const packageTutorAvailabilities =
		data?.availableTimeslots?.reduce((result, availability) => {
			const tutor = availability.tutorsAvailable?.find((tutor) => tutor.tutorId === enrolment?.tutorSfId);
			if (tutor) {
				const { matchingRank = '', tutorId = '', tutorName = '' } = tutor ?? {};
				const [firstname] = tutorName?.split(' ');
				const start = moment.utc(availability.start);
				const end = moment.utc(availability.end);
				result.push({
					firstname,
					scheduleDate: moment.utc(availability.start).format(SCHEDULE_DATE_FORMAT).toUpperCase(),
					sessionDuration: end.diff(start, 'minutes'),
					tutorNumber: tutorId,
					tutorOrder: String(matchingRank),
					startDate: start,
					endDate: end,
				});
			}
			return result;
		}, []) || [];

	// Last session of the enrolment
	const daysBeforeNextSession =
		nextSessionDateWithTempTutor === subsequentSessionTime
			? 13
			: Math.ceil(moment(subsequentSessionTime).diff(moment().utc(), 'days', true));
	const numberOfDaysToLoad = daysBeforeNextSession >= 13 ? 13 : daysBeforeNextSession - 1;

	useEffect(() => {
		if (!enrolmentId) {
			setShowErrorScreen(true);
		} else {
			fetchData({ enrolmentId });
		}
		return undefined;
		// eslint-disable-next-line
	}, []);

	if (isPageLoading) {
		return <InPageLoader />;
	}

	if (isPageLoadingFailed || showErrorScreen) {
		return <PageError activePage="hub-enrolment-detail" />;
	}

	let mainHeading;
	let viewDefault = false;
	let viewConfirmation = false;
	let viewConfirmed = false;
	let viewError = false;
	let pageTracking = '';

	switch (activeView) {
		case ENROLMENT_MANAGEMENT_STEPS.CONFIRMATION:
			mainHeading = 'Change session';
			viewConfirmation = true;
			pageTracking = TRACKING_TTC.CONFIRMATION_CHANGE_SINGLE_SESSION;
			break;
		case ENROLMENT_MANAGEMENT_STEPS.CONFIRMED:
			mainHeading = 'Change confirmed';
			viewConfirmed = true;
			pageTracking = TRACKING_TTC.SUCCESS_CHANGE_SINGLE_SESSION;
			break;
		case ENROLMENT_MANAGEMENT_STEPS.ERROR:
			mainHeading = 'We are having trouble processing your request';
			viewError = true;
			pageTracking = TRACKING_TTC.SUCCESS_CHANGE_SINGLE_SESSION;
			break;
		default:
			mainHeading = `Change session to meet ${enrolmentTutor}'s availability`;
			viewDefault = true;
			pageTracking = TRACKING_TTC.PAGE_CHANGE_SINGLE_SESSION;
	}

	if (viewConfirmed) {
		return (
			<EnrolmentManagementConfirmed
				mainHeading={mainHeading}
				student={studentName}
				subject={enrolmentSubject}
				enrolmentId={enrolmentId}
				message="We have changed your upcoming session time - you will see it reflected on your enrolment details soon and you will also receive an email confirming the change."
				tracking={pageTracking}
			/>
		);
	}

	const showMainContent = !isLoadingAvailability && !isLoadingAvailabilityFailed;
	const enrolmentNextSession = `${moment(nextSessionDate).tz(timezone).format(DATE_FORMAT_TYPE_3)} at 
  ${moment(nextSessionDate).tz(timezone).format(SESSION_TIME_FORMAT)}`;
	const tooLateToChange = moment(nextSessionDate).diff(moment(), 'hours', true) <= 1;

	let display = null;

	if (viewConfirmation) {
		display = (
			<ConnectedChangeSessionTimeConfirmation
				onConfirmClick={() => {
					setActiveView(ENROLMENT_MANAGEMENT_STEPS.CONFIRMED);
				}}
				onChangeClick={() => {
					setActiveView(ENROLMENT_MANAGEMENT_STEPS.DEFAULT);
				}}
				onSubmissionError={() => {
					setActiveView(ENROLMENT_MANAGEMENT_STEPS.ERROR);
				}}
			/>
		);
	}

	if (viewError) {
		display = (
			<div className="container mx-auto">
				<p className="mb-4 text-xl leading-[1.2] md:w-1/2 lg:mb-7 lg:w-3/4">Please try again in a few minutes</p>
				<Button
					size="xs"
					appearance="reverse"
					className={`uppercase tracking-[1px] ${TRACKING_TTC.ERROR_CHANGE_SINGLE_SESSION}`}
					onClick={() => {
						setActiveView(ENROLMENT_MANAGEMENT_STEPS.CONFIRMATION);
					}}
				>
					Return to previous page
				</Button>
			</div>
		);
	}

	const linkToEnrolmentDetail = (linkText) => (
		<CustomLink
			to={`${PATH_HUB_ENROLMENT_DETAIL}?token=${enrolmentId}`}
			customClasses="leading-6 text-blue-5 hover:text-blue-6"
			text={linkText}
		/>
	);

	const externalLink = ({ linkHref, linkText, linkClass }) => (
		<a
			href={linkHref}
			rel="noopener noreferrer"
			target="_blank"
			className={classNames('mb-1 block text-lg leading-6 tracking-normal text-blue-5 hover:text-blue-6', linkClass)}
		>
			{linkText}
		</a>
	);

	if (!display && !hasTempTutorChange) {
		display = (
			<div className="container mx-auto">
				<p className="mb-4 text-lg leading-[1.33] tracking-[-0.25px] md:mb-6">
					Unfortunately we can no longer show you {enrolmentTutor}&apos;s availability
				</p>
				{linkToEnrolmentDetail('View enrolment details')}
			</div>
		);
	}

	if (!display && hasTempTutorChange && tooLateToChange) {
		display = (
			<div className="container mx-auto">
				<p className="`text-lg` mb-4 leading-[1.33] tracking-[-0.25px] md:mb-6">
					Unfortunately the session cannot be rescheduled as it is set to start within the next hour.
				</p>
				{isSessionLinkValid({ sessionLink }) &&
					externalLink({
						linkHref: sessionLink,
						linkText: `Join session with ${nextSessionTutor}`,
					})}
				{externalLink({
					linkHref: `${getSkipSessionRoute(String(nextSessionId))}`,
					linkText: 'Skip this session (payment will not be credited)',
					linkClass: TRACKING_TTC.SKIP_CURRENT_SESSION,
				})}
				<Link
					to={PATH_HUB_SERVICE_FEES}
					size="lg"
					className={classNames('mb-1 block text-lg leading-6 tracking-normal')}
				>
					Review our service fees and rescheduling policy
				</Link>
				{linkToEnrolmentDetail('View enrolment details')}
			</div>
		);
	}

	if (!display) {
		const onAvailabilityItemClick = (availability) => {
			const { startDate } = availability;
			const selectedDay = moment(startDate).tz(timezone).format('D MMMM YYYY');
			const selectedTime = moment.utc(startDate, TUTOR_AVAILABILITY_FORMAT).format(SCHEDULE_DATE_FORMAT);
			saveSelectedDayAndTime({
				selectedDay,
				selectedTime,
				sessionId: nextSessionId,
			}).then(() => {
				setActiveView(ENROLMENT_MANAGEMENT_STEPS.CONFIRMATION);
			});
		};
		display = (
			<TutorAvailability
				startDay={moment().add(1, 'day').format()}
				numberOfDaysToShow={numberOfDaysToLoad}
				availability={packageTutorAvailabilities}
				timezone={timezone}
				enrolmentId={enrolmentId}
				nextSessionId={nextSessionId}
				prevTutorName={enrolmentTutor}
				currentTutorName={nextSessionTutor}
				redirectToChangeSessionApp
				onAvailabilityItemClick={onAvailabilityItemClick}
				page={PATH_HUB_TTC_CHANGE_SESSION_KEEP_TUTOR}
				tracking={pageTracking}
			/>
		);
	}

	return (
		<EnrolmentLayout
			sectionHeading={`${studentName} ${enrolmentSubject}`}
			mainHeading={mainHeading}
			enrolmentId={enrolmentId}
			tracking={TRACKING_TTC.PAGE_CHANGE_SINGLE_SESSION}
		>
			{viewDefault && (
				<Fragment>
					{isLoadingAvailability && <InPageLoader position="relative" height="auto" />}
					{isLoadingAvailabilityFailed && (
						<div className="container mx-auto">
							<ErrorBlock errorMsg={`Unable to load ${enrolmentTutor}'s availability. Please try again later`} />
						</div>
					)}
					{showMainContent && (
						<div className="container mx-auto">
							<EnrolmentBrief leftColumnTitle="session" leftColumnContent={enrolmentNextSession} />
						</div>
					)}
				</Fragment>
			)}

			{showMainContent && display}
		</EnrolmentLayout>
	);
};

ChangeSessionWithSameTutor.propTypes = {
	isPageLoading: PropTypes.bool.isRequired,
	isPageLoadingFailed: PropTypes.bool.isRequired,
	studentName: PropTypes.string.isRequired,
	enrolmentSubject: PropTypes.string.isRequired,
	nextSessionDate: PropTypes.string,
	enrolmentTutor: PropTypes.string,
	nextSessionInfo: PropTypes.shape({
		nextSessionId: PropTypes.string,
		nextSessionTutor: PropTypes.string,
		hasTempTutorChange: PropTypes.bool,
		nextSessionDateWithTempTutor: PropTypes.string,
		enrolmentTutorNumber: PropTypes.string,
		subsequentSessionTime: PropTypes.string,
	}),
	sessionLink: PropTypes.string,
	timezone: PropTypes.string,
	fetchData: PropTypes.func.isRequired,
	saveSelectedDayAndTime: PropTypes.func.isRequired,
};

ChangeSessionWithSameTutor.defaultProps = {
	nextSessionInfo: {},
	timezone: null,
	nextSessionDate: null,
	enrolmentTutor: null,
	sessionLink: null,
	enrolmentSfid: '',
};

export { ChangeSessionWithSameTutor as PresentationalChangeSessionWithSameTutor };
export const ConnectedChangeSessionWithSameTutor = connect(
	(state) => {
		const { firstFetch: isPageLoading, fetchError: isPageLoadingFailed } = state.ui.apiState.hubEnrolmentDetailPage;
		const {
			studentName,
			enrolmentSubject,
			nextSessionDate,
			tutorName: enrolmentTutor,
			sessionLink,
			enrolmentSfid,
		} = state.hubEnrolmentDetailPage;

		return {
			isPageLoading,
			isPageLoadingFailed,
			studentName,
			enrolmentSubject,
			nextSessionDate,
			enrolmentTutor,
			sessionLink,
			enrolmentSfid,
			nextSessionInfo: getTempTutorChangeInfo(state),
			timezone: state.hubUser.timezone,
		};
	},
	(dispatch) => {
		return {
			fetchData: bindActionCreators(initChangeSessionWithSameTutorPage, dispatch),
			saveSelectedDayAndTime: bindActionCreators(saveSelectedDayAndTimeAction, dispatch),
		};
	}
)(ChangeSessionWithSameTutor);

export { ChangeSessionWithSameTutor as StatelessChangeSessionWithSameTutor };
