import { get } from 'lodash';
import { SPECIAL_DATE_TYPE } from '../util/constants';
import { isNZUserPageUS } from './loginSelector';
import { isAHoliday } from '../util/helper/holiday';

export const getStudents = (state) => {
  return get(state, 'upcomingSessions.students');
};

export const getSessions = (state) => {
  return get(state, 'upcomingSessions.sessions');
};

export const getSchoolTerm = (state) => {
  const holidays = get(state, 'upcomingSessions.holidays');
  return holidays.filter((h) => {
    const type = get(h, 'type');
    return type && type.toLowerCase() === SPECIAL_DATE_TYPE.SCHOOL_TERM;
  });
};

export const getHolidays = (state) => {
  const holidays = get(state, 'upcomingSessions.holidays', []);
  const isNZUser = isNZUserPageUS(state);

  return holidays.filter((h) => {
    const { type = '', state = '' } = h;
    return isNZUser ? isAHoliday(type) && state.toLowerCase() === 'nz' : isAHoliday(type);
  });
};
