import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { BillingInformationType } from '../../../../types/hubPropTypes';
import ErrorBlock from '../../../../common/ErrorBlock';
import { InfoBox } from '../../home/InfoBox';
import { Button } from '@cluey/cluey-components';

export const ReadView = ({ billingInformation = {}, setMode, isStaff, success }) => {
	const { creditCardType, endingIn = 'XXXX' } = billingInformation;

	return (
		<Fragment>
			{creditCardType && endingIn ? (
				<>
					{success && (
						<div className="pb-6">
							<InfoBox body={success} type="info" />
						</div>
					)}
					<div className="pb-6">
						<h3 className="mb-3 font-display font-bold">Payment details</h3>
						<div className="">
							<p className="mb-0 pb-4 leading-none">Credit card ending **** {endingIn.replace(/\*/g, '')}</p>
						</div>
					</div>
					{/* <div className="pb-6">
            <h3 className="font-display text-base font-weight-bold mb-3">Billing details</h3>
            <div className="">
              <p className="pb-4 leading-none mb-0 text-[15px]">{name}</p>
              {address && <p className="pb-4 leading-none mb-0 text-[15px]">{address.join(', ')}</p>}
              {phone && <p className="pb-4 leading-none mb-0 text-[15px]">{formatPhoneNumber(phone)}</p>}
            </div>
          </div> */}
					{!isStaff && (
						<Button
							appearance="reverse"
							size="xs"
							onClick={() => {
								setMode('edit');
							}}
						>
							EDIT PAYMENT DETAILS
						</Button>
					)}
				</>
			) : (
				<ErrorBlock errorMsg="There's an error with a card on file. Try updating your card below." />
			)}
		</Fragment>
	);
};

ReadView.propTypes = {
	billingInformation: BillingInformationType.isRequired,
	setMode: PropTypes.func.isRequired,
	isStaff: PropTypes.bool.isRequired,
	success: PropTypes.string,
};

ReadView.defaultProps = {
	success: '',
};
