import { get, isEmpty } from 'lodash';
import moment from 'moment';

import { logError } from '../../logging';
import * as actionTypes from '../actionTypes';
import { getResumeSession } from './hubContactPageActions';
import { fetchUpcomingSessionsWithSTPH } from './hubSessionsPageActions';
import { api } from '../../api';

// eslint-disable-next-line valid-jsdoc
/**
 * @deprecated This function has been deprecated in favor of api/services/enrolment.all
 *
 */
export const fetchEnrolments = filterType => async (dispatch) => {
  let errorMsg = '';
  let startActionType = '';
  let successActionType = '';
  let failedActionType = '';

  if (filterType === 'active') {
    errorMsg = 'Unable to fetch active enrolments for hub';

    startActionType = actionTypes.HUB_EP_FETCH_ACTIVE_ENROLMENTS_START;
    successActionType = actionTypes.HUB_EP_FETCH_ACTIVE_ENROLMENTS_SUCCESS;
    failedActionType = actionTypes.HUB_EP_FETCH_ACTIVE_ENROLMENTS_FAILED;
  } else if (filterType === 'past') {
    errorMsg = 'Unable to fetch past enrolments for hub';

    startActionType = actionTypes.HUB_EP_FETCH_PAST_ENROLMENTS_START;
    successActionType = actionTypes.HUB_EP_FETCH_PAST_ENROLMENTS_SUCCESS;
    failedActionType = actionTypes.HUB_EP_FETCH_PAST_ENROLMENTS_FAILED;
  }

  dispatch({ type: startActionType, payload: null });

  try {

    const enrolments = await api.enrolment.all.fetchQuery(filterType === 'active' ? 'active' : "past")

    if(!enrolments) throw new Error('Enrolments could not be retrieved.')

    dispatch({ type: successActionType, payload: enrolments });
    
  } catch (e) {
      dispatch({ type: failedActionType });

      logError(e, null, { message: errorMsg });
    
  }
};

export const fetchActiveEnrolments = () => (dispatch, getState) => {
  const state = getState();
  const activeEnrolments = get(
    state,
    ['hubEnrolmentsPage', 'activeEnrolments'],
    [],
  );

  const hasFetched = get(state, 'ui.apiState.hubActiveEnrolmentsPage.hasFetched', false);
  const retryEnabled = get(state, 'ui.apiState.hubActiveEnrolmentsPage.retryEnabled', false);
  const forceToReload = get(state, 'ui.apiState.hubActiveEnrolmentsPage.forceToReload', false);

  if ((activeEnrolments.length > 0 || hasFetched || !retryEnabled) && !forceToReload) {
    // Don't dispatch the fetching of active enrolments if already there
    return Promise.resolve(activeEnrolments);
  }

  return dispatch(fetchEnrolments('active'));
};

export const fetchPastEnrolments = () => (dispatch, getState) => {
  const state = getState();
  const pastEnrolments = get(
    state,
    ['hubEnrolmentsPage', 'pastEnrolments'],
    [],
  );

  const hasFetched = get(state, 'ui.apiState.hubPastEnrolmentsPage.hasFetched', false);
  const retryEnabled = get(state, 'ui.apiState.hubPastEnrolmentsPage.retryEnabled', false);

  if (pastEnrolments.length > 0 || hasFetched || !retryEnabled) {
    // Don't dispatch the fetching of past enrolments if already there OR you have already fetched
    return Promise.resolve(pastEnrolments);
  }
  return dispatch(fetchEnrolments('past'));
};

// TODO: Fetching active and past enrolments are not dependent, however this currently calls them sequentially.
// Investigate calling in parrallel and indicating when both are completed.
export const fetchAllEnrolments = ({ fetchPast = true } = {}) => (dispatch, getState) => {
  return dispatch(fetchActiveEnrolments()).then(() => {
    const activeEnrolments = get(getState(), 'hubEnrolmentsPage.activeEnrolments', []);
    const timestampOfFetchingResumeSessions = get(getState(), 'hubEnrolmentsPage.timestampOfFetchingResumeSessions', null);
    const fetchResumeSessions = !timestampOfFetchingResumeSessions || (timestampOfFetchingResumeSessions && moment().diff(moment(timestampOfFetchingResumeSessions), 'minutes', true) > 5);

    if (
      !isEmpty(activeEnrolments) && fetchResumeSessions
    ) {
      activeEnrolments.forEach((ae) => {
        const {
          enrolmentPausedFrom, enrolmentPausedTo, enrolmentId,
        } = ae;
        if (
          enrolmentPausedFrom && enrolmentPausedTo
          && enrolmentId
        ) {
          dispatch(getResumeSession({ enrolmentId, pauseTo: enrolmentPausedTo }));
        }
      });
      dispatch({
        type: actionTypes.HUB_SET_FETCH_RESUME_SESSION_FLAG,
        payload: moment().toISOString(),
      });
    }
    dispatch(fetchUpcomingSessionsWithSTPH());

    if (fetchPast) {
      return dispatch(fetchPastEnrolments());
    }
    return Promise.resolve();
  });
};

// NOTE: clear active enrolments to force page reload
export const setFlagToReloadApp = () => (dispatch) => {
  return dispatch({
    type: actionTypes.HUB_EP_CLEAR_ACTIVE_ENROLMENTS_TO_FORCE_RELOAD,
  });
};
