import { formatAmountWithCurrency } from '../../../util/helpers';
import useAccountBalanceDetailsQuery from '../../../hooks/queries/useAccountBalanceQuery';
import { BillingInformationType } from '../../../types/hubPropTypes';
import { Loader } from '../../../components/Loader/Loader';

const AccountBalance = ({
  billingInformation,
  loading,
  error,
}: {
  loading?: boolean;
  error?: boolean;
  billingInformation: BillingInformationType;
}) => {
  const {
    data: accountBalanceDetails,
    isLoading: isLoadingAccountBalance,
    error: getAccountBalanceError,
  } = useAccountBalanceDetailsQuery(billingInformation?.zuoraId);
  const isLoading = isLoadingAccountBalance || loading;
  const hasError = getAccountBalanceError || error;
  if (hasError) return <div>error...</div>;
  const { currency, accountBalance, credit, unpaidInvoice } = accountBalanceDetails || {};
  return (
    <div className="mb-8">
      <div className="mb-0 flex flex-row pb-2">
        <h3 className="font-display font-bold">Account Balance{!isLoading && ':'}</h3>
        {!isLoading && (
          <h3 className="ml-2 font-display " data-testid="account-balance-value">
            {formatAmountWithCurrency(accountBalance, currency)}
          </h3>
        )}
      </div>
      {isLoading ? (
          <Loader size={10} />
      ) : (
        <div className="flex flex-row">
          <p>Credit: </p>
          <p className="ml-2" data-testid="credit-value">
            {formatAmountWithCurrency(credit, currency)}
          </p>
          <p className="mx-2">|</p>
          <p>Unpaid Invoice:</p>
          <p className="ml-2" data-testid="unpaid-invoice-value">
            {formatAmountWithCurrency(unpaidInvoice, currency)}
          </p>
        </div>
      )}
    </div>
  );
};

export default AccountBalance;
