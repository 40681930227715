import { useMemo, useState } from 'react';
import { useSelectEnrolmentModal } from '../../../../components/Modals/SelectEnrolmentModal/SelectEnrolmentModal';
import { PATH_HUB_CR_PAYMENT_QUERY } from '../../../../util/pagePath';
import useInvoiceHistoryQuery from '../../../../hooks/queries/useInvoiceHistoryQuery';
import Invoice from '../Invoice/Invoice';
import RecordsPagination from '../../../../components/hub/RecordsPagination';
import { Loader } from '../../../../components/Loader/Loader';

const PER_PAGE = 10;

const InvoicesList = () => {
  const { open, openModal, closeModal, SelectEnrolmentModal } = useSelectEnrolmentModal();
  const { mappedData: data, isLoading, error } = useInvoiceHistoryQuery();
  const [startIndex, setStartIndex] = useState(0);
  const invoiceHistory = data?.invoiceHistory;
    const pageInvoices = useMemo(() => {
      if (Array.isArray(invoiceHistory)) {
        return [...invoiceHistory].slice(startIndex, startIndex + PER_PAGE);
      }
      return [];
    }, [invoiceHistory, startIndex]);

  return (
    <div>
      <SelectEnrolmentModal
        closeModal={closeModal}
        enrolmentLinkTo={(enrolment) => `${PATH_HUB_CR_PAYMENT_QUERY}?token=${enrolment.enrolmentId}`}
        open={open}
      />
      <h3 className="mb-0 pb-3 pt-6 font-display text-2xl font-bold">Invoice History</h3>
      {error && <div>{error}</div>}

      <ul className="m-0 p-0">
        {isLoading ? (
          <Loader size={10} />
        ) : !invoiceHistory?.length ? (
          <li>No invoices found</li>
        ) : (
            pageInvoices.map((invoice) => (
            <Invoice key={invoice.invoiceNumber} invoice={invoice} onClick={openModal} />
          ))
        )}
      </ul>
      {!!invoiceHistory?.length && (
        <RecordsPagination
          recordsTotal={invoiceHistory?.length}
          noOfRecordsPerPage={PER_PAGE}
          handlePaginationClick={setStartIndex}
        />
      )}
    </div>
  );
};

export default InvoicesList;
