import { useLayoutEffect, useState } from 'react';

export const useMaxHeight = (ref: React.MutableRefObject<HTMLElement | null>, status: boolean) => {
  const [maxWidth, setMaxWidth] = useState('0px');

  useLayoutEffect(() => {
    const calcHeight = () => {
      if (status && ref.current) {
        setMaxWidth(`${ref.current.scrollHeight}px`);
      } else {
        setMaxWidth('0px');
      }
    };

    calcHeight();
    window.addEventListener('resize', calcHeight);
    return () => window.removeEventListener('resize', calcHeight);
  }, [status, ref]);

  return maxWidth;
};
