import first from 'lodash/first';
import { Loader } from '../../../../components/Loader/Loader';
import useGetAssessments from '../useGetAssessments';
import { AssessmentHero } from './AssessmentHero';
import { WelcomeHero } from './WelcomeHero';

type HomeHeroProps = {
	isLead: boolean;
};

export const HomeHero = ({ isLead }: HomeHeroProps) => {
	const { data: assessments, isLoading: loadingAssessments } = useGetAssessments();

	if (loadingAssessments) {
		return (
			<div className="mx-auto my-14">
				<Loader />
			</div>
		);
	}
	const assessment = first(assessments);

	switch (true) {
		case isLead:
			if (assessments?.length > 0) {
				return <AssessmentHero assessment={assessment} />;
			} else {
				return <WelcomeHero />;
			}
		
		default:
			return null;
	}
};
