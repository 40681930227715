import moment from 'moment';
import { Container, Link } from '@cluey/cluey-components';
import loudspeakerIcon from '../../../../../assets/images/loudspeaker-icon 2.svg';
import { Article, getArticleById } from '../articles';
import ArticleLayout from '../ArticleLayout';
import { SnapshotWidgetPreview } from '../../../home/widgets/SnapshotWidget/SnapshotWidgetPreview';
import StudentProfilePreview from '../../../student/StudentProfile/StudentProfilePreview';
import LearningProfilePreview from '../../../student/StudentProfile/LearningProfilePreview';
import DisableInteractiveElements from '../DisableInteractiveElements';
import { PATH_HUB_SUPPORT, PATH_HUB_UPDATES } from '../../../../../util/pagePath';

export interface NewCustomerHubProps {
	data: Article;
}

const NewCustomerHubBase = ({ data }: NewCustomerHubProps) => {
	return (
		<ArticleLayout
			title={data.name}
			renderBreadcrumbs={
				<>
					<div className="mb-2">
						<Link className="text-xs font-bold uppercase" to={PATH_HUB_SUPPORT}>
							Support
						</Link>
						<span className="mx-2 text-xs font-bold">{'>'}</span>
						<Link className="text-xs font-bold uppercase" to={PATH_HUB_UPDATES}>
							Customer Hub updates
						</Link>
					</div>
				</>
			}
		>
			<Container className="px-4 pb-7 md:pb-8 lg:mb-0">
				<div className="flex flex-row items-center pb-10">
					<img className="mr-10" alt="Announcement icon" src={loudspeakerIcon} />
					<div className="sm:w-1/2">
						<div className="mb-2 text-sm">{moment(data.publishedAt).format('DD MMM YYYY')}</div>
						<b>{data.description}</b>
					</div>
				</div>
				<section className="my-10 rounded-lg border border-grey-3 p-10">
					<b className="">Page content</b>
					<ul className="ml-8 mt-5 list-disc">
						<li>
							<a href="#new-home-page" className="underline underline-offset-4 hover:text-blue-5">
								New home page
							</a>
						</li>
						<li>
							<a href="#student-profile" className="underline underline-offset-4 hover:text-blue-5">
								Student profile
							</a>
						</li>
					</ul>
					<span id="new-home-page" />
				</section>
				<section>
					<h2 className="mb-6 font-display text-[28px] font-bold">New home page</h2>
					<div className="md:w-2/3">
						<div>
							The new home page has a Snapshot view which will show upcoming sessions for everyone enrolled in your
							household.
						</div>
						<br />
						<div>
							Each student will have their own student profile, which can be accessed from the home page. Enrolment and
							session details for each student can be managed all in one place in their student profile, making it
							easier to manage sessions for your household.
						</div>
					</div>
					<br />
					<div className="mb-14">You can also add a student straight from the home page.</div>
					<DisableInteractiveElements>
						<SnapshotWidgetPreview className="rounded-xl p-4" isPreview />
					</DisableInteractiveElements>
					<div className="mt-3 text-center text-sm">
						See a snapshot of upcoming sessions from the home page and access enrolments in each student's profile
					</div>
				</section>
			</Container>
			<div className="bg-slate-1 py-20">
				<Container>
					<h2 id="student-profile" className="mb-6 font-display text-[28px] font-bold">
						Student profile
					</h2>
					<div className="md:w-2/3">
						<div>
							You can access student profiles from the home page. Each student will now have their own student profile,
							with an overview of their upcoming sessions and their learning profile.
						</div>
						<br />
						<div>You can add a student from any student profile page.</div>
					</div>
					<DisableInteractiveElements>
						<StudentProfilePreview />
					</DisableInteractiveElements>
					<div className="mt-3 text-center text-sm">Overview of upcoming sessions in student profile</div>
				</Container>
			</div>
			<Container className="px-4 py-7 md:py-8 lg:mb-0">
				<div className="pt-20 md:w-2/3">
					<div>
						On the student profile page, underneath the overview of the upcoming sessions you will find the Learning
						Profile.
					</div>
					<br />
					<div>
						In the Learning Profile, you can view the student's enrolment details or edit their profile. All enrolments
						and available subjects for the student will be displayed in this section.
					</div>
					<br />
					<div>
						You can now view tutor details, manage sessions and review feedback in the Learning Profile, which were
						previously accessed from the home page.
					</div>
				</div>
				<div className="mt-14 rounded-lg p-4 shadow-lg">
					<DisableInteractiveElements>
						<LearningProfilePreview />
					</DisableInteractiveElements>
					<div className="mt-3 text-center text-sm">Manage enrolments and student details in Learning Profile</div>
				</div>
			</Container>
		</ArticleLayout>
	);
};

const NewCustomerHub = () => {
	return <NewCustomerHubBase data={getArticleById('new-customer-hub')} />;
};

export default NewCustomerHub;
