import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import withSelectedEnrolment from '../../../common/hub/NewWithSelectedEnrolment';
import ErrorBlock from '../../../common/ErrorBlock';
import ContactFormLayout from '../../../common/hub/ContactFormLayout';
import FormSubHeading from '../../../components/hub/contact/FormSubHeading';
import { useTextAreaProps, TextArea } from '../../../components/TextArea/TextArea';
import { RestrictedButton } from '../../../components/RestrictedButton/RestrictedButton';

import { submitCaseToSF } from '../../../actions/hub/hubContactPageActions';
import { PATH_HUB_CR_SUBMIT_CASE_SUCCESS, PATH_HUB_CR_OTHER_QUERY } from '../../../util/pagePath';
import { useActions } from '../../../util/hooks/useActions';
import { useState } from 'react';
import { Enrolment } from '../../../api/types/enrolment';

interface Props {
  selectedEnrolment: Enrolment;
}

const FormOtherQuery = ({ selectedEnrolment }: Props) => {
  const { isSubmitting, submitError, errorMsg } = useSelector(
    (state: {
      ui: { apiState: { hubContactPage: { isLoading: boolean; fetchError: boolean; errorMsg: string } } };
    }) => {
      const isSubmitting = state.ui.apiState.hubContactPage.isLoading;
      const submitError = state.ui.apiState.hubContactPage.fetchError;
      const errorMsg = state.ui.apiState.hubContactPage.errorMsg;
      return { isSubmitting, submitError, errorMsg };
    }
  );

  const submit = useActions(submitCaseToSF);
  const history = useHistory();
  const { enrolmentId, studentSfid, student, subject } = selectedEnrolment;

  const textAreaProps = useTextAreaProps({
    id: 'text-how-can-we-help',
    labelText: 'How can we help you?',
  });

  const message = textAreaProps.value;
  const [loading, setLoading] = useState(false);
  const onClickHandler = () => {
    setLoading(true);
    submit({
      uniqueEnrolmentId: enrolmentId,
      message: `Details: ${message}`,
      caseSubject: 'Contact request - Feedback - Other',
      caseType: 'Feedback',
      caseCategory: 'Other',
      onSubmitSuccess: () => {
        history.push(PATH_HUB_CR_SUBMIT_CASE_SUCCESS, {
          firstName: student,
          studentId: studentSfid,
          message: `Your request for ${student}'s ${subject} enrolment has been submitted successfully. We'll be in touch soon.`,
        });
      },
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <ContactFormLayout selectedEnrolment={selectedEnrolment}>
      <div className="px-5 pt-5 lg:px-20 lg:pt-12">
        <FormSubHeading />
        {submitError && errorMsg && <ErrorBlock errorMsg={errorMsg} />}
        <form>
          <TextArea {...textAreaProps} className="mb-7" disabled={loading} />
        </form>
        <div className="mb-4 text-center tracking-normal lg:mb-8">
          <RestrictedButton
            buttonText={loading ? 'Submitting' : 'Submit query'}
            disabled={loading}
            truthyChecks={[message.length > 0, !isSubmitting]}
            onClick={() => {
              onClickHandler();
            }}
          />
        </div>
      </div>
    </ContactFormLayout>
  );
};
export default withSelectedEnrolment(FormOtherQuery, null, PATH_HUB_CR_OTHER_QUERY);
