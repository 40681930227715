import { orderBy } from 'lodash';
import { Assessment } from '../../../../../api/types/assessment';
import { Student } from '../../../../../api/types/student';
import { AccountDetails } from '../../../../../types/hubInterfaces';

export function parseYearString(yearString) {
	// Use a regular expression to find the first number in the string
	const match = yearString.match(/\d+/);

	// If a number is found, convert it to an integer, otherwise return null
	return match ? parseInt(match[0], 10) : null;
}

export function isWithinYearLevelRange(student: Student, country: AccountDetails['country']) {
	// kiwi = year 4 to year 10, aussie = year 3 to year 9 these rules are based on the implementation for TCU-3048
	const aussieRange = [3, 4, 5, 6, 7, 8, 9];
	const kiwiRange = [4, 5, 6, 7, 8, 9, 10];
	const yearLevel = parseYearString(student?.yearLevel || '');

	if (country === 'NZ') {
		return kiwiRange.includes(yearLevel);
	} else {
		return aussieRange.includes(yearLevel);
	}
}

export function isEnrolledInSubject(student: Student, subject: 'Mathematics' | 'English') {
	const availableSubjects = student?.availableSubjects || [];
	const subjectLowerCase = subject.toLowerCase();
	const enrolments = student?.enrolments || {};

	if (availableSubjects.length > 0 || enrolments[subjectLowerCase]) {
		return availableSubjects.includes(subject) || enrolments[subjectLowerCase]?.length > 0;
	}

	return false;
}

export function getAssessmentEligibilities(student: Student, country: AccountDetails['country']) {
	const mathematicsEligibility = !isEnrolledInSubject(student, 'Mathematics') && isWithinYearLevelRange(student, country);
	const englishEligibility = !isEnrolledInSubject(student, 'English') && isWithinYearLevelRange(student, country);
	return [
		...(mathematicsEligibility ? ['Mathematics'] : []),
		...(englishEligibility ? ['English'] : []),
	];
}

export function getLatestAssessmentInSubjectForStudent(
	assessments: Assessment[],
	subject: string,
	yearLevel: string,
	studentId: string
) {
	const assessmentsByMostRecentlyCreated = orderBy(assessments, ['createdAt'], ['desc']);

	return assessmentsByMostRecentlyCreated.find((assessment) => {
		return assessment.studentId === studentId && assessment.subject === subject && assessment.yearLevel === yearLevel;
	});
}

export function filterOngoingAssessmentsFromEligibilities(
	studentAssessmentEligibilities: string[],
	englishAssessment: Assessment,
	mathematicsAssessment: Assessment
) {
	return studentAssessmentEligibilities.filter((assessment) => {
		if (englishAssessment && assessment === 'English') return false;
		if (mathematicsAssessment && assessment === 'Mathematics') return false;
		return true;
	});
}

export function isValidUrl(string) {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;  
  }
}

export function countContent(filteredAssessmentEligibilities, englishAssessment, mathematicsAssessment) {
	let count = 0;
	if (englishAssessment) {
		count++;
	}
	if (mathematicsAssessment) {
		count++;
	}
	if (filteredAssessmentEligibilities?.length > 0) {
		count += filteredAssessmentEligibilities.length;
	}
	return count;
}
