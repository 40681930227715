import { AnchorButton, Container } from '@cluey/cluey-components';
import EnrolmentLayout from '../../../../common/hub/EnrolmentLayout';
import { CheckCircleIcon, OpenInNewTabIcon } from '../../../../icons';
import { useHistory, useParams } from 'react-router';
import { HeroDesktopOnly } from '../../home/hero/AssessmentHero/HeroDesktopOnly';
import { PATH_HUB_HOME } from '../../../../util/pagePath';
import type { AssessmentSubject } from '../../../../types/hubTypes';

interface State {
  studentLink: string;
  yearLevel: string;
}

const GenerateAssessmentSuccessful = () => {
  const history = useHistory<State>();
  const { location: { state } } = history;
  const { studentLink, yearLevel } = state ?? {};
  const { assessmentSubject } = useParams<{ assessmentSubject: AssessmentSubject }>();

  return (
    <EnrolmentLayout>
      <Container className='flex flex-col items-center gap-5 text-grey-6'>
        <CheckCircleIcon width={40} height={40} className='text-green-4' />
        <p className='font-bold text-grey-6'>Assessment link successfully generated</p>
        <p>Your {yearLevel} {assessmentSubject} assessment is ready on our Learning Platform.</p>
        <p>You can also access this link from your homepage from now on until it's completed.</p>
        <>
          <HeroDesktopOnly />
          <AnchorButton
            className="hidden lg:flex gap-3 self-start items-center m-auto normal-case"
            onClick={() => history.push(PATH_HUB_HOME)}
            href={studentLink}
            target="blank"
            size="lg">
            <span>Take assessment now</span>
            <OpenInNewTabIcon />
          </AnchorButton>
        </>
      </Container>
    </EnrolmentLayout>
  )
}

export { GenerateAssessmentSuccessful }