import { z } from 'zod';
import { createApi } from '../core';
import { EnrolmentDetail, Enrolment, ProgramOverview } from '../types/enrolment';
import { store } from '../../store/configureStore';
import { HUB_EP_FETCH_ENROLMENT_DETAIL_SUCCESS } from '../../actions/actionTypes';

export const enrolmentApi = createApi.group(['enrolment'], (createKey) => {
	return {
		all: createApi.query({
			queryKey: createKey(['all']),
			queryFn: (filter: 'active' | 'cancelled' | 'past') => {
				return createApi.fetch({
					path: ['enrolments'],
					method: 'GET',
					query: {
						filter,
					},
					validator: z.array(Enrolment),
					basePath: 'portalAPI',
					sign: true,
				});
			},
			staleTime: createApi.time(5, 'minutes'),
		}),
		detail: createApi.query({
			queryKey: createKey(['detail']),
			queryFn: async (params: { enrolmentId: string }) => {
				try {
					const result = await createApi.fetch({
						path: ['enrolment', params.enrolmentId],
						method: 'GET',
						validator: EnrolmentDetail,
						disableValidation: true,
						basePath: 'portalAPI',
						sign: true,
					});
					/* @TODO remove in the future */
					/*
          Currently, several components depend on redux for enrolment detail information.
          The store dispatch below will be removed once all components are migrated to use
          the enrolmentApi.detail query.
        */
					store.dispatch({
						type: HUB_EP_FETCH_ENROLMENT_DETAIL_SUCCESS,
						payload: result,
					});
					return result;
				} catch (error) {
					console.error('Error fetching enrolment detail:', error);
					return {};
				}
			},
			staleTime: createApi.time(5, 'minutes'),
		}),
		oneOffSession: createApi.mutation({
			mutationFn: (params: {
				tutorId: string;
				enrolmentId: string;
				customerId: string;
				recipientFirstname: string;
				recipientTimezone: string;
				recipientCountry: string;
				studentFirstName: string;
				studentName: string;
				studentYearLevel: string;
				studentCurriculum: string;
				programSubject: string;
				sessionStartDateTime: string;
				sessionEndDateTime: string;
				tutorFirstName: string;
				tutorTimezone: string;
				tutorPhoto: string;
			}) => {
				return createApi.fetch({
					path: ['enrolment', 'addSession'],
					method: 'POST',
					body: params,
					validator: z.unknown(),
					basePath: 'portalAPI',
					sign: true,
				});
			},
		}),
		programOverview: createApi.query({
			queryKey: createKey(['programOverview']),
			queryFn: (params: { studentCustomerNumber: string; subject: string }) => {
				return createApi.fetch({
					path: ['reporting', params.studentCustomerNumber, params.subject],
					method: 'GET',
					validator: ProgramOverview,
					basePath: 'portalAPI',
					sign: true,
				});
			},
			staleTime: createApi.time(5, 'minutes'),
		}),
	};
});
