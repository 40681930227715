import { DATE_FORMAT_TYPE_7, SESSION_TIME_FORMAT } from '../../../../../util/constants';
import { getTimezone } from '../../../../../selectors/hubSelector';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { TutorTimeSlot } from '../../../../../types/hubInterfaces';
import { useSelector } from 'react-redux';

interface Props {
  timeSlotCallback(param: TutorTimeSlot): void;
  selectedDate: string;
  timeSlots: Array<TutorTimeSlot>;
  tutorName: string;
}

const TimeSlots = ({selectedDate, timeSlotCallback, timeSlots, tutorName}: Props) => {
  const timeZone: string = useSelector(getTimezone);
  return (
    <section>
      <p className='text-xl font-bold'>Select session time</p>
      {isEmpty(timeSlots) ? 
        <div className='border border-dashed border-grey-3 flex items-center justify-center h-48 mt-9 rounded text-grey-5'>
          <span className='text-lg'>
            {tutorName} doesn't have any availabilty on this day. Please select another day.
          </span>
        </div>
        :
        <>
          <p className="mt-3 text-grey-5">{tutorName} has the following available times on {moment(selectedDate).format(DATE_FORMAT_TYPE_7)}</p>
            <div className="mt-4">
              {timeSlots.map(timeSlot => (
                <button
                  key={timeSlot.start}
                  className="mb-3 flex w-full cursor-pointer items-center justify-between rounded-lg border-2 border-slate-1 bg-slate-1 py-3 px-4 transition-colors hover:border-slate-2"
                  type="button"
                  tabIndex={-1}
                  onClick={() => {
                    timeSlotCallback(timeSlot);
                  }}
                >
                  <span className="text-lg font-bold text-primary lg:text-2xl">
                    {moment.utc(timeSlot.start).tz(timeZone).format(SESSION_TIME_FORMAT)}
                  </span>
                </button>
              ))}
            </div>
        </>
      }
    </section>
  )
}

export { TimeSlots };