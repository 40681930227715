import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Form } from '@cluey/cluey-components';

import withHeadTag from '../../../common/HeadComponent';
import ErrorBlock from '../../../common/ErrorBlock';
import AuthPageLayout from '../../../common/hub/AuthPageLayout';
import InPageLoader from '../../../common/InPageLoader';
import PasswordField from '../../../components/hub/auth/PasswordField';
import RetypedPasswordField from '../../../components/hub/auth/RetypedPasswordField';
import KeepMeLoggedIn from '../../../components/hub/auth/KeepMeLoggedIn';

import { setNewPassword } from '../../../actions/hub/hubAuthPagesActions';
import { PASSWORD_VALIDATION_RESULT } from '../../../util/constants';
import { PATH_HUB_NEW_PASSWORD_SUCCESS } from '../../../util/pagePath';
import { validatePassword } from '../../../util/validation/password';
import { passwordValidatorHelper } from '../../../util/helpers';
import { BackToLogin } from './HubResetPassword';
import TextBlock from '../../../components/hub/auth/TextBlock';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const HubSetNewPasswordPage = ({
  inPageSpinner,
  pageError,
  errorMsg,
  setNewPasswordRequest,
}) => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [passwordOrg, setPasswordOrg] = useState('');
  const [passwordRetyped, setPasswordRetyped] = useState('');
  const [retypedPWDValidation, setRetypedPWDValidation] = useState(PASSWORD_VALIDATION_RESULT.INIT);
  const [inPageError, setInPageError] = useState('');

  const query = useQuery();
  useEffect(() => {
    const emailParam = query.get('email');
    const codeParam = query.get('code');
    if (emailParam !== '' && emailParam !== null && codeParam !== '' && codeParam !== null) {
      setEmail(emailParam);
      setCode(codeParam);
    } else {
      setInPageError('You are not allowed to set your new password.');
    }
  }, [query, setEmail, setCode, setInPageError]);

  useEffect(() => {
    passwordValidatorHelper({
      passwordOrg,
      passwordRetyped,
      setRetypedPWDValidation,
    });
  }, [passwordOrg, passwordRetyped]);

  const onConfirm = (e) => {
    e.target.blur();
    if (passwordOrg === '' || passwordRetyped === '') {
      setInPageError('Please enter and confirm your password!');
      return;
    }

    if (!validatePassword(passwordRetyped)) {
      setRetypedPWDValidation(PASSWORD_VALIDATION_RESULT.INVALID);
      return;
    }

    if (validatePassword(passwordOrg) && validatePassword(passwordRetyped)) {
      if (passwordOrg === passwordRetyped) {
        setRetypedPWDValidation(PASSWORD_VALIDATION_RESULT.MATCH);
      } else {
        setRetypedPWDValidation(PASSWORD_VALIDATION_RESULT.NOT_MATCH);
        return;
      }
    } else {
      setInPageError('Please make sure your passwords are valid!');
      return;
    }

    setInPageError('');
    setRetypedPWDValidation(PASSWORD_VALIDATION_RESULT.INIT);
    setNewPasswordRequest({
      username: email,
      code,
      password: passwordOrg,
      onSuccess: () => {
        history.push(PATH_HUB_NEW_PASSWORD_SUCCESS);
      },
    });
  };

  return (
    <AuthPageLayout
      title="Set your new password"
      subtitle="Please enter your new password below."
    >
      { inPageSpinner && <InPageLoader position="relative" /> }
      { pageError && errorMsg && <ErrorBlock errorMsg={errorMsg} /> }
      { inPageError && <ErrorBlock errorMsg={inPageError} /> }
      { !inPageSpinner && (
        <Fragment>
          <TextBlock
            upper="Your email address"
            lower={email}
          />
          <Form>
            <PasswordField
              fieldLabel="New Password"
              fieldId="passwordOrg"
              savePassword={setPasswordOrg}
            />
            <RetypedPasswordField
              fieldLabel="Confirm new password"
              fieldId="passwordRetyped"
              placeholderText="Please re-enter your password"
              savePassword={setPasswordRetyped}
              retypedPWDValidation={retypedPWDValidation}
            />
            <KeepMeLoggedIn />
          </Form>
          <Button size="xs" className="w-full uppercase" onClick={onConfirm}>Reset Password</Button>
          {BackToLogin}
        </Fragment>
      ) }
    </AuthPageLayout>
  );
};

HubSetNewPasswordPage.propTypes = {
  inPageSpinner: PropTypes.bool.isRequired,
  pageError: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string,
  setNewPasswordRequest: PropTypes.func.isRequired,
};

HubSetNewPasswordPage.defaultProps = {
  errorMsg: '',
};

export default connect(
  (state) => {
    const { ui } = state;
    return {
      inPageSpinner: ui.apiState.hubAuthPage.isLoading,
      pageError: ui.apiState.hubAuthPage.fetchError,
      errorMsg: ui.apiState.hubAuthPage.errorMsg,
    };
  },
  (dispatch) => {
    return {
      setNewPasswordRequest: bindActionCreators(setNewPassword, dispatch),
    };
  },
)(withHeadTag(HubSetNewPasswordPage));
