import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { SPECIFIC_CONTACT_OPTIONS_ACTIONS, APP_VIEW_CONTACT_CANCEL_REQUEST } from '../../util/constants';
import { initContactUsForm, initContactSpecialRequest } from '../../actions/contactUsFlowActions';
import { saveSelectedEnrolment, saveSelectedSession } from '../../actions/hub/hubContactPageActions';
import { saveEnrolmentIdForEnrolmentManagement } from '../../actions/hub/hubEnrolmentManagementActions';

const ContactOptionItem = ({
  label,
  ciClass,
  action,
  caseCategory,
  caseType,
  recordType,
  updateAppViewAction,
  initCancelFlow,
  currentSession,
  sessionForm,
  enrolmentForm,
  changeSchedule,
  selectedSessionForHubForm,
  saveSelectedEnrolmentForHub,
  saveSelectedSessionForHub,
  saveEnrolmentIdForEM,
}) => {
  const history = useHistory();
  const onOptionClick = () => {
    if (action === SPECIFIC_CONTACT_OPTIONS_ACTIONS.SKIP_SESSION) {
      initCancelFlow({
        requestView: APP_VIEW_CONTACT_CANCEL_REQUEST,
        selectedSession: currentSession,
      });
    } else {
      const { uniqueEnrolmentId } = selectedSessionForHubForm;
      if (changeSchedule) {
        saveEnrolmentIdForEM({ enrolmentId: uniqueEnrolmentId });
        history.push(changeSchedule);
        return null;
      }

      if (enrolmentForm) {
        saveSelectedEnrolmentForHub({ enrolmentId: uniqueEnrolmentId });
        history.push(enrolmentForm);
        return null;
      }

      if (sessionForm) {
        saveSelectedSessionForHub({ session: selectedSessionForHubForm });
        history.push(sessionForm);
        return null;
      }

      updateAppViewAction({
        title: label,
        ciClass,
        caseCategory,
        caseType,
        recordType,
      });
    }

    return null;
  };

  const liClasses = classNames(
    'p-3 md:p-4 lg:p-6 pl-10 md:p-10 lg:pl-20 mb-4 rounded font-bold text-primary text-lg lg:text-xl border border-slate-3 bg-white cursor-pointer ci list-group-item',
    ciClass
  );
  return (
    <li
      className={liClasses}
      role="presentation"
      onClick={onOptionClick}
    >
      { label }
    </li>
  );
};

ContactOptionItem.propTypes = {
  label: PropTypes.string.isRequired,
  ciClass: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  caseCategory: PropTypes.string.isRequired,
  caseType: PropTypes.string.isRequired,
  recordType: PropTypes.string.isRequired,
  updateAppViewAction: PropTypes.func.isRequired,
  initCancelFlow: PropTypes.func.isRequired,
  currentSession: PropTypes.shape({
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    tutorName: PropTypes.string,
  }),
  sessionForm: PropTypes.string,
  enrolmentForm: PropTypes.string,
  changeSchedule: PropTypes.string,
  selectedSessionForHubForm: PropTypes.shape({
    uniqueEnrolmentId: PropTypes.string,
    student: PropTypes.string,
    subject: PropTypes.string,
    studentSessionId: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    tutor: PropTypes.string,
  }),
  saveSelectedEnrolmentForHub: PropTypes.func.isRequired,
  saveSelectedSessionForHub: PropTypes.func.isRequired,
  saveEnrolmentIdForEM: PropTypes.func.isRequired,
};

ContactOptionItem.defaultProps = {
  currentSession: {
    startTime: '',
    endTime: '',
    tutorName: '',
  },
  sessionForm: null,
  enrolmentForm: null,
  changeSchedule: null,
  selectedSessionForHubForm: {
    uniqueEnrolmentId: null,
    student: null,
    subject: null,
    studentSessionId: null,
    startTime: null,
    endTime: null,
    tutor: null,
  },
};

export default connect(
  (state) => {
    return {
      currentSession: state.rescheduleSession.currentSession,
      selectedSessionForHubForm: state.contactUsFlow.selectedSessionForHubForm,
    };
  },
  (dispatch) => {
    return {
      updateAppViewAction: bindActionCreators(initContactUsForm, dispatch),
      initCancelFlow: bindActionCreators(initContactSpecialRequest, dispatch),
      saveSelectedEnrolmentForHub: bindActionCreators(saveSelectedEnrolment, dispatch),
      saveSelectedSessionForHub: bindActionCreators(saveSelectedSession, dispatch),
      saveEnrolmentIdForEM: bindActionCreators(saveEnrolmentIdForEnrolmentManagement, dispatch),
    };
  },
)(ContactOptionItem);
