import { api } from "../../../../../api";
import { AccountDetails } from "../../../../../api/types/account";
import { Assessment } from "../../../../../api/types/assessment";
import { Student } from "../../../../../api/types/student";
import { filterOngoingAssessmentsFromEligibilities, getAssessmentEligibilities, getLatestAssessmentInSubjectForStudent } from "./functions";
import { StudentAssessmentsEligibilities } from "./types";

export function useMapToStudentAssessmentsEligibilities(students: Student[], country: AccountDetails['country']): StudentAssessmentsEligibilities[] {

	return students?.map((student) => {
		const assessments: Assessment[] = api.assessment.all.getQueryData({ customerNumber: student.customerNumber });
		const studentAssessmentEligibilities = getAssessmentEligibilities(student, country);
		const englishAssessment = getLatestAssessmentInSubjectForStudent(
			assessments || [],
			'English',
			student?.yearLevel || '',
			student?.studentId || ''
		);
		const mathematicsAssessment = getLatestAssessmentInSubjectForStudent(
			assessments || [],
			'Mathematics',
			student?.yearLevel || '',
			student?.studentId || ''
		);
		const filteredAssessmentEligibilities = filterOngoingAssessmentsFromEligibilities(
			studentAssessmentEligibilities,
			englishAssessment,
			mathematicsAssessment
		);
		const shouldShow = !!englishAssessment || !!mathematicsAssessment || filteredAssessmentEligibilities?.length > 0;

		return {
			student,
			shouldShow,
			englishAssessment,
			mathematicsAssessment,
			assessmentEligibilities: filteredAssessmentEligibilities
		};
	}) || [];
}