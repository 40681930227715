import React from 'react';
import PropTypes from 'prop-types';

import ResetPasswordResetLink from '../components/hub/auth/ResetPasswordResetLink';
import { EXPIRED_CODE_EXCEPTION_MSG } from '../util/constants';

const ErrorBlock = ({ errorMsg, bgColor, textColor }) => {
	const showRedirect = errorMsg === EXPIRED_CODE_EXCEPTION_MSG;
	return (
		<p className={`rounded px-3 py-2 ${bgColor} ${textColor}`}>
			{errorMsg}
			{showRedirect && (
				<ResetPasswordResetLink
					btnClasses="block p-0 mt-1 text-primary text-base font-body font-medium tracking-normal "
					btnText="Re-send password reset link"
				/>
			)}
		</p>
	);
};

ErrorBlock.propTypes = {
	errorMsg: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
	]).isRequired,
	bgColor: PropTypes.string,
	textColor: PropTypes.string,
};

ErrorBlock.defaultProps = {
	bgColor: 'bg-red-1',
	textColor: 'text-red-5',
};

export default ErrorBlock;
