import env from '../env';

export const BASE_PATHS = {
  portalAPI: `https://${env.REACT_APP_CLUEY_API_HOST_NAME}/customer-hub/`,
  newPortalAPI: `https://${env.REACT_APP_NEW_CLUEY_API_HOST_NAME}/`,
  mulesoft: `https://${env.REACT_APP_MULESOFT_DOMAIN}/`,
  salesforce: `https://${env.REACT_APP_API_SF_HOST_NAME}/`,
} as const;
export type BASE_PATHS = typeof BASE_PATHS;
export type BASE_PATH = keyof BASE_PATHS;
