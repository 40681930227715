import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { getUpcomingSessions } from './hubSelector';

/**
 * Get next upcoming sessions of given enrolment
 * @param {object} state
 * @param {string} enrolmentId
 * @returns { object | {}}
 */
export const getNextSessionByEnrolmentId = (state, enrolmentId) => {
  const sessions = getUpcomingSessions(state);
  if (isEmpty(sessions)) {
    return {};
  }

  const [nextSession = { isBundled: false, sessionsInBundle: 0 }] = sortBy(
    sessions.filter((session) => session.uniqueEnrolmentId === enrolmentId),
    (session) => moment(session.startTime).unix()
  );

  return nextSession;
};
