import { Auth2Layout } from '../common';
import { Button, Input, Label, Link, Pending } from '@cluey/cluey-components';
import { z } from 'zod';
import { BackButton } from '../common';
import { api } from '../../../../api';
import { InlineLoader } from '../common';
import { useHistory } from 'react-router';
import { PATH_HUB_HOME } from '../../../../util/pagePath';
import { useDispatch } from 'react-redux';
import { HUB_AP_UPDATE_ACCOUNT_DETAILS_SUCCESS } from '../../../../actions/actionTypes';
import { MOBILE_NUMBER_DUPLICATE_ERROR_MSG } from '../../../../util/constants';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import classNames from 'classnames';
import { BootlegSelect } from './components/BootlegSelect';

export function mapErrorMessage(errorMessage: string) {
	if (errorMessage.includes('Mobile number already exists')) {
		return MOBILE_NUMBER_DUPLICATE_ERROR_MSG;
	}
	return errorMessage;
}

const UserType = z.enum(['Parent / Guardian', 'Student', 'Other']);

const whoAreYouOptions = [
	{
		label: 'Parent / Guardian of a student',
		value: UserType.Values['Parent / Guardian'],
	},
	{
		label: 'Student',
		value: UserType.Values.Student,
	},
	{
		label: 'Teacher / Educator',
		value:
			UserType.Values.Other +
			' ' /* The space is needed here. Otherwise, the user won't be able to select this option if they've currently selected "Other". */,
	},
	{
		label: 'Other',
		value: UserType.Values.Other,
	},
];

const COUNTRY_OPTIONS = [
	{
		label: 'Australia',
		value: 'AU',
	},
	{
		label: 'New Zealand',
		value: 'NZ',
	},
];
const PhoneNumberValidator = z
	.string()
	.transform((val) => val.replace(/[ +()]/g, ''))
	.pipe(
		z
			.string()
			.min(7, 'Phone number must be 7 digits or more')
			.max(12, 'Phone number must be 12 digits or less')
			.regex(/^[0-9]+$/, 'Please enter a valid phone number')
			.or(z.string().max(0))
	);

const SignUpForm = z.object({
	countryCode: z.enum(['AU', 'NZ']),
	userType: z
		.string()
		.transform((value) => value.trim())
		.refine((value) => whoAreYouOptions.some((option) => option.value === value), {
			message: 'Please select an option.',
		}),
	phoneNumber: PhoneNumberValidator.optional(),
});
type SignUpForm = z.infer<typeof SignUpForm>;

const SignUpContactPage = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const redirect = () => history.push(PATH_HUB_HOME);

	const { data: details, isLoading: loadingDetails } = api.account.details.useQuery();

	const {
		watch,
		setValue,
		handleSubmit,
		register,
		formState: { errors },
	} = useForm<SignUpForm>({
		defaultValues: {
			countryCode: 'AU',
			userType: '',
			phoneNumber: '',
		},
		resolver: zodResolver(SignUpForm),
	});

	const {
		mutate,
		isLoading: updatingAccount,
		error,
	} = api.account.update.useMutation({
		onSuccess: async () => {
			await api.account.details.invalidateQueries();
			redirect();
		},
	});
	const submitError = mapErrorMessage((error as Error)?.message || '');

	const links = (
		<div className="w-full flex-grow md:pl-20 [&>*]:pb-4">
			<p>
				Already have an account?{' '}
				<Link size="base" to="/">
					Login
				</Link>
			</p>
		</div>
	);

	const onSubmit = handleSubmit((data: SignUpForm) => {
		mutate(
			{
				customerType: details?.userType || 'Lead',
				mobilePhone: data.phoneNumber.length ? data.phoneNumber : undefined,
				email: details?.email,
				customerNumber: details?.customerNumber,
				recordType: data.userType.trim(),
				countryCode: data.countryCode || 'AU',
			},
			{
				onSuccess: () => {
					dispatch({
						type: HUB_AP_UPDATE_ACCOUNT_DETAILS_SUCCESS,
						payload: {
							...details,
							phone: data.phoneNumber,
						},
					});
				},
			}
		);
	});

	const [phoneNumber, userType] = watch(['phoneNumber', 'userType']);

	return (
		<Auth2Layout
			title="You're almost there"
			links={links}
			headerLinks={
				<div className="flex justify-between">
					<BackButton />
				</div>
			}
		>
			<Pending
				loading={loadingDetails}
				loader={
					<div className="flex h-full w-full items-center justify-center">
						<InlineLoader />
					</div>
				}
			>
				<p>To help us tailor a personalised experience for you, please provide a few more details:</p>
				<div className="flex flex-col gap-4 pt-7">
					<div className="flex w-full flex-col gap-1">
						<Label className="!text-grey-5" id="country">
							Country
						</Label>
						<BootlegSelect id="country" disabled={updatingAccount} {...register('countryCode')}>
							{COUNTRY_OPTIONS.map((option) => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</BootlegSelect>
						{errors.countryCode && <span className="text-xs !text-red-5">{errors.countryCode.message}</span>}
					</div>
					<div className="flex w-full flex-col gap-1">
						<Label className="!text-grey-5" id="user-type">
							Tell us who you are
						</Label>
						<BootlegSelect
							id="user-type"
							disabled={updatingAccount}
							className={classNames('w-full', userType === '' && 'text-grey-4')}
							{...register('userType')}
						>
							<option value="" disabled>
								Select
							</option>
							{whoAreYouOptions.map((option) => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</BootlegSelect>
						{errors.userType && <span className="text-xs !text-red-5">{errors.userType.message}</span>}
					</div>
					<div className="flex w-full flex-col justify-end gap-1  text-grey-5">
						<Label className="!text-grey-5" id="phone-number">
							Preferred contact number (optional)
						</Label>
						<Input
							id="phone-number"
							value={phoneNumber}
							disabled={updatingAccount}
							onChange={(value) => setValue('phoneNumber', value)}
							error={Boolean(submitError || errors.phoneNumber)}
						/>
						{submitError || errors.phoneNumber ? (
							<span className="text-xs !text-red-5">{submitError || errors.phoneNumber.message}</span>
						) : (
							<span className="text-xs !text-grey-5">Include any applicable area code</span>
						)}
					</div>
				</div>
				{errors.root && <p className="text-xs text-red-5">{errors.root.message}</p>}
				<Button className="mt-10" size="lg" onClick={onSubmit} disabled={updatingAccount}>
					{updatingAccount ? <InlineLoader /> : 'Sign in'}
				</Button>
			</Pending>
		</Auth2Layout>
	);
};

export { SignUpContactPage };
