import { NSW_TOPICS, VIC_TOPICS, QLD_TOPICS, SA_TOPICS, WA_TOPICS, TAS_TOPICS, type Topic } from './topics';

function getAllTopics() {
  return [...NSW_TOPICS, ...VIC_TOPICS, ...QLD_TOPICS, ...SA_TOPICS, ...WA_TOPICS, ...TAS_TOPICS];
}

export function getAllTopicsForState(state: string) {
  const topics = getAllTopics();
  switch (state) {
    case 'NSW':
    case 'VIC':
    case 'QLD':
    case 'SA':
    case 'WA':
    case 'TAS': {
      return topics.filter((topic) => topic.state === state);
    }

    case 'NT': {
      return topics.filter((topic) => topic.state === 'SA');
    }
    case 'ACT':
    default: {
      return topics.filter((topic) => topic.state === 'NSW');
    }
  }
}

export default function getTopics(state: string, subject: string): Topic[] {
  const topics = getAllTopics();

  if (subject.includes('Chemistry')) {
    // We have some enrolments like "Chemistry HSC", "Chemistry VCE"
    return topics.filter((topic) => topic.state === state && subject.includes(topic.subject));
  }

  return topics.filter((topic) => topic.state === state && topic.subject === subject);
}
