import { ReactNode } from 'react';
import { ButtonAsLink } from '@cluey/cluey-components';

export interface EnrolmentCardDetailProps {
  renderIcon: ReactNode;
  description: string;
  actionLabel: string;
  onActionClicked: () => void;
  hideAction?: boolean;
  disableAction?: boolean;
}

function EnrolmentCardDetail(props: EnrolmentCardDetailProps) {
  const { renderIcon, description, actionLabel, onActionClicked, hideAction = false, disableAction = false } = props;

  return (
    <div className="flex flex-row py-5">
      <div className="mr-3 flex h-8 w-8 items-center">{renderIcon}</div>
      <div className="flex flex-grow items-center">{description}</div>
      <div className="flex items-center">
        {!hideAction && (
          <ButtonAsLink onClick={onActionClicked} disabled={disableAction} className="text-right lg:min-w-[192px]">
            <p className="font-bold uppercase">{actionLabel}</p>
          </ButtonAsLink>
        )}
      </div>
    </div>
  );
}

export default EnrolmentCardDetail;
