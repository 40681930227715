import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@cluey/cluey-components';

import dataNotAvailableImg from '../../assets/images/data-not-available.svg';

const DataNotAvailable = ({ firstLine, secondLine, showReloadButton }) => {
  return (
    <div className="flex flex-col items-center text-grey-6 m-auto pt-2 pb-5 text-center">
      <img
        src={dataNotAvailableImg}
        width={68}
        height={72}
        alt="data not available"
      />
      <span className="pt-3 text-xl font-bold">{firstLine}</span>
      <span className="text-lg">{secondLine}</span>
      {showReloadButton && (
        <div className='mt-4'>
          <Button
            size='lg'
            onClick={() => window.location.reload()}
          >
            Reload page
          </Button>
        </div>
      )}
    </div>
  );
};

DataNotAvailable.propTypes = {
  firstLine: PropTypes.string,
  secondLine: PropTypes.string,
  showReloadButton: PropTypes.bool,
};

DataNotAvailable.defaultProps = {
  firstLine: null,
  secondLine: null,
  showReloadButton: false,
};

export default DataNotAvailable;
