import { Button, Container, Heading4, Link } from '@cluey/cluey-components';
import { PATH_HUB_HOME } from '../../../../../util/pagePath';
import { ShadowContainer } from '../../../../../components/ShadowContainer';
import { StudentInformationForm } from '../../../../../components/hub/student/StudentInformationForm';
import { useFormContext } from 'react-hook-form';
import { ChevronDownIcon } from '../../../../../icons';
import { FormFlowProps } from '../../../../../types/hubInterfaces';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { StudentProfileData } from '../../StudentProfile/StudentProfile';

interface Props {
	formFlowProps: FormFlowProps;
}

const AddStudentInformation = ({ formFlowProps }: Props) => {
	const formUtils = useFormContext();
	const history = useHistory<StudentProfileData>();
	const historyState = history.location.state;

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	const onNext = async () => {
		const formValid = await formUtils.trigger();
		if (formValid) {
			formFlowProps.next({ ...historyState, ...formUtils.getValues() });
		}
	};

	return (
		<Container className="px-4 py-7 md:py-8 lg:mb-0">
			<Link className="text-xs font-bold uppercase" to={PATH_HUB_HOME}>
				Home
			</Link>
			<h1 className="text-[28px] font-bold">Add a new student</h1>
			<ShadowContainer className="mt-10 p-6 md:p-0">
				<Heading4 className="text-grey-6">
					Before we find the right program, please tell us a bit about about the student:
				</Heading4>
				<StudentInformationForm className="mt-10" formUtils={formUtils} />
			</ShadowContainer>
			<Button onClick={onNext} className="mt-8 flex items-center justify-center gap-5 normal-case" size="lg">
				<span>Next</span>
				<ChevronDownIcon className="rotate-[-90deg] text-white" />
			</Button>
			<Link className="mt-8 block text-xs" to={PATH_HUB_HOME}>
				Cancel
			</Link>
		</Container>
	);
};

export { AddStudentInformation };
