import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export function SmallBox({
  icon,
  iconBackgroundClass,
  leftText,
  rightText,
  renderExtraContent,
}) {
  return (
    <React.Fragment>
      <div className="mb-3 flex h-[56px]">
        <div className="flex w-full rounded-lg border border-custom-3">
          <div className={classNames('w-[56px] p-3', iconBackgroundClass)}>
            {icon && (
              <img src={icon} width="25" height="25" alt="generic icon" />
            )}
          </div>
          <div className="w-full px-4">
            <div
              className={classNames('flex h-full items-center justify-between')}
            >
              <span className="text-base font-bold">{leftText}</span>
              <span className="text-2xl">{rightText}</span>
            </div>
            {typeof renderExtraContent === 'function' && renderExtraContent()}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

SmallBox.propTypes = {
  icon: PropTypes.string,
  iconBackgroundClass: PropTypes.string,
  leftText: PropTypes.string,
  rightText: PropTypes.string,
  renderExtraContent: PropTypes.func,
};
SmallBox.defaultProps = {
  icon: '',
  iconBackgroundClass: '',
  leftText: '',
  rightText: '',
  renderExtraContent: null,
};
