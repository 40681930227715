import { get } from 'lodash';
import { ROLE_STAFF, ROLE_TUTOR } from '../util/constants';
import { isNZCheck } from '../util/helper/accountDetails';

export const isStaffCheck = (state) => {
  const role = get(state, 'login.role');
  return role === ROLE_STAFF;
};

export const isTutor = (state) => {
  const role = get(state, 'login.role');
  return role === ROLE_TUTOR;
};

export const isNZUserPageLA = (state) => {
  const country = get(state, 'login.country', '');
  return isNZCheck({ country });
};

export const isNZUserPageUS = (state) => {
  const country = get(state, 'upcomingSessions.country', '');
  return isNZCheck({ country });
};

export const loginSelector = (state) => state.login;
