import React from 'react';
import { Link } from 'react-router-dom';

import withHeadTag from '../../../common/HeadComponent';
import AuthPageLayout from '../../../common/hub/AuthPageLayout';

import { PATH_HUB_LOG_IN } from '../../../util/pagePath';

import arrow from '../../../assets/images/icon-arrow-right-primary.svg';
import iconPostbox from '../../../assets/images/hubAuth/postbox.svg';

const HubSignupErrorPage = () => {
  return (
    <AuthPageLayout
      title="Been here before?"
      subtitle="It looks like you already have a Cluey Hub account."
      imgIcon={iconPostbox}
      imgIconAlt="icon postbox"
    >
      <div className="w-full h-[1px] bg-slate-3 mt-10 mb-6" />
      <Link
        to={PATH_HUB_LOG_IN}
        className="flex items-center text-primary hover:underline"
      >
        <span>Log in to your account here</span>
        <img src={arrow} className="ml-3" alt="link to log in page" />
      </Link>
    </AuthPageLayout>
  );
};

export default withHeadTag(HubSignupErrorPage);
