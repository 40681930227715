import { IconProps as Props } from '../types/hubInterfaces';
import { iconDefaults } from '.';
import classNames from 'classnames';

function CompoundCalculatorIcon(props: Props) {
	const { width, height, className } = { ...iconDefaults, ...props };
	return (
		<svg className={classNames('fill-current', className)} xmlns="http://www.w3.org/2000/svg" width={`${width}`} height={`${height}`} viewBox="0 0 44 44" fill="none">
			<title>Compound Calculator icon</title>
			<path fillRule="evenodd" clipRule="evenodd" d="M6.29416 24.1792H20.3793V17.9452H6.29416V24.1792ZM18.3238 31.4348C17.4599 32.2987 16.057 32.2987 15.1924 31.4348C14.3278 30.5695 14.3278 29.1674 15.1924 28.3028C16.057 27.4389 17.4599 27.4389 18.3238 28.3028C19.1884 29.1674 19.1884 30.5695 18.3238 31.4348ZM18.3238 38.2776C17.4599 39.1429 16.057 39.1429 15.1924 38.2776C14.3278 37.413 14.3278 36.0108 15.1924 35.1462C16.057 34.2816 17.4599 34.2816 18.3238 35.1462C19.1884 36.0108 19.1884 37.413 18.3238 38.2776ZM11.4803 31.4348C10.6164 32.2987 9.21429 32.2987 8.34969 31.4348C7.48436 30.5695 7.48436 29.1674 8.34969 28.3028C9.21429 27.4389 10.6164 27.4389 11.4803 28.3028C12.3449 29.1674 12.3449 30.5695 11.4803 31.4348ZM11.4803 38.2776C10.6164 39.1429 9.21429 39.1429 8.34969 38.2776C7.48436 37.413 7.48436 36.0108 8.34969 35.1462C9.21429 34.2816 10.6164 34.2816 11.4803 35.1462C12.3449 36.0108 12.3449 37.413 11.4803 38.2776ZM21.6927 15.3169H4.98003C4.25476 15.3169 3.66663 15.9058 3.66663 16.631V41.1236C3.66663 41.8489 4.25476 42.437 4.98003 42.437H21.6927C22.4187 42.437 23.0061 41.8489 23.0061 41.1236V16.631C23.0061 15.9058 22.4187 15.3169 21.6927 15.3169Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M35.0765 7.85609H30.9038V3.68342C30.9038 2.45949 29.9109 1.46655 28.687 1.46655C27.4623 1.46655 26.4694 2.45949 26.4694 3.68342V7.85609H22.2974C21.0735 7.85609 20.0806 8.84902 20.0806 10.073C20.0806 11.2969 21.0735 12.2898 22.2974 12.2898H26.4694V16.4625C26.4694 17.6872 27.4623 18.6801 28.687 18.6801C29.9109 18.6801 30.9038 17.6872 30.9038 16.4625V12.2898H35.0765C36.3004 12.2898 37.2934 11.2969 37.2934 10.073C37.2934 8.84902 36.3004 7.85609 35.0765 7.85609Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M29.7326 28.6723L31.211 27.1939C31.6444 26.7613 31.6444 26.058 31.211 25.6239C30.7776 25.1905 30.0736 25.1905 29.6402 25.6239L28.1625 27.1015L26.6849 25.6239C26.2515 25.1905 25.5475 25.1905 25.1148 25.6239C24.6807 26.058 24.6807 26.7613 25.1148 27.1939L26.5925 28.6723L25.1148 30.15C24.6807 30.5834 24.6807 31.2867 25.1148 31.7201C25.3311 31.9371 25.6157 32.0457 25.8995 32.0457C26.184 32.0457 26.4678 31.9371 26.6849 31.7201L28.1625 30.2424L29.6402 31.7201C29.8565 31.9371 30.1418 32.0457 30.4256 32.0457C30.7094 32.0457 30.9939 31.9371 31.211 31.7201C31.6444 31.2867 31.6444 30.5834 31.211 30.15L29.7326 28.6723Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M38.8924 20.4875H30.979C30.1694 20.4875 29.5123 21.1446 29.5123 21.9542C29.5123 22.7638 30.1694 23.4209 30.979 23.4209H38.8924C39.702 23.4209 40.3591 22.7638 40.3591 21.9542C40.3591 21.1446 39.702 20.4875 38.8924 20.4875Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M34.9361 24.0684C34.0605 24.0684 33.3506 24.7782 33.3506 25.6531C33.3506 26.528 34.0605 27.2378 34.9361 27.2378C35.8117 27.2378 36.5208 26.528 36.5208 25.6531C36.5208 24.7782 35.8117 24.0684 34.9361 24.0684Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M34.9361 19.8409C35.8117 19.8409 36.5208 19.131 36.5208 18.2561C36.5208 17.3805 35.8117 16.6707 34.9361 16.6707C34.0605 16.6707 33.3506 17.3805 33.3506 18.2561C33.3506 19.131 34.0605 19.8409 34.9361 19.8409Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.1899 21.9807H10.4171C9.82603 21.9807 9.34717 21.5018 9.34717 20.9108C9.34717 20.3197 9.82603 19.8408 10.4171 19.8408H16.1899C16.781 19.8408 17.2598 20.3197 17.2598 20.9108C17.2598 21.5018 16.781 21.9807 16.1899 21.9807Z" />
		</svg>
	);
}

export { CompoundCalculatorIcon };
