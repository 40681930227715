import initialState from './initialState';
import {
  HUB_SP_FETCH_UPCOMING_SESSIONS_SUCCESS,
  HUB_SP_FETCH_COMPLETED_SESSIONS_SUCCESS,
  HUB_SP_FETCH_SCHOOL_TERMS_PUBLIC_HOLIDAYS_SUCCESS,
  HUB_SP_REMOVE_CANCELLED_SESSION
} from '../actions/actionTypes';

export default function hubSessionsPage(state = initialState.hubSessionsPage, action) {
  switch (action.type) {
    case HUB_SP_FETCH_UPCOMING_SESSIONS_SUCCESS:
      return {
        ...state,
        upcomingSessions: action.payload,
      };
    case HUB_SP_FETCH_COMPLETED_SESSIONS_SUCCESS:
      return {
        ...state,
        completedSessions: action.payload,
      };
    case HUB_SP_FETCH_SCHOOL_TERMS_PUBLIC_HOLIDAYS_SUCCESS:
      return {
        ...state,
        schoolTermsPublicHolidays: action.payload,
      };
    case HUB_SP_REMOVE_CANCELLED_SESSION:
      return {
        ...state,
        upcomingSessions: action.payload,
      }
    default:
      return state;
  }
}
