/*
 * NOTE, users can land on this page only via enrolment detail page so enrolment detail will be used here
 *
 */
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import EnrolmentLayout from '../../../common/hub/EnrolmentLayout';
import WithSelectedEnrolment from '../../../common/hub/NewWithSelectedEnrolment';
import { EnrolmentBrief } from '../../../components/EnrolmentManagement/EnrolmentBrief';
import {
  isEnrolmentCancelRequested,
  isEnrolmentPausedCheck,
  scrollToTop,
  willEnrolmentBePausedCheck,
} from '../../../util/helpers';
import {
  PATH_HUB_CR_PAUSE_SESSIONS,
  PATH_HUB_ENROLMENT_CHANGE_SCHEDULE,
  PATH_HUB_ENROLMENT_MANAGE_PAUSE,
  PATH_HUB_HOME,
  PATH_HUB_CR_CANCEL_ENROLMENT,
} from '../../../util/pagePath';
import { ENROLMENT_MANAGEMENT_STEPS, PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT } from '../../../util/constants';
import { TRACKING_MP } from '../../../util/trackingClasses';
import { getEnrolmentWillBePausedResumeSession, willEnrolmentBePaused } from '../../../selectors/enrolmentSelector';

import { resetManagePausePageState as resetManagePausePageStateAction } from '../../../actions/hub/hubEnrolmentManagementActions';
import { isGroupEnrolment } from '../../../selectors/hubSelector';
import { AffectedSessionsSummary } from '../../../components/EnrolmentManagement/PauseSessions/AffectedSessionsSummary';
import { EnrolmentManagementConfirmed } from '../../../components/EnrolmentManagement/EnrolmentManagementConfirmed';
import PageError from '../../../common/PageError';
import InPageLoader from '../../../common/InPageLoader';
import { ManagePauseKeepExistingSchedule } from './ManagePauseKeepExistingSchedule';
import { OptionCard } from '../../../components/EnrolmentManagement/OptionCard';
import SectionTitle from '../../../components/EnrolmentManagement/SectionTitle';
import { PromoText } from '../../../components/EnrolmentManagement/PromoText';
import { getEnrolmentSchedule } from '../../../selectors/enrolmentManagementSelector';
import { submitChangePause } from '../../../actions/hub/hubPauseActions';
import { isIncentiveValid } from '../../../util/summerCopy';
import { getSummerPromo } from '../../../selectors/summerModeSelector';
import { getResumeSession, submitCaseToSF } from '../../../actions/hub/hubContactPageActions';
import { DatesUtils } from '../../../util/dates';
import { saveSummerModeData as saveSummerModeDataAction } from '../../../actions/summerModeActions';
import { Container } from '@cluey/cluey-components';
import SummerPromoLegalCopy from '../../../components/EnrolmentManagement/SummerMode/SummerPromoLegalCopy';
import { api } from '../../../api/';
import { PreferToStopSessions } from '../../../components/PreferToStopSessions';

const DATE_FORMAT = 'dddd, MMMM D';

const ManagePauseResumptionOptions = (props) => {
  const {
    loadingError,
    enrolmentId,
    enrolmentSfid,
    studentName,
    enrolmentSubject,
    tutorName,
    tutorPhoto,
    willBePausedDates,
    isRestart,
    resetManagePausePageState,
    enrolmentSchedule,
    managePause,
    enrolmentPausedTo,
    enrolmentPausedFrom,
    saveSummerModeData,
    summerPromo,
    enrolmentStatus,
    isGroup,
  } = props;
  const { incentiveCode, promoText } = summerPromo;
  const dispatch = useDispatch();
  const [view, setView] = useState(ENROLMENT_MANAGEMENT_STEPS.DEFAULT);
  const [selectedResumeDay, setSelectedResumeDay] = useState('');
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const enrolmentIdToken = query.get('token');

  const { data: accountDetails } = api.account.details.useQuery();
  const { timezone } = accountDetails;
  const { isLoading } = api.enrolment.detail.useQuery({ enrolmentId: enrolmentIdToken });
  const changeSchedulePath = `${PATH_HUB_ENROLMENT_CHANGE_SCHEDULE}?token=${enrolmentId || enrolmentIdToken}`;

  useEffect(() => {
    if (!isLoading) {
      const isEnrolmentPaused =
        isEnrolmentPausedCheck({ enrolmentStatus }) ||
        willEnrolmentBePausedCheck({ enrolmentPausedFrom, enrolmentPausedTo });

      if (!isEnrolmentPaused) {
        history.push(PATH_HUB_CR_PAUSE_SESSIONS);
      }
      if (isGroup) {
        history.push(PATH_HUB_HOME);
      }
      if (isEnrolmentCancelRequested({ enrolmentStatus })) {
        history.push(`${PATH_HUB_CR_CANCEL_ENROLMENT}?view=form&token=${enrolmentId}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, enrolmentStatus, enrolmentPausedFrom, enrolmentPausedTo, isGroup]);

  useEffect(() => {
    if (isEmpty(enrolmentId) && !enrolmentIdToken) {
      history.replace(PATH_HUB_HOME);
      return undefined;
    }

    return () => {
      resetManagePausePageState();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    scrollToTop();
  });

  if (isEmpty(enrolmentId) && !enrolmentIdToken) {
    return null;
  }

  if (isLoading) {
    return <InPageLoader />;
  }

  if (loadingError) {
    return <PageError activePage="hub-manage-pause" />;
  }

  const { resumeFrom } = willBePausedDates;
  const resumeDateFormatted = moment(resumeFrom).tz(timezone).format(DATE_FORMAT);
  const pauseToDateFormatted = moment(enrolmentPausedTo).tz(timezone).format(DATE_FORMAT);

  const shouldApplyIncentive = isIncentiveValid(summerPromo.incentiveCode, selectedResumeDay || new Date(), {
    screen: PATH_HUB_ENROLMENT_MANAGE_PAUSE,
  });

  const onKeepScheduleHandler = ({ selectedResumeDate }) => {
    /* UTC midnight of the user's account timezone */
    const currentResumeDate = DatesUtils.convertFromTimezoneToUTC({
      stringDate: moment(resumeFrom).format(PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT),
      dateFormat: PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT,
      timezone,
    }).toISOString();
    setSelectedResumeDay(selectedResumeDate);

    /* selected resume date is in UTC, so it needs to be converted to the account time zone first */
    managePause({
      enrolmentId: enrolmentSfid,
      currentPauseStart: enrolmentPausedFrom,
      currentResumeDate,
      selectedResumeDate: moment(selectedResumeDate).tz(timezone),
      callback: () => {
        const summerModePayload = {
          salesForceEnrolmentId: enrolmentSfid,
          shouldApplyIncentive: isIncentiveValid(summerPromo.incentiveCode, selectedResumeDay, {
            screen: PATH_HUB_ENROLMENT_MANAGE_PAUSE,
          }),
          appliedSummerModeIncentive: incentiveCode,
          enrolmentId,
          summerPauseFrom: enrolmentPausedFrom,
          summerPauseTo: enrolmentPausedTo,
          onSubmitSuccess: () => {
            setView(ENROLMENT_MANAGEMENT_STEPS.CONFIRMED);
          },
        };
        dispatch(
          getResumeSession({
            enrolmentId,
            pauseTo: moment(selectedResumeDate).format(),
            page: 'manage-pause',
          })
        );
        saveSummerModeData(summerModePayload);
      },
    });
  };

  const renderDefaultView = () => (
    <EnrolmentLayout
      sectionHeading={`${studentName} ${enrolmentSubject}`}
      mainHeading="Change enrolment pause"
      enrolmentId={enrolmentId}
      hasCTAAboveFooter
      tracking={TRACKING_MP.PAGE}
    >
      <Container>
        <EnrolmentBrief
          leftColumnTitle={resumeFrom ? 'Enrolment set to resume from' : 'ENROLMENT PAUSED UNTIL'}
          leftColumnContent={resumeFrom ? resumeDateFormatted : pauseToDateFormatted}
          tutorName={tutorName}
          tutorPhoto={tutorPhoto}
        />
        <SectionTitle title="Manage resumption" />
        {promoText && shouldApplyIncentive && (
          <div className="mb-4">
            <PromoText text={promoText} />
          </div>
        )}
        <OptionCard onClickHandler={() => setView(ENROLMENT_MANAGEMENT_STEPS.KEEP_EXISTING_SCHEDULE)}>
          <h4 className="mb-3 text-2xl font-bold text-primary group-hover:text-purple-6">Keep existing schedule</h4>
          {enrolmentSchedule.map((s) => {
            return (
              <p key={`${s}`} className="mb-1">
                {s}
              </p>
            );
          })}
          <p className="mb-0 text-xs text-grey-4">You can change your ongoing schedule later</p>
        </OptionCard>
        <OptionCard className="mb-10" linkTo={changeSchedulePath}>
          <h4 className="mb-3 text-2xl font-bold text-primary group-hover:text-purple-6">Resume with new schedule</h4>
          <p className="mb-1">Manage ongoing schedule</p>
        </OptionCard>
        {promoText && shouldApplyIncentive && <SummerPromoLegalCopy />}
        <PreferToStopSessions enrolmentId={enrolmentId} />
      </Container>
    </EnrolmentLayout>
  );

  const shouldShowPromoText =
    promoText && isIncentiveValid(incentiveCode, selectedResumeDay, { screen: PATH_HUB_ENROLMENT_MANAGE_PAUSE });

  return (
    <Fragment>
      {view === ENROLMENT_MANAGEMENT_STEPS.DEFAULT && renderDefaultView()}
      {view === ENROLMENT_MANAGEMENT_STEPS.KEEP_EXISTING_SCHEDULE && (
        <ManagePauseKeepExistingSchedule
          {...props}
          timezone={timezone}
          onKeepScheduleHandler={onKeepScheduleHandler}
          incentive={promoText}
        />
      )}
      {view === ENROLMENT_MANAGEMENT_STEPS.CONFIRMED && (
        <EnrolmentManagementConfirmed
          mainHeading="Enrolment pause change has been confirmed"
          student={studentName}
          subject={enrolmentSubject}
          enrolmentId={enrolmentId}
          message="We will contact you 8 days before the resumption date to remind you."
          tracking={TRACKING_MP.SUCCESS}
          promoText={shouldShowPromoText ? promoText : ''}
          showLegalCopy={shouldShowPromoText}
        >
          <p className="mb-lg-8 md:wd-1/2 mb-5 text-xl leading-[1.2] lg:w-3/4">
            These changes will be reflected in your Hub account shortly. You will also receive an email confirming the
            following details:
          </p>
          <AffectedSessionsSummary
            timezone={timezone}
            resumeSessionDate={selectedResumeDay}
            isManagePauseConfirmed
            isRestart={isRestart}
          />
        </EnrolmentManagementConfirmed>
      )}
    </Fragment>
  );
};

ManagePauseResumptionOptions.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  loadingError: PropTypes.bool.isRequired,
  enrolmentId: PropTypes.string.isRequired,
  enrolmentSfid: PropTypes.string.isRequired,
  studentName: PropTypes.string.isRequired,
  enrolmentSubject: PropTypes.string.isRequired,
  enrolmentPausedTo: PropTypes.string,
  enrolmentType: PropTypes.string.isRequired,
  tutorName: PropTypes.string,
  tutorPhoto: PropTypes.string,
  willBePaused: PropTypes.bool.isRequired,
  willBePausedDates: PropTypes.shape({
    resumeFrom: PropTypes.string,
  }),
  isRestart: PropTypes.bool,
  isGroup: PropTypes.bool.isRequired,
  sessionFrequency: PropTypes.string.isRequired,
  resetManagePausePageState: PropTypes.func.isRequired,
  managePause: PropTypes.func.isRequired,
  enrolmentSchedule: PropTypes.arrayOf(PropTypes.string),
  enrolmentPausedFrom: PropTypes.string,
  incentive: PropTypes.string,
  sendFebruaryDiscountCase: PropTypes.func.isRequired,
  saveSummerModeData: PropTypes.func.isRequired,
  summerPromo: {
    incentiveCode: PropTypes.string,
    promoText: PropTypes.string,
  },
  enrolmentStatus: PropTypes.string.isRequired,
};

ManagePauseResumptionOptions.defaultProps = {
  enrolmentPausedTo: '',
  tutorName: '',
  tutorPhoto: '',
  willBePausedDates: {},
  isRestart: false,
  enrolmentSchedule: [],
  enrolmentPausedFrom: '',
  incentive: '',
  summerPromo: {
    incentiveCode: '',
    promoText: '',
  },
};

export { ManagePauseResumptionOptions as PresentationalManagePauseResumptionOptions };
export const ConnectedManagePauseResumptionOptions = connect(
  (state) => {
    const uiHubEnrolmentDetailPage = get(state, 'ui.apiState.hubEnrolmentDetailPage');
    const {
      enrolmentId,
      studentName,
      enrolmentSubject,
      enrolmentPausedTo,
      tutorName,
      tutorPhoto,
      managePause,
      sessionFrequency,
      enrolmentPausedFrom,
      enrolmentSfid,
      enrolmentStatus,
      enrolmentType,
    } = state.hubEnrolmentDetailPage;

    return {
      isLoading: uiHubEnrolmentDetailPage.firstFetch,
      loadingError: uiHubEnrolmentDetailPage.fetchError,
      enrolmentId,
      enrolmentSfid,
      enrolmentStatus,
      studentName,
      enrolmentSubject,
      enrolmentPausedTo,
      enrolmentType,
      tutorName,
      tutorPhoto,
      willBePaused: willEnrolmentBePaused(state),
      willBePausedDates: getEnrolmentWillBePausedResumeSession(state, true),
      isRestart: managePause.isRestart,
      isGroup: isGroupEnrolment(state),
      sessionFrequency,
      enrolmentSchedule: getEnrolmentSchedule(state),
      enrolmentPausedFrom,
      summerPromo: getSummerPromo(state, { screen: PATH_HUB_ENROLMENT_MANAGE_PAUSE }),
    };
  },
  (dispatch) => {
    return {
      resetManagePausePageState: bindActionCreators(resetManagePausePageStateAction, dispatch),
      managePause: bindActionCreators(submitChangePause, dispatch),
      sendFebruaryDiscountCase: bindActionCreators(submitCaseToSF, dispatch),
      saveSummerModeData: bindActionCreators(saveSummerModeDataAction, dispatch),
    };
  }
)(WithSelectedEnrolment(ManagePauseResumptionOptions, null, PATH_HUB_ENROLMENT_MANAGE_PAUSE));
