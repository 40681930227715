import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Radio } from '../RadioGroup/Radio';
import { groupSessionType } from '../../types/hubPropTypes';
import { SESSION_TIME_FORMAT } from '../../util/constants';
import { getGroupSessionMoment } from '../../util/helpers';

export const GroupTimeslot = ({
  sessions,
  packageId,
  className,
  onChoose,
  activeGroup,
  timezone,
}) => {
  if (!sessions.length) {
    return null;
  }

  return (
    <div
      className={`overflow-hidden rounded-lg border border-grey-2 ${className}`}
    >
      {/* Title */}
      <div className="flex items-center justify-between bg-slate-1">
        <div className="mb-0 px-5 py-4 font-display text-lg font-bold md:py-3 md:text-2xl">
          {sessions[0].groupDay}s at{' '}
          {moment(sessions[0].groupTime, 'hh:mm A')
            .tz(timezone)
            .format(SESSION_TIME_FORMAT)}
        </div>
      </div>

      {/* Date Options */}
      <div className="flex flex-col gap-5 p-5 md:flex-row">
        {sessions.map((opt) => {
          const sessionTime = getGroupSessionMoment(opt);
          return (
            <Radio
              key={`${packageId}-${sessionTime.toISOString()}`}
              groupName="group-timeslot"
              text={`Start ${sessionTime.tz(timezone).format('MMM D')}`}
              value={opt.groupDate}
              containerClassName="mb-0 w-full md:w-1/6"
              labelClassName="font-body font-normal mb-0"
              checked={
                activeGroup.id === packageId &&
                activeGroup.session === sessionTime.toISOString()
              }
              onChange={() => onChoose(sessionTime.toISOString())}
            />
          );
        })}
      </div>
    </div>
  );
};

GroupTimeslot.propTypes = {
  sessions: PropTypes.arrayOf(groupSessionType).isRequired,
  packageId: PropTypes.string.isRequired,
  className: PropTypes.string,
  onChoose: PropTypes.func.isRequired,
  activeGroup: PropTypes.shape({
    id: PropTypes.string.isRequired,
    session: PropTypes.string,
  }),
  timezone: PropTypes.string.isRequired,
};

GroupTimeslot.defaultProps = {
  className: '',
  activeGroup: {
    id: '',
    session: null,
  },
};
