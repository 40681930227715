import { Heading2, LinkButton, Pending } from "@cluey/cluey-components";
import { minBy } from "lodash";
import { Loader } from '../../../../components/Loader';
import { ReactComponent as CheckIcon } from '../../../../assets/images/icon-filled-tick.svg';
import { PATH_HUB_CHANGE_PLAN } from "../../../../util/pagePath";
import useAccountPricingPlanQuery from "../../../../hooks/queries/useAccountPricingPlanQuery";
import useRetrievePricingPlansQuery from "../../../../hooks/queries/useRetrievePricingPlansQuery";
import { calculatePlanDiscount } from "../../../../views/account/ChangePricingPlanPage/helper";

const CancellationDeterrent = () => {
  const { data: pricingPlans, isLoading: isLoadingPricingPlans } = useAccountPricingPlanQuery();
  const { currentPricingPlan } = pricingPlans ?? {};
  const {
    data: alternativePricingPlans,
    isLoading: isLoadingAlternativePricingPlans,
  } = useRetrievePricingPlansQuery();
  const { allowedPlansForTransition } = alternativePricingPlans || { allowedPlansForTransition: [] };
  const cheapestPlan = minBy(allowedPlansForTransition, 'privatePriceTier.costPerSession');
  const disc = calculatePlanDiscount(currentPricingPlan, cheapestPlan);
  const texts = [
    `Save up to ${disc}% off every session`,
    "Discover the right plan to fit your needs"
  ];
  const pricingPlanName = String(currentPricingPlan?.pricingPlanName).toLowerCase();
  const hideDeterrent = !pricingPlanName.includes('flexi');
  return (
    <Pending loading={isLoadingPricingPlans || isLoadingAlternativePricingPlans} loader={<Loader />}>
      {hideDeterrent ? null : (
        <div className="border border-grey-2 rounded p-6 mb-5 inline-block">
          <Heading2 className="!mb-0">Did you know?</Heading2>
          <Heading2 className="!mb-2">We have more affordable plans</Heading2>
          {texts.map(text => (
            <div key={text} className="flex gap-x-3 items-center">
              <CheckIcon width={16} height={16} />
              <span className="text-grey-5">{text}</span>
            </div>
          ))}
          <LinkButton
            appearance="reverse"
            to={PATH_HUB_CHANGE_PLAN}
            className="mt-5">
              View all pricing plans
          </LinkButton>
        </div>
      )}
    </Pending>
  )
}

export default CancellationDeterrent;