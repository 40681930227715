import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { LinkButton, Link, Anchor, Alert } from '@cluey/cluey-components';

import { redirectToChangeSchedule } from '../../actions/hub/hubChangeSchedulePageActions';
import {
	isGroupEnrolment,
	isEnrolmentCancelled,
	isEnrolmentPending,
	isEnrolmentPaused,
	isEnrolmentCancelRequested,
	isEnrolmentFinished,
	isEnrolmentActive,
	isEnrolmentOutsidePolicyPauseRequested,
} from '../../selectors/hubSelector';
import { willEnrolmentBePaused, getEnrolmentWillBePausedResumeSession } from '../../selectors/enrolmentSelector';
import {
	PATH_HUB_ENROLMENT_CHANGE_SCHEDULE,
	PATH_HUB_ENROLMENT_MANAGE_PAUSE,
	PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP,
} from '../../util/pagePath';
import { enrolmentScheduleType, enrolmentScheduleDefault } from '../../types/hubPropTypes';
import {
	SCHEDULE_DATE_FORMAT,
	SESSION_TIME_FORMAT,
	DATE_FORMAT_TYPE_2,
	PAUSED_ENROLMENT_RESUME_DATE_FORMAT,
	ENROLMENT_STATUS_TEXT,
} from '../../util/constants';

import { getNextSessionByEnrolmentId } from '../../selectors/priceChangeSelector';
import { getSelectedSessionRedirectPath } from '../../util/helper';

import alertExclamation from '../../assets/images/icon-filled-alert-exclamation.svg';
import alertInfo from '../../assets/images/icon-filled-alert-info.svg';
import alertExclamationRed from '../../assets/images/icon-filled-alert-exclamation--red.svg';
import { redirectToFormWithEnrolmentId } from '../../actions/hub/hubEnrolmentManagementActions';
import { SessionType } from '../../types/hubPropTypes';
import { useGetSessionPolicy } from '../../hooks/queries/useSessionQuery';

const formatDate = ({ date, timezone, dateFormat = DATE_FORMAT_TYPE_2 }) => {
	return moment(date).tz(timezone).format(dateFormat);
};

const EnrolmentStatus = ({
	enrolmentId,
	enrolmentPausedTo,
	enrolmentSchedule,
	timezone,
	isGroup,
	isPaused,
	isPending,
	isCancelledOrFinished,
	isCancelRequested,
	isOutsidePolicyPauseRequested,
	isActive,
	nextSession,
	willBePaused,
	willBePausedDates,
	redirectToCSP,
}) => {
	const history = useHistory();
	const containerClasses = classNames(
		'flex flex-col lg:flex-row mt-5 md:mt-6 lg:mt-7 rounded-lg enrolment-status',
		{ 'bg-yellow-1': isPaused },
		{ 'bg-blue-1': isCancelledOrFinished || isCancelRequested || willBePaused },
		{ 'bg-red-1': isPending }
	);

	const { pauseFrom = null, resumeFrom = null } = willBePausedDates;

	const linkManagePause = (
		<Link
			to={`${PATH_HUB_ENROLMENT_MANAGE_PAUSE}?token=${enrolmentId}`}
			className={classNames('ml-auto block text-sm', isPaused && 'leading-[1.14]', willBePaused && 'leading-6')}
		>
			Manage pause
		</Link>
	);

	const { data: sessionPolicy, error: policyError } = useGetSessionPolicy({ sessionId: nextSession?.studentSessionId });
	if (isPaused) {
		const textContent = resumeFrom ? 'Enrolment paused and set to resume' : 'Enrolment paused until';
		const dateToShow = resumeFrom || enrolmentPausedTo;

		const dateContent = formatDate({
			date: dateToShow,
			timezone,
			dateFormat: PAUSED_ENROLMENT_RESUME_DATE_FORMAT,
		});

		const changeSchedulePage = isGroup ? PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP : PATH_HUB_ENROLMENT_CHANGE_SCHEDULE;
		const track = isGroup
			? 'track-hub-enrolment-details-pause-banner-change-schedule'
			: 'track-hub-enrolment-details-change-schedule';
		const label = isGroup ? 'Change group' : 'Change ongoing schedule';
		const changeCTA = (
			<LinkButton
				to={changeSchedulePage}
				appearance="reverse"
				size="xs"
				className={`mb-6 block lg:mb-5 ${track}`}
				onClick={(e) => {
					e.preventDefault();
					redirectToCSP({
						enrolmentId,
						callback: () => {
							history.push(changeSchedulePage);
						},
					});
				}}
			>
				{label}
			</LinkButton>
		);

		return (
			<Fragment>
				{policyError && (
					<Alert
						className="mt-2"
						type="error"
						body={`There was an error retrieving this session's policy. ${policyError}`}
					/>
				)}
				<div className={containerClasses}>
					<div className="mb-6 flex items-start lg:mb-0">
						<img src={alertExclamation} alt="icon" className="mr-lg-5 mr-6" />
						<div>
							<span className="block leading-tight">{textContent}</span>
							<span className="mt-2 block text-lg font-bold leading-snug">{dateContent}</span>
							{enrolmentSchedule.length > 0 &&
								enrolmentSchedule.map((s) => {
									const date = `${moment.utc(s.schedule, SCHEDULE_DATE_FORMAT).format('dddd')}s`;
									const time = moment.utc(s.schedule, SCHEDULE_DATE_FORMAT).format(SESSION_TIME_FORMAT);
									return (
										<span key={`${date}-${time}`} className="block text-lg font-bold leading-snug">
											Sessions on {date} at {time}
										</span>
									);
								})}
						</div>
					</div>
					<div className="cta-wrapper lg:ml-auto">
						{changeCTA}
						{!isGroup && (
							<Fragment>
								{!policyError && (
									<Anchor
										size="sm"
										href={getSelectedSessionRedirectPath(isGroup, nextSession, timezone, sessionPolicy)}
										className="mb-2 block leading-[1.14]"
									>
										Reschedule next session
									</Anchor>
								)}
								{linkManagePause}
							</Fragment>
						)}
					</div>
				</div>
			</Fragment>
		);
	}

	if (isActive && !willBePaused) {
		return null;
	}

	const statusImg = isPending ? alertExclamationRed : alertInfo;
	let statusInfo = ENROLMENT_STATUS_TEXT.FINISHED;
	if (isOutsidePolicyPauseRequested) {
		statusInfo = ENROLMENT_STATUS_TEXT.OUTSIDE_POLICY_PAUSE_REQUESTED;
	} else if (isCancelRequested) {
		statusInfo = ENROLMENT_STATUS_TEXT.CANCEL_REQUESTED;
	} else if (isPending) {
		statusInfo = ENROLMENT_STATUS_TEXT.PENDING;
	} else if (willBePaused) {
		if (pauseFrom && resumeFrom) {
			statusInfo = [
				'This enrolment will be ',
				<span className="font-bold">paused from {formatDate({ date: pauseFrom, timezone })} </span>,
				' and ',
				<span className="font-bold">resume on {formatDate({ date: resumeFrom, timezone })}</span>,
			];
		} else {
			statusInfo = [
				'This enrolment will be ',
				<span className="font-bold">paused from {formatDate({ date: pauseFrom, timezone })} </span>,
				<span className="font-bold">to {formatDate({ date: enrolmentPausedTo, timezone })}</span>,
			];
		}
	}

	if (!statusInfo) {
		return null;
	}

	return (
		<div className={containerClasses}>
			<div className={classNames('flex items-center', willBePaused && 'mb-5 lg:mb-0')}>
				<img src={statusImg} alt="icon" className="mr-5 lg:mr-4" />

				<div>
					<span className="block text-base leading-tight">
						{Array.isArray(statusInfo) && React.Children.toArray(statusInfo.map((info) => info))}
						{!Array.isArray(statusInfo) && statusInfo}
					</span>
				</div>
			</div>
			{willBePaused && !isGroup && <div className="cta-wrapper lg:ml-auto">{linkManagePause}</div>}
		</div>
	);
};

EnrolmentStatus.propTypes = {
	enrolmentId: PropTypes.string.isRequired,
	enrolmentPausedTo: PropTypes.string,
	enrolmentSchedule: enrolmentScheduleType,
	timezone: PropTypes.string.isRequired,
	isGroup: PropTypes.bool,
	isPaused: PropTypes.bool,
	isPending: PropTypes.bool,
	isCancelledOrFinished: PropTypes.bool,
	isCancelRequested: PropTypes.bool,
	isOutsidePolicyPauseRequested: PropTypes.bool,
	isActive: PropTypes.bool,
	nextSession: SessionType.isRequired,
	willBePaused: PropTypes.bool,
	willBePausedDates: PropTypes.shape({
		pauseFrom: PropTypes.string,
		resumeFrom: PropTypes.string,
	}),
	redirectToCSP: PropTypes.func.isRequired,
};

EnrolmentStatus.defaultProps = {
	enrolmentPausedTo: '',
	enrolmentSchedule: enrolmentScheduleDefault,
	isGroup: false,
	isPaused: false,
	isPending: false,
	isCancelledOrFinished: false,
	isCancelRequested: false,
	isOutsidePolicyPauseRequested: false,
	isActive: false,
	willBePaused: false,
	willBePausedDates: {},
};

export default connect(
	(state) => {
		const { enrolmentId, enrolmentPausedTo, enrolmentSchedule } = state.hubEnrolmentDetailPage;
		return {
			enrolmentId,
			enrolmentPausedTo,
			enrolmentSchedule,
			timezone: state.hubUser.timezone,
			isGroup: isGroupEnrolment(state),
			isPaused: isEnrolmentPaused(state),
			isPending: isEnrolmentPending(state),
			isCancelledOrFinished: isEnrolmentCancelled(state) || isEnrolmentFinished(state),
			isCancelRequested: isEnrolmentCancelRequested(state),
			isOutsidePolicyPauseRequested: isEnrolmentOutsidePolicyPauseRequested(state),
			isActive: isEnrolmentActive(state),
			nextSession: getNextSessionByEnrolmentId(state, enrolmentId),
			willBePaused: willEnrolmentBePaused(state),
			willBePausedDates: getEnrolmentWillBePausedResumeSession(state, true),
		};
	},
	(dispatch) => {
		return {
			redirectToCSP: bindActionCreators(redirectToChangeSchedule, dispatch),
			redirectToForm: bindActionCreators(redirectToFormWithEnrolmentId, dispatch),
		};
	}
)(EnrolmentStatus);

export { EnrolmentStatus as StatelessEnrolmentStatus };
