import Chart from 'chart.js';
import 'chartjs-plugin-colorschemes';
import { PuRd6 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.brewer';

const backgroundColours = [
	'#08306b',
	'#08519c',
	'#2171b5',
	'#4292c6',
	'#6baed6',
	'#9ecae1',
	'#d4b9da',
	'#c994c7',
	'#df65b0',
	'#e7298a',
	'#ce1256',
	'#980043',
];

export function addColours(graphData) {
	return {
		...graphData,
		datasets: graphData.datasets.map((d, idx) => {
			const bgColor = backgroundColours[idx % backgroundColours.length];
			const hover = backgroundColours.reverse()[idx % backgroundColours.length];
			return {
				...d,
				backgroundColor: bgColor,
				hoverBackgroundColor: hover,
			};
		}),
	};
}

// refer to the link below for possible different colour schemes
// https://nagix.github.io/chartjs-plugin-colorschemes/colorchart.html
/**
 * this file serves as documentation/reference for the graph library react-chart-js
 *
 * Documentation of the underlining library: https://www.chartjs.org/docs/2.7.2/
 * Most popular plugins: https://www.chartjs.org/docs/2.7.2/notes/extensions.html
 * If you want any plugin for every chart, use
 *
 * import Chart from 'chart.js';
 * import * as ChartAnnotation from 'chartjs-plugin-annotation';
 * Chart.plugins.register([ChartAnnotation]); // Global
 *
 * otherwise just passed it as a property to Component (plugins)
 */

// custom plugin to make the graph area white
Chart.plugins.register({
	beforeDraw(chartInstance) {
		const { ctx } = chartInstance.chart;
		ctx.fillStyle = 'white';

		const { chartArea } = chartInstance;
		ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
	},
});
Chart.Tooltip.positioners.cursor = function (chartElements, coordinates) {
	return coordinates;
};
function getBaseGraphOptions(xLabel, yLabel, yTicks, tooltipLabelCallBack, maintainAspectRatio = false) {
	const opts = {
		legend: {
			display: false,
		},
		title: {
			display: true,
			text: '',
		},
		layout: {
			padding: {
				left: 20,
				right: 20,
				top: 20,
				bottom: 20,
			},
		},
		hover: {
			intersect: true,
			mode: 'nearest',
		},
		tooltips: {
			mode: 'point',
			position: 'cursor',
			intersect: true,
			displayColors: false,
			bodyAlign: 'center',
			backgroundColor: '#535759',
			cornerRadius: 8,
			xPadding: 28,
			yPadding: 17,
			bodyFontFamily: 'font-body, sans-serif',
			bodyFontSize: 14,
			bodyFontStyle: 'bold',
			callbacks: {
				title() {},
			},
		},
		responsive: true,
		maintainAspectRatio,
		scales: {
			xAxes: [
				{
					gridLines: {
						display: false,
					},
					stacked: true,
					scaleLabel: {
						display: true,
						labelString: xLabel,
						fontColor: '#383c40',
						fontSize: 11,
						fontFamily: 'font-display',
						fontStyle: 'bold',
					},
					ticks: {
						beginAtZero: true,
					},
				},
			],
			yAxes: [
				{
					type: 'linear',
					gridLines: {
						display: false,
					},
					stacked: true,
					scaleLabel: {
						display: true,
						labelString: yLabel,
						fontColor: '#383c40',
						fontSize: 11,
						fontFamily: 'font-display',
						fontStyle: 'bold',
					},
					ticks: {
						beginAtZero: true,
						min: 0,
						max: 100,
						maxTicksLimit: 3,
						stepSize: 50,
					},
				},
			],
		},
		plugins: {
			colorschemes: {
				scheme: PuRd6,
				// eslint-disable-next-line
				custom: (schemeColors) => {
					// Array.prototype.push.apply(schemeColors, Blues9);
					return backgroundColours;
				},
			},
		},
	};
	if (yTicks) {
		opts.scales.yAxes[0].ticks = yTicks;
	}
	if (tooltipLabelCallBack) {
		opts.tooltips.callbacks = {
			...opts.tooltips.callbacks,
			label: tooltipLabelCallBack,
		};
	}
	return opts;
}
export function getCVGraphOptions() {
	return getBaseGraphOptions(
		'SESSIONS',
		'',
		{
			beginAtZero: true,
			min: 0,
			max: 100,
			maxTicksLimit: 3,
			stepSize: 50,
			callback() {
				return '';
			},
		},
		(tooltipItem, data) => {
			const perModuleCoverage = data.datasets[tooltipItem.datasetIndex].coverage[tooltipItem.index];

			const label = `${data.datasets[tooltipItem.datasetIndex].label}: ${
				Math.round(perModuleCoverage * 100) / 100
			}% covered`;
			return label;
		}
	);
}

export function getPracticeGraphOptions(maxMins = 100) {
	return getBaseGraphOptions(
		'SESSION ASSIGNED',
		'PRACTICE TIME',
		{
			beginAtZero: true,
			min: 0,
			max: maxMins,
			maxTicksLimit: 5,
			stepSize: 30,
			callback(value) {
				return `${value}mins`;
			},
		},
		(tooltipItem, data) => {
			const dataLabel = data.datasets[tooltipItem.datasetIndex].label;
			if (dataLabel === 'Practice not assigned') {
				return dataLabel;
			}
			let label = `${Math.round(tooltipItem.yLabel * 100) / 100}mins`;
			label += data.datasets[tooltipItem.datasetIndex].label
				? ` - ${data.datasets[tooltipItem.datasetIndex].label}`
				: '';

			return label;
		}
	);
}
export function getModuleModalGraphOptions() {
	return getBaseGraphOptions(
		'SESSIONS',
		'COVERAGE',
		{
			beginAtZero: true,
			min: 0,
			max: 100,
			maxTicksLimit: 3,
			stepSize: 50,
			callback(value) {
				return `${value}% -`;
			},
		},
		(tooltipItem, data) => {
			const perTopicCoverage = data.datasets[tooltipItem.datasetIndex].coverage[tooltipItem.index];
			const label = `${data.datasets[tooltipItem.datasetIndex].label}: ${
				Math.round(perTopicCoverage * 100) / 100
			}% covered`;
			return label;
		},
		false
	);
}
