import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import classNames from 'classnames';

import env from '../../env';

import SectionHeader from './SectionHeader';
import { SummaryCard } from './SummaryCard';
import redExclamationMark from '../../assets/images/icon-filled-alert-exclamation-red.svg';
import tickImage from '../../assets/images/icon-filled-tick-green.svg';
import musclesImage from '../../assets/images/atoms-icon-filled-muscles--buttercup.svg';
import atomFilledTick from '../../assets/images/atoms-icon-filled-tick.svg';
import { EMOJI_LIST, SUBJECT_CHEMISTRY } from '../../util/constants';
import { isGroupSessionCheck } from '../../util/helpers';
import {
  getYearLevelAsNumber,
  isEndOfTheYear,
  showPracticeData,
} from '../../selectors/studentProgressSelector';

const LEARNT_A_LOT_DISPLAY_LABEL = 'I learned a lot';

function getInfoFromEmoji(label) {
  if (!label) {
    return {
      displayLabel: null,
      emoji: null,
      backgroundClass: 'bg-slate-1',
    };
  }
  let displayLabel = label;
  switch (label.toLowerCase()) {
    case 'learned a lot':
      displayLabel = LEARNT_A_LOT_DISPLAY_LABEL;
      break;
    case 'too easy':
      displayLabel = 'Easy';
      break;
    case 'too hard':
      displayLabel = 'Hard';
      break;
    default:
      break;
  }
  return {
    displayLabel,
    emoji: (
      EMOJI_LIST.find(
        (e) => e.emojiValue.toLowerCase() === label.toLowerCase()
      ) || {}
    ).emojiName,
    backgroundClass: [
      'Encouraging',
      'Learned a lot',
      'Fun',
      'Too easy',
    ].includes(label)
      ? 'bg-green-1'
      : 'bg-slate-1',
  };
}

export function getPracticeInfoByPercentage(percentage) {
  const data = {
    copy: '',
    backgroundClass: '',
    icon: '',
    iconText: '',
  };
  if (percentage > 75) {
    // positive
    data.copy = 'This is a great sign of motivation and commitment.';
    data.iconText = 'A great sign of confidence and motivation';
    data.backgroundClass = 'bg-green-1';
    data.icon = tickImage;
  } else if (percentage < 25) {
    // negative
    data.copy = 'More focus on practice builds confidence and engagement.';
    data.iconText = 'Cluey recommends completing all questions';
    data.backgroundClass = 'bg-red-1';
    data.icon = redExclamationMark;
  } else {
    // warning
    data.copy =
      'This level of practice demonstrates a good level of motivation, however…';
    data.iconText = 'Completing all questions is recommended';
    data.icon = musclesImage;
    data.backgroundClass = 'bg-yellow-1';
  }
  return data;
}

function getIntroMessage(isEOY, subject, yearLevel, studentName) {
  const ignoreCaseSubject = (subject || '').toLowerCase();
  if (ignoreCaseSubject === SUBJECT_CHEMISTRY) {
    if (isEOY) {
      return `Well Done! ${studentName} has assembled some of the building blocks in Chemistry. Let’s see how ${studentName} has progressed through the year…`;
    }
    return `We are proud of ${studentName}'s progress in the lead up to the (increasingly-less-daunting!) Chemistry exam. Let’s take a deep-dive into how ${studentName} is currently progressing…`;
  }
  if (isEOY) {
    return `We’re delighted to have been part of ${studentName}'s learning journey through Year ${yearLevel}. Let’s take a look at what we’ve covered…`;
  }
  return `We’re very happy to be part of ${studentName}'s learning journey through Year ${yearLevel}. Let’s take a look at how things are going so far…`;
}
const StudentProgressSummary = ({
  studentName,
  subject,
  yearLevel,
  mode,
  isEOY,
  totalSessionsCompleted,
  studentLearningExperience,
  totalLearningGoals,
  totalQuestionsAssigned,
  totalQuestionsSubmitted,
  practiceCompletion,
  hasPractice,
}) => {
  const { displayLabel, emoji, backgroundClass } = getInfoFromEmoji(
    studentLearningExperience
  );
  const {
    copy,
    iconText,
    icon,
    backgroundClass: practiceBg,
  } = getPracticeInfoByPercentage(practiceCompletion);

  const isGroup = isGroupSessionCheck(mode);
  let learningSessionsSummaryCardBody = `${studentName} completed ${totalSessionsCompleted} ${
    isGroup ? 'group' : 'private 1-to-1'
  } ${totalSessionsCompleted > 1 ? 'sessions' : 'session'}`;
  if (displayLabel) {
    if (displayLabel === LEARNT_A_LOT_DISPLAY_LABEL) {
      learningSessionsSummaryCardBody +=
        ' and said after the majority of them…';
    } else {
      learningSessionsSummaryCardBody += ', finding the majority…';
    }
  }

  return (
    <React.Fragment>
      <SectionHeader
        title="Summary"
        text={getIntroMessage(isEOY, subject, yearLevel, studentName)}
        alignRightsideElement="items-end"
        showBottomBorder
      />
      {totalSessionsCompleted > 0 && (
        <React.Fragment>
          <div
            className={classNames(
              'mt-6 flex flex-col gap-4 text-grey-6 lg:flex-row lg:justify-between',
              'lg:overflow-x-scroll lg:flex-wrap lg:[&::-webkit-scrollbar]:hidden [scrollbar-width:none]'
            )}
          >
            <div
              className={classNames(
                'single-card-container flex w-full',
                hasPractice ? 'lg:w-1/3' : 'lg:w-1/2'
              )}
            >
              <SummaryCard
                title="Learning sessions"
                subTitle={`${totalSessionsCompleted}`}
                body={learningSessionsSummaryCardBody}
                bodyClass={`pb-2 ${backgroundClass}`}
              >
                {displayLabel && (
                  <div className="flex items-center">
                    <span className="mr-1 font-bold leading-tight">{`"${displayLabel}"`}</span>
                    <img
                      className="ml-auto h-[42px] w-[42px]"
                      src={`${env.REACT_APP_CDN_STATIC_CONTENT}/emoji/emoji-${emoji}.svg`}
                      alt={studentLearningExperience}
                    />
                  </div>
                )}
              </SummaryCard>
            </div>
            <div
              className={classNames(
                'single-card-container flex w-10/12',
                hasPractice ? 'lg:w-1/3' : 'lg:w-1/2'
              )}
            >
              <SummaryCard
                title="Learning goals"
                subTitle={`${totalLearningGoals}`}
                body="Tutors and students set learning goals at the start of each session."
                bodyClass="pb-2 bg-green-1"
              >
                <div className="flex items-center">
                  <span className="mr-1 font-bold leading-tight">
                    A sign {studentName} is engaged in keeping sessions focused
                    and productive
                  </span>
                  <img
                    className="ml-auto h-[42px] w-[42px]"
                    src={atomFilledTick}
                    alt="icon"
                  />
                </div>
              </SummaryCard>
            </div>
            {hasPractice && (
              <div className="single-card-container flex w-10/12 lg:w-1/3">
                <SummaryCard
                  title="Practice questions"
                  subTitle={`${totalQuestionsSubmitted} of ${totalQuestionsAssigned}`}
                  body={copy}
                  bodyClass={`pb-2 ${practiceBg}`}
                >
                  <div className="flex items-center">
                    <span className="mr-1 font-bold leading-tight">
                      {iconText}
                    </span>
                    <img
                      className="ml-auto h-[42px] w-[42px]"
                      src={icon}
                      alt="icon"
                    />
                  </div>
                </SummaryCard>
              </div>
            )}
          </div>
          <div className="mt-16 mb-14 h-px rounded-lg border-b border-grey-2" />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

StudentProgressSummary.propTypes = {
  studentName: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  yearLevel: PropTypes.number.isRequired,
  mode: PropTypes.string.isRequired,
  totalSessionsCompleted: PropTypes.number,
  studentLearningExperience: PropTypes.string,
  totalLearningGoals: PropTypes.number,
  totalQuestionsAssigned: PropTypes.number,
  totalQuestionsSubmitted: PropTypes.number,
  practiceCompletion: PropTypes.number,
  isEOY: PropTypes.bool,
  hasPractice: PropTypes.bool,
};
StudentProgressSummary.defaultProps = {
  totalSessionsCompleted: 0,
  studentLearningExperience: '',
  totalLearningGoals: 0,
  totalQuestionsAssigned: 0,
  totalQuestionsSubmitted: 0,
  practiceCompletion: 0,
  isEOY: false,
  hasPractice: false,
};

export default connect((state) => {
  const summary = get(state, 'studentProgress.summary');
  const {
    totalSessionsCompleted,
    studentLearningExperience,
    totalLearningGoals,
    totalQuestionsAssigned,
    totalQuestionsSubmitted,
    practiceCompletion,
  } = summary;
  return {
    totalSessionsCompleted,
    studentLearningExperience,
    totalLearningGoals,
    totalQuestionsAssigned,
    totalQuestionsSubmitted,
    practiceCompletion,
    yearLevel: getYearLevelAsNumber(state),
    subject: state.report.subject,
    mode: state.report.mode,
    isEOY: isEndOfTheYear(state),
    studentName: state.report.studentName,
    hasPractice: showPracticeData(state),
  };
}, null)(StudentProgressSummary);
