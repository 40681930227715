import { useState } from 'react';
import { useSelector } from 'react-redux';

import { ReadView } from './ReadView';

import { isStaffCheck } from '../../../../selectors/loginSelector';
import { ConnectedEditViewZuora } from './EditViewZuora';
import { BillingInformationType } from '../../../../types/hubPropTypes';

type AccountBillingInformationProps = {
  billingInformation: BillingInformationType;
  error?: boolean;
};

const MIN_HEIGHT = 'min-h-[166px]'

const AccountBillingInformation = ({ billingInformation, error }: AccountBillingInformationProps) => {
  const [mode, setMode] = useState('read');
  const isStaff = useSelector(isStaffCheck);
  let view = null;

  if (error) return <div>error...</div>;
  if (mode === 'read') {
    view = (
      <ReadView
        billingInformation={billingInformation}
        setMode={setMode}
        isStaff={isStaff}
        success={billingInformation.billingInformationSucceedMsg}
      />
    );
  } else if (mode === 'edit') {
    view = <ConnectedEditViewZuora setMode={setMode} zuoraAccountId={billingInformation.zuoraId} />;
  }

  return (
    <section className={`${MIN_HEIGHT} pt-md-8 bg-white pt-5 pb-6`}>
      {view}
    </section>
  );
};

export default AccountBillingInformation;
