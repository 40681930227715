import { AccountDetails, EnrolmentDetail, FormFlowProps } from '../../../../../types/hubInterfaces';
import { Button, Container } from '@cluey/cluey-components';
import EnrolmentLayout from '../../../../../common/hub/EnrolmentLayout';
import { PATH_HUB_ENROLMENT_DETAIL } from '../../../../../util/pagePath';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAccountDetails } from '../../../../../selectors/hubSelector';

interface Props {
  formFlowProps: FormFlowProps;
}

interface State {
  discountEligible: boolean;
  enrolmentDetail: EnrolmentDetail;
}

const Success = ({ formFlowProps }: Props) => {
  const history = useHistory<State>();
  const { location: { state } } = history;
  const { enrolmentDetail, discountEligible } = state ?? {};
  const { customerNumber: accountNumber }: AccountDetails = useSelector(getAccountDetails);

  const {
    customerNumber,
    enrolmentId,
    enrolmentDiscipline,
    studentName,
  } = enrolmentDetail ?? {};

  const accountOwner = customerNumber === accountNumber;
  const accountOwnerText = accountOwner ? 'your' : `${studentName}'s`;

  useEffect(() => {
    if (!enrolmentDetail) {
      formFlowProps.jump(0);
    }
  }, [enrolmentDetail, formFlowProps])
  
  return (
    <EnrolmentLayout
      sectionHeading={`${studentName} ${enrolmentDiscipline}`}
      mainHeading="One-off session added"
      showEnrolmentStatus={false}
      showSummerModeEnrolmentStatus={false}
    >
      <Container>
        <section className='text-grey-5'>
          <p>
            {
              discountEligible ?
              `We have received your request to add a one-off session to ${studentName}'s schedule.`
              :
              `We have added a one-off session to ${accountOwnerText} schedule.`
            }
          </p>
          <p>
            {discountEligible 
              ? "Please allow up to 48 hours to see the new session reflected in your enrolment." 
              : "Please allow a few minutes to see the new session reflected in your enrolment."
            }
          </p>
          <p>You will also receive an email confirming the change.</p>
        </section>
        <div className='mt-10'>
          <Button onClick={() => history.push(`${PATH_HUB_ENROLMENT_DETAIL}?token=${enrolmentId}`)} size='lg'>View {accountOwnerText} {enrolmentDiscipline} enrolment</Button>
        </div>
      </Container>
    </EnrolmentLayout>
  )
}

export { Success }