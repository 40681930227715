import { Container } from '@cluey/cluey-components';
import Layout from '../Layout';
import { aiTutorFaq } from './AITutorFAQContents';
import FAQComponent from '../../../components/FAQComponent/FAQComponent';
import AppFAQAnswer from '../../../components/FAQComponent/components/FAQAnswer/AppFAQAnswer';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import HubPageLayout from '../../../common/hub/HubPageLayout';
import PageNotFound from '../../../components/routing/PageNotFound';

const AITutorFAQBase = () => {
  return (
    <>
      <Container>
        <FAQComponent
          title="FAQs"
          titleClassNames="!justify-start mb-5 text-[40px]"
          subtitleClassNames="!justify-start"
          questions={[aiTutorFaq]}
          itemConfig={{
            answerComponent: ({ item }) => <AppFAQAnswer answers={item.answers} />,
          }}
        />
        <br />
        <div>
          <span className="mb-5 mt-10 block font-bold">Disclaimer</span>
          <p className="text-sm">
            Cluey Coach is designed to provide educational support and offer study assistance. However, students should
            be aware of the following: <b>Supplemental Tool</b>: Cluey Coach is meant to complement and support, not
            replace, traditional teaching methods, classroom instruction, or personalised tutoring.{' '}
            <b>Accuracy of Information</b>: While every effort is made to ensure the accuracy of the information
            provided, Cluey Coach may occasionally present incomplete or incorrect responses. Students are encouraged to
            cross-check facts and verify answers with reliable sources. <b>User Responsibility</b>: Students and
            guardians are responsible for how they use Cluey Coach, and it is recommended that students engage with
            Cluey Coach responsibly and seek clarification from educators when needed. By using Cluey Coach, students
            acknowledge and accept these terms.
          </p>
        </div>
      </Container>
    </>
  );
};

export const AITutorFAQ = () => {
  const clueyCoachEnabled = useFeatureFlagEnabled('cluey-coach-enabled');
  if (!clueyCoachEnabled) {
    return (
      <HubPageLayout>
        <PageNotFound />
      </HubPageLayout>
    );
  }
  return (
    <Layout>
      <AITutorFAQBase />
    </Layout>
  );
};

export default AITutorFAQ;
