import { Button, ButtonAsLink, Container, ErrorText, FieldWrapper, H2 } from '@cluey/cluey-components';
import ErrorBlock from '../../common/ErrorBlock';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import withHeadTag from '../../common/HeadComponent';
import AccountPageLayout from '../../templates/AccountPageLayout/AccountPageLayout';
import { api } from '../../api';
import { ComponentProps, ReactNode, forwardRef, useState } from 'react';
import { AccountDetails } from '../../api/types/account';
import { MOBILE_NUMBER_DUPLICATE_ERROR_MSG } from '../../util/constants';
import { z } from 'zod';
import classNames from 'classnames';
import { InlineLoader } from '../hub/auth2/common';
import { formatError } from '../../util/errors';

export function mapErrorMessage(errorMessage: string) {
  if (errorMessage.includes('Mobile number already exists')) {
    return MOBILE_NUMBER_DUPLICATE_ERROR_MSG;
  }
  return errorMessage;
}

type ViewAccountDetailsProps = {
  details: AccountDetails;
  loading: false;
  edit: () => void;
};

type ViewAccountLoadingProps = {
  details: undefined;
  loading: true;
  edit: () => void;
};

type ViewAccountProps = ViewAccountDetailsProps | ViewAccountLoadingProps;

const SkeletonDiv = ({ loading, className, ...props }: { loading: boolean } & ComponentProps<'div'>) => (
  <div
    {...props}
    className={classNames('rounded-full leading-4', loading && 'animate-pulse bg-grey-2 text-transparent', className)}
  />
);

const ViewAccountDetails = (props: ViewAccountProps) => {
  let phone: ReactNode;
  if (props.loading) {
    phone = '0212341234'; // placeholder for skeleton width
  } else if (props.details.phone) {
    phone = props.details.phone;
  } else {
    phone = <ButtonAsLink onClick={props.edit}>+ Add a contact number</ButtonAsLink>;
  }

  return (
    <>
      <div className="flex gap-4 pb-10">
        {/* Labels */}
        <div className="flex flex-col gap-3">
          <SkeletonDiv loading={props.loading}>First Name</SkeletonDiv>
          <SkeletonDiv loading={props.loading}>Last Name</SkeletonDiv>
          <SkeletonDiv loading={props.loading}>Email</SkeletonDiv>
          <SkeletonDiv loading={props.loading}>Phone number</SkeletonDiv>
        </div>
        {/* Values */}
        <div className="flex flex-grow flex-col items-start gap-3">
          <SkeletonDiv loading={props.loading}>{!props.loading ? props.details.firstName : 'Joseph'}</SkeletonDiv>
          <SkeletonDiv loading={props.loading}>{!props.loading ? props.details.lastName : 'Smith'}</SkeletonDiv>
          <SkeletonDiv loading={props.loading}>
            {!props.loading ? props.details.email : 'jsmith@example.com'}
          </SkeletonDiv>
          <SkeletonDiv loading={props.loading}>{phone}</SkeletonDiv>
        </div>
      </div>
      <Button
        size="lg"
        disabled={props.loading}
        className={props.loading ? 'animate-pulse !text-transparent' : ''}
        onClick={props.edit}
      >
        Update contact details
      </Button>
    </>
  );
};

const EditableAccountDetails = z.object({
  firstName: z
    .string()
    .min(1, 'Please enter a valid first name')
    .regex(/^[a-zA-Z\s0-9_\-']+$/, "Please enter only letters, numbers, spaces and any of these symbols: _-'."),
  lastName: z
    .string()
    .min(1, 'Please enter a valid last name')
    .regex(/^[a-zA-Z\s0-9_\-']+$/, "Please enter only letters, numbers, spaces and any of these symbols: _-'."),
  email: z.string().email('Please enter a valid email address'),
  phone: z
    .string()
    .transform((val) => val.replace(/[ +()]/g, ''))
    .pipe(
      z
        .string()
        .min(7, 'Phone number must be 7 digits or more')
        .max(12, 'Phone number must be 12 digits or less')
        .regex(/^[0-9]+$/, 'Please enter a valid phone number')
    ),
});
type EditableAccountDetails = z.infer<typeof EditableAccountDetails>;

// We need to update cluey-components to properly support react-hook-form
const FormInput = forwardRef<HTMLInputElement, ComponentProps<'input'> & { error?: boolean }>(function FormInput(
  { className, error, ...props },
  ref
) {
  return (
    <input
      ref={ref}
      {...props}
      className={classNames(
        'w-[400px] rounded border px-2 py-2 focus:outline-none focus:ring focus:ring-blue-4/40',
        error ? 'border-red-5' : 'border-grey-3 focus:border-blue-4',
        className
      )}
    />
  );
});

const EditAccountDetails = ({ details, view }: { details: AccountDetails; view: () => void }) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, isDirty, errors },
    reset,
  } = useForm<EditableAccountDetails>({
    resolver: zodResolver(EditableAccountDetails),
    defaultValues: {
      firstName: details.firstName,
      lastName: details.lastName,
      email: details.email,
      phone: details.phone || '',
    },
  });

  const { mutateAsync: mutateCustomer } = api.account.update.useMutation({
    onError: (error) => {
      reset(undefined, { keepDirtyValues: true, keepErrors: true, keepDirty: true });
      setError('root', {
        message: mapErrorMessage(formatError(error)),
      });
    },
  });

  const onSubmit = handleSubmit(async (data: EditableAccountDetails) => {
    if (!isDirty) {
      view();
      return;
    }

    await mutateCustomer({
      customerType: details.userType,
      customerNumber: details.customerNumber,
      firstname: data.firstName,
      lastname: data.lastName,
      mobilePhone: data.phone,
      email: data.email,
    });

    const currData = api.account.details.getQueryData();
    api.account.details.setQueryData(undefined, {
      ...currData,
      ...data,
    });
    api.account.details.invalidateQueries();
    view();
  });

  return (
    <>
      <H2 className="pb-8">Update contact details</H2>
      <div className="flex">
        <form onSubmit={onSubmit} className="flex flex-col items-start gap-4">
          <FieldWrapper label="First Name" id="account-details-first-name" error={errors.firstName?.message}>
            <FormInput {...register('firstName')} disabled={isSubmitting} id="account-details-first-name" />
          </FieldWrapper>
          <FieldWrapper label="Last Name" id="account-details-last-name" error={errors.lastName?.message}>
            <FormInput {...register('lastName')} disabled={isSubmitting} id="account-details-last-name" />
          </FieldWrapper>
          <FieldWrapper label="Email Address" id="account-details-email" error={errors.email?.message}>
            <FormInput {...register('email')} disabled={isSubmitting} id="account-details-email" />
          </FieldWrapper>
          <div className="flex gap-8">
            <FieldWrapper label="Phone Number" id="account-details-phone" error={errors.phone?.message}>
              <FormInput
                {...register('phone')}
                disabled={isSubmitting}
                id="account-details-phone"
                error={!!errors.phone?.message || !!errors.root?.message}
              />
              <span className="text-xs">Include any applicable area code</span>
            </FieldWrapper>
          </div>
          {errors.root && <ErrorText className="mt-4">{errors.root.message}</ErrorText>}
          <div className="flex flex-col gap-8 pt-10">
            <Button type="submit" size="lg" disabled={isSubmitting}>
              {isSubmitting ? <InlineLoader /> : 'Save'}
            </Button>
            <ButtonAsLink onClick={view} size="base" className="text-left">
              Cancel
            </ButtonAsLink>
          </div>
        </form>
      </div>
    </>
  );
};

const AccountDetailsPage = () => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const { data: accountDetails, isLoading, error } = api.account.details.useQuery();

  return (
    <AccountPageLayout>
      <Container>
        <section className="mb-10 pb-10 lg:mb-0 lg:pb-0">
          {error && <ErrorBlock errorMsg={formatError(error)} />}
          {isLoading ? (
            <ViewAccountDetails loading={true} details={undefined} edit={() => setMode('edit')} />
          ) : mode === 'view' ? (
            <ViewAccountDetails loading={false} details={accountDetails} edit={() => setMode('edit')} />
          ) : (
            <EditAccountDetails details={accountDetails} view={() => setMode('view')} />
          )}
          <div className="mb-10 pb-10" />
        </section>
      </Container>
    </AccountPageLayout>
  );
};

export default withHeadTag(AccountDetailsPage);
