import initialState from '../../initialState';
import * as actionTypes from '../../../actions/actionTypes';

export default function upcomingSessionsReducer(state = initialState.ui.apiState.upcomingSessions, action) {
  switch (action.type) {
    case actionTypes.US_FETCH_SESSIONS_START:
      return {
        ...state,
        fetchError: false,
        isLoading: true,
      };
    case actionTypes.US_FETCH_SESSIONS_FAILED:
      return {
        ...state,
        firstFetch: false,
        fetchError: true,
        isLoading: false,
      };
    case actionTypes.US_FETCH_SESSIONS_SUCCESS:
      return {
        ...state,
        firstFetch: false,
        fetchError: false,
        isLoading: false,
      };
    case actionTypes.US_SHOW_UPCOMING_SESSIONS:
      return {
        ...state,
        ucSessionsVisible: true,
      };
    case actionTypes.US_HIDE_UPCOMING_SESSIONS:
      return {
        ...state,
        ucSessionsVisible: false,
      };
    default:
      return state;
  }
}
