import { TRACKING_PE } from '../../../../../util/trackingClasses';
import { useHistory } from 'react-router';
import type { Enrolment } from '../../../../../types/hubInterfaces';
import { Button, Container } from '@cluey/cluey-components';
import { PATH_HUB_HOME } from '../../../../../util/pagePath';
// import { CodeCampPromo } from '../../../../../components/hub/home/CodeCampPromo';
import HubFooter from '../../../../../common/hub/HubFooter/HubFooter';
import HubHeader from '../../../../../common/hub/HubHeader';
import classNames from 'classnames';
import { CheckIcon } from '../../../../../icons';
import moment from 'moment';
import { useFeatureFlagEnabled, useFeatureFlagPayload } from 'posthog-js/react';
import { CodeCampPromo } from '../../../../../components/hub/home/CodeCampPromo';
import { api } from '../../../../../api';

type CodeCampPromo = {
  startDate: string;
  endDate: string;
  yearLevels: string[];
};

const PauseSuccessV2 = () => {
  const history = useHistory<{ enrolment: Enrolment; pauseFrom: string; resumeFrom: string }>();
  const {
    location: { state },
  } = history;
  const enrolment = state?.enrolment;
  const resumeFrom = state?.resumeFrom;
  const pauseFrom = state?.pauseFrom;
  const { student, subject } = enrolment ?? {};
  const codeCampPromoFlagEnabled = useFeatureFlagEnabled('summer-pause-code-camp-promo');
  const codeCampPromo = useFeatureFlagPayload('summer-pause-code-camp-promo') as CodeCampPromo;

  const isDuringCodeCampPromo = moment().isBetween(codeCampPromo?.startDate, codeCampPromo?.endDate, 'day', '[]');
  const isYearLevelEligible = codeCampPromo?.yearLevels.includes(enrolment?.yearLevel);
  const { data: accountDetails } = api.account.details.useQuery();
  const isAU = accountDetails?.country === 'AU';
  const showCodeCampPromo = codeCampPromoFlagEnabled && isDuringCodeCampPromo && isYearLevelEligible && isAU;

  return (
    <>
      <HubHeader title="Hub" />
      <Container className="flex flex-col items-center gap-9 px-4 py-7 md:py-8 lg:mb-0">
        <div
          className={classNames('mt-10 flex flex-col items-center gap-2', {
            [TRACKING_PE.SUCCESS]: TRACKING_PE.SUCCESS,
          })}
        >
          <div className="flex h-[60px] w-[60px] items-center justify-center rounded-full bg-green-4">
            <CheckIcon className="text-white" width={40} height={40} />
          </div>
          <h1 className="text-center font-display text-[32px] font-bold sm:text-[40px]">
            Pause enrolment request sent
          </h1>
        </div>
        <div className="flex flex-col items-center gap-4 text-lg">
          <p>{`Your request to pause ${student}'s ${subject} enrolment has been sent.`}</p>
          <p>
            {`Sessions will be paused from ${moment(pauseFrom).format('dddd, D MMMM YYYY')} and resume on ${moment(
              resumeFrom
            ).format('dddd, D MMMM YYYY')}`}
          </p>
        </div>
        <Button
          onClick={() => {
            history.push(PATH_HUB_HOME);
          }}
          size="lg"
          appearance="reverse"
        >
          Return to home
        </Button>
        {showCodeCampPromo && (
          <div className="mt-12 w-full">
            <CodeCampPromo />
          </div>
        )}
        <div className="h-[150px]" />
      </Container>
      <HubFooter />
    </>
  );
};

export default PauseSuccessV2;
