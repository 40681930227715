import { Container, Link } from '@cluey/cluey-components';
import HubHeader from '../../../common/hub/HubHeader';
import HubFooter from '../../../common/hub/HubFooter/HubFooter';
import { Redirect, useParams } from 'react-router';
import { PATH_HUB_HOME } from '../../../util/pagePath';
import { api } from '../../../api';
import { Loader } from '../../../components/Loader/Loader';
import { Assessment } from '../../../api/types/assessment';
import AssessmentReportWidget from '../home/widgets/AssessmentReportWidget';
import CompletionBanner from '../../Assessments/CompletionBanner';
import { Student } from '../../../api/types/student';
import withHeadTag from '../../../common/HeadComponent';
import useScrollToTop from '../../../util/hooks/useScrollToTop';

export function reduceQueriesToAggregateData(queries) {
	return queries.reduce((acc, query) => {
		if (query.isSuccess || query.status === 'success') {
			acc.push(...query.data);
		}
		return acc;
	}, []);
}

export function findAssessmentByToken(assessments: Assessment[], token: string) {
	return assessments.find((assessment) => assessment.studentLink.includes(token) && assessment.status === 'Completed');
}

export interface ViewAssessmentBaseProps {
	token?: string;
	assessment?: Assessment;
	student?: Student;
	studentLink?: string;
	loading?: boolean;
}

const ViewAssessmentBase = ({ token, assessment, student, loading = true }: ViewAssessmentBaseProps) => {
	if (loading) {
		return (
			<div>
				<HubHeader title="Hub" />
				<Container className="px-4 py-7 md:py-8 lg:mb-0">
					<div className="flex flex-row justify-center pt-12">
						<div className="flex-1" />
						<Loader />
						<div className="flex-1" />
					</div>
				</Container>
			</div>
		);
	}

	return (
		<div>
			<HubHeader title="Hub" />
			<Container className="px-4 py-7 md:py-8 lg:mb-0">
				<div className="mb-8">
					<Link className="text-xs font-bold uppercase" to={PATH_HUB_HOME}>
						home
					</Link>
					<h1 className="font-display text-[28px] font-bold">Assessments</h1>
				</div>
				{assessment ? (
					<>
						<CompletionBanner
							studentName={student?.firstName || ''}
							subject={assessment.subject}
							yearLevel={assessment.yearLevel}
							completedAt={assessment.completedAt}
							questionsCorrect={assessment.questionsCorrect}
							questionsTotal={assessment.questionsTotal}
						/>
						<AssessmentReportWidget token={token} studentLink={assessment.studentLink} />
					</>
				) : (
					<div>Could not find the assessment.</div>
				)}
			</Container>
			<HubFooter />
		</div>
	);
};

const ViewAssessment = () => {
	useScrollToTop();
	const { token } = useParams<{ token: string }>();
	const {
		data: students,
		isLoading: isStudentsLoading,
		isSuccess,
	} = api.student.all.useQuery(undefined, { enabled: !!token });
	const hasFetchedStudents = isSuccess && !isStudentsLoading && students?.length > 0;

	const queries = api.assessment.all.useQueries(
		students?.map((student) => ({ customerNumber: student.customerNumber })),
		{ enabled: hasFetchedStudents }
	);
	const firstQuery = queries[0];
	const initialized = firstQuery && !firstQuery.isInitialLoading;

	const aggregateData = reduceQueriesToAggregateData(queries);
	const allQueriesFinished = queries.every((query) => query.isLoading === false);
	const assessment = findAssessmentByToken(aggregateData || [], token || '');
	const assessmentNotFound = initialized && allQueriesFinished && !assessment;
	const assessmentStudent = students?.find((student) => student.studentId === assessment?.studentId);

	if (!token || assessmentNotFound) {
		return <Redirect to={PATH_HUB_HOME} />;
	}

	return (
		<ViewAssessmentBase
			token={token}
			assessment={assessment}
			student={assessmentStudent}
			loading={!initialized || !allQueriesFinished}
		/>
	);
};

export default withHeadTag(ViewAssessment);
