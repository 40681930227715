import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { generatePath, Link, useParams } from 'react-router-dom';
import { ClueyLogoBase, SiteTitle } from '@cluey/cluey-components';

import {
	PATH_HUB_AI_TUTOR,
	PATH_HUB_AI_TUTOR_FAQ,
	PATH_HUB_HOME,
} from '../../util/pagePath';
import ClueyLogo from '../../assets/images/cluey-logo.svg';

import Borger from '../../assets/images/atoms-icon-filled-hamburger.svg';
import Cross from '../../assets/images/atoms-icon-filled-cross.svg';
import posthog from 'posthog-js';

interface HeaderProps {
	title?: string;
	hideMenu?: boolean;
}

interface MenuItem {
	label: string;
	path: string;
	enrolmentId: boolean;
}

interface Menu {
	[key: string]: MenuItem;
}

const NAV_ITEMS: Menu = {
	home: {
		label: 'Home',
		path: PATH_HUB_AI_TUTOR,
		enrolmentId: true,
	},
	faqs: {
		label: 'FAQs',
		path: PATH_HUB_AI_TUTOR_FAQ,
		enrolmentId: true,
	},
	hub: {
		label: 'Return to Hub',
		path: PATH_HUB_HOME,
		enrolmentId: false,
	},
};

const Header = ({ title, hideMenu = false }: HeaderProps) => {
	const { enrolmentId } = useParams<{ enrolmentId: string }>();
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const menuOnHover = () => setDropdownOpen(true);
	const menuOnHoverExit = () => setDropdownOpen(false);
	const toggleMenu = () => setDropdownOpen(!dropdownOpen);

	const dropdownRef = useRef<HTMLDivElement>(null);

	const navOptions = Object.values(NAV_ITEMS);

	useEffect(() => {
		const closeMenu = (e: MouseEvent) => {
			if (dropdownOpen && dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
				setDropdownOpen(false);
			}
		};

		if (dropdownOpen) {
			document.addEventListener('click', closeMenu);
		}
		return () => {
			document.removeEventListener('click', closeMenu);
		};
	}, [dropdownOpen]);

	const renderMenuButton = (
		<div
			className="flex h-full items-center lg:relative"
			ref={dropdownRef}
			onMouseEnter={menuOnHover}
			onMouseLeave={menuOnHoverExit}
		>
			<button
				className={classNames(
					'absolute right-0 top-0 flex h-14 w-14 cursor-pointer items-center gap-2 px-4 lg:static lg:h-full lg:w-auto lg:hover:bg-slate-1',
					{
						'bg-slate-1': dropdownOpen,
					}
				)}
				onClick={toggleMenu}
			>
				<img src={Borger} alt="" className={dropdownOpen ? 'hidden lg:block' : 'block'} />
				<img src={Cross} alt="" className={dropdownOpen ? 'block lg:hidden' : 'hidden'} />
				<span className="hidden font-bold text-blue-5 lg:block">Menu</span>
			</button>
			<nav
				className={classNames(
					'absolute left-0 right-0 top-[calc(100%+4px)] z-20 flex h-screen w-screen flex-col bg-slate-1 pb-5 pt-4 lg:left-auto lg:top-full lg:h-auto lg:w-44',
					{
						hidden: !dropdownOpen,
					}
				)}
			>
				{navOptions.map((option) => (
					<Link
						key={option.path}
						className="flex h-10 items-center px-4 text-sm font-bold tracking-wider text-blue-5 hover:bg-white"
						to={option.enrolmentId ? generatePath(option.path, { enrolmentId }) : option.path}
						onClick={() => posthog.capture('click_cluey-coach_nav-menu-item', { $menuItem: option.label })}
					>
						{option.label}
					</Link>
				))}
			</nav>
		</div>
	);

	return (
		<div className="sticky top-0 z-10 flex h-14 w-full border-b-4 border-blood-orange-4 bg-white lg:h-20">
			<header className="container mx-auto flex items-center justify-between lg:px-4">
				<div className="flex items-center px-4 lg:px-0">
					<Link to={PATH_HUB_HOME}>
						<ClueyLogoBase src={ClueyLogo} />
					</Link>
					<span className='[&>span]:md:!text-2xl'>
						<SiteTitle title={title} />
					</span>
				</div>
				{!hideMenu && renderMenuButton}
			</header>
		</div>
	);
};

export default Header;
