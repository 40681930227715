import { ComputerIcon } from "../../../../../../icons"
import classNames from "classnames"

interface Props {
  className?: string;
  overrideText?: string;
}

const HeroDesktopOnly = ({ className, overrideText }: Props) => {
  return (
    <div className={classNames("w-full bg-grey-1 rounded-md py-3 px-5 flex gap-4 items-center lg:hidden", className)}>
      <ComputerIcon width={55} height={55} className='text-grey-4' />
      <span className="text-sm leading-4 text-grey-6">{overrideText || "Please use a desktop or laptop computer to continue your assessment."}</span>
    </div>
  )
}
export { HeroDesktopOnly }