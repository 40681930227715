import { isPaymentFailed } from '../helpers';
/**
 * Get payment failure message by checking student sessions
 * @param  {Array} sessions                studentSessions array
 * @return {string}                        payment failure message
 */
export const getPaymentFailureAlertInfo = (sessions) => {
  const sessionsFailedToPay = sessions.filter((session) => {
    return isPaymentFailed(session.paymentStatus);
  });

  if (sessionsFailedToPay.length === 0) {
    return '';
  }

  const enrolmentIds = sessionsFailedToPay.map(session => session.uniqueEnrolmentId);
  const enrolmentIdsUnique = [...new Set(enrolmentIds)];

  const { student, subject } = sessionsFailedToPay[0];

  const infoPartial = enrolmentIdsUnique.length === 1
    ? `${student}’s upcoming ${subject} session`
    : 'upcoming sessions';

  return `Your payment for ${infoPartial} has failed. We’ll try again shortly, however if unsuccessful before the session, it will be cancelled.`;
};
