import classNames from 'classnames';
import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from 'react';
import { ChevronDownIcon } from '../../../../../icons';
export type SelectProps = ComponentPropsWithoutRef<'select'> & {
	testing?: boolean;
	error?: boolean;
};
const BootlegSelectRaw = (
	{ className, error, testing, children, disabled, defaultValue, ...props }: SelectProps,
	ref?: ForwardedRef<HTMLSelectElement>
) => {
	return (
		<div
			className={classNames(
				'relative w-full rounded border focus-within:border-blue-4 focus-within:ring focus-within:ring-blue-4/40',
				error ? 'border-red-5' : 'border-grey-3',
				{ 'bg-slate-2 text-grey-4': disabled },
				className
			)}
			data-testid={testing && 'dropdown-wrapper'}
		>
			<select
				{...props}
				ref={ref}
				disabled={disabled}
				className={classNames('w-full min-w-0 appearance-none bg-transparent py-2 pl-3 pr-9 focus:outline-none')}
				defaultValue={defaultValue}
			>
				{children}
			</select>
			<div className="pointer-events-none absolute right-0 top-[50%] flex flex-shrink-0 translate-y-[-50%] items-center justify-center px-4">
				<ChevronDownIcon height={16} className="text-blue-5" />
			</div>
		</div>
	);
};

/** @deprecated - I will be replaced in cluey-components v2 */
export const BootlegSelect = forwardRef(BootlegSelectRaw);
