/* @TODO add types to other selectors */
import { get, sortBy, filter, uniqBy, isEmpty } from 'lodash';
import moment from 'moment';
import { PAYMENT_STATUS } from '../types/hubEnums';
import {
  isGroupSessionCheck,
  isPaymentFailed,
  isEnrolmentPausedCheck,
  isEnrolmentActiveCheck,
  isEnrolmentOutsidePolicyPauseRequested as isEnrolmentOutsidePolicyPauseRequestedCheck,
} from '../util/helpers';
import { isNZCheck } from '../util/helper';
import { store } from '../store/configureStore';

import {
  UNIQUE_ENROLMENT_ID_FOR_NEW_STUDENT,
  SPECIAL_DATE_TYPE,
  ENROLMENT_STATUSES,
  SESSION_TIME_FORMAT,
  DATE_FORMAT_YEAR,
} from '../util/constants';
import { isAHoliday } from '../util/helper/holiday';
import type { SchoolTerm, Session } from '../types/hubInterfaces';

const A_NEW_STUDENT = 'A new student';

export const getTimezone = (state) => get(state, 'hubUser.timezone', moment.tz.guess()); // default to Sydney because moment().tz('') will result in undefined which would break parts of our code that have additional chaining such as .add() or .format()
export const getTimezoneFromStore = () => {
  const state = store.getState();
  return getTimezone(state) || moment.tz.guess();
};

export const getActiveEnrolments = (state) => get(state, 'hubEnrolmentsPage.activeEnrolments', []);
export const getActiveGroupEnrolments = (state) => {
  const activeEnrolments = getActiveEnrolments(state);
  return activeEnrolments.filter((e) => {
      return e.type === "Group";
  });
};
export const getIsNZuser = (state) => {
  const { country } = state.hubAccountPage.accountDetails;
  return isNZCheck({ country });
};
export const getPastEnrolments = (state) => get(state, 'hubEnrolmentsPage.pastEnrolments', []);

export const getSessionsInXDays = (state, days) => {
  const sessions = getUpcomingSessions(state);
  if (sessions.length === 0) {
    return [];
  }

  let filteredSessions = [];
  if (days === 0) {
    filteredSessions = filter(sessions, (s) => moment(s.startTime).isSame(moment(), 'day'));
  } else {
    filteredSessions = filter(sessions, (s) => {
      return (
        moment(s.startTime).isSameOrAfter(moment(), 'day') &&
        moment(s.startTime).isSameOrBefore(moment().add(days, 'day'))
      );
    });
  }

  return sortBy(filteredSessions, 'startTime');
};

export const getSessionsIn7DaysWithFailedPayment = (state) => {
  const sessionsIn7Days = getSessionsInXDays(state, 7);
  return sessionsIn7Days.filter((session) => {
    return isPaymentFailed(session.paymentStatus);
  });
};

export const getPausedEnrolments = (state) => {
  const enrolments = getActiveEnrolments(state);
  const enrolmentsWithResumeSession = state.hubEnrolmentsWithResumeSession || [];

  const pausedEnrolments = enrolments.filter((e) => {
    return e.enrolmentStatus.toLowerCase() === ENROLMENT_STATUSES.PAUSED;
  });

  if (isEmpty(enrolmentsWithResumeSession)) {
    return pausedEnrolments;
  }

  return pausedEnrolments.map((pe) => {
    const enrolmentResumeSession = enrolmentsWithResumeSession.find((e) => {
      return pe.enrolmentId === e.enrolmentId;
    });

    pe.resumeSessionDate = get(enrolmentResumeSession, 'resumeSessionDate', null);
    return pe;
  });
};

export const getNextUnpaidSession = (state): Session | null => {
  const sessions = getUpcomingSessions(state);
  if (sessions.length === 0) {
      return null;
  }
  const unpaidSessions = sessions.filter((session) => session.paymentStatus !== PAYMENT_STATUS.PAID);
  return sortBy(unpaidSessions, 'startTime')[0];
};

export const getNextUpcomingSessionForEnrolment =
  ({ enrolmentId }) =>
  (state) => {
    const upcoming = getUpcomingSessions(state);
    const upcomingForEnrolment = upcoming.filter((session) => {
      return session.uniqueEnrolmentId === enrolmentId;
    });
    return upcomingForEnrolment[0];
  };

export const getUpcomingSessions = (state) => {
  const sessions = get(state, 'hubSessionsPage.upcomingSessions', []);
  if (sessions.length === 0) {
    return [];
  }

  const flaggedEnrolments = [];
  const filteredSessions = filter(sessions, (s) => {
    return moment(s.startTime).isSameOrAfter(new Date(), 'day');
  });
  return sortBy(filteredSessions, 'startTime').map((session) => {
    if (flaggedEnrolments.includes(session.uniqueEnrolmentId)) {
      session.isNextSessionOfEnrolement = false;
    } else {
      flaggedEnrolments.push(session.uniqueEnrolmentId);
      session.isNextSessionOfEnrolement = true;
    }
    return session;
  });
};

export const getUpcomingSessionsWithFailedPayment = (state) => {
  const sessions = getUpcomingSessions(state);
  return sessions.filter((session) => {
    return isPaymentFailed(session.paymentStatus);
  });
};

export const getEnrolmentsAsTextValuePair = (state) => {
  const enrolments = getActiveEnrolments(state);
  if (enrolments.length === 0) {
    return [];
  }

  return enrolments.map((enrolment) => {
    const { student, subject, enrolmentId, type } = enrolment;
    return {
      text: `${student}, ${subject}`,
      value: enrolmentId,
      isGroup: isGroupSessionCheck(type),
    };
  });
};

export const getStudentsAsTextValuePair = (state) => {
  const activeEnrolments = getActiveEnrolments(state);
  const pastEnrolments = getPastEnrolments(state);

  const enrolments = [...activeEnrolments, ...pastEnrolments];
  if (enrolments.length === 0) {
    return [];
  }

  const enrolmentsUniqByStudents = uniqBy(enrolments, (item) => item.student);
  enrolmentsUniqByStudents.push({
    student: A_NEW_STUDENT,
    enrolmentId: UNIQUE_ENROLMENT_ID_FOR_NEW_STUDENT,
  });

  return enrolmentsUniqByStudents.map((enrolment) => {
    const { student, lastname, enrolmentId } = enrolment;
    return {
      text: student,
      lastname,
      value: enrolmentId,
    };
  });
};

export const getOneEnrolmentIdForSF = (state) => {
  const activeEnrols = getActiveEnrolments(state);
  const pastEnrols = getPastEnrolments(state);

  if (activeEnrols.length !== 0) {
    return activeEnrols[0].enrolmentId;
  }

  if (pastEnrols.length !== 0) {
    return pastEnrols[0].enrolmentId;
  }

  return 'NoEnrolmentFound';
};

export const getSelectedEnrolment = (state) => {
  const enrolments = getActiveEnrolments(state);
  const selectedEnrolId = get(state, 'hubContactPage.selectedEnrolment', '');
  const resumeDates = get(state, 'hubEnrolmentsWithResumeSession', []);
  const timezone = get(state, 'hubUser.timezone', '');

  if (enrolments.length === 0 || selectedEnrolId === '') {
    return {};
  }

  const selectedEnrolment = enrolments.find((enrolment) => {
    return enrolment.enrolmentId === selectedEnrolId;
  });

  const { tutor, startTime, endTime } = selectedEnrolment;
  const { ...se } = selectedEnrolment;

  const nextSessionDate = moment(startTime).tz(timezone).format('dddd, MMMM D');
  const nextSessionStartTime = moment(startTime).tz(timezone).format(SESSION_TIME_FORMAT);
  const nextSessionEndTime = moment(endTime).tz(timezone).format(SESSION_TIME_FORMAT);

  se.nextSessionTutor = `Next session with ${tutor}`;
  se.nextSessionDetail = `${nextSessionDate} at ${nextSessionStartTime} - ${nextSessionEndTime}`;

  const { resumeSessionDate } = (resumeDates || []).find((e) => e.enrolmentId === selectedEnrolId) || {};
  se.resumeSessionDate = resumeSessionDate;
  se.timezone = timezone;

  return se;
};

export const getSelectedSession = (state) => {
  const selectedSession = get(state, 'hubContactPage.selectedSession', null);
  const timezone = get(state, 'hubUser.timezone', '');

  if (selectedSession === null) {
    return {};
  }

  const { uniqueEnrolmentId, student, subject, tutor, startTime, endTime, studentSessionId } = selectedSession;

  const sessionDate = moment(startTime).tz(timezone).format('dddd, MMMM D');
  const sessionStartTime = moment(startTime).tz(timezone).format(SESSION_TIME_FORMAT);
  const sessionEndTime = moment(endTime).tz(timezone).format(SESSION_TIME_FORMAT);

  const sessionInfo = `${sessionDate} at ${sessionStartTime} - ${sessionEndTime}`;
  return {
    uniqueEnrolmentId,
    studentSessionId,
    sessionInfo,
    student,
    subject,
    tutor,
  };
};

export const getSelectedStudent = (state) => {
  const activeEnrolments = getActiveEnrolments(state);
  const pastEnrolments = getPastEnrolments(state);
  const enrolments = [...activeEnrolments, ...pastEnrolments];

  const enrolmentId = get(state, 'hubContactPage.selectedStudent', '');

  if (enrolments.length === 0 || enrolmentId === '' || enrolmentId === UNIQUE_ENROLMENT_ID_FOR_NEW_STUDENT) {
    return {
      student: A_NEW_STUDENT,
      enrolmentId: UNIQUE_ENROLMENT_ID_FOR_NEW_STUDENT,
    };
  }

  return enrolments.find((enrolment) => enrolment.enrolmentId === enrolmentId);
};

export const isDataLoadedToInitApp = (state) => {
  const timezone = get(state, 'hubUser.timezone', '');
  const hasFetchedActiveEnrols = get(state, 'ui.apiState.hubActiveEnrolmentsPage.hasFetched');
  const hasFetchedPastEnrols = get(state, 'ui.apiState.hubPastEnrolmentsPage.hasFetched');
  const hasFetchedUpCSessions = get(state, 'ui.apiState.hubUpcomingSessionsPage.hasFetched');

  return !isEmpty(timezone) && hasFetchedActiveEnrols && hasFetchedPastEnrols && hasFetchedUpCSessions;
};

export const isLoadingUpcomingSessionsData = (state) => {
  const isUpcomingSessionsLoading = get(state, 'ui.apiState.hubUpcomingSessionsPage.isLoading', false);
  const areSchoolTermsPublicHolidaysLoading = get(state, 'ui.apiState.hubSchoolTermsPublicHolidays.isLoading', false);

  return isUpcomingSessionsLoading || areSchoolTermsPublicHolidaysLoading;
};

export const getSchoolTerms = (state) => {
  const data = get(state, 'hubSessionsPage.schoolTermsPublicHolidays', []);
  const schoolTerms = data.filter((h) => {
    const type = get(h, 'type');
    return type && type.toLowerCase() === SPECIAL_DATE_TYPE.SCHOOL_TERM;
  });

  if (schoolTerms.length > 0) {
    return schoolTerms
      .filter((term) => {
        return moment(term.endDate).isSameOrAfter(moment());
      })
      .map((st) => {
        return [
          {
            type: SPECIAL_DATE_TYPE.SCHOOL_TERM,
            startTime: st.startDate,
            state: st.state,
            schoolTerm: st.schoolTerm,
            isTermStartDate: true,
          },
          {
            type: SPECIAL_DATE_TYPE.SCHOOL_TERM,
            startTime: st.endDate,
            state: st.state,
            schoolTerm: st.schoolTerm,
            isTermStartDate: false,
          },
        ];
      })
      .flat();
  }

  return [];
};

export const getTerm123PauseSessionsFormData = (state): {
    endDate?: string;
    nextTermStartDate?: string;
    schoolTerm?: string;
    startDate?: string;
    state?: string;
    type?: string;
} => {
    const data = get(state, 'hubSessionsPage.schoolTermsPublicHolidays', []);
    let result = {};
    if (isEmpty(data)) {
        return result;
    }

    const schoolTerms = data.filter((h) => {
        const type = get(h, 'type');
        return (type || '').toLowerCase() === SPECIAL_DATE_TYPE.SCHOOL_TERM;
    });

    if (schoolTerms.length > 0) {
        const schoolTermsSortByStartDate = sortBy(schoolTerms, 'startDate');

        for (let i = 1; i < schoolTermsSortByStartDate.length; i++) {
            const termAStartDate = schoolTermsSortByStartDate[i - 1].startDate;
            const termBStartDate = schoolTermsSortByStartDate[i].startDate;
            if (moment().isSameOrAfter(termAStartDate) && moment().isBefore(termBStartDate)) {
                result = {
                    ...schoolTermsSortByStartDate[i - 1],
                    nextTermStartDate: schoolTermsSortByStartDate[i].startDate,
                };
                break;
            }
        }
    }
    return result;
};

export const getSchoolTermHolidayPauseEnabled = (state): boolean => {
  const term = getTerm123PauseSessionsFormData(state);
  const {
    startDate: termStartDate,
    nextTermStartDate,
  } = term;

  return (
    moment().isSameOrAfter(moment(termStartDate), 'day') &&
    moment().isBefore(moment(nextTermStartDate), 'day') &&
    moment().isSameOrAfter(moment(nextTermStartDate).subtract(6, 'weeks'))
  )
}

export const getOneTerm = (state, termToSearch, year): SchoolTerm => {
  let result = {
    endDate: null,
    schoolTerm: null,
    startDate: null,
    state: null,
    type: null,
  };
  const data = get(state, 'hubSessionsPage.schoolTermsPublicHolidays', []);
  if (isEmpty(data) || !termToSearch) {
    return result;
  }

  const schoolTerms = data.filter((h) => {
    const type = get(h, 'type');
    return (type || '').toLowerCase() === SPECIAL_DATE_TYPE.SCHOOL_TERM;
  });

  if (schoolTerms.length > 0) {
    result = schoolTerms.find((term) => {
      const { schoolTerm, startDate } = term;
      return (
        (schoolTerm || '').toLowerCase() === termToSearch.toLowerCase() &&
        moment(startDate).isSame(moment(year, DATE_FORMAT_YEAR), 'year')
      );
    });
  }
  return result;
};

export const getHolidays = (state) => {
  const data = get(state, 'hubSessionsPage.schoolTermsPublicHolidays', []);
  const isNZUser = getIsNZuser(state);
  return data.filter((h) => {
    const { type = '', state = '', startDate } = h;
    if (moment(startDate).isBefore(moment(), 'day')) {
      return false;
    }
    return isNZUser ? isAHoliday(type) && state.toLowerCase() === 'nz' : isAHoliday(type);
  });
};

// selector for enrolment detail page
export const isLoadingEnrolmentDetails = (state) => get(state, 'ui.apiState.hubEnrolmentDetailPage.isLoading');
export const isGroupEnrolment = (state) => {
  const enrolmentType = get(state, 'hubEnrolmentDetailPage.enrolmentType', '');
  return !!enrolmentType && isGroupSessionCheck(enrolmentType);
};

export const isEnrolmentCancelled = (state) => {
  const enrolmentStatus = get(state, 'hubEnrolmentDetailPage.enrolmentStatus', '');
  return !!enrolmentStatus && enrolmentStatus.toLowerCase() === ENROLMENT_STATUSES.CANCELLED;
};

export const isEnrolmentCancelRequested = (state) => {
  const enrolmentStatus = get(state, 'hubEnrolmentDetailPage.enrolmentStatus', '');
  return !!enrolmentStatus && enrolmentStatus.toLowerCase() === ENROLMENT_STATUSES.CANCELLED_REQUESTED;
};

export const isEnrolmentOutsidePolicyPauseRequested = (state) => {
  const enrolmentStatus = get(state, 'hubEnrolmentDetailPage.enrolmentStatus', '');
  const cancelReason = get(state, 'hubEnrolmentDetailPage.cancelReason', '');
  return isEnrolmentOutsidePolicyPauseRequestedCheck({
    enrolmentStatus,
    cancelReason,
  });
};

export const isEnrolmentPaused = (state) => {
  const enrolmentStatus = get(state, 'hubEnrolmentDetailPage.enrolmentStatus', '');
  return isEnrolmentPausedCheck({ enrolmentStatus });
};

export const isEnrolmentPending = (state) => {
  const enrolmentStatus = get(state, 'hubEnrolmentDetailPage.enrolmentStatus', '');
  return !!enrolmentStatus && enrolmentStatus.toLowerCase() === ENROLMENT_STATUSES.PENDING;
};

export const isEnrolmentActive = (state) => {
  const enrolmentStatus = get(state, 'hubEnrolmentDetailPage.enrolmentStatus', '');
  return isEnrolmentActiveCheck({ enrolmentStatus });
};

export const isEnrolmentFinished = (state) => {
  const enrolmentStatus = get(state, 'hubEnrolmentDetailPage.enrolmentStatus', '');
  return !!enrolmentStatus && enrolmentStatus.toLowerCase() === ENROLMENT_STATUSES.FINISHED;
};

export const getSessionReportId = (state, uniqueEnrolmentId): string => {
  const enrolmentId = uniqueEnrolmentId || get(state, 'hubEnrolmentDetailPage.enrolmentId', '');
  const activeEnrolments = getActiveEnrolments(state);
  const pastEnrolments = getPastEnrolments(state);
  const enrolments = [...activeEnrolments, ...pastEnrolments];

  if (isEmpty(enrolments) || isEmpty(enrolmentId)) {
    return '';
  }

  const selectedEnrol = enrolments.find((enrolment) => enrolment.enrolmentId === enrolmentId);
  return selectedEnrol ? selectedEnrol.sessionReportId : '';
};

export const haveTutorAvailabilitiesLoaded = (state) => {
  const tutorAvailabilities = get(state, 'hubChangeSchedulePage.tutorAvailabilities');
  return !isEmpty(tutorAvailabilities);
};

export const getSelectedEnrolmentToManage = (state) => {
  const enrolments = getActiveEnrolments(state);
  const selectedEnrolId = get(state, 'hubEnrolmentDetailPage.enrolmentId', '');

  if (enrolments.length === 0 || selectedEnrolId === '') {
    return null;
  }

  const selectedEnrolment = enrolments.find((enrolment) => {
    return enrolment.enrolmentId === selectedEnrolId;
  });

  return selectedEnrolment;
};

export const getUnitCompletion = (state) => {
  const yearLevel = get(state, 'hubEnrolmentDetailPage.enrolmentYearLevel');
  const noCommencedMsg = `No ${yearLevel} units have commenced`;
  const modulesCommencedCoverage = get(
    state,
    'hubEnrolmentDetailPage.progressSummary.cumulative.modulesCommencedCoverage',
    0
  );

  return modulesCommencedCoverage && modulesCommencedCoverage > 0
    ? `${modulesCommencedCoverage}% of commenced`
    : noCommencedMsg;
};

export const getPracticeProgress = (state) => {
  const yearLevel = get(state, 'hubEnrolmentDetailPage.enrolmentYearLevel');
  const summary = get(state, 'hubEnrolmentDetailPage.progressSummary.summary', {});
  const { totalQuestionsAssigned = 0, totalQuestionsSubmitted = 0 } = summary;

  if (totalQuestionsAssigned && totalQuestionsAssigned > 0) {
    return totalQuestionsSubmitted && totalQuestionsSubmitted > 0
      ? `${totalQuestionsSubmitted} out of ${totalQuestionsAssigned}`
      : `No ${yearLevel} practice has been completed`;
  }
  return `No ${yearLevel} practice has been assigned`;
};

const getTermStartDate = ({ schoolTerms, term }) => {
  const termStartDates = schoolTerms
    .filter((item) => {
      return (item.schoolTerm || '').toLowerCase() === term;
    })
    .map((st) => {
      return [
        {
          startTime: st.startDate,
          schoolTerm: st.schoolTerm,
          isTermStartDate: true,
        },
        {
          startTime: st.endDate,
          schoolTerm: st.schoolTerm,
          isTermStartDate: false,
        },
      ];
    })
    .flat()
    .filter((item) => {
      return item.isTermStartDate;
    });

  const sortedDates = sortBy(termStartDates, 'startTime');
  return sortedDates[sortedDates.length - 1];
};

export const isTerm4Check = (state) => {
  const data = get(state, 'hubSessionsPage.schoolTermsPublicHolidays', []);
  const schoolTerms = data.filter((h) => {
    const type = get(h, 'type');
    return type && type.toLowerCase() === SPECIAL_DATE_TYPE.SCHOOL_TERM;
  });

  if (schoolTerms.length > 0) {
    const lastTerm1StartDate = getTermStartDate({
      schoolTerms,
      term: 'term 1',
    });
    const lastTerm4StartDate = getTermStartDate({
      schoolTerms,
      term: 'term 4',
    });

    if (
      lastTerm4StartDate &&
      lastTerm4StartDate.startTime &&
      moment().isSameOrAfter(moment(lastTerm4StartDate.startTime)) &&
      lastTerm1StartDate &&
      lastTerm1StartDate.startTime &&
      moment().isBefore(moment(lastTerm1StartDate.startTime))
    ) {
      return true;
    }
  }

  return false;
};

export const getAccountDetails = (state) => {
  return state?.hubAccountPage?.accountDetails || {};
};
