import { isEmpty } from 'lodash';
import * as reqTypes from './endpointTypes';
import env from '../../env';

const METHOD_GET = 'GET';
const METHOD_POST = 'POST';
const getCustomerHubPath = (partial) => {
  return `customer-hub/${partial}`;
};

const getKPPath = (partial) => {
  return `kp/${partial}`;
};

const getHubReportPath = (partial) => {
  return `hub-session-report/${partial}`;
};
/**
 * Hash of apiOptions object:
 * {
 *      host: string                Host of the request
 *      path: string                Path of the request. Include placeholders.
 *      preprocessOptions?: Function Function to preprocess options based on requestPayload.
 *                                  Signature: (apiOptions: Object, { requestPayload }: Object) => newApiOptions: Object
 *      ?method: GET|POST|PUT...    Method of the request. Default is GET
 *      ?logContext: string         Context for logging different error scenarios
 *      ?signRequest: boolean       Should the request be signed with AWS credentials? Default: true
 *      ?validateResponseStatus: ({ ok: boolean, status: number }) => boolean.
 *                                  Optional validation for the response status. Default checks the ok boolean
 *      ?isDefinitiveFail:          ({ ok: boolean, status: number }) => boolean.
 *                                  Optional check after validateResponseStatus fails. If used, the Promise will faile and
 *                                  should not be retried
 *      ?validateResponse: (json: Object) => boolean
 *                                  Optional validator for the JSON response
 * }
 */
export default {
  [reqTypes.REQ_GET_LOGIN]: {
    host: env.REACT_APP_API_LOGIN_HOST_NAME,
    path: 'login/[TOKEN]',
    method: METHOD_GET,
    logContext: 'Login API',
    signRequest: false,
  },
  [reqTypes.REQ_GET_STUDENT_PROGRESS_SUMMARY]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getKPPath('progress/summary/[STUDENT_ID]/[DISCIPLINE]/[YEAR_LEVEL]/[COURSE_MODE]'),
    method: METHOD_GET,
    region: 'ap-southeast-2',
    logContext: 'Get student progress summary API',
    validateResponseStatus: ({ ok, status }) => {
      return ok || status === 404;
    },
    signRequest: true,
  },
  [reqTypes.REQ_GET_STUDENT_PROGRESS_MODULE_DETAIL]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getKPPath('progress/summary/module/[STUDENT_ID]/[DISCIPLINE]/[YEAR_LEVEL]/[COURSE_MODE]/[MODULE_ID]'),
    method: METHOD_GET,
    logContext: 'Get selected module detail API',
    signRequest: true,
  },
  [reqTypes.REQ_GET_SUMMARY]: {
    host: env.REACT_APP_SESSION_REPORT_HOST_NAME,
    path: getHubReportPath('summary/[PKG_CODE]/[TOKEN]'),
    method: METHOD_GET,
    logContext: 'Summary GET api',
  },
  [reqTypes.REQ_GET_ACTIVITIES]: {
    host: env.REACT_APP_SESSION_REPORT_HOST_NAME,
    path: getHubReportPath('activities/[PKG_CODE]/[TOKEN]/[YEAR_LEVEL]/[DISCIPLINE]/[COURSE_MODE]?page=[ACTIVITY_FETCH_NEXT_PAGE]'),
    method: METHOD_GET,
    logContext: 'Activities GET api',
    validateResponseStatus: ({ ok, status }) => {
      return ok || status === 403;
    },
  },
  [reqTypes.REQ_GET_UPCOMING_SESSIONS]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('household/[UPCOMING_SESSION_TOKEN]'),
    method: METHOD_GET,
    logContext: 'Upcoming Sessions GET api',
    signRequest: false,
    validateResponseStatus: ({ ok, status }) => {
      return ok || status === 404;
    },
  },
  [reqTypes.REQ_GET_UPCOMING_SESSIONS_TOKEN]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('reporting/parentlink/[TOKEN]'),
    method: METHOD_GET,
    logContext: 'GET Upcoming Sessions token api',
    signRequest: false,
    validateResponseStatus: ({ ok, status }) => {
      return ok || status === 404;
    },
  },
  [reqTypes.REQ_GET_OUTCOME_NEXT_YEAR]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('reporting/[STUDENT_ID]/[SUBJECT]'),
    method: METHOD_GET,
    logContext: 'GET outcome next year',
    signRequest: false,
  },
  [reqTypes.REQ_GET_DISCIPLINE_LIST]: {
    host: env.REACT_APP_SESSION_REPORT_HOST_NAME,
    path: getHubReportPath('discipline/[PKG_CODE]/[TOKEN]'),
    method: METHOD_GET,
    logContext: 'GET - get discipline list',
    signRequest: true,
    validateResponseStatus: ({ ok, status }) => {
      return ok || status === 404;
    },
    isDefinitiveFail: () => true,
  },
  [reqTypes.REQ_POST_CREATE_NEW_CASE]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('upcomingsession/contact'),
    method: METHOD_POST,
    logContext: 'POST contact us message to create a new case in SF',
    signRequest: false,
  },
  [reqTypes.REQ_POST_TO_CONTACT]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('contactus'),
    method: METHOD_POST,
    logContext: 'POST to heroku ESSL',
    signRequest: false,
  },
  [reqTypes.REQ_POST_CUSTOMER_TRIGGER_PAYMENT]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('upcomingsession/paynow'),
    method: METHOD_POST,
    logContext: 'POST customer trigger payment - set flag in SF',
    signRequest: false,
  },
  [reqTypes.REQ_POST_RESCHEDULE_SESSION]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('reschedule/'),
    method: METHOD_POST,
    logContext: 'POST to reschedule a session',
    signRequest: false,
  },
  [reqTypes.REQ_POST_CANCEL_SESSION]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('reschedule/'),
    method: METHOD_POST,
    logContext: 'POST to cancel a session',
    signRequest: false,
  },
  [reqTypes.REQ_POST_FETCH_REFUND_POLICY]: {
    host: env.REACT_APP_API_SF_HOST_NAME,
    path: 'Customer/services/apexrest/checksessionpolicy',
    method: METHOD_POST,
    logContext: 'POST to get refund policy for a session',
    signRequest: false,
  },
  [reqTypes.REQ_GET_HUB_UPCOMING_SESSIONS]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('sessions?filter=upcoming'),
    method: METHOD_GET,
    logContext: 'GET sessions filtered by upcoming',
    signRequest: true,
  },
  [reqTypes.REQ_GET_HUB_COMPLETED_SESSIONS]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('sessions?filter=completed'),
    method: METHOD_GET,
    logContext: 'GET sessions filtered by completed',
    signRequest: true,
  },
  [reqTypes.REQ_GET_HUB_SCHOOL_TERMS_PUBLIC_HOLIDAYS]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('account/holidays'),
    method: METHOD_GET,
    logContext: 'GET school terms and public holidays',
    signRequest: true,
  },
  [reqTypes.REQ_GET_HUB_ACTIVE_ENROLMENTS]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('enrolments?filter=active'),
    method: METHOD_GET,
    logContext: 'GET all active enrolments',
    signRequest: true,
  },
  [reqTypes.REQ_GET_HUB_PAST_ENROLMENTS]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('enrolments?filter=past'),
    method: METHOD_GET,
    logContext: 'GET all past enrolments',
    signRequest: true,
  },
  [reqTypes.REQ_GET_HUB_ENROLMENT_DETAIL]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('enrolment/[ENROLMENT_ID]'),
    method: METHOD_GET,
    logContext: 'GET enrolment detail by id',
    signRequest: true,
  },
  [reqTypes.REQ_GET_TUTOR_INFO]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('tutorinfo/[TUTOR_ID]'),
    method: METHOD_GET,
    logContext: 'GET tutor info by tutor id',
    signRequest: false,
    preprocessOptions: (apiOptions, { requestPayload }) => {
      if (isEmpty(requestPayload)) {
        return apiOptions;
      }
      return {
        ...apiOptions,
        path: apiOptions.path.replace('[TUTOR_ID]', encodeURI(requestPayload.tutorNumber)),
      };
    },
  },
  [reqTypes.REQ_GET_SELECTED_TUTOR_INFO]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('tutorinfo/[SELECTED_TUTOR_ID]'),
    method: METHOD_GET,
    logContext: 'GET tutor info by selected tutor id',
    signRequest: false,
  },
  [reqTypes.REQ_POST_HUB_NEW_SCHEDULE]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('contact'),
    method: METHOD_POST,
    logContext: 'POST save new schedule',
    signRequest: true,
  },
  [reqTypes.REQ_GET_HUB_ACCOUNT_PRICING_PLAN]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('pricingPlan/account'),
    method: METHOD_GET,
    logContext: 'GET account details',
    signRequest: true,
  },
  [reqTypes.REQ_GET_HUB_ACCOUNT_DETAILS]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('account/details'),
    method: METHOD_GET,
    logContext: 'GET account details',
    signRequest: true,
  },
  [reqTypes.REQ_PUT_HUB_ACCOUNT_DETAILS]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('account/details'),
    method: 'PUT',
    logContext: 'PUT account details',
    signRequest: true,
    bubbleOriginalMessage: true,
  },
  [reqTypes.REQ_GET_HUB_BILLING_INFORMATION]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('account/billing'),
    method: METHOD_GET,
    logContext: 'GET payment schedule',
    signRequest: true,
  },
  [reqTypes.REQ_POST_HUB_SF_CHARGENT_ORDER]: {
    host: env.REACT_APP_API_SF_HOST_NAME,
    path: 'Customer/services/apexrest/chargentorder',
    method: METHOD_POST,
    logContext: 'POST chargent order',
    signRequest: false,
  },
  [reqTypes.REQ_POST_HUB_SF_PROCESS_PAYMENT]: {
    host: env.REACT_APP_API_SF_HOST_NAME,
    path: 'Customer/services/apexrest/custcardservice',
    method: METHOD_POST,
    logContext: 'POST process payment',
    signRequest: false,
  },
  [reqTypes.REQ_GET_HUB_INVOICE_HISTORY]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('account/invoices'),
    method: METHOD_GET,
    logContext: 'GET invoice history',
    signRequest: true,
  },
  [reqTypes.REQ_POST_HUB_CREATE_NEW_CASE_SF]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('contact'),
    method: METHOD_POST,
    logContext: 'POST to create a new case in SF',
    signRequest: true,
  },
  [reqTypes.REQ_POST_HUB_RESEND_SIGNUP_LINK]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('customer/resend'),
    method: METHOD_POST,
    logContext: 'POST - resend user signup link request',
    signRequest: false,
  },
  [reqTypes.REQ_POST_HUB_REQUEST_TO_RESET_PASSWORD]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('customer/reset'),
    method: METHOD_POST,
    logContext: 'POST - request to reset password',
    signRequest: false,
  },
  [reqTypes.REQ_GET_PAST_TUTOR]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('change/tutor/enrolment/[ENROLMENT_ID]'),
    method: METHOD_GET,
    logContext: 'GET past tutor of one enrolment',
    signRequest: true,
    validateResponseStatus: ({ ok, status }) => {
      return ok || status === 404;
    },
  },
  [reqTypes.REQ_GET_TUTOR_AVAILABILITY]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('change/tutor/schedule/[TUTOR_ID]'),
    method: METHOD_GET,
    logContext: 'GET schedule of a tutor',
    signRequest: true,
    preprocessOptions: (apiOptions, { requestPayload }) => {
      if (isEmpty(requestPayload)) {
        return apiOptions;
      }
      const { tutorNumber, numberOfDays = 0 } = requestPayload;
      let newPath = apiOptions.path;
      newPath = newPath.replace('[TUTOR_ID]', tutorNumber);
      newPath = numberOfDays > 0 ? `${newPath}?numberOfDays=${numberOfDays}` : newPath;
      return {
        ...apiOptions,
        path: encodeURI(newPath),
      };
    },
  },
  [reqTypes.REQ_GET_HUB_PAUSE_SESSIONS_RESUME_SESSION]: {
    host: env.REACT_APP_API_SF_HOST_NAME,
    path: 'Customer/services/apexrest/getSessionStartDate?enrolid=[ENROLMENT_TO_PAUSE]&pausetodate=[PAUSE_TO_DATE]',
    method: METHOD_GET,
    logContext: 'GET resume session after paused period',
    signRequest: false,
    preprocessOptions: (apiOptions, { requestPayload }) => {
      if (isEmpty(requestPayload)) {
        return apiOptions;
      }
      return {
        ...apiOptions,
        path: apiOptions.path
          .replace('[ENROLMENT_TO_PAUSE]', encodeURI(requestPayload.enrolmentId))
          .replace('[PAUSE_TO_DATE]', encodeURI(requestPayload.pauseTo)),
      };
    },
  },
  [reqTypes.REQ_GET_GROUP_PACKAGES]: {
    host: env.REACT_APP_API_ENROLMENT_SF_HOST_NAME,
    path: 'enrolment/services/apexrest/grouppackage/?yearLevel=[YEAR_LEVEL]&curriculum=[CURRICULUM]&discipline=[DISCIPLINE]&includeTestPrep=[TEST_PREP]',
    method: METHOD_GET,
    logContext: 'GET group enrolment packages',
    signRequest: false,
    preprocessOptions: (apiOptions, { requestPayload }) => {
      if (isEmpty(requestPayload)) {
        return apiOptions;
      }
      return {
        ...apiOptions,
        path: apiOptions.path
          .replace('[YEAR_LEVEL]', encodeURI(requestPayload.yearlevel))
          .replace('[CURRICULUM]', encodeURI(requestPayload.curriculum))
          .replace('[DISCIPLINE]', encodeURI(requestPayload.discipline))
          .replace('[TEST_PREP]', 'false'),
      };
    },
  },
  [reqTypes.REQ_GET_SUMMER_MODE_DATA]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('summerdata/'),
    method: METHOD_GET,
    logContext: 'GET summer mode data with customer link id',
    signRequest: false,
    preprocessOptions: (apiOptions, { requestPayload }) => {
      return {
        ...apiOptions,
        path: `${apiOptions.path}${requestPayload.customerLinkId}`,
      };
    },
  },
  [reqTypes.REQ_FETCH_ZUORA_PARAMS]: {
    host: env.REACT_APP_CLUEY_API_HOST_NAME,
    path: getCustomerHubPath('zuora/[PAGE_ID]'),
    method: METHOD_GET,
    logContext: 'Post to get zuora params',
    signRequest: true,
    preprocessOptions: (apiOptions, { requestPayload }) => {
      return {
        ...apiOptions,
        path: apiOptions.path.replace('[PAGE_ID]', encodeURI(requestPayload.pageId)),
      };
    },
  },
};
