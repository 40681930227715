import React from 'react';

import { Maintenance } from '../../common/Maintenance';
import HomeSessions from '../../components/hub/home/HomeSessions/HomeSessions';
import HubHeader from '../../common/hub/HubHeader';
import HubFooter from '../../common/hub/HubFooter/HubFooter';

export const HubMaintenancePage = () => {
  return (
    <div className="hub flex flex-col">
      <HubHeader hideContactUs />
      <div className="body-container">
        <div className="container mx-auto mt-7 px-0 lg:mt-10  lg:pt-7">
          <div className="w-full lg:mx-auto lg:w-8/12">
            <Maintenance showBottomSeparator />
            <HomeSessions />
          </div>
        </div>
      </div>
      <HubFooter hideContactUs />
    </div>
  );
};
