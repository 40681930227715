import { get, isEmpty, sortBy } from 'lodash';
import moment from 'moment';
import Cookie from 'js-cookie';
import { COOKIE_SAVE_ENROLMENT, SPECIAL_DATE_TYPE } from '../util/constants';
import {
  isEnrolmentCancelRequested,
  isEnrolmentPausedCheck,
  isGroupSessionCheck,
  isSummerModeOn,
} from '../util/helpers';
import {
  INCENTIVE_JANUARY_RESTARTER_PACK,
  INCENTIVE_FEBRUARY_RESTARTER_PACK,
  SUMMER_15PCT_ALL_SESSIONS,
} from '../util/constants/incentives';
import {
  completedSummerModeCopy,
  isIncentiveActive,
  isIncentiveValid,
  restarterIncentiveCopy,
} from '../util/summerCopy';

export const DISCOUNT_PROMO_MAP = {
  PROMO_DISCOUNTED_CODECAMP: '$100 OFF',
  PROMO_DISCOUNTED_CODECAMP_50: '$50 OFF',
  INCENTIVE_INCREASE_FREQUENCY_DISCOUNT: '30%',
  INCENTIVE_MAINTAIN_FREQUENCY_DISCOUNT: '15%',
  MAINTAIN_HIGH_FREQUENCY_DISCOUNT: '30%',
  INCENTIVE_JANUARY_RESTARTER_PACK: '20%',
  INCENTIVE_INCREASE_FREQUENCY_20PCT_DISCOUNT: '20%',
  SUMMER_15PCT_ALL_SESSIONS: '15%',
};

/**
 * Get discount with given incentive code
 * @param  {Object | null} appliedIncentiveCodes
 * @param  {Array | null} searchFor
 * @return {String}
 */
export const getDiscountText = ({ appliedIncentiveCodes, searchFor }) => {
  if (!appliedIncentiveCodes) return '';
  if (typeof searchFor === 'string') {
    return appliedIncentiveCodes.includes(searchFor) ? DISCOUNT_PROMO_MAP[searchFor] : '';
  }
  let discount = '';
  searchFor.every((searchItem) => {
    if ((appliedIncentiveCodes || []).includes(searchItem)) {
      discount = DISCOUNT_PROMO_MAP[searchItem];
      return false;
    }
    return true;
  });
  return discount;
};

export const getEnrolmentSummerModeDetailById = (state, enrolmentId) => {
  const enrolments = get(state, 'summerMode.enrolments', []);
  if (isEmpty(enrolments)) {
    return {};
  }

  const currentEnrolment = enrolments.find((enrolment) => enrolment.uniqueEnrolmentId === enrolmentId);

  return isEmpty(currentEnrolment) ? {} : currentEnrolment;
};

export const isJanDiscountApplicable = (state) => {
  const summerIncentivesAndPromotions = get(state, 'summerMode.summerIncentivesAndPromotions', []);
  return (summerIncentivesAndPromotions || []).includes('INCENTIVE_JANUARY_RESTARTER_PACK');
};

export const showHolidayPracticeOptOutCheck = (state, enrolmentId) => {
  const currentEnrolment = getEnrolmentSummerModeDetailById(state, enrolmentId);
  const { isHolidayPracticeApplicable = false } = currentEnrolment;

  return isHolidayPracticeApplicable;
};

export const getCodeCampIncentive = (state) => {
  const summerIncentivesAndPromotions = get(state, 'summerMode.summerIncentivesAndPromotions', []);
  return getDiscountText({
    appliedIncentiveCodes: summerIncentivesAndPromotions,
    searchFor: ['PROMO_DISCOUNTED_CODECAMP_50', 'PROMO_DISCOUNTED_CODECAMP'],
  });
};

/* @TODO investigate */
export const showSummerModeBannerOrPromo = (state) => {
  const displayOffboardingJourneyForHousehold = get(state, 'summerMode.displayOffboardingJourneyForHousehold', false);
  return displayOffboardingJourneyForHousehold && isSummerModeOn();
};

export const getSummerDiscount = (state) => {
  const summerIncentivesAndPromotions = get(state, 'summerMode.summerIncentivesAndPromotions', []);
  return getDiscountText({
    appliedIncentiveCodes: summerIncentivesAndPromotions,
    searchFor: ['SUMMER_15PCT_ALL_SESSIONS'],
  });
};

export const getSummerModeCompleted = (state, isDetailPage = false) => {
  const enrolments = get(state, 'summerMode.enrolments', []);
  let completed = [];

  if (isEmpty(enrolments)) {
    return completed;
  }

  enrolments.forEach((enrolment) => {
    if (enrolment.offboardingJourneySubmitted && !enrolment.summerModeProcessed) {
      const completedEnrolment = completedSummerModeCopy(enrolment, isDetailPage);
      if (completedEnrolment) {
        completed = [...completed, { ...completedEnrolment, type: SPECIAL_DATE_TYPE.SUMMER_ALERT }];
      }
    }
  });
  return completed;
};

export const getSummerModeCompletedEnrolmentById = (state, enrolmentId) => {
  const completedEnrolments = getSummerModeCompleted(state, true);
  if (isEmpty(completedEnrolments)) {
    return {};
  }

  const completedEnrolment = completedEnrolments.find((enrolment) => enrolment.id === enrolmentId);
  return isEmpty(completedEnrolment) ? {} : completedEnrolment;
};

export const getNextYearEnrolments = (state) => {
  /*
    this will return summer mode enrolments that are of type "Group" and have their `summerModeAdditionalDetails`
    set to the next year; e.g. the date now is 2022-12-30 and the enrolment's`summerModeAdditionalDetails` property is set to `2023-1-10`
    this property can be access in Sales Force under `Summer Mode Details`; the format is YYYY-MM-DD
  */
  const summerModeEnrolments = get(state, 'summerMode.enrolments', false);
  /*
    summerMode will return an empty object in unauthenticated pages initially;
    hence, `summerMode.enrolments` will return undefined;
    return an empty array lest invoking a function on an undefined entity
  */
  if (!summerModeEnrolments) {
    return [];
  }
  const nextYearEnrolments = summerModeEnrolments.filter((enrolment) => {
    const groupStartDate = moment(enrolment.summerModeAdditionalDetails);
    if (!groupStartDate.isValid()) {
      return false;
    }
    return groupStartDate.year() === moment().add(1, 'year').year() && isGroupSessionCheck(enrolment.type);
  });
  /* sort by earliest date in ascending order */
  return sortBy(nextYearEnrolments, 'summerModeAdditionalDetails');
};

const showPreWizardOnDetail = (state, enrolmentId) => {
  const currentEnrolment = getEnrolmentSummerModeDetailById(state, enrolmentId);
  const { enrolmentEnabledForOffboardingJourney = false } = currentEnrolment;
  return enrolmentEnabledForOffboardingJourney && isSummerModeOn();
};

/* @TODO delete */
export const shouldShowSummerModeEnrolmentStatus = (state, enrolmentId) => {
  const completedEnrolment = getSummerModeCompletedEnrolmentById(state, enrolmentId);
  /*
    an enrolment will only be considered completed if its Sales force `Offboarding Journey Submitted` data field under
    Summer Mode Details contains a valid date and time

    isEmpty(completedEnrolment) evaluates to true if completedEnrolment is an empty object;
    that is, it hasn't gone through the offboarding process

    if the enrolment hasn't gone through the offboarding process,
    and the enrolment is neither cancelled nor paused, show the redirect to wizard banner
  */
  const enrolmentStatus = String(get(state, 'hubEnrolmentDetailPage.enrolmentStatus'));
    console.log(enrolmentStatus)
  if (isEnrolmentCancelRequested({ enrolmentStatus }) || isEnrolmentPausedCheck({ enrolmentStatus })) {
    return false;
  }

  const isThisEnrolmentCompleted = !isEmpty(completedEnrolment);
  const isNextYearEnrolment = getNextYearEnrolments(state).find((en) => en.uniqueEnrolmentId === enrolmentId);
  if (isNextYearEnrolment && isThisEnrolmentCompleted) {
    return true;
  }
  return (showPreWizardOnDetail(state, enrolmentId) || !isThisEnrolmentCompleted) && isSummerModeOn();
};

export const shouldShowReviewEnrolmentBanner = (state) => {
  const enrolments = get(state, 'summerMode.enrolments', []);
  if (isEmpty(enrolments)) {
    return false;
  }

  const requestedPauseDuringOffboarding = enrolments.find(
    (enrolment) => enrolment.offboardingJourneySubmitted && enrolment.selectedSummerMode === 'Pause'
  );

  if (!isEmpty(requestedPauseDuringOffboarding)) {
    return moment().isSameOrAfter(moment('2022-01-01'), 'day') && moment().isBefore(moment('2022-02-01'), 'day');
  }

  const offboardingJourneySubmitted = enrolments.find((enrolment) => enrolment.offboardingJourneySubmitted);

  if (!isEmpty(offboardingJourneySubmitted)) {
    return moment().isSameOrAfter(moment('2022-01-15'), 'day') && moment().isBefore(moment('2022-02-01'), 'day');
  }

  return false;
};

export const shouldShowHolidayLearningBanner = (state, uniqueEnrolmentId) => {
  const enrolments = get(state, 'summerMode.enrolments', []);

  if (isEmpty(enrolments)) {
    return false;
  }

  const enrolment = enrolments.find((o) => o.uniqueEnrolmentId === uniqueEnrolmentId);

  if (!enrolment) {
    return false;
  }

  return !!enrolment.offboardingJourneySubmitted;
};

export const getSummerPromo = (state, options = {}) => {
  const summerIncentivesAndPromotions = get(state, 'summerMode.summerIncentivesAndPromotions', []);
  const { confirming = false } = options;
  if (
    summerIncentivesAndPromotions.includes(INCENTIVE_JANUARY_RESTARTER_PACK) &&
    isIncentiveActive(INCENTIVE_JANUARY_RESTARTER_PACK)
  ) {
    return {
      promoText: restarterIncentiveCopy(INCENTIVE_JANUARY_RESTARTER_PACK, confirming),
      incentiveCode: INCENTIVE_JANUARY_RESTARTER_PACK,
    };
  }

  if (
    summerIncentivesAndPromotions.includes(INCENTIVE_FEBRUARY_RESTARTER_PACK) &&
    isIncentiveActive(INCENTIVE_FEBRUARY_RESTARTER_PACK)
  ) {
    return {
      promoText: restarterIncentiveCopy(INCENTIVE_FEBRUARY_RESTARTER_PACK, confirming),
      incentiveCode: INCENTIVE_FEBRUARY_RESTARTER_PACK,
    };
  }

  if (
    summerIncentivesAndPromotions.includes(SUMMER_15PCT_ALL_SESSIONS) &&
    isIncentiveValid(SUMMER_15PCT_ALL_SESSIONS, moment().toISOString(), options)
  ) {
    return {
      promoText: restarterIncentiveCopy(SUMMER_15PCT_ALL_SESSIONS, confirming),
      incentiveCode: SUMMER_15PCT_ALL_SESSIONS,
    };
  }

  return { promoText: '', incentiveCode: '' };
};

export const hasIncentiveFebruaryRestartedPack = (state) => {
  const summerIncentivesAndPromotions = get(state, 'summerMode.summerIncentivesAndPromotions', []);

  return (
    summerIncentivesAndPromotions.includes(INCENTIVE_FEBRUARY_RESTARTER_PACK) &&
    isIncentiveActive(INCENTIVE_FEBRUARY_RESTARTER_PACK)
  );
};

export const getIncentiveForManagement = (state, enrolmentId) => {
  const summerModeIncentives = get(state, 'summerMode.summerIncentivesAndPromotions', []);
  const saveEnrolments = JSON.parse(Cookie.get(COOKIE_SAVE_ENROLMENT) || '[]');
  if (
    summerModeIncentives.includes(INCENTIVE_JANUARY_RESTARTER_PACK) &&
    isIncentiveActive(INCENTIVE_JANUARY_RESTARTER_PACK)
  ) {
    return INCENTIVE_JANUARY_RESTARTER_PACK;
  }
  if (Array.isArray(saveEnrolments) && saveEnrolments.includes(enrolmentId)) {
    if (
      summerModeIncentives.includes(INCENTIVE_FEBRUARY_RESTARTER_PACK) &&
      isIncentiveActive(INCENTIVE_FEBRUARY_RESTARTER_PACK)
    ) {
      return INCENTIVE_FEBRUARY_RESTARTER_PACK;
    }
  }

  return '';
};

export const getEnrolmentsWithSummerIncentiveApplied = (state) => {
  const summerModeIncentives = get(state, 'summerMode.summerIncentivesAndPromotions', []);
  const enrolments = get(state, 'summerMode.enrolments', []);

  const enrolmentsWithSummerIncentiveApplied = enrolments.filter((enrolment) =>
    summerModeIncentives.includes(enrolment.appliedSummerModeIncentive)
  );
  return enrolmentsWithSummerIncentiveApplied.map((enrolment) => {
    return {
      uniqueEnrolmentId: enrolment.uniqueEnrolmentId,
      appliedSummerModeIncentive: enrolment.appliedSummerModeIncentive,
    };
  });
};
