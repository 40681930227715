import { useState } from "react";
import { Auth2Layout } from "../../common";
import { PasswordField } from "./PasswordField";
import { validatePassword } from "../../../../../util/validation/password";
import { Alert, Button, Link, Pending } from "@cluey/cluey-components";
import { resetPassword } from '../../../../../actions/hub/hubAuthPagesActions';
import { useDispatch, useSelector } from "react-redux";
import { InlineLoader } from "../../common";
import update from 'immutability-helper';
import { useLocation } from "react-router";
import { extractEmailAndTempPassword } from "../../../../../util/helper/resetPassword";
import { PATH_HUB_RESET_PASSWORD_REQUEST } from "../../../../../util/pagePath";
import { KeepMeLoggedIn } from "./KeepMeLoggedIn";
import { useRedirectIfAuthenticated } from "../../common";
import type { InitialState } from "../../../../../reducers/initialState";

interface FormField {
	[key: string]: {
		error: string;
		valid: boolean;
		value: string;
    textVisible: boolean;
		validator(firstParam: string, secondParam?: string);
	};
}
function ResetPassword() {
  const dispatch = useDispatch();
  const loading = useSelector((state: InitialState) => state.ui.apiState.hubAuthPage.isLoading);
  const queryParams = new URLSearchParams(useLocation().search);
  const { token, email, tempPassword } = extractEmailAndTempPassword(queryParams);
  useRedirectIfAuthenticated();

  const passwordRequirements = [
    'At least 8 characters',
    'At least 1 uppercase letter',
    'At least 1 lowercase letter',
    'At least 1 number'
  ];

  const [formData, setFormData] = useState<FormField>({
    newPassword: {
      error: '',
			valid: true,
			value: '',
      textVisible: false,
      validator: (value: string) => {
        return !!validatePassword(value);
      }
    },
    newPasswordConfirmation: {
      error: '',
      valid: true,
      value: '',
      textVisible: false,
      validator: (password, passwordConfirmation) => {
        return password === passwordConfirmation && password.length > 0;
      }
    }
  });

  const onChange = (value: string, event: React.ChangeEvent) => {
    setFormData((current) => {
      const valid = current[event.target.id].validator(value, formData.newPassword.value);
      const errorMessage = event.target.id === "newPassword" 
        ? 'Password does not meet the requirements.'
        : "The passwords don't match.";
      return update(current, {
        [event.target.id]: {
          $merge: {
            value,
            valid,
            error: valid ? '' : errorMessage
          }
        }
      })
    })
  }

  const onEyeClick = (target: 'newPassword' | 'newPasswordConfirmation') => {
    setFormData((current) => {
      return update(current, {
        [target]: {
          textVisible: {
            $set: !current[target].textVisible
          }
        }
      })
    })
  }

  const onResetButtonClick = () => {
    const validationResult: FormField = Object.entries(formData).reduce((acc, fieldItem) => {
      const [field, fieldData] = fieldItem;
      const valid = fieldData.validator(fieldData.value, formData.newPassword.value);
      const errorMessage = field === "newPassword" 
        ? 'Password does not meet the requirements.'
        : "The passwords don't match.";
      return update(acc, {
        $merge: {
          [field]: update(fieldData, {
            $merge: {
              valid,
              error: valid ? '' : errorMessage
            }
          })
        }
      })
    }, {});

    setFormData(validationResult);
    if (Object.values(validationResult).every((field) => field.valid)) {
      dispatch(resetPassword({
        email,
        tempPassword,
        newPassword: formData.newPassword.value,
      }));
    }
  }

  return (
    <Auth2Layout title="Set your new password">
      <Pending loading={loading} loader={<InlineLoader />}>
        {!token && (
          <Alert type="error" body={null}>
            Invalid reset password link - please use the link sent to your email.
          </Alert>
        )}
        <div className="text-grey-6 text-sm">
          <p className="font-bold">
            Create your new password below. It should include:
          </p>
          <ul className="list-disc pl-2 mt-4">
            {passwordRequirements.map((requirement) => (
              <li key={requirement} className="flex gap-2 items-center">
                <div className="w-1 h-1 bg-black opacity-70 rounded-full"></div>
                <div>{requirement}</div>
              </li>
            ))}
          </ul>
          <div className="w-full flex flex-col gap-1 mt-4">
            <PasswordField
              id="newPassword"
              label="New password"
              onChange={onChange}
              data={formData.newPassword}
              onEyeClick={onEyeClick}
            />
            
            <PasswordField
              id="newPasswordConfirmation"
              label="Confirm new password"
              onChange={onChange}
              data={formData.newPasswordConfirmation}
              onEyeClick={onEyeClick}
            />
          </div>
        </div>
        <div className="my-2">
          <KeepMeLoggedIn />
        </div>
        {!token && (
          <Link to={PATH_HUB_RESET_PASSWORD_REQUEST}>Don't have the email? Request a new one</Link>
        )}
        <Button
          disabled={!token}
          className="my-5"
          onClick={onResetButtonClick}
          size="lg">
            Reset password</Button>
      </Pending>
    </Auth2Layout>
  )
}

export default ResetPassword;