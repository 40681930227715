import React, { useEffect } from 'react';
import { bool, func } from 'prop-types';
import { Hub } from 'aws-amplify';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import Cookies from 'js-cookie';

import InPageLoader from '../../../common/InPageLoader';
import PageError from '../../../common/PageError';
import { getCredentials, userSignout } from '../../../actions/hub/hubAuthPagesActions';
import {
  PATH_HUB_LOG_IN, PATH_HUB_SIGN_UP, PATH_HUB_HOME, PATH_HUB_ONBOARDING, PATH_HUB_SOCIAL_OAUTH_FAILED
} from '../../../util/pagePath';
import { OAUTH_ERROR_NO_ACCOUNT } from './HubSocialOauthErrorPage';

const UNKNOWN_ACCOUNT_ERRORS = [
  'PreSignUp+failed+with+error+This+email+address+is+unknown+to+us.+',
  'Unrecognizable+lambda+output+',
  'Permissions+error%3B+error%3Daccess_denied%3B+error_code%3D200%3B+error_reason%3Duser_denied',
]

const HubSocialOauthCallback = ({
  isClockSkewDetected,
  getUserCredentials,
  signOut,
}) => {
  const history = useHistory();

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      const message = get(data, 'message');
      const socialOauthSourcePage = Cookies.get('socialOauthSourcePage') || PATH_HUB_LOG_IN;

      const onSuccessRedirect = socialOauthSourcePage === PATH_HUB_SIGN_UP
        ? PATH_HUB_ONBOARDING : PATH_HUB_HOME;
      console.log('auth >>> ', { message, event, socialOauthSourcePage })
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUserCredentials({
            onSuccess: () => {
              history.push(onSuccessRedirect);
            },
          });
          break;
        case 'signOut':
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
        case 'customState_failure':
          if (UNKNOWN_ACCOUNT_ERRORS.includes(message)) {
            history.push(`${PATH_HUB_SOCIAL_OAUTH_FAILED}?error=${OAUTH_ERROR_NO_ACCOUNT}`);
          } else {
            history.push(`${PATH_HUB_SOCIAL_OAUTH_FAILED}`);
          }
          break;
        default:
          signOut();
      }
    });
  });

  if (isClockSkewDetected) {
    return <PageError activePage="redirect-clock-skew-handle" />;
  }

  return (
    <InPageLoader />
  );
};

HubSocialOauthCallback.propTypes = {
  isClockSkewDetected: bool.isRequired,
  getUserCredentials: func.isRequired,
  signOut: func.isRequired,
};

export default connect(
  (state) => {
    return {
      isClockSkewDetected: state.login.isClockSkewDetected,
    };
  },
  (dispatch) => {
    return {
      getUserCredentials: bindActionCreators(getCredentials, dispatch),
      signOut: bindActionCreators(userSignout, dispatch),
    };
  },
)(HubSocialOauthCallback);
