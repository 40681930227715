import { useQuery } from '@tanstack/react-query';
import { AVAILABLE_SLOTS_FOR_SCHEDULE_QKEY } from '../../util/constants/queryKeys';
import { ScheduleService } from '../../services/ScheduleService';

export const useGetAvailableSlotsForSchedule = ({enabled, packageId, rescheduleFrom, sessionFrequency}) => {
  return useQuery({
    queryKey: [AVAILABLE_SLOTS_FOR_SCHEDULE_QKEY, packageId, rescheduleFrom, sessionFrequency],
    queryFn: () => ScheduleService.getAvailableSlotsForSchedule({packageId, rescheduleFrom, sessionFrequency}),
    retry: 3,
    staleTime: 300000,
    enabled,
  })
}