import initialState from './initialState';
import {
  HUB_EP_FETCH_ACTIVE_ENROLMENTS_SUCCESS,
  HUB_EP_FETCH_PAST_ENROLMENTS_SUCCESS,
  HUB_SET_FETCH_RESUME_SESSION_FLAG,
} from '../actions/actionTypes';

export default function hubEnrolmentsPage(state = initialState.hubEnrolmentsPage, action) {
  switch (action.type) {
    case HUB_EP_FETCH_ACTIVE_ENROLMENTS_SUCCESS:
      return {
        ...state,
        activeEnrolments: action.payload,
      };
    case HUB_EP_FETCH_PAST_ENROLMENTS_SUCCESS:
      return {
        ...state,
        pastEnrolments: action.payload,
      };
    case HUB_SET_FETCH_RESUME_SESSION_FLAG:
      return {
        ...state,
        timestampOfFetchingResumeSessions: action.payload,
      };
    default:
      return state;
  }
}
