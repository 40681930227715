import { useMutation, useQueryClient } from '@tanstack/react-query';
import { RefObject, useEffect, useRef } from 'react';

import useAccountDetailsQuery from '../../hooks/queries/useAccountDetailsQuery';
import { getPortalAPIRequestParams } from '../../services/PortalAPIServices/helper';
import { sendPostRequest } from '../../services/RequestService';
import { PRICING_PLAN_POLICY_HEADERS } from '../../types/hubEnums';
import { InvoicePer } from '../../types/hubTypes';
import { GET_ACCOUNT_DETAILS, UPDATE_INVOICE_PER_QKEY } from '../../util/constants/queryKeys';
import { RowHeight } from './ChangePricingPlanPage/useChangePricingPlanContext';

export function useCollapsible({
  collapsed,
  ref,
  maxHeights,
}: {
  collapsed: boolean;
  ref: RefObject<HTMLDivElement>;
  maxHeights: RowHeight;
}) {
  const initialHeight = useRef<number>(null);
  useEffect(() => {
    initialHeight.current = ref.current.getBoundingClientRect().height;
  }, [ref]);

  if (!initialHeight.current) {
    return 'auto';
  }

  if (collapsed) {
    const heights = [
      maxHeights[PRICING_PLAN_POLICY_HEADERS.PAYMENT_TERMS],
      maxHeights[PRICING_PLAN_POLICY_HEADERS.RESCHEDULING_A_SESSION],
      maxHeights[PRICING_PLAN_POLICY_HEADERS.CHANGE_OR_CANCEL_PLAN],
    ];
    return `${initialHeight.current - Object.values(heights ?? []).reduce((acc, curr) => acc + curr, 0)}px`;
  } else {
    return `${initialHeight.current}px`;
  }
}

export function useInvoicePerMutation() {
  const { data: accountDetails, isLoading: isLoadingAccountDetails } = useAccountDetailsQuery();
  const queryClient = useQueryClient();

  async function changeInvoicePer(payload: { invoicePer: InvoicePer }) {
    const { url, reqOptions } = getPortalAPIRequestParams(`account/invoice-per`);
    return await sendPostRequest({
      url,
      payload,
      signRequest: true,
      reqOptions,
    }).then((res) => res.json());
  }

  const result = useMutation({
    mutationKey: [UPDATE_INVOICE_PER_QKEY],
    mutationFn: changeInvoicePer,
    onSuccess: (_, variables) => {
      queryClient.setQueryData([GET_ACCOUNT_DETAILS], { ...accountDetails, ...variables });
    },
  });
  return {
    ...result,
    isLoading: result.isLoading || isLoadingAccountDetails,
  };
}
