import { IconProps as Props } from '../types/hubInterfaces';
import { iconDefaults } from '.';
import classNames from 'classnames';

import BotAvatar from '../assets/images/virtualCoach/cluey-coach-bot-avatar.png';

function VirtualCoachIcon(props: Props) {
  const { width, height, className } = { ...iconDefaults, ...props };
  return (
    <img
      width={width}
      height={height}
      src={BotAvatar}
      className={classNames(className)}
      alt="Cluey Coach Bot Avatar"
    />
  );
}

export { VirtualCoachIcon };
