import { IconProps as Props } from "../types/hubInterfaces";
import { iconDefaults } from ".";
import classNames from 'classnames';

function ClockIcon(props: Props) {
  const { width, height, className } = { ...iconDefaults, ...props };
  return (
    <svg className={classNames('fill-current', className)} xmlns="http://www.w3.org/2000/svg" width={`${width}`} height={`${height}`} viewBox="0 0 20 20">
      <title>clock icon</title>
      <path fillRule="evenodd" clipRule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10 11.1111H13.3333C13.9467 11.1111 14.4444 10.6144 14.4444 10C14.4444 9.38556 13.9467 8.88889 13.3333 8.88889H11.1111V6.66667C11.1111 6.05222 10.6133 5.55556 10 5.55556C9.38667 5.55556 8.88889 6.05222 8.88889 6.66667V10C8.88889 10.6144 9.38667 11.1111 10 11.1111Z" />
    </svg>
  )
}

export { ClockIcon };