import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@cluey/cluey-components';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { PATH_HUB_HOME, PATH_HUB_LOG_IN } from '../util/pagePath';

import Logo from '../assets/images/cluey-logo.svg';

import AnnoyedFace from '../assets/images/icon-annoyed.svg';

const reload = () => {
	window.location.reload();
};

const getErrorPropsForUpcomingSessionsPage = ({
	upcomingSessionsToken,
	upcomingSessionsStudentsCount,
	upcomingSessionsFetchError,
}) => {
	let errorObj = {};
	if (upcomingSessionsFetchError || !upcomingSessionsToken || upcomingSessionsToken === 'null') {
		errorObj = {
			title: 'Invalid customer link',
			content: 'You cannot view upcoming sessions for this customer link',
		};
	} else if (upcomingSessionsStudentsCount === 0) {
		errorObj = {
			title: 'No active enrolments',
			content: 'You can only view upcoming sessions for active enrolments',
		};
	}

	return errorObj;
};

const PageError = ({
	retryEnabled,
	activePage,
	upcomingSessionsToken,
	upcomingSessionsStudentsCount,
	upcomingSessionsFetchError,
}) => {
	const history = useHistory();
	// Determine error object

	let errorObj = {};
	switch (activePage) {
		case 'upcoming-sessions-page':
			errorObj = getErrorPropsForUpcomingSessionsPage({
				upcomingSessionsToken,
				upcomingSessionsStudentsCount,
				upcomingSessionsFetchError,
			});
			retryEnabled = false;
			break;
		case 'hub-page':
			errorObj.showFullPage = false;
			break;
		case 'hub-first-log-in-page':
			errorObj.showFullPage = false;
			errorObj.content = 'Please check your link or call us for help.';
			break;
		case 'hub-staff-login-page':
			errorObj.title = 'Invalid customer link';
			errorObj.content = 'You cannot view the account for this customer link.';
			break;
		case 'hub-clock-skew-handle':
			errorObj.title = 'System clock out of sync';
			errorObj.content =
				'Please reset your system’s clock to enable your sessions and enrolments to be displayed accurately.';
			retryEnabled = true;
			break;
		case 'redirect-clock-skew-handle':
			errorObj.title = 'System clock out of sync';
			errorObj.content =
				'Please reset your system’s clock to enable your sessions and enrolments to be displayed accurately.';
			retryEnabled = false;
			errorObj.ctaText = 'Refresh page';
			errorObj.ctaAction = () => {
				window.location = PATH_HUB_LOG_IN;
			};
			break;
		case 'hub-no-active-enrolments':
			errorObj.title = 'You currently have no active enrolments';
			errorObj.content = [
				'To re-activate or add a new enrolment, please email ',
				<a href="mailto:support@clueylearning.com">support@clueylearning.com</a>,
			];
			retryEnabled = false;
			errorObj.ctaText = 'Go back';
			errorObj.ctaAction = () => {
				history.goBack();
			};
			break;
		case 'hub-enrolment-detail':
			errorObj.title = 'Invalid enrolment';
			errorObj.content = 'You cannot view details for this enrolment';
			retryEnabled = false;
			errorObj.ctaText = 'Return to Hub';
			errorObj.ctaAction = () => {
				history.push(PATH_HUB_HOME);
			};
			break;
		case 'hub-manage-pause':
			errorObj.title = 'Invalid enrolment';
			errorObj.content = 'You cannot manage pause for this enrolment';
			retryEnabled = false;
			errorObj.ctaText = 'Return to Hub';
			errorObj.ctaAction = () => {
				history.push(PATH_HUB_HOME);
			};
			break;
		default:
			break;
	}

	const {
		imgSrc = AnnoyedFace,
		imgAlt = 'Annoyed Face',
		title = 'Page unavailable',
		content = 'We’re having trouble loading the page. Please refresh to try again.',
		showFullPage = true,
		ctaText,
		ctaAction,
	} = errorObj;

	const wrapperClasses = classNames('flex flex-col items-center text-center m-auto py-10 page-error', {
		'bg-slate-1 min-w-[100vw] min-h-[100vh]': showFullPage,
	});

	return (
		<div className={wrapperClasses}>
			{showFullPage && <img src={Logo} alt="Cluey Learning" className="logo pb-7 pt-10" />}
			<div className="inner rounded bg-white p-10">
				<div className="relative mb-6 flex w-full justify-center">
					<img src={imgSrc} alt={imgAlt} className="rounded-full bg-blood-orange-1" />
				</div>
				<h3 className="mb-6 font-bold">{title}</h3>
				<p className="mb-8 text-xl font-bold">{content}</p>
				{retryEnabled && (
					<Button size="lg" onClick={() => reload()}>
						Refresh page
					</Button>
				)}
				{ctaText && ctaAction && (
					<Button size="lg" onClick={ctaAction}>
						{ctaText}
					</Button>
				)}
			</div>
		</div>
	);
};

PageError.propTypes = {
	retryEnabled: PropTypes.bool,
	activePage: PropTypes.string,
	upcomingSessionsToken: PropTypes.string,
	upcomingSessionsStudentsCount: PropTypes.number,
	upcomingSessionsFetchError: PropTypes.bool,
};

PageError.defaultProps = {
	retryEnabled: false,
	activePage: '',
	upcomingSessionsToken: 'null',
	upcomingSessionsStudentsCount: 0,
	upcomingSessionsFetchError: false,
};

export default connect((state) => {
	return {
		upcomingSessionsToken: state.upcomingSessions.token,
		upcomingSessionsStudentsCount: Array.isArray(state.upcomingSessions.students)
			? state.upcomingSessions.students.length
			: 0,
		upcomingSessionsFetchError: state.ui.apiState.upcomingSessions.fetchError,
	};
}, null)(PageError);

export { PageError as PresentationalPageError };
