import { Container } from '@cluey/cluey-components';
import EnrolmentLayout from '../../common/hub/EnrolmentLayout';
import { getAccountDetails } from '../../selectors/hubSelector';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getWebsiteDomainByCountry } from '../../util/helper/website';
import { Loader } from '../../components/Loader';
import type { AccountDetails } from '../../types/hubInterfaces';

const ServiceFees = () => {
  const { country }: AccountDetails = useSelector(getAccountDetails);

  useEffect(() => {
    const websiteDomain = getWebsiteDomainByCountry(country);
    window.location.href = `${websiteDomain}/service-fees`;
  }, [country]);

  return (
    <EnrolmentLayout
      showEnrolmentStatus={false}
      showSummerModeEnrolmentStatus={false}
    >
      <Container>
        <Loader />   
      </Container>
    </EnrolmentLayout>
  )
}
export { ServiceFees }