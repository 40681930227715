import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../reducers/rootReducer';

/**
 * Here we will save the single instance of our redux store
 */
// let store;
//
// export const getStore = () => store;

export default function configureStore() {
  const middleware = [thunk];
  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(rootReducer, composeEnhancer(applyMiddleware(...middleware)));
  if (process.env.NODE_ENV === 'development') {
    window.store = store;
  }
  return store;
}

export const store = configureStore();
