import React from 'react';
import PropTypes from 'prop-types';
import { restarterIncentiveCopy } from '../util/summerCopy';
import { PromoText } from './EnrolmentManagement/PromoText';

export const IncentiveText = ({ code, confirming }) => {
  const copy = restarterIncentiveCopy(code, confirming);
  return code ? <PromoText text={copy} /> : null;
};

IncentiveText.propTypes = {
  code: PropTypes.string.isRequired,
  confirming: PropTypes.bool,
};

IncentiveText.defaultProps = {
  confirming: false,
};
