import type { AccountPricingPlan } from "../../types/hubTypes"

export const getSessionCost = ({
  data,
}: {
  data?: AccountPricingPlan;
}) => {
  if (!data) return null;
  const { currentPricingPlan, upcomingPricingPlan } = data;
  if (upcomingPricingPlan) {
    return upcomingPricingPlan.privatePriceTier?.costPerSession;
  }
  return currentPricingPlan.privatePriceTier?.costPerSession;
}