import get from 'lodash/get';
import { SPECIAL_DATE_TYPE } from '../../util/constants';
import moment from 'moment';
import { SchoolHolidayTerm } from '../../types/hubTypes';
import { SCHOOL_TERM } from '../../types/hubEnums';
import { getCurrentTerm, getNearestTerm, getNextTerm } from '../../util/helper/schoolTerm';

export const getSchoolTermsPublicHolidays = (state) => get(state, 'hubSessionsPage.schoolTermsPublicHolidays', []);

export const getUpcomingTerms = (state): Array<SchoolHolidayTerm> => {
  const terms = getSchoolTermsPublicHolidays(state);
  const schoolTerms = terms.filter((term) => {
    return (term.type || '').toLowerCase() === SPECIAL_DATE_TYPE.SCHOOL_TERM;
  });
  return schoolTerms;
};

export const getCurrentTerm_deprecated = (state): undefined | SchoolHolidayTerm => {
  const terms = getUpcomingTerms(state);
  const currentDate = moment().utc();
  const currentTerm = terms.find((term) => {
    const startDate = moment(term.startDate);
    const endDate = moment(term.endDate);
    return currentDate.isBetween(startDate, endDate.add(1, 'days'));
  });
  return currentTerm;
};

export const getIsSummer = (state) => {
  const { summerStart, summerEnd } = getSummerDates(state);
  return moment.utc().isSameOrAfter(summerStart) && moment.utc().isSameOrBefore(summerEnd);
};

export const getSummerDates = (state) => {
  try {
    const upcomingTerms = getUpcomingTerms(state);
    const term4 = getNearestTerm({ upcomingTerms, term: SCHOOL_TERM.TERM_4, order: 'desc' });
    const term1 = getNearestTerm({ upcomingTerms, term: SCHOOL_TERM.TERM_1, order: 'asc' });

    if (!term4) {
      return {
        summerStart: null,
        summerEnd: null,
      };
    }
    const summerStart = moment.utc(term4.endDate).add(1, 'day');
    const summerEnd = moment.utc(term1.startDate).subtract(1, 'day');
    return {
      summerStart,
      summerEnd,
    };
  } catch (e) {
    return {
      summerStart: null,
      summerEnd: null,
    };
  }
};

export const allowHolidayPause = (state) => {
  const terms = getSchoolTermsPublicHolidays(state);
  const currentTerm = getCurrentTerm(terms);
  const isSummer = getIsSummer(state);
  /* pausing during summer is handled by the SummerPause component */
  if (isSummer) {
    return false;
  }
  if (currentTerm) {
    const endDate = moment(currentTerm.endDate);
    const currentDate = moment();
    const diff = endDate.diff(currentDate, 'days');
    /*
      summer pause will be handled by the `SummerPause` component;
      thus, if the upcoming holiday is summer, we should not allow the user to pause via
      the standard pause form, but allow them to do so via the aforementioned component
    */
    if (diff < 14 && currentTerm.schoolTerm !== SCHOOL_TERM.TERM_4) {
      return true;
    }
    return false;
  }
  return true;
};

// SUMMER PAUSING IS ENABLED FROM NOV. 4 ONWARDS
export const allowSummerHolidayPause = (state) => {
  const schoolTerms = getUpcomingTerms(state);
  const currentTerm = getCurrentTerm(schoolTerms);
  const nextTerm = getNextTerm(schoolTerms);
  const isSummer = getIsSummer(state);

  const isTerm4 = currentTerm?.schoolTerm === SCHOOL_TERM.TERM_4;
  // isSummer -> it is term 4 OR there is no ongoing term and the next term is term 1
  const pausingForSummer = isTerm4 || (!currentTerm && nextTerm?.schoolTerm === SCHOOL_TERM.TERM_1) || isSummer;

  const term1 = nextTerm;
  const summerPausePromoStartDate = moment.utc('2024-11-03'); // TODO: change to Nov. 4 2024 when releasing
  const summerPausePromoEndDate = moment(term1?.startDate).subtract(1, 'day');

  const isDuringSummerPausePromo = moment()
    .utc()
    .isBetween(summerPausePromoStartDate, summerPausePromoEndDate, 'D', '[]');

  // hopefully we will have migrated to Hub 2.0 by next year - else this will need to be addressed before summer next year (2025)
  return isDuringSummerPausePromo && pausingForSummer;
};

// WE SHOW THE SUMMER HOLIDAY BANNER 4 WEEKS BEFORE THE END OF THE TERM
export const showSummerHolidayPauseBanner = (state) => {
  const schoolTerms = getUpcomingTerms(state);
  const currentTerm = getCurrentTerm(schoolTerms);
  const nextTerm = getNextTerm(schoolTerms);
  const isSummer = getIsSummer(state);

  const isTerm4 = currentTerm?.schoolTerm === SCHOOL_TERM.TERM_4;
  // isSummer -> it is term 4 OR there is no ongoing term and the next term is term 1
  const pausingForSummer = isTerm4 || (!currentTerm && nextTerm?.schoolTerm === SCHOOL_TERM.TERM_1) || isSummer;
  const endDate = moment(currentTerm?.endDate);

  const today = moment();
  const diff = endDate.diff(today, 'days');
  const SHOW_WEEKS_BEFORE_TERM_END = 4;
  const daysBeforeTermEndToShowBanner = SHOW_WEEKS_BEFORE_TERM_END * 7;
  const isTermEnding = diff < daysBeforeTermEndToShowBanner;

  return pausingForSummer && isTermEnding;
};
