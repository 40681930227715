import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';

import ContactFormLayout from '../../../common/hub/ContactFormLayout';
import { useRadioGroupProps, RadioGroup } from '../../../components/RadioGroup/RadioGroup';
import { useTextAreaProps, TextArea } from '../../../components/TextArea/TextArea';
import { getSelectedSession } from '../../../selectors/hubSelector';
import { submitCaseToSF } from '../../../actions/hub/hubContactPageActions';
import FormSubHeading from '../../../components/hub/contact/FormSubHeading';
import { RestrictedButton } from '../../../components/RestrictedButton/RestrictedButton';
import ErrorBlock from '../../../common/ErrorBlock';
import { PATH_HUB_CR_SUBMIT_CASE_SUCCESS, PATH_HUB_UPCOMING_SESSIONS } from '../../../util/pagePath';

const groupName = 'contact-form-skip-session';
const radios = [
  {
    text: 'Student unavailable',
    value: 'Student unavailable',
    key: `${groupName}-category-1`,
  },
  {
    text: 'Holidays',
    value: 'Holidays',
    key: `${groupName}-category-2`,
  },
  {
    text: 'Other',
    value: 'Other',
    key: `${groupName}-category-4`,
  },
];

const FormSkipSession = ({ selectedSession, isSubmitting, submitError, errorMsg, submit }) => {
  const radioGroupProps = useRadioGroupProps({
    labelText: 'Select a reason for skipping this session',
    groupName,
    radios,
  });
  const textAreaProps = useTextAreaProps({
    id: `${groupName}-textarea`,
    labelText: 'Is there anything else you’d like to tell us?',
  });
  const history = useHistory();

  if (selectedSession === null || selectedSession === undefined) {
    history.push(PATH_HUB_UPCOMING_SESSIONS);
    return null;
  }

  const { uniqueEnrolmentId, studentSessionId, student, subject } = selectedSession;

  const onClickHandler = () => {
    const caseType = 'Scheduling';
    const caseCategory = 'Skip/Cancel session';
    const caseSubCategory = radioGroupProps.selectedRadio;
    submit({
      uniqueEnrolmentId,
      message: `Request to ${caseCategory} due to ${caseSubCategory}. Other details: ${textAreaProps.value}`,
      caseSubject: `Contact request - ${caseType} - ${caseCategory} - ${caseSubCategory}`,
      caseType,
      caseCategory,
      caseSubCategory,
      studentSession: studentSessionId,
      onSubmitSuccess: () => {
        history.push(PATH_HUB_CR_SUBMIT_CASE_SUCCESS, {
          message: `Your skip/cancel session request for ${student}'s ${subject} enrolment has been submitted successfully. We'll be in touch soon.`,
        });
      },
    });
  };

  return (
    <ContactFormLayout selectedSession={selectedSession}>
      <div className="px-5 pt-5 lg:px-20 lg:pt-12">
        <FormSubHeading />
        {submitError && errorMsg && <ErrorBlock errorMsg={errorMsg} />}
        <form>
          <RadioGroup {...radioGroupProps} className="mb-9" />
          <TextArea {...textAreaProps} className="mb-7" />
          <div className="mb-4 text-center tracking-normal lg:mb-8">
            <RestrictedButton
              buttonText="Request change"
              truthyChecks={[!!radioGroupProps.selectedRadio, !isSubmitting]}
              onClick={onClickHandler}
            />
          </div>
        </form>
      </div>
    </ContactFormLayout>
  );
};

FormSkipSession.propTypes = {
  selectedSession: PropTypes.shape({
    uniqueEnrolmentId: PropTypes.string.isRequired,
    studentSessionId: PropTypes.string.isRequired,
    student: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
  }).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitError: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    return {
      selectedSession: getSelectedSession(state),
      isSubmitting: state.ui.apiState.hubContactPage.isLoading,
      submitError: state.ui.apiState.hubContactPage.fetchError,
      errorMsg: state.ui.apiState.hubContactPage.errorMsg,
    };
  },
  (dispatch) => {
    return {
      submit: bindActionCreators(submitCaseToSF, dispatch),
    };
  }
)(FormSkipSession);
