import initialState from './initialState';
import { HUB_EWP_FETCH_RESUME_SESSION_SUCCESS } from '../actions/actionTypes';

export default function hubEnrolmentsWithResumeSession(state = initialState.hubEnrolmentsWithResumeSession, action) {
  switch (action.type) {
    case HUB_EWP_FETCH_RESUME_SESSION_SUCCESS:
      // return new Set([...state, action.payload]);
      // NOTE, may need to check duplicates here
      // use Set doesn't achieve this
      return [...state, action.payload];
    default:
      return state;
  }
}
