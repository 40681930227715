import { IconProps as Props } from '../types/hubInterfaces';
import { iconDefaults } from '.';
import classNames from 'classnames';

export const TutorIcon = (props: Props) => {
	const { width, height, className } = { ...iconDefaults, ...props };
	return (
		<svg
			className={classNames('fill-current', className)}
			xmlns="http://www.w3.org/2000/svg"
			width={`${width}`}
			height={`${height}`}
			viewBox="0 0 25 25"
		>
			<title>pencil icon</title>
			<path
				d="M15.585 23.75c-.216-.636-.549-1.522-.781-2.325l-2.043-.52c-.263-.066-.423-.338-.357-.605.066-.268.333-.429.596-.363l2.106.537c.56.102 2.095.724 3.076.018.742-.534 1.038-3.13 1.13-4.217.316-.058 1.071-.251 1.234-.796.207-.692-.958-.994-1.426-3.084-.06-.263-.105-.551-.146-.837-.024-.329-.077-.648-.146-.962.004-.02.013-.036.013-.055V8.346l-5.05 1.952c-.307.12-.653-.037-.771-.35-.117-.313.038-.664.347-.783l8.895-3.44c.143-.063.238-.204.238-.364 0-.166-.1-.315-.254-.374L12.64 1.276c-.09-.035-.19-.035-.28 0l-9.607 3.71c-.153.06-.254.209-.254.375 0 .165.1.315.254.373l.66.255v2.743c-.528.17-.914.674-.914 1.267 0 .731.587 1.327 1.309 1.327s1.31-.596 1.31-1.327c0-.593-.386-1.097-.916-1.267V6.294l1.957.756v3.491c0 .018.009.034.01.051-.103.469-.163.953-.163 1.453 0 1.529.515 2.936 1.376 4.054h-.002l.023.028c.036.045.069.089.105.132.192.258.54.808.614 1.485.092.834-.541 5.035-.649 6.006h8.111z"
				transform="translate(-286 -1280) translate(286 1280)"
			/>
		</svg>
	);
};
