import { z } from 'zod';

const noEmailsOrPhoneNumbers = (value: string) => {
	const emailPattern =
		/(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/i;
	const contactNumberPattern = /[+]?[0-9]{1,4}?[-.\s]?[0-9]{1,4}[-.\s]?[0-9]{3,4}[-.\s]?[0-9]{3,4}/;

	if (emailPattern.test(value) || contactNumberPattern.test(value)) {
		return false;
	}

	return true;
};

export const ERROR_CONTACT_DETAILS_DETECTED = 'Message cannot contain email or contact details';

export const FormSchema = z.object({
	category: z
		.string()
		.nullable()
		.refine((value) => value !== null, { message: 'Select an option' }),
	message: z.string().min(1, 'Message cannot be empty').refine(noEmailsOrPhoneNumbers, {
		message: ERROR_CONTACT_DETAILS_DETECTED,
	}),
});

export type FormSchema = z.infer<typeof FormSchema>;
