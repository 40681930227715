import EnrolmentLayout from '../../../common/hub/EnrolmentLayout';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { sortBy } from 'lodash';
import { filterSchoolTerms, isHolidayCheck } from '../../../util/helpers';
import { BasicCard, Container, Alert } from '@cluey/cluey-components';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { PATH_HUB_HOME, PATH_HUB_RESCHEDULE_SESSION } from '../../../util/pagePath';
import { filterUpcomingForEnrolment } from '../../../util/helper';
import { DATE_FORMAT_TYPE_1, DATE_FORMAT_TYPE_4, SESSION_TIME_FORMAT } from '../../../util/constants';
import SchoolTermListItem from '../../../components/hub/sessions/SchoolTermListItem';
import alertIcon from '../../../assets/images/icon-filled-alert-exclamation--red.svg';
import infoIcon from '../../../assets/images/icon-filled-alert-info.svg';
import classNames from 'classnames';
import moment from 'moment';
import { ManageUpcomingSessionModal } from '../../../components/Modals/ManageUpcomingSessionModal';
import { fetchEnrolment } from '../../../actions/hub/hubEnrolmentManagementActions';
import { useGetSessionPolicy } from '../../../hooks/queries/useSessionQuery';
import { ReactComponent as FilledAlertInfo } from '../../../assets/images/icon-filled-alert-info-custom-fill.svg';
import { api } from '../../../api';
import { convertSchoolTerms } from '../../../util/helper/schoolTerm';
import { orderBy } from 'lodash';

const SelectSession = () => {
	const { enrolmentId } = useParams<{ enrolmentId: string }>();
	const { data: upcomingSessions = [], isLoading: loadingSessions } = api.session.all.useQuery('upcoming', {
		select: (data) => {
			const filteredSessions = data.filter((s) => {
				return moment(s.startTime).isSameOrAfter(new Date(), 'day');
			});
			return orderBy(filteredSessions, ['startTime'], ['asc']);
		},
	});

	const { data: activeEnrolments, isLoading: loadingEnrolments } = api.enrolment.all.useQuery('active');
	const { data: terms, isLoading: loadingSchoolTerms } = api.schoolTerm.all.useQuery();
	const schoolTerms = convertSchoolTerms({ terms });
	const { data: holidays, isLoading: loadingHolidays } = api.schoolTerm.holidays.useQuery();
	const { data: accountDetails, isLoading: loadingAccountDetails } = api.account.details.useQuery();
	const timezone = accountDetails?.timezone ?? 'Australia/Sydney';

	const enrolment = activeEnrolments.find((enrolment) => enrolment.enrolmentId === enrolmentId);
	const upcomingForEnrolment = filterUpcomingForEnrolment(enrolmentId, upcomingSessions);
	const history = useHistory<{ action: 'reschedule' | 'cancel' }>();
	const dispatch = useDispatch();
	const [policySession, setPolicySession] = useState(null);
	const [modalVisible, setModalVisible] = useState(false);
	const [timestamp, setTimestamp] = useState(Date.now());
	const {
		data: sessionPolicy,
		error: policyError,
		isLoading: isPolicyAcquisitionLoading,
	} = useGetSessionPolicy({
		sessionId: policySession?.studentSessionId,
	});

	useEffect(() => {
		/* 
      redirect to the home page if the enrolment is not active, or it doesn't exist
    */
		if (!enrolment) {
			history.push(PATH_HUB_HOME);
		} else if (!enrolmentId) {
			dispatch(fetchEnrolment({ enrolmentId }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enrolment]);

	useEffect(() => {
		if (sessionPolicy) {
			if (history.location.state?.action === 'reschedule') {
				history.push(
					generatePath(PATH_HUB_RESCHEDULE_SESSION, { sessionId: policySession?.studentSessionId ?? 'sessionId' })
				);
			} else {
				setModalVisible(true);
			}
		}
	}, [history, timestamp, timezone, policySession, sessionPolicy]);

	const getActionText = () => {
		if (!history.location?.state?.action) {
			return 'Reschedule or cancel';
		}
		return history.location?.state?.action;
	};

	const getSessionsAndTerms = () => {
		if (upcomingForEnrolment.length === 0) {
			return [];
		}

		const filteredSchoolTerms = schoolTerms.filter((st) => {
			return moment(st.startTime).diff(moment(), 'days') <= 60;
		});

		const filteredSchoolTermsByLastSession = filterSchoolTerms(
			filteredSchoolTerms,
			upcomingForEnrolment[upcomingForEnrolment.length - 1].startTime
		);

		const sortedSessionsAndTerms = sortBy(
			[...upcomingForEnrolment, ...filteredSchoolTermsByLastSession],
			['startTime']
		);
		return sortedSessionsAndTerms;
	};

	const renderSessionNotice = (startInSydneyTime) => {
		/*
      Cluey is closed on Cluey holidays, but open on Public holidays.
      If the start date is a Cluey holiday, show an exclamation alert with a red icon and background.
      If the start date is a Public holiday, show an info alert with a slate background
      Render nothing if it's a regular working day
    */
		const { isHoliday, isPublicHoliday, indicatorText } = isHolidayCheck(startInSydneyTime, holidays);
		if (isHoliday) {
			return (
				<div className={classNames('gap-x flex px-4 py-2', isPublicHoliday ? 'bg-slate-1' : 'bg-red-1')}>
					<img src={isPublicHoliday ? infoIcon : alertIcon} alt="alert" width="20" height="20" className="mr-3" />
					<span className="text-sm text-grey-6">{indicatorText}</span>
				</div>
			);
		}
		return null;
	};

	const renderPolicyErrorInformation = () => {
		const { startTime, student, subject, tutor } = policySession ?? {};
		const sessionDate = moment().isSame(moment(startTime), 'day')
			? 'today'
			: `on ${moment(startTime).format(DATE_FORMAT_TYPE_1)}`;
		return `There was an error retrieving the session policy for ${student}'s ${subject} session with ${tutor} ${sessionDate}. ${policyError}`;
	};

	const onSessionClick = (session) => {
		setPolicySession(session);
		/*
      timestamp is updated every time a session item is clicked;
      this is to ensure that the modal the cancel session flow is opened on subsequent clicks
      to the same session item
    */
		setTimestamp(Date.now());
	};

	if (!enrolment) {
		return null;
	}

	return (
		<EnrolmentLayout
			sectionHeading={`${enrolment.student} ${enrolment.subject}`}
			mainHeading={
				<div>
					<span className="capitalize">{getActionText()}</span> upcoming session
				</div>
			}
			showEnrolmentStatus={false}
			showSummerModeEnrolmentStatus={false}
			enrolmentId={enrolmentId}
			isLoading={
				(isPolicyAcquisitionLoading && policySession) ||
				loadingSessions ||
				loadingEnrolments ||
				loadingSchoolTerms ||
				loadingHolidays ||
				loadingAccountDetails
			}
		>
			<Container>
				{policySession && (
					<ManageUpcomingSessionModal
						open={modalVisible}
						closeModal={() => setModalVisible(false)}
						session={policySession}
					/>
				)}
				<p className="text-xl text-grey-6">
					Which of {enrolment.student}'s upcoming sessions do you want to {getActionText()}?
				</p>
				{policyError && <Alert className="mt-2" type="error" body={renderPolicyErrorInformation()} />}
				<section className="m-auto mt-8 flex flex-col justify-center gap-y-2">
					{getSessionsAndTerms().map((session, index) => {
						const isLastItem = getSessionsAndTerms().length === index + 1;
						const sessionStartTime = moment(session.startTime).tz(timezone);
						const sessionEndTime = moment(session.endTime).tz(timezone);
						const isOneOffRescheduled = session?.tutorRescheduleRequestStatusC === 'Approved';

						return session?.type?.toLowerCase() === 'school term' ? (
							<SchoolTermListItem
								key={session.startTime}
								termItem={session}
								timezone={timezone}
								isLastItem={isLastItem}
							/>
						) : (
							<button key={session.startTime} onClick={() => onSessionClick(session)} className="text-left">
								<BasicCard className="text-blue-5 hover:cursor-pointer hover:bg-slate-1 hover:text-purple-6">
									<div className="w-full">
										{isOneOffRescheduled && (
											<section className="align-items-center flex w-full flex-1 flex-row space-x-4 bg-yellow-1 px-4 py-2">
												<div>
													<FilledAlertInfo fill="#F6A11D" />
												</div>
												<div className="flex pt-1 text-sm text-black">
													<p>One-off rescheduled session</p>
												</div>
											</section>
										)}
										{renderSessionNotice(sessionStartTime)}
										<div className="px-4 py-6">
											<p className="font-bold">{sessionStartTime.format(DATE_FORMAT_TYPE_4)}</p>
											<p>
												{sessionStartTime.format(SESSION_TIME_FORMAT)} - {sessionEndTime.format(SESSION_TIME_FORMAT)}{' '}
												(with {session.tutor})
											</p>
											{isOneOffRescheduled && <p>One-off rescheduled session</p>}
										</div>
									</div>
								</BasicCard>
							</button>
						);
					})}
					{upcomingForEnrolment.length === 0 && <p>You have no upcoming sessions for this enrolment.</p>}
				</section>
			</Container>
		</EnrolmentLayout>
	);
};

export default SelectSession;
