import React from 'react';
import PropTypes from 'prop-types';

export const PromoText = ({ text }) => {
	return <span className="mt-1 block text-2xs font-bold uppercase tracking-[1px] text-red-5">{text}</span>;
};

PromoText.propTypes = {
	text: PropTypes.string.isRequired,
};
