import initialState from '../../initialState';
import * as actionTypes from '../../../actions/actionTypes';

export default function hubSchoolTermsPublicHolidaysReducer(
  state = initialState.ui.apiState.hubSchoolTermsPublicHolidays,
  action,
) {
  switch (action.type) {
    case actionTypes.HUB_SP_FETCH_SCHOOL_TERMS_PUBLIC_HOLIDAYS_START:
      return {
        ...state,
        fetchError: false,
        isLoading: true,
      };
    case actionTypes.HUB_SP_FETCH_SCHOOL_TERMS_PUBLIC_HOLIDAYS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasFetched: true,
      };
    case actionTypes.HUB_SP_FETCH_SCHOOL_TERMS_PUBLIC_HOLIDAYS_FAILED:
      return {
        ...state,
        isLoading: false,
        fetchError: true,
      };
    case actionTypes.HUB_SP_FETCH_SCHOOL_TERMS_PUBLIC_HOLIDAYS_FAILURE_DEFINITIVE:
      return {
        ...state,
        fetchError: true,
        isLoading: false,
        retryEnabled: false,
      };
    default:
      return state;
  }
}
