import initialState from '../../initialState';
import * as actionTypes from '../../../actions/actionTypes';

export default function hubAccountPageReducer(state = initialState.ui.apiState.hubAccountPage, action) {
  switch (action.type) {
    case actionTypes.HUB_AP_FETCH_ACCOUNT_DETAILS_START:
    case actionTypes.HUB_AP_UPDATE_ACCOUNT_DETAILS_START:
    case actionTypes.HUB_AP_FETCH_BILLING_INFORMATION_START:
    case actionTypes.HUB_AP_CREATE_CHARGENT_ORDER_START:
    case actionTypes.HUB_AP_PROCESS_PAYMENT_START:
    case actionTypes.HUB_AP_FETCH_INVOICE_HISTORY_START:
      return {
        ...state,
        fetchError: false,
        isLoading: true,
      };
    case actionTypes.HUB_AP_FETCH_ACCOUNT_DETAILS_SUCCESS:
    case actionTypes.HUB_AP_UPDATE_ACCOUNT_DETAILS_SUCCESS:
    case actionTypes.HUB_AP_FETCH_BILLING_INFORMATION_SUCCESS:
    case actionTypes.HUB_AP_CREATE_CHARGENT_ORDER_SUCCESS:
    case actionTypes.HUB_AP_PROCESS_PAYMENT_SUCCESS:
    case actionTypes.HUB_AP_FETCH_ACCOUNT_DETAILS_FAILED:
    case actionTypes.HUB_AP_UPDATE_ACCOUNT_DETAILS_FAILED:
    case actionTypes.HUB_AP_FETCH_INVOICE_HISTORY_SUCCESS:
    case actionTypes.HUB_AP_FETCH_INVOICE_HISTORY_FAILED:
    case actionTypes.HUB_AP_FETCH_BILLING_INFORMATION_FAILED:
    case actionTypes.HUB_AP_CREATE_CHARGENT_ORDER_FAILED:
    case actionTypes.HUB_AP_PROCESS_PAYMENT_FAILED:
      return {
        ...state,
        fetchError: false,
        isLoading: false,
      };
    default:
      return state;
  }
}
