import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as steps from './actions/sessionReportAppLifecycleSteps';
import { startup } from './actions/sessionReportAppLifecycleActions';
import PageError from './common/PageError';
import FullscreenLoader from './common/FullscreenLoader';
import { getRetryEnabled } from './selectors/errorSelector';
import HeadComponent from './common/HeadComponent';
import StudentProgressAndReport from './views/StudentProgressAndReport';

class SessionReportLifecycle extends Component {
	static propTypes = {
		login: PropTypes.func.isRequired,
		currentStep: PropTypes.oneOf(Object.keys(steps)).isRequired,
		retryEnabled: PropTypes.bool.isRequired,
	};

	constructor(props) {
		super(props);
		const { currentStep, login } = this.props;
		console.log('currentStep === steps.SR_STARTUP ===> ', currentStep === steps.SR_STARTUP);
		if (currentStep === steps.SR_STARTUP) {
			// initial check
			login();
		}
	}

	render() {
		const { currentStep, retryEnabled } = this.props;
		switch (currentStep) {
			case steps.SR_STARTUP:
				return <FullscreenLoader isOpen />;
			case steps.SR_INITIAL_AUTH_FAILED:
				return <PageError retryEnabled={retryEnabled} />;
			case steps.SR_CAN_ACCESS:
				return (
					<>
						<HeadComponent />
						<StudentProgressAndReport />
					</>
				);
			case steps.SR_INITIAL_AUTH:
				return null;
			default:
				return null;
		}
	}
}

export default connect(
	(state) => {
		return {
			currentStep: state.ui.appLifecycle.currentStep,
			retryEnabled: getRetryEnabled(state),
		};
	},
	(dispatch) => {
		return {
			login: bindActionCreators(startup, dispatch),
		};
	}
)(SessionReportLifecycle);
