import React from 'react';
import PropTypes from 'prop-types';
import { Textarea as ClueyTextarea, ErrorText } from '@cluey/cluey-components';

export const Textarea = ({
  value,
  onChange,
  id,
  label,
  placeholder,
  disabled,
  error,
  maxLength,
}) => {
  return (
    <div className="flex flex-col">
      {label && (
        <label htmlFor={id} className="mb-2 text-xs">
          {label}
        </label>
      )}

      <ClueyTextarea
        value={value}
        onChange={onChange}
        id={id}
        disabled={disabled}
        placeholder={placeholder}
        maxLength={maxLength}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </div>
  );
};

Textarea.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  maxLength: PropTypes.number,
};

Textarea.defaultProps = {
  label: '',
  value: '',
  onChange: () => {},
  id: undefined,
  placeholder: '',
  disabled: false,
  error: '',
  maxLength: undefined,
};
