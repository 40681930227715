/*
 * NOTE, users can land on this page only via enrolment detail page so enrolment detail will be used here
 *
 */
import { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { Alert, Button, LinkButton } from '@cluey/cluey-components';
import EnrolmentLayout from '../../../common/hub/EnrolmentLayout';
import { EnrolmentBrief } from '../../../components/EnrolmentManagement/EnrolmentBrief';
import { ManagePauseOptions } from '../../../components/EnrolmentManagement/PauseSessions/ManagePauseOptions';

import {
  PATH_HUB_ENROLMENT_CHANGE_SCHEDULE,
  PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP,
  PATH_HUB_HOME,
  PATH_HUB_PLAN_DETAILS,
  PATH_HUB_CR_PAUSE_SESSIONS,
} from '../../../util/pagePath';
import { PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT } from '../../../util/constants';
import { TRACKING_MP } from '../../../util/trackingClasses';
import PageError from '../../../common/PageError';
import InPageLoader from '../../../common/InPageLoader';
import SummerPromoLegalCopy from '../../../components/EnrolmentManagement/SummerMode/SummerPromoLegalCopy';
import { useSelector } from 'react-redux';
import { InitialState } from '../../../types/hubTypes';
import { PolicyAlert } from '../../../components/PolicyAlert';
import { getKeepExistingScheduleResumeOptions, getManagePausePolicyAlertData } from '../../../util/helper/managePause';
import { useGetSessionPolicy } from '../../../hooks/queries/useSessionQuery';
import { getTerm123PauseSessionsFormData } from '../../../selectors/hubSelector';
import { getIsSummer, getSummerDates } from '../../../selectors/schoolTerms/schoolTermsSelectors';
import { summerPauseInsteadOfCancelEnabled } from '../../../selectors/enrolmentSelector';
import { PreferToStopSessions } from '../../../components/PreferToStopSessions';

const DATE_FORMAT = 'dddd, MMMM D';
const formatResumeDatesOptions = ({ resumeDates, timezone }) => {
  if (isEmpty(resumeDates)) {
    return [];
  }

  return resumeDates.map((date, index) => {
    return {
      text: moment(date).tz(timezone).format(DATE_FORMAT),
      value: date,
      key: `manage-pause-option-${index}`,
    };
  });
};

interface Props {
  isLoading: boolean;
  loadingError: boolean;
  enrolmentId: string;
  enrolmentSfid: string;
  studentName: string;
  enrolmentSubject: string;
  enrolmentPausedFrom: string;
  enrolmentPausedTo: string;
  tutorName: string;
  tutorPhoto: string;
  willBePaused: boolean;
  willBePausedDates: {
    pauseFrom: string;
    resumeFrom: string;
  };
  timezone: string;
  isGroup: boolean;
  sessionFrequency: string;
  resetManagePausePageState(): void;
  onKeepScheduleHandler(): void;
  incentive: string;
}

/* @TODO add test */
export const ManagePauseKeepExistingSchedule = ({
  isLoading,
  loadingError,
  enrolmentId,
  enrolmentSfid,
  studentName,
  enrolmentSubject,
  enrolmentPausedFrom,
  enrolmentPausedTo,
  tutorName,
  tutorPhoto,
  willBePaused,
  willBePausedDates,
  timezone,
  isGroup,
  sessionFrequency,
  resetManagePausePageState,
  onKeepScheduleHandler,
  incentive,
}: Props) => {
  const history = useHistory();
  const {
    data: enrolmentPolicy,
    isLoading: isPolicyAcquisitionLoading,
    error: enrolmentPolicyError,
  } = useGetSessionPolicy({
    enrolmentId: enrolmentSfid,
  });
  const pauseRequestLoading = useSelector((state: InitialState) => {
    return (
      state.ui.apiState.hubContactPage.isLoading ||
      state.ui.apiState.summerMode.saveSummerModeData.isSubmitting ||
      isPolicyAcquisitionLoading
    );
  });
  const maxPauseWeeks = Number(enrolmentPolicy?.pause?.allowedWeeks);
  const { nextTermStartDate } = useSelector(getTerm123PauseSessionsFormData);
  const isSummer = useSelector(getIsSummer);
  const summerDates = useSelector(getSummerDates);
  const summerPauseEnabled = useSelector(summerPauseInsteadOfCancelEnabled);

  useEffect(() => {
    resetManagePausePageState();
    if (isEmpty(enrolmentId)) {
      history.push(PATH_HUB_HOME);
      return undefined;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (willBePaused) {
      history.replace(`${PATH_HUB_CR_PAUSE_SESSIONS}?token=${enrolmentId}`);
    }
  }, [history, enrolmentId, willBePaused, enrolmentPolicy]);

  if (isEmpty(enrolmentId)) {
    return null;
  }

  if (isLoading) {
    return <InPageLoader />;
  }

  if (loadingError) {
    return <PageError activePage="hub-manage-pause" />;
  }

  /* resumeFrom can be present in one of the objects in hubEnrolmentsWithResumeSession */
  const { resumeFrom } = willBePausedDates ?? {};
  const resumeFromOnlyDay = moment(resumeFrom).tz(timezone).format(PAUSE_SESSIONS_PAYLOAD_DATE_FORMAT);
  const resumeDateFormatted = moment(resumeFrom).tz(timezone).format(DATE_FORMAT);
  const pauseToDateFormatted = moment(enrolmentPausedTo ?? '')
    .tz(timezone)
    .format(DATE_FORMAT);
  const resumeDates = getKeepExistingScheduleResumeOptions({
    resumeFrom,
    resumeFromOnlyDay,
    sessionFrequency,
    timezone,
    enrolmentPausedFrom,
    maxPauseWeeks,
    policy: enrolmentPolicy,
    nextTermStartDate,
    isSummer,
    summerDates,
  });
  const pausedFromFormatted = moment(enrolmentPausedFrom).tz(timezone).format(DATE_FORMAT);
  const options = formatResumeDatesOptions({ resumeDates, timezone });
  const { title, body } = getManagePausePolicyAlertData({
    enrolmentPolicy,
    resumeDateOptions: resumeDates,
    maxPauseWeeks,
  });

  const onManageScheduleClick = (e) => {
    e.preventDefault();
    // NOTE, when changing change group flow, may need to identify close group here
    if (isGroup) {
      history.push(`${PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP}?token=${enrolmentId}`);
    } else {
      history.push(`${PATH_HUB_ENROLMENT_CHANGE_SCHEDULE}?token=${enrolmentId}`);
    }
  };

  return (
    <Fragment>
      <EnrolmentLayout
        isLoading={pauseRequestLoading}
        sectionHeading={`${studentName} ${enrolmentSubject}`}
        mainHeading="Manage enrolment pause"
        enrolmentId={enrolmentId}
        hasCTAAboveFooter
        tracking={TRACKING_MP.PAGE}
      >
        <div className="container mx-auto">
          {!!enrolmentPolicyError && (
            <Alert
              className="my-2"
              type="error"
              body={`There was an error retrieving this enrolment's policy. ${enrolmentPolicyError}`}
            />
          )}
          <EnrolmentBrief
            leftColumnTitle={resumeFrom ? 'Enrolment set to pause:' : 'ENROLMENT PAUSED UNTIL'}
            leftColumnContent={`From ${pausedFromFormatted} ${
              resumeFrom ? `resuming ${resumeDateFormatted}` : pauseToDateFormatted
            }`}
            tutorName={tutorName ?? ''}
            tutorPhoto={tutorPhoto ?? ''}
          />
          {!summerPauseEnabled && (
            <div className="mb-12">
              <PolicyAlert
                alertType="warning"
                title={title}
                body={body}
                color="bg-yellow-1"
                href={PATH_HUB_PLAN_DETAILS}
              />
            </div>
          )}
          {resumeDates?.length > 0 ? (
            <ManagePauseOptions
              options={options}
              /* 
                  Resume date is from Sales Force which is always in Syndey timezone;
                  Notwithstanding account time zone

                  Explicitly casting the time zone to Australia/Sydney is essential
                  so moment won't set it automatically to the machine/local time zone
                */
              defaultOption={resumeFrom ? moment(resumeFrom).tz('Australia/Sydney', true).utc().toISOString() : ''}
              onSubmitHandler={onKeepScheduleHandler}
              incentive={incentive ?? ''}
            />
          ) : (
            <LinkButton to={PATH_HUB_HOME} appearance="reverse" size="xs">
              Home
            </LinkButton>
          )}

          {incentive && (
            <div className="mt-5 px-4">
              <SummerPromoLegalCopy />
            </div>
          )}
        </div>
        <div className="mt-10 bg-slate-1 py-11 text-center">
          <p className="mx-10 mb-5 font-display text-xl font-bold lg:text-2xl">Need to change your ongoing schedule?</p>
          <Button
            appearance="reverse"
            size="sm"
            tracking={TRACKING_MP.CTA_CHANGE_SCHEDULE}
            onClick={onManageScheduleClick}
          >
            Manage schedule
          </Button>
        </div>
        {summerPauseEnabled && <PreferToStopSessions enrolmentId={enrolmentId} />}
      </EnrolmentLayout>
    </Fragment>
  );
};
