import React from 'react';
import PropTypes from 'prop-types';

export const ChoiceButton = ({
  onClick,
  disabled,
  type,
  children,
  className,
}) => {
  return (
    <button
      type={type === 'submit' ? 'submit' : 'button'}
      disabled={disabled}
      onClick={onClick}
      className={`leading-7 rounded-lg bg-slate-1 px-6 py-4 text-left text-2xl font-bold text-blue-5 hover:text-purple-5 ${className}`}
      color="default"
    >
      {children}
    </button>
  );
};

ChoiceButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ChoiceButton.defaultProps = {
  onClick: () => {},
  disabled: false,
  type: 'button',
  className: '',
};
