import React from 'react';
import PropTypes from 'prop-types';
import { GenericTextSection } from './GenericTextSection';

export class TutorRecommendations extends React.Component {
  render() {
    const { text, tutorName } = this.props;
    return (
      <GenericTextSection
        text={text}
        title="TUTOR RECOMMENDATIONS"
        messageWhenEmpty={`${tutorName} did not provide any recommendations for the session.`}
      />
    );
  }
}

TutorRecommendations.propTypes = {
  text: PropTypes.string,
  tutorName: PropTypes.string.isRequired,
};

TutorRecommendations.defaultProps = {
  text: null,
};
