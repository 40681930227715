import * as types from './actionTypes';
import { logError, WARNING } from '../logging';
import { apiFetch } from '../services/backendApi';
import { REQ_GET_LOGIN } from '../services/backendApi/endpointTypes';
import { getParentTokenWithSessionReportToken } from './upcomingSessionsActions';
import { fetchDisciplineList } from './studentProgressActions';

export function srReceiveLoginInfo(json) {
  const serverTimestamp = json.currentTimestamp;
  let clockSkew = false;
  let timeDiff = 0;
  if (serverTimestamp > 0) {
    timeDiff = new Date().getTime() - serverTimestamp;
    clockSkew = Math.abs(timeDiff) > 240000; // 4 mins
    if (clockSkew) {
      logError(
        'Clock skew detected - debug',
        { timeOffset: timeDiff },
        { level: WARNING }
      );
    }
  }

  const payload = {
    ...json,
    clockSkew: {
      skew: clockSkew,
      timeOffset: timeDiff,
    },
  };
  return { type: types.SR_LOGIN_RECEIVE_INFO, payload };
}

export function srLogin(token) {
  return (dispatch, getState) => {
    dispatch({ type: types.SR_LOGIN_SET_URL_TOKEN, payload: { token } });
    return apiFetch(REQ_GET_LOGIN, getState())
      .then((json) => {
        dispatch(srReceiveLoginInfo(json));
        dispatch(getParentTokenWithSessionReportToken(token));
        dispatch(fetchDisciplineList());
      })
      .catch((error) => {
        if (error.statusCode === 404) {
          dispatch({ type: types.SR_LOGIN_API_FAILURE_DEFINITIVE });
        } else {
          dispatch({ type: types.SR_LOGIN_API_FAILURE });
        }
      });
  };
}
