import {
    PATH_HUB_ACCOUNT_DETAILS,
    PATH_HUB_INVOICE_HISTORY,
    PATH_HUB_PLAN_DETAILS,
} from '../../../../util/pagePath';

export type NavLinkItemProps = {
    to: string;
    name: string;
    isActive?: boolean;
};

export const planDetailsProps: NavLinkItemProps = {
    to: PATH_HUB_PLAN_DETAILS,
    name: 'Plan details',
};

export const accountDetailsProps: NavLinkItemProps = {
    to: PATH_HUB_ACCOUNT_DETAILS,
    name: 'Contact details',
};

export const invoiceHistoryProps: NavLinkItemProps = {
    to: PATH_HUB_INVOICE_HISTORY,
    name: 'Payment & Billing',
};
export const BASE_ACCOUNT_PATH = '/portal/customer/account'
export const ACCOUNT_LINK_PLAN = 'ACCOUNT_LINK_PLAN';
export const ACCOUNT_LINK_DETAILS = 'ACCOUNT_LINK_DETAILS';
export const ACCOUNT_LINK_BILLING = 'ACCOUNT_LINK_BILLING';
export const ACCOUNT_LINK_INVOICES = 'ACCOUNT_LINK_INVOICES';

export const accountLinkMap = [
    planDetailsProps,
    accountDetailsProps,
    invoiceHistoryProps,
];
