import { Link } from '@cluey/cluey-components';
import classNames from 'classnames';

export type Breadcrumb = {
  label: string;
  to?: string;
  onClick?: () => void;
};

type PageTitleProps = {
  title: string;
  className?: string;
  breadcrumbs?: Breadcrumb[];
};

export const PageTitle = ({ title, className, breadcrumbs }: PageTitleProps) => {
  return (
    <div className={classNames('container mx-auto flex flex-col pb-9', className)}>
      {breadcrumbs && (
        <div className="flex gap-2">
          {breadcrumbs.map((breadcrumb, index) => (
            <span key={breadcrumb.label}>
              <Link
                size="xs"
                className="mb-3 font-bold uppercase tracking-widest lg:mb-2"
                to={breadcrumb.to}
                onClick={breadcrumb.onClick}
              >
                {breadcrumb.label}
              </Link>
              {index !== breadcrumbs.length - 1 && <span className="mb-3 text-xs font-bold lg:mb-2 pl-2">&gt;</span>}
            </span>
          ))}
        </div>
      )}
      <h1 className="font-display text-[32px] md:text-[40px] font-bold">{title}</h1>
    </div>
  );
};
