import classnames from 'classnames';

export interface OptionButtonProps {
  label: string;
  value?: string;
  onClick(): void;
  isSelected?: boolean;
  className?: string;
}

export const OptionButton = ({ label, value, onClick, isSelected, className }: OptionButtonProps) => {
  const buttonClassNames = classnames(
    'proxima-soft block w-full rounded-lg bg-slate-1',
    'px-6 py-[20px] text-left text-lg font-bold text-blue-5 hover:ring',
    'hover:ring-blue-1 focus:ring focus:ring-blue-1 border-2 border-solid',
    className
  );

  return (
    <button
      className={classnames(buttonClassNames, isSelected ? 'border-primary bg-slate-2' : 'border-slate-1')}
      key={value || label}
      onClick={onClick}
    >
      {label}
    </button>
  );
};
