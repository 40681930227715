import { useLocation, Link } from 'react-router-dom';
import { ClueyLogoBase, SiteTitle } from '@cluey/cluey-components';

import { updateAppView } from '../../actions/contactUsFlowActions';
import { APP_VIEW_DEFAULT } from '../../util/constants';
import { PATH_HUB_HOME } from '../../util/pagePath';
import ClueyLogo from '../../assets/images/cluey-logo.svg';
import { isTutor as isLocalUserTutor } from '../../selectors/loginSelector';
import { useSelector, useDispatch } from 'react-redux';
import useWindowWidth from '../../hooks/common/useWindowWidth';
import { BREAKPOINTS } from '../../util/constants';

interface Props {
	title: string;
	isNZUser: boolean; // eslint-disable-line
}

/* @TODO add test */
const Header = ({ title }: Props) => {
	const dispatch = useDispatch();
	const isTutor = useSelector(isLocalUserTutor);
	const windowWidth = useWindowWidth();

	const location = useLocation();
	const currentLocation = `${location.pathname}${location.search}`;

	const onLogoClick = (e) => {
		e.preventDefault();
		dispatch(updateAppView({ appView: APP_VIEW_DEFAULT }));
	};

	return (
		<header className="header container mx-auto flex items-center px-4">
			<Link to={currentLocation} onClick={onLogoClick}>
				<ClueyLogoBase src={ClueyLogo} />
			</Link>

			{windowWidth > BREAKPOINTS.md ? <SiteTitle title={title} /> : <SiteTitle title="" />}
			<div className="ml-auto flex items-center bg-white text-base text-primary lg:py-0">
				<div className="container m-auto flex flex-col items-center px-0 lg:flex-row">
					{!isTutor ? (
						<Link to={PATH_HUB_HOME} className="h-full px-4 font-bold hover:underline">
							Home
						</Link>
					) : (
						<a href="https://tutor-hub.clueylearning.com" className="h-full px-4 font-bold hover:underline">
							Home
						</a>
					)}
				</div>
			</div>
		</header>
	);
};

export { Header };
