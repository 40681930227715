import React from 'react';
import PropTypes from 'prop-types';

import InfoModalLayout from './InfoModalLayout';
import creditCard from '../../../../assets/images/hubContact/credit-card.svg';
import { PATH_HUB_INVOICE_HISTORY } from '../../../../util/pagePath';

const UpdatePaymentInformationModal = ({ isModalOpen, toggleModal }) => {
  return (
    <InfoModalLayout
      isModalOpen={isModalOpen}
      toggleModal={toggleModal}
      modalIcon={creditCard}
      title="Update payment information"
      renderIntro={() => (
        <p className="mb-0 font-body text-base lg:text-lg">
          You can update payment information in the Billing section, click “
          <span className="font-bold">GO TO BILLING</span>” to proceed.
        </p>
      )}
      redirectText="GO TO BILLING"
      redirectLink={PATH_HUB_INVOICE_HISTORY}
    />
  );
};

UpdatePaymentInformationModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default UpdatePaymentInformationModal;
