import { ReactComponent as EmptyStar } from '../../../../../assets/images/progressStars/star-0.svg';
import { ReactComponent as FilledStar } from '../../../../../assets/images/progressStars/star-100.svg';
import { range } from 'lodash';
import { Props as CancellationStepsProps } from '../CancellationSteps'

export interface Props extends Pick<CancellationStepsProps, 'setCancelSelection'> {
  rating: number;
}

const CancelRating = ({ rating, setCancelSelection }: Props) => {
  const onStarClick = (index: number) => setCancelSelection(current => ({...current, rating: index}));

  return (
    <div>
      <p className="text-center text-blue-5 font-bold text-lg">Rate your experience with Cluey</p>
      <div className='flex justify-center gap-x-5 my-5 min-h-[42px]'>
        {range(5).map((index) => (
          <button
            key={`star-${index + 1}`}
            onClick={() => onStarClick(index + 1)}
            type='button'>
            {index + 1 <= rating ? <FilledStar /> : <EmptyStar />}
          </button>
        ))}
      </div>
    </div>
  )
}

export default CancelRating;