import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { api } from '../api';

const useReinitializeBeacon = () => {
  const location = useLocation();
  const { data: details } = api.account.details.useQuery();

  useEffect(() => {
    if (window.SalesforceInteractions && typeof window.SalesforceInteractions.sendEvent === 'function') {
      try {
        window.SalesforceInteractions.reinit();
      } catch (error) {
        console.error('Error reinitializing MCP beacon', error);
      }
      console.log('MCP Beacon reinitialization', location.pathname, window.location.href);
    } else {
      console.error('SalesforceInteractions.sendEvent is not available.');
    }
  }, [location.pathname, details]);

  useEffect(() => {
    if (window.SalesforceInteractions && details?.email) {
      console.log('MCP Identify user');
      try {
        window.SalesforceInteractions.sendEvent({
          interaction: {
            name: 'Identify',
          },
          user: {
            identities: {
              emailAddress: details.email,
            },
            attributes: {
              _sfUserId: details.customerSfid,
              firstName: details.firstName,
              lastName: details.lastName,
              state: details.curriculum,
            }
          },
        });
      } catch (error) {
        console.error('Error identifying with email', error);
      }
    }
  }, [details]);
};

export default useReinitializeBeacon;