import { BasicCard, Button } from '@cluey/cluey-components';
import { PRICING_PLAN_ROWS, useHasActiveGroupEnrolments } from '../../../helpers';
import { PricePlan } from '../../../../../types/hubTypes';
import { Link } from 'react-router-dom';
import { PATH_HUB_CHANGE_PLAN } from '../../../../../util/pagePath';

export type PricingPlanDetailsSectionProps = {
  plan: PricePlan;
  wrapperClassName?: string;
  chipContent?: string;
};

const PricingPlanDetailsSection = ({ plan, wrapperClassName, chipContent }: PricingPlanDetailsSectionProps) => {
  const hasGroupEnrolments = useHasActiveGroupEnrolments();
  return (
    <BasicCard className={wrapperClassName}>
      <div className="flex w-full flex-col overflow-hidden md:flex-row">
        <div className=" relative flex basis-1/3 flex-col  items-center justify-center border-r border-grey-2 bg-[#f2f5fa] px-4 py-8 ">
          {chipContent && (
            <div className="absolute right-3 top-3 flex items-center justify-center rounded-lg bg-blood-orange-4 p-2 text-sm font-bold leading-3 text-white md:top-3 md:h-auto md:p-4">
              {chipContent}
            </div>
          )}
          <div className="proxima-nova mt-4 text-xl font-bold text-grey-6 md:text-2xl">{plan.pricingPlanName}</div>
          <div className="proxima-nova text-5xl font-bold text-grey-6 ">${plan.privatePriceTier.costPerSession}</div>
          <div className="proxima-nova text-base font-bold  text-grey-6">per session</div>
          {hasGroupEnrolments && (
            <div className="mt-5 text-xs text-grey-4">${plan.groupPriceTier.costPerSession} per group session</div>
          )}
        </div>
        <div className="flex basis-2/3 flex-col justify-start p-8 align-middle">
          <div className="mb-4 text-xs font-bold tracking-[1px]">PLAN POLICY</div>
          <div className="flex flex-col">
            {PRICING_PLAN_ROWS.map((row) => (
              <div
                key={row.header}
                className="flex flex-col border-b-2 border-grey-2 pb-2 pt-3 last:border-none lg:flex-row"
              >
                <div className="flex basis-1/2 text-base font-bold text-grey-6 ">
                  {row.getHeader(plan, hasGroupEnrolments)}
                </div>
                <div className="flex basis-1/2 text-grey-6  ">{row.getContent(plan, hasGroupEnrolments)}</div>
              </div>
            ))}
          </div>
          <div className="mt-4 text-sm text-grey-4">
            The cancellation or change of plan can only take effect after the last paid session
          </div>
          {plan?.allowTransitionTo && (
            <div className="mt-6">
              <Link to={PATH_HUB_CHANGE_PLAN}>
                <Button className="w-full md:w-auto" appearance="reverse">
                  Change plan
                </Button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </BasicCard>
  );
};
export default PricingPlanDetailsSection;
