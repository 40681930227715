import moment from 'moment';
import { apiFetch } from '../services/backendApi';
import { logError } from '../logging';
import { REQ_GET_SUMMER_MODE_DATA } from '../services/backendApi/endpointTypes';
import * as actionTypes from './summerModeActionTypes';

import { submitCaseToSF } from './hub/hubContactPageActions';
import { SF_CASES_DESCRIPTION_DATE_FORMAT } from '../util/constants';
import { SummerModeService } from '../services/SummerModeService';

export const fetchSummerModeData =
  ({ customerLinkId }) =>
  (dispatch, getState) => {
    if (!customerLinkId || moment().isSameOrAfter('2023-03-01', 'day')) {
      return Promise.resolve();
    }

    const requestPayload = {
      customerLinkId,
    };

    return apiFetch(REQ_GET_SUMMER_MODE_DATA, getState(), requestPayload, dispatch)
      .then((res) => {
        dispatch({
          type: actionTypes.SM_FETCH_SUMMER_MODE_DATA_SUCCESS,
          payload: {
            wizardToken: customerLinkId,
            ...res,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.SM_FETCH_SUMMER_MODE_DATA_FAILED,
        });
        logError(e, null, { message: `Unable to get summer data with token: ${customerLinkId}` });
      });
  };

export const saveSummerModeData =
  ({
    salesForceEnrolmentId,
    enrolmentId,
    selectedSummerMode = 'Pause',
    summerPauseFrom,
    summerPauseTo,
    summerModeHolidayPractice,
    summerModeNewScheduleStartTime,
    summerModeNewScheduleDay,
    summerModeFrequency,
    summerModeHoldingGroup,
    appliedSummerModeIncentive,
    summerModeAdditionalDetails,
    shouldApplyIncentive = false,
    onSubmitSuccess = () => {},
    onSubmitFailed = () => {},
  }) =>
  (dispatch) => {
    const requestPayload = {
      salesForceEnrolmentId,
      enrolmentId,
      selectedSummerMode,
      summerPauseFrom,
      summerPauseTo,
      summerModeHolidayPractice,
      summerModeNewScheduleStartTime,
      summerModeNewScheduleDay,
      summerModeFrequency,
      summerModeHoldingGroup,
      appliedSummerModeIncentive,
      shouldApplyIncentive,
      summerModeAdditionalDetails,
    };

    dispatch({
      type: actionTypes.SM_SAVE_SUMMER_MODE_DATA_START,
    });

    const summerModeService = new SummerModeService();

    return summerModeService
      .updateSummerModeData(requestPayload)
      .then((res) => {
        dispatch({
          type: actionTypes.SM_SAVE_SUMMER_MODE_DATA_SUCCESS,
          payload: {
            ...res,
          },
        });
        onSubmitSuccess();
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.SM_SAVE_SUMMER_MODE_DATA_FAILED,
        });
        onSubmitFailed();
        logError(e, null, { message: `Unable to save summer data for: ${enrolmentId}` });
      });
  };

export const managePauseSummerMode =
  ({ enrolmentId, currentPauseStart, currentResumeDate, selectedResumeDate, callback }) =>
  (dispatch, getState) => {
    if (moment(currentResumeDate).isSame(moment(selectedResumeDate), 'day')) {
      return callback();
    }

    let sfAction = 'Extend pause';
    // Note, to align with SF, we need to minus one day from the selected resume date
    // so SF could calculate the correct resume session.
    let datesPayload = {
      startDate: currentPauseStart,
      endDate: moment(selectedResumeDate).subtract(1, 'days').utc().toISOString(),
    };

    if (moment(selectedResumeDate).isBefore(moment(currentResumeDate), 'day')) {
      sfAction = 'Restart';
      datesPayload = {
        startDate: moment(selectedResumeDate).utc().toISOString(),
      };
    }

    const state = getState();
    const { timezone } = state.hubUser;

    const caseType = 'Scheduling';
    const caseCategory = 'Pause sessions';

    const resumeDateFormatted = moment(selectedResumeDate).tz(timezone).format(SF_CASES_DESCRIPTION_DATE_FORMAT);

    const payload = {
      page: 'manage-pause',
      action: sfAction,
      uniqueEnrolmentId: enrolmentId,
      message: `Request to ${sfAction} and resume on ${resumeDateFormatted}`,
      caseSubject: `Contact request - ${caseType} - ${caseCategory}`,
      caseType,
      caseCategory,
      onSubmitSuccess: () => {
        callback();
      },
      ...datesPayload,
    };
    return dispatch(submitCaseToSF(payload));
  };
