import { Fragment, useEffect, useState } from 'react';
import { compose } from 'redux';
import { get, sortBy } from 'lodash';
import { getSessionsInViewByIndex, filterSchoolTerms, isPaymentFailed } from '../../../util/helpers';

import { fetchUpcomingSessionsWithSTPH } from '../../../actions/hub/hubSessionsPageActions';
import { isLoadingUpcomingSessionsData } from '../../../selectors/hubSelector';
import withHeadTag from '../../../common/HeadComponent';
import HubPageLayout from '../../../common/hub/HubPageLayout';
import InPageLoader from '../../../common/InPageLoader';
import PageError from '../../../common/PageError';
import ErrorBlock from '../../../common/ErrorBlock';
import SessionsPageContentHeadNav from '../../../components/hub/sessions/SessionsPageContentHeadNav';
import { HubSessionsListItem } from '../../../components/hub/sessions/HubSessionsListItem';
import SchoolTermListItem from '../../../components/hub/sessions/SchoolTermListItem';
import RecordsPagination from '../../../components/hub/RecordsPagination';
import { NO_OF_SESSIONS_PER_PAGE, SPECIAL_DATE_TYPE } from '../../../util/constants';
import { getSummerModeCompleted } from '../../../selectors/summerModeSelector';
import { ContextualAlert } from '../../../components/ContextualAlert/ContextualAlert';
import { SELECTED_SUMMER_MODE } from '../../../util/summerCopy';
import { PATH_HUB_CR_OTHER_QUERY, PATH_HUB_ENROLMENT_DETAIL } from '../../../util/pagePath';
import { getPaymentFailureAlertInfo } from '../../../util/helper';
import { PaymentFailureAlertBox } from '../../../components/hub/home/InfoBox';
import NextYearEnrolmentsAlerts from '../../../components/EnrolmentManagement/NextYearEnrolmentsAlerts';
import { useDispatch, useSelector } from 'react-redux';
import { InitialState } from '../../../types/hubTypes';
import { Enrolment } from '../../../api/types/enrolment';
import { api } from '../../../api';
import { convertSchoolTerms } from '../../../util/helper/schoolTerm';
import moment from 'moment';
import { orderBy } from 'lodash';

const UpcomingSessions = () => {
	const { data: terms, isLoading: termsLoading } = api.schoolTerm.all.useQuery();
	const { data: accountDetails, isLoading: accountDetailsLoading } = api.account.details.useQuery();
	const { data: upcomingSessions = [], isLoading: loadingSessions } = api.session.all.useQuery('upcoming', {
		select: (data) => {
			const filteredSessions = data.filter((s) => {
				return moment(s.startTime).isSameOrAfter(new Date(), 'day');
			});
			return orderBy(filteredSessions, ['startTime'], ['asc']);
		},
	});

	const [{ allSessions, sessionsInView, totalSessions, startIndex }, setSessionDetails] = useState({
		allSessions: [],
		totalSessions: 0,
		sessionsInView: [],
		startIndex: 0,
	});
	const dispatch = useDispatch();
	const activeSpinner = useSelector(isLoadingUpcomingSessionsData);
	const errorLoading = useSelector((state: InitialState) => state.ui.apiState.hubCompletedSessionsPage.fetchError);
	const timezone = accountDetails?.timezone ?? 'Australia/Sydney';
	const upcomingSessionsWithFailedPayment = upcomingSessions?.filter((sesh) => {
		return isPaymentFailed(sesh.paymentStatus);
	});
	const schoolTerms = convertSchoolTerms({ terms });
	const summerModeAlerts: Array<Enrolment> = useSelector(getSummerModeCompleted);

	useEffect(() => {
		dispatch(fetchUpcomingSessionsWithSTPH());
	}, [dispatch]);

	useEffect(() => {
		if (upcomingSessions?.length > 0 && allSessions.length === 0) {
			let sortedSessions = sortBy(upcomingSessions, ['startTime', 'student', 'subject']);
			// Will fix sessions page banner later. Now just disable this part so customer will see all their sessions
			/*
      if (summerModeAlerts.length > -1) {
        // need cutoff date
        const completedIds = summerModeAlerts.map(smAlert => smAlert.id);
        sortedSessions = sortedSessions.filter((session) => {
          if (!completedIds.includes(session.uniqueEnrolmentId)) {
            return true;
          }
          return moment(session.startTime).isBefore(moment(SUMMER_MODE_START_DATE));
        });
      }
      */
			/**
			 * Filter sessions from summerMode data
			 * Insert the alerts after the cutoff
			 */
			const lastSession = sortedSessions[sortedSessions.length - 1];
			const filteredSchoolTerms = filterSchoolTerms(schoolTerms, lastSession.startTime);
			const mixedSessionsAndTerms = [...sortedSessions, ...filteredSchoolTerms, ...summerModeAlerts];
			const sortedMixedSessionsAndTerms = sortBy(mixedSessionsAndTerms, ['startTime', 'student', 'subject']);
			const sessionsInView = getSessionsInViewByIndex(sortedMixedSessionsAndTerms, startIndex);
			setSessionDetails((current) => {
				return {
					...current,
					allSessions: sortedMixedSessionsAndTerms,
					totalSessions: sortedMixedSessionsAndTerms.length,
					sessionsInView,
				};
			});
		}
	}, [allSessions.length, startIndex, schoolTerms, summerModeAlerts, upcomingSessions]);

	const handlePaginationClick = (newStartIndex) => {
		setSessionDetails((current) => {
			return {
				...current,
				sessionsInView: getSessionsInViewByIndex(current.allSessions, newStartIndex),
				startIndex: newStartIndex,
			};
		});
	};

	let display = null;
	if (upcomingSessions.length === 0 && !loadingSessions) {
		display = <ErrorBlock errorMsg="You have no upcoming sessions." />;
	}

	if (Array.isArray(sessionsInView) && sessionsInView.length > 0) {
		display = (
			<div className="flex flex-col gap-4">
				{sessionsInView.map((s, index) => {
					const isLastItem = sessionsInView.length === index + 1;
					const type = get(s, 'type', '').toLowerCase();
					if (type === SPECIAL_DATE_TYPE.SCHOOL_TERM) {
						return <SchoolTermListItem key={s.startTime} termItem={s} timezone={timezone} isLastItem={isLastItem} />;
					}
					if (type === SPECIAL_DATE_TYPE.SUMMER_ALERT) {
						return (
							<ContextualAlert
								key={s.id}
								body={s.body}
								subtitle={s.subtitle}
								title={s.title}
								cta={
									s.action === SELECTED_SUMMER_MODE.PAUSE
										? { to: `${PATH_HUB_ENROLMENT_DETAIL}?token=${s.id}`, text: 'Manage enrolment' }
										: { to: PATH_HUB_CR_OTHER_QUERY, text: 'Contact us to manage' }
								}
								ctaWrap={s.action !== SELECTED_SUMMER_MODE.PAUSE}
							/>
						);
					}
					return <HubSessionsListItem key={s.uniqueEnrolmentId + s.startTime} session={s} timezone={timezone} />;
				})}
			</div>
		);
	}

	const paymentFailureAlert = getPaymentFailureAlertInfo(upcomingSessionsWithFailedPayment);
	return (
		<HubPageLayout>
			{(activeSpinner || termsLoading || accountDetailsLoading || loadingSessions) && (
				<InPageLoader position="absolute" />
			)}
			{!activeSpinner && errorLoading && <PageError activePage="hub-page" retryEnabled={false} />}
			{!activeSpinner && !errorLoading && (
				<Fragment>
					{paymentFailureAlert && (
						<div className="mb-lg-11 mb-10">
							<PaymentFailureAlertBox message={paymentFailureAlert} />
						</div>
					)}
					<SessionsPageContentHeadNav />
					{display}
					<NextYearEnrolmentsAlerts />
					<RecordsPagination
						recordsTotal={totalSessions}
						noOfRecordsPerPage={NO_OF_SESSIONS_PER_PAGE}
						handlePaginationClick={handlePaginationClick}
					/>
				</Fragment>
			)}
		</HubPageLayout>
	);
};

export default compose(withHeadTag(UpcomingSessions));
