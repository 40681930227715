import initialState from '../../initialState';
import * as actionTypes from '../../../actions/actionTypes';

export default function hubAuthPageReducer(state = initialState.ui.apiState.hubAuthPage, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.HUB_AUTH_RESET_AUTH_UI_STATE:
      return {
        ...state,
        fetchError: false,
        isLoading: false,
        errorMsg: '',
      };
    case actionTypes.HUB_AUTH_FIRST_LOG_IN_START:
    case actionTypes.HUB_AUTH_LOG_IN_START:
    case actionTypes.HUB_AUTH_RESET_PASSWORD_REQUEST_START:
    case actionTypes.HUB_AUTH_GET_USER_INFO_START:
    case actionTypes.HUB_AUTH_SET_NEW_PASSWORD_START:
    case actionTypes.HUB_AUTH_RESEND_SIGNUP_LINK_REQUEST_START:
      return {
        ...state,
        fetchError: false,
        isLoading: true,
        errorMsg: '',
      };
    case actionTypes.HUB_AUTH_FIRST_LOG_IN_SUCCESS:
    case actionTypes.HUB_AUTH_LOG_IN_SUCCESS:
    case actionTypes.HUB_AUTH_SIGN_UP_SUCCESS:
    case actionTypes.HUB_AUTH_RESET_PASSWORD_REQUEST_SUCCESS:
    case actionTypes.HUB_AUTH_GET_USER_INFO_SUCCESS:
    case actionTypes.HUB_AUTH_SET_NEW_PASSWORD_SUCCESS:
    case actionTypes.HUB_AUTH_RESEND_SIGNUP_LINK_REQUEST_SUCCESS:
      return {
        ...state,
        fetchError: false,
        isLoading: false,
        errorMsg: '',
      };
    case actionTypes.HUB_AUTH_FIRST_LOG_IN_FAILED:
      return {
        ...state,
        fetchError: true,
        isLoading: false,
        errorMsg: payload.errorMsg,
      };
    case actionTypes.HUB_AUTH_GET_USER_INFO_FAILED:
      return {
        ...state,
        fetchError: true,
        isLoading: false,
      };
    case actionTypes.HUB_AUTH_LOG_IN_FAILED:
    case actionTypes.HUB_AUTH_RESET_PASSWORD_REQUEST_FAILED:
    case actionTypes.HUB_AUTH_SET_NEW_PASSWORD_FAILED:
    case actionTypes.HUB_AUTH_USER_UN_AUTHENTICATED:
    case actionTypes.HUB_AUTH_RESEND_SIGNUP_LINK_REQUEST_FAILED:
      return {
        ...state,
        fetchError: true,
        isLoading: false,
        errorMsg: payload.errorMsg,
      };
    default:
      return state;
  }
}
