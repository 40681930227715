import Papa from 'papaparse';
import nswTopicsCsv from './nsw-topics.csv';
import vicTopicsCsv from './vic-topics.csv';
import qldTopicsCsv from './qld-topics.csv';
import saTopicsCsv from './sa-topics.csv';
import waTopicsCsv from './wa-topics.csv';
import tasTopicsCsv from './tas-topics.csv';

export type Subject =
  | 'Mathematics'
  | 'General Mathematics'
  | 'Mathematical Applications'
  | 'Mathematics Extension 1'
  | 'Mathematics Advanced'
  | 'Mathematical Methods'
  | 'Mathematics Standard'
  | 'Mathematics Extension 2'
  | 'Maths LANTITE'
  | 'Mathematics Methods'
  | 'Specialist Mathematics'
  | 'Chemistry VCE'
  | 'Chemistry QCAA'
  | 'Chemistry SACE'
  | 'Chemistry WACE & SSC'
  | 'Chemistry TASC'
  | 'Chemistry HSC'
  | 'Chemistry'
  | 'Biology'
  | 'Physics';

export type Topic = {
  state: string;
  curriculum: string;
  subject: Subject;
  topicName: string;
  label: string;
  topicKey: string;
};

// Modify the parser to work with Vite's CSV parsing
export function parseTopicsFromViteCSV(csvData): Topic[] {
  const topics: Topic[] = [];

  const parseResult = Papa.parse(csvData, {
    header: true,
    delimiter: ',',
    skipEmptyLines: true,
  });

  for (const row of parseResult.data as { [key: string]: string }[]) {
    const { state, curriculum, subject, topic_name: topicName, label, topic_key: topicKey } = row;

    topics.push({
      state,
      subject: subject as Subject,
      curriculum,
      topicName,
      label,
      topicKey,
    });
  }

  return topics;
}

export const NSW_TOPICS = parseTopicsFromViteCSV(nswTopicsCsv);
export const VIC_TOPICS = parseTopicsFromViteCSV(vicTopicsCsv);
export const QLD_TOPICS = parseTopicsFromViteCSV(qldTopicsCsv);
export const SA_TOPICS = parseTopicsFromViteCSV(saTopicsCsv);
export const WA_TOPICS = parseTopicsFromViteCSV(waTopicsCsv);
export const TAS_TOPICS = parseTopicsFromViteCSV(tasTopicsCsv);
