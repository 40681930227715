import env from '../env';
import { ddLogger, ddLoggerLevel, logError } from '../logging';
import { sendPostRequest } from './RequestService';

class AuthService {
  static async verifyEmail(payload: { email: string }) {
    const portalHost = env.REACT_APP_CLUEY_API_HOST_NAME;
    const path = `/customer-hub/customer/cognito-status`;
    const url = `https://${portalHost}${path}`;
    try {
      const res = await sendPostRequest({
        url,
        reqOptions: {
          host: env.REACT_APP_CLUEY_API_HOST_NAME,
          path: `customer/cognito-status`,
        },
        payload,
        customErrorMsg: 'There was an error when trying to verify your email.',
      });
      const data = await res.json();
      return data;
    } catch (error) {
      const timestamp = new Date().toISOString();
      logError('Failed to verify user email', { payload, timestamp }, {});
      ddLogger({
        level: ddLoggerLevel.ERROR,
        label: 'POST failed',
        data: {
          payload,
          timestamp,
        },
        error,
      });
      throw error;
    }
  }
}

export default AuthService;
