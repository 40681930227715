import initialState from './initialState';
import * as actionTypes from '../actions/actionTypes';

export default function hubEnrolmentDetailPageReducer(state = initialState.hubEnrolmentDetailPage, action) {
  switch (action.type) {
    case actionTypes.HUB_EM_SAVE_ENROLMENT_ID_FOR_MANAGEMENT:
      return {
        ...state,
        enrolmentId: action.payload.enrolmentId,
      };
    case actionTypes.HUB_EP_SAVE_ENROLMENT_DETAIL_PAGE_TOKEN:
      return {
        ...state,
        enrolmentId: action.payload.enrolmentId,
      };
    case actionTypes.HUB_EP_FETCH_ENROLMENT_DETAIL_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.HUB_EP_FETCH_ENROLMENT_DETAIL_FAILED:
    case actionTypes.HUB_EP_RELOAD_ENROLMENT_DETAIL_REQUIRED:
      return {
        ...state,
        enrolmentId: '',
      };
    case actionTypes.HUB_EP_FETCH_COURSE_OVERVIEW_SUCCESS:
      return {
        ...state,
        courseOverview: {
          ...state.courseOverview,
          ...action.payload,
        },
      };
    case actionTypes.HUB_EP_FETCH_PROGRESS_SUMMARY_SUCCESS:
      return {
        ...state,
        progressSummary: {
          ...state.progressSummary,
          ...action.payload,
        },
      };
    case actionTypes.HUB_EM_SAVE_PREVIOUS_TUTOR_NUMBER:
    case actionTypes.HUB_EM_FETCH_TUTOR_INFO_SUCCESS:
      return {
        ...state,
        alternativeTutor: {
          ...state.alternativeTutor,
          ...action.payload,
        },
      };
    case actionTypes.HUB_EM_SAVE_SESSION_INFO_WITH_NEW_TUTOR:
      return {
        ...state,
        sessionWithNewTutor: {
          ...state.sessionWithNewTutor,
          ...action.payload,
        },
      };
    case actionTypes.HUB_EM_FETCH_TUTOR_AVAILABILITY_SUCCESS:
      return {
        ...state,
        alternativeTutor: {
          ...state.alternativeTutor,
          ...action.payload,
        },
      };
    case actionTypes.HUB_EM_SAVE_SELECTED_TUTOR_SCHEDULE:
      return {
        ...state,
        selectedSchedule: {
          ...state.selectedSchedule,
          ...action.payload,
        },
      };
    case actionTypes.HUB_EM_MP_RESET_PAGE_STATE:
      return {
        ...state,
        managePause: {
        },
      };
    case actionTypes.HUB_EM_MP_SAVE_RESUME_SESSION:
    case actionTypes.HUB_EM_MP_FETCH_RESUME_SESSION_SUCCESS:
      return {
        ...state,
        managePause: {
          ...state.managePause,
          ...action.payload,
        },
      };
    case actionTypes.HUB_TTC_FETCH_SESSION_TUTOR_START:
    case actionTypes.HUB_TTC_FETCH_SESSION_TUTOR_FAILED:
      return {
        ...state,
        tempTutorChange: {},
      };
    case actionTypes.HUB_TTC_FETCH_SESSION_TUTOR_SUCCESS:
      return {
        ...state,
        tempTutorChange: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
