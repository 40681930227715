import { useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ButtonAsLink, Modal } from '@cluey/cluey-components';
import { EnrolmentType } from '../../types/hubPropTypes';
import { Loader } from '../../components/Loader';
import { getAccountDetails } from '../../selectors/hubSelector';
import ErrorBlock from '../../common/ErrorBlock';
import starIcon from '../../assets/images/atoms-icon-filled-star.svg';
import heartIcon from '../../assets/images/atoms-icon-filled-heart.svg';
import ribbonIcon from '../../assets/images/atoms-icon-filled-ribbon.svg';
import emojiImaginative from '../../assets/images/atoms-emoji-animated-imaginative.svg';
import emojiAttentive from '../../assets/images/atoms-emoji-animated-mostly-attentive.svg';
import emojiShy from '../../assets/images/atoms-emoji-animated-shy.svg';
import { useTutorInfoQuery } from '../../hooks/queries/useTutorInfoQuery';
import { PATH_HUB_CR_CHANGE_TUTOR } from '../../util/pagePath';
import mapStudentInformationToTutorDetails from './mapStudentInformationToTutorDetails';
import { TTBM_CUSTOMER_NUMBER } from '../../util/constants';
import { setTutorTypeDefault } from '../../util/helpers';

const skillToEmojiMap = {
	'Engaging to students who struggle to stay focused': emojiAttentive,
	'Engaging students who can lose focus': emojiAttentive,
	'Keeping highly focused students engaged and challenged': emojiAttentive,
	'Building confidence in shy students': emojiShy,
	'Harnessing the energy of outgoing students': emojiShy,
	'Building confidence in students': emojiShy,
	'Connecting with creative students': emojiImaginative,
	'Connecting with active students': emojiImaginative,
	default: starIcon,
};

export const getModalContent = (modalState) => {
	const { isLoading, error, tutorInfo, enrolment, isAccountOwner, onClickRequestTutorChange } = modalState;
	const { tutorName, tutorPhoto, tutorState, tutorType, tutorDescription } = tutorInfo;
	const { studentInformation, englishTextStudied, tutorNumber } = enrolment;
	const studentName = enrolment?.student || enrolment?.studentName; // getEnrolments endpoint returns the property 'student' while getEnrolmentDetail returns the property 'studentName'
	const { skilledAt, highlyValuedFor } = mapStudentInformationToTutorDetails(studentInformation);
	const enrolmentOwnership = isAccountOwner || !studentName ? 'your' : `${studentName}'s`;

	const isMatchingTutor = tutorNumber === TTBM_CUSTOMER_NUMBER;
	const renderHighlyValuedForSection = highlyValuedFor?.length > 0 && !isMatchingTutor && (
		<div className="mb-6">
			<div className="mb-6 font-bold tracking-wider">Highly valued for</div>
			{englishTextStudied ? (
				<div className="flex flex-row items-start py-3">
					<img src={ribbonIcon} className="mr-6 h-6" alt="bullet point icon" />
					<p>{englishTextStudied}</p>
				</div>
			) : null}
			<div className="flex flex-row items-start py-3">
				<img src={starIcon} className="mr-6 h-6" alt="bullet point icon" />
				<p>{highlyValuedFor}</p>
			</div>
		</div>
	);

	const renderSkilledAtSection = skilledAt.length > 0 && !isMatchingTutor && (
		<div className="mb-6">
			<div className="mb-6 font-bold tracking-wider">Skilled at</div>
			<ul>
				{skilledAt.map((item, index) => {
					return (
						<li key={`skilled-at-${index}`} className="flex flex-row items-center py-3">
							<img src={skillToEmojiMap[item] || skillToEmojiMap.default} className="mr-6" alt="bullet point icon" />
							<p>{item}</p>
						</li>
					);
				})}
			</ul>
		</div>
	);

	const renderTutorDescriptionSection = tutorDescription && !isMatchingTutor && (
		<div className="mb-6">
			<div className="mb-6 font-bold leading-none tracking-wider">A little about {tutorName}…</div>
			<div className="mt-6 flex flex-row items-start">
				<img src={heartIcon} className="mr-6 h-6" alt="tutor description icon" />
				<p className="">{tutorDescription}</p>
			</div>
		</div>
	);

	if (isLoading) {
		return (
			<div className="flex h-80 items-center justify-center">
				<Loader />
			</div>
		);
	} else if (error) {
		return (
			<div className="pt-12">
				<ErrorBlock errorMsg="There was a problem retrieving the data, please try again." />
			</div>
		);
	} else {
		return (
			<div className="px-8 py-8">
				<div className="w-3/4 pb-10">
					<h2 className="pb-6 font-display text-2xl font-bold tracking-[-0.02rem] md:text-4xl lg:text-[40px] lg:leading-[48px]">
						Meet the tutor
					</h2>
					<p>{`We've found a tutor whose teaching style, expertise and background aligns with ${enrolmentOwnership} learning needs.`}</p>
				</div>

				<div className="rounded-xl border border-grey-2">
					<div>
						<div className="flex flex-row border-b border-grey-2 bg-grey-1 px-16 pb-8 pt-12">
							<img
								src={tutorPhoto}
								alt="tutor avatar"
								className="mr-lg-6 mr-5 h-[60px] w-[60px] rounded-full border border-grey-3"
							/>
							<div className="flex flex-col">
								<span className="lh-1-33 mb-1 text-lg font-bold">{tutorName}</span>
								<span className="lh-1-33">
									{tutorState} {setTutorTypeDefault({ tutorType })}
								</span>
							</div>
						</div>
						<div className="border-b border-grey-2 px-16 pb-10 pt-8">
							{renderHighlyValuedForSection}
							{renderSkilledAtSection}
							{renderTutorDescriptionSection}
						</div>
					</div>

					<div className="p-16">
						<div lassName="w-5/6">
							<p className="mb-2 font-bold">Concerned with the match?</p>
							<p>
								{`Cluey tutors undergo a thorough vetting process; this includes a multi-stage interview as well as identity, reference, credential, working with children and police checks.
              If you have any concerns about a tutor assigned to ${
								isAccountOwner ? 'you' : 'your child'
							} you may request a change at any time.`}
							</p>
							<ButtonAsLink onClick={onClickRequestTutorChange}>
								<p className="mt-4 text-blue-5">Request a tutor change</p>
							</ButtonAsLink>
						</div>
					</div>
				</div>
			</div>
		);
	}
};

const TutorInfoModal = (props) => {
	const { open, closeModal, enrolment, tutorNumber } = props;
	const { data, isLoading, error } = useTutorInfoQuery(tutorNumber, open); // pass open as 2nd parameter so that the request is made (if necessary) only when the modal is opened
	const tutorInfo = data || {};
	const { customerNumber } = useSelector(getAccountDetails);
	const isAccountOwner = customerNumber === enrolment?.customerNumber; // placeholder
	const history = useHistory();
	const onClickRequestTutorChange = () => history.push(`${PATH_HUB_CR_CHANGE_TUTOR}?token=${enrolment?.enrolmentId}`);
	const modalContent = getModalContent({
		isLoading,
		error,
		tutorInfo,
		enrolment,
		isAccountOwner,
		onClickRequestTutorChange,
	});

	return (
		<Modal
			open={open}
			setOpen={closeModal}
			centered
			modalClassName="z-10"
			contentClassName="container no-gutter"
			accent
			closeButton
			closeOnBackground
		>
			{modalContent}
		</Modal>
	);
};

TutorInfoModal.propTypes = {
	open: PropTypes.bool.isRequired,
	closeModal: PropTypes.func.isRequired,
	tutorNumber: PropTypes.string.isRequired,
	enrolment: EnrolmentType,
};

TutorInfoModal.defaultProps = {
	enrolment: {},
};

export const useTutorInfoModal = () => {
	const [open, setOpen] = useState(false);
	const openModal = () => {
		setOpen(true);
	};
	const closeModal = () => setOpen(false);

	return {
		open,
		openModal,
		closeModal,
		TutorInfoModal,
	};
};
