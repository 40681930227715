import { IconProps as Props } from '../types/hubInterfaces';
import { iconDefaults } from '.';
import classNames from 'classnames';

function ArrowBackIcon(props: Props) {
	const { width, height, className } = { ...iconDefaults, ...props };
	return (
    <svg className={classNames('fill-current', className)} width={`${width}`} height={`${height}`} viewBox="0 0 24 24" fill="none">
      <title>Arrow back icon</title>
      <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
    </svg>
	);
}

export { ArrowBackIcon };
