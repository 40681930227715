import { Button, Field, Link } from '@cluey/cluey-components';
import { FC, FormEvent, PropsWithChildren, useState } from 'react';
import { Auth } from 'aws-amplify';
import type { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router';
import { Auth2Layout, InlineLoader, useRedirectIfAuthenticated } from './common';
import * as PATHS from '../../../util/pagePath';
import iconGG from '../../../assets/images/google.svg';
import iconFB from '../../../assets/images/facebook.svg';
import { z } from 'zod';
import { useDispatch } from 'react-redux';
import { getCredentials, loginSuccess } from '../../../actions/hub/hubAuthPagesActions';
import { useMutation } from '@tanstack/react-query';
import { logIn } from '../../../services/awsAuth';
import get from 'lodash/get';
import { CUSTOMER_CARE_CONTACT_PHONE_2 } from '../../../util/constants';
import { logError } from '../../../logging';
import withHeadTag from '../../../common/HeadComponent';
import { usePostHogCapture } from '../../../util/posthog';
import { useVerifyEmail } from '../../../hooks/queries/useVerifyEmail';
import { ArrowBackIcon } from '../../../icons';
import { requestToResetPassword } from '../../../actions/hub/hubAuthPagesActions';
import { useSignupStore } from './SignUp';

const ICON_SIZE = 16;

export const LoginWith: FC<PropsWithChildren<{ onClick: () => void; icon: string; alt: string }>> = ({
  children,
  icon,
  alt,
  onClick,
}) => {
  return (
    <button
      className="flex w-full items-center rounded border border-grey-2 ring-blue-2 hover:bg-slate-1 focus:ring"
      onClick={onClick}
    >
      <div className="p-3">
        <img src={icon} height={ICON_SIZE} width={ICON_SIZE} alt={alt} />
      </div>
      <div className="flex h-full flex-grow items-center border-l border-grey-2 p-3 text-xs font-bold uppercase tracking-widest text-grey-6">
        {children}
      </div>
    </button>
  );
};

const LoginPageValidation = z.object({
  email: z.string().email('Please enter a valid email address.').min(1, 'Please enter your email address.'),
  password: z.string().min(1, 'Please enter your password.'),
});

const EmailOnlyValidation = LoginPageValidation.omit({ password: true });

const AUTH_ERROR_MSG = {
  NO_USER: "We don't have an account associated with this email, please try again.",
  INCORRECT: 'Incorrect username or password.',
};

interface EmailValidationResponse {
  exists: boolean;
  enabled: boolean;
  status:
    | 'UNCONFIRMED'
    | 'CONFIRMED'
    | 'ARCHIVED'
    | 'COMPROMISED'
    | 'UNKNOWN'
    | 'RESET_REQUIRED'
    | 'FORCE_CHANGE_PASSWORD'
    | 'EXTERNAL_PROVIDER';

  username: string;
}

const LoginPageComponent = () => {
  const history = useHistory();
  useRedirectIfAuthenticated();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [notifyActivationNeeded, setNotifyActivationNeeded] = useState(false);
  const [notifyPasswordReset, setNotifyPasswordReset] = useState(false);
  const [promptForPassword, setPromptForPassword] = useState(false);
  const [errors, setErrors] = useState({ email: '', password: '', root: '' });
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const capture = usePostHogCapture();

  const fedAuthSignIn = (provider: `${CognitoHostedUIIdentityProvider}`) => {
    Cookies.set('socialOauthSourcePage', location.pathname);
    Cookies.set('socialOauthSource', provider);
    capture('click_auth-log-in', { provider });
    Auth.federatedSignIn({ provider: provider as CognitoHostedUIIdentityProvider });
  };

  const { mutate: verifyEmail } = useVerifyEmail({
    email,
    onSuccess: async (response: EmailValidationResponse) => {
      if (!response.exists) {
        history.push(PATHS.PATH_HUB_SIGN_UP, { loginPageEmail: email });
      } else if (response.enabled && response.status === 'CONFIRMED') {
        setPromptForPassword(true);
      } else if (response.status === 'EXTERNAL_PROVIDER') {
        setErrors((curr) => ({
          ...curr,
          email: 'Please login with your external provider (Google or Facebook) using the buttons below.',
        }));
      } else if (response.status === 'FORCE_CHANGE_PASSWORD' || response.status === 'RESET_REQUIRED') {
        dispatch(
          requestToResetPassword({
            email,
            onSuccess: () => {
              setNotifyPasswordReset(true);
              setLoading(false);
            },
            onError: () => {
              setErrors((curr) => ({
                ...curr,
                email: 'Failed to verify email. Please try again.',
              }));
              setLoading(false);
            },
          })
        );
      } else {
        useSignupStore.setState({
          username: response.username,
          email,
        });
        await Auth.resendSignUp(response.username);
        history.push(`${PATHS.PATH_HUB_SIGN_UP}verify`);
      }
      // If the user has to reset their password, we need to keep the loading state until the user resets their password
      setLoading(response.status !== 'FORCE_CHANGE_PASSWORD' ? false : true);
    },
    onError: () => {
      setErrors((curr) => ({
        ...curr,
        email: 'Failed to verify email. Please try again.',
      }));
      setLoading(false);
    },
  });

  const { mutate: signIn } = useMutation({
    mutationFn: async (params: { email: string; password: string }) => {
      capture('click_auth-log-in', { provider: 'email' });
      try {
        await logIn(params);
        dispatch(getCredentials()).then(() => {
          history.push(PATHS.PATH_HUB_HOME);
          dispatch(loginSuccess());
        });
        return true;
      } catch (error) {
        let errorMsg = get(error, 'message', '');
        if (errorMsg === 'User is disabled.') {
          errorMsg = `Your login doesn't appear to be working, please contact Customer Care on ${CUSTOMER_CARE_CONTACT_PHONE_2}.`;
        } else if (errorMsg === 'User does not exist.') {
          errorMsg = "We don't have an account associated with this email, please try again.";
        }

        setErrors((curr) => ({
          ...curr,
          root: errorMsg,
        }));
        logError(errorMsg, null, {
          email,
          message: `User using email ${email} failed to log in`,
        });
        return false;
      }
    },
    onSettled: (result: boolean) => {
      if (!result) {
        setLoading(false);
      }
    },
    onMutate: () => {
      setLoading(true);
    },
  });

  const handleValidateEmail = async (email: string) => {
    const result = EmailOnlyValidation.safeParse({ email });
    if (result.success === true) {
      setLoading(true);
      verifyEmail(email);
    } else {
      setErrors((curr) => ({
        ...curr,
        email: 'Please enter a valid email address.',
      }));
    }
  };

  const handleLogin = () => {
    const result = LoginPageValidation.safeParse({ email, password });

    if (result.success === true) {
      // sign in
      signIn({ email, password });
    } else {
      const errorFields = {
        email: '',
        password: '',
        root: '',
      };

      result.error.errors.forEach((error) => {
        const field = error.path.join('.');
        errorFields[field] = error.message;
      });

      setErrors(errorFields);
      setLoading(false);
    }
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrors({ email: '', password: '', root: '' });

    if (promptForPassword) {
      handleLogin();
    } else {
      handleValidateEmail(email);
    }
  };

  const submitLabel = promptForPassword ? 'Log in' : 'Continue';

  if (notifyActivationNeeded) {
    return (
      <Auth2Layout
        title="Check your email"
        headerLinks={
          <div className="flex justify-between">
            <button
              onClick={() => setNotifyActivationNeeded(false)}
              className="flex items-center justify-between gap-1"
            >
              <ArrowBackIcon width={ICON_SIZE} height={ICON_SIZE} className="text-blue-5" />
              <span className="underline-offset-3 text-lg text-blue-5 underline">Back</span>
            </button>
          </div>
        }
      >
        <p className="pb-8">
          We have sent a link to <strong className="font-bold">{email || 'your email'}</strong> to activate your
          account.
        </p>

        <p>Check your spam or junk folder if you don't see the email.</p>
      </Auth2Layout>
    );
  }

  if (notifyPasswordReset) {
    return (
      <Auth2Layout
        title="Check your email"
        headerLinks={
          <div className="flex justify-between">
            <button onClick={() => setNotifyPasswordReset(false)} className="flex items-center justify-between gap-1">
              <ArrowBackIcon width={ICON_SIZE} height={ICON_SIZE} className="text-blue-5" />
              <span className="underline-offset-3 text-lg text-blue-5 underline">Back</span>
            </button>
          </div>
        }
      >
        <p className="pb-8">
          Your password needs to be reset. We have sent a link to{' '}
          <strong className="font-bold">{email || 'your email'}</strong> to reset your password.
        </p>

        <p>Check your spam or junk folder if you don't see the email.</p>
      </Auth2Layout>
    );
  }

  return (
    <Auth2Layout title="Log in to Cluey Hub">
      <form onSubmit={onSubmit} className="flex w-full flex-col items-start gap-6">
        <Field
          label="Email"
          name="email"
          value={email}
          onChange={(e) => {
            setErrors({ ...errors, email: '' });
            setEmail(e);
          }}
          type="email"
          className="w-full max-w-sm"
          error={errors.email || (errors.root === AUTH_ERROR_MSG.NO_USER && errors.root)}
          disabled={loading}
        />
        {promptForPassword && (
          <Field
            label="Password"
            name="password"
            value={password}
            onChange={(e) => {
              setErrors({ ...errors, password: '' });
              setPassword(e);
            }}
            type="password"
            className="w-full max-w-sm"
            error={errors.password || (errors.root === AUTH_ERROR_MSG.INCORRECT && errors.root)}
            disabled={loading}
          />
        )}
        <Button size="lg" type="submit" disabled={loading} className="flex w-full max-w-sm flex-row justify-center">
          {!loading ? submitLabel : <InlineLoader />}
        </Button>
        {promptForPassword ? (
          <p>
            Forgot your password?{' '}
            <Link size="base" to={PATHS.PATH_HUB_RESET_PASSWORD_REQUEST}>
              Reset password
            </Link>
          </p>
        ) : (
          <p>
            Don't have an account?{' '}
            <Link size="base" to={PATHS.PATH_HUB_SIGN_UP}>
              Sign up
            </Link>
          </p>
        )}
      </form>
      <div className="flex w-full max-w-sm items-center py-14">
        <div className="h-px flex-grow bg-slate-2"></div>
        <p className="px-4">Or</p>
        <div className="h-px flex-grow bg-slate-2"></div>
      </div>
      <div className="flex w-full max-w-sm flex-col items-start gap-4">
        {/* Login with Facebook */}
        <LoginWith icon={iconFB} alt="facebook" onClick={() => fedAuthSignIn('Facebook')}>
          Login with Facebook
        </LoginWith>
        {/* Login with Google */}
        <LoginWith icon={iconGG} alt="google" onClick={() => fedAuthSignIn('Google')}>
          Login with Google
        </LoginWith>
      </div>
    </Auth2Layout>
  );
};

export const LoginPage = withHeadTag(LoginPageComponent);
