import { COUNTRY } from '../types/hubEnums'; 
import { sendGetRequest } from './RequestService';
import { POLICY_ID_MAP } from '../types/hubEnums';
import { Params as GetPolicyParams } from '../hooks/queries/usePolicyQuery';
import env from '../env';

class PolicyService {
  static async getPolicy({ country, policyType }: GetPolicyParams) {
    const domain = country === COUNTRY.AUSTRALIA.COUNTRY_CODE ? env.REACT_APP_AUSTRALIA_WEBSITE_DOMAIN : env.REACT_APP_NEW_ZEALAND_WEBSITE_DOMAIN;
    const url = `${domain}/wp-json/wp/v2/pages/${POLICY_ID_MAP[policyType]}`;
    try {
      const res = await sendGetRequest({ 
        payload: {},
        url,
      });
      return res.json();
    } catch(error) {
      throw error;
    }
  }
}

export default PolicyService;