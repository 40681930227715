import { Redirect, Route, Switch, generatePath, useRouteMatch } from 'react-router';
import { SignUpPage } from './SignUpPage';
import { create } from 'zustand';
import { SignUpVerifyPage } from './SignUpVerifyPage';
import * as ROOT_PATHS from '../../../../util/pagePath';
import { SignUpPasswordPage } from './SignUpPasswordPage';
import { SignUpContactPage } from './SignUpContact';
import { z } from 'zod';

const ROOT_PATH = ROOT_PATHS.PATH_HUB_SIGN_UP;
const VERIFY_PATH = `${ROOT_PATH}verify`;
const PASSWORD_PATH = `${ROOT_PATH}password`;
const CONTACT_PATH = `${ROOT_PATH}contact`;

export const PATHS = {
	ROOT_PATH,
	VERIFY_PATH,
	PASSWORD_PATH,
	CONTACT_PATH,
} as const;

export const SignUpRouter = () => {
	const { path } = useRouteMatch();

	console.log(generatePath(path));

	return (
		<Switch>
			<Route path={ROOT_PATH} exact component={SignUpPage} />
			<Route path={VERIFY_PATH} exact component={SignUpVerifyPage} />
			<Route path={PASSWORD_PATH} exact component={SignUpPasswordPage} />
			<Route path={CONTACT_PATH} exact component={SignUpContactPage} />
			<Route path={`${ROOT_PATH}*`} component={() => <Redirect to={ROOT_PATH} />} />
		</Switch>
	);
};

export const SIGNUP_ORIGIN = z.enum(['worksheets', 'assessments']);
export type T_SIGNUP_ORIGIN = z.infer<typeof SIGNUP_ORIGIN>;

type SignUpStore = {
	username: string;
	email: string;
	fname: string;
	lname: string;
	token: string;
	tempPwd: string;
	origin: T_SIGNUP_ORIGIN | null;
	customerNumber: string;
};

type SignUpStoreModifiers = {
	setState: (state: Partial<SignUpStore>) => void;
	clearState: () => void;
};

const initialState: SignUpStore = {
	username: '',
	email: '',
	fname: '',
	lname: '',
	token: '',
	origin: null,
	tempPwd: '',
	customerNumber: '',
};

export const useSignupStore = create<SignUpStore & SignUpStoreModifiers>((set) => ({
	...initialState,
	setState: (state) => set(state),
	clearState: () =>
		set({
			...initialState,
		}),
}));

const tokenTypeI = z.object({
	tmpPassword: z.string(),
	email: z.string(),
});
type RawTokenTypeI = z.infer<typeof tokenTypeI>;
type TokenTypeI = Required<RawTokenTypeI>;

const tokenTypeII = z.object({
	temporaryPassword: z.string(),
	email: z.string(),
});
type RawTokenTypeII = z.infer<typeof tokenTypeII>;
type TokenTypeII = Required<RawTokenTypeII>;

export const isTokenTypeI = (tokenResult: TokenTypeI | TokenTypeII): tokenResult is TokenTypeI => {
	return 'tmpPassword' in tokenResult;
};

export const tokenPassword = (tokenResult: TokenTypeI | TokenTypeII) => {
	return isTokenTypeI(tokenResult) ? tokenResult.tmpPassword : tokenResult.temporaryPassword;
};

export const decodeToken = (token: string) => {
	const decoded = JSON.parse(Buffer.from(token, 'base64').toString());

	const parse = z.union([tokenTypeI, tokenTypeII]).safeParse(decoded);

	return parse.success ? (parse.data as TokenTypeI | TokenTypeII) : null;
};
