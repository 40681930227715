import HomeAssessmentCard from "./HomeAssessmentCard/HomeAssessmentCard";
import { books, gears } from '../assets';
import { CompoundBookIcon, CompoundCalculatorIcon } from "../../../../../icons";

export const assessmentCardData = [
  { image: books, icon: <CompoundBookIcon width={34} height={34} className='text-blue-4' />, subject: 'English' },
  { image: gears, icon: <CompoundCalculatorIcon width={34} height={34} className='text-blood-orange-4' />, subject: 'Mathematics' }
];

const HomeAssessmentsWidget = () => {
  return (
    <div className="container my-12 mx-auto px-4 pb-4">
      <div className="flex flex-col gap-4 text-grey-6">
        <p className="text-xl font-bold">Take a free assessment to start your learning journey with us:</p>
        <p className="text-lg">
          An assessment provides a clear picture of your academic journey. 
          By understanding your strengths and areas that might need a boost, we can tailor our guidance to fit your unique learning path.
        </p>
        <p className="text-lg">You can generate 1 free assessment, which is made up of 45 questions and takes at least 30 minutes.</p>
      </div>
      <div className="mt-10 flex gap-5 flex-col md:flex-row">
        {assessmentCardData.map((assessment, index) => {
          return (
            <HomeAssessmentCard
              key={index}
              image={assessment.image}
              icon={assessment.icon}
              subject={assessment.subject}
            />
          )
        })}
      </div>
    </div>
  )
}

export { HomeAssessmentsWidget };