import React, { ComponentType } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import * as TITLES from '../util/pageTitle';
import { PATH_NAME_REPORT } from '../util/pagePath';
import { PATH_TO_TITLE_MAPPING } from '../util/pathNameMapping';
import { useHistory, useRouteMatch } from 'react-router';
import { useTrackPageView } from '../tracking/pageView/TrackPageView';
import { InitialState } from '../reducers/initialState';
import { Location } from 'history';

function getTitleForCurrentPage(location: Location<unknown>, matchedUrl: string, subject: string) {
	let { pathname } = location;
	if (pathname === PATH_NAME_REPORT) {
		return `Learning activity in ${subject}`;
	}

	if (!pathname.endsWith('/')) {
		pathname += '/';
	}
	if (matchedUrl && !matchedUrl.endsWith('/')) {
		matchedUrl += '/';
	}

	const title = PATH_TO_TITLE_MAPPING[pathname] || PATH_TO_TITLE_MAPPING[matchedUrl];

	if (!title) {
		return TITLES.DEFAULT_TITLE;
	}
	return `${title} | ${TITLES.DEFAULT_TITLE}`;
}

export const HeadComponent = () => {
	const history = useHistory();
	const match = useRouteMatch();

	const reportPageSubject = useSelector((state: InitialState) => state.report.subject);

	const title = getTitleForCurrentPage(history.location, match.path, reportPageSubject);

	useTrackPageView(title);

	return (
		<Helmet>
			<title>{title}</title>
		</Helmet>
	);
};

const withHeadTag = <T,>(Component: ComponentType<T>) => {
	const ComposedComponent = (props: T) => {
		return (
			<>
				<HeadComponent />
				<Component {...props} />
			</>
		);
	};

	return ComposedComponent;
};
export default withHeadTag;
