import React, { Children, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import { get, isEmpty } from 'lodash';

import InPageLoader from '../../common/InPageLoader';
import PageError from '../../common/PageError';
import EnrolmentLayout from '../../common/hub/EnrolmentLayout';
import NAPLANOptOut from '../../components/EnrolmentManagement/NAPLANOptOut';

import { fetchEnrolment } from '../../actions/hub/hubEnrolmentManagementActions';
import { getNAPLAN } from '../../selectors/enrolmentSelector';

import { infoNAPLANType } from '../../types/hubPropTypes';
import { NAPLAN_NUMERACY, NAPLAN_LITERACY } from '../../util/constants';
import { TRACKING_NAPLAN } from '../../util/trackingClasses';

import iconMuscle from '../../assets/images/atoms-icon-filled-muscles--blazed-orange.svg';
import iconPencil from '../../assets/images/icon-filled-pencil--orange.svg';
import iconReport from '../../assets/images/icon-filled-report.svg';
import iconSpeech from '../../assets/images/atoms-icon-filled-speech-bubble.svg';

const getContentList = ({ typeNAPLAN, student, yearLevel }) => {
	const list = [];
	list.push({
		icon: iconMuscle,
		title: 'NAPLAN Preparation',
		content: `From January 23 to March 12, tutoring sessions will focus on the review, 
    practice and coverage of key ${typeNAPLAN} topics and concepts. 
    After each session, NAPLAN-like questions will be assigned as practice for 
    ${student} to complete before the next session.`,
	});

	if (typeNAPLAN === NAPLAN_NUMERACY) {
		list.push({
			icon: iconPencil,
			title: 'FREE Simulated Test',
			content: `Two weeks before the official NAPLAN exam, a simulated test will 
      be assigned for ${student} to complete independently. It will contain a 
      full set of NAPLAN-like questions covering all Numeracy concepts for ${yearLevel}.`,
		});
	} else if (typeNAPLAN === NAPLAN_LITERACY) {
		list.push({
			icon: iconPencil,
			title: 'FREE Simulated Test',
			content: `Two weeks before the official NAPLAN exam, three simulated tests 
      will be assigned for ${student} to complete independently. They will 
      contain full sets of NAPLAN-like questions for the following test areas: 
      Reading and Comprehension, Language Conventions, Writing.`,
		});
	}

	list.push({
		icon: iconSpeech,
		title: 'Feedback session',
		content: `In the week before the official NAPLAN exam begins, a dedicated 
    feedback session will focus on the results of the simulated test(s) and 
    provide advice and strategies around how ${student} can best approach NAPLAN 
    itself.`,
	});

	list.push({
		icon: iconReport,
		title: 'NAPLAN Season Reporting',
		content: `You will continue to receive session reports, including tutor 
    feedback, during the NAPLAN preparation period, so you can keep up with 
    ${student}'s progress.`,
	});

	return list;
};

const ContentList = ({ list }) => {
	if (isEmpty(list)) {
		return null;
	}

	return (
		<ul className="list-unstyled mb-0">
			{Children.toArray(
				list.map((item, index) => {
					const liClass = index === list.length - 1 ? 'flex items-start mb-8 lg:mb-0' : 'flex items-start mb-8 lg:mb-8';
					const { icon, title, content } = item;
					return (
						<li className={liClass}>
							<img src={icon} alt="icon" className="mr-2 h-5 w-5" />
							<div>
								<h4 className="mb-1 text-sm font-bold leading-[1.14]">{title}</h4>
								<p className="mb-0 text-base leading-snug">{content}</p>
							</div>
						</li>
					);
				})
			)}
		</ul>
	);
};

ContentList.propTypes = {
	list: PropTypes.arrayOf(
		PropTypes.shape({
			icon: PropTypes.node,
			title: PropTypes.string,
			content: PropTypes.string,
		})
	),
};

ContentList.defaultProps = {
	list: [],
};

const HubEnrolmentNAPLAN = ({
	isLoading,
	loadingError,
	studentName,
	subject,
	yearLevel,
	yearAsInteger,
	infoNAPLAN,
	fetchEnrolmentWithId,
}) => {
	const query = new URLSearchParams(useLocation().search);
	const enrolmentId = query.get('token');
	const [showErrorScreen, setShowErrorScreen] = useState(false);

	useEffect(() => {
		if (!enrolmentId) {
			setShowErrorScreen(true);
		} else {
			fetchEnrolmentWithId({ enrolmentId });
		}
		// eslint-disable-next-line
	}, []);

	const { showNAPLAN, typeNAPLAN, optNAPLAN } = infoNAPLAN;

	if (isLoading) {
		// Note, need to display loader inside Enrolmentlayout
		return (
			<EnrolmentLayout
				sectionHeading={`${studentName} ${subject}`}
				mainHeading="NAPLAN Preparation Options"
				enrolmentId={enrolmentId}
				tracking={TRACKING_NAPLAN.PAGE}
			>
				<InPageLoader />
			</EnrolmentLayout>
		);
	}

	if (loadingError || showErrorScreen || !showNAPLAN) {
		return <PageError activePage="hub-enrolment-detail" />;
	}

	const pageIntro = `With NAPLAN coming up from March 15 to 27 for students Australia wide,
  we’ve included preparation units for ${studentName}’s ${yearLevel} ${subject} program.`;

	const contentIntro = `We’ll automatically transition from ${studentName}’s current ${subject} focus to
                NAPLAN specific ${typeNAPLAN} preparation in their upcoming sessions.`;

	const contentList = getContentList({
		typeNAPLAN,
		student: studentName,
		yearLevel,
	});

	return (
		<EnrolmentLayout
			sectionHeading={`${studentName} ${subject}`}
			mainHeading="NAPLAN Preparation Options"
			enrolmentId={enrolmentId}
			tracking={TRACKING_NAPLAN.PAGE}
		>
			<div className="container mx-auto mb-7 border-b border-grey-2 px-4 lg:mb-1">
				<div className="flex flex-col lg:flex-row">
					<div className="w-full lg:w-7/12">
						<p className="text-xl leading-tight">{pageIntro}</p>
					</div>
				</div>
			</div>

			<div className="container mx-auto mb-12 px-4 lg:mb-20">
				<div className="flex flex-col lg:flex-row">
					<div className="w-full lg:w-7/12">
						<div className="mb-10 pt-10 lg:mb-0 lg:pt-4">
							<h2 className="font-display text-2xl font-bold leading-[1.17]">How does it work?</h2>
							<p className="mb-10 text-xl leading-tight">{contentIntro}</p>
							<ContentList list={contentList} />
						</div>
					</div>
					<div className="w-full lg:ml-[8.33%] lg:w-1/3">
						<div className="h-full border-t border-grey-2 pt-10 lg:border-l lg:border-t-0 lg:pl-6  lg:pt-4">
							<h3 className="font-display text-xl font-bold leading-tight">
								What are the advantages of NAPLAN preparation?
							</h3>
							<div className="flex">
								<p className="text-base leading-snug">
									Cluey Chief Education Officer Dr. Selina Samuels looks at the importance of NAPLAN and the advantage
									of preparing for it.
								</p>
								<img
									src="https://secure.gravatar.com/avatar/9fda0ead1aa9ff98e33c6ff708c9b558?s=192&d=mm&r=g"
									alt="Selina Samuels"
									className="ml-6 h-[60px] w-[60px] rounded-full"
								/>
							</div>
							<a
								href="https://clueylearning.com.au/blog/why-prepare-kids-for-naplan/"
								className="font-display text-xs font-bold uppercase leading-tight tracking-[1px]"
								target="_blank"
								rel="noopener noreferrer"
							>
								Read on...
							</a>
						</div>
					</div>
				</div>
			</div>
			<NAPLANOptOut
				student={studentName}
				enrolmentId={enrolmentId}
				yearAsInteger={yearAsInteger}
				optNAPLAN={optNAPLAN}
			/>
		</EnrolmentLayout>
	);
};

HubEnrolmentNAPLAN.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	loadingError: PropTypes.bool.isRequired,
	studentName: PropTypes.string.isRequired,
	subject: PropTypes.string.isRequired,
	yearLevel: PropTypes.string.isRequired,
	yearAsInteger: PropTypes.number.isRequired,
	infoNAPLAN: infoNAPLANType.isRequired,
	fetchEnrolmentWithId: PropTypes.func.isRequired,
};

export default connect(
	(state) => {
		const uiHubEnrolmentDetailPage = get(state, 'ui.apiState.hubEnrolmentDetailPage');
		const { studentName, enrolmentDiscipline, enrolmentYearLevel, yearAsInteger } = state.hubEnrolmentDetailPage;

		return {
			isLoading: uiHubEnrolmentDetailPage.firstFetch,
			loadingError: uiHubEnrolmentDetailPage.fetchError,
			studentName,
			subject: enrolmentDiscipline,
			yearLevel: enrolmentYearLevel,
			yearAsInteger,
			infoNAPLAN: getNAPLAN(state),
		};
	},
	(dispatch) => {
		return {
			fetchEnrolmentWithId: bindActionCreators(fetchEnrolment, dispatch),
		};
	}
)(HubEnrolmentNAPLAN);

export { HubEnrolmentNAPLAN as StatelessHubEnrolmentNAPLAN };
