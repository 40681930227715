import { useQuery } from "@tanstack/react-query"
import { TutorAvailabilitiesService } from "../../services/TutorAvailabilitiesService"
import { AVAILABLE_SLOTS_FOR_SESSION_QKEY } from "../../util/constants/queryKeys"

export const useGetAvailableSlotsForSession = (sessionId, packageId, tutorInitiated = false, tutorSfid) => {
	return useQuery({
		queryKey: [AVAILABLE_SLOTS_FOR_SESSION_QKEY, sessionId, tutorInitiated],
		queryFn: () =>
			TutorAvailabilitiesService.getAvailableSlotsForSession(sessionId, packageId, tutorInitiated, tutorSfid),
		staleTime: 300000,
		enabled: !!sessionId && !!packageId,
	});
};