import initialState from '../../initialState';
import * as actionTypes from '../../../actions/actionTypes';

export default function hubChangeGroupPage(state = initialState.ui.apiState.hubChangeGroupPage, action) {
  const { type } = action;
  switch (type) {
    case actionTypes.HUB_EM_CG_FETCH_GROUP_SESSIONS_START:
      return {
        ...state,
        firstFetch: true,
        fetchError: false,
      };
    case actionTypes.HUB_EM_CG_FETCH_GROUP_SESSIONS_FAILED:
      return {
        ...state,
        firstFetch: false,
        fetchError: true,
      };
    case actionTypes.HUB_EM_CG_FETCH_GROUP_SESSIONS_SUCCESS:
      return {
        ...state,
        firstFetch: false,
        fetchError: false,
      };
    case actionTypes.HUB_EM_CG_SUBMIT_GROUP_START:
      return {
        ...state,
        changingGroup: {
          ...state.changingGroup,
          isLoading: true,
          fetchError: false,
        },
      };
    case actionTypes.HUB_EM_CG_SUBMIT_GROUP_SUCCESS:
      return {
        ...state,
        changingGroup: {
          ...state.changingGroup,
          isLoading: false,
          fetchError: false,
        },
      };
    case actionTypes.HUB_EM_CG_SUBMIT_GROUP_FAILED:
      return {
        ...state,
        changingGroup: {
          ...state.changingGroup,
          isLoading: false,
          fetchError: true,
        },
      };
    case actionTypes.HUB_EM_CG_CLEAR_GROUP_SESSIONS:
      return {
        ...state,
        firstFetch: false,
        fetchError: false,
        changingGroup: {
          ...state.changingGroup,
          isLoading: false,
          fetchError: false,
        },
      };
    default:
      return state;
  }
}
