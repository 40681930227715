import { Alert, Modal, Pending } from '@cluey/cluey-components';
import { OptionCard, OptionCardContent } from '../../EnrolmentManagement/OptionCard';
import { useState } from 'react';
import { generatePath } from 'react-router';
import { PATH_HUB_RESCHEDULE_SESSION, PATH_HUB_SKIP_SESSION } from '../../../util/pagePath';
import { IntentIntro } from '../../IntentIntro';
import { Loader } from '../../../components/Loader';
import { useGetSessionPolicy } from '../../../hooks/queries/useSessionQuery';
import { Session } from '../../../types/hubInterfaces';
import { isGroupSessionCheck } from '../../../util/helpers';
import posthog from 'posthog-js';

interface Props {
  session: Session;
  open: boolean;
  closeModal(): void;
}

const ManageUpcomingSessionModal = ({ session, open, closeModal }: Props) => {
  const { studentSessionId, student, subject } = session;
  const possessiveNoun = student ? `${student}'s` : 'your';
  const titleSubject = subject ? `${subject} session` : 'session';
  const title = `Can't make ${possessiveNoun} ${titleSubject}?`;

  const {
    data: sessionPolicy,
    isLoading: isPolicyLoading,
    error: policyError,
  } = useGetSessionPolicy({
    sessionId: session.studentSessionId,
    enabled: open,
  });
  const isGroupSession = isGroupSessionCheck(session.type);
  const canBeRescheduled = sessionPolicy?.reschedule?.isAllowed;
  const allowedHours = sessionPolicy?.reschedule?.allowedHours;

  const createHeading = () => {
    if (policyError) {
      return (
        <Alert
          className="mt-2"
          type="error"
          body={`There was an error retrieving this session's policy. ${policyError}`}
        />
      );
    }
    if (canBeRescheduled) {
      return (
        <IntentIntro className="mb-10">
          This session starts soon. So that we can advise the tutor, what would you like to do?
        </IntentIntro>
      );
    } else {
      const errorMessage = isGroupSession
        ? sessionPolicy?.reschedule?.externalMessage
        : `Your ${sessionPolicy?.policyName} plan doesn't allow Reschedule within ${allowedHours} hour${
            allowedHours > 1 ? 's' : ''
          } of session`;
      return <div className="mb-10 text-xs font-bold uppercase text-red-5">{errorMessage}</div>;
    }
  };

  return (
    <Modal open={open} setOpen={closeModal} accent closeButton>
      <div className="px-8 py-8">
        <Pending loading={isPolicyLoading} loader={<Loader />}>
          <h2 className="pb-6 font-display text-2xl font-bold tracking-[-0.02rem] md:text-4xl lg:text-[40px] lg:leading-[48px]">
            {title}
          </h2>
          {createHeading()}
          <OptionCard
            linkTo={generatePath(PATH_HUB_RESCHEDULE_SESSION, { sessionId: studentSessionId })}
            disabled={!canBeRescheduled}
            capturePosthogEvent={
              canBeRescheduled ? () => posthog.capture('click_manage-upcoming-session_reschedule-session') : undefined
            }
          >
            <OptionCardContent disabled={!canBeRescheduled}>Reschedule this session</OptionCardContent>
          </OptionCard>

          <OptionCard
            linkTo={generatePath(PATH_HUB_SKIP_SESSION, { sessionId: studentSessionId })}
            capturePosthogEvent={() => posthog.capture('click_manage-upcoming-session_cancel-session')}
          >
            <OptionCardContent>Cancel this session</OptionCardContent>
          </OptionCard>
        </Pending>
      </div>
    </Modal>
  );
};

export const useManageUpcomingSessionModal = () => {
  const [open, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  return {
    open,
    openModal,
    closeModal,
    ManageUpcomingSessionModal,
  };
};

export default ManageUpcomingSessionModal;
