import { IconProps as Props } from '../types/hubInterfaces';
import { iconDefaults } from '.';
import classNames from 'classnames';

import ClueyCoachBot from '../assets/images/virtualCoach/cluey-coach-bot.png';

function VirtualCoachBotIcon(props: Props) {
  const { width, height, className } = { ...iconDefaults, ...props };
  return (
    <img width={width} height={height} src={ClueyCoachBot} className={classNames(className)} alt="Cluey Coach Bot" />
  );
}

export { VirtualCoachBotIcon };
