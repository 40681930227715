import classnames from 'classnames';
import FAQItem, { FAQItemProps } from './components/FAQItem/FAQItem';
import { mergeQuestions } from './helper';
import { FAQQuestions } from './types';

export type FAQComponentProps = {
  questions: FAQQuestions[];
  title?: string;
  subtitle?: string;
  titleClassNames?: string;
  subtitleClassNames?: string;
  itemConfig?: {
    answerComponent?: (props: FAQItemProps) => JSX.Element;
  };
};

const FAQComponent = ({
  questions,
  title = 'FAQ',
  subtitle = 'Here are some of our frequently asked questions to help you out.',
  titleClassNames,
  subtitleClassNames,
  itemConfig,
}: FAQComponentProps) => {
  const parsedQuestions = mergeQuestions(questions);
  return (
    <div className="flex flex-col">
      {(title || subtitle) && (
        <div className="mb-[60px]">
          {title && (
            <div
              className={classnames(
                'proxima-nova flex items-center justify-center text-4xl font-bold',
                titleClassNames
              )}
            >
              {title}
            </div>
          )}
          {subtitle && (
            <div className={classnames('proxima-nova flex items-center justify-center text-lg', subtitleClassNames)}>
              {subtitle}
            </div>
          )}
        </div>
      )}
      <div className="flex flex-col border-b-2 border-grey-2">
        {Object.entries(parsedQuestions)
          .sort(([_1, a], [_2, b]) => a.priority - b.priority)
          .map(([key, question]) => (
            <div key={key} className="flex flex-col">
              <FAQItem key={key} id={key} item={question} itemConfig={itemConfig} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default FAQComponent;
