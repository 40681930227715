import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ContactScreenWrap = ({
  ciClass,
  h1Text,
  children,
}) => {
  const h1Classes = classNames('font-bold mt-10 mb-5 font-body text-2xl md:text-3xl lg:text-4xl ci', ciClass);
  return (
    <div className="container mx-auto bg-white text-center p-5 pt-7 md:p-10 rounded-lg relative border-t-8 border-yellow-3 c-section c-section--contact">
      {
        h1Text && h1Text.length > 0 && (
          <h1 className={h1Classes}>{ h1Text }</h1>
        )
      }
      {children}
    </div>
  );
};

ContactScreenWrap.propTypes = {
  ciClass: PropTypes.string,
  h1Text: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ContactScreenWrap.defaultProps = {
  ciClass: null,
  h1Text: null,
};

export default ContactScreenWrap;
