import initialState from './initialState';
import * as actionTypes from '../actions/actionTypes';

export default function hubChangeSchedulePage(state = initialState.hubChangeSchedulePage, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.HUB_CSP_SAVE_ENROLMENT_ID:
      return {
        ...state,
        enrolmentId: payload.enrolmentId,
      };
    case actionTypes.HUB_CSP_SAVE_SELECTED_SCHEDULE:
      return {
        ...state,
        selectedSchedule: {
          ...state.selectedSchedule,
          ...payload,
        },
      };
    case actionTypes.HUB_CSP_FETCH_SELECTED_TUTOR_INFO_SUCCESS:
      return {
        ...state,
        selectedSchedule: {
          ...state.selectedSchedule,
          ...payload,
        },
      };
    case actionTypes.HUB_EP_FETCH_ENROLMENT_DETAIL_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.HUB_EM_CS_RESET_PAGE_STATE:
      return {
        enrolmentId: '',
        tutorAvailabilities: [],
        selectedSchedule: {
          selectedDay: null,
          scheduleDate: '',
          sessionDuration: '',
        },
        selectedTutor: {
          tutorNumber: '',
          tutorName: '',
          tutorState: '',
          tutorPhoto: '',
          tutorType: '',
          tutorDescription: '',
        },
      };
    default:
      return state;
  }
}
