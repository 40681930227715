import { useState, useEffect } from 'react';
import { Auth2Layout, useRedirectIfAuthenticated } from '../../common';
import { Button, FieldWrapper, Input, Pending } from '@cluey/cluey-components';
import { FormFlowProps } from '../../../../../types/hubInterfaces';
import { requestToResetPassword } from '../../../../../actions/hub/hubAuthPagesActions';
import { useDispatch, useSelector } from 'react-redux';
import update from 'immutability-helper';
import { InitialState } from '../../../../../reducers/initialState';
import { InlineLoader } from '../../common';
import { useLocation } from 'react-router';

interface Props {
  formFlowProps: FormFlowProps;
}

function RequestPage({ formFlowProps }: Props) {
  useRedirectIfAuthenticated();
  const location = useLocation<{ emailParam: string }>();
  const dispatch = useDispatch();
  const { isLoading: loading } = useSelector((state: InitialState) => state.ui.apiState.hubAuthPage);

  const emailRegex =
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  const [email, setEmail] = useState({
    value: location.state?.emailParam ?? '',
    valid: true,
    error: '',
  });

  useEffect(() => {
    if (location.state?.emailParam && email) {
      onClick();
    }
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  const onClick = () => {
    const emailValid = !!email.value.match(emailRegex);
    setEmail(
      update(email, {
        $merge: {
          valid: emailValid,
          error: emailValid ? '' : 'Please enter a valid email address',
        },
      })
    );
    if (emailValid) {
      dispatch(
        requestToResetPassword({
          email: email.value,
          onSuccess: () => {
            formFlowProps.next({ email: email.value });
          },
          onError: () => {
            formFlowProps.next({ email: email.value });
          },
        })
      );
    }
  };

  const onChange = (text) => {
    setEmail(
      update(email, {
        $merge: {
          error: '',
          value: text,
          valid: true,
        },
      })
    );
  };

  return (
    <Auth2Layout title="Reset your password">
      <Pending loading={loading} loader={<InlineLoader />}>
        <div className="text-grey-6">
          <p>
            Enter the email address associated with your Cluey account and we'll send you a link to reset your password.
          </p>
        </div>
        <div className="mt-4 flex w-full max-w-sm flex-col gap-1">
          <FieldWrapper label="Email" error={email.error}>
            <Input className="text-grey-6" onChange={onChange} value={email.value} error={!email.valid} />
          </FieldWrapper>
        </div>
        <Button className="mt-10" onClick={onClick} size="lg">
          Email Link
        </Button>
      </Pending>
    </Auth2Layout>
  );
}

export default RequestPage;
