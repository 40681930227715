import { Fragment } from 'react';
import { Button, Modal } from '@cluey/cluey-components';

import ModalImg from '../../assets/images/modal-emoji-smile.svg';
import { EMOJI_LIST } from '../../util/constants';
import useWindowWidth from '../../hooks/common/useWindowWidth';
import { BREAKPOINTS } from '../../util/constants';

interface Props {
  open: boolean;
  closeModalHandler(): void;
}

const EmojiRatingModal = ({ 
  open,
  closeModalHandler
 }: Props) => {
  const windowWidth = useWindowWidth();
  const showNativeModal = !(windowWidth >= BREAKPOINTS.sm);

  return (
    <Modal open={open} setOpen={closeModalHandler}>
      {showNativeModal && (
        <Fragment>
          <div
            className="modal-backToPage border-b"
            role="presentation"
            onClick={closeModalHandler}
          >
            <div className="mt-5 flex items-start pl-5 text-left">
              <i className="icon icon icon-arrow-back-outline align-middle" />
              <span className="pl-3">Back to page</span>
            </div>
          </div>
          <div className="modal-title border-b-0 pt-2 text-center">
            <img className="pt-5" src={ModalImg} alt="Modal SmileImg" />
            <div className="pt-4 pb-3 text-center">
              How student ratings work
            </div>
          </div>
        </Fragment>
      )}
      {!showNativeModal && (
        <div className="mb-4 flex flex-row items-center pt-3 text-center text-grey-6 md:pt-20">
          <img className="pr-4" src={ModalImg} alt="Modal SmileImg" />
          <h3 className="text-2xl">How student ratings work</h3>
        </div>
      )}

      <div className="mb-5">
        <p className="text-sm text-grey-6 lg:text-base">
          At the end of each learning session, we ask the student to rate the
          session by picking an emoji and accompanying sentiment. We ask:{' '}
          <span className="font-bold">
            “Which option best describes your learning experience today?”{' '}
          </span>
          The student can choose from the 8 following emojis.
        </p>

        <div className="flex flex-wrap justify-between pt-0.5 text-grey-6 md:pt-2 lg:flex-nowrap">
          {EMOJI_LIST.map((item) => {
            return (
              <div
                key={item.emojiName}
                className="flex h-1/2 w-1/2 flex-col items-center pt-8"
              >
                <img
                  src={`https://static.clueylearning.com/emoji/emoji-${item.emojiName}.svg`}
                  alt="emoji"
                  className="emoji-item"
                />
                <span>{item.emojiValue}</span>
              </div>
            );
          })}
        </div>
      </div>
      {!showNativeModal && (
        <div className="flex justify-end">
          <Button size="lg" onClick={closeModalHandler}>
            OK, got it!
          </Button>
        </div>
      )}
    </Modal>
  );
}

EmojiRatingModal.defaultProps = { open: false };
export { EmojiRatingModal };
