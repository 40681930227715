// https://vimeo.com/386391398/8fb38b0466?
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { ButtonAsLink } from '@cluey/cluey-components';
import { BlockLoader } from '../../common/BlockLoader';
import ErrorBlock from '../../common/ErrorBlock';
import { GenericTextSection } from './GenericTextSection';
import { submitContactCase as requestSessionRecordingAction } from '../../actions/contactUsFlowActions';
import { SAF_CASE_ORIGIN_SESSION_REPORT, VIDEO_STATUS_ARCHIVED } from '../../util/constants';
import {
	SR_REQUEST_SESSION_RECORDING_FAILED,
	SR_REQUEST_SESSION_RECORDING_START,
	SR_REQUEST_SESSION_RECORDING_SUCCESS,
} from '../../actions/actionTypes';

class VideoBox extends React.Component {
	render() {
		const { videoUrl } = this.props;
		const isArchived = videoUrl === VIDEO_STATUS_ARCHIVED;
		let videoId1 = null;
		let videoId2 = null;
		if (videoUrl && !isArchived) {
			const videoUrlBits = videoUrl.split('/');
			videoId1 = videoUrlBits[videoUrlBits.length - 2]; // second last element
			videoId2 = videoUrlBits[videoUrlBits.length - 1]; // last element
		}
		return (
			<Fragment>
				<GenericTextSection title="SESSION RECORDING" />
				{isArchived && 'Your session video has been archived.'}
				{!isArchived &&
					!videoId1 &&
					!videoId2 &&
					'Your session is being encoded at the moment… Videos are usually available 48hours after the session ended.'}
				{!isArchived && videoId1 && videoId2 && (
					<div ref={this.selector} className="video-container">
						<iframe
							src={`https://player.vimeo.com/video/${videoId1}?h=${videoId2}`}
							allow="autoplay; fullscreen"
							allowFullScreen
						/>
					</div>
				)}
			</Fragment>
		);
	}
}

VideoBox.propTypes = {
	videoUrl: PropTypes.string,
};

VideoBox.defaultProps = {
	videoUrl: null,
};

export default VideoBox;

// NOTE: This is a replacement of the above VideoBox.
// Since we don't know what is this.selector, we keep the above component unattached so we can easily switch back when needed
export const SessionRecording = ({ sessionId, videoUrl, requestSessionRecording }) => {
	const [isRequestingVideo, setIsRequestingVideo] = useState(false);
	const [requestFailed, setRequestFailed] = useState(false);
	const [isVideoRequested, setIsVideoRequested] = useState(false);

	const onRequestRecordingClick = () => {
		setIsRequestingVideo(true);
		setRequestFailed(false);

		const caseType = 'Feedback';
		const caseCategory = 'Request session recording';
		requestSessionRecording({
			caseOrigin: SAF_CASE_ORIGIN_SESSION_REPORT,
			caseSubject: `Contact request - ${caseType} - ${caseCategory}`,
			caseType,
			caseCategory,
			studentSession: sessionId,
			message: `Request session recording. Session: ${sessionId}`,
			onSubmitSucceed: () => {
				setIsRequestingVideo(false);
				setRequestFailed(false);
				setIsVideoRequested(true);
			},
			onSubmitFailed: () => {
				setIsRequestingVideo(false);
				setRequestFailed(true);
			},
			actionsTodo: {
				start: SR_REQUEST_SESSION_RECORDING_START,
				success: SR_REQUEST_SESSION_RECORDING_SUCCESS,
				failed: SR_REQUEST_SESSION_RECORDING_FAILED,
			},
		});
	};

	const isArchived = videoUrl === VIDEO_STATUS_ARCHIVED;
	let videoId1 = null;
	let videoId2 = null;
	if (videoUrl && !isArchived) {
		const videoUrlBits = videoUrl.split('/');
		videoId1 = videoUrlBits[videoUrlBits.length - 2]; // second last element
		videoId2 = videoUrlBits[videoUrlBits.length - 1]; // last element
	}

	let content = null;
	switch (true) {
		case isArchived:
			content = <p>Your session video has been archived.</p>;
			break;
		case isRequestingVideo:
			content = <BlockLoader />;
			break;
		case requestFailed:
			content = <ErrorBlock errorMsg="Unable to submit your request, please try again later." />;
			break;
		case isVideoRequested:
			content = (
				<p>
					Your session recording is being prepared. Once available, you&apos;ll receive an email notification with the
					video link.
				</p>
			);
			break;
		case !!videoId1 && !!videoId2:
			content = (
				<div className="video-container">
					<iframe
						src={`https://player.vimeo.com/video/${videoId1}?h=${videoId2}`}
						allow="autoplay; fullscreen"
						allowFullScreen
					/>
				</div>
			);
			break;
		default:
			content = (
				<button
					className="rounded-none p-0 text-left font-body text-sm font-bold leading-tight tracking-normal text-primary"
					onClick={onRequestRecordingClick}
				>
					Request session recording
				</button>
			);
	}
	return (
		<Fragment>
			<GenericTextSection title="SESSION RECORDING" />
			{content}
		</Fragment>
	);
};

SessionRecording.propTypes = {
	sessionId: PropTypes.string.isRequired,
	videoUrl: PropTypes.string,
	requestSessionRecording: PropTypes.func.isRequired,
};

SessionRecording.defaultProps = {
	videoUrl: null,
};

export const ConnectedSessionRecording = connect(null, (dispatch) => {
	return {
		requestSessionRecording: bindActionCreators(requestSessionRecordingAction, dispatch),
	};
})(SessionRecording);
