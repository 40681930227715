import { useSelector } from 'react-redux';

import {
  isEnrolmentCancelled,
  isEnrolmentPending,
  isEnrolmentFinished,
} from '../../../selectors/hubSelector';

import { ButtonAsLink, Alert } from '@cluey/cluey-components';
import { getSession } from '../../../selectors/sessionSelector';
import { InitialState } from '../../../types/hubTypes';
import { useGetSessionPolicy } from '../../../hooks/queries/useSessionQuery';
import { SessionDateSummary } from '../SessionDateSummary';
import { ManageUpcomingSessionModal } from '../../Modals/ManageUpcomingSessionModal';
import { useState } from 'react';

const NextSession = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { 
    nextSessionDate,
    nextSessionEndDate,
    nextSessionStudentSessionId
  } = useSelector((state: InitialState) => state.hubEnrolmentDetailPage);
  const nextSession = useSelector(getSession({ studentSessionId: nextSessionStudentSessionId }));
  const isPending = useSelector(isEnrolmentPending);
  const isCancelled = useSelector(isEnrolmentCancelled);
  const isFinished = useSelector(isEnrolmentFinished);
  const { error: policyError } = useGetSessionPolicy({ sessionId: nextSession?.studentSessionId });

  if (policyError) {
    return <Alert className='mt-2' type='error' body={`There was an error retrieving this session's policy. ${policyError}`} />
  }

  if (isCancelled || isFinished || isPending || !nextSessionDate) {
    return null;
  }

  return (
    <div className="my-10 border-t border-grey-2 pt-10">
      <ManageUpcomingSessionModal
        open={modalOpen}
        closeModal={() => setModalOpen(false)}
        session={nextSession}
      />
      <SessionDateSummary
        heading="next session"
        sessionStartDate={nextSessionDate}
        sessionEndDate={nextSessionEndDate}
      />
      <ButtonAsLink
        className='ml-12 pl-1'
        onClick={() => setModalOpen(true)}
      >
        Can't make it
      </ButtonAsLink>
    </div>
  );
};

export default NextSession;