import debounce from 'lodash/debounce';
import { logError } from './logging';
import { WINDOW_ON_ERROR } from './logging/errors';

class WindowEvents {
  static mouseMoveEvents = [];

  static bindEvents() {
    window.addEventListener('beforeunload', WindowEvents.beforeUnloadListener);
    window.addEventListener('unload', WindowEvents.unloadListener);

    window.onerror = (message, url, lineNo, colNo, error) => {
      // console.error(`********* UNHANDLED ERROR CAUGHT *********: ${message}, ${url}, ${lineNo}, ${colNo}`, error);
      if (error instanceof Error) {
        logError(
          error,
          {
            onErrorData: {
              message,
              url,
              lineNo,
              colNo,
            },
          },
          { unexpected: true }
        );
      } else {
        logError(
          WINDOW_ON_ERROR,
          {
            onErrorData: {
              message,
              url,
              lineNo,
              colNo,
            },
          },
          { message, unexpected: true }
        );
      }
      return false;
    };
  }

  static beforeUnloadListener(ev) {
    // This event will trigger only once if the user hits OK
    document.addEventListener(
      'mousemove',
      WindowEvents.mouseMoveUnloadListener
    );
    ev.returnValue = 'Are you sure that you want to leave this site?';
    return ev;
  }

  static mouseMoveUnloadListener = debounce((e) => {
    WindowEvents.mouseMoveEvents.push(e);
    if (WindowEvents.mouseMoveEvents.length > 1) {
      WindowEvents.mouseMoveEvents = [];
      document.removeEventListener(
        'mousemove',
        WindowEvents.mouseMoveUnloadListener
      );
    }
  }, 10);

  static unBindEventBeforeUnload() {
    window.removeEventListener(
      'beforeunload',
      WindowEvents.beforeUnloadListener
    );
  }
}

export default WindowEvents;
