import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LinkButton } from '@cluey/cluey-components';

import withHeadTag from '../../../common/HeadComponent';
import InPageLoader from '../../../common/InPageLoader';
import { PATH_HUB_HOME } from '../../../util/pagePath';
import ClueyLogo from '../../../assets/images/cluey-logo.svg';
import ClueyOnBoarding from '../../../assets/images/hubAuth/cluey-hub-on-boarding.svg';

import { getUserInfo } from '../../../actions/hub/hubAuthPagesActions';

const HubOnboardingPage = ({ activeSpinner, name, getInfo }) => {
	useEffect(() => {
		if (name === '') {
			getInfo();
		}
	}, [name, getInfo]);

	const subtitle = name !== '' ? `Welcome, ${name}` : 'Welcome';
	return (
		<Fragment>
			{activeSpinner && <InPageLoader />}
			{!activeSpinner && (
				<div className="hub lg: relative flex h-full flex-col border-t-4 border-secondary before:absolute before:z-[-1] before:block after:absolute after:bottom-0 after:right-0 after:z-[-1] after:block after:h-[80px] after:w-[120px]">
					<div className="header-container flex w-full lg:mt-[80px]">
						<img src={ClueyLogo} alt="Cluey Logo" className="m-auto" />
					</div>
					<div className="body-container on-boarding flex px-5">
						<div className="container mx-auto flex p-0 pt-5">
							<div className="m-auto lg:w-full lg:max-w-6xl lg:rounded-md lg:bg-slate-1 lg:py-[76px]">
								<div className="m-auto text-base lg:max-w-lg lg:text-center">
									<div className="relative mx-auto mb-9 h-[224px] w-[224px] bg-gradient-to-tl from-slate-1 to-blue-2 lg:bg-white">
										<img
											src={ClueyOnBoarding}
											alt="welcome on boarding"
											className="absolute left-0 top-0 h-auto w-[160px]"
										/>
									</div>
									<p className="font-medium text-red-5 lg:text-2xl">{subtitle}</p>
									<h1 className="text-2xl lg:text-3xl">To the Cluey Hub</h1>
									<p className="mb-6 lg:text-xl">
										The Hub allows you to view and manage your sessions, enrolments and account settings.
									</p>
									<LinkButton to={PATH_HUB_HOME} size="sm" className="m-auto block font-medium uppercase lg:max-w-xs">
										Go to the hub
									</LinkButton>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

HubOnboardingPage.propTypes = {
	activeSpinner: PropTypes.bool.isRequired,
	name: PropTypes.string,
	getInfo: PropTypes.func.isRequired,
};

HubOnboardingPage.defaultProps = {
	name: '',
};

export default connect(
	(state) => {
		const { ui, hubUser } = state;
		return {
			activeSpinner: ui.apiState.hubAuthPage.isLoading,
			name: hubUser.name,
		};
	},
	(dispatch) => {
		return {
			getInfo: bindActionCreators(getUserInfo, dispatch),
		};
	}
)(withHeadTag(HubOnboardingPage));

export { HubOnboardingPage as PresentationalHubOnboardingPage };
