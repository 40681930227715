import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ButtonAsLink } from '@cluey/cluey-components';

import { SESSION_TIME_FORMAT } from '../../../util/constants';
import iconReview from '../../../assets/images/icon-outline-review.svg';

export const NextOnGoingSchedule = ({ selectedDay, timezone, onChangeClick, startDate }) => {
	const newDate = `${moment(startDate).tz(timezone).format('dddd')}s`;

	const newTime = moment(startDate).tz(timezone).format(SESSION_TIME_FORMAT);

	return (
		<div className="mb-10 border-b border-grey-2 pb-10 lg:mt-9">
			<h3 className="mb-5 text-xs font-bold uppercase tracking-wider">new ongoing schedule</h3>
			<div className="flex items-start">
				<img src={iconReview} alt="icon" className="mr-5 lg:mr-6" />
				<div className="text-left">
					<p className="text-lg font-bold leading-[1.33] lg:mb-4">
						{newDate} at {newTime}
					</p>
					<p className="lg:mb-4">Starting from {selectedDay}</p>
					<ButtonAsLink onClick={onChangeClick}>Change</ButtonAsLink>
				</div>
			</div>
		</div>
	);
};

NextOnGoingSchedule.propTypes = {
	selectedDay: PropTypes.string.isRequired,
	timezone: PropTypes.string.isRequired,
	onChangeClick: PropTypes.func.isRequired,
	startDate: PropTypes.instanceOf(Date).isRequired /* a momentjs object */,
};
