import classNames from 'classnames';
import { Link } from '@cluey/cluey-components';
import { api } from '../../../../../api';
import { PATH_HUB_UPCOMING_SESSIONS } from '../../../../../util/pagePath';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { ReactNode, useMemo, useState } from 'react';
import { ChevronDownIcon } from '../../../../../icons';
import { HubSessionListSkeletonItem } from '../../../../../components/hub/sessions/HubSessionsListItem/HubSessionListSkeletonItem';
import { formatError } from '../../../../../util/errors';
import { AddAStudentCard, StudentCard, StudentCardSkeleton } from './student-card';
import { EnrolmentSession, SnapshotUpcomingItem } from './enrolment-session';
import { hasCancelRequested, hasTrial } from './common';
import { ENROLMENT_STATUS } from '../../../../../types/hubEnums';
import { Student } from '../../../../../api/types/student';
import { Enrolment } from '../../../../../api/types/enrolment';
import moment from 'moment';
import { Session } from '../../../../../api/types/session';

export interface SnapshotStudentsProps {
	studentsQuery: ReturnType<typeof api.student.all.useQuery>;
}

export const SnapshotStudents = ({ studentsQuery }: SnapshotStudentsProps) => {
	const [studentCursor, setStudentCursor] = useState(0);

	const { data: students, isLoading: isStudentsLoading, error: studentsError } = studentsQuery;

	const studentsToShow = students?.slice(studentCursor, studentCursor + 3) || [];

	let desktop: ReactNode, mobile: ReactNode;

	if (studentsError) {
		// Error States
		desktop = (
			<div className="col-span-3 flex w-full flex-col gap-2 py-2">
				<div className="flex items-center justify-center">Something went wrong 😭</div>
				<pre className="text-center text-xs">{formatError(studentsError)}</pre>
			</div>
		);
		mobile = desktop;
	} else if (isStudentsLoading) {
		// Loading States
		desktop = (
			<>
				<StudentCardSkeleton />
				<StudentCardSkeleton />
				<StudentCardSkeleton />
			</>
		);
		mobile = <StudentCardSkeleton />;
	} else {
		// All is well, show content!
		desktop = (
			<>
				{studentCursor > 0 && (
					<div className="absolute right-full top-1/2 -translate-y-1/2 pr-5">
						<button
							type="button"
							onClick={() => setStudentCursor(studentCursor - 1)}
							className="flex h-10 w-10 items-center justify-center rounded border border-grey-2 hover:bg-slate-1"
						>
							<ChevronDownIcon className="rotate-90 text-primary" />
							<span className="sr-only">Previous Student</span>
						</button>
					</div>
				)}
				{studentsToShow.map((student) => (
					<StudentCard key={student.studentId} student={student} />
				))}
				{studentsToShow.length < 3 && <AddAStudentCard />}
				{studentsToShow.length > 2 && (
					<div className="absolute left-full top-1/2 -translate-y-1/2 pl-5">
						<button
							type="button"
							onClick={() => setStudentCursor(studentCursor + 1)}
							className="flex h-10 w-10 items-center justify-center rounded border border-grey-2 hover:bg-slate-1"
						>
							<ChevronDownIcon className="-rotate-90 text-primary" />
							<span className="sr-only">Next Student</span>
						</button>
					</div>
				)}
			</>
		);
		mobile = (
			<div className="flex snap-x gap-4 overflow-x-auto pb-4 md:hidden">
				{students?.map((student) => (
					<StudentCard key={student.studentId} student={student} className="snap-center" />
				))}
				<AddAStudentCard />
			</div>
		);
	}

	return (
		<div className="flex flex-col gap-4">
			<div className="text-xs font-bold uppercase tracking-[1px]">Students</div>
			{/* Desktop View */}
			<div className="relative hidden grid-cols-3 gap-8 md:grid">{desktop}</div>
			{/* Mobile View */}
			<div className="relative h-36 md:hidden">{mobile}</div>
		</div>
	);
};

export interface SnapshotSessionsProps {
	accountDetailsQuery: ReturnType<typeof api.account.details.useQuery>;
	studentsQuery: ReturnType<typeof api.student.all.useQuery>;
	upcomingSessionsQuery: ReturnType<typeof api.session.all.useQuery>;
	enrolmentsQuery: ReturnType<typeof api.enrolment.all.useQuery>;
}

export const SnapshotSessions = ({
	accountDetailsQuery,
	studentsQuery,
	upcomingSessionsQuery,
	enrolmentsQuery,
}: SnapshotSessionsProps) => {
	const { data: accountDetails } = accountDetailsQuery;
	const { data: students, isLoading: isStudentsLoading, error: studentsError } = studentsQuery;
	const { data: upcomingSessions, isLoading: isSessionsLoading, error: sessionsError } = upcomingSessionsQuery;
	const { data: enrolments, isLoading: isLoadingEnrolments } = enrolmentsQuery;

	const upcomingItems = useMemo(() => {
		if (!upcomingSessions) return [];

		// in an emergency we can show the first 5 sessions
		if (!students) return upcomingSessions.slice(0, 5).map((session) => ({ type: 'session', session }));

		let withSessions: SnapshotUpcomingItem[] = [];
		let withoutSessions: SnapshotUpcomingItem[] = [];

		students.forEach((student) => {
			// flatten all enrolmentIds for the student
			const enrolmentIds = Object.values(student.enrolments).flat();

			// if there are no enrolments, show the student card
			if (enrolmentIds.length === 0) {
				withoutSessions.push({
					type: 'student',
					student,
				});
			} else {
				let missedEnrolments = 0;
				// get the next sessiopn for each of the student's enrolments
				enrolmentIds.forEach(({ uniqueEnrolmentId }) => {
					// fyi, upcomingSessions is sorted by select in useQuery
					const session = upcomingSessions.find((session) => session.uniqueEnrolmentId === uniqueEnrolmentId);
					if (session) {
						withSessions.push({
							type: 'session',
							session,
						});
					} else {
						// no upcoming sessions for this enrolment, check if it's active
						const enrolment = enrolments?.find((enrolment) => enrolment.enrolmentId === uniqueEnrolmentId);
						if (
							enrolment &&
							enrolment.enrolmentStatus !== ENROLMENT_STATUS.CANCELLED &&
							enrolment.enrolmentStatus !== ENROLMENT_STATUS.FINISHED &&
							(hasCancelRequested(enrolment) || hasTrial(enrolment))
						) {
							withoutSessions.push({
								type: 'student',
								enrolment,
								student,
							});
						} else {
							// if we can't find any valid enrolments mark it as missed
							missedEnrolments++;
						}
					}
				});

				if (missedEnrolments >= enrolmentIds.length) {
					// if all enrolments have no upcoming sessions or can't find a valid enrolment, show the student card
					withoutSessions.push({
						type: 'student',
						student,
					});
				}
			}
		});

		return [...sortBy(withSessions, ['session.startTime', 'session.student', 'session.subject']), ...withoutSessions];
	}, [upcomingSessions, students, enrolments]);

	let view: ReactNode;
	if (sessionsError) {
		view = (
			<div className="col-span-3 flex w-full flex-col gap-2 py-2">
				<div className="flex items-center justify-center">Something went wrong 😭</div>
				<pre className="text-center text-xs">{formatError(studentsError || sessionsError)}</pre>
			</div>
		);
	} else if (isSessionsLoading || isLoadingEnrolments || isStudentsLoading) {
		view = (
			<>
				<HubSessionListSkeletonItem />
				<HubSessionListSkeletonItem />
				<HubSessionListSkeletonItem />
			</>
		);
	} else if (isEmpty(upcomingItems)) {
		view = <p className="text-center">No upcoming sessions</p>;
	} else {
		view = upcomingItems.map((item) => (
			<EnrolmentSession
				key={item.type === 'session' ? item.session.studentSessionId : item.student.studentId}
				timezone={accountDetails.timezone}
				{...item}
			/>
		));
	}

	return (
		<div className="flex flex-col gap-4">
			<div className="text-xs font-bold uppercase tracking-[1px]">Upcoming Sessions</div>
			<div className="flex flex-col justify-between gap-4 lg:flex-row lg:items-center">
				{accountDetails ? (
					<p className="text-sm">Times are in your account timezone ({accountDetails.timezone})</p>
				) : (
					<p className="animate-pulse rounded-full bg-slate-2 text-sm text-transparent">
						Times are in your account timezone
					</p>
				)}
				<Link size="base" to={PATH_HUB_UPCOMING_SESSIONS}>
					View all sessions
				</Link>
			</div>
			<div className="flex flex-col gap-4">{view}</div>
		</div>
	);
};

export interface SnapshotWidgetPreviewProps {
	className?: string;
	isPreview?: boolean;
}

export const SnapshotWidgetPreview = ({ className, isPreview = true }: SnapshotWidgetPreviewProps) => {
  const accountDetailsQuery = api.account.details.useQuery(undefined, {
		enabled: false,
		placeholderData: { timezone: 'Australia/Sydney' },
	});
	const mockStudents: Student[] = [
		{
			studentId: 'studentId1',
			customerNumber: 'S1234567890',
			firstName: 'Kerri',
			lastName: 'Reynolds',
			studentCountry: 'Australia',
			location: 'AU',
			yearLevel: 'Year 5',
			schoolName: 'Test',
			schoolId: 'schoolId1',
			learningNeeds: null,
			sessionsCompleted: '2',
			availableSubjects: ['Mathematics'],
			enrolments: {
				mathematics: [
					{
						sfId: 'packageId1',
						uniqueEnrolmentId: 'uniqueEnrolmentId1',
					},
				],
			},
			createdDate: '2023-01-12T00:00:00.000Z',
		},
    {
			studentId: 'studentId1',
			customerNumber: 'S1234567891',
			firstName: 'James',
			lastName: 'Heckfield',
			studentCountry: 'Australia',
			location: 'AU',
			yearLevel: 'Year 8',
			schoolName: 'Test',
			schoolId: 'schoolId2',
			learningNeeds: null,
			sessionsCompleted: '2',
			availableSubjects: ['English'],
			enrolments: {
				english: [
					{
						sfId: 'packageId2',
						uniqueEnrolmentId: 'uniqueEnrolmentId2',
					},
				],
			},
			createdDate: '2023-01-12T00:00:00.000Z',
		},
	];
	const mockEnrolments: Enrolment[] = [
		{
			cancelReason: null,
			costPerSession: 0,
			courseName: 'Mathematics',
			curriculum: 'AU',
			customerNumber: 'S1234567890',
			englishTextStudied: null,
			endTime: null,
			enrolmentCancelRequestedDate: null,
			enrolmentEndDate: null,
			enrolmentId: 'uniqueEnrolmentId1',
			enrolmentPausedFrom: null,
			enrolmentPausedTo: null,
			enrolmentSfid: 'enrolmentId1',
			enrolmentStartDate: '2023-07-19T00:00:00.000Z',
			enrolmentStatus: 'New Customer' as ENROLMENT_STATUS,
			lastname: 'Reynolds',
			packageMasterCode: 'MathsAU005',
			packageSfid: 'packageId1',
			packageTutorSfid: 'tutorId1',
			packageType: null,
			schedule: [],
			sessions: [],
			sessionCount: '0',
			sessionFrequency: 'Weekly',
			sessionReportId: null,
			startTime: null,
			student: 'Kerri',
			studentInformation: '',
			studentSfid: 'studentId1',
			subject: 'Mathematics',
			tutor: 'Tia',
			tutorPhoto: '',
			tutorNumber: 'T1234567890',
			tutorSfId: 'tutorId1',
			type: 'Course',
			yearAsInteger: 5,
			yearLevel: 'Year 5',
		},
    {
			cancelReason: null,
			costPerSession: 0,
			courseName: 'Mathematics',
			curriculum: 'AU',
			customerNumber: 'S1234567891',
			englishTextStudied: null,
			endTime: null,
			enrolmentCancelRequestedDate: null,
			enrolmentEndDate: null,
			enrolmentId: 'uniqueEnrolmentId1',
			enrolmentPausedFrom: null,
			enrolmentPausedTo: null,
			enrolmentSfid: 'enrolmentId2',
			enrolmentStartDate: '2023-07-19T00:00:00.000Z',
			enrolmentStatus: 'New Customer' as ENROLMENT_STATUS,
			lastname: 'Heckfield',
			packageMasterCode: 'EnglishAU008',
			packageSfid: 'packageId2',
			packageTutorSfid: 'tutorId2',
			packageType: null,
			schedule: [],
			sessions: [],
			sessionCount: '0',
			sessionFrequency: 'Weekly',
			sessionReportId: null,
			startTime: null,
			student: 'Kerri',
			studentInformation: '',
			studentSfid: 'studentId1',
			subject: 'Mathematics',
			tutor: 'Cami',
			tutorPhoto: '',
			tutorNumber: 'T1234567891',
			tutorSfId: 'tutorId2',
			type: 'Course',
			yearAsInteger: 8,
			yearLevel: 'Year 8',
		},
	];
  const today = moment().tz(accountDetailsQuery.data.timezone);
  const mockUpcomingSessionStart1 = today.clone().add(2, 'day').startOf('day').hour(20).toISOString();
  const mockUpcomingSessionEnd1 = today.clone().add(2, 'day').startOf('day').hour(21).toISOString();
  const mockUpcomingSessionStart2 = today.clone().add(3, 'day').startOf('day').hour(19).toISOString();
  const mockUpcomingSessionEnd2 = today.clone().add(3, 'day').startOf('day').hour(20).toISOString();
	const mockUpcomingSessions: Session[] = [
		{
			automatedTutorChange: true,
			cancellationDateTime: null,
			discountCode: '',
			discountPercentage: 20,
			enrolmentType: '',
			invoiceId: 'invoiceId1',
			invoiceName: 'INVOICENAME1',
			endTime: mockUpcomingSessionEnd1,
			initiatedBy: null,
			isBundled: false,
			nextPaymentDate: '2023-01-06T06:00:00.000Z',
			paymentDate: null,
			paymentStatus: 'Paid',
			paymentType: 'PrePaid',
			recordingLink: null,
			rescheduledWithinDeadline: false,
			sessionReportId: 'sessionReportId1',
			sessionToken: 'Not Available',
			sessionsInBundle: 0,
			sessionDiscountActive: true,
			sessionDiscountName: '',
			sessionDiscountPercentage: 20,
			sessionDiscountStatus: 'Active',
			startTime: mockUpcomingSessionStart1,
			statusCode: '',
			student: 'Kerri',
			studentSessionId: 'studentSessionId1',
			subject: 'Mathematics',
			tutor: 'Tia',
			tutorNumber: 'T1234567890',
			tutorSessionId: 'tutorSessionId1',
			tutorPhoto: '',
			type: 'Course',
			uniqueEnrolmentId: 'uniqueEnrolmentId1',
			tutorRescheduleRequestStatusC: null,
			tutorSfId: 'tutorId1',
			packageTutorSfid: 'tutorId1',
		},
    {
			automatedTutorChange: true,
			cancellationDateTime: null,
			discountCode: '',
			discountPercentage: 20,
			enrolmentType: '',
			invoiceId: 'invoiceId2',
			invoiceName: 'INVOICENAME2',
			endTime: mockUpcomingSessionEnd2,
			initiatedBy: null,
			isBundled: false,
			nextPaymentDate: '2023-01-06T06:00:00.000Z',
			paymentDate: null,
			paymentStatus: 'Paid',
			paymentType: 'PrePaid',
			recordingLink: null,
			rescheduledWithinDeadline: false,
			sessionReportId: 'sessionReportId2',
			sessionToken: 'Not Available',
			sessionsInBundle: 0,
			sessionDiscountActive: true,
			sessionDiscountName: '',
			sessionDiscountPercentage: 20,
			sessionDiscountStatus: 'Active',
			startTime: mockUpcomingSessionStart2,
			statusCode: '',
			student: 'James',
			studentSessionId: 'studentSessionId2',
			subject: 'Mathematics',
			tutor: 'Cami',
			tutorNumber: 'T1234567891',
			tutorSessionId: 'tutorSessionId2',
			tutorPhoto: '',
			type: 'Course',
			uniqueEnrolmentId: 'uniqueEnrolmentId2',
			tutorRescheduleRequestStatusC: null,
			tutorSfId: 'tutorId2',
			packageTutorSfid: 'tutorId2',
		},
	];

	const studentsQuery = api.student.all.useQuery(undefined, {
    queryKey: ['students', 'all', 'preview'],
		enabled: false,
		placeholderData: mockStudents,
	});
	const upcomingSessionsQuery = api.session.all.useQuery('upcoming', {
    queryKey: ['session', 'all', 'preview'],
    enabled: false,
    placeholderData: mockUpcomingSessions,
		select: (data) => sortBy(data, ['startTime'], ['asc']),
	});
	const enrolmentsQuery = api.enrolment.all.useQuery('active', {
    queryKey: ['enrolment', 'all', 'preview'],
		enabled: false,
		placeholderData: mockEnrolments,
	});

	const studentsQueryWithSelector = api.student.all.useQuery(undefined, {
    queryKey: ['student', 'all', 'preview'],
		enabled: false,
		placeholderData: mockStudents,
		select: (data) => sortBy(data, ['firstName', 'lastName']),
	});
	return (
		<section className={classNames('bg-slate-1 ', className)}>
			<div
				className={classNames('container mx-auto px-4 pb-16 md:px-0', isPreview ? 'pointer-events-none pt-0 pb-4' : 'pt-10 pb-16')}
			>
				<h2 className="pb-8 font-display text-[28px] font-bold">Snapshot</h2>
				<div className="flex flex-col gap-16 rounded-lg border border-slate-2 bg-white px-4 py-8 shadow-md md:px-20 md:py-14">
					{/* Upcoming Sessions Section */}
					<SnapshotSessions
						accountDetailsQuery={accountDetailsQuery}
						studentsQuery={studentsQuery}
						upcomingSessionsQuery={upcomingSessionsQuery}
						enrolmentsQuery={enrolmentsQuery}
					/>
					{/* Students Section */}
					<SnapshotStudents studentsQuery={studentsQueryWithSelector} />
				</div>
			</div>
		</section>
	);
};
