import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'lodash';
import { Link } from '@cluey/cluey-components';

import {
  getEnrolmentSummerModeDetailById,
  getSummerDiscount,
  getSummerModeCompletedEnrolmentById,
} from '../../../selectors/summerModeSelector';
import alertInfo from '../../../assets/images/icon-filled-alert-info.svg';
import { redirectToFormWithEnrolmentId } from '../../../actions/hub/hubEnrolmentManagementActions';
import { willEnrolmentBePaused, getEnrolmentWillBePausedResumeSession } from '../../../selectors/enrolmentSelector';
import classNames from 'classnames';
import moment from 'moment';
import { PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP, PATH_HUB_CR_PAUSE_SESSIONS } from '../../../util/pagePath';
import { isEnrolmentPausedCheck, isGroupSessionCheck } from '../../../util/helpers';

const EnrolmentStatusSummerMode = ({
  enrolmentId,
  enrolmentStatus,
  type,
  enrolment,
  summerModeDiscount,
  summerModeCompletedEnrolmentDetail,
  willBePaused,
  willBePausedDates,
}) => {
  const { pauseFrom, resumeFrom } = willBePausedDates;
  const { yearLevel } = enrolment;
  const isEnrolmentPaused = isEnrolmentPausedCheck({ enrolmentStatus });
  const isGroup = isGroupSessionCheck(type);
  const redirectPath = isGroup
    ? PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP
    : isEnrolmentPaused
    ? `${PATH_HUB_CR_PAUSE_SESSIONS}?token=${enrolmentId}`
    : `${PATH_HUB_CR_PAUSE_SESSIONS}?token=${enrolmentId}`;

  if (!isEmpty(summerModeCompletedEnrolmentDetail)) {
    const { title, body } = summerModeCompletedEnrolmentDetail;
    const alignOption = body && typeof body === 'string' ? 'lg:items-start' : 'lg:items-center';
    return (
      <div
        className={`mt-4 flex flex-col rounded-lg bg-blue-1 px-4 py-5 md:mt-5 lg:mt-6 lg:flex-row lg:px-[60px] lg:py-7 ${alignOption}`}
      >
        <div className="mb-4 flex items-center lg:mb-0">
          <img src={alertInfo} alt="icon" className="mr-3" width="28" height="28" />
          <div className="flex flex-col">
            {title && <span className="mb-2 text-2xs font-semibold uppercase tracking-wider">{title}</span>}
            {body && typeof body === 'string' && <span className="mb-0 text-base font-semibold">{body}</span>}
            {body && typeof body !== 'string' && <div>{body}</div>}
          </div>
        </div>
      </div>
    );
  }

  const style = 'm-0 mb-3 font-display text-lg font-semibold text-grey-6';
  const preWizard = summerModeDiscount ? (
    <p className={style}>
      <span className="text-red-5">Save up to {summerModeDiscount}</span> with summer learning options
    </p>
  ) : (
    <p className={style}>Continue or take a break over the summer holidays</p>
  );

  const willBePausedText = (
    <p className={style}>
      Your enrolment will be paused from {moment(pauseFrom).format('MMMM D, YYYY')}
      &nbsp;and resume on {moment(resumeFrom).format('MMMM D, YYYY')}
    </p>
  );

  return (
    <div
      className={classNames(
        willBePaused ? 'bg-blue-1' : 'bg-yellow-1',
        'mt-4 flex flex-col rounded-lg px-4 py-5 md:mt-5 lg:mt-6 lg:px-[60px] lg:py-7'
      )}
    >
      <p className="mb-2 text-2xs font-semibold uppercase tracking-wider">End of year {yearLevel} approaching</p>
      {willBePaused ? willBePausedText : preWizard}
      <Link className="text-center text-base" to={redirectPath}>
        Manage your summer schedule or pause sessions.
      </Link>
    </div>
  );
};

EnrolmentStatusSummerMode.propTypes = {
  enrolmentId: PropTypes.string.isRequired,
  enrolmentStatus: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  enrolment: PropTypes.shape({
    yearLevel: PropTypes.number,
    enrolmentEnabledForOffboardingJourney: PropTypes.bool,
  }),
  summerModeDiscount: PropTypes.string,
  summerModeCompletedEnrolmentDetail: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.element, PropTypes.string])),
  willBePaused: PropTypes.bool.isRequired,
  willBePausedDates: PropTypes.shape({
    pauseFrom: PropTypes.string,
    resumeFrom: PropTypes.string,
  }).isRequired,
};

EnrolmentStatusSummerMode.defaultProps = {
  enrolment: {},
  summerModeDiscount: '',
  summerModeCompletedEnrolmentDetail: {},
  willBePaused: false,
};

export { EnrolmentStatusSummerMode as PresentationalEnrolmentStatusSummerMode };

export const ConnectedEnrolmentStatusSummerMode = connect(
  (state) => {
    const { enrolmentId, enrolmentStatus, enrolmentType: type } = state.hubEnrolmentDetailPage;
    return {
      enrolmentId,
      enrolmentStatus,
      type,
      wizardToken: state.summerMode.wizardToken,
      enrolment: getEnrolmentSummerModeDetailById(state, enrolmentId),
      summerModeDiscount: getSummerDiscount(state),
      summerModeCompletedEnrolmentDetail: getSummerModeCompletedEnrolmentById(state, enrolmentId),
      willBePaused: willEnrolmentBePaused(state),
      willBePausedDates: getEnrolmentWillBePausedResumeSession(state, true),
    };
  },
  (dispatch) => {
    return {
      redirectToForm: bindActionCreators(redirectToFormWithEnrolmentId, dispatch),
    };
  }
)(EnrolmentStatusSummerMode);
