import { LinkButton } from '@cluey/cluey-components';
import { ExclamationMarkIcon } from '../../icons';
import HubFooter from '../hub/HubFooter/HubFooter';
import HubHeader from '../hub/HubHeader';
import { PATH_HUB_HOME } from '../../util/pagePath';

export const ErrorFallback = (error: Error, resetError: () => void) => {
  return (
    <div className="hub flex flex-col">
      <HubHeader hideMenu onLogoClick={resetError} />
      <div className="body-container">
        <div className="container m-auto mt-7 flex flex-col gap-y-4 px-0 lg:mt-10 lg:pt-7">
          <ExclamationMarkIcon width={100} height={100} className="lg:mx-auto" />
          <div className="mx-auto w-full text-center text-3xl">Something went wrong</div>
          <LinkButton to={PATH_HUB_HOME} size="lg" onClick={resetError} className="mx-auto">
            Return to Hub
          </LinkButton>
        </div>
      </div>
      <HubFooter hideContactUs />
    </div>
  );
};
