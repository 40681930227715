import initialState from '../../initialState';
import * as actionTypes from '../../../actions/actionTypes';

export default function contactUsFlowReducer(state = initialState.ui.apiState.contactUsFlow, action) {
  switch (action.type) {
    case actionTypes.APP_UPDATE_VIEW:
      return {
        ...state,
        isLoading: false,
        fetchError: false,
      };
    case actionTypes.CF_SUBMIT_CONTACTUS_FORM_START:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.CF_SUBMIT_CONTACTUS_FORM_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.CF_SUBMIT_CONTACTUS_FORM_FAILED:
      return {
        ...state,
        isLoading: false,
        fetchError: true,
      };
    case actionTypes.RS_POST_SESSION_TO_CHANGE_START:
      return {
        ...state,
        isLoading: true,
        requestType: action.payload.requestType,
      };
    case actionTypes.RS_POST_SESSION_TO_CHANGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.RS_POST_SESSION_TO_CHANGE_FAILED:
      return {
        ...state,
        isLoading: false,
        fetchError: true,
      };
    default:
      return state;
  }
}
