import React from 'react';
import PropTypes from 'prop-types';

const TextBlock = ({ upper, lower }) => {
  return (
    <div className="mb-5">
      <span className="block mb-3 text-base font-bold leading-none">{upper}</span>
      <span className="block mb-4 text-sm leading-none">{ lower }</span>
    </div>
  );
};

TextBlock.propTypes = {
  upper: PropTypes.string.isRequired,
  lower: PropTypes.string.isRequired,
};

export default TextBlock;
