import { z } from 'zod';
import { ENROLMENT_STATUS } from '../../types/hubEnums';
import { RESCHEDULE_REQUEST_STATUS } from '../../types/hubEnums';
import { DefaultedNullString } from './common';
import { PaymentStatus } from './session';

export const EnrolmentSchedule = z.object({
	dayOfWeek: z.string(),
	schedule: z.string(),
	timeOfDay: z.string(),
});
export type EnrolmentSchedule = z.infer<typeof EnrolmentSchedule>;

export const EnrolmentDetail = z.object({
	automatedTutorChange: z.boolean().nullable().optional(),
	cancelReason: z.string().nullable().optional(),
	courseName: z.string(),
	curriculum: z.string(),
	customerNumber: z.string(),
	englishTextStudied: z.nullable(z.string()),
	effectiveFromDate: z.string().nullable().optional(),
	enrolmentCancelRequestedDate: z.nullable(z.string()),
	enrolmentDiscipline: z.string().optional(),
	enrolmentId: z.string(),
	enrolmentPausedFrom: z.nullable(z.string()),
	enrolmentPausedTo: z.nullable(z.string()),
	enrolmentSchedule: z.array(EnrolmentSchedule).optional(),
	enrolmentSfid: z.string(),
	enrolmentStatus: z.nativeEnum(ENROLMENT_STATUS),
	enrolmentSubject: z.string().optional(),
	enrolmentType: z.string().optional(),
	enrolmentYearLevel: z.string().optional(),
	nextPaymentDate: z.string().nullish(),
	nextSessionDate: z.string().nullish(),
	nextSessionEndDate: z.string().nullish(),
	nextSessionStudentSessionId: z.string().nullish(),
	packageMasterCode: z.string(),
	packageSfid: z.string(),
	sessions: z.array(
		z.object({
			end: z.string(),
			enrolmentId: z.string(),
			rescheduleRequestStatus: z.nativeEnum(RESCHEDULE_REQUEST_STATUS).nullish(),
			start: z.string(),
			studentSessionId: z.string(),
			tutorSessionId: z.string(),
			paymentStatus: PaymentStatus,
		})
	),
	sessionFrequency: z.string(),
	sessionLink: z.string().nullish(),
	specialAttributes: z.string().nullable().optional(),
	studentInformation: z.string().nullable(),
	studentName: z.string().optional(),
	tutorChangeReason: z.string().nullable().optional(),
	tutorDescription: z.string().nullable(),
	tutorName: z.string().optional(),
	tutorNumber: z.string(),
	tutorPhoto: z.string(),
	tutorSfId: z.string(),
	tutorState: z.string().nullish(),
	tutorTimezone: z.string().optional(),
	tutorType: z.string().nullable(),
	yearAsInteger: z.number(),
});
export type EnrolmentDetail = z.infer<typeof EnrolmentDetail>;

export const PackageType = z.enum(['Test Prep']);
export type PackageType = z.infer<typeof PackageType>;

export const Enrolment = z.object({
	aiTutor: z.object({
		allowed: z.boolean(),
		requestsLeft: z.number(),
	}).optional(),
	cancelReason: z.string().nullable(),
	costPerSession: z.number(),
	courseName: z.string(),
	curriculum: z.string(),
	customerNumber: z.string(),
	englishTextStudied: z.string().nullable(),
	endTime: z.string().nullable(),
	enrolmentCancelRequestedDate: z.string().nullable(),
	enrolmentEndDate: z.string().nullable(),
	enrolmentId: z.string(),
	enrolmentPausedFrom: z.string().nullable(),
	enrolmentPausedTo: z.string().nullable(),
	enrolmentSfid: z.string(),
	enrolmentStartDate: z.string(),
	enrolmentStatus: z.nativeEnum(ENROLMENT_STATUS),
	lastname: z.string(),
	packageMasterCode: z.string(),
	packageSfid: z.string(),
	packageTutorSfid: z.string(),
	packageType: z.string().nullable(),
	schedule: z.array(z.string()).optional(), // past enrolments do not have a schedule property
	sessions: z
		.array(
			z.object({
				end: z.string(),
				enrolmentId: z.string(),
				rescheduleRequestStatus: z.nativeEnum(RESCHEDULE_REQUEST_STATUS).nullish(),
				start: z.string(),
				studentSessionId: z.string(),
				tutorSessionId: z.string(),
				paymentStatus: PaymentStatus,
				enrolmentType: z.enum(['Trial', '']).nullish(),
				sessionToken: z.string(),
			})
		)
		.optional(),
	sessionCount: z.string(),
	sessionFrequency: z.string(),
	sessionReportId: z.string().nullable(),
	startTime: z.string().nullable(),
	student: z.string(),
	studentInformation: z.string().nullable(),
	studentSfid: z.string(),
	subject: z.string(),
	tutor: z.string(),
	tutorPhoto: z.string(),
	tutorNumber: z.string(),
	tutorSfId: z.string(),
	type: z.string(),
	yearAsInteger: z.number(),
	yearLevel: z.string(),
});
export type Enrolment = z.infer<typeof Enrolment>;

export const ProgramOverview = z.object({
	enrolmentDetailsUrl: DefaultedNullString,
	outcomesOverview: DefaultedNullString,
	uniqueEnrolmentId: DefaultedNullString,
	yearLevel: DefaultedNullString,
});

export type ProgramOverview = z.infer<typeof ProgramOverview>;
