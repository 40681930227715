import initialState from './initialState';
import {
  SR_REMOVE_ALL_ACTIVITIES,
  SR_REQUEST_ACTIVITIES_SUCCESS,
} from '../actions/actionTypes';

export default function activitiesReducer(state = initialState.activities, action) {
  switch (action.type) {
    case SR_REMOVE_ALL_ACTIVITIES:
      return [];
    case SR_REQUEST_ACTIVITIES_SUCCESS:
      return state.concat(action.payload.activities || []);
    default:
      return state;
  }
}
