export const REQ_GET_SUMMARY = 'REQ_GET_SUMMARY';
export const REQ_GET_LOGIN = 'REQ_GET_LOGIN';
export const REQ_GET_ACTIVITIES = 'REQ_GET_ACTIVITIES';
export const REQ_GET_DISCIPLINE_LIST = 'REQ_GET_DISCIPLINE_LIST';

// upcoming sessions
export const REQ_GET_UPCOMING_SESSIONS = 'REQ_GET_UPCOMING_SESSIONS';
export const REQ_GET_UPCOMING_SESSIONS_TOKEN = 'REQ_GET_UPCOMING_SESSIONS_TOKEN';

// contact us
// NOTE: this endpoint is duplicated from REQ_POST_HUB_CREATE_NEW_CASE_SF but is unauthenticated.
export const REQ_POST_TO_CONTACT = 'REQ_POST_TO_CONTACT';
export const REQ_POST_CREATE_NEW_CASE = 'REQ_POST_CREATE_NEW_CASE';
export const REQ_POST_CUSTOMER_TRIGGER_PAYMENT = 'REQ_POST_CUSTOMER_TRIGGER_PAYMENT';

// change session
export const REQ_POST_RESCHEDULE_SESSION = 'REQ_POST_RESCHEDULE_SESSION';
export const REQ_POST_CANCEL_SESSION = 'REQ_POST_CANCEL_SESSION';
export const REQ_POST_FETCH_REFUND_POLICY = 'REQ_POST_FETCH_REFUND_POLICY';

// hub sessions page (upcoming sessions & completed sessions)
export const REQ_GET_HUB_UPCOMING_SESSIONS = 'REQ_GET_HUB_UPCOMING_SESSIONS';
export const REQ_GET_HUB_COMPLETED_SESSIONS = 'REQ_GET_HUB_COMPLETED_SESSIONS';
export const REQ_GET_HUB_SCHOOL_TERMS_PUBLIC_HOLIDAYS = 'REQ_GET_HUB_SCHOOL_TERMS_PUBLIC_HOLIDAYS';

// hub enrolments page (active enrolments & past enrolments)
export const REQ_GET_HUB_ACTIVE_ENROLMENTS = 'REQ_GET_HUB_ACTIVE_ENROLMENTS';
export const REQ_GET_HUB_PAST_ENROLMENTS = 'REQ_GET_HUB_PAST_ENROLMENTS';

// hub account page
export const REQ_GET_HUB_ACCOUNT_DETAILS = 'REQ_GET_HUB_ACCOUNT_DETAILS';
export const REQ_GET_HUB_ACCOUNT_PRICING_PLAN = 'REQ_GET_HUB_ACCOUNT_PRICING_PLAN';
export const REQ_PUT_HUB_ACCOUNT_DETAILS = 'REQ_PUT_HUB_ACCOUNT_DETAILS';
export const REQ_GET_HUB_BILLING_INFORMATION = 'REQ_GET_HUB_BILLING_INFORMATION';
export const REQ_POST_HUB_SF_CHARGENT_ORDER = 'REQ_POST_HUB_SF_CHARGENT_ORDER';
export const REQ_POST_HUB_SF_PROCESS_PAYMENT = 'REQ_POST_HUB_SF_PROCESS_PAYMENT';
export const REQ_GET_HUB_INVOICE_HISTORY = 'REQ_GET_HUB_INVOICE_HISTORY';

export const REQ_POST_HUB_CREATE_NEW_CASE_SF = 'REQ_POST_HUB_CREATE_NEW_CASE_SF';
export const REQ_POST_HUB_RESEND_SIGNUP_LINK = 'REQ_POST_HUB_RESEND_SIGNUP_LINK';
export const REQ_GET_TUTOR_INFO = 'REQ_GET_TUTOR_INFO';
export const REQ_GET_SELECTED_TUTOR_INFO = 'REQ_GET_SELECTED_TUTOR_INFO';
export const REQ_POST_HUB_REQUEST_TO_RESET_PASSWORD = 'REQ_POST_HUB_REQUEST_TO_RESET_PASSWORD';

// student progress
export const REQ_GET_STUDENT_PROGRESS_SUMMARY = 'REQ_GET_STUDENT_PROGRESS_SUMMARY';
export const REQ_GET_STUDENT_PROGRESS_MODULE_DETAIL = 'REQ_GET_STUDENT_PROGRESS_MODULE_DETAIL';

export const REQ_GET_OUTCOME_NEXT_YEAR = 'REQ_GET_OUTCOME_NEXT_YEAR';

// hub enrolment management
export const REQ_GET_HUB_ENROLMENT_DETAIL = 'REQ_GET_HUB_ENROLMENT_DETAIL';
export const REQ_GET_HUB_PAUSE_SESSIONS_RESUME_SESSION = 'REQ_GET_HUB_PAUSE_SESSIONS_RESUME_SESSION';

// hub enrolment management - change schedule
export const REQ_POST_HUB_NEW_SCHEDULE = 'REQ_POST_HUB_NEW_SCHEDULE';
export const REQ_GET_PAST_TUTOR = 'REQ_GET_PAST_TUTOR';
export const REQ_GET_TUTOR_AVAILABILITY = 'REQ_GET_TUTOR_AVAILABILITY';

export const REQ_GET_GROUP_PACKAGES = 'REQ_GET_GROUP_PACKAGES';

// summer mode
export const REQ_GET_SUMMER_MODE_DATA = 'REQ_GET_SUMMER_MODE_DATA';
// Zuora
export const REQ_FETCH_ZUORA_PARAMS = 'REQ_FETCH_ZUORA_PARAMS';
