import { Button, Container } from '@cluey/cluey-components';
import { ClockIcon } from '../../../../icons';
import EnrolmentLayout from '../../../../common/hub/EnrolmentLayout';
import { useHistory } from 'react-router';
import { RetryIcon } from '../../../../icons';
import type { FormFlowProps } from '../../../../types/hubInterfaces';

interface Props {
  formFlowProps: FormFlowProps
}

interface State {
  yearLevel: string;
}

const GenerateAssessmentTimedOut = ({ formFlowProps }: Props) => {
  const history = useHistory<State>();
  const { location: { state } } = history;
  const { yearLevel } = state ?? {};

  const onRetryClick = () => {
    formFlowProps.jump(1, { yearLevel });
  }

  return (
    <EnrolmentLayout>
      <Container className='flex flex-col items-center gap-5 text-grey-6'>
        <ClockIcon width={40} height={40} className='text-blood-orange-4' />
        <p className='font-bold text-grey-6'>Oops, the assessment link didn't generate on time.</p>
        <p>Please try again, thank you for being patient.</p>
        <Button className='flex gap-3.5 items-center normal-case' onClick={onRetryClick} size="lg">
          <span>Generate assessment link again</span>
          <RetryIcon width={25} height={25} />
        </Button>
      </Container>
    </EnrolmentLayout>
  )
}

export { GenerateAssessmentTimedOut }