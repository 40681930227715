import { IconProps as Props } from "../types/hubInterfaces";
import { iconDefaults } from ".";
import classNames from 'classnames';
function PlusIcon(props: Props) {
  const { width, height, className } = { ...iconDefaults, ...props };
  return (
    <svg className={classNames('fill-current', className)} xmlns="http://www.w3.org/2000/svg" width={`${width}`} height={`${height}`} viewBox="0 0 14 14">
      <title>plus icon</title>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.7188 6.18346H7.81875V1.28346C7.81875 0.831847 7.45288 0.466797 7.00208 0.466797C6.55128 0.466797 6.18542 0.831847 6.18542 1.28346V6.18346H1.28542C0.834617 6.18346 0.46875 6.54851 0.46875 7.00013C0.46875 7.45175 0.834617 7.8168 1.28542 7.8168H6.18542V12.7168C6.18542 13.1684 6.55128 13.5335 7.00208 13.5335C7.45288 13.5335 7.81875 13.1684 7.81875 12.7168V7.8168H12.7188C13.1696 7.8168 13.5354 7.45175 13.5354 7.00013C13.5354 6.54851 13.1696 6.18346 12.7188 6.18346Z" />
    </svg>
  )
}
export { PlusIcon };