import { get, isEmpty } from 'lodash';
import moment from 'moment';
import { logError } from '../../logging';
import * as actionTypes from '../actionTypes';
import {
  apiFetch,
  REQ_GET_HUB_ENROLMENT_DETAIL,
  REQ_GET_PAST_TUTOR,
  REQ_GET_STUDENT_PROGRESS_SUMMARY,
  REQ_GET_TUTOR_INFO,
  REQ_GET_TUTOR_AVAILABILITY,
} from '../../services/backendApi';
import { REQ_GET_OUTCOME_NEXT_YEAR } from '../../services/backendApi/endpointTypes';
import { getResumeSession, saveSelectedEnrolment, saveSelectedStudent, submitCaseToSF } from './hubContactPageActions';
import { SF_CASES_DESCRIPTION_DATE_FORMAT } from '../../util/constants';
import {
  PATH_HUB_TTC_CHANGE_SESSION_KEEP_TUTOR,
  PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_KEEP_PREV_TUTOR,
  PATH_HUB_ENROLMENT_DETAIL,
} from '../../util/pagePath';

export const saveEnrolmentIdForEnrolmentManagement =
  ({ enrolmentId }) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.HUB_EM_SAVE_ENROLMENT_ID_FOR_MANAGEMENT,
      payload: { enrolmentId },
    });
  };

export const fetchOutcomeNextYear = () => (dispatch, getState) => {
  dispatch({
    type: actionTypes.HUB_EP_FETCH_COURSE_OVERVIEW_START,
    payload: null,
  });
  return apiFetch(REQ_GET_OUTCOME_NEXT_YEAR, getState())
    .then((json) => {
      dispatch({
        type: actionTypes.HUB_EP_FETCH_COURSE_OVERVIEW_SUCCESS,
        payload: json,
      });
    })
    .catch((e) => {
      dispatch({ type: actionTypes.HUB_EP_FETCH_COURSE_OVERVIEW_FAILED });
      logError(e, null, { message: 'Hub - Unable to fetch next year description' });
    });
};

export const fetchStudentProgressData = () => (dispatch, getState) => {
  dispatch({
    type: actionTypes.HUB_EP_FETCH_PROGRESS_SUMMARY_START,
    payload: null,
  });
  return apiFetch(REQ_GET_STUDENT_PROGRESS_SUMMARY, getState())
    .then((json) => {
      dispatch({
        type: actionTypes.HUB_EP_FETCH_PROGRESS_SUMMARY_SUCCESS,
        payload: json,
      });
    })
    .catch((e) => {
      dispatch({ type: actionTypes.HUB_EP_FETCH_PROGRESS_SUMMARY_FAILED });
      logError(e, null, { message: 'Unable to fetch student progress data' });
    });
};

export const fetchTutorInfo =
  ({ tutorNumber = null, start = null, success = null, failed = null }) =>
  (dispatch, getState) => {
    let actions = {
      start: actionTypes.HUB_EM_FETCH_TUTOR_INFO_START,
      success: actionTypes.HUB_EM_FETCH_TUTOR_INFO_SUCCESS,
      failed: actionTypes.HUB_EM_FETCH_TUTOR_INFO_FAILED,
    };
    let requestPayload = {};

    if (tutorNumber) {
      actions = {
        start,
        success,
        failed,
      };
      requestPayload = { tutorNumber };
    }
    dispatch({ type: actions.start });
    return apiFetch(REQ_GET_TUTOR_INFO, getState(), requestPayload, dispatch)
      .then((json) => {
        dispatch({ type: actions.success, payload: json });
      })
      .catch((e) => {
        dispatch({ type: actions.failed });
        logError(e, null, { message: 'Get tutor info failed' });
      });
  };

export const fetchPastTutor = () => (dispatch, getState) => {
  dispatch({ type: actionTypes.HUB_EM_FETCH_PREVIOUS_TUTOR_INFO_START });
  return apiFetch(REQ_GET_PAST_TUTOR, getState())
    .then(async (response) => {
      const { oldTutorId, oldTutorSfId, nextSessionWithNewTutor, previousSessionWithNewTutor } = response || {};
      if (oldTutorId !== undefined) {
        await dispatch({
          type: actionTypes.HUB_EM_SAVE_PREVIOUS_TUTOR_NUMBER,
          payload: {
            tutorNumber: oldTutorId,
            tutorSfId: oldTutorSfId,
          },
        });
        dispatch({
          type: actionTypes.HUB_EM_SAVE_SESSION_INFO_WITH_NEW_TUTOR,
          payload: {
            nextSessionWithNewTutor,
            previousSessionWithNewTutor,
          },
        });
        dispatch({ type: actionTypes.HUB_EM_FETCH_PREVIOUS_TUTOR_INFO_SUCCESS });
        dispatch(
          fetchTutorInfo({
            tutorNumber: oldTutorId,
            start: actionTypes.HUB_EM_FETCH_TUTOR_INFO_START,
            success: actionTypes.HUB_EM_FETCH_TUTOR_INFO_SUCCESS,
            failed: actionTypes.HUB_EM_FETCH_TUTOR_INFO_FAILED,
          })
        );
      } else {
        // NOTE: when no previous tutor found, it will be a 404 response.
        // Since we treat 404 as ok, we have to handle it here
        dispatch({ type: actionTypes.HUB_EM_FETCH_PREVIOUS_TUTOR_INFO_FAILED });
      }
      return null;
    })
    .catch((e) => {
      dispatch({ type: actionTypes.HUB_EM_FETCH_PREVIOUS_TUTOR_INFO_FAILED });
      logError(e, null, { message: 'Get past tutor failed' });
    });
};

export const fetchEnrolment =
  ({ enrolmentId }) =>
  (dispatch, getState) => {
    const state = getState();
    const savedPageToken = get(state, 'hubEnrolmentDetailPage.enrolmentId', '');

    if (!enrolmentId) {
      dispatch({ type: actionTypes.HUB_EP_FETCH_ENROLMENT_DETAIL_FAILED });
      return Promise.resolve({});
    }

    if (
      !isEmpty(savedPageToken) &&
      enrolmentId === savedPageToken &&
      !state.ui.apiState.hubEnrolmentDetailPage.firstFetch
    ) {
      // Don't dispatch the fetching of enrolment detail if
      // the enrolment we want to fetch is the one saved in state
      const savedEnrolmentDetail = get(state, 'hubEnrolmentDetailPage', {});
      dispatch({ type: actionTypes.HUB_EP_FETCH_ENROLMENT_DETAIL_SUCCESS });
      return Promise.resolve(savedEnrolmentDetail);
    }

    dispatch({
      type: actionTypes.HUB_EP_SAVE_ENROLMENT_DETAIL_PAGE_TOKEN,
      payload: { enrolmentId },
    });

    dispatch({ type: actionTypes.HUB_EP_FETCH_ENROLMENT_DETAIL_START });

    return apiFetch(REQ_GET_HUB_ENROLMENT_DETAIL, getState())
      .then(async (json) => {
        dispatch({ type: actionTypes.HUB_EP_FETCH_ENROLMENT_DETAIL_SUCCESS, payload: { ...json } });
        dispatch(fetchOutcomeNextYear());
        dispatch(fetchStudentProgressData());
        const { automatedTutorChange = false } = json;
        if (automatedTutorChange) {
          await dispatch(fetchPastTutor());
        } else {
          // NOTE: to stop tutor loading spinner
          dispatch({ type: actionTypes.HUB_EM_FETCH_PREVIOUS_TUTOR_INFO_SUCCESS });
          dispatch({ type: actionTypes.HUB_EM_FETCH_TUTOR_INFO_SUCCESS });
        }
      })
      .catch((e) => {
        dispatch({ type: actionTypes.HUB_EP_FETCH_ENROLMENT_DETAIL_FAILED });
        logError(e, null, { message: `Hub - Get enrolment detail failed with ${enrolmentId}` });
      });
  };

export const redirectToEnrolmentManagement =
  ({ enrolmentId, callback }) =>
  (dispatch) => {
    dispatch(saveEnrolmentIdForEnrolmentManagement({ enrolmentId }));
    dispatch(saveSelectedEnrolment({ enrolmentId }));
    return callback();
  };

export const redirectToFormWithEnrolmentId =
  ({ enrolmentId, student = '', callback }) =>
  (dispatch) => {
    if (!!student) {
      dispatch(saveSelectedStudent({ student }));
      return callback();
    }

    dispatch(saveSelectedEnrolment({ enrolmentId }));
    return callback();
  };

export const fetchTutorAvailability =
  ({ page, tutorNumber, numberOfDaysToLoad = 0 }) =>
  (dispatch, getState) => {
    let actions = {};
    const requestPayload = { tutorNumber };
    if (page === PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_KEEP_PREV_TUTOR) {
      actions = {
        start: actionTypes.HUB_EM_FETCH_TUTOR_AVAILABILITY_START,
        success: actionTypes.HUB_EM_FETCH_TUTOR_AVAILABILITY_SUCCESS,
        failed: actionTypes.HUB_EM_FETCH_TUTOR_AVAILABILITY_FAILED,
      };
    } else if (page === PATH_HUB_TTC_CHANGE_SESSION_KEEP_TUTOR) {
      actions = {
        start: actionTypes.HUB_TTC_FETCH_CURRENT_TUTOR_AVAILABILITY_START,
        success: actionTypes.HUB_TTC_FETCH_CURRENT_TUTOR_AVAILABILITY_SUCCESS,
        failed: actionTypes.HUB_TTC_FETCH_CURRENT_TUTOR_AVAILABILITY_FAILED,
      };
      requestPayload.numberOfDays = numberOfDaysToLoad;
    }
    return new Promise(() => {
      dispatch({ type: actions.start });

      if (!tutorNumber) {
        dispatch({
          type: actions.failed,
          payload: {
            errorMsg: 'Unable to get tutor availability. Please try again later.',
          },
        });
        return null;
      }

      return apiFetch(REQ_GET_TUTOR_AVAILABILITY, getState(), requestPayload, dispatch)
        .then((response) => {
          dispatch({
            type: actions.success,
            payload: {
              tutorAvailability: response,
            },
          });
        })
        .catch((e) => {
          dispatch({
            type: actions.failed,
            payload: {
              errorMsg: (e || {}).message || 'Unable to get tutor availability. Please try again later.',
            },
          });
          logError(e, null, { message: `Unable to get availabilities for tutor: ${tutorNumber}` });
        });
    });
  };

export const initPreviousTutorAvailabilityPage =
  ({ enrolmentId }) =>
  async (dispatch, getState) => {
    return dispatch(fetchEnrolment({ enrolmentId }))
      .then(() => {
        const state = getState();
        const automatedTutorChange = get(state, 'hubEnrolmentDetailPage.automatedTutorChange', false);
        if (automatedTutorChange) {
          const tutorNumber = get(state, 'hubEnrolmentDetailPage.alternativeTutor.tutorNumber', '');
          return dispatch(
            fetchTutorAvailability({
              page: PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_KEEP_PREV_TUTOR,
              tutorNumber,
            })
          );
        }
        return null;
      })
      .catch((e) => {
        console.error(e);
      });
  };

export const loadPkgTutorAvailability =
  ({ tutorNumber, numberOfDaysToLoad }) =>
  (dispatch) => {
    if (numberOfDaysToLoad > 0) {
      return dispatch(
        fetchTutorAvailability({
          page: PATH_HUB_TTC_CHANGE_SESSION_KEEP_TUTOR,
          tutorNumber,
          numberOfDaysToLoad,
        })
      );
    }

    return dispatch({
      type: actionTypes.HUB_TTC_FETCH_CURRENT_TUTOR_AVAILABILITY_SUCCESS,
      payload: {
        tutorAvailability: [],
      },
    });
  };

export const saveSelectedDayAndTime =
  ({ selectedDay, selectedTime, sessionId }) =>
  (dispatch) => {
    return Promise.resolve(
      dispatch({
        type: actionTypes.HUB_TTC_SAVE_SELECTED_SESSION_DAY_TIME,
        payload: {
          sessionId,
          selectedDay,
          selectedTime,
        },
      })
    );
  };

/*
 * NOTE: this function is duplicated from hubChangeSchedulePageActions.js / saveSelectedSchedule
 * We are planning to merge change schedule page into enrolment management flow
 * but we just don't have time to do this now
 */
export const saveSelectedSchedule =
  ({ selectedDay, scheduleDate, sessionDuration, tutorNumber }) =>
  (dispatch) => {
    return Promise.resolve(
      dispatch({
        type: actionTypes.HUB_EM_SAVE_SELECTED_TUTOR_SCHEDULE,
        payload: {
          tutorNumber,
          selectedDay,
          scheduleDate,
          sessionDuration,
        },
      })
    );
  };

export const resetManagePausePageState = () => (dispatch) => {
  return dispatch({
    type: actionTypes.HUB_EM_MP_RESET_PAGE_STATE,
  });
};

export const managePause =
  ({ enrolmentId, currentPauseStart, currentResumeDate, selectedResumeDate, callback }) =>
  (dispatch, getState) => {
    if (moment(currentResumeDate).isSame(moment(selectedResumeDate), 'day')) {
      dispatch({
        type: actionTypes.HUB_EM_MP_SAVE_RESUME_SESSION,
        payload: {
          resumeSessionDate: currentResumeDate,
        },
      });
      return callback();
    }

    let sfAction = 'Extend pause';

    let datesPayload = {
      startDate: currentPauseStart,
      endDate: selectedResumeDate,
    };

    let resumeSessionAction = () => {
      return dispatch(
        getResumeSession({
          enrolmentId,
          pauseTo: selectedResumeDate,
          page: 'manage-pause',
        })
      );
    };

    if (moment(selectedResumeDate).isBefore(moment(currentResumeDate), 'day')) {
      sfAction = 'Restart';
      datesPayload = {
        startDate: selectedResumeDate,
      };
      resumeSessionAction = () => {
        return new Promise((resolve) => {
          dispatch({
            type: actionTypes.HUB_EM_MP_FETCH_RESUME_SESSION_SUCCESS,
            payload: {
              enrolmentId,
              resumeSessionDate: selectedResumeDate,
              isRestart: true,
            },
          });
          resolve();
        });
      };
    }

    return resumeSessionAction().then(() => {
      const state = getState();
      const newResumeSessionDate = state.hubEnrolmentDetailPage.managePause.resumeSessionDate;
      if (!newResumeSessionDate) {
        return Promise.resolve();
      }
      const { timezone } = state.hubUser;

      const caseType = 'Scheduling';
      const caseCategory = 'Pause sessions';

      const resumeDateInDescription = sfAction === 'Restart' ? selectedResumeDate : newResumeSessionDate;
      const resumeDateFormatted = moment(resumeDateInDescription).tz(timezone).format(SF_CASES_DESCRIPTION_DATE_FORMAT);

      const payload = {
        page: 'manage-pause',
        action: sfAction,
        uniqueEnrolmentId: enrolmentId,
        message: `Request to ${sfAction} and resume on ${resumeDateFormatted}`,
        caseSubject: `Contact request - ${caseType} - ${caseCategory}`,
        caseType,
        caseCategory,
        onSubmitSuccess: () => {
          callback();
        },
        ...datesPayload,
      };
      return dispatch(submitCaseToSF(payload));
    });
  };

export const fetchEnrolmentForManagement =
  ({ enrolmentId, callback }) =>
  (dispatch) => {
    return new Promise((resolve) => {
      dispatch(fetchEnrolment({ enrolmentId }));
      resolve();
    }).then(() => {
      callback();
    });
  };

export const changeSession =
  ({ enrolmentId, sessionId, startDate, endDate, customerNumber, onSuccess, onError }) =>
  (dispatch) => {
    const payload = {
      action: 'Reschedule session',
      uniqueEnrolmentId: enrolmentId,
      studentSession: sessionId,
      startDate,
      endDate,
      customerNumber,
      onSubmitSuccess: onSuccess,
      onSubmitFailed: onError,
      requestActions: {
        start: actionTypes.HUB_TTC_RESCHEDULE_SESSION_START,
        success: actionTypes.HUB_TTC_RESCHEDULE_SESSION_SUCCESS,
        failed: actionTypes.HUB_TTC_RESCHEDULE_SESSION_FAILED,
      },
    };
    return dispatch(submitCaseToSF(payload));
  };

export const fetchSessionTutor =
  ({ tutorNumber }) =>
  (dispatch) => {
    return dispatch(
      fetchTutorInfo({
        tutorNumber,
        start: actionTypes.HUB_TTC_FETCH_SESSION_TUTOR_START,
        success: actionTypes.HUB_TTC_FETCH_SESSION_TUTOR_SUCCESS,
        failed: actionTypes.HUB_TTC_FETCH_SESSION_TUTOR_FAILED,
      })
    );
  };

export const accessEnrolmentDetailPage =
  ({ history, enrolmentIdToken = null } = {}) =>
  (dispatch, getState) => {
    console.info({ enrolmentIdToken });
    const { hubEnrolmentsPage } = getState();
    const { activeEnrolments, pastEnrolments } = hubEnrolmentsPage;
    const allEnrolments = [...activeEnrolments, ...pastEnrolments];
    //  Note, if there are more than one active enrolment, there will be a select model popup
    let enrolment = {};

    if (enrolmentIdToken) {
      enrolment = (allEnrolments || []).find((e) => e.enrolmentId === enrolmentIdToken) || {};
    }

    // NOTE: Do not merge the below two if statement.
    // There is a bug when user only has one enrolment so we have to do this.
    if (!isEmpty(enrolment)) {
      const { enrolmentId } = enrolment;
      return dispatch(fetchEnrolment({ enrolmentId }));
    }

    if (allEnrolments.length === 1) {
      const { enrolmentId } = allEnrolments[0];
      history.push(`${PATH_HUB_ENROLMENT_DETAIL}?token=${enrolmentId}`);
    }

    return null;
  };
