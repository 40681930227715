import { Checkbox } from '@cluey/cluey-components';
import { Student } from '../../../../api/types/student';
import { SpecialNeedsLoader } from './SpecialNeedsLoader';
import { UseFormReturn, Controller } from 'react-hook-form';
import { z } from 'zod';
import { HUB_LEARNING_NEEDS } from '../../../../util/learning-needs';

interface Props {
	student: Student;
	loading?: boolean;
	formUtils: UseFormReturn<SpecialNeedsData>;
}

const SpecialNeeds = ({ student, loading, formUtils }: Props) => {
	const { control, watch } = formUtils;
	const checkedNeeds = watch('specialLearningNeeds', []);

	if (loading) {
		return <SpecialNeedsLoader />;
	}

	return (
		<div className="flex flex-col gap-6">
			<div className="text-xs font-bold uppercase tracking-[1px]">Diagnosed Special learning needs</div>
			<p>
				Has {student.firstName} been diagnosed with any of the following special learning needs that our tutors may be
				able to support? (Optional)
			</p>
			<div className="flex flex-col items-start gap-4">
				{HUB_LEARNING_NEEDS.map((need) => {
					return (
						<div key={need}>
							<label className="flex cursor-pointer items-center justify-start gap-4" htmlFor={need}>
								<Controller
									name="specialLearningNeeds"
									control={control}
									render={({ field: { onChange } }) => (
										<div className="shrink-0">
											<Checkbox
												value={checkedNeeds?.includes(need)}
												id={need}
												onChange={() => {
													if (checkedNeeds?.includes(need)) {
														onChange(checkedNeeds.filter((n) => n !== need));
													} else {
														onChange([...checkedNeeds, need]);
													}
												}}
											/>
										</div>
									)}
								></Controller>
								<span className="select-none text-grey-6">{need}</span>
							</label>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export const SpecialNeedsData = z.object({
	specialLearningNeeds: z.array(z.string()).nullish(),
});
export type SpecialNeedsData = z.infer<typeof SpecialNeedsData>;

export { SpecialNeeds };
