import { z } from 'zod';
import { createApi } from '../core';
import { AccountDetails } from '../types/account';
import { ACCOUNT_TYPE } from '../../util/constants';

export type UpdateCustomerParams = {
	customerType: typeof ACCOUNT_TYPE[keyof typeof ACCOUNT_TYPE];
	email: string;
	customerId?: string; // --this will be the SFID of either the lead or account
	customerNumber?: string; // --this will be the customer Id of the Cognito Account
	mobilePhone?: string;
	firstname?: string;
	lastname?: string;
	studentFirstName?: string;
	studentLastName?: string;
	curriculum?: string;
	yearLevel?: string;
	schoolId?: string; // --this will be the SFId of the school account
	recordType?: string; // --valid values are “Student”, “Parent / Guardian”
	countryCode?: string;
};

type UpdateAccountParams = {
	firstName: string;
	lastName: string;
	email: string;
	phone?: string;
};

export const accountApi = createApi.group(['account'], (createKey) => {
	const detailsQueryConfig = {
		queryKey: createKey(['details']),
		queryFn: () => {
			return createApi.fetch({
				path: ['account', 'details'],
				method: 'GET',
				basePath: 'portalAPI',
				sign: true,
				validator: AccountDetails,
			});
		},
		staleTime: createApi.time(1, 'hours'),
		refetchInterval: createApi.time(2, 'hours'),
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	};

	return {
		details: createApi.query(detailsQueryConfig),
		timezone: createApi.query({
			queryKey: createKey(['details']),
			queryFn: () => {
				return createApi.fetch({
					path: ['account', 'details'],
					method: 'GET',
					basePath: 'portalAPI',
					sign: true,
					validator: AccountDetails,
				});
			},
			staleTime: createApi.time(1, 'hours'),
			refetchInterval: createApi.time(2, 'hours'),
			refetchOnMount: false,
			refetchOnReconnect: false,
			refetchOnWindowFocus: false,
		}),
		update: createApi.mutation({
			mutationFn: (params: Partial<UpdateCustomerParams>) => {
				try {
					return createApi.fetch({
						path: ['customer', 'details'],
						method: 'PUT',
						body: params,
						validator: z.unknown(),
						basePath: 'portalAPI',
						sign: true,
					});
				} catch (error) {
					console.error('THROWN', error);
				}
			},
		}),
		update_legacy: createApi.mutation({
			mutationFn: (params: UpdateAccountParams) => {
				return createApi.fetch({
					path: ['account', 'details'],
					method: 'PUT',
					body: params,
					validator: z.unknown(),
					basePath: 'portalAPI',
					sign: true,
				});
			},
		}),
	};
});
