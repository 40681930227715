import { WEBSITE_DOMAIN, COUNTRY } from '../constants';

export const getWebsiteDomainByTZ = (): string => {
  const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  const isInNewZealand = (timezone || '').toLowerCase() === 'pacific/auckland';
  return isInNewZealand ? WEBSITE_DOMAIN.NZ_DOMAIN : WEBSITE_DOMAIN.AU_DOMAIN;
};

export const getWebsiteDomainByCountry = (country: string): string => {
  return country.toLowerCase() === COUNTRY.NEW_ZEALAND ? WEBSITE_DOMAIN.NZ_DOMAIN : WEBSITE_DOMAIN.AU_DOMAIN;
};
