import React, { useEffect, useState } from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';

import { SelectionModalOptionsType, SelectionModalOptionsDefault } from '../../../types/hubPropTypes';
import withHeadTag from '../../../common/HeadComponent';
import ContactFormLayout from '../../../common/hub/ContactFormLayout';
import UpdatePaymentInformationModal from '../../../components/hub/contact/infoModals/UpdatePaymentInformationModal';
import GeneralChangeSessionModal from '../../../components/hub/contact/infoModals/GeneralChangeSessionModal';
import SelectionModal, { useSelectionModalProps } from '../../../components/hub/contact/SelectionModal';

import { getEnrolmentsAsTextValuePair } from '../../../selectors/hubSelector';
import { saveSelectedEnrolment } from '../../../actions/hub/hubContactPageActions';
import { redirectToChangeSchedule } from '../../../actions/hub/hubChangeSchedulePageActions';

import * as PATHS from '../../../util/pagePath';
import { ENROLMENT_SELECTION_MODAL_TITLE } from '../../../util/constants';

const HubGeneralContactOptions = ({ enrolments, saveSelectedEnrolmentId, redirectToCSP }) => {
	const history = useHistory();

	useEffect(() => {
		if (enrolments.length === 0) {
			history.push(PATHS.PATH_HUB_HOME);
		}
	});

	const [showModalUPI, setShowModalUPI] = useState(false);
	const toggleModalUPI = () => setShowModalUPI(!showModalUPI);

	const [showModalGCS, setShowModalGCS] = useState(false);
	const toggleModalGCS = () => setShowModalGCS(!showModalGCS);

	const [redirect, setRedirect] = useState(PATHS.PATH_HUB_CR_REQUEST_OPTIONS);

	const { showModal, setShowModal, selectedRadio, setSelectedRadio, toggleModal } = useSelectionModalProps();

	const onClickHandler = (redirectPath) => {
		if (enrolments.length === 1) {
			saveSelectedEnrolmentId({
				enrolmentId: enrolments[0].value,
			});
			history.push(redirectPath);
		} else {
			setRedirect(redirectPath);
			setShowModal(true);
		}
	};

	const onPermanentScheduleChangeClick = () => {
		if (enrolments.length === 1) {
			const { isGroup } = enrolments[0];
			const redirectPath = isGroup
				? PATHS.PATH_HUB_ENROLMENT_CHANGE_SCHEDULE_GROUP
				: PATHS.PATH_HUB_ENROLMENT_CHANGE_SCHEDULE;
			redirectToCSP({
				enrolmentId: enrolments[0].value,
				callback: () => {
					history.push(redirectPath);
				},
			});
		} else {
			setRedirect(PATHS.PATH_HUB_CR_PERMANENT_SCHEDULE_CHANGE);
			setShowModal(true);
		}
	};

	const requestOptions2 = [
		{
			category: "Tell us how we can help and we'll direct you to the right part of your Cluey Hub",
			options: [
				{
					option: 'Change session schedule or frequency',
					action: () => {
						onPermanentScheduleChangeClick();
					},
					tracking: 'track-hub-contact-request-options-change-schedule',
				},
				{
					option: 'Pause enrolment (skip consecutive sessions)',
					action: () => {
						onClickHandler(PATHS.PATH_HUB_CR_PAUSE_SESSIONS);
					},
				},
				{
					option: 'Reschedule or skip one upcoming session',
					action: () => {
						history.push(PATHS.PATH_HUB_UPCOMING_SESSIONS);
					},
				},
				{
					option: 'Request a tutor change',
					action: () => {
						onClickHandler(PATHS.PATH_HUB_CR_CHANGE_TUTOR);
					},
				},
				{
					option: 'Change learning content and focus',
					action: () => {
						onClickHandler(PATHS.PATH_HUB_CR_CHANGE_CONTENT_FOCUS);
					},
				},
				{
					option: 'Change learning mode',
					action: () => {
						onClickHandler(PATHS.PATH_HUB_CR_SWITCH_LEARNING_MODE);
					},
				},
				{
					option: 'Add a subject or enrol another student',
					action: () => {
						onClickHandler(PATHS.PATH_HUB_CR_ADD_ENROLMENT);
					},
				},
				{
					option: 'Get technical help',
					action: () => {
						onClickHandler(PATHS.PATH_HUB_CR_TECHNICAL_ISSUE);
					},
				},
				{
					option: 'Cancel enrolment',
					action: () => {
						onClickHandler(PATHS.PATH_HUB_CR_CANCEL_ENROLMENT);
					},
				},
				{
					option: 'Update payment information',
					action: () => {
						history.push(PATHS.PATH_HUB_INVOICE_HISTORY);
					},
				},
				{
					option: 'Query a payment',
					action: () => {
						onClickHandler(PATHS.PATH_HUB_CR_PAYMENT_QUERY);
					},
				},
			],
		},
		{
			category: 'Something else?',
			options: [
				{
					option: 'Send us feedback',
					action: () => {
						onClickHandler(PATHS.PATH_HUB_CR_FEEDBACK);
					},
				},
				{
					option: 'Contact us',
					action: () => {
						onClickHandler(PATHS.PATH_HUB_CR_OTHER_QUERY);
					},
				},
			],
		},
	];

	/*
  OLD REQUEST OPTIONS
  const requestOptions = [
    {
      category: 'Change a specific session',
      options: [
        {
          option: 'Reschedule or skip a session',
          action: () => {
            setShowModalGCS(true);
          },
        },
      ],
    },
    {
      category: 'Change schedule',
      options: [
        {
          option: 'Skip consecutive sessions (Pause)',
          action: () => {
            onClickHandler(PATHS.PATH_HUB_CR_PAUSE_SESSIONS);
          },
        },
        {
          option: 'Permanent schedule change',
          action: () => {
            onPermanentScheduleChangeClick();
          },
          tracking: 'track-hub-contact-request-options-change-schedule',
        },
      ],
    },
    {
      category: 'Change an enrolment',
      options: [
        {
          option: 'Change tutor',
          action: () => {
            onClickHandler(PATHS.PATH_HUB_CR_CHANGE_TUTOR);
          },
        },
        {
          option: 'Change content focus',
          action: () => {
            onClickHandler(PATHS.PATH_HUB_CR_CHANGE_CONTENT_FOCUS);
          },
        },
        {
          option: 'Change learning mode',
          action: () => {
            onClickHandler(PATHS.PATH_HUB_CR_SWITCH_LEARNING_MODE);
          },
        },
        {
          option: 'Cancel enrolment',
          action: () => {
            onClickHandler(PATHS.PATH_HUB_CR_CANCEL_ENROLMENT);
          },
        },
        {
          option: 'Other',
          action: () => {
            onClickHandler(PATHS.PATH_HUB_CR_OTHER_ENROLMENT_CHANGE);
          },
        },
      ],
    },
    {
      category: 'Payment enquiry',
      options: [
        {
          option: 'Update payment information',
          action: () => {
            setShowModalUPI(true);
          },
        },
        {
          option: 'Query a payment',
          action: () => {
            onClickHandler(PATHS.PATH_HUB_CR_PAYMENT_QUERY);
          },
        },
      ],
    },
    {
      category: 'Other',
      options: [
        {
          option: 'Other type of enquiry not listed',
          action: () => {
            onClickHandler(PATHS.PATH_HUB_CR_OTHER_QUERY);
          },
        },
      ],
    },
  ];
  */

	return (
		<ContactFormLayout page="request-options" pageTitle="How can we help you?" customerCareImpact={false}>
			<div className="px-5 pt-5 lg:px-20 lg:pt-12">
				{requestOptions2.length > 0 &&
					requestOptions2.map((requestOption) => {
						const { category, options } = requestOption;
						const categoryId = `category--${category.replace(' ', '-')}`;
						return (
							<section className="mb-4" key={categoryId}>
								<h3 className="m-0 p-5 text-lg font-medium leading-none">{category}</h3>
								{options.length > 0 &&
									options.map((op) => {
										const { option, action, tracking = null } = op;
										const optionId = `option--${option.replace(' ', '-')}`;

										return (
											<button
												key={optionId}
												className={`mb-3 w-full rounded border-0 bg-slate-1 p-4 text-left font-normal tracking-normal text-primary transition-colors hover:bg-slate-2 ${tracking}`}
												onClick={action}
											>
												{option}
											</button>
										);
									})}
							</section>
						);
					})}
				<div className="mb-4 tracking-normal lg:mb-8" />
				{showModalGCS && <GeneralChangeSessionModal isModalOpen={showModalGCS} toggleModal={toggleModalGCS} />}
				{showModalUPI && <UpdatePaymentInformationModal isModalOpen={showModalUPI} toggleModal={toggleModalUPI} />}

				<SelectionModal
					showModal={showModal}
					toggleModal={toggleModal}
					redirect={redirect}
					selectedRadio={selectedRadio}
					setSelectedRadio={setSelectedRadio}
					radios={enrolments}
					title={ENROLMENT_SELECTION_MODAL_TITLE}
					showBackBtn
					cyClassName="cy-enrolment-selection"
				/>
			</div>
		</ContactFormLayout>
	);
};

HubGeneralContactOptions.propTypes = {
	enrolments: SelectionModalOptionsType,
	saveSelectedEnrolmentId: func.isRequired,
	redirectToCSP: func.isRequired,
};

HubGeneralContactOptions.defaultProps = {
	enrolments: SelectionModalOptionsDefault,
};

export default connect(
	(state) => {
		return {
			enrolments: getEnrolmentsAsTextValuePair(state),
		};
	},
	(dispatch) => {
		return {
			saveSelectedEnrolmentId: bindActionCreators(saveSelectedEnrolment, dispatch),
			redirectToCSP: bindActionCreators(redirectToChangeSchedule, dispatch),
		};
	}
)(withHeadTag(HubGeneralContactOptions));
