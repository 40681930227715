import { Enrolment, PackageType } from '../../../../../api/types/enrolment';
import { ENROLMENT_STATUS } from '../../../../../types/hubEnums';

export const hasTrial = (enrolment: Enrolment) => {
	return enrolment.packageType === PackageType.Values['Test Prep'];
};

export const hasCancelRequested = (enrolment: Enrolment) => {
	return enrolment.enrolmentStatus === ENROLMENT_STATUS.CANCEL_REQUESTED;
};
