import moment from 'moment';
import starIcon from '../../assets/images/icon-filled-star-orange.svg';

export function getBannerTitle(yearLevel: string, subject: string, scorePercentage): string {
	if (scorePercentage >= 80) {
		return `Well done on the ${yearLevel} ${subject} assessment results`;
	}
	return `Congratulations on completing the ${yearLevel} ${subject} assessment`;
}

export interface CompletionBannerProps {
	studentName: string;
	subject: string;
	yearLevel: string;
	completedAt: string;
	questionsCorrect: number | null;
	questionsTotal: number | null;
}

const CompletionBanner = ({
	studentName,
	subject,
	yearLevel,
	completedAt,
	questionsCorrect,
	questionsTotal,
}: CompletionBannerProps) => {
	const score = Math.round((questionsCorrect / questionsTotal) * 100);
	const formattedCompletedAt = moment(completedAt).format('ddd DD MMM YYYY');
	const title = getBannerTitle(yearLevel, subject, score);

	return (
		<div className="rounded-lg bg-slate-1 p-6">
			<div className="flex flex-row">
				<img src={starIcon} className="mr-2 h-6" alt="bullet point icon" />
				<b className="font-display text-xl">{title}</b>
			</div>
			<div className="ml-8 text-lg">{`${studentName} scored ${score}% and answered ${questionsCorrect} out of ${questionsTotal} questions correctly`}</div>
			<div className="ml-8 mt-2">{`Completed on ${formattedCompletedAt}`}</div>
		</div>
	);
};

export default CompletionBanner;
