import { useState, useEffect } from 'react';
import { isNull } from 'lodash';
import { VirtualCoachBotIcon } from '../../../icons';
import { ConversationLayout } from './ConversationLayout';
import { OptionButton } from './OptionButton';
import useAiTutorContext from '../useAiTutorContext';
import { type Topic } from './topics/index';
import getTopics from './getTopics';

export interface SelectTopicProps {
  setTopicCallback(topic: Topic): void;
}

export const SelectTopic = ({ setTopicCallback }: SelectTopicProps) => {
  const { enrolment, topic, setTopic } = useAiTutorContext();
  const [selectedTopic, setSelectedTopic] = useState<Topic>(topic);
  const [selected, setSelected] = useState(false);

  const topics = getTopics(enrolment.curriculum, enrolment.subject);

  useEffect(() => {
    if (isNull(selectedTopic)) {
      setSelectedTopic(topics[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConversationLayout
      bottomActionClick={() => {
        const newTopic = selectedTopic || topics[0];
        setTopic(newTopic);
        setTopicCallback(newTopic);
      }}
    >
      <div className="mb-6">
        <p className="flex flex-row items-center justify-center gap-x-5 px-0 py-6 text-center font-bold md:px-10 md:py-10">
          <span className="ml-3 text-left text-xl md:text-[28px]">
            Hi {enrolment.student}, how can I help you today?
          </span>
          <VirtualCoachBotIcon width={100} height={120} />
        </p>
      </div>
      <div className="flex flex-col gap-y-3">
        <p className="text-lg font-bold">Which topic in {enrolment.subject}?</p>
        <div>
          <div className="relative grid grid-flow-row auto-rows-auto gap-3 md:grid-cols-2">
            {topics.map((subjectTopic) => (
              <OptionButton
                label={subjectTopic.label}
                value={subjectTopic.topicKey}
                key={subjectTopic.topicName}
                isSelected={selectedTopic === subjectTopic}
                onClick={() => {
                  setSelected(true);
                  setSelectedTopic(subjectTopic);
                }}
              />
            ))}
          </div>
          <OptionButton
            label="I'm not sure"
            value="im_not_sure"
            onClick={() => {
              setSelected(true);
              setSelectedTopic(null);
            }}
            isSelected={isNull(selectedTopic) && selected}
            className="mt-3"
          />
        </div>
      </div>
    </ConversationLayout>
  );
};
