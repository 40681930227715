import React, { Fragment, useState } from 'react';
import { Form, Button, ButtonAsLink } from '@cluey/cluey-components';
import isEmail from 'validator/lib/isEmail';

import withHeadTag from '../../../common/HeadComponent';
import AuthPageLayout from '../../../common/hub/AuthPageLayout';
import InPageLoader from '../../../common/InPageLoader';
import EmailField from '../../../components/hub/auth/EmailField';
import { EMAIL_VALIDATION_RESULT } from '../../../util/constants';
import paperplane from '../../../assets/images/hubAuth/paperplane.svg';
import mailSent from '../../../assets/images/hubAuth/mail-sent.svg';
import ErrorBlock from '../../../common/ErrorBlock';
import { BackToLogin } from './HubResetPassword';
import { useActions } from '../../../util/hooks/useActions';
import { submitContactCase } from '../../../actions/contactUsFlowActions';

const HubActivateAccountPage = () => {
  const [email, setEmail] = useState('');
  const [emailValidation, setEmailValidation] = useState(EMAIL_VALIDATION_RESULT.INIT);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const submit = useActions(submitContactCase);

  const sendRequest = () => {
    if (isEmail(email)) {
      setSubmitting(true);
      setEmailValidation(EMAIL_VALIDATION_RESULT.INIT);
      submit({
        action: 'Activate Hub',
        email,
        onSubmitSucceed: () => {
          setSubmitting(false);
          setSubmitSuccess(true);
        },
        onSubmitFailed: (errorMessage: string) => {
          setSubmitting(false);
          setSubmitError(errorMessage);
        },
      })
    } else {
      setEmailValidation(EMAIL_VALIDATION_RESULT.INVALID);
    }
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    sendRequest();
  };

  if (submitSuccess) {
    return (
      <AuthPageLayout
        title="Thanks for your request"
        subtitle="If your email address is associated with a Cluey account, you'll receive an email with a link to activate your Hub account."
        imgIcon={mailSent}
        imgIconAlt="mail sent"
        imgIconWrapClass="icon-wrap--sail"
      >
        {submitting && <InPageLoader position="relative" /> }
        {submitError && (
          <ErrorBlock
            errorMsg={submitError}
          />
        )}
        {!submitting && (
          <ButtonAsLink
            className="block p-0 mt-10 text-primary font-body ls-normal"
            onClick={sendRequest}
          >
            Didn’t receive the link? Re-send
          </ButtonAsLink>
        )}
      </AuthPageLayout>
    );
  }

  return (
    <AuthPageLayout
      title="Activate your account"
      subtitle="Enter the email address associated with your Cluey account and we’ll send you instructions on how to activate it."
      imgIcon={paperplane}
      imgIconAlt="paper plane"
      imgIconWrapClass="icon-wrap--sail"
    >
      {submitting && <InPageLoader position="relative" /> }
      {submitError && (
        <ErrorBlock
          errorMsg="submitError"
        />
      )}
      {!submitting && (
        <Fragment>
          <Form onSubmit={onFormSubmit}>
            <EmailField
              emailValidation={emailValidation}
              saveEmail={setEmail}
            />
            <Button
              type="submit"
              size="xs"
              className="w-full uppercase"
            >
              Submit
            </Button>
          </Form>
          {BackToLogin}
        </Fragment>
      )}
    </AuthPageLayout>
  );
};

export default withHeadTag(HubActivateAccountPage);

export { HubActivateAccountPage as StatelessHubActivateAccountPage };
