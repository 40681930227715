import initialState from './initialState';
import * as actionTypes from '../actions/actionTypes';

export default function schoolsUpcomingSessions(state = initialState.schoolsUpcomingSessions, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SCHOOLS_US_FETCH_HOUSEHOLD_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
