import moment, { Moment } from 'moment';
import { PAUSE_ALLOWED } from '../../types/hubEnums';
import type { SessionPolicy } from '../../types/hubInterfaces';

export const getManagePausePolicyAlertData = ({
  enrolmentPolicy,
  resumeDateOptions,
  maxPauseWeeks,
}: {
  enrolmentPolicy: SessionPolicy;
  resumeDateOptions: Array<string>;
  maxPauseWeeks: number;
}): {
  title: string;
  body?: string;
} => {
  const title = enrolmentPolicy?.pause?.pauseAllowed === 'Anytime'
    ? `Your current plan allows you to pause for up to ${maxPauseWeeks} weeks at a time`
    : 'Your current plan allows you to pause within the school holidays';
  const body = resumeDateOptions.length === 0 ? 'No available dates to change within your pause' : null;
  return {
    title,
    body
  };
};


/**
* Recursively finds all dates within a range that have the same day of the week.
* Pass it the earliest date of resumption, and the maximum date for the range.
* It will return an array of dates within the range with the same day of the week.
*
* @param {Object} params - The parameters for finding the dates.
* @param {Date} params.earliestDate - The earliest date in the range.
* @param {Date} params.maxDate - The maximum date for the range.
* @param {Array<Date>} [params.dates=[]] - An array of already found dates (optional).
* @returns {Array<Date>} - An array of dates within the range with the same day of the week.
*/
export function getResumeDates({ earliestDate, maxDate, dates = [], resumeDate }) {
  // If the test date is within the range, we test add it to the dates and test the next week
  if (earliestDate.isSameOrBefore(maxDate, 'day')) {
    // Recursively call the function with the updated parameters
    /*
      Resume date is from Sales Force which is always in Syndey timezone;
      Notwithstanding account time zone

      Explicitly casting the time zone to Australia/Sydney is essential
      so moment won't set it automatically to the machine/local time zone
    */
    return getResumeDates({
      earliestDate: moment(earliestDate).tz('Australia/Sydney').add(1, 'weeks').set('hour', resumeDate.hour()).set('minutes', resumeDate.minutes()).set('seconds', 0).set('millisecond', 0),
      maxDate,
      dates: [...dates, moment(earliestDate).tz('Australia/Sydney').set('hour', resumeDate.hour()).set('minutes', resumeDate.minutes()).set('seconds', 0).set('millisecond', 0)],
      resumeDate
    });
  }
  // Return the found dates
  return dates;
}

export const getKeepExistingScheduleResumeOptions = ({
  resumeFrom,
  timezone,
  enrolmentPausedFrom,
  maxPauseWeeks,
  policy,
  nextTermStartDate,
  isSummer = false,
  summerDates
}: {
  resumeFrom: string;
  resumeFromOnlyDay: string;
  sessionFrequency: string;
  timezone: string;
  enrolmentPausedFrom?: string;
  maxPauseWeeks: number;
  policy: SessionPolicy;
  nextTermStartDate: string;
  isSummer?: boolean;
  summerDates: {
    summerStart: Moment;
    summerEnd: Moment;
  }
}): Array<string> => {
  if (!resumeFrom) {
      return [];
  }

  // resumeDate is the date of the next session, provided by SF
  const resumeDate = moment(resumeFrom);
  const sessionWeekDay = resumeDate.weekday();

  // Get the next weekly session, based on the day of the week.
  // The minimum possible is 2 days from today
  const minimumResumptionDate = moment.max([moment().add(2, 'days'), moment(enrolmentPausedFrom)]);
  // The limit is calculated by getting this weeks session, and adding 1 week if the session is before is prior to or within the next 2 days
  // ie. today is monday, the session is on wednesdays (within 2 days), so the limit is next wednesday.
  // ie. today is monday, the session is on fridays, so the limit is friday (good to go)
  // ie. today is thursday, the session is on fridays, so the limit is next friday (within 2 days)
  // ie. today is thursday, the session is on mondays, so the limit is next monday (next week)
  const earlyResumptionLimit = moment(minimumResumptionDate).day(sessionWeekDay).add(moment(enrolmentPausedFrom).day(sessionWeekDay).isSameOrBefore(minimumResumptionDate, 'day') ? 1 : 0, 'weeks');
  const nextTermStartMoment = moment(nextTermStartDate).tz(timezone).subtract(1, 'day');

  /* 
    The maximum date is either:
    PAUSE ANYTIME - the later of either the school term start date, or 4 weeks from the pause date
    They cannot manage an upcoming pause, they can only manage the current pause
    PAUSE WITHIN HOLIDAYS - the next term start date
  */
  const futureResumptionLimit: Moment = (function() {
    if (isSummer) {
      const { summerEnd } = summerDates;
      return policy?.pause?.pauseAllowed === PAUSE_ALLOWED.ANYTIME
        ? summerEnd.add(maxPauseWeeks, 'weeks')
        : summerEnd.add(1, 'week')
    } else {
      return policy?.pause?.pauseAllowed === PAUSE_ALLOWED.ANYTIME
        ? moment(enrolmentPausedFrom).add(maxPauseWeeks, 'weeks')
        : nextTermStartMoment.add(1, 'week');
    }
  })();

  const resumeDates = getResumeDates({
    earliestDate: earlyResumptionLimit.clone(),
    maxDate: futureResumptionLimit,
    dates: [],
    resumeDate
  });

  if (!resumeDates.find(d => d.isSame(resumeDate, 'day'))) {
    resumeDates.push(moment(resumeDate).tz('Australia/Sydney', true));
  }
  return resumeDates.map((date) => date.tz(timezone).utc().toISOString()).sort();
};
