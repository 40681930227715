import React from 'react';
import PropTypes from 'prop-types';
import { AnchorButton } from '@cluey/cluey-components';
import { PATH_HUB_BASE } from '../util/pagePath';
import { CUSTOMER_CARE_CONTACT_PHONE, CUSTOMER_CARE_CONTACT_PHONE_2 } from '../util/constants';

export const Maintenance = ({ showJoinHub, showBottomSeparator }) => {
  const hubLink = showJoinHub ? (
    <>
      <h2 className="mb-3 text-xl font-bold leading-tight">Upcoming sessions?</h2>
      <AnchorButton size="xs" appearance="reverse" className="mb-10 font-bold uppercase" href={PATH_HUB_BASE}>
        Join via cluey hub
      </AnchorButton>
    </>
  ) : (
    <p className="mb-1 text-base leading-snug">To join upcoming sessions, use link below.</p>
  );
  return (
    <div className="flex flex-col items-center text-center">
      <h1 className="mb-6 font-display text-2xl font-bold leading-tight">
        Our system is currently undergoing maintenance
      </h1>
      <p className="mb-6 text-xl leading-tight">We&apos;ll be back online Tuesday 13 December, 10am</p>
      {hubLink}
      <p className="text-base leading-snug">
        For urgent matters, contact us on{' '}
        <a href={`tel:${CUSTOMER_CARE_CONTACT_PHONE}`} className="text-primary hover:underline">
          {CUSTOMER_CARE_CONTACT_PHONE_2}
        </a>
        .
      </p>
      {showBottomSeparator && <div className="my-10 w-full border-b border-grey-2" />}
    </div>
  );
};

Maintenance.propTypes = {
  showJoinHub: PropTypes.bool,
  showBottomSeparator: PropTypes.bool,
};

Maintenance.defaultProps = {
  showJoinHub: false,
  showBottomSeparator: false,
};
