import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SectionTitle from './SectionTitle';

import { getUnitCompletion, getPracticeProgress } from '../../selectors/hubSelector';
import { STUDENT_PROGRESS_URL_PARAM_TAB, STUDENT_PROGRESS_TAB_VIEWS } from '../../util/constants';
import { PATH_NAME_REPORT } from '../../util/pagePath';
import iconReport from '../../assets/images/icon-filled-report.svg';
import iconStar from '../../assets/images/progressStars/star-100.svg';
import iconPencil from '../../assets/images/icon-filled-pencil--orange.svg';
import { Anchor } from '@cluey/cluey-components';

const LearningProgress = ({ reportToken, unitsCompletion, practiceProgress, isProgressLoaded }) => {
	return (
		<div className="mb-8 lg:mb-11">
			<SectionTitle title="Learning progress" />
			<div className="flex flex-col rounded-lg border border-grey-2 bg-white lg:flex-row">
				<div className="flex-1 border-b border-grey-2 px-4 py-7 lg:mb-0 lg:border-b-0 lg:border-r lg:px-10 lg:py-7">
					<ColumnTitle title="Session reports" />
					<div className="flex items-start">
						<ColumnIcon icon={iconReport} />
						<div className="text-left">
							<p className="text-lg font-bold leading-[1.33] lg:mb-4">Individual session feedback</p>
							{reportToken && (
								<ColumnCTA
									to={`${PATH_NAME_REPORT}?token=${reportToken}&${STUDENT_PROGRESS_URL_PARAM_TAB}=${STUDENT_PROGRESS_TAB_VIEWS.SESSION_REPORT}`}
									label="View reports"
								/>
							)}
						</div>
					</div>
				</div>
				{isProgressLoaded && (
					<Fragment>
						<div className="flex-1 border-b border-grey-2 px-4 py-7 lg:border-b-0 lg:border-r lg:px-10 lg:py-7">
							<ColumnTitle title="Coverage summary" />
							<div className="flex items-start">
								<ColumnIcon icon={iconStar} />
								<div className="text-left">
									<p className="mb-2 text-lg font-bold leading-[1.33]">Units completed</p>
									<p className="mb-2 leading-[1.33]">{unitsCompletion}</p>
									{reportToken && (
										<ColumnCTA
											to={`${PATH_NAME_REPORT}?token=${reportToken}&${STUDENT_PROGRESS_URL_PARAM_TAB}=${STUDENT_PROGRESS_TAB_VIEWS.PROGRESS_OVERVIEW}`}
											label="View topics and progress"
										/>
									)}
								</div>
							</div>
						</div>
						<div className="flex-1 px-4 py-7 lg:px-10 lg:py-7">
							<ColumnTitle title="Practice progress" />
							<div className="flex items-start">
								<ColumnIcon icon={iconPencil} />
								<div className="text-left">
									<p className="font-weight-bold mb-2 text-lg leading-[1.33]">Practice questions completed</p>
									<p className="mb-2 leading-[1.33]">{practiceProgress}</p>
								</div>
							</div>
						</div>
					</Fragment>
				)}
			</div>
		</div>
	);
};

LearningProgress.propTypes = {
	reportToken: PropTypes.string,
	unitsCompletion: PropTypes.string,
	practiceProgress: PropTypes.string,
	isProgressLoaded: PropTypes.bool.isRequired,
};

LearningProgress.defaultProps = {
	reportToken: '',
	unitsCompletion: '',
	practiceProgress: '',
};

export default connect((state) => {
	return {
		isProgressLoaded: !state.ui.apiState.hubEnrolmentDetailPage.progressSummary.fetchError,
		unitsCompletion: getUnitCompletion(state),
		practiceProgress: getPracticeProgress(state),
	};
}, null)(LearningProgress);

const ColumnTitle = ({ title }) => {
	return <h3 className="pb-3 text-xs font-bold uppercase tracking-wide">{title}</h3>;
};

ColumnTitle.propTypes = {
	title: PropTypes.string.isRequired,
};

const ColumnIcon = ({ icon }) => {
	return <img src={icon} alt="icon" className="mr-lg-6 mr-5" width={24} height={24} />;
};

ColumnIcon.propTypes = {
	icon: PropTypes.node.isRequired,
};

const ColumnCTA = ({ to, label }) => {
	return (
		<Anchor href={to} target="_blank">
			{label}
		</Anchor>
	);
};

ColumnCTA.propTypes = {
	to: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
};
