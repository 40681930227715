import moment from 'moment';
import { BusinessHours, WEEKDAYS, Weekday, type WorkDay, type WorkHours } from './constants';
import { groupBy } from 'lodash';

const differentLastSeparator = ({ arr, firstSep = ', ', lastSep = ' and ' }) => {
  if (arr.length === 1) return arr[0];
  if (arr.length === 2) return arr.join(lastSep);
  const firstPart = arr.slice(0, -1).join(firstSep);
  const lastPart = arr.slice(-1);
  return [firstPart, lastPart].join(lastSep);
};

const removeMinutes = (momentDate) => {
  var momentTime = momentDate.format('h:mma'); // Time formatted as 10:00 AM
  return momentTime.replace(/(:00)/g, '');
};

const getFirstActiveDay = (workHours: WorkDay[]) => {
  const firstActiveDay = workHours.find((day) => day.open);
  return firstActiveDay;
};

const getLastActiveDay = (workHours: WorkDay[]) => {
  const lastActiveDay = [...workHours].reverse().find((day) => day.open);
  return lastActiveDay;
};

const convertToAccountTimezone = (hours: WorkHours, timezone = 'Australia/Sydney') => {
  const time = hours.utc.time;
  const timeMoment = moment.utc(time, 'HH:mm').tz(timezone);
  return timeMoment;
};

const getOpeningHours = (workHours: WorkDay[], timezone = 'Australia/Sydney') => {
  const firstActiveDay = getFirstActiveDay(workHours);
  if (!firstActiveDay) {
    return [];
  }
  const localStart = convertToAccountTimezone(firstActiveDay?.start, timezone);
  const localFinish = convertToAccountTimezone(firstActiveDay?.finish, timezone);
  return [removeMinutes(localStart), removeMinutes(localFinish)];
};

const getGroups = (hours: BusinessHours) => {
  const workHours = hours.workHours;
  const groupedDays: { [key: string]: WorkDay[] } = groupBy(
    workHours,
    (day) => `"${day.start.time}-${day.finish.time}"`
  );
  const filteredDays = Object.entries(groupedDays).map(([_, val]) => {
    const onlyOpenDays = val.filter((day) => day.open);
    return onlyOpenDays;
  });
  const sortedDays = filteredDays.sort((a) => {
    return Number(a.find((day) => day.day === 'Monday'));
  });
  return sortedDays;
};

const stringifyGroup = (group: WorkDay[], timezone = 'Australia/Sydney') => {
  if (!group.some((day) => day.open)) return;
  const [openTime, closeTime] = getOpeningHours(group, timezone);
  const isConsecutive = group.every((day) => day.open) && WEEKDAYS.join().includes(group.map((d) => d.day).join());
  if (isConsecutive) {
    const firstDay = getFirstActiveDay(group).day;
    const lastDay = getLastActiveDay(group).day;
    return `${differentLastSeparator({ arr: group.length === 1 ? [firstDay] : [firstDay, lastDay], lastSep: group.length > 2 ? ' to ' : ' and ' })}${group.length > 1 ? ',' : ' -'} ${openTime} to ${closeTime}`;
  }
  const dayMap = group.map((day) => day.day);
  return `${differentLastSeparator({ arr: dayMap, lastSep: group.length === 2 ? ' to ' : ' and ' })}, ${openTime} to ${closeTime}`;
};

const stringifyGroups = (groups: WorkDay[][], timezone = 'Australia/Sydney') => {
  const stringGroups = groups
    .map((group) => {
      return stringifyGroup(group, timezone);
    })
    .filter((group) => group);
  return differentLastSeparator({ arr: stringGroups });
};

export const getChatSupportHours = (): BusinessHours => {
  /* 
    Cluey headquarters is based in New South Wales.
    Business hours are derived therefrom thus.
    Look alive. DST must be supported, hence
    the hours must be properly converted to the correct utc hours.
   */
  const opening = moment.tz('Australia/Sydney').hour(9).minute(0);
  const closing = moment.tz('Australia/Sydney').hour(18).minute(0);
  const timeFormat = 'HH:mm';

  const isWeekend = (day: Weekday) => day === 'Saturday' || day === 'Sunday';
  const hours = {
    name: 'Customer Hub support',
    workHours: WEEKDAYS.map((day) => ({
      day,
      start: {
        time: '9:00',
        utc: {
          time: opening.utc().format(timeFormat),
        },
      },
      finish: {
        time: '18:00',
        utc: {
          time: closing.utc().format(timeFormat),
        },
      },
      open: isWeekend(day) ? false : true,
    })),
    active: true,
    type: 'custom',
    timezone: {
      name: 'Australia/Sydney',
      utc: '+10:00',
    },
  };

  return hours;
};

/**
 * Converts a BusinessHours object into a human-readable string format.
 * It will return a string like "Monday to Friday, 9am to 5pm" if the work hours match on consecutive days.
 * if the work hours are the same, but the days are not consecutive it will return a string like "Monday, Wednesday and Friday, 9am to 5pm".
 * If the work hours are different, it will return a string like "Monday to Friday, 9am to 5pm, Saturday, 10am to 2pm".
 * If some days have matching work hours but there are outliers it will return a string like "Monday, Tuesday and Thursday, 9am to 5pm, Wednesday, 9am to 1pm, Friday, 9am to 3pm"
 * If the work hours are not set, it will return "UNAVAILABLE".
 *
 * @param {BusinessHours} hours - The BusinessHours object containing work hours data.
 * @param {string} timezone - The timezone to use when converting the work hours to account time.
 * @returns {string} A formatted string representation of the work hours.
 */
export const stringifyBusinessHours = (hours: BusinessHours = getChatSupportHours(), timezone = 'Australia/Sydney') => {
  const groups = getGroups(hours);
  if (!groups.length) return 'UNAVAILABLE';
  return stringifyGroups(groups, timezone);
};
