import { Auth2Layout } from "../../../common";
import { LinkButton } from '@cluey/cluey-components';
import { PATH_HUB_LOG_IN } from "../../../../../../util/pagePath";

function ResetPasswordSuccess() {
  return (
    <Auth2Layout title='Your password has been reset'>
      <p className="text-grey-6">
        Log in to your Cluey Hub account to view and manage your sessions, enrolments and account settings.
      </p>

      <LinkButton
        to={PATH_HUB_LOG_IN}
        className="mt-10 uppercase w-48"
        size="lg"
      >
        log in
      </LinkButton>
    </Auth2Layout>
  )
}

export default ResetPasswordSuccess;