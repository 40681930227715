// SR stands for SESSION REPORT
// action starting with SR are intended to be used in the session report page
// summary
export const SR_REQUEST_SUMMARY_SUCCESS = 'SR_REQUEST_SUMMARY_SUCCESS';
export const SR_REQUEST_SUMMARY_FAILED = 'SR_REQUEST_SUMMARY_FAILED';
export const SR_LOGIN_SET_URL_TOKEN = 'SR_LOGIN_SET_URL_TOKEN';
export const SR_LOGIN_RECEIVE_INFO = 'SR_LOGIN_RECEIVE_INFO';
export const SR_LOGIN_API_FAILURE = 'SR_LOGIN_API_FAILURE';
export const SR_LOGIN_API_FAILURE_DEFINITIVE = 'SR_LOGIN_API_FAILURE_DEFINITIVE';
export const SR_LOGIN_RESET = 'SR_LOGIN_RESET';
export const SR_REQUEST_ACTIVITIES_SUCCESS = 'SR_REQUEST_ACTIVITIES_SUCCESS';
export const SR_REQUEST_ACTIVITIES_FAILED = 'SR_REQUEST_ACTIVITIES_FAILED';
export const SR_REQUEST_ACTIVITIES_START = 'SR_REQUEST_ACTIVITIES_START';
export const SR_REMOVE_ALL_ACTIVITIES = 'SR_REMOVE_ALL_ACTIVITIES';
export const SR_REQUEST_SESSION_RECORDING_START = 'SR_REQUEST_SESSION_RECORDING_START';
export const SR_REQUEST_SESSION_RECORDING_SUCCESS = 'SR_REQUEST_SESSION_RECORDING_SUCCESS';
export const SR_REQUEST_SESSION_RECORDING_FAILED = 'SR_REQUEST_SESSION_RECORDING_FAILED';

// STPG stands for STUDENT PROGRESS
// action starting with STPG are intended to be used in the student progress page
export const STPG_FETCH_STUDENT_PROGRESS_DATA_START = 'STPG_FETCH_STUDENT_PROGRESS_DATA_START';
export const STPG_FETCH_STUDENT_PROGRESS_DATA_SUCCESS = 'STPG_FETCH_STUDENT_PROGRESS_DATA_SUCCESS';
export const STPG_FETCH_DISCIPLINE_LIST_SUCCESS = 'STPG_FETCH_DISCIPLINE_LIST_SUCCESS';
export const STPG_FETCH_DISCIPLINE_LIST_FAILED = 'STPG_FETCH_DISCIPLINE_LIST_FAILED';
export const STPG_UPDATE_SELECTED_DISCIPLINE = 'STPG_UPDATE_SELECTED_DISCIPLINE';
export const STPG_UPDATE_TABBED_VIEW_ACTIVE_TAB = 'STPG_UPDATE_TABBED_VIEW_ACTIVE_TAB';
export const STPG_TOPIC_MODAL_CLOSED = 'STPG_TOPIC_MODAL_CLOSED';
export const STPG_FETCH_STUDENT_PROGRESS_DATA_FAILED = 'STPG_FETCH_STUDENT_PROGRESS_DATA_FAILED';
export const STPG_SAVE_SELECTED_MODULE_ID = 'STPG_SAVE_SELECTED_MODULE_ID';
export const STPG_FETCH_SELECTED_MODULE_DETAIL_START = 'STPG_FETCH_SELECTED_MODULE_DETAIL_START';
export const STPG_FETCH_SELECTED_MODULE_DETAIL_SUCCESS = 'STPG_FETCH_SELECTED_MODULE_DETAIL_SUCCESS';
export const STPG_FETCH_SELECTED_MODULE_DETAIL_FAILED = 'STPG_FETCH_SELECTED_MODULE_DETAIL_FAILED';
export const STPG_FETCH_OUTCOME_NEXT_YEAR_SUCCESS = 'STPG_FETCH_OUTCOME_NEXT_YEAR_SUCCESS';
export const STPG_FETCH_OUTCOME_NEXT_YEAR_FAILED = 'STPG_FETCH_OUTCOME_NEXT_YEAR_FAILED';

// APP
// actions related to APP
export const APP_UPDATE_VIEW = 'APP_UPDATE_VIEW';

// US stands for UPCOMING SESSIONS
// action starting with US are intended to be used in the upcoming sessions page

export const US_FETCH_SESSIONS_START = 'US_FETCH_SESSIONS_START';
export const US_FETCH_SESSIONS_SUCCESS = 'US_FETCH_SESSIONS_SUCCESS';
export const US_FETCH_SESSIONS_FAILED = 'US_FETCH_SESSIONS_FAILED';

export const US_UPDATE_STUDENTS_FILTERS = 'US_UPDATE_STUDENTS_FILTERS';
export const US_GET_UPCOMING_SESSIONS_TOKEN = 'US_GET_UPCOMING_SESSIONS_TOKEN';
export const US_UPDATE_UPCOMING_SESSIONS_TOKEN = 'US_UPDATE_UPCOMING_SESSIONS_TOKEN';

export const US_SHOW_UPCOMING_SESSIONS = 'US_SHOW_UPCOMING_SESSIONS';
export const US_HIDE_UPCOMING_SESSIONS = 'US_HIDE_UPCOMING_SESSIONS';

// CF stands for Contact Us Flow
// action starting with CF are intended to be used in contact us flow
export const CF_UPDATE_CONTACTUS_FLOW_TYPE = 'CF_UPDATE_CONTACTUS_FLOW_TYPE';
export const CF_UPDATE_CONTACTUS_FORM = 'CF_UPDATE_CONTACTUS_FORM';
export const CF_SUBMIT_CONTACTUS_FORM_START = 'CF_SUBMIT_CONTACTUS_FORM_START';
export const CF_SUBMIT_CONTACTUS_FORM_SUCCESS = 'CF_SUBMIT_CONTACTUS_FORM_SUCCESS';
export const CF_SUBMIT_CONTACTUS_FORM_FAILED = 'CF_SUBMIT_CONTACTUS_FORM_FAILED';
export const CF_RESET_CONTACTUS_STATE = 'CF_RESET_CONTACTUS_STATE';
export const CF_SAVE_ENROLMENT_ID_FOR_SAF = 'CF_SAVE_ENROLMENT_ID_FOR_SAF';
export const CF_SET_SESSION_FOR_FORM = 'CF_SET_SESSION_FOR_FORM';
export const CF_TRIGGER_PAYMENT_START = 'CF_TRIGGER_PAYMENT_START';
export const CF_TRIGGER_PAYMENT_SUCCESS = 'CF_TRIGGER_PAYMENT_SUCCESS';
export const CF_TRIGGER_PAYMENT_FAILED = 'CF_TRIGGER_PAYMENT_FAILED';
export const CF_SAVE_SELECTED_SESSIONS_FOR_HUB = 'CF_SAVE_SELECTED_SESSIONS_FOR_HUB';

// RS stands for reschedule session
// actions starting with RS are intended to be used on 'reschedule session' page
export const RS_FETCH_SESSION_START = 'RS_FETCH_SESSION_START';
export const RS_FETCH_SESSION_SUCCESS = 'RS_FETCH_SESSION_SUCCESS';
export const RS_FETCH_SESSION_FAILED = 'RS_FETCH_SESSION_FAILED';
export const RS_RESCHEDULE_SESSION_EXCEPTION = 'RS_RESCHEDULE_SESSION_EXCEPTION';
export const RS_SAVE_RESCHEDULE_SESSION_PAGE_TOKEN = 'RS_SAVE_RESCHEDULE_SESSION_PAGE_TOKEN';
export const RS_SAVE_FETCHED_SESSION = 'RS_SAVE_FETCHED_SESSION';
export const RS_SELECT_SESSION_TO_CHANGE = 'RS_SELECT_SESSION_TO_CHANGE';
export const RS_POST_SESSION_TO_CHANGE_START = 'RS_POST_SESSION_TO_CHANGE_START';
export const RS_POST_SESSION_TO_CHANGE_SUCCESS = 'RS_POST_SESSION_TO_CHANGE_SUCCESS';
export const RS_POST_SESSION_TO_CHANGE_FAILED = 'RS_POST_SESSION_TO_CHANGE_FAILED';
export const RS_GET_REFUND_POLICY_START = 'RS_GET_REFUND_POLICY_START';
export const RS_GET_REFUND_POLICY_SUCCESS = 'RS_GET_REFUND_POLICY_SUCCESS';
export const RS_GET_REFUND_POLICY_FAILED = 'RS_GET_REFUND_POLICY_FAILED';

// actions starting with HUB_SP are intended to be used on 'hub sessions' page
export const HUB_SP_FETCH_UPCOMING_SESSIONS_START = 'HUB_SP_FETCH_UPCOMING_SESSIONS_START';
export const HUB_SP_FETCH_COMPLETED_SESSIONS_START = 'HUB_SP_FETCH_COMPLETED_SESSIONS_START';
export const HUB_SP_FETCH_UPCOMING_SESSIONS_SUCCESS = 'HUB_SP_FETCH_UPCOMING_SESSIONS_SUCCESS';
export const HUB_SP_FETCH_COMPLETED_SESSIONS_SUCCESS = 'HUB_SP_FETCH_COMPLETED_SESSIONS_SUCCESS';
export const HUB_SP_FETCH_UPCOMING_SESSIONS_FAILED = 'HUB_SP_FETCH_UPCOMING_SESSIONS_FAILED';
export const HUB_SP_FETCH_COMPLETED_SESSIONS_FAILED = 'HUB_SP_FETCH_COMPLETED_SESSIONS_FAILED';
export const HUB_SP_FETCH_UPCOMING_SESSIONS_FAILURE_DEFINITIVE = 'HUB_SP_FETCH_UPCOMING_SESSIONS_FAILURE_DEFINITIVE';
export const HUB_SP_FETCH_COMPLETED_SESSIONS_FAILURE_DEFINITIVE = 'HUB_SP_FETCH_COMPLETED_SESSIONS_FAILURE_DEFINITIVE';
export const HUB_SP_FETCH_SCHOOL_TERMS_PUBLIC_HOLIDAYS_START = 'HUB_SP_FETCH_SCHOOL_TERMS_PUBLIC_HOLIDAYS_START';
export const HUB_SP_FETCH_SCHOOL_TERMS_PUBLIC_HOLIDAYS_SUCCESS = 'HUB_SP_FETCH_SCHOOL_TERMS_PUBLIC_HOLIDAYS_SUCCESS';
export const HUB_SP_FETCH_SCHOOL_TERMS_PUBLIC_HOLIDAYS_FAILED = 'HUB_SP_FETCH_SCHOOL_TERMS_PUBLIC_HOLIDAYS_FAILED';
export const HUB_SP_FETCH_SCHOOL_TERMS_PUBLIC_HOLIDAYS_FAILURE_DEFINITIVE =
  'HUB_SP_FETCH_SCHOOL_TERMS_PUBLIC_HOLIDAYS_FAILURE_DEFINITIVE';
export const HUB_SP_REMOVE_CANCELLED_SESSION = 'HUB_SP_REMOVE_CANCELLED_SESSION';

// actions starting with HUB_EP are intended to be used on 'hub enrolments' page
export const HUB_EP_FETCH_ACTIVE_ENROLMENTS_START = 'HUB_EP_FETCH_ACTIVE_ENROLMENTS_START';
export const HUB_EP_FETCH_ACTIVE_ENROLMENTS_SUCCESS = 'HUB_EP_FETCH_ACTIVE_ENROLMENTS_SUCCESS';
export const HUB_EP_FETCH_ACTIVE_ENROLMENTS_FAILED = 'HUB_EP_FETCH_ACTIVE_ENROLMENTS_FAILED';
export const HUB_EP_FETCH_ACTIVE_ENROLMENTS_FAILURE_DEFINITIVE = 'HUB_EP_FETCH_ACTIVE_ENROLMENTS_FAILURE_DEFINITIVE';
export const HUB_EP_CLEAR_ACTIVE_ENROLMENTS_TO_FORCE_RELOAD = 'HUB_EP_CLEAR_ACTIVE_ENROLMENTS_TO_FORCE_RELOAD';

export const HUB_EP_FETCH_PAST_ENROLMENTS_START = 'HUB_EP_FETCH_PAST_ENROLMENTS_START';
export const HUB_EP_FETCH_PAST_ENROLMENTS_SUCCESS = 'HUB_EP_FETCH_PAST_ENROLMENTS_SUCCESS';
export const HUB_EP_FETCH_PAST_ENROLMENTS_FAILED = 'HUB_EP_FETCH_PAST_ENROLMENTS_FAILED';
export const HUB_EP_FETCH_PAST_ENROLMENTS_FAILURE_DEFINITIVE = 'HUB_EP_FETCH_PAST_ENROLMENTS_FAILURE_DEFINITIVE';

export const HUB_EP_SAVE_ENROLMENT_DETAIL_PAGE_TOKEN = 'HUB_EP_SAVE_ENROLMENT_DETAIL_PAGE_TOKEN';
export const HUB_EP_FETCH_ENROLMENT_DETAIL_START = 'HUB_EP_FETCH_ENROLMENT_DETAIL_START';
export const HUB_EP_FETCH_ENROLMENT_DETAIL_SUCCESS = 'HUB_EP_FETCH_ENROLMENT_DETAIL_SUCCESS';
export const HUB_EP_FETCH_ENROLMENT_DETAIL_FAILED = 'HUB_EP_FETCH_ENROLMENT_DETAIL_FAILED';
export const HUB_EP_RELOAD_ENROLMENT_DETAIL_REQUIRED = 'HUB_EP_RELOAD_ENROLMENT_DETAIL_REQUIRED';
export const HUB_EP_FETCH_COURSE_OVERVIEW_START = 'HUB_EP_FETCH_COURSE_OVERVIEW_START';
export const HUB_EP_FETCH_COURSE_OVERVIEW_SUCCESS = 'HUB_EP_FETCH_COURSE_OVERVIEW_SUCCESS';
export const HUB_EP_FETCH_COURSE_OVERVIEW_FAILED = 'HUB_EP_FETCH_COURSE_OVERVIEW_FAILED';
export const HUB_EP_FETCH_PROGRESS_SUMMARY_START = 'HUB_EP_FETCH_PROGRESS_SUMMARY_START';
export const HUB_EP_FETCH_PROGRESS_SUMMARY_SUCCESS = 'HUB_EP_FETCH_PROGRESS_SUMMARY_SUCCESS';
export const HUB_EP_FETCH_PROGRESS_SUMMARY_FAILED = 'HUB_EP_FETCH_PROGRESS_SUMMARY_FAILED';

// actions starting with HUB_AUTH are intended to be used on 'hub auth' pages
export const HUB_AUTH_SAVE_EMAIL_TEMPPWD = 'HUB_AUTH_SAVE_EMAIL_TEMPPWD';
export const HUB_AUTH_FIRST_LOG_IN_START = 'HUB_AUTH_FIRST_LOG_IN_START';
export const HUB_AUTH_FIRST_LOG_IN_SUCCESS = 'HUB_AUTH_FIRST_LOG_IN_SUCCESS';
export const HUB_AUTH_SIGN_UP_SUCCESS = 'HUB_AUTH_SIGN_UP_SUCCESS';
export const HUB_AUTH_FIRST_LOG_IN_FAILED = 'HUB_AUTH_FIRST_LOG_IN_FAILED';

export const HUB_AUTH_RESET_AUTH_UI_STATE = 'HUB_AUTH_RESET_AUTH_UI_STATE';

export const HUB_AUTH_LOG_IN_START = 'HUB_AUTH_LOG_IN_START';
export const HUB_AUTH_LOG_IN_SUCCESS = 'HUB_AUTH_LOG_IN_SUCCESS';
export const HUB_AUTH_LOG_IN_FAILED = 'HUB_AUTH_LOG_IN_FAILED';

export const HUB_AUTH_STAFF_LOGIN_START = 'HUB_AUTH_STAFF_LOGIN_START';
export const HUB_AUTH_STAFF_LOGIN_SUCCESS = 'HUB_AUTH_STAFF_LOGIN_SUCCESS';
export const HUB_AUTH_STAFF_LOGIN_FAILED = 'HUB_AUTH_STAFF_LOGIN_FAILED';

export const HUB_AUTH_USER_AUTHENTICATED = 'HUB_AUTH_USER_AUTHENTICATED';
export const HUB_AUTH_USER_UN_AUTHENTICATED = 'HUB_AUTH_USER_UN_AUTHENTICATED';
export const HUB_AUTH_USER_AUTHENTICATION_ERROR = 'HUB_AUTH_USER_AUTHENTICATION_ERROR';

export const HUB_AUTH_RESET_PASSWORD_REQUEST_START = 'HUB_AUTH_RESET_PASSWORD_REQUEST_START';
export const HUB_AUTH_RESET_PASSWORD_REQUEST_SUCCESS = 'HUB_AUTH_RESET_PASSWORD_REQUEST_SUCCESS';
export const HUB_AUTH_RESET_PASSWORD_REQUEST_FAILED = 'HUB_AUTH_RESET_PASSWORD_REQUEST_FAILED';

export const HUB_AUTH_SET_NEW_PASSWORD_START = 'HUB_AUTH_SET_NEW_PASSWORD_START';
export const HUB_AUTH_SET_NEW_PASSWORD_SUCCESS = 'HUB_AUTH_SET_NEW_PASSWORD_SUCCESS';
export const HUB_AUTH_SET_NEW_PASSWORD_FAILED = 'HUB_AUTH_SET_NEW_PASSWORD_FAILED';

export const HUB_AUTH_GET_USER_INFO_START = 'HUB_AUTH_GET_USER_INFO_START';
export const HUB_AUTH_GET_USER_INFO_SUCCESS = 'HUB_AUTH_GET_USER_INFO_SUCCESS';
export const HUB_AUTH_GET_USER_INFO_FAILED = 'HUB_AUTH_GET_USER_INFO_FAILED';

export const HUB_AUTH_RESEND_SIGNUP_LINK_REQUEST_START = 'HUB_AUTH_RESEND_SIGNUP_LINK_REQUEST_START';
export const HUB_AUTH_RESEND_SIGNUP_LINK_REQUEST_SUCCESS = 'HUB_AUTH_RESEND_SIGNUP_LINK_REQUEST_SUCCESS';
export const HUB_AUTH_RESEND_SIGNUP_LINK_REQUEST_FAILED = 'HUB_AUTH_RESEND_SIGNUP_LINK_REQUEST_FAILED';

// actions starting with HUB_AP are intended to be used on 'hub account' page
// ACCOUNT DETAILS - FETCH
export const HUB_AP_FETCH_ACCOUNT_DETAILS_START = 'HUB_AP_FETCH_ACCOUNT_DETAILS_START';
export const HUB_AP_FETCH_ACCOUNT_DETAILS_SUCCESS = 'HUB_AP_FETCH_ACCOUNT_DETAILS_SUCCESS';
export const HUB_AP_FETCH_ACCOUNT_DETAILS_FAILED = 'HUB_AP_FETCH_ACCOUNT_DETAILS_FAILED';
export const HUB_AP_CLEAR_ACCOUNT_DETAILS_STATUS_MESSAGE = 'HUB_AP_CLEAR_ACCOUNT_DETAILS_STATUS_MESSAGE';

// ACCOUNT DETAILS - UPDATE
export const HUB_AP_UPDATE_ACCOUNT_DETAILS_START = 'HUB_AP_UPDATE_ACCOUNT_DETAILS_START';
export const HUB_AP_UPDATE_ACCOUNT_DETAILS_SUCCESS = 'HUB_AP_UPDATE_ACCOUNT_DETAILS_SUCCESS';
export const HUB_AP_UPDATE_ACCOUNT_DETAILS_FAILED = 'HUB_AP_UPDATE_ACCOUNT_DETAILS_FAILED';

// PAYMENT SCEHDULE - FETCH
export const HUB_AP_FETCH_PAYMENT_SCHEDULE_START = 'HUB_AP_FETCH_PAYMENT_SCHEDULE_START';
export const HUB_AP_FETCH_PAYMENT_SCHEDULE_SUCCESS = 'HUB_AP_FETCH_PAYMENT_SCHEDULE_SUCCESS';
export const HUB_AP_FETCH_PAYMENT_SCHEDULE_FAILED = 'HUB_AP_FETCH_PAYMENT_SCHEDULE_FAILED';

// BILLING INFORMATION - FETCH
export const HUB_AP_FETCH_BILLING_INFORMATION_START = 'HUB_AP_FETCH_BILLING_INFORMATION_START';
export const HUB_AP_FETCH_BILLING_INFORMATION_SUCCESS = 'HUB_AP_FETCH_BILLING_INFORMATION_SUCCESS';
export const HUB_AP_FETCH_BILLING_INFORMATION_FAILED = 'HUB_AP_FETCH_BILLING_INFORMATION_FAILED';
export const HUB_AP_CLEAR_BILLING_INFORMATION_STATUS_MESSAGE = 'HUB_AP_CLEAR_ACCOUNT_BILLING_INFORMATION_MESSAGE';

// BILLING INFORMATION - CREATE CHARGENT ORDER
export const HUB_AP_CREATE_CHARGENT_ORDER_START = 'HUB_AP_CREATE_CHARGENT_ORDER_START';
export const HUB_AP_CREATE_CHARGENT_ORDER_SUCCESS = 'HUB_AP_CREATE_CHARGENT_ORDER_SUCCESS';
export const HUB_AP_CREATE_CHARGENT_ORDER_FAILED = 'HUB_AP_CREATE_CHARGENT_ORDER_FAILED';

// BILLING INFORMATION - PROCESS PAYMENT
export const HUB_AP_PROCESS_PAYMENT_START = 'HUB_AP_PROCESS_PAYMENT_START';
export const HUB_AP_PROCESS_PAYMENT_SUCCESS = 'HUB_AP_PROCESS_PAYMENT_SUCCESS';
export const HUB_AP_PROCESS_PAYMENT_FAILED = 'HUB_AP_PROCESS_PAYMENT_FAILED';
export const HUB_AP_BILLING_INFO_SUCCESS = 'HUB_AP_BILLING_INFO_SUCCESS';

// INVOICE HISTORY - FETCH
export const HUB_AP_FETCH_INVOICE_HISTORY_START = 'HUB_AP_FETCH_INVOICE_HISTORY_START';
export const HUB_AP_FETCH_INVOICE_HISTORY_SUCCESS = 'HUB_AP_FETCH_INVOICE_HISTORY_SUCCESS';
export const HUB_AP_FETCH_INVOICE_HISTORY_FAILED = 'HUB_AP_FETCH_INVOICE_HISTORY_FAILED';

// Hub - contact flow
export const HUB_CR_SAVE_SELECTED_ENROLMENT = 'HUB_CR_SAVE_SELECTED_ENROLMENT';
export const HUB_CR_SAVE_SELECTED_SESSION = 'HUB_CR_SAVE_SELECTED_SESSION';
export const HUB_CR_SAVE_SELECTED_STUDENT = 'HUB_CR_SAVE_SELECTED_STUDENT';

export const HUB_CR_RESET_CONTACT_CASE_STATE = 'HUB_CR_RESET_CONTACT_CASE_STATE';
export const HUB_CR_SUBMIT_CONTACT_CASE_START = 'HUB_CR_SUBMIT_CONTACT_CASE_START';
export const HUB_CR_SUBMIT_CONTACT_CASE_SUCCESS = 'HUB_CR_SUBMIT_CONTACT_CASE_SUCCESS';
export const HUB_CR_SUBMIT_CONTACT_CASE_FAILED = 'HUB_CR_SUBMIT_CONTACT_CASE_FAILED';

// Hub - contact flow - pause sessions
export const HUB_CR_PS_SAVE_AFFECTED_SESSIONS = 'HUB_CR_PS_SAVE_AFFECTED_SESSIONS';
export const HUB_CR_PS_SAVE_PAUSE_FROM_RESUME_FROM_DATE = 'HUB_CR_PS_SAVE_PAUSE_FROM_RESUME_FROM_DATE';
export const HUB_CR_PS_FETCH_RESUME_SESSION_START = 'HUB_CR_PS_FETCH_RESUME_SESSION_START';
export const HUB_CR_PS_FETCH_RESUME_SESSION_SUCCESS = 'HUB_CR_PS_FETCH_RESUME_SESSION_SUCCESS';
export const HUB_CR_PS_FETCH_RESUME_SESSION_FAILED = 'HUB_CR_PS_FETCH_RESUME_SESSION_FAILED';

// Hub - contact flow - enrolments will be paused
export const HUB_EWP_FETCH_RESUME_SESSION_START = 'HUB_EWP_FETCH_RESUME_SESSION_START';
export const HUB_EWP_FETCH_RESUME_SESSION_SUCCESS = 'HUB_EWP_FETCH_RESUME_SESSION_SUCCESS';
export const HUB_EWP_FETCH_RESUME_SESSION_FAILED = 'HUB_EWP_FETCH_RESUME_SESSION_FAILED';

// Hub - global actions
export const HUB_GLOBAL_GET_USER_TIMEZONE_SUCCESS = 'HUB_GLOBAL_GET_USER_TIMEZONE_SUCCESS';
export const HUB_GLOBAL_CLOCK_SKEW_DETECTED = 'HUB_GLOBAL_CLOCK_SKEW_DETECTED';

// actions starting with HUB_EM are intended to be used on 'hub enrolment management' pages
// Change schedule / change group schedule / cancel enrolment / pause enrolment
export const HUB_EM_SAVE_ENROLMENT_ID_FOR_MANAGEMENT = 'HUB_EM_SAVE_ENROLMENT_ID_FOR_MANAGEMENT';

export const HUB_EM_FETCH_TUTOR_INFO_START = 'HUB_EM_FETCH_TUTOR_INFO_START';
export const HUB_EM_FETCH_TUTOR_INFO_SUCCESS = 'HUB_EM_FETCH_TUTOR_INFO_SUCCESS';
export const HUB_EM_FETCH_TUTOR_INFO_FAILED = 'HUB_EM_FETCH_TUTOR_INFO_FAILED';
export const HUB_EM_SAVE_SESSION_INFO_WITH_NEW_TUTOR = 'HUB_EM_SAVE_SESSION_INFO_WITH_NEW_TUTOR';

export const HUB_EM_CSP_SAVE_SELECTED_TUTOR_NUMBER = 'HUB_EM_CSP_SAVE_SELECTED_TUTOR_NUMBER';
export const HUB_EM_SAVE_PREVIOUS_TUTOR_NUMBER = 'HUB_EM_SAVE_PREVIOUS_TUTOR_NUMBER';
export const HUB_EM_FETCH_PREVIOUS_TUTOR_INFO_START = 'HUB_EM_FETCH_PREVIOUS_TUTOR_INFO_START';
export const HUB_EM_FETCH_PREVIOUS_TUTOR_INFO_SUCCESS = 'HUB_EM_FETCH_PREVIOUS_TUTOR_INFO_SUCCESS';
export const HUB_EM_FETCH_PREVIOUS_TUTOR_INFO_FAILED = 'HUB_EM_FETCH_PREVIOUS_TUTOR_INFO_FAILED';
export const HUB_EM_FETCH_PREVIOUS_TUTOR_INFO_NOT_NEEDED = 'HUB_EM_FETCH_PREVIOUS_TUTOR_INFO_NOT_NEEDED';

export const HUB_EM_FETCH_TUTOR_AVAILABILITY_START = 'HUB_EM_FETCH_TUTOR_AVAILABILITY_START';
export const HUB_EM_FETCH_TUTOR_AVAILABILITY_SUCCESS = 'HUB_EM_FETCH_TUTOR_AVAILABILITY_SUCCESS';
export const HUB_EM_FETCH_TUTOR_AVAILABILITY_FAILED = 'HUB_EM_FETCH_TUTOR_AVAILABILITY_FAILED';

export const HUB_EM_SAVE_SELECTED_TUTOR_SCHEDULE = 'HUB_EM_SAVE_SELECTED_TUTOR_SCHEDULE';

// Enrolment management - Manage Pause
export const HUB_EM_MP_RESET_PAGE_STATE = 'HUB_EM_MP_RESET_PAGE_STATE';
export const HUB_EM_MP_SAVE_RESUME_SESSION = 'HUB_EM_MP_SAVE_RESUME_SESSION';

export const HUB_SET_FETCH_RESUME_SESSION_FLAG = 'HUB_SET_FETCH_RESUME_SESSION_FLAG';
export const HUB_EM_MP_FETCH_RESUME_SESSION_START = 'HUB_EM_MP_FETCH_RESUME_SESSION_START';
export const HUB_EM_MP_FETCH_RESUME_SESSION_SUCCESS = 'HUB_EM_MP_FETCH_RESUME_SESSION_SUCCESS';
export const HUB_EM_MP_FETCH_RESUME_SESSION_FAILED = 'HUB_EM_MP_FETCH_RESUME_SESSION_FAILED';

export const HUB_EM_MP_SUBMIT_NEW_RESUME_DATE_START = 'HUB_EM_MP_SUBMIT_NEW_RESUME_DATE_START';
export const HUB_EM_MP_SUBMIT_NEW_RESUME_DATE_SUCCESS = 'HUB_EM_MP_SUBMIT_NEW_RESUME_DATE_SUCCESS';
export const HUB_EM_MP_SUBMIT_NEW_RESUME_DATE_FAILED = 'HUB_EM_MP_SUBMIT_NEW_RESUME_DATE_FAILED';

// NOTE: TODO: replace CSP actions with EM actions
export const HUB_CSP_SAVE_ENROLMENT_ID = 'HUB_CSP_SAVE_ENROLMENT_ID';

export const HUB_CSP_SAVE_SELECTED_SCHEDULE = 'HUB_CSP_SAVE_SELECTED_SCHEDULE';

export const HUB_CSP_UPDATE_SCHEDULE_START = 'HUB_CSP_UPDATE_SCHEDULE_START';
export const HUB_CSP_UPDATE_SCHEDULE_SUCCESS = 'HUB_CSP_UPDATE_SCHEDULE_SUCCESS';
export const HUB_CSP_UPDATE_SCHEDULE_FAILED = 'HUB_CSP_UPDATE_SCHEDULE_FAILED';
export const HUB_CSP_UPDATE_SCHEDULE_FAILED_SHOW_NEW_SCHEDULE_CONFIRMATION =
  'HUB_CSP_UPDATE_SCHEDULE_FAILED_SHOW_NEW_SCHEDULE_CONFIRMATION';

export const HUB_CSP_FETCH_SELECTED_TUTOR_INFO_START = 'HUB_CSP_FETCH_SELECTED_TUTOR_INFO_START';
export const HUB_CSP_FETCH_SELECTED_TUTOR_INFO_SUCCESS = 'HUB_CSP_FETCH_SELECTED_TUTOR_INFO_SUCCESS';
export const HUB_CSP_FETCH_SELECTED_TUTOR_INFO_FAILED = 'HUB_CSP_FETCH_SELECTED_TUTOR_INFO_FAILED';

export const HUB_EM_CS_RESET_PAGE_STATE = 'HUB_EM_CS_RESET_PAGE_STATE';

// Enrolment management - Change group
export const HUB_EM_CG_FETCH_GROUP_SESSIONS_START = 'HUB_EM_CG_FETCH_GROUP_SESSIONS_START';
export const HUB_EM_CG_FETCH_GROUP_SESSIONS_SUCCESS = 'HUB_EM_CG_FETCH_GROUP_SESSIONS_SUCCESS';
export const HUB_EM_CG_FETCH_GROUP_SESSIONS_FAILED = 'HUB_EM_CG_FETCH_GROUP_SESSIONS_FAILED';
export const HUB_EM_CG_CLEAR_GROUP_SESSIONS = 'HUB_EM_CG_CLEAR_GROUP_SESSIONS';
export const HUB_EM_CG_SUBMIT_GROUP_START = 'HUB_EM_CG_SUBMIT_GROUP_START';
export const HUB_EM_CG_SUBMIT_GROUP_SUCCESS = 'HUB_EM_CG_SUBMIT_GROUP_SUCCESS';
export const HUB_EM_CG_SUBMIT_GROUP_FAILED = 'HUB_EM_CG_SUBMIT_GROUP_FAILED';

// Schools - Upcoming Sessions
export const SCHOOLS_US_FETCH_HOUSEHOLD_START = 'SCHOOLS_US_FETCH_HOUSEHOLD_START';
export const SCHOOLS_US_FETCH_HOUSEHOLD_SUCCESS = 'SCHOOLS_US_FETCH_HOUSEHOLD_SUCCESS';
export const SCHOOLS_US_FETCH_HOUSEHOLD_FAILED = 'SCHOOLS_US_FETCH_HOUSEHOLD_FAILED';

// Enrolment management - Temp Tutor Change
export const HUB_TTC_PAUSE_FETCH_RESUME_SESSION_START = 'HUB_TTC_PAUSE_FETCH_RESUME_SESSION_START';
export const HUB_TTC_PAUSE_FETCH_RESUME_SESSION_SUCCESS = 'HUB_TTC_PAUSE_FETCH_RESUME_SESSION_SUCCESS';
export const HUB_TTC_PAUSE_FETCH_RESUME_SESSION_FAILED = 'HUB_TTC_PAUSE_FETCH_RESUME_SESSION_FAILED';
export const HUB_TTC_PAUSE_SAVE_PAUSE_TO_DATE = 'HUB_TTC_PAUSE_SAVE_PAUSE_TO_DATE';
export const HUB_TTC_PAUSE_SUBMIT_REQUEST_START = 'HUB_TTC_PAUSE_SUBMIT_REQUEST_START';
export const HUB_TTC_PAUSE_SUBMIT_REQUEST_SUCCESS = 'HUB_TTC_PAUSE_SUBMIT_REQUEST_SUCCESS';
export const HUB_TTC_PAUSE_SUBMIT_REQUEST_FAILED = 'HUB_TTC_PAUSE_SUBMIT_REQUEST_FAILED';
export const HUB_TTC_FETCH_CURRENT_TUTOR_AVAILABILITY_START = 'HUB_TTC_FETCH_CURRENT_TUTOR_AVAILABILITY_START';
export const HUB_TTC_FETCH_CURRENT_TUTOR_AVAILABILITY_SUCCESS = 'HUB_TTC_FETCH_CURRENT_TUTOR_AVAILABILITY_SUCCESS';
export const HUB_TTC_FETCH_CURRENT_TUTOR_AVAILABILITY_FAILED = 'HUB_TTC_FETCH_CURRENT_TUTOR_AVAILABILITY_FAILED';
export const HUB_TTC_CHANGE_SESSION_WITH_ENROLMENT_TUTOR_CLEAN = 'HUB_TTC_CHANGE_SESSION_WITH_ENROLMENT_TUTOR_CLEAN';
export const HUB_TTC_SAVE_SELECTED_SESSION_DAY_TIME = 'HUB_TTC_SAVE_SELECTED_SESSION_DAY_TIME';
export const HUB_TTC_RESCHEDULE_SESSION_START = 'HUB_TTC_RESCHEDULE_SESSION_START';
export const HUB_TTC_RESCHEDULE_SESSION_SUCCESS = 'HUB_TTC_RESCHEDULE_SESSION_SUCCESS';
export const HUB_TTC_RESCHEDULE_SESSION_FAILED = 'HUB_TTC_RESCHEDULE_SESSION_FAILED';
export const HUB_TTC_FETCH_SESSION_TUTOR_START = 'HUB_TTC_FETCH_SESSION_TUTOR_START';
export const HUB_TTC_FETCH_SESSION_TUTOR_SUCCESS = 'HUB_TTC_FETCH_SESSION_TUTOR_SUCCESS';
export const HUB_TTC_FETCH_SESSION_TUTOR_FAILED = 'HUB_TTC_FETCH_SESSION_TUTOR_FAILED';

// Zuora Payment Pages
export const HUB_ZUORA_FETCH_ZUORA_SUCCESS = 'HUB_ZUORA_FETCH_ZUORA_SUCCESS';
export const HUB_ZUORA_FETCH_ZUORA_FAILED = 'HUB_ZUORA_FETCH_ZUORA_FAILED';
export const HUB_ZUORA_RESET_PARAMS = 'HUB_ZUORA_RESET_PARAMS';
