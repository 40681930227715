import { AnchorButton, Badge, ButtonAsLink, Link } from '@cluey/cluey-components';
import env from '../../../../env';
import ChangeSessionModal from '../../../Modals/ChangeSessionModal/ChangeSessionModal';
import ManageUpcomingSessionModal from '../../../Modals/ManageUpcomingSessionModal/ManageUpcomingSessionModal';
import moment from 'moment';
import { noop } from 'lodash';
import { sessionStartsInLessThanADay } from '../../../../util/helper/sessions';
import { ReactNode, useState } from 'react';
import { formatSession } from '../../../../util/helpers';
import { getPkgTutorNameByEnrolmentId } from '../../../../selectors/hubEnrolmentsSelector';
import { TTBM_CUSTOMER_NUMBER, DATE_FORMAT_TYPE_13 } from '../../../../util/constants';
import { ListItemHeader } from './ListItemHeader';
import { SessionDetails } from './SessionDetails';
import SessionPaymentInfo from '../SessionPaymentInfo';
import { useLiveUpdateSessionStatus } from './hooks';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { DesktopOnly } from '../../../DesktopOnly/DesktopOnly';
import { PATH_HUB_RESCHEDULE_SESSION_WITH_TUTOR } from '../../../../util/pagePath';
import { generatePath } from 'react-router';
import type { Session } from '../../../../types/hubInterfaces';
import isSessionJoinable from '../../../../util/helper/isSessionJoinable';
import { usePostHogCapture } from '../../../../util/posthog';

interface Props {
	session: Session;
	timezone: string;
	isPreview?: boolean;
}

const HubSessionsListItem = ({ session, timezone, isPreview = false }: Props) => {
	const {
		student,
		subject,
		tutor,
		tutorNumber,
		uniqueEnrolmentId,
		paymentStatus,
		studentSessionId,
		tutorRescheduleRequestStatusC,
	} = session;
	const isMaintenanceMode = env.REACT_APP_MAINTENANCE_MODE;
	const isTodaySession = moment(session.startTime).isSame(new Date(), 'day');
	const showJoinButton = isSessionJoinable(session);
	const [changeSessionModalOpen, setChangeSessionModalOpen] = useState(false);

	const capture = usePostHogCapture();

	const sessionProgress = useLiveUpdateSessionStatus({
		startTime: session.startTime,
	});

	const closeChangeSessionModal = () => {
		setChangeSessionModalOpen(false);
	};
	const openChangeSessionModal = () => {
		capture('click_session-manage');
		setChangeSessionModalOpen(true);
	};
	const getBoxBackgroundColor = () => {
		if (paymentStatus === 'Failed') {
			return 'bg-red-1';
		}
		if (tutorRescheduleRequestStatusC === 'Requested') {
			return 'bg-yellow-1';
		}
		if (showJoinButton) {
			return 'bg-blue-1';
		}
		return 'bg-white';
	};

	let badge: ReactNode | null = null;
	if (session.tutorRescheduleRequestStatusC === 'Approved') {
		badge = (
			<Badge className="flex min-h-[20px] flex-shrink-0 items-center rounded-xl !bg-slate-3 py-px !font-bold">
				Rescheduled with Tutor
			</Badge>
		);
	}

	if (session.tutorRescheduleRequestStatusC === 'Requested') {
		badge = (
			<Badge className="flex h-[20px] flex-shrink-0 items-center rounded-xl py-px !font-bold">
				Tutor Can't Make It
			</Badge>
		);
	}

	if (session.enrolmentType === 'Trial') {
		badge = (
			<Badge className="flex h-[20px] flex-shrink-0 items-center rounded-xl !bg-slate-3 py-px !font-bold">Trial</Badge>
		);
	}

	const packageTutor = useSelector((state) => getPkgTutorNameByEnrolmentId(state, uniqueEnrolmentId));
	const displayTutor = tutorNumber === TTBM_CUSTOMER_NUMBER ? packageTutor : tutor;
	const { date, time } = formatSession({
		session,
		timezone,
		sessionDateFormat: DATE_FORMAT_TYPE_13,
		useSemiColon: false,
	});
	const isRescheduleRequested = session.tutorRescheduleRequestStatusC === 'Requested';

	return (
		<div
			className={classNames(
				'relative flex w-full flex-col items-start gap-1 rounded-lg border-2 border-grey-2 px-5 py-3 shadow-md xl:flex',
				getBoxBackgroundColor()
			)}
		>
			{sessionStartsInLessThanADay({ session, timezone }) ? (
				<ManageUpcomingSessionModal
					open={changeSessionModalOpen}
					closeModal={closeChangeSessionModal}
					session={session}
				/>
			) : (
				<ChangeSessionModal
					open={changeSessionModalOpen}
					closeModal={closeChangeSessionModal}
					session={session}
					timezone={timezone}
				/>
			)}
			<div className="flex w-full items-start justify-between">
				<div className="flex flex-1 flex-col">
					<ListItemHeader session={session} sessionStatus={sessionProgress} />
					<div className="flex flex-col flex-wrap items-start gap-2 lg:flex-row lg:items-center lg:gap-3">
						<SessionDetails student={student} subject={subject} tutor={displayTutor} />
						{badge}
					</div>
				</div>
				<div className="ml-4">
					{showJoinButton ? (
						<AnchorButton
							size="sm"
							href={!isPreview ? session.sessionToken : ''}
							onClick={() => capture('click_session-join')}
							className="mb-1 hidden min-w-[80px] !px-6 normal-case lg:block"
						>
							Join now
						</AnchorButton>
					) : (
						<p className="hidden text-base text-grey-6 lg:block">{sessionProgress}</p>
					)}
				</div>
			</div>
			{isRescheduleRequested && (
				<Link
					className="block md:hidden"
					to={generatePath(PATH_HUB_RESCHEDULE_SESSION_WITH_TUTOR, {
						sessionId: studentSessionId,
					})}
					size="base"
				>
					Reschedule with {tutor}
				</Link>
			)}
			<div className="mt-1 flex w-full flex-col items-start justify-between lg:flex-row lg:items-center">
				<div className="flex flex-col gap-1">
					{isRescheduleRequested && (
						<Link
							className="hidden lg:block"
							to={generatePath(PATH_HUB_RESCHEDULE_SESSION_WITH_TUTOR, {
								sessionId: studentSessionId,
							})}
							size="base"
						>
							Reschedule with {tutor}
						</Link>
					)}
					<span className="text-xs font-bold uppercase">{isTodaySession ? 'Today' : date}</span>
					<span className="text-base">{time}</span>
					<p className="block text-base text-grey-6 lg:hidden">{sessionProgress}</p>
				</div>
				<div className="my-1 self-start lg:my-0">
					{!isMaintenanceMode &&
						(isTodaySession ? (
							<ButtonAsLink onClick={isPreview ? noop : openChangeSessionModal} size="sm">
								Can't make it
							</ButtonAsLink>
						) : (
							<div className="flex flex-col items-start gap-1 lg:items-end">
								<ButtonAsLink onClick={isPreview ? noop : openChangeSessionModal} size="base">
									Manage
								</ButtonAsLink>
							</div>
						))}
				</div>
			</div>
			<DesktopOnly className={classNames(showJoinButton ? 'flex' : 'hidden')} />
			{!isMaintenanceMode && <SessionPaymentInfo session={session} />}
		</div>
	);
};
export default HubSessionsListItem;
