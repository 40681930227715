import { useLocation } from 'react-router-dom';
import env from '../../env';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

export function useTrackPageView(title) {
    const location = useLocation()
    useEffect(() => {
        const dataLayer = {
            event: 'VirtualPageview',
            virtualPageURL: `${window.origin}${location.pathname}`,
            virtualPageTitle : title,
        }
        if(env.REACT_APP_ENABLE_GOOGLE_TAG_MANAGER){
        TagManager.dataLayer({ dataLayer });
        }  
      }, [location.pathname, title]);
}
