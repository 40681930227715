import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SimpleRadio } from '@cluey/cluey-components';

export const RadioPanel = ({ groupName, text, value, checked, setSelectedRadio }) => {
	const id = `${groupName}__${value}`;
	return (
		<button
			type="button"
			onClick={() => {
				setSelectedRadio(value);
			}}
			className={classnames(
				checked ? 'bg-primary text-white' : 'bg-slate-1 text-primary hover:bg-slate-2',
				'flex w-full cursor-pointer items-center justify-between rounded px-4 py-5 transition-all'
			)}
		>
			<label htmlFor={id} className="mb-0 cursor-pointer text-left text-lg tracking-normal">
				{text}
			</label>
			<SimpleRadio id={id} value={value} group={groupName} checked={checked} />
		</button>
	);
};
RadioPanel.propTypes = {
	groupName: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	checked: PropTypes.bool,
	setSelectedRadio: PropTypes.func.isRequired,
};
RadioPanel.defaultProps = {
	checked: false,
};
