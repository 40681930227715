import { Label } from '@cluey/cluey-components';
import { UseFormReturn } from 'react-hook-form';
import { BootlegSelect } from '../../../../views/hub/auth2/SignUp/components/BootlegSelect';
import { api } from '../../../../api';
import { useMemo } from 'react';
import range from 'lodash/range';
import { z } from 'zod';
import { StudentInformationLoader } from '../StudentInformationForm/StudentInformationLoader';
import classNames from 'classnames';
import type { Student } from '../../../../api/types/student';
import { StudentInformationFormData } from '../StudentInformationForm';

interface Props {
	className?: string;
	student?: Student;
	loading?: boolean;
	title?: boolean;
	formUtils: UseFormReturn<StudentInformationFormData>;
}

export const StudentYearLevelForm = ({ student, loading, formUtils, className, title }: Props) => {
	const {
		register,
		formState: { errors },
		clearErrors,
	} = formUtils;
	const { data: accountDetails } = api.account.details.useQuery();
	const kiwi = !!student ? student?.studentCountry !== 'Australia' : accountDetails?.country === 'NZ';

	const yearLevelOptions = useMemo(() => {
		const ops = (kiwi ? range(3, 12) : range(2, 13)).map((year) => {
			const yearValue = `Year ${year}`;
			return (
				<option key={year} value={yearValue}>
					{yearValue}
				</option>
			);
		});
		ops.unshift(
			<option key="default" value="default">
				Select
			</option>
		);
		return ops;
	}, [kiwi]);

	const onChange = () => clearErrors();

	if (loading) {
		return <StudentInformationLoader />;
	}

	return (
		<div className={classNames('flex max-w-md flex-col gap-5', className)}>
			{title && <div className="pb-4 text-xs font-bold uppercase tracking-[1px]">Student Information</div>}

			<div>
				<Label>School year</Label>
				<BootlegSelect
					id="yearLevel"
					className="mt-2"
					{...register('yearLevel', { onChange, required: true })}
					defaultValue={!!student ? student?.yearLevel : null}
				>
					{yearLevelOptions}
				</BootlegSelect>
				{errors.yearLevel && <p className="mt-1 text-xs text-red-5">{errors.yearLevel.message}</p>}
			</div>
		</div>
	);
};

export const StudentYearLevelFormData = StudentInformationFormData.pick({ yearLevel: true });
export type StudentYearLevelFormData = z.infer<typeof StudentYearLevelFormData>;
