/* @TODO add types to other selectors */
import {
  get, isEmpty, sortBy,
} from 'lodash';
import moment from 'moment';

import {
  PAUSE_SESSIONS_BY_FREQUENCY, PAUSE_SESSIONS_UNITS,
  SCHEDULE_DATE_FORMAT,
  SESSION_TIME_FORMAT,
} from '../util/constants';
import { getSelectedEnrolment } from './hubSelector';
import { isGroupSessionCheck } from '../util/helpers';

export const getEnrolmentSchedule = (state) => {
  const { enrolmentSchedule } = state.hubEnrolmentDetailPage;

  if (isEmpty(enrolmentSchedule)) {
    return [];
  }

  return enrolmentSchedule.map((s) => {
    const date = `${moment.utc(s.schedule, SCHEDULE_DATE_FORMAT).format('dddd')}s`;
    const time = moment.utc(s.schedule, SCHEDULE_DATE_FORMAT).format(SESSION_TIME_FORMAT);
    return `${date} at ${time}`;
  });
};

// NOTE: this is actually a helper function. In order to reduce render, it is moved to selector
export const getPauseSessionsOptionsByFrequency = (state): {
  RANGE_DEFAULT?: number;
  RANGE_SESSIONS?: Array<{text: number, value: number}>;
  RANGE_WEEKS?: Array<{text: number, value: number}>;
  UNITS?: Array<{text: 'Sessions' | 'Weeks', value: 'Sessions' | 'Weeks'}>;
  UNIT_DEFAULT?: string;
} => {
  const enrolments = get(state, 'hubEnrolmentsPage.activeEnrolments', []);
  const selectedEnrolId = get(state, 'hubContactPage.selectedEnrolment', '');

  if (enrolments.length === 0 || selectedEnrolId === '') {
    return {};
  }

  const selectedEnrolment = enrolments.find((enrolment) => {
    return enrolment.enrolmentId === selectedEnrolId;
  });

  const { sessionFrequency } = selectedEnrolment;

  let config = PAUSE_SESSIONS_BY_FREQUENCY[(sessionFrequency || 'other').toLowerCase()];
  if (isEmpty(config)) {
    config = PAUSE_SESSIONS_BY_FREQUENCY.other;
  }

  const {
    MIN_SESSIONS_TO_SKIP, MAX_SESSIONS_TO_SKIP, MIN_WEEKS_TO_SKIP, MAX_WEEKS_TO_SKIP,
  } = config;
  const UNITS = [];
  const RANGE_SESSIONS = [];
  const RANGE_WEEKS = [];

  if (MIN_SESSIONS_TO_SKIP > 0) {
    UNITS.push(
      {
        text: PAUSE_SESSIONS_UNITS.SESSIONS,
        value: PAUSE_SESSIONS_UNITS.SESSIONS,
      },
    );

    for (let i = MIN_SESSIONS_TO_SKIP; i <= MAX_SESSIONS_TO_SKIP; i++) {
      RANGE_SESSIONS.push(
        {
          text: i,
          value: i,
        },
      );
    }
  }

  if (MIN_WEEKS_TO_SKIP > 0) {
    UNITS.push(
      {
        text: PAUSE_SESSIONS_UNITS.WEEKS,
        value: PAUSE_SESSIONS_UNITS.WEEKS,
      },
    );

    for (let j = MIN_WEEKS_TO_SKIP; j <= MAX_WEEKS_TO_SKIP; j++) {
      RANGE_WEEKS.push(
        {
          text: j,
          value: j,
        },
      );
    }
  }

  return {
    UNITS,
    RANGE_SESSIONS,
    RANGE_WEEKS,
    RANGE_DEFAULT: 2,
    UNIT_DEFAULT: RANGE_SESSIONS.length > 0 ? PAUSE_SESSIONS_UNITS.SESSIONS : PAUSE_SESSIONS_UNITS.WEEKS,
  };
};

export const getTempTutorChangeInfo = (state) => {
  const defaultReturn = {
    hasTempTutorChange: false,
    enrolmentTutor: null,
    enrolmentTutorNumber: null,
    nextSessionTutor: null,
    nextSessionTutorNumber: null,
    nextSessionDateWithTempTutor: null,
    sessionsWithTempTutorChange: 0,
    nextSessionDateWithCurrentTutor: null,
    subsequentSessionTime: null,
  };
  // get sessions
  const sessions = get(
    state,
    'hubSessionsPage.upcomingSessions',
    [],
  );

  if (isEmpty(sessions)) {
    return defaultReturn;
  }

  // Need to update detail to return tutor id
  const enrolmentId = get(state, 'hubEnrolmentDetailPage.enrolmentId', null);
  const enrolmentTutorName = get(state, 'hubEnrolmentDetailPage.tutorName', '');
  const enrolmentTutorId = get(state, 'hubEnrolmentDetailPage.tutorNumber', '');

  const sessionsOfEnrolment = sessions.filter(Boolean).filter((s) => {
    return s.uniqueEnrolmentId === enrolmentId
    && moment(s.startTime).isAfter(moment(), 'minutes');
  });
  if (isEmpty(sessionsOfEnrolment)) {
    return defaultReturn;
  }
  const sortedSessions = sortBy(sessionsOfEnrolment, 'startTime');
  const nextSession = sortedSessions[0];
  let subsequentSession = sortedSessions[1];
  // NOTE: next session is still with package tutor
  if (!nextSession.automatedTutorChange || nextSession.tutorNumber === enrolmentTutorId) {
    return defaultReturn;
  }

  const sessionsWithTempTutor = sortedSessions.filter((s) => {
    return !!s.automatedTutorChange && s.tutorNumber !== enrolmentTutorId;
  });
  const nextSessionWithTempTutor = sessionsWithTempTutor[0] || {};
  const {
    studentSessionId: nextSessionId = null,
    tutor: nextSessionTutor = null,
    tutorNumber: nextSessionTutorNumber = null,
    startTime: nextSessionDateWithTempTutor = null,
  } = nextSessionWithTempTutor;

  // next session is the last session
  if (isEmpty(subsequentSession)) {
    subsequentSession = nextSession;
  }

  const nextSessionWithCurrentTutor = sortedSessions.find(s => s.tutorNumber === enrolmentTutorId);
  const { startTime: nextSessionDateWithCurrentTutor = null } = nextSessionWithCurrentTutor || {};

  return {
    hasTempTutorChange: !isEmpty(sessionsWithTempTutor),
    nextSessionId,
    nextSessionTutor,
    enrolmentTutor: enrolmentTutorName,
    enrolmentTutorNumber: enrolmentTutorId,
    nextSessionTutorNumber,
    nextSessionDateWithTempTutor,
    sessionsWithTempTutorChange: sessionsWithTempTutor.length,
    nextSessionDateWithCurrentTutor,
    subsequentSessionTime: subsequentSession.startTime,
  };
};

const getEnrolmentsOfAlternateMode = (enrolments, enrolmentId, student, yearAsInteger, subject, type) => {
  return enrolments.find(e => e.enrolmentId !== enrolmentId && e.student === student && e.yearAsInteger === yearAsInteger
    && e.subject === subject && e.type !== type);
};

// NOTE: Cancel enrolment page selector
export const switchToLearningMode = (state): {
  currentMode?: 'group sessions' | 'private 1-to-1 sessions';
  switchToMode?: 'private learning' | 'group learning';
} => {
  const selectedEnrolment = getSelectedEnrolment(state);
  const {
    yearAsInteger, student, subject, enrolmentId, type,
  } = selectedEnrolment || {};

  const enrolments = get(state, 'hubEnrolmentsPage.activeEnrolments', []);

  if (isEmpty(enrolments) || !enrolmentId) {
    return {};
  }

  const conflicts = getEnrolmentsOfAlternateMode(enrolments, enrolmentId, student, yearAsInteger, subject, type);

  if (isEmpty(conflicts)) {
    return {
      currentMode: isGroupSessionCheck(type) ? 'group sessions' : 'private 1-to-1 sessions',
      switchToMode: isGroupSessionCheck(type) ? 'private learning' : 'group learning',
    };
  }

  return {};
};

// NOTE, enrolment detail page selector
export const getEnrolmentAlternateMode = (state) => {
  const {
    studentName, enrolmentDiscipline, yearAsInteger, enrolmentId, enrolmentType,
  } = state.hubEnrolmentDetailPage || {};

  const enrolments = get(state, 'hubEnrolmentsPage.activeEnrolments', []);

  if (isEmpty(enrolments) || !enrolmentId) {
    return {};
  }

  const conflicts =
    getEnrolmentsOfAlternateMode(enrolments, enrolmentId, studentName, yearAsInteger, enrolmentDiscipline, enrolmentType);

  if (isEmpty(conflicts)) {
    return {
      detail: isGroupSessionCheck(enrolmentType) ? 'private 1-to-1' : 'group',
    };
  }

  return {};
};
