import React, { useEffect } from 'react';
import { Container } from '@cluey/cluey-components';
import HubHeader from './HubHeader';
import HubFooter from './HubFooter/HubFooter';
import { scrollToTop } from '../../util/helpers';
import withHeadTag from '../HeadComponent';

interface HubPageLayoutProps {
  title?: string;
  children: React.ReactNode;
  noContainer?: boolean;
}

const HubPageLayout = ({
  title = 'HUB',
  children,
  noContainer,
}: HubPageLayoutProps) => {

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    // class hub removed from below container
    <div className="flex min-h-screen flex-col">
      <HubHeader title={title} />
      {!noContainer ? (
        <Container className="mb-20 flex flex-grow lg:mb-0">
          <div className="relative w-full pt-5 lg:pt-11">{children}</div>
        </Container>
      ) : (
        children
      )}
      <HubFooter />
    </div>
  );
};

export default withHeadTag(HubPageLayout);
