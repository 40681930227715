import posthog from 'posthog-js';
import { usePostHog } from 'posthog-js/react';
import { useCallback } from 'react';
import env from '../env';

if (env.REACT_APP_POSTHOG_ENABLED) {
  posthog.init(env.REACT_APP_POSTHOG_API_KEY, {
    api_host: env.REACT_APP_POSTHOG_API_HOST,
    ui_host: 'https://us.posthog.com',
    autocapture: true,
    capture_pageleave: false,
    capture_pageview: false,
  });
  console.log('[debug] PostHog initialized');
}

posthog.capture('initialized', { environment: env.REACT_APP_POSTHOG_ENVIRONMENT });

export type PostHogEvent =
	| 'click_auth-log-in'
	| 'click_session-manage'
	| 'click_session-join'
	| 'click_session-join__upcoming-sessions-page'
	| 'click_rocket-chat-open'
	| 'click_rocket-chat-close'
	| 'click_add-session-message_open-support-chat'
	| 'click_add-session-message_submit-message'
	| 'click_add-session-message_submit-error-contact-details-detected'
	| 'click_add-session-message_is-support-related-yes'
	| 'click_add-session-message_is-support-related-no'
	| 'click_add-session-message_is-session-specific-yes'
	| 'click_add-session-message_is-session-specific-no'
	| 'click_add-session-message_select-category-request-specific-content'
	| 'click_add-session-message_select-category-provide-student-update'
	| 'click_add-session-message_select-category-other'
	| 'view_add-session-message'
	| 'click_support_session-times'
	| 'click_support_manage-enrolment'
	| 'click_support_learning-feedback'
	| 'click_support_learning-adjustments'
	| 'click_support_add-a-subject'
	| 'click_support_billing-and-payments'
	| 'click_support_account-details'
	| 'click_support_technology'
	| 'click_support_cancel-enrolment'
	| 'click_support_report-an-issue'
	| 'click_support_customer-hub-updates'
	| 'click_support_chat'
	| 'click_manage-enrolment_change-enrolment-pause'
	| 'click_manage-enrolment_change-schedule'
	| 'click_manage-enrolment_change-tutor'
	| 'click_manage-enrolment_change-content-focus'
	| 'click_manage-enrolment_pause-enrolment'
	| 'click_manage-enrolment_cancel-enrolment'
	| 'click_session-times_reschedule-session'
	| 'click_session-times_cancel-session'
	| 'click_session-times_add-one-off-session'
	| 'click_session-times_change-schedule'
	| 'click_session-times_pause-enrolment'
	| 'click_session-times_cancel-enrolment'
	| 'click_manage-upcoming-session_reschedule-session'
	| 'click_manage-upcoming-session_cancel-session'
	| 'click_manage-session_reschedule-session'
	| 'click_manage-session_cancel-session'
	| 'click_manage-session_add-session-message'
	| 'click_manage-session_change-schedule'
	| 'click_manage-session_pause-enrolment'
	| 'click_manage-session_cancel-enrolment'
	| 'click_manage-session_change-tutor'
	| 'click_manage-session_something-else'
	| 'click_home-cluey-coach'
	| 'click_student-profile_cluey-coach'
	| 'click_cluey-coach_select-topic'
	| 'click_cluey-coach_toggle-virtual-keyboard'
	| 'click_cluey-coach_open-mathlive-menu'
	| 'click_cluey-coach_book-a-session'
	| 'click_cluey-coach_beta-banner-learn-more'
	| 'click_cluey-coach_learn-more'
	| 'click_cluey-coach_new-chat'
	| 'click_cluey-coach_cancel-new-chat'
	| 'cluey-coach_add-attachment'
	| 'cluey-coach_submit-question'
	| 'cluey-coach_initialize'
	| 'view_cluey-coach_requests-used-up'


const capture = (key: PostHogEvent, params?: Record<string, unknown>) => {
  posthog.capture(key, {
    ...params,
    env: env.REACT_APP_POSTHOG_ENVIRONMENT,
  });
};

const usePostHogCapture = () => {
  const posthog = usePostHog();

  const capture = useCallback(
    (key: PostHogEvent, params?: Record<string, unknown>): void => {
      if (posthog) {
        posthog.capture(key, {
          ...params,
          env: env.REACT_APP_POSTHOG_ENVIRONMENT,
        });
      }
    },
    [posthog]
  );

  return capture;
};

export { posthog, capture, usePostHogCapture };
