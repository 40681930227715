import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ButtonAsLink } from '@cluey/cluey-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'lodash';
import LockOrange from '../../../../assets/images/icon-lock-orange.svg';
import ClueyLoaderWhite from '../../../../assets/images/cluey-loader-white.gif';
import { PaymentService } from '../../../../services/PaymentService';
import {
  fetchZuoraParams as fetchZuoraParamsAction,
  resetZuoraParams as resetZuoraParamsAction,
  setZuoraSuccessMsg,
} from '../../../../actions/hub/fetchZuoraParams';
import { getZuoraPrefill } from '../../../../selectors/zuoraSelector';
import { InfoBox } from '../../home/InfoBox';

const importZuoraJS = () =>
  new Promise((resolve, reject) => {
    const scriptTag = document.createElement('script');
    scriptTag.src =
      'https://static.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js';
    scriptTag.async = true;
    scriptTag.onload = () => resolve(scriptTag);
    scriptTag.onerror = () => reject();
    document.body.appendChild(scriptTag);
  });

const destroyZuoraJS = (scriptTag) => () => {
  scriptTag.remove();
  // eslint-disable-next-line
  Z = undefined;
};

/**
 * Renders payment page, returning a promise that will resolve upon submission
 * @param {object} params zuora params for page render
 * @param {object} prefill any prefill data for payment page
 * @returns {Promise<object>}
 */
const promisifyZRender = (params, prefill) =>
  new Promise((resolve, reject) => {
    if (typeof Z !== 'undefined') {
      try {
        // eslint-disable-next-line
        Z.render(params, prefill, (response) => resolve(response));
      } catch (error) {
        console.warn('ZUORA.JS HIT AN ERROR: ', { error });
        error.isZuoraJSError = true;
        reject(error);
      }
    } else {
      reject(new Error('Zuora JS not found'));
    }
  });

/**
 * Triggers payment page rendering, returning a promise that will resolve to another promise
 * @param {object} params zuora params for render
 * @param {object} prefill prefill form items
 * @returns {Promise}
 */
const renderZuora = (params, prefill = {}) =>
  new Promise((resolve, reject) => {
    if (typeof Z !== 'undefined') {
      const renderPromise = promisifyZRender(params, prefill);
      // eslint-disable-next-line
      Z.setEventHandler('onloadCallback', () => {
        resolve(() => renderPromise);
      });
    } else {
      reject(new Error('Zuora JS not found'));
    }
  });

// <EditViewZuora />
export const EditViewZuora = ({
  setMode,
  fetchZuoraParams,
  zuoraParams,
  zuoraError,
  zuoraAccountId,
  resetZuoraParams,
  prefill,
  setZuoraSuccess,
}) => {
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    let destructor = () => {};
    let ok = true;

    importZuoraJS().then((scriptTag) => {
      if (ok) {
        setScriptLoaded(true);
        destructor = destroyZuoraJS(scriptTag);
      } else {
        destroyZuoraJS(scriptTag)();
      }
    });

    return () => {
      destructor();
      ok = false;
    };
  }, []);

  useEffect(() => {
    let ok = true;
    const buildZuora = async (params) => {
      if (!isEmpty(params)) {
        setLoading(true);
        setErrorMsg('');
        try {
          // await rendering of payment page
          const zuoraSuccess = await renderZuora(params, prefill);

          // ensure useEffect hasn't been cancelled
          if (!ok) throw new Error('Promise Cancelled');

          // no longer loading
          setLoading(false);

          // await the response from user submission -- might take a while to resolve
          const { refId } = await zuoraSuccess();

          // ensure useEffect hasn't been cancelled
          if (!ok) throw new Error('Promise Cancelled');

          // loading again for Mulesoft request
          setLoading(true);

          // Need to verify card details!
          await PaymentService.validateCreditCard(refId);

          // ensure useEffect hasn't been cancelled
          if (!ok) throw new Error('Promise Cancelled');

          // await MuleSoft request set primary payment method
          await PaymentService.setPrimaryPaymentMethod(zuoraAccountId, refId);

          // Reset the zuora params as they may expire
          resetZuoraParams();

          // Set success message in redux
          setZuoraSuccess();

          // Done now, go back to read mode
          setMode('read');
        } catch (error) {
          if (error.isZuoraJSError) {
            setErrorMsg('');
          } else {
            console.warn('buildZoura threw error: ', { error });
            setErrorMsg(error.message);
          }
          setLoading(false);
        }
      }
    };

    if (
      zuoraParams &&
      !isEmpty(zuoraParams) &&
      zuoraAccountId &&
      scriptLoaded
    ) {
      buildZuora({ ...zuoraParams, field_accountId: zuoraAccountId });
    } else if (zuoraError) {
      setErrorMsg(zuoraError);
    } else {
      fetchZuoraParams();
    }

    return () => {
      ok = false;
    };
  }, [
    setMode,
    zuoraParams,
    zuoraError,
    fetchZuoraParams,
    resetZuoraParams,
    zuoraAccountId,
    scriptLoaded,
    prefill,
    setZuoraSuccess,
  ]);

  return (
    <>
      {loading ? (
        <div className="flex justify-center">
          <img src={ClueyLoaderWhite} alt="Loading" className="h-[100px]" />
        </div>
      ) : (
        <div className="block">
          <h2 className="flex items-center text-sm text-slate-5">
            <img src={LockOrange} alt="" height="20px" className="pr-4" /> We
            use 128-bit SSL encryption to secure your details
          </h2>
        </div>
      )}
      <div className="">
        {errorMsg && <InfoBox type="error" body={errorMsg} />}
        <div id="zuora_payment" style={{ opacity: loading ? 0 : 1 }} />
      </div>
      {!loading && (
        <div className="p-4">
          <ButtonAsLink size="lg" onClick={() => setMode('read')}>
            Cancel
          </ButtonAsLink>
        </div>
      )}
    </>
  );
};

EditViewZuora.propTypes = {
  setMode: PropTypes.func.isRequired,
  fetchZuoraParams: PropTypes.func.isRequired,
  resetZuoraParams: PropTypes.func.isRequired,
  zuoraParams: PropTypes.shape({
    id: PropTypes.string,
    key: PropTypes.string,
    signature: PropTypes.string,
    style: PropTypes.string,
    submitEnabled: PropTypes.bool,
    tenantId: PropTypes.string,
    token: PropTypes.string,
    url: PropTypes.string,
  }),
  zuoraError: PropTypes.string.isRequired,
  zuoraAccountId: PropTypes.string.isRequired,
  prefill: PropTypes.shape({
    city: PropTypes.string,
    postcode: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
  setZuoraSuccess: PropTypes.func.isRequired,
};

EditViewZuora.defaultProps = {
  zuoraParams: null,
};

export const ConnectedEditViewZuora = connect(
  (state) => {
    const { zuora } = state;
    return {
      zuoraParams: zuora.params,
      zuoraError: zuora.error,
      prefill: getZuoraPrefill(state),
    };
  },
  (dispatch) => {
    return {
      fetchZuoraParams: bindActionCreators(fetchZuoraParamsAction, dispatch),
      resetZuoraParams: bindActionCreators(resetZuoraParamsAction, dispatch),
      setZuoraSuccess: bindActionCreators(setZuoraSuccessMsg, dispatch),
    };
  }
)(EditViewZuora);
