import { ddLogger, ddLoggerLevel, logError } from '../logging';
import { DatesUtils } from '../util/dates';
import env from '../env';
import { INVALID_PAYLOAD } from '../logging/errors';
import moment from 'moment';
import { sendPostRequest } from './RequestService';
import { CancelEnrolmentParams, CancelReason } from '../types/hubInterfaces';
import { getCancelMessage, getConcatenatedCancelReason } from '../util/helper/cancelEnrolment';

export class CancelService {
  async cancelEnrolment({
    rating,
    enrolmentId,
    endDate,
    message,
    selectedReasons,
    otherMessage
  }: CancelEnrolmentParams) {
    const url = CancelService.getMuleSoftEndpoint(enrolmentId);
    const effectiveFromDate = moment(endDate).format('YYYY-MM-DD');
    const customerMessage = getCancelMessage({ selectedReasons, otherMessage, message });
    const cancellationReason = getConcatenatedCancelReason({ selectedReasons });
    const payload = {
      sourceSystem: 'Hub',
      cancellationDetails: {
        enrolmentId,
        caseOrigin: 'Hub',
        customerMessage,
        requestDate: moment().format('YYYY-MM-DD'),
        effectiveFromDate,
        type: 'new',
        rating: String(rating),
        cancellationReason
      },
    };

    const errorMessageForUser = 'There was a problem cancelling your enrolment. Please try again later or contact us.';

    if (!CancelService.validateCancelEnrolmentPayload(payload)) {
      ddLogger({
        level: ddLoggerLevel.ERROR,
        label: INVALID_PAYLOAD.message,
        data: { url, ...payload },
        error: new Error(INVALID_PAYLOAD.message),
      });
      throw new Error(errorMessageForUser);
    }

    return sendPostRequest({
      url,
      payload,
      customErrorMsg: errorMessageForUser,
    });
  }

  static async getCancellationReasons(): Promise<[{
    name: string;
    type: string;
    values: Array<CancelReason>
  }]> {
    const url = `https://${env.REACT_APP_API_SF_HOST_NAME}/Customer/master-data`;
    try {
      const res = await sendPostRequest({
        url,
        payload: [{
          name: "enrolment-cancel-reason",
          type : "picklist"
        }],
        customErrorMsg: "Cancellation reasons retrieval failed.",
      })
      return res.json();
    } catch(error) {
      throw error;
    }
  }

  static validateCancelEnrolmentPayload(payload) {
    if (!payload) {
      logError('Cancel Service Error. No payload found', null, {});
      return false;
    }

    const validSourceSystem = ['hub', 'salesforce'];
    if (!payload.sourceSystem || !validSourceSystem.includes(payload.sourceSystem.toLowerCase())) {
      logError(`Cancel Service Error. Invalid sourceSystem ${payload.sourceSystem}`, null, {});
      return false;
    }

    if (!payload.cancellationDetails) {
      logError('Cancel Service Error. No cancellationDetails found in payload', null, {});
      return false;
    }

    const { cancellationDetails } = payload;

    if (!cancellationDetails.requestDate || !DatesUtils.isValidDate(cancellationDetails.requestDate)) {
      logError(`Cancel Service Error. Invalid requestDate ${cancellationDetails.requestDate}`, null, {});
      return false;
    }

    if (!cancellationDetails.effectiveFromDate || !DatesUtils.isValidDate(cancellationDetails.effectiveFromDate)) {
      logError(`Cancel Service Error. Invalid effectiveFromDate ${cancellationDetails.effectiveFromDate}`, null, {});
      return false;
    }

    return true;
  }

  static getMuleSoftEndpoint(enrolmentId) {
    return `https://${env.REACT_APP_MULESOFT_DOMAIN}/enrolments/${enrolmentId}/cancellations`;
  }
}
