import { ChoiceButton, Container, Heading2, Heading4 } from '@cluey/cluey-components';
import { ClueyModal } from '../../../../../common/ClueyModal';
import EnrolmentLayout from '../../../../../common/hub/EnrolmentLayout';
import { api } from '../../../../../api';
import { SkeletonLoader } from '../../../../../components/SkeletonLoader';
import { PATH_HUB_ADD_STUDENT, PATH_HUB_CR_ADD_ENROLMENT } from '../../../../../util/pagePath';
import { Student } from '../../../../../api/types/student';
import { useHistory, generatePath } from 'react-router';
import { AUSTRALIA_STATES } from '../../../../../util/constants';
import { useState } from 'react';
import { PATH_HUB_ADD_ONE_OFF_SESSION } from '../../../../../util/pagePath';
import { Enrolment } from '../../../../../api/types/enrolment';
import { PlusIcon } from '../../../../../icons';

const ManageServiceStudentsAndSubjects = () => {
	const history = useHistory();
	const [modalVisible, setModalVisible] = useState(false);
	const { data: students, isLoading: loadingStudents } = api.student.all.useQuery();
	const { data: enrolments, isLoading: loadingEnrolments } = api.enrolment.all.useQuery('active');
	const modalConfig = {
		choices: enrolments ?? [],
		title: 'Select enrolment',
		subtitle: '',
		callback: (choice: Enrolment) => {
			const { enrolmentId } = choice;
			history.push(generatePath(PATH_HUB_ADD_ONE_OFF_SESSION, { enrolmentId }));
		},
	};

	const onStudentOptionClick = (student: Student) => {
		const state = Object.entries(AUSTRALIA_STATES).find(([key]) => key === student.location);
		history.push(PATH_HUB_CR_ADD_ENROLMENT, {
			firstName: student.firstName,
			lastName: student.lastName,
			yearLevel: student.yearLevel ? student.yearLevel : undefined,
			location: state ? state[1] : undefined,
			studentId: student.studentId,
		});
	};

	const onAddNewStudent = () => {
		history.push(PATH_HUB_ADD_STUDENT, {
			enrolStudent: true,
		});
	};

	return (
		<EnrolmentLayout
			sectionHeading="Home"
			mainHeading="Enrol into a subject"
			showEnrolmentStatus={false}
			showSummerModeEnrolmentStatus={false}
		>
			<Container>
				<ClueyModal open={modalVisible} setOpen={setModalVisible}>
					<Container className="pb-10 lg:px-16 lg:pb-16">
						<Heading2 className="text-grey-6">{modalConfig.title}</Heading2>
						<p className="text-grey-6">{modalConfig.subtitle}</p>
						<section className="mt-8 flex flex-col gap-y-2.5">
							{modalConfig.choices.map((choice, index) => (
								<ChoiceButton
									key={`${modalConfig.title}-${choice.student}-${index}`}
									onClick={() => modalConfig.callback(choice)}
								>
									{`${choice.student} - ${choice.yearLevel} ${choice.subject} with ${choice.tutor}`}
								</ChoiceButton>
							))}
						</section>
					</Container>
				</ClueyModal>
				<SkeletonLoader className="h-6 w-auto md:w-80" loading={loadingStudents}>
					<Heading4>Who would you like to enrol into a new learning program?</Heading4>
				</SkeletonLoader>
				<div className="mt-7 flex flex-col gap-3">
					{students?.map((student) => (
						<SkeletonLoader key={student.studentId} className="h-[60px]" loading={loadingStudents}>
							<ChoiceButton key={student.studentId} onClick={() => onStudentOptionClick(student)}>
								{student.firstName}
							</ChoiceButton>
						</SkeletonLoader>
					))}
					<SkeletonLoader className="mt-3 h-[60px]" loading={loadingStudents} />
					<SkeletonLoader className="mt-3 h-[60px]" loading={loadingStudents}>
						<ChoiceButton key="add-a-new-student-button" onClick={onAddNewStudent}>
							<div className="flex flex-row items-center">
								<PlusIcon width={16} height={16} className="mr-2" />
								Add a new student
							</div>
						</ChoiceButton>
					</SkeletonLoader>
				</div>
				<div className="mt-10">
					<SkeletonLoader className="mb-3 h-6 w-auto md:w-80" loading={loadingStudents}>
						<Heading4>Already enrolled and want extra sessions?</Heading4>
					</SkeletonLoader>
					<SkeletonLoader className="mt-7 h-6 w-auto md:w-36" loading={loadingEnrolments}>
						<button className="cursor-pointer text-blue-5" onClick={() => setModalVisible(true)}>
							Add ad-hoc sessions
						</button>
					</SkeletonLoader>
				</div>
			</Container>
		</EnrolmentLayout>
	);
};

export default ManageServiceStudentsAndSubjects;
